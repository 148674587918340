import { Collapse } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";
import { setLeftAccordionExpandedState } from "Data/Actions/UI";
import { getActiveNavigationItem, getLeftAccordionExpanded } from "Data/Selectors/UI";

interface IAccordionProps {
	activeNavigationItem: string;
	condense?: boolean;
	elements: AccordionElement[];
	expanded: string[];
	header?: JSX.Element | string;
	headerPadding?: string;
	setExpanded: (key: string, expanded: string[]) => void;
}

interface IAccordionState {
}

const { Panel } = Collapse;

const mapStateToProps = (state, ownProps) => {
	const leftAccordion = getLeftAccordionExpanded(state);
	// provide a default "open" state if we don't have any accordion state for this page
	const expanded = leftAccordion === null ? ownProps.elements.map((e, i) => i.toString()) : leftAccordion;

	return {
		activeNavigationItem: getActiveNavigationItem(state),
		expanded
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	setExpanded: (key: string, expanded: string[]) => dispatch(setLeftAccordionExpandedState(key, expanded))
});

export class UnconnectedAccordion extends React.PureComponent<IAccordionProps, IAccordionState> {
	constructor(props: IAccordionProps) {
		super(props);

		this.styles = {
			container: {
				height: "100%",
				display: "flex",
				flexDirection: "column",
				background: "transparent"
			},
			headerStyle: {
				display: this.props.header ? "block" : "none",
				color: Colors.white,
				background: Colors.darkGray,
				padding: this.props.headerPadding || "4px 0",
				textAlign: "center"
			},
			collapse: {
				color: Colors.white,
				display: "flex",
				flexDirection: "column",
				height: 0,
				flex: 1,
				background: Colors.darkestGray,
				width: 250,
				border: 0,
				borderRadius: 0
			},
			expandedPanel: {
				transition: "all .4s",
				color: Colors.white,
				background: Colors.darkestGray,
				borderRadius: 0,
				overflow: "hidden"
			},
			collapsedPanel: {
				transition: "all .4s",
				position: "relative",
				background: Colors.darkestGray,
				bottom: 0,
				height: 35,
				borderRadius: 0
			},
			panelHeader: {
				padding: 0,
				margin: "0px 30px 0px 8px",
				height: 35,
				lineHeight: "35px",
				display: "flex",
				flexGrow: 1,
				justifyContent: "space-between",
				color: Colors.white,
				background: Colors.darkGray
			},
			panelContent: {
				color: Colors.white,
				background: Colors.darkestGray,
				overflowY: "auto",
				padding: 0,
				height: "100%",
				position: "relative",
				overflowX: "hidden"
			}
		}

		this.isExpanded = this.isExpanded.bind(this);
		this.toggleExpanded = this.toggleExpanded.bind(this);
	}

	styles: {
		container: CustomCSS;
		headerStyle: CustomCSS;
		collapse: CustomCSS;
		expandedPanel: CustomCSS;
		collapsedPanel: CustomCSS;
		panelHeader: CustomCSS;
		panelContent: CustomCSS;
	}

	render() {
		const { elements, expanded, header } = this.props;
		const { container, headerStyle, collapse } = this.styles;
		return (
			<div style={ container }>
				<div style={ headerStyle }>
					{ header }
				</div>
				<Collapse
					className="accordion"
					defaultActiveKey={ expanded }
					style={ collapse }
					onChange={ this.toggleExpanded }>
					{ this.renderPanels(elements) }
				</Collapse>
			</div>
		);
	}

	renderPanels(panels: AccordionElement[]) {
		const { panelHeader, panelContent } = this.styles;
		return panels
			// filter out panels that are conditionally not rendered
			.filter((panel) => panel)
			.map((panel, index) => {
				return (
					<Panel
						className={ `accordion ${ panel.cssClass }` }
						style={ this.getPanelStyle(panel, index) }
						header={
							<div
								style={panelHeader}
								onClick={(e) => e.stopPropagation()}>
								{ panel.header }
								<span style={ this.getColorBlockStyle(panel) } />
							</div>
						}
						key={ index.toString() }>
						<div style={ panelContent }>
							{ panel.content }
						</div>
						<div style={ this.getFooterStyle(panel) }>
							{ panel.footer }
						</div>
					</Panel>
				);
			})
	}

	getPanelStyle(panel: AccordionElement, index: number) {
		if (this.isExpanded(index)) {
			return {
				...this.styles.expandedPanel,
				flex: this.props.condense ? "0 1 auto" : "1 0",
				paddingBottom: this.props.condense ? 0 : 35
			} as CustomCSS;
		}
		return this.styles.collapsedPanel;
	}

	getColorBlockStyle(panel: AccordionElement) {
		return {
			display: panel.color ? "block" : "none",
			width: 6,
			height: "100%",
			background: panel.color,
			position: "absolute",
			top: 0,
			right: 0
		} as CustomCSS;
	}

	getFooterStyle(panel: AccordionElement) {
		return {
			position: "sticky",
			flex: "0 1 35px",
			display: panel.footer ? "block" : "none",
			padding: 0,
			margin: 0,
			bottom: 35,
			width: "100%"
		} as CustomCSS;
	}

	canGrow(panel: AccordionElement, index: number) {
		return !!panel.canGrow || !(Number(index) === 0 && this.props.expanded.length > 1)
	}

	isExpanded(index: number) {
		const i = index.toString();
		return this.props.expanded.indexOf(i) > -1;
	}

	toggleExpanded(keys: string[]) {
		const { activeNavigationItem, setExpanded } = this.props;

		setExpanded(activeNavigationItem, keys);
	}
}

export const Accordion = connect(mapStateToProps, mapDispatchToProps)(UnconnectedAccordion);

export class AccordionElement {
	constructor(header: string | JSX.Element, content: JSX.Element, color?: string,
		footer?: JSX.Element, canGrow?: boolean, expanded: boolean = true, maxHeight?: number, cssClass?: string) {
		return { header, content, color, footer, canGrow, expanded, maxHeight, cssClass };
	}

	header: string | JSX.Element;
	content: JSX.Element;
	color?: string;
	footer?: JSX.Element;
	canGrow?: boolean;
	expanded: boolean;
	maxHeight?: number;
	cssClass?: string;
}
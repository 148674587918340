import { API_SORT, IMedia, IMediaApi, InitiateUploadResult, MediaListRequest, MediaResult, MediaType,
	MediaUploadRequest } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class MediaApi extends ApiV2 implements IMediaApi {
	deleteMedia(uuid: string): Promise<Response> {
		return this.DELETE(`media/${uuid}`) as Promise<Response>;
	}

	getMedia(mediaId: string): Promise<IMedia> {
		return this.GET(`media/${mediaId}`) as Promise<IMedia>;
	}

	getMediaList(
		perPage?: number,
		page?: number,
		favorite?: boolean,
		sort?: API_SORT,
		mediaType?: MediaType
	): Promise<MediaResult> {
		const req: MediaListRequest = {
			page: page || 1,
			"per_page": perPage || 100,
			sort_by: sort || "created_at"
		};

		if (favorite) {
			req.favorite = favorite;
		}

		if (mediaType) {
			req.mediaType = mediaType;
		}

		return this.GET("media", req) as Promise<MediaResult>;
	}

	getAllMedia(perPage?: number, page?: number, sort?: API_SORT): Promise<MediaResult> {
		return this.getMediaList(perPage, page, undefined, sort) as Promise<MediaResult>;
	}

	getBanners(perPage?: number, page?: number): Promise<MediaResult> {
		return this.getMediaList(perPage, page, undefined, undefined, "banner") as Promise<MediaResult>;
	}

	getFavorites(perPage?: number, page?: number): Promise<MediaResult> {
		return this.getMediaList(perPage, page, true) as Promise<MediaResult>;
	}

	initiateMediaUpload(file: File, mediaType?: MediaType): Promise<InitiateUploadResult> {
		const { name } = file;
		const fileName = name.substr(0, 190);
		const request: MediaUploadRequest = { name: fileName };

		if (mediaType) {
			request.mediaType = mediaType;
		}

		return this.POST("media", request) as Promise<InitiateUploadResult>;
	}

	updateMedia(media: IMedia): Promise<Response> {
		return this.PUT(`media/${media.uuid}`, media) as Promise<Response>;
	}
}

import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { Context, CustomCSS, IAd, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import AdsGrid from "Components/Ads/AdsGrid";
import Loader from "Components/Global/Loader";
import { setAdsAsync } from "Data/Actions/AdsAsync";
import { AppState, AsyncState } from "Data/Objects/AppState";
import { getFilteredSortedTaggedAds } from "Data/Selectors/Ads";
import {
	canFetchAds,
	getActiveFilters,
	getActiveSorts,
	getActiveTags,
	getAdsAsyncState,
	makeLastAdsPage
} from "Data/Selectors/UI";

const mapStateToProps = (state: AppState) => {
	const getLastAdsPage = makeLastAdsPage();
	const { filter } = getActiveFilters(state, Filters.ADS);
	const activeTags = getActiveTags(state, "ads");

	return {
		ads: getFilteredSortedTaggedAds(state, {
			sort: getActiveSorts(state, Sorts.ADS) as SortTypes,
			filter: filter as string,
			tags: activeTags
		}),
		asyncState: getAdsAsyncState(state),
		canFetch: canFetchAds(state),
		lastFetchedPage: getLastAdsPage(state)
	}
};

const mapDispatchToProps = (dispatch) => ({
	tryFetchNextAds: () => dispatch(setAdsAsync())
});

interface AdsContentAreaProps {
	ads: IAd[];
	asyncState: AsyncState;
	bulkSelectActive: boolean;
	canFetch: boolean;
	context?: Context;
	lastFetchedPage: number;
	onAdSelected?: (ad: IAd) => void; // this prop is used in the case of a modal ad context
	onCreateNewAd: () => void;
	tryFetchNextAds: () => void;
}

export class AdsContentArea extends React.Component<AdsContentAreaProps> {
	constructor(props: AdsContentAreaProps) {
		super(props);

		this.styles = {
			loader: {
				height: 40,
				margin: "40px auto 0",
				textAlign: "center",
				width: "100%"
			}
		}

		this.fetchNextPageOfAds = this.fetchNextPageOfAds.bind(this);
	}

	styles: {
		loader: CustomCSS;
	};

	componentDidMount() {
		this.props.tryFetchNextAds();
	}

	render() {
		const {
			asyncState,
			canFetch,
			lastFetchedPage
		} = this.props;
		let display = canFetch ? "inline-block" : "none";

		if (asyncState.haveAllData) {
			display = "none";
		}

		return (
			<React.Fragment>
				{ this.renderAdsGrid() }
				<div style={{ ...this.styles.loader, display }}>
					<Waypoint key={ lastFetchedPage } onEnter={ this.fetchNextPageOfAds } />
					<Loader />
				</div>
			</React.Fragment>
		);
	}

	renderAdsGrid() {
		const { ads, bulkSelectActive, context, onAdSelected, onCreateNewAd } = this.props;
		const filteredAds = context !== Context.ANALYTICS ? ads : ads.filter((ad) => !Boolean(ad.analytics));
		return (
			<AdsGrid
				content={ filteredAds }
				context={ context }
				onAdSelected={ onAdSelected }
				onCreateNewAd={ onCreateNewAd }
				selectModeOn={ bulkSelectActive }
			/>
		);
	}

	fetchNextPageOfAds() {
		const { canFetch, tryFetchNextAds } = this.props;

		if (canFetch) {
			tryFetchNextAds();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsContentArea);

import { Alert } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { ActiveUuidRoute, CustomCSS, HealthReport, HealthReportColumn, IDevice } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Button, Icon, Loader } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import DragNDropReportColumn from "Components/Health/DragNDropReportColumn";
import DropColumnPlaceholder from "Components/Health/DropColumnPlaceholder";
import { updateReportAsync } from "Data/Actions/HealthReportAsync";
import { setActiveSelection } from "Data/Actions/UI";
import { getSelectedHealthReport, getSelectedReportResult } from "Data/Selectors/HealthReports";
import { getActiveUuid, getReportIsRunning } from "Data/Selectors/UI";
import { getReportResultsAsync } from "Data/Actions/HealthReportAsync";
import HeaderText from "Components/Global/HeaderText";
import { toggleFeature } from "@connect/Features";
import PresenceUsers from "Components/Global/PresenceUsers";

const { dropBackground, lightGray } = Colors

const mapStateToProps = (state, ownProps) => {
	const activeHealthReport = getActiveUuid(state, "health");
	const activeReport = getSelectedHealthReport(state, activeHealthReport);
	const result = getSelectedReportResult(state, activeHealthReport);
	const lastRunDate = activeReport && activeReport.executedAt ? new Date(activeReport.executedAt) : null;

	return ({
		activeHealthReport: activeReport,
		activeReportResult: result ? result.results : [],
		lastRun: lastRunDate,
		reportLoading: getReportIsRunning(state, activeHealthReport)
	});
};

const mapDispatchToProps = (dispatch) => ({
	setSelectedDevices: (uuids: string[]) => dispatch(setActiveSelection("healthDevices", uuids)),
	setSelectedGroups: (uuids: string[]) => dispatch(setActiveSelection("healthDevices_groups", uuids)),
	updateReport: (report: HealthReport) => dispatch(updateReportAsync(report)),
	getReportResults: (uuid: string) => dispatch(getReportResultsAsync(uuid))
});

interface IDeviceHealthReportProps extends RouteComponentProps<ActiveUuidRoute> {
	activeHealthReport: HealthReport;
	activeReportResult: IDevice[];
	lastRun: Date;
	reportLoading: boolean;
	setSelectedDevices: (uuids: string[]) => void;
	setSelectedGroups: (uuids: string[]) => void;
	sort: HealthReportColumn,
	sortDirection: "asc" | "desc",
	updateReport: (report: HealthReport) => void;
	getReportResults: (uuid: string) => void;
}

export class DeviceHealthReport extends React.Component<IDeviceHealthReportProps> {
	constructor(props: IDeviceHealthReportProps) {
		super(props);

		this.styles = {
			button: {
				width: 120,
				margin: 5
			},
			close: {
				cursor: "pointer",
				margin: 10,
				position: "absolute",
				right: 0,
				top: 0
			},
			statColumn: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
				padding: 20
			},
			content: {
				display: "flex",
				flexDirection: "column",
				height: "100%",
				position: "relative",
				textAlign: "center",
				overflowY: "hidden"
			},
			disclaimer: {
				margin: "0 auto 6px",
				padding: "6px 12px",
				textAlign: "center"
			},
			dragColumn: {
				minWidth: 100,
				flex: "0 0 auto"
			},
			editor: {
				border: "1px solid #f7f7f7",
				borderRadius: "6px 6px 0 0",
				display: "flex",
				flexDirection: "column",
				margin: "10px 15px",
				maxHeight: 700,
				minHeight: 300,
				overflowY: "hidden",
				position: "relative",
				textAlign: "left"
			},
			editorTable: {
				display: "flex",
				flex: 1,
				height: "100%",
				overflow: "scroll",
				width: "100%"
			},
			footer: {
				width: "100%",
				textAlign: "right",
				padding: 10
			},
			instructions: {
				color: lightGray
			},
			loadingChild: {
				flex: "1 1 auto",
				maxWidth: "100%"
			},
			loadingOverlay: {
				alignItems: "center",
				background: dropBackground,
				display: "flex",
				flexDirection: "column",
				height: "100%",
				justifyContent: "center",
				position: "absolute",
				width: "100%",
				zIndex: 100
			},
			loadingText: {
				alignItems: "center",
				display: "flex",
				fontSize: "2em",
				justifyContent: "center"
			},
			presenceWrapper: {
				position: "absolute",
				top: 5,
				left: 10
			},
			spacer: {
				position: "relative",
				top: "-50%",
				fontSize: "1.7em",
				color: lightGray
			},
			stats: {
				width: "100%",
				display: "flex",
				justifyContent: "center",
				textAlign: "left"
			}
		};

		this.closeReport = this.closeReport.bind(this);
		this.renderStat = this.renderStat.bind(this);
		this.renderColumn = this.renderColumn.bind(this);
		this.resetReportWrapper = this.resetReportWrapper.bind(this);
	}

	styles: CustomCSS;

	componentWillMount() {
		const { activeHealthReport: report } = this.props;

		if (report && report.columns && report.columns.length === 0) {
			this.resetReportWrapper();
		}
	}

	componentDidUpdate(prevProps) {
		const { activeHealthReport } = this.props;
		const { activeHealthReport: prevHealthReport } = prevProps;

		if (!activeHealthReport) {
			this.closeReport();
		} else {
			const { uuid: activeUUID } = activeHealthReport;
			const { uuid: prevUUID } = prevHealthReport;

			const reportsChanged = prevUUID !== activeUUID;
			const hasDevices = activeHealthReport.devices && activeHealthReport.devices.length;
			const hasDeviceGroups = activeHealthReport.deviceGroups && activeHealthReport.deviceGroups.length;

			if (reportsChanged && (hasDevices || hasDeviceGroups)) {
				this.props.getReportResults(activeUUID);
			}
		}
	}

	render() {
		if (!this.props.activeHealthReport) {
			return null;
		}

		return (
			<div style={ this.styles.content }>
				{ this.renderClose() }
				{ this.renderPresenceUsers() }
				{ this.renderHeader() }
				{ this.renderStats() }
				{ this.renderInstructions() }
				{ this.renderEditor() }
				{ this.renderFooter() }
			</div>
		);
	}

	renderClose() {
		return (
			<Icon
				style={ this.styles.close }
				size="smaller"
				name="times"
				onClick={ this.closeReport } />
		);
	}

	renderColumn(lockedColumn?: boolean, additionalStyle?: CustomCSS) {
		const { activeHealthReport, sort, sortDirection } = this.props;

		return (column) => {
			const index = (activeHealthReport.columns || []).indexOf(column);

			return (
				<DragNDropReportColumn
					additionalStyle={additionalStyle}
					column={column}
					index={index}
					key={activeHealthReport.uuid + column + index}
					lockedColumn={lockedColumn}
					sort={sort}
					sortDirection={sortDirection}
				/>
			);
		}
	}

	renderEditor() {
		const { activeHealthReport, lastRun } = this.props;
		const columns = activeHealthReport.columns || [];
		const { editor, editorTable, dragColumn } = this.styles;
		const statusColumn = columns.slice(0, 1);
		const nameColumn = columns.slice(1, 2);
		const reportItems = columns.length > 2 ? columns.slice(2) : [];
		const timestampKey = lastRun ? lastRun.toISOString() : "";

		return (
			<div key={timestampKey} style={editor}>
				<div style={editorTable} key="100">
					{statusColumn.map(this.renderColumn(true, dragColumn))}
					{nameColumn.map(this.renderColumn(true))}
					{reportItems.map(this.renderColumn())}
					<DropColumnPlaceholder isFirst={columns.length < 3}/>
				</div>
				{this.renderLoading()}
			</div>
		);
	}

	renderFooter() {
		const { footer, button } = this.styles;
		return (
			<div style={ footer }>
				<Button
					style={ button }
					icon="eraser"
					iconWeight="solid"
					onClick={this.resetReportWrapper}>
					Clear
				</Button>
			</div>
		);
	}

	renderPresenceUsers() {
		const { activeHealthReport: { uuid } } = this.props;
		const { presenceWrapper } = this.styles;

		return toggleFeature("notifications",
			(
				<div style={ presenceWrapper }>
					<PresenceUsers
						type="healthReport"
						uuid={ uuid } />
				</div>
			),
			null
		);
	}

	renderHeader() {
		return (
			<HeaderText truncateLength={ 41 }>
				{ `Report Overview: ${this.props.activeHealthReport.name}` }
			</HeaderText>
		);
	}

	renderInstructions() {
		return (
			<React.Fragment>
				<Alert
					style={ this.styles.disclaimer }
					message=""
					description={ this.renderDisclaimer() }
					type="warning"
				/>
				<p style={ this.styles.instructions }>
					Drag desired component onto report layout.
				</p>
			</React.Fragment>
		);
	}

	renderLoading() {
		if (!this.props.reportLoading) {
			return false;
		}

		const { loadingChild, loadingOverlay, loadingText } = this.styles;

		return (
			<div style={loadingOverlay}>
				<div style={{
					...loadingText,
					...loadingChild
				}}>
					We're running your report in the background.<br />
					This could take a few minutes so we'll send you<br />
					an email to let you know when it's ready.
				</div>
				<div style={loadingChild}>
					<Loader size="large" />
				</div>
			</div>
		);
	}

	renderSpacer() {
		return (
			<div style={ this.styles.spacer }>
				|
			</div>
		);
	}

	renderStat(title: string, index: number, titles: string[]) {
		const { statColumn } = this.styles;
		const { activeHealthReport: { devices, deviceGroups }, activeReportResult, lastRun } = this.props;
		const stores = activeReportResult ? activeReportResult.filter((row) => row["device.store"]).length : 0;
		const runDate = Utils.getHumanReadableDate(String(lastRun));
		const run = runDate.length ? runDate : "N/A";
		const statistics = [
			(devices || []).length,
			(deviceGroups || []).length,
			stores,
			run
		];

		return (
			<div key={ index }>
				<div style={ statColumn }>
					<div>
						<h1>{ statistics[index] }</h1>
					</div>
					<div>
						<h5>{ title }</h5>
					</div>
				</div>
				{ index > 0 && index < titles.length ? this.renderSpacer() : null }
			</div>
		);
	}

	renderStats() {
		const { stats } = this.styles;
		const titles = [
			"Devices in Report",
			"Groups in Report",
			"Stores in Report",
			"Last Report Run"
		];

		return (
			<div style={ stats }>
				{ titles.map(this.renderStat) }
			</div>
		);
	}

	renderDisclaimer() {
		const lastRun = this.props.activeHealthReport.executedAt;
		if (lastRun === null) {
			return (
				<div>
					<strong>To see your device report, please select the Run Report button.</strong>
				</div>
			);
		} else {
			return (
				<div>
					The information that is shown in the report below displays only the last reported status of each item.
					<br /><strong>It’s important to note that this is not real-time data.</strong>
				</div>
			);
		}
	}

	resetReportWrapper() {
		const clearedReport = Object.assign({}, this.props.activeHealthReport, {
			columns: [ "status.timestamp", "device.name" ]
		});
		this.props.updateReport(clearedReport);
	}

	closeReport() {
		const { history, setSelectedDevices, setSelectedGroups } = this.props;

		setSelectedDevices([]);
		setSelectedGroups([]);
		history.push("/health");
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeviceHealthReport));

import * as update from "immutability-helper";

import { UuidDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ModalsState } from "Data/Objects/AppState";
import { createReducer } from "Data/Utils";

export function setDeviceHealthModalUUID(state: ModalsState, action: UuidDispatch) {
	return update(state, {
		activeDeviceHealthModalUUID: {
			$set: action.uuid
		}
	});
}

export function setCompanyCreateModalVisibility(state: ModalsState, action: any) {
	return update(state, {
		companyCreateModalVisible: {
			$set: action.value
		}
	});
}

export function setCompanyRequestModalVisibility(state: ModalsState, action: any) {
	return update(state, {
		companyRequestModalVisible: {
			$set: action.value
		}
	});
}

export function setDeploymentApprovalModalUUID(state: ModalsState, action: any) {
	return update(state, {
		deploymentApprovalModalUUID: {
			$set: action.value
		}
	});
}

export function setDeploymentApprovalModalVisibility(state: ModalsState, action: any) {
	return update(state, {
		deploymentApprovalModalVisible: {
			$set: action.value
		}
	});
}

export function setMediaPreviewModalVisibility(state: ModalsState, action: any) {
	return update(state, {
		mediaPreviewModalVisible: {
			$set: action.value
		}
	});
}

export function setPrivacyPolicyModalVisibility(state: ModalsState, action: any) {
	return update(state, {
		privacyPolicyModalVisible: {
			$set: action.value
		}
	});
}

export function setDeviceGroupTreeBrowserModal(state: ModalsState, action: any) {
	const { visible, selection } = action;

	return update(state, {
		deviceGroupTreeBrowser: {
			visible: { $set: visible },
			selection: { $set: selection }
		}
	});
}

export function setRequestNameModal(state: ModalsState, action: any) {
	return update(state, {
		requestNameModal: {
			visible: { $set: action.visible },
			type: { $set: action.context },
			adTemplate: { $set: action.adTemplate }
		}
	});
}

export function setDeviceErrorLogModal(state: ModalsState, action: any) {
	return update(state, {
		deviceErrorLogModal: {
			visible: { $set: action.visible }
		}
	});
}

export function setCheckinDeviceModal(state: ModalsState, action: any) {
	return update(state, {
		checkinDeviceModal: {
			visible: { $set: action.visible }
		}
	});
}

export function setCreateStoreModal(state: ModalsState, action: any) {
	return update(state, {
		createStoreModal: {
			visible: { $set: action.visible }
		}
	});
}

export function setCreateUserModal(state: ModalsState, action: any) {
	return update(state, {
		createUserModal: {
			visible: { $set: action.visible }
		}
	});
}

export function setSelectMediaModal(state: ModalsState, action: any) {
	return update(state, {
		selectMediaModal: {
			visible: { $set: action.visible },
			selection: { $set: action.selection },
			orientation: { $set: action.orientation },
			context: { $set: action.context }
		}
	});
}

export function setSelectActionsModal(state: ModalsState, action: any) {
	return update(state, {
		selectActionsModal: {
			visible: { $set: action.visible },
			context: { $set: action.context }
		}
	});
}

export function setSelectPlaylistsModal(state: ModalsState, action: any) {
	return update(state, {
		selectPlaylistsModal: {
			visible: { $set: action.visible },
			selection: { $set: action.selection },
			context: { $set: action.context }
		}
	});
}

export function setSelectAdsModal(state: ModalsState, action: any) {
	return update(state, {
		selectAdsModal: {
			visible: { $set: action.visible },
			context: { $set: action.context }
		}
	});
}

export function setTemplatesListModal(state: ModalsState, action: any) {
	return update(state, {
		templatesListModal: {
			visible: { $set: action.visible }
		}
	});
}

export function setTermsConditionsModalVisibility(state: ModalsState, action: any) {
	return update(state, {
		termsConditionsModalVisible: {
			$set: action.value
		}
	});
}

const {
	SET_DEVICE_HEALTH_MODAL_UUID,
	SET_CHECKIN_DEVICE_MODAL,
	SET_COMPANY_CREATE_MODAL_VISIBILITY,
	SET_COMPANY_REQUEST_MODAL_VISIBILITY,
	SET_CREATE_STORE_MODAL,
	SET_CREATE_USER_MODAL,
	SET_DEPLOYMENT_APPROVAL_MODAL_UUID,
	SET_DEPLOYMENT_APPROVAL_MODAL_VISIBILITY,
	SET_DEVICE_ERROR_LOG_MODAL,
	SET_DEVICE_GROUP_TREE_BROWSER_MODAL,
	SET_MEDIA_PREVIEW_MODAL_VISIBILITY,
	SET_REQUEST_NAME_MODAL,
	SET_SELECT_ACTIONS_MODAL,
	SET_SELECT_ADS_MODAL,
	SET_SELECT_MEDIA_MODAL,
	SET_SELECT_PLAYLISTS_MODAL,
	SET_TEMPLATES_LIST_MODAL,
	SET_TERMS_CONDITIONS_MODAL_VISIBILITY,
	SET_PRIVACY_POLICY_MODAL_VISIBILITY
} = ACTION_TYPES.UI.Modals;

const reducers = {
	[SET_CHECKIN_DEVICE_MODAL.type]: setCheckinDeviceModal,
	[SET_CREATE_STORE_MODAL.type]: setCreateStoreModal,
	[SET_CREATE_USER_MODAL.type]: setCreateUserModal,
	[SET_DEVICE_ERROR_LOG_MODAL.type]: setDeviceErrorLogModal,
	[SET_DEVICE_HEALTH_MODAL_UUID.type]: setDeviceHealthModalUUID,
	[SET_COMPANY_CREATE_MODAL_VISIBILITY.type]: setCompanyCreateModalVisibility,
	[SET_COMPANY_REQUEST_MODAL_VISIBILITY.type]: setCompanyRequestModalVisibility,
	[SET_DEPLOYMENT_APPROVAL_MODAL_UUID.type]: setDeploymentApprovalModalUUID,
	[SET_DEPLOYMENT_APPROVAL_MODAL_VISIBILITY.type]: setDeploymentApprovalModalVisibility,
	[SET_DEVICE_GROUP_TREE_BROWSER_MODAL.type]: setDeviceGroupTreeBrowserModal,
	[SET_MEDIA_PREVIEW_MODAL_VISIBILITY.type]: setMediaPreviewModalVisibility,
	[SET_TERMS_CONDITIONS_MODAL_VISIBILITY.type]: setTermsConditionsModalVisibility,
	[SET_PRIVACY_POLICY_MODAL_VISIBILITY.type]: setPrivacyPolicyModalVisibility,
	[SET_REQUEST_NAME_MODAL.type]: setRequestNameModal,
	[SET_SELECT_ACTIONS_MODAL.type]: setSelectActionsModal,
	[SET_SELECT_ADS_MODAL.type]: setSelectAdsModal,
	[SET_TEMPLATES_LIST_MODAL.type]: setTemplatesListModal,
	[SET_SELECT_MEDIA_MODAL.type]: setSelectMediaModal,
	[SET_SELECT_PLAYLISTS_MODAL.type]: setSelectPlaylistsModal
};

export default createReducer(reducers, ModalsState);
import { createSelector } from "reselect";

import { DeploymentWizardState } from "Data/Objects/AppState";
import { getDeploymentWizardState } from "Data/Selectors/UI";

export function getDeployment(state: DeploymentWizardState) {
	return state.activeDeployment;
}

export function getScheduleDates(state: DeploymentWizardState) {
	return state.scheduleDates;
}

export function getScheduled(state: DeploymentWizardState) {
	return state.scheduled;
}

export function getStep(state: DeploymentWizardState) {
	return state.step;
}

export const getActiveDeployment = createSelector(
	[ getDeploymentWizardState ],
	getDeployment
);

export const getDeploymentScheduleDates = createSelector(
	[ getDeploymentWizardState ],
	getScheduleDates
);

export const getDeploymentScheduled = createSelector(
	[ getDeploymentWizardState ],
	getScheduled
);

export const getDeploymentStep = createSelector(
	[ getDeploymentWizardState ],
	getStep
);
import * as React from "react";
import { DragSource, DragSourceSpec } from "react-dnd";

import { CustomCSS } from "@connect/Interfaces";
import { Card, Icon, Link } from "Components/Global/Common";
import { WIDGET_COLORS } from "Components/Global/Constants";
import FutureDeploymentsContainer from "Components/Home/Widgets/FutureDeploymentsWidget/FutureDeploymentsContainer";
import { WIDGET_TYPES } from "Data/Objects/Widgets";

const { name } = WIDGET_TYPES.FUTURE_DEPLOYMENTS;

const styles = {
	cardWrapper: {
		position: "relative",
		zIndex: 15
	},
	dragging: {
		opacity: 0.4,
		zIndex: 25
	},
	header: {
		paddingLeft: 8
	},
	link: {
		background: "#FFF",
		bottom: 0,
		left: 0,
		marginTop: 10,
		padding: "3px 0",
		position: "absolute",
		textAlign: "center",
		width: "100%"
	}
} as CustomCSS;

const cardSource: DragSourceSpec<IFutureDeploymentsWidgetProps, IFutureDeploymentsWidgetDragObject> = {
	beginDrag(props: IFutureDeploymentsWidgetProps) {
		return {
			icon: "calendar",
			type: name
		};
	}
};

interface IFutureDeploymentsWidgetDragObject {
	icon: string;
	type: string;
}

interface IFutureDeploymentsWidgetProps {
	connectDragPreview?: Function;
	connectDragSource?: Function;
	isDragging?: boolean;
	onDelete: () => void;
}

class FutureDeploymentsWidget extends React.Component<IFutureDeploymentsWidgetProps> {
	constructor(props: IFutureDeploymentsWidgetProps) {
		super(props);
	}

	render() {
		const { connectDragSource, isDragging, onDelete } = this.props;
		const style = {
			...styles.cardWrapper,
			...(isDragging ? styles.dragging : null)
		};
		const content = (
			<div style={style}>
				<Card
					onClose={ onDelete }
					header={ this.renderHeader() }
					height={ 382 }
					color={ WIDGET_COLORS.deployment }>
					<FutureDeploymentsContainer />
					<div style={ styles.link }>
						<Link to={"/deploy"}>View All</Link>
					</div>
				</Card>
			</div>
		);

		if (connectDragSource) {
			return connectDragSource(content);
		}

		return content;
	}

	renderHeader() {
		const { connectDragPreview } = this.props;
		const preview = (
			<h2 style={ styles.header }>
				<Icon name="calendar" iconWeight="regular" /> { name }
			</h2>
		);

		if (connectDragPreview) {
			return connectDragPreview(preview, {
				captureDraggingState: true
			});
		}

		return preview;
	}
}

export default DragSource("Widget", cardSource, (connect, monitor) => ({
	connectDragPreview: connect.dragPreview(),
	connectDragSource: connect.dragSource(),
	isDragging: monitor.isDragging()
}))(FutureDeploymentsWidget);
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

import { toggleFeature } from "@connect/Features";
import { CustomCSS, ICompany, IUser } from "@connect/Interfaces";
import AccountPage from "Components/Admin/AccountPage";
import AdminActivityPage from "Components/Admin/AdminActivityPage";
import AdminDevicesPage from "Components/Admin/AdminDevicesPage";
import AdminNavButtons from "Components/Admin/AdminNavButtons";
import AdminStoresPage from "Components/Admin/AdminStoresPage";
import AdminTutorialsPage from "Components/Admin/AdminTutorialsPage";
import AdminUsersPage from "Components/Admin/AdminUsersPage";
import AdminVersionsPage from "Components/Admin/AdminVersionsPage";
import EmailPreferencesPage from "Components/Admin/EmailPreferencesPage";
import IntegrationCompaniesPage from "Components/Admin/IntegrationCompaniesPage";
import ManagedCompaniesPage from "Components/Admin/ManagedCompaniesPage";
import ResourcesPage from "Components/Admin/ResourcesPage";
import SecurityPage from "Components/Admin/SecurityPage";
import UserPermissionsPage from "Components/Admin/UserPermissionsPage";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import StatusBar from "Components/Global/StatusBar";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import UserPermissionRoute from "Components/Global/UserPermissionRoute";
import { PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveCompany } from "Data/Selectors/Company";
import { getCurrentUser } from "Data/Selectors/User";
import PasswordPoliciesPage from "Components/Admin/PasswordPoliciesPage";

type Page = "" | "account" | "companies" | "users" | "devices" | "activity" | "versions" | "video";

interface IAdminPageProps extends RouteComponentProps<null> {
	user: IUser;
	userCompany: ICompany;
}

interface IAdminPageState {
	page: Page;
}

const mapStateToProps = (state) => {
	const user = getCurrentUser(state);

	return {
		userCompany: getActiveCompany(state),
		user
	};
};

class AdminPage extends React.Component<IAdminPageProps, IAdminPageState> {
	constructor(props: IAdminPageProps) {
		super(props);

		this.state = {
			page: "account"
		};

		this.styles = {
			wrapper: {
				width: "100%",
				height: "100vh"
			}
		};

		this.handleNavigate = this.handleNavigate.bind(this);
	}

	styles: CustomCSS;

	componentDidUpdate() {
		let page = this.props.location.pathname.split("/").pop();

		if (page !== "admin" && page !== this.state.page) {
			this.setState((prevState) => {
				return update(prevState, {
					page: { $set: page }
				});
			});
		}
	}

	render() {
		return (
			<div>
				<ThreeColumnLayout
					leftContent={this.renderLeftContent()}
					centerContent={this.renderCenterContent()}
				/>
			</div>
		);
	}

	renderLeftContent() {
		const { user } = this.props;

		return (
			<Accordion
				key={ `${ this.props.userCompany.uuid }_${ this.state.page }` }
				elements={[
					new AccordionElement("Admin", (
						<AdminNavButtons
							handleClick={ this.handleNavigate }
							isIntegrator={ this.isIntegrator(this.props) }
							isUserOrMarketer={ this.isUserOrMarketer(this.props) }
							isSAMLUser={ !!user.singleSignOn }
							pageState={ this.state.page }
						/>
					))
				]}
			/>
		);
	}

	handleNavigate(e: React.SyntheticEvent<HTMLDivElement>) {
		let { page } = e.currentTarget.dataset;
		this.props.history.push("/admin/" + page);

		this.setState((prevState) => {
			return update(prevState, {
				page: { $set: page }
			});
		});
	}

	renderCenterContent() {
		const isIntegrator = this.isIntegrator(this.props);
		const isUserOrMarketer = this.isUserOrMarketer(this.props);

		return (
			<div style={this.styles.wrapper}>
				<StatusBar />
				<Switch>
					<UserPermissionRoute
						component={AccountPage}
						path="/admin/account"
						permissions={[ PERMISSIONS.SELF_EDIT ]}
					/>
					<UserPermissionRoute
						component={AdminUsersPage}
						path="/admin/users"
						permissions={[ PERMISSIONS.USERS_USER_EDIT ]}
					/>
					<UserPermissionRoute
						component={IntegrationCompaniesPage}
						path="/admin/integration-companies"
						permissions={[ PERMISSIONS.VIEW_COMPANY_INTEGRATIONS ]}
					/>
					{
						isIntegrator ? (<UserPermissionRoute
							component={ManagedCompaniesPage}
							path="/admin/managed-companies"
						/>) : null
					}
					<UserPermissionRoute
						component={AdminDevicesPage}
						path="/admin/devices"
						permissions={[ PERMISSIONS.DEVICES_MANAGE ]}
					/>
					<UserPermissionRoute
						component={AdminActivityPage}
						path="/admin/activity"
						permissions={[ PERMISSIONS.OWN_COMPANIES_EDIT ]}
					/>
					<UserPermissionRoute
						component={AdminVersionsPage}
						path="/admin/versions"
						permissions={[ PERMISSIONS.DEVICES_CHECK_IN ]}
					/>
					<UserPermissionRoute
						component={SecurityPage}
						path="/admin/security"
					/>
					<UserPermissionRoute
						component={PasswordPoliciesPage}
						path="/admin/passwordpolicies"
						permissions={[ PERMISSIONS.OWN_COMPANIES_EDIT ]}
					/>
					{
						isUserOrMarketer ? null : (<UserPermissionRoute
							component={toggleFeature("customUserPermissions", UserPermissionsPage, null)}
							path="/admin/permissions"
						/>)
					}
					<UserPermissionRoute
						component={AdminTutorialsPage}
						path="/admin/video"
					/>
					<UserPermissionRoute
						component={ ResourcesPage }
						path="/admin/resources"
					/>
					<UserPermissionRoute
						component={ AdminStoresPage }
						permissions={ [ PERMISSIONS.OWN_COMPANIES_EDIT ] }
						path="/admin/stores"
					/>
					<Redirect
						exact
						from="/admin"
						to="/admin/account"
					/>
					<UserPermissionRoute
						component={EmailPreferencesPage}
						path="/admin/emailPreferences"
					/>
				</Switch>
			</div>
		);
	}

	isIntegrator(props: IAdminPageProps) {
		const { user, userCompany } = this.props;
		const teamType = userCompany && userCompany.teamType;

		return teamType === "integrator" || user.role.name.startsWith("cec-");
	}

	isUserOrMarketer(props: IAdminPageProps) {
		const { user } = this.props;

		return user.role.name === "user" || user.role.name === "marketer";
	}

}

export default connect(mapStateToProps)(AdminPage);

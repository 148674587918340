import { Progress, Tooltip } from "antd";
import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IDevice, DeviceCapabilities } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Truncate, HelpPopover } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import Status from "Components/Global/Status";
import { AppState } from "Data/Objects/AppState";
import { getDeviceHealthModalDevice, getDeviceHealthModalUUID } from "Data/Selectors/UI";
import { getDeviceHealthModalDeviceState } from "Data/Selectors/UI";
import { checkDeviceCapabilities } from "Data/Objects/Devices";
import NetworkWarning  from "Components/Devices/NetworkWarning";
import { toggleFeature } from "@connect/Features";

const { lightGray, lightestGray, black, primaryGreen, orange, red } = Colors;
const { getHumanReadableBytesize, getHumanReadableDate, getHumanReadableDuration } = Utils;

const mapStateToProps = (state: AppState, ownProps: DeviceStatusPanel) => {
	const device = getDeviceHealthModalDevice(state);
	const selectedUUID = getDeviceHealthModalUUID(state);
	const { lastSeen } = getDeviceHealthModalDeviceState(state, selectedUUID);

	return {
		device,
		lastSeen,
		selectedUUID
	};
};

interface DeviceStatusRow {
	label: string;
	labelIcon?: JSX.Element;
	value: string | number | JSX.Element;
}

interface DeviceStatusCard {
	title: string;
	rows: DeviceStatusRow[];
}

interface DeviceStatusPanelProps {
	selectedUUID: string;
	device: IDevice;
	lastSeen: string;
}

interface DeviceStatusPanelState {
	cards: DeviceStatusCard[];
}

export class DeviceStatusPanel extends React.Component<DeviceStatusPanelProps, DeviceStatusPanelState> {
	constructor(props: DeviceStatusPanelProps) {
		super(props);

		this.styles = {
			grid: {
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				gridGap: 16,
				margin: 8
			},
			title: {
				color: black,
				fontSize: "1.2em",
				marginBottom: 10
			},
			row: {
				borderBottom: `1px solid ${ lightestGray }`,
				margin: "0px 16px",
				padding: "14px 6px",
				display: "flex",
				position: "relative"
			},
			label: {
				width: "50%",
				color: lightGray
			},
			value: {
				width: "50%",
				color: black
			},
			card: {
				boxShadow: "0 4px 8px 0 #F3F3F3, 0 6px 20px 0 #F9F9F9",
				padding: "16px 16px 64px 16px"
			},
			progress: {
				position: "absolute",
				width: 294,
				top: 31,
				right: -29
			},
			statusList: {
				marginTop: 16,
				display: "flex",
				flexDirection: "column"
			},
			noStatus: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: 429
			}
		};

		this.state = {
			cards: []
		}

		this.renderCard = this.renderCard.bind(this);
		this.renderPopoverContent = this.renderPopoverContent.bind(this);
		this.renderRow = this.renderRow.bind(this);
		this.setCardData = this.setCardData.bind(this);
		this.renderUptime = this.renderUptime.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		if (this.props.device.status) {
			this.setCardData();
		}
	}

	componentDidUpdate(prevProps: DeviceStatusPanelProps) {
		const prevHeartbeat = prevProps.device.status && prevProps.device.status.heartbeat;
		const currentHeartbeat = this.props.device.status && this.props.device.status.heartbeat;

		if (prevHeartbeat !== currentHeartbeat) {
			this.setCardData();
		}
	}

	setCardData() {
		const { status } = this.props.device;

		if (!status) {
			return;
		}

		const {
			wifiNetworkName,
			ip,
			heartbeat,
			playlist,
			deployment,
			lastScheduleDownload,
			lastDeploymentFailed,
			displayOn,
			pusherConnected,
			availableStorage,
			totalStorage,
			availableMemoryUsage,
			memoryTotalSize,
			heapMemoryUsage,
			heapTotalSize,
			timestamp,
			uptime
		} = status;

		const storageUsed = totalStorage - availableStorage;

		const cards = [
			{
				title: "Network Status",
				rows: [
					{
						label: "Connection Type",
						value: this.getConnectionType()
					},
					{
						label: "Network Name",
						value: wifiNetworkName
					},
					{
						label: "IP Address",
						value: ip
					},
					{
						label: "Last Seen Online",
						value: getHumanReadableDate(String(heartbeat))
					}
				]
			},
			{
				title: "Deployment",
				rows: [
					{
						label: "Playlist",
						value: <Truncate length={ 16 }>{ playlist ?? "(None)" }</Truncate>
					},
					{
						label: "Deployment",
						value: <Truncate length={ 16 }>{ deployment ?? "(None)" }</Truncate>
					},
					{
						label: "Last Synced",
						value: getHumanReadableDate(String(lastScheduleDownload))
					},
					{
						label: "Deployment Status",
						value: lastDeploymentFailed ?
							this.renderLED("Fail", "red") :
							this.renderLED("Success", "green")
					}
				]
			},
			{
				title: "Device Status",
				rows: [
					{
						label: "Last Updated",
						value: getHumanReadableDate(timestamp)
					},
					{
						label: "LED Status",
						labelIcon: this.getLEDIndicatorStatusLabel(),
						value: this.renderLEDStatusSection()
					},
					{
						label: "Camera Status",
						value: this.renderCameraStatus()
					},
					{
						label: "Display Status",
						value: displayOn ?
							this.renderLED("On", "green") :
							this.renderLED("Off", "red")
					},
					{
						label: "Pusher Status",
						value: pusherConnected ?
							this.renderLED("Connected", "green") :
							this.renderLED("Disconnected", "red")
					}
				]
			},
			{
				title: "Performance",
				rows: [
					{
						label: "HDD Usage",
						value: this.renderProgress(
							(storageUsed / totalStorage) * 100,
							`${ getHumanReadableBytesize(storageUsed) } /
							${ getHumanReadableBytesize(totalStorage) }`)
					},
					{
						label: "Memory Usage",
						value: this.renderProgress(
							(availableMemoryUsage / memoryTotalSize) * 100,
							`${ availableMemoryUsage }MB / ${ memoryTotalSize }MB`
						)
					},
					{
						label: "Heap Usage",
						value: this.renderProgress(
							(heapMemoryUsage / heapTotalSize) * 100,
							`${ heapMemoryUsage }MB / ${ heapTotalSize }MB`
						)
					},
					{
						label: "CPU Temperature",
						value: this.renderTemperature()
					}
				]
			}
		];

		if (toggleFeature("lastReboot", true, false)) {
			cards[3].rows.push({
				label: "Last Reboot",
				value: this.renderUptime(uptime)
			})
		}

		this.setState(() => ({ cards }));
	}

	render() {
		const { device } = this.props;
		const { cards } = this.state;
		const { noStatus, grid } = this.styles;

		if (!device.status && !(cards && cards.length)) {
			return (
				<div style={ noStatus }>
					This device does not appear to have valid status information,
					or may not be a fully functional device.
				</div>
			);
		}

		const renderedCards = cards && cards.map(this.renderCard);

		return (
			<React.Fragment>
				<NetworkWarning />
				<div key={device.status && device.status.heartbeat} style={ grid }>{ renderedCards }</div>
			</React.Fragment>
		);
	}

	renderCameraStatus() {
		const { status, model } = this.props.device;

		if (!checkDeviceCapabilities(model, DeviceCapabilities.CAMERA)) {
			return "No Camera";
		} else if (status && status.cameraOn) {
			return this.renderLED("On", "green");
		} else {
			return this.renderLED("Off", "red");
		}
	}

	renderTemperature() {
		const { device } = this.props;
		const { cpuTemp } = device.status || { cpuTemp: 0 };
		const tempString = Utils.formatTemperature(cpuTemp);

		return (
			<React.Fragment>
				{ this.renderLED("", this.getColor(cpuTemp, 100, 125))}
				{ tempString }
			</React.Fragment>
		);
	}

	renderProgress(percent: number, text?: string) {
		return (
			<React.Fragment>
				{ text }
				<div style={ this.styles.progress }>
					<Progress
						percent={ percent }
						className="square-progress"
						strokeWidth={ 11 }
						strokeColor={ this.getColor(percent, 50, 75, true) }
					/>
				</div>
			</React.Fragment>
		);
	}

	renderLED(text: string, color: string = "red") {
		return <Status text={ text } color={ color }/>;
	}

	renderCard(card: DeviceStatusCard) {
		const { title, rows } = card;
		const { device } = this.props;
		const { heartbeat } = device.status || { heartbeat: "" };

		return (
			<div key={ title + "-" + heartbeat } style={ this.styles.card }>
				{ this.renderTitle(title) }
				{ rows.map(this.renderRow) }
			</div>
		);
	}

	renderTitle(title: string) {
		return <span style={ this.styles.title }>{ title }</span>;
	}

	renderRow(statusRow: DeviceStatusRow) {
		const { label, labelIcon, value } = statusRow;
		const { row, label: labelStyle, value: valueStyle } = this.styles;

		return (
			<div key={ label } style={ row }>
				<div style={ labelStyle }>
					{ label }{ labelIcon }:
				</div>
				<div style={ valueStyle }>
					{ value }
				</div>
			</div>
		);
	}

	renderLEDStatusSection() {
		const { device } = this.props;
		const { ledStatus } = device.status || { ledStatus: undefined };

		const color = ledStatus || null;

		const getText = () => {
			switch (color) {
				case "green":
					return "Normal Operation";
				case "purple":
					return "No Network";
				case "blue":
					return "Schedule Error";
				case "red":
					return "Camera Error";
				case "yellow":
					return "Sleep Mode";
				default:
					return "N/A";
			}
		}

		return (
			<React.Fragment>
				{ this.renderLED("", ledStatus ) }
				{ getText() }
			</React.Fragment>
		);
	}

	renderPopoverContent() {
		const { statusList } = this.styles;

		return (
			<div>
				The LED status indicator is used to alert you to any issues
				that may be present on your device.
				<div style={ statusList }>
					{ this.renderLED("Green - Normal", "green") }
					{ this.renderLED("Purple - No Network", "purple") }
					{ this.renderLED("Blue - Schedule Error", "blue") }
					{ this.renderLED("Yellow - Sleep Mode", "yellow") }
					{ this.renderLED("Red - Camera Error", "red") }
				</div>
			</div>
		);
	}

	getLEDIndicatorStatusLabel() {
		return (
			<HelpPopover title="LED Status">
				{ this.renderPopoverContent() }
			</HelpPopover>
		);
	}

	getConnectionType() {
		const { device } = this.props;
		const { wifiConnected, ethernetConnected, heartbeat } = device.status || {
			wifiConnected: false,
			ethernetConnected: false,
			heartbeat: ""
		};
		let type = "N/A";

		if (wifiConnected) {
			type = "WiFi";
		}

		if (ethernetConnected) {
			type = "Ethernet";
		}

		if (!this.isDeviceOnline(heartbeat)) {
			type = "Offline";
		}

		return type;
	}

	getColor(currentCelsius: number, medium: number, high: number, realColor?: boolean) {
		if (currentCelsius <= medium) {
			return realColor ? primaryGreen : "green";
		} else if (currentCelsius > medium && currentCelsius <= high) {
			return realColor ? orange : "orange";
		}

		return realColor ? red : "red";
	}

	isDeviceOnline(heartbeat: string) {
		const secondsOffline = moment()
			.utc()
			.diff(heartbeat, "seconds");

		if (secondsOffline < (60 * 30)) {
			return true;
		}

		return false;
	}

	renderUptime(uptime: number) {
		const rebootTime = new Date(Date.now().valueOf() - (uptime * 1000)).toString();
		return (
			<Tooltip title={ rebootTime }>
				{ getHumanReadableDuration(uptime) }
			</Tooltip>
		)
	}
}

export default connect(mapStateToProps)(DeviceStatusPanel);
import { ErrorResultV2, ICompany, IUser, IUserApi, PaginatedDataResult, UserCompanySort,
	UserTokenResult } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class UserApi extends ApiV2 implements IUserApi {
	changePassword(current: string, password: string, confirmation: string) {
		return this.PUT("user/security", {
			current,
			password,
			confirmation
		}, true) as Promise<UserTokenResult>;
	}

	getUserCompanies(perPage: number, page: number, sort?: UserCompanySort) {
		return this.GET("user/companies", {
			page,
			perPage,
			sort
		}, true) as Promise<PaginatedDataResult<ICompany[]>>;
	}

	getUserDetails() {
		return this.GET("user", undefined, true) as Promise<IUser>;
	}

	sendVerificationEmail(user: string) {
		return this.POST("user/send-verification-email", { user }, true) as Promise<ErrorResultV2>;
	}

	updateUser({ email, name, phone, settings, acceptedTerms }: Partial<IUser>) {
		return this.PUT("user", { email, name, phone, settings, acceptedTerms }, true) as Promise<ErrorResultV2>;
	}

	verifyUserEmail(token: string) {
		return this.POST("user/verify-email", { token }, true) as Promise<ErrorResultV2>;
	}

	verifyPassword(password: string) {
		return this.POST("user/security/sudo", { password }, true) as Promise<ErrorResultV2>;
	}
}

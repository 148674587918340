import * as React from "react";

import { StringArrayObject } from "@connect/Interfaces";

export interface SingleError {
	error?: string;
	message?: string;
}

export interface NestedError {
	errors?: StringArrayObject[];
	message?: string;
}

interface IErrorDescriptionProps {
	error: SingleError | NestedError;
}

class ErrorDescription extends React.PureComponent<IErrorDescriptionProps> {
	render() {
		const { error } = this.props;
		const errorKeys = Object.keys(error);
		const isSingleError = error && errorKeys.includes("error") && errorKeys.includes("message");
		const isNestedError = error && (error as NestedError).errors;
		let errorList;

		if (isSingleError) {
			errorList = [ error.message ];
		} else if (isNestedError) {
			const { errors } = error as NestedError;
			const errorTypes = errors ? Object.keys(errors) : [];

			errorList = errorTypes.map((err) => (
				errors && Array.isArray(errors[err])
					? errors[err].map((msg) => msg)
					: errors && errors[err]
			));
		} else {
			return null;
		}

		return (
			<div>
				{ errorList.map(this.renderError) }
			</div>
		);
	}

	renderError(msg: string, i: number, list: string[]) {
		return (
			<div key={ i }>
				{ list.length ? " • " : null }
				{ msg }
			</div>
		);
	}
}

export default ErrorDescription;
import { Collapse } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { AccordionElement } from "Components/Global/Accordion";
import { setCollapseExpandedState } from "Data/Actions/UI";
import { getActiveNavigationItem, getCollapseExpandedState } from "Data/Selectors/UI";

interface ICollapseProps {
	activeNavigationItem: string;
	customPanelStyle: CustomCSS;
	elements: AccordionElement[];
	expanded: string[];
	header?: JSX.Element | string;
	setExpanded: (key: string, expanded: string[]) => void;
}

const { Panel } = Collapse;

const mapStateToProps = (state, ownProps) => {
	const leftAccordion = getCollapseExpandedState(state);
	// provide a default "open" state if we don't have any collapse state for this page
	const expanded = leftAccordion === null ? ownProps.elements.map((e, i) => i.toString()) : leftAccordion;

	return {
		activeNavigationItem: getActiveNavigationItem(state),
		expanded
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	setExpanded: (key: string, expanded: string[]) => dispatch(setCollapseExpandedState(key, expanded))
});

export class UnconnectedCollapse extends React.PureComponent<ICollapseProps> {
	constructor(props: ICollapseProps) {
		super(props);

		this.styles = {
			container: {
				display: "flex",
				flexDirection: "column",
				paddingBottom: 35,
				height: "100%",
				overflow: "hidden"
			},
			headerStyle: {
				display: this.props.header ? "block" : "none",
				flex: "0 0 auto",
				marginLeft: 20,
				textAlign: "left",
				fontWeight: 700
			},
			collapse: {
				display: "flex",
				flex: "0 0 auto",
				height: "100%",
				flexDirection: "column",
				padding: "0 12px",
				border: 0,
				background: "transparent"
			},
			collapsedPanel: {
				bottom: 0,
				height: 30
			},
			panelContent: {
				marginBottom: 35,
				overflowY: "auto",
				padding: 0,
				height: "100%"
			}
		}

		this.isExpanded = this.isExpanded.bind(this);
		this.toggleExpanded = this.toggleExpanded.bind(this);
	}

	styles: {
		container: CustomCSS;
		headerStyle: CustomCSS;
		collapse: CustomCSS;
		collapsedPanel: CustomCSS;
		panelContent: CustomCSS;
	}

	render() {
		const { elements, expanded, header } = this.props;
		const { container, headerStyle, collapse } = this.styles;
		return (
			<div style={ container }>
				<div style={ headerStyle }>
					{ header }
				</div>
				<Collapse
					className="collapse"
					defaultActiveKey={ expanded }
					style={ collapse }
					onChange={ this.toggleExpanded }>
					{ this.renderPanels(elements) }
				</Collapse>
			</div>
		);
	}

	renderPanels(panels: AccordionElement[]) {
		const { panelContent } = this.styles;
		return panels.map((panel, index) => {
			return (
				<Panel
					className="collapse"
					style={ this.getPanelStyle(panel, index) }
					header={
						<div onClick={(e) => e.stopPropagation()}>
							{ panel.header }
						</div>
					}
					key={ index.toString() }>
					<div style={{ ...panelContent, ...this.props.customPanelStyle }}>
						{ panel.content }
					</div>
				</Panel>
			);
		})
	}

	getPanelStyle(panel: AccordionElement, index: number) {
		const allOthersCollapsed = this.props.expanded.length === 1 && Number(this.props.expanded[0]) === index;

		if (this.isExpanded(index)) {
			return {
				height: "100%",
				maxHeight: allOthersCollapsed ? "100%" : panel.maxHeight,
				display: "flex",
				flexDirection: "column"
			} as CustomCSS;
		}
		return this.styles.collapsedPanel;
	}

	canGrow(panel: AccordionElement, index: number) {
		return !!panel.canGrow || !(Number(index) === 0 && this.props.expanded.length > 1)
	}

	isExpanded(index: number) {
		const i = index.toString();
		return this.props.expanded.indexOf(i) > -1;
	}

	toggleExpanded(keys: string[]) {
		const { activeNavigationItem, setExpanded } = this.props;

		setExpanded(activeNavigationItem, keys);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedCollapse);

import Radium from "radium";
import * as React from "react";
import { ConnectDragPreview } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { CustomCSS, DeviceCapabilities, IDevice, ViewTypes } from "@connect/Interfaces";
import DeviceImage from "Components/Devices/DeviceImage";
import { Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import {
	DnDGridViewCard, GridViewCard, GridViewDndComponent, IGridViewCardProps
} from "Components/Global/GridViewCard";
import { checkDeviceCapabilities } from "Data/Objects/Devices";

interface DeviceCardProps extends IGridViewCardProps<IDevice> {
	bulkSelectActive?: boolean;
	cardObject: IDevice;
	connectDragPreview?: ConnectDragPreview;
	selectedView?: ViewTypes;
}

const { black, lightGray, evenMoreLightGray } = Colors;
const width = 200;

@Radium
class DeviceCard extends GridViewCard<DeviceCardProps> {
	constructor(props: DeviceCardProps) {
		super(props);

		this.styles = Object.assign(this.styles, {
			base: {
				userSelect: "none",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: 2,
				transition: "all 500ms ease",
				":hover": {
					cursor: "pointer",
					boxShadow: "#f1f1f1"
				},
				height: width,
				width
			},
			name: {
				fontSize: 12,
				textShadow: "1px 0 " + black,
				letterSpacing: 1
			},
			model: {
				marginTop: -2
			},
			deviceImage: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				marginBottom: 6,
				height: 132
			},
			noPreview: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: 101.25,
				width: 180,
				alignSelf: "flex-end",
				color: lightGray,
				background: evenMoreLightGray
			}
		});

		this.deviceHeight = width * .66;
	}

	deviceHeight: number;

	styles: {
		base: CustomCSS;
		name: CustomCSS;
		model: CustomCSS;
		deviceImage: CustomCSS;
		noPreview: CustomCSS;
	}

	componentDidMount() {
		const { connectDragPreview } = this.props;

		if (connectDragPreview) {
			connectDragPreview(getEmptyImage(), {
				captureDraggingState: true
			});
		}

		this.scrollIntoView();
	}

	render() {
		return this.renderContainer(
			<div
				key={ this.props.cardObject.uuid }
				style={ this.getCardStyle() }>
				{ this.renderDeviceImage() }
				{ this.renderDevicePreview() }
				{ this.renderName() }
				{ this.renderModel() }
			</div>
		);
	}

	getCardStyle() {
		return {
			...this.styles.base,
			opacity: this.props.isDragging ? 0.5 : 1,
			background: this.getBackgroundColor()
		}
	}

	renderDeviceImage() {
		const { selectedView, cardObject } = this.props;
		const { model } = cardObject;

		if (selectedView === ViewTypes.SNAPSHOT) {
			return null;
		}

		return (
			<div style={ this.styles.deviceImage }>
				<DeviceImage
					model={ model }
					height={ this.deviceHeight }
				/>
			</div>
		);
	}

	renderDevicePreview() {
		const { selectedView, cardObject } = this.props;
		const { deviceImage, noPreview } = this.styles;
		const { snapshotThumbnail } = cardObject;

		if (selectedView === ViewTypes.GRID) {
			return null;
		}

		if (!snapshotThumbnail) {
			return (
				<div style={ deviceImage }>
					<div style={ noPreview }>
						No preview available
					</div>
				</div>
			);
		}

		let style: React.CSSProperties = { height: 101.25, width: 180, alignSelf: "flex-end" };

		if (checkDeviceCapabilities(cardObject.model, DeviceCapabilities.CAMERA_PORTRAIT)) {
			style = { width: 101.25, height: 180, alignSelf: "flex-end", maxHeight: "100%" }
		}

		return (
			<div style={ deviceImage }>
				<img src={ snapshotThumbnail } style={ style } />
			</div>
		);
	}

	renderName() {
		return (
			<div style={ this.styles.name }>
				<Truncate length={ 18 }>{ this.props.cardObject.name }</Truncate>
			</div>
		);
	}

	renderModel() {
		return (
			<div style={ this.styles.model }>
				{ this.props.cardObject.model }
			</div>
		);
	}

	getBackgroundColor() {
		const { isSelected, bulkSelectActive } = this.props;

		if (bulkSelectActive && isSelected) {
			return "#d7e6fc";
		}

		if (isSelected) {
			return "#f1f1f1";
		}

		return "#fff";
	}
}

export const DraggableDeviceCard: GridViewDndComponent<DeviceCardProps> =
	DnDGridViewCard.GridViewCardDraggable(DeviceCard);

export default DraggableDeviceCard;
import { getState } from "@connect/Data";
import { envSwitch } from "@connect/Env";
import { HeaderObject, IApiV2 } from "@connect/Interfaces";
import CRUD from "Api/CRUD";
import { getActiveCompany } from "Data/Selectors/Company";

export const envHost = envSwitch<string>(
	"https://api.clintonconnect.com",
	"https://staging.api.clintonconnect.com",
	"https://development.api.clintonconnect.com",
	"http://connect-api.test"
);

export default class ApiV2 implements IApiV2 {
	constructor() {
		if (!(this instanceof ApiV2)) {
			return new ApiV2();
		}

		const host = envHost;

		const activeCompany = getActiveCompany(getState());
		const crud = new CRUD({
			baseUrl: host,
			companyUuid: activeCompany && activeCompany.uuid,
			version: 2
		});

		this.DELETE = crud.DELETE.bind(crud);
		this.GET = crud.GET.bind(crud);
		this.POST = crud.POST.bind(crud);
		this.PUT = crud.PUT.bind(crud);
		this.STREAM = crud.STREAM.bind(crud);
	}

	DELETE: (path: string, params?: {}, admin?: boolean, raw?: boolean) => Promise<{} | null>;
	GET: (path: string, params?: {}, admin?: boolean, raw?: boolean, headers?: HeaderObject) => Promise<{} | null>;
	POST: (path: string, params: {}, admin?: boolean, headers?: HeaderObject, raw?: boolean) => Promise<{} | null>;
	PUT: (path: string, params: {}, admin?: boolean) => Promise<{} | null>;
	STREAM: (path: string, onStart: (item: any) => void, onProgress: (item: any) => void) => Promise<{}>;
}

import { Deployment, DeploymentsResult, IDeploymentsApi, DeploymentRequest } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class DeploymentsApi extends ApiV2 implements IDeploymentsApi {
	getDeployments(params?: DeploymentRequest) {
		if (params?.["filter[state]"] === "all") {
			delete params["filter[state]"];
		}

		if (params?.["filter[type]"] === "all") {
			delete params["filter[type]"];
		}

		return this.GET("deployments", params) as Promise<DeploymentsResult>;
	}

	getDeployment(uuid: string, expand?: boolean) {
		return this.GET(`deployments/${uuid}`, expand && {
			expand: [ "devices", "deviceGroups" ].join(",")
		});
	}

	createDeployment(deployment: Partial<Deployment>) {
		return this.POST("deployments", deployment);
	}

	deleteDeployment(uuid: string) {
		return this.DELETE(`deployments/${uuid}`);
	}

	updateDeployment(deployment: Deployment) {
		return this.PUT(`deployments/${deployment.uuid}`, deployment);
	}

	cancelDeployment(uuid: string) {
		return this.POST(`deployments/${uuid}/cancel`, {});
	}

	redeployDeployment(uuid: string) {
		return this.POST(`deployments/${uuid}/redeploy`, {});
	}

	getDeploymentTargets(companyUUID: string) {
		return this.GET(`companies/${companyUUID}/deployments/users`)
	}
}

import { ButtonType } from "antd/lib/button/button";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { ActiveUuidRoute, AnalyticsResult, Context, CustomCSS, IAd, ICompany, Filters,
	Sorts } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import AnalyticsContentArea from "Components/Analytics/AnalyticsContentArea";
import AnalyticsFilters, { UnwrappedAnalyticsFilters } from "Components/Analytics/AnalyticsFilters";
import AnalyticsPropertiesPanel from "Components/Analytics/AnalyticsPropertiesPanel";
import AnalyticsReport from "Components/Analytics/AnalyticsReport";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import { Button, Icon } from "Components/Global/Common";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import { IconWeights } from "Components/Global/Icon";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import {
	getAdAnalyticsAsync, getAnalyticsReportDownloadAsync, setAdsAsync, updateAdAsync
}
	from "Data/Actions/AdsAsync";
import { setSelectAdsModal } from "Data/Actions/UI/Modals";
import { setActiveFilters, setActiveSelection } from "Data/Actions/UI";
import { getActiveAnalyticsAd, getActiveAnalyticsResult, getActiveAnalyticsUuid,
	getAdAnalyticsFilter, getFilteredSortedAnalyticsAds } from "Data/Selectors/AdAnalytics";
import { getActiveCompany } from "Data/Selectors/Company";
import { getActiveSelection } from "Data/Selectors/UI";

const mapDispatchToProps = (dispatch) => ({
	fetchAds: () => dispatch(setAdsAsync(true)),
	getAnalytics: (uuid: string) => dispatch(getAdAnalyticsAsync(uuid)),
	getAnalyticsReportDownload: (uuid: string, token: string, name: string) =>
		dispatch(getAnalyticsReportDownloadAsync(uuid, token, name)),
	selectAnalytics: (uuids: string[]) => dispatch(setActiveSelection("analytics", uuids)),
	setFilter: (value: string) => dispatch(setActiveFilters("filter", Filters.ANALYTICS, value)),
	showAdsModal: () => dispatch(setSelectAdsModal(true, Context.ANALYTICS)),
	updateAd: (ad: IAd) => dispatch(updateAdAsync(ad))
});

const mapStateToProps = (state, ownProps) => {
	return {
		activeAd: getActiveAnalyticsAd(state),
		activeAnalytics: getActiveAnalyticsResult(state),
		activeCompany: getActiveCompany(state),
		activeSelection: getActiveSelection(state, "analytics"),
		activeUuid: getActiveAnalyticsUuid(state),
		ads: getFilteredSortedAnalyticsAds(state),
		filter: getAdAnalyticsFilter(state)
	};
};

interface AnalyticsPageProps extends RouteComponentProps<ActiveUuidRoute> {
	activeAd: IAd;
	activeAnalytics: AnalyticsResult;
	activeCompany: ICompany;
	activeSelection: string[];
	activeUuid: string;
	ads: IAd[];
	fetchAds: () => void;
	filter: string;
	getAnalytics: (uuid: string) => void;
	getAnalyticsReportDownload: (uuid: string, token: string, name: string) => void;
	selectAnalytics: (uuids: string[]) => void;
	setFilter: (value: string) => void;
	showAdsModal: () => void;
	updateAd: (ad: IAd) => Promise<void>;
}

interface AnalyticsPageState {
	selectModeOn: boolean;
}

export class AnalyticsPage extends React.Component<AnalyticsPageProps, AnalyticsPageState> {
	constructor(props: AnalyticsPageProps) {
		super(props);

		this._filters = null;

		this.state = {
			selectModeOn: false
		};

		this.styles = {
			button: {
				padding: 5
			},
			clear: {
				position: "absolute",
				right: 34,
				top: 2
			}
		}

		this.clearAllFilters = this.clearAllFilters.bind(this);
		this.deleteAdAnalytics = this.deleteAdAnalytics.bind(this);
		this.deselectAll = this.deselectAll.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getBatchOperations = this.getBatchOperations.bind(this);
		this.handleDeleteAdAnalytics = this.handleDeleteAdAnalytics.bind(this);
		this.renderHeader = this.renderHeader.bind(this);
		this.renderAnalyticsFilters = this.renderAnalyticsFilters.bind(this);
		this.renderCenterContent = this.renderCenterContent.bind(this);
		this.renderLeftContent = this.renderLeftContent.bind(this);
		this.renderRightContent = this.renderRightContent.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.setAnalyticsFiltersRef = this.setAnalyticsFiltersRef.bind(this);
		this.showAdModal = this.showAdModal.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
	}

	_filters: connect<UnwrappedAnalyticsFilters>;
	styles: CustomCSS;

	componentDidMount() {
		const { activeAd, getAnalyticsReportDownload, location } = this.props;
		const { search } = location;

		this.fetchData();

		// handle analytics email report downloads
		if (activeAd && search && search.includes("?export=")) {
			const { 1: token } = search.split("?export=");
			const { name, uuid } = activeAd;

			getAnalyticsReportDownload(uuid, token, name);
		}
	}

	componentDidUpdate(prevProps: AnalyticsPageProps) {
		const { activeCompany: { uuid }, activeUuid } = this.props;

		if (uuid !== prevProps.activeCompany.uuid || activeUuid !== prevProps.activeUuid) {
			this.fetchData();
		}
	}

	render() {
		return (
			<ThreeColumnLayout
				leftContent={ this.renderLeftContent() }
				centerContent={ this.renderCenterContent() }
				rightContent={ this.renderRightContent() }
			/>
		);
	}

	renderHeader() {
		if (!this.props.activeAd) {
			return null;
		}

		return (
			<div style={ this.styles.button }>
				<Button
					type="primary"
					icon="plus-circle"
					corners="rounded"
					onClick={ this.showAdModal }
				>
					New Ad Analytics
				</Button>
			</div>
		);
	}

	renderAnalyticsFilters() {
		const icon = (
			<Icon
				key={ Math.random() }
				style={ this.styles.clear }
				size="smaller"
				name="times-circle"
				iconWeight="regular"
				onClick={ this.clearAllFilters }
			/>
		);

		return new AccordionElement((
			<div>
				Filter By
				{ icon }
			</div>
		), (
			<AnalyticsFilters ref={ this.setAnalyticsFiltersRef } />
		), undefined, undefined, undefined, undefined, undefined, "dark");
	}

	renderCenterContent() {
		const { activeUuid, filter, setFilter } = this.props;

		if (activeUuid) {
			return <AnalyticsReport />;
		}

		return (
			<React.Fragment>
				<ContentAreaTopBar
					batch={ this.getBatchOperations() }
					sort={{
						dataType: Sorts.ANALYTICS
					}}
					search={{
						filterText: filter,
						onSearchChange: setFilter
					}}
				/>
				<AnalyticsContentArea
					onCreateNewAnalytics={ this.showAdModal }
					selectModeOn={ this.state.selectModeOn }
				/>
			</React.Fragment>
		);
	}

	renderLeftContent() {
		const { activeUuid } = this.props;

		if (!activeUuid) {
			return undefined;
		}

		return (
			<Accordion
				header={(
					<Button
						type="primary"
						icon="plus-circle"
						fluid
						corners="rounded"
						onClick={ this.showAdModal }
					>
						New Ad Analytics
					</Button>
				)}
				elements={[
					this.renderAnalyticsFilters()
				]}
			/>
		);
	}

	renderRightContent() {
		const { activeUuid } = this.props;

		if (!activeUuid) {
			return undefined;
		}

		return (
			<AnalyticsPropertiesPanel />
		);
	}

	clearAllFilters() {
		this._filters.getWrappedInstance().clearAllFilters();
	}

	deselectAll() {
		this.props.selectAnalytics([]);
	}

	fetchData() {
		const { activeUuid, fetchAds, getAnalytics } = this.props;

		fetchAds();

		if (activeUuid) {
			getAnalytics(activeUuid);
		}
	}

	getBatchOperations() {
		const { ads, activeSelection } = this.props;
		const numSelected = activeSelection.length;

		const batchButtons = [
			{
				disabled: numSelected === ads.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.selectAll
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.deselectAll
			},
			{
				disabled: !numSelected,
				label: "Delete Analytics",
				icon: "trash",
				iconWeight: "regular" as IconWeights,
				onClick: this.handleDeleteAdAnalytics,
				type: "danger" as ButtonType
			}
		];

		return {
			active: this.state.selectModeOn,
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Analytics",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	handleDeleteAdAnalytics() {
		Notifications.confirm("Remove ad analytics?",
			"Are you sure you want to remove analytics from the selected Ad?",
			"Delete",
			"Cancel",
			this.deleteAdAnalytics
		);
	}

	deleteAdAnalytics() {
		const { activeSelection, ads, updateAd } = this.props;
		const adsToDelete: IAd[] = ads.filter((ad) => {
			return activeSelection.includes(ad.uuid);
		});

		adsToDelete.forEach((ad) => {
			const updatedAd = Object.assign({}, ad, { analytics: null });
			updateAd(updatedAd);
		});

		this.toggleSelectMode();
	}

	selectAll() {
		const { ads, selectAnalytics } = this.props;
		selectAnalytics(ads.map((ad) => ad.uuid));
	}

	setAnalyticsFiltersRef(ref: connect<UnwrappedAnalyticsFilters>) {
		this._filters = ref;
	}

	showAdModal() {
		this.props.showAdsModal();
	}

	toggleSelectMode() {
		this.props.selectAnalytics([]);

		this.setState(prevState => ({ selectModeOn: !prevState.selectModeOn }));
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);

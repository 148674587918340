import * as moment from "moment";
import { push } from "react-router-redux";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { HealthReport, HealthReportApiResult, HealthReportNotification, HealthReportResult, InitialStreamResults,
	StreamResults } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import HealthReportApi from "Api/HealthReport";
import {
	createReport,
	deleteReport,
	resetReports,
	setAllReports,
	setReportDownloading,
	setReportResult,
	updateNotification,
	updateReport
} from "Data/Actions/HealthReport";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { addRunningHealthReport, removeRunningHealthReport, resetAsyncStates, setAsyncFetching,
	setAsyncState } from "Data/Actions/UI";
import { CacheInvalidationPeriod } from "Data/Objects/Global";
import { getServerColumns, getUIColumns } from "Data/Objects/HealthReport";
import { getHealthReportsLastFetchAll } from "Data/Selectors/Async";
import { getActiveCompanyId } from "Data/Selectors/Company";
import { getSelectedHealthReport } from "Data/Selectors/HealthReports";
import { getActiveUuid, getHealthReportsAsyncState } from "Data/Selectors/UI";

/**
 * Async Actions
 */

function createReportAsync(name: string) {
	const api = new HealthReportApi();
	const report = { name, columns: [ "status.timestamp", "device.name" ] };
	return (dispatch) => {
		return api.createReport(report)
			.then((result: Partial<HealthReport>) => {
				dispatch(createReport(Object.assign({}, report, {
					uuid: result.uuid,
					createdAt: Utils.getISOTimestamp(),
					devices: [],
					deviceGroups: []
				})));
				dispatch(push(`/health/${result.uuid}`));
				dispatch(successNotification(`Created ${name}.`));
			},
			(error) => {
				dispatch(errorNotification("Error creating report.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function deleteReportAsync(uuid: string) {
	const api = new HealthReportApi();

	return (dispatch, getState) => {
		const isActive = getActiveUuid(getState(), "health") === uuid;

		return api.deleteReport(uuid)
			.then(() => {
				if (isActive) {
					dispatch(push("/health/"));
				}

				dispatch(deleteReport(uuid));
				dispatch(successNotification("Report successfully deleted."));
			},
			(error) => {
				dispatch(errorNotification("Error deleting report.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function copyReportAsync(report: HealthReport) {
	const { name, columns, devices, deviceGroups, global } = report;
	const api = new HealthReportApi();

	return (dispatch, getState) => {
		const duplicateReports = getState().HealthReports.reports.filter((r) => r.name.indexOf(report.name) > -1);
		let newName = duplicateReports.length ? Utils.copyName(name) : name;

		const newReport: Partial<HealthReport> = {
			name: newName,
			columns,
			devices,
			deviceGroups,
			global
		};

		return api.createReport(Object.assign({}, newReport, {
			// Modify report columns to account for differing Server and UI requirements
			columns: getServerColumns(newReport.columns || [])
		}))
			.then((result: Partial<HealthReport>) => {
				newReport.uuid = result.uuid;

				dispatch(createReport(newReport));
				dispatch(push(`/health/${ result.uuid }`));
				dispatch(successNotification(`Created ${ newName }.`));
			},
			(error) => {
				dispatch(errorNotification("Error creating report.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function getAllReportsAsync(full?: boolean) {
	const api = new HealthReportApi();

	return (dispatch, getState) => {
		const state = getState();
		const activeCompanyId = getActiveCompanyId(state);
		const { currentPage, currentlyFetching, haveAllData, lastFetchedCompany } = getHealthReportsAsyncState(state);
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const lastFetchAll = getHealthReportsLastFetchAll(state);
		const lastFetchDiff = moment().diff(lastFetchAll, "minute");
		const companyChanged = activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = lastFetchDiff > CacheInvalidationPeriod || companyChanged;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		if (shouldInvalidateCache) {
			dispatch(resetReports());
			dispatch(resetAsyncStates());
		}

		dispatch(setAsyncFetching("healthReport", true, activeCompanyId));

		const page = shouldInvalidateCache ? 0 : currentPage;

		return api.getReports(page + 1, undefined, full)
			.then((result: HealthReportApiResult) => {
				const reports = result.data.map((report) => Object.assign({}, report, {
					// Modify report columns to account for differing Server and UI requirements
					columns: getUIColumns(report.columns || [])
				}));

				dispatch(setAllReports(reports, shouldInvalidateCache));
				dispatch(setAsyncState(
					"healthReport",
					!result.links.next,
					result.meta.current_page
				));
				dispatch(setAsyncFetching("healthReport", false));
			},
			(error) => {
				dispatch(errorNotification("Error getting reports.", error));
				dispatch(setAsyncFetching("healthReport", false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function getReportAsync(uuid: string, expandDevices: boolean, appendNotification: boolean) {
	const api = new HealthReportApi();

	return (dispatch) => {
		return api.getReport(uuid, expandDevices, appendNotification)
			.then((result: HealthReport) => {
				dispatch(updateReport(Object.assign({}, result, {
					// Modify report columns to account for differing Server and UI requirements
					columns: getUIColumns(result.columns || [])
				})));
			},
			(error) => {
				dispatch(errorNotification("Error getting report.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function getReportResultsAsync(uuid: string) {
	const api = new HealthReportApi();
	let batch: HealthReportResult[] = [];

	return (dispatch) => {
		return api.getReportResults(uuid, (initialResults: InitialStreamResults) => {
			dispatch(setReportResult(Object.assign({}, { uuid, noResult: initialResults.total === 0 })));
		}, (results: StreamResults<HealthReportResult>) => {
			if (results.save) {
				dispatch(setReportResult({ results: batch, uuid }));
				batch = [];
			} else if (results.node) {
				batch.push(results.node);
			}
		});
	};
}

function getReportExportAsync(report: HealthReport, onDone?: (data: any) => Promise<Response>) {
	const api = new HealthReportApi();
	const { uuid, name } = report;
	return (dispatch) => {
		dispatch(setReportDownloading(uuid, true));
		return api.getReportExport(uuid)
			.then((response: Response) => {
				dispatch(setReportDownloading(uuid, false));

				if (response.text && typeof response.text === "function") {
					response.text()
						.then((csvText) => {
							let csvContent = "data:text/csv;charset=utf-8," + csvText;
							const encodedUri = encodeURI(csvContent);
							Utils.initiateDownload(encodedUri, `${ name }.csv`);
						});
				} else {
					dispatch(errorNotification("Health Report not yet run.",
						"In order to download the results of a Health Report, it must be run first."))
				}
			},
			(error) => {
				dispatch(setReportDownloading(uuid, false));
				dispatch(errorNotification("Error downloading report.", error));
			})
			.catch((error) => {
				dispatch(setReportDownloading(uuid, false));
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	}
}

function updateNotificationAsync(uuid: string, notification: HealthReportNotification) {
	const api = new HealthReportApi();

	return (dispatch, getState) => {
		const state = getState();
		const activeReportId = getActiveUuid(state, "health");
		const activeHealthReport = getSelectedHealthReport(state, activeReportId);

		if (!activeHealthReport) {
			return;
		}

		const oldNotification = activeHealthReport.notification || {
			enabled: false,
			frequency: "daily"
		};

		dispatch(updateNotification(uuid, notification));

		return api.updateNotificationSettings(uuid, notification)
			.then((result: HealthReportNotification) => {
			},
			(error) => {
				dispatch(updateNotification(uuid, oldNotification));
				dispatch(errorNotification("Error updating report notification settings.", error));
			})
			.catch((error) => {
				dispatch(updateNotification(uuid, oldNotification));
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function updateReportAsync(report: HealthReport) {
	const api = new HealthReportApi();

	return (dispatch, getState) => {
		const oldReport = getSelectedHealthReport(getState(), report.uuid);

		dispatch(updateReport(report));

		return api.updateReport(Object.assign({}, report, {
			// Modify report columns to account for differing Server and UI requirements
			columns: getServerColumns(report.columns || [])
		}))
			.then((result: Partial<HealthReport>) => {
				// Event succeeded, but don't spam the user with notifications
			},
			(error) => {
				dispatch(updateReport(oldReport || {} as HealthReport));
				dispatch(errorNotification("Error updating report.", error));
			})
			.catch((error) => {
				dispatch(updateReport(oldReport || {} as HealthReport));
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function runReportAsync(uuid: string) {
	const api = new HealthReportApi();

	return (dispatch, getState) => {
		dispatch(addRunningHealthReport(uuid));
		return api.runReport(uuid)
			.then(() => {
				// good to go
				const report = getSelectedHealthReport(getState(), uuid);
				const now = new Date();
				dispatch(setReportResult({ updatedAt: now, uuid }));
				dispatch(updateReport({ ...report, executedAt: now.toString() } as HealthReport));
			},
			(error) => {
				dispatch(errorNotification("Error running report.", error));
				dispatch(removeRunningHealthReport(uuid));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

export {
	copyReportAsync,
	createReportAsync,
	deleteReportAsync,
	getAllReportsAsync,
	getReportAsync,
	getReportResultsAsync,
	getReportExportAsync,
	updateNotificationAsync,
	updateReportAsync,
	runReportAsync
};

import Radium from "radium";
import * as React from "react";
import { DragSource, DragSourceCollector, DragSourceSpec } from "react-dnd";

import { CustomCSS, IAd } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

const { darkerGray, filters: { hover, hoverAlt } } = Colors;

export const IDENTIFIER = "adRow";

interface DraggableAdRowCollectedProps {
	connectDragSource?: Function;
}

interface DraggableAdRowDragObject {
	ad: IAd;
}

interface DraggableAdRowProps {
	ad: IAd;
	connectDragSource?: Function;
	draggable: boolean;
	isSelected?: boolean;
}

@Radium
class DraggableAdRow extends React.Component<DraggableAdRowProps> {
	constructor(props: DraggableAdRowProps) {
		super(props);

		this.styles = {
			icon: {
				flex: "1 1 50%",
				textAlign: "right",
				color: "rgba(255, 255, 255, 0.5)"
			},
			inner: {
				flex: "1 1 100%",
				whiteSpace: "nowrap",
				overflow: "hidden",
				textOverflow: "ellipsis"
			},
			row: {
				padding: 6,
				display: "flex",
				whiteSpace: "nowrap",
				background: darkerGray,
				":hover": {
					background: hover
				}
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { ad, connectDragSource, draggable, isSelected } = this.props;
		const { inner, row } = this.styles;
		const innerStyle = {
			...inner,
			maxWidth: !draggable ? "100%" : "50%"
		};
		const rowStyle = {
			...row,
			background: isSelected ? hoverAlt : "none"
		};

		const element = (
			<div style={ rowStyle }>
				<div style={ innerStyle }>
					{ ad.name }
				</div>
				{ this.renderDraggableIcon() }
			</div>
		);

		if (draggable && connectDragSource) {
			return connectDragSource(element);
		}

		return element;
	}

	renderDraggableIcon() {
		const { ad, draggable } = this.props;

		if (!draggable) {
			return null;
		}

		return (
			<div style={ this.styles.icon }>
				<Icon name="clock" iconWeight="regular" />{ Utils.getHumanReadableDuration(ad.duration) }
			</div>
		);
	}
}

const source: DragSourceSpec<DraggableAdRowProps, DraggableAdRowDragObject> = {
	beginDrag(props: DraggableAdRowProps) {
		return {
			ad: props.ad
		}
	}
}

const collector: DragSourceCollector<DraggableAdRowCollectedProps> = (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource()
	}
}

export default DragSource(IDENTIFIER, source, collector)(DraggableAdRow);
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { CustomCSS, ICompany, Team } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Loader } from "Components/Global/Common";
import { getCompanyAdminInfoAsync } from "Data/Actions/Company";
import { getIntegratorListAsync } from "Data/Actions/Team";
import { getActiveCompany, getCompany } from "Data/Selectors/Company";
import { getTeam } from "Data/Selectors/Team";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";

interface InstructionsPageRoute {
	companyId: string;
	integratorId: string;
}

interface InstructionsPageProps extends RouteComponentProps<InstructionsPageRoute> {
	activeCompany: ICompany;
	company: ICompany;
	getCompanyDetails: (uuid: string) => void;
	getIntegrators: () => void;
	integrator: Team;
	qrBase64: string;
}

export const mapStateToProps = (state, ownProps) => {
	const { match: { params: { companyId, integratorId }}} = ownProps;
	const activeCompany = getActiveCompany(state);
	const company = getCompany(state, companyId);
	const integratorCompany = getCompany(state, integratorId)
	let integrator = getTeam(state, integratorId);
	let integratorHash = integrator && integrator.hashid;
	if (activeCompany.teamType === "integrator" && activeCompany.hashid !== integratorHash) {
		integratorHash = activeCompany.hashid;
	}

	if (!integrator && integratorId && integratorCompany?.hashid) {
		integratorHash = integratorCompany.hashid;
		integrator = {
			name: integratorCompany.name,
			hashid: integratorCompany.hashid
		} as Team
	}

	return {
		activeCompany,
		company,
		integrator,
		qrBase64: Utils.generateQrCode(company ? company.hashid : "", integratorHash)
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCompanyDetails: (uuid: string) => dispatch(getCompanyAdminInfoAsync(uuid)),
	getIntegrators: () => dispatch(getIntegratorListAsync())
});

class InstructionsPage extends React.Component<InstructionsPageProps, any> {
	constructor(props: InstructionsPageProps) {
		super(props);

		this.additionalInstructions = decodeURIComponent(props.location.search.substring(3));

		this.styles = {
			additionalInstructions: {
				marginBottom: 20
			},
			bottom: {
				pageBreakBefore: "avoid",
				margin: 50
			},
			loading: {
				alignItems: "center",
				display: "flex",
				height: "100vh",
				justifyContent: "center",
				width: "100%"
			},
			qrCode: {
				width: "100%"
			},
			wrapper: {
				margin: 20,
				maxHeight: "100vh",
				maxWidth: "100vw",
				overflow: "auto"
			},
			codeSection: {
				border: "1px solid black",
				marginRight: 5,
				paddingLeft: 5,
				display: "inline-block",
				letterSpacing: "0.5em"
			},
			hyphen: {
				paddingRight: 5,
				display: "inline-block"
			}
		};
	}

	additionalInstructions: string;

	styles: CustomCSS;

	componentDidMount() {
		const { activeCompany, company: { hashid, uuid }, getCompanyDetails, getIntegrators, integrator, match } = this.props;

		if (!hashid) {
			// TODO: add company fetching check here (Redux ticket)
			getCompanyDetails(uuid);
		}

		if (
			activeCompany.teamType !== "integrator" &&
			match.params.integratorId &&
			!integrator && hasPermission(PERMISSIONS.VIEW_COMPANY_INTEGRATIONS)
		) {
			// TODO: add team fetching check here (Redux ticket)
			getIntegrators();
		}
	}

	render() {
		const { company: { hashid, name }, integrator, match, qrBase64 } = this.props;
		const { bottom, loading, qrCode, wrapper } = this.styles;

		if (!qrBase64) {
			return (
				<div style={loading}>
					<Loader />
				</div>
			);
		}

		let instructions = `Installation Instructions for ${name}`;
		let hashId = hashid;

		if (match.params.integratorId && integrator) {
			instructions += ` ( ${integrator.name} )`;
			hashId += integrator.hashid;
		}

		return (
			<div style={wrapper}>
				<h1>{ instructions }</h1>
				<div>
					<ol>
						<li>Power up the Clinton Electronics public view monitor.</li>
						<li>Read the initial setup dialog, and follow the instructions</li>
						<li>Manual Entry Code: <b>{ this.renderCode(hashId) }</b></li>
					</ol>
				</div>
				{this.renderAdditionalInstructions()}
				<hr />
				<div style={bottom}>
					<img src={qrBase64} style={qrCode} />
				</div>
			</div>
		);
	}

	renderCode(code: string) {
		const { codeSection: codeSectionStyle, hyphen } = this.styles;
		const codeSections = code.match(/.{1,4}/g) || [];

		return codeSections.map((section, index) => {
			let codeSection = [ (
				<div
					style={ codeSectionStyle }
					key={ `company-code-character-${ section }` }>
					{ section }
				</div>
			) ];

			if (index < codeSections.length - 1) {
				codeSection.push(
					<div style={ hyphen }>
						-
					</div>
				)
			}

			return codeSection;
		});
	}

	renderAdditionalInstructions() {
		if (!this.additionalInstructions) {
			return null;
		}

		return (
			<div style={this.styles.additionalInstructions}>
				<h4>Additional Instructions</h4>
				{this.additionalInstructions}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructionsPage);
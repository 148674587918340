import * as update from "immutability-helper";
import * as localforage from "localforage";
import * as moment from "moment";
import { createTransform, persistReducer } from "redux-persist";

import { Action, DeviceError, DeviceErrorsDispatch, DeviceMomentDispatch, DeviceSpeedTestDispatch,
	DeviceStatusDispatch, DeviceUpdateDispatch, DeviceUrlDispatch, SpeedTestProgressArgs
} from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DeviceHealthModalState, DeviceHealthModalStates } from "Data/Objects/AppState";
import Perishable from "Data/Objects/Perishable";
import { createReducer } from "Data/Utils";
import { cloneDeep } from "lodash";

export function updateDeviceSetting(
	state: DeviceHealthModalStates,
	device: string,
	setting: string,
	value: Perishable<boolean> | string | DeviceError[] | moment.Moment | SpeedTestProgressArgs,
	reset?: boolean
) {
	let newState = cloneDeep(state);

	if (!state[device]) {
		newState[device] = new DeviceHealthModalState();
	}

	if (setting === "deviceErrors" && !reset) {
		return update(newState, {
			[device]: {
				[setting]: {
					$push: value
				}
			}
		})
	}

	return update(newState, {
		[device]: {
			[setting]: {
				$set: value
			}
		}
	});
}

export function setDeviceClearCacheStatus(state: DeviceHealthModalStates, action: Action<DeviceStatusDispatch>) {
	const { deviceUUID, status } = action.args;
	return updateDeviceSetting(state, deviceUUID, "clearingCache", status);
}

export function setDeviceDatabaseStatus(state: DeviceHealthModalStates, action: Action<DeviceStatusDispatch>) {
	const { deviceUUID, status } = action.args;
	return updateDeviceSetting(state, deviceUUID, "fetchingDatabase", status);
}

export function setDeviceErrorLogStatus(state: DeviceHealthModalStates, action: Action<DeviceStatusDispatch>) {
	const { deviceUUID, status } = action.args;
	return updateDeviceSetting(state, deviceUUID, "fetchingErrorLog", status);
}

export function setPingStatus(state: DeviceHealthModalStates, action: Action<DeviceStatusDispatch>) {
	const { deviceUUID, status } = action.args;
	return updateDeviceSetting(state, deviceUUID, "pingStatus", status);
}

export function setDeviceRebooting(state: DeviceHealthModalStates, action: Action<DeviceStatusDispatch>) {
	const { deviceUUID, status } = action.args;
	return updateDeviceSetting(state, deviceUUID, "rebooting", status);
}

export function setDeviceScreenshotStatus(state: DeviceHealthModalStates, action: Action<DeviceStatusDispatch>) {
	const { deviceUUID, status } = action.args;
	return updateDeviceSetting(state, deviceUUID, "fetchingScreenshot", status);
}

export function setDeviceUpdatingAds(state: DeviceHealthModalStates, action: Action<DeviceStatusDispatch>) {
	const { deviceUUID, status } = action.args;
	return updateDeviceSetting(state, deviceUUID, "adsUpdating", status);
}

export function setDeviceScreenshotURL(state: DeviceHealthModalStates, action: Action<DeviceUrlDispatch>) {
	const { deviceUUID, url } = action.args;
	return updateDeviceSetting(state, deviceUUID, "screenshotURL", url);
}

export function setDeviceScreenshotTime(state: DeviceHealthModalStates, action: Action<DeviceUpdateDispatch>) {
	const { deviceUUID, time } = action.args;
	return updateDeviceSetting(state, deviceUUID, "screenshotTime", time);
}

export function setDeviceDBURL(state: DeviceHealthModalStates, action: Action<DeviceUrlDispatch>) {
	const { deviceUUID, url } = action.args;
	return updateDeviceSetting(state, deviceUUID, "databaseURL", url);
}

export function setDeviceErrorLogs(state: DeviceHealthModalStates, action: Action<DeviceErrorsDispatch>) {
	const { deviceUUID, errors, reset } = action.args;

	return updateDeviceSetting(state, deviceUUID, "deviceErrors", errors, reset);
}

export function setDeviceSnapshotStatus(state: DeviceHealthModalStates, action: Action<DeviceStatusDispatch>) {
	const { deviceUUID, status } = action.args;
	return updateDeviceSetting(state, deviceUUID, "fetchingSnapshot", status);
}

export function setDeviceLastSeen(state: DeviceHealthModalStates, action: Action<DeviceMomentDispatch>) {
	const { deviceUUID, lastSeen } = action.args;
	return updateDeviceSetting(state, deviceUUID, "lastSeen", lastSeen);
}

export function setSpeedTestResult(state: DeviceHealthModalStates, action: Action<DeviceSpeedTestDispatch>) {
	const { deviceUUID, result } = action.args;
	return updateDeviceSetting(state, deviceUUID, "speedTest", result);
}

export function resetDeviceHealthModalState(state: DeviceHealthModalState, action: Action<null>) {
	return new DeviceHealthModalState();
}

export function setDeviceSoftwareUpdating(state: DeviceHealthModalStates, action: Action<DeviceStatusDispatch>) {
	const { deviceUUID, status } = action.args;
	return updateDeviceSetting(state, deviceUUID, "deviceUpdating", status);
}

const {
	SET_DEVICE_UPDATING_ADS,
	SET_DEVICE_REBOOTING,
	SET_PING_STATUS,
	SET_DEVICE_SCREENSHOT_STATUS,
	SET_DEVICE_SCREENSHOT_TIME,
	SET_DEVICE_DATABASE_STATUS,
	SET_DEVICE_ERROR_LOG_STATUS,
	SET_DEVICE_CLEAR_CACHE_STATUS,
	SET_DEVICE_SCREENSHOT_URL,
	SET_DEVICE_DB_URL,
	SET_DEVICE_ERRORS,
	SET_DEVICE_SNAPSHOT_STATUS,
	SET_DEVICE_LAST_SEEN,
	SET_DEVICE_SPEED_TEST_RESULT,
	RESET_DEVICE_HEALTH_MODAL_STATE,
	SET_DEVICE_SOFTWARE_UPDATING
} = ACTION_TYPES.UI;

const reducers = {
	[SET_DEVICE_UPDATING_ADS.type]: setDeviceUpdatingAds,
	[SET_DEVICE_REBOOTING.type]: setDeviceRebooting,
	[SET_PING_STATUS.type]: setPingStatus,
	[SET_DEVICE_SCREENSHOT_STATUS.type]: setDeviceScreenshotStatus,
	[SET_DEVICE_SCREENSHOT_TIME.type]: setDeviceScreenshotTime,
	[SET_DEVICE_DATABASE_STATUS.type]: setDeviceDatabaseStatus,
	[SET_DEVICE_ERROR_LOG_STATUS.type]: setDeviceErrorLogStatus,
	[SET_DEVICE_CLEAR_CACHE_STATUS.type]: setDeviceClearCacheStatus,
	[SET_DEVICE_SCREENSHOT_URL.type]: setDeviceScreenshotURL,
	[SET_DEVICE_DB_URL.type]: setDeviceDBURL,
	[SET_DEVICE_ERRORS.type]: setDeviceErrorLogs,
	[SET_DEVICE_SNAPSHOT_STATUS.type]: setDeviceSnapshotStatus,
	[SET_DEVICE_LAST_SEEN.type]: setDeviceLastSeen,
	[SET_DEVICE_SPEED_TEST_RESULT.type]: setSpeedTestResult,
	[RESET_DEVICE_HEALTH_MODAL_STATE.type]: resetDeviceHealthModalState,
	[SET_DEVICE_SOFTWARE_UPDATING.type]: setDeviceSoftwareUpdating
};

const perishableTransforms = createTransform(
	(state: DeviceHealthModalState, key: string) => {
		return state;
	},
	(state: DeviceHealthModalState, key: string) => {
		// Ignore the _persist state generated by redux-persist
		if (state.hasOwnProperty("rehydrated")) {
			return state;
		}
		for (key in state) {
			if (state[key] && state[key]._value !== undefined) {
				// If this item is a perishable item, refresh it properly
				const newItem = new Perishable<boolean>(state[key]._value);
				newItem._expiration = state[key]._expiration;
				state[key] = newItem;
			}
		}

		return state;
	},
	{ }
);

const persistTransforms = [
	perishableTransforms
];

export default persistReducer(
	{storage: localforage, key: "DeviceHealthModal", transforms: persistTransforms},
	createReducer(reducers, DeviceHealthModalStates)
);
import { DayOption } from "@connect/Interfaces";

export const sendDays: DayOption[] = [
	{ day: "Sunday", value: 0 },
	{ day: "Monday", value: 1 },
	{ day: "Tuesday", value: 2 },
	{ day: "Wednesday", value: 3 },
	{ day: "Thursday", value: 4 },
	{ day: "Friday", value: 5 },
	{ day: "Saturday", value: 6 }
];
import { Button, Card, Form } from "antd";
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, PasswordRequirements, InputValidator } from "@connect/Interfaces";
import { Input, Loader } from "Components/Global/Common";
import { changePassword, logout } from "Data/Actions/UserAsync";
import {
	minSpecialCharsValidator,
	minCharsValidator,
	minLowerCaseValidator,
	minUpperCaseValidator,
	minNumbersValidator
} from "Data/Objects/Validation";
import { getActiveCompany } from "Data/Selectors/Company";

const FormItem = Form.Item;

const mapStateToProps = (state) => {
	const company = getActiveCompany(state);

	return ({
		passwordRequirements: company.passwordRequirements
	});
}

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (oldPassword, newPassword, newPasswordConfirmation) =>
		dispatch(changePassword(oldPassword, newPassword, newPasswordConfirmation)),
	logout: () => dispatch(logout())
});

interface IPasswordResetPageProps extends React.Component {
	resetPassword: (oldPassword: string, newPassword: string, verifyValue: string) => Promise<any>;
	passwordRequirements: PasswordRequirements;
	logout: () => void;
}

interface IPasswordResetPageState {
	oldPassword: string;
	newPassword: string;
	verifyNewPassword: string;
	loaderVisibility: boolean;
	canSubmit: boolean;
}

class PasswordResetPage extends React.Component<IPasswordResetPageProps, IPasswordResetPageState> {
	constructor(props: IPasswordResetPageProps) {
		super(props);

		this.state = {
			oldPassword: "",
			newPassword: "",
			verifyNewPassword: "",
			loaderVisibility: false,
			canSubmit: false
		};

		this.styles = {
			borderRadius: {
				borderRadius: 6
			},
			button: {
				width: "100%"
			},
			card: {
				width: "30em",
				display: "block"
			},
			label: {
				color: "#8e8e8e",
				fontSize: "larger"
			},
			loginButton: {
				width: "100%"
			},
			wrapper: {
				backgroundColor: "#2e333f",
				position: "absolute",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 1000
			},
			wrapperInner: {
				display: "flex",
				height: "100%",
				alignItems: "center",
				justifyContent: "center"
			}
		};

		this.setLoader = this.setLoader.bind(this);
		this.submitPasswordVerification = this.submitPasswordVerification.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { wrapper, wrapperInner } = this.styles;

		return (
			<div style={ wrapper }>
				<div style={ wrapperInner }>
					{ this.renderCardAndLoader() }
				</div>
			</div>
		);
	}

	renderCardAndLoader() {
		const { loaderVisibility } = this.state;

		if (loaderVisibility) {
			return <Loader loading={ loaderVisibility } />
		} else {
			return this.renderPasswordCard();
		}
	}

	renderPasswordCard() {
		const {  newPassword, verifyNewPassword } = this.state;
		const { borderRadius, button, card, label, loginButton } = this.styles;
		const { passwordRequirements } = this.props;

		if (!passwordRequirements) {
			return;
		}

		const { minChars, minLowerCase, minUpperCase, minNumbers, minSpecialChars } = passwordRequirements;
		const formLabel = (item: string) => (<span style={ label }>{ item }</span>);
		const doesNotMatch = newPassword.length > 0 && newPassword !== verifyNewPassword;
		let error = "";
		let status: "success" | "error" = "success";

		if (doesNotMatch) {
			error = "Password must match.";
			status = "error";
		}

		const passwordValidator: InputValidator[] = [
			minCharsValidator(minChars),
			minLowerCaseValidator(minLowerCase),
			minUpperCaseValidator(minUpperCase),
			minNumbersValidator(minNumbers),
			minSpecialCharsValidator(minSpecialChars)
		]

		return (
			<React.Fragment>
				<Card
					style={ card }
					title="Create Your New Password"
				>
					<Form
						layout="vertical"
						onSubmit={ this.submitPasswordVerification }
					>
						<FormItem
							label={ formLabel("Current Password") }
							colon={ false }
							hasFeedback={ false }
						>
							<Input type="password"
								id="oldPass"
								style={ borderRadius }
								hideMaxLength
								updateCallback={ this.updateOldPassword }
								value=""
								disableSubmitCallback={ this.disableSubmitButton }
							/>
						</FormItem>
						<FormItem
							label={ formLabel("New Password") }
							colon={ false }
							hasFeedback={ false }
						>
							<Input type="password"
								id="newPass"
								style={ borderRadius }
								updateCallback={ this.updateNewPassword }
								value=""
								validator={ passwordValidator }
								disableSubmitCallback={ this.disableSubmitButton }
							/>
						</FormItem>
						<FormItem
							label={ formLabel("Verify Password") }
							colon={ false }
							hasFeedback={ false }
							validateStatus={ status }
							help={ error }
						>
							<Input type="password"
								id="newPassConfirm"
								style={ borderRadius }
								updateCallback={ this.updateVerifyNewPassword }
								value=""
								disableSubmitCallback={ this.disableSubmitButton }
							/>
						</FormItem>
						<FormItem>
							<Button
								htmlType="submit"
								size="large"
								style={ button }
								type="primary"
								disabled={ !this.state.canSubmit || doesNotMatch }
							>
								Verify
							</Button>
						</FormItem>
					</Form>
					<Button
						size="large"
						style={ loginButton }
						onClick={ this.handleLogout }
					>
						Log Out
					</Button>
				</Card>
			</React.Fragment>
		);
	}

	resetPassword() {
		const { resetPassword: resetPasswordAction } = this.props;
		const { verifyNewPassword, newPassword, oldPassword } = this.state;

		this.setState((prevState) => {
			return update(prevState, {
				loaderVisibility: { $set: true },
				newPasswordVisibility: { $set: false }
			});
		});

		resetPasswordAction(oldPassword, newPassword, verifyNewPassword);

		this.setLoader(false);
	}

	updateState(key: string, value: string | boolean) {
		this.setState((state) => {
			return update(state, {
				[key]: { $set: value }
			});
		});
	}

	disableSubmitButton = (canSubmit: boolean) => {
		const { oldPassword, newPassword, verifyNewPassword } = this.state;
		const inputsAreValid = canSubmit && !!oldPassword && !!newPassword && !!verifyNewPassword

		this.updateState("canSubmit", inputsAreValid);
	}

	updateOldPassword = (password: string) => {
		this.updateState("oldPassword", password)
	}

	updateNewPassword = (password: string) => {
		this.updateState("newPassword", password)
	}

	updateVerifyNewPassword = (password: string) => {
		this.updateState("verifyNewPassword", password)
	}

	setLoader(value: boolean) {
		this.updateState("loaderVisibility", value)
	}

	submitPasswordVerification(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const { verifyNewPassword, newPassword } = this.state;

		if (newPassword !== verifyNewPassword || !this.state.canSubmit) {
			return;
		}

		return this.resetPassword();
	}

	handleLogout = () => {
		this.props.logout();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { CustomCSS, IDeviceGroup, Filters } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { ButtonTypes, IconWeights } from "Components/Global/Button";
import { Input } from "Components/Global/Common";
import PropertiesPanel, { PropertiesPanelButton } from "Components/Global/PropertiesPanel";
import { deleteDeviceGroupAsync, updateDeviceGroupAsync } from "Data/Actions/Devices";
import { setActiveFilters, setActiveUuid } from "Data/Actions/UI";
import { AllGroup } from "Data/Objects/Devices";
import { DeviceGroupMaxLength } from "Data/Objects/Global";
import { nameValidation } from "Data/Objects/Validation";
import { getDeviceGroupById } from "Data/Selectors/Devices";
import { getActiveUuid } from "Data/Selectors/UI";

const mapStateToProps = (state) => {
	const activeUuid = getActiveUuid(state, "deviceGroups");
	const deviceGroup = getDeviceGroupById(state, activeUuid);

	return {
		activeUuid,
		deviceGroup
	}
};

const mapDispatchToProps = (dispatch) => ({
	deleteDeviceGroup: (group: IDeviceGroup) => dispatch(deleteDeviceGroupAsync(group)),
	selectGroup: (groupId: string) => dispatch(setActiveFilters("group", Filters.DEVICES, groupId)),
	setActiveGroupUuid: (uuid: string) => dispatch(setActiveUuid("deviceGroups", uuid)),
	updateDeviceGroup: (group: IDeviceGroup) => dispatch(updateDeviceGroupAsync(group)),
	pushToDevicesPage: (state?: { selectModeOn: boolean }) => dispatch(
		push({
			pathname: "/devices",
			state
		})
	)
});

interface DeviceGroupPropertiesPanelProps {
	activeUuid: string;
	deviceGroup: IDeviceGroup;
	deleteDeviceGroup: (group: IDeviceGroup) => void;
	pushToDevicesPage: (state?: { selectModeOn: boolean }) => void;
	selectGroup: (groupId: string) => void;
	setActiveGroupUuid: (uuid: string) => void;
	updateDeviceGroup: (group: IDeviceGroup) => void;
}

interface DeviceGroupPropertiesPanelState {
}

class DeviceGroupPropertiesPanel extends
	React.Component<DeviceGroupPropertiesPanelProps, DeviceGroupPropertiesPanelState> {
	constructor(props: DeviceGroupPropertiesPanelProps) {
		super(props);

		this.leftColumnWidth = 100;

		this.state = {
		};

		this.styles = {
			center: {
				textAlign: "center"
			},
			leftColumn: {
				display: "inline-block",
				width: this.leftColumnWidth
			},
			rightColumn: {
				flexBasis: 130
			},
			row: {
				display: "flex"
			}
		}

		this.getAvailableActions = this.getAvailableActions.bind(this);
		this.handleDeleteDeviceGroup = this.handleDeleteDeviceGroup.bind(this);
		this.handleNameChanged = this.handleNameChanged.bind(this);
		this.promptDeleteDeviceGroup = this.promptDeleteDeviceGroup.bind(this);
		this.updateDeviceGroup = this.updateDeviceGroup.bind(this);
	}

	leftColumnWidth: number;

	styles: CustomCSS;

	render() {
		const { activeUuid, deviceGroup } = this.props;

		if (!activeUuid || !deviceGroup) {
			return null;
		}

		return (
			<PropertiesPanel
				key={ activeUuid }
				actions={this.getAvailableActions()}
				properties={this.getRenderedContent()}
				title="Group Properties"
			/>
		);
	}

	renderChildCount() {
		const { leftColumn, row } = this.styles;
		const { devicesTotal } = this.props.deviceGroup;

		return (
			<div style={ row }>
				<div style={ leftColumn }>
					Total Devices:
				</div>
				{ devicesTotal }
			</div>
		);
	}

	renderTitleInput() {
		const { uuid, name } = this.props.deviceGroup;

		return (
			<div key={`${ uuid }_title-input`}>
				Name:
				<Input
					id={ uuid }
					maxLength={ DeviceGroupMaxLength }
					placeholder={ "My Device Group" }
					required={ true }
					value={ name }
					saveCallback={ this.handleNameChanged }
					validator={ nameValidation(DeviceGroupMaxLength) } />
			</div>
		);
	}

	getAvailableActions(): PropertiesPanelButton[] {
		const actions: PropertiesPanelButton[] = [
			{
				action: this.promptDeleteDeviceGroup,
				key: "delete_group",
				name: "Delete Group",
				icon: { name: "trash-alt", weight: "regular" as IconWeights },
				type: "danger" as ButtonTypes
			}
		];

		return actions;
	}

	getRenderedContent() {
		const properties = [
			this.renderTitleInput(),
			this.renderChildCount()
		];

		return properties;
	}

	promptDeleteDeviceGroup() {
		Notifications.confirm(
			"Are you sure you want to delete this group?",
			undefined,
			"Delete",
			"Cancel",
			this.handleDeleteDeviceGroup
		)
	}

	handleDeleteDeviceGroup() {
		const { deviceGroup, deleteDeviceGroup, setActiveGroupUuid, pushToDevicesPage, selectGroup } = this.props;
		const newSelection = deviceGroup.parent || AllGroup.uuid;

		deleteDeviceGroup(deviceGroup);
		pushToDevicesPage();
		setActiveGroupUuid(newSelection);
		selectGroup(newSelection);
	}

	handleNameChanged(newName: string) {
		const group = Object.assign({}, this.props.deviceGroup, { name: newName });
		this.updateDeviceGroup(group);
	}

	updateDeviceGroup(group: IDeviceGroup) {
		this.props.updateDeviceGroup(group);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceGroupPropertiesPanel);
import * as update from "immutability-helper";
import * as moment from "moment";

import { Action, AsyncFetchingDispatch, AsyncStateDispatch, NewAsyncStateDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { AsyncState, AsyncStates } from "Data/Objects/AppState";
import { initialAsyncStates } from "Data/Objects/UI";
import { createReducer } from "Data/Utils";

function splitAsyncKey(wholeKey: string) {
	let splitKey = wholeKey.split(".").filter(Boolean);
	const key = splitKey.shift();

	return {
		key: key || "",
		query: splitKey.filter(Boolean).join(".")
	}
}

export function createAsyncState(state: AsyncStates, action: Action<NewAsyncStateDispatch>) {
	const { key, query } = splitAsyncKey(action.args.key);
	const subState = state[key];
	let asyncState = query ? (subState.query || {})[query] : subState;

	if (!asyncState) {
		asyncState = new AsyncState();
	}

	const updateSet = query ? {
		[key]: { query: {
			[query]: { $set: asyncState }
		}}
	} : {
		[key]: { $set: asyncState }
	}

	return update(state, updateSet);
}

export function resetAsyncStates(state: AsyncStates, action: Action<null>) {
	return new AsyncStates(initialAsyncStates);
}

export function setAsyncFetching(state: AsyncStates, action: Action<AsyncFetchingDispatch>) {
	const { companyId, key, value } = action.args;
	const { key: section, query } = splitAsyncKey(key);
	const subState = state[section];
	const possibleAsyncState = query ? (subState.query || {})[query] : subState;
	const asyncState = possibleAsyncState || new AsyncState();
	const lastFetchedCompany = companyId !== undefined ? companyId : asyncState.lastFetchedCompany;
	let commands;
	if (possibleAsyncState) {
		commands = {
			currentlyFetching: { $set: value },
			lastFetchedCompany: { $set: lastFetchedCompany }
		};
	} else {
		commands = {
			$set: Object.assign({}, asyncState, {
				currentlyFetching: value,
				lastFetchedCompany
			})
		}
	}

	const updateSet = query ? {
		[section]: {
			query: { [query]: commands }
		}
	} : {
		[section]: commands
	};

	return update(state, updateSet);
}

export function setAsyncState(state: AsyncStates, action: Action<AsyncStateDispatch>) {
	const { currentPage, haveAll, key } = action.args;
	const { key: section, query } = splitAsyncKey(key);
	const subState = state[section];
	const asyncState = query ? (subState.query || {})[query] : subState;
	let commands;

	if (asyncState) {
		commands = {
			currentPage: { $set: currentPage },
			haveAllData: { $set: haveAll },
			lastFetch: { $set: moment() }
		};
	} else {
		commands = {
			$set: Object.assign({}, new AsyncState(), {
				currentPage,
				haveAllData: haveAll,
				lastFetch: moment()
			})
		};
	}
	const updateSet = query ? {
		[section]: {
			query: { [query]: commands }
		}
	} : {
		[section]: commands
	};

	return update(state, updateSet);
}

const {
	CREATE_ASYNC_STATE,
	RESET_ASYNC_STATES,
	SET_ASYNC_FETCHING,
	SET_ASYNC_STATE
} = ACTION_TYPES.UI;

const reducers = {
	[CREATE_ASYNC_STATE.type]: createAsyncState,
	[RESET_ASYNC_STATES.type]: resetAsyncStates,
	[SET_ASYNC_FETCHING.type]: setAsyncFetching,
	[SET_ASYNC_STATE.type]: setAsyncState
};

export default createReducer(reducers, AsyncStates, initialAsyncStates);

import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { OptionalTableProps } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import { Button, Loader, Select, Table } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import { releaseDevicesAsync, setDevices, tryFetchDevicesAsync } from "Data/Actions/Devices";
import { setCheckinDeviceModal } from "Data/Actions/UI/Modals";
import { setActiveSearch, setActiveSelection } from "Data/Actions/UI";
import { Company } from "Data/Objects/Company";
import { Device } from "Data/Objects/Devices";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getDeviceModelNames, getFilteredDevices, getUnassociatedDevices } from "Data/Selectors/Devices";
import {
	getActiveSearch, getActiveSelection, makeCanFetchDevices, makeHaveAllDevices, makeLastDevicesPage
} from "Data/Selectors/UI";
import { deepSearch } from "Data/Utils";

const { lightGray, offWhite } = Colors;
const { Option } = Select;

const mapStateToProps = (state) => {
	const lastPageFetched = makeLastDevicesPage();
	const haveAllDevices = makeHaveAllDevices();
	const canFetchDevices = makeCanFetchDevices();
	const search = getActiveSearch(state, "adminDevices");

	return {
		activeCompany: state.Company.activeCompany,
		canFetch: canFetchDevices(state),
		devices: getFilteredDevices(state, { filter: search }),
		unassociatedDevices: getUnassociatedDevices(state),
		haveAllDevices: haveAllDevices(state),
		lastPageFetched: lastPageFetched(state),
		selectedDevices: getActiveSelection(state, "devices"),
		models: getDeviceModelNames(state),
		search
	}
};

const mapDispatchToProps = (dispatch) => ({
	addDevices: (devices: Device[]) => dispatch(setDevices(devices, false, "")),
	getDevices: (companyUuid: string) => dispatch(tryFetchDevicesAsync(companyUuid, true)),
	releaseDevices: (devices: Device[]) => dispatch(releaseDevicesAsync(devices, "releaseDevice")),
	removeDevices: (devices: Device[]) => dispatch(releaseDevicesAsync(devices, "removeDevice")),
	selectDevices: (deviceUuids: string[]) => dispatch(setActiveSelection("devices", deviceUuids)),
	setSearch: (query: string) => dispatch(setActiveSearch("adminDevices", query)),
	showCheckinModal: () => dispatch(setCheckinDeviceModal(true))
});

interface AdminDevicesPageProps {
	activeCompany: Company;
	canFetch: boolean;
	devices: Device[];
	unassociatedDevices: Device[];
	selectedDevices: string[];
	haveAllDevices: boolean;
	lastPageFetched: number;
	models: string[];
	getDevices: (companyUuid: string) => void;
	selectDevices: (deviceUuids: string[]) => void;
	showCheckinModal: () => void;
	removeDevices: (devices: Device[]) => void;
	releaseDevices: (devices: Device[]) => void;
	search: string;
	setSearch: (query: string) => void;
}

interface AdminDevicesPageState {
	selectedList: string;
	selectModeOn: boolean;
}

export class AdminDevicesPage extends React.Component<AdminDevicesPageProps, AdminDevicesPageState> {
	constructor(props: AdminDevicesPageProps) {
		super(props);

		this.unassociated = "Unassociated";
		this.perPage = 50;

		this.state = {
			selectedList: props.activeCompany.name,
			selectModeOn: false
		}

		this.confirmDeleteDevices = this.confirmDeleteDevices.bind(this);
		this.deselectAllDevices = this.deselectAllDevices.bind(this);
		this.handleBulkDelete = this.handleBulkDelete.bind(this);
		this.handleDeviceSelection = this.handleDeviceSelection.bind(this);
		this.handlePaging = this.handlePaging.bind(this);
		this.handleRemoveDevice = this.handleRemoveDevice.bind(this);
		this.handleRemoveDevices = this.handleRemoveDevices.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.onSelectedListChange = this.onSelectedListChange.bind(this);
		this.confirmDeleteSelectedDevices = this.confirmDeleteSelectedDevices.bind(this);
		this.confirmReleaseSelectedDevices = this.confirmReleaseSelectedDevices.bind(this);
		this.releaseDevice = this.releaseDevice.bind(this);
		this.selectAllDevices = this.selectAllDevices.bind(this);
		this.showCheckinModal = this.showCheckinModal.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
	}

	unassociated: string;
	perPage: number;

	styles = {
		buttonStyle: {
			color: lightGray,
			background: offWhite
		},
		headerButtons: {
			marginRight: 3
		},
		checkInInputs: {
			display: "block",
			width: "100%"
		},
		container: {
			padding: "0 30px"
		},
		dropzone: {
			marginBottom: 8,
			flexDirection: "column",
			width: 250,
			padding: 6
		},
		link: {
			display: "inline",
			textDecoration: "none"
		}
	}

	componentWillMount() {
		this.props.getDevices(this.props.activeCompany.uuid);
		this.props.selectDevices([]);
	}

	render() {
		if (!(hasPermission(PERMISSIONS.DEVICES_CHECK_IN) || hasPermission(PERMISSIONS.DEVICES_MANAGE))) {
			return null;
		}

		const { container } = this.styles;
		const { search } = this.props;

		return (
			<div style={ container }>
				<ContentAreaTopBar
					batch={ this.getBatchOperations() }
					prefixButtons={ this.getHeaderPrefixButtons() }
					search={{
						filterText: search,
						onSearchChange: this.handleSearchChange
					}}
				>
					{ this.getHeaderChildren() }
				</ContentAreaTopBar>
				<div>
					{this.renderDevicesTable()}
					{this.renderWaypoint()}
				</div>
			</div>
		);
	}

	renderDevicesTable() {
		const { selectedList } = this.state;
		const { devices, search, unassociatedDevices } = this.props;

		const tableColumns: any[] = [ {
			title: "Device ID",
			dataIndex: "serial",
			defaultSortOrder: "descend"
		}, {
			title: "Serial #",
			dataIndex: "cec_serial",
			render: (serial) => serial || <span>&nbsp;</span>
		}, {
			title: "Model",
			dataIndex: "model"
		}, {
			title: "Name",
			dataIndex: "name"
		} ];

		// add the release column if we're not looking at the unassociated list
		if (selectedList !== this.unassociated) {
			tableColumns.push({
				title: "Release",
				render: (t, d, i) => {
					return (
						<Button
							key={i}
							icon="unlock"
							circular
							onClick={ this.releaseDevice(d) } />
					);
				}
			});
		}

		// add the delete column for those with proper permissions
		if (hasPermission(PERMISSIONS.DEVICES_CHECK_IN)) {
			tableColumns.push({
				title: "Delete",
				render: (t, d, i) => {
					return (
						<Button
							key={i}
							icon="trash-alt"
							iconWeight="regular"
							circular
							type="danger"
							onClick={ this.handleRemoveDevice(d.uuid) } />
					);
				}
			});
		}

		if (!devices) {
			return null;
		}

		const { selectModeOn } = this.state;
		const { selectedDevices } = this.props;

		const source = selectedList === this.unassociated ? unassociatedDevices : devices;
		const props: OptionalTableProps = {};

		if (selectModeOn) {
			props.rowSelection = {
				selectedRowKeys: selectedDevices,
				onChange: this.handleDeviceSelection
			}
		}

		return (
			<Table
				{ ...props }
				key={ `devices_table_select_${ selectModeOn }_source.length` }
				size="middle"
				className="admin-table-no-expand"
				indentSize={ 0 }
				dataSource={ this.filterData(source, search) }
				rowKey={ this.getUuid }
				columns={ tableColumns }
				locale={{ emptyText: "No devices found." }}
				pagination={ false } />
		);
	}

	filterData(devices: Device[], filterText: string) {
		const keys = [ "serial", "cecSerial", "name", "model" ];

		return deepSearch<Device>(devices, filterText, keys);
	}

	renderWaypoint() {
		const { canFetch, lastPageFetched, activeCompany } = this.props;
		const view = this.state.selectedList === this.unassociated ? "unassociatedDevices" : "devices";
		const key = `${activeCompany.uuid}-${lastPageFetched}-${view}-${canFetch ? "fetch" : ""}`;

		return (
			<div style={{
				height: 40,
				marginTop: 40,
				width: "100%",
				textAlign: "center",
				display: canFetch ? "inline-block" : "none"
			}}>
				<Waypoint key={ key } onEnter={ this.handlePaging } />
				<Loader />
			</div>
		);
	}

	getBatchOperations() {
		const { selectedDevices, devices } = this.props;
		const numSelected = selectedDevices.length;
		const batchButtons = [
			{
				disabled: numSelected === devices.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular",
				onClick: this.selectAllDevices
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular",
				onClick: this.deselectAllDevices
			},
			{
				disabled: !numSelected,
				label: "Release Devices",
				icon: "unlock",
				iconWeight: "regular",
				onClick: this.confirmReleaseSelectedDevices
			},
			{
				disabled: !numSelected,
				label: "Delete Devices",
				icon: "trash",
				iconWeight: "regular",
				onClick: this.confirmDeleteDevices,
				type: "danger"
			}
		];

		return {
			active: this.state.selectModeOn,
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Devices",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	getHeaderPrefixButtons() {
		return [ {
			disabled: this.state.selectModeOn,
			icon: "plus-circle",
			onClick: this.showCheckinModal,
			style: this.styles.buttonStyle,
			children: "Check In Device",
			className: "primary-button"
		} ];
	}

	getHeaderChildren() {
		if (!hasPermission(PERMISSIONS.DEVICES_MANAGE)) {
			return null;
		}

		const { activeCompany } = this.props;
		const { name } = activeCompany;

		return (
			<span style={{marginLeft: 10}}>
				View:&nbsp;
				<Select
					defaultValue={ name }
					value={ this.state.selectedList }
					onChange={ this.onSelectedListChange }>
					<Option value={ name }>{ name }</Option>
					<Option value={ this.unassociated }>{ this.unassociated }</Option>
				</Select>
			</span>
		);
	}

	confirmDeleteDevices () {
		Notifications.confirm(
			"Remove Selected Devices",
			"Are you sure you want to delete the selected devices?",
			"Yes",
			"No",
			this.handleBulkDelete
		);
	}

	deselectAllDevices() {
		this.handleDeviceSelection([]);
	}

	selectAllDevices() {
		this.handleDeviceSelection(this.props.devices.map((device) => device.uuid));
	}

	toggleSelectMode() {
		this.setState(prevState => ({ selectModeOn: !prevState.selectModeOn }));
		this.props.selectDevices([]);
	}

	handleBulkDelete() {
		this.handleRemoveDevices(this.props.selectedDevices);
		this.deselectAllDevices();
	}

	handleDeviceSelection(uuids: string[]) {
		this.props.selectDevices(uuids);
	}

	handleSearchChange(value: string) {
		this.props.setSearch(value);
	}

	showCheckinModal() {
		this.props.showCheckinModal();
	}

	confirmDeleteSelectedDevices() {
		Notifications.confirm("Delete Selected Devices",
			"Are you sure you want to delete the selected devices?",
			"Yes", "No", () => this.handleRemoveDevices());
	}

	confirmReleaseSelectedDevices() {
		const { selectedDevices, devices } = this.props;
		Notifications.confirm("Release Selected Devices",
			"Are you sure you want to release the selected devices?",
			"Yes", "No", () => {
				const filteredDevices = devices.filter(device => selectedDevices.includes(device.uuid));
				this.props.releaseDevices(filteredDevices);
			});
	}

	onSelectedListChange(value: string) {
		const { activeCompany, getDevices, selectDevices } = this.props;

		this.setState((prevState) => {
			return update(prevState, {
				devices: { $set: [] },
				selectedDevices: { $set: [] },
				lastPageFetched: { $set: 0 },
				selectedList: { $set: value }
			});
		}, () => {
			const companyUuid = value === this.unassociated ? this.unassociated : activeCompany.uuid;

			selectDevices([]);

			getDevices(companyUuid);
		});
	}

	handleRemoveDevice(uuid: string) {
		return () => this.handleRemoveDevices([ uuid ]);
	}

	releaseDevice(d: Device) {
		return () => this.props.releaseDevices([ d ]);
	}

	getUuid(d: Device) {
		return d.uuid;
	}

	handleRemoveDevices(selectedUuids?: string[]) {
		const { selectedDevices, devices, unassociatedDevices } = this.props;
		const selection = selectedUuids || selectedDevices;
		const devicesToRemove = devices
			.concat(unassociatedDevices)
			.filter(device => selection
				.includes(device.uuid));
		this.props.removeDevices(devicesToRemove);
	}

	handlePaging() {
		const { activeCompany, canFetch } = this.props;

		if (canFetch) {
			const companyUuid = this.state.selectedList === this.unassociated
				? this.unassociated
				: activeCompany.uuid;
			this.props.getDevices(companyUuid);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDevicesPage);
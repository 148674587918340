import { Layout, LocaleProvider, Modal, notification } from "antd";
import * as EN_US from "antd/lib/locale-provider/en_US";
import { check as browserCheck } from "bowser";
import { createLocation, Location } from "history";
import { parse } from "query-string";
import Radium from "radium";
import * as React from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { match as matchType, matchPath } from "react-router";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { toggleFeature } from "@connect/Features";
import initFontAwesome from "@connect/FontAwesome";
import initGoogleAnalytics from "@connect/GoogleAnalytics";
import { CustomCSS, IUser } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import SystemApi from "Api/System";
import ActionsPage from "Components/Actions/ActionsPage";
import AdminPage from "Components/Admin/AdminPage";
import CheckinDeviceModal from "Components/Admin/CheckinDeviceModal";
import CompanyCreateModal from "Components/Admin/CompanyCreateModal";
import CompanyRequestModal from "Components/Admin/CompanyRequestModal";
import CreateStoreModal from "Components/Admin/CreateStoreModal";
import CreateUserModal from "Components/Admin/CreateUserModal";
import AdsPage from "Components/Ads/AdsPage";
import TemplatesListModal from "Components/Ads/TemplatesListModal";
import AnalyticsPage from "Components/Analytics/AnalyticsPage";
import DeploymentApprovalModal from "Components/Deploy/DeploymentApprovalModal";
import DeploymentReport from "Components/Deploy/DeploymentReport";
import DeployPage from "Components/Deploy/DeployPage";
import DeviceGroupTreeBrowserModal from "Components/Devices/DeviceGroupTreeBrowserModal";
import DeviceHealthModal from "Components/Devices/DeviceHealthModal";
import DeviceErrorLogModal from "Components/Devices/DeviceHealthModal/DeviceErrorLogModal";
import DevicesPage from "Components/Devices/DevicesPage";
import InstructionsPage from "Components/Devices/InstructionsPage";
import MainNavigation from "Components/Global/MainNavigation";
import MediaPreviewModal from "Components/Global/MediaPreviewModal";
import RequestNameModal from "Components/Global/RequestNameModal";
import SAMLPage from "Components/Global/SAMLPage";
import SelectActionsModal from "Components/Global/SelectActionsModal";
import SelectAdsModal from "Components/Global/SelectAdsModal";
import SelectMediaModal from "Components/Global/SelectMediaModal";
import UserPermissionRoute from "Components/Global/UserPermissionRoute";
import HealthPage from "Components/Health/HealthPage";
import HomePage from "Components/Home/HomePage";
import MediaPage from "Components/Media/MediaPage";
import PlaylistsPage from "Components/Playlists/PlaylistsPage";
import SelectPlaylistsModal from "Components/Playlists/SelectPlaylistsModal";
import LoginPage from "Components/User/LoginPage";
import LogoutPage from "Components/User/LogoutPage";
import PrivacyPolicyModal from "Components/User/PrivacyPolicyModal";
import TermsConditionsModal from "Components/User/TermsConditionsModal";
import TokenVerifyPage from "Components/User/TokenVerifyPage";
import { getActiveCompanyDetails } from "Data/Actions/Company";
import { setRedirect as setRedirectTo } from "Data/Actions/System";
import { setFeatureTogglesAsync } from "Data/Actions/SystemAsync";
import { clearUploads as clearUploadsState } from "Data/Actions/Media";
import { getUserDetails, logout as logOut } from "Data/Actions/UserAsync";
import { LocationState } from "Data/Objects/AppState";
import { PERMISSIONS } from "Data/Objects/Permissions";
import { getConnected, getRedirect } from "Data/Selectors/System";
import { getCurrentUser } from "Data/Selectors/User";
import { cloneDeep } from "lodash";
import PasswordResetPage from "Components/User/PasswordResetPage";
import { setTermsConditionsModalVisibility } from "Data/Actions/UI/Modals";
import { termsVersion } from "Data/TermsAndConditions";

const { StyleRoot } = Radium;
const { Content, Sider } = Layout;
const enUS: any = EN_US;

const supported = browserCheck({
	chrome: "63",
	msedge: "16",
	safari: "11"
}, true);

const mapDispatchToProps = (dispatch) => ({
	clearUploads: () => dispatch(clearUploadsState()),
	logout: () => dispatch(logOut()),
	setCompany: (companyId: string, preserveUuid?: boolean) => dispatch(getActiveCompanyDetails(companyId, preserveUuid)),
	setFeatureToggles: () => dispatch(setFeatureTogglesAsync()),
	setRedirect: (location: LocationState) => dispatch(setRedirectTo(location)),
	getUserDetails: () => dispatch(getUserDetails()),
	showTermsModal: (value: boolean) => dispatch(setTermsConditionsModalVisibility(value))
});

const mapStateToProps = (state) => {
	return ({
		user: getCurrentUser(state),
		isConnected: getConnected(state),
		redirect: getRedirect(state)
	});
};

interface AppProps extends RouteComponentProps<any> {
	clearUploads: () => void;
	isConnected: boolean;
	logout: () => void;
	redirect: LocationState;
	setCompany: (companyId: string, preserveUuid?: boolean) => void;
	setFeatureToggles: () => void;
	setRedirect: (location: LocationState) => void;
	getUserDetails: () => Promise<IUser>;
	showTermsModal: (value: boolean) => void;
	user: IUser;
}

class App extends React.Component<AppProps> {
	constructor(props: AppProps) {
		super(props);

		initFontAwesome();
		initGoogleAnalytics();

		this.styles = {
			main: {
				height: "100vh"
			},
			navigation: {
				zIndex: 900
			},
			reload: {
				marginTop: 14
			}
		};

		this.connectionCheckInterval = 0;

		this.renderRedirects = this.renderRedirects.bind(this);
	}

	styles: {
		main: CustomCSS;
		navigation: CustomCSS;
		reload: CustomCSS;
	};

	connectionCheckInterval: number;

	componentWillMount() {
		if (!supported) {
			// set duration to 0 so that it does not close automatically and the user has to acknowledge
			Notifications.warning("You are using an unsupported browser.", (
				<div>
					Clinton Connect officially supports the latest versions of the following browsers:
					<ul>
						<li><a href="https://www.google.com/chrome/">Google Chrome</a></li>
						<li><a href="https://www.apple.com/safari/">Apple Safari</a></li>
						<li><a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a></li>
					</ul>
				</div>
			), 0);
		}

		this.handleConnection(this.props);
	}

	componentDidMount() {
		const {
			user, clearUploads, location, setFeatureToggles, setRedirect, getUserDetails, showTermsModal
		} = this.props;
		const { pathname } = location;

		clearUploads();
		setFeatureToggles();

		// we don't want to redirect to any of these paths on login
		const nonRedirectPaths = [ "/login", "/logout", "/auth/reset", "/auth/verify" ];


		if (!nonRedirectPaths.includes(pathname)) {
			// if there is no user (logged out state) and there is a pathname
			// present, cache it to redirect the user to after they've logged
			if (!user) {
				setRedirect(location);
			}

			getUserDetails().then((updatedUser) => {
				if (!updatedUser?.acceptedTerms
					|| (updatedUser?.acceptedTerms && updatedUser.acceptedTerms !== termsVersion)) {
					showTermsModal(true);
				}
			});
		}
	}

	componentWillUnmount() {
		window.clearInterval(this.connectionCheckInterval);
		this.connectionCheckInterval = 0;
	}

	shouldComponentUpdate(nextProps: AppProps) {
		// only update if user, connection, or location has changed
		const { location, isConnected, user } = this.props;
		const { pathname, search } = location;
		const {
			user: nextUser,
			isConnected: nextIsConnected,
			location: { pathname: nextPath, search: nextSearch }
		} = nextProps;
		const locationHasChanged = pathname !== nextPath || search !== nextSearch;
		const connectionHasChanged = isConnected !== nextIsConnected;
		const userHasChanged = user !== nextUser;

		if (locationHasChanged || connectionHasChanged || userHasChanged) {
			Modal.destroyAll();
			return true;
		}

		return false;
	}

	componentWillReceiveProps(nextProps: AppProps) {
		const { location } = nextProps;
		const locationHasChanged = this.props.location.pathname !== location.pathname;
		const connectionHasChanged = this.props.isConnected !== nextProps.isConnected;

		if (location.state) {
			const { companyId, denied } = location.state;

			if (denied && location.pathname !== "/login") {
				Notifications.error("Access Denied", "You must be logged in to visit that page.");
			}

			if (companyId) {
				// pass true as the second argument here so we preserve the UUID attached to
				// the incoming location pathname, if available, so we can properly deep-link between companies
				this.props.setCompany(companyId, true);
			}
		}

		if (locationHasChanged || connectionHasChanged) {
			ReactGA.pageview(location.pathname);
			this.handleConnection(nextProps);
		}
	}

	handleConnection(props: AppProps) {
		const { navigator } = window;
		const api = new SystemApi();
		const isOnline = navigator.hasOwnProperty("isOnline") ? navigator.onLine : true;
		const isConnected = props.isConnected && isOnline;

		// display notification if user has become disconnected
		// or if the user refreshes and is still disconnected
		if (!isConnected) {
			Notifications.error(`
				Your browser does not seem to be connected to the internet,
				or you may be experiencing network connectivity issues.
				Close this notification or click below to reload the page.
			`, (
				<div style={ this.styles.reload }>
					<a onClick={() => window.location.reload() }>Reload Page</a>
				</div>
			), 0, () => window.location.reload(), "connectionWarning");

			if (!this.connectionCheckInterval) {
				// check the heartbeat endpoint every 30 seconds
				this.connectionCheckInterval = window.setInterval(() => {
					api.getHeartbeat();
				}, 30000);
			}
		}

		// destroy notification if user has become reconnected
		if (isConnected) {
			window.clearInterval(this.connectionCheckInterval);
			this.connectionCheckInterval = 0;
			notification.close("connectionWarning");
		}
	}

	render() {
		return (
			<LocaleProvider locale={enUS}>
				<StyleRoot>
					<Layout style={this.styles.main}>
						{this.renderSider()}
						<Layout>
							<Content>
								{this.renderRedirects()}
							</Content>
							{this.renderModals()}
						</Layout>
					</Layout>
				</StyleRoot>
			</LocaleProvider>
		);
	}

	renderRedirects() {
		const { user, redirect, location, history, logout } = this.props;
		const { pathname, search } = location;
		const requestedPath = redirect && redirect.pathname;

		// get path and match information from location
		let path = requestedPath || pathname;
		let loc = cloneDeep(location);
		const query = parse(search);
		const match: matchType<{ company: string, redirect: string }> | null = matchPath(path, {
			path: "/:company/:redirect(.*)"
		});
		const uuid = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i);
		const newPath = match && match.params;
		const companyMatch = newPath && newPath.company || "";
		const redirectMatch = newPath && newPath.redirect || "";
		const companyId = companyMatch.match(uuid);
		const shouldRedirect = requestedPath || companyId;

		// compute a full path
		const newPathFull = requestedPath || "/" + redirectMatch + search;

		if (path === "/login" || path === "/undefined") {
			path = "/";
			loc = createLocation(path);

			loc.state = {}
		}

		// redirect the user if necessary due to login status or redirect
		let redirectPath = "/login";
		if (shouldRedirect && (companyId && redirectMatch !== pathname)) {
			redirectPath = newPathFull;
		} else if (user) {
			redirectPath = requestedPath || "/";
		}

		const defaultRedirect = shouldRedirect ? {
			pathname: redirectPath,
			state: {
				companyId: companyId && companyId[0],
				denied: !user && pathname !== "/"
			}
		} as Location : loc;

		// https://clintonelectronics.atlassian.net/wiki/spaces/APIDOX/pages/328826964/Email+Web+Routes
		if (query && query.token && pathname.split("/").filter(Boolean)[0] !== "auth") {
			logout();

			return (
				<Redirect to={{
					pathname: "/auth/reset",
					search: this.getQueryToken()
				}} />
			);
		}

		if (user?.passwordReset && location.pathname !== "/passwordreset") {
			Notifications.plain(
				"Password Reset Required",
				"An administrator on your company has required your password to be reset",
				null,
				0
			);
			history.push("/passwordreset");
		}

		return (
			<Switch>
				<UserPermissionRoute
					component={HomePage}
					exact
					path="/"
				/>
				<UserPermissionRoute
					component={InstructionsPage}
					exact
					path="/devices/:companyId/instructions/:integratorId?"
				/>
				<UserPermissionRoute
					component={AdsPage}
					path="/ads/:activeUuid?"
					permissions={[ PERMISSIONS.ADS_MANAGE ]}
				/>
				<UserPermissionRoute
					component={PlaylistsPage}
					path="/playlists"
					permissions={[ PERMISSIONS.PLAYLISTS_MANAGE, PERMISSIONS.DEPLOYMENTS_MANAGE ]}
				/>
				<UserPermissionRoute
					component={MediaPage}
					path="/media/:activeUuid?"
					permissions={[ PERMISSIONS.MEDIA_MANAGE ]}
				/>
				<UserPermissionRoute
					component={DeployPage}
					path="/deploy/:activeUuid?"
					permissions={[ PERMISSIONS.PLAYLISTS_MANAGE, PERMISSIONS.DEPLOYMENTS_MANAGE ]}
				/>
				<UserPermissionRoute
					component={DeploymentReport}
					path="/deploymentReport/:activeUuid?"
					permissions={[ PERMISSIONS.PLAYLISTS_MANAGE, PERMISSIONS.DEPLOYMENTS_MANAGE ]}
				/>
				<UserPermissionRoute
					component={DevicesPage}
					path="/devices/:activeUuid?"
					permissions={[ PERMISSIONS.DEVICES_MANAGE, PERMISSIONS.OWN_DEVICES_MANAGE ]}
				/>
				<UserPermissionRoute
					component={HealthPage}
					path="/health/:activeUuid?"
					permissions={[ PERMISSIONS.DEVICE_HEALTH_VIEW ]}
				/>
				<UserPermissionRoute
					component={ ActionsPage }
					path="/actions/:activeUuid?"
					permissions={[ PERMISSIONS.ACTIONS_MANAGE ]}
				/>
				<UserPermissionRoute
					component={toggleFeature("ad-analytics", AnalyticsPage, null)}
					path="/analytics/:activeUuid?"
				/>
				<UserPermissionRoute
					component={AdminPage}
					path="/admin"
				/>
				<UserPermissionRoute
					component={ PasswordResetPage }
					path="/passwordreset"
				/>
				<Route
					component={SAMLPage}
					path="/saml/:clientName?"
				/>
				<Route
					path="/login/:ssoType?/:clientName?"
					component={this.renderLoginComponent(user, defaultRedirect, redirectPath)}
				/>
				<Route path="/logout" component={LogoutPage} />
				<Route path="/auth/reset" component={TokenVerifyPage} />
				<Route path="/auth/verify" component={TokenVerifyPage} />
				<Redirect to={defaultRedirect} />
			</Switch>
		);
	}

	renderLoginComponent(user: IUser, defaultRedirect: Location, redirectPath: string) {
		return (props) => {
			if (user && redirectPath) {
				return <Redirect to={defaultRedirect} />;
			} else if (user && !redirectPath) {
				return <Redirect to="/" />;
			} else {
				return <LoginPage {...props} />;
			}
		}
	}

	renderSider() {
		if (Utils.isInstructionsPage() || !this.props.user) {
			return null;
		}

		return (
			<Sider collapsed={true} collapsedWidth={80} style={this.styles.navigation}>
				<MainNavigation />
			</Sider>
		);
	}

	renderModals() {
		if (!this.props.user) {
			return (
				<React.Fragment>
					<PrivacyPolicyModal />
					<TermsConditionsModal />
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<CheckinDeviceModal />
				<CompanyCreateModal />
				<CompanyRequestModal />
				<CreateStoreModal />
				<CreateUserModal />
				<DeviceErrorLogModal />
				<DeviceGroupTreeBrowserModal />
				<DeviceHealthModal />
				<MediaPreviewModal />
				<DeploymentApprovalModal />
				<PrivacyPolicyModal />
				<RequestNameModal />
				<SelectActionsModal />
				<SelectAdsModal />
				<SelectMediaModal />
				<SelectPlaylistsModal />
				<TemplatesListModal />
				<TermsConditionsModal />
			</React.Fragment>
		);
	}

	getQueryToken() {
		const query = parse(this.props.location.search);
		let queryString = "?";

		for (let p in query) {
			if (query[p]) {
				queryString += (p + "=" + query[p] + "&");
			}
		}

		return queryString.substring(0, queryString.length - 1);
	}
}

export default DragDropContext(HTML5Backend)(
	connect(mapStateToProps, mapDispatchToProps)(App)
);
import { Modal } from "antd";
import { notify } from "./Notify";

const { confirm } = Modal;
const defaultAutoDismissSeconds = 4.5;

const nullBack = () => null;

const Notifications = {
	plain: (title: string, content: string | JSX.Element = "",
		icon: JSX.Element | null = null, duration: number = defaultAutoDismissSeconds,
		onClose: () => void = nullBack, key?: string) => {
		return notify("plain", title, content, icon, duration, onClose, key);
	},
	success: (title: string, content: string | JSX.Element = "",
		duration: number = defaultAutoDismissSeconds, onClose: () => void = nullBack) => {
		return notify("success", title, content, null, duration, onClose);
	},
	info: (title: string, content: string | JSX.Element = "",
		duration: number = defaultAutoDismissSeconds, onClose: () => void = nullBack) => {
		return notify("info", title, content, null, duration, onClose);
	},
	warning: (title: string, content: string | JSX.Element = "",
		duration: number = defaultAutoDismissSeconds, onClose: () => void = nullBack, key?: string) => {
		return notify("warning", title, content, null, duration, onClose, key);
	},
	error: (title: string, content: string | JSX.Element = "",
		duration: number = defaultAutoDismissSeconds, onClose: () => void = nullBack, key?: string) => {
		return notify("error", title, content, null, duration, onClose, key);
	},
	confirm: (title: string, content: string | JSX.Element = "",
		okText: string = "", cancelText: string = "",
		onConfirm: () => void = nullBack, onCancel: () => void = nullBack) => {
		confirm({
			title: title,
			content: content,
			okText: okText,
			cancelText: cancelText,
			onOk: onConfirm,
			onCancel: onCancel
		});
	}
}

export { Notifications, defaultAutoDismissSeconds, nullBack };
import * as React from "react";
import { connect } from "react-redux";

import { Context, IPlaylist } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import GridView, { IGridViewProps } from "Components/Global/GridView";
import CreatePlaylistButton from "Components/Playlists/CreatePlaylistButton";
import PlaylistCard from "Components/Playlists/PlaylistCard";
import { replaceWithoutRender } from "Data/Actions/Navigation";
import { createPlaylistAsync, deletePlaylistAsync } from "Data/Actions/Playlists";
import { addToActiveSelection, removeActiveSelection } from "Data/Actions/UI";
import { getActiveSelection, getActiveUuid } from "Data/Selectors/UI";
import { cloneDeep } from "lodash";

interface IPlaylistGridProps extends IGridViewProps {
	content: IPlaylist[];
	context: Context;
	copyPlaylist: (playlist: IPlaylist) => Promise<IPlaylist>;
	deletePlaylist: (playlist: IPlaylist) => void;
	onCreatePlaylist: () => void;
	onPlaylistsSelected?: (playlists: IPlaylist[]) => void;
	setActive: (id: string) => void;
}

const mapDispatchToProps = (dispatch) => ({
	addToSelected: (ids: string[]) => dispatch(addToActiveSelection("playlists", ids)),
	copyPlaylist: (playlist: IPlaylist) => dispatch(createPlaylistAsync(playlist, true)),
	deletePlaylist: (playlist: IPlaylist) => dispatch(deletePlaylistAsync(playlist)),
	removeFromSelected: (ids: string[]) => dispatch(removeActiveSelection("playlists", ids)),
	setActive: (id: string) => dispatch(replaceWithoutRender(`/playlists/${id}`))
});

const mapStateToProps = (state) => ({
	activeUuid: getActiveUuid(state, "playlists"),
	activeSelection: getActiveSelection(state, "playlists")
});

class PlaylistGrid extends GridView<IPlaylistGridProps> {
	constructor(props: IPlaylistGridProps) {
		super(props);

		this.copyPlaylist = this.copyPlaylist.bind(this);
		this.deletePlaylist = this.deletePlaylist.bind(this);
		this.handleCardSelection = this.handleCardSelection.bind(this);
		this.renderCreatePlaylist = this.renderCreatePlaylist.bind(this);
		this.renderPlaylistCard = this.renderPlaylistCard.bind(this);
	}

	render() {
		const cards = [
			this.renderCreatePlaylist(),
			...this.props.content.map(this.renderPlaylistCard)
		];

		return this.renderContainer(cards);
	}

	renderCreatePlaylist() {
		const { selectModeOn, onCreatePlaylist } = this.props;

		if (selectModeOn) {
			return undefined;
		}

		return <CreatePlaylistButton key="create_playlist_button" onCreatePlaylist={ onCreatePlaylist } />;
	}

	renderPlaylistCard(playlist: IPlaylist, index: number) {
		const { selectModeOn } = this.props;
		const { uuid } = playlist;
		const isSelected = this.isSelected(uuid);

		return (
			<PlaylistCard
				key={ `${ uuid }_ad_card_${ index }` }
				cardObject={ playlist }
				cardType="playlist"
				isSelected={ isSelected }
				bulkSelectActive={ selectModeOn }
				onCardSelection={ this.handleCardSelection(playlist, isSelected) }
				actionButtons={[
					{ callback: this.copyPlaylist, name: "copy" },
					{ callback: this.deletePlaylist, name: "trash-alt" }
				]}
			/>
		);
	}

	copyPlaylist(cardObject: IPlaylist) {
		const { copyPlaylist, setActive } = this.props;
		let newPlaylist = cloneDeep(cardObject);

		newPlaylist.uuid = "";
		newPlaylist.name = Utils.copyName(newPlaylist.name);

		copyPlaylist(newPlaylist)
			.then((playlistCopy) => setActive(playlistCopy.uuid));
	}

	deletePlaylist(cardObject: IPlaylist) {
		Notifications.confirm("Delete selected playlist?",
			"Are you sure you want to delete the selected playlist? " +
			"The playlist will be removed from any deployments they may have been part of.",
			"Delete", "Cancel", () => {
				this.props.deletePlaylist(cardObject);
			});
	}

	handleCardSelection(playlist: IPlaylist, isSelected: boolean) {
		return (bulkAndShift: boolean) => {
			const { context, onPlaylistsSelected, selectModeOn } = this.props;

			if (onPlaylistsSelected && context !== Context.PAGE && !selectModeOn) {
				return onPlaylistsSelected([ playlist ])
			}

			return super.handleCardSelection(playlist, isSelected)(bulkAndShift);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistGrid);
import "antd/dist/antd.less"; // import official less entry file
import bugsnagReact from "@bugsnag/plugin-react"
import * as React from "react";
import * as ReactDOM from "react-dom";
import IdleTimer from "react-idle-timer";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { ConnectedRouter as BrowserRouter } from "react-router-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import "Theme/ant-theme.less"; // override variables here

import App from "@connect/App";
import { bugsnagClient } from "@connect/BugSnag";
import { history, persistor, store } from "@connect/Data";
import ErrorScreen from "Components/Global/ErrorScreen";
import { AppLoader } from "Components/Global/Loader";

const ErrorBoundary = bugsnagClient.use(bugsnagReact, React).getPlugin("react");

// default timeout for IdleTimer, so that it refreshes the page in an hour with no activity
const TIMEOUT: number = 3600000;

const ONIDLE = () => {
	window.location.reload();
}

ReactDOM.render(
	<ErrorBoundary
		FallbackComponent={ React.createFactory(ErrorScreen) } >
		<Provider store={store}>
			<PersistGate loading={<AppLoader />} persistor={persistor}>
				<BrowserRouter history={history}>
					{/* Idle timer that watches user and if no actions have occurred within
						an hour it refreshes the page */}
					<IdleTimer
						onIdle={ONIDLE}
						timeout={TIMEOUT}
					>
						<Route component={App} />
					</IdleTimer>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</ErrorBoundary>,
	document.getElementById("root")
);
import { Form } from "antd";
import * as update from "immutability-helper";
import * as React from "react";

import { InputValidationState, CustomCSS } from "@connect/Interfaces";
import Button from "Components/Global/Button";
import Input from "Components/Global/Input";

const FormItem = Form.Item;

const defaultInput: InputValidationState = {
	value: "",
	validateStatus: "success",
	errorMsg: ""
};

interface IPasswordPromptProps {
	onContinue: (password: string) => void;
}

interface IPasswordPromptState {
	password: InputValidationState;
}

class PasswordPrompt extends React.PureComponent <IPasswordPromptProps, IPasswordPromptState> {
	constructor(props: IPasswordPromptProps) {
		super(props);

		this.state = {
			password: defaultInput
		}

		this.styles = {
			button: {
				position: "relative",
				bottom: 20
			}
		}

		this.handleContinue = this.handleContinue.bind(this);
	}

	styles: CustomCSS

	render() {
		return (
			<div>
				<Form>
					<FormItem label={<span>Please confirm your password to continue.</span>}
						colon={false}
						hasFeedback={false}
						validateStatus={this.state.password.validateStatus}
						help={this.state.password.errorMsg}
					>
						<Input
							id="loginPassword"
							hideMaxLength
							updateCallback={(value) => {
								this.setState((state) => {
									return update(state, {
										password: { $merge: { value } }
									});
								});
							}}
							type="password"
							value={ this.state.password.value }
							onPressEnter={ this.handleContinue }
						/>
					</FormItem>
				</Form>
				<Button
					onClick={ this.handleContinue }
					style={ this.styles.button }
					type="primary"
				>
					Continue
				</Button>
			</div>
		);
	}

	handleContinue() {
		this.props.onContinue(this.state.password.value);
	}
}

export default PasswordPrompt;
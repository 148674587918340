import * as update from "immutability-helper";
import * as React from "react";

import DragNDropSortableListItem, { DragNDropListItem, HoverState } from "Components/Global/DragNDropSortableListItem";
import { Media } from "Data/Objects/Media";

interface DragNDropSortableListProps {
	data: DragNDropListItem[];
	sortKey: string;
	updateData: (items: DragNDropListItem[], indexToRemove?: number) => void;
	onUploadSuccess?: (media: Media) => void;
}

interface DragNDropSortableListState {
	selectedItem: string;
}

export class DragNDropSortableList extends React.PureComponent<DragNDropSortableListProps, DragNDropSortableListState> {
	constructor(props: DragNDropSortableListProps) {
		super(props);

		this.state = {
			selectedItem: ""
		};

		this.renderListItem = this.renderListItem.bind(this);
		this.removeItem = this.removeItem.bind(this);
		this.selectItem = this.selectItem.bind(this);
		this.sortData = this.sortData.bind(this);
		this.updateItem = this.updateItem.bind(this);
		this.updateSorts = this.updateSorts.bind(this);
	}

	render() {
		return (
			<div>
				<div style={{
					height: 148,
					width: "100%",
					backgroundColor: "#1e2027",
					border: "2px inset #23262e",
					overflowY: "auto"
				}}>
					{this.props.data.sort(this.sortData).map(this.renderListItem)}
					{this.renderDropZone()}
				</div>
			</div>
		);
	}

	renderDropZone() {
		return (
			<DragNDropSortableListItem
				onUploadSuccess={ this.props.onUploadSuccess }
				updateItem={ this.updateItem }
			/>
		);
	}

	renderListItem(item: DragNDropListItem, i: number) {
		return (
			<DragNDropSortableListItem
				isActive={ item.active }
				isSelected={this.state.selectedItem === item.uuid}
				item={item}
				key={item.name + item.uuid + item.sort + i}
				removeItem={this.removeItem(i)}
				selectItem={this.selectItem}
				updateItem={this.updateItem}
			/>
		);
	}

	removeItem(i: number) {
		return (itemToRemove) => {
			const item = this.props.data[i];
			const newData = [ ...this.props.data ];

			if (item.uuid === itemToRemove.uuid) {
				newData.splice(i, 1);

				this.props.updateData(newData.map(this.updateSorts), item.sort);
			}
		};
	}

	selectItem(item: DragNDropListItem) {
		this.setState(() => ({ selectedItem: item.uuid }));
	}

	updateSorts(itemToUpdate: DragNDropListItem, indexToUpdate: number) {
		return update(itemToUpdate, {
			[this.props.sortKey]: { $set: indexToUpdate }
		});
	}

	updateItem(beingDropped: DragNDropListItem | undefined, droppingOn: DragNDropListItem, hover: HoverState) {
		if (!beingDropped) {
			return;
		}

		const { data, updateData } = this.props;
		const withId = (uuid) => (i) => i.uuid === uuid;
		let index = data.findIndex(withId(beingDropped.uuid));
		let originalIndex = droppingOn && data.findIndex(withId(droppingOn.uuid));

		// if we're over a dropzone
		if (!(droppingOn && droppingOn.uuid)) {
			originalIndex = data.length;
		}

		// edge case: if you take an item and drag it down then back up over to its original position
		if (hover === "top" && index + 1 === originalIndex && originalIndex !== 0) {
			originalIndex -= 1;
		}

		// opposite of above edge case: if you drag an item up and then back down to its original position
		if (hover === "bottom" && index - 1 === originalIndex) {
			originalIndex += 1;
		}

		const newData = update(data, {
			$splice: [
				[ index, 1 ],
				[ originalIndex, 0, beingDropped ]
			]
		});

		updateData(newData.map(this.updateSorts))
	}

	sortData(a: DragNDropListItem, b: DragNDropListItem) {
		const sort = this.props.sortKey;
		return a[sort] - b[sort];
	}
}

export default DragNDropSortableList;

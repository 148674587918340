import { IMenuItem, SortTypes, StringArrayObject } from "@connect/Interfaces";
import { IconWeights } from "Components/Global/Icon";

export class MenuItem implements IMenuItem {
	constructor(name: string, path: string, icon: string, iconWeight?: IconWeights, badge?: number) {
		this.name = name;
		this.path = path;
		this.icon = icon;
		this.iconWeight = iconWeight;
		this.badge = badge;
	}

	name: string;
	path: string;
	icon: string;
	iconWeight?: IconWeights;
	badge?: number;

	static reconstruct(item: any | MenuItem) {
		if (item instanceof MenuItem) {
			return item;
		}

		return new MenuItem(
			item.name,
			item.path,
			item.icon,
			item.iconWeight || undefined,
			item.badge || undefined
		);
	}

	static setBadgeCount(item: MenuItem, badge: number) {
		return Object.assign({}, item, { badge });
	}
}

// constants

export const initialActiveFilterStates: StringArrayObject = {
	admin: [ "companies" ],
	ads: [
		"adsPanel",
		"playlistsPanel"
	],
	analytics: [
		"dateRange",
		{ devices: [] },
		{ sizes: [] },
		{ stores: [] }
	],
	deployDevices: [
		"model",
		"search",
		"store"
	],
	deployments: [ "type", "status" ],
	devices: [
		{ stores: [] },
		{ status: [] },
		{ sizes: [] },
		"group",
		"integrator"
	],
	healthDevices: [
		"model",
		"search",
		"store"
	],
	healthReports: [ "filter" ],
	media: [ "type" ]
};

export const initialActiveSearchStates = [
	"adminDevices",
	"companies",
	"deployments",
	"healthReports",
	"integrationCompanies",
	"managedCompanies",
	"playlists",
	"stores",
	"users"
];

export const initialActiveSelectionStates = [ "ads", "analytics", "companies", "deployDevices", "deployDevices_groups",
	"devices", "deviceGroups", "healthDevices", "healthDevices_groups", "healthReports", "media", "playlists",
	"stores", "users" ];

export const initialActiveSortStates = [
	{ actions: SortTypes.NEWEST_FIRST },
	{ ads: SortTypes.NEWEST_FIRST },
	{ analytics: SortTypes.NEWEST_FIRST },
	{ deployments: SortTypes.NEWEST_FIRST },
	{ devices: SortTypes.NEWEST_FIRST },
	{ healthReports: SortTypes.NEWEST_FIRST },
	{ media: SortTypes.NEWEST_FIRST },
	{ playlists: SortTypes.NEWEST_FIRST }
];

export const initialActiveTagStates = [ "media", "ads" ];

export const initialActiveUuidStates = [
	"ads",
	"analytics",
	"deploymentReport",
	"deploy",
	"devices",
	"deviceGroups",
	"health",
	"media",
	"playlists"
];

export const initialActiveViewStates = [ "media", "analytics" ];

export const initialAsyncStates = [
	"actions",
	"activity",
	"ads",
	"adminTeams",
	"allUsers",
	"analytics",
	"banners",
	"companies",
	"deployments",
	"deviceGroups",
	"devices",
	"deviceErrorLog",
	"healthReport",
	"managedCompanies",
	"media",
	"playlists",
	"stores",
	"teams",
	"users",
	"videos"
];

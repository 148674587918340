import { toggleFeature } from "@connect/Features";
import { AccordionDispatch, Action, ActiveDeselectDispatch, ActiveFilterArrayDispatch, ActiveFilterDispatch,
	ActiveSearchDispatch, ActiveSelectionDispatch, ActiveSortDispatch, ActiveTagDispatch, ActiveUuidDispatch,
	ActiveViewDispatch, AsyncFetchingDispatch, AsyncStateDispatch, DeviceError, DeviceStatusDispatch,
	GeneratedMenuDispatch, MediaType, NewAsyncStateDispatch, sortDataType, SpeedTestProgressArgs,
	UuidDispatch } from "@connect/Interfaces";
import { setMediaPreviewModalVisibility } from "Data/Actions/UI/Modals";
import { resetAdBuilder } from "Data/Actions/UI/AdBuilder";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import Perishable from "Data/Objects/Perishable";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { MenuItem } from "Data/Objects/UI";
import { getHealthReportsNavKey, getNumFinishedReports } from "Data/Selectors/UI";

const {
	ADD_ACTIVE_SELECTION,
	SET_ACTIVE_FILTERS,
	SET_ACTIVE_FILTER_ARRAY,
	SET_ACTIVE_SEARCH,
	SET_ACTIVE_SELECTION,
	REMOVE_ACTIVE_SELECTION,
	REMOVE_ACTIVE_SELECTION_ITEM,
	SET_ACTIVE_SORT,
	SET_ACTIVE_TAGS,
	SET_ACTIVE_UUID,
	SET_ACTIVE_VIEW,
	SET_ASYNC_FETCHING,
	SET_ASYNC_STATE,
	CREATE_ASYNC_STATE,
	ADD_RUNNING_REPORT,
	REMOVE_RUNNING_REPORT,
	ADD_REPORT_NOTIFICATION,
	REMOVE_REPORT_NOTIFICATION,
	SET_DEVICE_UPDATING_ADS,
	SET_DEVICE_REBOOTING,
	SET_DEVICE_SCREENSHOT_STATUS,
	SET_DEVICE_SCREENSHOT_TIME,
	SET_DEVICE_DATABASE_STATUS,
	SET_DEVICE_ERROR_LOG_STATUS,
	SET_PING_STATUS,
	SET_DEVICE_CLEAR_CACHE_STATUS,
	SET_DEVICE_SCREENSHOT_URL,
	SET_DEVICE_DB_URL,
	SET_DEVICE_ERRORS,
	SET_DEVICE_SNAPSHOT_STATUS,
	SET_DEVICE_LAST_SEEN,
	SET_DEVICE_SPEED_TEST_RESULT,
	SET_DEVICE_SOFTWARE_UPDATING,
	SET_LEFT_ACCORDION_EXPANDED,
	SET_COLLAPSE_EXPANDED,
	SET_MENU_ITEMS,
	UPDATE_MENU_BADGE,
	SET_MEDIA_PREVIEW,
	RESET_ACTIVE_FILTERS,
	RESET_ACTIVE_SEARCH,
	RESET_ACTIVE_SELECTION,
	RESET_ACTIVE_SORTS,
	RESET_ACTIVE_TAGS,
	RESET_ACTIVE_UUIDS,
	RESET_ACTIVE_VIEWS,
	RESET_ASYNC_STATES,
	RESET_DEVICE_HEALTH_MODAL_STATE,
	RESET_EXPANDED_DEVICE_GROUPS,
	RESET_FINISHED_REPORTS,
	RESET_RUNNING_REPORTS,
	SET_EXPANDED_DEVICE_GROUPS
} = ACTION_TYPES.UI;

export function addReportNotification(uuid: string): (dispatch: Function) => Promise<void> {
	return (dispatch) => Promise.resolve()
		.then(() => {
			dispatch({ type: ADD_REPORT_NOTIFICATION.type, args: { uuid } });
		})
		.then(() => {
			dispatch(updateMenuBadge());
		});
}

export function addRunningHealthReport(uuid: string): Action<UuidDispatch> {
	return { type: ADD_RUNNING_REPORT.type, args: { uuid }};
}

export function addToActiveSelection(path: string, selection: string[]): Action<ActiveSelectionDispatch> {
	return { type: ADD_ACTIVE_SELECTION.type, args: { path, selection } };
}

export function changeCompanyUIReset() {
	return (dispatch) => {
		dispatch(resetActiveFilters());
		dispatch(resetActiveSearch());
		dispatch(resetActiveSelection());
		dispatch(resetActiveSorts());
		dispatch(resetActiveTags());
		dispatch(resetActiveUuids());
		dispatch(resetActiveViews());
		dispatch(resetAdBuilder());
		dispatch(resetAsyncStates());
		dispatch(resetDeviceHealthModalState());
		dispatch(resetExpandedDeviceGroups());
		dispatch(updateMenuBadge(true));
		dispatch(resetFinishedReports());
		dispatch(resetRunningReports());
	}
}

export function removeReportNotification(uuid: string): (dispatch: Function) => Promise<void> {
	return (dispatch) => Promise.resolve()
		.then(() => {
			dispatch({ type: REMOVE_REPORT_NOTIFICATION.type, args: { uuid } });
		})
		.then(() => {
			dispatch(updateMenuBadge());
		});
}

export function removeRunningHealthReport(uuid: string): Action<UuidDispatch> {
	return { type: REMOVE_RUNNING_REPORT.type, args: { uuid }};
}

export function resetActiveFilters(): Action<null> {
	return { type: RESET_ACTIVE_FILTERS.type, args: null };
}

export function resetActiveSearch(): Action<null> {
	return { type: RESET_ACTIVE_SEARCH.type, args: null };
}

export function resetActiveSelection(): Action<null> {
	return { type: RESET_ACTIVE_SELECTION.type, args: null };
}

export function resetActiveSorts(): Action<null> {
	return { type: RESET_ACTIVE_SORTS.type, args: null };
}

export function resetActiveTags(): Action<null> {
	return { type: RESET_ACTIVE_TAGS.type, args: null };
}

export function resetActiveUuids(): Action<null> {
	return { type: RESET_ACTIVE_UUIDS.type, args: null };
}

export function resetActiveViews(): Action<null> {
	return { type: RESET_ACTIVE_VIEWS.type, args: null };
}

export function resetAsyncStates(): Action<null> {
	return { type: RESET_ASYNC_STATES.type, args: null };
}

export function resetExpandedDeviceGroups(): Action<null> {
	return { type: RESET_EXPANDED_DEVICE_GROUPS.type, args: null };
}

export function resetFinishedReports(): Action<null> {
	return { type: RESET_FINISHED_REPORTS.type, args: null };
}

export function resetRunningReports(): Action<null> {
	return { type: RESET_RUNNING_REPORTS.type, args: null };
}

export function setActiveFilters(filter: string, path: string, value: string): Action<ActiveFilterDispatch> {
	return { type: SET_ACTIVE_FILTERS.type, args: { filter, path, value }};
}

export function setActiveFilterArray(filter: string, path: string, value: string[]): Action<ActiveFilterArrayDispatch> {
	return { type: SET_ACTIVE_FILTER_ARRAY.type, args: { filter, path, value }};
}

export function setActiveSearch(path: string, search: string): Action<ActiveSearchDispatch> {
	return { type: SET_ACTIVE_SEARCH.type, args: { path, search } };
}

export function setExpandedDeviceGroups(groups: string[]) {
	return { type: SET_EXPANDED_DEVICE_GROUPS.type, args: { groups }};
}

export function setActiveSelection(path: string, selection: string[]): Action<ActiveSelectionDispatch> {
	return { type: SET_ACTIVE_SELECTION.type, args: { path, selection } };
}

export function removeActiveSelection(path: string, selection: string[]): Action<ActiveSelectionDispatch> {
	return { type: REMOVE_ACTIVE_SELECTION.type, args: { path, selection } };
}

export function removeActiveSelectionItem(path: string, uuid: string): Action<ActiveDeselectDispatch> {
	return { type: REMOVE_ACTIVE_SELECTION_ITEM.type, args: { path, uuid } };
}

export function setActiveSort(path: sortDataType, sort: string): Action<ActiveSortDispatch> {
	return { type: SET_ACTIVE_SORT.type, args: { path, sort } };
}

export function setActiveTags(path: string, tags: string[]): Action<ActiveTagDispatch> {
	return { type: SET_ACTIVE_TAGS.type, args: { path, tags } };
}

export function setActiveUuid(path: string, uuid: string): Action<ActiveUuidDispatch> {
	return { type: SET_ACTIVE_UUID.type, args: { path, uuid } };
}

export function setActiveView(path: string, view: string): Action<ActiveViewDispatch> {
	return { type: SET_ACTIVE_VIEW.type, args: { path, view } };
}

export function setAsyncFetching(key: string, value: boolean, companyId?: string): Action<AsyncFetchingDispatch> {
	return { type: SET_ASYNC_FETCHING.type, args: {
		companyId, key, value
	}};
}

export function setAsyncState(key: string, haveAll: boolean, currentPage: number): Action<AsyncStateDispatch> {
	return { type: SET_ASYNC_STATE.type, args: {
		currentPage, haveAll, key
	}};
}

export function createAsyncState(key: string): Action<NewAsyncStateDispatch> {
	return { type: CREATE_ASYNC_STATE.type, args: { key } };
}

export function resetDeviceHealthModalState(): Action<null> {
	return { type: RESET_DEVICE_HEALTH_MODAL_STATE.type, args: null };
}

export function setDeviceClearCacheStatus(deviceUUID: string, status: boolean): Action<DeviceStatusDispatch> {
	return {
		type: SET_DEVICE_CLEAR_CACHE_STATUS.type,
		args: {
			deviceUUID,
			status: new Perishable<boolean>(status)
		}
	}
}

export function setDeviceDatabaseStatus(deviceUUID: string, status: boolean): Action<DeviceStatusDispatch> {
	return {
		type: SET_DEVICE_DATABASE_STATUS.type,
		args: {
			deviceUUID,
			status: new Perishable<boolean>(status)
		}
	}
}

export function setDeviceErrorLogStatus(deviceUUID: string, status: boolean): Action<DeviceStatusDispatch> {
	return {
		type: SET_DEVICE_ERROR_LOG_STATUS.type,
		args: {
			deviceUUID,
			status: new Perishable<boolean>(status)
		}
	}
}

export function setPingStatus(deviceUUID: string, status: boolean): Action<DeviceStatusDispatch> {
	return {
		type: SET_PING_STATUS.type,
		args: {
			deviceUUID,
			status: new Perishable<boolean>(status)
		}
	}
}

export function setDeviceRebooting(deviceUUID: string, status: boolean): Action<DeviceStatusDispatch> {
	return {
		type: SET_DEVICE_REBOOTING.type,
		args: {
			deviceUUID,
			status: new Perishable<boolean>(status)
		}
	}
}

export function setDeviceScreenshotStatus(deviceUUID: string, status: boolean): Action<DeviceStatusDispatch> {
	return {
		type: SET_DEVICE_SCREENSHOT_STATUS.type,
		args: {
			deviceUUID,
			status: new Perishable<boolean>(status)
		}
	}
}

export function setDeviceUpdatingAds(deviceUUID: string, status: boolean): Action<DeviceStatusDispatch> {
	return {
		type: SET_DEVICE_UPDATING_ADS.type,
		args: {
			deviceUUID,
			status: new Perishable<boolean>(status)
		}
	}
}

export function setDeviceScreenshotURL(deviceUUID: string, url: string) {
	return {
		type: SET_DEVICE_SCREENSHOT_URL.type,
		args: {
			deviceUUID,
			url
		}
	}
}

export function setDeviceScreenshotTime(deviceUUID: string, time: string) {
	return {
		type: SET_DEVICE_SCREENSHOT_TIME.type,
		args: {
			deviceUUID,
			time
		}
	}
}

export function setDeviceDBURL(deviceUUID: string, url: string) {
	return {
		type: SET_DEVICE_DB_URL.type,
		args: {
			deviceUUID,
			url
		}
	}
}

export function setDeviceErrors(deviceUUID: string, errors: DeviceError[], reset: boolean) {
	return {
		type: SET_DEVICE_ERRORS.type,
		args: {
			deviceUUID,
			errors,
			reset
		}
	}
}

export function setDeviceSnapshotStatus(deviceUUID: string, status: boolean) {
	return {
		type: SET_DEVICE_SNAPSHOT_STATUS.type,
		args: {
			deviceUUID,
			status: new Perishable<boolean>(status)
		}
	}
}

export function setDeviceLastSeen(deviceUUID: string, lastSeen: string) {
	return {
		type: SET_DEVICE_LAST_SEEN.type,
		args: {
			deviceUUID,
			lastSeen
		}
	}
}

export function setDeviceSpeedTestResult(deviceUUID: string, result: SpeedTestProgressArgs) {
	return {
		type: SET_DEVICE_SPEED_TEST_RESULT.type,
		args: {
			deviceUUID,
			result
		}
	}
}

export function setDeviceSoftwareUpdating(deviceUUID: string, status: boolean) {
	return {
		type: SET_DEVICE_SOFTWARE_UPDATING.type,
		args: {
			deviceUUID,
			status: new Perishable<boolean>(status)
		}
	}
}

export function setLeftAccordionExpandedState(
	accordionIndex: string,
	expandedState: string[]
): Action<AccordionDispatch> {
	return {
		type: SET_LEFT_ACCORDION_EXPANDED.type,
		args: {
			key: accordionIndex,
			value: expandedState
		}
	};
}

export function setCollapseExpandedState(
	collapseIndex: string,
	expandedState: string[]
): Action<AccordionDispatch> {
	return {
		type: SET_COLLAPSE_EXPANDED.type,
		args: {
			key: collapseIndex,
			value: expandedState
		}
	};
}

export function setMediaPreviewInfo(uri: string, vimeoId: number, type: MediaType) {
	return { type: SET_MEDIA_PREVIEW.type, args: { uri, vimeoId, type } };
}

export function setMediaPreview(uri: string, vimeoId: number, type: MediaType) {
	const visible = !!(uri || Number.parseInt(String(vimeoId), 10) > -1);

	return (dispatch) => {
		dispatch(setMediaPreviewInfo(uri, vimeoId, type));
		dispatch(setMediaPreviewModalVisibility(visible));
	}
}

export function setMenuItems(): Action<GeneratedMenuDispatch> {
	const adminLabel = hasPermission(PERMISSIONS.OWN_COMPANIES_EDIT) ? "Admin" : "Account";
	let menu = [
		new MenuItem("Home", "/", "clinton")
	];

	if (hasPermission(PERMISSIONS.MEDIA_MANAGE)) {
		menu.push(new MenuItem("Media", "/media", "image"));
	}

	if (hasPermission(PERMISSIONS.ADS_MANAGE)) {
		menu.push(new MenuItem("Ads", "/ads", "ad"));
	}

	if (hasPermission(PERMISSIONS.PLAYLISTS_MANAGE)) {
		menu.push(new MenuItem("Playlists", "/playlists", "th-list"));
	}

	if (hasPermission(PERMISSIONS.ACTIONS_MANAGE)) {
		menu.push(new MenuItem("Actions", "/actions", "running"))
	}

	if (hasPermission(PERMISSIONS.DEPLOYMENTS_MANAGE)) {
		menu.push(new MenuItem("Deploy", "/deploy", "rocket"));
	}

	if (hasPermission(PERMISSIONS.OWN_DEVICES_MANAGE)
		|| hasPermission(PERMISSIONS.DEVICES_MANAGE)) {
		menu.push(new MenuItem("Devices", "/devices", "mobile-alt", "solid"));
	}

	if (hasPermission(PERMISSIONS.DEVICE_HEALTH_VIEW)) {
		menu.push(new MenuItem("Health", "/health", "medkit", "solid"));
	}

	const analyticsPage = toggleFeature("ad-analytics",
		new MenuItem("Analytics", "/analytics", "analytics", "solid"),
		null
	);

	if (analyticsPage) {
		menu.push(analyticsPage);
	}

	menu.push(new MenuItem(adminLabel, "/admin", "user", "solid"));

	return {
		type: SET_MENU_ITEMS.type,
		args: { menu }
	};
}

export function updateMenuBadge(reset?: boolean): (dispatch: Function, getState: Function) => void {
	return (dispatch, getState) => {
		const currentState = getState();
		const badge = reset ? 0 : getNumFinishedReports(currentState);
		const path = getHealthReportsNavKey(currentState);

		dispatch({
			type: UPDATE_MENU_BADGE.type,
			args: {
				badge,
				path
			}
		});
	};
}
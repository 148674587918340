import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { HealthReport, NameUuid } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import GridView, { IGridViewProps } from "Components/Global/GridView";
import CreateHealthReport from "Components/Health/CreateHealthReportButton";
import HealthReportCard from "Components/Health/HealthReportCard";
import { copyReportAsync, deleteReportAsync, updateReportAsync } from "Data/Actions/HealthReportAsync";
import { addToActiveSelection, removeActiveSelection, setActiveSelection } from "Data/Actions/UI";
import { getActiveSelection, getActiveUuid } from "Data/Selectors/UI";

const mapStateToProps = (state, ownProps) => ({
	activeUuid: getActiveUuid(state, "healthReports"),
	activeSelection: getActiveSelection(state, "healthReports")
});

const mapDispatchToProps = (dispatch) => ({
	addToSelected: (ids: string[]) => dispatch(addToActiveSelection("healthReports", ids)),
	deleteReport: (uuid: string) => dispatch(deleteReportAsync(uuid)),
	copyReport: (report: HealthReport) => dispatch(copyReportAsync(report)),
	removeFromSelected: (ids: string[]) => dispatch(removeActiveSelection("healthReports", ids)),
	setActive: (id: string) => dispatch(push(`/health/${id}`)),
	setSelectedDevices: (uuids: string[]) => dispatch(setActiveSelection("healthDevices", uuids)),
	setSelectedGroups: (uuids: string[]) => dispatch(setActiveSelection("healthDevices_groups", uuids)),
	updateReport: (report: HealthReport) => dispatch(updateReportAsync(report))
});

interface IHealthGridProps extends IGridViewProps {
	content: HealthReport[];
	copyReport: (report: HealthReport) => void;
	deleteReport: (uuid: string) => void;
	onCreateReport: () => void;
	setSelectedDevices: (uuids: string[]) => void;
	setSelectedGroups: (uuids: string[]) => void;
	updateReport: (report: HealthReport) => void;
}

class HealthGrid extends GridView<IHealthGridProps> {
	constructor(props: IHealthGridProps) {
		super(props);

		this.handleCopyReport = this.handleCopyReport.bind(this);
		this.handleDeleteReport = this.handleDeleteReport.bind(this);
		this.handleToggleGlobal = this.handleToggleGlobal.bind(this);
		this.renderReportCard = this.renderReportCard.bind(this);
	}

	render() {
		const cards = [
			this.renderCreateHealthReport(),
			...this.props.content.map(this.renderReportCard)
		];

		return this.renderContainer(cards);
	}

	renderCreateHealthReport() {
		const { onCreateReport, selectModeOn } = this.props;

		if (selectModeOn) {
			return null;
		}

		return <CreateHealthReport key="create_health_report" onCreateReport={ onCreateReport } />
	}

	renderReportCard(report: HealthReport, index: number) {
		const { selectModeOn } = this.props;
		const { uuid } = report;
		const selected = this.isSelected(uuid);

		return (
			<HealthReportCard
				bulkSelectActive={ selectModeOn }
				cardObject={ report }
				cardType="healthReport"
				key={ `${ uuid }_health_card_${ index }` }
				isSelected={ selected }
				onCardSelection={ this.handleCardSelection(report, selected) }
				actionButtons={[
					{ callback: this.handleToggleGlobal, name: "globe" },
					{ callback: this.handleCopyReport, name: "copy" },
					{ callback: this.handleDeleteReport, name: "trash-alt" }
				]}
			/>
		);
	}

	handleCardSelection(card: NameUuid, isSelected: boolean): (bulkAndShift: boolean) => void {
		return (bulkAndShift: boolean) => {
			const { content, selectModeOn, setSelectedDevices, setSelectedGroups } = this.props;

			if (!selectModeOn) {
				const { devices, deviceGroups } = content.find(({ uuid }) => uuid === card.uuid)
					|| { devices: [], deviceGroups: [] };
				const mapUuidToObject = (obj: string | NameUuid) => typeof obj === "string" ? obj : obj.uuid;
				const stepDevices = devices && devices.length
					? (devices as any[]).map(mapUuidToObject)
					: [];
				const stepDeviceGroups = deviceGroups && deviceGroups.length
					? (deviceGroups as any[]).map(mapUuidToObject)
					: [];

				setSelectedDevices(stepDevices);
				setSelectedGroups(stepDeviceGroups);
			}

			return super.handleCardSelection(card, isSelected)(bulkAndShift);
		};
	}

	handleCopyReport(report: HealthReport) {
		this.props.copyReport(report);
	}

	handleDeleteReport(report: HealthReport) {
		const { name, uuid } = report;

		Notifications.confirm(
			"Delete Health Report",
			`Are you sure you want to delete "${ name }"?`,
			"Yes",
			"No",
			() => this.props.deleteReport(uuid)
		);
	}

	handleToggleGlobal(report: HealthReport) {
		this.props.updateReport(update(report, {
			global: { $set: !report.global }
		}));
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthGrid);

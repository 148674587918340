import * as update from "immutability-helper";

import { HealthReportColumnsMap } from "@connect/Interfaces";

// these components exist on both UI and server
// https://clintonelectronics.atlassian.net/wiki/spaces/CON/pages/48791605/Device+Health+Reports
export const healthReportComponents = [
	"device.group",
	"device.install_date",
	"device.model",
	"device.name",
	"device.serial",
	"device.snapshot",
	"device.software_version",
	"device.store",
	"status.available_memory_usage",
	"status.available_storage",
	"status.camera_on",
	"status.cpu_temp",
	"status.display_on",
	"status.heap_memory_usage",
	"status.ip",
	"status.last_deployment_failed",
	"status.last_schedule_download",
	"status.led_status",
	"status.online",
	"status.pusher_connected",
	"status.timestamp",
	"status.uptime",
	"status.wifi_network_name"
];

// server components contain extra information, and do not map 1-1 with UI components
export const serverReportComponents = [
	...healthReportComponents,
	"device.snapshot_timestamp", // no UI equivalent
	"status.ethernet_connected", // combined with wifi_connected in UI
	"status.wifi_connected" // combined with ethernet_connected in UI
];

// UI components have an extra component type and combine several of the server components
export const uiReportComponents = [
	...healthReportComponents,
	"status.connection_type" // Ethernet || WiFi || Disconnected
];

export const HealthReportColumns: HealthReportColumnsMap = {
	"device.group": { name: "Device Group", url: "deviceGroup" },
	"device.install_date": { name: "Install Date", url: "installDate" },
	"device.model": { name: "Model #", url: "deviceModel" },
	"device.name": { name: "Name", url: "deviceName",
		help: `This is the assigned name of the device.
		Clicking on the device name will bring up the Device Overview modal.`, visibleComponent: false },
	"device.serial": { name: "Serial #", url: "deviceSerial" },
	"device.snapshot": { name: "Snapshot", url: "deviceSnapshot",
		serverComponents: [ "device.snapshot", "device.snapshot_timestamp" ] },
	"device.software_version": { name: "Software Version", url: "softwareVersion" },
	"device.store": { name: "Store", url: "store" },
	"status.available_memory_usage": { name: "Available Memory", url: "availableMemory",
		help: "This is the amount of memory that the device is using." },
	"status.available_storage": { name: "Available Storage", url: "availableStorage",
		help: "This is the amount of disk space available." },
	"status.camera_on": { name: "Camera Status", url: "cameraOn",
		help: "This is the last reported status of the internal camera." },
	"status.connection_type": { name: "Connection Type", url: "connectionType",
		serverComponents: [ "status.ethernet_connected", "status.wifi_connected" ],
		help: "This is the last reported connection type used on the device." },
	"status.cpu_temp": { name: "CPU Temp", url: "cpuTemp",
		help: `An Orange or Red status indicates the CPU is running too hot.
		A service call may be needed to inspect the device further.` },
	"status.display_on": { name: "Display Status", url: "displayOn",
		help: "This is the last reported status of the LCD." },
	"status.heap_memory_usage": { name: "Heap Memory", url: "heapMemory",
		help: "This is the amount of memory that the Connect PVM application is using." },
	"status.ip": { name: "IP Address", url: "ipAddress",
		help: "This is the IP Address assigned to this device by the router." },
	"status.last_deployment_failed": { name: "Last Deployment Status", url: "lastDeploymentFailed",
		help: "This is the status of the last attempted content download of the device." },
	"status.last_schedule_download": { name: "Last Successful Download", url: "lastScheduleDownload",
		help: "This is the last time this device successfully downloaded its assigned deployment." },
	"status.led_status": { name: "LED Status", url: "ledStatus",
		help: "The LED status indicator is used to alert you to any issues that may be present on your device." },
	"status.online": { name: "Connection Status", url: "deviceOnline",
		help: `This is the last reported network status of the device. If you suspect a device is offline,
		click on the device name to bring up the Device Overview modal for real-time data.` },
	"status.pusher_connected": { name: "Pusher Status", url: "pusherConnected",
		help: "This is the status of the device's connection to the real-time notification server." },
	"status.timestamp": { name: "Status", url: "statusTimestamp",
		help: `This is the status of the information in this report. The indicator is used to alert
		you to any data that may be in question or old.`, visibleComponent: false},
	"status.uptime": { name: "Last Reboot", url: "deviceUptime",
		help: "This is the time since the last reboot." },
	"status.wifi_network_name": { name: "WiFi Network", url: "wifiNetwork",
		help: "This is the name of the WiFi network to which the device is connected." }
};

export const HealthReportSorts = Object.keys(HealthReportColumns)
	.reduce((sortsObject, key) => Object.assign({}, sortsObject, {
		[HealthReportColumns[key].url]: key
	}), {});

export const ReportComponentsList = uiReportComponents
	.map((item) => item.split("."))
	.reduce((obj, [ section, prop ]) => {
		if (!obj[section]) {
			obj[section] = { components: [] };
		}

		const key = [ section, prop ].join(".");
		const title = HealthReportColumns[key].name;

		return update(obj, {
			[section]: {
				components: { $push: [ {
					key,
					title
				} ] }
			}
		});
	}, {});

export function getServerColumns(columns: string[]) {
	return columns.reduce((cols: string[], next: string) => {
		if (next === "device.snapshot") {
			cols.push(next, "device.snapshot_timestamp");
		} else if (next === "status.connection_type") {
			cols.push("status.ethernet_connected", "status.wifi_connected");
		} else {
			cols.push(next);
		}

		return cols;
	}, []);
}

export function getUIColumns(columns: string[]) {
	return columns.reduce((cols: string[], next: string) => {
		const hasConnection = cols.includes("status.connection_type");

		if (next === "device.snapshot_timestamp") {
			return cols;
		} else if (next === "status.ethernet_connected" || next === "status.wifi_connected") {
			if (hasConnection) {
				return cols;
			}
			cols.push("status.connection_type");
		} else {
			cols.push(next);
		}

		return cols;
	}, []);
}
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, ICompany, Team, IUser, NameUuid } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Button, Link, Loader, Select } from "Components/Global/Common";
import { getCompanyAdminInfoAsync } from "Data/Actions/Company";
import { getIntegratorListAsync } from "Data/Actions/Team";
import { getActiveCompany, getCompany, getUserIntegrators } from "Data/Selectors/Company";
import { getTeam, getTeams } from "Data/Selectors/Team";
import { getCurrentUser } from "Data/Selectors/User";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";

const mapStateToProps = (state, ownProps) => {
	const { companyId, integratorId } = ownProps;
	const activeCompany = getActiveCompany(state);
	const company = companyId
		? getCompany(state, companyId)
		: activeCompany;
	const integrator = integratorId ? getTeam(state, integratorId) : null;
	const userIntegrators = getUserIntegrators(state);
	const user = getCurrentUser(state);

	return {
		activeCompany,
		company,
		hashid: company && company.hashid,
		integrator,
		integrators: getTeams(state),
		user,
		userIntegrators
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCompanyDetails: (uuid: string) => dispatch(getCompanyAdminInfoAsync(uuid)),
	getIntegrators: () => dispatch(getIntegratorListAsync())
});

interface AdminQRCodeProps {
	activeCompany: ICompany;
	company: ICompany;
	companyId?: string;
	getCompanyDetails: (uuid: string) => Promise<void>;
	getIntegrators: () => void;
	hashid: string;
	integratorId?: string;
	integrator: Team;
	integrators: Team[];
	showIntegratorSelect?: boolean;
	simple?: boolean;
	style?: CustomCSS;
	userIntegrators: ICompany[];
	user: IUser;
}

interface AdminQRCodeState {
	integrator: Team | ICompany;
	qr: string;
}

export class AdminQRCode extends React.Component<AdminQRCodeProps, AdminQRCodeState> {
	constructor(props: AdminQRCodeProps) {
		super(props);

		this.state = {
			integrator: {} as Team,
			qr: ""
		};

		this.styles = {
			container: {
				width: "40%"
			},
			content: {
				padding: "5px 0",
				width: "100%"
			},
			loader: {
				alignItems: "center",
				display: "flex",
				justifyContent: "center",
				minHeight: 140,
				width: "100%"
			}
		};

		this.getIntegrator = this.getIntegrator.bind(this);
		this.getQRLink = this.getQRLink.bind(this);
		this.printQRCode = this.printQRCode.bind(this);
		this.renderLink = this.renderLink.bind(this);
		this.setIntegrator = this.setIntegrator.bind(this);
		this.setQr = this.setQr.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		const { company, hashid, getCompanyDetails, getIntegrators,
			showIntegratorSelect, user, integrators } = this.props;
		const canGetIntegrators = hasPermission(PERMISSIONS.VIEW_COMPANY_INTEGRATIONS, user);

		if (!company) {
			return;
		}

		if (hashid) {
			this.setQr();
		} else {
			getCompanyDetails(company.uuid);
		}

		if (!integrators.length && showIntegratorSelect && canGetIntegrators) {
			getIntegrators();
		}
	}

	componentDidUpdate(prevProps: AdminQRCodeProps) {
		if (this.props.hashid !== prevProps.hashid) {
			this.setQr();
		}
	}

	render() {
		if (this.props.simple) {
			return this.renderLink();
		}

		const { container, content } = this.styles;
		const containerStyle = Object.assign({}, container, this.props.style);

		return (
			<div style={ containerStyle }>
				{ this.renderQR() }
				{ this.renderIntegratorSelection() }
				<Button
					icon="print"
					iconWeight="regular"
					onClick={ this.printQRCode }
					style={ content }
				>
					Print
				</Button>
			</div>
		);
	}

	renderIntegratorOption(integrator: Team | ICompany, i: number) {
		const { name, uuid } = integrator;


		return (
			<Select.Option
				value={ uuid }
				key={ uuid + i }
			>
				{ name }
			</Select.Option>
		);
	}

	renderIntegratorSelection() {
		const { company, integrators, showIntegratorSelect, user, userIntegrators } = this.props;
		const { integrator } = this.state;

		if (!showIntegratorSelect) {
			return null;
		}

		let integratorOptions: NameUuid[] =
			hasPermission("own.companies.integrations.view", user) ?
				integrators : userIntegrators;

		const integratorId = integrator && integrator.uuid;
		const selectIntegratorValue = integratorId ? company.name : "Select Integrator";

		return (
			<Select
				defaultValue={ "" }
				style={ this.styles.content }
				onChange={ this.setIntegrator }
				placeholder="Select Integrator"
				value={ integratorId }
			>
				<Select.Option value={ "" } key="unset">
					{ selectIntegratorValue }
				</Select.Option>
				<Select.Option disabled value="separator" key="separator">
				----------
				</Select.Option>
				{ integratorOptions.map(this.renderIntegratorOption) }
			</Select>
		);
	}

	renderLink() {
		return (
			<Link to={ this.getQRLink() } target="_blank" rel="noopener noreferrer">View/Print</Link>
		);
	}

	renderQR() {
		const { content, loader } = this.styles;

		if (!this.state.qr) {
			return (
				<div style={{ ...content, ...loader }}>
					<Loader />
				</div>
			);
		}

		return (
			<img src={ this.state.qr } style={ content } />
		);
	}

	getIntegrator() {
		const { integrator, integratorId } = this.props;
		return integratorId ? integrator : this.state.integrator;
	}

	getQRLink() {
		const { company } = this.props;
		const integrator = this.getIntegrator();
		let url = "/devices/" + company.uuid + "/instructions";

		if (integrator) {
			url += `/${integrator.uuid}`;
		}

		return url;
	}

	printQRCode() {
		const url = window.location.origin + this.getQRLink();

		window.open(url, "_blank", "noopener noreferrer");
	}

	// we only need to hold the integrator locally -- the integrator select is only used on the My Account page
	setIntegrator(uuid: string) {
		const integrator1 = this.props.integrators.find((team) => team.uuid === uuid) as Team;
		const integrator2 = this.props.userIntegrators.find((c) => c.uuid === uuid);

		this.setState(() => ({
			integrator: integrator1 || integrator2
		}), this.setQr);
	}

	setQr() {
		const { activeCompany, hashid } = this.props;
		const integrator = this.getIntegrator();

		let integratorHash = integrator && integrator.hashid;
		if (activeCompany.teamType === "integrator" && activeCompany.hashid !== hashid) {
			integratorHash = activeCompany.hashid;
		}

		this.setState(() => {
			return {
				qr: Utils.generateQrCode(hashid, integratorHash)
			};
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminQRCode);

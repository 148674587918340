import { cloneDeep } from "lodash";
import * as moment from "moment";

import ActionsApi from "@connect/Api/Actions";
import { notifyBugSnagAsync } from "@connect/BugSnag";
import { ActionSet, ActionSetsResult, ActionType, BulkDeleteError, BulkDeleteResult, generateDefaultAction, SortTypes,
	Trigger, TriggeredAction, TriggerType, Sorts, TriggerDwell, TriggerBoxing,
	NetworkActionAuthTypes } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { createActionSet, deleteActionSet, resetActionSets, setActionSets,
	updateActionSet } from "Data/Actions/Actions";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { setAsyncFetching, setAsyncState } from "Data/Actions/UI";
import { CacheInvalidationPeriod } from "Data/Objects/Global";
import { getSelectedActionType, getSelectedTriggerIndex } from "Data/Selectors/ActionBuilder";
import { getActiveActionSet } from "Data/Selectors/Actions";
import { getActiveSorts } from "Data/Selectors/UI";
import { getState } from "@connect/Data";
import { getCurrentUser } from "Data/Selectors/User";

function createActionSetAsync(action?: Partial<ActionSet>) {
	const api = new ActionsApi();

	return (dispatch, getState) => {
		const state = getState();
		const currentUser = getCurrentUser(state)
		const { name, uuid } = currentUser;
		const actionSet = { ...action, createdBy: { name, uuid } };

		return api.createActionSet(actionSet)
			.then((actionSet: ActionSet) => {
				dispatch(successNotification(`Created ${ actionSet.name }`));
				dispatch(createActionSet(actionSet));
				return actionSet;
			}, (error) => {
				dispatch(errorNotification("Error creating action set.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function deleteActionSetAsync(uuid: string) {
	const api = new ActionsApi();

	return (dispatch) => {
		return api.deleteActionSet(uuid)
			.then((response: Response) => {
				dispatch(successNotification("Action set successfully deleted."));
				dispatch(deleteActionSet(uuid));
			}, (error) => {
				dispatch(errorNotification("Error deleting action set.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function deleteActionSetsAsync(actionSets: ActionSet[]) {
	const api = new ActionsApi();
	const uuids = actionSets.map((actionSet) => actionSet.uuid);
	let updateUuids = [ ...uuids ];

	return (dispatch) => {
		const generateError = (e: BulkDeleteError) => {
			const [ actionSet ] = actionSets.filter((set) => set.uuid === e.uuid);
			dispatch(errorNotification(`Error deleting action set "${ actionSet.name }"`, e.message));
		}

		return api.deleteActionSets(uuids)
			.then((result: BulkDeleteResult) => {
				const { error } = result;

				// handle errors
				if (result && error && error.length) {
					error.map((e: BulkDeleteError) => {
						const index = uuids.findIndex((id) => id === e.uuid);
						updateUuids.splice(index, 1);
						generateError(e);
					});
				}

				// delete our remaining actionSets from redux and dispatch a success notification
				updateUuids.forEach((uuid) => dispatch(deleteActionSet(uuid)));
				dispatch(successNotification(`${ updateUuids.length } action sets successfully deleted!`))
			}, (errors) => {
				if (errors && errors.length) {
					errors.map((e: BulkDeleteError) => generateError(e));
				}
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function getActionSetAsync(uuid: string) {
	const api = new ActionsApi();

	return (dispatch) => {
		return api.getActionSet(uuid)
			.then((actionSet: ActionSet) => {
				dispatch(updateActionSet(actionSet));
			}, (error) => {
				dispatch(errorNotification("Error getting action set.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function tryFetchActionsAsync() {
	const api = new ActionsApi();

	return (dispatch, getState) => {
		const state = getState();
		const { Company: { activeCompanyId }, UI: { asyncState } } = state;
		const { currentPage, currentlyFetching, lastFetch, haveAllData, lastFetchedCompany } = asyncState.actions;
		const changedCompanies = activeCompanyId !== lastFetchedCompany;
		const expired = moment().diff(lastFetch, "minute") > CacheInvalidationPeriod;
		const shouldInvalidateCache = changedCompanies || expired;
		const dontNeedToFetch = currentlyFetching || (haveAllData && !changedCompanies);

		if (shouldInvalidateCache) {
			dispatch(resetActionSets());
		} else if (dontNeedToFetch) {
			return Promise.resolve();
		}

		dispatch(setAsyncFetching("actions", true, activeCompanyId));

		const sortType = getActiveSorts(state, Sorts.ACTIONS) as SortTypes;
		const page = shouldInvalidateCache ? 1 : currentPage;
		const perPage = 20;

		let apiSort = Utils.getApiSort({ sortType });

		return api.getActionSets(perPage, page, apiSort)
			.then((result: ActionSetsResult) => {
				dispatch(setActionSets(result.data, shouldInvalidateCache));
				dispatch(setAsyncState(
					"actions",
					!result.links.next,
					result.meta.current_page + 1
				));
				dispatch(setAsyncFetching("actions", false));
			}, (error) => {
				dispatch(errorNotification("Error getting action sets.", error));
				dispatch(setAsyncFetching("actions", false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function updateActionSetAsync(actionSet: ActionSet) {
	const api = new ActionsApi();

	return (dispatch) => {
		const oldActionSet = getActiveActionSet(getState());
		const updatedActionSet = { ...actionSet, updatedAt: moment().format() };

		dispatch(updateActionSet(updatedActionSet))

		return api.updateActionSet(updatedActionSet)
			.then((updatedActionSet: ActionSet) => {

			}, (error) => {
				dispatch(errorNotification("Error updating action set.", error));
				dispatch(updateActionSet(oldActionSet));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
				dispatch(updateActionSet(oldActionSet));
			});
	}
}

function addTriggerAsync(triggerType: TriggerType) {
	return (dispatch, getState) => {
		const state = getState();
		const activeActionSet = getActiveActionSet(state);
		const alreadyExists = findTrigger(triggerType, activeActionSet.data);

		const defaultTrigger = {
			duration: 10,
			throttle: 10,
			actions: []
		};

		const defaultDwell = {
			enabled: false,
			duration: 5
		};

		const defaultBoxing = {
			enabled: false,
			color: "#00ff00"
		}

		if (alreadyExists && triggerType !== "network_request") {
			return;
		}

		let updatedActionSet = cloneDeep(activeActionSet);

		let newTrigger: Trigger = {
			...defaultTrigger,
			type: triggerType
		}

		if (triggerType === "face" || triggerType === "person") {
			newTrigger = {
				...newTrigger,
				dwell: defaultDwell,
				boxing: defaultBoxing
			}
		} else if (triggerType === "motion") {
			newTrigger = {
				...newTrigger,
				dwell: defaultDwell
			}
		} else if (triggerType === "network_request") {
			let isRepeat = false;
			let count = -1;
			let parameter;

			do {
				count++;
				parameter = `default-${ count }`;
				isRepeat = !!activeActionSet.data.find((trigger: Trigger) => {
					return trigger.type === "network_request" && trigger.parameter === parameter;
				});
			} while (isRepeat);

			newTrigger = {
				...newTrigger,
				parameter
			}
		}

		updatedActionSet.data.push(newTrigger);
		dispatch(updateActionSetAsync(updatedActionSet));
	}
}

function addActionAsync(triggerIndex: number, actionType: ActionType) {
	return (dispatch, getState) => {
		const state = getState();
		const activeActionSet = getActiveActionSet(state);
		const targetTrigger = activeActionSet.data[triggerIndex];
		if (!targetTrigger) {
			return;
		}
		const alreadyExists = targetTrigger.actions.find((a: TriggeredAction) => {
			if (actionType === "ad" || actionType === "camera") { // Is our new action ad or camera
				return a.type === "ad" || a.type === "camera"; // If so, make sure we don't already have ad or camera
			}
			return a.type === actionType;
		});
		if (alreadyExists) {
			return;
		}
		let updatedActionSet = cloneDeep(activeActionSet);
		updatedActionSet.data[triggerIndex].actions.push(generateDefaultAction(actionType));
		dispatch(updateActionSetAsync(updatedActionSet));
	}
}

function deleteActionAsync(triggerIndex: number, actionType: ActionType) {
	return (dispatch, getState) => {
		const state = getState();
		const activeActionSet = getActiveActionSet(state);
		const targetTrigger = activeActionSet.data[triggerIndex];
		if (!targetTrigger) {
			return;
		}
		const alreadyExists = targetTrigger.actions.find((a: TriggeredAction) => {
			return a.type === actionType;
		});
		if (!alreadyExists) {
			return;
		}
		const updatedActionSet = cloneDeep(activeActionSet);

		updatedActionSet.data[triggerIndex].actions =
			updatedActionSet.data[triggerIndex].actions.filter((a: TriggeredAction) => {
				return a.type !== actionType;
			});

		dispatch(updateActionSetAsync(updatedActionSet));
	}
}

function deleteTriggerAsync(triggerIndex: number) {
	return (dispatch, getState) => {
		const state = getState();
		const activeActionSet = cloneDeep(getActiveActionSet(state));
		const alreadyExists = activeActionSet.data[triggerIndex];
		if (!alreadyExists) {
			return;
		}
		let updatedActionSet = cloneDeep(activeActionSet);
		updatedActionSet.data.splice(triggerIndex, 1);
		dispatch(updateActionSetAsync(updatedActionSet));
	}
}

function updateSelectedTriggerProperty<PropertyType>(propertyKey: string) {
	return (propertyValue: PropertyType) => {
		return (dispatch, getState) => {
			const state = getState();
			let updatedActionSet = cloneDeep(getActiveActionSet(state));
			const triggerIndex = getSelectedTriggerIndex(state);
			updatedActionSet.data[triggerIndex][propertyKey] = propertyValue;
			return dispatch(updateActionSetAsync(updatedActionSet));
		}
	}
}

const updateSelectedTriggerParameter = updateSelectedTriggerProperty<string>("parameter");
const updateSelectedTriggerBoxing = updateSelectedTriggerProperty<TriggerBoxing>("boxing");
const updateSelectedTriggerDwell = updateSelectedTriggerProperty<TriggerDwell>("dwell");
const updateSelectedTriggerThreshold = updateSelectedTriggerProperty<number>("throttle");
const updateSelectedTriggerDuration = updateSelectedTriggerProperty<number>("duration");
const updateSelectedTriggerContinuous = updateSelectedTriggerProperty<boolean>("continuous");

function updateSelectedActionVolume(volume: number) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType) {
				a.audioLevel = volume;
			}
			return a;
		});

		dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionAd(adUuid: string, duration: number) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].duration = duration;
		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType) {
				a.adUuid = adUuid;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionFlashing(flashing: boolean) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType) {
				a.flashing = flashing;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionPreset(preset: number) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType) {
				a.preset = preset;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionURI(uri: string) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType) {
				a.uri = uri;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionMethod(method: "get" | "post") {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType) {
				a.method = method;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

const defaultAuthConfigs = {
	[ NetworkActionAuthTypes.NONE ]: {},
	[ NetworkActionAuthTypes.BASIC ]: {
		username: "",
		password: ""
	},
	[ NetworkActionAuthTypes.DIGEST ]: {
		username: "",
		password: ""
	},
	[ NetworkActionAuthTypes.BEARER ]: {
		token: ""
	},
	[ NetworkActionAuthTypes.HEADER ]: {
		name: "",
		value: ""
	}
}

function updateSelectedActionAuthType(authType: NetworkActionAuthTypes) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType) {
				a.auth = {
					type: authType,
					...defaultAuthConfigs[authType]
				}
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionAuthName(name: string) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType && a.auth) {
				a.auth.name = name;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionAuthUsername(username: string) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType && a.auth) {
				a.auth.username = username;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionAuthPassword(password: string) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType && a.auth) {
				a.auth.password = password;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionAuthToken(token: string) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType && a.auth) {
				a.auth.token = token;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function updateSelectedActionAuthValue(value: string) {
	return (dispatch, getState) => {
		const state = getState();
		const updatedActionSet = cloneDeep(getActiveActionSet(state));
		const triggerIndex = getSelectedTriggerIndex(state);
		const actionType = getSelectedActionType(state);

		updatedActionSet.data[triggerIndex].actions.map((a: TriggeredAction) => {
			if (a.type === actionType && a.auth) {
				a.auth.value = value;
			}
			return a;
		});

		return dispatch(updateActionSetAsync(updatedActionSet))
	};
}

function findTrigger(needle: TriggerType, haystack: Trigger[]) {
	if (!haystack || haystack.length === 0) {
		return null;
	}

	return haystack.find((t: Trigger) => {
		return t.type === needle;
	});
}

export {
	addActionAsync,
	addTriggerAsync,
	deleteActionAsync,
	deleteTriggerAsync,
	createActionSetAsync,
	deleteActionSetAsync,
	deleteActionSetsAsync,
	getActionSetAsync,
	tryFetchActionsAsync,
	updateActionSetAsync,
	updateSelectedTriggerDuration,
	updateSelectedTriggerThreshold,
	updateSelectedTriggerDwell,
	updateSelectedTriggerBoxing,
	updateSelectedTriggerContinuous,
	updateSelectedTriggerParameter,
	updateSelectedActionVolume,
	updateSelectedActionAd,
	updateSelectedActionFlashing,
	updateSelectedActionPreset,
	updateSelectedActionURI,
	updateSelectedActionMethod,
	updateSelectedActionAuthType,
	updateSelectedActionAuthName,
	updateSelectedActionAuthUsername,
	updateSelectedActionAuthPassword,
	updateSelectedActionAuthToken,
	updateSelectedActionAuthValue
}
import * as React from "react";
import { connect } from "react-redux";

import { ActionType, CustomCSS, MAX_ACTIONS, Trigger, TriggeredAction } from "@connect/Interfaces";
import ActionButton from "Components/Actions/ActionButton";
import NewActionDropzone from "Components/Actions/NewActionDropzone";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import SideScroller from "Components/Global/SideScroller";
import { deleteActionAsync, deleteTriggerAsync } from "Data/Actions/ActionsAsync";
import { setSelectedActionComponent } from "Data/Actions/UI/ActionSetBuilder";
import { getSelectedActionType, getSelectedTriggerIndex } from "Data/Selectors/ActionBuilder";

const { lightGray, offWhite, white, threeGray } = Colors;

interface ActionSetCardProps {
	trigger: Trigger;
	triggerIndex: number;
	selectedActionType: ActionType;
	selectedTriggerIndex: number;
	deleteAction: (triggerIndex: number, actionType: ActionType) => void;
	deleteTrigger: (triggerIndex: number) => void;
	saveAction: () => void;
	selectComponent: (triggerIndex: number, actionType: ActionType) => void;
}

interface ActionSetCardState {
	hovered: boolean;
}

const mapStateToProps = (state, ownProps) => {
	return {
		selectedActionType: getSelectedActionType(state),
		selectedTriggerIndex: getSelectedTriggerIndex(state)
	};
};

const mapDispatchToProps = (dispatch) => ({
	deleteAction: (triggerIndex: number, actionType: ActionType) =>
		dispatch(deleteActionAsync(triggerIndex, actionType)),
	deleteTrigger: (triggerIndex: number) => dispatch(deleteTriggerAsync(triggerIndex)),
	selectComponent: (triggerIndex: number, actionType: ActionType) =>
		dispatch(setSelectedActionComponent(triggerIndex, actionType))
});

export class ActionSetCard extends React.PureComponent<ActionSetCardProps, ActionSetCardState> {
	constructor(props: ActionSetCardProps) {
		super(props);

		this.state = {
			hovered: false
		}

		const baseHoverButton = {
			alignItems: "center",
			background: lightGray,
			color: white,
			cursor: "pointer",
			display: "flex",
			height: 25,
			justifyContent: "center",
			paddingLeft: 2,
			position: "absolute",
			textShadow: `0 1px 2px ${ threeGray }`,
			width: 25,
			zIndex: 110
		};

		this.styles = {
			deleteButton: {
				...baseHoverButton,
				top: 0,
				right: 1
			},
			editButton: {
				...baseHoverButton,
				top: 0,
				right: 31
			},
			flex: {
				display: "flex",
				height: "100%"
			},
			heading: {
				marginLeft: 8,
				marginTop: 8,
				fontSize: "1.1em",
				fontWeight: 700,
				position: "absolute"
			},
			ifStyle: {
				display: "flex",
				alignItems: "center",
				height: 160
			},
			item: {
				border: "none",
				marginTop: 16
			},
			outer: {
				position: "relative",
				boxShadow: `0px 2px 10px ${ lightGray }`,
				border: `1px solid ${ white }`,
				borderRadius: 6,
				minHeight: 161,
				padding: "10px 0px 10px 15px",
				justifySelf: "center",
				display: "inline-flex",
				width: "85%",
				margin: "10px 0px 10px 7.5%"
			},
			separator: {
				border: `1px solid ${ offWhite }`,
				margin: "30px 0px 20px"
			}
		};

		this.renderActions = this.renderActions.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<div
				onMouseEnter={ this.handleHoverChange(true) }
				onMouseLeave={ this.handleHoverChange(false) }
				onClick={ this.handleSelectComponent(-1, "") }
				style={ this.styles.outer }
				key={ String(this.props.trigger && this.props.trigger.type && this.props.triggerIndex) }
			>
				{ this.renderHoverOptions() }
				{ this.renderIf() }
				{ this.renderSeparator() }
				{ this.renderThen() }
			</div>
		);
	}

	renderHoverOptions() {
		const { triggerIndex } = this.props;
		const { hovered } = this.state;
		const { deleteButton, editButton } = this.styles;

		if (!hovered) {
			return null;
		}

		return (
			<React.Fragment>
				<div
					key="delete"
					style={ deleteButton }
					onClick={ this.handleDeleteTrigger(triggerIndex) }>
					<Icon name="trash-alt" iconWeight="regular" />
				</div>
				<div
					key="edit"
					style={ editButton }
					onClick={ this.handleSelectComponent(triggerIndex, "") }>
					<Icon name="cog" iconWeight="regular" />
				</div>
			</React.Fragment>
		);
	}

	renderIf() {
		const { selectedTriggerIndex, triggerIndex } = this.props;
		const { heading, ifStyle } = this.styles;
		const selected = this.checkSelected(selectedTriggerIndex, "");

		return (
			<div>
				<div style={ heading }>If</div>
				<div style={ ifStyle }>
					<ActionButton
						key={ `${ selected }` }
						selected={ selected }
						trigger
						onClick={ this.handleSelectComponent(triggerIndex, "") }
						type={ this.props.trigger.type } />
				</div>
			</div>
		);
	}

	renderSeparator() {
		return <div style={ this.styles.separator } />;
	}

	renderThen() {
		const { heading, item, flex } = this.styles;
		const { trigger, triggerIndex } = this.props;
		const { actions, type } = trigger;

		const actionsExist = trigger && actions;
		const showDropzone = !(actionsExist && actions.length === MAX_ACTIONS);

		return (
			<div style={ this.getThenStyle(showDropzone) }>
				<div style={ heading }>Then</div>
				<div style={ flex } key={ actions.length }>
					{ showDropzone && <NewActionDropzone type={ type } triggerIndex={ triggerIndex } /> }
					<SideScroller
						align="left"
						id={ type as string }
						itemStyles={ item }
					>
						{ trigger && actions.map(this.renderActions) }
					</SideScroller>
				</div>
			</div>
		);
	}

	renderActions(action: TriggeredAction) {
		const { selectedTriggerIndex, triggerIndex } = this.props;
		const { type } = action;
		const selected = this.checkSelected(selectedTriggerIndex, type);

		return (
			<ActionButton
				key={ `${ type }-${ selected }` }
				selected={ selected }
				type={ type }
				onClick={ this.handleSelectComponent(triggerIndex, type) }
				onDelete={ this.handleDeleteAction(triggerIndex, type) } />
		);
	}

	checkSelected(selectedTriggerIndex: number, actionType: ActionType) {
		const { selectedActionType, triggerIndex } = this.props;
		return (selectedActionType === actionType) && (selectedTriggerIndex === triggerIndex);
	}

	getThenStyle(withDropzone: boolean) {
		const widthDiff = withDropzone ? 234 : 110;

		return {
			width: "100%",
			maxWidth: `calc(100% - ${ widthDiff }px`,
			height: 160
		}
	}

	handleDeleteTrigger(triggerIndex: number) {
		const { deleteTrigger } = this.props;

		return () => {
			if (deleteTrigger) {
				deleteTrigger(triggerIndex);
			}
		}
	}

	handleSelectComponent(triggerIndex: number, actionType: ActionType) {
		const { selectComponent } = this.props;
		return (event: React.SyntheticEvent<HTMLDivElement>) => {
			if (selectComponent) {
				selectComponent(triggerIndex, actionType);
				event.stopPropagation();
			}
		}
	}

	handleDeleteAction(triggerIndex: number, actionType: ActionType) {
		const { deleteAction } = this.props;
		return () => {
			if (deleteAction) {
				deleteAction(triggerIndex, actionType);
			}
		}
	}

	handleHoverChange(value: boolean) {
		return () => {
			this.setState({
				hovered: value
			})
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionSetCard);
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, IAd, SortTypes, ViewTypes, Filters, Sorts } from "@connect/Interfaces";
import AdCard from "Components/Ads/AdCard";
import DraggableAdRow from "Components/Ads/DraggableAdRow";
import { Input, Loader } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import EmptyList from "Components/Global/EmptyList";
import { setAdsAsync } from "Data/Actions/AdsAsync";
import { setActiveFilters, setActiveSort, setActiveUuid } from "Data/Actions/UI";
import { getFilteredSortedAds } from "Data/Selectors/Ads";
import { getActiveFilters, getActiveSorts, getActiveUuid, makeHaveAllAds, makeLastAdsPage } from "Data/Selectors/UI";

type Context = "adsPanel" | "playlistsPanel";

const { darkerGray, filters: { hoverAlt } } = Colors;

const mapDispatchToProps = (dispatch, ownProps: IAdsListProps) => ({
	setSort: (sort: SortTypes) => dispatch(setActiveSort(Sorts.ADS, sort)),
	setActiveAdUuid: (ad: IAd) => dispatch(setActiveUuid("ads", ad.uuid)),
	setAdsList: () => dispatch(setAdsAsync()),
	setAdsFilter: (filter: string) => dispatch(setActiveFilters(ownProps.context, Filters.ADS, filter))
});

const mapStateToProps = (state, ownProps: IAdsListProps) => {
	const { context } = ownProps;
	const getMakeLastAdsPage = makeLastAdsPage();
	const getMakeHaveAllAds = makeHaveAllAds();
	const sort = getActiveSorts(state, Sorts.ADS);
	const filter = getActiveFilters(state, Filters.ADS)[context];

	return {
		ads: getFilteredSortedAds(state, {
			sort: sort as SortTypes,
			filter: filter as string
		}),
		activeUuid: getActiveUuid(state, "ads"),
		lastPageFetched: getMakeLastAdsPage(state),
		haveAllAds: getMakeHaveAllAds(state),
		adsSortType: sort,
		adFilter: filter
	}
};

interface IAdsListProps {
	ads: IAd[];
	activeUuid: string;
	adFilter: string;
	context: Context;
	onAdClicked?: (ad: IAd) => void;
	onAdFilterChanged?: (value: string) => void;
	adsViewType: ViewTypes;
	adsSortType: SortTypes;
	setAdsList: () => void;
	setAdsFilter: (filter: string) => void;
	setActiveAdUuid: (ad: IAd) => void;
	lastPageFetched: number;
	haveAllAds: boolean;
}

export class AdsList extends React.Component<IAdsListProps> {

	constructor(props: IAdsListProps) {
		super(props);

		this.styles = {
			filter: {
				height: 48,
				background: darkerGray,
				display: "block",
				width: "calc(100% - 12px)",
				padding: "8px 0px",
				margin: "0px 6px",
				position: "sticky",
				top: 0,
				zIndex: 100
			},
			adGrid: {
				display: "grid",
				gridTemplateColumns: "50% 50%",
				marginBottom: 30
			},
			adList: {
				maxWidth: "100%",
				overflowX: "hidden",
				cursor: this.isAdsPage ? "pointer" : "move",
				transition: "background 200ms"
			},
			adRow: {
				cursor: this.isAdsPage ? "pointer" : "move",
				borderRadius: 5,
				transition: "background 200ms"
			},
			waypoint: {
				textAlign: "center",
				width: "100%"
			}
		}

		this.getFilteredAds = this.getFilteredAds.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handlePaging = this.handlePaging.bind(this);
		this.setActiveAd = this.setActiveAd.bind(this);
	}
	// currently this component is used on the Ads page and
	// on the Playlists page. On the Ads page, ads need to be
	// selectable, but not draggable. On the Playlists page
	// they need to be draggable but not selectable.
	// there shouldn't be an onAdClicked prop provided for
	// the Playlists page, so we can use that to determine
	// which page we're on and apply the required functionality
	// this logic might change though, if this component gets used elsewhere!

	isAdsPage = !!this.props.onAdClicked;

	styles: {
		filter: CustomCSS;
		adGrid: CustomCSS;
		adList: CustomCSS;
		adRow: CustomCSS;
		waypoint: CustomCSS;
	};

	render() {
		const { adsViewType } = this.props;
		const view = adsViewType === ViewTypes.GRID ? this.renderGridView() : this.renderListView();

		return (
			<React.Fragment>
				{ this.renderAdsFilter() }
				{ view }
			</React.Fragment>
		);
	}

	renderAdsFilter() {
		return (
			<div style={ this.styles.filter }>
				<Input
					hideMaxLength
					color="dark"
					id="adsListFilter"
					placeholder="Filter - My Ads"
					updateCallback={ this.handleFilterChange }
					value={ this.props.adFilter }
				/>
			</div>
		)
	}

	renderGridView() {
		const { adGrid } = this.styles;

		return (
			<div style={ adGrid }>
				{ this.renderGrid() }
				{ this.renderEmptyList() }
				{ this.renderWaypoint() }
			</div>
		);
	}

	renderGrid() {
		const { lastPageFetched, ads } = this.props;
		const { adRow } = this.styles;

		if (!(ads.length || lastPageFetched !== 0)) {
			return null;
		}

		return ads
			.filter(this.getFilteredAds)
			.map((ad, index) => {
				return (
					<div
						key={ad.uuid}
						style={{
							...adRow,
							background: this.adIsSelected(ad) ? hoverAlt : "none"
						}}
						onClick={ this.setActiveAd(ad) }
					>
						<AdCard
							adListCard={true}
							cardObject={ad}
							cardType="ad"
							isDraggable={!this.isAdsPage}
						/>
					</div>
				);
			});
	}

	renderListView() {
		return (
			<React.Fragment>
				{ this.renderList() }
				{ this.renderEmptyList() }
				{ this.renderWaypoint() }
			</React.Fragment>
		);
	}

	renderList() {
		const { ads } = this.props;

		if (!ads.length) {
			return null;
		}

		return ads
			.filter(this.getFilteredAds)
			.map((ad, index) => {
				return (
					<div key={ad.uuid}
						style={{
							...this.styles.adList,
							background: this.adIsSelected(ad) ? hoverAlt : "none"
						}}
						onClick={ this.setActiveAd(ad) }
					>
						<DraggableAdRow
							ad={ad}
							draggable={!this.isAdsPage}
							isSelected={ this.adIsSelected(ad) }
						/>
					</div>
				);
			});
	}

	renderWaypoint() {
		const { lastPageFetched, haveAllAds } = this.props;

		return (
			<div
				key={ lastPageFetched }
				style={{
					...this.styles.waypoint,
					display: (!haveAllAds) ? "inline-block" : "none"
				}}
			>
				<Waypoint onEnter={ this.handlePaging } />
				<Loader size="small" />
			</div>
		);
	}

	renderEmptyList() {
		if (this.props.ads) {
			return null;
		}

		return <EmptyList type="ads" />;
	}

	handlePaging() {
		const { haveAllAds, setAdsList } = this.props;

		if (!haveAllAds) {
			setAdsList();
		}
	}

	adIsSelected(ad: IAd) {
		return this.props.activeUuid === ad.uuid;
	}

	getFilteredAds(ad: IAd) {
		const { adFilter } = this.props;
		return !adFilter || ad.name.toLowerCase().indexOf(adFilter.toLowerCase()) > -1
	}

	handleFilterChange(val: string) {
		const { onAdFilterChanged, setAdsFilter } = this.props;

		if (onAdFilterChanged) {
			onAdFilterChanged(val);
		}

		setAdsFilter(val);
	}

	setActiveAd(ad: IAd) {
		const { onAdClicked, setActiveAdUuid } = this.props;

		return () => {
			setActiveAdUuid(ad);

			if (onAdClicked) {
				onAdClicked(ad);
			}
		};
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsList);

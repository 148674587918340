import Radium from "radium";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { CustomCSS, IDevice, IDeviceGroup, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import DeviceGroupCard from "Components/Devices/DeviceGroupCard";
import { Colors } from "Components/Global/Constants";
import GridView, { IGridViewProps } from "Components/Global/GridView";
import Icon from "Components/Global/Icon";
import { assignDevicesAndGroupsAsync } from "Data/Actions/Devices";
import { replaceWithoutRender } from "Data/Actions/Navigation";
import { addToActiveSelection, removeActiveSelection, setActiveFilters, setActiveUuid } from "Data/Actions/UI";
import { DeviceGroup } from "Data/Objects/Devices";
import { getDeviceGroups, getFilteredSortedSearchedDevices } from "Data/Selectors/Devices";
import { getActiveFilters, getActiveSelection, getActiveSorts, getActiveUuid } from "Data/Selectors/UI";

const { lightGray, offWhite, primaryGreen, white } = Colors;

const mapStateToProps = (state) => {
	const deviceGroups = getDeviceGroups(state);
	const filters = getActiveFilters(state, Filters.DEVICES);
	const sortType = getActiveSorts(state, Sorts.DEVICES);
	const { filter, sizes, status, stores } = filters;

	return {
		activeUuid: getActiveUuid(state, "deviceGroups"),
		activeSelection: getActiveSelection(state, "deviceGroups"),
		selectedDevices: getActiveSelection(state, "devices"),
		devices: getFilteredSortedSearchedDevices(state, {
			filter: filter as string,
			sizes: sizes as string[],
			status: status as string[],
			stores: stores as string[],
			sort: sortType as SortTypes
		}),
		deviceGroups
	}
};

const mapDispatchToProps = (dispatch) => ({
	addToSelected: (ids: string[]) => dispatch(addToActiveSelection("deviceGroups", ids)),
	assignGroup: (devices: IDevice[], groups: IDeviceGroup[], target: IDeviceGroup) =>
		dispatch(assignDevicesAndGroupsAsync(devices, groups, target)),
	pushToDevicesPage: () => dispatch(push("/devices")),
	removeFromSelected: (ids: string[]) => dispatch(removeActiveSelection("deviceGroups", ids)),
	selectGroup: (groupId: string) => dispatch(setActiveFilters("group", Filters.DEVICES, groupId)),
	setActive: (id: string) => dispatch(setActiveUuid("deviceGroups", id)),
	setActiveDevice: (uuid: string) => dispatch(replaceWithoutRender(`/devices/${ uuid }`))
});

interface IDeviceGroupsGridProps extends IGridViewProps {
	assignGroup: (devices: IDevice[], groups: IDeviceGroup[], target: IDeviceGroup) => void;
	createNewDeviceGroup: () => void;
	deviceGroups: IDeviceGroup[];
	devices: IDevice[];
	pushToDevicesPage: () => void;
	selectedDevices: string[];
	selectGroup: (groupId: string) => void;
	setActiveDevice: (uuid: string) => void;
}

@Radium
export class DeviceGroupsGrid extends GridView<IDeviceGroupsGridProps> {
	constructor(props: IDeviceGroupsGridProps) {
		super(props);

		this.styles = Object.assign(this.styles, {
			createButton: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				color: lightGray,
				cursor: "pointer",
				userSelect: "none",
				marginTop: 2,
				width: 200,
				height: 50,
				background: offWhite,
				borderRadius: 4,
				":hover": {
					background: primaryGreen,
					color: white
				}
			},
			createText: {
				marginLeft: 6
			}
		});

		this.renderDeviceGroup = this.renderDeviceGroup.bind(this);
		this.handleCardSelection = this.handleCardSelection.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.onDoubleClick = this.onDoubleClick.bind(this);
	}

	styles: CustomCSS;

	render() {
		const cards = [
			this.renderCreateDeviceGroupButton(),
			...(this.props.content || []).map(this.renderDeviceGroup)
		];

		return this.renderContainer(cards);
	}

	renderDeviceGroup(d: IDeviceGroup, i: number) {
		const { selectModeOn, selectedDevices, activeSelection } = this.props;
		const { name, uuid } = d;
		const selected = this.isSelected(uuid);

		return (
			<DeviceGroupCard
				noAutoScroll
				key={ name + "_" + uuid + "_" + i }
				bulkSelectActive={ selectModeOn }
				cardObject={ d }
				cardType="deviceGroup"
				isFocused={ selected && !selectModeOn }
				isSelected={ selected }
				onCardSelection={ this.handleCardSelection(d, selected) }
				onDoubleClick={ this.onDoubleClick(d) }
				isDraggable={ true }
				isDroppable={ true }
				onDrop={ this.handleDrop }
				selectedDevicesCount={ selectedDevices.length }
				selectedGroupCount={ (activeSelection || []).length }
			/>
		);
	}

	renderCreateDeviceGroupButton() {
		const { createButton, createText } = this.styles;
		const { createNewDeviceGroup, selectModeOn } = this.props;

		if (selectModeOn) {
			return null;
		}

		return (
			<div
				key="create_device_group_button"
				style={ createButton }
				onClick={ createNewDeviceGroup }
			>
				<Icon name="plus-circle" size="small" />
				<span style={ createText }>New Group</span>
			</div>
		);
	}

	handleCardSelection(deviceGroup: IDeviceGroup, isSelected: boolean) {
		return (bulkAndShift: boolean) => {
			const { selectModeOn, setActiveDevice } = this.props;

			if (!selectModeOn) {
				setActiveDevice("");
			}

			return super.handleCardSelection(deviceGroup, isSelected)(bulkAndShift);
		};
	}

	handleDrop(dropReceiver: { cardObject: IDeviceGroup, dragItem: IDevice | IDeviceGroup }) {
		const { devices, selectedDevices,
			deviceGroups, activeSelection, assignGroup, selectModeOn } = this.props;
		const { cardObject, dragItem } = dropReceiver;
		let currentDevices: IDevice[] = [];
		let currentGroups: IDeviceGroup[] = [];

		if (!selectModeOn) {
			if (DeviceGroup.isDeviceGroup(dragItem as any)) {
				currentGroups.push(dragItem as IDeviceGroup);
			} else {
				currentDevices.push(dragItem as IDevice);
			}
		} else {
			currentDevices = devices.filter((device) => selectedDevices.includes(device.uuid));
			currentGroups = deviceGroups.filter((group) => (activeSelection || []).includes(group.uuid));
		}

		assignGroup(currentDevices, currentGroups, cardObject);
	}

	onDoubleClick(deviceGroup: IDeviceGroup) {
		return () => {
			const { selectModeOn, selectGroup, pushToDevicesPage } = this.props;

			if (!selectModeOn) {
				pushToDevicesPage();
				selectGroup(deviceGroup.uuid);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceGroupsGrid);
import { createSelector } from "reselect";

import { NameUuid, Team } from "@connect/Interfaces";
import { CompanyState } from "Data/Objects/AppState";
import { getCompanyState } from "Data/Selectors/Company";
import { identityReducer } from "Data/Utils";

export function getIntegratorTeam(teams: Team[], id: string) {
	return teams.find((team) => team.uuid === id);
}

export function getAdminIntegratorTeams(state: CompanyState) {
	return state.adminTeams;
}

export function getIntegratorTeams(state: CompanyState) {
	return state.teams;
}

export function getIntegratorTeamOptions(teams: Team[]): NameUuid[] {
	return teams.map(({ name, uuid }) => ({ name, uuid }));
}

export const getTeams = createSelector(
	[ getCompanyState ],
	getIntegratorTeams
);

export const getAdminTeams = createSelector(
	[ getCompanyState ],
	getAdminIntegratorTeams
);

export const getTeam = createSelector(
	[ getTeams, identityReducer ],
	getIntegratorTeam
);

export const getTeamOptions = createSelector(
	[ getTeams ],
	getIntegratorTeamOptions
);

import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, DeviceError } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Modal, Table } from "Components/Global/Common";
import { setDeviceErrorLogModal } from "Data/Actions/UI/Modals";
import { AppState } from "Data/Objects/AppState";
import { getDeviceErrorLogModalVisibility, getDeviceHealthModalUUID } from "Data/Selectors/UI";
import { getDeviceHealthModalDeviceState } from "Data/Selectors/UI";
import Waypoint from "react-waypoint";
import { fetchDeviceErrorLogsAsync } from "Data/Actions/UIAsync";

const mapStateToProps = (state: AppState, ownProps: DeviceErrorLogModalProps) => {
	const selectedUUID = getDeviceHealthModalUUID(state);
	const { deviceErrors } = getDeviceHealthModalDeviceState(state, selectedUUID);

	return {
		errors: deviceErrors,
		visible: getDeviceErrorLogModalVisibility(state)
	};
}

const mapDispatchToProps = (dispatch, ownProps: DeviceErrorLogModalProps) => {
	return {
		hideModal: () => dispatch(setDeviceErrorLogModal(false)),
		fetchDeviceErrorLogs: () => dispatch(fetchDeviceErrorLogsAsync())}
};

interface DeviceErrorLogModalProps {
	selectedUUID: string;
	errors: DeviceError[];
	hideModal: () => void;
	fetchDeviceErrorLogs: () => any;
	visible: boolean;
}

class DeviceErrorLogModal extends React.Component<DeviceErrorLogModalProps> {
	constructor(props: DeviceErrorLogModalProps) {
		super(props);

		this.styles = {
			container: {
				height: "60vh",
				overflowY: "auto"
			},
			waypoint: {
				height: 40,
				marginTop: 40,
				width: "100%"
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { visible, errors, hideModal, selectedUUID } = this.props;

		return (
			<Modal
				zIndex={ 20000 }
				modalKey="deviceErrorLogModal"
				key={ selectedUUID + errors.length }
				width={"80vw"}
				visible={visible}
				onCancel={hideModal}>
				<div style={ this.styles.container }>
					<Table
						size="small"
						rowKey={(record: DeviceError) => record.uuid}
						dataSource={ errors }
						locale={{emptyText: "No errors to report"}}
						pagination={ false }
						columns={[
							{
								title: "Title",
								dataIndex: "title",
								sorter: Utils.columnSorter("title"),
								className: "device-error-log-table-column"
							},
							{
								title: "Occurred At",
								dataIndex: "occurredAt",
								sorter: Utils.columnSorter("occurredAt"),
								render: (text) => Utils.getHumanReadableDate(text),
								className: "device-error-log-table-column",
								defaultSortOrder: "ascend"
							},
							{
								title: "Message",
								dataIndex: "message",
								sorter: Utils.columnSorter("message"),
								className: "device-error-log-table-column"
							}
						]}
					/>
					{ this.renderWaypoint() }
				</div>
			</Modal>
		);
	}

	renderWaypoint() {
		const { waypoint } = this.styles;
		const { errors, fetchDeviceErrorLogs  } = this.props;

		return (
			<div style= { waypoint }>
				<Waypoint
					onEnter={ fetchDeviceErrorLogs }
					key={ errors.length }/>
			</div>
		);
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceErrorLogModal);
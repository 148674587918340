import * as React from "react";
import { connect } from "react-redux";

import { ActionSet, Context } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import ActionCard from "Components/Actions/ActionCard";
import CreateActionButton from "Components/Actions/CreateActionButton";
import GridView, { IGridViewProps } from "Components/Global/GridView";
import { createActionSetAsync, deleteActionSetAsync } from "Data/Actions/ActionsAsync";
import { replaceWithoutRender } from "Data/Actions/Navigation";
import { addToActiveSelection, removeActiveSelection } from "Data/Actions/UI";
import { getActiveSelection, getActiveUuid } from "Data/Selectors/UI";
import { cloneDeep } from "lodash";

interface IActionsGridProps extends IGridViewProps {
	content: ActionSet[];
	context: Context;
	copyAction: (action: ActionSet) => Promise<any>;
	deleteAction: (uuid: string) => void;
	onActionSelected?: (action: ActionSet) => void;
	setActive: (id: string) => void;
}

const mapDispatchToProps = (dispatch) => ({
	addToSelected: (ids: string[]) => dispatch(addToActiveSelection("actions", ids)),
	copyAction: (action: ActionSet) => dispatch(createActionSetAsync(action)),
	deleteAction: (uuid: string) => dispatch(deleteActionSetAsync(uuid)),
	removeFromSelected: (ids: string[]) => dispatch(removeActiveSelection("actions", ids)),
	setActive: (id: string) => dispatch(replaceWithoutRender(`/actions/${id}`))
});

const mapStateToProps = (state) => ({
	activeUuid: getActiveUuid(state, "actions"),
	activeSelection: getActiveSelection(state, "actions")
});

class ActionsGrid extends GridView<IActionsGridProps> {
	constructor(props: IActionsGridProps) {
		super(props);

		this.copyAction = this.copyAction.bind(this);
		this.deleteAction = this.deleteAction.bind(this);
		this.handleCardSelection = this.handleCardSelection.bind(this);
		this.renderCreateAction = this.renderCreateAction.bind(this);
		this.renderActionCard = this.renderActionCard.bind(this);
	}

	render() {
		const cards = [
			this.renderCreateAction(),
			...this.props.content.map(this.renderActionCard)
		];

		return this.renderContainer(cards);
	}

	renderCreateAction() {
		const { selectModeOn } = this.props;

		if (selectModeOn) {
			return undefined;
		}

		return <CreateActionButton key="create_action_button" />;
	}

	renderActionCard(action: ActionSet, index: number) {
		const { selectModeOn } = this.props;
		const { uuid } = action;
		const isSelected = this.isSelected(uuid);

		return (
			<ActionCard
				key={ `${ uuid }_ad_card_${ index }` }
				cardObject={ action }
				cardType="action"
				isSelected={ isSelected }
				bulkSelectActive={ selectModeOn }
				onCardSelection={ this.handleCardSelection(action, isSelected) }
				actionButtons={[
					{ callback: this.copyAction, name: "copy" },
					{ callback: this.deleteAction, name: "trash-alt" }
				]}
			/>
		);
	}

	copyAction(cardObject: ActionSet) {
		const { copyAction, setActive } = this.props;
		let newAction = cloneDeep(cardObject);

		newAction.uuid = "";
		newAction.name = Utils.copyName(newAction.name);

		copyAction(newAction)
			.then((actionCopy) => setActive(actionCopy.uuid));
	}

	deleteAction(cardObject: ActionSet) {
		Notifications.confirm("Delete selected action?",
			"Are you sure you want to delete the selected action? " +
			"The action will be removed from any deployments they may have been part of.",
			"Delete", "Cancel", () => {
				this.props.deleteAction(cardObject.uuid);
			});
	}

	handleCardSelection(action: ActionSet, isSelected: boolean) {
		return (bulkAndShift: boolean) => {
			const { context, onActionSelected, selectModeOn } = this.props;

			if (onActionSelected && context !== Context.PAGE && !selectModeOn) {
				return onActionSelected(action)
			}

			return super.handleCardSelection(action, isSelected)(bulkAndShift);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsGrid);

import * as React from "react";
import { connect } from "react-redux"

import { OptionProps } from "antd/lib/select";
import { Select } from "Components/Global/Common";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { CustomCSS, ICompany, IDevice, Team, NameUuid } from "@connect/Interfaces";
import { updateDeviceAsync } from "Data/Actions/Devices";
import { getTeams, getAdminTeams } from "Data/Selectors/Team";
import { getDeviceById } from "Data/Selectors/Devices";
import { getAdminIntegratorListAsync, getIntegratorListAsync } from "Data/Actions/Team";

const { Option } = Select;

const mapDispatchToProps = (dispatch) => {
	return {
		updateDevice: (device: IDevice) => dispatch(updateDeviceAsync(device)),
		getAdminIntegrators: () => dispatch(getAdminIntegratorListAsync()),
		getIntegrators: () => dispatch(getIntegratorListAsync())
	}
};

const mapStateToProps = (state, ownProps) => {
	const  { uuid } = ownProps;
	const device = getDeviceById(state, uuid);
	const adminIntegrators = getAdminTeams(state);
	const integrators = getTeams(state);

	return {
		device,
		uuid,
		adminIntegrators,
		integrators
	}
}

interface IntegratorDropdownProps {
	adminIntegrators?: NameUuid[];
	device: IDevice;
	getAdminIntegrators: () => void;
	getIntegrators: () => void;
	integrators: Team[];
	updateDevice: (device: IDevice) => void;
	uuid: string;
}

const styles = {
	leftColumn: {
		display: "inline-block",
		width: 100
	},
	row: {
		display: "flex",
		marginTop: 10,
		marginBottom: 10
	},
	dropdown: {
		width: "100%"
	}
} as CustomCSS;

export class IntegratorDropdown extends React.Component<IntegratorDropdownProps> {
	constructor(props: IntegratorDropdownProps) {
		super(props);

		this.integratorPickerFilter = this.integratorPickerFilter.bind(this);
		this.renderAdminIntegratorPicker = this.renderAdminIntegratorPicker.bind(this);
		this.updateIntegrator = this.updateIntegrator.bind(this);

	}

	componentDidMount() {
		const canManageDevices = hasPermission(PERMISSIONS.DEVICES_MANAGE);
		const canViewIntegrators = hasPermission(PERMISSIONS.VIEW_COMPANY_INTEGRATIONS);
		const canSelectIntegrator = canManageDevices && canViewIntegrators;

		const { getAdminIntegrators, getIntegrators } = this.props;

		if (canSelectIntegrator) {
			return getAdminIntegrators();
		} else if (canViewIntegrators) {
			return getIntegrators();
		}
	}

	render() {
		const canSelectIntegrator = hasPermission(PERMISSIONS.DEVICES_MANAGE);
		if (canSelectIntegrator) {
			return this.renderAdminIntegratorPicker()
		} else {
			return this.renderIntegratorStatic()
		}
	}

	renderAdminIntegratorPicker() {
		const { device, adminIntegrators } = this.props;

		if (!adminIntegrators) {
			return null;
		}
		return (
			<div style={ styles.dropdown }>
				<Select
					filterOption={ this.integratorPickerFilter }
					showSearch
					key={ device.uuid }
					onSelect={ this.updateIntegrator }
					size="small"
					value={ device.integrator }
					style={ styles.dropdown }
				>
					<Option
						title=""
						key="no_integrator_selected"
						value= ""
					>
							No integrator selected
					</Option>
					{ adminIntegrators.map(this.renderIntegratorOptions) || "" }

				</Select>
			</div>
		);
	}

	renderIntegratorStatic() {
		const { device, integrators } = this.props;
		const integrator = integrators.find(integrator => integrator.uuid === device.integrator);

		if (!integrator) {
			return null;
		}

		return integrator.name;
	}

	renderIntegratorOptions(integrator: Team | ICompany) {
		const { name, uuid } = integrator;

		return (
			<Select.Option
				value={ uuid }
				key={ uuid  }
			>
				{ name }
			</Select.Option>
		);
	}

	integratorPickerFilter(inputValue: string, option: React.ReactElement<OptionProps>): boolean {
		const opt = (option.props.title as string).toLowerCase();
		return opt.indexOf(inputValue.toLowerCase()) > -1;
	}

	updateIntegrator(integrator: string) {
		const { updateDevice, device } = this.props;
		updateDevice({ ...device, integrator: integrator || "" });
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(IntegratorDropdown);

import * as React from "react";
import { CustomCSS } from "@connect/Interfaces";
import Truncate from "./Truncate";

interface HeaderTextProps extends React.ComponentProps<any> {
	truncateLength?: number;
}

export default class HeaderText extends React.PureComponent<HeaderTextProps> {
	constructor(props: HeaderTextProps) {
		super(props);

		this.styles = Object.assign({},
			{
				padding: 5,
				textAlign: "center"
			},
			{...this.props.style})
	}

	styles: CustomCSS;

	render() {
		const { truncateLength } = this.props;

		if (truncateLength) {
			return (
				<h1 style={this.styles}>
					<Truncate length={ truncateLength }>{ `${this.props.children}` }</Truncate>
				</h1>
			);
		} else {
			return (
				<h1 style={ this.styles }>
					{ this.props.children }
				</h1>
			);
		}
	}
}
import * as React from "react";

import { CustomCSS, IMedia, IMediaUpload } from "@connect/Interfaces";
import { Icon, Truncate } from "Components/Global/Common";
import { DEFAULT_CARD, Colors } from "Components/Global/Constants";
import FavoriteToggle from "Components/Global/FavoriteToggle";
import { GridViewCard, IGridViewCardProps } from "Components/Global/GridViewCard";
import MediaThumbnail from "Components/Media/MediaThumbnail";
import Radium from "radium";

interface MediaCardProps extends IGridViewCardProps<IMedia | IMediaUpload> {
	onUploadMediaClicked?: () => void;
}

const { lightGray, primaryGreen, white, offWhite } = Colors;

@Radium
class MediaCard extends GridViewCard<MediaCardProps> {
	constructor(props: MediaCardProps) {
		super(props);

		const { width, padding, margin } = DEFAULT_CARD;

		this.styles = Object.assign(this.styles, {
			base: {
				position: "relative",
				borderRadius: 2,
				height: width,
				margin,
				padding,
				textAlign: "center",
				transition: "all 200ms linear",
				width
			},
			uploadNewMediaButton: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: width,
				width,
				color: lightGray
			},
			uploadNewMediaInner: {
				cursor: "pointer",
				width: 120,
				height: 120,
				background: offWhite,
				borderRadius: 4,
				margin: "6px 6px 32px 6px",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				":hover": {
					background: primaryGreen,
					color: white
				}
			},
			uploadNewMediaText: {
				marginTop: 8
			}
		});

		this.renderFavorite = this.renderFavorite.bind(this);
		this.renderUploadNewMediaButton = this.renderUploadNewMediaButton.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { uuid } = this.props.cardObject;
		const mediaCardInner = uuid ? this.renderMediaCard() : this.renderUploadNewMediaButton();

		return this.renderContainer(
			<div
				style={{
					...this.styles.base,
					background: this.backgroundColor()
				}}
			>
				{ mediaCardInner }
			</div>
		);
	}

	renderMediaCard() {
		const media = this.props.cardObject;
		const { width, padding } = DEFAULT_CARD;
		const fileNameHeight = 25;
		return (
			<React.Fragment>
				{this.renderFavorite()}
				<MediaThumbnail
					media={media}
					width={width - padding - fileNameHeight}
					style={{display: "inline-block"}}/>
				<div style={{
					width: width - (padding * 2),
					height: fileNameHeight,
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden"
				}}>
					<Truncate length={ 25 }>{ this.getName(media) }</Truncate>
				</div>
			</React.Fragment>
		);
	}

	renderFavorite() {
		const { cardObject: { favorite, uuid } } = this.props;

		if (favorite === undefined) {
			return null;
		}

		return (
			<div style={{
				position: "absolute",
				right: "5px",
				top: "5px"
			}}>
				<FavoriteToggle
					mediaId={uuid}
					type="icon"
					style={{
						fontSize: "16px",
						color: "#127EC0"
					}}/>
			</div>
		);
	}

	getName(media: IMedia) {
		const mediaUpload = media as IMediaUpload;
		if (mediaUpload.file && mediaUpload.file.name) {
			return mediaUpload.file.name;
		} else if (media && media.name) {
			return media.name;
		}
		return "";
	}

	backgroundColor() {
		const { isSelected, bulkSelectActive } = this.props;
		if (!isSelected) {
			return "#fff";
		} else if (bulkSelectActive) {
			return "#d7e6fc";
		}
		return "#f1f1f1";
	}

	renderUploadNewMediaButton() {
		const { uploadNewMediaButton, uploadNewMediaInner, uploadNewMediaText } = this.styles;
		const { bulkSelectActive } = this.props;

		if (bulkSelectActive) {
			return null;
		}

		return (
			<div style= { uploadNewMediaButton }>
				<div
					style={ uploadNewMediaInner }
					onClick={ this.props.onUploadMediaClicked }
				>
					<Icon name="plus-circle" size="small"/>
					<div style={ uploadNewMediaText }>Upload Media</div>
				</div>
			</div>
		)
	}
}

export default MediaCard;
import * as React from "react";
import { connect } from "react-redux";

import { AspectRatios, CustomCSS, IAd, IMedia, IVideoComponent, MediaContext } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import AspectRatioPicker from "Components/Ads/AspectRatioPicker";
import { Input } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { setSelectMediaModal } from "Data/Actions/UI/Modals";
import { getSelectedComponentIndex } from "Data/Selectors/AdBuilder";
import { getMediaById } from "Data/Selectors/Media";
import { getAdBuilderAd, getSelectedComponentByIndex } from "Data/Selectors/UI";

const { primaryBlue, white } = Colors;

const mapDispatchToProps = (dispatch) => ({
	onAdPropertiesChanged: (ad: IAd, livePreview: boolean) => null,
	showSelectMediaModal: (orientation: string) =>
		dispatch(setSelectMediaModal(true, [], orientation, MediaContext.VIDEO))
});

const mapStateToProps = (state) => {
	const ad = getAdBuilderAd(state);
	const selectedComponentIndex = getSelectedComponentIndex(state);
	const selectedComponent = getSelectedComponentByIndex(ad, selectedComponentIndex) || { id: undefined };
	const mediaIds = ad.layout.media.filter((aml) => aml.layoutPosition === selectedComponent.id);
	const mediaId = (mediaIds && mediaIds[0]) ? mediaIds[0].mediaId : "";
	const media = getMediaById(state, { uuid: mediaId });

	return {
		ad,
		media,
		selectedComponent,
		contentsHeightPercent: 0,
		livePreview: false
	}
};

interface VideoPropertiesPanelContentProps {
	ad: IAd;
	media: IMedia;
	livePreview: boolean;
	contentsHeightPercent: number;
	onAdPropertiesChanged: (ad: IAd, livePreview: boolean) => void;
	updateAdDuration: (text: string) => void;
	updateAdLivePreview: (value: boolean) => void;
	selectedComponent: IVideoComponent;
	showSelectMediaModal: (orientation: string) => void;
}

export class VideoPropertiesPanelContent extends React.Component<VideoPropertiesPanelContentProps> {
	constructor(props: VideoPropertiesPanelContentProps) {
		super(props);

		this.styles = {
			emptySpace: {
				border: "1px solid #FF0075",
				padding: "4px 6px",
				margin: "6px 0px"
			},
			row: {
				marginBottom: 14
			},
			label: {
				display: "inline-block",
				width: 100
			},
			center: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			aspectButton: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				margin: "0 5px",
				color: white,
				textAlign: "center",
				fontSize: "0.7rem",
				background: "#2e333f",
				cursor: "pointer",
				borderRadius: 6,
				width: 50,
				height: 24
			},
			aspectButtonSelected: {
				background: primaryBlue
			},
			aspectButtonDisabled: {
				opacity: 0.5,
				cursor: "not-allowed"
			},
			aspectButtonText: {
				fontSize: "1em"
			}
		}

		this.handleShowSelectMediaModal = this.handleShowSelectMediaModal.bind(this);
	}

	styles: {
		emptySpace: CustomCSS;
		row: CustomCSS;
		label: CustomCSS;
		center: CustomCSS;
		aspectButton: CustomCSS;
		aspectButtonSelected: CustomCSS;
		aspectButtonDisabled: CustomCSS;
		aspectButtonText: CustomCSS;
	};

	render() {
		if (!this.props.media) {
			return (
				<div>
					{ this.renderDimensions() }
					{ this.renderVideoSelect() }
					{ this.renderAspectPicker() }
				</div>
			);
		}

		return (
			<div>
				{ this.renderDimensions() }
				{ this.renderVideoSelect() }
				{ this.renderAspectPicker() }
				{ this.renderDuration() }
				{ this.renderResolution() }
				{ this.renderFileSize() }
			</div>
		);
	}

	renderAspectPicker() {
		const { media } = this.props;
		const { SIXTEEN_NINE, FOUR_THREE, ONE_ONE, NINE_SIXTEEN } = AspectRatios;

		return (
			<div style={this.styles.row}>
				<AspectRatioPicker ratios={[ SIXTEEN_NINE, FOUR_THREE, ONE_ONE, NINE_SIXTEEN ]} video={ media } />
			</div>
		);
	}

	renderDimensions() {
		const { selectedComponent } = this.props;
		const width = selectedComponent.width.value || 0;
		const height = selectedComponent.height.value || 0;

		// calculate the component dimensions in px... they come in as %
		// note that this currently assumes 1920x1080 in portrait
		const componentWidth = Utils.getComponentWidthFromPercent(width);
		const componentHeight = Utils.getComponentHeightFromPercent(height);

		return (
			<div style={this.styles.row}>
				Component Dimensions: { componentWidth }x{ componentHeight }px
			</div>
		);
	}

	renderDuration() {
		return this.renderRow("Duration:", Utils.getHumanReadableDuration(this.props.media.duration));
	}

	renderResolution() {
		return this.renderRow("Resolution:", "1080p");
	}

	renderFileSize() {
		return this.renderRow("File Size:", Utils.getHumanReadableBytesize(this.props.media.bytesize));
	}

	renderRow(title: string, content: string) {
		const { row, label } = this.styles;
		return (
			<div style={ row }>
				<div style={ label }>{ title }</div>
				<span>{ content }</span>
			</div>
		);
	}

	renderVideoSelect() {
		const { media } = this.props;
		let name;
		let uuid;

		if (media) {
			({name, uuid} = media)
		}

		return (
			<div style={ this.styles.row }>
				<Input
					button={{
						callback: this.handleShowSelectMediaModal(),
						text: media ? "Replace" : "Select"
					}}
					disabled
					id={ uuid || "newVideo" }
					value={ media ? name : "" }
					readOnly
				/>
			</div>
		);
	}

	handleShowSelectMediaModal() {
		return () => {
			const { showSelectMediaModal } = this.props;
			showSelectMediaModal("");
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPropertiesPanelContent);

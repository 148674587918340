import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { push } from "react-router-redux";

import { ActiveUuidRoute, AnalyticsDataResult, AnalyticsResult, CustomCSS, DateFilters,
	IAd, ViewTypes, Filters } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import AdTimeOnScreenChart from "Components/Analytics/AdTimeOnScreenChart";
import AnalyticsTable from "Components/Analytics/AnalyticsTable";
import DwellTimeChart from "Components/Analytics/DwellTimeChart";
import PlaysAndViewsChart from "Components/Analytics/PlaysAndViewsChart";
import TopStatsChart from "Components/Analytics/TopStatsChart";
import ViewsByDeviceChart from "Components/Analytics/ViewsByDeviceChart";
import { Icon, Select } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ViewSwitch from "Components/Global/ViewSwitch";
import { getAdAnalyticsAsync } from "Data/Actions/AdsAsync";
import { setActiveFilters, setActiveView } from "Data/Actions/UI";
import { getActiveAnalyticsResult, getActiveFilteredAnalyticsData } from "Data/Selectors/AdAnalytics";
import { getActiveAd } from "Data/Selectors/Ads";
import { getActiveFilters, getActiveUuid, getActiveView } from "Data/Selectors/UI";
import HeaderText from "Components/Global/HeaderText";

const { Option } = Select;
const defaultView = ViewTypes.CHART;

const mapDispatchToProps = (dispatch) => ({
	close: () => {
		dispatch(push("/analytics"));
		dispatch(setActiveFilters("dateRange", Filters.ANALYTICS, ""));
		dispatch(setActiveFilters("sizes", Filters.ANALYTICS, [] as any));
		dispatch(setActiveFilters("stores", Filters.ANALYTICS, [] as any));
	},
	setDateFilter: (filter: string) => dispatch(setActiveFilters("dateRange", Filters.ANALYTICS, filter)),
	getAnalytics: (uuid: string) => dispatch(getAdAnalyticsAsync(uuid)),
	setView: (type: ViewTypes) => dispatch(setActiveView("analytics", type))
});

const mapStateToProps = (state) => {
	const activeUuid = getActiveUuid(state, "analytics");
	const { dateRange, devices, stores, sizes } = getActiveFilters(state, Filters.ANALYTICS);
	const activeAnalytics = getActiveAnalyticsResult(state);
	const activeAd = getActiveAd(state, activeUuid);
	const data = getActiveFilteredAnalyticsData(state);
	const selectedView = getActiveView(state, "analytics") || defaultView;

	return {
		activeAd,
		activeAnalytics,
		activeUuid,
		data,
		dateRange,
		devices,
		stores,
		sizes,
		selectedView
	};
};

interface AnalyticsReportProps extends RouteComponentProps<ActiveUuidRoute> {
	activeAd: IAd;
	activeAnalytics: AnalyticsResult;
	activeUuid: string;
	close: () => void;
	data?: AnalyticsDataResult[];
	dateRange: DateFilters;
	devices: string[];
	getAnalytics: (uuid: string) => void;
	setDateFilter: (filter: string) => void;
	sizes: string[];
	stores: string[];
	selectedView: ViewTypes;
	setView: (type: ViewTypes) => void;
}

export class AnalyticsReport extends React.Component<AnalyticsReportProps> {
	constructor(props: AnalyticsReportProps) {
		super(props);

		this.styles = {
			bottomRow: {
				width: "100%",
				height: 120,
				marginBottom: 20
			},
			charts: {
				position: "relative",
				margin: "0 30px",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "space-evenly"
			},
			closeButton: {
				cursor: "pointer",
				margin: 10,
				position: "absolute",
				right: 0,
				top: 0
			},
			dateRange: {
				padding: "5px 8px",
				background: Colors.offWhite,
				borderRadius: 4
			},
			filters: {
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				margin: "0px 30px 15px",
				minHeight: 30
			},
			main: {
				width: "100%",
				position: "relative"
			},
			middleRow: {
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr",
				gridGap: 16,
				width: "100%",
				minHeight: 285,
				marginBottom: 20
			},
			select: {
				width: 120,
				marginRight: 10
			},
			title: {
				flex: "0 0 auto",
				fontWeight: 500,
				minHeight: 40,
				color: "#646464"
			},
			topRow: {
				display: "grid",
				width: "100%",
				minHeight: 450,
				marginBottom: 20
			}
		}

		this.getDateRange = this.getDateRange.bind(this);
		this.handleChangeDateFilter = this.handleChangeDateFilter.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.toggleSelectedView = this.toggleSelectedView.bind(this);
		this.renderContent = this.renderContent.bind(this);
	}

	styles: CustomCSS;

	componentWillMount() {
		if (!this.props.dateRange) {
			this.handleChangeDateFilter(7);
		}

		this.props.getAnalytics(this.props.activeUuid);
	}

	componentWillReceiveProps(nextProps: AnalyticsReportProps) {
		const { getAnalytics, activeUuid, dateRange, devices, stores, sizes } = nextProps;
		const dateRangeChanged = dateRange !== this.props.dateRange;
		const devicesChanged = devices !== this.props.devices;
		const storesChanged = stores !== this.props.stores;
		const sizesChanged = sizes !== this.props.sizes;
		const hasChanges = dateRangeChanged || devicesChanged || storesChanged || sizesChanged;

		if (hasChanges) {
			getAnalytics(activeUuid);
		}
	}

	render() {
		if (!this.props.activeAd) {
			return null;
		}

		return (
			<div style={ this.styles.main }>
				{ this.renderTitle() }
				{ this.renderFilters() }
				{ this.renderCloseButton() }
				{ this.renderContent() }
			</div>
		);
	}

	renderContent () {
		const { selectedView } = this.props;
		if (selectedView === ViewTypes.CHART) {
			return this.renderCharts();
		}
		return <AnalyticsTable />;
	}

	renderFilters() {
		return (
			<div style={ this.styles.filters }>
				{ this.renderDateRange() }
				{ this.renderSortBy() }
			</div>
		);
	}

	renderTitle() {
		return (
			<HeaderText
				style={ this.styles.title }
				truncateLength={ 58 }
			>
				{ `Ad Analytics for: ${this.props.activeAd.name}` }
			</HeaderText>
		);
	}

	renderCloseButton() {
		return (
			<Icon
				style={ this.styles.closeButton }
				size="smaller"
				name="times"
				onClick={ this.handleClose }
			/>
		);
	}

	renderDateRange() {
		return (
			<div style={ this.styles.dateRange }>
				{ this.getDateRange() }
			</div>
		);
	}

	getDateRange() {
		const [ start, end ] = this.props.dateRange.split("_");
		const startDate = (start ? moment(start, "YYYY-MM-DD") : moment().subtract(1, "days")).format("MM-DD-YYYY");
		const endDate = (start ? moment(end, "YYYY-MM-DD") : moment().subtract(8, "days")).format("MM-DD-YYYY");

		return `${ startDate } to ${ endDate }`;
	}

	getDateFormat(date1: moment.Moment, date2: moment.Moment) {
		if (date1.year() !== date2.year()) {
			return "MMM D, YYYY";
		}
		return "MMM D";
	}

	renderSortBy() {
		const [ startDate, endDate ] = this.props.dateRange.split("_");
		const dayCount = startDate && endDate ? Utils.getDaysBetweenDates(startDate, endDate).length : 7;
		const { selectedView } = this.props;

		return (
			<div>
				<Select
					defaultValue={ 7 }
					value={ dayCount }
					style={ this.styles.select }
					onChange={ this.handleChangeDateFilter }
				>
					<Option value={ 7 }>Last 7 Days</Option>
					<Option value={ 30 }>Last 30 Days</Option>
					<Option value={ 90 }>Last 90 Days</Option>
					<Option value={ 365 }>Last Year</Option>
				</Select>
				<ViewSwitch
					onViewChange={ this.toggleSelectedView }
					view={ selectedView }/>
			</div>
		);
	}

	toggleSelectedView() {
		const { selectedView, setView } = this.props;
		if (selectedView === ViewTypes.CHART) {
			setView(ViewTypes.LIST);
		} else {
			setView(ViewTypes.CHART);
		}
	}

	renderPlaysViewsChart() {
		return (
			<PlaysAndViewsChart
				icon="chart-bar"
				showText
				title="Plays & Views"
			/>
		);
	}

	renderViewsByDeviceChart() {
		return (
			<ViewsByDeviceChart
				icon="mobile"
				title="Views By Device"
			/>
		);
	}

	renderDwellTimeChart() {
		return (
			<DwellTimeChart
				icon="user-clock"
				title="Dwell Time"
			/>
		);
	}

	renderAdTimeOnScreenChart() {
		return (
			<AdTimeOnScreenChart
				icon="clock"
				title="Ad Time On Screen"
			/>
		);
	}

	renderTopStatsChart() {
		return (
			<TopStatsChart
				icon="trophy-alt"
				title="Top Stats"
			/>
		);
	}

	renderCharts() {
		const { charts, topRow, middleRow, bottomRow } = this.styles;

		return (
			<div style={ charts }>
				<div style={ topRow }>
					{ this.renderPlaysViewsChart() }
				</div>
				<div style={ middleRow }>
					{ this.renderViewsByDeviceChart() }
					{ this.renderDwellTimeChart() }
					{ this.renderAdTimeOnScreenChart() }
				</div>
				<div style={ bottomRow }>
					{ this.renderTopStatsChart() }
				</div>
			</div>
		);
	}

	handleChangeDateFilter(range: number) {
		const endRange = moment()
			.subtract(1, "days")
			.format("YYYY-MM-DD");

		const startRange = moment()
			.subtract(range, "days")
			.format("YYYY-MM-DD");

		const dateRange = `${ startRange }_${ endRange }`;

		this.props.setDateFilter(dateRange);
	}

	handleClose() {
		this.props.close();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsReport);

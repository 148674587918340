import * as React from "react";
import { connect } from "react-redux";

import { ActionSet, Context, CustomCSS, TriggerType } from "@connect/Interfaces";
import { Icon, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { GridViewCard, IconData, IGridViewCardProps } from "Components/Global/GridViewCard";
import SideScroller from "Components/Global/SideScroller";
import { ActionDataType, TriggerIcon, TriggerName } from "Data/Objects/Actions";
import { getCurrentSelectActionsModalContext } from "Data/Selectors/UI";

const { lightGray, offWhite, selectedBlue, white } = Colors;

const mapStateToProps = (state, ownProps: ActionCardProps) => ({
	context: getCurrentSelectActionsModalContext(state)
});

interface ActionCardProps extends IGridViewCardProps<ActionSet> {
	context: Context;
}

class ActionCard extends GridViewCard<ActionCardProps> {
	constructor(props: ActionCardProps) {
		super(props);

		this.styles = {
			outer: {
				position: "relative",
				cursor: "pointer",
				boxShadow: `0px 2px 10px ${lightGray}`,
				border: `1px solid ${white}`,
				borderRadius: 6,
				minHeight: 160,
				width: 560,
				margin: 10,
				justifySelf: "center"
			},
			checkbox: {
				position: "absolute",
				top: 4,
				left: 5
			},
			contentRow: {
				borderRadius: "6px 6px 0px 0px",
				width: "100%",
				height: 170,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				fontSize: 14,
				borderBottom: `1px solid ${white}`,
				backgroundColor: offWhite
			},
			infoRow: {
				height: 30,
				marginTop: 2,
				padding: 10,
				borderRadius: "6px 6px 0px 0px",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				fontSize: 14
			},
			center: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			thumbnail: {
				backgroundColor: white,
				border: 0,
				height: 130,
				width: 150,
				padding: 10,
				textAlign: "left",
				borderRadius: 6
			},
			name: {
				display: "flex",
				flex: "1 0 55%",
				whiteSpace: "nowrap",
				overflowX: "hidden",
				marginRight: 10
			},
			actions: {
				display: "flex",
				flex: "1 0 auto",
				justifyContent: "flex-end"
			},
			cardStyle: {
				border: "none",
				verticalAlign: "middle"
			},
			cardInner: {
				maxWidth: "100%",
				overflow: "hidden"
			},
			iconStyle: {
				display: "block",
				padding: 5
			},
			actionTitle: {
				fontSize: "1em",
				fontWeight: "bold",
				marginTop: 3
			},
			actionsList: {
				color: lightGray,
				fontSize: "0.741em",
				whiteSpace: "normal"
			}
		};

		this.renderIcon = this.renderIcon.bind(this);
		this.renderInfoRow = this.renderInfoRow.bind(this);
		this.renderTriggerThumbnail = this.renderTriggerThumbnail.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { bulkSelectActive, cardObject: actionSet, isSelected } = this.props;
		const { cardStyle, contentRow, outer } = this.styles;
		const thumbnails = actionSet.data.map(this.renderTriggerThumbnail);

		let row = {
			...contentRow
		};

		let innerContainer = {
			...outer
		};

		if (bulkSelectActive && isSelected) {
			innerContainer.backgroundColor = selectedBlue;
			innerContainer.border = `1px solid ${ selectedBlue }`;
			row.borderBottom = `1px solid ${ selectedBlue }`;
			row.backgroundColor = selectedBlue;
		}

		return this.renderContainer(
			<div style={ innerContainer }>
				<div style={ row }>
					<SideScroller
						id={ actionSet.uuid }
						itemStyles={ cardStyle }
					>
						{ thumbnails }
					</SideScroller>
				</div>
				{ this.renderInfoRow() }
			</div>
		);
	}

	renderInfoRow() {
		const { infoRow, name } = this.styles;

		return (
			<div style={ infoRow }>
				<div style={ name }>
					<b><Truncate length={ 36 }>{ this.props.cardObject.name }</Truncate></b>
				</div>
				<div style={ this.getActionSetStyle() }>
					{ (this.props.actionButtons || []).map(this.renderIcon) }
				</div>
			</div>
		);
	}

	renderIcon(iconData: IconData) {
		return (
			<Icon
				name={ iconData.name }
				key={ iconData.name }
				style={ this.styles.iconStyle }
				iconWeight="regular"
				onClick={ this.clickIcon(iconData.callback) } />
		)
	}

	renderTriggerThumbnail(action: any, index: number) {
		const { type, actions } = action;
		return (
			<div key={ this.props.cardObject.uuid + index }
				style={ this.styles.thumbnail }>
				{ this.getTriggerIcon(type) }
				{ this.getTriggerName(type) }
				{ this.getActionsList(actions.map((a) => a.type)) }
			</div>
		)
	}

	getTriggerIcon(type: TriggerType) {
		const icon = TriggerIcon[type];
		const { cardInner, iconStyle } = this.styles;

		return (
			<div style={ cardInner }>
				<Icon name={ icon } iconWeight="solid" size="small" style={ iconStyle } />
			</div>
		);
	}

	getTriggerName(type: TriggerType) {
		const { cardInner, actionTitle } = this.styles;

		return (
			<div style={ cardInner }>
				<h3 style={ actionTitle }>{ TriggerName[type] }</h3>
			</div>
		);
	}

	getActionsList(actionTypes: ActionDataType[]) {
		const { actionsList } = this.styles;
		const actionList = actionTypes.reduce((list, type, index) => {
			const end = (index + 1) === actionTypes.length ? "" : ", ";
			return list + ActionDataType[type] + end;
		}, "");

		return (
			<div>
				<p style={ actionsList }>{ actionList }</p>
			</div>
		);
	}

	getActionSetStyle() {
		const { bulkSelectActive, context } = this.props;
		const visible = bulkSelectActive || context === Context.PLAYLISTS;

		return {
			...this.styles.actions,
			visibility: visible ? "hidden" : "visible"
		}
	}
}

export default connect(mapStateToProps)(ActionCard);

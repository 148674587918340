import { ButtonType } from "antd/lib/button/button";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { push } from "react-router-redux";

import { ActiveUuidRoute, CustomCSS, IAd, IBaseComponent, ICompany, IMedia,
	MediaContext as Context, MediaFilterTypes, SortTypes, ViewTypes, Filters, Sorts } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import { Button, Icon } from "Components/Global/Common";
import ContentAreaTopBar, { SuffixButtonProps } from "Components/Global/ContentAreaTopBar";
import { Droppable, UnwrappedDroppable } from "Components/Global/DropZone";
import { IconWeights } from "Components/Global/Icon";
import MediaHandler from "Components/Global/MediaHandler";
import MediaUsagePanel from "Components/Global/MediaUsagePanel";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import MediaContentArea from "Components/Media/MediaContentArea";
import MediaFilters from "Components/Media/MediaFilters";
import MediaPropertiesPanel from "Components/Media/MediaPropertiesPanel";
import { deleteMediaAsync, setMediaAsync } from "Data/Actions/MediaAsync";
import { setActiveFilters, setActiveSelection, setActiveTags, setActiveView } from "Data/Actions/UI";
import { getSelectedComponent } from "Data/Selectors/AdBuilder";
import { getMediaAsyncQueryState, getNestedAsyncState } from "Data/Selectors/Async";
import { getActiveCompany } from "Data/Selectors/Company";
import { getFilteredTaggedSortedSearchedMedia, getMediaById, getMediaTags } from "Data/Selectors/Media";
import {
	getActiveFilters,
	getActiveSelection,
	getActiveSorts,
	getActiveTags,
	getActiveUuid,
	getActiveView
} from "Data/Selectors/UI";

interface MediaPageProps extends RouteComponentProps<ActiveUuidRoute> {
	activeCompany: ICompany;
	activeMedium: IMedia;
	activeTags: string[];
	activeUuid: string;
	ad: IAd;
	canFetch: boolean;
	context?: Context;
	deleteMedia: (uuid: string) => void;
	filter: string;
	getMedia: () => Promise<void>;
	lastFetchedPage: number;
	media: IMedia[];
	mediaAsyncQuery: string;
	mediaTags: string[];
	onMediaSelected?: (media: IMedia[]) => void; // this prop is used in the case of video or image context above
	originator: string;
	pushToMediaPage: () => void;
	selectedComponent: IBaseComponent;
	selectMedia: (media: string[]) => void;
	selectedMedia: string[];
	selectedView: ViewTypes;
	setMediaTags: (tags: string[]) => void;
	setMediaType: (filter: MediaFilterTypes) => void;
	setFilter: (query: string) => void;
	setView: (view: ViewTypes) => void;
	sortType: SortTypes;
	onCloseModal?: () => void;
}

interface MediaPageState {
	selectModeOn: boolean;
}

const getContext = (props: MediaPageProps) => props.context || Context.PAGE;

const mapDispatchToProps = (dispatch) => ({
	deleteMedia: (uuid: string) => dispatch(deleteMediaAsync(uuid)),
	getMedia: () => dispatch(setMediaAsync()),
	pushToMediaPage: () => dispatch(push({
		pathname: "/media"
	})),
	selectMedia: (media: string[]) => dispatch(setActiveSelection("media", media)),
	setMediaTags: (tags: string[]) => dispatch(setActiveTags("media", tags)),
	setMediaType: (filter: MediaFilterTypes) => dispatch(setActiveFilters("type", Filters.MEDIA, filter)),
	setFilter: (query: string) => dispatch(setActiveFilters("filter", Filters.MEDIA, query)),
	setView: (view: ViewTypes) => dispatch(setActiveView("media", view))
});

const mapStateToProps = (state, ownProps) => {
	const mediaFilters = getActiveFilters(state, Filters.MEDIA);
	const { type, filter } = mediaFilters;
	const activeTags = getActiveTags(state, "media");
	const filterType = type as MediaFilterTypes;
	const sortType = getActiveSorts(state, Sorts.MEDIA) as SortTypes;
	const mediaAsyncQuery = getMediaAsyncQueryState({ filterType, sortType });
	const { currentlyFetching, currentPage, haveAllData } = getNestedAsyncState(state, mediaAsyncQuery);;
	const media = getFilteredTaggedSortedSearchedMedia(state, {
		sort: sortType as SortTypes,
		tags: activeTags,
		type: filterType,
		filter: filter as string
	});
	const originator = getContext(ownProps) + "_" + media.length;
	const activeUuid = getActiveUuid(state, "media");
	const selectedComponent = getSelectedComponent(state);

	return {
		activeCompany: getActiveCompany(state),
		activeMedium: getMediaById(state, { uuid: activeUuid }),
		activeTags,
		activeUuid,
		canFetch: !currentlyFetching && !haveAllData,
		filter,
		lastFetchedPage: currentPage,
		media,
		mediaAsyncQuery,
		mediaTags: getMediaTags(state),
		originator,
		selectedComponent,
		selectedMedia: getActiveSelection(state, "media"),
		selectedView: getActiveView(state, "media") || ViewTypes.GRID,
		sortType
	};
};

export class MediaPage extends React.Component<MediaPageProps, MediaPageState> {
	constructor(props: MediaPageProps) {
		super(props);

		const { location } = props;
		const selectModeOn = location && location.state && location.state.selectModeOn || false;

		this.state = {
			selectModeOn
		}

		this.styles = {
			container: {
				height: "100%",
				minHeight: "100vh",
				position: "relative",
				width: "100%"
			},
			usageHelp: {
				float: "right"
			}
		}

		this.panelWidth = 250;
		this.perPage = 100;

		this.deselectAllMedia = this.deselectAllMedia.bind(this);
		this.getBatchOperations = this.getBatchOperations.bind(this);
		this.getHeaderSuffixButtons = this.getHeaderSuffixButtons.bind(this);
		this.handleFileDrop = this.handleFileDrop.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.maybeResetPage = this.maybeResetPage.bind(this);
		this.openDropzone = this.openDropzone.bind(this);
		this.promptDeleteMedia = this.promptDeleteMedia.bind(this);
		this.renderLeftContent = this.renderLeftContent.bind(this);
		this.renderRightContent = this.renderRightContent.bind(this);
		this.selectAllMedia = this.selectAllMedia.bind(this);
		this.setDropzone = this.setDropzone.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
		this.handleSelectMedia = this.handleSelectMedia.bind(this);
	}

	styles: CustomCSS;
	panelWidth: number;
	perPage: number;
	dropzone: connect<UnwrappedDroppable>;

	static getDerivedStateFromProps(props: MediaPageProps, state: MediaPageState) {
		const { location } = props;
		const selectModeOn = location && location.state && location.state.selectModeOn;

		if (selectModeOn !== undefined && selectModeOn !== state.selectModeOn) {
			return { selectModeOn };
		}

		return null;
	}

	componentDidMount() {
		const context = getContext(this.props);
		const { getMedia, setMediaType } = this.props;

		getMedia();

		if (context === Context.IMAGE) {
			setMediaType(MediaFilterTypes.IMAGES);
		} else if (context === Context.VIDEO) {
			setMediaType(MediaFilterTypes.VIDEOS);
		}

		this.maybeResetPage();
	}

	componentDidUpdate(prevProps: MediaPageProps) {
		const { activeCompany, mediaAsyncQuery } = prevProps;

		if (activeCompany.uuid !== this.props.activeCompany.uuid || mediaAsyncQuery !== this.props.mediaAsyncQuery) {
			this.props.getMedia();
		}

		this.maybeResetPage(this.props);
	}

	render() {
		const { activeTags, canFetch, context, filter, lastFetchedPage, media, mediaTags, onCloseModal,
			originator, onMediaSelected, setMediaTags, sortType } = this.props;

		return (
			<div style={{height: "100%"}}>
				<ThreeColumnLayout
					leftContent={this.renderLeftContent()}
					centerContent={
						<div style={this.styles.container}>
							<Droppable
								fullScreen
								key={ `${ sortType }`}
								ref={this.setDropzone}
								onDrop={this.handleFileDrop}
							>
								<ContentAreaTopBar
									batch={this.getBatchOperations()}
									closeButton={onCloseModal}
									context={context}
									search={{
										filterText: filter,
										onSearchChange: this.handleSearchChange
									}}
									sort={{
										dataType: Sorts.MEDIA
									}}
									suffixButtons={this.getHeaderSuffixButtons()}
									tags={{
										activeTags: activeTags,
										tags: mediaTags,
										tagSelectChange: setMediaTags,
										tagType: "media"
									}}
								/>
								<MediaContentArea
									canFetch={canFetch}
									lastFetchedPage={lastFetchedPage}
									media={media}
									originator={originator}
									selectModeOn={this.state.selectModeOn}
									onUploadMediaClicked={this.openDropzone}
									onMediaSelected={onMediaSelected}
								/>
							</Droppable>
						</div>
					}
					rightContent={this.renderRightContent()} />
			</div>
		);
	}

	getBatchOperations() {
		const { context, media, selectedComponent, selectedMedia, selectedView } = this.props;

		if (selectedView !== ViewTypes.GRID) {
			return undefined;
		}

		const numSelected = selectedMedia.length;

		const batchButtons = [
			{
				disabled: numSelected === media.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.selectAllMedia
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.deselectAllMedia
			},
			{
				disabled: !numSelected,
				label: "Delete Media",
				icon: "trash",
				iconWeight: "regular" as IconWeights,
				onClick: this.promptDeleteMedia,
				type: "danger" as ButtonType
			}
		];

		if (context && selectedComponent && selectedComponent.type === "slideshow") {
			batchButtons.push({
				disabled: !numSelected,
				label: "Add Selected Media",
				icon: "check",
				iconWeight: "regular" as IconWeights,
				onClick: this.handleSelectMedia,
				type: "primary"
			});
		}

		return {
			active: this.state.selectModeOn,
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Media",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	handleSelectMedia() {
		this.toggleSelectMode();

		if (this.props.onMediaSelected) {
			const mediaUuids = this.props.selectedMedia;
			const media = this.props.media.filter((m) => mediaUuids.includes(m.uuid));
			this.props.onMediaSelected(media);

			if (this.props.onCloseModal) {
				this.props.onCloseModal();
			}
		}
	}

	getHeaderSuffixButtons() {
		const { selectedView, setView } = this.props;
		const currentViewIsGrid = ViewTypes.GRID === selectedView;
		const gridButtonIcon = currentViewIsGrid ? "list-ul" : "th";
		const gridButtonHandler = () => {
			const newView = currentViewIsGrid ? ViewTypes.LIST : ViewTypes.GRID;
			setView(newView);
		};
		const buttonStyle = { marginLeft: 10 };

		let buttons = [
			{
				icon: gridButtonIcon,
				onClick: gridButtonHandler,
				style: buttonStyle
			} as SuffixButtonProps
		];

		if (getContext(this.props) !== Context.PAGE) {
			buttons.push({
				children: "Upload Media",
				onClick: this.openDropzone,
				style: buttonStyle
			} as SuffixButtonProps);
		}

		return buttons;
	}

	renderLeftContent() {
		const context = getContext(this.props);

		if (context !== Context.PAGE) {
			return undefined;
		}

		const { usageTitle } = this.styles;

		return (
			<Accordion
				condense
				header={
					<Button type="primary" corners="rounded" icon="plus-circle" fluid
						onClick={ this.openDropzone }>
						Upload Media
					</Button>
				}
				elements={[
					new AccordionElement("Media Types", (
						<MediaFilters context={ context } />
					)),
					new AccordionElement(
						<React.Fragment>
							<div style={ usageTitle }>
								<Icon
									size="smaller"
									name="exclamation-triangle"
									iconWeight="regular"
									style={{
										color: "red",
										display: this.displayUsageWarning() ? "inline-block" : "none"
									}} />
								<span>Usage</span>
							</div>
						</React.Fragment>,
						<div>
							{<MediaUsagePanel vertical type="circle" spacers={true} uuid={this.props.activeCompany.uuid} />}
						</div>
						, undefined, undefined, true)
				]}
			/>
		);
	}

	renderRightContent() {
		const { activeMedium } = this.props;

		if (!(activeMedium && getContext(this.props) === Context.PAGE)) {
			return undefined;
		}

		return (
			<MediaPropertiesPanel media={activeMedium} />
		);
	}

	toggleSelectMode() {
		const { activeUuid, pushToMediaPage, selectMedia } = this.props;
		const newSelectMode = { selectModeOn: !this.state.selectModeOn };

		selectMedia([]);

		if (activeUuid) {
			this.setState(() => (newSelectMode));
			pushToMediaPage();
		} else {
			this.setState(() => (newSelectMode));
		}
	}

	promptDeleteMedia() {
		// confirm that user wants to delete all selected media
		Notifications.confirm("Delete selected media?",
			"Are you sure you want to delete the selected media?",
			"Delete", "Cancel", () => {
				this.props.selectedMedia.forEach((uuid) => {
					this.props.deleteMedia(uuid);
				});
			});
	}

	selectAllMedia() {
		const { media, selectMedia } = this.props;

		selectMedia(media.map((m) => m.uuid));
	}

	deselectAllMedia() {
		const { selectMedia } = this.props;

		selectMedia([]);
	}

	displayUsageWarning() {
		const { activeCompany } = this.props;
		const { mediaUsage: usage, mediaLimit: limit } = activeCompany;

		if (activeCompany && usage && limit) {
			const libraryUsage = usage.library;
			const maxLibrarySize = limit.librarySize;
			const maxMonthlyImageSize = limit.monthlyImageSize;
			const maxMonthlyVideoLength = limit.monthlyVideoLength;
			const monthlyImageUsage = usage.image;
			const monthlyVideoLength = usage.duration

			// used to show a warning icon on the usage panel for > 90% usage
			if ((monthlyImageUsage / maxMonthlyImageSize) > .9) {
				return true;
			} else if ((monthlyVideoLength / maxMonthlyVideoLength) > .9) {
				return true;
			} else if ((libraryUsage / maxLibrarySize) > .9) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	}

	setDropzone(node: UnwrappedDroppable) {
		this.dropzone = node;
	}

	openDropzone() {
		this.dropzone.getWrappedInstance().open();
	}

	handleFileDrop(files: File[]) {
		let mediaHandler = new MediaHandler(this.props.originator);
		const allowedTypes = (() => {
			switch (getContext(this.props)) {
				case Context.PAGE: return [ "Image", "Video" ];
				case Context.IMAGE: return [ "Image" ];
				case Context.VIDEO: return [ "Video" ];
				default: return [ "Image", "Video" ];
			}
		})();
		mediaHandler.allowedFileTypes = allowedTypes;
		mediaHandler.handleFiles(files);
	}

	handleSearchChange(value: string) {
		this.props.setFilter(value);
	}

	maybeResetPage(nextProps?: MediaPageProps) {
		const props = nextProps || this.props;
		const { activeMedium, activeUuid, pushToMediaPage } = props;

		if (activeUuid && !activeMedium && getContext(props) === Context.PAGE) {
			pushToMediaPage();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaPage);
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { connect } from "react-redux";

import { toggleFeature } from "@connect/Features";
import { CustomCSS, CustomPermissions, ICompany, Team } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import CompanyInfo from "Components/Admin/CompanyInfo";
import { Button, Header, Table } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import { updateCompanyAsync } from "Data/Actions/Company";
import { fetchPermissionSettings, updatePermissionSettings } from "Data/Actions/PermissionsAsync";
import { getIntegratorListAsync } from "Data/Actions/Team";
import { setActiveSearch } from "Data/Actions/UI";
import { isCompanyOwner } from "Data/Objects/Roles";
import { getActiveCompany, getActiveCompanyId } from "Data/Selectors/Company";
import { getCustomPermissions } from "Data/Selectors/Permissions";
import { getTeams } from "Data/Selectors/Team";
import { getActiveSearch } from "Data/Selectors/UI";
import { deepSearch } from "Data/Utils";

const { black } = Colors;

const mapDispatchToProps = (dispatch) => ({
	fetchPermissions: () => dispatch(fetchPermissionSettings()),
	getIntegrators: () => dispatch(getIntegratorListAsync()),
	setCompanySearch: (value: string) => dispatch(setActiveSearch("integrationCompanies", value)),
	setHasCustomPermissions: (company: ICompany) => dispatch(updateCompanyAsync(company)),
	updatePermissions: (permissions: CustomPermissions) => dispatch(updatePermissionSettings(permissions))
});

const mapStateToProps = (state) => ({
	activeCompanyId: getActiveCompanyId(state),
	company: getActiveCompany(state),
	companies: getTeams(state),
	companySearch: getActiveSearch(state, "integrationCompanies"),
	permissions: getCustomPermissions(state)
});

interface CompaniesPageCompany {
	devicesCount: number;
	name: string;
	owner: string;
	uuid: string;
}

interface IntegrationCompaniesPageProps {
	activeCompanyId: string;
	company: ICompany;
	companies: Team[];
	companySearch: string;
	fetchPermissions: () => void;
	getIntegrators: () => void;
	permissions: CustomPermissions;
	setCompanySearch: (value: string) => void;
	setHasCustomPermissions: (company: ICompany) => Promise<void>;
	setIntegratorAccess: () => void;
	updatePermissions: (permissions: CustomPermissions) => void;
}

interface IntegrationCompaniesPageState {
}

export class IntegrationCompaniesPage extends React.Component<
IntegrationCompaniesPageProps,
IntegrationCompaniesPageState
> {
	constructor(props: IntegrationCompaniesPageProps) {
		super(props);

		this.state = {};

		this.styles = {
			container: {
				padding: "0px 30px"
			},
			integratorAccess: {
				width: "33%"
			},
			integratorAccessContent: {
				fontSize: "0.9em",
				padding: "6px 0 6px 12px"
			},
			integratorButton: {
				marginLeft: 12
			},
			separator: {
				backgroundColor: black,
				border: 0,
				height: 1,
				margin: "20px 0"
			},
			table: {
				paddingTop: 14
			}
		};

		this.getData = this.getData.bind(this);
		this.hasIntegratorAccess = this.hasIntegratorAccess.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.renderExpanded = this.renderExpanded.bind(this);
		this.renderIntegratorAccess = this.renderIntegratorAccess.bind(this);
		this.setIntegratorAccess = this.setIntegratorAccess.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		const { companies, fetchPermissions, getIntegrators, permissions } = this.props;

		if (!companies.length) {
			getIntegrators();
		}

		if (!permissions) {
			fetchPermissions();
		}
	}

	componentDidUpdate(prevProps: IntegrationCompaniesPageProps) {
		const { activeCompanyId, companies, getIntegrators } = this.props;

		if (activeCompanyId !== prevProps.activeCompanyId || !companies.length) {
			getIntegrators();
		}
	}

	render() {
		const { activeCompanyId } = this.props;
		const { container, table } = this.styles;
		const defaultExpanded = isCompanyOwner() ? [ activeCompanyId ] : [];

		return (
			<div style={ container }>
				<Header size={ 3 }>Integration Companies</Header>
				<ContentAreaTopBar
					search={{
						filterText: this.props.companySearch,
						onSearchChange: this.handleSearchChange
					}}
				/>
				<Table
					className="admin-table"
					columns={ this.getColumns() }
					dataSource={ this.getData() }
					defaultExpandedRowKeys={ defaultExpanded }
					expandedRowRender={ this.renderExpanded }
					expandRowByClick
					indentSize={ 0 }
					locale={{ emptyText: "No Companies" }}
					pagination={ false }
					rowKey={ this.getRowKey }
					size="middle"
					style={ table }
				/>
				{ this.renderIntegratorAccess() }
			</div>
		);
	}

	renderExpanded(record: CompaniesPageCompany) {
		return (
			<CompanyInfo company={ record.uuid } integrationCompany />
		);
	}

	renderIntegratorAccess() {
		const { headerStyle, integratorAccess, integratorAccessContent, integratorButton } = this.styles;
		const { companies, company } = this.props;
		const noTeams = !companies.length;
		const disabledStyle = noTeams ? { opacity: 0.4 } : null;
		const capIntegrator = companies.length > 1 ? "Integrators" : "Integrator";
		const integrator = companies.length > 1 ? "integrators" : "integrator";
		const assignedIntegrators = companies.length > 1 ? "assigned integrators" : "an assigned integrator";
		const hasNoAccess = !this.hasIntegratorAccess();
		const buttonClass = hasNoAccess ? "primary-button" : "warning-button";
		const buttonIcon = hasNoAccess ? "hand-point-right" : "times";
		const buttonTitle = hasNoAccess ? `Transfer to ${ capIntegrator }` : "Revoke Access";

		return toggleFeature("customUserPermissions",
			(
				<React.Fragment>
					<hr style={ this.styles.separator } />
					<div style={{ ...integratorAccess, ...disabledStyle }}>
						<Header size={ 3 } style={ headerStyle }>{ capIntegrator } Access</Header>
						<p style={ integratorAccessContent }>
							{`
						Since this company has ${ assignedIntegrators }, you may transfer all management of your company,
						${ company.name }, to them. This is useful if your ${ integrator } will be responsible for creating,
						managing and deploying advertising content. Integrators will have full access to Media,
						Ads, Playlists and Deployments.
						`}
						</p>
						<Button
							className={ buttonClass }
							disabled={ noTeams }
							icon={ buttonIcon }
							onClick={ this.setIntegratorAccess }
							style={ integratorButton }
						>
							{ buttonTitle }
						</Button>
					</div>
				</React.Fragment>
			),
			null
		);
	}

	getColumns() {
		return [
			{
				title: "Company Name",
				dataIndex: "name",
				key: "name",
				sorter: Utils.columnSorter("name"),
				defaultSortOrder: "descend"
			},
			{
				title: "Company Owner",
				dataIndex: "owner",
				key: "owner",
				sorter: Utils.columnSorter("owner")
			},
			{
				title: "Managed Devices",
				dataIndex: "devicesCount",
				key: "devicesCount",
				sorter: Utils.columnSorter("devicesCount")
			}
		] as ColumnProps<CompaniesPageCompany>[];
	}

	getData(): CompaniesPageCompany[] {
		const { companies, companySearch } = this.props;
		const keys = [ "name", "owner" ];

		const mappedCompanies = companies
			.map((team) => {
				const newTeam = Object.assign({}, team, {
					owner: team.users[0] && team.users[0].name
				});

				delete newTeam.users;
				delete newTeam.hashid;

				return newTeam;
			});

		return deepSearch<CompaniesPageCompany>(mappedCompanies, companySearch, keys);
	}

	getRowKey(record: CompaniesPageCompany) {
		return record.uuid ? record.uuid : "";
	}

	handleSearchChange(value: string) {
		this.props.setCompanySearch(value);
	}

	hasIntegratorAccess() {
		const { company, permissions } = this.props;

		if (!company || !permissions) {
			return false;
		}

		const integratorPermissions = Object.values(permissions.integrator);
		const isTrue = (val: boolean) => val;
		const getIntegratorPerms = !!(integratorPermissions.every(isTrue));

		// will return true if we have the customPermissions bit AND all of our integrator perms are true
		return company.customPermissions && getIntegratorPerms;
	}

	setIntegratorAccess() {
		const { company, permissions, setHasCustomPermissions, updatePermissions } = this.props;
		const hasAccess = this.hasIntegratorAccess();
		const customCompany = Object.assign({}, company, {
			customPermissions: !hasAccess
		});

		setHasCustomPermissions(customCompany).then(() => {
			if (!hasAccess) {
				const newPermissions = Object.assign({}, permissions, {
					integrator: {
						manageCompany: true,
						manageContent: true,
						deployContent: true,
						manageDevices: true,
						manageHealthReports: true,
						managePermissions: true
					},
					owner: {
						manageCompany: true,
						manageContent: false,
						deployContent: false,
						manageDevices: false,
						manageHealthReports: false,
						managePermissions: true
					}
				});

				updatePermissions(newPermissions);
			}
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationCompaniesPage);

import Radium from "radium";
import * as React from "react";

import { CustomCSS, IAd, PlaylistAd } from "@connect/Interfaces";
import { Icon, Truncate } from "Components/Global/Common";
import { Colors, DEFAULT_CARD } from "Components/Global/Constants";
import {
	DnDGridViewCard,
	GridViewCard,
	GridViewDndComponent,
	IGridViewCardProps
} from "Components/Global/GridViewCard";

const { lightGray, offWhite, primaryGreen, white } = Colors;

interface AdCardProps extends IGridViewCardProps<IAd | PlaylistAd> {
	adListCard?: boolean;
	context?: "preview" | "thumbnail";
	fontColor?: string;
	format?: "wide" | "narrow";
	onCreateAdClicked?: () => void;
	width?: number;
	bulkSelectActive?: boolean;
}

@Radium
export class AdCard extends GridViewCard<AdCardProps> {
	constructor(props: AdCardProps) {
		super(props);

		const { context, fontColor, format, width: propWidth } = props;
		const { width, padding, margin } = DEFAULT_CARD;
		const isWide = format === "wide";
		const calculateWidth = isWide ? width : "auto";

		this.thumbWidth = propWidth || 66;
		this.thumbHeight = (16 / 9) * this.thumbWidth;

		this.styles = Object.assign(this.styles, {
			adCard: {
				width: this.thumbWidth,
				height: this.thumbHeight,
				background: "#d7e6fc",
				border: "5px solid #202020",
				borderRadius: 4,
				fontSize: "0.9em",
				marginBottom: context !== "preview" ? 4 : 0
			},
			adThumbnail: {
				marginBottom: context !== "preview" ? 10 : 0,
				width: this.thumbWidth - 10,
				height: this.thumbHeight - 10
			},
			base: {
				alignItems: "center",
				borderRadius: 2,
				color: fontColor ? fontColor : Colors.white,
				cursor: "pointer",
				display: "flex",
				flexDirection: "column",
				height: calculateWidth,
				justifyContent: "center",
				margin,
				padding,
				position: "relative",
				textAlign: "center",
				transition: "all 200ms linear",
				width: calculateWidth
			},
			createNewAdButton: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "auto",
				color: lightGray
			},
			createNewAdButtonInner: {
				width: 120,
				height: 120,
				background: offWhite,
				borderRadius: 4,
				margin: "6px 6px 13px 6px",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				":hover": {
					background: primaryGreen,
					color: white
				}
			},
			createNewAdButtonText: {
				marginTop: 8
			}
		});

		this.clickCreateAd = this.clickCreateAd.bind(this);
		this.renderAdCard = this.renderAdCard.bind(this);
		this.renderAdThumbnail = this.renderAdThumbnail.bind(this);
		this.renderContainer = this.renderContainer.bind(this);
		this.renderCreateNewAdButton = this.renderCreateNewAdButton.bind(this);
	}

	styles: CustomCSS;
	thumbWidth: number;
	thumbHeight: number;

	render() {
		const { cardObject, isDragging } = this.props;
		const { uuid } = cardObject;
		const adCardInner = uuid ? this.renderAdCard() : this.renderCreateNewAdButton();

		return this.renderContainer(
			<div
				style={{
					...this.styles.base,
					background: this.backgroundColor(),
					opacity: isDragging ? 0.5 : 1
				}}
			>
				{ adCardInner }
			</div>
		);
	}

	renderAdCard() {
		const { name } = this.props.cardObject;

		return (
			<React.Fragment>
				<div style={ this.styles.adCard }>
					{ this.renderAdThumbnail() }
				</div>
				<div title={ name }>
					{ this.getName() }
				</div>
			</React.Fragment>
		);
	}

	renderAdThumbnail() {
		const { thumbnail } = this.props.cardObject;

		if (!thumbnail) {
			return null;
		}

		return (
			<img src={ thumbnail } style={ this.styles.adThumbnail } />
		);
	}

	renderCreateNewAdButton() {
		const { createNewAdButton, createNewAdButtonInner, createNewAdButtonText } = this.styles;

		if (this.props.bulkSelectActive) {
			return null;
		}

		return (
			<div style={ createNewAdButton }>
				<div
					onClick={ this.clickCreateAd }
					style={ createNewAdButtonInner }
				>
					<Icon name="plus-circle" size="small" />
					<div style={ createNewAdButtonText }>New Ad</div>
				</div>
			</div>
		);
	}

	backgroundColor() {
		const { adListCard, isSelected, bulkSelectActive } = this.props;
		if (adListCard) {
			return null;
		}
		if (!isSelected) {
			return "#fff";
		} else if (bulkSelectActive) {
			return "#d7e6fc";
		}
		return "#f1f1f1";
	}

	clickCreateAd() {
		const { onCreateAdClicked } = this.props;

		if (onCreateAdClicked) {
			onCreateAdClicked();
		}
	}

	getName() {
		const { cardObject, context, format } = this.props;
		const { name } = cardObject;

		if (context === "preview") {
			return null;
		}

		if (format === "wide") {
			return <Truncate length={ 26 }>{ name }</Truncate>
		}

		return <Truncate>{ name }</Truncate>
	}
}

export const DraggableAdCard: GridViewDndComponent<AdCardProps> = DnDGridViewCard.GridViewCardDraggable(AdCard);

export default DraggableAdCard;

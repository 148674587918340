import * as update from "immutability-helper";
import * as moment from "moment";
import { push } from "react-router-redux";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { Deployment, DeploymentsResult, DeploySteps, IUser, NameUuid, SortTypes, StringObject,
	WithUuid, Filters, Sorts} from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import DeploymentsApi from "Api/Deployments";
import { deleteDeployment, resetDeployments, setApprovers, setDeploymentDetails,
	setDeployments, createDeployment} from "Data/Actions/Deployments";
import { setDeploymentApprovalModalUUID } from "Data/Actions/UI/Modals";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { resetAsyncStates, setActiveSelection, setAsyncFetching, setAsyncState } from "Data/Actions/UI";
import { setActiveDeployment, setDeploymentStep } from "Data/Actions/UI/DeploymentWizard";
import { DeployStepDetails } from "Data/Objects/Deployments";
import { CacheInvalidationPeriod } from "Data/Objects/Global";
import { getDeployAsyncQueryState, getDeployLastFetchAll, getNestedAsyncState } from "Data/Selectors/Async";
import { getActiveCompanyId } from "Data/Selectors/Company";
import { getActiveDeployment, getDeploymentStep } from "Data/Selectors/DeploymentWizard";
import { getDeviceGroupsByArray, getDevicesByArray } from "Data/Selectors/Devices";
import { getActiveFilters, getActiveSelection, getActiveSorts } from "Data/Selectors/UI";
import { getNameUuid, getUuid } from "Data/Utils";
import { cloneDeep } from "lodash";

/**
 * Async Actions
 */

export function cancelDeploymentAsync(uuid: string) {
	const api = new DeploymentsApi();

	return (dispatch) => {
		return api.cancelDeployment(uuid)
			.catch((error) => {
				dispatch(errorNotification("Error canceling deployment.", error));
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

export function createDeploymentAsync(deployment: Partial<Deployment>) {
	const api = new DeploymentsApi();

	return (dispatch) => {
		return api.createDeployment(deployment)
			.then((result: WithUuid) => {
				return api.getDeployment(result.uuid).then((createdDeployment: Deployment) => {
					dispatch(createDeployment(createdDeployment));
					return createdDeployment;
				});
			}, (error) => {
				dispatch(errorNotification("Error creating deployment.", error));
			})
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	};
}

export function deleteDeploymentAsync(uuid: string) {
	const api = new DeploymentsApi();

	return (dispatch) => {
		return api.deleteDeployment(uuid)
			.then(() => {
				dispatch(deleteDeployment(uuid));
			}, (error) => {
				dispatch(errorNotification("Error deleting deployment.", error));
			})
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	};
}

export function duplicateDeploymentAsync(uuid: string, user: NameUuid) {
	return (dispatch) => {
		return dispatch(getDeploymentAsync(uuid))
			.then((result: Deployment) => {
				const { uuid: userUuid, name } = user;
				const data = cloneDeep(result);

				delete data.uuid;
				delete data.startDate;
				delete data.endDate;

				if (data.type === "event") {
					delete data.approvedBy;
					delete data.submittedTo;
				}

				const initializedData = {
					devices: [],
					deviceGroups: [],
					schedule: []
				};
				const updatedData = {
					createdBy: { name, uuid: userUuid }
				}

				if (data.devices && data.devices.length) {
					(updatedData as any).devices = data.devices.map(getUuid);
				}
				if (data.deviceGroups && data.deviceGroups.length) {
					(updatedData as any).deviceGroups = data.deviceGroups.map(getUuid);
				}

				const deploymentToSave = Object.assign(initializedData, data, updatedData);

				return dispatch(createDeploymentAsync(deploymentToSave));
			}, (error) => {
				dispatch(errorNotification("Error duplicating deployment.", error));
			})
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	};
}

export function getDeploymentAsync(uuid: string) {
	const api = new DeploymentsApi();

	return (dispatch) => {
		return api.getDeployment(uuid, true)
			.then((result: Deployment) => {
				dispatch(setDeploymentDetails(result));

				return result;
			}, (error) => {
				dispatch(errorNotification("Error getting deployment.", error));
			})
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	};
}

export function getDeploymentsAsync(filterType?: StringObject, sortType?: SortTypes) {
	const api = new DeploymentsApi();

	return (dispatch, getState) => {
		const state = getState();
		const filters = filterType || getActiveFilters(state, Filters.DEPLOYMENTS);
		const currentSort = sortType || getActiveSorts(state, Sorts.DEPLOYMENTS) as SortTypes;
		const { status, type } = filters as StringObject;
		const filterSort = { filterType: filters as StringObject, sortType: currentSort };
		const asyncQuery = getDeployAsyncQueryState(filterSort);
		const { currentPage, currentlyFetching, haveAllData, lastFetchedCompany } = getNestedAsyncState(state, asyncQuery);

		const dontNeedToFetch = haveAllData || currentlyFetching;
		const activeCompanyId = getActiveCompanyId(state);
		const lastFetchAll = getDeployLastFetchAll(state);
		const lastFetchDiff = moment().diff(lastFetchAll, "minute");
		const companyChanged = activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = lastFetchDiff > CacheInvalidationPeriod || companyChanged;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		if (shouldInvalidateCache) {
			dispatch(resetDeployments());
			dispatch(resetAsyncStates());
		}

		dispatch(setAsyncFetching(asyncQuery, true, activeCompanyId));

		const page = (shouldInvalidateCache ? 0 : currentPage) + 1;
		const params = {
			page,
			"filter[state]": status,
			"filter[type]": type,
			sort: Utils.getApiSort({ sortType: currentSort })
		};

		return api.getDeployments(params)
			.then((result: DeploymentsResult) => {
				dispatch(setDeployments(result.data, shouldInvalidateCache));
				dispatch(setAsyncState(asyncQuery, !result.links.next, result.meta.current_page));
				dispatch(setAsyncFetching(asyncQuery, false));
			}, (error) => {
				dispatch(errorNotification("Error getting deployments.", error));
				dispatch(setAsyncFetching(asyncQuery, false));
			})
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	};
}

export function redeployDeployment(uuid: string) {
	const api = new DeploymentsApi();

	return (dispatch) => {
		return api.redeployDeployment(uuid)
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	};
}

export function fetchDeploymentTargets() {
	const api = new DeploymentsApi();

	return (dispatch, getState) => {
		const state = getState();

		const uuid = getActiveCompanyId(state);

		return api.getDeploymentTargets(uuid)
			.then((result: Partial<IUser>[]) => {
				dispatch(setApprovers(result));
			}, (error) => {
				dispatch(errorNotification("Error getting deployment approvers.", error));
			})
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	}
}

export function submitDeploymentForApproval(deploymentUUID: Deployment, approvers: string[]) {
	const api = new DeploymentsApi();

	return (dispatch, getState) => {
		const updatedDeployment = update(deploymentUUID, {
			submittedTo: {
				$set: approvers
			}
		});

		return api.updateDeployment(updatedDeployment)
			.then((result: any) => {
				dispatch(successNotification("Deployment submitted for approval"));
				dispatch(setDeploymentApprovalModalUUID({} as Deployment));
				dispatch(push("/deploy"));
			}, (error) => {
				dispatch(errorNotification("Error submitting deployment for approval.", error));
			})
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	};
}

export function updateDeploymentAsync(deployment: Deployment) {
	const api = new DeploymentsApi();

	return (dispatch) => {
		const deploymentToSave = Object.assign({}, deployment, {
			devices: deployment.devices.map(getUuid),
			deviceGroups: deployment.deviceGroups.map(getUuid),
			submittedTo: deployment.submittedTo || []
		});

		return api.updateDeployment(deploymentToSave)
			.then(() => {
				dispatch(setDeploymentDetails(deployment));
			}, (error) => {
				dispatch(errorNotification("Error updating deployment.", error));
			})
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	};
}

export function updateDeploymentAndChangeStep(nextStep: DeploySteps, back?: boolean) {
	return (dispatch, getState) => {
		const state = getState();
		const currentStep = getDeploymentStep(state);
		const activeDeployment = getActiveDeployment(state);
		const isDevicesStep = currentStep === DeploySteps.DEVICES;
		const nextDevices = nextStep === DeploySteps.DEVICES
		const { devices, deviceGroups } = activeDeployment;
		// devices are sent to us as NameUuid and must be sent to the API as string
		// they come from the device selection step as string and most of the rest of the time as NameUuid
		// sometimes however we will get them as an array of strings and from that they must be mapped
		const noIncomingDevices = nextDevices
			&& (activeDeployment.devices.length && typeof activeDeployment.devices[0] === "string")
			|| (activeDeployment.deviceGroups.length && typeof activeDeployment.deviceGroups[0] === "string")
		const deviceIds = noIncomingDevices ? devices as string[] : getActiveSelection(state, "deployDevices");
		const deviceGroupIds = noIncomingDevices
			? deviceGroups as string[] : getActiveSelection(state, "deployDevices_groups");
		const fullDevices = getDevicesByArray(state, deviceIds);
		const fullDeviceGroups = getDeviceGroupsByArray(state, deviceGroupIds);
		// if devices need to be mapped, do that here, otherwise use the current activeDeployment as-is
		const deployment = isDevicesStep || noIncomingDevices ? Object.assign({}, activeDeployment, {
			devices: fullDevices.map(getNameUuid),
			deviceGroups: fullDeviceGroups.map(getNameUuid)
		}) : activeDeployment;
		// ensure we're valid before proceeding
		const { isValid, message } = DeployStepDetails[currentStep].validate(deployment);

		// we only want to validate on explicit step changes or forward wizard navigation
		if (!isValid && !back) {
			return dispatch(errorNotification("Error updating deployment.", message));
		}

		// because our devices come from the UI activeSelection state, we need to set these elements on the deployment
		if (isDevicesStep) {
			dispatch(setActiveDeployment(deployment));
		}

		return dispatch(updateDeploymentAsync(deployment))
			.then(() => {
				if (nextDevices) {
					dispatch(setActiveSelection("deployDevices", deployment.devices.map(getUuid)));
					dispatch(setActiveSelection("deployDevices_groups", deployment.deviceGroups.map(getUuid)));
				}

				dispatch(setDeploymentStep(nextStep));
			});
	};
}

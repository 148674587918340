import { ActionSet, ActionSetsResult, API_SORT, BulkDeleteResult, IActionsApi } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class ActionsApi extends ApiV2 implements IActionsApi {
	createActionSet(actionSet?: Partial<ActionSet>) {
		return this.POST("action-sets", actionSet || {}) as Promise<ActionSet>;
	}

	deleteActionSet(uuid: string) {
		return this.DELETE(`action-sets/${uuid}`) as Promise<Response>;
	}

	deleteActionSets(uuids: string[]) {
		return this.DELETE("action-sets/bulk", uuids) as Promise<BulkDeleteResult>;
	}

	getActionSet(uuid: string) {
		return this.GET(`action-sets/${ uuid }`, { include: "playlists" }) as Promise<ActionSet>;
	}

	getActionSets(perPage?: number, page?: number, sort?: API_SORT) {
		return this.GET("action-sets", { perPage, page, sort  }) as Promise<ActionSetsResult>;
	}

	updateActionSet(actionSet: ActionSet) {
		return this.PUT(`action-sets/${ actionSet.uuid }`, actionSet) as Promise<ActionSet>;
	}
}
import * as update from "immutability-helper";
import * as React from "react";

import { CustomCSS, LightbarFrame } from "@connect/Interfaces";
import LED from "Components/Actions/Lightbar/LED";
import { isEqual } from "lodash";

interface LightbarContainerProps {
	style?: CustomCSS;
	preset: LightbarFrame[];
	disabled?:  boolean;
	selected?: boolean;
	onClick?: () => void;
}

interface LightbarContainerState {
	currentFrame: number;
	hover: boolean;
}

export default class LightbarContainer extends React.Component<LightbarContainerProps, LightbarContainerState> {
	constructor(props: LightbarContainerProps) {
		super(props);

		this._animation = 0;
		this._timeout = 0;

		this.state = {
			currentFrame: 0,
			hover: false
		};

		this.getLightbarFrame = this.getLightbarFrame.bind(this);
		this.setFrame = this.setFrame.bind(this);
		this.startAnimation = this.startAnimation.bind(this);
		this.stop = this.stop.bind(this);
	}

	_animation: number;
	_timeout: number;

	componentDidMount() {
		this.setFrame(0);
	}

	componentDidUpdate(props: LightbarContainerProps) {
		if (!isEqual(props.preset, this.props.preset)) {
			this.setFrame(0);
		}
	}

	render() {
		const frame = this.getLightbarFrame();

		const style = { ...this.props.style, opacity: this.state.hover || this.props.selected  ? 1 : 0.25 } ;

		return (
			<div
				onClick={ this.props.onClick }
				onMouseEnter={ this.setHoverStateHandler(true) }
				onMouseLeave={ this.setHoverStateHandler(false) }
				style={ style } >
				{ frame && frame.colors.map(this.renderLED) }
			</div>
		);
	}

	setHoverStateHandler(value: boolean) {
		return () => {
			this.setState((prevState) => {
				return update(prevState, {
					hover: { $set: value }
				});
			});
		}
	}

	renderLED(frame: string, index: number) {
		return (
			<LED frame={ frame } key={ index } />
		);
	}

	getLightbarFrame() {
		return this.props.preset[this.state.currentFrame];
	}

	setFrame(index: number, reset: boolean = false) {
		this.setState(() => ({ currentFrame: index }), () => {
			cancelAnimationFrame(this._animation);

			if (reset) {
				return;
			} else {
				this.startAnimation(0);
			}
		});
	}

	startAnimation(time: number) {
		const { currentFrame, hover } = this.state;
		const { disabled, preset } = this.props;
		const frame = preset[currentFrame];

		// if we do not have a frame, we should loop
		if (!frame) {
			this.setFrame(0);
			return;
		}

		// set _timeout when we reset (call startAnimation outside of rAF)
		if (!time) {
			this._timeout = performance.now(); // now() prduces the same timestamp as rAF callback argument
		}

		// loop
		this._animation = requestAnimationFrame(this.startAnimation);

		// if we have reset or are not yet meeting our duration, halt execution
		if (!time || time - this._timeout < frame.duration) {
			return;
		}

		// advance to the next frame if needed
		if (!disabled || hover) {
			this.setFrame(currentFrame + 1);
		}
	}

	public stop() {
		this.setFrame(0, true);
	}
}

import { Button as AntButton } from "antd";
import * as React from "react";
import { findDOMNode } from "react-dom";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { FlipTypes } from "Components/Global/Icon";

export type ButtonTypes = "primary" | "ghost" | "dashed" | "danger";
type CornerTypes = "rounded" | "default" | "square";
type ButtonSizes = "small" | "large";
type ButtonLoadingTypes = boolean | { delay: number };
export type IconWeights = "regular" | "solid" | "light" | "brands";

interface ButtonGroupProps {
	size?: ButtonSizes;
	style?: CustomCSS;
}

class ButtonGroup extends React.PureComponent<ButtonGroupProps, any> {
	constructor(props: ButtonGroupProps) {
		super(props);
	}

	render() {
		return (
			<AntButton.Group size={this.props.size} style={this.props.style}>
				{React.Children.map(this.props.children, (child) => {
					// pass the isGrouped prop down so we render in a group
					return React.cloneElement(child as any, { isGrouped: true });
				})}
			</AntButton.Group>
		);
	}
}

export interface ButtonProps {
	type?: ButtonTypes;
	ghost?: boolean;
	corners?: CornerTypes;
	color?: string;
	icon?: string;
	circular?: boolean;
	disabled?: boolean;
	size?: ButtonSizes;
	fluid?: boolean;
	loading?: ButtonLoadingTypes;
	isGrouped?: boolean;
	onClick?: (event: React.FormEvent<any>) => void;
	style?: CustomCSS;
	isSuffix?: boolean;
	suffixIcon?: boolean;
	visible?: boolean;
	className?: string;
	iconWeight?: IconWeights;
	iconFlip?: FlipTypes;
	iconSpin?: boolean;
}

interface ButtonState {
	buttonStyles: CustomCSS;
}

export default class Button extends React.Component<ButtonProps, ButtonState> {
	constructor(props: ButtonProps) {
		super(props);

		this.state = {
			buttonStyles: this.getButtonStyles(props)
		};

		this.blur = this.blur.bind(this);
		this.setButtonRef = this.setButtonRef.bind(this);
	}

	static Group = ButtonGroup;

	_button: HTMLButtonElement;

	componentWillReceiveProps(nextProps: ButtonProps) {
		this.setState(() => ({
			buttonStyles: this.getButtonStyles(nextProps)
		}));
	}

	render() {
		const { fluid, isGrouped, visible } = this.props;

		if (visible !== undefined && !visible) {
			return null;
		}

		if (isGrouped) {
			return this.renderAntButton();
		}

		// wrap in a span unless these buttons are used in a group
		return (
			<span style={{
				display: fluid ? "block" : "inline",
				width: fluid ? "100%" : "auto",
				textAlign: "center"
			}}>
				{this.renderAntButton()}
			</span>
		);
	}

	renderAntButton() {
		const {
			type,
			ghost,
			icon,
			onClick,
			suffixIcon,
			children,
			circular,
			disabled,
			size,
			loading,
			className,
			iconWeight,
			iconFlip,
			iconSpin
		} = this.props;

		return (
			<div style={{ display: "inherit" }} onFocus={(e) => {
				// This is used as a wrapper for AntButton since it doesn't accept an
				// onFocus handler or an ID, and ref gives a reference to Ant Button.
				// Must be handled here to result in the same behavior when the state
				// reverts to a focused state, such as in the ad builder after clear.
				e.preventDefault();
				this.blur();
			}}>
				<AntButton
					ref={this.setButtonRef}
					type={type}
					ghost={ghost || type === "ghost" || type === "dashed"}
					shape={circular ? "circle" : undefined}
					disabled={disabled}
					size={size}
					loading={loading}
					style={this.state.buttonStyles}
					onClick={(e) => {
						if (onClick) {
							onClick(e);
						}
					}}
					className={className}
				>
					{icon && !suffixIcon ?
						<Icon
							name={icon}
							iconWeight={iconWeight}
							flip={iconFlip}
							spin={iconSpin}
							size="smaller"
							style={{
								margin: children ? "0 10px 0 -5px" : 0
							}} />
						: null}
					{children}
					{icon && suffixIcon ?
						<Icon
							name={icon}
							iconWeight={iconWeight}
							flip={iconFlip}
							size="smaller"
							style={{
								margin: children ? "0 -5px 0 10px" : 0
							}} />
						: null}
				</AntButton>
			</div>
		);
	}

	getButtonStyles(props: ButtonProps) {
		const { color, corners, fluid, isSuffix, style } = props;
		let buttonStyles = style || {};

		if (corners === "rounded") {
			buttonStyles = { ...buttonStyles, borderRadius: 20 };
		} else if (corners === "square") {
			buttonStyles = { ...buttonStyles, borderRadius: 0 };
		}

		if (isSuffix) {
			buttonStyles = { ...buttonStyles,
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
				borderTopRightRadius: 3,
				borderBottomRightRadius: 3
			};
		}

		if (color) {
			buttonStyles = { ...buttonStyles,
				backgroundColor: color,
				borderColor: color
			};
		}

		if (fluid) {
			buttonStyles = { ...buttonStyles, width: "80%" };
		}

		return buttonStyles;
	}

	setButtonRef(antButton: AntButton) {
		this._button = findDOMNode(antButton) as HTMLButtonElement;
	}

	blur() {
		this._button.blur();
	}
}

import * as React from "react";

interface HeaderProps {
	size: 1 | 2 | 3 | 4 | 5 | 6;
	color?: "white" | "black" | "gray";
	style?: {};
}

export default class Header extends React.PureComponent<HeaderProps> {
	render() {
		const colors = {
			white: "rgba(255, 255, 255, 0.8)",
			black: "rgba(0, 0, 0, 0.8)",
			gray: "rgba(0, 0, 0, 0.6)"
		};
		const { children, color, size, style } = this.props;

		// don't override props.style with the color prop
		const styles: React.CSSProperties = Object.assign({
			color: colors[color || "black"]
		}, style);

		return React.createElement(`h${ size }`, { style: styles }, children);
	}
}
import * as update from "immutability-helper";
import { debounce } from "lodash";
import * as React from "react";
import { ConnectDropTarget, DropTarget, DropTargetCollector, DropTargetMonitor, DropTargetSpec } from "react-dnd";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { CustomCSS, IAd } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { RatioHeightPercents, UnusedSpaceWarningThreshold } from "Components/Ads/Constants";
import { NewComponentIdentifier } from "Components/Ads/DraggableNewComponentCard";
import FeedComponentCard from "Components/Ads/FeedComponentCard";
import ImageComponentCard from "Components/Ads/ImageComponentCard";
import SlideshowComponentCard from "Components/Ads/SlideshowComponentCard";
import SlideshowDragPreview from "Components/Ads/SlideshowDragPreview";
import { AdComponentIdentifier } from "Components/Ads/SuperComponentCard";
import TickerComponentCard from "Components/Ads/TickerComponentCard";
import TickerDragPreview from "Components/Ads/TickerDragPreview";
import VideoComponentCard from "Components/Ads/VideoComponentCard";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import {
	addComponent as addComponentCard,
	moveComponent as moveComponentCard,
	saveUnsavedAd,
	setComponentResizing,
	setSelectedComponent
} from "Data/Actions/UI/AdBuilder";
import { TemplateComponentFactory } from "Data/Objects/AdTemplates";
import { getActiveAd, getAdComponentsHeight, getLivePreview } from "Data/Selectors/AdBuilder";
import { getUnsavedState } from "Data/Selectors/System";
import { getAdBuilderState } from "Data/Selectors/UI";
import PresenceUsers from "Components/Global/PresenceUsers";
import { toggleFeature } from "@connect/Features";

const { twoGray, black } = Colors;

interface DraggableComponentDropItem {
	contentType?: string;
	index?: number;
}

interface AdBuilderCollectedProps {
	canDrop?: boolean;
	connectDropTarget?: ConnectDropTarget;
	dropItem?: DraggableComponentDropItem;
	isOver?: boolean;
}

interface AdBuilderProps {
	activeAd: IAd;
	addComponent: (component: any, hoverIndex: number) => void;
	canDrop?: boolean;
	componentsHeight: number;
	connectDropTarget?: ConnectDropTarget;
	deselectComponent: () => void;
	dropItem?: DraggableComponentDropItem;
	exitAd: () => void;
	hasChanges: string;
	hasEmptySpace: boolean;
	isOver?: boolean;
	livePreview: boolean;
	moveComponent: (dragIndex: number, hoverIndex: number) => void;
	resizingComponents: boolean[];
	saveAd: () => void;
	selectComponent: (index: number) => void;
	setIsResizing: (index: number, resizing: boolean) => void;
}

interface AdBuilderState {
	height: number;
	width: number;
}

// Utils for AdBuilder drop/hover calculations
const getEl = (c: React.Component, cln: string) =>
	(findDOMNode(c) as Element).getElementsByClassName(cln)[0];
const getElDetails = (el: Element) => ({
	hoverRect: el.getBoundingClientRect(),
	clientHeight: el.clientHeight
});

let _className;
let _component;
let _element;
let _elementDetails;
let _lastYOffset = 0;

// cache hover details
function getHoverDetails(component: AdBuilder, className: string) {
	const classesEqual = _className === className;
	const componentsEqual = _component && _component.props.activeAd.uuid === component.props.activeAd.uuid;

	if (classesEqual && componentsEqual && _element) {
		return _elementDetails;
	}

	_className = className;
	_component = component;
	_element = getEl(component, className);
	_elementDetails = getElDetails(_element);

	return _elementDetails;
}
// End AdBuilder Utils

const mapStateToProps = (state) => {
	const componentsHeight = getAdComponentsHeight(state);
	return {
		activeAd: getActiveAd(state),
		componentsHeight,
		hasChanges: getUnsavedState(state, "ads"),
		hasEmptySpace: (componentsHeight > UnusedSpaceWarningThreshold && componentsHeight < 100),
		livePreview: getLivePreview(state),
		resizingComponents: getAdBuilderState(state).componentIsResizing
	};
};

const mapDispatchToProps = (dispatch) => ({
	addComponent: (component: any, hoverIndex: number) => dispatch(addComponentCard(component, hoverIndex)),
	deselectComponent: () => dispatch(setSelectedComponent(-1)),
	exitAd: () => dispatch(push("/ads")),
	moveComponent: (dragIndex: number, hoverIndex: number) => dispatch(moveComponentCard(dragIndex, hoverIndex)),
	saveAd: () => dispatch(saveUnsavedAd()),
	selectComponent: (index: number) => dispatch(setSelectedComponent(index)),
	setIsResizing: (index: number, resizing: boolean) => dispatch(setComponentResizing(index, resizing))
});

const DropCollector: DropTargetCollector<AdBuilderCollectedProps> = (dndConnect, monitor) => ({
	connectDropTarget: dndConnect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop(),
	dropItem: monitor.getItem()
});

const DropSpec: DropTargetSpec<AdBuilderProps> = {
	drop(props: AdBuilderProps, monitor: DropTargetMonitor, component: AdBuilder) {
		const item = monitor.getItem();
		const isNew = item.hasOwnProperty("contentType");

		if (!isNew) {
			props.saveAd();
			return;
		}

		const { components } = props.activeAd.layout;
		const type = item.contentType;
		const newComponent = TemplateComponentFactory.generate(type);
		const currentHeight = components
			.map(({ height }) => height.value)
			.reduce((prev, cur) => prev + cur, 0);
		const canFitContent = (height: number) => height <= 100 - currentHeight;

		let canFit = canFitContent(newComponent.height.value);

		// feed components are first measured in 1:1 format
		// in case 1:1 doesn't fit, check if 16:9 would fit
		if (type === "feed" && !canFit) {
			newComponent.height.value = RatioHeightPercents.sixteenByNine;
			newComponent.aspectRatio = "16:9";

			canFit = canFitContent(newComponent.height.value);
		}

		if (!canFit) {
			Notifications.warning("Cannot Add Content",
				`There is not enough remaining space to add more content.
				Please remove or resize content in the current layout before
				adding more content.`);

			return;
		}

		const { hoverRect, clientHeight } = getHoverDetails(component, "adbuilder-pvm-screen");
		const offset = monitor.getClientOffset() || { y: 0 };

		// get the mouse position relative to the rectangle being hovered
		const hoverMouseY = offset.y - hoverRect.top + window.scrollY;
		// get the mouse position as a percentage of container height
		const hoverMousePercentage = Math.floor(hoverMouseY / clientHeight * 100);

		let hoverIndex = -1;
		// we may never get this value; we should put the component at the bottom by default
		let middleY = 100;

		if (components.length) {
			hoverIndex = components
				.findIndex(({ height, top }) => {
					return (height.value + top.value) > hoverMousePercentage;
				});

			if (hoverIndex !== -1) {
				// get info about the component we're hovering
				const { height: hoverHeight, top: hoverTop } = components[hoverIndex];
				// get the middle of the component if it exists
				middleY = (hoverHeight.value / 2) + hoverTop.value;
			} else {
				hoverIndex = components.length;
			}
		}

		const isAbove = hoverMousePercentage <= middleY;
		const { index } = item;

		// only move when the mouse has crossed the hoverMiddleY
		// when moving down, only move when the mouse is below 50%
		if (index < hoverIndex && isAbove && index !== hoverIndex - 1) {
			hoverIndex -= 1;
		}

		// when moving up, only move when the mouse is above 50%
		if (index > hoverIndex && !isAbove && index !== hoverIndex + 1) {
			hoverIndex += 1;
		}

		// if we're still here, add the new component
		props.addComponent(newComponent, hoverIndex);
	},
	hover: debounce((props: AdBuilderProps, monitor: DropTargetMonitor, component: AdBuilder) => {
		const item = monitor.getItem();
		const isNew = item && item.hasOwnProperty("contentType");

		// return early if we do not get an item, or if the item is new
		if (!item || isNew) {
			return;
		}

		const { hoverRect, clientHeight } = getHoverDetails(component, "adbuilder-pvm-screen");
		const offset = monitor.getClientOffset() || { y: 0 };
		const { y: yDiff } = monitor.getDifferenceFromInitialOffset() || { y: 0 };

		const offsetDiff = yDiff - _lastYOffset;
		_lastYOffset = yDiff;

		const draggingUpwards = offsetDiff < 0;

		// return early if we do not have an offsetDiff / drag position hasn't changed since last hover event
		if (offsetDiff === 0) {
			return;
		}

		const { activeAd, moveComponent, selectComponent } = props;
		const { components } = activeAd.layout;
		const { index } = item;

		// get the mouse position relative to the rectangle being hovered
		const hoverMouseY = offset.y - hoverRect.top + window.scrollY;

		// get the mouse position as a percentage of container height
		const hoverMousePercentage = hoverMouseY / clientHeight * 100;
		let hoverIndex = -1;
		let nextComponentHeight;
		let nextComponentTop;
		let nextComponentMiddle;

		if (components.length) {
			hoverIndex = components
				.findIndex(({ height, top }) => {
					return (height.value + top.value) > hoverMousePercentage;
				});

			if (hoverIndex !== -1) {
				// get the next component info
				const nextComponent = offsetDiff > 0 ? components[index + 1] : components[index - 1];
				if (nextComponent) {
					nextComponentHeight = nextComponent.height;
					nextComponentTop = nextComponent.top;
					nextComponentMiddle = (nextComponentHeight.value / 2) + nextComponentTop.value;
				} else {
					return;
				}
			} else {
				hoverIndex = components.length;
			}
		}

		const hoverMouseLessThanNextComponentMiddle = hoverMousePercentage <= nextComponentMiddle;
		const hoverMouseGreaterThanNextComponentMiddle = hoverMousePercentage >= nextComponentMiddle;
		const isAbove = draggingUpwards ? hoverMouseLessThanNextComponentMiddle : hoverMouseGreaterThanNextComponentMiddle;

		// only move when the mouse has crossed the hoverMiddleY
		// when moving down, only move when the mouse is below 50%
		if (index < hoverIndex && isAbove && index !== hoverIndex - 1) {
			hoverIndex -= 1;
		}

		// when moving up, only move when the mouse is above 50%
		if (index > hoverIndex && isAbove && index !== hoverIndex + 1) {
			hoverIndex += 1;
		}

		// make sure that the hoverIndex cannot be larger than the indexes of our layout
		if (hoverIndex > components.length) {
			hoverIndex = components.length - 1;
		}

		// return early if we are over the same component we are dragging or
		// if we are right at the nextComponentMiddle to prevent UI tweaking
		if (index === hoverIndex || offsetDiff < 0 && !nextComponentMiddle ||
			(offsetDiff > 0 && hoverMouseLessThanNextComponentMiddle ||
			offsetDiff < 0 && hoverMouseGreaterThanNextComponentMiddle)) {
			return;
		}

		const currentHoveredId = components[hoverIndex] && components[hoverIndex].id;

		// if we dragged this off the bottom, set index to last
		if (!currentHoveredId || hoverIndex === undefined || hoverIndex === -1) {
			hoverIndex = components.length - 1;
		}

		// if we dragged this off the top, set the index to first
		if (hoverMousePercentage < 0) {
			hoverIndex = 0;
		}

		// if we're still here, move the card
		moveComponent(index, hoverIndex);

		// be sure to select the new index so our properties panel does not appear to change
		selectComponent(hoverIndex);

		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().index = hoverIndex;
	}, 10)
};

const ConnectedDropTarget = DropTarget<any>([
	AdComponentIdentifier,
	NewComponentIdentifier
], DropSpec, DropCollector);

const PVMScale = 0.9;
const PVMWidth = PVMScale * 56.25;
const PVMHeight = PVMScale * 100;
const PVMMarginTop = (100 - PVMHeight) / 2;

export class AdBuilder extends React.PureComponent<AdBuilderProps, AdBuilderState> {
	constructor(props: AdBuilderProps) {
		super(props);

		this.state = {
			height: this.getTargetHeight(),
			width: this.getTargetWidth()
		};

		this.styles = {
			adBuilderContainer: {
				height: "100vh",
				width: "100%",
				position: "relative"
			},
			adBuilderExitButton: {
				position: "absolute",
				top: 0,
				right: 0,
				margin: 10
			},
			adBuilderSaving: {
				fontSize: "80%",
				left: 0,
				margin: 10,
				position: "absolute",
				top: 0
			},
			camera: {
				display: "inline-block",
				position: "absolute",
				bottom: "50%",
				left: "50%",
				transform: "translateX(-50%)",
				width: `${.9 * 56.25 * .085}vh`,
				height: `${.9 * 56.25 * .085}vh`,
				background: "rgba(50,50,50,0.7)",
				borderRadius: "50px",
				borderStyle: "inset",
				borderColor: "rgba(60,60,60,0.2)",
				borderWidth: 4
			},
			cameraLEDContainer: {
				position: "absolute",
				bottom: "0",
				width: "100%",
				height: "4.125vh"
			},
			clintonLogo: {
				display: "inline-block",
				position: "absolute",
				right: "1vw",
				bottom: "1vh",
				width: "7vw"
			},
			dropIconContainer: {
				background: "rgba(255, 255, 255, 0.2)",
				textAlign: "center",
				transition: "all 250ms",
				width: "100%"
			},
			dropIconInner: {
				color: "white",
				margin: "0 auto",
				position: "relative",
				top: "50%",
				transform: "translateY(-50%)"
			},
			dropIconText: {
				marginTop: 10
			},
			leftLED: {
				display: "inline-block",
				position: "absolute",
				left: "8%",
				bottom: "1.5vh",
				width: `${.9 * 56.25 * .015}vh`,
				height: `${.9 * 56.25 * .015}vh`,
				background: "rgba(250,250,90,0.25)",
				borderRadius: "50px",
				borderStyle: "inset",
				borderColor: "rgba(60,60,60,0.2)",
				borderWidth: 4
			},
			PVMBody: {
				background: `linear-gradient(225deg, ${ twoGray }, ${ black })`,
				width: `${PVMWidth}vh`,
				height: `${PVMHeight}vh`,
				marginLeft: "auto",
				marginRight: "auto",
				borderTopLeftRadius: 10,
				borderTopRightRadius: 10,
				borderBottomLeftRadius: 10,
				borderBottomRightRadius: 10,
				top: `${PVMMarginTop}vh`,
				paddingTop: "3vh",
				position: "relative"
			},
			PVMScreen: {
				background: "#494B4D",
				width: `${.88 * PVMWidth}vh`,
				height: `${.88 * PVMHeight}vh`,
				marginLeft: "auto",
				marginRight: "auto"
			},
			rightLED: {
				display: "inline-block",
				position: "absolute",
				left: "11%",
				bottom: "1.5vh",
				width: `${.9 * 56.25 * .015}vh`,
				height: `${.9 * 56.25 * .015}vh`,
				background: "rgba(50,50,50,0.7)",
				borderRadius: "50px",
				borderStyle: "inset",
				borderColor: "rgba(60,60,60,0.2)",
				borderWidth: 4
			}
		};

		this.renderComponent = this.renderComponent.bind(this);

		this.updateStyles(props);
	}

	styles: {
		adBuilderContainer: CustomCSS;
		adBuilderExitButton: CustomCSS;
		adBuilderSaving: CustomCSS;
		camera: CustomCSS;
		cameraLEDContainer: CustomCSS;
		clintonLogo: CustomCSS;
		dropIconContainer: CustomCSS;
		dropIconInner: CustomCSS;
		dropIconText: CustomCSS;
		leftLED: CustomCSS;
		PVMBody: CustomCSS;
		PVMScreen: CustomCSS;
		rightLED: CustomCSS;
	};

	componentDidMount() {
		window.addEventListener("resize", this.updateTargetSize.bind(this));
		this.updateStyles(this.props);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateTargetSize.bind(this));
	}

	render() {
		const { adBuilderContainer, adBuilderExitButton } = this.styles;
		const { deselectComponent, exitAd } = this.props;

		return (
			<div onClick={ deselectComponent } style={ adBuilderContainer }>
				{ this.renderSaving() }
				<div style={ adBuilderExitButton }>
					<Icon
						size="smaller"
						name="times"
						onClick={exitAd} />
				</div>
				{ this.renderPVM() }
			</div>
		);
	}

	renderSaving() {
		const { gray, lightestGray } = Colors;
		const { hasChanges } = this.props;
		const color = hasChanges === "Saving..." ? gray : lightestGray;

		return (
			<div style={{ ...this.styles.adBuilderSaving, color }}>
				{ hasChanges }
				{ this.renderPresenceUsers() }
			</div>
		);
	}

	renderPresenceUsers() {
		const { activeAd: { uuid } } = this.props;
		return toggleFeature("notifications",
			(
				<PresenceUsers
					type="ad"
					uuid={ uuid } />
			),
			null
		)
	}

	renderPVM() {
		const { activeAd, connectDropTarget, hasEmptySpace } = this.props;

		if (!activeAd) {
			return null;
		}

		const { PVMBody, PVMScreen } = this.styles;
		const background = hasEmptySpace ? "#FF0080" : "#494B4D";
		const content = (
			<div className="adbuilder-pvm-screen" style={{ ...PVMScreen, background }}>
				{ this.renderComponents() }
				{ this.renderDropPlus() }
			</div>
		);
		const targetContent = connectDropTarget ? connectDropTarget(content) : content;

		return (
			<div style={ PVMBody }>
				{ targetContent }
				{ this.renderCustomDragLayers() }
				{ this.renderCameraAndLEDs() }
			</div>
		);
	}

	renderCameraAndLEDs() {
		const { cameraLEDContainer, camera, leftLED, rightLED, clintonLogo } = this.styles;

		return (
			<div style={ cameraLEDContainer }>
				<div style={ leftLED } />
				<div style={ rightLED } />
				<div style={ camera } />
				<img src="/img/PVM_Logo.svg" style={ clintonLogo } />
			</div>
		);
	}

	renderComponents() {
		const { components } = this.props.activeAd.layout;

		if (!components || components.length === 0) {
			return null;
		}

		return (
			<div>
				{components.map(this.renderComponent)}
			</div>
		);
	}

	renderComponent(component: any, index: number) {
		const { livePreview, resizingComponents, setIsResizing } = this.props;
		const { height, width } = this.state;
		const { id, type } = component;
		const key = id + "_" + type + "_" + index;
		const setResizing = (isResizing: boolean) => setIsResizing(index, isResizing);
		const resizing = resizingComponents.includes(true);
		const props = {
			component: component,
			containerHeight: height,
			containerWidth: width,
			disableDrop: this.props.canDrop,
			index: index,
			key: key,
			livePreview,
			resizing: resizing,
			setIsResizing: setResizing
		};

		// TODO this needs a second look to remove the `any` typing; not a rabbit hole worth spending time on within CON-3494
		switch (component.type) {
			case "feed":
				return <FeedComponentCard { ...props as any } />;
			case "image":
				return <ImageComponentCard { ...props as any } />;
			case "slideshow":
				return <SlideshowComponentCard customDragLayer { ...props as any } />;
			case "video":
				return <VideoComponentCard { ...props as any } />;
			case "ticker":
				return <TickerComponentCard customDragLayer { ...props as any } />;
			default:
				return null;
		}

		return null;
	}

	renderCustomDragLayers() {
		return (
			<React.Fragment>
				<SlideshowDragPreview
					key="slideshow_drag_preview"
					containerWidth={ this.state.width }
					containerHeight={ this.state.height }
				/>
				<TickerDragPreview
					key="ticker_drag_preview"
					containerWidth={ this.state.width }
					containerHeight={ this.state.height }
				/>
			</React.Fragment>
		);
	}

	renderDropPlus() {
		const { canDrop, componentsHeight, dropItem, isOver } = this.props;
		const { dropIconContainer, dropIconInner, dropIconText } = this.styles;
		const droppable = canDrop && dropItem && dropItem.hasOwnProperty("contentType") && isOver;
		const { height } = this.state;
		const remainderHeight = 100 - componentsHeight;
		const remainderPx = height * (remainderHeight / 100);
		const nullOrValue = (value) => remainderHeight < 8 || remainderPx < 50 ? null : value;
		const icon = nullOrValue(
			<Icon name="plus" size="small" />
		);

		return (
			<div style={{
				...dropIconContainer,
				display: droppable ? "inline-block" : "none",
				height: `${remainderHeight}%`
			}}>
				<div style={ dropIconInner }>
					{ icon }
					<div style={ nullOrValue(dropIconText) }>
						Drop to add to this template.
					</div>
				</div>
			</div>
		);
	}

	getTargetHeight() {
		// PVMHeight is 90vh
		// times 0.88 (PVMScreen, not body, height)
		// === 0.7925
		return Math.floor(window.innerHeight * 0.7925);
	}

	getTargetWidth() {
		// width is 9:16 ratio * height
		return (9 * this.getTargetHeight()) / 16;
	}

	updateTargetSize() {
		this.setState(() => ({
			width: this.getTargetWidth(),
			height: this.getTargetHeight()
		}));
	}

	updateStyles(props: AdBuilderProps) {
		const { canDrop, componentsHeight, dropItem, hasEmptySpace, isOver } = props;
		const droppable = canDrop && dropItem && dropItem.hasOwnProperty("contentType") && isOver;
		const emptySpace = hasEmptySpace ? "#FF0080" : "#494B4D";

		this.styles = update(this.styles, {
			dropIconContainer: {
				display: { $set: droppable ? "inline-block" : "none" },
				height: { $set: `${100 - componentsHeight}%` }
			},
			PVMScreen: {
				background: { $set: emptySpace }
			}
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(
	ConnectedDropTarget(AdBuilder)
);
import * as React from "react";
import { DragLayer } from "react-dnd";

import { CustomCSS } from "@connect/Interfaces";
import { Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import Icon from "Components/Global/Icon";

interface IDragPreviewProps {
	currentOffset?: {
		x: number;
		y: number;
	};
	deviceCount?: number;
	groupCount?: number;
	isDragging?: boolean;
	item?: any;
}

export class DragPreview extends React.Component<IDragPreviewProps> {
	constructor(props: IDragPreviewProps) {
		super(props);

		this.styles = {
			badge: {
				alignItems: "center",
				background: Colors.red,
				borderRadius: "1em",
				color: Colors.white,
				fontSize: ".75em",
				height: "1.5em",
				justifyContent: "center",
				lineHeight: "1.5em",
				position: "absolute",
				right: "-0.5em",
				textAlign: "center",
				top: "-0.5em",
				width: "1.5em"
			},
			base: {
				alignItems: "center",
				background: Colors.white,
				borderRadius: 2,
				boxShadow: "2px 2px 8px #ccc",
				display: "flex",
				height: 50,
				left: -5,
				position: "relative",
				top: -5,
				transition: "all 500ms ease",
				userSelect: "none",
				width: 200
			},
			icon: {
				color: Colors.lightGray,
				marginLeft: 18
			},
			layerStyles: {
				height: "100%",
				left: 0,
				position: "fixed",
				pointerEvents: "none",
				top: 0,
				width: "100%",
				zIndex: 100
			},
			multipleBase: {
				background: Colors.white,
				borderRadius: 2,
				boxShadow: "2px 2px 8px #ccc",
				height: 50,
				width: 200
			},
			name: {
				fontSize: 12,
				fontWeight: "bold",
				letterSpacing: 1,
				marginLeft: 6
			}
		};
	}

	styles: {
		badge: CustomCSS;
		base: CustomCSS;
		icon: CustomCSS;
		layerStyles: CustomCSS;
		multipleBase: CustomCSS;
		name: CustomCSS;
	};

	render() {
		const { badge, base, icon: iconStyle, layerStyles, multipleBase, name: nameStyle } = this.styles;
		const { deviceCount, groupCount, item, isDragging } = this.props;

		if (!isDragging || !item) {
			return null;
		}

		const { data, type } = item;

		if (!data) {
			return null;
		}

		const icon = type === "deviceGroup" ? "folder" : "mobile";
		const name =  <Truncate end length={ 14 }>{ data.name }</Truncate>;
		const total = (deviceCount || 0) + (groupCount || 0);
		const multiSelect = deviceCount && groupCount ? multipleBase : {};
		const displayBadge = !total ? null : (
			<div style={ badge }>
				{ total }
			</div>
		);

		return (
			<div style={ layerStyles }>
				<div style={ this.getItemStyles() }>
					<div style={ multiSelect }>
						<div style={ base }>
							{ displayBadge }
							<Icon
								name={ icon }
								size="small"
								style={ iconStyle }
							/>
							<div style={ nameStyle }>
								{ name }
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	getItemStyles(): CustomCSS {
		const { currentOffset } = this.props;

		if (!currentOffset) {
			return {
				display: "none"
			}
		}

		const { x, y } = currentOffset;
		const transform = `translate(${x}px, ${y}px)`;

		return {
			transform,
			WebkitTransform: transform
		};
	}
}
export default DragLayer<IDragPreviewProps>((monitor) => ({
	currentOffset: monitor.getClientOffset(),
	isDragging: monitor.isDragging(),
	item: monitor.getItem()
}))(DragPreview);

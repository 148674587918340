import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, Deployment, DeploymentType, DeploySteps } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { ButtonTypes } from "Components/Global/Button";
import { Input } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { Modal, ModalKeys } from "Components/Global/Modal";
import { createDeploymentAsync, updateDeploymentAsync } from "Data/Actions/DeploymentsAsync";
import { pushWithoutRender } from "Data/Actions/Navigation";
import { errorNotification } from "Data/Actions/Notifications";
import { setActiveDeployment, setDeploymentStep } from "Data/Actions/UI/DeploymentWizard";
import { validateCreateNewStep } from "Data/Objects/Deployments";
import inputValidation from "Data/Objects/Validation";
import { getActiveDeployment, getDeploymentStep } from "Data/Selectors/DeploymentWizard";

const { errorRed, offWhite } = Colors;

const mapStateToProps = (state) => ({
	activeDeployment: getActiveDeployment(state),
	currentStep: getDeploymentStep(state)
});

const mapDispatchToProps = (dispatch) => ({
	createDeployment: (deployment: Deployment) => dispatch(createDeploymentAsync(deployment)),
	onCancel: () => dispatch(pushWithoutRender("/deploy")),
	openDeployment: (uuid: string) => dispatch(pushWithoutRender(`/deploy/${uuid}`)),
	setError: (message: string) => dispatch(errorNotification("Error updating deployment.", message)),
	setScheduleStep: (deployment: Deployment) => {
		dispatch(setActiveDeployment(deployment));
		dispatch(setDeploymentStep(DeploySteps.SCHEDULE));
	},
	updateDeployment: (deployment: Deployment) => dispatch(updateDeploymentAsync(deployment))
});

interface CreateNewDeploymentProps {
	activeDeployment: Deployment;
	createDeployment: (deployment: Deployment) => Promise<Deployment>;
	currentStep: DeploySteps;
	onCancel: () => void;
	openDeployment: (uuid: string) => void;
	setError: (message: string) => void;
	setScheduleStep: (deployment: Deployment) => void;
	updateDeployment: (deployment: Deployment) => Promise<void>;
}

interface CreateNewDeploymentState {
	canSubmit: boolean;
	name: string;
}

export class CreateNewDeployment extends React.Component<CreateNewDeploymentProps, CreateNewDeploymentState> {
	constructor(props: CreateNewDeploymentProps) {
		super(props);

		this.state = this.getDefaults(props);

		this.styles = {
			align: {
				textAlign: "center"
			},
			errorStyle: {
				border: `2px solid ${errorRed}`,
				borderRadius: 6
			},
			input: {
				width: "70%"
			},
			instructions: {
				backgroundColor: offWhite,
				fontSize: "90%",
				margin: "0 auto 20px",
				padding: "20px 0"
			}
		};

		this.disableSubmit = this.disableSubmit.bind(this);
		this.getContent = this.getContent.bind(this);
		this.getDefaults = this.getDefaults.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	styles: CustomCSS;

	componentDidUpdate(prevProps: CreateNewDeploymentProps) {
		const { activeDeployment } = this.props;

		if ((activeDeployment && activeDeployment.name) !== (prevProps.activeDeployment && prevProps.activeDeployment.name)) {
			this.setState(() => this.getDefaults());
		}
	}

	render() {
		if (!this.props.activeDeployment) {
			return null;
		}

		const { align, errorStyle, input, instructions } = this.styles;
		const { canSubmit } = this.state;
		const { activeDeployment, currentStep } = this.props;
		// TODO: make `visible` a memoized selector?
		const visible = (activeDeployment && currentStep === DeploySteps.CREATE_NEW);
		const { description, label, title } = this.getContent();
		const key = `createDeployment_visible-${ String(visible) }` ;
		const actions = [
			{
				text: "Cancel",
				icon: "times-circle",
				callback: this.handleCancel
			},
			{
				text: "Next",
				icon: "chevron-right",
				type: "primary" as ButtonTypes,
				callback: this.handleSubmit,
				disabled: !canSubmit,
				keyCode: ModalKeys.ENTER
			}
		];

		return (
			<Modal
				actions={ actions }
				key={ key }
				modalKey={ key }
				onCancel={ this.handleCancel }
				title={ title }
				visible={ visible }
			>
				<div style={ align }>
					<div>
						<h3>{label}</h3>
						<Input
							autoselect="true"
							autoFocus={ true }
							disableSubmitCallback={ this.disableSubmit }
							id={ activeDeployment.type }
							updateCallback={ this.handleNameChange }
							style={{ ...input, ...(!canSubmit && errorStyle) }}
							value={ this.state.name }
							validator={ inputValidation.name } />
					</div>
					<div style={ instructions }>
						{ description }
					</div>
				</div>
			</Modal>
		);
	}

	disableSubmit(canSubmit: boolean) {
		this.setState(() => ({ canSubmit }));
	}

	getContent() {
		const { activeDeployment: { type, uuid } } = this.props;
		const action = uuid ? "Edit" : "Create";

		if (type === DeploymentType.EVENT) {
			return {
				description: (<p>Events temporarily interrupt deployed schedules.<br />Use this for one-time promotions.</p>),
				label: "Event Name",
				title: {
					icon: "exclamation-triangle",
					text: `${ action } an Event`
				}
			};
		}

		return {
			description: (<p>Schedules play 24/7/365 unless interrupted by an event.<br />Use this for daily content.</p>),
			label: "Schedule Name",
			title: {
				icon: "calendar",
				text: `${ action } a Schedule`
			}
		};
	}

	getDefaults(props?: CreateNewDeploymentProps) {
		const { activeDeployment } = props || this.props;

		if (!activeDeployment || !activeDeployment.name) {
			const defaultName = `Untitled Deployment ${Utils.getDefaultTimestamp()}`;
			return {
				canSubmit: defaultName.length > 0,
				name: defaultName
			};
		}

		const { name } = activeDeployment

		return {
			canSubmit: name.length > 0,
			name
		};
	}

	handleCancel() {
		this.props.onCancel();

		this.setState(() => this.getDefaults());
	}

	handleNameChange(name: string) {
		if (name === "") {
			this.disableSubmit(false);
		}

		this.setState(() => ({ name }));
	}

	handleSubmit() {
		const { activeDeployment, createDeployment, openDeployment, setError, setScheduleStep,
			updateDeployment } = this.props;
		const { name } = this.state;
		const deployment = Object.assign({
			// set schedule, devices, and deviceGroups on the initial object just in case we don't have them yet
			deviceGroups: [],
			devices: [],
			schedule: [],
			submittedTo: []
		}, activeDeployment, { name });
		const { isValid, message } = validateCreateNewStep(deployment);

		if (!isValid) {
			setError(message);
			return;
		}

		if (activeDeployment.uuid) {
			// If we're editing an existing deployment, perform an update action
			updateDeployment(deployment).then(() => setScheduleStep(deployment));
		} else {
			createDeployment(deployment).then((result: Deployment) => {
				openDeployment(result.uuid);
			});
		}

		this.setState(() => this.getDefaults());
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewDeployment);

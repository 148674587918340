import * as React from "react";
import { Tooltip } from "antd";

import { Utils } from "@connect/Utils";

interface TruncateProps {
	children: string | null | undefined;
	length?: number;
	end?: boolean;
	tooltipDisabled?: boolean;
}

class Truncate extends React.PureComponent<TruncateProps> {
	constructor(props: TruncateProps) {
		super(props);
	}

	render() {
		const { children, end, length = 11, tooltipDisabled } = this.props;
		if (children && children.length <= length) {
			return (
				<span className="selectable">
					{ children }
				</span>
			);
		}

		let truncatedName;

		if (end) {
			truncatedName = Utils.getEndTruncatedName(children as string, length);
		} else {
			truncatedName = Utils.getTruncatedName(children as string, length);
		}


		const truncatedSpan = <span className="selectable"> { truncatedName }</span>

		if (tooltipDisabled) {
			return truncatedSpan;
		}

		return (
			<Tooltip title= { children }>
				{ truncatedSpan }
			</Tooltip>
		);
	}
}

export default Truncate;
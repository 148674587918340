import { Slider } from "antd";
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { AdMediaLink, CustomCSS, IAd, IMedia, IMediaUpload, ISlideshowComponent,
	MediaContext, SlideshowAnimations } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Button } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import DragNDropSortableList from "Components/Global/DragNDropSortableList";
import { DragNDropListItem } from "Components/Global/DragNDropSortableListItem";
import OptionButton from "Components/Global/OptionButton";
import { setSelectMediaModal } from "Data/Actions/UI/Modals";
import {
	addMediaToComponent,
	removeMediaFromComponent,
	updateAdDuration,
	updateAllAdMedia,
	updateComponent
} from "Data/Actions/UI/AdBuilder";
import { SlideshowDirections, SlideshowDurations, SlideshowTransitionDurations } from "Data/Objects/AdTemplates";
import { getCurrentSlide, getMediaIds, getSelectedComponentIndex } from "Data/Selectors/AdBuilder";
import { getMediasByIds } from "Data/Selectors/Media";
import { getAdBuilderAd, getSelectedComponentByIndex } from "Data/Selectors/UI";
import { getUploadingMedia } from "Data/Selectors/Media";

const { white, primaryBlue } = Colors;
const originator = "slideshow_image";

const mapDispatchToProps = (dispatch) => ({
	addComponentMedia: (index: number, ids: string[], type: string) => dispatch(addMediaToComponent(index, ids, type)),
	onComponentUpdated: (index: number, component: ISlideshowComponent) =>
		dispatch(updateComponent(index, component, true)),
	onMediaUpdated: (media: AdMediaLink[]) => dispatch(updateAllAdMedia(media)),
	removeComponentMedia: (index: number, ids: string[], type: string, indexToRemove?: number) =>
		dispatch(removeMediaFromComponent(index, ids, type, indexToRemove)),
	showSelectMediaModal: () => dispatch(setSelectMediaModal(true, [], "", MediaContext.IMAGE)),
	updateDuration: (duration?: string) => dispatch(updateAdDuration(duration))
});

const mapStateToProps = (state) => {
	const ad = getAdBuilderAd(state);
	const selectedComponentIndex = getSelectedComponentIndex(state);
	const component = getSelectedComponentByIndex(ad, selectedComponentIndex);
	const mediaIds = getMediaIds(state);
	const media = getMediasByIds(state, mediaIds);
	const currentUploads = getUploadingMedia(state, originator);

	return {
		ad,
		media,
		component,
		selectedComponentIndex,
		contentsHeightPercent: 0,
		livePreview: false,
		currentUploads,
		currentSlide: getCurrentSlide(state)
	}
}

interface ISlideshowPanelProps {
	ad: IAd;
	addComponentMedia: (index: number, ids: string[], type: string) => void;
	selectedComponentIndex: number;
	component: ISlideshowComponent;
	mediaId: string;
	media: IMedia[];
	currentSlide: number;
	currentUploads: IMediaUpload[];
	onMediaUpdated: (media: AdMediaLink[]) => void;
	onComponentUpdated: (index: number, component: ISlideshowComponent) => void;
	removeComponentMedia: (index: number, ids: string[], type: string, indexToRemove?: number) => void;
	showSelectMediaModal: () => void;
	updateDuration: (duration?: string) => void;
}

class SlideshowPropertiesPanel extends React.Component<ISlideshowPanelProps> {
	constructor(props: ISlideshowPanelProps) {
		super(props);

		this.styles = {
			row: {
				marginBottom: 14
			},
			label: {
				width: 100,
				display: "inline-block"
			},
			center: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			small: {
				fontSize: "0.8em"
			},
			button: {
				border: 0,
				marginRight: 12,
				color: white,
				backgroundColor: "#373a46"
			},
			buttonActive: {
				border: 0,
				marginRight: 12,
				color: white,
				backgroundColor: primaryBlue
			},
			buttonRow: {
				display: "flex",
				alignItems: "center",
				margin: "5px 10px"
			},
			dPad: {
				display: "grid",
				gridTemplateColumns: "34px 34px 34px",
				gridGap: 10,
				alignItems: "center",
				justifyContent: "center",
				margin: 10
			},
			dPadButton: {
				height: 34,
				width: 34,
				display: "flex",
				fontSize: "1.4em",
				fontWeight: 900,
				border: 0,
				outline: "none",
				cursor: "pointer"
			},
			dPadButtonActive: {
				background: primaryBlue
			},
			upButton: {
				gridColumnStart: 2,
				gridRowStart: 1
			},
			rightButton: {
				gridColumnStart: 3,
				gridRowStart: 2
			},
			downButton: {
				gridColumnStart: 2,
				gridRowStart: 3
			},
			leftButton: {
				gridColumnStart: 1,
				gridRowStart: 2
			},
			transitionButton: {
				width: 43,
				height: 22
			},
			floatRight: {
				float: "right"
			},
			slider: {
				marginLeft: 10,
				marginRight: 10
			},
			empty: {
				height: 22
			}
		}

		this.handleDurationChange = this.handleDurationChange.bind(this);
		this.showSelectMediaModal = this.showSelectMediaModal.bind(this);
		this.updateFileList = this.updateFileList.bind(this);
		this.renderTransitionButton = this.renderTransitionButton.bind(this);
		this.renderTransitionLengthButton = this.renderTransitionLengthButton.bind(this);
		this.setTransition = this.setTransition.bind(this);
		this.renderDPadButton = this.renderDPadButton.bind(this);
		this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { ad, component } = this.props;
		const { id, durationPerSlide } = component;
		const slideCount = ad.layout.media.filter((aml) => aml.layoutPosition === id).length;
		const duration = slideCount * durationPerSlide;

		return (
			<div>
				{ this.renderComponentDimensions() }
				{ this.renderSlideshowSelectImage() }
				{ this.renderFileList() }
				{ this.renderNumberSlides(slideCount) }
				{ this.renderTotalDuration(duration) }
				{ this.renderDurationSlider() }
				{ this.renderTransitionButtons() }
				{ this.renderTransitionLengthButtons() }
				{ this.renderDirectionButtons() }
			</div>
		);
	}

	renderComponentDimensions() {
		const { width, height } = this.props.component;

		// calculate the component dimensions in px... they come in as %
		// note that this currently assumes 1920x1080 in portrait
		const componentWidth = Math.round(1080 * (width.value / 100));
		const componentHeight = Math.round(1920 * (height.value / 100));

		return (
			<div style={this.styles.row}>
				Component Dimensions: {componentWidth}x{componentHeight}px
			</div>
		);
	}

	renderSlideshowSelectImage() {
		return (
			<div style={this.styles.row}>
				<Button fluid
					style={{ border: 0 }}
					type="primary"
					color={ primaryBlue}
					onClick={ this.showSelectMediaModal }
				>
					Select Image
				</Button>
			</div>
		);
	}

	renderNumberSlides(slideCount: number) {
		const { row, floatRight } = this.styles;

		if (!this.props.media) {
			return null;
		}

		return (
			<div style={ row }>
				Number of Slides: #
				<div style={ floatRight }>
					{ slideCount }
				</div>
			</div>
		);
	}

	renderTotalDuration(duration: number) {
		const { row, floatRight } = this.styles;
		if (!this.props.media) {
			return null;
		}

		return (
			<div style={ row }>
				Total Duration: #
				<div style={ floatRight }>
					{ duration } sec
				</div>
			</div>
		);
	}

	renderFileList() {
		const { media, ad, component, currentSlide } = this.props;

		if (!media) {
			return null;
		}

		let files = ad.layout.media
			.filter((m) => {
				return m.layoutPosition === component.id;
			})
			.map((m, i) => ({
				name: this.getFileName(m.mediaId),
				sort: m.sort,
				uuid: m.mediaId,
				active: currentSlide === i
			}));

		let uploads = this.props.currentUploads.map((upload) => ({
			name: upload.name,
			sort: 999,
			uuid: upload.uuid,
			active: false,
			progress: upload.progress
		}));

		const data = uploads ? files.concat(uploads) : files;

		return (
			<div style={ this.styles.row }>
				File List:
				<DragNDropSortableList
					onUploadSuccess={ this.handleUploadSuccess }
					data={ data }
					sortKey="sort"
					updateData={ this.updateFileList }
				/>
			</div>
		);
	}

	handleUploadSuccess(media: IMedia) {
		const { addComponentMedia, component, selectedComponentIndex } = this.props;

		addComponentMedia(selectedComponentIndex, [ media.uuid ], component.type);
	}

	showSelectMediaModal() {
		this.props.showSelectMediaModal();
	}

	updateFileList(updatedFiles: DragNDropListItem[], indexToRemove?: number) {
		const { ad, addComponentMedia, component, onMediaUpdated, removeComponentMedia, selectedComponentIndex } = this.props;
		const inFileList = (m) => m.layoutPosition === component.id;
		const toId = (idKey) => (objWithId) => objWithId[idKey];
		const notInOther = (theirArr, theirKey, ourKey) =>
			(ourFile) => !theirArr.find((theirFile) => theirFile[theirKey] === ourFile[ourKey]);
		const previousFiles = ad.layout.media.filter(inFileList);

		if (previousFiles.length > updatedFiles.length) {
			const oldMedia = previousFiles
				.filter(notInOther(updatedFiles, "uuid", "mediaId"))
				.map(toId("mediaId"));

			removeComponentMedia(selectedComponentIndex, oldMedia, component.type, indexToRemove);
		} else if (previousFiles.length < updatedFiles.length) {
			const newMedia = updatedFiles
				.filter(notInOther(previousFiles, "mediaId", "uuid"))
				.map(toId("uuid"));

			addComponentMedia(selectedComponentIndex, newMedia, component.type);
		} else {
			// everything but our component
			const notThisMedia = ad.layout.media.filter((m) => m.layoutPosition !== component.id);
			const updatedMedia = updatedFiles.map((f, i) => ({
				layoutPosition: component.id,
				mediaId: f.uuid,
				sort: i // the DragNDropSortableList re-orders the file array for us, so we can just use index here
			}));

			onMediaUpdated([ ...notThisMedia, ...updatedMedia ]);
		}
	}

	getFileName(fileId: string) {
		const media = this.props.media.filter(m => m.uuid === fileId);
		return media && media.length && media[0].name || "";
	}

	renderDurationSlider() {
		const { component, media } = this.props;
		const { row, slider } = this.styles;

		if (!media) {
			return null;
		}

		// ant slider requires marks; if we use the seconds, the marks
		// aren't symmetrical and will be at 5, 10, 15, and 30 seconds
		const marks = {
			1: `${SlideshowDurations.SHORTEST} sec`,
			2: `${SlideshowDurations.SHORT} sec`,
			3: `${SlideshowDurations.LONG} sec`,
			4: `${SlideshowDurations.LONGEST} sec`
		};

		// translate durations to marks on the slider
		const getMark = (duration: number) => {
			switch (duration) {
				case 5: return 1;
				case 10: return 2;
				case 15: return 3;
				case 30: return 4;
				default: return 1;
			}
		}

		return (
			<div style={ row }>
				Duration Per Slide:
				<div style={ slider }>
					<Slider
						tipFormatter={ null }
						step={ null }
						marks={ marks }
						min={ 1 }
						max={ 4 }
						defaultValue={ getMark(component.durationPerSlide) }
						onAfterChange={ this.handleDurationChange } />
				</div>
			</div>
		);
	}

	handleDurationChange(index: number) {
		// translate slider marks to actual durations
		const { component, selectedComponentIndex, onComponentUpdated, updateDuration } = this.props;
		const { SHORTEST, SHORT, LONG, LONGEST } = SlideshowDurations;

		const value = (() => {
			switch (index) {
				case 1: return SHORTEST;
				case 2: return SHORT;
				case 3: return LONG;
				case 4: return LONGEST;
				default: return SHORT;
			}
		})();

		onComponentUpdated(selectedComponentIndex, update(component, {
			durationPerSlide: { $set: value }
		}));

		updateDuration();
	}

	renderTransitionButtons() {
		const { row, buttonRow } = this.styles;

		if (!this.props.media) {
			return null;
		}

		const  options = this.getArrayFromObject(SlideshowAnimations);

		return (
			<div style={ row }>
				Transition:
				<div style={ buttonRow }>
					{ options.map(this.renderTransitionButton) }
				</div>
			</div>
		);
	}

	renderTransitionButton(transition: string) {
		const { animation, id } = this.props.component;
		const currentAnimation = animation || "none";

		return (
			<OptionButton
				rounded
				key={ transition + currentAnimation + id }
				style={ this.styles.transitionButton }
				selected={ transition === currentAnimation }
				size="small"
				onClick={ this.setTransition(transition) }
			>
				{ Utils.properCase(transition) }
			</OptionButton>
		);
	}

	setTransition(transition: string) {
		return () => {
			const { onComponentUpdated, selectedComponentIndex, component } = this.props;

			onComponentUpdated(selectedComponentIndex, update(component, {
				animation: { $set: transition }
			}));
		}
	}

	renderTransitionLengthButtons() {
		const { media, component } = this.props;
		const { empty, buttonRow, row } = this.styles;

		if (!media) {
			return null;
		}

		let content: JSX.Element;

		if (component.animation === "none") {
			content = (<div style={ empty }>N/A</div>);
		}

		const options = this.getArrayFromObject(SlideshowTransitionDurations);

		content = (
			<div style={ buttonRow }>
				{ options.map(this.renderTransitionLengthButton) }
			</div>
		);

		return (
			<div style={row}>
				Transition Duration:
				{content}
			</div>
		);
	}

	renderTransitionLengthButton(length: number) {
		const { id, transitionLength } = this.props.component;
		const currentLength = transitionLength || 0.5;

		return (
			<OptionButton
				rounded
				key={ length + currentLength + id }
				style={ this.styles.transitionButton }
				selected={ length === currentLength }
				size="small"
				onClick={ this.setTransitionLength(length) }
			>
				{ length === SlideshowTransitionDurations.LONGEST ? "Slow" : "Fast" }
			</OptionButton>
		);
	}

	setTransitionLength(length: number) {
		return () => {
			const { onComponentUpdated, selectedComponentIndex, component } = this.props;

			onComponentUpdated(selectedComponentIndex, update(component, {
				transitionLength: { $set: length }
			}));
		}
	}

	renderDirectionButtons() {
		const { component, media } = this.props;
		const { animation } = component;
		const hasDirections = animation === "push" || animation === "wipe";

		if (!media || !hasDirections) {
			return null;
		}

		const directions = [ "UP", "RIGHT", "DOWN", "LEFT" ];
		const { row, dPad } = this.styles;

		return (
			<div style={ row }>
				Direction:
				<div style={ dPad }>
					{ directions.map(this.renderDPadButton) }
				</div>
			</div>
		);
	}

	renderDPadButton(direction: string) {
		const { symbol } = SlideshowDirections[direction];
		const { component } = this.props;
		const { direction: currentDirection } = component;
		const thisDirection = direction.toLowerCase();

		return (
			<OptionButton
				rounded
				size="medium"
				selected={ thisDirection === currentDirection }
				key={ `${direction}` }
				style={{
					...this.styles.dPadButton,
					...this.styles[`${thisDirection}Button`]
				}}
				onClick={ this.setDirection(thisDirection) }
			>
				{ symbol }
			</OptionButton>
		);
	}

	setDirection(direction: string) {
		return () => {
			const { onComponentUpdated, selectedComponentIndex, component } = this.props;

			onComponentUpdated(selectedComponentIndex, update(component, {
				direction: { $set: direction }
			}));
		}
	}

	getArrayFromObject(obj: {}) {
		return Object
			.keys(obj)
			.filter(Boolean)
			.map((key) => obj[key]);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideshowPropertiesPanel);
import * as React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import { hasPermission } from "Data/Objects/Permissions";

interface LinkProps extends RouterLinkProps {
	permissions?: string[];
	hide?: boolean;
}

export default class Link extends React.PureComponent<LinkProps> {
	constructor(props: LinkProps) {
		super(props);
	}

	render() {
		const { children, hide, permissions, ...passThroughProps } = this.props;

		const allowed = !permissions || permissions.every((permission) => hasPermission(permission));

		if (!allowed && hide) {
			return null;
		}

		if (!allowed) {
			return children;
		}

		return (
			<RouterLink { ...passThroughProps }>
				{ children }
			</RouterLink>
		);
	}
}
import { Popover, Icon as AntIcon, Tooltip } from "antd";
import * as update from "immutability-helper";
import * as moment from "moment";
import * as React from "react";
import {
	DragSource,
	DragSourceCollector,
	DragSourceConnector,
	DragSourceMonitor,
	DragSourceSpec,
	DropTarget,
	DropTargetCollector,
	DropTargetConnector,
	DropTargetMonitor,
	DropTargetSpec
} from "react-dnd";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { ActiveUuidRoute, CustomCSS, HealthReport, HealthReportColumn, HealthReportRow,
	IDevice, IStore } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon, Truncate, HelpPopover } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import Status from "Components/Global/Status";
import DropColumnPlaceholder from "Components/Health/DropColumnPlaceholder";
import { updateReportAsync } from "Data/Actions/HealthReportAsync";
import { setDeviceHealthModalUUID } from "Data/Actions/UI/Modals";
import { DragTable } from "Data/Objects/DragTypes";
import { HealthReportColumns } from "Data/Objects/HealthReport";
import { getStores } from "Data/Selectors/Company";
import { getSelectedHealthReport, getSelectedReportResult } from "Data/Selectors/HealthReports";
import { getActiveUuid, getReportIsRunning } from "Data/Selectors/UI";

const { black, gray, orange, primaryGreen, red, yellow, white } = Colors;

const mapStateToProps = (state, ownProps) => {
	const activeUuid = getActiveUuid(state, "health");
	const activeResult = getSelectedReportResult(state, activeUuid);
	const resultSet = activeResult && activeResult.results ? activeResult.results : [];

	return {
		activeHealthReport: getSelectedHealthReport(state, activeUuid),
		activeReportResult: Utils.sort(resultSet, ownProps.sort, ownProps.sortDirection === "asc"),
		loading: getReportIsRunning(state, activeUuid),
		noResults: activeResult && resultSet.length === 0,
		stores: getStores(state)
	};
};

const mapDispatchToProps = (dispatch) => ({
	openDeviceHealthModal: (uuid: string) => dispatch(setDeviceHealthModalUUID(uuid)),
	updateReport: (report: HealthReport) =>
		dispatch(updateReportAsync(report))
});

const reportDragCollector: DragSourceCollector<
Partial<DragNDropReportColumnCollectedProps>
> = (dragConnect: DragSourceConnector, monitor: DragSourceMonitor) => ({
	connectDragSource: dragConnect.dragSource(),
	currentlyDragging: monitor.isDragging()
});

const reportDragSource: DragSourceSpec<DragNDropReportColumnProps, DragNDropReportColumnDragObject> = {
	beginDrag(props: DragNDropReportColumnProps) {
		return {
			index: props.index,
			key: props.column
		};
	},
	endDrag(props: DragNDropReportColumnProps, monitor: DragSourceMonitor, component: React.Component) {
		const dropped = monitor.didDrop();
		const mounted = component !== null;

		if (dropped && mounted) {
			const { activeHealthReport, column, index } = props;

			const result = monitor.getDropResult() as { key: string, index: number };
			const correctItem = result && result.key === column;
			let columns: any = { $push: [ column ] };

			const forward = index + 1 <= result.index;
			let moved = false;

			// if we moved the item to the index it was in before, return
			if (index + 1 === result.index) {
				return;
			}

			// if moving to the last drag area
			if (result && result.index === index) {
				columns = { $splice: [ [ index, 1 ], [ (activeHealthReport.columns || []).length, 0, column ] ] };
				moved = true;
			}

			// if moving something to a new index
			if (result && result.index !== undefined && result.index !== index + 1 && !moved) {
				columns = { $splice: [ [ index, 1 ], [ forward ? result.index - 1 : result.index, 0, column ] ] };
				moved = true;
			}

			if (correctItem) {
				const newReport = update(activeHealthReport, {
					columns
				});

				props.updateReport(newReport);
			}
		}
	}
};

const reportDropCollector: DropTargetCollector<
Partial<DragNDropReportColumnCollectedProps>
> = (dropConnect: DropTargetConnector, monitor: DropTargetMonitor) => ({
	connectDropTarget: dropConnect.dropTarget(),
	isOver: monitor.isOver()
});

const reportDropTarget: DropTargetSpec<any> = {
	drop(props: DragNDropReportColumnProps, monitor: DropTargetMonitor, component: React.Component) {
		if (!monitor.getItem()) {
			return false;
		}

		return Object.assign({}, monitor.getItem(), { index: props.index });
	}
};

interface DragNDropReportColumnDragObject {
	index: number;
	key: HealthReportColumn;
}

interface DragNDropReportColumnCollectedProps {
	connectDragSource: Function;
	connectDropTarget: Function;
	currentlyDragging: boolean;
	isOver: boolean;
}

interface DragNDropReportColumnProps extends RouteComponentProps<ActiveUuidRoute> {
	activeHealthReport: HealthReport;
	activeReportResult: IDevice[];
	additionalStyle?: CustomCSS;
	column: HealthReportColumn;
	connectDragSource: Function;
	connectDropTarget: Function;
	index: number;
	currentlyDragging: boolean;
	isOver: boolean;
	loading: boolean;
	lockedColumn: boolean;
	noResults: boolean;
	openDeviceHealthModal: (uuid: string) => void;
	sort: HealthReportColumn;
	sortDirection: "asc" | "desc";
	updateReport: (report: HealthReport) => void;
	stores: IStore[];
}

export class DragNDropReportColumn extends React.Component<DragNDropReportColumnProps> {
	constructor(props: DragNDropReportColumnProps) {
		super(props);

		this.styles = {
			columnHeader: {
				borderColor: "#f7f7f7",
				borderStyle: "solid",
				borderWidth: "0 1px 1px 0",
				display: "flex",
				flex: "1 0 auto",
				justifyContent: "flex-start",
				minWidth: 120,
				padding: "16px 8px",
				position: "sticky",
				textAlign: "left",
				top: 0,
				borderBottom: "1px solid #e9e9e9"
			},
			columnName: {
				alignSelf: "flex-start",
				display: "inline-block",
				flex: "1 1 auto",
				fontSize: 12,
				fontWeight: "bold",
				lineHeight: 1.7
			},
			columnSort: {
				alignSelf: "flex-start",
				flex: "0 auto",
				padding: "0 6px"
			},
			lockedColumn: {
				left: 0,
				position: "sticky",
				zIndex: 1
			},
			helpLedList: {
				display: "flex",
				flexDirection: "column",
				marginTop: 16
			},
			helpOuter: {
				alignSelf: "flex-start",
				display: "inline-block",
				flex: "0 auto",
				padding: "0 3px"
			},
			hover: {
				display: "flex",
				flex: "1 0 270px"
			},
			popoverStyle: {
				padding: "0 14px"
			},
			removeColumn: {
				alignSelf: "flex-end",
				flex: "0 1 auto",
				fontWeight: "bold"
			},
			resultCell: {
				height: 26,
				overflow: "hidden",
				padding: "24px 8px",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				borderBottom: "1px solid #e9e9e9",
				display: "flex",
				alignItems: "center"
			},
			tableColumn: {
				flex: "1 0 auto",
				minWidth: 100,
				position: "relative"
			},
			tableColumnNoResults: {
				background: "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
				content: "",
				height: "100%",
				pointerEvents: "none",
				position: "absolute",
				top: 0,
				width: "100%"
			}
		};

		this.openDeviceModal = this.openDeviceModal.bind(this);
		this.removeColumn = this.removeColumn.bind(this);
		this.renderResultCell = this.renderResultCell.bind(this);
		this.sortColumn = this.sortColumn.bind(this);
		this.renderUptime = this.renderUptime.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { column, index, isOver, lockedColumn, currentlyDragging } = this.props;
		const { hover, tableColumn } = this.styles;
		let renderedColumn = this.renderColumn(lockedColumn);

		if (lockedColumn) {
			return renderedColumn;
		}

		let style = {
			...tableColumn,
			display: currentlyDragging ? "none" : "block"
		};

		if (isOver) {
			style = Object.assign({}, style, hover);
		}

		return this.connectIfDroppable(
			<div key={"column_" + column + index} style={style}>
				<DropColumnPlaceholder overAdjacent={isOver} />
				{renderedColumn}
			</div>
		);
	}

	renderColumn(locked?: boolean) {
		const { activeReportResult, additionalStyle, column, index, isOver, noResults } = this.props;
		const { tableColumn, lockedColumn, tableColumnNoResults } = this.styles;
		const key = locked ? "column_" + column + index : "";
		const nameColumn = column === "device.name";
		const statusColumn = column === "status.timestamp";
		let noResultsMask;
		let style;

		if (noResults) {
			noResultsMask = (
				<div style={tableColumnNoResults} />
			);
		}

		if (locked) {
			style = {
				...tableColumn,
				...lockedColumn,
				...additionalStyle
			};

			if (statusColumn) {
				style = { ...style, minWidth: 46 };
			}

			if (nameColumn) {
				style = { ...style, left: 46, width: 150, maxWidth: 150 };
			}
		}

		if (isOver && !locked) {
			style = tableColumn;
		}

		if (locked) {
			return (
				<div key={key} style={style}>
					<div>
						{noResultsMask}
						{this.renderColumnHeaders()}
						{activeReportResult.map(this.renderResultCell)}
					</div>
				</div>
			);
		}

		return (
			<div key={key} style={style}>
				{noResultsMask}
				{this.renderColumnHeaders()}
				{activeReportResult.map(this.renderResultCell)}
			</div>
		);
	}

	renderColumnHeaders() {
		const { column, index, sort } = this.props;
		const { columnName } = this.styles;
		const activeSort = column === sort;
		const statusColumn = column === "status.timestamp";
		const name = statusColumn ? null : (
			<span style={columnName}>
				{HealthReportColumns[column].name}
			</span>
		);

		return (
			<div
				key={"header_" + column + index}
				onClick={this.sortColumn(column)}
				style={ this.getHeaderStyles(column, activeSort) }
			>
				{ name }
				{this.renderColumnHelp()}
				{this.renderColumnSort()}
				{this.renderRemoveColumn()}
			</div>
		);
	}

	getHeaderStyles(column: string, activeSort: boolean) {
		const { columnHeader } = this.styles;

		let headerStyles = {
			...columnHeader,
			background: activeSort ? "#eee": "#f7f7f7"
		}

		if (column === "status.timestamp") {
			headerStyles = {
				...headerStyles,
				maxWidth: 46,
				minWidth: 46,
				padding: "17px 8px",
				justifyContent: "center"
			}
		}

		if (column === "device.name") {
			headerStyles = {
				...headerStyles,
				borderRight: "1px solid #e9e9e9"
			}
		}

		return headerStyles;
	}

	renderColumnHelp() {
		const { column } = this.props;
		const { help, name, url } = HealthReportColumns[column];

		if (!help) {
			return null;
		}

		const { helpLedList, helpOuter } = this.styles;
		let helpContent: string | React.ReactNode = help;

		switch (url) {
			case "ledStatus":
				helpContent = (
					<div>
						{ help }
						<div style={ helpLedList }>
							<Status text="Green - Normal" color="green" />
							<Status text="Purple - No Network" color="purple" />
							<Status text="Blue - Schedule Error" color="blue" />
							<Status text="Yellow - Sleep Mode" color="yellow" />
							<Status text="Red - Camera Error" color="red" />
						</div>
					</div>
				);
				break;
			case "cpuTemp":
				helpContent = (
					<div>
						{ help }
						<div style={ helpLedList }>
							<Status text="Green - Normal" color="green" />
							<Status text="Orange - High Temperature" color="orange" />
							<Status text="Red - Contact Support" color="red" />
						</div>
					</div>
				);
				break;
			case "statusTimestamp":
				helpContent = (
					<div>
						{ help }
						<div style={ helpLedList }>
							<Status icon="check-circle" text="Green - Recent Information" color="green" />
							<Status icon="question-square" text="Yellow - Questionable Information" color="yellow" />
							<Status icon="exclamation-triangle" text="Red - Outdated Information" color="red" />
						</div>
					</div>
				);
				break;
		}

		return (
			<span onClick={this.stopPropagation} style={ helpOuter }>
				<HelpPopover title={ name }>
					<div>{ helpContent } </div>
				</HelpPopover>
			</span>
		);
	}

	renderColumnSort() {
		const { column, sort, sortDirection } = this.props;
		const activeSort = column === sort;
		const arrow = sortDirection === "asc" && activeSort ? "up" : "down";

		return (
			<span
				style={{
					...this.styles.columnSort,
					color: activeSort ? black : gray
				}}
			>
				<AntIcon type={arrow}/>
			</span>
		);
	}

	renderRemoveColumn() {
		const { column, index } = this.props;
		const lockedColumns = [ "status.timestamp", "device.name" ];

		if (lockedColumns.includes(column) && index < 2) {
			return null;
		}

		return (
			<span
				onClick={this.removeColumn(column)}
				style={this.styles.removeColumn}
			>
				&times;
			</span>
		);
	}

	renderResultCell(row: HealthReportRow, index: number) {
		const { column } = this.props;
		const content = this.getCellContent(row, column);

		return (
			<div
				key={column + index}
				style={ this.getCellStyles(content, index, column) }
			>
				{content}
			</div>
		);
	}

	getCellStyles(content: any, index: number, column: string) {
		const lastRun = this.props.activeHealthReport.executedAt;
		const isName = column === "device.name";
		const isStatus = column === "status.timestamp";

		let cellStyles = {
			...this.styles.resultCell,
			backgroundColor: index % 2 === 0 ? white : "#fcfcfc",
			color: content === "undefined" ? "lightgray" : null,
			borderRight: isName ? "1px solid #e9e9e9" : "none",
			justifyContent: isStatus ? "center" : this.styles.resultCell
		}

		if (lastRun === null && column !== "device.name" && column !== "status.timestamp") {
			cellStyles = {
				...cellStyles,
				filter: "blur(8px)"
			}
		}

		return cellStyles;
	}

	getCellContent(row: HealthReportRow, column: string) {
		const deviceColumn = [ "device.name" ];

		const rawStringColumns = [ "device.group", "device.model",
			"device.serial", "device.software_version", "status.ip",
			"status.wifi_network_name", "device.store" ];

		const dateTimeColumns = [ "device.install_date", "status.last_schedule_download" ];

		const snapshotColumn = [ "device.snapshot" ];

		const byteColumns = [ "status.available_storage" ];

		const percentageColumns = [ "status.available_memory_usage", "status.heap_memory_usage" ];

		const booleanColumns = [ "status.last_deployment_failed", "status.online" ];

		const secondsColumns = [ "status.uptime" ];

		const ledStatusColumns = [ "status.camera_on", "status.cpu_temp", "status.display_on",
			"status.led_status", "status.pusher_connected" ];

		const networkColumn = [ "status.connection_type" ];
		const statusColumns = [ "status.timestamp" ];

		let content: any = "undefined";

		const value = row[column];

		if (deviceColumn.indexOf(column) >= 0) {
			// open device health modal
			content = this.renderDeviceModalLink(row, column);
		}

		if (rawStringColumns.indexOf(column) >= 0) {
			// Render raw string
			content = <Truncate length={ 24 }>{ value }</Truncate>;
		}

		if (dateTimeColumns.indexOf(column) >= 0) {
			// Render datetime string
			content = Utils.getHumanReadableDate(value);
		}

		if (snapshotColumn.indexOf(column) >= 0) {
			// Render URLs
			content = this.renderSnapshotLink(row, column);
		}

		if (byteColumns.indexOf(column) >= 0) {
			// Render byte counts
			content = Utils.getHumanReadableBytesize(value);
		}

		if (percentageColumns.indexOf(column) >= 0) {
			// Render percentage
			content = `${value}%`;
		}

		if (booleanColumns.indexOf(column) >= 0) {
			// Render booleans
			content = this.renderBooleanContent(row, column);
		}

		if (secondsColumns.indexOf(column) >= 0) {
			// Render seconds
			content = this.renderUptime(value);
		}

		if (ledStatusColumns.indexOf(column) >= 0) {
			// Render status LEDs, in the device overview style
			content = this.renderStatusDot(row, column);
		}

		if (networkColumn.indexOf(column) >= 0) {
			content = this.renderConnectionType(row, column);
		}

		if (statusColumns.indexOf(column) >= 0) {
			// render graphical status
			content = this.renderTimestampStatusIcon(row, column);
		}

		return content;
	}

	renderUptime(uptime: number) {
		const rebootTime = new Date(Date.now().valueOf() - (uptime * 1000)).toString();
		return (
			<Tooltip title={ rebootTime }>
				{ Utils.getHumanReadableDuration(uptime) } ago
			</Tooltip>
		)
	}

	renderStatusDot(row: HealthReportRow, column: string) {
		const name = column.split(".").pop();
		const value = row[column];
		let color;
		let text;

		switch (name) {
			case "cpu_temp":
				color = this.getColor(value, 100, 125);
				text = Utils.formatTemperature(Number.parseInt(value, 10));
				break;
			case "camera_on":
			case "display_on":
				color = value ? "green" : "red";
				text = value ? "On" : "Off";
				break;
			case "led_status":
				color = value;
				text = this.getLedText(value);
				break;
			case "pusher_connected":
				color = value ? "green" : "red";
				text = value ? "Connected" : "Disconnected";
				break;
		}

		return <Status text={ text } color={ color }/>;
	}

	renderTimestampStatusIcon(row: HealthReportRow, column: string) {
		const { popoverStyle } = this.styles;
		const timestamp = row[column];

		const formattedTimestamp = timestamp ? Utils.getHumanReadableDate(timestamp) : "N/A";
		const timestampAge = timestamp ? moment(moment()).diff(timestamp, "minutes") : null;

		let content = (
			<div>
				<Icon name="exclamation-triangle" style={{ color: red }} />
			</div>
		);

		if (timestampAge !== null && timestampAge < 60) {
			content = (
				<div>
					<Icon name="check-circle" style={{ color: primaryGreen }} />
				</div>
			);
		}

		if (timestampAge !== null && timestampAge >= 60 && timestampAge <= 120) {
			content = (
				<div>
					<Icon name="question-square" style={{ color: yellow }} />
				</div>
			);
		}

		return (
			<Popover
				key={ column }
				content={ <div style={ popoverStyle }>{ formattedTimestamp }</div> }
				placement="right"
				trigger="hover"
			>
				{ content }
			</Popover>
		);
	}

	renderBooleanContent(row: HealthReportRow, column: string) {
		let value = row[column];

		// Flip the value in case of the deployment failed
		if (column === "status.last_deployment_failed") {
			value = !value;
		}

		const color = value ? black : red;

		let content = "undefined";

		switch (column) {
			case "status.last_deployment_failed":
				content = value ? "Succeeded" : "Failed";
				break;
			case "status.online":
				content = value ? "Online" : "Offline";
				break;
		}

		return (
			<span style={{ color }}>
				{content}
			</span>
		);
	}

	renderConnectionType(row: HealthReportRow, column: string) {
		const ethernet = row["status.ethernet_connected"];
		const wifi = row["status.wifi_connected"];

		if (ethernet) {
			return "Ethernet";
		}

		if (wifi) {
			return "WiFi";
		}

		return "Disconnected";
	}

	renderDeviceModalLink(row: HealthReportRow, column: string) {
		// pass the UUID to open the device modal
		const uuid = row["device.uuid"] || null;

		return (
			<a onClick={ this.openDeviceModal(uuid) }>
				<Truncate length={ 24 }>{ row[column] }</Truncate>
			</a>
		);
	}

	renderSnapshotLink(row: HealthReportRow, column: string) {
		const value = row[column];
		const timestamp = Utils.getHumanReadableDate(row["device.snapshot_timestamp"]);

		return (
			<a
				href={ value }
				target="_blank"
				rel="noopener noreferrer">
				{ timestamp }
			</a>
		)
	}

	connectIfDroppable(renderable: JSX.Element) {
		const { connectDragSource, connectDropTarget, loading } = this.props;

		if (!loading) {
			return connectDragSource(connectDropTarget(renderable));
		}

		return renderable;
	}

	getColor(current: number, medium: number, high: number, realColor?: boolean) {
		const colorRed = realColor ? red : "red";

		if (!current) {
			return colorRed;
		}

		if (current <= medium) {
			return realColor ? primaryGreen : "green";
		} else if (current > medium && current <= high) {
			return realColor ? orange : "orange";
		}

		return colorRed;
	}

	getLedText(color: string) {
		switch (color) {
			case "green":
				return "Normal Operation";
			case "purple":
				return "No Network";
			case "blue":
				return "Schedule Error";
			case "red":
				return "Camera Error";
			case "yellow":
				return "Sleep Mode";
			default:
				return "N/A";
		}
	}

	openDeviceModal(uuid: string) {
		return () => this.props.openDeviceHealthModal(uuid);
	}

	removeColumn(column: HealthReportColumn) {
		return () => {
			const { activeHealthReport, updateReport } = this.props;
			const index = (activeHealthReport.columns || []).indexOf(column);
			const updatedReport = update(activeHealthReport, {
				columns: { $splice: [ [ index, 1 ] ]}
			});

			updateReport(updatedReport);
		};
	}

	sortColumn(column: HealthReportColumn) {
		return (e: React.SyntheticEvent<HTMLDivElement>) => {
			e.preventDefault();

			const { history, location, sort, sortDirection } = this.props;
			const currentSort = sort && sortDirection;
			const newSort = HealthReportColumns[column].url;
			let direction = "asc";

			if (currentSort && newSort === HealthReportColumns[sort].url && sortDirection === "asc") {
				direction = "desc";
			}

			history.push(location.pathname + `?sort=${newSort}_${direction}`)
		};
	}

	stopPropagation(e: React.SyntheticEvent<HTMLElement>) {
		e.stopPropagation();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(
	DragSource(DragTable.Column, reportDragSource, reportDragCollector)(
		DropTarget([ DragTable.Component, DragTable.Column ], reportDropTarget, reportDropCollector)(
			withRouter(DragNDropReportColumn)
		)
	)
);

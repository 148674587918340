import { Form, Input as AntInput } from "antd";
import { InputProps as AntInputProps } from "antd/lib/input";
import { Picker as EmojiPicker } from "emoji-mart";
import * as emojiRegex from "emoji-regex";
import * as update from "immutability-helper";
import * as React from "react";
import { length as strLength, substring } from "stringz";

import { CustomCSS, InputValidator } from "@connect/Interfaces";
import { Button, Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { DefaultMaxLength } from "Data/Objects/Global";
import { getValidatorErrors } from "Data/Objects/Validation";

const FormItem = Form.Item;

interface InputProps extends AntInputProps {
	autoComplete?: string;
	autoFocus?: boolean;
	// autoselect is a string here instead of a boolean
	// in order to be consistent with the HTML standard
	autoselect?: string;
	button?: {
		callback: (value: string) => void;
		text: string;
	};
	className?: string;
	color?: "light" | "dark";
	disabled?: boolean;
	disableSubmitCallback?: (canSubmit: boolean) => void;
	emoji?: boolean;
	maxLength?: number;
	hideMaxLength?: boolean;
	id: any;
	placeholder?: string;
	readOnly?: boolean;
	required?: boolean;
	saveCallback?: (value: string) => void;
	style?: CustomCSS;
	type?: string;
	updateCallback?: (value: string) => void;
	validator?: InputValidator[];
	value: string;
}

interface InputState {
	inputValue: string;
	lengthColor: string;
	showEmojiPicker: boolean;
	validationErrors: string[];
	validationStatus: "success" | "error";
}

export default class Input extends React.PureComponent<InputProps, InputState> {
	constructor(props: InputProps) {
		super(props);

		const { lightestGray } = Colors;

		this.setHasButton();

		this.state = {
			inputValue: props.value,
			lengthColor: lightestGray,
			showEmojiPicker: false,
			validationErrors: [],
			validationStatus: "success"
		};

		this.getInput = this.getInput.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.saveInputValue = this.saveInputValue.bind(this);
		this.setCanSubmit = this.setCanSubmit.bind(this);
		this.setInputRef = this.setInputRef.bind(this);
		this.updateLengthColor = this.updateLengthColor.bind(this);
		this.updateInput = this.updateInput.bind(this);
		this.updateInputWithEmoji = this.updateInputWithEmoji.bind(this);
		this.updateShowEmojiPicker = this.updateShowEmojiPicker.bind(this);
		this.updateValidation = this.updateValidation.bind(this);
		this.validateThenCall = this.validateThenCall.bind(this);
		this.validateInput = this.validateInput.bind(this);
	}

	hasButton: boolean;
	_input: AntInput;
	style: CustomCSS;

	componentWillReceiveProps(props: InputProps) {
		if (this.props.id !== null && this.props.id !== props.id) {
			this.setHasButton();

			this.setState((prevState) => {
				return update(prevState, {
					inputValue: { $set: props.value },
					lengthColor: { $set: Colors.white },
					showEmojiPicker: { $set: false },
					validationErrors: { $set: [] },
					validationStatus: { $set: "success" }
				});
			});
		}
	}

	render() {
		const { autoFocus, color, className, emoji, disabled, placeholder, validator, id, required, value } = this.props;
		const { inputValue, validationErrors, validationStatus } = this.state;
		const emojiClass = emoji ? "emoji-picker" : "";
		const hasError = validationStatus === "error";
		let help: string | JSX.Element[] = "";

		if (hasError) {
			if (required && !inputValue) {
				help = "This field is required.";
			} else if (validator && validator.length) {
				help = getValidatorErrors(validationErrors, validator).map(this.renderValidatorErrors) as JSX.Element[];
			}
		}

		// get rid of all our named props, to pass through other valid props that might not exist here explicitly
		let otherProps = {};
		const invalidProps = [ "autoFocus", "autoSelect", "button", "className", "color", "disabled", "disableSubmitCallback",
			"emoji", "maxLength", "hideMaxLength", "id", "placeholder", "readOnly", "required", "saveCallback", "style",
			"type", "updateCallback", "validator", "value" ];

		for (let prop in this.props) {
			if (this.props.hasOwnProperty(prop) && !invalidProps.includes(prop)) {
				otherProps[prop] = this.props[prop];
			}
		}

		let wrappedHelp = ( <React.Fragment>{ help }</React.Fragment> );

		return (
			<div className={ "cec-input" } key={ id }>
				<FormItem
					help={ hasError ? wrappedHelp : null }
					required={required}
					validateStatus={validationStatus}>
					<AntInput
						id={id}
						autoFocus={autoFocus}
						className={`${ emojiClass } ${color}-input ${className}`}
						disabled={disabled}
						onBlur={this.validateThenCall(this.saveInputValue)}
						onChange={ this.onInputChange }
						onPressEnter={this.validateThenCall(this.saveInputValue)}
						placeholder={placeholder}
						ref={ this.setInputRef }
						style={{
							...this.props.style
						}}
						suffix={this.hasButton && this.renderButton()}
						type={this.props.type || "text"}
						value={ inputValue }
						defaultValue={ value }
						{ ...otherProps }
					/>
				</FormItem>

				{this.renderFormFieldSuffix()}
			</div>
		);
	}

	renderValidatorErrors(err: string) {
		return (
			<p key={ err }>{ err }</p>
		);
	}

	setInputRef(input: AntInput) {
		this._input = input;
		const { autoselect } = this.props;

		if (autoselect && autoselect === "true" && input) {
			input.select();
		}

	}

	onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
		const newValue = event.currentTarget.value;

		this.updateInput(newValue);
	}

	updateInput(value: string) {
		// prevent passing null or undefined from breaking the input
		let updateValue = value === null || value === undefined ? "" : value;
		const { updateCallback } = this.props;
		const allowedLength = this.getAllowedLength();

		// Truncate our string if we've hit our character cap
		if (updateValue && updateValue.length > allowedLength) {
			updateValue = substring(updateValue, 0, allowedLength);
		}

		// Check validation and make sure we show an error if needed
		this.validateInput(updateValue);

		// Update the local state
		this.setState((prevState) => {
			return update(prevState, {
				inputValue: { $set: updateValue }
			});
		});

		if (updateCallback) {
			updateCallback(updateValue);
		}
	}

	renderButton() {
		const { button, emoji } = this.props;

		if (emoji) {
			return this.renderEmojiButton();
		}

		return (
			<Button
				isSuffix
				onClick={(event) => {
					event.preventDefault();

					if (button && button.callback) {
						button.callback(this.state.inputValue);
					}
				}}
				type="primary"
			>
				{ button && button.text }
			</Button>
		);
	}

	renderEmojiButton() {
		return (
			<Button
				isSuffix
				onClick={(event) => {
					event.preventDefault();
					this.updateShowEmojiPicker();
				}}
				className="emoji"
			>
				<Icon name="smile" iconWeight="regular" size="smaller" />
			</Button>
		);
	}

	renderEmojiPicker() {
		if (!(this.props.emoji && this.state.showEmojiPicker)) {
			return null;
		}

		const excludedEmoji = [
			"1F595" // middle_finger
		];

		return (
			<EmojiPicker
				autoFocus={true}
				emoji="grinning"
				emojiSize={20}
				onClick={this.updateInputWithEmoji}
				perLine={6}
				set="google"
				title="Emoji"
				emojisToShowFilter={emoji => emoji.unified.indexOf(excludedEmoji) === -1}
				backgroundImageFn={() => {
					return "https://cdn.clintonconnect.com/images/emoji-datasheet-64.png";
				}}
			/>
		);
	}

	renderEmojiWarning() {
		const regex = emojiRegex();
		const containsEmoji = regex.test(this.state.inputValue);

		if (!containsEmoji) {
			return null;
		}

		return (
			<div className="emoji-warning">
				Actual emoji may vary by browser.
			</div>
		);
	}

	renderFormFieldSuffix() {
		const picker = this.renderEmojiPicker();
		const enforcement = this.renderLengthEnforcement();
		const warning = this.renderEmojiWarning();
		let br;

		if (picker || enforcement || warning) {
			br = (<br />);
		}

		return (
			<div className="form-field-suffix">
				{br}
				{enforcement}
				{picker}
				{warning}
			</div>
		);
	}

	renderLengthEnforcement() {
		if (this.props.hideMaxLength) {
			return null;
		}

		return (
			<div className="input-length-warning" style={{
				color: this.getLengthColor()
			}}>
				{this.getRemainingChars()} characters remaining
			</div>
		);
	}

	getCursorLocation(): number {
		const input = this.getInput();

		return input.selectionStart || input.value.length;
	}

	getInput(): HTMLInputElement {
		return this._input.input;
	}

	getLengthColor() {
		return this.state.lengthColor;
	}

	getRemainingChars() {
		const { inputValue } = this.state;
		const length = inputValue ? strLength(inputValue) : 0;
		return this.getAllowedLength() - length;
	}

	getAllowedLength() {
		const { maxLength } = this.props;
		return maxLength ? Math.min(maxLength, DefaultMaxLength) : DefaultMaxLength;
	}

	saveInputValue(value: string) {
		const {  saveCallback } = this.props;

		if (saveCallback) {
			saveCallback(value);
		}
	}

	setCanSubmit(isValid?: boolean) {
		const { disableSubmitCallback } = this.props;

		if (disableSubmitCallback) {
			const { value } = this.getInput();
			const allowedLength = this.getAllowedLength();
			const overLength = allowedLength && allowedLength < strLength(value);
			let canSubmit = true;

			if (!isValid) {
				canSubmit = false;
			}

			if (overLength) {
				canSubmit = false;
			}

			disableSubmitCallback(canSubmit);
		}
	}

	setHasButton() {
		const { button, emoji } = this.props;

		this.hasButton = false;

		if (emoji || button && button.text) {
			this.hasButton = true;
		}
	}

	updateLengthColor() {
		const { lightestGray, orange, red } = Colors;
		const remaining = this.getRemainingChars();
		let color = lightestGray;

		if (remaining <= 10 && remaining > 0) {
			color = orange;
		}
		if (remaining <= 0) {
			color = red;
		}

		return this.setState((prevState) => {
			return update(prevState, {
				lengthColor: { $set: color }
			});
		});
	}

	updateInputWithEmoji(emoji: any) {
		const input = this.getInput();
		const insertIndex = this.getCursorLocation();
		const newValue = input.value.substr(0, insertIndex) + emoji.native + input.value.substr(insertIndex);

		this.updateInput(newValue);
		this.updateShowEmojiPicker();
	}

	updateShowEmojiPicker() {
		this.setState((prevState) => {
			return update(prevState, {
				showEmojiPicker: { $set: !prevState.showEmojiPicker }
			});
		}, () => {
			if (!this.state.showEmojiPicker) {
				this._input.focus();
			}
		});
	}

	updateValidation(validationStatus: string, validationErrors: string[]) {
		this.setState((prevState) => update(prevState, {
			validationErrors: { $set: validationErrors },
			validationStatus: { $set: validationStatus }
		}));
	}

	validateThenCall(method: (value: string) => void) {
		return () => {
			const input = this.getInput();
			let { value } = input;

			if (this.validateInput(value)) {
				method(value);
			}
		};
	}

	// always resolves so we can update the input
	validateInput(value: string): boolean {
		const { required, validator } = this.props;
		const error = "error";
		const success = "success";
		let validationErrors: string[] = [];
		let isValid = true;

		if (!value && required) {
			isValid = false;
		} else if (validator && validator.length) {
			isValid = !!validator
				.map((v) => {
					const valid = v.callback(value); // check whether this particular validator passes

					if (!valid) { // run a check inside the map so we don't iterate twice over the validators
						validationErrors.push(v.name); // send the name of the validator to our error array
					}

					return valid; // map the value to valid boolean
				})
				.reduce((finalValid, validValue) => finalValid && validValue, true); // reduce to a boolean
		}

		const validationStatus = isValid ? success : error;

		this.setCanSubmit(isValid);

		this.updateValidation(validationStatus, validationErrors);

		return isValid;
	}
}
import { createSelector } from "reselect";

import { IUser } from "@connect/Interfaces";
import { AdminState, AdminUsersState, AppState } from "Data/Objects/AppState";

export function getAdminState(state: AppState): AdminState {
	return state.Admin;
}

export function getAdminUsersState(state: AdminState): AdminUsersState {
	return state.Users;
}

export function getUsers(state: AdminUsersState): IUser[] {
	return state.users;
}

export function getAllUsers(state: AdminUsersState): IUser[] {
	return state.allUsers;
}

export function getIntegrators(state: AdminUsersState): IUser[] {
	return state.allUsers.filter((user) => user.role.name === "integrator");
}

export const getAdminUsers = createSelector(
	[ getAdminState ],
	getAdminUsersState
);

export const getCurrentIntegrators = createSelector(
	[ getAdminUsers ],
	getIntegrators
);

export const getUsersList = createSelector(
	[ getAdminUsers ],
	getUsers
);

export const getAllUsersList = createSelector(
	[ getAdminUsers ],
	getAllUsers
);

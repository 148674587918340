import { Checkbox, Switch } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, DayOption, HealthReport, HealthReportNotification } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Button, Input, Select } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import PropertiesPanel, { PropertiesPanelButton } from "Components/Global/PropertiesPanel";
import {
	deleteReportAsync,
	getReportAsync,
	getReportExportAsync,
	runReportAsync,
	updateNotificationAsync,
	updateReportAsync
} from "Data/Actions/HealthReportAsync";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { sendDays } from "Data/Objects/Reports";
import inputValidation from "Data/Objects/Validation";
import { getReportIsDownloading, getSelectedHealthReport } from "Data/Selectors/HealthReports";
import { getActiveUuid, getReportIsRunning } from "Data/Selectors/UI";
import { getHealthReportsNewsletterSetting } from "Data/Selectors/User";

interface HealthPagePropertiesPanelProps {
	activeHealthReport: HealthReport;
	activeReportId: string;
	deleteReport: (uuid: string) => void;
	editDevicesCallback: () => void;
	getReportDetails: (uuid: string) => void;
	healthReportNotifications: boolean;
	updateNotification: (uuid: string, notification: HealthReportNotification) => void;
	updateReport: (report: HealthReport) => void;
	getReportExport: (report: HealthReport) => void;
	runReport: (uuid: string) => null;
	isRunning: boolean;
	isDownloading: boolean;
}

interface HealthReportToggle {
	key: string;
	description: string;
}

const { gray, primaryBlue, white } = Colors;
const { Option } = Select;

const healthReportToggles: HealthReportToggle[] = [
	{ key: "includeLink", description: "Include Updated Health Report" },
	{ key: "devicesOffline", description: "Device Offline" },
	{ key: "camerasOffline", description: "Camera Offline" },
	{ key: "noSchedule", description: "Schedule Not Assigned" },
	{ key: "failedDeployments", description: "Schedule Failed to Deploy" },
	{ key: "displaysOff", description: "Display Off" }
];

const mapStateToProps = (state) => {
	const activeReportId = getActiveUuid(state, "health");
	return {
		activeHealthReport: getSelectedHealthReport(state, activeReportId),
		activeReportId,
		healthReportNotifications: getHealthReportsNewsletterSetting(state),
		isRunning: getReportIsRunning(state, activeReportId),
		isDownloading: getReportIsDownloading(state, activeReportId)
	};
};

const mapDispatchToProps = (dispatch) => ({
	deleteReport: (uuid: string) =>
		dispatch(deleteReportAsync(uuid)),
	getReportDetails: (uuid: string) =>
		dispatch(getReportAsync(uuid, false, true)), // expand notifications
	updateNotification: (uuid: string, notification: HealthReportNotification) =>
		dispatch(updateNotificationAsync(uuid, notification)),
	updateReport: (report: HealthReport) =>
		dispatch(updateReportAsync(report)),
	runReport: (uuid: string) =>
		dispatch(runReportAsync(uuid)),
	getReportExport: (report: HealthReport) =>
		dispatch(getReportExportAsync(report))
});

export class HealthPagePropertiesPanel extends React.Component<HealthPagePropertiesPanelProps> {
	constructor(props: HealthPagePropertiesPanelProps) {
		super(props);

		this.styles = {
			buttonInner: {
				color: white,
				width: "100%"
			},
			buttonWrapper: {
				margin: "10px auto 0",
				width: "80%"
			},
			checkboxInner: {
				fontSize: "0.85em",
				fontWeight: "lighter"
			},
			checkboxRow: {
				margin: "10px auto 0",
				width: "80%"
			},
			dropdownInner: {
				margin: "3px auto 0",
				width: "80%"
			},
			dropdownRow: {
				fontSize: "0.85em",
				marginTop: 10
			},
			row: {
				marginBottom: 14,
				display: "flex",
				justifyContent: "space-between"
			},
			rowDescription: {
				color: gray,
				fontSize: "0.85em",
				textAlign: "center"
			},
			select: {
				width: "100%"
			},
			toggleLabel: {
				flex: 8,
				lineHeight: "24px",
				verticalAlign: "middle"
			},
			toggleRow: {
				display: "flex",
				flexDirection: "row"
			},
			toggleSwitch: {
				flex: 2
			}
		};

		this.renderCheckbox = this.renderCheckbox.bind(this);
		this.renderContent = this.renderContent.bind(this);
		this.promptDeleteReport = this.promptDeleteReport.bind(this);
		this.downloadReport = this.downloadReport.bind(this);
		this.getAvailableActions = this.getAvailableActions.bind(this);
		this.getNotificationDataIfNeeded = this.getNotificationDataIfNeeded.bind(this);
		this.runReport = this.runReport.bind(this);
		this.updateHealthReportGlobalFlag = this.updateHealthReportGlobalFlag.bind(this);
		this.updateHealthReportNotification = this.updateHealthReportNotification.bind(this);
		this.updateHealthReportNotificationFlag = this.updateHealthReportNotificationFlag.bind(this);
		this.updateHealthReportNotificationWrapper = this.updateHealthReportNotificationWrapper.bind(this);
		this.updateName = this.updateName.bind(this);
	}

	styles: CustomCSS;

	componentWillMount() {
		this.getNotificationDataIfNeeded();
	}

	render() {
		if (!this.props.activeHealthReport) {
			return null;
		}

		return (
			<PropertiesPanel
				actions={this.getAvailableActions()}
				properties={this.renderContent()}
				title="Report Properties"
			/>
		)
	}

	renderCheckbox(item: HealthReportToggle) {
		const { checkboxInner, checkboxRow } = this.styles;
		const { notification, uuid } = this.props.activeHealthReport;
		const { key, description } = item;

		return (
			<div key={key + "_" + uuid} style={checkboxRow}>
				<Checkbox
					checked={notification && notification[key]}
					onChange={this.updateHealthReportNotificationWrapper(key)}
					style={checkboxInner}
				>
					{description}
				</Checkbox>
			</div>
		);
	}

	renderContent() {
		const properties = [
			this.renderTitleInput(),
			this.renderCreatedBy(),
			this.renderDevicesButton()
		];

		if (hasPermission(PERMISSIONS.DEVICE_HEALTH_CREATE)) {
			properties.push(this.renderGlobalSwitch());
		}

		if (hasPermission(PERMISSIONS.DEVICE_HEALTH_NOTIFICATION)) {
			properties.push(this.renderNotificationSettings());
		}

		return properties;
	}

	renderDayOptions(option: DayOption) {
		const value = option.value.toString();

		return (
			<Option key={value} value={value}>{option.day}</Option>
		);
	}

	renderCreatedBy() {
		const { createdBy } = this.props.activeHealthReport
		const { row } = this.styles;

		return (
			<div style={ row }>
				<div>Created By:</div>
				<span>
					{ createdBy && createdBy.name }
				</span>
			</div>
		);
	}

	renderDevicesButton() {
		const { activeReportId, editDevicesCallback } = this.props;
		const { buttonInner, buttonWrapper } = this.styles;

		return (
			<div key={activeReportId + "_devicebutton"}>
				Devices:
				<br />
				<div style={buttonWrapper}>
					<Button
						color={primaryBlue}
						onClick={editDevicesCallback}
						style={buttonInner}
					>
						Edit Devices
					</Button>
				</div>
			</div>
		);
	}

	renderGlobalSwitch() {
		const { activeHealthReport, activeReportId } = this.props;
		const { rowDescription, toggleLabel, toggleRow, toggleSwitch } = this.styles;

		return (
			<div key={activeReportId + "_globalswitch"}>
				<div style={toggleRow}>
					<div style={toggleLabel}>
						Make Global Report
					</div>
					<div style={toggleSwitch}>
						<Switch
							key={activeReportId}
							checked={activeHealthReport.global}
							onChange={this.updateHealthReportGlobalFlag}
						/>
					</div>
				</div>
				<div style={rowDescription}>
					Allow all users with Device Health permissions to view this report, but not edit the details.
				</div>
			</div>
		);
	}

	renderNotificationSettings() {
		const { activeHealthReport, activeReportId, healthReportNotifications } = this.props;
		const { dropdownInner, dropdownRow, rowDescription, select, toggleLabel, toggleRow, toggleSwitch } = this.styles;
		const { notification } = activeHealthReport;
		const checked = notification !== undefined && notification !== null && notification.enabled;
		const description = healthReportNotifications ?
			"Configure email settings for this report."
			:
			(
				<div>
					You must enable Health Reports in your
					<a href="/admin/emailPreferences"> Email Preferences </a>
					in order to receive emails about this report.
				</div>
			);
		const showAdditionalToggles = checked && healthReportNotifications;
		const sendDay = notification && notification.sendDay || 0;

		return (
			<div key={ activeReportId + "_notificationsettings" }>
				<div style={ toggleRow }>
					<div style={ toggleLabel }>
						Email Notifications
					</div>
					<div style={ toggleSwitch }>
						<Switch
							checked={ checked }
							disabled={ !healthReportNotifications }
							key={ activeReportId }
							onChange={ this.updateHealthReportNotificationFlag }
						/>
					</div>
				</div>
				<div style={ rowDescription }>{ description }</div>
				{
					!showAdditionalToggles ? null : (
						<div>
							{ healthReportToggles.map(this.renderCheckbox) }
							<br />
							<div style={ dropdownRow }>
								Email Frequency: <br />
								<div style={ dropdownInner }>
									<Select
										onChange={ this.updateHealthReportNotificationWrapper("frequency") }
										style={ select }
										value={ notification && notification.frequency }
									>
										<Option key="daily" value="daily">Daily Digest</Option>
										<Option key="weekly" value="weekly">Weekly Digest</Option>
									</Select>
								</div>
							</div>
							<div style={{
								...dropdownRow,
								display: notification && notification.frequency === "daily" ? "none" : "inherit"
							}}>
								Email Day of Week: <br />
								<div style={ dropdownInner }>
									<Select
										onChange={ this.updateHealthReportNotificationWrapper("sendDay") }
										style={ select }
										value={ sendDay.toString() }
									>
										{
											sendDays.map(this.renderDayOptions)
										}
									</Select>
								</div>
							</div>
						</div>
					)
				}
			</div>
		);
	}

	renderTitleInput() {
		const { activeHealthReport, activeReportId } = this.props;

		return (
			<div key={activeReportId + "_title"}>
				Title:
				<Input
					id={activeReportId}
					saveCallback={this.updateName}
					validator={ inputValidation.name }
					value={activeHealthReport.name}
				/>
			</div>
		);
	}

	promptDeleteReport() {
		Notifications.confirm("Delete " + this.props.activeHealthReport.name + "?",
			"Are you sure you want to delete this report?",
			"Delete", "Cancel", () => {
				this.deleteReport()
			});
	}

	deleteReport() {
		this.props.deleteReport(this.props.activeReportId);
	}

	downloadReport() {
		this.props.getReportExport(this.props.activeHealthReport);
	}

	getAvailableActions(): PropertiesPanelButton[] {
		const { activeReportId, isDownloading, isRunning } = this.props;
		return [
			{
				action: () => this.downloadReport(),
				disabled: isDownloading,
				key: `${activeReportId}_download`,
				icon: { name: "download", weight: "solid" },
				name: "Download"
			},
			{
				action: () => this.runReport(),
				key: `${activeReportId}_run`,
				icon: { name: "flag", weight: "solid" },
				name: "Run Report",
				type: "primary",
				disabled: isRunning
			},
			{
				action: () => this.promptDeleteReport(),
				key: `${activeReportId}_delete`,
				icon: { name: "trash-alt", weight: "regular" },
				name: "Delete Report",
				type: "danger"
			}
		];
	}

	getNotificationDataIfNeeded() {
		const { activeHealthReport, activeReportId, getReportDetails } = this.props;

		const haveNotifications = activeHealthReport && activeHealthReport.notification !== undefined;

		if (!haveNotifications && activeHealthReport) {
			getReportDetails(activeReportId);
		}
	}

	runReport() {
		const { devices, deviceGroups } = this.props.activeHealthReport;
		if (!(devices && devices.length) && !(deviceGroups && deviceGroups.length)) {
			Notifications.warning("Please select some devices and/or device groups to run this report with.");
			return;
		}

		this.props.runReport(this.props.activeReportId);
	}

	updateHealthReportGlobalFlag(global: boolean) {
		const { activeHealthReport, updateReport } = this.props;
		const newReport = Object.assign({}, activeHealthReport, { global });

		updateReport(newReport);
	}

	updateHealthReportNotification(setting: string, value: any) {
		const { activeHealthReport, updateNotification } = this.props;
		const updatedNotification = {};

		updatedNotification[setting] = value;

		const newNotification = Object.assign({}, activeHealthReport.notification, updatedNotification);

		updateNotification(activeHealthReport.uuid, newNotification);
	}

	updateHealthReportNotificationFlag(enabled: boolean) {
		const { activeHealthReport, updateNotification } = this.props;
		const { notification } = activeHealthReport;
		const brandNewNotification = {
			enabled: true,
			frequency: "daily",
			sendDay: "0"
		};

		const newNotification = Object.assign({}, notification, (notification ? {
			enabled
		} : brandNewNotification));

		updateNotification(activeHealthReport.uuid, newNotification);
	}

	updateHealthReportNotificationWrapper(key: any) {
		return (value: any) => {
			let determinedValue: any = value;

			if (value.target) {
				determinedValue = value.target.checked;
			}

			return this.updateHealthReportNotification(key, determinedValue);
		}
	}

	updateName(name: string) {
		const { activeHealthReport, updateReport } = this.props;
		const newReport = Object.assign({}, activeHealthReport, { name });

		updateReport(newReport);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthPagePropertiesPanel);

import * as update from "immutability-helper";
import * as React from "react";
import { DragLayer, DropTarget, DropTargetMonitor } from "react-dnd";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";

import { CustomCSS, WidgetLocationState, WidgetState } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";
import { updateWidgetsAsync } from "Data/Actions/UserAsync";
import { ActiveObjectArrayState } from "Data/Objects/AppState";
import { WIDGET_TYPES } from "Data/Objects/Widgets";
import { getRenderableWidgets } from "Data/Selectors/Widgets";

type DropZoneLocation = "left" | "right" | "top";

interface WidgetDropTargetProps {
	connectDropTarget: Function;
	forceVisible?: boolean;
	isOver?: boolean;
	location: DropZoneLocation;
	renderableWidgets: ActiveObjectArrayState;
	updateWidgets: (widgets: WidgetState[]) => void;
	widgetIsDragging?: boolean;
	widgetLocations: WidgetLocationState;
	x?: number;
	y?: number;
}

const mapStateToProps = (state) => ({
	renderableWidgets: getRenderableWidgets(state)
});

const mapDispatchToProps = (dispatch) => ({
	updateWidgets: (widgets: WidgetState[]) => dispatch(updateWidgetsAsync(widgets))
});

const widgetDropTarget = {
	drop(props: WidgetDropTargetProps, monitor: DropTargetMonitor, component: React.Component) {
		const { location, renderableWidgets } = props;
		const draggedItem = monitor.getItem() as { type: string };
		const el = (findDOMNode(component) as Element);
		const offset = monitor.getClientOffset() || { y: 0 };
		const locationWidgets = renderableWidgets[location] || [];
		const widgets = locationWidgets.sort((a, b) => a.index - b.index);
		const widgetTypeIndex = Object.values(WIDGET_TYPES).findIndex((w) => w.name === draggedItem.type);
		const widgetType = Object.keys(WIDGET_TYPES)[widgetTypeIndex];
		const isTop = location === "top";
		// get the rectangle we're hovering over
		const hoverRect = el.getBoundingClientRect();
		// get the mouse position relative to the rectangle being hovered
		const hoverMouseY = offset.y - hoverRect.top + window.scrollY;
		// get the mouse position as a percentage of container height
		const hoverMousePercentage = Math.floor(hoverMouseY / el.clientHeight * 100);
		// get all widget heights
		let widgetHeights = widgets.map(({ name }) => {
			return el.getElementsByClassName(name)[0].clientHeight;
		});
		// add the dropzone height to our list
		widgetHeights[isTop ? "unshift" : "push"](el.children[0].clientHeight);
		// map them to have both height and top, so we can better calculate below
		widgetHeights = widgetHeights.map((height, i, arr) => ({
			height, top: i === 0 ? i : arr[i - 1]
		}));
		// put a new element on the end of the array by default
		let hoverIndex = -1;
		// we may never get this value; we should put the component at the bottom by default
		let middleY = 100;
		// if we're dropping something on the top we need to add an extra index to account for the dropzone
		const adder = isTop ? 2 : 1;
		// make sure we've got the current index of the item being dropped
		const index = widgets.findIndex(({ name }) => name === widgetType) + adder;

		if (widgets.length) {
			hoverIndex = widgetHeights
				.findIndex(({ height, top }, i, arr) => {
					const val = height + top;
					return isTop ? val > hoverMousePercentage : val <= hoverMousePercentage;
				});

			if (hoverIndex !== -1) {
				// get info about the component we're hovering
				const { height, top } = widgetHeights[hoverIndex];
				// get the middle of the component if it exists
				middleY = (height / 2) + top;
			} else {
				hoverIndex = widgetHeights.length;
			}
		}

		const isAbove = isTop ? hoverMousePercentage <= middleY : hoverMousePercentage > middleY;

		// only move when the mouse has crossed the hoverMiddleY
		// when moving down, only move when the mouse is below 50%
		if (index < hoverIndex && isAbove && index !== hoverIndex - 1) {
			hoverIndex -= 1;
		}

		// when moving up, only move when the mouse is above 50%
		if (index > hoverIndex && !isAbove && index !== hoverIndex + 1) {
			hoverIndex += 1;
		}

		let newWidget = {};

		newWidget[draggedItem.type] = {
			index: hoverIndex, position: location
		};

		let spliceCommand = [ [ hoverIndex, 0, newWidget ] ];

		if (index - adder > -1) {
			spliceCommand.unshift([ isTop ? index : index - adder, 1 ]);
		}

		const newWidgets = update(widgets, {
			$splice: spliceCommand
		}).map((w, i) => {
			if (w.hasOwnProperty("index")) {
				const temp = w;
				const { name } = WIDGET_TYPES[temp.name];

				w = {
					[name]: temp
				};
			}

			for (let prop in w) {
				if (w.hasOwnProperty(prop)) {
					w[prop].index = i;
				}
			}

			return w;
		});

		// if we're still here, update the widgets
		props.updateWidgets(newWidgets);
	}
};

const styles = {
	dropTargetBase: {
		borderRadius: 5,
		border: "1px dashed " + Colors.primaryBlue,
		color: Colors.lightBlue,
		height: 80,
		left: null,
		right: null,
		transition: "opacity 0.5s",
		width: "100%",
		zIndex: 10
	},
	dropTargetFont: {
		fontSize: 36,
		lineHeight: "80px",
		textAlign: "center"
	}
} as CustomCSS;

export class WidgetDropTarget extends React.Component<WidgetDropTargetProps> {
	render() {
		const { children, connectDropTarget, forceVisible, isOver, location, widgetIsDragging } = this.props;
		const { dropTargetBase, dropTargetFont } = styles;
		const show = isOver || forceVisible;
		const notTop = location === "left" || location === "right";
		const topChildren = notTop ? children : null;
		const bottomChildren = notTop ? null : children;
		const fontStyle = {
			...dropTargetFont,
			fontSize: notTop ? 28 : 36
		};
		const dropContainerClass = `widget-drop-target-${location}`;
		let zIndex = 10;

		if (widgetIsDragging) {
			zIndex = 20;
		} else if (notTop) {
			zIndex = 11;
		}

		let dynamicDropStyles = {
			...dropTargetBase,
			backgroundColor: forceVisible && !isOver ? null : Colors.dropBackground,
			opacity: show ? 1 : 0,
			position: show ? "relative" : "absolute",
			margin: "6px",
			zIndex
		} as CustomCSS;

		if (notTop) {
			dynamicDropStyles[location] = 0;
		}

		return connectDropTarget(
			<div className={ dropContainerClass }>
				{ topChildren }
				<div style={dynamicDropStyles}>
					<p style={fontStyle}>Drag & Drop Widget Here</p>
				</div>
				{ bottomChildren }
			</div>
		);
	}
}

const DNDWidgetDropTarget = DragLayer((dragMonitor) => ({
	widgetIsDragging: dragMonitor.isDragging() && dragMonitor.getItemType() === "Widget"
}))(
	DropTarget(
		"Widget",
		widgetDropTarget,
		(dndConnect, monitor) => ({
			connectDropTarget: dndConnect.dropTarget(),
			isOver: monitor.isOver()
		})
	)(WidgetDropTarget)
);

export { DNDWidgetDropTarget };

export default connect(mapStateToProps, mapDispatchToProps)(DNDWidgetDropTarget);
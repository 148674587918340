import * as update from "immutability-helper";
import PusherApi from "Api/Pusher";
import { PusherEventsResult, Action, PusherEvent, NameUuid, LastNotificationDispatch,
	PresenceUserDispatch, PresenceChannelDispatch, SpeedTestProgressArgs, WithUuid, ApkVersion,
	DatabaseUrlArgs, IntegratorsChangedArgs, IAd, IPlaylist, ActionSet, HealthReport,
	ScreenshotChangedArgs } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";
import { getLastNotification } from "Data/Selectors/Pusher";
import { pusherConnection } from "@connect/Pusher2";
import { setDeviceSpeedTestResult, setDeviceLastSeen, setDeviceSoftwareUpdating, setDeviceDatabaseStatus,
	setDeviceDBURL, setDeviceClearCacheStatus, setDeviceRebooting, setDeviceUpdatingAds, addReportNotification,
	removeRunningHealthReport, removeReportNotification, setDeviceScreenshotURL } from "Data/Actions/UI";
import { fetchUpdatedDeviceAsync, updateDevice, deleteDevice } from "Data/Actions/Devices";
import { getAllDevices, getDeviceByUUID, getDeviceById } from "Data/Selectors/Devices";
import { Utils } from "@connect/Utils";
import {
	successNotification,
	errorNotification,
	confirmNotification,
	plainNotification
} from "Data/Actions/Notifications";
import { getState, store } from "@connect/Data";
import { Notifications } from "@connect/Notifications";
import { getReportResultsAsync } from "Data/Actions/HealthReportAsync";
import { getActiveUuid } from "Data/Selectors/UI";
import { push } from "react-router-redux";
import { notification } from "antd";
import { createElement } from "react";
import { getUsersAsync } from "Data/Actions/UsersAsync";
import { getManagedCompanyAsync, getManagedCompaniesListAsync, getCompanyListAsync } from "Data/Actions/Company";
import { logoutUser } from "Data/Actions/User";
import { forgotPassword } from "Data/Actions/UserAsync";
import { getCurrentUser } from "Data/Selectors/User";
import { deleteAd } from "Data/Actions/Ads";
import { deletePlaylist } from "Data/Actions/Playlists";
import { deleteActionSet } from "Data/Actions/Actions";
import { deleteReport } from "Data/Actions/HealthReport";

const {
	SET_LAST_NOTIFICATION,
	USER_JOINED_CHANNEL,
	USER_LEFT_CHANNEL,
	RESET_CHANNEL
} = ACTION_TYPES.Pusher;


/*
 * Simple Actions
 */
export function setLastNotification(channel: string, uuid: string): Action<LastNotificationDispatch> {
	return new DispatchableAction(SET_LAST_NOTIFICATION, { channel, uuid });
}

export function userJoinedChannel(channel: string, user: NameUuid): Action<PresenceUserDispatch> {
	return new DispatchableAction(USER_JOINED_CHANNEL, { channel, user });
}

export function userLeftChannel(channel: string, user: NameUuid): Action<PresenceUserDispatch> {
	return new DispatchableAction(USER_LEFT_CHANNEL, { channel, user });
}

export function resetChannel(channel: string): Action<PresenceChannelDispatch> {
	return new DispatchableAction(RESET_CHANNEL, { channel });
}

/*
 * Async Actions
 */

export function passwordResetRequired() {
	return (dispatch) => {
		const state = getState();
		const user = getCurrentUser(state);
		const title = "Password Reset Notification";
		const message = "Password requirements have changed, please click 'Confirm' to reset your password";
		const onConfirm = () => {
			dispatch(forgotPassword(user.email));
		}

		dispatch(logoutUser())
			.then(
				dispatch(confirmNotification(title, message, "Confirm", "Cancel", onConfirm))
			);
	}
}

export function fetchMissedNotifications(channel: string) {
	return (dispatch, getState) => {
		const api = new PusherApi();
		const lastNotification = getLastNotification(getState(), channel);

		return api.getMissedEvents(channel, lastNotification).then((result: PusherEventsResult) => {
			result.data.map((event: PusherEvent) => {
				pusherConnection.createPusherHandler(channel)("", event);
			});
		});
	}
}

export function handleDeviceAssociated(data: WithUuid) {
	return (dispatch) => {
		dispatch(fetchUpdatedDeviceAsync(data.uuid));
	}
}

export function handleDeviceReleased(result: WithUuid) {
	return (dispatch) => {
		const { uuid } = result;
		const device = getDeviceById(getState(), uuid);
		dispatch(deleteDevice(device, device.company));
	}
}

export function handleScreenshotUpdated(data: WithUuid & ScreenshotChangedArgs) {
	return (dispatch) => {
		const { uuid, url } = data;
		dispatch(setDeviceScreenshotURL(uuid, url));
	}
}

export function handleDevicePong(data: WithUuid) {
	return (dispatch, getState) => {
		const devices = getAllDevices(getState());
		const device = getDeviceByUUID(devices, data.uuid);

		if (device) {
			const newHeartbeat = Utils.getISOTimestamp();
			const updatedDevice = update(device, {
				status: {
					heartbeat: {
						$set: newHeartbeat
					}
				}
			});

			dispatch(updateDevice(updatedDevice));
			dispatch(setDeviceLastSeen(data.uuid, newHeartbeat));
		}
	}
}

export function handleSoftwareUpdating(data: any) {
	return (dispatch) => {
		dispatch(successNotification("Device Software Update", "Device software is updating"));
	}
}

export function handleSoftwareUpdated(data: WithUuid & ApkVersion) {
	return (dispatch, getState) => {
		const device = getDeviceById(getState(), data.uuid);
		dispatch(successNotification("Device Software Update", "Device software has finished updating"));
		dispatch(setDeviceSoftwareUpdating(data.uuid, false));

		const updatedDevice = update(device, {
			softwareVersion: {
				$set: data.version
			}
		});

		dispatch(updateDevice(updatedDevice));
	}
}

export function handleSpeedTestUpdate(data: SpeedTestProgressArgs & WithUuid) {
	return (dispatch) => {
		const { uuid, ...result } = data;
		dispatch(setDeviceSpeedTestResult(uuid, { ...result, device: uuid }));
	}
}

export function handleSpeedTestFailed(data: SpeedTestProgressArgs & WithUuid) {
	return (dispatch) => {
		const { uuid, ...result } = data;
		dispatch(errorNotification("Speed Test Failed", "Device speed test failed. Speed test cancelled."));
		dispatch(setDeviceSpeedTestResult(uuid, { ...result, device: uuid, failed: true }));
	}
}

export function handleDatabaseUploadStarted() {
	return (dispatch) => {
		dispatch(successNotification("Database Uploading", "Device is uploading database"));
	}
}

export function handleDatabaseUploadCompleted(result: WithUuid & DatabaseUrlArgs) {
	return (dispatch) => {
		dispatch(successNotification("Database Uploaded", "Device database has successfully uploaded"));
		dispatch(setDeviceDatabaseStatus(result.uuid, false));
		dispatch(setDeviceDBURL(result.uuid, result.url));
	}
}

export function handleCacheCleared(result: WithUuid) {
	return (dispatch) => {
		const { uuid } = result;
		const modalState = getState().UI.deviceHealthModalState[uuid];

		if (modalState && !modalState.clearingCache) {
			return;
		}

		dispatch(successNotification("Cache Cleared", "Device cache successfully cleared"));
		dispatch(setDeviceClearCacheStatus(uuid, false));
	}
}

export function handleRebootStarted(result: any) {
	return (dispatch) => {
		dispatch(successNotification("Device Rebooting", "Device has begun rebooting"));
	}
}

export function handleRebootCompleted(result: WithUuid) {
	return (dispatch) => {
		const { uuid } = result;
		dispatch(successNotification("Device Rebooted", "Device has successfully rebooted"));
		dispatch(setDeviceRebooting(uuid, false));
	}
}

export function handleScheduleUpdating(result: WithUuid) {
	return (dispatch) => {
		const { uuid } = result;

		const modalState = getState().UI.deviceHealthModalState[uuid];
		if (modalState && modalState.adsUpdating && modalState.adsUpdating.value !== true) {
			return;
		}

		dispatch(successNotification("Device Updating", "Device is updating ads"));
	}
}

export function handleScheduleUpdated(result: WithUuid) {
	return (dispatch) => {
		const { uuid } = result;

		const modalState = getState().UI.deviceHealthModalState[uuid];
		if (modalState && modalState.adsUpdating && modalState.adsUpdating.value !== true) {
			return;
		}

		dispatch(successNotification("Device Updating", "Device successfully updated ads"));
		dispatch(setDeviceUpdatingAds(uuid, false));
	}
}

export function handleIntegratorsChanged(result: any) {
	return (dispatch) => {
		// TODO: [CON-5656] Address issues with Integrators Changed Event
	}
}

export const handleHealthReportClick = (reportId: string) => {
	return () => {
		if (getActiveUuid(getState(), "health") === reportId) {
			store.dispatch(removeReportNotification(reportId));
		}

		store.dispatch(push(`/health/${reportId}`));
		notification.close(`health_report_${reportId}`);
	}
}

export function handleHealthReportResult(result: WithUuid) {
	return (dispatch, getState) => {
		const { uuid } = result;
		dispatch(getReportResultsAsync(uuid));

		if (getState().UI.runningReports.indexOf(uuid) > -1) {
			Notifications.plain("Your report is ready", createElement(
				"a", {
					onClick: handleHealthReportClick(uuid)
				}, "Click here to view"
			), undefined, undefined, undefined, `health_report_${uuid}`);

			dispatch(addReportNotification(uuid));
			dispatch(removeRunningHealthReport(uuid));
		}
	}
}

export function integratorsChangedCompany(notification: IntegratorsChangedArgs) {
	return (dispatch) => {
		dispatch(getUsersAsync(true));
		dispatch(getManagedCompanyAsync(notification.uuid));
	};
}

export function integratorsChangedIntegrator(notification: IntegratorsChangedArgs) {
	return (dispatch) => {
		dispatch(getManagedCompaniesListAsync(true))
		dispatch(getCompanyListAsync(true))
	};
}

export function handleEntityDeleted<T extends WithUuid>(entityName: string, entityPath: string,
	deleteAction: Function, entity: T) {
	const pathname = entityPath.replace("/", "")
	const activeUuid = getActiveUuid(getState(), pathname);

	return (dispatch) => {
		dispatch(deleteAction(entity));

		if (activeUuid) {
			dispatch(push({ pathname: entityPath }));
			dispatch(plainNotification(
				`${ entityName } Deleted`,
				`This ${ entityName } was deleted by another user, redirecting to the ${ entityName }s page.`,
				0
			));
		}
	}
}

export const handleAdDeleted = (entity) => handleEntityDeleted<IAd>("Ad", "/ads", deleteAd, entity.uuid);
export const handlePlaylistDeleted = (entity) =>
	handleEntityDeleted<IPlaylist>("Playlist", "/playlists", deletePlaylist, entity);
export const handleActionDeleted = (entity) =>
	handleEntityDeleted<ActionSet>("Action", "/actions", deleteActionSet, entity.uuid);
export const handleHealthReportDeleted = (entity) =>
	handleEntityDeleted<HealthReport>("Health Report", "/health", deleteReport, entity.uuid);
import { AdGroupLink, AdMediaLink, IAd, IBaseComponent } from "@connect/Interfaces";
import { AdTemplate } from "Data/Objects/AdTemplates";
import { cloneDeep } from "lodash";

export class Ad extends AdTemplate implements IAd {
	constructor() {
		super();

		this.adGroup = [];
		this.duration = Ad.DEFAULT_DURATION;
		this.layout = {
			components: [],
			media: []
		}
	}

	static DEFAULT_DURATION = 15;

	adGroup: AdGroupLink[];
	totalPlays: number;
	analytics: string;
	duration: number;
	layout: {
		components: IBaseComponent[];
		media: AdMediaLink[];
	};

	tags: string[];

	static clone(ad: Ad): Ad {
		return cloneDeep(ad);
	}

	fromTemplate(template: AdTemplate) {
		this.layout = {
			components: template.components,
			media: []
		}
		this.name = "Untitled Ad";
		this.orientation = template.orientation;
		this.tags = [];
		this.uuid = "";

		return this;
	}
}
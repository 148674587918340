import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, NameUuid } from "@connect/Interfaces";
import { Droppable, UnwrappedDroppable } from "Components/Global/DropZone";

export interface IGridViewProps {
	activeSelection?: string[]; // add this via mapStateToProps in the extending component
	activeUuid?: string; // add this via mapStateToProps in the extending component
	addToSelected?: (ids: string[]) => void; // add this via mapDispatchToProps in the extending component
	content?: NameUuid[]; // we use this instead of children so the extending component can create its own card
	dropHandler?: (files: File[]) => void;
	isDroppable?: boolean;
	minWidth?: string;
	removeFromSelected?: (ids: string[]) => void; // add this via mapDispatchToProps in the extending component
	selectModeOn?: boolean;
	setActive?: (id: string) => void; // add this via mapDispatchToProps in the extending component
}

export interface IGridViewState {
	lastSelected: string;
}

export default class GridView<T extends IGridViewProps> extends React.PureComponent<T, IGridViewState> {
	constructor(props: T) {
		super(props);

		const minWidth = props.minWidth || "200px";

		this.state = {
			lastSelected: ""
		};

		this.styles = {
			container: {
				display: "grid",
				gridGap: 10,
				gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}, auto))`,
				justifyContent: "center",
				height: "auto",
				outlineOffset: 0,
				outline: "none",
				placeItems: "flex-start",
				userSelect: "none"
			}
		};

		this.isSelected = this.isSelected.bind(this);
	}

	_dropzone: connect<UnwrappedDroppable>;
	styles: CustomCSS;

	static getDerivedStateFromProps(props: IGridViewProps, state: IGridViewState) {
		if (!props.selectModeOn && state.lastSelected) {
			return { lastSelected: "" };
		}

		return null;
	}

	render() {
		// We throw here to prevent rendering the GridView directly, but we must return a <div />
		// so the extending components can properly type their render methods.
		throw new Error("WARNING: GridView#render must be overridden in the extending component.");
		return <div />;
	}

	renderContainer(child: React.ReactNode) {
		const { isDroppable } = this.props;
		const content = (
			<div style={this.styles.container}>{ child }</div>
		);

		if (isDroppable) {
			return (
				<Droppable
					ref={this.setDropzone}
					onDrop={this.handleDropzone}>
					{ content }
				</Droppable>
			);
		}

		return content;
	}

	// this method is passed to the components which extend GridViewCard as the handler for onCardSelection
	handleCardSelection(card: NameUuid, isSelected: boolean): (bulkAndShift: boolean) => void {
		return (bulkAndShift: boolean) => {
			const { addToSelected, content, removeFromSelected, selectModeOn, setActive } = this.props;
			const { uuid } = card;

			if (selectModeOn) {
				// we do this first to ensure that if we're not deselecting we set the lastSelected point from which to
				// calculate the next shift-selection bulk operation
				if (!isSelected) {
					this.setState(() => ({ lastSelected: uuid }));
				}

				if (bulkAndShift) {
					const getObj = (id) => (cardObject) => cardObject.uuid === id;
					const fromIndex = content ? content.findIndex(getObj(this.state.lastSelected)) : -1;
					const toIndex = content ? content.findIndex(getObj(uuid)) : -1;
					const count = Math.abs(toIndex - fromIndex) + 1;
					const start = toIndex > fromIndex ? fromIndex : toIndex;
					const bulkSelection = content ? content.map((item) => item.uuid).splice(start, count) : [];

					return isSelected
						? removeFromSelected && removeFromSelected(bulkSelection)
						: addToSelected && addToSelected(bulkSelection);
				}

				if (isSelected) {
					return removeFromSelected && removeFromSelected([ uuid ]);
				}

				return addToSelected && addToSelected([ uuid ]);
			}

			return setActive && setActive(uuid);
		};
	}

	handleDropzone(files: File[]) {
		const { dropHandler } = this.props;

		// We ought to provide errors instead of just a null check if a thing doesn't exist which should.
		// In this case the entire GridView is *optionally* droppable but if it is enabled
		// it should have both a flag and a callback, which is called in the try block below.
		try {
			if (dropHandler) {
				dropHandler(files);
			}
		} catch (e) {
			// eslint-disable-next-line
			console.log("Please pass a `dropHandler` method to the GridView if you intend to make it droppable!", e);
		}
	}

	isSelected(uuid: string) {
		const { activeSelection, activeUuid } = this.props;

		return (activeUuid && activeUuid === uuid) ||
				activeSelection && activeSelection.indexOf(uuid) > -1;
	}

	openDropzone() {
		this._dropzone.getWrappedInstance().open();
	}

	setDropzone(node: UnwrappedDroppable) {
		this._dropzone = node;
	}
}

import * as moment from "moment";

import { notifyBugSnag } from "@connect/BugSnag";
import { Action, Team, TeamsDispatch, TeamsResult } from "@connect/Interfaces";
import IntegratorsApi from "Api/Integrators";
import { errorNotification } from "Data/Actions/Notifications";
import { setAsyncFetching, setAsyncState } from "Data/Actions/UI";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";
import { CacheInvalidationPeriod } from "Data/Objects/Global";

const {
	SET_ADMIN_TEAMS,
	SET_TEAMS
} = ACTION_TYPES.Company;

export function setIntegratorTeams(teams: Team[]): Action<TeamsDispatch> {
	return new DispatchableAction(SET_TEAMS, { teams });
}

export function setAdminIntegratorTeams(teams: Team[]): Action<TeamsDispatch> {
	return new DispatchableAction(SET_ADMIN_TEAMS, { teams });
}

export function getIntegratorListAsync() {
	const api = new IntegratorsApi();

	return (dispatch, getState) => {
		const { Company: CompanyState, UI: { asyncState } } = getState();
		const { currentlyFetching, haveAllData, lastFetch, lastFetchedCompany } = asyncState.teams
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const companyChanged = CompanyState.activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = moment().diff(lastFetch, "minute") > CacheInvalidationPeriod || companyChanged;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		dispatch(setAsyncFetching("teams", true, CompanyState.activeCompanyId));

		return api.getIntegrationTeams()
			.then((result: TeamsResult) => {
				dispatch(setIntegratorTeams(result.data));
				dispatch(setAsyncState(
					"teams",
					true,
					0
				));
				dispatch(setAsyncFetching("teams", false));

				return result;
			}, (error) => {
				dispatch(errorNotification("Error receiving integrator team list.", error));
				dispatch(setAsyncFetching("teams", false));
			})
			.catch(error => notifyBugSnag(new Error(error)));
	};

}

export function getAdminIntegratorListAsync() {
	return (dispatch, getState) => {
		const api = new IntegratorsApi();
		const { Company: CompanyState, UI: { asyncState } } = getState();
		const { currentPage, currentlyFetching, haveAllData, lastFetch, lastFetchedCompany } = asyncState.adminTeams
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const companyChanged = CompanyState.activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = moment().diff(lastFetch, "minute") > CacheInvalidationPeriod || companyChanged;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		if (shouldInvalidateCache) {
			dispatch(setAdminIntegratorTeams([]));
		}

		dispatch(setAsyncFetching("adminTeams", true, CompanyState.activeCompanyId));

		const page = shouldInvalidateCache ? 1 : currentPage;
		let perPage = 200;

		return api.getAdminIntegratorList(perPage, page)
			.then((result: TeamsResult) => {
				dispatch(setAdminIntegratorTeams(result.data));
				dispatch(setAsyncState(
					"adminTeams",
					!result.links.next,
					result.meta.current_page
				));
				dispatch(setAsyncFetching("adminTeams", false));

				return result.data;
			}, (error) => {
				dispatch(errorNotification("Error receiving admin integrator team list.", error));
				dispatch(setAsyncFetching("adminTeams", false));
			})
			.catch(error => notifyBugSnag(new Error(error)));
	};
}

export function syncIntegrationCompanies(teams: string[]) {
	const api = new IntegratorsApi();

	return (dispatch) => {
		return api.syncIntegrationTeams(teams);
	};
}
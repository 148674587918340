import * as React from "react";
import * as update from "immutability-helper";
import { connect } from "react-redux";
import { isEqual } from "lodash";

import { PasswordRequirements, ICompany, CustomCSS } from "@connect/Interfaces";
import { getActiveCompany } from "Data/Selectors/Company";
import { updateCompanyAsync } from "Data/Actions/Company";
import { Input, Button } from "Components/Global/Common";

const mapStateToProps = (state) => ({
	company: getActiveCompany(state)
});

const mapDispatchToProps = (dispatch) => ({
	updateCompany: (company: ICompany) => dispatch(updateCompanyAsync(company))
});

interface PasswordPoliciesState extends PasswordRequirements {
}

interface PasswordPoliciesProps {
	company: ICompany;
	updateCompany: (company: ICompany) => Promise<void>;
}

class PasswordPolicies extends React.Component<PasswordPoliciesProps, PasswordPoliciesState> {
	constructor(props: PasswordPoliciesProps) {
		super(props);

		this.state = {
			minChars: props.company.passwordRequirements?.minChars,
			minLowerCase: props.company.passwordRequirements?.minLowerCase,
			minUpperCase: props.company.passwordRequirements?.minUpperCase,
			minNumbers: props.company.passwordRequirements?.minNumbers,
			minSpecialChars: props.company.passwordRequirements?.minSpecialChars
		}

		this.styles = {
			button: {
				marginTop: 10
			},
			grid: {
				display: "grid",
				width: "100%",
				gridTemplateColumns: "50% 50%"
			},
			gridChild: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "0px 18px 0px 18px"
			},
			headerStyle: {
				marginLeft: 12,
				paddingBottom: 14
			},
			info: {
				paddingLeft: 26
			},
			inline: {
				display: "inline-flex",
				alignItems: "center"
			},
			input: {
				width: 80
			},
			label: {
				marginRight: 10
			},
			tooltip: {
				marginTop: 10,
				width: "70%"
			}
		}

		this.updateMinChars = this.updateMinChars.bind(this);
		this.updateMinLowerCase = this.updateMinLowerCase.bind(this);
		this.updateMinUpperCase = this.updateMinUpperCase.bind(this);
		this.updateMinNumbers = this.updateMinNumbers.bind(this);
		this.updateMinSpecialChars = this.updateMinSpecialChars.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { gridChild, headerStyle, info, inline, grid, button, tooltip } = this.styles;

		if (!this.props.company.passwordRequirements) {
			return null;
		}

		const {
			minChars, minLowerCase, minUpperCase, minNumbers, minSpecialChars
		} = this.props.company.passwordRequirements;
		const disabled = isEqual(this.state, this.props.company.passwordRequirements);

		return (
			<div style={ gridChild }>
				<h3 style={ headerStyle }>Password Policy</h3>
				<div style={ info }>
					<div style={ { ...inline, ...grid } }>
						{ this.renderField("Minimum Characters:", minChars, "minChars", this.updateMinChars) }
						{ this.renderField("Minimum Lower Case Characters:", minLowerCase,
							"minLowerCase", this.updateMinLowerCase) }
						{ this.renderField("Minimum Upper Case Characters:", minUpperCase,
							"minUpperCase", this.updateMinUpperCase) }
						{ this.renderField("Minimum Numbers:", minNumbers, "minNumbers", this.updateMinNumbers) }
						{ this.renderField("Minimum Special Characters:", minSpecialChars,
							"minSpecialChars", this.updateMinSpecialChars) }
					</div>
					<Button
						type="primary"
						onClick={ this.handleUpdate }
						disabled={ disabled }
						style={ button }
					>
						Update Password Policies
					</Button>
					<p style={ tooltip }>
						Note: Users with passwords that do not conform to these rules will be
						logged out and must change their password.
					</p>
				</div>
			</div>
		);
	}

	renderField(title: string, value: number,  id: string, callback: (val: string) => void) {
		const { label, input } = this.styles;
		return (
			<React.Fragment>
				<div style={ label }>{ title }</div>
				<Input
					id={ id }
					key={ value }
					hideMaxLength
					updateCallback={ callback }
					style={ input }
					value={ value.toString() }
				/>
			</React.Fragment>
		);
	}

	updateState(key: string, value: number | boolean) {
		this.setState((state) => {
			return update(state, {
				[key]: { $set: value }
			});
		});
	}

	updateMinChars(characters: string) {
		const number = parseInt(characters, 10);
		this.updateState("minChars", number);
	}

	updateMinLowerCase(characters: string) {
		const number = parseInt(characters, 10);
		this.updateState("minLowerCase", number);
	}

	updateMinUpperCase(characters: string) {
		const number = parseInt(characters, 10);
		this.updateState("minUpperCase", number);
	}

	updateMinNumbers(characters: string) {
		const number = parseInt(characters, 10);
		this.updateState("minNumbers", number);
	}

	updateMinSpecialChars(characters: string) {
		const number = parseInt(characters, 10);
		this.updateState("minSpecialChars", number);
	}

	handleUpdate() {
		const { company, updateCompany } = this.props;
		const passwordRequirements: PasswordRequirements = this.state;
		const newCompany: ICompany = { ...company, passwordRequirements };

		updateCompany(newCompany);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPolicies);
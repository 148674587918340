import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IPlaylist, PlaylistAd } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { GridViewCard, IconData, IGridViewCardProps } from "Components/Global/GridViewCard";
import SideScroller from "Components/Global/SideScroller";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActivePlaylistDuration } from "Data/Selectors/Playlists";

const mapStateToProps = (state, ownProps: PlaylistCardProps) => ({
	duration: getActivePlaylistDuration(state)
});

interface PlaylistCardProps extends IGridViewCardProps<IPlaylist> {
	duration: number;
}

class PlaylistCard extends GridViewCard<PlaylistCardProps> {
	constructor(props: PlaylistCardProps) {
		super(props);

		this.styles = {
			outer: {
				position: "relative",
				cursor: "pointer",
				boxShadow: `0px 2px 10px ${Colors.lightGray}`,
				border: `1px solid ${Colors.white}`,
				borderRadius: 6,
				minHeight: 160,
				width: 560,
				margin: 10,
				justifySelf: "center"
			},
			checkbox: {
				position: "absolute",
				top: 4,
				left: 5
			},
			contentRow: {
				borderRadius: "6px 6px 0px 0px",
				width: "100%",
				height: 155,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				fontSize: 14,
				borderBottom: `1px solid ${Colors.white}`,
				backgroundColor: Colors.offWhite
			},
			infoRow: {
				height: 30,
				marginTop: 2,
				padding: 10,
				borderRadius: "6px 6px 0px 0px",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				fontSize: 14
			},
			center: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			thumbnail: {
				height: 120,
				display: "flex"
			},
			name: {
				display: "flex",
				flex: "1 0 55%",
				whiteSpace: "nowrap",
				overflowX: "hidden",
				marginRight: 10
			},
			duration: {
				display: "flex",
				flex: "1 0 auto"

			},
			actions: {
				display: "flex",
				marginLeft: 3,
				justifyContent: "flex-end"
			}
		};
		this.renderActionIcon = this.renderActionIcon.bind(this);
		this.renderIcon = this.renderIcon.bind(this);
		this.renderInfoRow = this.renderInfoRow.bind(this);
		this.renderThumbnail = this.renderThumbnail.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { bulkSelectActive, cardObject: playlist, isSelected } = this.props;
		const thumbnails = playlist.ads.map(this.renderThumbnail);

		let contentRow = {
			...this.styles.contentRow
		};

		let outer = {
			...this.styles.outer
		};

		if (bulkSelectActive && isSelected) {
			outer.backgroundColor = "#D7E6FC";
			outer.border = "1px solid #D7E6FC";
			contentRow.borderBottom = "1px solid #D7E6FC";
			contentRow.backgroundColor = "#D7E6FC";
		}

		return this.renderContainer(
			<div style={ outer }>
				<div style={ contentRow }>
					<SideScroller id={ playlist.uuid }>
						{ thumbnails }
					</SideScroller>
				</div>
				{ this.renderInfoRow() }
			</div>
		);
	}

	renderActionIcon() {
		if (!hasPermission(PERMISSIONS.ACTIONS_MANAGE)) {
			return null;
		}

		const { actionSet } = this.props.cardObject;

		const iconStyle = {
			color: (actionSet && actionSet.name) ? Colors.primaryBlue : Colors.lightestGray,
			display: "flex",
			flex: "1 0 auto",
			justifyContent: "flex-end"
		};

		return (
			<React.Fragment>
				<Icon name="running" iconStyle={ iconStyle } iconWeight="solid" />
				&nbsp;
			</React.Fragment>
		);
	}

	renderIcon(iconData: IconData) {
		return (
			<Icon
				name={ iconData.name }
				key={ iconData.name }
				style={ this.styles.actions }
				iconWeight="regular"
				onClick={ this.clickIcon(iconData.callback) } />
		)
	}

	renderInfoRow() {
		const { infoRow, name, duration } = this.styles;

		return (
			<div style={ infoRow }>
				<div style={ name }>
					<b><Truncate length={ 36 }>{ this.props.cardObject.name }</Truncate></b>
				</div>
				<div style={ duration }>
					{ this.props.duration }
				</div>
				<div style={ this.getActionsStyle() }>
					{ this.renderActionIcon() }
					{ (this.props.actionButtons || []).map(this.renderIcon) }
				</div>
			</div>
		);
	}

	renderThumbnail(ad: PlaylistAd) {
		// playlists are unique in this context but ads are not
		// so for each ad we additionally append the key with the playlist uuid
		return (
			<img
				key={ this.props.cardObject.uuid + ad.uuid }
				src={ ad.thumbnail }
				style={ this.styles.thumbnail } />
		)
	}

	getActionsStyle() {
		return {
			...this.styles.actions,
			visibility: this.props.bulkSelectActive ? "hidden" : "visible"
		}
	}

	getPlaylistDuration() {
		const { ads } = this.props.cardObject;
		let length = 0;

		if (ads) {
			length = ads.map((ad) => ad.duration).reduce((a, b) => a + b, 0);
		}

		return Utils.getHumanReadableDuration(length, false, false, true);
	}
}

export default connect(mapStateToProps)(PlaylistCard);
import * as moment from "moment";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { ApkVersion } from "@connect/Interfaces";
import { Api } from "Api/Api";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { createVersion, setCurrentVersion, setVersions } from "Data/Actions/Versions";

function getVersionsAsync() {
	return (dispatch) => {
		Api.AdminApi.getApkVersions()
			.then((result: ApkVersion[]) => {
				const versions = result.sort((a, b) => {
					// sort so that the unpublished or most recently published are first
					if (!a.published_at) {
						return -1;
					} else if (!b.published_at) {
						return 1;
					} else {
						return (moment.utc(a.published_at).isBefore(moment.utc(b.published_at))) ? 1 : -1;
					}
				});
				const currentVersion = versions.find((a) => !!a.published_at);

				dispatch(setVersions(versions.slice(0, 5)));
				dispatch(setCurrentVersion(currentVersion || {} as ApkVersion));
			}, (error) => {
				dispatch(errorNotification("Error getting versions"));
			})
			.catch((error) => dispatch(notifyBugSnagAsync(new Error(error))));
	}
}

function createVersionAsync(newVersion: ApkVersion) {
	return (dispatch) => {
		Api.AdminApi.createApkVersion(newVersion)
			.then((apkVersion: ApkVersion) => {
				dispatch(createVersion(apkVersion));
				dispatch(successNotification("Version " + apkVersion.version + " created!"));
			}, (error) => {
				dispatch(errorNotification("Could not create new version."));
			})
			.catch((error) => dispatch(notifyBugSnagAsync(new Error(error))));
	}
}

function publishVersionAsync(apkVersion: ApkVersion) {
	return (dispatch, getState) => {
		const { versions } = getState().Admin.Versions;

		Api.AdminApi.publishApkVersion(apkVersion)
			.then(() => {
				dispatch(successNotification("Version " + apkVersion.version + " published!"));

				const newVersions = versions.map((a) => {
					if (a.id === apkVersion.id) {
						a.published_at = moment.utc().format("YYYY-MM-DD HH:mm:SS");
					}
					return a;
				})

				dispatch(setVersions(newVersions));
				dispatch(setCurrentVersion(apkVersion));
			}, (error) => {
				dispatch(errorNotification("Could not publish version."));
			})
			.catch((error) => dispatch(notifyBugSnagAsync(new Error(error))));
	}
}

export {
	getVersionsAsync,
	createVersionAsync,
	publishVersionAsync
};
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as update from "immutability-helper";

import { ActiveUuidRoute, CustomCSS, Deployment, DeploymentPageLocationState, DeploySteps,
	IUser, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import ConfirmStep from "Components/Deploy/ConfirmStep";
import CreateNewDeployment from "Components/Deploy/CreateNewDeployment";
import DevicesStep from "Components/Deploy/DevicesStep"
import FilteredDeployments from "Components/Deploy/FilteredDeployments";
import ScheduleBuilderStep from "Components/Deploy/ScheduleBuilderStep";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import { Button, Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { IconWeights } from "Components/Global/Icon";
import Filter from "Components/Global/SidebarFilter";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import PlaylistList from "Components/Playlists/PlaylistList";
import { getDeploymentAsync, updateDeploymentAsync } from "Data/Actions/DeploymentsAsync";
import { setActiveFilters, setActiveSort } from "Data/Actions/UI";
import { cancelWizard, createDeploymentFromSchedule, setActiveDeployment as setActive, setDeploymentStep,
	startEventWizard,
	startScheduleWizard } from "Data/Actions/UI/DeploymentWizard";
import { getActiveDeployment, getDeploymentStep } from "Data/Selectors/DeploymentWizard";
import { getDeviceModelNames } from "Data/Selectors/Devices";
import { getActiveFilters, getActiveSorts, getActiveUuid, getPreviousNavigationItem } from "Data/Selectors/UI";
import { getCurrentUser } from "Data/Selectors/User";
import { isEqual } from "lodash";
import { getDeploymentById } from "Data/Selectors/Deployments";
import PresenceUsers from "Components/Global/PresenceUsers";
import { toggleFeature } from "@connect/Features";

const { yellow, white } = Colors;

const mapDispatchToProps = (dispatch) => ({
	cancelDeploymentWizard: () => dispatch(cancelWizard()),
	createPartialDeployment: (deployment: DeploymentPageLocationState) =>
		dispatch(createDeploymentFromSchedule(deployment)),
	getDeployment: (uuid: string) => dispatch(getDeploymentAsync(uuid)),
	saveDeployment: (deployment: Deployment) => dispatch(updateDeploymentAsync(deployment)),
	setActiveDeployment: (deployment: Deployment) => dispatch(setActive(deployment)),
	setActiveStatus: (status: string) => dispatch(setActiveFilters("status", Filters.DEPLOYMENTS, status)),
	setActiveType: (type: string) => dispatch(setActiveFilters("type", Filters.DEPLOYMENTS, type)),
	setCurrentStep: (step: DeploySteps) => dispatch(setDeploymentStep(step)),
	setSort: (sort: SortTypes) => dispatch(setActiveSort(Sorts.PLAYLISTS, sort)),
	startSchedule: () => dispatch(startScheduleWizard()),
	startEvent: () => dispatch(startEventWizard())
});

const mapStateToProps = (state) => {
	const { status, type } = getActiveFilters(state, Filters.DEPLOYMENTS);
	const activeUuid = getActiveUuid(state, "deploy");
	return {
		activeDeployment: getActiveDeployment(state),
		activeStatus: status,
		activeType: type,
		activeUuid: getActiveUuid(state, "deploy"),
		currentStep: getDeploymentStep(state),
		models: getDeviceModelNames(state),
		originalDeployment: getDeploymentById(state, activeUuid),
		playlistsSortType: getActiveSorts(state, Sorts.PLAYLISTS),
		previousPage: getPreviousNavigationItem(state),
		user: getCurrentUser(state)
	};
};

interface DeployPageProps extends RouteComponentProps<ActiveUuidRoute> {
	activeDeployment: Deployment;
	activeStatus: string;
	activeType: string;
	activeUuid: string;
	cancelDeploymentWizard: () => void;
	createPartialDeployment: (deployment: DeploymentPageLocationState) => void;
	currentStep: DeploySteps;
	getDeployment: (uuid: string) => Promise<Deployment>;
	models?: string[];
	originalDeployment: Deployment;
	playlistsSortType?: SortTypes;
	previousPage: string;
	saveDeployment: (deployment: Deployment) => Promise<void>;
	setActiveDeployment: (deployment: Deployment) => void;
	setActiveStatus: (status: string) => void;
	setActiveType: (type: string) => void;
	setCurrentStep: (step: DeploySteps) => void;
	setSort: (sort: SortTypes) => void;
	startEvent: () => void;
	startSchedule: () => void;
	user: IUser;
}

interface DeployPageState {
	agreeToLeave: boolean;
}

class DeployPage extends React.Component<DeployPageProps, DeployPageState> {
	constructor(props: DeployPageProps) {
		super(props);

		this.state = {
			agreeToLeave: false
		};

		this.styles = {
			dualButtons: {
				margin: "0 0.25em",
				color: white
			},
			listHeader: {
				display: "block",
				width: "100%"
			},
			listButtons: {
				display: "inline",
				float: "right"
			},
			presenceWrapper: {
				position: "absolute",
				top: 5,
				left: 10
			}
		};

		this.confirmLeaving = this.confirmLeaving.bind(this);
		this.createEvent = this.createEvent.bind(this);
		this.createSchedule = this.createSchedule.bind(this);
		this.handleDeploymentStatus = this.handleDeploymentStatus.bind(this);
		this.handleDeploymentType = this.handleDeploymentType.bind(this);
		this.handleSetDateSort = this.handleSetDateSort.bind(this);
		this.routerWillLeave = this.routerWillLeave.bind(this);
	}

	styles: CustomCSS;
	unblock: () => any;

	componentDidMount() {
		const {
			activeDeployment, activeUuid, createPartialDeployment, getDeployment,
			history, location, previousPage, setActiveDeployment
		} = this.props;
		const { state } = location;

		// we can do this because the DeployPage is rendered as a Route component
		if (history && !this.unblock) {
			this.unblock = history.block(this.routerWillLeave);
		}

		const comingFromDeployable = previousPage.includes("ads") || previousPage.includes("playlists");

		if (state && state.deployment && comingFromDeployable) {
			createPartialDeployment(state.deployment);
		}

		if (activeUuid && !activeDeployment) {
			getDeployment(activeUuid)
				.then((deployment: Deployment) => setActiveDeployment(deployment));
		}
	}

	componentWillUnmount() {
		this.unblock();
	}

	componentDidUpdate(prevProps: DeployPageProps) {
		if (prevProps.activeUuid && !this.props.activeUuid) {
			this.setState((prevState) => {
				return update(prevState, {
					agreeToLeave: { $set: false }
				});
			});
			this.props.cancelDeploymentWizard();
		}
	}

	render() {
		return (
			<React.Fragment>
				<ThreeColumnLayout
					leftContent={ this.renderLeftContent() }
					centerContent={ this.renderCenterContent() } />
				<CreateNewDeployment />
			</React.Fragment>
		);
	}

	renderCenterContent() {
		const { activeUuid, currentStep } = this.props;

		if (activeUuid) {
			const { CREATE_NEW, SCHEDULE, DEVICES, CONFIRM } = DeploySteps;

			let stepComponent;

			switch (currentStep) {
				case CREATE_NEW:
					// render FilteredDeployments, which serves as background for Create New Modal
					break;
				case SCHEDULE:
					stepComponent = <ScheduleBuilderStep />;
					break;
				case DEVICES:
					stepComponent = <DevicesStep />;
					break;
				case CONFIRM:
					stepComponent = <ConfirmStep />;
					break;
				default:
					return undefined;
			}

			return (
				<div>
					{ this.renderPresenceUsers() }
					{ stepComponent }
				</div>
			);

		}

		return (
			<FilteredDeployments />
		);
	}

	renderPresenceUsers() {
		if (!this.props.activeDeployment) {
			return null;
		}

		const { activeDeployment: { uuid } } = this.props;
		const { presenceWrapper } = this.styles;
		return toggleFeature("notifications",
			(
				<div style={ presenceWrapper }>
					<PresenceUsers
						type="deployment"
						uuid={ uuid }/>
				</div>
			),
			null
		)
	}

	renderCreateButtons() {
		const { createEvent, createSchedule } = this;

		return (
			<div>
				<Button
					corners="rounded"
					fluid
					icon="plus-circle"
					onClick={ createSchedule }
					type="primary">
					New Schedule
				</Button>
				<br />
				<Button
					className="dark-hover-button"
					color={ yellow }
					corners="rounded"
					fluid
					icon="exclamation-triangle"
					onClick={ createEvent }>
					New Event
				</Button>
			</div>
		);
	}

	renderDeploymentStatusFilter(): AccordionElement | null {
		const { activeType, activeStatus, currentStep } = this.props;

		if (currentStep && currentStep !== DeploySteps.CREATE_NEW) {
			return null;
		}

		const filters = [
			{ name: "All", icon: "archive", id: "all" },
			{ name: "Upcoming", icon: "clock", id: "upcoming", iconWeight: "regular" as IconWeights },
			{ name: "Deployed", icon: "check", id: "deployed" },
			{ name: "Drafts", icon: "pencil", id: "draft" },
			{ name: "Cancelled", icon: "ban", id: "cancelled"}
		];

		if (!activeType || activeType === "all" || activeType === "event") {
			filters.push({ name: "Live Events", icon: "bullseye", id: "live" });
		}

		return new AccordionElement("Deployment Status", (
			<Filter
				activeFilter={ activeStatus }
				filters={ filters }
				onFilterChange={ this.handleDeploymentStatus }
			/>
		));
	}

	renderDeploymentTypeFilter(): AccordionElement | null {
		const { activeType, currentStep } = this.props;

		if (currentStep && currentStep !== DeploySteps.CREATE_NEW) {
			return null;
		}

		const filters = [
			{ name: "All", icon: "archive", id: "all" },
			{ name: "Schedules", icon: "calendar", id: "schedule" },
			{ name: "Events", icon: "exclamation-triangle", id: "event" }
		];

		return new AccordionElement("Deployment Type", (
			<Filter
				activeFilter={activeType}
				filters={filters}
				onFilterChange={this.handleDeploymentType}
			/>
		));
	}

	renderLeftContent() {
		const { currentStep } = this.props;
		const sidebarIsVisible =
			!currentStep ||
			currentStep === DeploySteps.SCHEDULE ||
			currentStep === DeploySteps.CREATE_NEW;

		if (!sidebarIsVisible) {
			return undefined;
		}

		const typeFilter = this.renderDeploymentTypeFilter();
		const statusFilter = this.renderDeploymentStatusFilter();
		const playlists = this.renderPlaylistPicker();
		let sidebarElements: AccordionElement[] = [];

		if (typeFilter) {
			sidebarElements.push(typeFilter);
		}
		if (statusFilter) {
			sidebarElements.push(statusFilter);
		}
		if (playlists) {
			sidebarElements.push(playlists);
		}

		return (
			<Accordion
				header={ this.renderCreateButtons() }
				elements={ sidebarElements }
			/>
		);
	}

	renderPlaylistPicker(): AccordionElement | null {
		if (this.props.currentStep !== DeploySteps.SCHEDULE) {
			return null;
		}
		const { listHeader, listButtons } = this.styles;
		const { playlistsSortType } = this.props;
		const dateSortDirection = playlistsSortType === SortTypes.NEWEST_FIRST ? "up" : "down";

		return new AccordionElement((
			<div style={ listHeader }>
				My Playlists
				<div style={ listButtons }>
					<span onClick={ this.handleSetDateSort }>
						<Icon name="calendar" sortDirection={ dateSortDirection } />
					</span>
				</div>
			</div>
		), <PlaylistList sortType={ this.props.playlistsSortType } />);
	}

	confirmLeaving(nextLocation: any) {
		const { activeDeployment, history, saveDeployment,
			setCurrentStep} = this.props;

		Notifications.confirm(
			"You have unsaved changes",
			"Leaving this page will result in losing any changes.",
			"Save Changes",
			"Discard Changes",
			() => {
				saveDeployment(activeDeployment).then(() => {
					this.setState((prevState) => {
						return update(prevState, {
							agreeToLeave: { $set: true }
						});
					});
					setCurrentStep({} as DeploySteps);
					history.push(nextLocation);
				});
			},
			() => {
				this.setState((prevState) => {
					return update(prevState, {
						agreeToLeave: { $set: true }
					});
				}, this.discardCallback(nextLocation));
			}
		);
	}

	discardCallback(nextLocation: any) {
		return () => {
			this.props.cancelDeploymentWizard();
			this.props.history.push(nextLocation);
		}
	}

	createEvent(event: React.MouseEvent<HTMLElement>) {
		this.props.startEvent();
	}

	createSchedule(event: React.MouseEvent<HTMLElement>) {
		this.props.startSchedule();
	}

	handleDeploymentStatus(name: string) {
		const { activeType, setActiveStatus, setActiveType } = this.props;

		setActiveStatus(name);

		if (name && !activeType) {
			setActiveType("all");
		}
	}

	handleDeploymentType(name: string) {
		const { activeStatus, setActiveStatus, setActiveType } = this.props;

		setActiveType(name);

		const noActiveStatus = name && !activeStatus;
		const isScheduleAndFilterIsLive = name === "schedule" && activeStatus === "live";

		if (noActiveStatus || isScheduleAndFilterIsLive) {
			setActiveStatus("all");
		}
	}

	handleSetDateSort(event: React.SyntheticEvent) {
		event.preventDefault();

		const { setSort, playlistsSortType } = this.props;
		const { NEWEST_FIRST, OLDEST_FIRST } = SortTypes;
		const newSortType = playlistsSortType === NEWEST_FIRST ? OLDEST_FIRST : NEWEST_FIRST;

		setSort(newSortType);
	}

	routerWillLeave(nextLocation: any): false | void {
		const { activeDeployment, activeUuid, originalDeployment } = this.props;
		// ensure the wizard is not active before navigating away
		if (!activeUuid || !activeDeployment) {
			return undefined;
		}

		if (!isEqual(originalDeployment, activeDeployment) && !this.state.agreeToLeave) {
			this.confirmLeaving(nextLocation);
			return false;
		}

		return undefined;
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(DeployPage);

import * as React from "react";
import {
	DragSource,
	DragSourceCollector,
	DragSourceConnector,
	DragSourceMonitor,
	DragSourceSpec,
	DropTarget,
	DropTargetCollector,
	DropTargetConnector,
	DropTargetMonitor,
	DropTargetSpec
} from "react-dnd";
import { findDOMNode } from "react-dom";

import { CustomCSS, IMedia } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { DropZone } from "Components/Global/DropZone";
import MediaHandler from "Components/Global/MediaHandler";
import { SortableList } from "Data/Objects/DragTypes";

export type HoverState = "top" | "bottom";

export interface DragNDropListItem {
	name: string;
	sort: number;
	uuid: string;
	active?: boolean;
	progress?: number;
}

interface DragNDropSortableListItemCollectedProps {
	connectDragSource?: Function;
	connectDropTarget?: Function;
	currentlyDragging?: boolean;
	isOver?: boolean;
}

interface DragNDropSortableListItemProps {
	connectDragSource?: Function;
	connectDropTarget?: Function;
	currentlyDragging?: boolean;
	isOver?: boolean;
	isActive?: boolean;
	isSelected?: boolean;
	item?: DragNDropListItem;
	removeItem?: (item: DragNDropListItem) => void;
	selectItem?: (item: DragNDropListItem) => void;
	updateItem?: (item: DragNDropListItem | undefined, originalItem: DragNDropListItem, hover: HoverState) => void;
	onUploadSuccess?: (media: IMedia) => void;
}

interface DragNDropSortableListItemState {
	hover?: HoverState;
}

const { dropBackground, filters, gray, lightGray, lightestGray, lightishGray, primaryBlue, white } = Colors;

const listDragCollector: DragSourceCollector<
DragNDropSortableListItemCollectedProps
> = (dragConnect: DragSourceConnector, monitor: DragSourceMonitor) => ({
	connectDragSource: dragConnect.dragSource(),
	currentlyDragging: monitor.isDragging()
});
const listDragSource: DragSourceSpec<DragNDropSortableListItemProps, DragNDropSortableListItemProps> = {
	beginDrag(props: DragNDropSortableListItemProps) {
		return props;
	},
	endDrag(props: DragNDropSortableListItemProps, monitor: DragSourceMonitor, component: React.Component) {
		const dropped = monitor.didDrop();
		const mounted = component !== null;

		if (dropped && mounted) {
			const { item, updateItem } = props;
			const { item: itemDroppingOver, hover } = monitor.getDropResult() as { item: DragNDropListItem, hover: HoverState };

			if (updateItem) {
				updateItem(item, itemDroppingOver, hover);
			}
		}
	}
};

const listDropCollector: DropTargetCollector<
DragNDropSortableListItemCollectedProps
> = (dropConnect: DropTargetConnector, monitor: DropTargetMonitor) => ({
	connectDropTarget: dropConnect.dropTarget(),
	isOver: monitor.isOver()
});

const listDropTarget: DropTargetSpec<DragNDropSortableListItemProps> = {
	drop(props: DragNDropSortableListItemProps, monitor: DropTargetMonitor, component: DragNDropSortableListItem) {
		if (props.item === null) {
			return {};
		}
		return {
			item: props.item,
			hover: component.state.hover
		};
	},
	hover(props: DragNDropSortableListItemProps, monitor: DropTargetMonitor, component: DragNDropSortableListItem) {
		const { 0: listEl } = (findDOMNode(component) as Element).getElementsByClassName("sortable-list-item")

		if (listEl) {
			const bounds = listEl.getBoundingClientRect();
			const middle = (bounds.bottom - bounds.top) / 2;
			const mousePosition = monitor.getClientOffset() || { y: 0 };
			const mouseFromTop = mousePosition.y - bounds.top;

			component.setState(() => ({
				hover: mouseFromTop < middle ? "top" : "bottom"
			}));
		}
	}
};

export class DragNDropSortableListItem
	extends React.PureComponent<DragNDropSortableListItemProps, DragNDropSortableListItemState> {
	constructor(props: DragNDropSortableListItemProps) {
		super(props);

		const { item, isActive } = props;

		this.state = {
			hover: undefined
		};

		this.styles = {
			around: {
				justifyContent: "space-around"
			},
			between: {
				justifyContent: "space-between"
			},
			dropRow: {
				border: `1px dashed ${lightestGray}`,
				color: lightGray,
				marginTop: 2
			},
			hidden: {
				display: "none"
			},
			hover: {
				backgroundColor: dropBackground,
				borderColor: primaryBlue,
				color: gray
			},
			itemContainer: {
				display: "flex",
				padding: 2,
				transition: "background 200ms",
				background: isActive ? lightishGray : "transparent"
			},
			pointer: {
				cursor: "pointer"
			},
			text: {
				overflow: "hidden",
				textOverflow: "ellipsis",
				whitespace: "nowrap",
				display: "inline",
				color: item && item.progress ? lightishGray : white
			},
			itemRow: {
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				position: "relative",
				padding: "0px 3px"
			},
			progress: {
				display: item && item.progress ? "block" : "none",
				background: "rgba(50, 70, 180, 0.5",
				width: `${ item && item.progress }%`,
				height: "100%",
				position: "absolute",
				top: 0,
				left: 0
			},
			icon: {
				display: item && item.progress ? "none" : "block"
			}
		}

		this.removeItem = this.removeItem.bind(this);
		this.selectItem = this.selectItem.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { connectDragSource, connectDropTarget, currentlyDragging, item } = this.props;
		const { hover } = this.state;

		if (!item && connectDropTarget) {
			return connectDropTarget(this.renderDropZone());
		}

		if (currentlyDragging) {
			return null;
		}

		const content = (
			<div>
				{hover === "top" ? this.renderDropZone() : null}
				{item ? this.renderItem() : null}
				{hover === "bottom" ? this.renderDropZone() : null}
			</div>
		);
		const dropContent = connectDropTarget ? connectDropTarget(content) : content;

		return connectDragSource ? connectDragSource(dropContent) : dropContent;
	}

	renderDropZone() {
		const { around, dropRow, hidden, hover, itemContainer, text } = this.styles;
		const { isOver, item } = this.props;
		const isVisible = (item && isOver) || !item;

		return (
			<div>
				<DropZone
					clear
					ref={ null }
					prompt=""
					onDrop={ this.handleDrop }
				>
					<div style={{
						...itemContainer,
						...around,
						...dropRow,
						...(!isVisible ? hidden : null),
						...(isOver ? hover : null)
					}}>
						<div style={text}>Drop your item to place it here.</div>
						<Icon name="plus-circle" size="smallest" />
					</div>
				</DropZone>
			</div>
		);
	}

	renderItem() {
		const { item } = this.props;

		if (!item) {
			return null;
		}

		const { between, itemContainer, pointer, text, itemRow, progress, icon } = this.styles;

		return (
			<div className="sortable-list-item" key={item.uuid} style={{
				...itemContainer,
				...between,
				...pointer,
				background: this.getBackground()
			}}>
				<div style={ itemRow }>
					<div style={ progress } />
					<div style={text} onClick={this.selectItem}>
						{item.name}
					</div>
					<div style={ icon }>
						<Icon name="times" onClick={this.removeItem} />
					</div>
				</div>
			</div>
		);
	}

	handleDrop(files: File[]) {
		const { onUploadSuccess } = this.props;
		const handler = new MediaHandler();

		handler.originator = "slideshow_image";
		handler.allowedFileTypes = [ "Image" ];

		if (onUploadSuccess) {
			handler.onSuccess = onUploadSuccess;
		}

		handler.handleFiles(files);
	}

	getBackground() {
		const { isSelected, isActive } = this.props;
		if (isSelected) {
			return  filters.hoverAlt;
		}

		if (isActive) {
			return lightishGray;
		}

		return "none";
	}

	removeItem() {
		const { item, removeItem } = this.props;

		if (removeItem && item) {
			removeItem(item);
		}
	}

	selectItem() {
		const { item, selectItem } = this.props;

		if (selectItem && item) {
			selectItem(item);
		}
	}
}

export default DragSource(SortableList.ListItem, listDragSource, listDragCollector)(
	DropTarget(SortableList.ListItem, listDropTarget, listDropCollector)(DragNDropSortableListItem)
);

import * as update from "immutability-helper";

import { WithUuid } from "@connect/Interfaces";
import { AppState } from "Data/Objects/AppState";

export function updateStateItem<S>(state: S, key: string, data: WithUuid) {
	if (state && state[key]) {
		return update(state, {
			[key]: {
				$merge: data
			}
		});
	}

	return state;
}

export function updateStateArrayItem<S>(state: S, key: string, data: WithUuid) {
	if (state && state[key] && Array.isArray(state[key])) {
		const index =
			state[key]
				.map((item) => item.uuid)
				.indexOf(data.uuid);
		if (index >= 0) {
			return update(state, {
				[key]: {
					[index]:
					{
						$merge: data
					}
				}
			});
		}
	}

	return state;
}

export function identityReducer<S>(state: AppState, argument: S): S {
	return argument;
}
import { notification } from "antd";

import { AntNotificationArgs, NotificationTypes } from "@connect/Interfaces";

export const notify = (type: NotificationTypes, title: string, content: string | JSX.Element,
	icon: JSX.Element | null, duration: number, onClose: () => void, key?: string) => {
	let args: AntNotificationArgs = {
		message: title,
		description: content,
		icon: icon,
		duration: duration,
		onClose: onClose,
		key
	};

	if (args.duration < 0) {
		args.duration = 0;
	}

	if (type === "plain") {
		notification.open(args);
	} else {
		notification[type](args);
	}

	return notification;
}
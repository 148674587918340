import * as React from "react";

import { CustomCSS, LightbarFrame } from "@connect/Interfaces";
import LightbarContainer from "Components/Actions/Lightbar/Container";
import { LightbarPresets } from "Data/Objects/Actions";
import { Colors } from "Components/Global/Constants";

export const getPreset = (index: number): LightbarFrame[] => LightbarPresets[index];

interface LightbarPreviewProps {
	index: number;
	disabled?: boolean;
	selected?: boolean;
	onClick?: () => void;
}

export default class LightbarPreview extends React.Component<LightbarPreviewProps> {
	constructor(props: LightbarPreviewProps) {
		super(props);

		this.styles = {
			lightbar: {
				display: "flex",
				textAlign: "center",
				overflow: "hidden",
				marginBottom: 10,
				border: "2px solid transparent"
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { lightbar } = this.styles;
		const { disabled, index, selected, onClick } = this.props;
		const preset = getPreset(index);

		return (
			<LightbarContainer
				onClick={ onClick }
				preset={ preset }
				style={ {
					...lightbar,
					borderColor: selected ? Colors.primaryBlue : "transparent"
				} }
				selected={ selected }
				disabled={ disabled } />
		);
	}
}

import { getState } from "@connect/Data";
import { ErrorResultV2, ICompany, IUser, IUsersApi, UsersResult  } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

const getCompanyUrl = (uuid: string) => `companies/${ uuid }`;

export default class UsersApi extends ApiV2 implements IUsersApi {
	createUser(user: IUser, company: ICompany) {
		const { name, email, role } = user;
		const params = {
			name,
			email,
			role: role.name,
			password: "11111111"
		};

		return this.POST(`${ getCompanyUrl(company.uuid) }/users`, params) as Promise<IUser>;
	}

	getUserList(companyUuid: string, perPage: number = 20, page: number = 1) {
		const params = { page, perPage };
		return this.GET("users", params) as Promise<UsersResult>;
	}

	deleteUser(user: IUser, company: ICompany) {
		return this.DELETE(
			`${ getCompanyUrl(company.uuid) }/users/${ user.uuid }`,
			undefined,
			true
		) as Promise<ErrorResultV2>;
	}

	updateUser(user: IUser) {
		const companyUuid = user.company ? user.company.uuid : getState().Company.companies[0].uuid;
		const updateUser = Object.assign({}, user, { role: user.role.name });
		return this.PUT(`${ getCompanyUrl(companyUuid) }/users/${ user.uuid }`, updateUser, true) as Promise<ErrorResultV2>;
	}
}

import * as React from "react";

import { CustomCSS, MediaContext as Context } from "@connect/Interfaces";
import BatchOperations, { IBatchOperationsProps } from "Components/Global/BatchOperations";
import Button, { ButtonProps } from "Components/Global/Button";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import SearchBar, { ISearchBarProps } from "Components/Global/SearchBar";
import SortDropdown, { ISortDropdownProps } from "Components/Global/SortDropdown";
import TagFilter, { ITagFilterProps } from "Components/Global/TagFilter";
import ViewSwitch, { IViewSwitchProps } from "Components/Global/ViewSwitch";

export interface SuffixButtonProps extends ButtonProps {
	children?: string; // add button text
}

interface IContentAreaTopBarProps {
	batch?: IBatchOperationsProps;
	closeButton?: () => void;
	context?: Context;
	currentView?: IViewSwitchProps;
	prefixButtons?: SuffixButtonProps[];
	search?: ISearchBarProps;
	sort?: Partial<ISortDropdownProps>;
	suffixButtons?: SuffixButtonProps[];
	tags?: ITagFilterProps;
}

export default class ContentAreaTopBar extends React.PureComponent<IContentAreaTopBarProps> {
	constructor(props: IContentAreaTopBarProps) {
		super(props);

		this.styles = {
			buttonPadding: {
				leftMargin: 10
			},
			child: {
				display: "flex",
				flex: "0",
				paddingTop: 3
			},
			childLabel: {
				marginRight: 10
			},
			closeButtonStyle: {
				cursor: "pointer",
				padding: "4px 10px",
				textAlign: "center",
				width: 30
			},
			container: {
				alignItems: "start",
				background: Colors.white,
				display: "flex",
				flex: "1 0 auto",
				flexWrap: "wrap",
				justifyContent: "flex-end",
				padding: "10px 20px",
				position: "sticky",
				top: 0,
				width: "100%",
				zIndex: 1000
			},
			flexChild: {
				flex: "1 1 auto"
			},
			flexEnd: {
				justifyContent: "flex-end"
			},
			sorts: {
				alignItems: "center",
				display: "inline-flex",
				minWidth: 174
			}
		};

		this.renderButton = this.renderButton.bind(this);
		this.renderCloseButton = this.renderCloseButton.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { child, container, flexChild, flexEnd } = this.styles;

		return (
			<div className="global-content-top-bar-header" style={ container }>
				{ this.renderButtons(this.props.prefixButtons) }
				{ this.renderBatchOperations() }
				<div style={{ ...child, ...flexChild, ...flexEnd }}>
					{
						/**
						 * we want to allow for any children passed in that may not be associated with
						 * the common functionality we are attempting to codify with this component
						 */
						this.props.children
					}
				</div>
				{ this.renderSorts() }
				{ this.renderTagFilter() }
				{ this.renderTextSearch() }
				{ this.renderGridListSwitch() }
				{ this.renderButtons(this.props.suffixButtons) }
				{ this.renderCloseButton() }
			</div>
		);
	}

	renderBatchOperations() {
		const { batch, context } = this.props;

		if (!batch) {
			return null;
		}

		batch.context = context;

		return (
			<div style={{ ...this.styles.child, ...this.styles.flexChild }}>
				<BatchOperations { ...batch } />
			</div>
		);
	}

	renderGridListSwitch() {
		const { currentView } = this.props;

		if (!currentView) {
			return null;
		}

		return (
			<div style={ this.styles.child }>
				<ViewSwitch { ...currentView } />
			</div>
		);
	}

	renderSorts() {
		const { sort } = this.props;

		if (!sort) {
			return null;
		}

		const { child, childLabel, sorts } = this.styles;

		return (
			<div style={{ ...child, ...sorts }}>
				<span style={ childLabel }>
					Sort by
				</span>
				<SortDropdown { ...sort } />
			</div>
		);
	}

	renderButton(buttonProps: ButtonProps, index: number) {
		const props = { ...buttonProps, styles: this.styles.buttonPadding }

		return (
			<Button { ...props } key={`suffixButton_${index}`} />
		);
	}

	renderButtons(buttons: SuffixButtonProps[] = []) {
		if (!buttons.length) {
			return null;
		}

		return (
			<div style={ this.styles.child }>
				{ buttons.map(this.renderButton) }
			</div>
		);
	}

	renderCloseButton() {
		const { closeButton } = this.props;

		if (!closeButton) {
			return null;
		}

		const { child, closeButtonStyle } = this.styles;

		return (
			<div style={ child }>
				<Icon
					name="times"
					onClick={ closeButton }
					size="smaller"
					style={ closeButtonStyle }
				/>
			</div>
		);
	}

	renderTagFilter() {
		const { tags } = this.props;

		if (!tags) {
			return null;
		}

		return (
			<div style={ this.styles.child }>
				<TagFilter { ...tags } />
			</div>
		);
	}

	renderTextSearch() {
		const { search } = this.props;

		if (!search) {
			return null;
		}

		return (
			<div style={ this.styles.child }>
				<SearchBar { ...search } />
			</div>
		);
	}
}
import * as React from "react";

import { CustomCSS, TagDataType } from "@connect/Interfaces";
import { Select } from "Components/Global/Common";

const { Option } = Select;
const properCaseTagType = {
	ads: "Ads",
	media: "Media",
	devices: "Devices"
};

export interface ITagFilterProps {
	activeTags: string[];
	tags: string[];
	tagSelectChange: (tags: string[]) => void;
	tagType: TagDataType;
	style?: CustomCSS;
	theme?: "dark";
}

export default class TagFilter extends React.PureComponent<ITagFilterProps> {
	constructor(props: ITagFilterProps) {
		super(props);

		this.styles = {
			select: {
				marginLeft: 10,
				width: 250,
				...this.props.style
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { activeTags, tags, tagSelectChange, tagType, theme } = this.props;
		const content = properCaseTagType[tagType];
		const tagPlaceholder = `Search Tagged ${content}`;

		return (
			<Select
				key={ `tags_${ activeTags.length }_${ tags.length }` }
				className={ `${ theme }-select` }
				defaultValue={ activeTags }
				disabled={ !tags.length }
				mode="multiple"
				onChange={ tagSelectChange }
				placeholder={ tagPlaceholder }
				style={ this.styles.select }
				tokenSeparators={ [ "," ] }
			>
				{ tags.map(this.renderTagOption) }
			</Select>
		);
	}

	renderTagOption(tag: string, index: number) {
		return (
			<Option key={ tag }>{ tag }</Option>
		);
	}
}

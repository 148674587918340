import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Line,
	LineChart,
	LineProps,
	LineType,
	ResponsiveContainer,
	Tooltip,
	TooltipProps,
	XAxis,
	YAxis
} from "recharts";

import { AnalyticsDataResult, CustomCSS } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import AnalyticsTooltip from "Components/Analytics/AnalyticsTooltip";
import { ISuperAnalyticsChartProps, mapStateToProps as superMapStateToProps,
	SuperAnalyticsChart } from "Components/Analytics/SuperAnalyticsChartComponent";
import { XAxisTick } from "Components/Analytics/XAxisTick";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { cloneDeep } from "lodash";

const { lightGray, primaryBlue, primaryGreen, red, yellow, lightestGray, violet, white, offWhite, black } = Colors;

const iconColors = [ primaryBlue, primaryGreen, yellow, red, violet, lightestGray ];

type ViewTypes = "bar" | "line";

interface ViewsStat {
	stat: number;
	name: string;
	dataKey: string;
}

interface PlaysAndViewsChartProps extends ISuperAnalyticsChartProps {
	grouping: number;
}

interface PlaysAndViewsChartState {
	selectedStat: string;
	selectedView: ViewTypes;
}

// leave this set statically until we decide to use it in the future for better granularity
const grouping = 1; // can be 1, 5, or 30

const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);

	return {
		...superProps,
		grouping
	};
};

export class PlaysAndViewsChart extends SuperAnalyticsChart<PlaysAndViewsChartProps, PlaysAndViewsChartState> {
	constructor(props: PlaysAndViewsChartProps) {
		super(props);

		this.state = {
			selectedStat: "plays",
			selectedView: "bar"
		}

		this.styles = Object.assign({}, this.styles, {
			block: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: 125,
				width: 125,
				borderStyle: "solid",
				borderColor: offWhite,
				paddingTop: 8
			},
			chartContainer: {
				...this.styles.chartContainer,
				marginLeft: -20
			},
			description: {
				fontWeight: 700,
				marginBottom: 16
			},
			statistic: {
				fontSize: "2.5em",
				fontWeight: 700
			},
			statsContainer: {
				...this.styles.statsContainer,
				justifyContent: "center"
			}
		});

		this.formatData = this.formatData.bind(this);
		this.getBlockStyle = this.getBlockStyle.bind(this);
		this.getIconStyle = this.getIconStyle.bind(this);
		this.getViewButtonStyle = this.getViewButtonStyle.bind(this);
		this.renderStats = this.renderStats.bind(this);
		this.renderView = this.renderView.bind(this);
		this.setSelectedStat = this.setSelectedStat.bind(this);
		this.setSelectedView = this.setSelectedView.bind(this);
	}

	styles: CustomCSS;
	data: AnalyticsDataResult[];
	stats: ViewsStat[];

	componentWillMount() {
		this.setupComponent()
	}

	componentWillUpdate(nextProps: PlaysAndViewsChartProps) {
		this.setupComponent(nextProps);
	}

	render() {
		const { data } = this.props;

		if (!data.length) {
			return this.renderContainer(<div />);
		}

		const { chartContainer, main, statsContainer, views } = this.styles;
		const stats = this.stats.map(this.renderStats);
		const chart = this.props.loading ? <div /> : this.formatChart();

		return this.renderContainer(
			<div style={ main }>
				<div style={ views }>
					{ this.renderView("bar") }
					{ this.renderView("line") }
				</div>
				<div style={ chartContainer }>
					<ResponsiveContainer width="90%" height="90%">
						{ chart }
					</ResponsiveContainer>
				</div>
				<div style={ statsContainer }>
					{ stats }
				</div>
			</div>
		);
	}

	renderStats(item: ViewsStat, index: number) {
		const { stat, name, dataKey } = item;
		const { data, result } = this.props;
		const { statistic, description } = this.styles;
		const formattedStat = Utils.abbreviateNumber(Number(stat), 1);
		const enabled = Boolean(stat);
		const trend = result.trend && result.trend[dataKey] && result.trend[dataKey].toFixed(2);
		let trendIcon;
		let trendColor;

		if (trend > 0) {
			trendIcon = "↑";
			trendColor = primaryGreen;
		} else if (trend < 0) {
			trendIcon = "↓";
			trendColor = red;
		} else {
			trendIcon = "";
			trendColor = black;
		}

		if (!data || !data.length) {
			return null;
		}

		return (
			<div
				style={ this.getBlockStyle(dataKey, enabled, index) }
				key={ dataKey }
				onClick={ stat ? this.setSelectedStat(dataKey) : undefined }
			>
				<span style={ description }>
					<Icon
						name="circle"
						style={ this.getIconStyle(index, enabled) }
					/>
					{ name }
				</span>
				<span style={ statistic }>{ formattedStat }</span>
				<span style={{ color: trendColor }}>{ trendIcon } { trend }%</span>
			</div>
		);
	}

	renderView(chartType: ViewTypes) {
		return (
			<div
				style={ this.getViewButtonStyle(chartType) }
				onClick={ this.setSelectedView(chartType) }
			>
				<Icon
					name={ `chart-${ chartType }` }
					size="smaller"
				/>
			</div>
		);
	}

	formatChart() {
		const { selectedStat, selectedView } = this.state;
		const isLine = selectedView === "line";
		const statIndex = this.stats.findIndex((stat) => stat.dataKey === selectedStat);
		const [ start, end ] = this.props.range.split("_");
		const allDates = Utils.getDaysBetweenDates(start, end);
		const thirtyPlus = allDates.length > 30;
		let interval = 0;

		switch (allDates.length) {
			case 30:
				interval = 8; // (30 / 4)
				break;
			case 90:
				interval = 3; // (90 / 7 / 4)
				break;
			case 365:
				interval = 13; // (365 / 7 / 4)
				break;
		}

		const xAxisProps = {
			dataKey: "date",
			interval,
			stroke: "6px",
			style: { fill: lightGray },
			tick: this.renderXAxisTick(interval, thirtyPlus)
		};

		const yAxisProps = {
			dataKey: selectedStat,
			style: { fill: lightGray },
			tickFormatter: this.formatYAxis
		};

		let areaProps = {
			dataKey: selectedStat,
			fill: iconColors[statIndex],
			stroke: isLine ? iconColors[statIndex] : undefined,
			strokeWidth: 3
		};

		if (isLine) {
			(areaProps as LineProps).type = "linear" as LineType;
		}

		const tooltipProps = {
			content: this.renderTooltip(thirtyPlus),
			cursor: { visibility: "hidden" },
			isAnimationActive: false
		};

		const chartProps = {
			data: this.data,
			key: this.data.length,
			margin: { top: 20, bottom: 10 }
		};

		return isLine ? (
			<LineChart { ...chartProps }>
				<CartesianGrid strokeDasharray="1 1" vertical={ false } />
				<XAxis { ...xAxisProps } />
				<YAxis { ...yAxisProps } />
				<Tooltip { ...tooltipProps } />
				<Line { ...areaProps } />
			</LineChart>
		) : (
			<BarChart { ...chartProps }>
				<CartesianGrid strokeDasharray="1 1" vertical={ false } />
				<XAxis { ...xAxisProps } />
				<YAxis { ...yAxisProps } />
				<Tooltip { ...tooltipProps } />
				<Bar { ...areaProps } />
			</BarChart>
		);
	}

	renderTooltip(thirtyPlus: boolean) {
		return ({ active, payload, label }: TooltipProps) => {
			if (!active || !payload) {
				return null;
			}

			const [ { payload: data, name } ] = payload;
			const { date } = data;
			const value = data[this.state.selectedStat];

			return (
				<AnalyticsTooltip
					label={ name }
					value={ Utils.abbreviateNumber(value, 1) }
					date={ date }
					combineDates={ thirtyPlus }
				/>
			);
		};
	}

	renderXAxisTick(interval: number, timesSeven: boolean) {
		return (props: any) => {
			return <XAxisTick { ...props } interval={ interval } timesSeven />;
		};
	}

	formatYAxis(stat: number) {
		return Utils.abbreviateNumber(stat, 0);
	}

	formatData(props: PlaysAndViewsChartProps) {
		// filter data down to one combined data point per day, combining duplicate date entries
		const [ start, end ] = props.range.split("_");
		const allDates = Utils.getDaysBetweenDates(start, end);
		const subdivide = allDates.length > 30; // if we are looking at more than 30 days of data, pare it down by week
		const newDates = allDates
			.filter((date, index) => subdivide ? index % 7 === 0 : true)
			.map((date) => {
				return {
					date,
					plays: 0,
					views: 0,
					motion: 0,
					buttonFront: 0,
					alarm0: 0,
					alarm1: 0
				} as Partial<AnalyticsDataResult>
			});
		const data = cloneDeep(props.data);

		data.forEach((item, i) => {
			const dates = newDates.map((d) => d.date);
			const momentDate = moment(item.date);
			const dateIndex = subdivide
				? dates.findIndex((d) => momentDate.isSameOrBefore(d, "week"))
				: dates.indexOf(item.date);
			const keys = Object.keys(item);

			// iterate over the values in each row of data
			keys.forEach((key) => {
				const value = item[key];

				// not an object
				if (value === undefined) {
					return newDates[dateIndex] = item;
				}

				// discard non-numeric values
				if (typeof(value) === "number") {
					// append data to the last date-entry if we don't have an index of it
					if (dateIndex === -1) {
						return newDates[newDates.length - 1][key] += value;
					}

					// if we're all good here, concatenate the value to the appropriate date index
					return newDates[dateIndex][key] += value;
				}
			});
		});

		this.data = Utils.sort(newDates, "date", true);
	}

	formatStats(props: PlaysAndViewsChartProps) {
		const data: any = cloneDeep(props.data);
		const totals = data.reduce((total, current) => {
			Object.keys(current).forEach((key) => {
				if (typeof(current[key]) === "number") {
					if (!total[key]) {
						total[key] = 0;
					}

					total[key] += current[key];
				}
			});

			return total;
		}, {});

		const { plays, views, motion, buttonFront, alarm0, alarm1 } = totals;

		this.stats = [
			{
				stat: plays,
				name: "Total Ad Plays",
				dataKey: "plays"
			},
			{
				stat: views,
				name: "Total Views",
				dataKey: "views"
			},
			{
				stat: motion,
				name: "Motion",
				dataKey: "motion"
			},
			{
				stat: buttonFront,
				name: "Button",
				dataKey: "buttonFront"
			},
			{
				stat: alarm0,
				name: "Alarm 1",
				dataKey: "alarm0"
			},
			{
				stat: alarm1,
				name: "Alarm 2",
				dataKey: "alarm1"
			}
		];
	}

	getBlockStyle(dataKey: string, enabled: boolean, index: number) {
		return {
			...this.styles.block,
			borderWidth: index === 0 ? "1px" : "1px 1px 1px 0",
			cursor: enabled ? "pointer" : "not-allowed",
			color: enabled ? black : lightGray,
			background: this.state.selectedStat === dataKey ? offWhite : white
		}
	}

	getIconStyle(index: number, enabled: boolean) {
		return {
			color: enabled ? iconColors[index] : lightGray,
			marginRight: 4
		}
	}

	getViewButtonStyle(chartType: ViewTypes) {
		const { selectedView } = this.state;
		const isSelected = selectedView === chartType;

		return {
			...this.styles.viewButton,
			color: isSelected ? black : lightestGray,
			background: isSelected ? offWhite : white
		}
	}

	setSelectedStat(selectedStat: string) {
		return () => {
			this.setState({ selectedStat });
		}
	}

	setSelectedView(selectedView: ViewTypes) {
		return () => {
			this.setState({ selectedView });
		}
	}

	setupComponent(nextProps?: PlaysAndViewsChartProps) {
		const props = nextProps || this.props;

		this.formatData(props);
		this.formatStats(props);
	}
}

export default connect(mapStateToProps, null)(PlaysAndViewsChart);
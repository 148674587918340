import Player from "@vimeo/player";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, MediaType, VimeoTutorial } from "@connect/Interfaces";
import { Button, Link } from "Components/Global/Common";
import { tryFetchVimeoVideosAsync } from "Data/Actions/SystemAsync";
import { setMediaPreview } from "Data/Actions/UI";
import { getVimeoVideoWithId } from "Data/Selectors/System";

const mapStateToProps = (state, ownProps) => {
	const { uri, vimeoId, type } = state.UI.mediaPreview;
	const id = ownProps.vimeoId || vimeoId;

	return {
		uri: ownProps.uri || uri,
		type: ownProps.type || type,
		vimeoId: id,
		video: getVimeoVideoWithId(state, id)
	}
};

const mapDispatchToProps = (dispatch) => ({
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => dispatch(setMediaPreview(uri, vimeoId, type)),
	fetchVideos: () => dispatch(tryFetchVimeoVideosAsync())
});

interface IMediaPreviewProps {
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => void;
	fetchVideos: () => void;
	type: MediaType;
	uri: string;
	vimeoId: number;
	video: VimeoTutorial;
	size?: "small" | "medium" | "large";
	isWidget?: boolean;
}

export class UnwrappedMediaPreview extends React.PureComponent<IMediaPreviewProps> {
	constructor(props: IMediaPreviewProps) {
		super(props);
		this.styles = {
			border: {
				borderBottom: "1px solid grey"
			},
			buttonBorder: {
				borderColor: "#127EC0"
			},
			center: {
				textAlign: "center"
			},
			left: {
				textAlign: "left"
			},
			media: {
				maxHeight: "80vh",
				maxWidth: "80vw"
			},
			padding: {
				padding: "10px 0"
			},
			tutorial: {
				minWidth: "50vw",
				padding: "10px",
				textAlign: "center"
			},
			tutorialText: {
				margin: "0 auto",
				maxWidth: "48vw"
			}
		}

		this.getSize = this.getSize.bind(this);
		this.setVideoActive = this.setVideoActive.bind(this);
		this.setVideoRef = this.setVideoRef.bind(this);
		this.viewMoreVideos = this.viewMoreVideos.bind(this);
	}

	player: Player

	container: any;

	styles: CustomCSS;

	componentWillMount() {
		const { vimeoId, fetchVideos } = this.props;

		if (vimeoId) {
			fetchVideos();
		}
	}

	render() {
		return (
			<div className="preview" style={ this.styles.center }>
				{ this.renderImage() }
				{ this.renderVideo() }
			</div>
		);
	}

	renderImage() {
		const { type, uri } = this.props;
		if (type !== "image" && type !== "banner") {
			return null;
		}

		return (
			<img
				src={ uri }
				style={ this.styles.media } />
		);
	}

	renderTutorialContainer(video: React.ReactNode, vimeoId: number) {
		const { border, buttonBorder, padding, tutorial, tutorialText } = this.styles;
		const text = vimeoId > 0 ? null : (
			<p style={ tutorialText }>
				Currently, there is no tutorial video for this page.
				Click the button below to be taken to our Support Page, where you can
				search through documentation or reach out to a team member for support.
			</p>
		);
		return (
			<div style={ tutorial }>
				<h2 style={ border }>Need Help?</h2>
				{ video }
				<div style={ padding }><strong>{ text }</strong></div>
				<Button
					color="#127EC0"
					icon="life-ring"
					iconWeight="regular"
					onClick={this.openSupportPage}
					style={ buttonBorder }
					type="primary"
				>Request Support</Button>
			</div>
		);
	}

	renderVideo() {
		const { isWidget, type, uri, vimeoId } = this.props;
		const { border, center, left, media, padding } = this.styles;
		if (type !== "video") {
			return null;
		}

		if (typeof(vimeoId) === "number" && vimeoId > -1) {
			const videoContainer = (<div ref={ this.setVideoRef } />);
			const video = vimeoId !== 0 ? (
				<div style={{ ...center, ...border }}>
					<div style={ padding }>
						<strong>Watch a video about this page.</strong>
					</div>
					{ videoContainer }
					<div style={ padding }>
						<Link onClick={this.viewMoreVideos} to="/admin/video">View More Videos ></Link>
					</div>
				</div>
			) : null;

			if (isWidget) {
				return videoContainer;
			}

			return this.renderTutorialContainer(video, vimeoId);
		}

		return (
			<video controls style={ media }>
				<source src={ uri } />
				<div style={ left }>
					Your browser does not support this video tag. Upgrading to the latest<br />
					version of one of our supported browsers may help to resolve this issue.
					<ul>
						<li><a href="https://www.google.com/chrome/">Google Chrome</a></li>
						<li><a href="https://www.apple.com/safari/">Apple Safari</a></li>
						<li><a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a></li>
					</ul>
				</div>
			</video>
		);
	}

	getSize() {
		const { isWidget, size, vimeoId } = this.props;

		if (vimeoId && !isWidget) {
			return { width: 960, height: 540 };
		}

		switch (size) {
			case "small": return { width: 480, height: 270 };
			case "medium": return { width: 640, height: 360 };
			case "large": return { width: 1280, height: 720 };
			default: return { width: 640, height: 360 };
		}
	}

	openSupportPage() {
		window.open("https://support.clintonelectronics.com", "_blank", "noopener noreferrer");
	}

	setVideoRef(container: HTMLDivElement) {
		this.container = container;
		this.setVideoActive();
	}

	setVideoActive() {
		const size = this.getSize();
		const { vimeoId, video } = this.props;
		if (vimeoId && video && this.container) {
			this.player = new Player(this.container, {
				url: video.link,
				width: size.width,
				height: size.height
			});
		}
	}

	viewMoreVideos() {
		this.props.setMediaPreview("", -1, "" as MediaType);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedMediaPreview);

import Radium from "radium";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

const { lightGray, offWhite, primaryGreen, white } = Colors;

interface ICreateHealthReportProps {
	onCreateReport: () => void;
}

@Radium
export default class CreateHealthReport extends React.Component<ICreateHealthReportProps> {
	constructor(props: ICreateHealthReportProps) {
		super(props);

		this.styles = {
			outer: {
				color: lightGray,
				cursor: "pointer",
				background: offWhite,
				boxShadow: `0px 2px 10px ${ lightGray }`,
				border: `1px solid ${ white }`,
				borderRadius: 6,
				minHeight: 174,
				width: 560,
				margin: 10,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				":hover": {
					color: white,
					background: primaryGreen,
					border: `1px solid ${ primaryGreen }`
				}
			},
			icon: {
				marginBottom: 18
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { outer, icon } = this.styles;
		return (
			<div
				style={ outer }
				onClick={ this.props.onCreateReport }>
				<Icon
					name="plus-circle"
					size="small"
					style={ icon } />
				<p>Create New Health Report</p>
			</div>
		);
	}
}
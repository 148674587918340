import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IUser, RolesList, ICompany } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { Button, Input, Select } from "Components/Global/Common";
import DeactivateUserButton from "Components/Global/DeactivateUserButton";
import HelpPopover from "Components/Global/HelpPopover";
import { resendVerification as resendVerificationAction } from "Data/Actions/UserAsync";
import { deleteUserAsync, updateUserAsync } from "Data/Actions/UsersAsync";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import inputValidation from "Data/Objects/Validation";
import { getEditRolesList } from "Data/Selectors/Roles";
import { getActiveUserUuid } from "Data/Selectors/User";
import { getActiveCompany } from "Data/Selectors/Company";
import { isEqual } from "lodash";
import { cloneDeep } from "lodash";


const { Option } = Select;

const mapDispatchToProps = (dispatch) => ({
	resendVerification: (user) => dispatch(resendVerificationAction(user)),
	updateUser: (user: IUser) => dispatch(updateUserAsync(user)),
	deleteUser: (user: IUser) => dispatch(deleteUserAsync(user))
});

const mapStateToProps = (state) => ({
	activeUuid: getActiveUserUuid(state),
	rolesList: getEditRolesList(state),
	activeCompany: getActiveCompany(state)
});

interface IUserInfoPanelProps {
	activeUuid: string;
	activeCompany: ICompany;
	disabled?: boolean;
	user: IUser;
	rolesList: RolesList;
	updateUser: (user: IUser) => void;
	deleteUser: (user: IUser) => void;
	resendVerification: (user: IUser) => void;
}

class UserInfoPanel extends React.Component<IUserInfoPanelProps> {
	constructor(props: IUserInfoPanelProps) {
		super(props);

		this.styles = {
			center: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			},
			deleteButton: {
				width: 180,
				margin: "0 0 20px"
			},
			fullWidth: {
				width: "100%"
			},
			gridChild: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "0px 18px 0px 18px"
			},
			label: {
				marginLeft: -6,
				wordBreak: "normal"
			},
			notRightPanel: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "0px 18px",
				borderRight: "1px solid #ddd"
			},
			resetButton: {
				width: 180
			},
			rightPanel: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "0px 18px"
			},
			verified: {
				width: "100%",
				border: "1px solid #ddd",
				margin: "0 0 42px 6px"
			},
			wrapper: {
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr"
			}
		};

		this.confirmDeletion = this.confirmDeletion.bind(this);
		this.handleRoleChange = this.handleRoleChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.renderDeactivate = this.renderDeactivate.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.renderRole = this.renderRole.bind(this);
		this.renderSelectRole = this.renderSelectRole.bind(this);
		this.resendVerificationEmail = this.resendVerificationEmail.bind(this);
		this.renderPasswordReset = this.renderPasswordReset.bind(this);
		this.handlePasswordReset = this.handlePasswordReset.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<div style={ this.styles.wrapper }>
				{ this.renderLeftPanel() }
				{ this.renderCenterPanel() }
				{ this.renderRightPanel() }
			</div>
		);
	}

	renderCenterPanel() {
		const { user } = this.props;
		const { uuid, company } = user;
		const nullBack = () => null;

		return (
			<div style={ this.styles.notRightPanel }>
				{ this.renderInput("User ID", "userId", uuid, true, nullBack, true) }
				{ this.renderInput("Company", "userCompany", company.name, true, nullBack, true) }
				{ this.renderSelectRole() }
			</div>
		);
	}

	renderDeactivate() {
		const { disabled, user } = this.props;

		return (
			<DeactivateUserButton
				disabled={ disabled }
				user={ user }
			/>
		);
	}

	renderDelete() {
		const { center, deleteButton } = this.styles;
		const { activeCompany, user, activeUuid } = this.props;

		if (!hasPermission(PERMISSIONS.USERS_ANY_DELETE) || this.props.disabled || user.uuid === activeUuid) {
			return null;
		}


		const disabled = user.role.title == "Integrator" && activeCompany.teamType != "integrator";

		return (
			<div style={ center }>
				<Button
					icon="trash-alt"
					className="warning-button"
					disabled = { disabled }
					onClick={ this.confirmDeletion }
					style={ deleteButton }>
					Delete User
				</Button>
			</div>
		);
	}

	renderPasswordReset() {
		const { center, resetButton } = this.styles;

		if (!hasPermission(PERMISSIONS.USERS_ANY_EDIT)) {
			return null;
		}

		return (
			<div style={ center }>
				<Button
					icon="sign-out-alt"
					style={ resetButton }
					className="warning-button"
					onClick={ this.handlePasswordReset }>
					Reset Password
				</Button>
			</div>
		)
	}

	renderInput(
		label: string,
		id: string,
		value: string,
		disabled?: boolean,
		callback?: (v: string) => void,
		hideLength?: boolean
	) {
		const computedStyle = hideLength ? { marginBottom: 17 } : {};

		const validator = label === "Name" ?  inputValidation.name : undefined;

		return (
			<React.Fragment>
				<span style={ this.styles.label }>{ label }:</span>
				<Input
					key={ value }
					hideMaxLength={ hideLength }
					disabled={ disabled || this.props.disabled }
					saveCallback={ callback }
					id={ id }
					value={ value }
					style={ computedStyle }
					validator={ validator }
				/>
				<br />
			</React.Fragment>
		);
	}

	renderLeftPanel() {
		const { name, email, phone, singleSignOn } = this.props.user;

		return (
			<div style={ this.styles.notRightPanel }>
				{ this.renderInput("Name", "userName", name, !!singleSignOn, this.handleSave("name")) }
				{ this.renderInput("Email (Username)", "userEmail", email, !!singleSignOn, this.handleSave("email")) }
				{ this.renderInput("Phone #", "userPhone", phone, !!singleSignOn, this.handleSave("phone"), true) }
			</div>
		);
	}

	renderRightPanel() {
		return (
			<div style={ this.styles.rightPanel }>
				{ this.renderVerified() }
				{ this.renderDeactivate() }
				{ this.renderDelete() }
				{ this.renderPasswordReset() }
			</div>
		);
	}

	renderRole(role: [string, string]) {
		const [ value, title ] = role;

		return (
			<Option key={ value } value={ value } title={ title } disabled={ this.props.disabled }>
				{ Utils.properCase(title) }
			</Option>
		);
	}

	renderSelectRole() {
		const { label, fullWidth } = this.styles;
		const { user, activeUuid, disabled, rolesList, activeCompany } = this.props;
		const { uuid, role, singleSignOn } = user;
		const roles = Object.entries(rolesList).map(this.renderRole);

		const roleSelectDisabled = disabled || (user.role.title == "Integrator" && activeCompany.teamType != "integrator");

		return (
			<React.Fragment>
				<span style={ label }>
					Role:
					<HelpPopover title="User Role">
						<p>Your role is used to determine your user's capabilities within the Connect application.</p>
					</HelpPopover>
				</span>
				<Select placeholder="Select role..."
					disabled={ !!singleSignOn && uuid === activeUuid || roleSelectDisabled }
					style={ fullWidth }
					defaultValue={ Utils.properCase(role.name) }
					key={ role.name }
					onChange={ this.handleRoleChange }>
					{ roles }
				</Select>
				<br />
			</React.Fragment>
		);
	}

	renderVerified() {
		const { label, verified } = this.styles;
		const { user } = this.props;
		const status = user.verified ? "Verified" : "Pending- ";
		const link = !user.verified ? (
			<a onClick={ this.resendVerificationEmail }>
				Resend Email Invite
			</a>
		) : null;

		return (
			<React.Fragment>
				<span style={ label }>Status:</span>
				<div style={ verified } className="ant-input ant-input-disabled">
					<span>
						{ status }
						{ link }
					</span>
				</div>
			</React.Fragment>
		);
	}

	confirmDeletion() {
		const { user } = this.props;

		Notifications.confirm(
			"Delete Selected Users",
			`Are you sure you want to delete ${user.name}?`,
			"Yes", "No", () => {
				this.props.deleteUser(user)
			});
	}

	handleRoleChange(role: string) {
		const { user } = this.props;
		const newUser = cloneDeep(user);

		const newRole = {
			name: role,
			title: ""
		};

		switch (role) {
			case "owner":
				newRole.title = "Company Owner";
				break;
			case "user":
				newRole.title = "Company User";
				break;
			case "marketer":
				newRole.title = "Marketer";
				break;
		}

		newUser.role = newRole;

		this.props.updateUser(newUser);
	}

	handleSave(field: string) {
		const { user, updateUser } = this.props;
		const record = cloneDeep(user);

		return (value) => {
			record[field] = value;
			if (!isEqual(record, user)) {
				updateUser(record);
			}
		};
	}

	resendVerificationEmail() {
		const { user, resendVerification } = this.props;

		return resendVerification(user);
	}

	handlePasswordReset() {
		const { user, updateUser } = this.props;

		Notifications.confirm(
			"Reset User's Password",
			`Are you sure you want to reset ${user.name}'s password?`,
			"Yes", "No", () => {
				updateUser({ ...user, passwordReset: true })
			}
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoPanel);
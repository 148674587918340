import * as React from "react";
import { connect } from "react-redux";

import { sortDataType, SortTypes } from "@connect/Interfaces";
import { Select } from "Components/Global/Common";
import { setActiveSort } from "Data/Actions/UI";
import { makeGetActiveSort } from "Data/Selectors/UI";

const { Option } = Select;

const mapStateToProps = (state, ownProps) => {
	const getActiveSort = makeGetActiveSort();
	return {
		sortType: getActiveSort(state, ownProps.dataType)
	}
};

const mapDispatchToProps = (dispatch) => ({
	setSort: (dataType: sortDataType, sort: SortTypes) => dispatch(setActiveSort(dataType, sort))
});

export interface ISortDropdownProps {
	dataType: sortDataType;
	sortType: SortTypes;
	setSort: (dataType: sortDataType, sort: SortTypes) => void;
}

class SortDropdown extends React.PureComponent<ISortDropdownProps> {
	constructor(props: ISortDropdownProps) {
		super(props);
	}

	render() {
		const { dataType, sortType, setSort } = this.props;
		return (
			<Select defaultValue={Object.keys(SortTypes)[sortType]}
				style={{ width: 120 }}
				onChange={(by: string) => {
					setSort(dataType, SortTypes[by]);
				}}>
				<Option value={"OLDEST_FIRST"}>Oldest First</Option>
				<Option value={"NEWEST_FIRST"}>Newest First</Option>
				<Option value={"ALPHA"}>A-Z</Option>
				<Option value={"REVERSE_ALPHA"}>Z-A</Option>
			</Select>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SortDropdown)
import { Switch } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { ActionSetActions, CustomCSS } from "@connect/Interfaces";
import { ActionPropertiesPanelContent,
	ActionPropertiesPanelContentProps } from "Components/Actions/ActionPropertiesPanelContent";
import { updateSelectedActionFlashing } from "Data/Actions/ActionsAsync";
import { getSelectedActionFlashing, getSelectedActionType } from "Data/Selectors/ActionBuilder";
import { mapStateToProps as superMapStateToProps } from "Components/Actions/ActionPropertiesPanelContent";


const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		activeActionType: getSelectedActionType(state),
		flashing: getSelectedActionFlashing(state)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateFlashing: (flashing: boolean) => dispatch(updateSelectedActionFlashing(flashing))
	}
};

interface LEDActionPropertiesPanelContentProps extends ActionPropertiesPanelContentProps {
	flashing: boolean;
	updateFlashing: (flashing: boolean) => void;
}

export class LEDActionPropertiesPanelContent
	extends ActionPropertiesPanelContent<LEDActionPropertiesPanelContentProps> {
	constructor(props: LEDActionPropertiesPanelContentProps) {
		super(props);

		const { icon, title } = ActionSetActions.led_front;

		this.icon = icon;
		this.title = title;
		this.styles = {
			...this.styles,
			label: {
				float: "left"
			},
			toggle: {
				float: "right"
			}
		};
	}

	styles: CustomCSS;

	render() {
		return this.renderContainer(this.renderContent());
	}

	renderContent() {
		const { label, toggle } = this.styles;
		const { flashing, updateFlashing } = this.props;

		return (
			<div>
				<div style={ label } >Flashing LED:</div>
				<Switch key={ `${ flashing }` } style={ toggle } defaultChecked={ flashing } onChange={ updateFlashing } />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LEDActionPropertiesPanelContent);
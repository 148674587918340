import { Action, TriggerActionTypeArgs } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ActionBuilderState } from "Data/Objects/AppState";
import { createReducer } from "Data/Utils";

const {
	SET_SELECTED_ACTION_COMPONENT
} = ACTION_TYPES.UI.ActionSetBuilder;

export function setSelectedActionComponent(state: ActionBuilderState, action: Action<TriggerActionTypeArgs>) {
	return {
		selectedTrigger: action.args.triggerType,
		selectedAction: action.args.actionType
	}
}

const reducers = {
	[SET_SELECTED_ACTION_COMPONENT.type]: setSelectedActionComponent
}

export default createReducer(reducers, ActionBuilderState);
import { Action, ActiveSearchDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ActiveStringState } from "Data/Objects/AppState";
import { initialActiveSearchStates } from "Data/Objects/UI";
import { createReducer, setState } from "Data/Utils";

export function setActiveSearch(state: ActiveStringState, action: Action<ActiveSearchDispatch>) {
	const { path, search } = action.args;

	return setState(state, path, search);
}

export function resetActiveSearch(state: ActiveStringState, action: Action<null>) {
	return new ActiveStringState(initialActiveSearchStates);
}

const reducers = {
	[ACTION_TYPES.UI.RESET_ACTIVE_SEARCH.type]: resetActiveSearch,
	[ACTION_TYPES.UI.SET_ACTIVE_SEARCH.type]: setActiveSearch
};

export default createReducer(reducers, ActiveStringState, initialActiveSearchStates);

import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, HealthReport } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { GridViewCard, IconData, IGridViewCardProps } from "Components/Global/GridViewCard";
import { removeReportNotification } from "Data/Actions/UI";
import { getFinishedReports } from "Data/Selectors/UI";
import { cloneDeep } from "lodash";

const { lightGray, white, offWhite, primaryGreen, primaryBlue } = Colors;

interface HealthReportCardProps extends IGridViewCardProps<HealthReport> {
	markReportRead: (uuid: string) => void;
	finishedReports: string[];
}

const mapStateToProps = (state, ownProps) => ({
	finishedReports: getFinishedReports(state)
});

const mapDispatchToProps = (dispatch) => ({
	markReportRead: (uuid: string) =>
		dispatch(removeReportNotification(uuid))
});

class HealthReportCard extends GridViewCard<HealthReportCardProps> {
	constructor(props: HealthReportCardProps) {
		super(props);

		this.styles = {
			outer: {
				position: "relative",
				cursor: "pointer",
				boxShadow: `0px 2px 10px ${ lightGray }`,
				border: `1px solid ${ white }`,
				borderRadius: 6,
				minHeight: 160,
				width: 560,
				margin: 10,
				justifySelf: "center",
				userSelect: "none"
			},
			checkbox: {
				position: "absolute",
				top: 4,
				left: 5
			},
			contentRow: {
				borderRadius: "6px 6px 0px 0px",
				width: "100%",
				height: 140,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				borderBottom: `1px solid ${ white }`,
				backgroundColor: offWhite
			},
			infoRow: {
				height: 30,
				marginTop: 2,
				padding: 10,
				fontSize: 14,
				borderRadius: "6px 6px 0px 0px",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around"
			},
			center: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			name: {
				display: "flex",
				flex: "1 0 70%",
				whiteSpace: "nowrap",
				overflowX: "hidden",
				marginRight: 10
			},
			actions: {
				display: "flex",
				flex: "1 0 auto",
				justifyContent: "flex-end"
			},
			actionIcon: {
				marginLeft: 3
			},
			link: {
				textDecoration: "none",
				color: "inherit"
			},
			section: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				fontSize: "0.9em",
				margin: "0 auto"
			},
			divider: {
				height: 44,
				background: lightGray,
				width: 1
			},
			stat: {
				fontSize: "2.4em"
			},
			ready: {
				position: "absolute",
				top: 6,
				right: 8,
				color: primaryGreen
			}
		}

		this.clickCard = this.clickCard.bind(this);
		this.renderIcon = this.renderIcon.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { cardObject, isSelected, bulkSelectActive } = this.props;

		let outer = {
			...this.styles.outer
		};

		if (bulkSelectActive && isSelected) {
			outer.backgroundColor = "#D7E6FC";
			outer.border = "1px solid #D7E6FC";
		}

		return this.renderContainer(
			<div key={ `${ cardObject.uuid }_${ isSelected }` } style={ outer }>
				{ this.renderReportReady() }
				{ this.renderContentRow() }
				{ this.renderInfoRow() }
			</div>
		);
	}

	renderReportReady() {
		const { finishedReports, cardObject } = this.props;

		if (!finishedReports.includes(cardObject.uuid)) {
			return null;
		}

		return (
			<Icon name="check-circle" size="smaller" style={ this.styles.ready } />
		);
	}

	renderContentRow() {
		const { bulkSelectActive, isSelected, cardObject } = this.props;
		const { devices, deviceGroups, executedAt } = cardObject;
		const { section, stat, divider } = this.styles;
		const lastRun = Utils.getHumanReadableShortDate(executedAt || "");
		let contentRow = cloneDeep(this.styles.contentRow);

		if (bulkSelectActive && isSelected) {
			contentRow.borderBottom = "1px solid #D7E6FC";
			contentRow.backgroundColor = "#D7E6FC";
		}

		return (
			<div style={ contentRow }>
				<div style={ section }>
					<span style={ stat }>
						{ (devices || []).length }
					</span>
					Devices In Report
				</div>
				<div style={ divider } />
				<div style={ section }>
					<span style={ stat }>
						{ (deviceGroups || []).length }
					</span>
					Groups In Report
				</div>
				<div style={ divider } />
				<div style={ section }>
					<span style={ stat }>
						{ lastRun }
					</span>
					Last Report Run
				</div>
			</div>
		);
	}

	renderInfoRow() {
		const { cardObject, bulkSelectActive } = this.props;
		const { infoRow, name, actions } = this.styles;

		const actionStyle = {
			...actions,
			display: bulkSelectActive ? "none" : "flex"
		};

		return (
			<div style={ infoRow }>
				<div style={ name }>
					<b><Truncate length={ 36 }>{ cardObject.name }</Truncate></b>
				</div>
				<div style={ actionStyle }>
					{ (this.props.actionButtons || []).map(this.renderIcon) }
				</div>
			</div>
		);
	}

	renderIcon(iconData: IconData) {
		const { cardObject } = this.props;
		const { name, callback } = iconData;

		let color = {};

		if (name === "globe") {
			color = {
				color: cardObject.global ? primaryBlue : "inherit"
			}
		}

		return (
			<Icon
				name={ name }
				key={ name }
				style={{ ...this.styles.actionIcon, ...color }}
				iconWeight="regular"
				onClick={ this.clickIcon(callback) }
			/>
		);
	}

	clickCard(e: React.MouseEvent<HTMLDivElement>) {
		const { bulkSelectActive, cardObject, markReportRead } = this.props;

		if (!bulkSelectActive) {
			markReportRead(cardObject.uuid);
		}

		super.clickCard(e);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthReportCard);

import * as update from "immutability-helper";
import Radium from "radium";
import * as React from "react";
import { DragSource, DragSourceCollector, DragSourceMonitor, DragSourceSpec } from "react-dnd";
import { connect } from "react-redux";

import { CustomCSS, HealthReport, IReportComponent } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";
import { updateReportAsync } from "Data/Actions/HealthReportAsync";
import { DragTable } from "Data/Objects/DragTypes";
import { getSelectedHealthReport } from "Data/Selectors/HealthReports";
import { getActiveUuid } from "Data/Selectors/UI";

const { black, darkGray, filters, gray, white } = Colors

const mapStateToProps = (state) => {
	const activeUuid = getActiveUuid(state, "health");
	return {
		activeHealthReport: getSelectedHealthReport(state, activeUuid)
	};
};

const mapDispatchToProps = (dispatch) => ({
	updateReport: (report: HealthReport) =>
		dispatch(updateReportAsync(report))
});

const reportItemSource: DragSourceSpec<ReportComponentProps, IReportComponent> = {
	beginDrag(props: ReportComponentProps) {
		return props.item;
	},
	endDrag(props: ReportComponentProps, monitor: DragSourceMonitor, component: React.Component) {
		const dropped = monitor.didDrop();
		const mounted = component !== null;

		if (dropped && mounted) {
			const { key } = props.item;
			const result = monitor.getDropResult() as { key: string, index: number };
			const correctItem = result && result.key === key;
			let columns: any = { $push: [ key ] };

			if (result && result.index !== undefined) {
				columns = { $splice: [ [ result.index, 0, key ] ] };
			}

			if (correctItem) {
				const newReport = update(props.activeHealthReport, {
					columns
				});

				props.updateReport(newReport);
			}
		}
	}
};

const reportItemCollector: DragSourceCollector<ReportComponentCollectedProps> = (dragConnect) => ({
	connectDragSource: dragConnect.dragSource()
});

interface ReportComponentCollectedProps {
	connectDragSource: Function;
}

interface ReportComponentProps {
	activeHealthReport: HealthReport;
	connectDragSource: Function;
	item: IReportComponent;
	last: boolean;
	updateReport: (report: HealthReport) => void;
}

@Radium
export class ReportComponent extends React.Component<ReportComponentProps> {
	constructor(props: ReportComponentProps) {
		super(props);

		this.style = {
			background: black,
			borderBottom: props.last ? 0 : `1px solid ${darkGray}`,
			color: white,
			cursor: "move",
			display: "flex",
			flexGrow: 1,
			height: 35,
			justifyContent: "space-between",
			lineHeight: "35px",
			padding: "0px 36px 0px 28px",
			width: "100%",
			":hover": {
				background: filters.hover
			}
		};
	}

	style: CustomCSS;

	render() {
		const { connectDragSource, item } = this.props;
		const { currentlyUsed, key, title } = item;
		let style = {
			...this.style
		};

		if (currentlyUsed) {
			style.color = gray;
			style.cursor = "not-allowed";
		}

		// we wrap it in an extra div because without it the Radium hover state will conflict with the DnD wrapper
		const panelItemRow = (
			<div>
				<div key={key} style={ style }>
					{title}
				</div>
			</div>
		);

		if (!currentlyUsed) {
			return connectDragSource(panelItemRow);
		}

		return panelItemRow;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(
	DragSource(DragTable.Component, reportItemSource, reportItemCollector)(ReportComponent)
);

import * as update from "immutability-helper";
import { Action, HealthReportDispatch, HealthReportDownloadingDispatch, HealthReportNotificationDispatch,
	HealthReportReportsDispatch, HealthReportResultsDispatch, HealthReportUuidDispatch,
	WithUuid } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { HealthReportState } from "Data/Objects/AppState";
import {
	createReducer,
	deleteFromState,
	getIndexFromState,
	mergeWithUuidArrays,
	pushMergeUpdate,
	pushToState,
	setState,
	updateStateArray,
	updateStateArrayItem
} from "Data/Utils";
import { toggleFeature } from "@connect/Features";
import { getUIColumns } from "Data/Objects/HealthReport";

export function createReport(state: HealthReportState, action: Action<HealthReportDispatch>) {
	return pushToState(state, "reports", action.args.report);
}

export function deleteReport(state: HealthReportState, action: Action<HealthReportUuidDispatch>) {
	const index = getIndexFromState(state.reports, action.args.uuid);

	return deleteFromState(state, "reports", index);
}

export function getAllReports(state: HealthReportState, action: Action<HealthReportReportsDispatch>) {
	if (action.args.reset) {
		return setState(state, "reports", action.args.reports);
	}

	return mergeWithUuidArrays(state, "reports", action.args.reports);
}

export function resetReports(state: HealthReportState, action: Action<null>) {
	return setState(state, "reports", []);
}

export function setReportResult(state: HealthReportState, action: Action<HealthReportResultsDispatch>) {
	const { results } = action.args;
	const index = getIndexFromState(state.results, results.uuid);

	if (results.noResult !== undefined) {
		return updateStateArray(state, "results", index, results);
	}

	return pushMergeUpdate(state, "results", action.args.results, "device.uuid");
}

export function setReportDownloading(state: HealthReportState, action: Action<HealthReportDownloadingDispatch>) {
	const { uuid, value } = action.args;
	const index = state.downloadingReports.indexOf(uuid);

	if (!value && index > -1) {
		return deleteFromState(state, "downloadingReports", index);
	} else if (value && index === -1) {
		return pushToState(state, "downloadingReports", uuid);
	}

	return state;
}

export function updateNotificationSettings(state: HealthReportState, action: Action<HealthReportNotificationDispatch>) {
	const { settings, uuid } = action.args;
	const index = getIndexFromState(state.reports, uuid);
	const updatedReport = Object.assign({}, state.reports[index], { notification: settings });

	return updateStateArray(state, "reports", index, updatedReport);
}

export function updateReport(state: HealthReportState, action: Action<HealthReportDispatch>) {
	const { report } = action.args;

	if (toggleFeature("notifications", true, false)) {
		return updateReport2(state, action);
	}

	const index = getIndexFromState(state.reports, report.uuid || "");

	return updateStateArray(state, "reports", index, report);
}

export function updateReport2(state: HealthReportState, action: Action<HealthReportDispatch>)  {
	let { report } = action.args;

	// Modify report columns to account for differing Server and UI requirements
	const uiColumns = getUIColumns(report.columns || []);

	report = update(report, {
		columns: {
			$set: uiColumns
		}
	})

	return updateStateArrayItem(state, "reports", report as WithUuid);
}

const {
	CREATE_REPORT,
	DELETE_REPORT,
	GET_ALL_REPORTS,
	RESET_REPORTS,
	SET_REPORT_RESULT,
	SET_REPORT_DOWNLOADING,
	UPDATE_HEALTH_REPORTS_NOTIFICATION_SETTINGS,
	UPDATE_REPORT
} = ACTION_TYPES.HealthReports;

const reducers = {
	[CREATE_REPORT.type]: createReport,
	[DELETE_REPORT.type]: deleteReport,
	[GET_ALL_REPORTS.type]: getAllReports,
	[RESET_REPORTS.type]: resetReports,
	[SET_REPORT_RESULT.type]: setReportResult,
	[SET_REPORT_DOWNLOADING.type]: setReportDownloading,
	[UPDATE_HEALTH_REPORTS_NOTIFICATION_SETTINGS.type]: updateNotificationSettings,
	[UPDATE_REPORT.type]: updateReport
};

export default createReducer(reducers, HealthReportState);

import * as update from "immutability-helper";

import { Action, GeneratedMenuDispatch, IMenuItem, UpdateMenuBadgeDispatch  } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { MenuItem } from "Data/Objects/UI";
import { createReducer } from "Data/Utils";

export function setMenuItems(state: IMenuItem[], action: Action<GeneratedMenuDispatch>) {
	return action.args.menu;
}

export function updateMenuBadge(state: IMenuItem[], action: Action<UpdateMenuBadgeDispatch>) {
	const { badge, path } = action.args;
	let newState = [ ...state ];
	let index = newState.findIndex((i) => i.path === path);

	if (index > -1) {
		return update(newState, {
			[index]: {
				$set: MenuItem.setBadgeCount(newState[index], Math.min(badge, 99))
			}
		});
	}

	return newState;
}

const {
	SET_MENU_ITEMS,
	UPDATE_MENU_BADGE
} = ACTION_TYPES.UI;

const reducers = {
	[SET_MENU_ITEMS.type]: setMenuItems,
	[UPDATE_MENU_BADGE.type]: updateMenuBadge
};

export default createReducer(reducers, Array, 0);

import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { logout } from "Data/Actions/UserAsync";
import { User } from "Data/Objects/User";
import { getCurrentUser } from "Data/Selectors/User";

interface LogoutPageProps {
	logoutUser: () => void;
	user: User;
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(logout())
});

const mapStateToProps = (state) => {
	return ({
		user: getCurrentUser(state)
	});
};

export class LogoutPage extends React.Component<LogoutPageProps, any> {
	componentDidMount() {
		const { user, logoutUser } = this.props;

		if (user) {
			logoutUser();
		}
	}

	render() {
		if (!this.props.user) {
			return (
				<Redirect to="/login" />
			);
		}

		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);

import * as React from "react";
import { DragSource, DragSourceSpec } from "react-dnd";
import { connect } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { Card, Icon, Link } from "Components/Global/Common";
import { WIDGET_COLORS } from "Components/Global/Constants";
import MediaPreview from "Components/Global/MediaPreview";
import { WIDGET_TYPES } from "Data/Objects/Widgets";

const { name } = WIDGET_TYPES.WELCOME_VIDEO;

const styles = {
	cardWrapper: {
		position: "relative",
		zIndex: 15
	},
	dragging: {
		opacity: 0.4,
		zIndex: 25
	},
	header: {
		paddingLeft: 8
	},
	link: {
		background: "#FFF",
		bottom: 0,
		left: 0,
		marginTop: 10,
		padding: "0 0 5px",
		position: "absolute",
		textAlign: "center",
		width: "100%"
	}
} as CustomCSS;

interface IWelcomeWidgetDragObject {
	icon: string;
	type: string;
}

interface IWelcomeWidgetProps {
	connectDragPreview: Function;
	connectDragSource: Function;
	isDragging?: boolean;
	onDelete: () => void;
}

const cardSource: DragSourceSpec<IWelcomeWidgetProps, IWelcomeWidgetDragObject> = {
	beginDrag(props: IWelcomeWidgetProps) {
		return {
			icon: "globe",
			type: name
		};
	}
};

export class WelcomeWidget extends React.Component<IWelcomeWidgetProps> {
	constructor(props: IWelcomeWidgetProps) {
		super(props);
	}

	render() {
		const { connectDragSource, isDragging, onDelete } = this.props;
		const style = {
			...styles.cardWrapper,
			...(isDragging ? styles.dragging : null)
		};

		return connectDragSource(
			<div style={style}>
				<Card
					header={ this.renderHeader() }
					height={ 420 }
					onClose={ onDelete }
					color={ WIDGET_COLORS.misc }>
					<MediaPreview
						type="video"
						size="medium"
						vimeoId={ 277655933 }
						isWidget={ true }
					/>
					<div style={ styles.link }>
						<Link to={"/admin/video"}>More Videos</Link>
					</div>
				</Card>
			</div>
		);
	}

	renderHeader() {
		return this.props.connectDragPreview((
			<h2 style={ styles.header }>
				<Icon iconWeight="regular" name="globe" /> { name }
			</h2>
		), {
			captureDraggingState: true
		});
	}
}

export default connect()(DragSource("Widget", cardSource, (dndConnect, monitor) => ({
	connectDragPreview: dndConnect.dragPreview(),
	connectDragSource: dndConnect.dragSource(),
	isDragging: monitor.isDragging()
}))(WelcomeWidget));
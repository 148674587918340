import { IPlaylist, IPlaylistsApi, PlaylistsResult } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";
import { flattenAds } from "Data/Objects/Playlists";

export default class PlaylistsApi extends ApiV2 implements IPlaylistsApi {
	getPlaylists(page?: number, perPage?: number, expandAds?: boolean): Promise<PlaylistsResult> {
		const url = "playlists";
		let attrs: any = {};

		if (perPage) {
			attrs.perPage = perPage;
		}
		if (page) {
			attrs.page = page;
		}
		if (expandAds) {
			attrs.expand = "ads";
		}

		return this.GET(url, attrs) as Promise<PlaylistsResult>;
	}

	getPlaylist(uuid: string) {
		return this.GET(`playlists/${uuid}`, { expand: "ads" });
	}

	createPlaylist(playlist: IPlaylist) {
		// convert the keyed ads to just UUIDs for the api
		const newPlaylist = flattenAds(playlist);
		return this.POST("playlists", newPlaylist);
	}

	deletePlaylist(uuid: string) {
		return this.DELETE(`playlists/${uuid}`);
	}

	updatePlaylist(playlist: IPlaylist) {
		const newPlaylist = flattenAds(playlist);
		return this.PUT(`playlists/${playlist.uuid}`, newPlaylist);
	}
}

import { ColumnProps } from "antd/lib/table/interface";
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, ICompany, IUser, OptionalTableProps } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import UserInfoPanel from "Components/Admin/UserInfoPanel";
import { Table } from "Components/Global/Common";
import HelpPopover from "Components/Global/HelpPopover";
import Status from "Components/Global/Status";
import { setActiveSearch, setActiveSelection } from "Data/Actions/UI";
import { getUsersAsync } from "Data/Actions/UsersAsync";
import { hasPermission } from "Data/Objects/Permissions";
import { getUsersList } from "Data/Selectors/Admin";
import { getCompanies } from "Data/Selectors/Company";
import { getActiveSearch, getActiveSelection, makeHaveAllUsers, makeLastUsersPage } from "Data/Selectors/UI";
import { deepSearch } from "Data/Utils";
import { getCurrentUser } from "Data/Selectors/User";

const mapStateToProps = (state) => {
	const lastFetchedPage = makeLastUsersPage();
	const haveAllUsers = makeHaveAllUsers();
	return {
		companies: getCompanies(state),
		users: getUsersList(state),
		selectedUsers: getActiveSelection(state, "users"),
		haveAllUsers: haveAllUsers(state),
		lastFetchedPage: lastFetchedPage(state),
		filterText: getActiveSearch(state, "users"),
		user: getCurrentUser(state)
	}
};

const mapDispatchToProps = (dispatch) => ({
	getUsers: () => dispatch(getUsersAsync()),
	selectUsers: (userUuids: string[]) => dispatch(setActiveSelection("users", userUuids)),
	setSearch: (query: string) => dispatch(setActiveSearch("users", query))
});

interface AdminUsersTableProps {
	companies: ICompany[];
	filterText: string;
	getUsers: () => void;
	handleUserSelection?: (uuids: string[]) => void;
	haveAllUsers: boolean;
	lastFetchedPage: number;
	selectedUsers: string[];
	selectModeOn?: boolean;
	selectUsers: (userUuids: string[]) => void;
	setSearch: (query: string) => void;
	users: IUser[];
	user: IUser;
}

class AdminUsersTable extends React.Component<AdminUsersTableProps> {
	constructor(props: AdminUsersTableProps) {
		super(props);

		this.styles = {
			header: {
				padding: "0 30px"
			},
			link: {
				textDecoration: "none"
			}
		}

		this.handlePaging = this.handlePaging.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.renderAccountStatusColumn = this.renderAccountStatusColumn.bind(this);
		this.renderEmailColumn = this.renderEmailColumn.bind(this);
		this.renderLastLoginColumn = this.renderLastLoginColumn.bind(this);
		this.renderRoleHeader = this.renderRoleHeader.bind(this);
		this.renderRow = this.renderRow.bind(this);
	}

	styles: CustomCSS;
	columns: ColumnProps<IUser>[];

	render() {
		const { selectedUsers, selectModeOn, lastFetchedPage } = this.props;
		const props: OptionalTableProps = {};

		if (selectModeOn) {
			props.rowSelection = {
				selectedRowKeys: selectedUsers,
				onChange: this.handleSelectChange
			};
		}

		return (
			<React.Fragment>
				<Table
					{ ...props }
					className="admin-table"
					size="middle"
					rowKey={ this.getRowKey }
					columns={ this.getColumns() }
					expandRowByClick
					expandedRowRender={ this.renderRow }
					dataSource={ this.getDataSource() }
					locale={{ emptyText: "No Users" }}
					pagination={ false }
				/>
				<Waypoint
					key={ String(lastFetchedPage) }
					onEnter={ this.handlePaging }
				/>
			</React.Fragment>
		);
	}

	renderRow(record: IUser) {
		const { uuid, active } = record;
		const { user } = this.props;

		const missingPermission = !hasPermission(`users.${ record.role.name }.edit`);
		const activeUserSSO = !!user.singleSignOn;
		const targetUserSSO = !!record.singleSignOn;

		const disabled = missingPermission || activeUserSSO || targetUserSSO;

		return (
			<UserInfoPanel
				key={ `${ uuid }-${ String(active) }` }
				user={ record }
				disabled={ disabled }
			/>
		);
	}

	handlePaging() {
		const { haveAllUsers, getUsers } = this.props;

		if (!haveAllUsers) {
			getUsers();
		}
	}

	handleSelectChange(selectedRowKeys: string[]) {
		this.props.selectUsers(selectedRowKeys);
	}

	getRowKey(record: IUser) {
		return record.uuid || "";
	}

	getColumns() {
		return [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				sorter: Utils.columnSorter("name"),
				defaultSortOrder: "descend"
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email",
				sorter: Utils.columnSorter("email"),
				render: this.renderEmailColumn
			},
			{
				title: this.renderRoleHeader(),
				dataIndex: "role.title",
				key: "role.title",
				sorter: Utils.columnSorter("role.title")
			},
			{
				title: "Phone #",
				dataIndex: "phone",
				key: "phone",
				sorter: Utils.columnSorter("phone")
			},
			{
				title: "Account Status",
				dataIndex: "active",
				key: "active",
				width: 150,
				sorter: Utils.columnSorter("active"),
				render: this.renderAccountStatusColumn
			},
			{
				title: "Last Login",
				dataIndex: "lastLogin",
				key: "lastLogin",
				sorter: Utils.dateColumnSorter("lastLogin"),
				render: this.renderLastLoginColumn
			}
		] as ColumnProps<IUser>[];
	}

	renderAccountStatusColumn(text: string, user: IUser) {
		const { active, verified } = user;
		let status = "Deactivated";
		let color = "red";
		if (active && verified) {
			status = "Verified";
			color = "green"
		} else if (active && !verified) {
			status = "Pending";
			color = "gray";
		}
		return (
			<span>
				{ status }
				<Status color={ color } />
			</span>
		);
	}

	renderEmailColumn(text: string) {
		return (
			<a style={ this.styles.link } href={ `mailto:${ text }` }>
				{ text }
			</a>
		);
	}

	renderLastLoginColumn(text: string, user: IUser) {
		return user.lastLogin && Utils.getHumanReadableDate(user.lastLogin);
	}

	renderRoleHeader() {
		return (
			<React.Fragment>
				Role
				<HelpPopover title="User Role">
					<p>Your role is used to determine your user's capabilities within the Connect application.</p>
				</HelpPopover>
			</React.Fragment>
		);
	}

	getDataSource() {
		const { users, filterText } = this.props;
		const keys = [ "name", "email", "role.title", "phone", "company.name", "lastLogin" ];

		return deepSearch<IUser>(users, filterText, keys);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersTable);
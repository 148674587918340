import * as update from "immutability-helper";
import Radium from "radium";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IFeedComponent, IMedia } from "@connect/Interfaces";
import { AdColors, SecurityBanners } from "Components/Ads/Constants";
import {
	AdComponentCard,
	IAdComponentCardProps,
	IAdComponentCardState,
	SuperComponentCard,
	SuperComponentClass
} from "Components/Ads/SuperComponentCard";
import { getBanners } from "Data/Selectors/Media";

const {
	Draggable,
	mapStateToProps,
	mapDispatchToProps
} = SuperComponentCard;

const mapStateToFeedProps = (state, ownProps) => {
	const superState = mapStateToProps(state, ownProps);

	return Object.assign({}, superState, {
		bannerMedia: getBanners(state)
	});
};

interface FeedComponentCardProps extends IAdComponentCardProps<IFeedComponent> {
	bannerMedia?: IMedia[];
}

interface FeedComponentCardState extends IAdComponentCardState {
	feedStyle: CustomCSS;
	selectedBanner: IMedia;
}

@Radium
export class FeedComponentCard extends AdComponentCard<FeedComponentCardProps, FeedComponentCardState> {
	constructor(props: FeedComponentCardProps) {
		super(props);

		this.icon = "video";
		this.name = "Security Feed";

		this.state = this.getInitialState(props);

		this.styles = update(this.styles, {
			card: {
				backgroundColor: { $set: AdColors.feed.background },
				backgroundImage: { $set: "" }
			},
			feed: {
				$set: {
					position: "absolute",
					width: "100%",
					backgroundColor: "transparent",
					backgroundImage: "url(" + require("../../Images/checkerboard.png") + ")",
					backgroundSize: "50px",
					zIndex: 100
				}
			}
		});

		this.renderContainer = this.renderContainer.bind(this);
	}

	icon: string;
	name: string;
	styles: {
		baseButton: CustomCSS;
		bottomResizeHandle: CustomCSS;
		card: CustomCSS;
		container: CustomCSS;
		deleteButton: CustomCSS;
		eraseButton: CustomCSS;
		feed: CustomCSS;
		heightAdjuster: CustomCSS;
		livePreview: CustomCSS;
		livePreviewName: CustomCSS;
		promptIcon: CustomCSS;
		promptText: CustomCSS;
		resolutionOverlay: CustomCSS;
		snapButton: CustomCSS;
		upload: CustomCSS;
		video: CustomCSS;
	};

	static getDerivedStateFromProps(props: FeedComponentCardProps, state: FeedComponentCardState) {
		const superDerivedState = AdComponentCard.getDerivedStateFromProps(props, state);
		const newState = Object.assign({}, state, superDerivedState);
		const { bannerMedia, component } = props;
		const { id, position } = component && component.banner || { id: null, position: null };

		return update(newState, {
			feedStyle: {
				bottom: { $set: position === "bottom" ? 0 : null },
				top: { $set: position === "top" ? 0 : null }
			},
			selectedBanner: {
				$set: bannerMedia && bannerMedia.filter(({ uuid }) => uuid === id)[0]
			}
		});
	}

	render() {
		const { banner } = this.props.component;
		const { livePreview } = this.props;
		const { selectedBanner } = this.state;
		const { flashing, id, position } = banner || { flashing: null, id: null, position: null };
		const bannerById = SecurityBanners[id || 0];
		const feedClassName =  flashing ? "banner-image-preview flashing" : "banner-image-preview" ;
		let feedBannerSrc = "";
		if (!livePreview) {
			feedBannerSrc = "";
		} else if (banner && bannerById) {
			feedBannerSrc = bannerById.uri;
		} else if (selectedBanner) {
			feedBannerSrc = selectedBanner.uri;
		}
		const bottom = position === "bottom" ? 0 : null;
		const top = position === "top" ? 0 : null;
		const style = Object.assign({}, this.styles.feed, this.state.feedStyle, {
			bottom, top
		});

		return this.renderContainer(
			<img
				draggable={ false }
				className={ feedClassName }
				src={ feedBannerSrc }
				style={ style } />
		);
	}

	getInitialState(props: FeedComponentCardProps): FeedComponentCardState {
		const state = super.getInitialState(props);

		return Object.assign({}, state, {
			feedStyle: {
				bottom: null,
				top: null
			},
			selectedBanner: null
		});
	}

	shouldShowLivePreview() {
		const { livePreview, component } = this.props;
		const { banner } = component;
		return !!(livePreview && banner);
	}
}

export const DndFeedComponentCard: SuperComponentClass<FeedComponentCardProps> = Draggable(
	connect(mapStateToFeedProps, mapDispatchToProps)(FeedComponentCard)
);

export default DndFeedComponentCard;

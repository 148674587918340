import * as update from "immutability-helper";
import * as moment from "moment";
import { match as matchType, matchPath } from "react-router";
import { LOCATION_CHANGE, LocationChangeAction } from "react-router-redux";

import { store } from "@connect/Data";
import { Action, DeleteScheduleItemDispatch, DeploymentDispatch, DeploymentScheduleDispatch, DeploymentType,
	DeploySteps, MoveScheduleItemDispatch, ResizeScheduleItemDispatch } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DeploymentWizardState } from "Data/Objects/AppState";
import { PageUuidType } from "Data/Reducers/UI/activeUuids";
import { getDeploymentById } from "Data/Selectors/Deployments";
import { createReducer } from "Data/Utils";
import { cloneDeep } from "lodash";

const {
	SET_DEPLOYMENT_DETAILS
} = ACTION_TYPES.Deployments;

const {
	DELETE_DEPLOYMENT_SCHEDULE_ITEM,
	MOVE_DEPLOYMENT_SCHEDULE_ITEM,
	RESIZE_DEPLOYMENT_SCHEDULE_ITEM,
	SET_ACTIVE_DEPLOYMENT,
	SET_ACTIVE_DEPLOYMENT_SCHEDULE,
	SET_DEPLOYMENT_SCHEDULE_DATES,
	SET_DEPLOYMENT_SCHEDULED,
	SET_DEPLOYMENT_STEP
} = ACTION_TYPES.UI.DeploymentWizard;

export function handleLocationChange(state: DeploymentWizardState, action: LocationChangeAction) {
	const { pathname } = action.payload;
	const match: matchType<PageUuidType> | null = matchPath(pathname, {
		path: "/deploy/:uuid?"
	});

	if (match && match.params && match.params.uuid) {
		const { params: { uuid } } = match;
		const targetDeployment = getDeploymentById(store.getState(), uuid);

		return update(state, {
			activeDeployment: {
				$set: targetDeployment
			},
			step: {
				$set: DeploySteps.SCHEDULE
			}
		});
	}

	return update(state, {
		activeDeployment: {
			$set: null
		},
		step: {
			$set: null
		}
	});
}

export function deleteActiveDeploymentScheduleItem(state: DeploymentWizardState,
	action: Action<DeleteScheduleItemDispatch>) {
	const { schedule } = state.activeDeployment;
	const index = schedule.indexOf(action.args.item);
	const newSchedule = update(schedule, { $splice: [ [ index, 1 ] ] });

	// we always want to do this after we update our items
	return setActiveDeploymentSchedule(state, {
		type: SET_ACTIVE_DEPLOYMENT_SCHEDULE.type,
		args: { schedule: newSchedule, itemMoved: false }
	});
}

export function moveActiveDeploymentScheduleItem(state: DeploymentWizardState,
	action: Action<MoveScheduleItemDispatch>) {
	const { schedule } = state.activeDeployment;
	const { dragIndex, hoverIndex } = action.args;

	// get the original start time, pre-drag
	const hoverTime = schedule[hoverIndex].startTime;
	const dragTime = schedule[dragIndex].startTime;

	// get dragAd and hoverAd to work with
	const dragAd = { ...schedule[dragIndex], startTime: hoverTime };
	const hoverAd = { ...schedule[hoverIndex], startTime: dragTime };

	// remove the item at hoverIndex and insert the dragged item followed by the hovered item
	let $splice = [ [ hoverIndex, 1, dragAd, hoverAd ] ];
	const removeDraggedItem = [ dragIndex, 1 ];

	if (dragIndex > hoverIndex) {
		// if dragging up remove dragged item first because the dragged item index is higher
		$splice.unshift(removeDraggedItem)
	} else {
		// if dragging down remove dragged item last
		$splice.push(removeDraggedItem)
	}

	// swap the items
	const newSchedule = update(schedule, { $splice });

	// we always want to do this after we update our items
	return setActiveDeploymentSchedule(state, {
		type: SET_ACTIVE_DEPLOYMENT_SCHEDULE.type,
		args: { schedule: newSchedule, itemMoved: true }
	});
}

export function resizeActiveDeploymentScheduleItem(state: DeploymentWizardState,
	action: Action<ResizeScheduleItemDispatch>) {
	const { schedule } = state.activeDeployment;
	const { containerHeight, item, height } = action.args;
	const content = schedule.slice();
	const index = content.indexOf(item);
	const nextItem = content[index + 1];
	const newDuration = Math.round(Utils.getDurationFromHeight(height, containerHeight));
	const diff = newDuration - item.duration;
	const newStartTime = item.startTime + newDuration;

	const updatedItem = update(item, {
		duration: { $set: newDuration }
	});

	const updatedNextItem = update(nextItem, {
		duration: { $set: nextItem.duration - diff },
		startTime: { $set: newStartTime }
	});

	const newSchedule = update(content, {
		[index]: { $set: updatedItem },
		[index + 1]: { $set: updatedNextItem }

	});

	// we always want to do this after we update our items
	return setActiveDeploymentSchedule(state, {
		type: SET_ACTIVE_DEPLOYMENT_SCHEDULE.type,
		args: { schedule: newSchedule, itemMoved: false }
	});
}

export function setActiveDeployment(state: DeploymentWizardState, action: Action<DeploymentDispatch>) {
	return update(state, {
		activeDeployment: {
			$set: action.args.deployment
		}
	});
}

export function updateActiveDeployment(state: DeploymentWizardState, action: Action<DeploymentDispatch>) {
	const { activeDeployment } = state;
	const { deployment: updatedDeployment } = action.args;

	if (!activeDeployment || !updatedDeployment) {
		return state;
	}

	const { uuid: activeUuid } = activeDeployment;
	const { uuid: updatedUuid } = updatedDeployment;

	if (activeUuid === updatedUuid) {
		return update(state, {
			activeDeployment: {
				$merge: updatedDeployment
			}
		});
	}

	return state;
}

export function setActiveDeploymentSchedule(state: DeploymentWizardState, action: Action<DeploymentScheduleDispatch>) {
	const { schedule, itemMoved } = action.args;
	const newSchedule = cloneDeep(schedule)
		.sort((a, b) => a.startTime - b.startTime);

	newSchedule.forEach((c, i) => {
		const prev = newSchedule[i - 1];
		const next = newSchedule[i + 1];

		if (!prev) {
			c.startTime = 0;
		}

		// if there's one before this, set the startTime of the
		// current one to the end time of the previous one
		if (prev) {
			c.startTime = prev.startTime + prev.duration;
		}

		// if there's another one after this, base the duration of this one on the next one
		// otherwise, make it run until the end of the day
		if (next && !itemMoved) {
			c.duration = next.startTime - c.startTime;
		}

		if (!next) {
			c.duration = Utils.dayMinutes - c.startTime;
		}

		// if this is the only one, duration is all day
		if (newSchedule.length === 1 && !itemMoved) {
			c.startTime = 0;
			c.duration = Utils.dayMinutes;
		}
	});

	return update(state, {
		activeDeployment: {
			schedule: {
				$set: newSchedule
			}
		}
	});
}

export function setDeploymentScheduleDates(state: DeploymentWizardState, action: Action<any>) {
	return update(state, {
		scheduleDates: {
			$set: action.args.scheduleDates
		}
	});
}

export function setDeploymentScheduled(state: DeploymentWizardState, action: Action<any>) {
	return update(state, {
		scheduled: {
			$set: action.args.scheduled
		}
	});
}

export function setDeploymentStep(state: DeploymentWizardState, action: Action<any>) {
	const { step } = action.args;
	const date = moment().format("YYYY-MM-DDTHH:mm:ss");

	return update(state, {
		scheduleDates: {
			// if we are entering the confirm step, set some default scheduleDates for us to make use of
			$set: step === DeploySteps.CONFIRM
				? [ date, state.activeDeployment.type === DeploymentType.SCHEDULE ? null : date ]
				: [ null, null ]
		},
		step: {
			$set: step
		}
	});
}

const reducers = {
	[SET_DEPLOYMENT_DETAILS.type]: updateActiveDeployment,
	[LOCATION_CHANGE]: handleLocationChange,
	[DELETE_DEPLOYMENT_SCHEDULE_ITEM.type]: deleteActiveDeploymentScheduleItem,
	[MOVE_DEPLOYMENT_SCHEDULE_ITEM.type]: moveActiveDeploymentScheduleItem,
	[RESIZE_DEPLOYMENT_SCHEDULE_ITEM.type]: resizeActiveDeploymentScheduleItem,
	[SET_ACTIVE_DEPLOYMENT.type]: setActiveDeployment,
	[SET_ACTIVE_DEPLOYMENT_SCHEDULE.type]: setActiveDeploymentSchedule,
	[SET_DEPLOYMENT_SCHEDULE_DATES.type]: setDeploymentScheduleDates,
	[SET_DEPLOYMENT_SCHEDULED.type]: setDeploymentScheduled,
	[SET_DEPLOYMENT_STEP.type]: setDeploymentStep
};

export default createReducer(reducers, DeploymentWizardState)
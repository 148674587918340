import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IDevice, IStore, Filters } from "@connect/Interfaces";
import { ISuperAnalyticsChartProps, ISuperAnalyticsChartState, mapStateToProps as superMapStateToProps,
	SuperAnalyticsChart } from "Components/Analytics/SuperAnalyticsChartComponent";
import { Icon, Link, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { getStoreAsync } from "Data/Actions/Company";
import { fetchUpdatedDeviceAsync } from "Data/Actions/Devices";
import { setActiveFilterArray } from "Data/Actions/UI";
import { PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveAnalyticsPeakTraffic, getActiveAnalyticsTopDevice,
	getActiveAnalyticsTopDeviceStore, getActiveAnalyticsTopStore, getTopDeviceStoreUuid,
	getTopDeviceUuid, getTopStoreUuid } from "Data/Selectors/AdAnalytics";

const { OWN_DEVICES_VIEW } = PERMISSIONS;

const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		device: getActiveAnalyticsTopDevice(state),
		deviceStore: getActiveAnalyticsTopDeviceStore(state),
		peakTrafficTime: getActiveAnalyticsPeakTraffic(state),
		store: getActiveAnalyticsTopStore(state),
		topDeviceUuid: getTopDeviceUuid(state),
		topStoreUuid: getTopStoreUuid(state),
		topDeviceStoreUuid: getTopDeviceStoreUuid(state)
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchDevice: (uuid: string) => dispatch(fetchUpdatedDeviceAsync(uuid)),
	fetchStore: (uuid: string) => dispatch(getStoreAsync(uuid)),
	setStoreFilter: (store: IStore) => dispatch(setActiveFilterArray("stores", Filters.DEVICES, [ store.uuid ]))
});

interface TopStatsChartProps extends ISuperAnalyticsChartProps {
	device: IDevice;
	deviceStore: IStore;
	fetchDevice: (uuid: string) => void;
	fetchStore: (uuid: string) => void;
	peakTrafficTime: string;
	setStoreFilter: (store: IStore) => void;
	store: IStore;
	topDeviceUuid: string;
	topStoreUuid: string;
	topDeviceStoreUuid: string;
}

export class TopStatsChart extends SuperAnalyticsChart<TopStatsChartProps, ISuperAnalyticsChartState> {
	constructor(props: TopStatsChartProps) {
		super(props);

		this.styles = Object.assign({}, this.styles, {
			bigFont: {
				fontSize: "1.5em",
				fontWeight: 700
			},
			container: {
				display: "flex",
				width: "100%",
				marginBottom: 20
			},
			mediumFont: {
				fontSize: "1.25em",
				fontWeight: 700
			},
			rightBordered: {
				borderRight: `1px solid ${ Colors.lightestGray }`
			},
			smallFont: {
				fontSize: "1.0em",
				fontWeight: 700
			},
			section: {
				flex: 1,
				marginLeft: 5,
				marginRight: 5,
				textAlign: "center"
			},
			topStatsIcon: {
				position: "absolute",
				right: "115%",
				top: "50%",
				transform: "translateY(-18px)",
				color: "rgb(164, 164, 167)"
			},
			topStatsWrapper: {
				position: "relative",
				display: "inline-block",
				textAlign: "left"
			}
		});

		this.getFormattedTime = this.getFormattedTime.bind(this);
		this.handleSetStoreFilter = this.handleSetStoreFilter.bind(this);
		this.renderMostViewedDevice = this.renderMostViewedDevice.bind(this);
		this.renderMostViewsByStore = this.renderMostViewsByStore.bind(this);
	}

	styles: CustomCSS;

	componentDidUpdate() {
		const { device, deviceStore, fetchDevice, fetchStore, store, topDeviceUuid,
			topStoreUuid, topDeviceStoreUuid } = this.props;
		// get our top device details
		if (topDeviceUuid && !device) {
			fetchDevice(topDeviceUuid);
		}
		// get our top store details
		if (topStoreUuid && !store) {
			fetchStore(topStoreUuid);
		}
		// get our top device store details
		if (topDeviceStoreUuid && !deviceStore) {
			fetchStore(topDeviceStoreUuid);
		}
	}

	render() {
		const { container, section, bigFont, mediumFont, topStatsIcon, topStatsWrapper } = this.styles;
		const { data } = this.props;

		if (!data.length) {
			return this.renderContainer(<div />);
		}

		return this.renderContainer(
			<div style={ container }>
				{ this.renderMostViewsByStore() }
				{ this.renderMostViewedDevice() }
				<div style={ section }>
					<div style={ topStatsWrapper }>
						<Icon
							style={ topStatsIcon }
							name="clock"
							size="big"
						/>
						<div style={ mediumFont }>Peak Traffic Time</div>
						<div style={ bigFont }>{ this.getFormattedTime() }</div>
					</div>
				</div>
			</div>
		);
	}

	renderMostViewedDevice() {
		const { section, mediumFont, smallFont, rightBordered, topStatsIcon, topStatsWrapper } = this.styles;
		const { device, deviceStore } = this.props;

		let deviceLink;
		let storeLink;

		if (device && device.uuid) {
			const { name, store, uuid } = device;
			storeLink = store && deviceStore ? (
				<Link
					to={ "/devices" }
					onClick={ this.handleSetStoreFilter(deviceStore) }
					permissions={[ OWN_DEVICES_VIEW ]}
				>
					{ deviceStore.name }
				</Link>
			) : <span>No assigned store.</span>;

			deviceLink = (
				<Link
					to={ `/devices/${ uuid }` }
					permissions={[ OWN_DEVICES_VIEW ]}
				>
					{ name }
				</Link>
			);
		}

		return (
			<div style={{ ...section, ...rightBordered }} key={ device && device.uuid }>
				<div style={ topStatsWrapper }>
					<Icon
						style={ topStatsIcon }
						name="mobile"
						size="big"
					/>
					<div style={ mediumFont }>Most Viewed Device</div>
					<div style={ smallFont }>Name: { deviceLink }</div>
					<div style={ smallFont }>Store: { storeLink }</div>
				</div>
			</div>
		);
	}

	renderMostViewsByStore() {
		const { section, mediumFont, smallFont, rightBordered, topStatsIcon, topStatsWrapper } = this.styles;
		const { store } = this.props;

		let storeString = "N/A";
		let locationString = "";
		let storeLink = <React.Fragment>N/A</React.Fragment>;

		if (store) {
			const { city, state, name } = store;
			const location = city && state ? [ city, state ].filter(Boolean) : "N/A";
			storeString = name || "N/A";
			locationString = typeof location === "string" ? location : location.join(", ");
			storeLink = (
				<Link
					to={ "/devices" }
					permissions={[ OWN_DEVICES_VIEW ]}
					onClick={ this.handleSetStoreFilter(store) }
				>
					<Truncate length={ 40 }>{ storeString }</Truncate>
				</Link>
			);
		}

		return (
			<div style={{ ...section, ...rightBordered }} key={ store && store.uuid }>
				<div style={ topStatsWrapper }>
					<Icon
						style={ topStatsIcon }
						name="store"
						size="big"
					/>
					<div style={ mediumFont }>Most Views by Store</div>
					<div style={ smallFont }>Store: { storeLink }</div>
					<div style={ smallFont }><Truncate length={ 40 }>{ locationString }</Truncate></div>
				</div>
			</div>
		);
	}

	getFormattedTime() {
		const time = this.props.peakTrafficTime;

		if (!time) {
			return null;
		}

		// our peakTrafficTime comes in 24hr format and we need AM/PM here
		return moment(time, "HH:mm").format("hh:mm A");
	}

	handleSetStoreFilter(store: IStore) {
		return () => {
			const { setStoreFilter } = this.props;
			setStoreFilter(store);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopStatsChart);
import { CloudProvider, CompaniesResult, CompanyUpdate, ErrorResultV2,
	ExtendedCompaniesResult, ICompany, ICompanyApi } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

const getCompanyUrl = (uuid: string) => `companies/${ uuid }`;

export default class CompanyApi extends ApiV2 implements ICompanyApi {
	createCompany(name: string, cloudServiceProvider: CloudProvider, integrator?: boolean,
		customPermissions?: boolean) {
		return this.POST("companies", {
			name,
			cloudServiceProvider,
			customPermissions,
			integrator
		}, true) as Promise<ICompany>;
	}

	getCompanies(page?: number) {
		return this.GET("companies", {
			page
		}, true) as Promise<CompaniesResult>;
	}

	getCompany(company: ICompany, withPermissions?: boolean) {
		return this.GET(getCompanyUrl(company.uuid), {
			append: withPermissions ? "permissions" : null
		}, true) as Promise<ICompany>;
	}

	getManagedCompanies(page?: number) {
		return this.GET("companies/managed", {
			page
		}, true) as Promise<ExtendedCompaniesResult>;
	}

	getManagedCompany(companyId: string) {
		return this.GET(`companies/managed?filter[uuid]=${ companyId }`, undefined, true) as Promise<ExtendedCompaniesResult>;
	}

	// update contains one or more of: name, active, integrator,
	// passwordRequirements, or customPermissions, or passwordReset
	updateCompany(company: ICompany, update: CompanyUpdate) {
		return this.PUT(getCompanyUrl(company.uuid), update) as Promise<ErrorResultV2>;
	}
}

import { DragConstants, DragTableType, SortableListType } from "@connect/Interfaces";

export const DragTable: DragConstants<DragTableType> = {
	Component: "REPORT_COMPONENT",
	Column: "REPORT_COLUMN"
};

export const SortableList: DragConstants<SortableListType> = {
	ListItem: "DND_SORTABLE"
};

export default {
	DragTable,
	SortableList
};

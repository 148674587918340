import * as React from "react";
import { connect } from "react-redux";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import { CustomCSS } from "@connect/Interfaces";
import { ISuperAnalyticsChartProps, mapStateToProps as superMapStateToProps,
	SuperAnalyticsChart } from "Components/Analytics/SuperAnalyticsChartComponent";
import { Colors } from "Components/Global/Constants";
import Icon from "Components/Global/Icon";

const { primaryBlue, primaryGreen, red, yellow, lightestGray, black, white } = Colors;

const chartColors = [ primaryBlue, primaryGreen, yellow, red ];

interface ViewsStat {
	stat: number;
	views: number;
	name: string;
}

interface Payload {
	payload: ViewsStat
}

interface CustomTooltipProps {
	payload: Payload[];
}

interface ViewsByDeviceChartProps extends ISuperAnalyticsChartProps {
}

interface ViewsByDeviceChartState {
	hoveredStat: string;
}

export class ViewsByDeviceChart extends SuperAnalyticsChart<ViewsByDeviceChartProps, ViewsByDeviceChartState> {
	constructor(props: ViewsByDeviceChartProps) {
		super(props);

		this.state = {
			hoveredStat: ""
		}

		this.styles = Object.assign({}, this.styles, {
			tooltip: {
				padding: 10,
				border: `1px solid ${ lightestGray }`,
				background: white
			}
		});

		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.renderCells = this.renderCells.bind(this);
		this.renderCustomTooltip = this.renderCustomTooltip.bind(this);
		this.renderStats = this.renderStats.bind(this);
	}

	stats: ViewsStat[];
	styles: CustomCSS;

	componentWillMount() {
		this.setupComponent()
	}

	componentWillUpdate(nextProps: ViewsByDeviceChartProps) {
		this.setupComponent(nextProps);
	}

	render() {
		const { data, loading } = this.props;

		if (!data.length) {
			return this.renderContainer(<div />);
		}

		const { chartContainer, main, statsContainer } = this.styles;
		const cells = this.stats.map(this.renderCells);
		const stats = this.stats.map(this.renderStats);
		const chart = loading ? <div /> : (
			<PieChart key={ data.length }>
				<Pie
					onMouseEnter={ this.handleMouseEnter }
					onMouseLeave={ this.handleMouseLeave }
					dataKey="stat"
					data={ this.stats }
				>
					{ cells }
				</Pie>
				<Tooltip content={ this.renderCustomTooltip }/>
			</PieChart>
		);

		return this.renderContainer(
			<div style={ main } key={ data.length }>
				<div style={ chartContainer } key={ data.length }>
					<ResponsiveContainer width="90%" height="90%">
						{ chart }
					</ResponsiveContainer>
				</div>
				<div style={ statsContainer }>
					{ stats }
				</div>
			</div>
		);
	}

	renderCells(item: ViewsStat, index: number) {
		return (
			<Cell
				fill={ chartColors[index % chartColors.length ]}
				key={ index }
			/>
		);
	}

	renderCustomTooltip(props: CustomTooltipProps) {
		const { payload } = props;

		if (!payload.length) {
			return null;
		}

		return (
			<div style={ this.styles.tooltip }>
				{ payload[0].payload.views } Views
			</div>
		);
	}

	renderStats(item: ViewsStat, index: number) {
		const { stat, name } = item;
		const { description, statistic } = this.styles;

		return (
			<div style={ this.getStatStyle(name) } key={ name }>
				<span style={ statistic }>{ stat }%</span>
				<span style={ description }>
					<Icon
						name="circle"
						style={{
							color: chartColors[index],
							marginRight: 4
						}}
					/>
					{ name }"
				</span>
			</div>
		);
	}

	getStatStyle(name: string) {
		const { hoveredStat } = this.state;

		return {
			...this.styles.statBlock,
			color: !hoveredStat || name === hoveredStat ? black : lightestGray
		}
	}

	getViewsByDeviceSize(props: ViewsByDeviceChartProps) {
		let counts = { 13: 0, 21: 0, 32: 0, 43: 0 };
		let total = 0;

		props.data.forEach((item) => {
			counts[item.size] += item.views;
			total += item.views;
		});

		return { counts, total };
	}

	handleMouseEnter(data: Partial<ViewsStat>) {
		this.setState(() => ({ hoveredStat: data.name || "" }));
	}

	handleMouseLeave() {
		this.setState(() => ({ hoveredStat: "" }));
	}

	setupComponent(nextProps?: ViewsByDeviceChartProps) {
		const props = nextProps || this.props;
		const { counts, total } = this.getViewsByDeviceSize(props);
		const countValues = Object.values(counts);
		const fix = (val: number) => Number(val.toFixed(1));
		let percentRemaining = 100;

		const percentages = countValues.map((count, i) => {
			if (i === countValues.length - 1) {
				return percentRemaining;
			}

			const percentage = fix((count / total) * 100);

			percentRemaining = fix(percentRemaining - percentage);

			return percentage;
		});

		this.stats = [
			{
				stat: percentages[0],
				views: counts[13],
				name: "13"
			},
			{
				stat: percentages[1],
				views: counts[21],
				name: "21"
			},
			{
				stat: percentages[2],
				views: counts[32],
				name: "32"
			},
			{
				stat: percentages[3],
				views: counts[43],
				name: "43"
			}
		];
	}
}

export default connect(superMapStateToProps, null)(ViewsByDeviceChart);
import { Steps } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, Deployment, DeploymentType, DeploySteps } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Button, Icon } from "Components/Global/Common";
import { updateDeploymentAndChangeStep } from "Data/Actions/DeploymentsAsync";
import { completeDeploymentWizard, setDeploymentScheduled } from "Data/Actions/UI/DeploymentWizard";
import { DeployStepDetails } from "Data/Objects/Deployments";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveDeployment, getDeploymentScheduled, getDeploymentStep } from "Data/Selectors/DeploymentWizard";

const { Step } = Steps;

const mapDispatchToProps = (dispatch) => ({
	completeDeployment: () => dispatch(completeDeploymentWizard()),
	enableScheduled: () => dispatch(setDeploymentScheduled(true)),
	navigateToStep: (step: DeploySteps, back?: boolean) => dispatch(updateDeploymentAndChangeStep(step, back))
});

const mapStateToProps = (state) => ({
	currentStep: getDeploymentStep(state),
	deployment: getActiveDeployment(state),
	showSchedule: getDeploymentScheduled(state)
});

interface StepFooterProps {
	completeDeployment: () => void;
	currentStep: DeploySteps;
	deployment: Deployment;
	enableScheduled: () => void;
	navigateToStep: (step: DeploySteps, back?: boolean) => void;
	showSchedule: boolean;
};

const steps = [ DeploySteps.CREATE_NEW, DeploySteps.SCHEDULE, DeploySteps.DEVICES, DeploySteps.CONFIRM ];

class StepFooter extends React.Component<StepFooterProps> {
	constructor(props: StepFooterProps) {
		super(props);

		this.styles = {
			container: {
				height: "10vh",
				padding: 30,
				display: "flex",
				width: "100%"
			},
			button: {
				marginTop: 2
			},
			buttonContainer: {
				flex: 2,
				textAlign: "center"
			},
			stepsContainer: {
				flex: 6
			},
			step: {
				cursor: "pointer"
			},
			scheduleButton: {
				marginRight: 10
			}
		};

		this.confirmScheduleDeploy = this.confirmScheduleDeploy.bind(this);
		this.navigateBackward = this.navigateBackward.bind(this);
		this.navigateForward = this.navigateForward.bind(this);
		this.onStepClick = this.onStepClick.bind(this);
		this.renderRightButtons = this.renderRightButtons.bind(this);
		this.renderStep = this.renderStep.bind(this);
	}

	styles: CustomCSS;

	render() {
		if (!this.props.deployment) {
			return null;
		}

		const { button, buttonContainer, container, stepsContainer } = this.styles;

		return (
			<div style={ container }>
				<div style={ buttonContainer } >
					<Button
						icon="chevron-left"
						style={ button }
						onClick={ this.navigateBackward }>
						Back
					</Button>
				</div>
				{ /* Subtract a step to account for the first step not being in the footer */}
				<Steps style={ stepsContainer } current={ this.getStepIndex() - 1 }>
					{ steps.slice((1)).map(this.renderStep) }
				</Steps>
				{ this.renderRightButtons() }
			</div>
		)
	}

	renderIcon(name: string) {
		return <Icon name={ name } />;
	}

	renderRightButtons() {
		const { button, buttonContainer, scheduleButton: scheduleButtonStyle } = this.styles;
		const { currentStep, deployment, enableScheduled, showSchedule } = this.props;
		const isSchedule = deployment.type === DeploymentType.SCHEDULE;
		let deployText = hasPermission(PERMISSIONS.DEPLOYMENTS_DEPLOY) ? "Deploy" : "Submit for Approval";

		if (isSchedule) {
			deployText += " Now";
		}

		if (currentStep !== DeploySteps.CONFIRM) {
			return (
				<div style={ buttonContainer }>
					<Button
						icon="chevron-right"
						suffixIcon
						type="primary"
						style={ button }
						onClick={ this.navigateForward }>
						Next
					</Button>
				</div>
			);
		}

		if (!showSchedule) {
			// only show the schedule button on Schedule-type Deployments, not on Events
			const scheduleButton = !isSchedule ? null : (
				<Button
					icon="calendar"
					suffixIcon
					style={ { ...button, ...scheduleButtonStyle } }
					onClick={ enableScheduled }>
					Schedule
				</Button>
			);

			return (
				<div style={ buttonContainer }>
					{ scheduleButton }
					<Button
						icon="rocket"
						type="primary"
						style={ button }
						onClick={ this.confirmScheduleDeploy(false) }>
						{ deployText }
					</Button>
				</div>
			);
		}

		return (
			<div style={ buttonContainer }>
				<Button
					icon="calendar"
					type="primary"
					style={ button }
					onClick={ this.confirmScheduleDeploy(isSchedule) }>
					Schedule
				</Button>
			</div>
		);
	}

	renderStep(step: DeploySteps) {
		const { title: { text, icon } } = DeployStepDetails[step];
		const { step: stepStyle } = this.styles;
		return (
			<Step
				key={ text }
				onClick={ this.onStepClick(step) }
				style={ stepStyle }
				title={ text }
				icon={ this.renderIcon(icon) }
			/>
		);
	}

	confirmScheduleDeploy(schedule: boolean = false) {
		// if we do not have the permissions show the approval modal only, not the confirmation modal
		if (!hasPermission(PERMISSIONS.DEPLOYMENTS_DEPLOY)) {
			return this.props.completeDeployment;
		}

		const text = `Are you sure you want to ${ schedule ? "schedule this deployment" : "deploy" }?`;
		const confirmation = schedule ? "Schedule" : "Deploy";

		return () => {
			Notifications.confirm(
				text,
				undefined,
				confirmation,
				"Cancel",
				this.props.completeDeployment
			);
		};
	}

	getStepIndex() {
		const { currentStep } = this.props;
		return steps.indexOf(currentStep);
	}

	navigateBackward() {
		const { navigateToStep } = this.props;
		const previousStep = steps[this.getStepIndex() - 1];
		return navigateToStep(previousStep, true);
	}

	navigateForward() {
		const { navigateToStep } = this.props;
		const nextStep = steps[this.getStepIndex() + 1];
		return navigateToStep(nextStep);
	}

	onStepClick(step: DeploySteps) {
		return () => {
			this.props.navigateToStep(step);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StepFooter);
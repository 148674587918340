import { Popover } from "antd";
import * as React from "react";

import { CustomCSS, MediaContext as Context } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { ButtonTypes } from "Components/Global/Button";
import { Button } from "Components/Global/Common";
import { FlipTypes, IconWeights } from "Components/Global/Icon";

interface IBatchOperationsButtonPopover {
	content: React.ReactElement<any> | React.Component;
	onVisibleChange?: () => any;
	visible?: boolean;
}

export interface IBatchOperationsButton {
	disabled?: boolean;
	icon?: string;
	label: string;
	onClick?: () => any;
	popover?: IBatchOperationsButtonPopover;
	type?: ButtonTypes;
	iconWeight?: IconWeights;
	iconFlip?: FlipTypes;
}

export interface IBatchOperationsProps {
	active?: boolean;
	batchCallback?: () => any;
	batchLabel: string;
	buttons: IBatchOperationsButton[];
	context?: Context;
}

interface IBatchOperationsState {
	batchModeActive: boolean;
}

export default class BatchOperations extends React.PureComponent<IBatchOperationsProps, IBatchOperationsState> {
	constructor(props: IBatchOperationsProps) {
		super(props);

		this.state = {
			batchModeActive: props.active || false
		};

		this.styles = {
			button: {
				margin: "0 2px",
				zIndex: 500
			},
			buttonContainer: {
				padding: "0 10px",
				display: "flex"
			},
			popoverContainer: {
				zIndex: this.props.context === "video" || this.props.context === "image" ? 4030 : 500
			},
			relative: {
				position: "relative"
			}
		};

		this.getPopupContainer = this.getPopupContainer.bind(this);
		this.renderBatchButton = this.renderBatchButton.bind(this);
		this.renderButtons = this.renderButtons.bind(this);
		this.setPopupContainerRef = this.setPopupContainerRef.bind(this);
		this.toggleBatchMode = this.toggleBatchMode.bind(this);
	}

	popupContainer: HTMLDivElement;
	styles: CustomCSS;

	componentWillReceiveProps(nextProps: IBatchOperationsProps) {
		const { active } = nextProps;

		if (active !== undefined && active !== (this.props.active || this.state.batchModeActive)) {
			this.setState(() => ({ batchModeActive: active }))
		}
	}

	render() {
		const active = this.state.batchModeActive;
		const { popoverContainer, relative } = this.styles;

		const container = () => this.popupContainer || document.getElementById("batch_popup_container");

		return (
			<div style={ relative }>
				{/* Only show edit buttons if "Group Devices" mode on */}
				<Popover
					overlayStyle={popoverContainer}
					content={this.renderButtons()}
					placement="bottomLeft"
					visible={active}
					getPopupContainer={ container }
				>
					{this.renderButton({
						label: active ? "Done" : this.props.batchLabel,
						onClick: () => this.toggleBatchMode(this.props.batchCallback)
					})}
				</Popover>
				<span ref={ this.setPopupContainerRef } id="batch_popup_container" />
			</div>
		);
	}

	renderButtons() {
		return (
			<div style={ this.styles.buttonContainer }>
				{this.props.buttons.map(this.renderBatchButton)}
			</div>
		);
	}

	getPopupContainer() {
		return this.popupContainer;
	}

	setPopupContainerRef(ref: HTMLDivElement) {
		this.popupContainer = ref;
	}

	toggleBatchMode(callback: () => any = () => null) {
		this.setState((prevState) => Object.assign(
			prevState,
			{ batchModeActive: !prevState.batchModeActive }
		), callback);
	}

	renderBatchButton(button: IBatchOperationsButton) {
		if (button.popover) {
			return this.renderPopover(button);
		}

		return this.renderButton(button);
	}

	renderPopover(button: IBatchOperationsButton) {
		if (!button.popover) {
			return null;
		}

		const { content, visible, onVisibleChange } = button.popover;

		return (
			<span>
				<Popover
					content={content}
					key={Utils.hashCode(button.label)}
					trigger="click"
					visible={visible}
					onVisibleChange={onVisibleChange}>
					{this.renderButton(button)}
				</Popover>
			</span>
		);
	}

	renderButton(button: IBatchOperationsButton) {
		const { disabled, icon, iconWeight, label, onClick, type, iconFlip } = button;
		return (
			<Button
				disabled={disabled}
				key={Utils.hashCode(label)}
				onClick={onClick}
				style={this.styles.button}
				type={type}
				icon={icon}
				iconWeight={iconWeight}
				iconFlip={iconFlip} >
				{label}
			</Button>
		);
	}
}
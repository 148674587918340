import * as React from "react";
import { PulseLoader } from "react-spinners";

import { CustomCSS } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";

interface LoaderProps {
	size?: "small" | "medium" | "large";
	loading?: boolean;
}

export default class Loader extends React.PureComponent<LoaderProps> {
	render() {
		let size = 10;

		switch (this.props.size) {
			case "small":
				size = 5;
				break;
			case "large":
				size = 15;
				break;
		}

		return (
			<PulseLoader
				color={Colors.primaryBlue}
				size={size}
				loading={(this.props.loading === undefined) ? true : this.props.loading} />
		);
	}
}

export class AppLoader extends React.PureComponent {
	render() {
		const loading: CustomCSS = {
			alignItems: "center",
			display: "flex",
			justifyContent: "center"
		};
		const main: CustomCSS = {
			height: "100vh"
		};

		return (
			<div style={{
				...loading,
				...main
			}}>
				<Loader />
			</div>
		);
	}
}
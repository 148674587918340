import * as React from "react";
import { DropTarget, DropTargetMonitor } from "react-dnd";
import { connect } from "react-redux";

import { DeviceGroupChildren, IDeviceGroup, Filters } from "@connect/Interfaces";
import Button from "Components/Global/Button";
import { DnDIdentifier } from "Components/Global/GridViewCard";
import { RequestNameTypes } from "Components/Global/RequestNameModal";
import { createDeviceGroupAsync } from "Data/Actions/Devices";
import { setRequestNameModal as setRequestNameModalState } from "Data/Actions/UI/Modals";
import { setActiveSelection } from "Data/Actions/UI";
import { getDeviceGroupById, getDeviceGroupPath } from "Data/Selectors/Devices";
import { getRequestNameModalVisibility } from "Data/Selectors/UI";
import { getActiveFilters, getActiveSelection } from "Data/Selectors/UI";

const mapStateToProps = (state) => {
	const filters = getActiveFilters(state, Filters.DEVICES);

	return {
		activeDeviceGroup: getDeviceGroupById(state, filters.group as string),
		deviceGroupPath: getDeviceGroupPath(state, filters.group as string),
		requestNameModalVisible: getRequestNameModalVisibility(state),
		selectedDevices: getActiveSelection(state, "devices"),
		selectedGroups: getActiveSelection(state, "deviceGroups")
	}
};

const mapDispatchToProps = (dispatch) => ({
	createDeviceGroup: (name: string, activeGroup: IDeviceGroup, children: DeviceGroupChildren) =>
		dispatch(createDeviceGroupAsync(name, activeGroup, children)),
	selectDevices: (deviceUuids: string[]) => dispatch(setActiveSelection("devices", deviceUuids)),
	selectGroups: (groupUuids: string[]) => dispatch(setActiveSelection("deviceGroups", groupUuids)),
	showRequestNameModal: () => dispatch(setRequestNameModalState(true, RequestNameTypes.DEVICE_GROUP))
});

const dropHandler = {
	drop(props: CreateDeviceGroupButtonProps, monitor: DropTargetMonitor) {
		return { item: monitor.getItem(), drop: true };
	}
}

interface CreateDeviceGroupButtonProps {
	createDeviceGroup: (name: string, activeGroup: IDeviceGroup, children?: DeviceGroupChildren) => void;
	activeDeviceGroup: IDeviceGroup;
	deviceGroupPath: { name: string, uuid: string }[];
	selectDevices: (deviceUuids: string[]) => void;
	selectGroups: (groupUuids: string[]) => void;
	selectedDevices: string[];
	selectedGroups: string[];
	connectDropTarget: Function;
	forceVisible?: boolean;
	isOver?: boolean;
	didDrop?: boolean;
	dropResult?: any;
	requestNameModalVisible: boolean;
	showRequestNameModal: () => null;
}

export class CreateDeviceGroupButton extends React.PureComponent<CreateDeviceGroupButtonProps> {
	constructor(props: CreateDeviceGroupButtonProps) {
		super(props);

		this.toggleCreateDeviceGroupModal = this.toggleCreateDeviceGroupModal.bind(this);
	}

	componentDidUpdate() {
		const {
			didDrop,
			dropResult,
			selectDevices,
			selectGroups,
			selectedDevices,
			selectedGroups,
			showRequestNameModal,
			requestNameModalVisible
		} = this.props;

		if (didDrop && dropResult.drop && !requestNameModalVisible) {
			// if just one device / group was dropped, "select" in our redux
			// state so that our data layer can have access to the that item
			if (!selectedGroups.length && !selectedDevices.length) {
				const { item: { type, data } } = dropResult;
				if (type === "device") {
					selectDevices([ data.uuid ]);
				} else if (type === "deviceGroup") {
					selectGroups([ data.uuid ]);
				}
			}

			showRequestNameModal();
		}
	}

	render() {
		return this.props.connectDropTarget(
			<div>
				<Button
					corners="rounded"
					icon="plus-circle"
					fluid
					key="createDeviceGroup_addGroup"
					onClick={ this.toggleCreateDeviceGroupModal }
					type="primary"
				>
					New Group
				</Button>
			</div>
		);
	}

	toggleCreateDeviceGroupModal() {
		this.props.showRequestNameModal();
	}
}

const DNDCreateDeviceGroupButton = DropTarget(
	DnDIdentifier,
	dropHandler,
	(dndConnect, monitor) => ({
		connectDropTarget: dndConnect.dropTarget(),
		didDrop: monitor.didDrop(),
		dropResult: monitor.getDropResult()
	})
)(CreateDeviceGroupButton);

export { DNDCreateDeviceGroupButton };

export default connect(mapStateToProps, mapDispatchToProps)(DNDCreateDeviceGroupButton);
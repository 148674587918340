import { OptionProps } from "antd/lib/select";
import * as React from "react";
import { connect } from "react-redux";

import { notifyBugSnag } from "@connect/BugSnag";
import { CustomCSS, IDevice, IStore, DeviceCapabilities, Timezone } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { Api } from "Api/Api";
import DeviceImage from "Components/Devices/DeviceImage";
import { Button, Select, Truncate, HelpPopover, Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ErrorDescription from "Components/Global/ErrorDescription";
import Input from "Components/Global/Input";
import { updateDeviceAsync } from "Data/Actions/Devices";
import { setDeviceSnapshotStatus, setMediaPreview } from "Data/Actions/UI";
import { AppState } from "Data/Objects/AppState";
import Perishable from "Data/Objects/Perishable";
import { Timezones } from "Data/Objects/Timezones";
import { getStores } from "Data/Selectors/Company";
import { getDeviceSnapshotRefreshing } from "Data/Selectors/UI";
import { getCurrentApk } from "Data/Selectors/System";
import { checkDeviceCapabilities } from "Data/Objects/Devices";
import IntegratorDropdown  from "Components/Devices/IntegratorDropdown";
import { toggleFeature } from "@connect/Features";
import DevicesApi from "Api/Devices";
import NetworkWarning  from "Components/Devices/NetworkWarning";

const { Option } = Select;

const mapDispatchToProps = (dispatch, ownProps) => {
	const { uuid } = ownProps.device;

	return {
		setFetchingSnapshot: (status: boolean) => dispatch(setDeviceSnapshotStatus(uuid, status)),
		showMediaPreview: (url: string) => dispatch(setMediaPreview(url, -1, "image")),
		updateDevice: (device: IDevice) => dispatch(updateDeviceAsync(device))
	}
}

const mapStateToProps = (state: AppState) => {
	return {
		snapshotRefreshing: getDeviceSnapshotRefreshing(state),
		stores: getStores(state),
		currentApkVersion: getCurrentApk(state)
	}
}

interface DeviceInfoSectionProps {
	device: IDevice;
	snapshotRefreshing: Perishable<boolean>
	stores: IStore[];
	setFetchingSnapshot: (status: boolean) => void;
	showMediaPreview: (url: string) => void;
	updateDevice: (device: IDevice) => void;
	currentApkVersion: string;
}

interface DeviceInfoSectionState {

}

const styles = {
	container: {
		width: "100%",
		display: "flex",
		padding: 10
	},
	leftSection: {
		flex: 49
	},
	divider: {
		flex: 2
	},
	rightSection: {
		flex: 49
	},
	shadow: {
		boxShadow: "0 4px 8px 0 #F3F3F3, 0 6px 20px 0 #F9F9F9"
	},
	deviceInfoCard: {
		paddingLeft: 10,
		paddingRight: 10
	},
	deviceImageCard: {
		textAlign: "center"
	},
	deviceViewCard: {
		padding: 20
	},
	deviceViewHeader: {
		float: "left",
		fontWeight: "bold"
	},
	deviceView: {
		width: "100%"
	},
	deviceViewPortrait: {
		height: 207
	},
	deviceViewContainer: {
		width: "100%",
		marginBottom: 10,
		marginTop: 10,
		display: "inline-flex",
		justifyContent: "center"
	},
	snapshotDateContainer: {
		marginBottom: 10,
		float: "right",
		color: Colors.lightGray
	},
	dropdownSelector: {
		width: "100%",
		maxWidth: 271,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	deviceInfoLine: {
		width: "100%",
		display: "flex",
		paddingBottom: 10,
		paddingTop: 10
	},
	deviceInfoLineLabel: {
		flex: 3,
		color: Colors.lightGray
	},
	deviceInfoLineText: {
		flex: 7
	},
	updateButtonContainer: {
		width: "100%",
		textAlign: "center"
	},
	copyIcon: {
		marginLeft: 5,
		cursor: "pointer"
	}
} as CustomCSS;

export class DeviceInfoSection extends React.Component<DeviceInfoSectionProps, DeviceInfoSectionState> {
	constructor(props: DeviceInfoSectionProps) {
		super(props);

		this.updateDeviceName = this.updateDeviceName.bind(this);
		this.updateStore = this.updateStore.bind(this);
		this.renderPopover = this.renderPopover.bind(this);
		this.openSnapshotPreview = this.openSnapshotPreview.bind(this);
		this.updateDeviceSnapshot = this.updateDeviceSnapshot.bind(this);
		this.renderTimezonePicker = this.renderTimezonePicker.bind(this);
		this.updateTimezone = this.updateTimezone.bind(this);
		this.renderSoftwareVersion = this.renderSoftwareVersion.bind(this);
		this.renderDeviceInfoLine = this.renderDeviceInfoLine.bind(this);
		this.handleCopySerial = this.handleCopySerial.bind(this);
	}

	renderTimeout: any;

	// TODO: These timeouts need to be addressed when we have a better solution
	// Until then they are needed to force updates when the Perishable items update
	componentDidMount() {
		this.renderTimeout = setInterval(() => {
			this.forceUpdate()
		}, 10000);
	}

	componentWillUnmount() {
		clearInterval(this.renderTimeout);
	}

	render() {
		const { container, leftSection, rightSection, divider } = styles;

		return (
			<React.Fragment>
				<NetworkWarning />
				<div style={ container }>
					<div style={ leftSection }>
						{ this.renderDeviceInfo() }
					</div>
					<div style={ divider } />
					<div style={ rightSection }>
						{ this.renderDeviceImageSection() }
						{ this.renderDeviceViewSection() }
					</div>
				</div>
			</React.Fragment>
		);
	}

	renderDeviceInfo() {
		const { device } = this.props;
		const { deviceGroup } = device;
		const { shadow, deviceInfoCard } = styles;
		const installDate = Utils.getHumanReadableDate(device.installDate);

		const deviceInfo = [
			{
				label: "Model #:",
				value: device.model
			},
			{
				label: "Serial #:",
				value: device.serial
			},
			{
				label: "Device ID:",
				value: device.cecSerial
			},
			{
				label: "Install Date:",
				value: installDate
			},
			{
				label: "Software:",
				value: this.renderSoftwareVersion()
			},
			{
				label: "Group:",
				value: deviceGroup ? deviceGroup.name : "(None)"
			},
			{
				label: "Integrator:",
				value: this.renderIntegratorsPicker()
			},
			{
				label: "Store #:",
				value: this.renderStorePicker()
			},
			{
				label: "Time Zone:",
				value: this.renderTimezonePicker()
			},
			{
				label: "Camera Type:",
				value: this.getDeviceCameraType(device)
			},
			{
				label: "Wifi MAC:",
				value: device.wifiMac
			},
			{
				label: "Ethernet MAC:",
				value: device.ethernetMac
			}
		];

		return (
			<div style={ { ...shadow, ...deviceInfoCard } }>
				<Input
					id={ device.uuid }
					value={ device.name }
					saveCallback={ this.updateDeviceName } />
				{ deviceInfo.map(this.renderDeviceInfoLine) }
			</div>
		);
	}

	renderSoftwareVersion() {
		const { device, currentApkVersion } = this.props;
		let value = device.softwareVersion;

		if (currentApkVersion !== device.softwareVersion) {
			value += ` (Version ${currentApkVersion} is available)`
		}

		return value;
	}

	renderDeviceInfoLine(deviceInfo: any, index: number) {
		const { deviceInfoLine, deviceInfoLineLabel, deviceInfoLineText, copyIcon: copyIconStyles } = styles;

		const border = index < 10 ? "1px solid " + Colors.lightestGray : null;

		let copyIcon;

		if (deviceInfo.label === "Serial #:") {
			copyIcon = (
				<Icon
					style={ copyIconStyles }
					name="copy"
					iconWeight="regular"
					onClick={ this.handleCopySerial(deviceInfo.value) }
				/>
			);
		}

		return (
			<div
				key={ index }
				style={{
					...deviceInfoLine,
					borderBottom: border
				}}>
				<div style={ deviceInfoLineLabel }>
					{ deviceInfo.label }
				</div>
				<div style={ deviceInfoLineText }>
					{ deviceInfo.value }
					{ copyIcon }
				</div>
			</div>
		);
	}

	storePickerFilter(inputValue: string, option: React.ReactElement<OptionProps>): boolean {
		const opt = (option.props.children as string).toLowerCase();

		return opt.indexOf(inputValue.toLowerCase()) > -1;
	}

	renderIntegratorsPicker() {
		return <IntegratorDropdown
			uuid={ this.props.device.uuid }
		/>
	}

	renderStorePicker() {
		const { device, stores } = this.props;
		const { dropdownSelector } = styles;
		const sortedStores = Utils.sort(stores, "name", true);

		return (
			<Select
				key={ device.uuid }
				size="small"
				value={device.store}
				notFoundContent={false} // TODO: Fix this
				onSelect={this.updateStore}
				style={ dropdownSelector }
				filterOption={ this.storePickerFilter }
				showSearch>
				{ sortedStores.map(this.renderStoreOptions) }
			</Select>
		);
	}

	renderStoreOptions(store: IStore) {
		return (
			<Option
				key={ store.uuid }
				value={ store.uuid } >
				<Truncate length={ 40 }>{ store.name }</Truncate>
			</Option>
		);
	}

	renderTimezonePicker() {
		const { device } = this.props;
		const { dropdownSelector } = styles;

		return (
			<Select
				key={ device.uuid }
				size="small"
				style={ dropdownSelector }
				value={ device.timezone }
				onSelect={ this.updateTimezone }
				filterOption={ this.storePickerFilter }
				showSearch>
				{ Timezones.map(this.renderTimezoneOption) }
			</Select>
		)
	}

	renderTimezoneOption(timezone: Timezone) {
		return (
			<Option
				key={ timezone.id }
				className="timezoneItem"
				value={ timezone.id } >
				{ `${ timezone.displayName }: ${ timezone.description }` }
			</Option>
		)
	}

	renderDeviceImageSection() {
		const { shadow, deviceImageCard } = styles;

		return (
			<div style={ { ...shadow, ...deviceImageCard } }>
				<DeviceImage
					model={ this.props.device.model }
					height={ 200 }
				/>
			</div>
		);
	}

	renderDeviceViewSection() {
		const { shadow, deviceViewCard, deviceView, snapshotDateContainer,
			deviceViewHeader, updateButtonContainer, deviceViewContainer, deviceViewPortrait } = styles;
		const { device, snapshotRefreshing } = this.props;
		const snapshotDate = Utils.getHumanReadableDate(device.snapshotTimestamp);
		const isPortrait = checkDeviceCapabilities(device.model, DeviceCapabilities.CAMERA_PORTRAIT);
		const deviceViewStyle = isPortrait ? deviceViewPortrait : deviceView;

		if (!checkDeviceCapabilities(device.model, DeviceCapabilities.CAMERA)) {
			return null;
		}

		return (
			<div style={ { ...shadow, ...deviceViewCard } }>
				<div style={ deviceViewHeader }>
					Device View: { this.renderPopover() }
				</div>
				<div style={ snapshotDateContainer }>
					Date of Image: { snapshotDate }
				</div>
				<div style={ deviceViewContainer }>
					<img
						onClick={this.openSnapshotPreview}
						src={ device.snapshotThumbnail }
						style={ deviceViewStyle }/>
				</div>
				<div style={ updateButtonContainer }>
					<Button
						disabled={ snapshotRefreshing && snapshotRefreshing.value === true }
						icon="sync"
						iconSpin={ snapshotRefreshing && snapshotRefreshing.value === true }
						onClick={this.updateDeviceSnapshot}
					>
						Update Device View
					</Button>
				</div>
			</div>
		);
	}

	renderPopover() {

		return (
			<HelpPopover title="Device View">
				{ this.renderPopoverContent() }
			</HelpPopover>
		);
	}

	renderPopoverContent() {

		return (
			<div>
				This is the image that was taken during the installation process.
				Click Update Device View for an updated image.
				(This is not a streaming video)
			</div>
		);
	}

	openSnapshotPreview() {
		const { showMediaPreview, device } = this.props;

		showMediaPreview(device.snapshot);
	}

	updateDeviceName(name: string) {
		const { updateDevice, device } = this.props;

		updateDevice({ ...device, name });
	}

	updateStore(store: string) {
		const { updateDevice, device } = this.props;

		updateDevice({ ...device, store });
	}

	updateTimezone(timezone: string) {
		const { updateDevice, device } = this.props;

		updateDevice({ ...device, timezone });
	}

	getDeviceCameraType(device: IDevice) {
		if (checkDeviceCapabilities(device.model, DeviceCapabilities.CAMERA_AXIS)) {
			return "AXIS-2MP";
		} else if (checkDeviceCapabilities(device.model, DeviceCapabilities.CAMERA_EX)) {
			return "EX-SDI 2MP";
		} else if (checkDeviceCapabilities(device.model, DeviceCapabilities.CAMERA_CEC)) {
			return "Clinton IP-2MP";
		} else {
			return "No Camera"
		}
	}

	updateDeviceSnapshot() {
		const { setFetchingSnapshot } = this.props;

		const devicesApi = new DevicesApi();
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.updateSnapshot.bind(devicesApi),
			Api.DeviceApi.updateDeviceSnapshot.bind(Api.DeviceApi)
		);

		setFetchingSnapshot(true);
		apiMethod(this.props.device.uuid)
			.then(null, (error) => {
				setFetchingSnapshot(false);
				Notifications.error("Error updating device snapshot", <ErrorDescription error={ error } />);
			})
			.catch((error) => {
				Notifications.error("Error updating device snapshot", <ErrorDescription error={ error } />);
				setFetchingSnapshot(false);
				notifyBugSnag(new Error(error));
			});
	}

	handleCopySerial(serialNumber: string) {
		return () => navigator.clipboard.writeText(serialNumber);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfoSection);
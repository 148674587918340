import Radium from "radium";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

const { lightGray, offWhite, primaryGreen, white } = Colors;

interface ICreateNewAnalyticsButtonProps {
	onCreateNewAnalytics: () => void;
}

export class CreateNewAnalyticsButton
	extends React.Component<ICreateNewAnalyticsButtonProps> {
	constructor(props: ICreateNewAnalyticsButtonProps) {
		super(props);

		this.styles = {
			outer: {
				color: lightGray,
				cursor: "pointer",
				background: offWhite,
				boxShadow: `0px 2px 10px ${lightGray}`,
				border: `1px solid ${white}`,
				borderRadius: 6,
				minHeight: 300,
				width: 286,
				margin: 10,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				":hover": {
					color: white,
					background: primaryGreen,
					border: `1px solid ${primaryGreen}`
				}
			},
			icon: {
				marginBottom: 18
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { outer, icon } = this.styles;
		return (
			<div
				style={ outer }
				onClick={ this.props.onCreateNewAnalytics }>
				<Icon
					name="plus-circle"
					size="small"
					style={ icon } />
				<p>New Ad Analytics</p>
			</div>
		);
	}
}

export default Radium(CreateNewAnalyticsButton);

import { Progress } from "antd";
import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { ISuperAnalyticsChartProps, ISuperAnalyticsChartState, mapStateToProps as superMapStateToProps,
	SuperAnalyticsChart } from "Components/Analytics/SuperAnalyticsChartComponent";
import { Colors } from "Components/Global/Constants";
import { getActiveAnalyticsDeviceCount, getActiveAnalyticsTimeOnScreen } from "Data/Selectors/AdAnalytics";

const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		adTimeOnScreen: getActiveAnalyticsTimeOnScreen(state),
		deviceCount: getActiveAnalyticsDeviceCount(state)
	};
};

interface AdTimeOnScreenChartProps extends ISuperAnalyticsChartProps {
	adTimeOnScreen: number;
	deviceCount: number;
}

const { primaryGreen } = Colors;

export class AdTimeOnScreenChart extends SuperAnalyticsChart<AdTimeOnScreenChartProps, ISuperAnalyticsChartState> {
	constructor(props: AdTimeOnScreenChartProps) {
		super(props);

		this.styles = Object.assign({}, this.styles, {
			screenTime: {
				fontSize: "1.5em",
				fontWeight: 700
			},
			screenTimeLabel: {
				fontWeight: 700
			}
		});
	}

	styles: CustomCSS;

	render() {
		const { adTimeOnScreen, range: dateRange, deviceCount, disabled, loading } = this.props;

		if (disabled) {
			return this.renderContainer(<div />);
		}

		const { screenTime, screenTimeLabel, main, chartContainer, statsContainer, statBlock } = this.styles;

		const [ startDate, endDate ] = dateRange.split("_");
		const range = Math.abs(moment(startDate, "YYYY-MM-DD").diff(moment(endDate, "YYYY-MM-DD"), "days")) + 1;
		const screenTimePercent = adTimeOnScreen / (Utils.getSecondsFromDays(range) * deviceCount) * 100
		const duration = Utils.getHumanReadableDuration(adTimeOnScreen, true);
		const formatPercent = (percent) => {
			return Math.floor(percent) + "%"
		};
		const chart = loading ? <div /> : (
			<Progress
				type="circle"
				style={ screenTime }
				percent={ screenTimePercent }
				strokeColor={ primaryGreen }
				strokeWidth={ 5 }
				format={ formatPercent }
			/>
		);

		return this.renderContainer(
			<div style={ main }>
				<div style={ chartContainer }>
					{ chart }
				</div>
				<div style={ statsContainer }>
					<div style={ statBlock }>
						<div style={ screenTime } >
							{ duration }
						</div>
						<div style={ screenTimeLabel }>
							Total Ad Duration
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(AdTimeOnScreenChart);
import * as React from "react";
import { connect } from "react-redux";
import { Colors } from "Components/Global/Constants";
import { CustomCSS, Trigger } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Switch } from "antd";
import { getSelectedTrigger } from "Data/Selectors/ActionBuilder";
import { updateSelectedTriggerContinuous } from "Data/Actions/ActionsAsync";

interface ContinuousPropertiesProps {
	updateContinuous: (continuous: boolean) => void;
	continuous: boolean;
	trigger: Trigger;
}

const mapStateToProps = (state) => {
	const trigger = getSelectedTrigger(state);

	return {
		continuous: trigger?.continuous,
		trigger: trigger
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateContinuous: (continuous: boolean) => dispatch(updateSelectedTriggerContinuous(continuous))
	}
};

class ContinuousProperties extends React.Component<ContinuousPropertiesProps> {
	constructor(props: ContinuousPropertiesProps) {
		super(props);

		this.styles = {
			label: {
				float: "left"
			},
			row: {
				margin: "0px 0px 7px",
				display: "inline-block"
			},
			switchButton: {
				marginLeft: 100
			},
			description: {
				fontSize: "0.95em",
				color: Colors.slate,
				marginTop: 5
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { label, row, switchButton, description } = this.styles;
		const { continuous, trigger } = this.props;

		if (!trigger) {
			return null;
		}

		return (
			<div style={ row }>
				<div style={ label }><Icon name="clock" /> Continuous:</div>
				<Switch
					style={ switchButton }
					checked={ continuous }
					onChange={ this.props.updateContinuous }
				/>
				<p style={ description }>
					Warning: when this is enabled actions will play continuously until the trigger is disabled
				</p>
			</div>
		);
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(ContinuousProperties);
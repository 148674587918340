import { Icon, Table as AntTable } from "antd";
import FilterMenu from "antd/lib/table/filterDropdown";
import { ColumnProps, TableLocale, TableProps as AntTableProps } from "antd/lib/table/interface";
import { treeMap } from "antd/lib/table/util";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";

interface TableProps<T> extends AntTableProps<T> {}

export default class Table<T> extends AntTable<T> {

	constructor(props: TableProps<T>) {
		super(props);

		this.toggleSortOrder = this.toggleSortOrder.bind(this);
	}

	static defaultProps = {
		...AntTable.defaultProps,
		rowClassName: (record, index) => index % 2 === 0 ? "row-stripe" : ""
	};

	render() {
		// ensure we return the result of the Ant Table's `render` method
		return AntTable.prototype.render.call(this);
	}

	// this method overrides the Ant Table's `renderColumnsDropdown`
	renderColumnsDropdown(prefixCls: string, dropdownPrefixCls: string, columns: ColumnProps<T>[], locale: TableLocale) {
		const { sortOrder } = this.state;

		//TODO: CON-5439: Refactor destructuring copy
		return treeMap(columns, (originColumn, i) => {
			let column = { ...originColumn };
			const key = this.getColumnKey(column, i);
			let filterDropdown;
			let sortButton;
			let sortOnClick: () => void = () => null;

			if ((column.filters && column.filters.length > 0) || column.filterDropdown) {
				let colFilters = this.state.filters[key] || [];

				filterDropdown = (
					<FilterMenu
						locale={locale}
						column={column}
						selectedKeys={colFilters}
						confirmFilter={AntTable.prototype.handleFilter}
						prefixCls={`${prefixCls}-filter`}
						dropdownPrefixCls={dropdownPrefixCls || "ant-dropdown"}
						getPopupContainer={AntTable.prototype.getPopupContainer}
					/>
				);
			}

			if (column.sorter) {
				let isSortColumn = this.isSortColumn(column);

				if (isSortColumn) {
					column.className = column.className || "";

					if (sortOrder) {
						column.className += ` ${prefixCls}-column-sort`;
					}
				}

				// here's where we start to change the overridden method
				const isAscend = isSortColumn && sortOrder === "ascend";
				const upDown = isAscend ? "down" : "up";
				const sorterClass = `${prefixCls}-column-sorter`;
				const sorterToggleClass = `${sorterClass}-${upDown} on`
				const sorterTitle = isAscend ? "↑" : "↓";
				const sorterStyle: CustomCSS = {
					visibility: isSortColumn ? "visible" : "hidden"
				};

				sortButton = (
					<div className={sorterClass}>
						<span
							style={ sorterStyle }
							className={sorterToggleClass}
							title={sorterTitle}>
							<Icon type={upDown} />
						</span>
					</div>
				);

				sortOnClick = () => {
					this.toggleSortOrder(column);
				};
			}

			// add the click handler directly to the title, rather than the tiny spans of the `sortButton`
			column.title = (
				<div
					style={{
						height: "100%",
						width: "100%"
					}}
					onClick={sortOnClick}>
					{column.title}
					{sortButton}
					{filterDropdown}
				</div>
			);

			return column;
		});
	}

	/**
	 * We are discouraged from calling `super.methodName` directly
	 * so we proxy the methods below which are used in our overridden method above.
	 */
	getLocale() {
		return AntTable.prototype.getLocalData.call(this);
	}

	getColumnKey(column: any, i: number) {
		return AntTable.prototype.getColumnKey.call(this, column, i);
	}

	isSortColumn(column: any) {
		return AntTable.prototype.isSortColumn.call(this, column);
	}

	toggleSortOrder(column: ColumnProps<T>) {
		if (!column.sorter) {
			return;
		}

		// give two options so that our tables toggle between ascending and descending sorts
		const sortDirections = [ "ascend", "descend" ];
		const { sortOrder } = this.state;
		let newSortOrder;

		if (this.isSortColumn(column) && sortOrder !== undefined) {
			// change this block from ant base code to make our tables only toggle between
			// two sorts: ascending and descending, as opposed to their default behavior--
			// toggling between ascending, descending, and the default sort of the dataset
			newSortOrder = sortOrder === sortDirections[0] ? sortDirections[1] : sortDirections[0];
		} else {
			[ newSortOrder ] = sortDirections;
		}

		const newState = {
			sortOrder: newSortOrder,
			sortColumn: newSortOrder ? column : null
		};

		if (this.getSortOrderColumns().length === 0) {
			this.setState(newState);
		}

		const { onChange } = this.props;

		if (onChange) {
			onChange.apply(null, this.prepareParamsArguments({...this.state, ...newState}));
		}
	}
}
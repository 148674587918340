import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { TriggerPropertiesPanelContent,
	TriggerPropertiesPanelContentProps } from "Components/Actions/TriggerPropertiesPanelContent";
import { getSelectedTriggerType } from "Data/Selectors/ActionBuilder";
import DwellProperties from "Components/Actions/DwellProperties";
import DetectionBoxProperties from "Components/Actions/DetectionBoxProperties";

const mapStateToProps = (state) => {
	return {
		triggerType: getSelectedTriggerType(state)
	};
};

interface FaceTriggerPropertiesPanelContentProps extends TriggerPropertiesPanelContentProps {}

export class FaceTriggerPropertiesPanelContent
	extends TriggerPropertiesPanelContent<FaceTriggerPropertiesPanelContentProps> {
	constructor(props: FaceTriggerPropertiesPanelContentProps) {
		super(props);
	}

	styles: CustomCSS;

	render() {
		return this.renderContainer(
			<div>
				<DwellProperties />
				<DetectionBoxProperties />
			</div>
		);
	}
}

export default connect(mapStateToProps)(FaceTriggerPropertiesPanelContent);

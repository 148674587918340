import * as React from "react";

import { CustomCSS, DeploySteps } from "@connect/Interfaces";
import Schedule from "Components/Deploy/Schedule/Schedule";
import StepFooter from "Components/Deploy/StepFooter";
import StepHeader from "Components/Deploy/StepHeader";
import { DeployStepDetails } from "Data/Objects/Deployments";

class ScheduleBuilderStep extends React.Component {
	constructor(props: any) {
		super(props);

		this.styles = {
			scheduleWrapper: {
				margin: "0px auto",
				height: "80vh",
				minHeight: "80vh",
				overflow: "hidden auto",
				width: "90%"
			},
			container: {
				position: "relative",
				height: "100%",
				overflowY: "hidden"
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { title, subtitle } = DeployStepDetails[DeploySteps.SCHEDULE];
		const { container, scheduleWrapper } = this.styles;

		return (
			<div style={ container } >
				<StepHeader
					title={ title }
					subtitle={ subtitle } />
				<div style={ scheduleWrapper }>
					<Schedule />
				</div>
				<StepFooter />
			</div>
		)
	}
}

export default ScheduleBuilderStep;
import { connect } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { TriggerPropertiesPanelContent,
	TriggerPropertiesPanelContentProps } from "Components/Actions/TriggerPropertiesPanelContent";
import { getSelectedTriggerType } from "Data/Selectors/ActionBuilder";
import * as React from "react";
import DwellProperties from "Components/Actions/DwellProperties";

const mapStateToProps = (state) => {
	return {
		triggerType: getSelectedTriggerType(state)
	};
};

interface MotionTriggerPropertiesPanelContentProps extends TriggerPropertiesPanelContentProps {}

export class MotionTriggerPropertiesPanelContent
	extends TriggerPropertiesPanelContent<MotionTriggerPropertiesPanelContentProps> {
	constructor(props: MotionTriggerPropertiesPanelContentProps) {
		super(props);
	}

	styles: CustomCSS;

	render() {
		return this.renderContainer(
			<DwellProperties />
		);
	}
}

export default connect(mapStateToProps)(MotionTriggerPropertiesPanelContent);
import { Col, Row } from "antd";
import { ButtonType } from "antd/lib/button/button";
import { parse } from "query-string";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { ActiveUuidRoute, CustomCSS, HealthReport, HealthReportColumn, NameUuid, SortTypes,
	Filters,
	Sorts} from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import { IconWeights } from "Components/Global/Button";
import { Button, Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import DeviceSelection from "Components/Global/DeviceSelection";
import { RequestNameTypes } from "Components/Global/RequestNameModal";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import DeviceHealthReport from "Components/Health/DeviceHealthReport";
import HealthContentArea from "Components/Health/HealthContentArea";
import HealthPagePropertiesPanel from "Components/Health/HealthPagePropertiesPanel";
import ReportComponents from "Components/Health/ReportComponents";
import SavedReports from "Components/Health/SavedReports";
import { getStoresListAsync } from "Data/Actions/Company";
import {
	deleteReportAsync,
	getAllReportsAsync,
	getReportResultsAsync,
	updateReportAsync
} from "Data/Actions/HealthReportAsync";
import { setRequestNameModal as setRequestNameModalState } from "Data/Actions/UI/Modals";
import {
	addToActiveSelection,
	removeReportNotification,
	setActiveFilters,
	setActiveSelection,
	setActiveSort
} from "Data/Actions/UI";
import { AllGroup } from "Data/Objects/Devices";
import { HealthReportSorts } from "Data/Objects/HealthReport";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getFilteredSortedHealthReports, getSelectedHealthReport } from "Data/Selectors/HealthReports";
import { getActiveFilters, getActiveSelection, getActiveSorts, getActiveUuid } from "Data/Selectors/UI";

const { black, offWhite, lightGray, primaryGreen, white } = Colors;
const { NEWEST_FIRST, OLDEST_FIRST, ALPHA, REVERSE_ALPHA } = SortTypes;

const mapStateToProps = (state, ownProps) => {
	const { filter } = getActiveFilters(state, Filters.HEALTH_REPORTS);
	const sort = getActiveSorts(state, Sorts.HEALTH_REPORTS);
	const healthReports = getFilteredSortedHealthReports(state, {
		sort: sort as SortTypes,
		filter: filter as string
	});
	const uuid = ownProps.match.params.activeUuid || getActiveUuid(state, "health");

	return {
		activeHealthReport: getSelectedHealthReport(state, uuid),
		reports: healthReports,
		selectedDevices: getActiveSelection(state, "healthDevices"),
		selectedDeviceGroups: getActiveSelection(state, "healthDevices_groups"),
		selectedReports: getActiveSelection(state, "healthReports"),
		filter,
		sort
	}
};

const mapDispatchToProps = (dispatch) => ({
	selectDevices: (ids: string[]) => dispatch(addToActiveSelection("healthDevices", ids)),
	selectDeviceGroups: (ids: string[]) => dispatch(addToActiveSelection("healthDevices_groups", ids)),
	deleteReport: (uuid: string) => dispatch(deleteReportAsync(uuid)),
	fetchStores: () => dispatch(getStoresListAsync()),
	getReportResults: (uuid: string) => dispatch(getReportResultsAsync(uuid)),
	updateReport: (report: HealthReport) => dispatch(updateReportAsync(report)),
	removeReportNotification: (uuid: string) => dispatch(removeReportNotification(uuid)),
	selectReports: (uuids: string[]) => dispatch(setActiveSelection("healthReports", uuids)),
	setFilter: (value: string) => dispatch(setActiveFilters("filter", Filters.HEALTH_REPORTS, value)),
	showRequestNameModal: () => dispatch(setRequestNameModalState(true, RequestNameTypes.REPORT)),
	getAllReports: (full?: boolean) => dispatch(getAllReportsAsync(full)),
	setSort: (sort: SortTypes) => dispatch(setActiveSort(Sorts.HEALTH_REPORTS, sort))
});

interface HealthPageProps extends RouteComponentProps<ActiveUuidRoute> {
	activeHealthReport: HealthReport;
	selectDevices: (ids: string[]) => void;
	selectDeviceGroups: (ids: string[]) => void;
	deleteReport: (uuid: string) => void;
	fetchStores: () => Promise<void>;
	getReportResults: (uuid: string) => void;
	reports: HealthReport[];
	selectedReports: string[];
	updateReport: (report: HealthReport) => Promise<void>;
	removeReportNotification: (uuid: string) => void;
	selectedDevices: string[];
	selectedDeviceGroups: string[];
	selectReports: (uuids: string[]) => void;
	setFilter: (value: string) => void;
	showRequestNameModal: () => void;
	filter: string;
	sort: SortTypes;
	getAllReports: (full?: boolean) => Promise<void>;
	setSort: (sort: SortTypes) => void;
}

interface HealthPageState {
	centerContent?: null | "devices" | "report";
	columnSort?: HealthReportColumn;
	columnSortDirection?: "asc" | "desc";
	leftColumnVisible?: boolean;
	previousReportUuid: string;
	rightColumnVisible?: boolean;
	selectModeOn?: boolean;
}

export class HealthPage extends React.Component<HealthPageProps, HealthPageState> {
	constructor(props: HealthPageProps) {
		super(props);

		const buttonHover: CustomCSS = {
			backgroundColor: primaryGreen,
			color: white,
			cursor: "pointer"
		};

		this.state = {
			centerContent: null,
			columnSort: {} as HealthReportColumn,
			columnSortDirection: "asc",
			leftColumnVisible: true,
			previousReportUuid: "",
			rightColumnVisible: false,
			selectModeOn: false
		};

		this.styles = {
			button: {
				backgroundColor: offWhite,
				borderRadius: 3,
				color: lightGray,
				fontWeight: "bold",
				margin: "0 auto 10px",
				padding: 10,
				width: "70%",
				":focus": buttonHover,
				":hover": buttonHover
			},
			buttonContainer: {
				flex: "none",
				width: 200,
				textAlign: "center"
			},
			buttonDescription: {
				color: black,
				fontSize: "80%",
				padding: 2
			},
			deviceStep: {
				childContent: {
					margin: "0 auto",
					height: "80vh",
					minHeight: "80vh",
					overflowX: "hidden",
					width: "90%"
				},
				dualButtons: {
					margin: "0 0.25em"
				},
				footerItem: {
					flex: "none",
					lineHeight: "1em",
					textAlign: "center"
				},
				footerWrapper: {
					background: white,
					bottom: 0,
					display: "flex",
					justifyContent: "space-between",
					minHeight: "10vh",
					width: "100%"
				},
				header: {
					flex: "none",
					minHeight: "10vh",
					padding: 30,
					textAlign: "center"
				},
				subHead: {
					color: lightGray
				},
				wrapper: {
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					height: "100vh",
					margin: "0 auto",
					padding: "0 3em",
					width: "100%"
				}
			},
			wrapper: {
				alignItems: "center",
				display: "flex",
				height: "100vh",
				justifyContent: "center",
				textAlign: "center"
			},
			reportListHeader: {
				display: "block",
				width: "100%"
			},
			reportListButtons: {
				display: "inline",
				float: "right"
			}

		}

		this.closeReport = this.closeReport.bind(this);
		this.createReport = this.createReport.bind(this);
		this.saveDevicesAndGroups = this.saveDevicesAndGroups.bind(this);
		this.setDevicesActive = this.setDevicesActive.bind(this);
		this.setReportActive = this.setReportActive.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.haveSelectedReportDevices = false;
		this.deselectAll = this.deselectAll.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
		this.handleDeleteReports = this.handleDeleteReports.bind(this);
		this.handleSetAlphaSort = this.handleSetAlphaSort.bind(this);
		this.handleSetDateSort = this.handleSetDateSort.bind(this);
	}

	haveSelectedReportDevices: boolean;

	styles: {
		button: CustomCSS;
		buttonContainer: CustomCSS;
		buttonDescription: CustomCSS;
		deviceStep: {
			childContent: CustomCSS;
			dualButtons: CustomCSS;
			footerItem: CustomCSS;
			footerWrapper: CustomCSS;
			header: CustomCSS;
			subHead: CustomCSS;
			wrapper: CustomCSS;
		};
		wrapper: CustomCSS;
		reportListHeader: CustomCSS;
		reportListButtons: CustomCSS;
	};

	static getDerivedStateFromProps(props: HealthPageProps, state: HealthPageState) {
		const { activeHealthReport, location } = props;
		const query = parse(location.search);
		const querySort = query.sort && query.sort.split("_");
		const haveReportAndUUID = props && activeHealthReport && activeHealthReport.uuid;
		let newState = null;

		if (haveReportAndUUID && haveReportAndUUID !== state.previousReportUuid) {
			newState = Object.assign({}, newState, {
				previousReportUuid: haveReportAndUUID
			});

			if (!querySort) {
				newState = Object.assign({}, newState, {
					columnSort: null,
					columnSortDirection: null
				});
			}
		};

		if (querySort && querySort[0] in HealthReportSorts) {
			let [ sort, direction ] = querySort;

			newState = Object.assign({}, newState, {
				columnSort: HealthReportSorts[sort],
				columnSortDirection: direction
			});
		}

		if (!activeHealthReport && state.previousReportUuid !== "") {
			newState = Object.assign({}, newState, {
				centerContent: null,
				columnSort: null,
				columnSortDirection: null,
				previousReportUuid: ""
			});
		}

		return newState;
	}

	componentDidUpdate(prevProps: HealthPageProps) {
		this.handleUpdates(prevProps);
	}

	componentDidMount() {
		Promise.all([
			this.props.fetchStores(),
			this.props.getAllReports()
		]).then(() => this.handleUpdates());
	}

	render() {
		return (
			<ThreeColumnLayout
				centerContent={this.renderCenterContent()}
				leftContent={this.renderLeftContent()}
				rightContent={this.renderRightContent()}
			/>
		);
	}

	renderCenterContent() {
		switch (this.state.centerContent) {
			case "devices":
				return this.renderDeviceSelection();
			case "report":
				return this.renderReport();
		}

		return this.renderGallery();
	}

	renderGallery() {
		const { filter, setFilter, sort } = this.props;

		return (
			<React.Fragment>
				<ContentAreaTopBar
					batch={ this.getBatchOperations() }
					sort={{
						dataType: Sorts.HEALTH_REPORTS
					}}
					search={{
						filterText: filter,
						onSearchChange: setFilter
					}}
				/>
				<HealthContentArea
					key={ `${ sort }_${ filter }` }
					selectModeOn={ this.state.selectModeOn }
					onCreateReport={ this.createReport }
				/>
			</React.Fragment>
		);
	}

	renderCreateButton(mainButton?: boolean) {
		if (!hasPermission(PERMISSIONS.DEVICE_HEALTH_CREATE)) {
			return undefined;
		}

		if (!mainButton) {
			return (
				<Button
					corners="rounded"
					fluid
					icon="plus-circle"
					onClick={this.createReport}
					type="primary">
					New Report
				</Button>
			);
		}

		const { button, buttonContainer, buttonDescription, wrapper } = this.styles;

		return (
			<div style={wrapper}>
				<div style={buttonContainer}>
					<div onClick={this.createReport} style={button}>
						<Icon name="plus-circle" size="small" />
						<br />
						New Report
					</div>
					<div style={buttonDescription}>
						<p>OR</p>
					</div>
					<div style={buttonDescription}>
						<p><Icon name="arrow-left" /> Select from My Saved Reports</p>
					</div>
				</div>
			</div>
		);
	}

	renderDeviceSelection() {
		const report = this.props.activeHealthReport;

		if (!report) {
			return undefined;
		}

		const { childContent, dualButtons, footerItem, footerWrapper, header, subHead, wrapper } = this.styles.deviceStep;

		return (
			<div style={wrapper}>
				<div style={header}>
					<h1>Select Devices for Report</h1>
					<div style={subHead}>
						Select which device group(s) you would like to add to your report or select
						individual devices by selecting the Device tab.
					</div>
				</div>
				<div style={childContent}>
					<DeviceSelection
						filterSet={ Filters.HEALTH_DEVICES }
						valid={ true }
					/>
				</div>
				<Row align="middle" justify="space-between" style={footerWrapper} type="flex">
					<Col span={16} style={footerItem}>&nbsp;</Col>
					<Col span={8} style={footerItem}>
						<Button icon="times-circle" onClick={this.closeReport} style={dualButtons}>Cancel</Button>
						<Button icon="chevron-right"
							suffixIcon
							type="primary"
							onClick={this.saveDevicesAndGroups}
							style={dualButtons}>Save Devices</Button>
					</Col>
				</Row>
			</div>
		);
	}

	renderLeftContent() {
		if (!this.state.leftColumnVisible || !this.state.centerContent) {
			return undefined;
		}

		const reportComponents = this.renderReportComponents();
		const mySavedReports = this.renderMySavedReports();

		return (
			<Accordion
				header={this.renderCreateButton()}
				elements={[
					reportComponents,
					mySavedReports
				]}
			/>
		);
	}

	handleSetAlphaSort(event: React.SyntheticEvent) {
		event.preventDefault();

		const { setSort, sort } = this.props;
		const newSortType = sort === ALPHA ? REVERSE_ALPHA : ALPHA;

		setSort(newSortType);
	}

	handleSetDateSort(event: React.SyntheticEvent) {
		event.preventDefault();

		const { setSort, sort } = this.props;
		const newSortType = sort === NEWEST_FIRST ? OLDEST_FIRST : NEWEST_FIRST;

		setSort(newSortType);
	}

	renderMySavedReports() {
		const { sort } = this.props;
		const { reportListHeader, reportListButtons } = this.styles;

		const dateSortDirection = sort === NEWEST_FIRST ? "up" : "down";
		const sortIcon = sort === ALPHA ? "sort-alpha-down" : "sort-alpha-up";

		return new AccordionElement(
			<div style={ reportListHeader }>
				My Saved Reports
				<div style={ reportListButtons }>
					<span onClick={ this.handleSetAlphaSort }>
						<Icon name={ sortIcon } />
					</span>
					<span onClick={ this.handleSetDateSort }>
						<Icon name="calendar" sortDirection={ dateSortDirection }/>
					</span>
				</div>
			</div>
			, <SavedReports/>);
	}

	renderReport() {
		if (!this.props.activeHealthReport) {
			return undefined;
		}

		return (
			<DeviceHealthReport
				sort={this.state.columnSort}
				sortDirection={this.state.columnSortDirection}
			/>
		);
	}

	renderReportComponents() {
		return new AccordionElement("Report Components", (
			<ReportComponents />
		));
	}

	renderRightContent() {
		if (!this.props.activeHealthReport || !this.state.rightColumnVisible) {
			return undefined;
		}

		return (
			<HealthPagePropertiesPanel
				editDevicesCallback={this.setDevicesActive}
			/>
		)
	}

	getBatchOperations() {
		const { reports, selectedReports } = this.props;
		const numSelected = selectedReports.length;

		const batchButtons = [
			{
				disabled: numSelected === reports.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.selectAll
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.deselectAll
			},
			{
				disabled: !numSelected,
				label: "Delete Reports",
				icon: "trash",
				iconWeight: "regular" as IconWeights,
				onClick: this.handleDeleteReports,
				type: "danger" as ButtonType
			}
		];

		return {
			active: this.state.selectModeOn,
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Reports",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	handleUpdates(prevProps?: HealthPageProps) {
		const props = this.props || prevProps;
		const { activeHealthReport, removeReportNotification: removeNotification } = props;

		if (props && activeHealthReport) {
			removeNotification(activeHealthReport.uuid);

			const { devices, deviceGroups } = activeHealthReport
			const haveDevices = devices && devices.length || deviceGroups && deviceGroups.length;

			// make sure that if we are in edit step, we don't re-render the last step while
			// we're still trying to select or deselect additional devices and/or groups
			if (!haveDevices || this.state.centerContent === "devices") {
				this.setDevicesActive();
			} else {
				this.setReportActive();
			}
		}
	}

	selectAll() {
		const { reports, selectReports } = this.props;
		const uuids = reports.map((report) => report.uuid);
		selectReports(uuids);
	}

	deselectAll() {
		this.props.selectReports([]);
	}

	toggleSelectMode() {
		const { selectReports } = this.props;
		selectReports([]);
		this.setState(prevState => ({ selectModeOn: !prevState.selectModeOn }));
	}

	handleDeleteReports() {
		const { selectedReports, deleteReport } = this.props;

		Notifications.confirm(
			"Delete Health Reports",
			"Are you sure you want to delete the selected reports?",
			"Yes",
			"No",
			() => selectedReports.forEach(deleteReport)
		);

		this.toggleSelectMode();
	}

	closeReport() {
		this.props.history.push("/health");
	}

	createReport() {
		this.props.showRequestNameModal()
	}

	saveDevicesAndGroups() {
		const similarArray = (one, two) => (one.length === two.length && one.every((val, index) => val === two[index]));
		const { activeHealthReport: report, selectedDevices, selectedDeviceGroups } = this.props;

		if (this.state.centerContent === "devices" && !!report) {
			const haveDevices = report.devices && report.devices.length || report.deviceGroups && report.deviceGroups.length;
			const devicesDiffer = haveDevices
				&& !similarArray(selectedDevices.slice().sort(), (report.devices as any[]).slice().sort())
				|| !similarArray(selectedDeviceGroups.slice().sort(), (report.deviceGroups as any[]).slice().sort());

			if (!haveDevices || devicesDiffer) {
				const selectedDeviceGroupsWithoutAll = selectedDeviceGroups.filter((id) => id !== AllGroup.uuid);
				const newReport: HealthReport = Object.assign({}, report, {
					devices: selectedDevices, deviceGroups: selectedDeviceGroupsWithoutAll
				});

				this.props.updateReport(newReport).then(() => {
					this.setReportActive();
				});
			} else {
				this.setReportActive();
			}
		}
	}

	setDevicesActive() {
		if (this.state.centerContent !== "devices") {
			this.setState((prevState) => ({
				centerContent: "devices",
				leftColumnVisible: false,
				rightColumnVisible: false
			}));
		}

		const { activeHealthReport } = this.props;

		// if we have an activeHealthReport, and we have selected devices in it, select them in the UI
		// but only toggle them in the UI the first time that this component renders since we do not
		// save the health report with its updated devices until the user manually clicks "Save Devices"
		if (activeHealthReport && activeHealthReport.devices && activeHealthReport.deviceGroups) {
			const { devices, deviceGroups } = activeHealthReport;

			const reportDevices = devices.length && devices[0].hasOwnProperty("name")
				? (devices as NameUuid[]).map((d) => d.name)
				: devices as string[];
			const selectedDevices = reportDevices.length ? reportDevices : [];

			const reportGroups = deviceGroups.length && deviceGroups[0].hasOwnProperty("name")
				? (deviceGroups as NameUuid[]).map((d) => d.name)
				: deviceGroups as string[];
			const selectedGroups = reportGroups.length ? reportGroups : [];

			if (!this.haveSelectedReportDevices) {
				this.props.selectDevices(selectedDevices);
				this.props.selectDeviceGroups(selectedGroups);
				this.haveSelectedReportDevices = true;
			}
		}
	}

	setReportActive() {
		if (this.state.centerContent !== "report") {
			this.setState((prevState) => ({
				centerContent: "report",
				leftColumnVisible: true,
				rightColumnVisible: true
			}), () => {
				const report = this.props.activeHealthReport;

				if (report && report.uuid) {
					this.props.getReportResults(report.uuid);
				}
			});
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthPage);

import * as React from "react";

import { Select } from "Components/Global/Common";

const { Option } = Select;

interface IFilterDropdownProps {
	onFilterChange: (value: string) => void;
	options: string[];
	title: string;
}

export default class FilterDropdown extends React.PureComponent<IFilterDropdownProps> {
	constructor(props: IFilterDropdownProps) {
		super(props);

		this.changeFilter = this.changeFilter.bind(this);
	}

	render() {
		return (
			<Select
				allowClear={ true }
				className="connect-filter-dropdown"
				placeholder={this.props.title}
				style={{
					maxWidth: 200,
					width: 200
				}}
				onChange={ this.changeFilter }>
				{ this.props.options.map(this.renderOption) }
			</Select>
		);
	}

	renderOption(s: string, i: number) {
		return (
			<Option key={i} value={s}>{s}</Option>
		);
	}

	changeFilter(selectValue: string) {
		const value = selectValue || "";
		this.props.onFilterChange(value);
	}
}
import { WIDGET_COLORS } from "Components/Global/Constants";
import { PERMISSIONS } from "Data/Objects/Permissions";

const { MEDIA_MANAGE, DEPLOYMENTS_MANAGE, OWN_COMPANIES_EDIT } = PERMISSIONS;

export const initialWidgetLocations = { left: false, right: false, top: false };

export const WIDGET_TYPES = {
	MEDIA_USAGE: {
		name: "Media Usage",
		permissions: [ MEDIA_MANAGE ]
	},
	FUTURE_DEPLOYMENTS: {
		name: "Future Deployments",
		permissions: [ DEPLOYMENTS_MANAGE ]
	},
	USER_ACTIVITY: {
		name: "User Activity",
		permissions: [ OWN_COMPANIES_EDIT ]
	},
	WELCOME_VIDEO: {
		name: "Welcome Video",
		permissions: []
	}
}

export const WIDGET_CATEGORIES = {
	"Media Tools": {
		icon: "image",
		color: WIDGET_COLORS.media,
		widgets: [ WIDGET_TYPES.MEDIA_USAGE ]
	},
	"Ad Tools": {
		icon: "th-large",
		color: WIDGET_COLORS.ad,
		widgets: []
	},
	"Deployment Tools": {
		icon: "rocket",
		color: WIDGET_COLORS.deployment,
		widgets: [ WIDGET_TYPES.FUTURE_DEPLOYMENTS ]
	},
	"Device Tools": {
		icon: "tablet",
		color: WIDGET_COLORS.device,
		widgets: []
	},
	"Admin Tools": {
		icon: "lock",
		color: WIDGET_COLORS.admin,
		widgets: [ WIDGET_TYPES.USER_ACTIVITY ]
	},
	"Misc Tools": {
		icon: "globe",
		color: WIDGET_COLORS.misc,
		widgets: [ WIDGET_TYPES.WELCOME_VIDEO ]
	}
};
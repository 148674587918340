import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { Context, CustomCSS, IPlaylist, NameUuid } from "@connect/Interfaces";
import { Button, Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { setSelectActionsModal } from "Data/Actions/UI/Modals";
import { updatePlaylistAsync } from "Data/Actions/Playlists";
import { getActivePlaylist } from "Data/Selectors/Playlists";

interface PlaylistActionSetViewProps {
	playlist: IPlaylist;
	showSelectActionsModal: () => void;
	updatePlaylist: (playlist: IPlaylist) => void;
}

const mapStateToProps = (state, ownProps) => ({
	playlist: getActivePlaylist(state)
});

const mapDispatchToProps = (dispatch) => ({
	showSelectActionsModal: () => dispatch(setSelectActionsModal(true, Context.PLAYLISTS)),
	updatePlaylist: (playlist: IPlaylist) => dispatch(updatePlaylistAsync(playlist))
});

const styles = {
	actionListContainer: {
		textAlign: "center",
		cursor: "default",
		userSelect: "none"
	},
	actionListItem: {
		width: "100%",
		padding: 5,
		backgroundColor: Colors.filters.active,
		marginTop: 5,
		marginBottom: 5,
		display: "flex"
	},
	actionListItemTitle: {
		textAlign: "left",
		flex: 8
	},
	actionListItemDelete: {
		textAlign: "center",
		cursor: "pointer",
		flex: 1
	},
	actionListItemEdit: {
		textAlign: "center",
		cursor: "pointer",
		flex: 1
	}
} as CustomCSS;

export class PlaylistActionSetView extends React.Component<PlaylistActionSetViewProps, null> {
	constructor(props: PlaylistActionSetViewProps) {
		super(props);

		this.handleRemoveActionSet = this.handleRemoveActionSet.bind(this);
		this.handleShowSelectActionsModal = this.handleShowSelectActionsModal.bind(this);
	}

	render() {
		const { actionSet } = this.props.playlist;
		const hasActionSet = !!actionSet;

		return (
			<div style={styles.actionListContainer}>
				{ this.renderActionSet() }
				<Button
					color={ Colors.yellow }
					className="dark-hover-button"
					icon="running"
					onClick={ this.handleShowSelectActionsModal }
					key={ String(actionSet && actionSet.uuid) }
				>
					{ `${ hasActionSet ? "Change" : "Add" } Action Set` }
				</Button>
			</div>
		);
	}

	renderActionSet() {
		let { actionSet } = this.props.playlist;

		if (!actionSet) {
			return null;
		}

		const path = `/actions/${ actionSet.uuid }`;

		return (
			<div style={styles.actionListItem}>
				<div style={styles.actionListItemTitle}>
					<a href={ path }>
						{ this.props.playlist.actionSet!.name }
					</a>
				</div>
				<div style={styles.actionListItemDelete}>
					<Icon
						fixedWidth={true}
						name="times"
						onClick={() => {
							this.handleRemoveActionSet(actionSet!)
						} }
					/>
				</div>
			</div>
		);
	}

	handleRemoveActionSet(actionSet: NameUuid) {
		const { playlist } = this.props;

		const newPlaylist = update(playlist, {
			actionSet: { $set: null }
		});

		this.props.updatePlaylist(newPlaylist);
	}

	handleShowSelectActionsModal() {
		this.props.showSelectActionsModal();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistActionSetView);
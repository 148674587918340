import * as React from "react";
import { connect } from "react-redux";

import { ComponentsViewTypes, CustomCSS, TriggerType, Sorts } from "@connect/Interfaces";
import ComponentsView from "Components/Actions/ComponentsView";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import { Button } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { RequestNameTypes } from "Components/Global/RequestNameModal";
import { setRequestNameModal as setRequestNameModalState } from "Data/Actions/UI/Modals";
import { getUsedTriggers } from "Data/Selectors/ActionBuilder";
import { getActiveSorts } from "Data/Selectors/UI";

const mapDispatchToProps = (dispatch) => ({
	setRequestNameModal: (value: boolean) => dispatch(setRequestNameModalState(value, RequestNameTypes.ACTION))
});

const mapStateToProps = (state) => {
	return {
		actionsSortType: getActiveSorts(state, Sorts.ACTIONS),
		disabledTriggers: getUsedTriggers(state)
	}
};

export interface IActionsPageSidebarProps {
	activeUuid?: string;
	disabledTriggers: TriggerType[];
	onCreateNewAction?: () => void;
	setRequestNameModal: (value: boolean) => null;
}

export class ActionsPageSidebar extends React.Component<IActionsPageSidebarProps, null> {
	constructor(props: IActionsPageSidebarProps) {
		super(props);

		this.styles = {
			componentListHeader: {
				color: Colors.lightGray,
				textAlign: "center",
				fontSize: ".9em",
				marginBottom: 10,
				width: "100%",
				height: "100%"
			}
		}

		this.showNewActionModal = this.showNewActionModal.bind(this);
	}

	styles: {
		componentListHeader: CustomCSS;
	};

	render() {
		const { ACTION, TRIGGER } = ComponentsViewTypes;

		return (
			<Accordion
				header={ this.renderHeader() }
				elements={[
					this.renderListElement(TRIGGER, "Trigger Components"),
					this.renderListElement(ACTION, "Action Components")
				]}
			/>
		);
	}

	renderListElement(type: ComponentsViewTypes, title: string) {
		return new AccordionElement(title, (
			<React.Fragment>
				{ this.renderListHeader() }
				<ComponentsView
					disabledTriggers={ this.props.disabledTriggers }
					type={ type }
				/>
			</React.Fragment>
		));
	}

	renderListHeader() {
		return (
			<div style={ this.styles.componentListHeader }>
				Drag desired component onto action layout
			</div>
		);
	}

	renderHeader() {
		return (
			<Button
				type="primary"
				icon="plus-circle"
				fluid
				corners="rounded"
				onClick={ this.showNewActionModal }
			>
				New Action
			</Button>
		);
	}

	showNewActionModal() {
		this.props.setRequestNameModal(true);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPageSidebar);

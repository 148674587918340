import * as moment from "moment";
import { createSelector } from "reselect";

import { ADS_SORT_ARGS, API_SORT, DEPLOY_SORT_ARGS, MEDIA_SORT_ARGS, MediaFilterTypes,
	MediaType } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { AsyncState, AsyncStates, UIState } from "Data/Objects/AppState";
import { getUIState } from "Data/Selectors/UI";
import { identityReducer } from "Data/Utils";

const { getApiSort } = Utils;

export function getAdsAsync(asyncStates: AsyncStates): AsyncState {
	return asyncStates.ads;
}

export function getDeployAsync(asyncStates: AsyncStates): AsyncState {
	return asyncStates.deployments;
}

export function getHealthReportsAsync(asyncStates: AsyncStates): AsyncState {
	return asyncStates.healthReport;
}

export function getAdsAsyncQuery(sort: API_SORT, withAnalytics: boolean): string {
	const hasFilters = (sort && sort !== "-created_at") || withAnalytics;
	let asyncState = "ads";

	if (hasFilters) {
		let filterState = "";

		if (sort) {
			filterState += `${sort}.`;
		}
		if (withAnalytics) {
			filterState += "analytics";
		}

		asyncState += `.${filterState}`;
	}

	// remove any hanging separators
	return asyncState
		.split(".")
		.filter(Boolean)
		.join(".");
}

export function getAnalyticsAsyncQuery(dateFilter: string, uuidFilter: string): string {
	const hasFilters = !!dateFilter;
	let asyncState = "analytics";

	if (hasFilters) {
		let filterState = "";

		if (uuidFilter) {
			filterState += `${ uuidFilter }.`;
		}
		if (dateFilter) {
			filterState += `${ dateFilter }.`;
		}

		asyncState += `.${ filterState }`;
	}

	// remove any hanging separators
	return asyncState
		.split(".")
		.filter(Boolean)
		.join(".");
}

export function getAsyncNestedState(state: AsyncStates, query: string) {
	let splitQuery = query.split(".").filter(Boolean);
	const parentState = splitQuery.shift();
	const parentAsyncState = parentState ? state[parentState] : {} as AsyncState;

	if (splitQuery.length === 0) {
		return parentAsyncState;
	}

	const nestedQuery = splitQuery.filter(Boolean).join(".");
	const nestedState = parentAsyncState.query ? parentAsyncState.query[nestedQuery] : undefined;

	if (!nestedState) {
		return new AsyncState();
	}

	return nestedState;
}

export function getAsyncUIState(state: UIState): AsyncStates {
	return state.asyncState;
}

export function getCanFetch(asyncState: AsyncState) {
	const { currentlyFetching, haveAllData, query } = asyncState;
	const canFetchInit = !currentlyFetching && !haveAllData;

	if (!query || !Object.keys(query).length) {
		return canFetchInit;
	}

	return Object.keys(query).reduce((canFetchAll, key) => {
		const state = query[key];
		return canFetchAll && !state.currentlyFetching && !state.haveAllData;
	}, canFetchInit);
}

export function getLastFetchAll(state: AsyncState) {
	const { lastFetch, query } = state;
	const q = query || {};

	return Object.keys(q).reduce((oldestFetch, slice) => {
		const queryState = q[slice];
		const currentFetch = queryState && queryState.lastFetch;

		if (!currentFetch) {
			return oldestFetch;
		}

		return moment(oldestFetch).isBefore(currentFetch) ? oldestFetch : currentFetch;
	}, lastFetch);
}

export function getActionsAsync(asyncStates: AsyncStates): AsyncState {
	return asyncStates.actions;
}

export function getMediaAsync(asyncStates: AsyncStates): AsyncState {
	return asyncStates.media;
}

export function getAnalyticsAsync(asyncStates: AsyncStates): AsyncState {
	return asyncStates.analytics;
}

export function getAnalyticsAsyncQueries(state: AsyncState) {
	return state.query;
}

export function getMediaAsyncQuery(favorite: boolean, sort: API_SORT, mediaType?: MediaType): string {
	const hasFilters = (sort && sort !== "-created_at") || mediaType || favorite;
	let asyncState = "media";

	if (hasFilters) {
		let filterState = "";

		if (sort) {
			filterState += `${sort}.`;
		}
		if (mediaType) {
			filterState += `${mediaType}.`;
		}
		if (favorite) {
			filterState += "favorite";
		}

		asyncState += `.${filterState}`;
	}

	// remove any hanging separators
	return asyncState
		.split(".")
		.filter(Boolean)
		.join(".");
}

export function getDeployAsyncQuery(filter: string, type: string, sort: API_SORT): string {
	const hasFilters = (sort && sort !== "-created_at") || filter || type;
	let asyncState = "deployments";

	if (hasFilters) {
		let filterState = "";

		if (sort) {
			filterState += `${sort}.`;
		}
		if (filter) {
			filterState += `${filter}.`;
		}
		if (type) {
			filterState += `${type}.`;
		}

		asyncState += `.${filterState}`;
	}

	return asyncState
		.split(".")
		.filter(Boolean)
		.join(".");
}

export function getMediaFavorite(filterSort: MEDIA_SORT_ARGS): boolean {
	return filterSort.filterType === MediaFilterTypes.FAVORITES;
}

export function getRangeFilter(dateRange: string) {
	return dateRange;
}

export function getUuidFilter(uuid: string) {
	return uuid;
}

export function getMediaFilter(filterSort: MEDIA_SORT_ARGS): MediaType | undefined {
	const { VIDEOS, IMAGES, BANNERS, UNUSED } = MediaFilterTypes;
	const { filterType } = filterSort;
	let filter;

	switch (filterType) {
		case BANNERS:
			filter = "banner";
			break;
		case IMAGES:
			filter = "image";
			break;
		case VIDEOS:
			filter = "video";
			break;
		case UNUSED:
			filter = "unused";
			break;
	}

	return filter;
}

export function getWithAnalytics(filterSort: ADS_SORT_ARGS): boolean {
	return filterSort.withAnalytics;
}

export function getDeployFilter(filterSort: DEPLOY_SORT_ARGS): string {
	return filterSort.filterType.status;
}

export function getDeployType(filterSort: DEPLOY_SORT_ARGS): string {
	return filterSort.filterType.type;
}

export const getAsyncState = createSelector(
	[ getUIState ],
	getAsyncUIState
);

export const getAdsAsyncState = createSelector(
	[ getAsyncState ],
	getAdsAsync
);

export const getDeployAsyncState = createSelector(
	[ getAsyncState ],
	getDeployAsync
);

export const getHealthReportsAsyncState = createSelector(
	[ getAsyncState ],
	getHealthReportsAsync
);

export const getAdsAsyncQueryState = createSelector(
	[ getApiSort, getWithAnalytics ],
	getAdsAsyncQuery
);

export const getAdsLastFetchAll = createSelector(
	[ getAdsAsyncState ],
	getLastFetchAll
);

export const getDeployLastFetchAll = createSelector(
	[ getDeployAsyncState ],
	getLastFetchAll
);

export const getHealthReportsLastFetchAll = createSelector(
	[ getHealthReportsAsyncState ],
	getLastFetchAll
);

export const getAnalyticsAsyncState = createSelector(
	[ getAsyncState ],
	getAnalyticsAsync
);

export const getActionsAsyncState = createSelector(
	[ getAsyncState ],
	getActionsAsync
);

export const getMediaAsyncState = createSelector(
	[ getAsyncState ],
	getMediaAsync
);

export const getMediaAsyncQueryState = createSelector(
	[ getMediaFavorite, getApiSort, getMediaFilter ],
	getMediaAsyncQuery
);

export const getAdsCanFetch = createSelector(
	[ getActionsAsyncState ],
	getCanFetch
);

export const getMediaCanFetch = createSelector(
	[ getMediaAsyncState ],
	getCanFetch
);

export const getDeploymentsLastFetchAll = createSelector(
	[ getDeployAsyncState ],
	getLastFetchAll
);

export const getAnalyticsLastFetchAll = createSelector(
	[ getAnalyticsAsyncState ],
	getLastFetchAll
);

export const getAnalyticsQueries = createSelector(
	[ getAnalyticsAsyncState ],
	getAnalyticsAsyncQueries
);

export const getMediaLastFetchAll = createSelector(
	[ getMediaAsyncState ],
	getLastFetchAll
);

export const getNestedAsyncState = createSelector(
	[ getAsyncState, identityReducer ],
	getAsyncNestedState
);

export const getDeployAsyncQueryState = createSelector(
	[ getDeployFilter, getDeployType, getApiSort ],
	getDeployAsyncQuery
);

import { ButtonType } from "antd/lib/button/button";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { push } from "react-router-redux";

import { ActionSet, ActiveUuidRoute, Context, CustomCSS, ICompany, SortTypes, Sorts } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import ActionsContentArea from "Components/Actions/ActionsContentArea";
import ActionSetBuilder from "Components/Actions/ActionSetBuilder";
import ActionsPagePropertiesPanel from "Components/Actions/ActionsPagePropertiesPanel";
import ActionsPageSidebar from "Components/Actions/ActionsPageSidebar";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import { IconWeights } from "Components/Global/Icon";
import { RequestNameTypes } from "Components/Global/RequestNameModal";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import { deleteActionSetsAsync, getActionSetAsync } from "Data/Actions/ActionsAsync";
import { setRequestNameModal as setRequestNameModalState } from "Data/Actions/UI/Modals";
import { setActiveSearch, setActiveSelection, setActiveSort, setActiveUuid } from "Data/Actions/UI";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveActionSet, getAllActions } from "Data/Selectors/Actions";
import { getActiveCompany } from "Data/Selectors/Company";
import {
	getActiveSearch,
	getActiveSelection,
	getActiveUuid
} from "Data/Selectors/UI";

const { PAGE, MODAL } = Context;
const getContext = (props: ActionsPageProps) => props.context || PAGE;

const mapStateToProps = (state) => {
	const activeUuid = getActiveUuid(state, "actions");
	const searchText = getActiveSearch(state, "actions");
	return {
		actions: getAllActions(state),
		activeCompany: getActiveCompany(state),
		activeAction: getActiveActionSet(state),
		activeUuid,
		searchText,
		selectedActions: getActiveSelection(state, "actions")
	};
};

const mapDispatchToProps = (dispatch) => ({
	deleteActions: (actionSets: ActionSet[]) => dispatch(deleteActionSetsAsync(actionSets)),
	fetchAction: (uuid: string) => dispatch(getActionSetAsync(uuid)),
	pushToActionsPage: (state?: { selectModeOn: boolean }) => dispatch(push({
		pathname: "/actions",
		state
	})),
	selectActions: (actions: string[]) => dispatch(setActiveSelection("actions", actions)),
	resetActiveAction: (id: string) => dispatch(setActiveUuid("actions", id)),
	setRequestNameModal: (value: boolean) => dispatch(setRequestNameModalState(value, RequestNameTypes.ACTION)),
	setSearch: (query: string) => dispatch(setActiveSearch("actions", query)),
	setAdsSort: (sort: SortTypes) => dispatch(setActiveSort(Sorts.ADS, sort))
});

interface ActionsPageProps extends RouteComponentProps<ActiveUuidRoute> {
	activeAction: ActionSet;
	activeCompany: ICompany;
	activeUuid: string;
	actions: ActionSet[];
	context?: Context;
	deleteActions: (actionSets: ActionSet[]) => void;
	fetchAction: (uuid: string) => void;
	onCloseModal?: () => void;
	onActionSelected?: (action: ActionSet) => void;
	pushToActionsPage: (state?: { selectModeOn: boolean }) => void;
	searchText: string;
	selectActions: (actions: string[]) => void;
	selectedActions: string[];
	resetActiveAction: (id: string) => void;
	setRequestNameModal: (value: boolean) => null;
	setSearch: (value: string) => void;
	setActionsSort: (sort: SortTypes) => void;
}

interface ActionsPageState {
	selectModeOn: boolean;
}

export class ActionsPage extends React.Component<ActionsPageProps, ActionsPageState> {
	constructor(props: ActionsPageProps) {
		super(props);

		const { location } = props;
		const selectModeOn = location && location.state && location.state.selectModeOn || false;

		this.state = {
			selectModeOn
		};

		this.styles = {
			gallery: {
				position: "relative"
			}
		};

		this.deselectAllActions = this.deselectAllActions.bind(this);
		this.getBatchOperations = this.getBatchOperations.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.maybeResetPage = this.maybeResetPage.bind(this);
		this.promptDeleteAction = this.promptDeleteAction.bind(this);
		this.renderActionsGallery = this.renderActionsGallery.bind(this);
		this.renderCenterContent = this.renderCenterContent.bind(this);
		this.renderLeftContent = this.renderLeftContent.bind(this);
		this.renderRightContent = this.renderRightContent.bind(this);
		this.selectAllActions = this.selectAllActions.bind(this);
		this.showGallery = this.showGallery.bind(this);
		this.showNewActionModal = this.showNewActionModal.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		this.maybeResetPage();
	}

	componentWillReceiveProps(nextProps: ActionsPageProps, nextState: ActionsPageState) {
		const { location } = nextProps;
		const selectModeOn = location && location.state && location.state.selectModeOn;

		if (selectModeOn && selectModeOn !== this.state.selectModeOn) {
			this.setState(() => ({ selectModeOn }));
		}

		this.maybeResetPage(nextProps);
	}

	componentWillUnmount() {
		const { activeUuid, location, resetActiveAction } = this.props;
		if (activeUuid && location.pathname.indexOf("actions") === -1) {
			resetActiveAction("");
		}
	}

	render() {
		if (!hasPermission(PERMISSIONS.ACTIONS_MANAGE)) {
			return null;
		}

		return (
			<React.Fragment>
				<ThreeColumnLayout
					leftContent={ this.renderLeftContent() }
					centerContent={ this.renderCenterContent() }
					rightContent={ this.renderRightContent() }
				/>
			</React.Fragment>
		);
	}

	renderCenterContent() {
		if (this.showGallery()) {
			return this.renderActionsGallery();
		}

		return <ActionSetBuilder />
	}

	renderLeftContent() {
		if (this.showGallery()) {
			return undefined;
		}

		return (
			<ActionsPageSidebar />
		);
	}

	renderActionsGallery() {
		const { onCloseModal, onActionSelected, searchText } = this.props;

		return (
			<div style={ this.styles.gallery }>
				<ContentAreaTopBar
					batch={ this.getBatchOperations() }
					closeButton={onCloseModal}
					sort={{
						dataType: Sorts.ACTIONS
					}}
					search={{
						filterText: searchText,
						onSearchChange: this.handleSearchChange
					}}
				/>
				<ActionsContentArea
					context={ getContext(this.props) }
					selectModeOn={ this.state.selectModeOn }
					onCreateAction={ this.showNewActionModal }
					onActionSelected={ onActionSelected }
				/>
			</div>
		)
	}

	renderRightContent() {
		if (this.showGallery()) {
			return undefined;
		}

		return (
			<ActionsPagePropertiesPanel />
		);
	}

	deselectAllActions() {
		this.props.selectActions([]);
	}

	getBatchOperations() {
		if (getContext(this.props) !== PAGE) {
			return undefined;
		}

		const { actions, selectedActions } = this.props;
		const numSelected = selectedActions && selectedActions.length;

		const batchButtons = [
			{
				disabled: numSelected === actions.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.selectAllActions
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.deselectAllActions
			},
			{
				disabled: !numSelected,
				label: "Delete Actions",
				icon: "trash",
				iconWeight: "regular" as IconWeights,
				onClick: this.promptDeleteAction,
				type: "danger" as ButtonType
			}
		];

		return {
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Actions",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	handleSearchChange(value: string) {
		this.props.setSearch(value);
	}

	maybeResetPage(nextProps?: ActionsPageProps) {
		const props = nextProps || this.props;
		const { activeAction, activeUuid, fetchAction, match } = props;

		// we want to make sure we actually have an activeUuid e.g. when deleting a action
		// an incoming match.params will not have one but it will potentially still be in props
		if (activeUuid && match && match.params.activeUuid && !activeAction) {
			fetchAction(activeUuid);
		}
	}

	promptDeleteAction() {
		const { actions, deleteActions, selectedActions, selectActions } = this.props;
		const noun = selectedActions.length && selectedActions.length === 1 ? "action" : "actions";
		const actionSets = actions.filter((a) => selectedActions.includes(a.uuid));

		// confirm that user wants to delete all selected actions
		Notifications.confirm(`Delete selected ${noun}?`,
			`Are you sure you want to delete the selected ${noun}? ` +
		`The ${noun} will be removed from any deployments they may have been part of.`,
			"Delete", "Cancel", () => {
				deleteActions(actionSets);
				selectActions([]);
			});
	}

	selectAllActions() {
		const { actions, selectActions } = this.props;

		selectActions(actions.map(({ uuid }) => uuid));
	}

	showGallery() {
		return !this.props.activeAction || getContext(this.props) === MODAL;
	}

	showNewActionModal() {
		this.props.setRequestNameModal(true);
	}

	toggleSelectMode() {
		const { activeUuid, pushToActionsPage, selectActions } = this.props;
		const newSelectMode = { selectModeOn: !this.state.selectModeOn };

		selectActions([]);

		if (activeUuid) {
			pushToActionsPage(newSelectMode);
		} else {
			this.setState(() => (newSelectMode));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPage);

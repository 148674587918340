import * as update from "immutability-helper";
import { LOCATION_CHANGE, LocationChangeAction } from "react-router-redux";

import { Action, ApkDispatch, ConnectedStatusDispatch, FeatureToggleConfigDispatch, RedirectDispatch,
	SetUnsavedDispatch, VimeoVideosDispatch, PusherCredDispatch, Credential } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { SystemState } from "Data/Objects/AppState";
import { createReducer } from "Data/Utils";
import { envSwitch } from "@connect/Env";

export function setLocationState(state: SystemState, action: LocationChangeAction) {
	const previousLocation = state.location ? state.location.current : {};

	return update(state, {
		location: {
			current: { $set: action.payload },
			previous: { $set: previousLocation }
		}
	});
}

export function setRedirect(state: SystemState, action: Action<RedirectDispatch>) {
	return update(state, {
		redirect: { $set: action.args.location }
	});
}

export function setVimeoVideos(state: SystemState, action: Action<VimeoVideosDispatch>) {
	return update(state, {
		videos: { $set: action.args.videos }
	});
}

export function setConnectedStatus(state: SystemState, action: Action<ConnectedStatusDispatch>) {
	return update(state, {
		connected: { $set: action.args.connected }
	});
}

export function setFeatureToggles(state: SystemState, action: Action<FeatureToggleConfigDispatch>) {
	return update(state, {
		features: { $set: action.args.config }
	});
}

export function setUnsaved(state: SystemState, action: Action<SetUnsavedDispatch>) {
	const { section, value } = action.args;
	const newValue = value ? "Saving..." : "All changes saved";

	if (!state.unsaved[section]) {
		return state;
	}

	return update(state, {
		unsaved: {
			[section]: { $set: newValue }
		}
	});
}

export function setCurrentApk(state: SystemState, action: Action<ApkDispatch>) {
	const { version } = action.args;

	return update(state, {
		currentApk: {
			$set: version
		}
	});
}

export function setPusherCredentials(state: SystemState, action: Action<PusherCredDispatch>) {
	const { key } = action.args

	const DEV_CREDENTIALS: Credential = {
		appId: 297021,
		cluster: "mt1",
		key: key,
		secret: "0dbaa3667cb23e3bd37b"
	}

	const STAGE_CREDENTIALS: Credential = {
		appId: 297022,
		cluster: "mt1",
		key: key,
		secret: "af18118f2f9a609c3614"
	}

	const PROD_CREDENTIALS: Credential = {
		appId: 297023,
		cluster: "mt1",
		key: key,
		secret: "93961721275935219be0"
	}

	const currentCredentials: Credential = envSwitch(
		PROD_CREDENTIALS,
		STAGE_CREDENTIALS,
		DEV_CREDENTIALS
	);

	return update(state, {
		pusherCredentials: {
			$set: currentCredentials
		}
	});
}

const {
	SET_VIMEO_VIDEOS,
	SET_CONNECTED_STATUS,
	SET_FEATURE_TOGGLES,
	SET_REDIRECT,
	SET_UNSAVED,
	SET_CURRENT_APK_VERSION,
	SET_PUSHER_CREDENTIALS
} = ACTION_TYPES.System;

const reducers = {
	[LOCATION_CHANGE]: setLocationState,
	[SET_VIMEO_VIDEOS.type]: setVimeoVideos,
	[SET_REDIRECT.type]: setRedirect,
	[SET_CONNECTED_STATUS.type]: setConnectedStatus,
	[SET_FEATURE_TOGGLES.type]: setFeatureToggles,
	[SET_UNSAVED.type]: setUnsaved,
	[SET_CURRENT_APK_VERSION.type]: setCurrentApk,
	[SET_PUSHER_CREDENTIALS.type]: setPusherCredentials
};

export default createReducer(reducers, SystemState);
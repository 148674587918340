import { notifyBugSnagAsync } from "@connect/BugSnag";
import { DeviceError, PaginatedDataResult } from "@connect/Interfaces";
import { Api } from "Api/Api";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import {
	setDeviceClearCacheStatus,
	setDeviceDatabaseStatus,
	setDeviceErrors,
	setDeviceLastSeen,
	setDeviceRebooting,
	setDeviceScreenshotStatus,
	setDeviceSoftwareUpdating,
	setDeviceUpdatingAds,
	setAsyncState,
	setAsyncFetching,
	setPingStatus
} from "Data/Actions/UI";
import DeviceApiV2 from "Api/Devices";
import { getState } from "@connect/Data";
import moment = require("moment");
import { CacheInvalidationPeriod } from "Data/Objects/Global";
import { notifyBugSnag } from "@connect/BugSnag";
import { toggleFeature } from "@connect/Features";
import DevicesApi from "Api/Devices";

const devicesApi = new DevicesApi();

export function updateAds(deviceUUID: string) {
	return (dispatch) => {
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.updateAds.bind(devicesApi),
			Api.DeviceApi.updateDeviceAds.bind(Api.DeviceApi)
		);
		dispatch(setDeviceUpdatingAds(deviceUUID, true));
		apiMethod(deviceUUID)
			.then((response) => {
				// Do nothing if the request went through, wait for pusher to respond
			},
			(error) => {
				dispatch(errorNotification("Error Updating Ads", error));
				dispatch(setDeviceUpdatingAds(deviceUUID, false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(error));
			});
	}
}

export function requestDeviceDB(deviceUUID: string) {
	return (dispatch) => {
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.requestDatabase.bind(devicesApi),
			Api.DeviceApi.requestDeviceDb.bind(Api.DeviceApi)
		);
		dispatch(setDeviceDatabaseStatus(deviceUUID, true));
		apiMethod(deviceUUID)
			.then((result) => {
				// Do nothing if the request went through, wait for pusher to respond
			},
			(error) => {
				dispatch(errorNotification("Error Requesting Device Database", error));
				dispatch(setDeviceDatabaseStatus(deviceUUID, false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(error));
			});
	}
}

export function clearDeviceCacheAsync(deviceUUID: string) {
	return (dispatch) => {
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.clearCache.bind(devicesApi),
			Api.DeviceApi.requestClearCache.bind(Api.DeviceApi)
		);
		dispatch(setDeviceClearCacheStatus(deviceUUID, true));
		apiMethod(deviceUUID)
			.then((result) => {
				// Do nothing if the request went through, wait for pusher to respond
			},
			(error) => {
				dispatch(errorNotification("Error Clearing Device Cache", error));
				dispatch(setDeviceClearCacheStatus(deviceUUID, false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(error));
			});
	}
}

export function rebootDeviceAsync(deviceUUID: string) {
	return (dispatch) => {
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.rebootDevice.bind(devicesApi),
			Api.DeviceApi.rebootDevice.bind(Api.DeviceApi)
		);
		dispatch(setDeviceRebooting(deviceUUID, true));
		apiMethod(deviceUUID)
			.then((result) => {
				// Do nothing if the request went through, wait for pusher to respond
			},
			(error) => {
				dispatch(errorNotification("Error Rebooting Device", error));
				dispatch(setDeviceRebooting(deviceUUID, false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(error));
			});
	}
}

export function fetchDeviceErrorLogsAsync() {
	const { UI: { asyncState, activeUuids } } = getState();
	const deviceUUID = activeUuids.devices;
	const { currentPage, lastFetch, lastFetchedCompany: lastFetchedDevice, haveAllData, currentlyFetching}
		= asyncState.deviceErrorLog;
	const expired = moment().diff(lastFetch, "minute") > CacheInvalidationPeriod;

	// TODO: CON-5323 Update lastFetchedCompany to a more general name in the AsyncState object
	const deviceChanged = lastFetchedDevice != deviceUUID;
	const shouldFetch = (!haveAllData || deviceChanged) && !currentlyFetching;

	return (dispatch) => {

		if (!shouldFetch) {
			return Promise.resolve();
		} else if (currentPage === null || currentPage === undefined || deviceChanged || expired) {
			return dispatch(tryFetchDeviceErrorLogPage(deviceUUID, 1, true));
		} else {
			return dispatch(tryFetchDeviceErrorLogPage(deviceUUID, currentPage + 1, false));
		}
	}
}

/* eslint-disable camelcase */
export function tryFetchDeviceErrorLogPage(uuid: string, page: number, reset: boolean) {
	return (dispatch) => {

		dispatch(setAsyncFetching("deviceErrorLog", true));

		const api = new DeviceApiV2();

		return api.getDeviceErrorLogs(uuid, 25, page)
			.then((result: PaginatedDataResult<DeviceError>) => {
				const { current_page, last_page } = result.meta;
				const finalPage = current_page >= last_page;

				dispatch(setAsyncState("deviceErrorLog", finalPage, current_page));
				dispatch(setDeviceErrors(uuid, result.data, reset))
				dispatch(setAsyncFetching("deviceErrorLog", false, uuid));
			},
			(error) => {
				dispatch(setAsyncFetching("deviceErrorLog", false));
			})
			.catch((error) => {
				dispatch(setAsyncFetching("deviceErrorLog", false));
				dispatch(notifyBugSnag(new Error(error)))
			});
	}
}
/* eslint-disable camelcase */

export function requestDeviceScreenshotAsync(deviceUUID: string) {
	return (dispatch) => {
		dispatch(successNotification("Requesting Device Screenshot", "Device screenshot is being requested"));
		dispatch(setDeviceScreenshotStatus(deviceUUID, true));
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.updateScreenshot.bind(devicesApi),
			Api.DeviceApi.getDeviceScreenshot.bind(Api.DeviceApi)
		);
		apiMethod(deviceUUID)
			.then((result) => {
				// Do nothing if the request went through, wait for pusher to respond
			},
			(error) => {
				dispatch(errorNotification("Error Getting Device Screenshot", error));
				dispatch(setDeviceScreenshotStatus(deviceUUID, false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(error));
			});
	}
}

export function pingDeviceAsync(deviceUUID: string) {
	return (dispatch) => {
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.pingDevice.bind(devicesApi),
			Api.DeviceApi.pingDevice.bind(Api.DeviceApi)
		);
		dispatch(setPingStatus(deviceUUID, true));
		apiMethod(deviceUUID)
			.then((result) => {
				dispatch(setDeviceLastSeen(deviceUUID, ""));
			}, (error) => {
				dispatch(errorNotification("Error Pinging Device", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(error));
			});
	}
}

export function updateDeviceSoftwareAsync(deviceUUID: string) {
	return (dispatch) => {
		dispatch(setDeviceSoftwareUpdating(deviceUUID, true));
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.updateSoftware.bind(devicesApi),
			Api.DeviceApi.updateDeviceSoftware.bind(Api.DeviceApi)
		);
		apiMethod(deviceUUID)
			.then((result) => {
				// Do nothing if the request went through, wait for pusher to respond
			}, (error) => {
				dispatch(errorNotification("Error Updating Device Software", error));
				dispatch(setDeviceSoftwareUpdating(deviceUUID, false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(error));
			});
	}
}
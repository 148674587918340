import * as React from "react";
import { DragSource, DragSourceSpec } from "react-dnd";
import { connect } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { Card, Icon, Link } from "Components/Global/Common";
import { WIDGET_COLORS } from "Components/Global/Constants";
import MediaUsagePanel from "Components/Global/MediaUsagePanel";
import { WIDGET_TYPES } from "Data/Objects/Widgets";

const { name } = WIDGET_TYPES.MEDIA_USAGE;

const styles = {
	cardWrapper: {
		position: "relative",
		zIndex: 15
	},
	dragging: {
		opacity: 0.4,
		zIndex: 25
	},
	header: {
		paddingLeft: 8
	},
	link: {
		textAlign: "center",
		marginTop: 10
	}
} as CustomCSS;

const mapStateToProps = (state) => ({
	activeCompanyId: state.Company.activeCompanyId
});

interface IMediaUsageWidgetDragObject {
	icon: string;
	type: string;
}

interface IMediaUsageWidgetProps {
	activeCompanyId: string;
	connectDragPreview: Function;
	connectDragSource: Function;
	isDragging?: boolean;
	onDelete: () => void;
}

const cardSource: DragSourceSpec<IMediaUsageWidgetProps, IMediaUsageWidgetDragObject> = {
	beginDrag(props: IMediaUsageWidgetProps) {
		return {
			icon: "image",
			type: name
		};
	}
};

export class MediaUsageWidget extends React.Component<IMediaUsageWidgetProps> {
	constructor(props: IMediaUsageWidgetProps) {
		super(props);
	}

	render() {
		const { activeCompanyId, connectDragSource, isDragging, onDelete } = this.props;
		const style = {
			...styles.cardWrapper,
			...(isDragging ? styles.dragging : null)
		};

		return connectDragSource(
			<div style={style}>
				<Card
					header={ this.renderHeader() }
					height={ 240 }
					onClose={ onDelete }
					color={ WIDGET_COLORS.media }>
					<MediaUsagePanel
						uuid={ activeCompanyId }
						spacers={ true }
						type="circle" />
					<div style={ styles.link }>
						<Link to={"/media"}>Upload Media</Link>
					</div>
				</Card>
			</div>
		);
	}

	renderHeader() {
		return this.props.connectDragPreview((
			<h2 style={ styles.header }>
				<Icon iconWeight="regular" name="image" /> { name }
			</h2>
		), {
			captureDraggingState: true
		});
	}
}

export default connect(mapStateToProps)(DragSource("Widget", cardSource, (dndConnect, monitor) => ({
	connectDragPreview: dndConnect.dragPreview(),
	connectDragSource: dndConnect.dragSource(),
	isDragging: monitor.isDragging()
}))(MediaUsageWidget));
import Radium from "radium";
import * as React from "react";
import {
	DndComponentClass,
	DropTarget,
	DropTargetCollector,
	DropTargetConnector,
	DropTargetMonitor,
	DropTargetSpec,
	DragSourceCollector,
	DragSourceConnector,
	DragSourceMonitor,
	DragSourceSpec,
	DragSource,
	ConnectDragPreview
} from "react-dnd";
import { connect as connectToRedux } from "react-redux";

import { CustomCSS, IDevice, IDeviceGroup, Filters } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { DnDIdentifier, DropItem } from "Components/Global/GridViewCard";
import { assignDevicesAndGroupsAsync } from "Data/Actions/Devices";
import { setActiveFilters, setActiveUuid } from "Data/Actions/UI";
import { AllGroup, DeviceGroup } from "Data/Objects/Devices";
import { getAllDevices, getDeviceGroupById, getDeviceGroupParents, getDeviceGroups } from "Data/Selectors/Devices";
import { getActiveFilters, getActiveSelection } from "Data/Selectors/UI";
import { getEmptyImage } from "react-dnd-html5-backend"

const { evenMoreLightGray, filters: { hoverAlt, hoverLite }, lightestGray, selectedBlue } = Colors;

const mapDispatchToProps = (dispatch) => ({
	selectGroup: (groupId: string) => {
		dispatch(setActiveFilters("group", Filters.DEVICES, groupId));
		dispatch(setActiveUuid("deviceGroups", groupId));
	},
	assignGroup: (devices: IDevice[], groups: IDeviceGroup[], target: IDeviceGroup) =>
		dispatch(assignDevicesAndGroupsAsync(devices, groups, target))
});

const mapStateToProps = (state, ownProps) => {
	const filters = getActiveFilters(state, Filters.DEVICES);
	const allGroup = {
		name: ownProps.topLabel || AllGroup.name,
		parent: AllGroup.parent,
		uuid: AllGroup.uuid
	}
	const group = ownProps.groupId === "0" ? allGroup : getDeviceGroupById(state, ownProps.groupId);
	const deviceGroups = getDeviceGroups(state);

	return {
		activeGroup: filters.group,
		activeGroupParents: getDeviceGroupParents(state, filters.group as string),
		deviceGroups,
		selectedDeviceGroups: getActiveSelection(state, "deviceGroups"),
		selectedDevices: getActiveSelection(state, "devices"),
		devices: getAllDevices(state),
		group
	};
};

const DragCollector: DragSourceCollector<
TreeBrowserGroupCollectedProps> = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
});

const DragSpec: DragSourceSpec<TreeBrowserGroupProps, DropItem> = {
	beginDrag(props: TreeBrowserGroupProps) {
		return { data: props.group, type: "deviceGroup" };
	},
	endDrag(props: TreeBrowserGroupProps, monitor: DragSourceMonitor, component: TreeBrowserGroup) {
		if (component) {
			component.setState(() => ({ mouseDown: false }));
		}
	}
};

const DropCollector: DropTargetCollector<
TreeBrowserGroupCollectedProps> = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver()
});

const DropSpec: DropTargetSpec<TreeBrowserGroupProps> = {
	drop(props: TreeBrowserGroupProps, monitor: DropTargetMonitor, component: React.Component) {
		const dragItem = (monitor.getItem() as DropItem).data;
		const { devices, group, selectedDevices, deviceGroups, selectedDeviceGroups, selectModeOn } = props;
		let currentDevices: IDevice[] = [];
		let currentGroups: IDeviceGroup[] = [];

		if (!selectModeOn || !selectedDeviceGroups.includes(dragItem.uuid)) {
			if (DeviceGroup.isDeviceGroup(dragItem as any)) {
				currentGroups.push(dragItem as IDeviceGroup);
			} else {
				currentDevices.push(dragItem as IDevice);
			}
		} else {
			currentDevices = devices.filter((device) => selectedDevices.includes(device.uuid));
			currentGroups = deviceGroups.filter((g) => selectedDeviceGroups.includes(g.uuid));
		}

		return props.assignGroup(currentDevices, currentGroups, group);
	}
};

const GroupBrowserDraggable = DragSource<TreeBrowserGroupProps>(DnDIdentifier, DragSpec, DragCollector);

const GroupBrowserDroppable = DropTarget<TreeBrowserGroupProps>(DnDIdentifier, DropSpec, DropCollector);

interface TreeBrowserGroupCollectedProps {
	connectDropTarget?: Function;
	isOver?: boolean;
	connectDragSource?: Function;
	isDragging?: boolean;
}

interface TreeBrowserGroupProps {
	activeGroup: string;
	activeGroupParents: string[];
	connectDropTarget: Function;
	connectDragPreview: ConnectDragPreview;
	isOver: boolean;
	deviceGroups: IDeviceGroup[];
	isModalBrowser?: boolean;
	controlled?: boolean; // pass this to control the component
	selected?: boolean; // controls the UI state
	selectGroup: (groupId: string) => void;
	selectedGroup?: IDeviceGroup;
	selectedDevices: string[];
	selectedDeviceGroups: string[];
	selectedUuids: string[];
	selectModeOn: boolean;
	devices: IDevice[];
	assignGroup: (devices: IDevice[], groups: IDeviceGroup[], targetGroup: IDeviceGroup) => void;
	level?: number;
	group: IDeviceGroup;
	groupId?: string;
	topLabel?: string;
	expandedGroups: string[];
	setSelectedGroup: (group: IDeviceGroup) => void;
	onSetExpanded?: (uuid: string) => void;
	hideHover?: boolean;
	sidebar?: boolean;
	connectDragSource?: Function;
	isDragging?: boolean;
}

@Radium
export class TreeBrowserGroup extends React.Component<TreeBrowserGroupProps> {
	constructor(props: TreeBrowserGroupProps) {
		super(props);

		this.styles = {
			arrow: {
				display: "inline-block",
				textAlign: "center",
				width: 10
			},
			group: {
				cursor: "pointer",
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				width: props.sidebar || props.isModalBrowser ? "100%" : "40%",
				userSelect: "none"
			},
			hover: {
				background: props.sidebar ? hoverLite : evenMoreLightGray
			},
			icon: {
				display: "inline-block",
				paddingLeft: 5,
				width: 25
			},
			ul: {
				listStyleType: "none",
				margin: 0,
				padding: 0,
				userSelect: "none"
			},
			nameStyle: {
				background: selectedBlue,
				display: "inline",
				padding: "1px 4px 1px 0px",
				userSelect: "none"
			}
		}

		this.clickGroup = this.clickGroup.bind(this);
		this.expandGroup = this.expandGroup.bind(this);
		this.renderArrow = this.renderArrow.bind(this);
		this.renderGroup = this.renderGroup.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		const { connectDragPreview } = this.props;

		if (connectDragPreview) {
			connectDragPreview(getEmptyImage(), {
				captureDraggingState: true
			});
		}
	}

	render() {
		const { group, level } = this.props;
		if (!group) {
			return null;
		}

		return this.renderGroup(group, level);
	}

	renderArrow(arrowType: string, uuid: string, isExpanded: boolean) {
		const { arrow } = this.styles;

		if (!arrowType) {
			return (
				<div style={ arrow }>&nbsp;</div>
			);
		}

		return (
			<Icon
				name={ arrowType }
				onClick={ this.expandGroup(uuid, isExpanded) }
				style={ arrow }
			/>
		);
	}

	renderGroup(group: IDeviceGroup, level: number = 0) {
		const {
			activeGroup,
			activeGroupParents,
			connectDropTarget,
			connectDragSource,
			isDragging,
			deviceGroups,
			isModalBrowser,
			selectedGroup,
			expandedGroups,
			onSetExpanded,
			isOver,
			selectModeOn,
			setSelectedGroup,
			selected,
			selectedUuids,
			controlled,
			hideHover,
			sidebar
		} = this.props;

		const { group: groupStyle, hover, icon: iconStyle, ul, nameStyle } = this.styles;
		const { devicesCount, devicesTotal, name, parent, uuid } = group;
		const children = deviceGroups.filter((g) => g.parent === uuid);
		const key = `${level}_${uuid}_${parent}`;
		const isExpanded = expandedGroups && expandedGroups.includes(uuid);
		const isActive = !controlled ? activeGroupParents.includes(uuid) : false;
		const topLevel = uuid === "0";
		const activeBackground = !controlled ?
			(isModalBrowser ? selectedGroup && selectedGroup.uuid : activeGroup) === uuid : false;
		const hoverColor = sidebar ? hoverLite : evenMoreLightGray;
		const backgroundColor = sidebar ? hoverAlt : lightestGray;
		const hoverBackground = isOver ? hoverColor : "none";
		let renderedGroupBackground = "";
		if (!controlled) {
			if (activeBackground || selected) {
				renderedGroupBackground = backgroundColor;
			} else {
				renderedGroupBackground = hoverBackground;
			}
		}
		const renderedGroupStyle = {
			...groupStyle,
			background: renderedGroupBackground,
			border: sidebar ? null : `2px solid ${lightestGray}`,
			borderRadius: sidebar ? null : 3,
			marginBottom: sidebar ? null : 4,
			padding: `2px 2px 2px ${level + 10}px`,
			":hover": !hideHover ? hover : null
		} as CustomCSS;

		const deviceSuffix = devicesCount === 1 ? "Device" : "Devices";
		const devicesWithSuffix = sidebar ? ` (${devicesTotal || 0}) ` : ` (${devicesTotal} ${deviceSuffix}) ` ;
		const devices = topLevel ? null : devicesWithSuffix;
		let arrow = children && children.length ? "caret-right" : "";
		let icon = topLevel ? "archive" : "folder";

		if (children.length > 0 && (isActive || isExpanded)) {
			arrow = arrow ? "caret-down" : "";
			icon = "folder-open";
		}

		let renderedGroup = (
			<div
				key={ key }
				onClick={ this.clickGroup(group) }
				style={ renderedGroupStyle }
			>
				{ this.renderArrow(arrow, uuid, isExpanded) }
				<Icon
					iconWeight="regular"
					name={ icon }
					style={ iconStyle }
				/>
				<span style={ controlled && selected ? nameStyle : null }>{ name }{ devices }</span>
			</div>
		);

		if (!isModalBrowser && !isDragging) {
			renderedGroup = connectDropTarget(renderedGroup);
		}

		if (connectDragSource) {
			renderedGroup = connectDragSource(renderedGroup);
		}

		if (topLevel) {
			return renderedGroup;
		}

		const activeWithChildren = (isActive || isExpanded) && (children && children.length);
		const renderedChildGroups = activeWithChildren ? (
			<ul style={ ul }>
				{
					children.map((deviceGroup, i) =>
						<DroppableTreeBrowserGroup
							key={ "_browser_" + deviceGroup.uuid + "_parent_" + key }
							level={ level + 12 }
							groupId={ deviceGroup.uuid }
							expandedGroups={ expandedGroups }
							selectModeOn={ selectModeOn }
							onSetExpanded={ onSetExpanded }
							setSelectedGroup={ setSelectedGroup }
							selectedGroup={ selectedGroup }
							selectedUuids={ selectedUuids }
							selected={ selectedUuids && selectedUuids.includes(deviceGroup.uuid) }
							controlled={ controlled }
							hideHover={ hideHover }
							sidebar={ sidebar }
							isModalBrowser={ isModalBrowser }
						/>
					)
				}
			</ul>
		) : null;

		return (
			<li key={ `li_${key}` }>
				{ renderedGroup }
				{ renderedChildGroups }
			</li>
		);
	}

	clickGroup(group: IDeviceGroup) {
		return () => {
			const { isModalBrowser, selectGroup, setSelectedGroup } = this.props;
			const {uuid} = group;

			if (setSelectedGroup) {
				setSelectedGroup(group);
			}
			if (!isModalBrowser) {
				selectGroup(uuid)
			}
		}
	}

	expandGroup(uuid: string, isExpanded: boolean) {
		return (event) => {
			event.preventDefault();
			event.stopPropagation();

			const { onSetExpanded } = this.props;

			if (onSetExpanded) {
				onSetExpanded(uuid);
			}
		};
	}
}

export const DraggableDeviceGroup: DndComponentClass<TreeBrowserGroupProps> =
	connectToRedux(mapStateToProps, mapDispatchToProps)(GroupBrowserDraggable(TreeBrowserGroup));
export const DroppableDeviceGroup: DndComponentClass<TreeBrowserGroupProps> =
	connectToRedux(mapStateToProps, mapDispatchToProps)(GroupBrowserDroppable(DraggableDeviceGroup));

const DroppableTreeBrowserGroup = connectToRedux(mapStateToProps, mapDispatchToProps)(DroppableDeviceGroup);

export default DroppableTreeBrowserGroup;

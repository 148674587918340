import * as React from "react";
import { DropTarget, DropTargetCollector, DropTargetConnector, DropTargetMonitor, DropTargetSpec } from "react-dnd";
import { connect } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { DragTable } from "Data/Objects/DragTypes";
import { getActiveUuid, getReportIsRunning } from "Data/Selectors/UI";

const { dropBackground, gray, lightGray, lightestGray, primaryBlue } = Colors;

const mapStateToProps = (state) => {
	const activeUuid = getActiveUuid(state, "health");
	return {
		loading: getReportIsRunning(state, activeUuid)
	};
};

const reportDropCollector: DropTargetCollector<
DropColumnPlaceholderCollectedProps
> = (dropConnect: DropTargetConnector, monitor: DropTargetMonitor) => ({
	connectDropTarget: dropConnect.dropTarget(),
	isOver: monitor.isOver()
});

const reportDropTarget: DropTargetSpec<DropColumnPlaceholderProps> = {
	drop(props: DropColumnPlaceholderProps, monitor: DropTargetMonitor, component: React.Component) {
		return monitor.getItem();
	}
};

interface DropColumnPlaceholderCollectedProps {
	connectDropTarget: Function;
	isOver: boolean;
}

interface DropColumnPlaceholderProps {
	connectDropTarget: Function;
	isOver: boolean;
	loading: boolean;
	overAdjacent?: boolean;
	isFirst?: boolean;
}

export class DropColumnPlaceholder extends React.Component<DropColumnPlaceholderProps, any> {
	constructor(props: DropColumnPlaceholderProps) {
		super(props);

		this.styles = {
			dropColumn: {
				alignItems: "center",
				border: `1px dashed ${lightestGray}`,
				color: lightGray,
				display: "flex",
				flex: "1 0 120px",
				flexDirection: "column",
				justifyContent: "center",
				maxHeight: "700px",
				maxWidth: 120,
				minWidth: 60,
				position: "sticky",
				textAlign: "center",
				top: 0
			},
			dropColumnText: {
				marginTop: 20
			},
			hidden: {
				display: "none"
			},
			hover: {
				backgroundColor: dropBackground,
				borderColor: primaryBlue,
				color: gray
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { dropColumn, dropColumnText, hidden, hover } = this.styles;
		const { isOver, overAdjacent, isFirst } = this.props;
		const adjacent = overAdjacent !== undefined && !overAdjacent;

		return this.connectIfDroppable(
			<div style={{
				...(isFirst ? {...dropColumn, maxWidth: "100%"} : dropColumn),
				...(adjacent ? hidden : null),
				...(isOver ? hover : null)
			}}>
				<Icon name="plus-circle" size="small" />
				<div style={dropColumnText}>
					Drag<br />
					Component<br />
					Here
				</div>
			</div>
		);
	}

	connectIfDroppable(renderable: JSX.Element) {
		const { connectDropTarget, loading } = this.props;

		if (!loading) {
			return connectDropTarget(renderable);
		}

		return renderable;
	}
}

export default connect(mapStateToProps)(
	DropTarget([ DragTable.Component, DragTable.Column ], reportDropTarget, reportDropCollector)(DropColumnPlaceholder)
);

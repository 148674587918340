import { Switch } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { AdAnalyticsNotification, AnalyticsResult, CustomCSS, DayOption, IAd } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { Select, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import PropertiesPanel, { PropertiesPanelButton } from "Components/Global/PropertiesPanel";
import {
	getAnalyticsNotificationAsync,
	requestGetAnalyticsReportDownloadAsync,
	updateAdAsync,
	updateAnalyticsNotificationAsync
} from "Data/Actions/AdsAsync";
import { sendDays } from "Data/Objects/Reports";
import { getActiveAnalyticsNotification, getActiveAnalyticsReportPending,
	getActiveAnalyticsResult } from "Data/Selectors/AdAnalytics";
import { getActiveAd } from "Data/Selectors/Ads";
import { getActiveUuid } from "Data/Selectors/UI";
import { getAdAnalyticsNewsletterSetting } from "Data/Selectors/User";

const { gray, white } = Colors;

interface AnalyticsPagePropertiesPanelProps {
	activeAd: IAd;
	activeAnalytics: AnalyticsResult;
	activeReportId: string;
	analyticsNotification: AdAnalyticsNotification;
	analyticsNotificationsEnabled: boolean;
	deleteAnalytics: (ad: IAd) => Promise<void>;
	editDevicesCallback: () => void;
	getAnalyticsNotificationSettings: (uuid: string) => void;
	isPending: boolean;
	pushToAnalyticsPage: () => void;
	requestReportExport: (uuid: string) => void;
	updateAnalyticsNotification: (uuid: string, notification: AdAnalyticsNotification) => void;
	updateReport: (report: AnalyticsResult) => void;
}

const { Option } = Select;

const mapStateToProps = (state) => {
	const activeReportId = getActiveUuid(state, "analytics");
	const activeAd = getActiveAd(state, activeReportId);

	return {
		activeAd,
		activeAnalytics: getActiveAnalyticsResult(state),
		activeReportId,
		analyticsNotificationsEnabled: getAdAnalyticsNewsletterSetting(state),
		analyticsNotification: getActiveAnalyticsNotification(state),
		isPending: getActiveAnalyticsReportPending(state)
	};
};

const mapDispatchToProps = (dispatch) => ({
	deleteAnalytics: (ad: IAd) => dispatch(updateAdAsync(ad)),
	getAnalyticsNotificationSettings: (uuid: string) => dispatch(getAnalyticsNotificationAsync(uuid)),
	pushToAnalyticsPage: () => dispatch(push("/analytics")),
	requestReportExport: (uuid: string) => dispatch(requestGetAnalyticsReportDownloadAsync(uuid)),
	updateAnalyticsNotification: (uuid: string, analyticsNotification: AdAnalyticsNotification) =>
		dispatch(updateAnalyticsNotificationAsync(uuid, analyticsNotification))
});

export class AnalyticsPagePropertiesPanel extends React.Component<AnalyticsPagePropertiesPanelProps> {
	constructor(props: AnalyticsPagePropertiesPanelProps) {
		super(props);

		this.styles = {
			centered: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "100%"
			},
			dropdownInner: {
				margin: "3px auto 0",
				width: "80%"
			},
			dropdownRow: {
				fontSize: "0.85em",
				fontWeight: "lighter",
				marginTop: 10
			},
			information: {
				paddingBottom: 10
			},
			label: {
				display: "inline-block",
				width: 110
			},
			rowDescription: {
				color: gray,
				fontSize: "0.85em",
				textAlign: "center"
			},
			select: {
				width: "100%"
			},
			stats: {
				marginBottom: 40
			},
			title: {
				paddingBottom: 20
			},
			toggleLabel: {
				flex: 8,
				lineHeight: "24px",
				verticalAlign: "middle"
			},
			toggleRow: {
				display: "flex",
				flexDirection: "row"
			},
			toggleSwitch: {
				flex: 2
			},
			row: {
				marginBottom: 14
			},
			thumbnail: {
				border: `1px solid ${ white }`,
				margin: "20px auto 10px",
				width: "100px"
			}
		};

		this.deleteAnalytics = this.deleteAnalytics.bind(this);
		this.getAvailableActions = this.getAvailableActions.bind(this);
		this.getNotificationDataIfNeeded = this.getNotificationDataIfNeeded.bind(this);
		this.promptDeleteAnalytics = this.promptDeleteAnalytics.bind(this);
		this.renderContent = this.renderContent.bind(this);
		this.requestReport = this.requestReport.bind(this);
		this.updateAnalyticsNotificationNotification = this.updateAnalyticsNotificationNotification.bind(this);
		this.updateAnalyticsNotificationNotificationFlag = this.updateAnalyticsNotificationNotificationFlag.bind(this);
		this.updateAnalyticsNotificationNotificationWrapper = this.updateAnalyticsNotificationNotificationWrapper.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		this.getNotificationDataIfNeeded();
	}

	componentDidUpdate(prevProps: AnalyticsPagePropertiesPanelProps) {
		if (this.props.activeReportId !== prevProps.activeReportId) {
			this.getNotificationDataIfNeeded();
		}
	}

	render() {
		if (!this.props.activeAnalytics || !this.props.activeAd) {
			return null;
		}

		return (
			<PropertiesPanel
				actions={ this.getAvailableActions() }
				properties={ this.renderContent() }
				title="Ad Analytic Properties"
			/>
		);
	}

	renderContent() {
		return (
			<React.Fragment>
				{ this.renderAdInformation() }
				{ this.renderStatistics() }
				{ this.renderNotificationSettings() }
			</React.Fragment>
		);
	}

	renderDayOptions(option: DayOption) {
		const value = option.value.toString();

		return (
			<Option key={ value } value={ value }>{ option.day }</Option>
		);
	}

	renderAdInformation() {
		if (!this.props.activeAd) {
			return null;
		}

		const { information, centered, title, thumbnail } = this.styles;
		const { name, thumbnail: image } = this.props.activeAd;

		return (
			<div style={ information }>
				<img src={ image } style={{ ...centered, ...thumbnail }} />
				<div style={{ ...centered, ...title }}><Truncate length={ 26 }>{ name }</Truncate></div>
			</div>
		);
	}

	renderAdInfoLine(label: string, value: string, last?: boolean) {
		const { label: labelStyle, row } = this.styles;

		return (
			<div style={ row }>
				<span style={ labelStyle }>{ label }:</span>
				<span>{ value }</span>
			</div>
		);
	}

	renderNotificationSettings() {
		// TODO: Update this / verify that it works once CON-3979 is finished
		const { activeReportId, analyticsNotificationsEnabled, analyticsNotification } = this.props;
		const { dropdownInner, dropdownRow, rowDescription, select, toggleLabel, toggleRow, toggleSwitch } = this.styles;
		const checked = analyticsNotification !== undefined &&
			analyticsNotification !== null && analyticsNotification.enabled;
		const description = analyticsNotificationsEnabled ?
			"Configure email settings for this report."
			:
			(
				<div>
					You must enable Ad Analytics Reports in your
					<a href="/admin/emailPreferences"> Email Preferences </a>
					in order to receive emails about this report.
				</div>
			);
		const showAdditionalToggles = checked && analyticsNotificationsEnabled;
		const frequency = analyticsNotification && analyticsNotification.frequency;
		const sendDay = analyticsNotification && analyticsNotification.sendDay || 0;

		return (
			<div key={ activeReportId + "_notificationsettings" }>
				<div style={ toggleRow }>
					<div style={ toggleLabel }>
						Email Notifications
					</div>
					<div style={ toggleSwitch }>
						<Switch
							checked={ checked }
							disabled={ !analyticsNotificationsEnabled }
							onChange={ this.updateAnalyticsNotificationNotificationFlag }
						/>
					</div>
				</div>
				<div style={ rowDescription }>{ description }</div>
				{
					!showAdditionalToggles ? null : (
						<div>
							<br />
							<div style={ dropdownRow }>
								Email Frequency: <br />
								<div style={ dropdownInner }>
									<Select
										onChange={ this.updateAnalyticsNotificationNotificationWrapper("frequency") }
										style={ select }
										value={ frequency }
									>
										<Option key="daily" value="daily">Daily Digest</Option>
										<Option key="weekly" value="weekly">Weekly Digest</Option>
									</Select>
								</div>
							</div>
							<div style={{
								...dropdownRow,
								display: frequency === "daily" ? "none" : "inherit"
							}}>
								Email Day of Week: <br />
								<div style={ dropdownInner }>
									<Select
										onChange={ this.updateAnalyticsNotificationNotificationWrapper("sendDay") }
										style={ select }
										value={ sendDay.toString() }
									>
										{
											sendDays.map(this.renderDayOptions)
										}
									</Select>
								</div>
							</div>
						</div>
					)
				}
			</div>
		);
	}

	renderStatistics() {
		const { stats } = this.styles;
		const analyticsEnabled = String(this.props.activeAd.analytics);
		const { createdAt, duration, updatedAt } = this.props.activeAd;

		return (
			<div style={ stats }>
				{ this.renderAdInfoLine("Duration", Utils.getFormattedDuration(duration)) }
				{ this.renderAdInfoLine("Ad Created", Utils.getHumanReadableDate(createdAt)) }
				{ this.renderAdInfoLine("Ad Modified", Utils.getHumanReadableDate(updatedAt), true) }
				{ this.renderAdInfoLine("Analytics Enabled", Utils.getHumanReadableDate(analyticsEnabled)) }
			</div>
		);
	}

	promptDeleteAnalytics() {
		Notifications.confirm(
			`Delete ${ this.props.activeAnalytics.name }?`,
			"Are you sure you want to delete this report?",
			"Delete",
			"Cancel",
			this.deleteAnalytics
		);
	}

	deleteAnalytics() {
		const { activeAd, deleteAnalytics, pushToAnalyticsPage } = this.props;
		const updatedAd = Object.assign({}, activeAd, { analytics: null });

		deleteAnalytics(updatedAd).then(() => {
			pushToAnalyticsPage();
		});
	}

	requestReport() {
		const { requestReportExport, activeReportId } = this.props;
		requestReportExport(activeReportId);
	}

	getAvailableActions(): PropertiesPanelButton[] {
		const { activeReportId, isPending } = this.props;

		return [
			{
				action: this.requestReport,
				disabled: isPending,
				key: `${activeReportId}_download`,
				icon: { name: "download", weight: "solid" },
				name: "Request CSV"
			},
			{
				action: () => this.promptDeleteAnalytics(),
				key: `${activeReportId}_delete`,
				icon: { name: "trash-alt", weight: "regular" },
				name: "Delete Analytics",
				type: "danger"
			}
		];
	}

	getNotificationDataIfNeeded() {
		const { activeAnalytics, analyticsNotification, activeReportId, getAnalyticsNotificationSettings } = this.props;
		const haveNotifications = analyticsNotification && analyticsNotification.enabled !== undefined;

		if (!haveNotifications && activeAnalytics) {
			getAnalyticsNotificationSettings(activeReportId);
		}
	}

	updateAnalyticsNotificationNotification(setting: string, value: any) {
		const { activeReportId, analyticsNotification, updateAnalyticsNotification } = this.props;
		const updatedNotification = {};

		updatedNotification[setting] = value;

		const newNotification = Object.assign({}, analyticsNotification, updatedNotification);

		if (newNotification.frequency === "weekly" && !newNotification.sendDay) {
			newNotification.sendDay = 0;
		}

		updateAnalyticsNotification(activeReportId, newNotification);
	}

	updateAnalyticsNotificationNotificationFlag(enabled: boolean) {
		const { activeReportId, analyticsNotification, updateAnalyticsNotification } = this.props;
		const brandNewNotification = {
			enabled: true,
			frequency: "daily"
		};

		const newNotification = Object.assign({}, analyticsNotification, (analyticsNotification ? {
			enabled
		} : brandNewNotification));

		updateAnalyticsNotification(activeReportId, newNotification);
	}

	updateAnalyticsNotificationNotificationWrapper(key: any) {
		return (value: any) => {
			let determinedValue: any = value;

			if (value.target) {
				determinedValue = value.target.checked;
			}

			return this.updateAnalyticsNotificationNotification(key, determinedValue);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPagePropertiesPanel);

import createHistory from "history/createBrowserHistory";
import * as localforage from "localforage";
import { routerMiddleware, routerReducer } from "react-router-redux";
import { applyMiddleware, compose, createStore, Store } from "redux";
import { createMigrate, persistCombineReducers, Persistor, persistStore } from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import thunk from "redux-thunk";

import { pusherConnection } from "@connect/Pusher";
import { pusherConnection as pusherConnection2 } from "@connect/Pusher2";
import { AppState } from "Data/Objects/AppState";
import { dispatchableMiddleware } from "Data/Objects/DispatchableAction";
import migrations from "Data/Objects/Migrations";
import actionsReducer from "Data/Reducers/Actions";
import adminReducer from "Data/Reducers/Admin";
import adReducer from "Data/Reducers/Ads";
import companyReducer from "Data/Reducers/Company";
import deploymentReducer from "Data/Reducers/Deployment";
import deviceReducer from "Data/Reducers/Device";
import healthReportReducer from "Data/Reducers/HealthReport";
import mediaReducer from "Data/Reducers/Media";
import permissionsReducer from "Data/Reducers/Permissions";
import playlistReducer from "Data/Reducers/Playlist";
import pusherReducer from "Data/Reducers/Pusher";
import roleReducer from "Data/Reducers/Role";
import systemReducer from "Data/Reducers/System";
import uiReducer from "Data/Reducers/UI";
import userReducer from "Data/Reducers/User";
import { toggleFeature } from "@connect/Features";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";

const persistConfig = {
	key: "global",
	migrate: createMigrate(migrations),
	stateReconciler: hardSet,
	storage: localforage,
	// our current version should always be the highest numbered key in the migrations object
	// if for some reason we need to override (e.g. for testing migrations), set this key explicitly
	version: Math.max(...Object.keys(migrations).map((k) => parseInt(k, 10)))
};

let history;

if (typeof document !== "undefined") {
	history = createHistory();
}

const historyMiddleware = routerMiddleware(history);
const reduxEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = reduxEnhancer
	? reduxEnhancer({
		trace: true,
		traceLimit: 25
	})
	: compose;

const {
	CLEAR_CACHE
} = ACTION_TYPES.System;

const defaultReducer = (rootReducer) => {
	return (state, action) => {
		if (action.type === CLEAR_CACHE.type) {
			return new AppState();
		}

		return rootReducer(state, action);
	}
}

const getStore: () => Store<AppState> = () => {
	return createStore(
		defaultReducer(
			persistCombineReducers(persistConfig, {
				Actions: actionsReducer,
				Admin: adminReducer,
				Ads: adReducer,
				Company: companyReducer,
				Deployments: deploymentReducer,
				Devices: deviceReducer,
				HealthReports: healthReportReducer,
				Media: mediaReducer,
				Permissions: permissionsReducer,
				Playlists: playlistReducer,
				Pusher: pusherReducer,
				Roles: roleReducer,
				Router: routerReducer,
				System: systemReducer,
				UI: uiReducer,
				User: userReducer
			})
		),
		composeEnhancers(applyMiddleware(
			historyMiddleware,
			thunk,
			dispatchableMiddleware
		))
	);
};

const store: Store<AppState> = getStore();

const getPersistor: () => Persistor = () => {
	return persistStore(store, undefined, () => {
		if ( toggleFeature("notifications", true, false) ) {
			pusherConnection2.initializeConnection();
		} else {
			pusherConnection.initializeConnection();
		}
	});
};

const persistor: Persistor = getPersistor();

const getState = () => {
	return store.getState();
}

export {
	getPersistor,
	getState,
	getStore,
	history,
	persistor,
	store
};

import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IDevice, UIDeviceGroup } from "@connect/Interfaces";
import DeviceGroupTreeBrowser from "Components/Devices/DeviceGroupTreeBrowser";
import { Button, Header, Modal } from "Components/Global/Common";
import { assignDevicesAndGroupsAsync } from "Data/Actions/Devices";
import { setDeviceGroupTreeBrowserModal } from "Data/Actions/UI/Modals";
import { setActiveSelection } from "Data/Actions/UI";
import { AllGroup } from "Data/Objects/Devices";
import { getAllDevices, getDeviceGroups } from "Data/Selectors/Devices";
import {
	getDeviceGroupTreeBrowserModalSelection,
	getDeviceGroupTreeBrowserModalVisibility
} from "Data/Selectors/UI";
import { getActiveSelection } from "Data/Selectors/UI";

interface DeviceGroupTreeBrowserModalProps {
	visible: boolean;
	selectedDevices: string[];
	selectedGroup: string;
	devices: IDevice[];
	deviceGroups: UIDeviceGroup[];
	hideModal: () => void;
	setSelectedGroup: (group: string) => void;
	selectedDeviceGroups: string[];
	assignGroup: (devices: IDevice[], groups: UIDeviceGroup[], target: UIDeviceGroup | UIDeviceGroup) => void;
}

const mapStateToProps = (state) => ({
	devices: getAllDevices(state),
	deviceGroups: getDeviceGroups(state),
	selectedDeviceGroups: getActiveSelection(state, "deviceGroups"),
	selectedDevices: getActiveSelection(state, "devices"),
	selectedGroup: getDeviceGroupTreeBrowserModalSelection(state),
	visible: getDeviceGroupTreeBrowserModalVisibility(state)
});

const mapDispatchToProps = (dispatch) => ({
	assignGroup: (devices: IDevice[], groups: UIDeviceGroup[], target: UIDeviceGroup) =>
		dispatch(assignDevicesAndGroupsAsync(devices, groups, target)),
	hideModal: () => {
		dispatch(setDeviceGroupTreeBrowserModal(false, ""));
		dispatch(setActiveSelection("deviceGroups", []))
	},
	setSelectedGroup: (group: string) => dispatch(setDeviceGroupTreeBrowserModal(true, group))
});

export class DeviceGroupTreeBrowserModal
	extends React.Component<DeviceGroupTreeBrowserModalProps> {
	constructor(props: DeviceGroupTreeBrowserModalProps) {
		super(props);

		this.styles = {
			container: {
				maxHeight: 500,
				overflowY: "auto"
			}
		}

		this.moveSelection = this.moveSelection.bind(this);
		this.renderFooter = this.renderFooter.bind(this);
		this.setSelectedGroup = this.setSelectedGroup.bind(this);
	}

	styles: {
		container: CustomCSS;
	}

	render() {
		const { hideModal, visible } = this.props;

		return (
			<Modal
				modalKey="deviceGroupTreeBrowserModal"
				visible={ visible }
				onCancel={ hideModal }
			>
				<Header size={ 3 }>Move Selected To...</Header>
				<div style={ this.styles.container }>
					<DeviceGroupTreeBrowser
						isModalBrowser={ true }
						selectedModalGroup={ this.props.selectedGroup }
						setModalGroup={ this.setSelectedGroup }
					/>
				</div>
				{ this.renderFooter() }
			</Modal>
		);
	}

	renderFooter() {
		const { hideModal, selectedGroup } = this.props;

		return (
			<div>
				<Button onClick={ hideModal }>Cancel</Button> &nbsp;
				<Button type="primary"
					disabled={ !selectedGroup }
					onClick={ this.moveSelection }>
					Move
				</Button>
			</div>
		);
	}

	moveSelection() {
		const { devices, selectedGroup, assignGroup, hideModal,
			selectedDevices, selectedDeviceGroups, deviceGroups } = this.props;
		const currentDevices = devices.filter((device) => selectedDevices.includes(device.uuid));
		const currentGroups = deviceGroups.filter((group) => selectedDeviceGroups.includes(group.uuid));
		const [ selected ] = deviceGroups.filter((group) => group.uuid === selectedGroup);
		const targetGroup = selected || AllGroup;

		assignGroup(currentDevices, currentGroups, targetGroup);
		hideModal();
	}

	setSelectedGroup(selectedGroup: string) {
		this.props.setSelectedGroup(selectedGroup);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceGroupTreeBrowserModal);

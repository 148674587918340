import { Modal } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, Section, Subsection, Term } from "@connect/Interfaces";
import { setPrivacyPolicyModalVisibility } from "Data/Actions/UI/Modals";
import PrivacyPolicy from "Data/PrivacyPolicy";
import { getPrivacyPolicyModalVisibility } from "Data/Selectors/UI";

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => dispatch(setPrivacyPolicyModalVisibility(false))
});

const mapStateToProps = (state) => ({
	visible: getPrivacyPolicyModalVisibility(state)
});

interface PrivacyPolicyModalProps {
	closeModal: () => void;
	visible: boolean;
}

const styles = {
	term: {
		marginBottom: 10
	},
	container: {
		height: 500,
		overflowY: "auto",
		paddingRight: 20
	}
} as CustomCSS;

class PrivacyPolicyModal extends React.Component<PrivacyPolicyModalProps, {}> {
	constructor(props: PrivacyPolicyModalProps) {
		super(props);

		this.renderTerms = this.renderTerms.bind(this);
		this.renderSection = this.renderSection.bind(this);
		this.renderSubsection = this.renderSubsection.bind(this);
		this.renderTerm = this.renderTerm.bind(this);
		this.renderTermDataItem = this.renderTermDataItem.bind(this);
	}

	render() {
		const { visible, closeModal } = this.props;

		return (
			<Modal
				destroyOnClose
				onCancel={ closeModal }
				footer={ null }
				width={ 800 }
				visible={ visible }>
				<div style={ styles.container }>
					{ this.renderTerms(PrivacyPolicy) }
				</div>
			</Modal>
		);
	}

	renderTerms(sections: Section[]) {
		return sections.map(this.renderSection);
	}

	renderSection(section: Section) {
		return (
			<React.Fragment key={ section.title }>
				<h1>{ section.title }</h1>
				{ section.data.map(this.renderSubsection) }
			</React.Fragment>
		)
	}

	renderSubsection(subsection: Subsection) {
		return (
			<React.Fragment key={ subsection.subtitle }>
				<h2>{ subsection.subtitle }</h2>
				{ subsection.data.map(this.renderTerm) }
			</React.Fragment>
		);
	}

	renderTerm(term: Term, index: number) {
		if (term.type === "bullets") {
			return (
				<ul key={ term.type + "_" + index }>
					{ (term.data as string[]).map(this.renderTermDataItem) }
				</ul>
			);
		}
		return (
			<p key={ term.data as string + "_" + index } style={ styles.term }>{ term.data }</p>
		);
	}

	renderTermDataItem(item: string) {
		return (
			<li key={ item }>{ item }</li>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyModal);
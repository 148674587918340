import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { AspectRatios, CustomCSS, IAd, IFeedComponent, IVideoComponent, IMedia } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { RatioHeightPercents } from "Components/Ads/Constants";
import { Colors } from "Components/Global/Constants";
import OptionButton from "Components/Global/OptionButton";
import { updateComponent } from "Data/Actions/UI/AdBuilder";
import { getActiveAd, getAdComponentsHeight, getComponentByIndex,
	getSelectedComponentIndex } from "Data/Selectors/AdBuilder";
import { Button } from "Components/Global/Common";
import { isEqual } from "lodash";

type AspectRatioComponent = IVideoComponent | IFeedComponent;

interface AspectRatioPickerProps {
	ad: IAd;
	video?: IMedia;
	adHeightPercent: number;
	component: AspectRatioComponent;
	componentIndex: number;
	onComponentUpdated: (index: number, component: IFeedComponent) => void;
	ratios: AspectRatios[];
}

const mapStateToProps = (state) => {
	const ad = getActiveAd(state);
	const componentIndex = getSelectedComponentIndex(state);
	const component = getComponentByIndex(state, componentIndex);
	const adHeightPercent = getAdComponentsHeight(state);

	return {
		ad,
		component,
		componentIndex,
		adHeightPercent,
		livePreview: false,
		media: []
	}
};

const mapDispatchToProps = (dispatch) => ({
	onComponentUpdated: (index: number, component: AspectRatioComponent) =>
		dispatch(updateComponent(index, component, true))
});

class AspectRatioPicker extends React.Component<AspectRatioPickerProps> {
	constructor(props: AspectRatioPickerProps) {
		super(props);

		this.width = 150;
		this.height = (this.width * 9) / 16;
		this.aspectButtonWidth = 50;

		this.styles = {
			emptySpace: {
				border: "1px solid #FF0075",
				padding: "4px 6px",
				margin: "6px 0px",
				cursor: "pointer"
			},
			emptySpaceWarning: {
				color: "#DDDFE0"
			},
			row: {
				display: "flex",
				marginBottom: 2
			},
			aspectButton: {
				width: 50,
				height: 24
			},
			customAspectButton: {
				width: 100,
				height: 24
			},
			aspectButtonText: {
				fontSize: "1em"
			},
			baseRatio: {
				backgroundColor: "#4c5466",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				textAlign: "center",
				color: Colors.white,
				fontSize: ".85em"
			},
			bestFit: {
				width: "90%"
			},
			bestFitWrapper: {
				display: "block",
				textAlign: "center",
				margin: "10px 0px"
			},
			sixteenNine: {
				width: this.width,
				height: this.height
			},
			nineSixteen: {
				width: this.height * (9 / 16),
				height: this.height
			},
			fourThree: {
				width: this.width * (3 / 4),
				height: this.height
			},
			oneOne: {
				width: this.height,
				height: this.height
			},
			thumbContainer: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			border: {
				width: this.width,
				height: this.height,
				border: "2px dashed #484b55",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			},
			ratioList: {
				display: "flex",
				justifyContent: "center",
				marginTop: 16,
				marginBottom: 14
			},
			warning: {
				color: "#888888",
				textAlign: "center",
				fontSize: ".85em"
			}
		}

		this.getAspectPercent = this.getAspectPercent.bind(this);
		this.handleRequestChangeAspect = this.handleRequestChangeAspect.bind(this);
		this.handleBestFit = this.handleBestFit.bind(this);
	}

	width: number;
	height: number;
	aspectButtonWidth: number;

	styles: CustomCSS;

	componentDidUpdate(prevProps) {
		const { video } = this.props;

		if (!video || isEqual(video, prevProps.video)) {
			return null
		}

		return this.handleBestFit();
	}

	render() {
		const { component } = this.props;

		if (!component) {
			return null;
		}

		const isCustomRatio = this.getAspectPercent() !== component.height.value;

		const { thumbContainer, border, ratioList, row, warning } = this.styles;

		const thumb = (
			<div style={ thumbContainer }>
				<div style={ border }>
					{ this.renderAspectThumb(isCustomRatio) }
				</div>
			</div>
		);

		return (
			<div>
				{ this.renderVideoContainsEmptySpace() }
				<div style={ row }>
					Aspect Ratio:
				</div>
				{ this.props.video ? null : thumb }
				<div style={ ratioList }>
					{ this.renderAspectRatios() }
				</div>
				<div style={{...row, ...warning }}>
					{ component.aspectRatio !== AspectRatios.SIXTEEN_NINE && component.type === "feed"
						? `Cropped Video: The camera will still output the full
						16:9 image. Only the image shown on the screen will be cropped.` : null }
				</div>
			</div>
		);
	}

	renderAspectRatios() {
		const { component, ratios, ad } = this.props;
		const { id, height } = component;
		const media = ad.layout.media.filter((m) => m.layoutPosition === id);

		if (media && media.length && component.type !== "feed" && component.type !== "video") {
			return null;
		}

		const aspectRatio = this.getAspectRatio();
		const isCustomRatio = this.getAspectPercent() !== height.value;
		const { aspectButtonText, aspectButton, customAspectButton } = this.styles;
		let ratioButtons = ratios.map((ratio, index) => (
			<OptionButton rounded
				size="small"
				selected={ !isCustomRatio && aspectRatio === ratio }
				disabled={ this.shouldDisableAspectRatio(ratio) }
				style={ aspectButton }
				key={ ratio }
				onClick={ this.handleRequestChangeAspect(ratio) }
			>
				<div style={ aspectButtonText }>
					{ this.getDisplayText(index) }
				</div>
			</OptionButton>
		));

		if (isCustomRatio) {
			ratioButtons.unshift(
				<OptionButton rounded
					size="small"
					selected={ true }
					disabled={ false }
					style={ customAspectButton }
					key="custom-ratio"
				>
					<div style={ aspectButtonText }>
						{ "Custom" }
					</div>
				</OptionButton>
			);
		}

		return ratioButtons;
	}

	handleRequestChangeAspect(ratio: AspectRatios) {
		return () => {
			if (this.shouldDisableAspectRatio(ratio)) {
				Notifications.warning("Cannot Change Aspect Ratio",
					`There is not enough remaining space to change the aspect ratio.
					Please remove or resize content in the current layout in order
					to change aspect ratio.`);

				return;
			}

			this.requestChangeAspect(ratio);
		}
	}

	getDisplayText(index: number) {
		const displayText = Object.values(this.props.ratios)[index];
		switch (displayText) {
			case "9:16":
				return "Full";
			default:
				return displayText;
		}
	}

	shouldDisableAspectRatio(aspectRatio: AspectRatios) {
		const { component, adHeightPercent } = this.props;
		const { NINE_SIXTEEN, ONE_ONE, FOUR_THREE, SIXTEEN_NINE } = AspectRatios;
		const { nineBySixteen, oneByOne, fourByThree, sixteenByNine } = RatioHeightPercents;
		let adHeightWithoutFeed = adHeightPercent - component.height.value;
		let adHeightWithAspectChange;

		switch (aspectRatio) {
			case NINE_SIXTEEN:
				adHeightWithAspectChange = adHeightWithoutFeed + nineBySixteen;
				break;
			case ONE_ONE:
				adHeightWithAspectChange = adHeightWithoutFeed + oneByOne;
				break;
			case FOUR_THREE:
				adHeightWithAspectChange = adHeightWithoutFeed + fourByThree;
				break;
			case SIXTEEN_NINE:
				adHeightWithAspectChange = adHeightWithoutFeed + sixteenByNine;
				break;
		}

		return adHeightWithAspectChange > 100;
	}

	renderAspectThumb(isCustom?: boolean) {
		const { NINE_SIXTEEN, ONE_ONE, FOUR_THREE, SIXTEEN_NINE } = AspectRatios;
		let ratio = this.getAspectRatio();

		if (this.props.video) {
			return null;
		}

		if (isCustom) {
			return this.renderCustom();
		}

		switch (ratio) {
			case NINE_SIXTEEN:
				return this.render916();
			case ONE_ONE:
				return this.render11();
			case FOUR_THREE:
				return this.render43();
			case SIXTEEN_NINE:
				return this.render169();
		}
	}

	renderVideoContainsEmptySpace() {
		const { component, video } = this.props;
		const { emptySpace, emptySpaceWarning, bestFit, bestFitWrapper } = this.styles;

		if (!video) {
			return null;
		}

		const componentHeight = component.height.value * 1920 / 100;
		const componentWidth = 1080;

		const { height: videoHeight, width: videoWidth } = video;

		const videoRatio = videoHeight / videoWidth;
		const componentRatio = componentHeight / componentWidth;

		const ratios = [ videoRatio, componentRatio ].sort();
		const percentBlank: number = Number((100 - ratios[0] / ratios[1] * 100).toFixed(2));

		if (percentBlank <= .01) {
			return null;
		}

		const blackBars = videoRatio >= componentRatio ? "vertical" : "horizontal";

		return (
			<div style={ emptySpace }>
				<h4 style={ emptySpaceWarning }>Video Region Contains Extra Space!</h4>
				<span>
					{ percentBlank }% extra space as { blackBars } black bars.
					Click the button below for best fit.
				</span>
				<div style={ bestFitWrapper }>
					<Button
						onClick={ this.handleBestFit }
						type="primary"
						color={ Colors.primaryBlue }
						style={ bestFit }
					>
						Best Fit
					</Button>
				</div>
			</div>
		);
	}

	handleBestFit() {
		const { component, componentIndex, adHeightPercent, video } = this.props;

		if (!video) {
			return null;
		}

		const idealHeight = (video.height * 1080) / video.width;
		const idealHeightPercent = (idealHeight / 1920) * 100
		const adHeightWithoutFeed = adHeightPercent - component.height.value;
		const remainingHeight = 100 - adHeightWithoutFeed;

		if (idealHeightPercent > remainingHeight) {
			Notifications.warning("Best Fit Is Larger Than Available Space",
				"The Best Fit is larger than the remaining space. Maximizing video region instead.");
		}

		const updatedComponent = update(component, {
			height: {
				value: { $set: Math.min(remainingHeight, idealHeightPercent) }
			}
		});

		return this.props.onComponentUpdated(componentIndex, updatedComponent);
	}


	render916() {
		const { baseRatio, nineSixteen } = this.styles;
		return (
			<div style={{ ...baseRatio, ...nineSixteen }}>
				9:16<br />
				Cropped
			</div >
		);
	}

	renderCustom() {
		const { baseRatio } = this.styles;
		const { component } = this.props;
		const { height } = component;

		const h = (height.value / 100) * 1920;

		const customRatio = {
			width: this.height * (1080 / h),
			height: this.height
		};
		return (
			<div style={{ ...baseRatio, ...customRatio }}>
				Custom<br />
				Cropped
			</div >
		);
	}

	render11() {
		const { baseRatio, oneOne } = this.styles;
		return (
			<div style={{ ...baseRatio, ...oneOne }}>
				1:1<br />
				Cropped
			</div >
		);
	}

	render43() {
		const { baseRatio, fourThree } = this.styles;
		return (
			<div style={{ ...baseRatio, ...fourThree }}>
				4:3<br />
				Cropped
			</div >
		);
	}

	render169() {
		const { baseRatio, sixteenNine } = this.styles;
		return (
			<div style={{ ...baseRatio, ...sixteenNine }}>
				16:9
			</div >
		);
	}

	getAspectPercent() {
		const { NINE_SIXTEEN, ONE_ONE, FOUR_THREE, SIXTEEN_NINE } = AspectRatios;
		let ratio = this.getAspectRatio();

		switch (ratio) {
			case NINE_SIXTEEN:
				return RatioHeightPercents.nineBySixteen;
			case ONE_ONE:
				return RatioHeightPercents.oneByOne;
			case FOUR_THREE:
				return RatioHeightPercents.fourByThree;
			case SIXTEEN_NINE:
			default:
				return RatioHeightPercents.sixteenByNine;
		}
	}

	getAspectRatio() {
		const { component } = this.props;

		let ratio = AspectRatios.SIXTEEN_NINE;

		if (component && component.aspectRatio) {
			ratio = component.aspectRatio;
		}

		return ratio;
	}

	requestChangeAspect(aspectRatio: AspectRatios) {
		const { component, componentIndex } = this.props;
		let requestedHeight;

		switch (aspectRatio) {
			case AspectRatios.NINE_SIXTEEN:
				requestedHeight = RatioHeightPercents.nineBySixteen;
				break;
			case AspectRatios.ONE_ONE:
				requestedHeight = RatioHeightPercents.oneByOne;
				break;
			case AspectRatios.FOUR_THREE:
				requestedHeight = RatioHeightPercents.fourByThree;
				break;
			case AspectRatios.SIXTEEN_NINE:
				requestedHeight = RatioHeightPercents.sixteenByNine;
				break;
		}

		const updatedComponent = update(component, {
			aspectRatio: { $set: aspectRatio },
			height: {
				value: { $set: requestedHeight }
			}
		});

		this.props.onComponentUpdated(componentIndex, updatedComponent);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AspectRatioPicker);
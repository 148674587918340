import * as React from "react";
import { connect } from "react-redux";

import { ActionType, CustomCSS, TriggerType } from "@connect/Interfaces";
import { Icon, Loader } from "Components/Global/Common";
import { getSelectedTriggerType } from "Data/Selectors/ActionBuilder";

export const mapStateToProps = (state) => {
	return {
		selectedTrigger: getSelectedTriggerType(state)
	}
};

export interface IActionPropertiesPanelContent {
	renderContainer: (content: JSX.Element) => JSX.Element;
}

export interface ActionPropertiesPanelContentProps {
	activeActionType: ActionType;
	selectedTrigger: TriggerType;
}

export class ActionPropertiesPanelContent<T extends ActionPropertiesPanelContentProps>
	extends React.Component<T> implements IActionPropertiesPanelContent {
	constructor(props: T) {
		super(props);

		this.styles = {
			image: {
				display: "inline-block",
				margin: "3em 0 0"
			},
			name: {
				display: "inline-block",
				marginTop: 10
			},
			row: {
				marginBottom: 14,
				textAlign: "center"
			}
		};

		this.renderContainer = this.renderContainer.bind(this);
	}

	icon: string;
	title: string;

	styles: CustomCSS;

	render() {
		// We throw here to prevent rendering the ActionPropertiesPanelContent directly, but we must return a <div />
		// so the extending components can properly type their render methods.
		throw new Error("WARNING: ActionPropertiesPanelContent#render must be overridden in the extending component.");
		return <div />;
	}

	renderContainer(children?: JSX.Element) {
		if (!this.props.activeActionType) {
			return (
				<Loader size="small" />
			);
		}

		return (
			<div key={ this.props.selectedTrigger }>
				{ this.renderIcon() }
				{ children }
			</div>
		);
	}

	renderIcon() {
		const { image, name, row } = this.styles;
		const rotate = this.props.activeActionType === "lightbar" ? 45 : 0;

		return (
			<div style={ row } >
				<Icon
					name={ this.icon }
					size="bigger"
					rotate={ rotate }
					style={ image }
				/>
				<br />
				<span style={ name }>{ this.title }</span>
			</div>
		);
	}
}

export default connect(mapStateToProps)(ActionPropertiesPanelContent);

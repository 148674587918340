import { Action, ActiveSortDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ActiveObjectArrayState } from "Data/Objects/AppState";
import { initialActiveSortStates } from "Data/Objects/UI";
import { createReducer, setState } from "Data/Utils";

export function setActiveSort(state: ActiveObjectArrayState, action: Action<ActiveSortDispatch>) {
	const { path, sort } = action.args;

	return setState(state, path, sort);
}

export function resetActiveSorts(state: ActiveObjectArrayState, action: Action<null>) {
	return new ActiveObjectArrayState(initialActiveSortStates);
}

const reducers = {
	[ACTION_TYPES.UI.RESET_ACTIVE_SORTS.type]: resetActiveSorts,
	[ACTION_TYPES.UI.SET_ACTIVE_SORT.type]: setActiveSort
};

export default createReducer(reducers, ActiveObjectArrayState, initialActiveSortStates);

import { createSelector } from "reselect";

import { AdCollectionArgs, AdMediaLink, IAd,
	SortTypes } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { AdsState, AppState } from "Data/Objects/AppState";
import { getAllMedia, getMediaForAdComponent } from "Data/Selectors/Media";
import { identityReducer } from "Data/Utils";

export function getAppState(state: AppState): AppState {
	return state;
}

export function getAdUuid(state: AppState, ad: IAd) {
	return ad.uuid;
}

export function getActiveAdByUuid(adsState: IAd[], adUuid: string) {
	return adsState.filter((currentAd) => currentAd.uuid === adUuid)[0];
}

export function getAdsState(state: AppState): AdsState {
	return state.Ads;
}

export function getAdsSorted(ads: IAd[], sortType: SortTypes) {
	return Utils.sortListBySortType(ads, sortType);
}

export function getAds(state: AdsState): IAd[] {
	return state.ads;
}

export function getAdSort(state: AppState, args: AdCollectionArgs): SortTypes {
	return args.sort || SortTypes.NEWEST_FIRST
}

export function getAdsFilter(state: AppState, args: AdCollectionArgs): string {
	return args.filter || "";
}

export function getAdsFromArray(ads: IAd[], adsToFind: string[]) {
	return ads.filter((ad) => adsToFind.includes(ad.uuid));
}

export function getAdsFilteredBySearch(ads: IAd[], filter: string) {
	const filterText = filter.toLowerCase();
	return ads.filter((ad) => ad.name && ad.name.toLowerCase().includes(filterText));
}

export function getAdMediaIds(state: AppState, media: AdMediaLink[], componentId?: string) {
	let newMedia = [ ...media ];

	if (componentId) {
		newMedia = newMedia.filter((m) => m.layoutPosition === componentId);
	}

	return newMedia
		.sort((a, b) => a.sort - b.sort)
		.map((m) => m.mediaId);
}

export function getAdsFilteredByTags(ads: IAd[], tags: string[]) {
	if (!tags || tags.length === 0) {
		return ads;
	}

	return ads.filter((ad: IAd) => {
		if (ad.tags.length && ad.tags.some((tag) => tags.includes(tag))) {
			return true;
		}

		return false;
	});
}

export function getCurrentTags(state: AppState, args: AdCollectionArgs): string[] {
	return args.tags || [];
}

export function getTagsFromAds(ads: IAd[]) {
	return ads
		.reduce((arr: string[], ad: IAd) => {
			if (ad.tags && ad.tags.length) {
				ad.tags.forEach((tag) => arr.push(tag));
			}
			return arr;
		}, [])
		.filter((tag: string, index: number, self: string[]) => self.indexOf(tag) === index);
}

export const getAllAds = createSelector(
	[ getAdsState ],
	getAds
);

export const getActiveAd = createSelector(
	[ getAllAds, identityReducer ],
	getActiveAdByUuid
);

export const getAdTags = createSelector(
	[ getAllAds ],
	getTagsFromAds
);

export const getSortedAds = createSelector(
	[ getAllAds, getAdSort ],
	getAdsSorted
);

export const getFilteredAds = createSelector(
	[ getAllAds, getAdsFilter ],
	getAdsFilteredBySearch
);

export const getFilteredSortedAds = createSelector(
	[ getFilteredAds, getAdSort ],
	getAdsSorted
);

export const getFilteredSortedTaggedAds = createSelector(
	[ getFilteredSortedAds, getCurrentTags ],
	getAdsFilteredByTags
);

export const getDevicesByArray = createSelector(
	[ getAllAds, identityReducer ],
	getAdsFromArray
);

export const getAdMedia = createSelector(
	[ getAdMediaIds, getAllMedia ],
	getMediaForAdComponent
);

export const getAdMediaForComponent = createSelector(
	[ getAdMediaIds, getAllMedia ],
	getMediaForAdComponent
);
import { createSelector } from "reselect";

import { Activity } from "@connect/Interfaces";
import { AdminActivityState, AppState } from "Data/Objects/AppState";

export function getActivityState(state: AppState): AdminActivityState {
	return state.Admin.Activity;
}

export function getActivities(state: AdminActivityState): Activity[] {
	return state.activities;
}

export const getActivity = createSelector(
	[ getActivityState ],
	getActivities
);

import { AdAnalyticsNotification, AdCreationResult, AdListRequest, AdResult, AnalyticsFilterOptions, AnalyticsRequest,
	API_SORT, IAd, IAdApi } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class AdApi extends ApiV2 implements IAdApi {
	getAd(adId: string, expand?: boolean): Promise<IAd> {
		return this.GET(`ads/${adId}`, expand && { expand: [ "company", "media" ].join(",") }) as Promise<IAd>;
	}

	/* eslint-disable camelcase */
	getAdAnalytics(uuid: string, options?: AnalyticsFilterOptions) {
		const { days, devices, sizes, stores, includeSettings, data, start_date } = options || {} as AnalyticsFilterOptions;
		let req: AnalyticsRequest = {};

		if (days) {
			req["filter[days]"] = days;
		}
		if (devices) {
			req["filter[devices]"] = devices.join(",");
		}
		if (start_date) {
			req["filter[start_date]"] = start_date;
		}
		if (sizes && sizes.length) {
			req["filter[size]"] = sizes.join(",");
		}
		if (stores && stores.length) {
			req["filter[store]"] = stores.join(",");
		}
		if (includeSettings) {
			req.include = "user.notification";
		}
		if (data && data.length) {
			req.with = data.join(",");
		}

		return this.GET(`ads/${uuid}/analytics`, req);
	}
	/* eslint-enable camelcase */

	getAds(perPage: number, page: number, sort?: API_SORT, analytics?: boolean): Promise<AdResult> {
		const req: AdListRequest = {
			page,
			"per_page": perPage,
			"sort": sort ? sort : "created_at"
		};

		if (analytics) {
			req["filter[analytics]"] = true;
		}

		return this.GET("ads", req) as Promise<AdResult>;
	}

	getAdsWithAnalytics(perPage: number, page: number): Promise<AdResult> {
		return this.getAds(perPage, page, undefined, true);
	}

	getAnalyticsNotificationSettings(uuid: string): Promise<AdAnalyticsNotification> {
		return this.GET(`ads/${uuid}/analytics/notification`) as Promise<AdAnalyticsNotification>;
	}

	/* eslint-disable camelcase */
	getRequestReportExport(uuid: string, filterSet?: AnalyticsFilterOptions): Promise<null> {
		const { days, start_date, sizes, stores } = filterSet || {
			days: null,
			start_date: null,
			sizes: null,
			stores: null
		};
		let req: AnalyticsRequest = {};

		if (days) {
			req["filter[days]"] = days;
		}
		if (start_date) {
			req["filter[start_date]"] = start_date;
		}
		if (sizes && sizes.length) {
			req["filter[size]"] = sizes.join(",");
		}
		if (stores && stores.length) {
			req["filter[store]"] = stores.join(",");
		}

		return this.GET(`ads/${ uuid }/analytics/export`, req) as Promise<null>;
	}
	/* eslint-enable camelcase */

	getReportExport(uuid: string, token: string): Promise<any> {
		return this.GET(`ads/${ uuid }/analytics/export/${ token }`)
	}

	createAd(ad: IAd): Promise<AdCreationResult> {
		const updatedAd = Object.assign({}, ad, { analytics: !!ad.analytics });
		return this.POST("ads", updatedAd) as Promise<AdCreationResult>;
	}

	updateAd(ad: IAd): Promise<Response> {
		const updatedAd = Object.assign({}, ad, { analytics: !!ad.analytics });
		return this.PUT(`ads/${ad.uuid}`, updatedAd) as Promise<Response>;
	}

	updateAnalyticsNotificationSettings(
		uuid: string,
		settings: AdAnalyticsNotification
	): Promise<AdAnalyticsNotification> {
		return this.PUT(`ads/${uuid}/analytics/notification`, settings) as Promise<AdAnalyticsNotification>;
	}

	deleteAd(ad: IAd, force?: boolean): Promise<Response> {
		return this.DELETE(`ads/${ad.uuid}`, { force }) as Promise<Response>;
	}
}

import bugsnag, { Bugsnag } from "@bugsnag/js";

import { getState } from "@connect/Data";
import { getEnvironmentString, isLocalhost } from "@connect/Env";
import { ICompany, IUser } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { VERSION } from "Data/Objects/AppState";

const bugsnagApiKey = "939fdd060e8483ee8ac1eb577f379501";

export interface BugsnagUser {
	id: string;
	name: string;
}

export const bugsnagClient: Bugsnag.Client = bugsnag({
	apiKey: bugsnagApiKey,
	appVersion: VERSION,
	autoNotify: !isLocalhost(), // if we are not on localhost, notify of uncaught exceptions and unhandled rejections
	releaseStage: getEnvironmentString(),
	consoleBreadcrumbsEnabled: !isLocalhost()
});

export function getBugSnagUser(): BugsnagUser | null {
	const { user } = bugsnagClient;

	if (user && user.hasOwnProperty("id") && user.hasOwnProperty("name")) {
		return bugsnagClient.user as BugsnagUser;
	}

	return null;
}

/**
 * BugSnag Client data update or notification
 */
export function notifyBugSnag(error: Error) {
	const { User: { user } } = getState();
	const bsUser = getBugSnagUser();

	if (!bsUser || (user && user.uuid) !== bsUser.id) {
		setBugSnagUser(user)
	}

	if (!isLocalhost()) { // if we are not on localhost, notify bugsnag, otherwise console.error'
		bugsnagClient.notify(error);
	} else {
		Utils.consoleDevError(error);
	}
}

export function setBugSnagCompany(company: Partial<ICompany>) {
	bugsnagClient.metaData = Object.assign({}, bugsnagClient.metaData, {
		company: {
			id: company.uuid,
			name: company.name
		}
	})
}

export function setBugSnagUser(user: Partial<IUser>) {
	if (user) {
		bugsnagClient.user = Object.assign({}, bugsnagClient.user, {
			id: user.uuid,
			name: user.name
		});
	} else {
		bugsnagClient.user = {};
	}
}

/**
 * Dispatchable Action proxies for Client actions
 */
export function notifyBugSnagAsync(error: Error) {
	return (dispatch) => {
		notifyBugSnag(error);
	};
}

export function setBugSnagCompanyAsync(company: Partial<ICompany>) {
	return (dispatch) => {
		setBugSnagCompany(company);
	}
}

export function setBugSnagUserAsync(user: Partial<IUser>) {
	return (dispatch) => {
		setBugSnagUser(user);
	};
}

import { Button, Dropdown, Menu, Popover } from "antd";
import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, Deployment, IUser, SortTypes, StringObject, Filters, Sorts } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { Icon, Loader, Table } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import { cancelDeploymentAsync, deleteDeploymentAsync, duplicateDeploymentAsync } from "Data/Actions/DeploymentsAsync";
import { getDeploymentsAsync, redeployDeployment } from "Data/Actions/DeploymentsAsync";
import { setActiveSearch } from "Data/Actions/UI";
import { calculateDeviceCounts } from "Data/Objects/Deployments";
import { getDeployAsyncQueryState, getNestedAsyncState } from "Data/Selectors/Async";
import { getActiveCompanyId } from "Data/Selectors/Company";
import { startEventWizard, startScheduleWizard} from "Data/Actions/UI/DeploymentWizard";
import {
	areDeployed,
	areDraft,
	areLive,
	areUpcoming,
	getSortedDeployments,
	areCancelled,
	isPendingApproval
} from "Data/Selectors/Deployments";
import {
	getActiveFilters,
	getActiveSearch,
	getActiveSorts
} from "Data/Selectors/UI";
import { getCurrentUser } from "Data/Selectors/User";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { isFinite } from "lodash";
import Radium from "radium";
const { lightGray, primaryBlue, primaryGreen, red, white, offWhite, yellow } = Colors;

const mapDispatchToProps = (dispatch) => ({
	cancelDeployment: (uuid: string) => dispatch(cancelDeploymentAsync(uuid)),
	deleteDeployment: (uuid: string) => dispatch(deleteDeploymentAsync(uuid)),
	duplicateDeployment: (uuid: string, user: IUser) => dispatch(duplicateDeploymentAsync(uuid, user)),
	getDeployments: () => dispatch(getDeploymentsAsync()),
	redeploy: (uuid: string) => dispatch(redeployDeployment(uuid)),
	setDeployment: (path: string) => dispatch(push(path)),
	setSearch: (value: string) => dispatch(setActiveSearch("deployments", value)),
	startSchedule: () => dispatch(startScheduleWizard()),
	startEvent: () => dispatch(startEventWizard())
});

const mapStateToProps = (state) => {
	const filters = getActiveFilters(state, Filters.DEPLOYMENTS);
	const currentSort = getActiveSorts(state, Sorts.DEPLOYMENTS) as SortTypes;
	const search = getActiveSearch(state, "deployments");
	const { status, type } = filters as StringObject;
	const asyncQuery = getDeployAsyncQueryState({ filterType: filters as StringObject, sortType: currentSort });
	const { currentPage, haveAllData } = getNestedAsyncState(state, asyncQuery);

	return {
		activeCompanyId: getActiveCompanyId(state),
		asyncQuery,
		currentSort,
		deployments: getSortedDeployments(state, {
			search,
			sort: currentSort,
			status,
			type
		}),
		filter: status,
		haveAllDeployments: haveAllData,
		lastPageFetched: currentPage,
		search,
		type,
		user: getCurrentUser(state)
	};
};

interface FilteredDeploymentProps {
	activeCompanyId: string;
	asyncQuery: string;
	currentSort: SortTypes;
	cancelDeployment: (uuid: string) => Promise<void>;
	deleteDeployment: (uuid: string) => void;
	duplicateDeployment: (uuid: string, user: IUser) => Promise<Deployment>;
	deployments: Deployment[];
	filter: string;
	getDeployments: () => void;
	haveAllDeployments: boolean;
	lastPageFetched: number;
	redeploy: (uuid: string) => void;
	search: string;
	setDeployment: (path: string) => void;
	setSearch: (value: string) => void;
	startSchedule: () => void;
	startEvent: () => void;
	type: string;
	user: IUser;
}

@Radium
export class FilteredDeployments extends React.Component<FilteredDeploymentProps> {
	constructor(props: FilteredDeploymentProps) {
		super(props);

		this.styles = {
			actionStyle: {
				paddingLeft: 25
			},
			actionButton: {
				width: 150
			},
			actionButtonArrow: {
				width: 25
			},
			actionButtonDropdown: {
				width: 175
			},
			container: {
				height: "100vh",
				overflowY: "scroll",
				position: "relative",
				width: "100%"
			},
			containerInner: {
				padding: "10px 20px",
				width: "100%"
			},
			deploymentRowContent: {
				marginLeft: "1.4em"
			},
			deploymentRowCreated: {
				fontSize: "0.8em"
			},
			deploymentRowLeft: {
				flexGrow: 2
			},
			deploymentRowTitle: {
				color: primaryBlue,
				cursor: "pointer",
				fontSize: "1.2em"
			},
			disabled: {
				color: lightGray,
				marginTop: 3
			},
			failureStyle: {
				color: red,
				fontSize: "1.2em"
			},
			pendingStyle: {
				color: lightGray,
				fontSize: "1.2em"
			},
			successStyle: {
				color: primaryGreen,
				fontSize: "1.2em"
			},
			title: {
				textAlign: "center"
			},
			updateStatus: {
				display: "flex"
			},
			updateStatusNumber: {
				fontSize: "1.2em"
			},
			updateSegment: {
				padding: "0 12px",
				textAlign: "center"
			},
			updateSegmentTotal: {
				padding: "0 12px",
				textAlign: "left"
			},
			waypoint: {
				margin: "36px auto",
				textAlign: "center",
				width: "100%"
			},
			outer: {
				color: lightGray,
				cursor: "pointer",
				background: offWhite,
				boxShadow: `0px 2px 10px ${lightGray}`,
				border: `1px solid ${white}`,
				borderRadius: 6,
				minHeight: 174,
				minWidth: 140,
				width: "40%",
				marginTop: 60,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column"
			},
			parent: {
				display: "flex",
				width: "100%"
			},
			hoverYellow: {
				marginRight: 105,
				marginLeft: 60,
				":hover": {
					color: white,
					background: yellow,
					border: `1px solid ${yellow}`
				}
			},
			hoverGreen: {
				marginLeft: 105,
				marginRight: 60,
				":hover": {
					color: white,
					background: primaryGreen,
					border: `1px solid ${primaryGreen}`
				}
			}
		};

		this.cancelDeployment = this.cancelDeployment.bind(this);
		this.clickViewReport = this.clickViewReport.bind(this);
		this.deleteDeployment = this.deleteDeployment.bind(this);
		this.duplicateDeployment = this.duplicateDeployment.bind(this);
		this.getDeployments = this.getDeployments.bind(this);
		this.getDropdownContainer = this.getDropdownContainer.bind(this);
		this.redeployDeployment = this.redeployDeployment.bind(this);
		this.renderDeployment = this.renderDeployment.bind(this);
		this.renderDeploymentName = this.renderDeploymentName.bind(this);
		this.renderDeploymentType = this.renderDeploymentType.bind(this);
		this.renderDeploymentStatus = this.renderDeploymentStatus.bind(this);
		this.renderDeploymentDeviceStatus = this.renderDeploymentDeviceStatus.bind(this);
		this.renderDeploymentActions = this.renderDeploymentActions.bind(this);
		this.renderDeploymentActionsMenu = this.renderDeploymentActionsMenu.bind(this);
		this.renderNoData = this.renderNoData.bind(this);
		this.setDropdownContainerRef = this.setDropdownContainerRef.bind(this);
		this.viewReport = this.viewReport.bind(this);
		this.renderEmpty = this.renderEmpty.bind(this);
	}

	styles: CustomCSS;
	dropdownContainer: HTMLDivElement;

	componentDidMount() {
		if (this.props.lastPageFetched === 0) {
			this.getDeployments();
		}
	}

	componentWillReceiveProps({
		asyncQuery: prevAsyncQuery,
		activeCompanyId: nextCompanyId,
		haveAllDeployments
	}: FilteredDeploymentProps) {
		const { activeCompanyId, asyncQuery, deployments } = this.props;

		// if our company has changed, or we don't have any deployments yet, fetch
		if (activeCompanyId !== nextCompanyId
		|| !haveAllDeployments && deployments.length === 0
		|| asyncQuery !== prevAsyncQuery) {
			this.getDeployments();
		}
	}

	render() {
		const { deployments, filter, haveAllDeployments, lastPageFetched, search, setSearch, type } = this.props;
		const { container, containerInner, title, waypoint } = this.styles;

		if (!filter || !type || lastPageFetched === 0) {
			return (
				<div style={ waypoint }>
					<Loader />
				</div>
			);
		}

		let content;
		if (deployments.length === 0 && haveAllDeployments) {
			content = this.renderEmpty();
		} else if (deployments.length) {
			content = this.renderDeployment()
		} else {
			content = this.renderNoData();
		}

		return (
			<div style={container}>
				<ContentAreaTopBar
					sort={{
						dataType: Sorts.DEPLOYMENTS
					}}
					search={{
						filterText: search,
						onSearchChange: setSearch
					}}
				/>
				<div style={containerInner}>
					<h1 style={title}>{this.getFilterTitle()}</h1>
					{ content }
					<div style={{
						...waypoint,
						display: !haveAllDeployments && deployments ? "inline-block" : "none"
					}}>
						<Waypoint key={ lastPageFetched } onEnter={ this.getDeployments } />
						<Loader />
					</div>
				</div>
			</div>
		);
	}

	renderEmpty() {
		const { outer, parent, hoverGreen, hoverYellow } = this.styles;
		const { startEvent, startSchedule } = this.props;
		return (
			<div style={ parent }>
				<div
					key="new-schedule"
					onClick={ startSchedule }
					style={ { ...outer, ...hoverGreen } }>
					<Icon
						name="plus-circle"
						size="small" />
					<p>Create New Schedule</p>
				</div>
				<div
					key="new-event"
					onClick={ startEvent }
					style={ { ...outer, ...hoverYellow } }>
					<Icon
						name="exclamation-triangle"
						size="small" />
					<p>Create New Event</p>
				</div>
			</div>
		)
	}

	renderDeployment() {
		const columns = [
			{
				title: "Deployment Name",
				dataIndex: "name",
				key: "name",
				render: this.renderDeploymentName
			},
			{
				title: "Type",
				dataIndex: "type",
				key: "type",
				render: this.renderDeploymentType
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				render: this.renderDeploymentStatus
			},
			{
				title: "Device Update Status",
				dataIndex: "update_status",
				key: "update_status",
				render: this.renderDeploymentDeviceStatus
			},
			{
				title: "Actions",
				dataIndex: "actions",
				key: "actions",
				render: this.renderDeploymentActions
			}
		];
		const dataSource = this.props.deployments.map((d, i) => ({ ...d, key: i + "_" + d.uuid + "_" + d.name }));

		return (
			<Table
				columns={columns}
				dataSource={dataSource}
				pagination={false}
			/>
		);
	}

	renderDeploymentName(name: string, record: Deployment, index: number) {
		const {
			deploymentRowContent,
			deploymentRowCreated,
			deploymentRowLeft,
			deploymentRowTitle
		} = this.styles;
		const { createdBy, startDate, type, updatedAt, uuid } = record;
		const createdByName = createdBy && createdBy.name;

		const now = moment();

		const isEvent = type === "event";
		const isDraft = areDraft(record);
		const isUpcoming = areUpcoming(now)(record);
		const pendingApproval = isPendingApproval(record);

		const icon = isEvent ? "exclamation-triangle" : "calendar";

		let displayedDate;
		let prefix;

		if (isDraft) {
			displayedDate = updatedAt;
			prefix = "Draft, Edited"
		} else {
			displayedDate = startDate;
			if (isUpcoming) {
				prefix = "Scheduled";
			} else if (pendingApproval) {
				prefix = "Submitted"
			} else {
				prefix = "Deployed"
			}
		}

		const description = `${ prefix } ${ Utils.getHumanReadableDate(displayedDate) }`;

		return (
			<div style={deploymentRowLeft}>
				<h2 style={deploymentRowTitle} onClick={ this.clickViewReport(uuid, isDraft) }>
					<Icon name={icon} /> { name }
				</h2>
				<div style={deploymentRowContent}>
					<div>{ description }</div>
					<div style={deploymentRowCreated}>Created By: { createdByName }</div>
				</div>
			</div>
		);
	}

	renderDeploymentType(type: string, record: Deployment, index: number) {
		return Utils.properCase(type);
	}

	renderDeploymentStatus(value: any, record: Deployment, index: number) {
		const now = moment();
		const isDraft = areDraft(record);
		const isDeployed = areDeployed(record);
		const isLive = areLive(now)(record);
		const isUpcoming = areUpcoming(now)(record);
		const isCancelled = areCancelled(record);
		const pendingApproval = isPendingApproval(record);

		let icon = "";
		let iconWeight;
		let status = "";

		if (isCancelled) {
			icon = "ban";
			status = "Cancelled";
		} else if (pendingApproval) {
			icon = "check";
			status = "Pending Approval";
		} else if (isDeployed && isUpcoming) {
			icon = "clock";
			iconWeight = "regular"
			status = "Upcoming";
		} else if (isDeployed && isLive) {
			icon = "bullseye";
			status = "Live Deployment";
		} else if (isDeployed) {
			icon = "check";
			status = "Deployed";
		} else if (isDraft) {
			icon = "pencil";
			status = "Draft";
		}

		return (
			<div>
				<Icon name={icon} iconWeight={iconWeight} /> {status}
			</div>
		);
	}

	renderDeploymentDeviceStatus(value: any, record: Deployment, index: number) {
		const { endDate, startDate, type } = record;
		const { missCount, missed, succeedCount, success, total } = calculateDeviceCounts(record, true);
		const isDraft = type === "event" ? (!endDate && !startDate) : !startDate;
		const pendingApproval = isPendingApproval(record);
		const { disabled, pendingStyle, successStyle, failureStyle, updateStatus,
			updateStatusNumber, updateSegment, updateSegmentTotal } = this.styles;
		const draftStyle = isDraft || pendingApproval ? disabled : null;
		const isPending = moment().diff(startDate, "hours") <= 24;
		const pending = (
			<strong style={ pendingStyle }>Pending</strong>
		);

		const successNum = isFinite(success) ? success : 0;
		const missedNum = isFinite(missed) ? missed : 0;

		const successStatus = !isPending ? (
			<strong style={ successStyle }>{ successNum }%</strong>
		) : pending;
		const missedStatus = !isPending ? (
			<strong style={ failureStyle }>{ missedNum }%</strong>
		) : pending;

		let successDisplay = (
			<div style={{ ...updateSegment, ...draftStyle }}>
				{ !isDraft && !pendingApproval ? successStatus : "-" }<br />
				Success
			</div>
		);
		let missedDisplay = (
			<div style={{ ...updateSegment, ...draftStyle }}>
				{ !isDraft && !pendingApproval ? missedStatus : "-" }<br />
				Missed
			</div>
		);

		if (!isDraft && !pendingApproval) {
			successDisplay = (
				<Popover content={
					<div style={ updateSegment }>{ `${succeedCount} devices` }</div>
				}>
					{ successDisplay }
				</Popover>
			);
			missedDisplay = (
				<Popover content={
					<div style={ updateSegment }>{ `${missCount} devices` }</div>
				}>
					{ missedDisplay }
				</Popover>
			);
		}

		return (
			<div style={ updateStatus }>
				<Popover content={
					<div style={ updateSegment }>{ `${total} devices` }</div>
				}>
					<div style={ updateSegmentTotal }>
						<strong style={ updateStatusNumber }>{ total }</strong><br />
						Total Devices
					</div>
				</Popover>
				{ successDisplay }
				{ missedDisplay }
			</div>
		);
	}

	renderDeploymentActions(value: any, record: Deployment, index: number) {
		const { name, uuid } = record;
		const now = moment();

		const isDraft = areDraft(record);
		const isUpcoming = areUpcoming(now)(record);
		const isLive = areLive(now)(record) && !isUpcoming;
		const isCancelled = areCancelled(record);
		const actionsOverlay = this.renderDeploymentActionsMenu(isCancelled, isDraft, isUpcoming, isLive, uuid, name);

		const { actionButton, actionButtonArrow } = this.styles;

		return (
			<React.Fragment>
				<Button.Group className="ant-dropdown-button">
					<Button onClick={ this.clickViewReport(uuid, isDraft) } type="default" style={ actionButton }>
						{ isDraft ? "Edit Deployment" : "View Report" }
					</Button>
					<Dropdown
						overlay={ actionsOverlay }
						placement="bottomRight"
						trigger={[ "click" ]}
						getPopupContainer={ this.getDropdownContainer }
					>
						<Button type="default" icon="down" style={ actionButtonArrow } />
					</Dropdown>
				</Button.Group>
				<div ref={ this.setDropdownContainerRef } />
			</React.Fragment>
		);
	}

	renderDeploymentActionsMenu(isCancelled: boolean, isDraft: boolean, isUpcoming: boolean, isLive: boolean,
		uuid: string, name: string) {
		const { actionButtonDropdown, actionStyle } = this.styles;
		const hasDeployPermission = hasPermission(PERMISSIONS.DEPLOYMENTS_DEPLOY);
		const hasFinished = (!isUpcoming && !isLive);
		const cancelDisabled = isCancelled || isDraft || hasFinished || !hasDeployPermission;
		const deleteDisabled = !isDraft;

		const sendAction = ({ item, key }) => {
			const [ action ] = key.split("_");
			let state;

			if (action === "cancel") {
				state = "upcoming";

				if (isLive) {
					state = "live";
				}
			}

			switch (action) {
				case "cancel":
					this.cancelDeployment(uuid, name, state);
					break;
				case "delete":
					this.deleteDeployment(uuid, name);
					break;
				case "duplicate":
					this.duplicateDeployment(uuid);
					break;
				case "edit":
					this.viewReport(uuid, true);
					break;
				case "redeploy":
					this.redeployDeployment(uuid, name);
					break;
			}
		};

		return (
			<Menu onClick={ sendAction } style={ actionButtonDropdown }>
				<Menu.Item style={ actionStyle } disabled={!(isDraft || isUpcoming)} key={`edit_${uuid}`}>
					Edit
				</Menu.Item>
				<Menu.Item style={ actionStyle } key={`duplicate_${uuid}`}>
					Duplicate
				</Menu.Item>
				<Menu.Item style={ actionStyle } disabled={ cancelDisabled } key={`cancel_${uuid}`}>
					Cancel
				</Menu.Item>
				<Menu.Item style={ actionStyle } disabled={ deleteDisabled } key={`delete_${uuid}`}>
					Delete
				</Menu.Item>
				<Menu.Item style={ actionStyle } disabled={ isDraft || isCancelled } key={`redeploy_${uuid}`}>
					Redeploy
				</Menu.Item>
			</Menu>
		);
	}

	renderNoData() {
		const { filter, type } = this.props;

		return (
			<p>
				There is no data for Deployments of type <strong>{ type }</strong> and with status <strong>{ filter }</strong>.
			</p>
		);
	}

	cancelDeployment(uuid: string, name: string, state?: "live" | "upcoming") {
		let text: string = "";

		switch (state) {
			case "live":
				text = `Your currently live deployment, ${name} will be canceled and the previously
					deployed schedule will resume play on those devices.`;
				break;
			case "upcoming":
				text = `You have already scheduled the deployment, ${name}, to be sent.
					You'll need to cancel the deployment before you can make additional edits.`;
				break;
		}

		Notifications.confirm(
			"Cancel Deployment",
			text,
			"Confirm",
			"Cancel",
			() => {
				this.props.cancelDeployment(uuid).then(() => {
					Notifications.error(`Deployment ${name} has been canceled.`);
					this.viewReport(uuid, false);
				});
			});
	}

	clickViewReport(uuid: string, isDraft: boolean) {
		return (event: React.SyntheticEvent<HTMLElement>) => {
			event.preventDefault();

			this.viewReport(uuid, isDraft)
		};
	}

	deleteDeployment(uuid: string, name: string) {
		Notifications.confirm(
			"Delete Deployment",
			`Are you sure you want to delete your deployment: ${name}?`,
			"Delete",
			"Cancel",
			() => {
				this.props.deleteDeployment(uuid);
			}
		);
	}

	duplicateDeployment(uuid: string) {
		this.props.duplicateDeployment(uuid, this.props.user).then((duplicate) => {
			this.props.setDeployment(`/deploy/${duplicate.uuid}`);
		});
	}

	getDeployments() {
		this.props.getDeployments();
	}

	getDropdownContainer() {
		return this.dropdownContainer;
	}

	getFilterTitle() {
		const { filter, type } = this.props;

		let firstPart: string = "";
		let secondPart: string = "";

		switch (filter) {
			case "all":
				firstPart = "All";
				break;
			case "live":
				firstPart = "Live";
				break;
			case "upcoming":
				firstPart = "Upcoming";
				break;
			case "deployed":
				firstPart = "Deployed";
				break;
			case "draft":
				firstPart = "Draft";
				break;
			case "cancelled":
				firstPart = "Cancelled";
				break;
		}

		switch (type) {
			case "all":
				secondPart = "Deployments";
				break;
			case "event":
				secondPart = "Events";
				break;
			case "schedule":
				secondPart = "Schedules";
				break;
		}

		return firstPart + " " + secondPart;
	}

	redeployDeployment(uuid: string, name: string) {
		Notifications.confirm(
			`Redeploy ${name}`,
			`Are you sure you want to redeploy ${name} to missed devices?`,
			"Redeploy",
			"Cancel",
			() => {
				this.props.redeploy(uuid);

				Notifications.success(
					`Deployment ${name} has been redeployed to missed devices.`
				);
			}
		);
	}

	setDropdownContainerRef(ref: HTMLDivElement) {
		this.dropdownContainer = ref;
	}

	viewReport(uuid: string, isDraft: boolean) {
		const report = isDraft ? "deploy" : "deploymentReport";
		const path = `/${report}/${uuid}`;

		this.props.setDeployment(path);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FilteredDeployments);

import { cloneDeep } from "lodash";
import * as moment from "moment";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { Action, IPlaylist, PaginatedDataResult, PlaylistDispatch, PlaylistListDispatch } from "@connect/Interfaces";
import PlaylistsApi from "Api/Playlists";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { setAsyncFetching, setAsyncState } from "Data/Actions/UI";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";
import { CacheInvalidationPeriod } from "Data/Objects/Global";

const {
	CREATE_PLAYLIST,
	DELETE_PLAYLIST,
	RESET_PLAYLISTS,
	SET_PLAYLISTS,
	UPDATE_PLAYLIST
} = ACTION_TYPES.Playlists;

/**
 * Simple Actions
 */

function createPlaylist(playlist: IPlaylist): Action<PlaylistDispatch> {
	return new DispatchableAction(CREATE_PLAYLIST, { playlist });
}

function deletePlaylist(playlist: IPlaylist): Action<PlaylistDispatch> {
	return new DispatchableAction(DELETE_PLAYLIST, { playlist });
}

function resetPlaylists(): Action<null> {
	return new DispatchableAction(RESET_PLAYLISTS, null);
}

function setPlaylists(playlists: IPlaylist[], reset: boolean): Action<PlaylistListDispatch> {
	return new DispatchableAction(SET_PLAYLISTS, { playlists, reset })
}

function updatePlaylist(playlist: IPlaylist): Action<PlaylistDispatch> {
	return new DispatchableAction(UPDATE_PLAYLIST, { playlist });
}

/**
 * Async Actions
 */

function createPlaylistAsync(playlist: IPlaylist, isCopy?: boolean) {
	const api = new PlaylistsApi();
	const making = isCopy ? "copying" : "creating";

	// api compatibility; expects to receive just a uuid for actionSet
	const playlistWithoutActionSetName = Object.assign({}, playlist, {
		actionSet: playlist.actionSet && playlist.actionSet.uuid
	});

	return (dispatch) => api.createPlaylist(playlistWithoutActionSetName)
		.then((result: Partial<IPlaylist>) => {
			const { actionSet } = playlist;

			const newPlaylist = Object.assign({}, playlist, {
				uuid: result.uuid
			});

			newPlaylist.actionSet = actionSet && {
				name: actionSet.name,
				uuid: actionSet.uuid
			};

			dispatch(createPlaylist(newPlaylist));
			dispatch(successNotification(`Created ${newPlaylist.name}.`));

			return newPlaylist;
		}, (error) => {
			dispatch(errorNotification(`Error ${making} playlist.`, error));
		})
		.catch(error => notifyBugSnagAsync(new Error(error)));
}

function deletePlaylistAsync(playlist: IPlaylist) {
	const api = new PlaylistsApi();

	return (dispatch) => api.deletePlaylist(playlist.uuid)
		.then(() => {
			dispatch(deletePlaylist(playlist));
			dispatch(successNotification("Playlist successfully deleted."));
		}, (error) => {
			dispatch(errorNotification("Could not delete playlist.", error));
		})
		.catch(error => notifyBugSnagAsync(new Error(error)));
}

function getPlaylistAsync(playlist: IPlaylist) {
	const api = new PlaylistsApi();

	return (dispatch) => api.getPlaylist(playlist.uuid)
		.then((result: any) => {
			dispatch(updatePlaylist(result));
		}, (error) => {
			dispatch(errorNotification(`Error getting playlist ${playlist.name}.`, error));
		})
		.catch(error => notifyBugSnagAsync(new Error(error)));
}

function getPlaylistsAsync(full?: boolean) {
	const api = new PlaylistsApi();

	return (dispatch, getState) => {
		const { Company, UI: { asyncState } } = getState();
		const { currentPage, currentlyFetching, haveAllData, lastFetch, lastFetchedCompany } = asyncState.playlists;
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const companyChanged = Company.activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = moment().diff(lastFetch, "minute") > CacheInvalidationPeriod || companyChanged;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		if (shouldInvalidateCache) {
			dispatch(resetPlaylists());
		}

		dispatch(setAsyncFetching("playlists", true, Company.activeCompanyId));

		const page = shouldInvalidateCache ? 0 : currentPage;

		// the perPage arg doesn't make much sense to implement at this time
		return api.getPlaylists(page + 1, undefined, full)
			.then((result: PaginatedDataResult<IPlaylist>) => {
				dispatch(setPlaylists(result.data, shouldInvalidateCache));
				dispatch(setAsyncState(
					"playlists",
					!result.links.next,
					result.meta.current_page
				));
				dispatch(setAsyncFetching("playlists", false));
			}, (error) => {
				dispatch(errorNotification("Error getting playlists.", error));
				dispatch(setAsyncFetching("playlists", false));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

function updatePlaylistAsync(playlist: IPlaylist) {
	const api = new PlaylistsApi();

	return (dispatch, getState) => {
		const { Playlists: { playlists }} = getState();
		const [ originalPlaylist ] = playlists.filter(({uuid}) => uuid === playlist.uuid);
		const newPlaylist = cloneDeep(playlist);

		newPlaylist.actionSet = playlist.actionSet && {
			name: playlist.actionSet.name,
			uuid: playlist.actionSet.uuid
		};

		dispatch(updatePlaylist(newPlaylist));

		// api compatibility; expects to receive just a uuid for actionSet
		const playlistWithoutActionSetName = Object.assign({}, playlist, {
			actionSet: playlist.actionSet && playlist.actionSet.uuid
		});

		return api.updatePlaylist(playlistWithoutActionSetName)
			.then(() => {
				// Update to the Playlist happens synchronously, as the asynchrony here causes UI jank
			}, (error) => {
				dispatch(updatePlaylist(originalPlaylist));
				dispatch(errorNotification("Error updating playlist.", error));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	}
}

export {
	deletePlaylist,
	createPlaylist,
	resetPlaylists,
	createPlaylistAsync,
	deletePlaylistAsync,
	getPlaylistAsync,
	getPlaylistsAsync,
	setPlaylists,
	updatePlaylistAsync,
	updatePlaylist
};

import { Button, Popover } from "antd";
import * as update from "immutability-helper";
import * as React from "react";
import { SketchPicker } from "react-color";

import { CustomCSS } from "@connect/Interfaces";

interface ColorPickerButtonProps {
	onColorSelected: (color: string) => void;
	startColor: string;
}

interface ColorPickerState {
	selectedColor: string;
	visible: boolean;
}

const styles = {
	button: {
		position: "relative",
		top: 7,
		width: 70,
		height: 70,
		overflow: "hidden",
		border: "1px solid black",
		cursor: "pointer"
	},
	cornerMark: {
		position: "absolute",
		right: -6,
		bottom: -6,
		width: 13,
		height: 13,
		background: "#ccc",
		transform: "rotate(45deg)"
	},
	pickerButton: {
		float: "right"
	},
	pickerContainer: {
		marginBottom: "30px",
		padding: "5px 10px"
	}
} as CustomCSS;

export default class ColorPickerButton extends React.PureComponent<ColorPickerButtonProps, ColorPickerState> {
	constructor(props: ColorPickerButtonProps) {
		super(props);

		this.state = {
			selectedColor: props.startColor,
			visible: false
		}

		this.toggleVisibility = this.toggleVisibility.bind(this);
	}

	render() {
		let {visible, selectedColor} = this.state;
		return (
			<Popover
				content={this.renderPopover()}
				trigger="click"
				visible={visible}
				onVisibleChange={this.toggleVisibility}
			>
				<div style={{...styles.button, ...{
					background: selectedColor
				}}}>
					<div style={styles.cornerMark} />
				</div>
			</Popover>
		);
	}

	renderPopover() {
		return (
			<div style={ styles.pickerContainer }>
				<SketchPicker
					color={this.state.selectedColor}
					disableAlpha={true}
					onChangeComplete={(color) => {
						this.setState((prevState) => {
							return update(prevState, {
								selectedColor: {
									$set: color.hex
								}
							});
						});
					}}/>
				<br />
				<Button onClick={this.toggleVisibility} style={ styles.pickerButton }>
					Done
				</Button>
			</div>
		);
	}

	toggleVisibility() {
		this.setState((prevState) => {
			return update(prevState, {
				visible: {
					$set: !prevState.visible
				}
			});
		}, () => {
			this.props.onColorSelected(this.state.selectedColor);
		});
	}
}
import * as oboe from "oboe";

import { notifyBugSnag } from "@connect/BugSnag";
import { history, store } from "@connect/Data";
import { AuthErrorTypes, CRUDOpts, CRUDRequest, HeaderObject, InitialStreamResults,
	StreamResults } from "@connect/Interfaces";
import { setConnectedStatus } from "Data/Actions/System";
import { logoutUser } from "Data/Actions/User";
import { VERSION } from "Data/Objects/AppState";

export default class CRUD {
	constructor(opts: CRUDOpts) {
		if (!(this instanceof CRUD)) {
			return new CRUD(opts);
		}

		const { baseUrl, companyUuid, version } = opts;
		const versionString = version === 1 ? "/v1" : "";

		this.baseUrl = baseUrl ? baseUrl + versionString : "";
		this.companyUuid = companyUuid || "";
		this.version = version;

		if (!this.baseUrl) {
			throw new Error(`
				baseUrl is required in order to make any CRUD requests.
				Please include it in the constructor options to proceed.
			`);
		}
	}

	baseUrl: string;
	companyUuid: string;
	version: 1 | 2;

	DELETE(path: string, params?: {}, admin?: boolean) {
		return this.request({
			body: params && JSON.stringify(params),
			method: "DELETE",
			path
		}, admin);
	}

	GET(path: string, params?: {}, admin?: boolean, raw?: boolean, headers?: HeaderObject) {
		return this.request({
			method: "GET",
			path,
			headers,
			query: params
		}, admin, raw);
	}

	POST(path: string, params: {} | string, admin?: boolean, headers?: HeaderObject, raw?: boolean) {
		return this.request({
			body: raw ? params as string : JSON.stringify(params),
			method: "POST",
			path,
			headers
		}, admin, raw);
	}

	PUT(path: string, params: {}, admin?: boolean) {
		return this.request({
			body: JSON.stringify(params),
			method: "PUT",
			path
		}, admin);
	}

	STREAM(
		path: string,
		onStart: (initialResults: InitialStreamResults) => void,
		onProgress: (results: StreamResults<any>) => void
	) {
		const { User: { token } } = store.getState();
		const { baseUrl, companyUuid } = this;
		const url = `${baseUrl}/companies/${companyUuid}/${path}`;

		let fetched: number;
		let total: number;
		let uuid: string;

		return new Promise((resolve, reject) => {
			const instance = oboe({
				url,
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
					"Accept": "application/vnd.cecconnect.v2+json"
				}
			})
				.start((status, headers) => {
					fetched = 0;
					total = parseInt(headers["x-report-results"], 10);
					uuid = headers["x-report"];

					onStart({
						fetched,
						total,
						uuid
					});
				})
				.node({
					"!.*": (node) => {
						fetched += 1;

						// onProgress should send every item to be collected but only "save" every so often
						// in order to batch store updates so we don't overrender the UI
						onProgress({
							fetched,
							node,
							uuid
						});
					},
					"![10]": (node) => {
						if (total === 0) {
							instance.abort();
						}

						// every 10 items of the result, call "save"
						onProgress({
							fetched,
							save: true,
							uuid
						});
					}
				})
				.done(() => {
				// if the save above does not work (e.g. if results % 10 !== 0)
				// ensure that we also call "save" here
					onProgress({
						fetched,
						save: true,
						uuid
					});
				})
				.fail((err) => {
					notifyBugSnag(new Error(err));
					reject(err);
				});
		});
	}

	encodeQueryParams(params: {}) {
		return Object.keys(params)
			.map((key) => {
				const encKey = encodeURIComponent(key);
				const encParam = encodeURIComponent(params[key]);
				return `${encKey}=${encParam}`;
			})
			.join("&");
	}

	private getHeaders(additionalHeaders: HeaderObject) {
		const { User: { token } } = store.getState();

		const headers = new Headers({
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`,
			"X-Connect-Client": VERSION,
			...additionalHeaders
		});

		if (this.version === 2) {
			headers.set("Accept", "application/vnd.cecconnect.v2+json");
		}

		return headers;
	}

	private getUrl(admin: boolean, path: string) {
		const { baseUrl, version, companyUuid } = this;
		const company = version === 1 ? "company" : "companies";
		const adminUrl = `${baseUrl}/${path}`;
		const companyUrl = `${baseUrl}/${company}/${companyUuid}/${path}`;
		const requestUrl = `${baseUrl}/${company}/${path}`;
		const isAdminOrSpecificCompanyRequest = admin || path.slice(0, company.length) === company;

		if (isAdminOrSpecificCompanyRequest) {
			return adminUrl;
		} else if (path === "request") {
			return requestUrl;
		} else {
			return companyUrl;
		}
	}

	private request(opts: CRUDRequest, admin: boolean = false, raw: boolean = false) {
		const { body, method, path, headers } = opts;
		let url = this.getUrl(admin, path);
		if (opts.query && Object.keys(opts.query).length) {
			url += `?${this.encodeQueryParams(opts.query)}`;
		}

		return new Promise((resolve, reject) => {
			return fetch(url, {
				body,
				headers: this.getHeaders(headers || {}),
				method,
				mode: "cors"
			})
				.then(this.sessionIsValid.bind(this))
				.then(this.responseHasError)
				.then((response: Response) => {
					store.dispatch(setConnectedStatus(true));

					const contentType = response.headers.get("content-type");

					if (response.status === 204) {
						return resolve({});
					}

					if (contentType && contentType.indexOf("application/json") !== -1) {
						return resolve(response.json());
					}

					if (raw) {
						return resolve(response);
					}

					return resolve(response);
				}, (error) => {
					const failedToFetch = error && error.message === "Failed to fetch";
					store.dispatch(setConnectedStatus(!failedToFetch));
					return reject(error);
				})
				.catch((error) => {
					notifyBugSnag(new Error(error));
					store.dispatch(setConnectedStatus(true));
					return reject(error);
				});
		});
	}

	private responseHasError(response: Response) {
		return new Promise((resolve, reject) => {
			if (!response.ok) {
				// check specific headers in this block to make sure request is fully formed
				// and that more information (such as two factor auth, etc.) is not required
				if (response.headers.get("x-auth-otp") === "required") {
					reject({
						error: AuthErrorTypes.TWO_FACTOR_REQUIRED,
						message: "Two factor authentication required."
					});
				}

				// JSON the response body and send it back to the UI
				response.json()
					.then((error) => {
						notifyBugSnag(new Error(error));
						reject(error);
					})
					.catch((error) => {
						reject(error);
					});
			} else {
				resolve(response);
			}
		});
	}

	private sessionIsValid(response: Response) {
		const { User } = store.getState();

		return new Promise((resolve, reject) => {
			const allowedPaths = [ "/login" ];

			const authenticationPaths = [ `${ this.baseUrl }/auth/login` ];

			// do not push user if they are on a page we are expecting 401 on
			if (response.status === 401) {
				if (User?.user) {
					store.dispatch(logoutUser());
				}

				if (allowedPaths.indexOf(history.location.pathname) === -1) {
					history.push("/login");
				}

				// If we have a 401 to the auth endpoint, allow user to continue to input 2fa token
				if (authenticationPaths.includes(response.url)) {
					return resolve(response);
				}

				return reject("Your session may have expired and you are not logged in. Please log in again to continue.");
			}

			return resolve(response);
		});
	}
}

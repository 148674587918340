import Radium from "radium";
import * as React from "react";
import { DragSource, DragSourceCollector, DragSourceMonitor, DragSourceSpec } from "react-dnd";

import { ActionSetActions, ActionType, ComponentsViewTypes, CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { actionIdentifier } from "Data/Objects/Actions";

const { black, darkGray, lightishGray, white } = Colors;

const source: DragSourceSpec<DraggableNewComponentCardProps, DraggableNewComponentCardDragObject> = {
	beginDrag(props: DraggableNewComponentCardProps) {
		const { actionType, type } = props;

		return {
			actionType,
			type
		}
	},
	endDrag(props: DraggableNewComponentCardProps, monitor: DragSourceMonitor) {
		const dropResult = monitor.getDropResult();
		if (dropResult) {
			return monitor.getItem();
		}

		return null;
	}
}

const collector: DragSourceCollector<DraggableNewComponentCardCollectedProps> = (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource(),
		currentlyDragging: monitor.isDragging()
	}
}

interface SpecificProps {
	border: string;
	title: string;
	icon: string;
	rotate?: number;
	background: string;
}

export interface DraggableNewComponentCardCollectedProps {
	currentlyDragging?: boolean,
	connectDragSource?: Function,
}

export interface DraggableNewComponentCardDragObject {
	actionType: ActionType
}

export interface DraggableNewComponentCardProps {
	disabled?: boolean,
	currentlyDragging?: boolean,
	connectDragSource?: Function,
	actionType: ActionType,
	type: ComponentsViewTypes
}

@Radium
class DraggableNewComponentCard extends React.Component<DraggableNewComponentCardProps> {
	constructor(props: DraggableNewComponentCardProps) {
		super(props);

		this.styles = {
			container: {
				marginBottom: 10
			},
			base: {
				padding: "1.5rem 0",
				textAlign: "center",
				textShadow: "0 1px 1px rgba(0, 0, 0, 0.2)",
				boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
				transition: "background 500ms",
				borderRadius: 5,
				background: black,
				":hover": {
					boxShadow: "0 3px 5px rgba(0, 0, 0, 0.5)",
					cursor: "move"
				}
			},
			label: {
				marginTop: "0.4rem",
				fontSize: "0.7rem"
			}
		}
	}

	styles: {
		container: CustomCSS;
		base: CustomCSS;
		label: CustomCSS;
	}

	render() {
		const { currentlyDragging, disabled, actionType, connectDragSource } = this.props;
		const { container, base, label } = this.styles;

		if (currentlyDragging) {
			return null;
		}

		const specificProps: SpecificProps = Object.assign(
			{
				background: Colors.black,
				border: disabled ? `2px solid ${ darkGray }` : `2px solid ${ lightishGray }`,
				title: "",
				icon: ""
			},
			actionType && ActionSetActions[actionType]
		);

		const { border, title, icon, rotate } = specificProps;

		const content = (
			<div
				key={ actionType || "" }
				style={{
					...container,
					pointerEvents: disabled ? "none" : "auto"
				}}
			>
				<div style={{
					...base,
					border,
					color: disabled ? darkGray : white
				}}>
					<Icon
						name={ icon }
						size="small"
						rotate={ rotate }
					/>
					<div style={ label }>
						{ title }
					</div>
				</div>
			</div>
		);

		if (connectDragSource) {
			return connectDragSource(content);
		}

		return content;
	}
}

export default DragSource(actionIdentifier, source, collector)(Radium(DraggableNewComponentCard));

import { Action, ActiveTagDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ActiveStringArrayState } from "Data/Objects/AppState";
import { initialActiveTagStates } from "Data/Objects/UI";
import { createReducer, setState } from "Data/Utils";

export function setActiveTags(state: ActiveStringArrayState, action: Action<ActiveTagDispatch>) {
	const { path, tags } = action.args;

	return setState(state, path, tags);
}

export function resetActiveTags(state: ActiveStringArrayState, action: Action<null>) {
	return new ActiveStringArrayState(initialActiveTagStates);
}

const reducers = {
	[ACTION_TYPES.UI.SET_ACTIVE_TAGS.type]: setActiveTags,
	[ACTION_TYPES.UI.RESET_ACTIVE_TAGS.type]: resetActiveTags
};

export default createReducer(reducers, ActiveStringArrayState, initialActiveTagStates);

import { Switch } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, TriggerBoxing } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { getSelectedTriggerBoxing } from "Data/Selectors/ActionBuilder";
import { Colors } from "Components/Global/Constants";
import { updateSelectedTriggerBoxing } from "Data/Actions/ActionsAsync";
import ColorPickerButton from "Components/Global/ColorPickerButton";

interface DetectionBoxPropertiesProps {
	boxing: TriggerBoxing;
	updateBoxing: (value: TriggerBoxing) => void;
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateBoxing: (boxing: TriggerBoxing) =>
			dispatch(updateSelectedTriggerBoxing(boxing))
	}
}

const mapStateToProps = (state) => {
	return {
		boxing: getSelectedTriggerBoxing(state)
	}
};

class DetectionBoxProperties extends React.Component<DetectionBoxPropertiesProps> {
	constructor(props: DetectionBoxPropertiesProps) {
		super(props);

		this.styles = {
			colorPickerContainer: {
				marginBottom: 14,
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			},
			description: {
				fontSize: "0.95em",
				color: Colors.slate,
				marginTop: 5,
				marginBottom: 20
			},
			floatRight: {
				float: "right"
			},
			label: {
				display: "inline-block"
			},
			row: {
				margin: "35px 0px 7px"
			},
			switchButton: {
				marginLeft: 87
			}
		};

		this.updateBoxEnabled = this.updateBoxEnabled.bind(this);
		this.updateBoxColor = this.updateBoxColor.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { boxing } = this.props;

		if (!boxing) {
			return null;
		}

		const { enabled } = boxing;
		const { label, row, switchButton, description } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }><Icon name="expand" /> Detection Box:</div>
				<Switch
					style={ switchButton }
					checked={ enabled }
					onChange={ this.updateBoxEnabled }
				/>
				<p style={ description }>
					Draws a colored outline box around the detected area.
				</p>
				{this.renderColorPicker()}
			</div>
		);
	}

	renderColorPicker() {
		const { boxing } = this.props;
		const { enabled, color } = boxing;
		const { colorPickerContainer, floatRight } = this.styles;

		if (!enabled) {
			return null;
		}

		return (
			<div>
				<span>Box Color:</span>
				<span style={ floatRight }>{ color }</span>
				<div style={ colorPickerContainer }>
					<ColorPickerButton
						key={ color }
						startColor={ color }
						onColorSelected={ this.updateBoxColor }/>
				</div>
			</div>
		);
	}

	updateBoxColor(color: string) {
		const { boxing, updateBoxing } = this.props;
		updateBoxing({ ...boxing, color });
	}

	updateBoxEnabled(enabled: boolean) {
		const { boxing, updateBoxing } = this.props
		updateBoxing({ ...boxing, enabled });
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DetectionBoxProperties);

import * as React from "react";

import { isEqual } from "lodash";
import { Notifications } from "@connect/Notifications";
import ErrorDescription, { SingleError } from "Components/Global/ErrorDescription";
import FileHandler from "Components/Global/FileHandler";

export class CsvResults {
	headers: any[];
	data: any[];
}

export class CsvHandler extends FileHandler {
	onSuccess: (results: {headers: any[], data: any[]}) => void;
	requiredHeaders?: string[];

	handleFiles(files: File[]) {
		if (files.length) {
			let [ file ] = files;
			if (this.isCsv(file)) {
				let fileReader = new FileReader();
				fileReader.onprogress = (e: ProgressEvent) => {
					// Progress events here
					// Nothing is probably going to happen here,
					// unless we wire up with RxJS
				}
				fileReader.onload = (readerEvent: ProgressEvent & { target: FileReader }) => {
					const text = readerEvent.target.result as string;
					const csvData = this.getRawCSV(text);
					if (csvData) {
						this.onSuccess(csvData);
					}
				}
				fileReader.onerror = (error: ProgressEvent) => {
					Notifications.error("CSV Error", <ErrorDescription error={ fileReader.error as SingleError } />);
				}
				fileReader.readAsText(file);
			} else {
				Notifications.error("File Type Error", "File must be a .csv");
			}
		}
	}

	isCsv(file: File) {
		const { type } = file;
		const csvTypes = [ "text/comma-separated-values", "text/csv", "application/csv", "application/vnd.ms-excel" ];
		return csvTypes.includes(type);
	}

	getRawCSV(text: string) {
		const delimiter = ",";
		const newLine = "\n";
		const blank = "";

		let lines = text.split(newLine);
		if (lines.length) {
			let headers = lines[0].split(delimiter).map((value) => value.trim());

			if (this.requiredHeaders && !isEqual(this.requiredHeaders, headers)) {
				Notifications.error("Headers must match the defined template. Please adjust your csv and try again.");
				return null;
			}

			let rows = lines
				.slice(1)
				.filter((row) => row
					.split(delimiter)
					.join(blank)
					.trim())
				.map((row) => row.split(delimiter));

			return {
				headers: headers,
				data: rows
			};
			/* This comment is left here in the hopes we will rewrite this to do more with the data
			rows.map((row:string) => {
				let items = row.split(delimiter);
				if (items.length === numColumns) {
					// Only allow if the number of columns in a row matches the headers
					let test = {};
					items.forEach((item, index) => {
						test[headers[index]] = items[index];
					});
				}
			}); */
		}
		return null;
	}
}
import * as moment from "moment";

import { notifyBugSnag, notifyBugSnagAsync } from "@connect/BugSnag";
import { getState } from "@connect/Data";
import {
	Action, BulkCheckinResult, DeviceGroupChildren, DeviceModel,
	ErrorResultV2, IDevice, IDeviceGroup, PaginatedDataResult, UUIDResult
} from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import DeviceApiV2 from "Api/Devices";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { removeActiveSelection, removeActiveSelectionItem, setActiveSelection, setAsyncFetching,
	setAsyncState } from "Data/Actions/UI";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { AllGroup, Device } from "Data/Objects/Devices";
import { DispatchableAction } from "Data/Objects/DispatchableAction";
import { CacheInvalidationPeriod } from "Data/Objects/Global";
import { getAllDevices, getDeviceById, getDeviceGroupById,
	getDeviceGroups } from "Data/Selectors/Devices";
import SystemApi from "Api/System";

/**
 * Simple Actions
 */

const {
	SET_DEVICE_MODELS,
	SET_DEVICES,
	UPDATE_DEVICE,
	DELETE_DEVICE,
	CREATE_DEVICE,
	SET_DEVICE_GROUPS,
	UPDATE_DEVICE_GROUP,
	DELETE_DEVICE_GROUP,
	SELECT_DEVICES,
	RESET_DEVICES,
	CREATE_DEVICE_GROUP
} = ACTION_TYPES.Devices;

export function resetDevices(): Action<null> {
	return new DispatchableAction(RESET_DEVICES, null);
}

export function setDeviceModels(deviceModels: DeviceModel[]) {
	return { type: SET_DEVICE_MODELS.type, args: { deviceModels }};
}

export function setDevices(devices: IDevice[], reset: boolean, companyUuid: string) {
	return {
		type: SET_DEVICES.type,
		args: {
			devices,
			reset,
			companyUuid
		}
	}
}

export function createDevice(device: IDevice) {
	return {
		type: CREATE_DEVICE.type,
		args: {
			device
		}
	}
}

export function updateDevice(device: IDevice) {
	return {
		type: UPDATE_DEVICE.type,
		args: {
			device
		}
	}
}

export function deleteDevice(device: IDevice, companyUuid?: string) {
	return {
		type: DELETE_DEVICE.type,
		args: {
			device,
			companyUuid
		}
	}
}

export function createDeviceGroup(deviceGroup: IDeviceGroup) {
	return {
		type: CREATE_DEVICE_GROUP.type,
		args: {
			deviceGroup
		}
	}
}

export function setDeviceGroups(deviceGroups: IDeviceGroup[], reset: boolean) {
	return {
		type: SET_DEVICE_GROUPS.type,
		args: {
			deviceGroups,
			reset
		}
	}
}

export function updateDeviceGroup(deviceGroup: IDeviceGroup) {
	return {
		type: UPDATE_DEVICE_GROUP.type,
		args: {
			deviceGroup
		}
	}
}

export function deleteDeviceGroup(deviceGroup: IDeviceGroup) {
	return {
		type: DELETE_DEVICE_GROUP.type,
		args: {
			deviceGroup
		}
	}
}

export function selectDevices(devices: IDevice[]) {
	return {
		type: SELECT_DEVICES.type,
		args: {
			devices
		}
	}
}

/**
 * Async Actions
 */

export function getDeviceTypes() {
	return (dispatch) => {
		const api = new SystemApi();

		return api.getDeviceModels()
			.then((result: DeviceModel[]) => {
				dispatch(setDeviceModels(result));
			}, (error) => {
				dispatch(errorNotification("Error loading device types.", error));
			})
			.catch(error => notifyBugSnag(new Error(error)));
	};
}

export function tryFetchDevicesAsync(companyUuid?: string, admin?: boolean) {
	const { Company: { activeCompanyId }, UI: { asyncState } } = getState();
	const { currentPage, currentlyFetching, lastFetch, haveAllData, lastFetchedCompany } = asyncState.devices;
	const uuid = (companyUuid || activeCompanyId);
	const changedCompanies = uuid !== lastFetchedCompany;
	const expired =  moment().diff(lastFetch, "minute") > CacheInvalidationPeriod;
	const shouldInvalidateCache = changedCompanies || expired;

	return (dispatch) => {

		if (currentlyFetching) {
			return Promise.resolve();
		}

		if (haveAllData && !changedCompanies) {
			return Promise.resolve();
		}

		const page = shouldInvalidateCache ? 0 : currentPage;

		if (currentPage === null || currentPage === undefined) {
			return dispatch(fetchDevicesPageAsync(1, shouldInvalidateCache, uuid, admin));
		}

		// If no conditions are met, dispatch a load for the next page
		return dispatch(fetchDevicesPageAsync(page + 1, shouldInvalidateCache, uuid, admin));
	}
}

export function fetchDevicesPageAsync(pageNumber: number, shouldInvalidate: boolean,
	companyUuid: string, admin?: boolean) {
	return (dispatch) => {
		// Set the devices async data
		dispatch(setAsyncFetching("devices", true, companyUuid));

		const api = new DeviceApiV2();

		return api.getDevices(companyUuid, 25, pageNumber, admin)
			.then((result: PaginatedDataResult<IDevice>) => {
				// Handle API results
				const { current_page, last_page } = result.meta;
				dispatch(setAsyncState("devices", current_page === last_page, current_page)); // eslint-disable-line camelcase
				dispatch(setDevices(result.data, shouldInvalidate, companyUuid));
				dispatch(setAsyncFetching("devices", false, companyUuid));
			}, (error) => dispatch(setAsyncFetching("devices", false, companyUuid)))
			.catch((error) => {
				dispatch(setAsyncFetching("devices", false, companyUuid));
				dispatch(notifyBugSnag(new Error(error)));
			});
	};
}

export function checkInDevicesAsync(devices: Partial<IDevice>[]) {
	const joined = devices.join("\n");
	return (dispatch) => {
		const api = new DeviceApiV2();

		return api.checkinDevices(joined)
			.then((result: BulkCheckinResult[]) => {
				const uuids = result
					.filter(res => res.success)
					.map(r => r.uuid);
				const newDevices = devices
					.map((device, index) => {
						return new Device({
							serial: device[0],
							model: device[1],
							name: device[2],
							uuid: result[index].uuid
						});
					})
					.filter(d => d.uuid && uuids.includes(d.uuid));

				// Create a list of errored devices
				result
					.filter((currResult: BulkCheckinResult) => {
						return !currResult.success
					})
					.map((currResult: BulkCheckinResult) => {
						dispatch(errorNotification("Error Checking In Device", currResult.name))
					});

				dispatch(setDevices(newDevices, false, ""));
				dispatch(successNotification("Bulk Add Devices Succeeded!"));
			}, (error) => {
				dispatch(errorNotification("Bulk Add Devices Failed", error));
			})
			.catch(error => notifyBugSnag(new Error(error)));
	}
}

export function createDeviceAsync(device: Partial<IDevice>) {
	return (dispatch) => {
		const { serial, cec_serial, model } = device;

		const d = new Device({
			uuid: Utils.getGuid(),
			name: cec_serial,
			type: model,
			cec_serial,
			serial,
			model
		});

		const api = new DeviceApiV2();

		api.checkinDevice(d)
			.then((result: UUIDResult) => {
				const { uuid } = result;

				dispatch(setDevices([ new Device({
					...d,
					uuid
				}) ], false, ""));
			}, (error) => dispatch(errorNotification("Could not Check In Device")))
			.catch(error => notifyBugSnag(new Error(error)));
	}
}

export function releaseDevicesAsync(devices: IDevice[], command: "releaseDevice" | "removeDevice") {
	const action = command === "releaseDevice" ? "released" : "removed";
	return (dispatch) => {
		let completed = 0;
		let hasFailed = false;

		let successTitle = `Device ${ action }`;
		let successMessage = "";

		let errorTitle = `Device not ${ action }`;

		if (devices.length > 1) {
			successTitle = successTitle.replace("Device", "Devices");
			successMessage = `${ devices.length } devices have been ${ action }.`;
			errorTitle = `Some devices could not be ${ action }.`;
		}

		const notifyOnCompleted = () => {
			if (completed === devices.length) {
				if (!hasFailed) {
					dispatch(successNotification(successTitle, successMessage));
				} else {
					dispatch(errorNotification(errorTitle));
				}
			}

			dispatch(setActiveSelection("devices", []));
		}

		const api = new DeviceApiV2();

		devices.forEach((device) => {
			api[command](device.uuid)
				.then(() => {
					dispatch(deleteDevice(device, device.company));
					dispatch(removeActiveSelectionItem("devices", device.uuid));
					completed++;
					notifyOnCompleted();
				}, (error) => {
					completed++;
					hasFailed = true;
					notifyOnCompleted();
				})
				.catch(error => notifyBugSnag(new Error(error)));
		});
	}
}

export function updateDeviceAsync(device: IDevice) {
	return (dispatch) => {
		const api = new DeviceApiV2();
		const state = getState();
		const oldDevice = getDeviceById(state, device.uuid);

		api.updateDevice(device)
			.then((result) => {
				dispatch(updateDevice(device));
			}, (error) => {
				dispatch(errorNotification("Error updating device.", error));
				dispatch(notifyBugSnagAsync(error));
				dispatch(updateDevice(oldDevice));
			})
			.catch((error) => {
				dispatch(errorNotification(error));
			});
	}
}

export function tryFetchDeviceGroupsAsync() {
	return (dispatch, getDataState) => {
		const { Company, UI: { asyncState } } = getDataState();
		const { currentPage, currentlyFetching, lastFetch, haveAllData, lastFetchedCompany } = asyncState.deviceGroups;
		const { activeCompanyId } = Company;

		const changedCompanies = lastFetchedCompany && activeCompanyId !== lastFetchedCompany;
		const expired = !lastFetch || moment().diff(lastFetch, "minute") > CacheInvalidationPeriod;
		const shouldInvalidateCache = changedCompanies || expired;

		if (currentlyFetching) {
			return Promise.resolve();
		}

		if (haveAllData && !changedCompanies) {
			return Promise.resolve();
		}

		if (currentPage === null || currentPage === undefined) {
			return dispatch(fetchDeviceGroupsAsync(1, shouldInvalidateCache));
		}

		// If no conditions are met, dispatch a load for the next page
		const pageToFetch = shouldInvalidateCache ? 1 : currentPage + 1;
		return dispatch(fetchDeviceGroupsAsync(pageToFetch, shouldInvalidateCache));
	}
}

export function fetchDeviceGroupsAsync(pageNumber: number, shouldInvalidate: boolean) {
	return (dispatch, getDataState) => {
		const { activeCompanyId } = getDataState().Company;
		dispatch(setAsyncFetching("deviceGroups", true, activeCompanyId));
		// Set the devices async data

		const api = new DeviceApiV2();

		api.getDeviceGroups(25, pageNumber)
			.then((result: PaginatedDataResult<IDeviceGroup>) => {
				// Handle API results
				const { current_page, last_page } = result.meta;
				const haveAllData = current_page === last_page; // eslint-disable-line camelcase

				dispatch(setAsyncState("deviceGroups", haveAllData, current_page));
				dispatch(setDeviceGroups(result.data, shouldInvalidate));
				dispatch(setAsyncFetching("deviceGroups", false, activeCompanyId));

				if (!haveAllData) {
					dispatch(tryFetchDeviceGroupsAsync());
				}
			},
			(error) => {
				dispatch(setAsyncFetching("deviceGroups", false));
			})
			.catch((error) => {
				dispatch(setAsyncFetching("deviceGroups", false));
			});
	};
}

export function updateDeviceGroupAsync(deviceGroup: IDeviceGroup) {
	return (dispatch) => {
		const api = new DeviceApiV2();
		const state = getState();
		const oldDeviceGroup = getDeviceGroupById(state, deviceGroup.uuid);

		return api.updateDeviceGroup(deviceGroup, deviceGroup.name)
			.then((result) => {
				dispatch(updateDeviceGroup(deviceGroup));
			}, (error) => {
				dispatch(errorNotification("Error updating device group.", error));
				dispatch(notifyBugSnagAsync(error));
				dispatch(updateDeviceGroup(oldDeviceGroup || {} as IDeviceGroup));
			})
			.catch((error) => dispatch(notifyBugSnagAsync(error)));
	}
}

export function deleteDeviceGroupAsync(deviceGroup: IDeviceGroup, force?: boolean) {
	function getAllDeviceGroupChildren(children: string[], groups: IDeviceGroup[]): IDeviceGroup[] {
		return children.reduce((allChildren, id) => {
			const group = groups.find(({ uuid }) => uuid === id) || {} as IDeviceGroup;
			const recursiveChildren = getAllDeviceGroupChildren(group.children, groups);

			allChildren.push(...recursiveChildren, group);

			return allChildren;
		}, [] as IDeviceGroup[])
	}

	return (dispatch) => {
		const api = new DeviceApiV2();

		api.deleteDeviceGroup(deviceGroup, force)
			.then((result: ErrorResultV2) => {
				if (!result.message) {
					dispatch(deleteDeviceGroup(deviceGroup));

					// the API does all of this behind the scenes but we need to sync the UI
					if (force) {
						const state = getState();
						const allGroups = getDeviceGroups(state);
						const devices = getAllDevices(state);
						const children = getAllDeviceGroupChildren(deviceGroup.children, allGroups);
						const childIds = children.map((group) => group.uuid);

						// delete all children
						children.forEach((group) => {
							dispatch(deleteDeviceGroup(group));
						});

						// add the initial device group back so we can properly unassign devices
						const filtered = devices.filter(({ deviceGroup: group }) =>
							[ ...childIds, deviceGroup.uuid ].includes(group.uuid));

						// unassign group and child groups from all devices on which they were set
						filtered.forEach((device) => {
							dispatch(updateDevice(Object.assign({}, device, {
								deviceGroup: {
									uuid: AllGroup.uuid,
									name: AllGroup.name
								}
							})));
						});
					}
				}
			},
			(error) => {
				const errorMessage = `
					${ deviceGroup.name } was unable to be deleted
					because it contains other groups and/or devices.

					Click Confirm to delete all groups and/or remove selected
					devices (devices that can not be deleted).
				`;

				Notifications.confirm(
					"Confirm Delete Group",
					errorMessage,
					"Confirm",
					"Cancel",
					() => dispatch(deleteDeviceGroupAsync(deviceGroup, true))
				);
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(error));
			});
	}
}

export function createDeviceGroupAsync(groupName: string, selectedGroup: IDeviceGroup, children?: DeviceGroupChildren) {
	return (dispatch) => {
		const api = new DeviceApiV2();

		api.createDeviceGroup(groupName, selectedGroup)
			.then((result: IDeviceGroup) => {
				const state = getState();
				const stateDevices = getAllDevices(state);
				const stateGroups = getDeviceGroups(state);
				let deviceGroups, devices;
				if (children) {
					({deviceGroups, devices} = children)
				}

				// update also functions as create if the device group doesn't exist yet
				dispatch(updateDeviceGroup(Object.assign({}, result, {
					name: groupName,
					children: [],
					parent: selectedGroup ? selectedGroup.uuid : null
				})));

				const mappedDevices = stateDevices.filter((device) => devices.includes(device.uuid));
				const mappedGroups = stateGroups.filter((group) => deviceGroups.includes(group.uuid));

				// assign the selected child groups and devices to our new group
				if (children && (children.deviceGroups || children.devices)) {
					dispatch(assignDevicesAndGroupsAsync(mappedDevices, mappedGroups, result));
				}

				dispatch(setActiveSelection("devices", []));
				dispatch(setActiveSelection("deviceGroups", []));
				dispatch(removeActiveSelection("deviceGroups", []));
				dispatch(successNotification(`Created device group ${ groupName }.`))
			},
			(error) => {
				dispatch(notifyBugSnagAsync(error));
			})
			.catch((error) => dispatch(notifyBugSnagAsync(error)));
	};
}

export function assignDevicesAndGroupsAsync(devices: IDevice[], groups: IDeviceGroup[], targetGroup: IDeviceGroup) {
	return (dispatch) => {
		if (devices && devices.length) {
			const api = new DeviceApiV2();
			const completeAction = () => {
				dispatch(successNotification("Successfully updated selection!"));
				dispatch(setActiveSelection("devices", []));
				dispatch(setActiveSelection("deviceGroups", []));
			}

			if (String(targetGroup.uuid) === "0") {
				devices.forEach((device) => {
					if (device.deviceGroup.uuid) {
						api.detachDevices([ device.uuid ], device.deviceGroup.uuid).then(() => {
							const newDevice = Object.assign({}, device, {
								deviceGroup: {
									uuid: AllGroup.uuid,
									name: AllGroup.name
								}
							});

							dispatch(updateDevice(newDevice));
						});
					}
					completeAction();
				});
			} else {
				api.attachDevices(devices.map(({ uuid }) => uuid), targetGroup.uuid)
					.then(() => {
						devices.forEach((device) => {
							const newDevice = Object.assign({}, device, {
								deviceGroup: {
									name: targetGroup.name,
									uuid: targetGroup.uuid
								}
							});

							dispatch(updateDevice(newDevice));
						});
						completeAction();
					}, (error) => {
						dispatch(notifyBugSnagAsync(error))
					})
					.catch((error) => {
						dispatch(errorNotification(error))
					});
			}
		}

		if (groups && groups.length) {
			groups.map(assignGroup(targetGroup)).forEach(dispatch);
		}
	}
}

function assignGroup(targetGroup: IDeviceGroup) {
	return (group: IDeviceGroup) => {
		const { uuid: targetUuid } = targetGroup;

		if (checkDeviceGroupHelper(targetGroup, group)) {
			return errorNotification("Grouping Error", "You cannot assign a group to one of its descendants");
		}

		if (targetUuid && group.uuid !== targetUuid) {
			const newGroup = Object.assign({}, group, {
				// if "0" (all) is chosen, set parent to null
				parent: String(targetUuid) !== "0" ? targetUuid : null
			});

			return updateDeviceGroupAsync(newGroup);
		}

		return updateDeviceGroupAsync(group);
	}
}

function checkDeviceGroupHelper(group: IDeviceGroup, parentGroup: IDeviceGroup) {
	// Check if the current device group is a child of the parent or is the parent
	if (group.uuid === parentGroup.uuid || group.parent === parentGroup.uuid) {
		return true;
	}
	// Map the function out onto all the child groups, reduce it, and return
	if (parentGroup.children && parentGroup.children.length > 0) {
		return parentGroup.children
			.map(getDeviceGroup)
			.map((childGroup) => {
				return checkDeviceGroupHelper(group, childGroup || {} as IDeviceGroup);
			})
			.reduce((val1, val2) => {
				return val1 || val2;
			});
	}

	// Otherwise return false
	return false;
}

function getDeviceGroup(uuid: string) {
	return getDeviceGroupById(getState(), uuid);
}

export function fetchUpdatedDeviceAsync(uuid: string) {
	return (dispatch) => {
		const api = new DeviceApiV2();
		return api.getDevice(uuid)
			.then((result: IDevice) => {
				let device = result;

				dispatch(updateDevice(device));
			}, (error) => {
				dispatch(notifyBugSnagAsync(error));

				Utils.throwIfNoQueryResults(error);
			})
			.catch((error) => {
				dispatch(errorNotification(error));

				Utils.throwIfNoQueryResults(error);
			});
	}
}
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, IAd, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import AnalyticsGrid from "Components/Analytics/AnalyticsGrid";
import Loader from "Components/Global/Loader";
import { setAdsAsync } from "Data/Actions/AdsAsync";
import { getFilteredSortedAnalyticsAds } from "Data/Selectors/AdAnalytics";
import { getAdsAsyncQueryState, getNestedAsyncState } from "Data/Selectors/Async";
import { getSelectAdsModalVisibility } from "Data/Selectors/UI";
import { getActiveFilters, getActiveSorts } from "Data/Selectors/UI";

const mapDispatchToProps = (dispatch) => ({
	fetchAds: () => dispatch(setAdsAsync(true)),
	viewAnalytics: (uuid: string) => dispatch(push(`/analytics/${ uuid }`))
});

const mapStateToProps = (state, ownProps) => {
	const sortType = getActiveSorts(state, Sorts.ANALYTICS) as SortTypes;
	const sortAndFilter = { sortType, withAnalytics: true };
	const asyncState = getAdsAsyncQueryState(sortAndFilter);
	const asyncAds = getNestedAsyncState(state, asyncState);
	const { currentlyFetching, currentPage, haveAllData } = asyncAds;
	const { filter } = getActiveFilters(state, Filters.ANALYTICS);

	return {
		ads: getFilteredSortedAnalyticsAds(state),
		adsModalVisible: getSelectAdsModalVisibility(state),
		canFetch: !currentlyFetching && !haveAllData,
		filter,
		lastFetchedPage: currentPage,
		sort: sortType as string
	};
};

interface AnalyticsContentAreaProps {
	ads: IAd[];
	adsModalVisible: boolean;
	canFetch: boolean;
	fetchAds: () => void;
	filter: string;
	lastFetchedPage: number;
	onCreateNewAnalytics: () => void;
	selectModeOn: boolean;
	sort: string;
	viewAnalytics: (uuid: string) => void;
}

export class AnalyticsContentArea extends React.Component<AnalyticsContentAreaProps> {
	constructor(props: AnalyticsContentAreaProps) {
		super(props);

		this.styles = {
			loader: {
				height: 40,
				margin: "40px auto 0",
				textAlign: "center",
				width: "100%"
			}
		}

		this.fetchAds = this.fetchAds.bind(this);
		this.viewAnalytics = this.viewAnalytics.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { ads, canFetch, filter, lastFetchedPage, onCreateNewAnalytics, selectModeOn, sort } = this.props;
		const display = canFetch ? "inline-block" : "none";
		const key = filter + sort + ads.length;

		return (
			<React.Fragment>
				<AnalyticsGrid
					content={ ads }
					key={ key }
					minWidth="286px"
					onCreateNewAnalytics={ onCreateNewAnalytics }
					selectModeOn={ selectModeOn }
				/>
				<div style={{ ...this.styles.loader, display }}>
					<Waypoint key={ lastFetchedPage } onEnter={ this.fetchAds } />
					<Loader />
				</div>
			</React.Fragment>
		);
	}

	fetchAds() {
		const { canFetch, adsModalVisible, fetchAds } = this.props;

		if (canFetch && !adsModalVisible) {
			fetchAds();
		}
	}

	viewAnalytics(ad: IAd) {
		return () => this.props.viewAnalytics(ad.uuid);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsContentArea);

import { Action } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { AdminActivityState } from "Data/Objects/AppState";
import { addDataToState, createReducer, setState } from "Data/Utils";

export function setActivities(state: AdminActivityState, action: Action<any>) {
	const { reset, activities } = action.args;
	if (reset) {
		return setState(state, "activities", activities);
	}

	return addDataToState(state, "activities", activities);
}

const {
	SET_ACTIVITIES
} = ACTION_TYPES.Admin.Activities;

const reducers = {
	[SET_ACTIVITIES.type]: setActivities
};

export default createReducer(reducers, AdminActivityState);

import { Colors } from "Components/Global/Constants";
import { Media } from "Data/Objects/Media";

export const AdColors = {
	ticker: {
		background: "#748191",
		border: "#259a56"
	},
	image: {
		background: "#748191",
		border: "#146aa2"
	},
	video: {
		background: "#748191",
		border: "#74348a"
	},
	feed: {
		background: "#fd161d",
		border: "#bb3425"
	},
	slideshow: {
		background: "#748191",
		border: Colors.primaryBlue
	}
}

export const FullComponentHeight = 100;

export const MaxDurationSeconds = 900;

export const MaxTickerHeightPercent = (300 / 1920) * 100;

export const MinimumComponentHeight = 5;

export const NamePlaceholder = "Untitled Ad";

export const RatioHeightPercents = {
	nineBySixteen: 100, // 100%
	oneByOne: (9 / 16) * 100, // 56.25%
	fourByThree: ((9 / (4 / 3)) / 16) * 100, // 42.1875%
	sixteenByNine: ((9 / (16 / 9)) / 16) * 100 // 31.640625%
}

export const SecurityBanners = [
	{
		uuid: "0",
		uri: require("../../Images/Banners/AntiTheft_Device_2.png")
	},
	{
		uuid: "1",
		uri: require("../../Images/Banners/Notice_VideoMonitoring.png")
	},
	{
		uuid: "2",
		uri: require("../../Images/Banners/REC_dot.png")
	},
	{
		uuid: "3",
		uri: require("../../Images/Banners/Recording_In_Progress.png")
	},
	{
		uuid: "4",
		uri: require("../../Images/Banners/Security_Camera_In_Use.png")
	},
	{
		uuid: "5",
		uri: require("../../Images/Banners/Smile_Banner.png")
	},
	{
		uuid: "6",
		uri: require("../../Images/Banners/Video_Monitoring.png")
	},
	{
		uuid: "7",
		uri: require("../../Images/Banners/Warning_24hour_Surveillance.png")
	},
	{
		uuid: "8",
		uri: require("../../Images/Banners/Monitoring_In_Progress.png")
	}
] as Media[];

export const UnusedSpaceWarningThreshold = 80;

import { Button, Input, Tag, Tooltip } from "antd";
import * as update from "immutability-helper";
import * as React from "react";

interface EditableTagGroupProps {
	collection?: string[];
	maxLength?: number;
	onChange?: (tags: string[]) => void;
}

interface EditableTagGroupState {
	tags: string[];
	inputVisible: boolean;
	inputValue: string;
}

// eslint-disable-next-line
function noop() {}

class EditableTagGroup extends React.PureComponent<EditableTagGroupProps, EditableTagGroupState> {

	constructor(props: EditableTagGroupProps) {
		super(props);

		this.state = {
			tags: props.collection || [],
			inputVisible: false,
			inputValue: ""
		}

		this.handleClose = this.handleClose.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputConfirm = this.handleInputConfirm.bind(this);
		this.handleTagsPostChange = this.handleTagsPostChange.bind(this);
		this.setInputRef = this.setInputRef.bind(this);
		this.showInput = this.showInput.bind(this);
		this.renderTag = this.renderTag.bind(this);
	}

	public static defaultProps: EditableTagGroupProps = {
		collection: [],
		maxLength: 20,
		onChange: noop
	};

	_input: Input;

	render() {
		const { tags, inputVisible, inputValue } = this.state;

		return (
			<div>
				{ tags.map(this.renderTag) }
				{inputVisible && (
					<Input ref={ this.setInputRef }
						className="rounded"
						style={{
							backgroundColor: "transparent",
							color: "rgba(255,255,255,0.67)",
							borderStyle: "dashed",
							width: 78
						}}
						type="text"
						size="small"
						value={ inputValue }
						onChange={ this.handleInputChange }
						onBlur={ this.handleInputConfirm(false) }
						onPressEnter={this.handleInputConfirm(true)}
					/>
				)}
				{!inputVisible && (
					<Button ghost
						size="small"
						style={{
							color: "rgba(255,255,255,0.67)"
						}}
						type="dashed"
						onClick={this.showInput}
					>
						+ New Tag
					</Button>
				)}
			</div>
		);
	}

	renderTag(tag: string, index: number) {
		const isLongTag = tag.length > (this.props.maxLength || 0);
		const tagElem = (
			<Tag key={tag}
				closable={true}
				onClose={this.handleClose(tag)}
				style={{
					background: "rgba(255,255,255,0.67)",
					border: "0 none",
					color: "#2e333f"
				}}
			>
				{isLongTag ? `${tag.slice(0, this.props.maxLength)}...` : tag}
			</Tag>
		);

		return isLongTag ? <Tooltip key={tag} title={tag}>{tagElem}</Tooltip> : tagElem;
	}

	handleClose(removedTag: string) {
		return () => {
			const tags = this.state.tags.filter(tag => tag !== removedTag);
			this.setState((prevState) => {
				return update(prevState, {
					tags: { $set: tags }
				});
			}, this.handleTagsPostChange);
		};
	}

	handleInputChange(e: any) {
		let { value } = e.target;
		const maxLength = 25;

		if (value.length > maxLength) {
			value = value.substring(0, maxLength);
		}

		this.setState((prevState) => {
			return update(prevState, {
				inputValue: { $set: value }
			});
		});

	}

	handleInputConfirm(addAnother: boolean) {
		return () => {
			const { state } = this;
			let { inputValue } = state;
			let { tags } = state;

			inputValue = this.stripLeadingWhiteSpace(inputValue);
			inputValue = this.stripTrailingWhiteSpace(inputValue);

			if (inputValue && tags.indexOf(inputValue) === -1) {
				tags = [ ...tags, inputValue ];

				this.setState((prevState) => {
					return update(prevState, {
						tags: { $set: tags }
					});
				}, this.handleTagsPostChange);
			}

			this.setState((prevState) => {
				return update(prevState, {
					inputVisible: { $set: addAnother },
					inputValue: { $set: "" }
				});
			});
		};
	}

	handleTagsPostChange() {
		const { onChange } = this.props;

		if (onChange) {
			onChange(this.state.tags);
		}
	}

	setInputRef(input: Input) {
		this._input = input;
	}

	showInput() {
		this.setState((prevState) => {
			return update(prevState, {
				inputVisible: { $set: true }
			});
		}, () => this._input.focus());
	}

	stripLeadingWhiteSpace(s: string) {
		while (s.length && s[0] === " ") {
			s = s.substring(1);
		}

		return s;
	}

	stripTrailingWhiteSpace(s: string) {
		while (s.length && s[s.length - 1] === " ") {
			s = s.substring(0, s.length - 1);
		}

		return s;
	}
}

export default EditableTagGroup;
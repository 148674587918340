import * as deepEqual from "deep-equal";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";

import { IUser } from "@connect/Interfaces";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveCompanyId } from "Data/Selectors/Company";
import { getCurrentUser } from "Data/Selectors/User";

const mapStateToProps = (state) => ({
	user: getCurrentUser(state),
	activeCompany: getActiveCompanyId(state)
});

interface UserPermissionRouteProps extends RouteProps {
	activeCompany: string;
	user: IUser;
	permissions: string[];
	component: React.FunctionComponent;
}

export class UserPermissionRoute extends React.PureComponent<UserPermissionRouteProps> {
	constructor(props: UserPermissionRouteProps) {
		super(props);

		this.renderComponent = this.renderComponent.bind(this);
	}

	shouldComponentUpdate(nextProps: UserPermissionRouteProps) {
		if (!nextProps.user) {
			return true;
		}

		const { permissions, role, uuid, verified} = this.props.user;
		const { permissions: newPermissions, role: newRole, uuid: newUuid, verified: newVerified } = nextProps.user;
		return (
			uuid !== newUuid ||
			deepEqual(role, newRole) ||
			deepEqual(permissions, newPermissions) ||
			verified !== newVerified
		);
	}

	render() {
		return (
			<Route
				key={ this.props.activeCompany }
				component={ this.renderComponent }
				path={ this.props.path }
			/>
		);
	}

	renderComponent(props: RouteComponentProps<any> | any) {
		const { permissions = [ PERMISSIONS.SELF_VIEW ], component: CustomComponent, user } = this.props;

		if (permissions.some((perm) => hasPermission(perm, user))) {
			return <CustomComponent {...props} />
		}

		return <Redirect to={ user ? "/" : "/login" } />
	}
};

export default connect(mapStateToProps)(UserPermissionRoute);
import { Action, HealthReport, HealthReportDispatch, HealthReportDownloadingDispatch, HealthReportNotification,
	HealthReportNotificationDispatch, HealthReportReportsDispatch, HealthReportResultsDispatch,
	HealthReportUuidDispatch, PartialHealthReportResult } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";

const {
	CREATE_REPORT,
	DELETE_REPORT,
	GET_ALL_REPORTS,
	RESET_REPORTS,
	SET_REPORT_RESULT,
	SET_REPORT_DOWNLOADING,
	UPDATE_HEALTH_REPORTS_NOTIFICATION_SETTINGS,
	UPDATE_REPORT
} = ACTION_TYPES.HealthReports;

/**
 * Simple Actions
 */

function createReport(report: Partial<HealthReport>): Action<HealthReportDispatch> {
	return new DispatchableAction(CREATE_REPORT, { report });
}

function deleteReport(uuid: string): Action<HealthReportUuidDispatch> {
	return new DispatchableAction(DELETE_REPORT, { uuid });
}

function resetReports(): Action<null> {
	return new DispatchableAction(RESET_REPORTS, null);
}

function setAllReports(reports: HealthReport[], reset: boolean = false): Action<HealthReportReportsDispatch> {
	return new DispatchableAction(GET_ALL_REPORTS, { reports, reset });
}

function setReportResult(results: PartialHealthReportResult): Action<HealthReportResultsDispatch> {
	return new DispatchableAction(SET_REPORT_RESULT, { results });
}

function setReportDownloading(uuid: string, value: boolean): Action<HealthReportDownloadingDispatch> {
	return new DispatchableAction(SET_REPORT_DOWNLOADING, { uuid, value });
}

function updateNotification(
	uuid: string,
	notification: HealthReportNotification
): Action<HealthReportNotificationDispatch> {
	return new DispatchableAction(UPDATE_HEALTH_REPORTS_NOTIFICATION_SETTINGS, {
		uuid, settings: notification
	});
}

function updateReport(report: HealthReport): Action<HealthReportDispatch> {
	return new DispatchableAction(UPDATE_REPORT, { report });
}

export {
	createReport,
	deleteReport,
	resetReports,
	setAllReports,
	setReportResult,
	setReportDownloading,
	updateNotification,
	updateReport
};

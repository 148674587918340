import { Action, DeploymentDispatch, DeploymentListDispatch, DeploymentUuidDispatch,
	UserListDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DeploymentsState } from "Data/Objects/AppState";
import { createReducer, deleteFromState, getIndexFromState, mergeWithUuidArrays, pushMergeUpdate,
	setState, updateStateArrayItem, pushToState
} from "Data/Utils";
import { toggleFeature } from "@connect/Features";

const {
	CREATE_DEPLOYMENT,
	DELETE_DEPLOYMENT,
	RESET_DEPLOYMENTS,
	SET_DEPLOYMENT_DETAILS,
	SET_DEPLOYMENTS,
	SET_APPROVERS
} = ACTION_TYPES.Deployments;

export function createDeployment(state: DeploymentsState, action: Action<DeploymentDispatch>) {
	return pushToState(state, "deployments", action.args.deployment);
}

export function deleteDeployment(state: DeploymentsState, action: Action<DeploymentUuidDispatch>) {
	const index = getIndexFromState(state.deployments, action.args.deploymentId);

	return deleteFromState(state, "deployments", index);
}

export function resetDeployments(state: DeploymentsState, action: Action<null>) {
	return setState(state, "deployments", []);
}

export function setDeploymentDetails(state: DeploymentsState, action: Action<DeploymentDispatch>) {
	if (toggleFeature("notifications", true, false)) {
		return updateDeployment2(state, action);
	}

	return pushMergeUpdate(state, "deployments", action.args.deployment);
}

export function updateDeployment2(state: DeploymentsState, action: Action<DeploymentDispatch>) {
	const { deployment } = action.args;
	return updateStateArrayItem(state, "deployments", deployment);
}

export function setDeployments(state: DeploymentsState, action: Action<DeploymentListDispatch>) {
	const { deployments, reset } = action.args;

	if (reset) {
		return setState(state, "deployments", deployments);
	}

	return mergeWithUuidArrays(state, "deployments", deployments);
}

export function setApprovers(state: DeploymentsState, action: Action<UserListDispatch>) {
	const { users } = action.args;

	return setState(state, "approvers", users);
}

const reducers = {
	[CREATE_DEPLOYMENT.type]: createDeployment,
	[DELETE_DEPLOYMENT.type]: deleteDeployment,
	[RESET_DEPLOYMENTS.type]: resetDeployments,
	[SET_DEPLOYMENT_DETAILS.type]: setDeploymentDetails,
	[SET_DEPLOYMENTS.type]: setDeployments,
	[SET_APPROVERS.type]: setApprovers
};

export default createReducer(reducers, DeploymentsState);

import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IStore, Filters } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Button, Input, Link } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import StoreHours from "Components/Global/StoreHours";
import { deleteStoreAsync, updateStoreAsync } from "Data/Actions/Company";
import { setActiveFilterArray } from "Data/Actions/UI";
import { DefaultMaxLength, PostalCodeMaxLength } from "Data/Objects/Global";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { isEqual } from "lodash";
import { cloneDeep } from "lodash";

const mapDispatchToProps = (dispatch) => ({
	deleteStore: (currentStore: IStore) => dispatch(deleteStoreAsync(currentStore)),
	updateStore: (currentStore: IStore) => dispatch(updateStoreAsync(currentStore)),
	setStoreFilter: (store: IStore) => dispatch(setActiveFilterArray("stores", Filters.DEVICES, [ store.uuid ]))
});

interface IStoreInfoPanelProps {
	currentStore: IStore;
	deleteStore: (currentStore: IStore) => void;
	updateStore: (currentStore: IStore) => void;
	setStoreFilter: (store: IStore) => void;
}

class StoreInfoPanel extends React.Component<IStoreInfoPanelProps> {
	constructor(props: IStoreInfoPanelProps) {
		super(props);

		this.styles = {
			center: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			},
			cityStyle: {
				width: "50%"
			},
			deactivateButton: {
				width: 180,
				margin: "20px 0 44px"
			},
			deleteButton: {
				width: 180
			},
			deviceCount: {
				fontSize: "2.4em",
				fontWeight: "bold",
				marginBottom: 2
			},
			deviceSection: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				margin: "26px 0 68px"
			},
			fullWidth: {
				width: "100%"
			},
			gridChild: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "0px 18px 0px 18px"
			},
			label: {
				wordBreak: "normal"
			},
			notRightPanel: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "0px 18px",
				borderRight: "1px solid #ddd"
			},
			question: {
				color: Colors.lightGray,
				cursor: "pointer",
				marginLeft: 6
			},
			regionStyle: {
				display: "flex"
			},
			rightPanel: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "20px 18px 0px"
			},
			stateStyle: {
				width: "20%",
				marginLeft: 8
			},
			totalDevices: {
				fontSize: "1.4em",
				fontWeight: "bold",
				marginBottom: 2
			},
			verified: {
				width: "100%",
				border: "1px solid #ddd",
				margin: "0 0 42px 6px"
			},
			wrapper: {
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr"
			},
			zipStyle: {
				width: "30%",
				marginLeft: 8
			}
		};

		this.confirmDeletion = this.confirmDeletion.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleSetStoreFilter = this.handleSetStoreFilter.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<div style={ this.styles.wrapper }>
				{ this.renderLeftPanel() }
				{ this.renderCenterPanel() }
				{ this.renderRightPanel() }
			</div>
		);
	}

	renderCenterPanel() {
		const { currentStore } = this.props;
		const { address1, address2 } = currentStore;

		return (
			<div key={ JSON.stringify(currentStore) } style={ this.styles.notRightPanel }>
				{ this.renderInput("Address 1", "address1", address1, false, this.handleSave("address1"), true) }
				{ this.renderInput("Address 2", "address2", address2, false, this.handleSave("address2"), true) }
				{ this.renderRegion() }
			</div>
		);
	}

	renderDelete() {
		const { center, deleteButton } = this.styles;

		if (!hasPermission(PERMISSIONS.OWN_COMPANIES_EDIT)) {
			return null;
		}

		return (
			<div style={ center }>
				<Button
					className="warning-button"
					icon="trash-alt"
					onClick={ this.confirmDeletion }
					style={ deleteButton }
				>
					Delete Store
				</Button>
			</div>
		);
	}

	renderInput(
		label: string,
		id: string,
		value: string,
		disabled?: boolean,
		callback?: (v: string) => void,
		hideLength?: boolean,
		style?: CustomCSS
	) {
		const computedStyle = hideLength ? { marginBottom: 17 } : {};
		// each of the store's text fields aside from postalCode is allowed 191 characters
		const maxLength = id === "zip" ? PostalCodeMaxLength : DefaultMaxLength;

		return (
			<div style={ style }>
				<span style={ this.styles.label }>{ label }:</span>
				<Input
					hideMaxLength={ hideLength }
					maxLength={ maxLength }
					disabled={ disabled }
					saveCallback={ callback }
					id={ id }
					value={ value }
					style={ computedStyle }
				/>
				<br />
			</div>
		);
	}

	renderLeftPanel() {
		const { currentStore } = this.props;

		return (
			<div style={ this.styles.notRightPanel } key={ currentStore.name }>
				{ this.renderInput("Store Name", "storeName", currentStore.name, false, this.handleSave("name"), true) }
				<StoreHours
					fieldSpacing={ 25 }
					store={ currentStore }
					updateStore={ this.handleSave }
				/>
			</div>
		);
	}

	renderRegion() {
		const { city, state, postalCode } = this.props.currentStore;
		const { cityStyle, regionStyle, stateStyle, zipStyle } = this.styles;

		return (
			<div style={ regionStyle }>
				{ this.renderInput("City", "city", city, false, this.handleSave("city"), true, cityStyle) }
				{ this.renderInput("State", "state", state, false, this.handleSave("state"), true, stateStyle) }
				{ this.renderInput("ZIP Code", "zip", postalCode, false, this.handleSave("postalCode"), true, zipStyle) }
			</div>
		);
	}

	renderRightPanel() {
		const { deviceSection, rightPanel, deviceCount, totalDevices } = this.styles;

		return (
			<div style={ rightPanel }>
				<div style={ deviceSection }>
					<span style={ deviceCount }>{ this.props.currentStore.devicesCount }</span>
					<span style={ totalDevices }>Total Devices</span>
					<Link
						hide
						to="/devices"
						onClick={ this.handleSetStoreFilter }
					>
						View Devices >
					</Link>
				</div>
				{ this.renderDelete() }
			</div>
		);
	}

	confirmDeletion() {
		const { currentStore, deleteStore } = this.props;

		Notifications.confirm(
			"Delete Selected Stores",
			`Are you sure you want to delete ${ currentStore.name }?`,
			"Yes", "No", () => {
				deleteStore(currentStore);
			});
	}

	handleSave(field: string) {
		const { currentStore, updateStore } = this.props;
		const record = cloneDeep(currentStore);

		return (value: string) => {
			record[field] = value;

			if (!isEqual(record, currentStore)) {
				updateStore(record);
			}
		};
	}

	handleSetStoreFilter() {
		const { currentStore, setStoreFilter } = this.props;
		setStoreFilter(currentStore);
	}
}

export default connect(null, mapDispatchToProps)(StoreInfoPanel);
import Radium from "radium";
import * as React from "react";
import { connect as connectToRedux } from "react-redux";

import { CustomCSS, IDeviceGroup, Filters } from "@connect/Interfaces";
import TreeBrowserGroup from "Components/Devices/TreeBrowserGroup";
import { tryFetchDeviceGroupsAsync } from "Data/Actions/Devices";
import { setActiveFilters, setActiveSelection, setExpandedDeviceGroups } from "Data/Actions/UI";
import { AllGroup } from "Data/Objects/Devices";
import { getDeviceGroups } from "Data/Selectors/Devices";
import { getExpandedDeviceGroups } from "Data/Selectors/UI";

const mapDispatchToProps = (dispatch) => ({
	getDeviceGroups: () => dispatch(tryFetchDeviceGroupsAsync()),
	selectGroup: (groupId: string) => {
		dispatch(setActiveFilters("group", Filters.DEVICES, groupId));
		dispatch(setActiveSelection("deviceGroups", []));
		dispatch(setActiveSelection("devices", []));
	},
	setExpandedGroups: (uuids: string[]) => dispatch(setExpandedDeviceGroups(uuids))
});

const mapStateToProps = (state) => {
	return {
		deviceGroups: getDeviceGroups(state),
		expandedGroups: getExpandedDeviceGroups(state)
	};
};

interface DeviceGroupTreeBrowserProps {
	deviceGroups: IDeviceGroup[];
	expandedGroups: string[];
	getDeviceGroups: () => void;
	isModalBrowser?: boolean;
	onSelect?: (uuid: string) => void;
	selectedModalGroup?: string;
	controlled?: boolean; // pass this to control the UI
	selectedUuids?: string[] // pass this along with controlled flag
	selectGroup: (groupId: string) => void;
	selectModeOn: boolean;
	setExpandedGroups: (groups: string[]) => void;
	setModalGroup?: (groupId: string) => void;
	sidebar?: boolean;
	topLabel?: string;
	hideHover?: boolean;
}

@Radium
export class DeviceGroupTreeBrowser extends React.Component<DeviceGroupTreeBrowserProps> {
	constructor(props: DeviceGroupTreeBrowserProps) {
		super(props);

		this.styles = {
			ul: {
				listStyleType: "none",
				margin: 0,
				padding: 0
			}
		}

		this.getParentGroups = this.getParentGroups.bind(this);
		this.handleSetExpanded = this.handleSetExpanded.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	styles: CustomCSS;
	deviceGroups: IDeviceGroup[];

	componentWillMount() {
		const { getDeviceGroups: getGroups } = this.props;

		getGroups();
	}

	render() {
		const { expandedGroups, selectModeOn, selectedUuids, controlled, hideHover,
			sidebar, isModalBrowser, deviceGroups, selectedModalGroup, topLabel } = this.props;
		let allSelected = selectedUuids ? selectedUuids.includes(AllGroup.uuid) : false;
		let selectedGroup;

		if (isModalBrowser) {
			allSelected = selectedModalGroup === AllGroup.uuid;
			selectedGroup = deviceGroups.find(({ uuid }) => uuid === selectedModalGroup);
		}

		return (
			<React.Fragment>
				<TreeBrowserGroup
					key={ 0 }
					level={ 0 }
					groupId={ AllGroup.uuid }
					expandedGroups={ expandedGroups }
					selectModeOn={ selectModeOn }
					setSelectedGroup={ this.handleSelect }
					selectedGroup={ selectedGroup }
					selectedUuids={ selectedUuids }
					selected={ allSelected }
					sidebar={ sidebar }
					controlled={ controlled }
					hideHover={ hideHover }
					topLabel={ topLabel }
					isModalBrowser={ isModalBrowser }
				/>
				<ul style={ this.styles.ul } key="tree">
					{
						this.getParentGroups().map((group, i) =>
							<TreeBrowserGroup
								key={ "_browser_" + group.uuid }
								level={ 10 }
								groupId={ group.uuid }
								expandedGroups={ expandedGroups }
								onSetExpanded={ this.handleSetExpanded }
								selectModeOn={ selectModeOn }
								setSelectedGroup={ this.handleSelect }
								selectedGroup={ selectedGroup }
								selected={ selectedUuids && selectedUuids.includes(group.uuid) }
								selectedUuids={ selectedUuids }
								sidebar={ sidebar }
								controlled={ controlled }
								hideHover={ hideHover }
								isModalBrowser={ isModalBrowser }
							/>
						)
					}
				</ul>
			</React.Fragment>
		);
	}

	handleSelect(group: IDeviceGroup) {
		const { uuid } = group;
		const { setModalGroup, selectGroup, onSelect } = this.props;

		if (setModalGroup) {
			setModalGroup(uuid);
		} else if (onSelect) {
			onSelect(uuid)
		} else {
			selectGroup(uuid);
		}
	}

	getParentGroups() {
		return this.deviceGroups = this.props.deviceGroups.filter((g) => {
			return g.parent === null;
		});
	}

	handleSetExpanded(uuid: string) {
		const { expandedGroups, setExpandedGroups } = this.props;
		let expanded = expandedGroups.slice();

		if (expanded.includes(uuid)) {
			expanded = expanded.filter((groupUuid) => groupUuid !== uuid);
		} else {
			expanded.push(uuid);
		}

		setExpandedGroups(expanded);
	}
}

export default connectToRedux(mapStateToProps, mapDispatchToProps)(DeviceGroupTreeBrowser);

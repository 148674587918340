import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { connect } from "react-redux";

import { CompaniesPageCompany, CustomCSS, IExtendedCompany, OptionalTableProps } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import CompanyInfo from "Components/Admin/CompanyInfo";
import { Table } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { isAdmin, isCompanyOwner } from "Data/Objects/Roles";
import { getActiveCompanyId, getManagedCompanies } from "Data/Selectors/Company";
import { deepSearch } from "Data/Utils";

const mapStateToProps = (state) => ({
	activeCompanyId: getActiveCompanyId(state),
	companies: getManagedCompanies(state)
});

interface TableProps {
	activeCompanyId: string;
	companies: IExtendedCompany[];
	companySearch: string;
	selectedCompanies: string[];
	selectModeOn: boolean;
	setSelected: (selectedUuids: string[]) => void;
}

export class ManagedCompaniesTable extends React.Component<TableProps> {
	constructor(props: TableProps) {
		super(props);

		this.styles = {
			link: {
				color: Colors.primaryBlue,
				cursor: "pointer"
			},
			table: {
				paddingTop: 14
			}
		};

		this.clickQRCode = this.clickQRCode.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.getData = this.getData.bind(this);
		this.getRowKey = this.getRowKey.bind(this);
		this.isAdmin = this.isAdmin.bind(this);
		this.renderExpanded = this.renderExpanded.bind(this);
		this.renderQRLink = this.renderQRLink.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { activeCompanyId, companies, selectedCompanies, selectModeOn, setSelected } = this.props;
		const defaultExpanded = isCompanyOwner() ? [ activeCompanyId ] : [];
		let props: OptionalTableProps = {};

		if (selectModeOn) {
			props.rowSelection = {
				selectedRowKeys: selectedCompanies,
				onChange: setSelected
			};
		}

		return (
			<Table
				{ ...props }
				key={companies.length}
				className="admin-table"
				columns={ this.getColumns() }
				dataSource={ this.getData() }
				defaultExpandedRowKeys={ defaultExpanded }
				expandedRowRender={ this.renderExpanded }
				indentSize={ 0 }
				locale={{ emptyText: "No Companies" }}
				pagination={ false }
				rowKey={ this.getRowKey }
				size="middle"
				style={ this.styles.table }
			/>
		);
	}

	renderExpanded(record: CompaniesPageCompany) {
		return (
			<CompanyInfo company={ record } isAdmin={ this.isAdmin() } />
		);
	}

	renderQRLink() {
		return "View/Print";
	}

	clickQRCode(event: React.SyntheticEvent<HTMLElement>) {
		const el = (event.target as HTMLElement);
		const url = window.location.origin + "/devices/" + el.dataset.uuid + "/instructions";

		window.open(url, "_blank", "noopener noreferrer");
	}

	getColumns() {
		let columns: ColumnProps<CompaniesPageCompany>[] = [
			{
				title: "Company Name",
				dataIndex: "name",
				key: "name",
				sorter: Utils.columnSorter("name"),
				defaultSortOrder: "descend"
			},
			{
				title: "Company Owner",
				dataIndex: "owner",
				key: "owner",
				sorter: Utils.columnSorter("owner")
			},
			{
				title: "QR Code",
				dataIndex: "uuid",
				key: "qrcode",
				onCell: (data) => ({
					"data-uuid": data.uuid,
					onClick: this.clickQRCode,
					style: this.styles.link
				}),
				render: this.renderQRLink
			},
			{
				title: "Managed Devices",
				dataIndex: "devicesCount",
				key: "devicesCount",
				sorter: Utils.columnSorter("devicesCount")
			}
		];

		if (this.isAdmin()) {
			columns.push({
				title: "Company Type",
				dataIndex: "type",
				key: "type",
				sorter: Utils.columnSorter("type")
			}, {
				title: "Status",
				dataIndex: "status",
				key: "status",
				sorter: Utils.columnSorter("status")
			});
		}

		return columns;
	}

	getData(): CompaniesPageCompany[] {
		const { companySearch, companies } = this.props;

		const keys = [ "name", "owner", "type", "status" ];

		const mappedCompanies = companies
			.map((c) => {
				const { active, owners, teamType } = c;
				const newCompany = Object.assign({}, c, {
					owner: (owners && owners.length) ? owners[0].name || "" : "",
					status: active ? "Active" : "Inactive",
					type: teamType ? Utils.properCase(teamType) : "Company"
				});

				return newCompany;
			});

		return deepSearch<CompaniesPageCompany>(mappedCompanies, companySearch, keys);
	}

	getRowKey(record: CompaniesPageCompany) {
		return record.uuid ? record.uuid : "";
	}

	isAdmin() {
		return hasPermission(PERMISSIONS.OWN_COMPANIES_EDIT) && isAdmin();
	}
}

export default connect(mapStateToProps)(ManagedCompaniesTable);

import { IMediaUpload, IUploader } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Media } from "Data/Objects/Media";

export class MediaUpload extends Media implements IMediaUpload {
	constructor(file: File, originator?: string) {
		super();

		this.file = file;
		this.id = Utils.getGuid();
		this.isFailed = false;
		this.originator = originator || "";
		this.progress = 0;
	}

	file: File;
	id: string;
	isFailed: boolean;
	name: string;
	originator: string;
	progress: number;
	uploader: IUploader;
	uuid: string;

	/* eslint-disable camelcase */
	created_at: string;
	/* eslint-enable camelcase */
}
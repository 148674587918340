import { Tabs } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IDeviceGroup, IStore } from "@connect/Interfaces";
import DeviceGridWrapper from "Components/Devices/DeviceGridWrapper";
import DeviceGroupTreeBrowser from "Components/Devices/DeviceGroupTreeBrowser";
import { Link, Loader } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import FilterDropdown from "Components/Global/FilterDropdown";
import SearchBar from "Components/Global/SearchBar";
import { setActiveFilters, setActiveSelection } from "Data/Actions/UI";
import { AllGroup } from "Data/Objects/Devices";
import { getStores } from "Data/Selectors/Company";
import { getDeviceGroups, getDeviceModelNames } from "Data/Selectors/Devices";
import { getActiveFilters, getActiveSelection, getDeviceGroupsAsyncState } from "Data/Selectors/UI";

const { TabPane } = Tabs;
const filterGroups = (filterSet: string) => `${ filterSet }_groups`;

const mapDispatchToProps = (dispatch, { filterSet }) => ({
	setModelFilter: (value: string) => dispatch(setActiveFilters("model", filterSet, value)),
	setSearchFilter: (value: string) => dispatch(setActiveFilters("filter", filterSet, value)),
	setSelectedGroups: (uuids: string[]) => dispatch(setActiveSelection(filterGroups(filterSet), uuids)),
	setStoreFilter: (value: string) => dispatch(setActiveFilters("store", filterSet, value))
});

const mapStateToProps = (state, { filterSet }) => {
	const { filter } = getActiveFilters(state, filterSet) as { [key: string]: string };

	return {
		deviceGroups: getDeviceGroups(state),
		filterText: filter,
		groupsLoaded: getDeviceGroupsAsyncState(state).haveAllData,
		models: getDeviceModelNames(state),
		selectedGroupUuids: getActiveSelection(state, filterGroups(filterSet)),
		stores: getStores(state)
	};
};

enum TabType {
	group = "0",
	device = "1"
}

interface DeviceSelectionProps {
	deviceGroups: IDeviceGroup[];
	filterSet: string;
	filterText: string;
	groupsLoaded: boolean;
	models: string[];
	selectedGroupUuids: string[];
	setModelFilter: (value: string) => void;
	setSearchFilter: (value: string) => void;
	setSelectedGroups: (uuids: string[]) => void;
	setStoreFilter: (value: string) => void;
	stores: IStore[];
	valid: boolean;
}

interface DeviceSelectionState {
	currentTab: TabType;
}

export class DeviceSelection extends React.PureComponent<DeviceSelectionProps, DeviceSelectionState> {
	constructor(props: any) {
		super(props);

		this.state = {
			currentTab: TabType.group
		};

		this.style = {
			container: {
				height: "100%"
			},
			devicesContent: {
				height: "71vh"
			},
			devicesHeader: {
				display: "flex",
				height: "4vh",
				justifyContent: "space-around",
				margin: "0 30px",
				paddingBottom: 4
			},
			devicesWrapper: {
				height: "75vh"
			},
			errorStyle: {
				border: `2px solid ${Colors.errorRed}`
			},
			loader: {
				textAlign: "center",
				width: "100%"
			},
			noGroups: {
				margin: "0 auto",
				maxWidth: 500,
				textAlign: "center"
			}
		};

		this.addChildrenToArray = this.addChildrenToArray.bind(this);
		this.changeTab = this.changeTab.bind(this);
		this.getStoreByName = this.getStoreByName.bind(this);
		this.handleGroupSelection = this.handleGroupSelection.bind(this);
		this.handleStoreFilterChange = this.handleStoreFilterChange.bind(this);
		this.selectDevicesTab = this.selectDevicesTab.bind(this);
	}

	style: CustomCSS;

	componentDidMount() {
		// reset our filters
		this.props.setModelFilter("")
		this.props.setSearchFilter("")
		this.props.setStoreFilter("")
	}

	render() {
		const { container, errorStyle } = this.style;
		const error = (!this.props.valid && errorStyle);

		return (
			<Tabs activeKey={ this.state.currentTab }
				onChange={ this.changeTab }
				style={{ ...container, ...error }}
				type="card"
			>
				<TabPane tab="Group" key={ TabType.group }>
					{ this.renderGroups() }
					{ this.renderNoGroups() }
				</TabPane>
				<TabPane tab="Device" key={ TabType.device }>
					{ this.renderDevices() }
				</TabPane>
			</Tabs>
		);
	}

	renderDevices() {
		const { filterSet, filterText, models, setModelFilter, setSearchFilter, stores } = this.props;
		const { devicesContent, devicesHeader, devicesWrapper } = this.style;
		const storeNames = stores.map(({ name }) => name);

		return (
			<div style={ devicesWrapper }>
				<div style={ devicesHeader }>
					<SearchBar
						onSearchChange={ setSearchFilter }
						filterText={ filterText }
						title="Name" />
					<FilterDropdown
						onFilterChange={ setModelFilter }
						options={ models }
						title="Model" />
					<FilterDropdown
						onFilterChange={ this.handleStoreFilterChange }
						options={ storeNames }
						title="Store" />
				</div>
				<div style={ devicesContent }>
					<DeviceGridWrapper
						filterSet={ filterSet }
						key="device-selection-grid"
						selectModeOn={ true }
					/>
				</div>
			</div>
		);
	}

	renderGroups() {
		return (
			<DeviceGroupTreeBrowser
				topLabel="All Groups"
				hideHover
				controlled
				selectModeOn
				onSelect={ this.handleGroupSelection }
				selectedUuids={ this.props.selectedGroupUuids }
			/>
		);
	}

	renderNoGroups() {
		const { loader, noGroups } = this.style;
		const { deviceGroups, groupsLoaded } = this.props;

		if (groupsLoaded && !deviceGroups.length) {
			return (
				<p style={ noGroups }>
					It looks like you don't have any Device Groups set up.
					<br />
					Head over to the <Link to="/devices">Devices Page</Link> to set up a device group,
					<br />
					or go to the <a onClick={ this.selectDevicesTab }>Devices tab</a> to
					select devices independently of their groups.
				</p>
			);
		}

		if (!groupsLoaded) {
			return (
				<div style={ loader }>
					<Loader />
				</div>
			);
		}

		return null;
	}

	addChildrenToArray(uuid: string): string[] {
		if (uuid === AllGroup.uuid) {
			return [];
		}

		// if we haven't selected the "All" group, check the current uuid for children and add them
		const { deviceGroups } = this.props;
		const byId = (id) => (group) => group.uuid === id;
		const index = deviceGroups.findIndex(byId(uuid));
		let uuidsArray: string[] = [];

		deviceGroups[index].children.forEach((childId, i) => {
			const childObject = deviceGroups.find(byId(childId));

			if (!childObject) {
				return;
			}

			uuidsArray.push(childObject.uuid);

			// recurse for each child of the current childObject
			if (childObject.children.length > 0) {
				uuidsArray.push(...this.addChildrenToArray(childObject.uuid));
			}
		});

		return uuidsArray;
	}

	changeTab(tabType: TabType) {
		this.setState(() => ({
			currentTab: tabType
		}));
	}

	getStoreByName(name: string = "") {
		const store = this.props.stores.find((s) => s.name === name)

		return store ? store.uuid : "";
	}

	handleGroupSelection(uuid: string) {
		const { deviceGroups, selectedGroupUuids, setSelectedGroups } = this.props;
		const deviceGroupUuids = deviceGroups.map((group) => group.uuid);
		const index = deviceGroupUuids.indexOf(uuid);
		const deduplicate = (currentId, i, self) => i === self.indexOf(currentId);
		// we need to gather IDs separately than the final state so we can add or remove them as needed
		let newSelectedIds = [ uuid ];
		// our new selection can be overwritten, but in the majority of cases needs to start as the current selection
		let newState = [ ...selectedGroupUuids ];

		// uuid will be "0" if All groups was clicked
		if (uuid !== AllGroup.uuid) {
			// add children, if there are any
			if (index > -1 && deviceGroups[index].children.length > 0) {
				newSelectedIds.push(...this.addChildrenToArray(uuid));
			}

			// if selecting update newState and remove duplicates
			if (selectedGroupUuids.indexOf(uuid) === -1) {
				newState = newState.concat(newSelectedIds).filter(deduplicate);
			// otherwise, provide deselect functionality
			} else {
				newState = newState.filter((i) => {
					// if "0" (the All group) was selected, deselect it now
					const isAllGroup = i !== AllGroup.uuid;
					// additionally, ensure that we deselect children
					const inNewIds = !newSelectedIds.includes(i);

					return isAllGroup && inNewIds;
				});
			}
		} else if (selectedGroupUuids.length !== deviceGroups.length + 1) {
			// add all of the root level groups, and any children for each
			newSelectedIds.push(...deviceGroupUuids);

			// sort and remove duplicates
			newState = newSelectedIds.slice().filter(deduplicate);
		} else {
			// deselect all
			newState = [];
		}

		// and set the new groups
		setSelectedGroups(newState)
	}

	handleStoreFilterChange(value: string) {
		this.props.setStoreFilter(this.getStoreByName(value));
	}

	selectDevicesTab() {
		this.changeTab(TabType.device);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSelection);

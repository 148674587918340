import * as update from "immutability-helper";
import * as moment from "moment";

import { Action, NameUuid, ReducerHandlers, SortableCollection, SortTypes, StateConstructor,
	WithUuid } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { cloneDeep } from "lodash";

export function addDataToState<S, D>(state: S, key: string, data: D[]) {
	return update(state, {
		[key]: { $push: data }
	});
}

export function mergeWithKeyedArrays<S>(state: S, key: string, newObjectArray: WithUuid[], internalKey: string) {
	const oldObjectArray = state[key];
	const oldObjectUuids = oldObjectArray.map((obj) => obj[internalKey]);
	// start out with all our old objects
	let results = [ ...oldObjectArray ];

	newObjectArray.forEach((item) => {
		const originalIndex = oldObjectUuids.indexOf(item[internalKey]);

		if (originalIndex > -1) {
			// if the new object exists already, update it with the merged copy
			results = update(results, {
				[originalIndex]: { $merge: item }
			});
		} else {
			// otherwise add it to the results array
			results.push(item);
		}
	});

	return update(state, {
		[key]: { $set: results }
	});
}

export function mergeWithUuidArrays<S>(state: S, key: string, newObjectArray: WithUuid[]) {
	return mergeWithKeyedArrays(state, key, newObjectArray, "uuid");
}

export function createReducer<A, S extends {}>(handlers: ReducerHandlers, State: StateConstructor<A, S>,
	initialStateArgs?: A) {
	return function reducer(state: S | undefined, action: Action<any>): S {
		const actionType = action.type;
		const isPrimitive = [ "Array", "Number", "String" ].find((t) => t === State.prototype.constructor.name)

		// if we are explicitly resetting the state via our own mechanism, or for some reason the state comes through as
		// undefined, we want to assert that we are getting the right type of state back.
		if (actionType === ACTION_TYPES.RESET.type || state === undefined) {
			const newState = new State(initialStateArgs);
			if (isPrimitive) {
				return newState.valueOf() as S;
			}

			return newState;
		}

		if (handlers.hasOwnProperty(actionType)) {
			return handlers[actionType](state, action);
		}

		// default state is just state, and if we have a primitive value we cannot use Object.assign
		if (isPrimitive) {
			return state;
		}

		return defaultState(state, State, initialStateArgs);
	};
}

export function defaultState<A, S>(state: S, State: StateConstructor<A, S>, initialStateArgs?: A): S {
	const newState = new State(initialStateArgs);

	return Object.assign({}, newState, state);
}

export function deleteFromState<S>(state: S, key: string, index: number) {
	if (state?.[key]?.[index]) {
		return update(state, {
			[key]: { $splice: [ [ index, 1 ] ] }
		});
	} else {
		return state;
	}
}

export function getUuid(u: WithUuid) {
	return u.uuid;
}

export function getNameUuid({ name, uuid }: NameUuid) {
	return { name, uuid };
}

export function getIndexFromState(state: WithUuid[], uuid: string) {
	return state.map(getUuid).indexOf(uuid);
}

export function getSortedCollection<T extends SortableCollection>(data: T[], sortType: SortTypes) {
	return data.sort((d1, d2) => {
		let d1Created: moment.Moment;
		let d2Created: moment.Moment;

		switch (sortType) {
			case SortTypes.ALPHA:
				if (d1.name.toUpperCase() < d2.name.toUpperCase()) {
					return -1;
				}
				if (d1.name.toUpperCase() > d2.name.toUpperCase()) {
					return 1;
				}
				return 0;
			case SortTypes.REVERSE_ALPHA:
				if (d1.name.toUpperCase() < d2.name.toUpperCase()) {
					return 1;
				}
				if (d1.name.toUpperCase() > d2.name.toUpperCase()) {
					return -1;
				}
				return 0;
			case SortTypes.NEWEST_FIRST:
				d1Created = moment(d1.createdAt);
				d2Created = moment(d2.createdAt);

				if (d1Created.isBefore(d2Created)) {
					return 1;
				} else {
					return -1;
				}
			case SortTypes.OLDEST_FIRST:
				d1Created = moment(d1.createdAt);
				d2Created = moment(d2.createdAt);

				if (d1Created.isBefore(d2Created)) {
					return -1;
				} else {
					return 1;
				}
			default:
				return 0;
		}
	});
}

export function mergeWithState<S, D>(state: S, key: string, index: number, data: D) {
	return update(state, {
		[key]: {
			[index]: { $merge: data }
		}
	});
}

export function mergeStateWithArray<S, D extends {}>(state: S, key: string, index: number, data: D, arrayKey?: string) {
	let newData = cloneDeep(state[key][index]);

	for (let prop in data) {
		if (data.hasOwnProperty(prop)) {
			const val = data[prop];

			if (Array.isArray(val) && newData[prop] && newData[prop].length) {
				newData = mergeWithKeyedArrays(newData, prop, val, arrayKey || "uuid");
			} else {
				newData = update(newData, {
					[prop]: { $set: val }
				});
			}
		}
	}

	return update(state, {
		[key]: {
			[index]: { $set: newData }
		}
	});
}

export function pushMergeUpdate<S, D extends WithUuid>(state: S, key: string, data: D, arrayData?: string) {
	const index = getIndexFromState(state[key], data.uuid);

	if (index === -1) {
		return pushToState(state, key, data);
	}

	if (!arrayData) {
		return mergeWithState(state, key, index, data);
	}

	return mergeStateWithArray(state, key, index, data, arrayData);
}

export function pushToState<S, D extends {}>(state: S, key: string, data: D) {
	const stateSlice = state?.[key];
	let existsInSlice = false;

	if (data.hasOwnProperty("uuid")) {
		existsInSlice = stateSlice.find((item: WithUuid) => {
			return item.uuid === data?.["uuid"];
		});
	} else {
		existsInSlice = stateSlice.find((item: any) => {
			return item === data;
		});
	}

	if (existsInSlice) {
		return state;
	}

	return update(state, {
		[key]: { $push: [ data ] }
	});
}

export function unshiftToState<S, D>(state: S, key: string, data: D) {
	return update(state, {
		[key]: { $unshift: [ data ] }
	});
}

export function setState<S, D>(state: S, key: string | number, data: D) {
	return update(state, {
		[key]: { $set: data }
	});
}

export function mergeStateArray<S, D extends WithUuid>(state: S, key: string | number, data: D[]) {
	return update(state, {
		[key]: { $apply: (currentValues: D[]) => {
			return currentValues.map((v: D) => {
				const updatedValue = data.find((d: D) => d.uuid === v.uuid);

				if (updatedValue) {
					return update(v, { $merge: updatedValue });
				} else {
					return v;
				}
			});
		}}
	});
}

export function updateStateArray<S, D>(state: S, key: string, index: number, data: D) {
	if (index < 0) {
		return state;
	}

	return update(state, {
		[key]: { $splice: [ [ index, 1, data ] ] }
	});
}

export function deepSearch<T>(data: T[], search: string, fields: string[]) {
	const searchText = search ? search.toLowerCase() : "";

	if (data && data.length) {
		let result = data.filter((item: T) => {
			return fields.map((key: string) => {
				const value = item[key] || "";
				return value.toLowerCase().indexOf(searchText) > -1;
			}).reduce((prev: boolean, curr: boolean) => {
				return prev || curr;
			}, false);
		});
		return result;
	}

	return [];
}
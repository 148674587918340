import { Switch } from "antd";
import { OptionProps } from "antd/lib/select";
import * as React from "react";
import { connect } from "react-redux";

import { notifyBugSnag } from "@connect/BugSnag";
import { CustomCSS, DeviceLEDSetting, DeviceLEDSettingName, IDevice, IDeviceGroup, IStore,
	DeviceCapabilities, LightbarPatterns, LightbarPattern} from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { Api } from "Api/Api";
import AudioSlider from "Components/Global/AudioSlider";
import { ButtonTypes, IconWeights } from "Components/Global/Button";
import { Input, Loader, Select, Truncate } from "Components/Global/Common";
import ErrorDescription from "Components/Global/ErrorDescription";
import OptionButton from "Components/Global/OptionButton";
import OptionButtonGroup from "Components/Global/OptionButtonGroup";
import PropertiesPanel, { PropertiesPanelButton } from "Components/Global/PropertiesPanel";
import { fetchUpdatedDeviceAsync, updateDeviceAsync } from "Data/Actions/Devices";
import { setDeviceHealthModalUUID } from "Data/Actions/UI/Modals";
import { setDeviceSnapshotStatus, setMediaPreview } from "Data/Actions/UI";
import Perishable from "Data/Objects/Perishable";
import inputValidation from "Data/Objects/Validation";
import { getStores } from "Data/Selectors/Company";
import { getAllDevices, getDeviceGroupById } from "Data/Selectors/Devices";
import { getDeviceHealthModalState } from "Data/Selectors/UI";
import { getActiveUuid } from "Data/Selectors/UI";
import { checkDeviceCapabilities } from "Data/Objects/Devices";
import IntegratorDropdown from "Components/Devices/IntegratorDropdown";
import DevicesApi from "Api/Devices";
import { toggleFeature } from "@connect/Features";

const { Option } = Select;

const mapStateToProps = (state) => {
	const selectedUUID = getActiveUuid(state, "devices");
	const devices = getAllDevices(state);
	const [ device ] = devices.filter((d) => d.uuid === selectedUUID);
	const deviceGroup = device && device.deviceGroup ? getDeviceGroupById(state, device.deviceGroup.uuid) : null;
	const stores = getStores(state);
	const deviceModalSelection = getDeviceHealthModalState(state);
	const selectedDeviceSettings = deviceModalSelection[selectedUUID];
	const snapshotRefreshing = selectedDeviceSettings && selectedDeviceSettings.fetchingSnapshot;

	return {
		device,
		deviceGroup,
		selectedUUID,
		snapshotRefreshing,
		stores
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUpdatedDevice: (uuid: string) => dispatch(fetchUpdatedDeviceAsync(uuid)),
		openDeviceHealthModal: (uuid: string) => dispatch(setDeviceHealthModalUUID(uuid)),
		setFetchingSnapshot: (uuid: string, status: boolean) => dispatch(setDeviceSnapshotStatus(uuid, status)),
		showMediaPreview: (url: string) => dispatch(setMediaPreview(url, -1, "image")),
		updateDevice: (device: IDevice) => dispatch(updateDeviceAsync(device))
	}
};

interface DevicePropertiesPanelProps {
	device: IDevice;
	deviceGroup: IDeviceGroup;
	selectedUUID: string;
	stores: IStore[];
	fetchUpdatedDevice: (uuid: string) => void;
	openDeviceHealthModal: (uuid: string) => void;
	setFetchingSnapshot: (uuid: string, status: boolean) => void;
	showMediaPreview: (url: string) => void;
	snapshotRefreshing: Perishable<boolean>
	updateDevice: (device: IDevice) => void;
}

interface DevicePropertiesPanelState {

}

const styles = {
	center: {
		textAlign: "center"
	},
	leftColumn: {
		display: "inline-block",
		width: 100
	},
	rightColumn: {
		flexBasis: 130
	},
	row: {
		display: "flex",
		marginTop: 10,
		marginBottom: 10
	},
	snapshotContainer: {
		height: 134,
		textAlign: "center"
	},
	snapshotContainerPortrait: {
		textAlign: "center"
	},
	snapshotImage: {
		width: 215,
		cursor: "pointer"
	},
	snapshotImagePortrait: {
		height: 215,
		cursor: "pointer"
	},
	snapshotRow: {
		border: "none",
		background: "none",
		height: 134,
		width: 230,
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	snapshotRowPortrait: {
		border: "none",
		background: "none",
		width: 230,
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	snapshotSection: {
		position: "absolute",
		bottom: 0
	},
	dropdown: {
		width: 130
	},
	ledOptionButton: {
		width: "22%",
		height: 24,
		padding: 0,
		margin: 0
	},
	ledMotionLeftColumn: {
		width: 300
	},
	ledRightColumn: {
		width: "100%",
		textAlign: "right"
	}
} as CustomCSS;

class DevicePropertiesPanel extends React.Component<DevicePropertiesPanelProps, DevicePropertiesPanelState> {
	constructor(props: DevicePropertiesPanelProps) {
		super(props);

		this.handleNameChanged = this.handleNameChanged.bind(this);
		this.handleAudioLevelChanged = this.handleAudioLevelChanged.bind(this);
		this.toggleDeviceHealthModal = this.toggleDeviceHealthModal.bind(this);
		this.openSnapshotPreview = this.openSnapshotPreview.bind(this);
		this.updateDeviceSnapshot = this.updateDeviceSnapshot.bind(this);
		this.renderStorePicker = this.renderStorePicker.bind(this);
		this.renderIntegratorPicker = this.renderIntegratorPicker.bind(this);
		this.updateStore = this.updateStore.bind(this);
		this.renderLEDControl = this.renderLEDControl.bind(this);
		this.renderLEDControls = this.renderLEDControls.bind(this);
		this.updateLEDSetting = this.updateLEDSetting.bind(this);
		this.handleMotionSwitch = this.handleMotionSwitch.bind(this);
		this.storePickerFilter = this.storePickerFilter.bind(this);
		this.renderLightbarSettings = this.renderLightbarSettings.bind(this);
		this.handleToggleLightbar = this.handleToggleLightbar.bind(this);
		this.handleSetLightbarPreset = this.handleSetLightbarPreset.bind(this);
	}

	renderTimeout: any;

	// TODO: These timeouts need to be addressed when we have a better solution
	// Until then they are needed to force updates when the Perishable items update
	componentWillUnmount() {
		clearInterval(this.renderTimeout);
	}

	componentDidMount() {
		const { selectedUUID, fetchUpdatedDevice } = this.props;

		fetchUpdatedDevice(selectedUUID);
		this.renderTimeout = setInterval(() => {
			this.forceUpdate()
		}, 10000);
	}

	componentDidUpdate(prevProps: DevicePropertiesPanelProps) {
		if (this.props.selectedUUID && prevProps.selectedUUID !== this.props.selectedUUID) {
			const { selectedUUID, fetchUpdatedDevice } = this.props;

			fetchUpdatedDevice(selectedUUID);
		}
	}

	render() {
		if (!this.props.device) {
			return null;
		}

		return (
			<PropertiesPanel
				key={ `refreshing-${ this.props.snapshotRefreshing }` }
				actions={this.getAvailableActions()}
				properties={this.renderContent()}
				title="Device Properties"
			/>
		);
	}

	renderContent() {
		return (
			<React.Fragment>
				{this.renderTitleInput()}
				{this.renderModel()}
				{this.renderGroup()}
				{this.renderDeployment()}
				{this.renderPlaylist()}
				{this.renderIntegratorPicker()}
				{this.renderStorePicker()}
				{this.renderAudioSlider()}
				{this.renderLEDControls()}
				{this.renderDeviceView()}
			</React.Fragment>
		);
	}

	renderTitleInput() {
		const { uuid, name } = this.props.device;

		return (
			<div key={ `${ uuid }_title-input` }>
				Name:
				<Input
					key={ name }
					id={ uuid }
					placeholder={ "My Device" }
					value={ name }
					saveCallback={ this.handleNameChanged }
					validator={ inputValidation.name } />
			</div>
		);
	}

	renderModel() {
		const { leftColumn, row } = styles;
		const { model, uuid } = this.props.device;

		return (
			<div key={`${uuid}_model`} style={ row }>
				<div style={ leftColumn }>
					Model:
				</div>
				{ model }
			</div>
		);
	}

	renderGroup() {
		const { leftColumn, row } = styles;
		const { deviceGroup } = this.props.device;
		const { uuid, name } = deviceGroup || { uuid: "undefined", name: "(None)"};

		return (
			<div key={`${ uuid }_group`} style={ row }>
				<div style={ leftColumn }>
					Group:
				</div>
				<Truncate>{ name }</Truncate>
			</div>
		);
	}

	renderDeployment() {
		const { leftColumn, rightColumn, row } = styles;
		const { status, uuid, activeDeployment } = this.props.device;
		const statusDeployment = status && status.deployment;
		const activeDeploymentName = activeDeployment && activeDeployment.name;

		return (
			<div key={`${uuid}_deployment`} style={ row }>
				<div style={ leftColumn }>
					Deployment:
				</div>
				<div style={ rightColumn }>
					<Truncate>{ (statusDeployment || activeDeploymentName) ?? "(None)" }</Truncate>
				</div>
			</div>
		);
	}

	renderPlaylist() {
		const { leftColumn, rightColumn, row } = styles;
		const { status, uuid } = this.props.device;

		return (
			<div key={`${uuid}_playlist`} style={ row }>
				<div style={ leftColumn }>
					Playlist:
				</div>
				<div style={ rightColumn }>
					<Truncate>{ (status && status.playlist) ?? "(None)" }</Truncate>
				</div>
			</div>
		);
	}

	storePickerFilter(inputValue: string, option: React.ReactElement<OptionProps>): boolean {
		const opt = (option.props.title as string).toLowerCase();
		return opt.indexOf(inputValue.toLowerCase()) > -1;
	}

	renderStorePicker() {
		const { leftColumn, row, dropdown } = styles;
		const { device, stores } = this.props;
		const sortedStores = Utils.sort(stores, "name", true);

		return (
			<div key={`${device.uuid}_store`} style={ row }>
				<div style={ leftColumn }>
					Store:
				</div>
				<div style={ dropdown }>
					<Select
						filterOption={ this.storePickerFilter }
						showSearch
						key={ `select_${ device.uuid }` }
						size="small"
						value={ device.store }
						onSelect={ this.updateStore }
						style={ dropdown }
					>
						<Option
							title=""
							key="no_store_selected"
							value={ " " }
						>
							No store selected
						</Option>
						{ sortedStores.map(this.renderStoreOptions) }

					</Select>
				</div>
			</div>
		);
	}

	renderIntegratorPicker() {
		const { leftColumn, dropdown, row } = styles;
		return (
			<div key={ this.props.device.uuid } style={ row }>
				<div style={ leftColumn }>
					Integrator:
				</div>
				<div style={ dropdown }>
					<IntegratorDropdown
						uuid={ this.props.device.uuid }
					/>
				</div>
			</div>
		);
	}

	renderStoreOptions(store: IStore) {
		return (
			<Option
				title={ store.name }
				key={ store.uuid }
				value={ store.uuid } >
				<Truncate length={ 40 } tooltipDisabled={ true }>{ store.name }</Truncate>
			</Option>
		);
	}

	renderAudioSlider() {
		const { leftColumn, row } = styles;
		const { audioLevel, uuid } = this.props.device;

		return (
			<div key={`${uuid}_audiolevel`} style={ row }>
				<div style={ leftColumn }>
					Audio Level:
				</div>
				<div>
					<AudioSlider
						handleChange={ this.handleAudioLevelChanged }
						volume={ audioLevel } />
				</div>
			</div>
		);
	}

	// Render LED controls

	renderLEDControls() {
		const { ledSettings } = this.props.device;

		if (!ledSettings) {
			return null;
		}

		return ledSettings.map(this.renderLEDControl);
	}

	renderLEDControl(setting: DeviceLEDSetting) {
		const { leftColumn, row, ledOptionButton, ledRightColumn } = styles;
		const { device } = this.props;
		const { uuid } = device;

		const { enabled, flashing, name } = setting;

		const showSolid = enabled && !flashing;
		const showFlashing = enabled && flashing;

		const ledType = this.getLEDLabel(name);

		if (name === "button_front" && !checkDeviceCapabilities(device.model, DeviceCapabilities.BUTTON_FRONT)) {
			return null;
		}

		if (name === "lightbar") {
			return this.renderLightbarSettings(setting);
		}

		return (
			<React.Fragment key={ setting.name }>
				<div key={`${uuid}_${setting.name}_status`} style={ row }>
					<div style={ leftColumn }>
						{ ledType } LED:
					</div>
					<div style={ ledRightColumn }>
						<OptionButtonGroup>
							<OptionButton
								style={ ledOptionButton }
								key="off"
								selected={ !enabled }
								onClick={this.updateLEDSetting(name, "enabled", false)}>
								Off
							</OptionButton>
							<OptionButton
								style={ ledOptionButton }
								key="flash"
								selected={ showFlashing }
								onClick={this.updateLEDSetting(name, "flashing", true)}>
								Flash
							</OptionButton>
							<OptionButton
								style={ ledOptionButton }
								key="solid"
								selected={ showSolid }
								onClick={this.updateLEDSetting(name, "flashing", false)}>
								Solid
							</OptionButton>
						</OptionButtonGroup>
					</div>
				</div>
				{ this.renderMotionSwitch(setting) }
			</React.Fragment>
		);
	}

	renderMotionSwitch(setting: DeviceLEDSetting) {
		const { ledMotionLeftColumn, ledRightColumn, row } = styles
		const { device } = this.props
		const { uuid } = device;

		const { enabled, motion, name } = setting;
		const showMotion = enabled && motion;

		if (!checkDeviceCapabilities(device.model, DeviceCapabilities.CAMERA)) {
			return null;
		}

		return (
			<div key={`${uuid}_${setting.name}_motion`} style={ row }>
				<div style={ ledMotionLeftColumn }>
					LED Motion Activated:
				</div>
				<div style={ ledRightColumn }>
					<Switch
						size="small"
						checked={ showMotion }
						onChange={ this.handleMotionSwitch(name, "motion") }
					/>
				</div>
			</div>
		);
	}

	renderLightbarSettings(settings: DeviceLEDSetting) {
		const { device } = this.props;
		const { leftColumn, ledRightColumn, dropdown, row } = styles;

		if (!checkDeviceCapabilities(device.model, DeviceCapabilities.LIGHTBAR)) {
			return null;
		}

		return (
			<React.Fragment key={ JSON.stringify(settings) }>
				<div style={ row }>
					<div style={ leftColumn }>
						Lightbar:
					</div>
					<div style={ ledRightColumn }>
						<Switch
							size="small"
							defaultChecked={ settings.enabled }
							onChange={ this.handleToggleLightbar }
						/>
					</div>
				</div>
				<div style={ row }>
					<div style={ leftColumn }>
						Default Pattern:
					</div>
					<div style={ dropdown }>
						<Select
							size="small"
							defaultValue={ settings?.preset ?? 0 }
							onSelect={ this.handleSetLightbarPreset }
							style={ dropdown }
						>
							{ LightbarPatterns.map(this.renderLightbarPatternOption) }
						</Select>
					</div>
				</div>
			</React.Fragment>
		)
	}

	renderLightbarPatternOption(pattern: LightbarPattern, index: number) {
		return (
			<Option
				title={ pattern }
				key={ pattern }
				value={ index } >
				<Truncate length={ 40 } tooltipDisabled={ true }>{ pattern }</Truncate>
			</Option>
		);
	}

	getLEDLabel(name: DeviceLEDSettingName) {
		switch (name) {
			case "button_front":
				return "Button";
			case "front":
				return "Front";
			default:
				return "Unknown";
		}
	}

	handleMotionSwitch(name: DeviceLEDSettingName, key: string) {
		return (value: boolean) => {
			this.updateLEDSetting(name, key, value)();
		}
	}

	handleToggleLightbar(value: boolean) {
		this.updateLEDSetting(DeviceLEDSettingName.Lightbar, "enabled", value)();
	}

	handleSetLightbarPreset(presetIndex: number) {
		this.updateLEDSetting(DeviceLEDSettingName.Lightbar, "enabled", true)();
		this.updateLEDSetting(DeviceLEDSettingName.Lightbar, "preset", presetIndex)();
	}

	updateLEDSetting(name: DeviceLEDSettingName, key: string, value: boolean | number) {
		const { device, updateDevice } = this.props;
		let { ledSettings } = this.props.device;

		return () => {
			const settingIndex = ledSettings.findIndex((s: DeviceLEDSetting) => {
				return s.name === name;
			});

			if (settingIndex < 0) {
				// Our setting wasn't found, return
				return;
			}

			let setting = ledSettings[settingIndex];

			if (key === "flashing" || key === "motion") {
				setting.enabled = true;
			}

			setting[`${key}`] = value;

			ledSettings[settingIndex] = setting;

			updateDevice({
				...device,
				ledSettings
			});
		};
	}

	renderDeviceView() {
		const { center, leftColumn, snapshotContainer, snapshotRow, snapshotImage, snapshotSection,
			snapshotContainerPortrait, snapshotRowPortrait, snapshotImagePortrait } = styles;
		const { snapshotThumbnail, uuid, snapshotTimestamp } = this.props.device;
		const timestamp = Utils.getHumanReadableDate(snapshotTimestamp);

		if (!checkDeviceCapabilities(this.props.device.model, DeviceCapabilities.CAMERA)) {
			return null;
		}

		const isPortrait = checkDeviceCapabilities(this.props.device.model, DeviceCapabilities.CAMERA_PORTRAIT);
		const snapshotContainerStyle = isPortrait ? snapshotContainerPortrait : snapshotContainer;
		const snapshotRowStyle = isPortrait ? snapshotRowPortrait : snapshotRow;
		const snapshotImageStyle = isPortrait ? snapshotImagePortrait : snapshotImage

		return (
			<div style={ snapshotSection } key={`${uuid}_device_view`}>
				<div style={ leftColumn }>
					Device View:
				</div>
				<div style={ center }>
					<div style={ snapshotContainerStyle }>
						<div style={ snapshotRowStyle }>
							{ snapshotThumbnail ?
								<img
									src={ snapshotThumbnail }
									style={ snapshotImageStyle }
									onClick={ this.openSnapshotPreview } />
								:
								<Loader size="small" /> }
						</div>
					</div>
					<div>{ timestamp }</div>
				</div>
			</div>
		);
	}

	handleNameChanged(name: string) {
		const { device, updateDevice } = this.props;
		updateDevice({ ...device, name });
	}

	handleAudioLevelChanged(audioLevel: number) {
		const { device, updateDevice } = this.props;
		updateDevice({ ...device, audioLevel })
	}

	updateStore(store: string) {
		const { updateDevice, device } = this.props;
		const updatedStore = store === undefined ? "" : store;
		updateDevice({ ...device, store: updatedStore });
	}

	toggleDeviceHealthModal() {
		const { openDeviceHealthModal, device } = this.props;
		// Dispatch new device health modal
		openDeviceHealthModal(device.uuid);
	}

	openSnapshotPreview() {
		const { showMediaPreview, device } = this.props;

		showMediaPreview(device.snapshot);
	}

	updateDeviceSnapshot() {
		const { setFetchingSnapshot, device } = this.props;

		const devicesApi = new DevicesApi();
		const apiMethod = toggleFeature(
			"notifications",
			devicesApi.updateSnapshot.bind(devicesApi),
			Api.DeviceApi.updateDeviceSnapshot.bind(Api.DeviceApi)
		);

		setFetchingSnapshot(device.uuid, true);
		apiMethod(this.props.device.uuid)
			.then(null, (error) => {
				setFetchingSnapshot(device.uuid, false);
				Notifications.error("Error updating device snapshot", <ErrorDescription error={ error } />);
			})
			.catch((error) => {
				Notifications.error("Error updating device snapshot", <ErrorDescription error={ error } />);
				setFetchingSnapshot(device.uuid, false);
				notifyBugSnag(new Error(error));
			});
	}

	getAvailableActions(): PropertiesPanelButton[] {
		const { device, snapshotRefreshing } = this.props;
		const { uuid } = device;
		const disabled = snapshotRefreshing && snapshotRefreshing.value === true;

		const actions: PropertiesPanelButton[] = [
			{
				action: this.toggleDeviceHealthModal,
				key: `${uuid}_device_health`,
				icon: { name: "cogs", weight: "solid" as IconWeights },
				name: "Device Overview",
				type: "danger" as ButtonTypes
			}
		];

		if (checkDeviceCapabilities(device.model, DeviceCapabilities.CAMERA)) {
			actions.unshift({
				action: this.updateDeviceSnapshot,
				key: `${uuid}_refresh_snapshot`,
				icon: {
					name: "sync",
					weight: "regular" as IconWeights,
					spin: disabled
				},
				name: "Update Device View",
				disabled: disabled
			});
		}

		return actions;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicePropertiesPanel);
import * as moment from "moment";
import * as React from "react";
import Animate from "react-move/Animate";

import { Link, Truncate } from "Components/Global/Common";

interface DashboardProps {
	username: string;
}

interface DashboardState {
	componentHasRendered: boolean;
}

export default class HomePage extends React.Component<DashboardProps, DashboardState> {
	constructor(props: DashboardProps) {
		super(props);
	}

	render() {
		return (
			<div style={{
				marginLeft: 20,
				marginTop: 5
			}}>
				<Animate
					show
					start={{
						opacity: 0
					}}
					enter={{
						opacity: [ 1 ],
						timing: { duration: 2000, delay: 0 }
					}}>
					{({opacity}) => {

						return (
							<div>
								<div style={{
									fontSize: 32,
									color: "black",
									opacity: parseFloat(opacity.toString()) }}>
									{this.getGreeting()}, <Truncate end>{this.props.username.split(" ")[0]}</Truncate>!
								</div>
							</div>
						)
					}}
				</Animate>
				<Animate
					show
					start={{
						opacity: 0
					}}
					enter={{
						opacity: [ 1 ],
						timing: { duration: 2000, delay: 2000 }
					}}>
					{({opacity}) => {
						return (
							<div>
								<div style={{
									fontSize: 14,
									color: "gray",
									opacity: parseFloat(opacity.toString()) }}>
									(Not <Truncate end>{ this.props.username.split(" ")[0] }</Truncate>?
									<Link to="/logout"> Log out</Link>)
								</div>
							</div>
						)
					}}
				</Animate>
			</div>
		);
	}

	getGreeting() {
		let currMoment = moment();
		let hours = currMoment.hours();
		let minutes = currMoment.minutes();
		let totalMinutes = (hours * 60) + minutes;

		const minutesToSixPM = 60 * 18;
		const minutesToNoon = 60 * 12;

		if (totalMinutes >= minutesToSixPM) {
			return "Good Evening";
		} else if (totalMinutes >= minutesToNoon) {
			return "Good Afternoon";
		} else {
			return "Good Morning";
		}
	}
}
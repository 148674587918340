import { ErrorResult, IAuthApi, LoginResult, SSORedirectResult, StatusResult } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";
import { isLocalhost } from "@connect/Env";

export default class AuthApi extends ApiV2 implements IAuthApi {
	login(email: string, password: string, twoFactorKey?: string, recover?: boolean) {
		const method = recover ? "recover:" : "";
		const headers = {}

		if (twoFactorKey) {
			headers["X-Auth-OTP"] = `${ method }${ twoFactorKey }`;
		};

		return this.POST("auth/login", {
			email,
			password,
			twoFactorKey
		}, true, headers) as Promise<LoginResult | ErrorResult>;
	}

	logout() {
		return this.POST("auth/logout", {}, true) as Promise<StatusResult>;
	}

	forgotPassword(email: string) {
		return this.POST("auth/password/email", {email}, true) as Promise<null>;
	}

	resetPassword(token: string, password: string, passwordConfirmation: string) {
		return this.POST("auth/password/reset", {
			token,
			password,
			password_confirmation: passwordConfirmation
		}, true) as Promise<boolean>;
	}

	getTwoFactorKey(password: string) {
		return this.GET("settings/two-factor-authentication/enable", {}, true, false, {
			"X-Auth-Sudo": password
		}) as Promise<{ qrCodeUrl: string }>;
	}

	verifyTwoFactorAuth(code: string) {
		return this.POST("settings/two-factor-authentication/verify", { otp_verify: code }, true) as Promise<{}>;
	}

	disableTwoFactorAuth(password: string) {
		return this.POST("settings/two-factor-authentication/disable", {}, true, {
			"X-Auth-Sudo": password
		}) as Promise<{}>;
	}

	getRecoveryCodes(password: string) {
		return this.GET("settings/two-factor-authentication/recovery-codes", {}, true, false, {
			"X-Auth-Sudo": password
		}) as Promise<string[]>;
	}

	regenerateRecoveryCodes(password: string) {
		return this.POST("settings/two-factor-authentication/recovery-codes", {}, true, {
			"X-Auth-Sudo": password
		}) as Promise<string[]>;
	}

	ssoLogin(query: string) {
		return this.POST(`sso/login${ query }`, {}, true) as Promise<LoginResult | ErrorResult>;
	}

	getSAMLRedirect(clientName: string) {
		return this.GET(`sso/saml/${ clientName }`, { localhost: isLocalhost() }, true) as Promise<SSORedirectResult>;
	}
}

export class QriousConfig {
	background: string = "white";
	backgroundAlpha: number = 1;
	foreground: string = "black";
	foregroundAlpha: number = 1;
	level: "L" | "M" | "Q" | "H" = "H";
	mime: string = "image/png";
	padding: number = 0;
	size: number = 100;
	value: string = "";
}
import { Button } from "antd";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";

interface OptionButtonGroupProps {
	style?: CustomCSS;
}

class OptionButtonGroup extends React.PureComponent<OptionButtonGroupProps> {
	constructor(props: OptionButtonGroupProps) {
		super(props);
		this.styles = {
			base: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				marginBottom: 14,
				padding: 0,
				width: "100%",
				...this.props.style
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { children } = this.props;
		const { base } = this.styles;
		const buttons = React.Children.map(children, (child, index) => {
			let childStyles = children && { ...children[index].props.style };

			return React.cloneElement(child as React.ReactElement<any>, {
				style: {
					borderRadius: this.getBorderRadius(index, React.Children.count(children)),
					margin: 0,
					...childStyles
				}
			})
		});

		return (
			<Button.Group style={ base }>
				{ buttons }
			</Button.Group>
		);
	}

	getBorderRadius(index: number, length: number) {
		if (index === 0) {
			return "6px 0px 0px 6px";
		}
		if (index === length - 1) {
			return "0px 6px 6px 0px";
		}
		return 0;
	}
}

export default OptionButtonGroup;
import { Deployment, IAdTemplate } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { RequestNameTypes } from "Components/Global/RequestNameModal";

const {
	SET_COMPANY_CREATE_MODAL_VISIBILITY,
	SET_COMPANY_REQUEST_MODAL_VISIBILITY,
	SET_CHECKIN_DEVICE_MODAL,
	SET_CREATE_STORE_MODAL,
	SET_CREATE_USER_MODAL,
	SET_DEVICE_ERROR_LOG_MODAL,
	SET_DEVICE_GROUP_TREE_BROWSER_MODAL,
	SET_DEVICE_HEALTH_MODAL_UUID,
	SET_DEPLOYMENT_APPROVAL_MODAL_UUID,
	SET_DEPLOYMENT_APPROVAL_MODAL_VISIBILITY,
	SET_MEDIA_PREVIEW_MODAL_VISIBILITY,
	SET_REQUEST_NAME_MODAL,
	SET_SELECT_ACTIONS_MODAL,
	SET_SELECT_ADS_MODAL,
	SET_SELECT_MEDIA_MODAL,
	SET_SELECT_PLAYLISTS_MODAL,
	SET_TEMPLATES_LIST_MODAL,
	SET_TERMS_CONDITIONS_MODAL_VISIBILITY,
	SET_PRIVACY_POLICY_MODAL_VISIBILITY
} = ACTION_TYPES.UI.Modals;

export function setCompanyCreateModalVisibility(value: boolean) {
	return {
		type: SET_COMPANY_CREATE_MODAL_VISIBILITY.type,
		value
	}
}

export function setCompanyRequestModalVisibility(value: boolean) {
	return {
		type: SET_COMPANY_REQUEST_MODAL_VISIBILITY.type,
		value
	}
}

export function setDeviceHealthModalUUID(uuid: string) {
	return {
		type: SET_DEVICE_HEALTH_MODAL_UUID.type,
		uuid
	}
}

export function setDeploymentApprovalModalUUID(value: Deployment) {
	return {
		type: SET_DEPLOYMENT_APPROVAL_MODAL_UUID.type,
		value
	}
}

export function setMediaPreviewModalVisibility(value: boolean) {
	return {
		type: SET_MEDIA_PREVIEW_MODAL_VISIBILITY.type,
		value
	}
}

export function setTermsConditionsModalVisibility(value: boolean) {
	return {
		type: SET_TERMS_CONDITIONS_MODAL_VISIBILITY.type,
		value
	}
}

export function setPrivacyPolicyModalVisibility(value: boolean) {
	return {
		type: SET_PRIVACY_POLICY_MODAL_VISIBILITY.type,
		value
	}
}

export function setDeviceGroupTreeBrowserModal(visible: boolean, selection: string) {
	return {
		type: SET_DEVICE_GROUP_TREE_BROWSER_MODAL.type,
		visible,
		selection
	}
}

export function setDeviceErrorLogModal(visible: boolean) {
	return {
		type: SET_DEVICE_ERROR_LOG_MODAL.type,
		visible
	}
}

export function setCheckinDeviceModal(visible: boolean) {
	return {
		type: SET_CHECKIN_DEVICE_MODAL.type,
		visible
	}
}

export function setCreateStoreModal(visible: boolean) {
	return {
		type: SET_CREATE_STORE_MODAL.type,
		visible
	}
}

export function setCreateUserModal(visible: boolean) {
	return {
		type: SET_CREATE_USER_MODAL.type,
		visible
	}
}

export function setRequestNameModal(visible: boolean, type: RequestNameTypes | "", adTemplate?: IAdTemplate) {
	return {
		type: SET_REQUEST_NAME_MODAL.type,
		visible,
		context: type,
		adTemplate: adTemplate
	}
}

export function setSelectMediaModal(visible: boolean, selection: string[], orientation: string, context?: string) {
	return {
		type: SET_SELECT_MEDIA_MODAL.type,
		visible,
		selection,
		orientation,
		context
	}
}

export function setSelectActionsModal(visible: boolean, context?: string) {
	return {
		type: SET_SELECT_ACTIONS_MODAL.type,
		visible,
		context
	}
}

export function setSelectPlaylistsModal(visible: boolean, selection: string[], context?: string) {
	return {
		type: SET_SELECT_PLAYLISTS_MODAL.type,
		visible,
		selection,
		context
	}
}

export function setSelectAdsModal(visible: boolean, context?: string) {
	return {
		type: SET_SELECT_ADS_MODAL.type,
		visible,
		context
	}
}

export function setTemplatesListModal(visible: boolean) {
	return {
		type: SET_TEMPLATES_LIST_MODAL.type,
		visible
	}
}

export function hideDeploymentApprovalModal() {
	return {
		type: SET_DEPLOYMENT_APPROVAL_MODAL_VISIBILITY.type,
		value: false
	};
}

export function showDeploymentApprovalModal() {
	return {
		type: SET_DEPLOYMENT_APPROVAL_MODAL_VISIBILITY.type,
		value: true
	};
}
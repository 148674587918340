import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, HealthReport, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import { Loader } from "Components/Global/Common";
import HealthGrid from "Components/Health/HealthGrid";
import { getAllReportsAsync } from "Data/Actions/HealthReportAsync";
import { AsyncState } from "Data/Objects/AppState";
import { getFilteredSortedHealthReports } from "Data/Selectors/HealthReports";
import { canFetchHealthReports, getActiveFilters, getActiveSorts, getHealthReportsAsyncState } from "Data/Selectors/UI";

const mapStateToProps = (state, ownProps) => {
	const { filter } = getActiveFilters(state, Filters.HEALTH_REPORTS);
	const sort = getActiveSorts(state, Sorts.HEALTH_REPORTS);
	const healthReports = getFilteredSortedHealthReports(state, {
		sort: sort as SortTypes,
		filter: filter as string
	});
	const asyncState = getHealthReportsAsyncState(state);

	return {
		asyncState,
		canFetch: canFetchHealthReports(state),
		healthReports,
		filter,
		sort
	}
};

const mapDispatchToProps = (dispatch) => ({
	tryFetchNextHealthReports: () => dispatch(getAllReportsAsync())
});

interface HealthContentAreaProps {
	asyncState: AsyncState;
	canFetch: boolean;
	healthReports: HealthReport[];
	onCreateReport: () => void;
	selectModeOn: boolean;
	tryFetchNextHealthReports: () => void;
}

class HealthContentArea extends React.Component<HealthContentAreaProps> {
	constructor(props: HealthContentAreaProps) {
		super(props);

		this.styles = {
			loader: {
				height: 40,
				margin: "40px auto 0",
				textAlign: "center",
				width: "100%"
			}
		}

		this.fetchNextPageOfReports = this.fetchNextPageOfReports.bind(this);
	}

	styles: CustomCSS;

	render() {
		const {
			healthReports,
			canFetch,
			asyncState: { haveAllData, lastFetch },
			onCreateReport,
			selectModeOn
		} = this.props;

		const lastFetched = moment(lastFetch).toISOString();

		let display = canFetch ? "inline-block" : "none";

		if (haveAllData) {
			display = "none";
		}

		return (
			<React.Fragment>
				<HealthGrid
					minWidth="560px"
					content={ healthReports }
					onCreateReport={ onCreateReport }
					selectModeOn={ selectModeOn }
				/>
				<div style={{ ...this.styles.loader, display }}>
					<Waypoint key={ `reports_waypoint_${lastFetched}` } onEnter={ this.fetchNextPageOfReports } />
					<Loader />
				</div>
			</React.Fragment>
		);
	}

	fetchNextPageOfReports() {
		const { canFetch, tryFetchNextHealthReports } = this.props;

		if (canFetch) {
			tryFetchNextHealthReports();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthContentArea);
import { LOCATION_CHANGE, LocationChangeAction } from "react-router-redux";

import { NavigationItemState } from "Data/Objects/AppState";
import { createReducer } from "Data/Utils";

export function setActiveNavigationItem(state: NavigationItemState, action: LocationChangeAction) {
	const { pathname } = action.payload;
	let currentLocation: string;

	const [ currentPath ] = pathname.split("/").filter(Boolean);

	if (currentPath === "deploymentReport") {
		currentLocation = "/deploy";
	} else if (pathname === "/") {
		currentLocation = pathname;
	} else if (pathname.includes("/saml")) {
		currentLocation = "/login";
	} else {
		currentLocation = `/${currentPath}`;
	}

	return {
		currentLocation,
		previousLocation: state.currentLocation
	};
}

const reducers = {
	[LOCATION_CHANGE]: setActiveNavigationItem
};

export default createReducer(reducers, NavigationItemState);

import * as moment from "moment";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { ICompany, IUser, PaginatedDataResult } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import UsersApi from "Api/Users";
import { getActiveCompanyDetails } from "Data/Actions/Company";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { setAsyncFetching, setAsyncState } from "Data/Actions/UI";
import { logoutUser, setUser } from "Data/Actions/User";
import { createUser, deleteUser, setAllUsers, setUsers, updateUser } from "Data/Actions/Users";
import { Company } from "Data/Objects/Company";
import { CacheInvalidationPeriod } from "Data/Objects/Global";

function getAllUsersAsync(companies: ICompany[]) {
	return (dispatch, getState) => {
		companies.forEach((c) => {
			const { UI: { asyncState } } = getState();
			const { currentlyFetching, lastFetch } = asyncState.allUsers;
			const shouldInvalidateCache = moment().diff(lastFetch, "minutes") >= CacheInvalidationPeriod;

			if (currentlyFetching || shouldInvalidateCache) {
				return Promise.resolve();
			}

			// get users list at the current company (includes current integrators)
			const api = new UsersApi();
			return api.getUserList(c.uuid, 500, 1)
				.then((result: PaginatedDataResult<IUser>) => {
					// update the user result with current company info since it doesn't return with that
					const users = result.data
						.map((u: IUser) => {
							return Object.assign({}, u, { company: c });
						});

					dispatch(setAllUsers(users, shouldInvalidateCache));
					dispatch(setAsyncState(
						"allUsers",
						false,
						0
					));
					dispatch(setAsyncFetching("allUsers", false));
				}, (error) => {
					dispatch(errorNotification("Could not fetch integrators list."));
					dispatch(setAsyncFetching("allUsers", false));
				})
				.catch((error) => {
					notifyBugSnagAsync(new Error(error));
					dispatch(setAsyncFetching("allUsers", false));
				});
		});

		return Promise.resolve();
	}
}

function getUsersAsync(reset?: boolean) {
	return (dispatch, getState) => {
		const { Company: stateCompany, UI: { asyncState } } = getState();
		const { activeCompanyId, activeCompany: { uuid, name } } = stateCompany;
		const { currentPage, currentlyFetching, haveAllData, lastFetch, lastFetchedCompany } = asyncState.users;
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const companyChanged = activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = moment().diff(lastFetch, "minutes") >= CacheInvalidationPeriod
			|| companyChanged || reset;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		dispatch(setAsyncFetching("users", true, activeCompanyId));

		const page = shouldInvalidateCache ? 0 : currentPage;
		const api = new UsersApi();
		return api.getUserList(activeCompanyId, 50, page + 1)
			.then((result: PaginatedDataResult<IUser>) => {
				const { data, next_page_url, current_page } = result;
				const resultPage = current_page || 0; // eslint-disable-line camelcase
				let users: IUser[] = [];

				// add the company to each user since this isn't returned by the API
				data.forEach((u) => {
					let userCompany = new Company();
					userCompany.uuid = uuid;
					userCompany.name = name;
					u.company = userCompany;
					users.push(u);
				});

				dispatch(setUsers(users, shouldInvalidateCache));
				dispatch(setAsyncState(
					"users",
					!next_page_url, // eslint-disable-line camelcase
					resultPage
				));
				dispatch(setAsyncFetching("users", false));
			}, (error) => {
				dispatch(errorNotification("Could not fetch users."));
				dispatch(setAsyncFetching("users", false));
			})
			.catch(error => {
				notifyBugSnagAsync(new Error(error));
				dispatch(setAsyncFetching("users", false));
			});
	}
}

function createUserAsync(user: IUser, company: ICompany) {
	return (dispatch, getState) => {
		const api = new UsersApi();
		api.createUser(user, company)
			.then((result: IUser) => {
				const { activeCompanyId } = getState().Company;
				const { name } = user.role;
				const roleTitle = user.role.name === "marketer" ? "Marketer" : `Company ${Utils.properCase(name)}`;
				result.active = true;
				result.role = {
					name: user.role.name,
					title: roleTitle
				}
				result.company = company;
				dispatch(successNotification("User Created."));
				dispatch(getActiveCompanyDetails(company.uuid, true));
				if (company.uuid === activeCompanyId) {
					dispatch(createUser(result));
				}
			}, (error) => {
				dispatch(errorNotification("User not created.", error));
			})
			.catch(error => {
				dispatch(notifyBugSnagAsync(new Error(error)));
				dispatch(errorNotification("User not created.", error));
			});
	}
}

function updateUserAsync(user: IUser) {
	return (dispatch, getState) => {
		const api = new UsersApi();
		api.updateUser(user)
			.then(() => {
				// update current user information
				const activeUser = getState().User.user;
				if (user.uuid === activeUser.uuid) {
					const updatedActiveUser = Object.assign({}, activeUser, user);

					// logout user if they've deactivated themselves
					if (!updatedActiveUser.active) {
						dispatch(logoutUser());
					} else {
						dispatch(setUser(updatedActiveUser));
					}
				}

				dispatch(updateUser(user));
				dispatch(successNotification("User updated successfully."));
			}, (error) => {
				dispatch(errorNotification("Could not update user.", error));
			})
			.catch(error => dispatch(notifyBugSnagAsync(new Error(error))));
	}
}

function deleteUserAsync(user: IUser) {
	return (dispatch) => {
		const api = new UsersApi();
		api.deleteUser(user, user.company)
			.then((result) => {
				dispatch(deleteUser(user));
				dispatch(successNotification("User deleted successfully."));
			}, (error) => {
				dispatch(errorNotification(`Could not delete ${user.name}.`, error));
			})
			.catch(error => {
				dispatch(errorNotification(`Could not delete ${user.name}.`, error));
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	}
}

export {
	getAllUsersAsync,
	getUsersAsync,
	createUserAsync,
	updateUserAsync,
	deleteUserAsync
}
import * as React from "react";
import {
	ConnectDragPreview,
	ConnectDragSource,
	ConnectDropTarget,
	DndComponentClass,
	DragSource,
	DragSourceCollector,
	DragSourceConnector,
	DragSourceMonitor,
	DragSourceSpec,
	DropTarget,
	DropTargetCollector,
	DropTargetConnector,
	DropTargetMonitor,
	DropTargetSpec
} from "react-dnd";
import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";

import { CustomCSS, WithUuid } from "@connect/Interfaces";

export const DnDIdentifier = "DragNDropGridViewCard";

export interface DropItem {
	data: WithUuid;
	type: GridViewCardType;
}

export interface GridViewCardCollectedProps {
	connectDragPreview?: ConnectDragPreview;
	connectDragSource?: ConnectDragSource;
	connectDropTarget?: ConnectDropTarget;
	isDragging?: boolean;
	isOver?: boolean;
	hoverItem?: any;
}

const DragCollector: DragSourceCollector<
Partial<GridViewCardCollectedProps>
> = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
	connectDragPreview: connect.dragPreview(),
	connectDragSource: connect.dragSource(),
	isDragging: monitor.isDragging()
});

const DragSpec: DragSourceSpec<IGridViewCardProps<any>, DropItem> = {
	beginDrag(props: IGridViewCardProps<any>) {
		return { data: props.cardObject, type: props.cardType };
	},
	endDrag(props: IGridViewCardProps<any>, monitor: DragSourceMonitor, component: GridViewCard<any>) {
		if (props.onDragEnd) {
			props.onDragEnd();
		}

		if (component) {
			component.setState(() => ({ mouseDown: false }));
		}
	}
};

const DropCollector: DropTargetCollector<
Partial<GridViewCardCollectedProps>
> = (connect: DropTargetConnector, monitor: DropTargetMonitor) => {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		hoverItem: monitor.getItem()
	}
};

const DropSpec: DropTargetSpec<IGridViewCardProps<any>> = {
	drop(props: IGridViewCardProps<any>, monitor: DropTargetMonitor, component: React.Component): DropItem {
		const dragItem = (monitor.getItem() as DropItem).data;
		const { cardType, cardObject, onDrop } = props;

		if (cardType === undefined) {
			return {} as DropItem;
		}

		if (onDrop && (cardObject.uuid !== dragItem.uuid)) {
			onDrop({ cardObject, dragItem });
		}

		return {
			data: cardObject,
			type: cardType
		};
	}
};

const GridViewCardDraggable = DragSource<IGridViewCardProps<any>>(DnDIdentifier, DragSpec, DragCollector);

const GridViewCardDroppable = DropTarget<IGridViewCardProps<any>>(DnDIdentifier, DropSpec, DropCollector);

export const DnDGridViewCard = {
	DnDIdentifier,
	DragCollector,
	DragSpec,
	DropCollector,
	DropSpec,
	GridViewCardDraggable,
	GridViewCardDroppable
};

export type GridViewDndComponent<T> = DndComponentClass<T>;

export type IconData = { callback: (cardObject?: any) => void, name: string }

export type GridViewCardType = "ad" | "analytics" | "device" | "deviceGroup" | "media" | "playlist" | "healthReport";

export interface IGridViewCard {
	clickCard: (e: React.MouseEvent<HTMLDivElement>) => void;
	renderContainer: (content: React.ReactChild) => React.ReactNode;
	scrollIntoView: () => void;
	setContainer: (node: React.ReactNode) => void;
}

export interface IGridViewCardProps<CardData> {
	actionButtons?: IconData[];
	bulkSelectActive?: boolean;
	cardObject: CardData;
	cardType: GridViewCardType;
	connectDragPreview?: ConnectDragPreview;
	connectDragSource?: ConnectDragSource;
	connectDropTarget?: ConnectDropTarget;
	isDraggable?: boolean;
	isDragging?: boolean;
	isDroppable?: boolean;
	isFavorite?: boolean;
	isOver?: boolean;
	isSelected?: boolean;
	noAutoScroll?: boolean;
	onCardSelection?: (shiftClick: boolean) => void;
	onDoubleClick?: () => void;
	onDrop?: (dropTargetObject: any) => void;
	onDragEnd?: () => void;
	hoverItem?: any;

}

interface IGridViewCardState {
	currentPos: number[];
	mouseDown: boolean;
}

export class GridViewCard<T extends IGridViewCardProps<any>>
	extends React.PureComponent<T, IGridViewCardState> implements IGridViewCard {
	constructor(props: T) {
		super(props);

		this.styles = {
			container: {
				height: "auto",
				placeSelf: "center",
				position: "relative",
				width: "auto"
			}
		};

		this.state = {
			currentPos: [],
			mouseDown: false
		};

		this.clickCard = this.clickCard.bind(this);
		this.mouseDown = this.mouseDown.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
		this.doubleClick = this.doubleClick.bind(this);
		this.hasMoved = this.hasMoved.bind(this);
		this.renderContainer = this.renderContainer.bind(this);
		this.scrollIntoView = this.scrollIntoView.bind(this);
		this.setContainer = this.setContainer.bind(this);
	}

	_container: HTMLDivElement;
	styles: CustomCSS;

	componentDidMount() {
		this.scrollIntoView();
	}

	componentDidUpdate(prevProps: T) {
		if (!prevProps.isSelected && this.props.isSelected) {
			this.scrollIntoView()
		}
	}

	render() {
		// We throw here to prevent rendering the GridViewCard directly, but we must return a <div />
		// so the extending components can properly type their render methods.
		throw new Error("WARNING: GridViewCard#render must be overridden in the extending component.");
		return <div />;
	}

	renderContainer(content: React.ReactChild) {
		const { connectDragSource, connectDropTarget, isDraggable, isDroppable } = this.props;

		let container = (
			<div
				role="button"
				onMouseDown={ this.mouseDown }
				onMouseMove={ this.mouseMove }
				onClick={ this.clickCard }
				onDoubleClick={ this.doubleClick }
				ref={ this.setContainer }
				style={ this.getContainerStyle() }
			>
				{ content }
			</div>
		);

		if (isDraggable && connectDragSource) {
			container = connectDragSource(container);
		}

		if (isDroppable) {
			container = connectDropTarget && connectDropTarget(container);
		}

		return container;
	}

	getContainerStyle() {
		const { isOver, hoverItem, cardObject } = this.props;
		const canDrop = isOver && hoverItem.data.uuid !== cardObject.uuid;

		return {
			...this.styles.container,
			outline: canDrop ? "1px dashed lightblue" : "none",
			backgroundColor: canDrop ? "#d7e6fc" : "transparent"
		}
	}

	mouseDown(event: React.MouseEvent<HTMLDivElement>) {
		const { bulkSelectActive, isSelected, onCardSelection } = this.props;
		const { pageX, pageY, shiftKey } = event;

		if (bulkSelectActive && onCardSelection && !isSelected) {
			this.setState(() => ({
				currentPos: [ pageX, pageY ],
				mouseDown: true
			}));
			onCardSelection(bulkSelectActive && shiftKey);
		}
	}

	mouseMove(event: React.MouseEvent<HTMLDivElement>) {
		const { bulkSelectActive, isSelected, onCardSelection } = this.props;
		const { pageX, pageY, shiftKey } = event;

		if (bulkSelectActive && !isSelected && this.state.mouseDown && onCardSelection && this.hasMoved(pageX, pageY)) {
			onCardSelection(bulkSelectActive && shiftKey);
		}

		this.setState(() => ({ currentPos: [ pageX, pageY ] }));
	}

	clickCard(event: React.MouseEvent<HTMLDivElement>) {
		const { bulkSelectActive, isSelected, onCardSelection } = this.props;
		const { pageX, pageY, shiftKey } = event;

		const hasntMoved = !this.hasMoved(pageX, pageY);

		if (!bulkSelectActive && hasntMoved && onCardSelection) {
			onCardSelection(false);
		}

		if (bulkSelectActive && hasntMoved && isSelected && !this.state.mouseDown && onCardSelection) {
			onCardSelection(bulkSelectActive && shiftKey)
		}

		this.setState(() => ({ mouseDown: false }));
	}

	clickIcon(callback: (cardObject?: any) => void) {
		if (callback) {
			const { cardObject } = this.props;
			return (event: React.MouseEvent<HTMLDivElement>) => {
				callback(cardObject);
				event.stopPropagation();
			}
		}
		return;
	}

	doubleClick(event: React.MouseEvent<HTMLDivElement>) {
		event.preventDefault();

		const { bulkSelectActive, onDoubleClick } = this.props;

		if (!bulkSelectActive && onDoubleClick) {
			onDoubleClick();
		}
	}

	hasMoved(x: number, y: number) {
		const [ pageX, pageY ] = this.state.currentPos;
		const xDiff = Math.abs(pageX - x);
		const yDiff = Math.abs(pageY - y);
		const maxDiff = 20;

		return xDiff > maxDiff || yDiff > maxDiff;
	}

	scrollIntoView() {
		const { bulkSelectActive, isSelected, noAutoScroll } = this.props;

		if (noAutoScroll) {
			return;
		}

		const node = this._container;
		const scrollToTop = () => {
			// scrollIntoView requires this property and sometimes a condition can arise where the node exists but has
			// not fully rendered and so clientHeight doesn't exist
			if (node && node.clientHeight) {
				scrollIntoViewIfNeeded(node, {
					behavior: (actions) => actions.forEach(({ el, top }, index) => {
						// don't scroll anything but the immediate container
						if (index === 0) {
							// only set the scrollTop not left
							el.scrollTop = top;
						}
					}),
					block: "center",
					inline: "center",
					scrollMode: "always"
				});
			}
		};

		if (!bulkSelectActive && isSelected) {
			setTimeout(scrollToTop.bind(this), 0);
		}
	}

	setContainer(node: HTMLDivElement) {
		this._container = node;
	}
}

export const DraggableGridViewCard: DndComponentClass<IGridViewCardProps<any>> =
	GridViewCardDraggable(GridViewCard);
export const DroppableGridViewCard: DndComponentClass<IGridViewCardProps<any>> =
	GridViewCardDroppable(DraggableGridViewCard);

export default DroppableGridViewCard;

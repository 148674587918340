import { Modal as AntModal } from "antd";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { ButtonTypes } from "Components/Global/Button";
import { Button } from "Components/Global/Common";

export enum ModalKeys {
	ENTER = 13
}

export interface ModalAction {
	text: string;
	icon?: string;
	type?: ButtonTypes;
	disabled?: boolean;
	keyCode?: ModalKeys;
	callback: () => void;
}

interface ModalTitle {
	icon?: string;
	text: string;
}

export interface ModalProps {
	visible?: boolean;
	actions?: ModalAction[];
	modalKey: string;
	title?: ModalTitle;
	floatStyle?: CustomCSS;
	fullScreen?: boolean;
	bodyStyle?: CustomCSS;
	// attempted to have maskClosable here, but this is better controlled
	// by the parent component as ant has some issues internally to do with
	// using maskClosable as a controlled prop from a parent component
	// reference: https://clintonelectronics.atlassian.net/browse/CON-4868
	maskStyle?: CustomCSS;
	width?: number | string;
	zIndex?: number;
	onCancel?: () => void;
}

export interface ModalState {

}

export class Modal extends React.PureComponent<ModalProps, ModalState> {
	constructor(props: ModalProps) {
		super(props);

		this.styles = {
			actionButton: {
				marginLeft: 5
			},
			footerContainer: {
				width: "100%",
				textAlign: "right"
			},
			fullScreenStyle: {
				height: "100vh",
				padding: 0,
				zIndex: 1000
			}
		}

		this.renderHeader = this.renderHeader.bind(this);
		this.renderFooter = this.renderFooter.bind(this);
		this.renderActionButton = this.renderActionButton.bind(this);
		this.getDerivedStyle = this.getDerivedStyle.bind(this);
		this.handleKeybind = this.handleKeybind.bind(this);
	}

	styles: CustomCSS;

	componentWillMount() {
		document.addEventListener("keypress", this.handleKeybind);
	}

	componentWillUnmount() {
		document.removeEventListener("keypress", this.handleKeybind);
	}

	componentWillReceiveProps(props: ModalProps) {
		if (props.visible) {
			document.addEventListener("keypress", this.handleKeybind);
		} else {
			document.removeEventListener("keypress", this.handleKeybind);
		}
	}

	render() {
		const { bodyStyle, floatStyle, maskStyle, width, zIndex,
			modalKey, visible, onCancel, children, fullScreen } = this.props;

		return (
			<AntModal
				className="generic-modal"
				style={ this.getDerivedStyle(floatStyle) }
				bodyStyle={ this.getDerivedStyle(bodyStyle) }
				maskStyle={ maskStyle }
				width={ width }
				zIndex={ zIndex }
				key={ `${ modalKey }-modal` }
				footer={ this.renderFooter() }
				title={ this.renderHeader() }
				closable={ !fullScreen }
				visible={ visible }
				onCancel={ onCancel }
				destroyOnClose={ true }>
				{ children }
			</AntModal>
		);
	}

	renderHeader() {
		const { title } = this.props;

		if (!title) {
			return null;
		}

		return (
			<div>
				{ title.text }
			</div>
		)
	}

	renderFooter() {
		const { actions } = this.props;

		if (!actions || actions.length === 0) {
			return null;
		}

		return (
			<div style={ this.styles.footerContainer }>
				{ actions.map(this.renderActionButton) }
			</div>
		);
	}

	renderActionButton(action: ModalAction, index: number) {
		return (
			<Button
				key={ `${ this.props.modalKey }-button${ index }` }
				size="large"
				type={ action.type }
				onClick={ action.callback }
				style={ this.styles.actionButton }
				disabled={ action.disabled }>
				{ action.text }
			</Button>
		);
	}

	getDerivedStyle(baseStyle: CustomCSS = {}) {
		if (this.props.fullScreen) {
			return {
				...baseStyle,
				...this.styles.fullScreenStyle
			}
		}

		return baseStyle;
	}

	handleKeybind(event: KeyboardEvent) {
		const targetAction = this.props.actions && this.props.actions.find((action: ModalAction) => {
			return action.keyCode === event.keyCode
		});

		if (targetAction && !targetAction.disabled) {
			targetAction.callback();
		}
	}
}
import * as React from "react";
import { connect } from "react-redux";

import { notifyBugSnag } from "@connect/BugSnag";
import { CustomCSS, IUser } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import AuthApi from "Api/Auth";
import { Button } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ErrorDescription from "Components/Global/ErrorDescription";
import PasswordPrompt from "Components/Global/PasswordPrompt";
import { getCurrentUser } from "Data/Selectors/User";

const mapStateToProps = (state) => ({
	user: getCurrentUser(state)
});

interface IAdminRecoveryCodesState {
	password: string;
	showConfirmPassword: boolean;
	recoveryCodes: string[];
}

interface IAdminRecoveryCodesProps {
	user: IUser;
}

class AdminRecoveryCodes extends React.Component<IAdminRecoveryCodesProps, IAdminRecoveryCodesState> {
	constructor(props: IAdminRecoveryCodesProps) {
		super(props);

		this.state = {
			password: "",
			showConfirmPassword: false,
			recoveryCodes: []
		};

		this.styles = {
			description: {
				color: Colors.gray,
				marginBottom: 40
			},
			topic: {
				marginBottom: 16
			},
			container: {
				marginTop: 16
			},
			code: {
				fontWeight: "bold"
			},
			grid: {
				display: "grid",
				gridTemplateColumns: "100px 100px",
				gridGap: "10px 24px",
				margin: "22px 0px"
			},
			button: {
				padding: "0 8px",
				margin: "10px 10px 0 0"
			}
		}

		this.clickCopyCodes = this.clickCopyCodes.bind(this);
		this.clickDownloadCodes = this.clickDownloadCodes.bind(this);
		this.clickPrintCodes = this.clickPrintCodes.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.renderCopyButton = this.renderCopyButton.bind(this);
		this.getRecoveryCodes = this.getRecoveryCodes.bind(this);
		this.renderRecoveryCode = this.renderRecoveryCode.bind(this);
		this.toggleShowPassword = this.toggleShowPassword.bind(this);
		this.regenerateRecoveryCodes = this.regenerateRecoveryCodes.bind(this);

		this.api = new AuthApi();
	}

	styles: CustomCSS;

	api: AuthApi;

	render() {
		const { description, container } = this.styles;
		if (!this.props.user.twoFactorEnabled) {
			return null;
		}

		return (
			<div>
				<h4 style={ this.styles.topic }>Recovery Codes</h4>
				<div style={ description }>
				Recovery codes can be used to access your account in the event that
				you lose access to your authenticator device and cannot retrieve
				two-factor authentication codes.
					<div style={ container }>
						{ this.renderShowCodesLink() }
						{ this.renderPasswordInput() }
						{ this.renderRecoveryCodes() }
					</div>
				</div>
			</div>
		);
	}

	renderShowCodesLink() {
		if (this.state.showConfirmPassword || this.state.recoveryCodes.length) {
			return null;
		}

		return <a onClick={ this.toggleShowPassword }>View Recovery Codes</a>;
	}

	renderPasswordInput() {
		if (!this.state.showConfirmPassword) {
			return null;
		}

		return (
			<PasswordPrompt onContinue={this.handleSubmit} />
		);
	}

	renderRecoveryCode(recoveryCode: string) {
		return (
			<div key={ recoveryCode } style={ this.styles.code }>
				{ recoveryCode }
			</div>
		);
	}

	renderRecoveryCodes() {
		if (!this.state.recoveryCodes.length) {
			return null;
		}

		const codes = this.state.recoveryCodes.map(this.renderRecoveryCode);

		return (
			<div>
				<div
					style={ this.styles.grid }
					id={ "codes" }>
					{ codes }
				</div>
				{ this.renderControls() }
			</div>
		);
	}

	renderControls() {
		if (!this.state.recoveryCodes.length) {
			return null;
		}

		return (
			<div>
				{ this.renderDownloadButton() }
				{ this.renderPrintButton() }
				{ this.renderCopyButton() }
				<div style={ this.styles.container }>
					{ this.renderRegenerateButton() }
				</div>
			</div>
		);
	}

	renderDownloadButton() {
		return (
			<Button
				style={ this.styles.button }
				onClick={ this.clickDownloadCodes }>
				Download
			</Button>
		);
	}

	renderPrintButton() {
		return (
			<Button
				style={ this.styles.button }
				onClick={ this.clickPrintCodes}>
				Print
			</Button>
		);
	}

	renderCopyButton() {
		return (
			<Button
				style={ this.styles.button }
				onClick={ this.clickCopyCodes }>
				Copy
			</Button>
		);
	}

	renderRegenerateButton() {
		return (
			<Button
				style={ this.styles.button }
				onClick={ this.regenerateRecoveryCodes }>
				Regenerate Recovery Codes
			</Button>
		);
	}

	clickCopyCodes() {
		const codes = this.state.recoveryCodes.join("\n");
		Utils.copyToClipboard(codes);
	}

	clickDownloadCodes() {
		const codes = this.state.recoveryCodes.join("\n");
		Utils.downloadText(codes, "ClintonRecoveryCodes.txt", "text/plain");
	}

	clickPrintCodes() {
		Utils.printElement("codes");
	}

	handleSubmit(password: string) {
		this.setState({ password });
		this.getRecoveryCodes(password);
	}

	toggleShowPassword() {
		return this.setState(prevState => ({
			showConfirmPassword: !prevState.showConfirmPassword
		}));
	}

	getRecoveryCodes(password: string) {
		this.api.getRecoveryCodes(password)
			.then((result: string[]) => {
				this.setState(prevState => ({
					showConfirmPassword: !prevState.showConfirmPassword,
					recoveryCodes: result
				}));
			}, (error) => Notifications.error("Could not get recovery codes.", <ErrorDescription error={error} />))
			.catch((error) => notifyBugSnag(new Error(error)));
	}

	regenerateRecoveryCodes() {
		this.api.regenerateRecoveryCodes(this.state.password)
			.then((result: string[]) => {
				this.setState({
					recoveryCodes: result
				});
			}, (error) => Notifications.error("Could not regenerate recovery codes.", <ErrorDescription error={error} />))
			.catch((error) => notifyBugSnag(new Error(error)));
	}
}

export default connect(mapStateToProps)(AdminRecoveryCodes);
import { Col, DatePicker, Row, TimePicker } from "antd";
import * as update from "immutability-helper";
import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, Deployment, DeploymentType, DeploySteps } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import StepFooter from "Components/Deploy/StepFooter";
import StepHeader from "Components/Deploy/StepHeader";
import { Button, Icon, Input, Truncate } from "Components/Global/Common";
import HelpPopover from "Components/Global/HelpPopover";
import { Colors } from "Components/Global/Constants";
import { IconWeights } from "Components/Global/Icon";
import { updateDeploymentAsync } from "Data/Actions/DeploymentsAsync";
import { setActiveDeployment, setDeploymentScheduled, setDeploymentScheduleDates,
	setDeploymentStep } from "Data/Actions/UI/DeploymentWizard";
import { DeployStepDetails } from "Data/Objects/Deployments";
import inputValidation from "Data/Objects/Validation";
import { getActiveDeployment, getDeploymentScheduled,
	getDeploymentScheduleDates } from "Data/Selectors/DeploymentWizard";

const { lightGray, primaryGreen, white } = Colors;
const dateFormat = "MM/DD/YY";
const timeFormat = "HH:mm";

const mapDispatchToProps = (dispatch) => ({
	disableScheduled: () => dispatch(setDeploymentScheduled(false)),
	setNavigationStep: (step: DeploySteps) => ( () => dispatch(setDeploymentStep(step)) ),
	setScheduleDates: (startDate: string, endDate?: string) => dispatch(setDeploymentScheduleDates(startDate, endDate)),
	updateActiveDeployment: (deployment: Deployment) => dispatch(updateDeploymentAsync(deployment)).then(() => {
		dispatch(setActiveDeployment(deployment));
	})
});

const mapStateToProps = (state) => ({
	activeDeployment: getActiveDeployment(state),
	scheduleDates: getDeploymentScheduleDates(state),
	showSchedule: getDeploymentScheduled(state)
});

interface ConfirmStepProps {
	activeDeployment: Deployment;
	scheduleDates: [string, string];
	showSchedule: boolean;
	disableScheduled: () => void;
	setScheduleDates: (startDate: string, endDate: string) => void;
	updateActiveDeployment: (deployment: Deployment) => void;
	setNavigationStep(step: DeploySteps): () => void;
}

interface ConfirmStepState {
	endDate: string;
	endTime: string;
	startDate: string;
	startTime: string;
}

class ConfirmStep extends React.Component<ConfirmStepProps, ConfirmStepState> {
	constructor(props: ConfirmStepProps) {
		super(props);

		const [ tempStart, tempEnd ] = props.scheduleDates;
		const { endDate, startDate, type } = props.activeDeployment;
		const isSchedule = type === DeploymentType.SCHEDULE;
		const defaultEnd = isSchedule ? "" : endDate || tempEnd;
		const defaultStart = startDate || tempStart;
		const end = isSchedule ? "" : moment(defaultEnd);
		const start = moment(defaultStart);

		this.state = {
			endDate: isSchedule ? "" : end && end.format(dateFormat),
			endTime: isSchedule ? "" : end && end.format(timeFormat),
			startDate: start.format(dateFormat),
			startTime: start.format(timeFormat)
		};

		this.style = {
			content: {
				color: lightGray,
				paddingLeft: 10
			},
			errorStyle: {
				border: `2px solid ${Colors.errorRed}`,
				borderRadius: 6
			},
			flex: {
				display: "flex"
			},
			headerRow: {
				display: "flex",
				fontWeight: "bold"
			},
			helpContent: {
				padding: 10
			},
			list: {
				color: lightGray,
				display: "flex"
			},
			picker: {
				width: "80%"
			},
			playlist: {
				background: white
			},
			sideBySide: {
				listStyleType: "none",
				width: "50%"
			},
			container: {
				position: "relative",
				height: "100%"
			},
			deploymentTable: {
				margin: "0px auto",
				height: "80vh",
				minHeight: "80vh",
				overflow: "hidden auto",
				width: "90%"
			}
		};

		this.getPickerDisabledDate = this.getPickerDisabledDate.bind(this);
		this.getPickerDisabledTime = this.getPickerDisabledTime.bind(this);
		this.renderTimezoneWarning = this.renderTimezoneWarning.bind(this);
		this.setPickerState = this.setPickerState.bind(this);
		this.setTitle = this.setTitle.bind(this);
		this.updateDeploymentStateWithTime = this.updateDeploymentStateWithTime.bind(this);
	}

	style: CustomCSS;

	render() {
		const { container, deploymentTable } = this.style;
		const { activeDeployment, setNavigationStep } = this.props;
		const { title, subtitle } = DeployStepDetails[DeploySteps.CONFIRM];

		if (!activeDeployment) {
			return null;
		}

		return (
			<div style={ container }>
				<StepHeader
					title={ title }
					subtitle={ subtitle } />
				<div style={ deploymentTable }>
					{this.renderRow({
						name: "check-circle",
						iconWeight: "regular",
						color: primaryGreen
					}, "Title", this.renderTitleInput())}
					{this.renderRow({
						name: "check-circle",
						iconWeight: "regular",
						color: primaryGreen
					}, "Playlists", this.renderScheduledPlaylists(), {
						icon: "pencil",
						callback: setNavigationStep(DeploySteps.SCHEDULE),
						text: "Edit"
					})}
					{this.renderRow({
						name: "check-circle",
						iconWeight: "regular",
						color: primaryGreen
					}, "Devices", this.renderScheduledDevices(), {
						icon: "pencil",
						callback: setNavigationStep(DeploySteps.DEVICES),
						text: "Edit"
					})}
					{this.renderEventRow()}
					{this.renderScheduleRow()}
				</div>
				<StepFooter />
			</div>
		);
	}

	renderEventRow() {
		if (this.props.activeDeployment.type !== DeploymentType.EVENT) {
			return null;
		}

		return this.renderRow({ name: "clock", iconWeight: "regular" },
			this.renderTimezoneWarning("Set Event Duration"),
			(
				<div>
					<strong>When do you want this event to be deployed?</strong>
					<br />
					{this.renderPickers(true)}
					<strong>When do you want this event to end?</strong>
					<br />
					{this.renderPickers()}
				</div>
			));
	}

	renderPickers(start: boolean = false) {
		const { content, flex, picker, sideBySide } = this.style;
		const at = start ? "Start" : "End";
		const date = `Deploy ${at} Date`;
		const time = `Deploy ${at} Time`;
		const dateRef = `${at.toLowerCase()}Date`;
		const timeRef = `${at.toLowerCase()}Time`;

		const [ startDate, endDate ] = this.props.scheduleDates;

		const dateValue = start ? startDate : endDate;
		const timeValue = start ? startDate : endDate;
		const now = moment();
		const value = dateValue ? moment(dateValue) : now;

		return (
			<div style={{ ...content, ...flex }}>
				<div style={{ ...sideBySide, ...flex }}>
					<div style={sideBySide}>
						{date}
					</div>
					<div style={sideBySide}>
						<DatePicker allowClear={false}
							defaultValue={now}
							disabledDate={this.getPickerDisabledDate(start)}
							format={dateFormat}
							onChange={this.setPickerState(dateRef)}
							style={ picker }
							value={ value } />
					</div>
				</div>
				<div style={{ ...sideBySide, ...flex }}>
					<div style={sideBySide}>
						{time}
					</div>
					<div style={sideBySide}>
						<TimePicker defaultValue={now}
							disabledHours={this.getPickerDisabledTime(start, "hours")}
							disabledMinutes={this.getPickerDisabledTime(start, "minutes")}
							format={timeFormat}
							onChange={this.setPickerState(timeRef)}
							style={ picker }
							value={timeValue ? moment(timeValue) : now} />
					</div>
				</div>
			</div>
		);
	}

	renderRow(
		icon: { name: string, color?: string, iconWeight?: IconWeights },
		title: React.ReactNode,
		content: JSX.Element,
		button?: { icon: string, callback: () => void, text: string }
	) {
		let iconCol = (<Icon name={icon.name} size="big" />);
		let buttonCol;

		if (icon.color) {
			iconCol = (<Icon name={icon.name} size="big"  style={{ color: icon.color }} />);
		}

		if (button) {
			buttonCol = (
				<Col span={4}>
					<Button icon={button.icon}
						onClick={button.callback}
						iconWeight={icon.iconWeight}>
						{button.text}
					</Button>
				</Col>
			);
		}

		return (
			<Row align="top" justify="space-between" style={{
				borderTop: `1px solid ${lightGray}`,
				padding: "15px 0"
			}} type="flex">
				<Col span={2}>
					{iconCol}
				</Col>
				<Col span={button ? 18 : 22} style={{
					paddingTop: 8
				}}>
					<h3 style={{ paddingBottom: 10 }}>{title}</h3>
					{content}
				</Col>
				{buttonCol}
			</Row>
		);
	}

	renderScheduleRow() {
		const { activeDeployment, disableScheduled, showSchedule } = this.props;

		if (activeDeployment.type !== DeploymentType.SCHEDULE || !showSchedule) {
			return null;
		}

		return this.renderRow({ name: "clock", iconWeight: "regular" },
			this.renderTimezoneWarning("Scheduled Deployment"),
			(
				<div>
					<strong>When do you want this event to be deployed?</strong>
					<br />
					{this.renderPickers(true)}
				</div>
			), {
				icon: "times",
				callback: disableScheduled,
				text: "Cancel"
			});
	}

	renderScheduledDevices() {
		const { content, headerRow, list, sideBySide } = this.style;
		const { devices, deviceGroups } = this.props.activeDeployment;
		const deviceNames = devices.map(({ name }) => name);
		const deviceGroupNames = deviceGroups.map(({ name }) => name);

		/* eslint-disable camelcase */
		const numDevices = deviceGroups.filter(({ nestingLevel }) => nestingLevel === 0)
			.reduce((count, { devices_total }) => count + (devices_total || 0), 0) + devices.length;
		/* eslint-enable camelcase */

		const deviceCount = `${numDevices} ${numDevices === 1 ? "device" : "devices"}`

		const dgLength = deviceGroupNames.length;
		const dLength = deviceNames.length;
		let groupsHeader = `${dgLength} Device Group${dgLength !== 1 ? "s" : ""} Selected:`
		let devicesHeader = `${dLength} Device${dLength !== 1 ? "s" : ""} Selected:`

		if (!dgLength) {
			groupsHeader = "No Device Groups Selected.";
		}
		if (!dLength) {
			devicesHeader = "No Devices Selected.";
		}

		return (
			<div>
				<div style={headerRow}>
					<div style={sideBySide}>{groupsHeader}</div>
					<div style={sideBySide}>{devicesHeader}</div>
				</div>
				<div style={{...list}}>
					<ul style={{ ...sideBySide, ...content }}>
						{deviceGroupNames.map((group, index) => (
							<li key={`${group}`}><Truncate length={ 40 }>{group}</Truncate></li>
						))}
					</ul>
					<ul style={{ ...sideBySide, ...content }}>
						{deviceNames.map((device, index) => (
							<li key={`${device}`}><Truncate length={ 40 }>{device}</Truncate></li>
						))}
					</ul>
				</div>
				<div>
					<p>
						This deployment will affect {deviceCount}.
						Click the edit button to change which devices or groups receive this deployment.
					</p>
				</div>
			</div>
		);
	}

	renderScheduledPlaylists() {
		const { content, sideBySide, playlist } = this.style;
		const { schedule } = this.props.activeDeployment as Deployment;

		const playlists = schedule.map(({ duration, name, startTime }) => ({
			name,
			time: Utils.getTimeStringDuration(duration, startTime)
		}));

		return (
			<div>
				<table style={{ width: "100%" }}>
					<thead>
						<tr><th style={{ textAlign: "left" }}>Schedule Playlist:</th><th /></tr>
					</thead>
					<tbody>
						{playlists.map(({ name, time }) => {
							return (
								<tr style={ playlist } key={`${name}_${time}`}>
									<td style={{ ...sideBySide, ...content }}><Truncate length={ 40 }>{name}</Truncate></td>
									<td style={{ ...sideBySide, ...content }}>{time}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}

	renderTimezoneWarning(title: string) {
		const { helpContent } = this.style;

		return (
			<div>
				{ title }
				<HelpPopover title={ title }>
					<p style={ helpContent }>
					Please be aware that deployment times are relative to each device's timezone.
					</p>
				</HelpPopover>
			</div>
		);
	}

	renderTitleInput() {
		return (
			<Input id="title"
				value={this.props.activeDeployment.name}
				saveCallback={this.setTitle}
				validator={ inputValidation.name } />
		);
	}

	getPickerDisabledDate(start: boolean) {
		return (curr: moment.Moment): boolean => {
			const now = moment();
			const current = moment(curr);

			if (start) {
				// Don't allow the start of a deployment prior to today or over a year in advance
				const priorToToday = current.isBefore(now.subtract(2, "day"));
				const yearFromNow = current.diff(now, "years") >= 1;

				return priorToToday || yearFromNow;
			}

			const [ startDate ] = this.props.scheduleDates;

			// Don't allow end dates before our start date
			return current.isBefore(startDate);
		};
	}

	getPickerDisabledTime(start: boolean, val: "hours" | "minutes") {
		return (selectedHour?: number): number[] => {
			const { endDate, endTime, startDate, startTime } = this.state;
			const now = moment().subtract(24, "hour");
			const isHours = val === "hours";
			const endSplit = endTime.split(":");
			const nowSplit = now.format(timeFormat).split(":");
			const startSplit = startTime.split(":");
			const startHour = Number(startSplit[0]);
			const nothing = [];

			// if we are changing the start picker
			// and the start date is after the current date
			const startAfterNow = start && moment(startDate, "MM/DD/YY").isAfter(now.format(dateFormat))
			// or the current hour is before the start hour
			const currentHourBeforeStart = start && !isHours && Number(nowSplit[0]) < startHour;
			// or if we are changing the end picker
			// and the end date is after the start date
			const endAfterStart = !start && moment(endDate, "MM/DD/YY").isAfter(startDate);
			// or the start hour is before the end hour
			const startHourBeforeEnd = !start && !isHours && startHour < Number(endSplit[0]);

			if (endAfterStart || startAfterNow || startHourBeforeEnd || currentHourBeforeStart) {
				return nothing;
			}

			const startingTime = start ? nowSplit : startSplit;
			const hourOrMinute = isHours ? 0 : 1;
			const startMoment = Number(startingTime[hourOrMinute]);
			let disabled: number[] = []

			for (let i = -1; i <= (isHours ? 24 : 60); i++) {
				// if hours disable everything before the start hour
				// if minutes and hour is same, disable everything before the start minute
				if (i < startMoment - 1) {
					disabled.push(i + 1);
				}
			}

			return disabled;
		};
	}

	setPickerState(ref: string) {
		return (otherVal: moment.Moment, value: string) => {
			let { endDate, endTime, startDate, startTime } = this.state;
			const startDuration = moment.duration(startTime).as("minutes");
			const endDuration = moment.duration(endTime).as("minutes");

			let newObj: any = {};
			newObj[ref] = value;

			// ensure our endDate cannot be set before the startDate
			if (ref === "startDate") {
				if (moment(value).isAfter(endDate)) {
					newObj.endDate = value;
				}
				if (startDuration > endDuration) {
					newObj.endTime = startTime;
				}
			}
			// ensure our endTime cannot be set before the startTime
			if (ref === "startTime"
				&& moment.duration(value).as("minutes") > endDuration
				&& moment(endDate).isSame(startDate)) {
				newObj.endTime = value;
			}
			// even if our endDate changes to match the startDate
			if (ref === "endDate"
				&& moment(value).isSame(startDate)
				&& startDuration > endDuration) {
				newObj.endTime = startTime;
			}

			this.setState(() => (newObj), this.updateDeploymentStateWithTime);
		};
	}

	setTitle(title: string) {
		const { activeDeployment, updateActiveDeployment } = this.props;

		updateActiveDeployment(update(activeDeployment, {
			name: {
				$set: title
			}
		}));
	}

	updateDeploymentStateWithTime() {
		const { setScheduleDates } = this.props;
		const { endDate, endTime, startDate, startTime } = this.state;
		const format = `${dateFormat} ${timeFormat}`;
		const end = moment(`${endDate} ${endTime}`, format);
		const start = moment(`${startDate} ${startTime}`, format);
		const endString = end.isValid() ? end.format("YYYY-MM-DDTHH:mm:ss") : "";
		const startString = start.isValid() ? start.format("YYYY-MM-DDTHH:mm:ss") : "";

		setScheduleDates(startString, endString);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmStep);
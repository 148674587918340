import { Action, ActionSetDispatch, ActionSetsDispatch, UuidDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ActionsState } from "Data/Objects/AppState";
import { createReducer, deleteFromState, getIndexFromState, mergeWithUuidArrays, pushToState,
	setState, updateStateArray, updateStateArrayItem } from "Data/Utils";
import { toggleFeature } from "@connect/Features";

const {
	CREATE_ACTION_SET,
	DELETE_ACTION_SET,
	RESET_ACTION_SETS,
	SET_ACTION_SETS,
	UPDATE_ACTION_SET
} = ACTION_TYPES.Actions;

export function getActionSetIndex(state: ActionsState, uuid: string) {
	return getIndexFromState(state.actionSets, uuid);
}

export function createActionSet(state: ActionsState, action: Action<ActionSetDispatch>) {
	return pushToState(state, "actionSets", action.args.actionSet);
}

export function deleteActionSet(state: ActionsState, action: Action<UuidDispatch>) {
	const index = getActionSetIndex(state, action.args.uuid);

	if (index >= 0) {
		return deleteFromState(state, "actionSets", index);
	}

	return state;
}

export function resetActionSets(state: ActionsState, action: Action<null>) {
	return setState(state, "actionSets", []);
}

export function setActionSets(state: ActionsState, action: Action<ActionSetsDispatch>) {
	if (action.args.reset) {
		return setState(state, "actionSets", action.args.actionSets);
	}

	return mergeWithUuidArrays(state, "actionSets", action.args.actionSets);
}

export function updateActionSet(state: ActionsState, action: Action<ActionSetDispatch>) {
	const { actionSet } = action.args;

	if (toggleFeature("notifications", true, false)) {
		return updateActionSet2(state, action);
	}

	const { uuid } = actionSet;

	const index = getActionSetIndex(state, uuid);

	if (index === -1) {
		return pushToState(state, "actionSets", actionSet);
	}

	return updateStateArray(state, "actionSets", index, actionSet);
}

export function updateActionSet2(state: ActionsState, action: Action<ActionSetDispatch>) {
	const { actionSet } = action.args;
	return updateStateArrayItem(state, "actionSets", actionSet);
}

const reducers = {
	[CREATE_ACTION_SET.type]: createActionSet,
	[DELETE_ACTION_SET.type]: deleteActionSet,
	[RESET_ACTION_SETS.type]: resetActionSets,
	[SET_ACTION_SETS.type]: setActionSets,
	[UPDATE_ACTION_SET.type]: updateActionSet
};

export default createReducer(reducers, ActionsState);
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import AdminActivityTable from "Components/Admin/AdminActivityTable";

export default class AdminActivityPage extends React.Component {
	constructor(props: {}) {
		super(props);

		this.styles = {
			container: {
				padding: "0 30px"
			},
			header: {
				width: "100%",
				display: "block"
			},
			heading: {
				display: "inline-block"
			},
			sorts: {
				float: "right"
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { container, header, sorts } = this.styles;
		return (
			<div>
				<div style={ container }>
					<div style={ header }>
						<h3>Activity</h3>
						<div style={ sorts }>
							{/* Todo- add filter dropdowns once CON-1691 is done (API v2) */}
						</div>
					</div>
					<br />
					<AdminActivityTable />
				</div>
			</div>
		);
	}
}
import Radium from "radium";
import * as React from "react";
import { DragSource, DragSourceCollector, DragSourceSpec } from "react-dnd";

import { CustomCSS, IPlaylist, PlaylistListItemDragObject } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

export interface PlaylistListItemDragProps {
	connectDragSource?: Function;
}

export interface PlaylistListItemProps {
	connectDragSource?: Function;
	draggable?: boolean;
	playlist: IPlaylist;
	selected?: boolean;
}

const { darkerGray, filters } = Colors;

@Radium
export class PlaylistListItem extends React.Component<PlaylistListItemProps> {
	constructor(props: PlaylistListItemProps) {
		super(props);

		this.styles = {
			base: {
				padding: 6,
				display: "flex",
				whiteSpace: "nowrap",
				background: darkerGray,
				":hover": {
					background: filters.hover
				}
			},
			title: {
				flex: "1 1 100%",
				maxWidth: "100%",
				whiteSpace: "nowrap",
				overflow: "hidden",
				textOverflow: "ellipsis",
				paddingLeft: 5
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { connectDragSource, draggable, playlist, selected } = this.props;
		const { base, title } = this.styles;
		const { name, uuid } = playlist;
		const baseStyle = {
			...base,
			background: selected ? filters.hoverAlt : "none"
		};

		// we wrap it in an extra div because without it the Radium hover state will conflict with the DnD wrapper
		const element = (
			<div>
				<div
					key={ uuid }
					style={ baseStyle }
				>
					<Icon name="th-list"
						size="smaller"
					/>
					<div style={ title }>
						{ name }
					</div>
				</div>
			</div>
		);

		if (draggable && connectDragSource) {
			return connectDragSource(element);
		}

		return element;
	}
}

const playlistSource: DragSourceSpec<PlaylistListItemProps, PlaylistListItemDragObject> = {
	beginDrag(props: PlaylistListItemProps) {
		const { playlist } = props;
		return { playlist };
	}
};

const collector: DragSourceCollector<PlaylistListItemDragProps> = (connect, monitor) => ({
	connectDragSource: connect.dragSource()
});

export default DragSource("PLAYLIST", playlistSource, collector)(PlaylistListItem);
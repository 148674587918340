import { Switch, TimePicker } from "antd";
import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IAd, IMedia, ISlideshowComponent } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { MaxDurationSeconds, NamePlaceholder, UnusedSpaceWarningThreshold } from "Components/Ads/Constants";
import { Input, Loader } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import EditableTagGroup from "Components/Global/EditableTagGroup";
import { updateAdDuration, updateAdName, updateAdTags, updateLivePreview } from "Data/Actions/UI/AdBuilder";
import inputValidation from "Data/Objects/Validation";
import { getActiveAd, getLivePreview } from "Data/Selectors/AdBuilder";
import { getAdMedia } from "Data/Selectors/Ads";

const { orange } = Colors;

const mapDispatchToProps = (dispatch) => ({
	updateAdDuration: (duration: string) => dispatch(updateAdDuration(duration)),
	updateAdLivePreview: (value: boolean) => dispatch(updateLivePreview(value)),
	updateAdName: (name: string) => dispatch(updateAdName(name, true)),
	updateAdTags: (tags: string[]) => dispatch(updateAdTags(tags))
});

const mapStateToProps = (state) => {
	const ad = getActiveAd(state);

	return {
		ad,
		livePreview: getLivePreview(state),
		media: getAdMedia(state, ad ? ad.layout.media : [])
	};
};

interface AdPropertiesPanelContentProps {
	ad: IAd;
	media: IMedia[];
	livePreview: boolean;
	contentsHeightPercent: number;
	updateAdDuration: (text: string) => void;
	updateAdLivePreview: (value: boolean) => void;
	updateAdName: (text: string) => void;
	updateAdTags: (tags: string[]) => void;
}

interface AdPropertiesPanelContentState {
}

export class AdPropertiesPanelContent
	extends React.Component<AdPropertiesPanelContentProps, AdPropertiesPanelContentState> {
	constructor(props: AdPropertiesPanelContentProps) {
		super(props);

		this.styles = {
			emptySpace: {
				border: "1px solid #FF0075",
				padding: "4px 6px",
				margin: "6px 0px"
			},
			emptySpaceWarning: {
				color: "#DDDFE0"
			},
			row: {
				marginBottom: 14
			},
			label: {
				display: "inline-block",
				width: 100
			},
			warning: {
				color: orange,
				marginTop: 4
			}
		}

		this.handleDurationChanged = this.handleDurationChanged.bind(this);
		this.handleTextChanged = this.handleTextChanged.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { ad, media } = this.props;

		if (!ad && !media.length) {
			return (
				<Loader size="small" />
			);
		}

		return (
			<div>
				{ this.renderAdContainsEmptySpace() }
				{ this.renderAdTitleInput() }
				{ this.renderDuration() }
				{ this.renderDurationWarning() }
				{ this.renderLayout() }
				{ this.renderSize() }
				{ this.renderCreatedBy() }
				{ this.renderDateCreated() }
				{ this.renderLastModified() }
				{ this.renderLivePreview() }
				{ this.renderTags() }
			</div>
		);
	}

	renderAdContainsEmptySpace() {
		const { contentsHeightPercent } = this.props;
		const percentUsed = Math.round(contentsHeightPercent);

		if (!(contentsHeightPercent > UnusedSpaceWarningThreshold && percentUsed < 100)) {
			return null;
		}

		const { emptySpace, emptySpaceWarning } = this.styles;

		return (
			<div style={ emptySpace }>
				<h4 style={ emptySpaceWarning }>Ad Contains Empty Space!</h4>
				<span>{100 - percentUsed}% of the screen is currently unused.</span>
			</div>
		);
	}

	renderAdTitleInput() {
		const { name, uuid } = this.props.ad;

		return (
			<div style={ this.styles.row }>
				Title:
				<Input
					key={ name }
					id={ uuid }
					placeholder={ NamePlaceholder }
					value={ name }
					saveCallback={ this.handleTextChanged }
					validator={ inputValidation.name }
				/>
			</div>
		);
	}

	renderCreatedBy() {
		const { createdBy } = this.props.ad
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Created By:</div>
				<span>
					{ createdBy && createdBy.name }
				</span>
			</div>
		);
	}

	renderDateCreated() {
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Date Created:</div>
				<span>
					{ Utils.getHumanReadableDate(this.props.ad.createdAt) }
				</span>
			</div>
		);
	}

	renderDuration() {
		const { duration } = this.props.ad;
		const currentDuration = moment(Math.floor(duration / 60) + ":" + duration % 60, "mm:ss");

		return (
			<div style={this.styles.row}>
				Duration:
				<div>
					<TimePicker
						style={{ width: "100%" }}
						format={ "mm:ss" }
						placeholder={ "" } // this prevents chinese characters from appearing
						disabledMinutes={ this.getDisabledMinutes }
						disabledSeconds={ this.getDisabledSeconds }
						hideDisabledOptions={ true }
						value={ currentDuration }
						onChange={ this.handleDurationChanged } />
				</div>
			</div>
		);
	}

	renderDurationWarning() {
		const { layout } = this.props.ad;
		const minDuration = this.getMinimumDuration();
		const hasSlideshowsOrVideos = layout.components && layout.components.filter((c) =>
			[ "slideshow", "video" ].includes(c.type)).length;

		if (this.props.ad.duration >= minDuration || !hasSlideshowsOrVideos) {
			return null;
		}

		return (
			<div style={ this.styles.warning }>
				You have selected a duration that is shorter than the media runtime
				of this ad ({Utils.getHumanReadableDuration(minDuration).trim()}).
			</div>
		);
	}

	renderLastModified() {
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Last Modified:</div>
				<span>
					{ Utils.getHumanReadableDate(this.props.ad.updatedAt) }
				</span>
			</div>
		);
	}

	renderLayout() {
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Layout:</div>
				<span>
					{ Utils.properCase(this.props.ad.orientation) }
				</span>
			</div>
		);
	}

	renderLivePreview() {
		const { label, row } = this.styles;
		const { livePreview, updateAdLivePreview } = this.props;

		return (
			<div style={ row }>
				<div style={ label }>Live Preview:</div>
				<span>
					<Switch size="small"
						checked={ livePreview }
						onChange={ updateAdLivePreview }
					/>
				</span>
			</div>
		);
	}

	renderSize() {
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Size:</div>
				{ this.getTotalFileSize() }
			</div>
		);
	}

	renderTags() {
		const { tags, uuid } = this.props.ad;

		return (
			<div style={ this.styles.row }>
				Tags:
				<div className={ "has-scrollbars" }
					style={{
						backgroundColor: "rgba(0,0,0,.15)",
						boxShadow: "inset 0 0 1px rgba(0,0,0,.35)",
						overflowY: "auto",
						padding: 2,
						flex: "1 1 auto"
					}}
				>
					<EditableTagGroup key={ JSON.stringify(tags) + uuid }
						collection={ tags }
						onChange={ this.props.updateAdTags }
					/>
				</div>
			</div>
		);
	}

	getDisabledMinutes() {
		// disable minutes over 15
		let disabledMinutes: number[] = [];

		for (let i = 16; i < 60; i++) {
			disabledMinutes.push(i);
		}

		return disabledMinutes;
	}

	getDisabledSeconds(h: number, m: number) {
		// if 15 minutes are selected, disable all minutes
		let disabledSeconds: number[] = [];

		if (m === 15) {
			for (let i = 0; i < 60; i++) {
				disabledSeconds.push(i);
			}
		}

		return disabledSeconds;
	}

	getTotalFileSize() {
		const bytesize = this.props.media.reduce((prevTotal, currentMedia) => {
			return prevTotal + currentMedia.bytesize;
		}, 0);
		return Utils.getHumanReadableBytesize(bytesize);
	}

	handleDurationChanged(time: moment.Moment) {
		if (time) {
			const isValid = (val) => {
				return Number(String(val)) && /^\d+$/.test(val) || val === ""
			};
			let seconds = time.minutes() * 60;

			seconds += time.seconds();

			let text = seconds.toString();

			// verify that the value passed in is an integer less than
			// 901 or that it is an empty string before updating state
			// if the value is greater than 900-- set the value to 900
			if (isValid(text)) {
				if (Number(text) > MaxDurationSeconds) {
					text = MaxDurationSeconds.toString();
				}

				this.props.updateAdDuration(text);
			}
		}
	}

	handleTextChanged(text: string) {
		if (!text) {
			return;
		}

		const name = text.length <= 191 ? text : text.slice(0, 191);

		this.props.updateAdName(name);
	}

	getMinimumDuration() {
		const { media, ad } = this.props;
		const { layout } = ad;

		const videos = media && media
			.filter((m) => m.mediaType === "video")
			.sort((a, b) => b.duration - a.duration);

		const slideshows = ad.layout.components.filter((c) => c.type === "slideshow") as ISlideshowComponent[];
		const slideshowIds = slideshows.map((slideshow) => slideshow.id);
		const slideshowMediaCounts = slideshowIds.map((id) =>
			layout.media.filter((m) => m.layoutPosition === id).length);
		const maxSlideCount = Math.max.apply(null, slideshowMediaCounts);
		const maxSlideshowDuration = slideshows && slideshows.length &&
			slideshows.sort((a, b) => b.durationPerSlide - a.durationPerSlide)[0].durationPerSlide;

		const longestSlideshow = maxSlideshowDuration * maxSlideCount;
		const longestVideo = videos && videos.length && videos[0].duration;

		// disregard the slideshow length if we have a video
		const longest = longestVideo || Math.max(longestSlideshow, longestVideo) || 0;

		return longest;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdPropertiesPanelContent);

import { ButtonType } from "antd/lib/button/button";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { push } from "react-router-redux";

import { ActiveUuidRoute, CustomCSS, IDevice, IDeviceGroup, SortTypes, StringArrayObject,
	StringArrays, ViewTypes, Filters, Sorts } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import CreateDeviceGroupButton from "Components/Devices/CreateDeviceGroupButton";
import DeviceContentArea from "Components/Devices/DeviceContentArea";
import DeviceFilters, { UnconnectedDeviceFilters } from "Components/Devices/DeviceFilters";
import DeviceGroupPropertiesPanel from "Components/Devices/DeviceGroupPropertiesPanel";
import DeviceGroupTreeBrowser from "Components/Devices/DeviceGroupTreeBrowser";
import DevicePropertiesPanel from "Components/Devices/DevicePropertiesPanel";
import DragPreview from "Components/Devices/DragPreview";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import { IconWeights } from "Components/Global/Button";
import { Colors } from "Components/Global/Constants";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import Icon from "Components/Global/Icon";
import { RequestNameTypes } from "Components/Global/RequestNameModal";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import { createDeviceGroupAsync, deleteDeviceGroupAsync, fetchUpdatedDeviceAsync } from "Data/Actions/Devices";
import {
	setDeviceGroupTreeBrowserModal,
	setRequestNameModal as setRequestNameModalState
} from "Data/Actions/UI/Modals";
import { setActiveFilters, setActiveSelection, setActiveUuid, setActiveView } from "Data/Actions/UI";
import { getActiveCompanyId } from "Data/Selectors/Company";
import {
	getDeviceGroupById, getDeviceGroups, getFilteredSortedSearchedDevices
} from "Data/Selectors/Devices";
import {
	getActiveFilters,
	getActiveSelection,
	getActiveSorts,
	getActiveUuid,
	getActiveView
} from "Data/Selectors/UI";

const mapStateToProps = (state) => {
	const sortType = getActiveSorts(state, Sorts.DEVICES);
	const filters = getActiveFilters(state, Filters.DEVICES);
	const { sizes, status, stores, filter, integrator } = filters;
	const openGroupUuid = filters.group;
	const devices = getFilteredSortedSearchedDevices(state, {
		sizes: sizes as string[],
		status: status as string[],
		stores: stores as string[],
		filter: filter as string,
		sort: sortType as SortTypes,
		group: openGroupUuid as string,
		integrator: integrator as string
	});
	const deviceGroups = getDeviceGroups(state);
	const activeUuid = getActiveUuid(state, "devices");
	const activeGroupUuid = getActiveUuid(state, "deviceGroups");
	const [ device ] = devices.filter((d) => d.uuid === activeUuid);
	const parentUuid = openGroupUuid !== "0" ? openGroupUuid : null;

	return {
		activeUuid,
		activeGroupUuid,
		activeDeviceGroup: getDeviceGroupById(state, filters.group as string),
		activeGroup: filters.group,
		companyId: getActiveCompanyId(state),
		deviceGroups,
		device,
		devices,
		filter,
		filters,
		selectedDevices: getActiveSelection(state, "devices"),
		selectedDeviceGroups: getActiveSelection(state, "deviceGroups"),
		selectedView: getActiveView(state, "devices") || ViewTypes.GRID,
		sortType,
		visibleDeviceGroups: deviceGroups.filter((group) => group.parent === parentUuid)
	}
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	createDeviceGroup: (name: string, activeGroup: IDeviceGroup) => dispatch(createDeviceGroupAsync(name, activeGroup)),
	deleteDeviceGroup: (deviceGroup: IDeviceGroup) =>
		dispatch(deleteDeviceGroupAsync(deviceGroup)),
	fetchDevice: (uuid: string) => dispatch(fetchUpdatedDeviceAsync(uuid)),
	selectDevices: (deviceUuids: string[]) => dispatch(setActiveSelection("devices", deviceUuids)),
	selectDeviceGroups: (deviceGroupUuids: string[]) => dispatch(setActiveSelection("deviceGroups", deviceGroupUuids)),
	setActiveUuid: (deviceUuid: string) => dispatch(setActiveUuid("devices", deviceUuid)),
	setDeviceGroupsUuid: (deviceGroupUuid: string) => dispatch(setActiveUuid("deviceGroups", deviceGroupUuid)),
	setFilter: (query: string) => dispatch(setActiveFilters("filter", Filters.DEVICES, query)),
	showDeviceGroupModal: () => dispatch(setDeviceGroupTreeBrowserModal(true, "")),
	showRequestNameModal: () => dispatch(setRequestNameModalState(true, RequestNameTypes.DEVICE_GROUP)),
	setView: (view: ViewTypes) => dispatch(setActiveView("devices", view)),
	pushToDevicesPage: (state?: { selectModeOn: boolean }) => dispatch(push({
		pathname: "/devices",
		state
	}))
});

interface DevicesPageProps extends RouteComponentProps<ActiveUuidRoute> {
	activeDeviceGroup: IDeviceGroup;
	activeGroup: string;
	activeUuid: string;
	activeGroupUuid: string;
	companyId: string;
	createDeviceGroup: (name: string, activeGroup: IDeviceGroup) => void;
	deviceGroups: IDeviceGroup[];
	device: IDevice;
	devices: IDevice[];
	deleteDeviceGroup: (group: IDeviceGroup) => void;
	fetchDevice: (uuid: string) => Promise<void>;
	filter: string;
	filters: StringArrayObject;
	pushToDevicesPage: (state?: { selectModeOn: boolean }) => void;
	selectDevices: (deviceUuids: string[]) => void;
	selectedDevices: string[];
	visibleDeviceGroups: IDeviceGroup[];
	setActiveUuid: (deviceUuid: string) => void;
	setDeviceGroupsUuid: (deviceUuid: string) => void;
	selectDeviceGroups: (deviceGroupUuids: string[]) => void
	selectedDeviceGroups: string[];
	selectedView: ViewTypes;
	setFilter: (query: string) => void;
	showDeviceGroupModal: () => void;
	showRequestNameModal: () => null;
	setView: (view: ViewTypes) => void;
	sortType: SortTypes;
}

interface DevicesPageState {
	selectModeOn: boolean;
}

export class DevicesPage extends React.Component<DevicesPageProps, DevicesPageState> {
	constructor(props: DevicesPageProps) {
		super(props);

		this.defaultDeviceName = "My Device";

		this.state = {
			selectModeOn: false
		}

		this.styles = {
			content: {
				display: "flex",
				flexDirection: "column",
				height: "100%",
				position: "relative",
				width: "100%",
				overflow: "hidden"
			},
			clear: {
				position: "absolute",
				right: 34,
				top: 2
			},
			input: {
				height: 38,
				width: "auto"
			},
			inputPadding: {
				paddingBottom: 18,
				paddingTop: 14
			},
			buttonStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				marginLeft: 10,
				width: 48
			},
			deviceGroupTreeHint: {
				color: Colors.lightGray,
				textAlign: "center",
				marginBottom: 2,
				marginTop: 2,
				width: "100%",
				height: "100%"
			}
		}

		this.clearAllFilters = this.clearAllFilters.bind(this);
		this.deselectAll = this.deselectAll.bind(this);
		this.getBatchOperations = this.getBatchOperations.bind(this);
		this.handleDeleteDeviceGroups = this.handleDeleteDeviceGroups.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.renderCenterContent = this.renderCenterContent.bind(this);
		this.renderDeviceFilters = this.renderDeviceFilters.bind(this);
		this.renderDeviceFiltersHeader = this.renderDeviceFiltersHeader.bind(this);
		this.renderLeftContent = this.renderLeftContent.bind(this);
		this.renderRightContent = this.renderRightContent.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.setDeviceFiltersRef = this.setDeviceFiltersRef.bind(this);
		this.showDeviceGroupModal = this.showDeviceGroupModal.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
		this.toggleCreateDeviceGroupModal = this.toggleCreateDeviceGroupModal.bind(this);
	}

	styles: {
		content: CustomCSS;
		clear: CustomCSS;
		input: CustomCSS;
		inputPadding: CustomCSS;
		buttonStyle: CustomCSS;
		deviceGroupTreeHint: CustomCSS;
	}

	_filters: connect<UnconnectedDeviceFilters>;
	defaultDeviceName: string;

	componentDidMount() {
		const { activeUuid, device, fetchDevice, pushToDevicesPage } = this.props;

		if (activeUuid && !device) {
			fetchDevice(activeUuid).catch((err) => {
				pushToDevicesPage();
			});
		}
	}

	componentDidUpdate(prevProps: DevicesPageProps) {
		const { location } = this.props;
		const selectModeOn = location && location.state && location.state.selectModeOn;

		if (selectModeOn && selectModeOn !== this.state.selectModeOn) {
			this.setState(() => ({ selectModeOn }));
		}
	}

	render() {
		return (
			<ThreeColumnLayout
				leftContent={this.renderLeftContent()}
				centerContent={this.renderCenterContent()}
				rightContent={this.renderRightContent()} />
		);
	}

	renderCenterContent() {
		const { selectModeOn } = this.state;
		const { filter, selectedDevices, selectedDeviceGroups, sortType } = this.props;
		const deviceCount = selectModeOn ? selectedDevices.length : 0;
		const groupCount = selectModeOn ? selectedDeviceGroups.length : 0;

		return (
			<div style={ this.styles.content }>
				<ContentAreaTopBar
					key={ `topbar-${ selectModeOn }` }
					batch={this.getBatchOperations()}
					search={{
						filterText: filter || "",
						onSearchChange: this.handleSearchChange
					}}
					sort={{
						dataType: Sorts.DEVICES
					}}
					suffixButtons={this.getHeaderSuffixButtons()}
				/>
				<DeviceContentArea
					key={ sortType }
					createNewDeviceGroup={ this.toggleCreateDeviceGroupModal }
					selectModeOn={ selectModeOn }
				/>
				<DragPreview deviceCount={ deviceCount }
					groupCount={ groupCount } />
			</div>
		);
	}

	renderDeviceFilters() {
		return new AccordionElement(this.renderDeviceFiltersHeader(), (
			<DeviceFilters ref={ this.setDeviceFiltersRef } />
		), undefined, undefined, undefined, undefined, undefined, "dark");
	}

	renderDeviceFiltersHeader() {
		let filtersToUse: (string | StringArrays)[][] = [];
		for (let f in this.props.filters) {
			if (f !== "group") {
				filtersToUse.push(this.props.filters[f])
			}
		}

		const hasFilters = filtersToUse.some((value) => {
			if (Array.isArray(value)) {
				return !!value.length;
			}
			return !!value;
		});

		const icon = !hasFilters ? null : (
			<Icon
				key={ Math.random() }
				style={ this.styles.clear }
				size="smaller"
				name="times-circle"
				iconWeight="regular"
				onClick={ this.clearAllFilters }
			/>
		);

		return (
			<div>
				Filter By
				{ icon }
			</div>
		);
	}

	renderDeviceGroupTree() {
		return new AccordionElement("Groups", (
			<React.Fragment>
				<div style={ this.styles.deviceGroupTreeHint }>
					Drag devices into group to assign
				</div>
				<DeviceGroupTreeBrowser sidebar={ true } selectModeOn={ this.state.selectModeOn }/>
			</React.Fragment>
		));
	}

	renderLeftContent() {
		return (
			<Accordion
				header={ <CreateDeviceGroupButton /> }
				elements={[
					this.renderDeviceGroupTree(),
					this.renderDeviceFilters()
				]}
			/>
		);
	}

	renderRightContent() {
		const { activeUuid, device, activeGroupUuid } = this.props;
		if ((!activeUuid && !device && !activeGroupUuid) || this.state.selectModeOn) {
			return undefined;
		}

		if (activeGroupUuid) {
			return <DeviceGroupPropertiesPanel />
		}

		return <DevicePropertiesPanel />;
	}

	clearAllFilters() {
		this._filters.getWrappedInstance().clearAllFilters();
	}

	selectAll() {
		const { devices, selectDevices, visibleDeviceGroups, selectDeviceGroups } = this.props;
		selectDevices(devices.map((m) => m.uuid));
		selectDeviceGroups(visibleDeviceGroups.map((g) => g.uuid));
	}

	deselectAll() {
		this.props.selectDevices([]);
		this.props.selectDeviceGroups([]);
	}

	getBatchOperations() {
		const { selectedDevices, selectedDeviceGroups, devices, deviceGroups } = this.props;
		const selectionCount = selectedDevices.length + selectedDeviceGroups.length;
		const itemCount = devices.length + deviceGroups.length;
		const noSelectedDevices = !this.props.selectedDevices.length;
		const noSelectedGroups = !this.props.selectedDeviceGroups.length;
		const noSelectedItems = noSelectedGroups && noSelectedDevices;

		const batchButtons = [
			{
				disabled: selectionCount === itemCount,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.selectAll
			},
			{
				disabled: noSelectedItems,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.deselectAll
			},
			{
				disabled: noSelectedItems,
				label: "Move",
				icon: "reply-all",
				iconWeight: "solid" as IconWeights,
				iconFlip: "horizontal",
				onClick: this.showDeviceGroupModal
			},
			{
				disabled: noSelectedGroups || selectedDevices.length > 0,
				label: "Delete Groups",
				icon: "trash",
				iconWeight: "regular" as IconWeights,
				onClick: this.handleDeleteDeviceGroups,
				type: "danger" as ButtonType
			}
		];

		return {
			active: this.state.selectModeOn,
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	getHeaderSuffixButtons() {
		const { selectedView, setView } = this.props;
		const currentViewIsType = ViewTypes.GRID === selectedView;
		const gridButtonIcon = currentViewIsType ? "video" : "mobile";
		const gridButtonHandler = () => {
			const newView = currentViewIsType ? ViewTypes.SNAPSHOT : ViewTypes.GRID;
			setView(newView);
		};

		let buttons = [ {
			icon: gridButtonIcon,
			onClick: gridButtonHandler,
			style: this.styles.buttonStyle
		} ];

		return buttons;
	}

	handleDeleteDeviceGroups() {
		this.toggleSelectMode();
		const { selectedDeviceGroups, deviceGroups, deleteDeviceGroup } = this.props;
		const groups: IDeviceGroup[] = deviceGroups.filter((deviceGroup) => {
			return selectedDeviceGroups.includes(deviceGroup.uuid);
		});

		const deleteGroups = () => groups.forEach(group => {
			deleteDeviceGroup(group);
		});
		const noun = groups.length > 1 ? "groups" : "group";

		Notifications.confirm(
			"Delete Device Groups",
			`Are you sure you would like to delete the selected ${ noun }?`,
			"Confirm",
			"Cancel",
			deleteGroups
		);
	}

	handleSearchChange(value: string) {
		this.props.setFilter(value);
	}

	setDeviceFiltersRef(node: connect<UnconnectedDeviceFilters>) {
		this._filters = node;
	}

	showDeviceGroupModal() {
		this.props.showDeviceGroupModal();
	}

	toggleSelectMode() {
		const { setActiveUuid: setUuid, setDeviceGroupsUuid, selectDevices, selectDeviceGroups } = this.props;

		setDeviceGroupsUuid("");
		setUuid("");
		selectDevices([]);
		selectDeviceGroups([]);
		this.setState(prevState => ({ selectModeOn: !prevState.selectModeOn }));
	}

	toggleCreateDeviceGroupModal() {
		this.props.showRequestNameModal();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage);
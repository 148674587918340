import * as React from "react";
import { connect } from "react-redux";

import { IMedia, IMediaUpload, MediaType } from "@connect/Interfaces";
import GridView, { IGridViewProps } from "Components/Global/GridView";
import MediaCard from "Components/Media/MediaCard";
import { replaceWithoutRender } from "Data/Actions/Navigation";
import { addToActiveSelection, removeActiveSelection, setMediaPreview } from "Data/Actions/UI";
import { getActiveSelection, getActiveUuid } from "Data/Selectors/UI";

interface IMediaGridProps extends IGridViewProps {
	content: (IMedia | IMediaUpload)[];
	onMediaSelected?: (media: IMedia[]) => void;
	onUploadMediaClicked?: () => void;
	setPreview: (uri: string, type: MediaType) => void;
}

const mapDispatchToProps = (dispatch) => ({
	addToSelected: (ids: string[]) => dispatch(addToActiveSelection("media", ids)),
	removeFromSelected: (ids: string[]) => dispatch(removeActiveSelection("media", ids)),
	setActive: (id: string) => dispatch(replaceWithoutRender(`/media/${id}`)),
	setPreview: (uri: string, type: MediaType) => dispatch(setMediaPreview(uri, -1, type))
});

const mapStateToProps = (state, { originator }) => ({
	activeUuid: getActiveUuid(state, "media"),
	activeSelection: getActiveSelection(state, "media")
});

export class MediaGrid extends GridView<IMediaGridProps> {
	constructor(props: IMediaGridProps) {
		super(props);

		this.handleCardSelection = this.handleCardSelection.bind(this);
		this.renderMediaCard = this.renderMediaCard.bind(this);
		this.showPreview = this.showPreview.bind(this);
	}

	render() {
		const cards = [
			this.renderUploadMedia(),
			...this.props.content.map(this.renderMediaCard)
		];

		return this.renderContainer(cards);
	}

	renderMediaCard(m: IMedia | IMediaUpload) {
		const { selectModeOn } = this.props;
		const { favorite, uuid } = m;
		const isSelected = this.isSelected(uuid);

		return (
			<MediaCard
				key={ `${ uuid }_media_card` }
				bulkSelectActive={ selectModeOn }
				onCardSelection={ this.handleCardSelection(m, isSelected) }
				cardObject={ m }
				cardType="media"
				isFavorite={ favorite }
				isSelected={ isSelected }
				onDoubleClick={ this.showPreview(m) }
			/>
		);
	}

	renderUploadMedia() {
		const { selectModeOn, onUploadMediaClicked } = this.props;

		if (selectModeOn) {
			return null;
		}

		return (
			<MediaCard
				key="uploadButton"
				cardObject={ {} as IMedia }
				cardType="media"
				bulkSelectActive= { selectModeOn }
				onUploadMediaClicked={ onUploadMediaClicked }
			/>
		)
	}

	handleCardSelection(m: IMedia | IMediaUpload, isSelected: boolean) {
		return (bulkAndShift: boolean) => {
			const { onMediaSelected, selectModeOn } = this.props;

			if (onMediaSelected && !selectModeOn) {
				return onMediaSelected([ m ]);
			}

			return super.handleCardSelection(m, isSelected)(bulkAndShift);
		};
	}

	showPreview(media: IMedia) {
		return () => {
			const { uri, mediaType } = media;
			this.props.setPreview(uri, mediaType);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaGrid);

import * as React from "react";
import { connect } from "react-redux";
import { TriggerPropertiesPanelContent,
	TriggerPropertiesPanelContentProps } from "Components/Actions/TriggerPropertiesPanelContent";
import { getSelectedTriggerType } from "Data/Selectors/ActionBuilder";
import ParameterProperties from "Components/Actions/ParameterProperties";

const mapStateToProps = (state) => {
	return {
		triggerType: getSelectedTriggerType(state)
	};
};

interface NetworkTriggerPropertiesPanelContentProps extends TriggerPropertiesPanelContentProps {}

export class NetworkTriggerPropertiesPanelContent
	extends TriggerPropertiesPanelContent<NetworkTriggerPropertiesPanelContentProps> {
	constructor(props: NetworkTriggerPropertiesPanelContentProps) {
		super(props);
	}

	render() {
		return this.renderContainer(
			<ParameterProperties/>
		)
	}
}

export default connect(mapStateToProps)(NetworkTriggerPropertiesPanelContent);
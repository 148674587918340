import { createSelector } from "reselect";

import { RolesList } from "@connect/Interfaces";
import { AppState, RolesState } from "Data/Objects/AppState";

export function getRolesState(state: AppState): RolesState {
	return state.Roles;
}

export function getEditRoles(state: RolesState): RolesList {
	return state.editRoles;
}

export function getRoles(state: RolesState): RolesList {
	return state.roles;
}

export function getRolesListState(state: RolesList) {
	return state.rolesList;
}

export const getEditRolesStateSlice = createSelector(
	[ getRolesState ],
	getEditRoles
);

export const getRolesStateSlice = createSelector(
	[ getRolesState ],
	getRoles
);

export const getEditRolesList = createSelector(
	[ getEditRolesStateSlice ],
	getRolesListState
);

export const getRolesList = createSelector(
	[ getRolesStateSlice ],
	getRolesListState
);
import { ICompany, IUser, UserRole, UserSettings } from "@connect/Interfaces";
import { Company } from "Data/Objects/Company";
import { cloneDeep } from "lodash";

export class User implements IUser {
	constructor() {
		this.billing_state = "";
		this.company = new Company();
		this.created_at = "";
		this.current_billing_plan = "";
		this.email = "";
		this.last_read_announcements_at = "";
		this.name = "";
		this.permissions = [];
		this.photo_url = "";
		this.role = { name: "", title: "" };
		this.stripe_id = "";
		this.trial_ends_at = "";
		this.two_factor_reset_code = "";
		this.updated_at = "";
		this.uuid = "";
		this.vat_id = "";
		this.settings = {};
	}

	acceptedTerms?: string;
	active: boolean;
	company: ICompany;
	email: string;
	lastLogin: string;
	name: string;
	permissions?: string[];
	phone: string;
	role: UserRole;
	uuid: string;
	verified: boolean;
	settings: UserSettings;
	singleSignOn: string;
	twoFactorEnabled: boolean;

	/* eslint-disable camelcase */
	billing_state: string;
	created_at: string;
	current_billing_plan: string;
	last_read_announcements_at: string;
	photo_url: string;
	stripe_id: string;
	tax_rate: number;
	trial_ends_at: string;
	two_factor_enabled: boolean;
	two_factor_reset_code: string;
	updated_at: string;
	vat_id: string;
	/* eslint-enable camelcase */

	static clone(user: User) {
		return cloneDeep(user);
	}
}

import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IFeedComponent, IMedia } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";
import { addMediaToComponent, updateComponent } from "Data/Actions/UI/AdBuilder";
import { getSelectedComponentIndex } from "Data/Selectors/AdBuilder";
import { getAdBuilderAd, getSelectedComponentByIndex } from "Data/Selectors/UI";

interface BannerListItemProps {
	addComponentMedia: (index: number, ids: string[], type: string) => void;
	banner: IMedia;
	component: IFeedComponent;
	componentIndex: number;
	onComponentUpdated: (index: number, component: IFeedComponent) => void;
	selected: boolean;
}

const mapStateToProps = (state, ownProps) => {
	const activeAd = getAdBuilderAd(state);
	const componentIndex = getSelectedComponentIndex(state);
	const component = getSelectedComponentByIndex(activeAd, componentIndex) as IFeedComponent;

	return ({
		component,
		componentIndex,
		selected: component.banner && component.banner.id === ownProps.banner.uuid
	});
};

const mapDispatchToProps = (dispatch) => ({
	addComponentMedia: (index: number, ids: string[], type: string) => dispatch(addMediaToComponent(index, ids, type)),
	onComponentUpdated: (index: number, component: IFeedComponent) => dispatch(updateComponent(index, component))
});

export class BannerListItem extends React.Component<BannerListItemProps> {
	constructor(props: BannerListItemProps) {
		super(props);

		this.styles = {
			width: "100%",
			backgroundColor: "#2e333f",
			boxSizing: "border-box",
			display: "block",
			marginBottom: 5,
			border: "2px solid transparent",
			opacity: null
		};

		this.handleBannerSelected = this.handleBannerSelected.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { banner, selected } = this.props;

		return (
			<img
				className="banner-list-item"
				style={{ ...this.styles,
					borderColor: selected ? Colors.primaryBlue : "transparent",
					opacity: selected ? 1 : undefined
				}}
				src={ banner.uri }
				onClick={ this.handleBannerSelected }
			/>
		);
	}

	handleBannerSelected() {
		const { banner, component, componentIndex, onComponentUpdated, addComponentMedia } = this.props;
		const { uuid } = banner;
		const mediaIds = [ uuid ];
		const newBanner = Object.assign({...component.banner}, banner, {
			id: uuid
		});

		delete newBanner.uuid;

		onComponentUpdated(componentIndex, Object.assign({}, component, {
			banner: newBanner
		}));

		addComponentMedia(componentIndex, mediaIds, component.type);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerListItem);
import * as React from "react";
import { connect as connectToRedux } from "react-redux";

import { CustomCSS, MediaType } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { setMediaPreview } from "Data/Actions/UI";
import { getConnectTutorials, getInstallTutorials } from "Data/Selectors/System";

export interface VimeoTutorial {
	id: number;
	thumbnail: string;
	title: string;
	link: string;
	page: string;
}

export interface VimeoTutorials {
	[id: number]: VimeoTutorial
}

interface IAdminTutorialsProps {
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => void;
	videos: {
		connect: VimeoTutorial[];
		install: VimeoTutorial[];
	}
}

const mapStateToProps = (state) => ({
	videos: {
		connect: getConnectTutorials(state),
		install: getInstallTutorials(state)
	}
})

const mapDispatchToProps = (dispatch) => ({
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => dispatch(setMediaPreview(uri, vimeoId, type))
});

export class AdminTutorialsPage extends React.Component<IAdminTutorialsProps> {
	constructor(props: any) {
		super(props);

		this.styles = {
			container: {
				padding: "0 30px"
			},
			header: {
				width: "100%",
				display: "block"
			},
			tutorialsContainer: {
				marginBottom: 40,
				display: "flex",
				flexWrap: "wrap"
			},
			thumbnailContainer: {
				cursor: "pointer",
				display: "inline-block",
				padding: "20px 0",
				position: "relative",
				width: "32%"
			},
			thumbnailBase: {

			},
			thumbnailContent: {
				display: "block",
				margin: "0 auto",
				maxWidth: "80%",
				textAlign: "center"
			},
			thumbnailTitle: {
				display: "block",
				margin: "0 auto",
				maxWidth: "80%",
				textAlign: "center",
				height: 36
			},
			thumbnailHover: {
				alignItems: "center",
				background: "transparent",
				color: "transparent",
				display: "flex",
				height: "calc(100% - 70px)",
				justifyContent: "center",
				position: "absolute",
				width: "100%"
			}
		}

		this.renderVideoThumbnail = this.renderVideoThumbnail.bind(this);
		this.clickVideo = this.clickVideo.bind(this);
		this.setVideoActive = this.setVideoActive.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { connect, install } = this.props.videos;

		return (
			<div>
				<div style={ this.styles.container }>
					{ this.renderHeader("Clinton Connect Video Tutorials") }
					{ this.renderTutorials(connect) }
					{ this.renderHeader("Connect PVM Installation Videos") }
					{ this.renderTutorials(install) }
				</div>
			</div>
		);
	}

	renderHeader(text: string) {
		return (
			<div style={ this.styles.header }>
				<h3>{ text }</h3>
			</div>
		);
	}

	renderTutorials(videos: VimeoTutorial[]) {
		if (!videos) {
			return null;
		}
		return (
			<div style={ this.styles.tutorialsContainer }>
				{Object.keys(videos).map(this.renderVideoThumbnail)}
			</div>
		);
	}

	renderVideoThumbnail(v: string, i: number, a: string[]) {
		const { connect, install } = this.props.videos;
		const { thumbnailBase, thumbnailContainer, thumbnailContent, thumbnailHover, thumbnailTitle } = this.styles;
		const videos = { ...connect, ...install };
		const { id, thumbnail, title } = videos[v];

		return (
			<div
				data-video={id}
				key={id + "_" + i + "_" + title}
				onClick={this.clickVideo}
				style={thumbnailContainer}
			>
				<div style={thumbnailBase}>
					<div className="admin-video-icon" style={thumbnailHover}>
						<Icon name="play" size="big" />
					</div>
					<img
						alt={title}
						src={ thumbnail }
						style={thumbnailContent}
					/>
				</div>
				<div style={thumbnailTitle}>
					{title}
				</div>
			</div>
		);
	}

	clickVideo(e: React.MouseEvent<HTMLDivElement>) {
		e.preventDefault();

		this.setVideoActive(parseInt(e.currentTarget.getAttribute("data-video") || "", 10));
	}

	setVideoActive(activeVideo: number) {
		this.props.setMediaPreview("", activeVideo, "video");
	}
}

export default connectToRedux(mapStateToProps, mapDispatchToProps)(AdminTutorialsPage);

import { IAdminApi, IApi, IDeviceApi, ISystemApiV1 } from "@connect/Interfaces";
import AdminApi from "Api/V1/AdminApi";
import DeviceApi from "Api/V1/DeviceApi";
import SystemApi from "Api/V1/SystemApi";

export default class LaravelApi implements IApi {
	constructor() {
		this.DeviceApi = new DeviceApi();
		this.SystemApi = new SystemApi();
		this.AdminApi = new AdminApi();
	}

	DeviceApi: IDeviceApi;
	SystemApi: ISystemApiV1;
	AdminApi: IAdminApi;
}
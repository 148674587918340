import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IStore } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { Button, DropZone, HelpPopover, Input, Modal } from "Components/Global/Common";
import { CsvHandler, CsvResults } from "Components/Global/CsvHandler";
import StoreHours from "Components/Global/StoreHours";
import { createStoreAsync, createBulkStoresAsync } from "Data/Actions/Company";
import { setCreateStoreModal } from "Data/Actions/UI/Modals";
import { DefaultMaxLength, PostalCodeMaxLength } from "Data/Objects/Global";
import { Store } from "Data/Objects/Store";
import { getStores } from "Data/Selectors/Company";
import { getCreateStoreModalVisibility } from "Data/Selectors/UI";

const { properCase, replaceSeparators } = Utils;

const mapDispatchToProps = (dispatch) => ({
	createStore: (store: IStore) => dispatch(createStoreAsync(store)),
	createBulkStores: (storesCSV: string) => dispatch(createBulkStoresAsync(storesCSV)),
	hideModal: () => dispatch(setCreateStoreModal(false))
});

const mapStateToProps = (state) => ({
	stores: getStores(state),
	visible: getCreateStoreModalVisibility(state)
});

interface IStoreFormProps {
	createStore: (store: IStore) => Promise<void>;
	createBulkStores: (storesCSV: string) => Promise<void>;
	hideModal: () => void;
	saveCallback?: () => any;
	stores: IStore[];
	visible: boolean;
};

interface IStoreFormState {
	store: IStore;
};

export class StoreCreateEditForm extends React.Component<IStoreFormProps, IStoreFormState> {
	constructor(props: IStoreFormProps) {
		super(props);

		this.state = {
			store: new Store("")
		};

		this.styles = {
			container: {
				margin: "12px 0px 12px 0px",
				width: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center"
			},
			base: {
				display: "block",
				width: 354,
				alignItems: "center",
				justifyContent: "center",
				marginTop: 15
			},
			form: {
				display: "block",
				alignItems: "center",
				justifyContent: "center"
			},
			helpDescription: {
				whiteSpace: "pre-line",
				marginLeft: 8
			},
			helpFields: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between"
			},
			helpTitle: {
				whiteSpace: "pre-line"
			},
			dropzone: {
				marginBottom: 8,
				flexDirection: "column",
				width: "100%",
				padding: 6
			},
			link: {
				display: "inline",
				textDecoration: "none"
			}
		}

		this.storeFields = [ "name", "address1", "address2", "city", "state", "postalCode", "hours" ];

		this.createStore = this.createStore.bind(this);
		this.getMaxLength = this.getMaxLength.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.handleStoreInfoChange = this.handleStoreInfoChange.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.openDropZone = this.openDropZone.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.saveBulkUpload = this.saveBulkUpload.bind(this);
		this.setDropZoneRef = this.setDropZoneRef.bind(this);
		this.setStore = this.setStore.bind(this);
	}

	dropzone: DropZone;
	storeFields: string[];
	styles: CustomCSS;

	render() {
		return (
			<Modal
				modalKey="creatStoreModal"
				onCancel={ this.handleCancel }
				visible={ this.props.visible }
			>
				<React.Fragment>
					<h3>Create Store</h3>
					<div style={ this.styles.container }>
						{ this.renderForm() }
					</div>
				</React.Fragment>
			</Modal>
		);
	}

	renderBulkUploadForm() {
		const { dropzone, helpDescription, helpFields, helpTitle, link } = this.styles;

		return (
			<DropZone
				style={ dropzone }
				ref={ this.setDropZoneRef }
				prompt={ <span /> }
				onDrop={ this.handleDrop }
			>
				Drop a CSV here (one store per line) for<br />
				<a style={ link } onClick={ this.openDropZone }>&nbsp;Bulk Check In</a><br />
				<span>
					<a style={ link } href="/csv/StoresBulkUploadTemplate.csv" download>Download Template</a>
					<HelpPopover title="Bulk Store Check In">
						Please fill out the template fields as follows, with one store per row.
						<div style={ helpFields }>
							<div style={ helpTitle }>
								{`
									Store Number:
									Address 1:
									Address 2:
									City:
									State:
									Zip Code:
									Open Time:
									Close Time:
									Sleep Mode Enabled:
								`}
							</div>
							<div style={ helpDescription }>
								{`
									Any combination of letters and numbers
									Any valid address
									Any valid address
									Any valid city
									Any valid state
									Any valid Zip Code
									Hours and minutes in 24 hour format (8AM is 08:00, 8PM is 20:00)
									Hours and minutes in 24 hour format (8AM is 08:00, 8PM is 20:00)
									0 or 1
								`}
							</div>
						</div>
					</HelpPopover>
				</span>
			</DropZone>
		);
	}

	renderForm() {
		const { form, base } = this.styles;

		return (
			<div style={ form }>
				{ this.storeFields.map(this.renderInput) }
				{ this.renderBulkUploadForm() }
				<Button
					style={ base }
					onClick={ this.setStore }
				>
					Create Store
				</Button>
			</div>
		);
	}

	renderInput(field: string) {
		const placeholder = properCase(replaceSeparators(field, "_", " "));
		const storeValue = this.state.store[field];

		if (field === "hours") {
			return (
				<StoreHours
					key="create_new_store_hours"
					store={ this.state.store }
					updateStore={ this.handleStoreInfoChange }
				/>
			);
		}

		return (
			<Input
				id={ field }
				key={ field }
				placeholder={ placeholder }
				style={ this.styles.base }
				updateCallback={ this.handleStoreInfoChange(field) }
				value={ storeValue }
				maxLength={ this.getMaxLength(field) }
			/>
		);
	}

	createStore(store: IStore) {
		const { stores, createStore, hideModal } = this.props;
		const { name } = store;
		const storeNames = stores.map((s) => s.name);

		if (name.length) {
			// check for duped names and don't allow
			if (storeNames.includes(name)) {
				this.notifyUniqueName(name);
			} else {
				return createStore(store);
			}
		}

		hideModal();
		this.resetForm();
		return Promise.reject(false);
	}

	getMaxLength(field: string) {
		switch (field) {
			case "postalCode":
				return PostalCodeMaxLength;
			default:
				return DefaultMaxLength;
		}
	}

	handleCancel() {
		this.resetForm();
		this.props.hideModal();
	}

	handleDrop(files: File[]) {
		const handler = new CsvHandler();
		handler.requiredHeaders = [ "Store Number", "Address 1", "Address 2", "City", "State",
			"Zip Code", "Open Time", "Close Time", "Sleep Mode Enabled" ];
		handler.onSuccess = this.handleSuccess;
		handler.handleFiles(files);
	}

	handleSuccess(results: CsvResults) {
		const stores = results.data.join("\n");
		if (!stores) {
			Notifications.error(
				"No store information received.",
				"Please confirm that your file contents match the template layout and try again."
			);
			return;
		}

		this.saveBulkUpload(stores);
	}

	handleStoreInfoChange(key: string) {
		return (value: string) => {
			this.setState((prevState) => {
				return update(prevState, {
					store: { [key]: { $set: value }}
				});
			});
		}
	}

	notifyUniqueName(name?: string) {
		Notifications.error(
			`Store ${ name } already exists.`,
			"Store names must be unique",
			3
		);
	}

	openDropZone() {
		this.dropzone.open();
	}

	resetForm() {
		this.setState((prevState) => {
			return update(prevState, {
				store: { $set: new Store("") }
			});
		});
	}

	saveBulkUpload(text: string) {
		this.props.createBulkStores(text);

		this.props.hideModal();
		this.resetForm();
	}

	setDropZoneRef(node: DropZone) {
		this.dropzone = node;
	}

	setStore() {
		const { store } = this.state;

		this.createStore(store)
			.then(() => {
				this.resetForm();
				this.props.hideModal();
			});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreCreateEditForm);
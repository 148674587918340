import { createSelector } from "reselect";

import { CompanyFilterOption, CompanyMenuOption, ICompany, IExtendedCompany, IStore,
	SortTypes } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { AppState, CompanyState } from "Data/Objects/AppState";
import { identityReducer } from "Data/Utils";

type AnyCompany = ICompany | IExtendedCompany | null;

interface CompanyFilters {
	company?: string;
	sort?: string;
	text?: string;
}

export function getActiveCompanyUuid(company: ICompany): string {
	return company.uuid;
}

export function getAllCompanies(state: CompanyState): ICompany[] {
	return state.companies;
}

export function getAllManagedCompanies(state: CompanyState): IExtendedCompany[] {
	return state.managedCompanies;
}

export function getAllStores(state: CompanyState): IStore[] {
	return state.stores.filter((store) => {
		// we can sometimes have a garbage store that does not contain any properties but `deviceCount`
		return store.uuid && store.name;
	});
}

export function getStoreId(state: CompanyState, store: IStore) {
	return store.uuid;
}

export function getAllCompaniesFiltered(companies: ICompany[], companyFilters: CompanyFilters) {
	const { company, sort, text } = Object.assign({}, { company: "", sort: "name", text: "" }, companyFilters);
	const sortText = text.toLowerCase();
	const companyOrNumber = company.length && Number.isNaN(Number.parseInt(company, 10));

	if (!companies.length) {
		return [];
	}

	return companies.filter((c) => {
		const n = c.name.toLowerCase();
		const hasText = sortText.length ? n.indexOf(sortText) > -1 : true;
		const hasCompany = companyOrNumber ? n.indexOf(company) > -1 : true;

		return hasText && hasCompany;
	}).sort((a: ICompany, b: ICompany) => {
		if (a[sort] > b[sort]) {
			return 1;
		} else if (b[sort] < a[sort]) {
			return -1;
		}
		return 0;
	});
}

export function getAllStoresFiltered(stores: IStore[], search: string) {
	if (!stores.length) {
		return [];
	}

	return stores.filter((s) => {
		let n = s.name.toLowerCase();
		return n.indexOf(search.toLowerCase()) > -1;
	});
}

export function getAllIntegratorCompanies(companies: ICompany[]) {
	const userCompany = companies.filter((company) => (company.teamType === "integrator"));

	return userCompany;
}

export function getAllStoresSorted(stores: IStore[]) {
	return Utils.sortListBySortType(stores, SortTypes.ALPHA);
}

export function getCompanyExists(companies: AnyCompany[], companyId: string): AnyCompany {
	const company = companies.filter((c) => (c && c.uuid) === companyId);

	return company.length ? company[0] : null;
}

export function getCompanyState(state: AppState): CompanyState {
	return state.Company;
}

export function getIntegratorTeams(state: CompanyState) {
	return state.teams;
}

export function getStateActiveCompany(state: CompanyState): ICompany {
	return state.activeCompany;
}

export function mapCompanyFilterOptions(companies: ICompany[]): CompanyFilterOption[] {
	let companyOptions = [ { key: "All", name: "All", text: "\u00a0", value: "" } ];

	companies.forEach((c, i) => {
		companyOptions.push({
			key: c.uuid + i,
			name: c.name,
			text: c.name,
			value: c.name
		});
	});

	return companyOptions;
}

export function mapCompanyOptions(companies: ICompany[], activeCompanyId: string): CompanyMenuOption[] {
	return companies.map((c) => ({
		active: c.uuid === activeCompanyId,
		key: c.uuid,
		text: c.name,
		value: c.uuid
	}));
}

export function getStoreByUUID(stores: IStore[], uuid: string) {
	return stores.find((s: IStore) => {
		return s.uuid === uuid;
	});
}

export const getActiveCompany = createSelector(
	[ getCompanyState ],
	getStateActiveCompany
);

export const getActiveCompanyId = createSelector(
	[ getActiveCompany ],
	getActiveCompanyUuid
);

export const getCompanies = createSelector(
	[ getCompanyState ],
	getAllCompanies
);

export const getUserIntegrators = createSelector(
	[ getCompanies ],
	getAllIntegratorCompanies
);

export const getManagedCompanies = createSelector(
	[ getCompanyState ],
	getAllManagedCompanies
);

export const getStores = createSelector(
	[ getCompanyState ],
	getAllStores
);

export const getCompany = createSelector(
	[ getCompanies, identityReducer ],
	getCompanyExists
);

export const getManagedCompany = createSelector(
	[ getManagedCompanies, identityReducer ],
	getCompanyExists
);

export const getCompanyFilterOptions = createSelector(
	[ getCompanies ],
	mapCompanyFilterOptions
);

export const getCompanyOptions = createSelector(
	[ getCompanies, getActiveCompanyId ],
	mapCompanyOptions
);

export const getFilteredCompanies = createSelector(
	[ getCompanies, identityReducer ],
	getAllCompaniesFiltered
);

export const getFilteredStores = createSelector(
	[ getStores, identityReducer ],
	getAllStoresFiltered
);

export const getSortedStores = createSelector(
	[ getStores ],
	getAllStoresSorted
);

export const getStoreById = createSelector(
	[ getStores, identityReducer ],
	getStoreByUUID
);

export const getTeams = createSelector(
	[ getCompanyState ],
	getIntegratorTeams
);

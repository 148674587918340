import Radium from "radium";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, ICompany, IDevice, IDeviceGroup, NameUuid, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import DeviceGridWrapper from "Components/Devices/DeviceGridWrapper";
import DeviceGroupsGrid from "Components/Devices/DeviceGroupsGrid";
import { AccordionElement } from "Components/Global/Accordion";
import Collapse from "Components/Global/Collapse";
import { Colors } from "Components/Global/Constants";
import Icon from "Components/Global/Icon";
import { setActiveFilters, setActiveUuid } from "Data/Actions/UI";
import { getDeviceGroupPath, getDeviceGroups, getVisibleDeviceGroups } from "Data/Selectors/Devices";
import { getActiveFilters, getActiveSorts, getActiveUuid } from "Data/Selectors/UI";

const mapDispatchToProps = (dispatch) => ({
	selectGroup: (groupId: string) => dispatch(setActiveFilters("group", Filters.DEVICES, groupId)),
	setActiveDeviceGroup: (id: string) => dispatch(setActiveUuid("deviceGroups", id))
});

const mapStateToProps = (state) => {
	const filters = getActiveFilters(state, Filters.DEVICES);
	const activeGroup = String(filters.group);
	const sortType = getActiveSorts(state, Sorts.DEVICES);

	return {
		activeDeviceGroupUuid: getActiveUuid(state, "deviceGroups"),
		activeGroup,
		deviceGroupPath: getDeviceGroupPath(state, filters.group as string),
		deviceGroups: getDeviceGroups(state),
		sortType,
		visibleDeviceGroups: getVisibleDeviceGroups(state, activeGroup)
	};
};

interface DeviceContentAreaProps {
	activeCompany: ICompany;
	activeDeviceGroupUuid: string;
	activeGroup: string;
	createNewDeviceGroup: () => void;
	deviceGroupPath: NameUuid[];
	deviceGroups: IDeviceGroup[];
	devices: IDevice[];
	fetchDevices?: (companyUuid: string) => void;
	haveAllDevices: boolean;
	lastPageFetched: number;
	selectGroup: (groupId: string) => void;
	selectModeOn: boolean;
	sortType: SortTypes;
	setActiveDeviceGroup: (id: string) => void;
	visibleDeviceGroups: IDeviceGroup[];
}

interface Element {
	icon: string;
	header: string;
	content: JSX.Element;
	canGrow?: boolean;
	maxHeight?: number;
}

@Radium
export class DeviceContentArea extends React.Component<DeviceContentAreaProps> {
	constructor(props: DeviceContentAreaProps) {
		super(props);

		this.styles = {
			breadcrumb: {
				flex: "0 0 auto",
				alignItems: "flex-start",
				color: Colors.gray,
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				justifyContent: "flex-start",
				position: "relative",
				fontWeight: "bold",
				margin: "-4px 0px 0px 16px"
			},
			breadcrumbPart: {
				cursor: "pointer",
				flex: "0 0 auto",
				":hover": {
					color: Colors.primaryBlue
				}
			},
			breadcrumbSeparator: {
				cursor: "initial",
				padding: "0 6px"
			},
			content: {
				height: "100%"
			},
			header: {
				width: "100%",
				borderTop: `2px solid ${ Colors.lightestGray }`,
				marginTop: 6
			},
			groups: {
				height: "18vh",
				overflow: "auto",
				paddingBottom: 5
			},
			devices: {
				height: "66vh"
			},
			panel: {
				marginBottom: 0,
				position: "static",
				overflow: "hidden"
			}
		}

		this.createElement = this.createElement.bind(this);
		this.renderBreadcrumb = this.renderBreadcrumb.bind(this);
		this.renderBreadcrumbPart = this.renderBreadcrumbPart.bind(this);
		this.renderDeviceGrid = this.renderDeviceGrid.bind(this);
		this.renderDeviceGroupsGrid = this.renderDeviceGroupsGrid.bind(this);
		this.selectGroup = this.selectGroup.bind(this);
	}

	styles: {
		header: CustomCSS;
		breadcrumb: CustomCSS;
		breadcrumbPart: CustomCSS;
		breadcrumbSeparator: CustomCSS;
		content: CustomCSS;
		groups: CustomCSS;
		devices: CustomCSS;
		panel: CustomCSS;
	};

	componentDidUpdate() {
		const { activeDeviceGroupUuid, selectModeOn, setActiveDeviceGroup } = this.props;

		if (selectModeOn && activeDeviceGroupUuid) {
			setActiveDeviceGroup("");
		}
	}

	render() {
		const { content, panel } = this.styles;
		const elements = [
			{
				icon: "folder",
				header: "Device Groups",
				content: this.renderDeviceGroupsGrid(),
				canGrow: true,
				maxHeight: 205
			},
			{
				icon: "mobile",
				header: "Devices",
				content: this.renderDeviceGrid()
			}
		];

		return (
			<div style={ content }>
				<Collapse
					customPanelStyle={ panel }
					className="collapse"
					header={ this.renderBreadcrumb() }
					elements={ elements.map(this.createElement) }
				/>
			</div>
		);
	}

	renderBreadcrumb() {
		return (
			<div style={ this.styles.breadcrumb }>
				{ this.props.deviceGroupPath.map(this.renderBreadcrumbPart) }
			</div>
		);
	}

	renderBreadcrumbPart(group: NameUuid, index: number, arr: NameUuid[]) {
		const suffix = index + 1 === arr.length ? "" : "/";
		return (
			<span key={ group.uuid + group.name + index + "_breadcrumb" } style={ this.styles.breadcrumbPart }>
				<span onClick={ this.selectGroup(group.uuid) }>{ group.name }</span>
				<span style={ this.styles.breadcrumbSeparator }>{ suffix }</span>
			</span>
		);
	}

	renderDeviceGrid() {
		const { selectModeOn, sortType } = this.props;

		return (
			<div style={ this.styles.devices }>
				<DeviceGridWrapper
					key={ `device-content-area-grid-${ sortType }` }
					selectModeOn={ selectModeOn }
				/>
			</div>
		);
	}

	renderDeviceGroupsGrid() {
		const { activeGroup, createNewDeviceGroup, deviceGroups, selectModeOn, visibleDeviceGroups } = this.props;
		const topLevelGroups = deviceGroups.filter((group) => !group.parent);
		const visibleGroups = activeGroup === "0" ? topLevelGroups : visibleDeviceGroups;

		return (
			<div style={ this.styles.groups }>
				<DeviceGroupsGrid
					content={ visibleGroups }
					createNewDeviceGroup={ createNewDeviceGroup }
					key="device-groups-grid"
					selectModeOn={ selectModeOn }
				/>
			</div>
		);
	}

	createElement(element: Element) {
		const { icon, header, content, canGrow, maxHeight } = element;

		return new AccordionElement(
			<div style={ this.styles.header }>
				<Icon
					fixedWidth
					name={ icon }
					iconWeight="solid"
					size="smaller"
				/>&nbsp;
				{ header }
			</div>,
			content,
			undefined,
			undefined,
			canGrow,
			undefined,
			maxHeight
		);
	}

	selectGroup(groupId: string) {
		return (event) => {
			event.preventDefault();

			this.props.selectGroup(groupId);
		};
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceContentArea);
import * as moment from "moment";

import { Deployment } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";

export const DeployStepDetails = {
	"schedule": {
		title: {
			text: "Select Playlists",
			icon: "list-ul"
		},
		subtitle: "Drag & Drop saved playlists into the target area below.",
		validate: validateScheduleStep
	},
	"devices": {
		title: {
			text: "Select Location",
			icon: "map-marker"
		},
		subtitle:
			`Select which device group(s) you would like to deploy to or
			deploy to individual devices by selecting the Device tab.`,
		validate: validateDevicesStep
	},
	"confirm": {
		title: {
			text: "Deployment Checklist",
			icon: "check-square"
		},
		subtitle: "Review the information below and make any last minute changes before hitting Deploy.",
		validate: validateConfirmStep
	}
};

export function calculateDeviceCounts(
	{ deviceStatus, missedCount, successCount }: Partial<Deployment>,
	asPercentage: boolean = false
): { [key: string]: number } {
	const successes = (deviceStatus || []).map((device) => device.success);
	let missCount = 0;
	let missed = 0;
	let succeedCount = 0;
	let success = 0;

	if (missedCount !== undefined) {
		missCount = missedCount;
	} else {
		missCount = successes.filter((val) => !val).length
	}

	if (successCount !== undefined) {
		succeedCount = successCount;
	} else {
		succeedCount = successes.filter(Boolean).length
	}

	const total = missCount + succeedCount;

	if (asPercentage) {
		if (missCount !== 0) {
			missed = Math.round(missCount / total * 100);
		}
		if (succeedCount !== 0) {
			success = Math.round(succeedCount / total * 100);
		}
	}

	return { missCount, missed, succeedCount, success, total };
}

export function validateName(name: string) {
	const validName = name && Utils.validateName(name)
	let message = "";

	if (!validName) {
		if (!name) {
			message = "This is a required field. Please assign a name for this deployment.";
		} else {
			message = "Please assign a name for this deployment that meets our naming guidelines.";
		}
	}

	return message;
}

export function validateCreateNewStep(deployment: Partial<Deployment>) {
	const { name, type } = deployment;
	const validName = validateName(name || "")

	return {
		isValid: type && !validName,
		message: validName
	};
}

export function validateScheduleStep(deployment: Partial<Deployment>) {
	const { schedule } = deployment;
	return {
		isValid: (schedule || []).length > 0,
		message: "Please drag & drop a Playlist(s) into the target area to continue."
	};
}

export function validateDevicesStep(deployment: Partial<Deployment>) {
	const { devices, deviceGroups } = deployment;
	return {
		isValid: (devices || []).length > 0 || (deviceGroups || []).length > 0,
		message: "Please select a device group(s) or individual device(s) to deploy to."
	};
}

export function validateConfirmStep(deployment: Partial<Deployment>) {
	const { endDate, name, startDate } = deployment;
	const validName = validateName(name || "")
	const validDate = endDate === null ? true : moment(endDate).diff(moment(startDate)) > 0;

	let message: { name?: string, startDate?: string } = {};

	if (validName) {
		message.name = validName;
	}
	if (!validDate) {
		message.startDate = "Event Start Date and Time must be before End Date and Time.";
	}

	return {
		isValid: !validName && validDate,
		message
	};
}

import * as React from "react";
import { connect } from "react-redux";

import { ActionSetActions, CustomCSS } from "@connect/Interfaces";
import { ActionPropertiesPanelContent,
	ActionPropertiesPanelContentProps } from "Components/Actions/ActionPropertiesPanelContent";
import AudioSlider from "Components/Global/AudioSlider";
import { updateSelectedActionVolume } from "Data/Actions/ActionsAsync";
import { getSelectedActionType, getSelectedActionVolume } from "Data/Selectors/ActionBuilder";
import { mapStateToProps as superMapStateToProps } from "Components/Actions/ActionPropertiesPanelContent";


const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		activeActionType: getSelectedActionType(state),
		volume: getSelectedActionVolume(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateVolume: (volume: number) => dispatch(updateSelectedActionVolume(volume))
	}
}

interface AudioActionPropertiesPanelContentProps extends ActionPropertiesPanelContentProps {
	volume: number;
	updateVolume: (volume: number) => void;
}

export class AudioActionPropertiesPanelContent
	extends ActionPropertiesPanelContent<AudioActionPropertiesPanelContentProps> {
	constructor(props: AudioActionPropertiesPanelContentProps) {
		super(props);

		const { icon, title } = ActionSetActions.audio_level;

		this.icon = icon;
		this.title = title;

		this.styles = {
			...this.styles,
			label: {
				flex: 1,
				paddingTop: 2,
				textAlign: "left"
			},
			content: {
				display: "flex"
			},
			slider: {
				flex: 1
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { volume, updateVolume } = this.props;
		const { label, content, slider } = this.styles;

		return this.renderContainer(
			<div style={ content } >
				<div style={ label }>
					Volume:
				</div>
				<div style={ slider }>
					<AudioSlider
						handleChange={ updateVolume }
						volume={ volume } />
				</div>
			</div >
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioActionPropertiesPanelContent);

import { createSelector } from "reselect";

import { ActionSet, ActionType, Trigger, TriggeredAction, NetworkActionAuthTypes } from "@connect/Interfaces";
import { ActionBuilderState, UIState } from "Data/Objects/AppState";
import { getActiveActionSet } from "Data/Selectors/Actions";
import { getActiveAdByUuid, getAllAds } from "Data/Selectors/Ads";
import { getUIState } from "Data/Selectors/UI";

export function getActionBuilder(state: UIState) {
	return state.actionBuilderState;
}

export function getActionType(state: ActionBuilderState) {
	return state.selectedAction;
}

export function getTriggerIndex(state: ActionBuilderState) {
	return state.selectedTrigger;
}

export function getTriggerType(actionSet: ActionSet, index: number) {
	return actionSet && actionSet.data && actionSet.data[index] && actionSet.data[index].type;
}

export function getTrigger(actionSet: ActionSet, index: number) {
	return actionSet && actionSet.data && actionSet.data[index];
}

export function getHasAd(trigger: Trigger | undefined) {
	return trigger && trigger.actions && trigger.actions.map((t: TriggeredAction) => {
		return t.type === "ad"
	}).reduce((prevVal, nextVal) => {
		return prevVal || nextVal
	}, false);
}

export function getAction(trigger: Trigger | undefined, actionType: ActionType) {
	return trigger && trigger.actions && trigger.actions.find((a: TriggeredAction) => {
		return a.type === actionType
	});
}

export function getTriggerTypes(selectedActionSet: ActionSet) {
	return selectedActionSet.data.map((t: Trigger) => {
		return t.type;
	});
}

export function getDuration(trigger: Trigger) {
	return trigger && trigger.duration;
}

export function getContinuous(trigger: Trigger) {
	return trigger?.continuous;
}

export function getThrottle(trigger: Trigger) {
	return trigger && trigger.throttle;
}

export function getDwell(trigger: Trigger) {
	return trigger?.dwell;
}

export function getBoxing(trigger: Trigger) {
	return trigger?.boxing;
}

export function getParameter(trigger: Trigger) {
	return trigger?.parameter;
}

export function getVolume(action: TriggeredAction) {
	return action && action.audioLevel;
}

export function getAdUuid(action: TriggeredAction) {
	return action && action.adUuid;
}

export function getFlashing(action: TriggeredAction) {
	return action && action.flashing;
}

export function getPreset(action: TriggeredAction) {
	return action && action.preset;
}

export function getURI(action: TriggeredAction) {
	return action && action.uri;
}

export function getMethod(action: TriggeredAction) {
	return action && action.method;
}

export function getAuth(action: TriggeredAction) {
	return (action && action.auth) || { type: NetworkActionAuthTypes.NONE };
}

export const getActionBuilderState = createSelector(
	[ getUIState ],
	getActionBuilder
);

export const getSelectedActionType = createSelector(
	[ getActionBuilderState ],
	getActionType
);

export const getSelectedTriggerIndex = createSelector(
	[ getActionBuilderState ],
	getTriggerIndex
);

export const getSelectedTriggerType = createSelector(
	[ getActiveActionSet, getSelectedTriggerIndex ],
	getTriggerType
);

export const getUsedTriggers = createSelector(
	[ getActiveActionSet ],
	getTriggerTypes
);

export const getSelectedTrigger = createSelector(
	[ getActiveActionSet, getSelectedTriggerIndex ],
	getTrigger
);

export const getSelectedAction = createSelector(
	[ getSelectedTrigger, getSelectedActionType ],
	getAction
);

export const getActionHasAd = createSelector(
	[ getSelectedTrigger ],
	getHasAd
);

export const getSelectedTriggerDuration = createSelector(
	[ getSelectedTrigger ],
	getDuration
);

export const getSelectedTriggerThrottle = createSelector(
	[ getSelectedTrigger ],
	getThrottle
);

export const getSelectedTriggerDwell = createSelector(
	[ getSelectedTrigger ],
	getDwell
);

export const getSelectedTriggerContinuous = createSelector(
	[ getSelectedTrigger ],
	getContinuous
);

export const getSelectedTriggerBoxing = createSelector(
	[ getSelectedTrigger ],
	getBoxing
);

export const getSelectedTriggerParameter = createSelector(
	[ getSelectedTrigger ],
	getParameter
);

export const getSelectedActionVolume = createSelector(
	[ getSelectedAction ],
	getVolume
);

export const getSelectedActionAdUuid = createSelector(
	[ getSelectedAction ],
	getAdUuid
)

export const getSelectedActionAd = createSelector(
	[ getAllAds, getSelectedActionAdUuid ],
	getActiveAdByUuid
);

export const getSelectedActionFlashing = createSelector(
	[ getSelectedAction ],
	getFlashing
);

export const getSelectedActionPreset = createSelector(
	[ getSelectedAction ],
	getPreset
);

export const getSelectedActionURI = createSelector(
	[ getSelectedAction ],
	getURI
);

export const getSelectedActionMethod = createSelector(
	[ getSelectedAction ],
	getMethod
);

export const getSelectedActionAuth = createSelector(
	[ getSelectedAction ],
	getAuth
)

import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { connect } from "react-redux";

import { getActiveAnalyticsFetching, getActiveExtendedDeviceStats } from "@connect/Data/Selectors/AdAnalytics";
import { CustomCSS, DeviceStats, IDevice } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import ActivityIndicator from "Components/Analytics/ActivityIndicator";
import { Loader, Table } from "Components/Global/Common";

interface ExtendedDeviceStats extends DeviceStats {
	views: number;
	dwellTime: number;
	button: number;
	alarm0: number;
	alarm1: number;
	motion: number;
}

const mapStateToProps = (state) => {
	return {
		deviceStats: getActiveExtendedDeviceStats(state),
		loading: getActiveAnalyticsFetching(state)
	};
};

interface AnalyticsTableProps {
	deviceStats: ExtendedDeviceStats[];
	loading: boolean;
	handleDeviceClick: (uuid: string) => () => void;
}

class AnalyticsTable extends React.Component<AnalyticsTableProps, {}> {
	constructor(props: AnalyticsTableProps) {
		super(props);

		this.styles = {
			table: {
				padding: 10
			},
			loadingIcon: {
				marginLeft: 6
			},
			loadingIndicator: {
				position: "absolute",
				top: "80%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				zIndex: 100,
				fontWeight: 600,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center"
			},
			wrapper: {
				marginTop: "1em",
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%"
			}
		}
	}

	styles: CustomCSS;

	renderLoadingNotification() {
		const { loading } = this.props;
		const { loadingIcon, loadingIndicator } = this.styles;

		if (!loading) {
			return null;
		}

		const text = "Updating your stats. This may take a moment...";

		return (
			<div style={ loadingIndicator }>
				{ text }
				<div style={ loadingIcon }>
					<Loader size="small" />
				</div>
			</div>
		);
	}

	render() {
		const { wrapper } = this.styles;
		if (this.props.loading) {
			return (
				<div>
					{ this.renderLoadingNotification() }
					<div style={{...wrapper, filter: this.props.loading ? "blur(5px)" : null}} />
				</div>
			);
		}

		return (
			<Table
				style={ this.styles.table }
				dataSource={ this.props.deviceStats }
				locale={ { emptyText: "No Data" } }
				pagination={ false }
				columns={ this.getColumns() }/>
		);
	}

	getColumns() {
		const columns: ColumnProps<ExtendedDeviceStats>[] = [
			{
				title: "Device",
				dataIndex: "device",
				key: "device",
				render: this.renderDevice,
				sorter: Utils.columnSorter("device.name")
			},
			{
				title: "Location",
				dataIndex: "store.name",
				key: "location",
				render: this.renderString,
				sorter: Utils.columnSorter("store.name")
			},
			{
				title: "Activity",
				dataIndex: "activity",
				key: "activity",
				render: this.renderActivityIndicator,
				sorter: Utils.columnSorter("activity"),
				defaultSortOrder: "descend"
			},
			{
				title: "Views",
				dataIndex: "views",
				key: "views",
				render: this.renderNumber,
				sorter: Utils.columnSorter("views")
			},
			{
				title: "Avg. Dwell",
				dataIndex: "dwellTime",
				key: "dwell",
				sorter: Utils.columnSorter("dwellTime")
			},
			{
				title: "Peak Traffic",
				dataIndex: "peakTraffic",
				key: "traffic",
				render: this.renderString,
				sorter: Utils.columnSorter("peakTraffic")
			},
			{
				title: "Motion",
				dataIndex: "motion",
				key: "motion",
				render: this.renderNumber,
				sorter: Utils.columnSorter("motion")
			},
			{
				title: "Button",
				dataIndex: "button",
				key: "button",
				render: this.renderNumber,
				sorter: Utils.columnSorter("button")
			},
			{
				title: "Alarm 1",
				dataIndex: "alarm0",
				key: "alarm0",
				render: this.renderNumber,
				sorter: Utils.columnSorter("alarm0")
			},
			{
				title: "Alarm 2",
				dataIndex: "alarm1",
				key: "alarm1",
				render: this.renderNumber,
				sorter: Utils.columnSorter("alarm1")
			}
		];
		return columns;
	}

	renderString(value: string) {
		return value || "N/A";
	}

	renderNumber(value: number) {
		return Utils.abbreviateNumber(value, 0);
	}

	renderActivityIndicator(activity: number) {
		return <ActivityIndicator activity={ activity }/>
	}

	renderDevice(device: Partial<IDevice>) {
		const { name, uuid } = device;

		return (
			<a href={ `/devices/${ uuid }` } target="_blank" rel="noopener noreferrer">
				{ name }
			</a>
		);
	}
}

export default connect(mapStateToProps)(AnalyticsTable);
import { Modal } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, InputValidator, NewCompanyRequest } from "@connect/Interfaces";
import { Button, Input } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { setCompanyRequestModalVisibility } from "Data/Actions/UI/Modals";
import { requestNewCompany } from "Data/Actions/SystemAsync";
import inputValidation from "Data/Objects/Validation";
import { getCompanyRequestModalVisibility } from "Data/Selectors/UI";

const { lightGray, primaryGreen, white } = Colors;

const mapDispatchToProps = (dispatch) => ({
	closeCompanyRequestForm: () => dispatch(setCompanyRequestModalVisibility(false)),
	submitRequest: (state: NewCompanyRequest) => dispatch(requestNewCompany(state))
});

const mapStateToProps = (state) => ({
	visible: getCompanyRequestModalVisibility(state)
});

const fieldItems: ICompanyRequestField[] = [
	{ name: "companyName", label: "Name of Company:", type: "text", validator: inputValidation.name },
	{ name: "contactName", label: "Company Contact Name:", type: "text", validator: inputValidation.name },
	{ name: "contactEmail", label: "Company Contact Email:", type: "email", validator: inputValidation.email },
	{ name: "companyWebsite", label: "Company Website:", type: "text" },
	{ name: "contactPhone", label: "Company Contact Phone #:", type: "tel" }
];

interface ICompanyRequestField {
	label: string;
	name: string;
	type: string;
	validator?: InputValidator[];
}

interface IRequestModalProps {
	closeCompanyRequestForm: () => void;
	submitRequest: (state: NewCompanyRequest) => void;
	visible: boolean;
}

interface IRequestModalState {
	canSubmit: boolean;
	newCompanyRequest: NewCompanyRequest;
}

export class UnwrappedCompanyRequestModal extends React.Component<IRequestModalProps, IRequestModalState> {
	constructor(props: IRequestModalProps) {
		super(props);

		this.styles = {
			cancelButton: {
				color: lightGray
			},
			center: {
				textAlign: "center"
			},
			section: {
				marginBottom: 10
			},
			submitButton: {
				color: white,
				marginLeft: 10
			}
		}

		this.state = {
			canSubmit: false,
			newCompanyRequest: this.resetFields()
		};

		this.disableSubmitButton = this.disableSubmitButton.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.renderFooterButtons = this.renderFooterButtons.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.resetFields = this.resetFields.bind(this);
		this.setInputValue = this.setInputValue.bind(this);
		this.submitCompanyRequestForm = this.submitCompanyRequestForm.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { section } = this.styles;

		return (
			<Modal
				destroyOnClose
				footer={ this.renderFooterButtons() }
				key="Add Company Request"
				onCancel={ this.handleClose }
				title="Add Company Request"
				visible={ this.props.visible }
				width="auto"
			>
				<p style={ section }>Please fill out the form below to request to add a new managed company to your account.</p>
				{ fieldItems.map(this.renderInput) }
				<p style={ section }>Please allow up to 2-3 business days for review of request.</p>
				<p style={ section }>If you are verified by the company, you will receive an email notification.</p>
			</Modal>
		);
	}

	renderFooterButtons() {
		const { cancelButton, submitButton } = this.styles;
		const submitDisabled = !this.canSubmit();

		return (
			<div>
				<Button
					icon="times"
					onClick={ this.handleClose }
					style={ cancelButton }
				>
					Cancel
				</Button>
				<Button
					disabled={ submitDisabled }
					icon="check"
					color={ primaryGreen }
					onClick={ this.submitCompanyRequestForm }
					style={ submitButton }
				>
					Submit
				</Button>
			</div>
		);
	}

	renderInput({ label, name, type, validator }: ICompanyRequestField) {
		return (
			<React.Fragment key={ name }>
				<div>{ label }</div>
				<Input
					id={ name }
					disableSubmitCallback={ this.disableSubmitButton }
					hideMaxLength
					key={ name }
					required
					style={ this.styles.section }
					type={ type }
					updateCallback={ this.setInputValue(name) }
					validator={ validator }
					value={ this.state.newCompanyRequest[name] }
				/>
			</React.Fragment>
		);
	}

	canSubmit() {
		const { canSubmit, newCompanyRequest } = this.state;
		const haveAllValues = Object.keys(newCompanyRequest).every((field) => !!newCompanyRequest[field]);

		return canSubmit && haveAllValues;
	}

	disableSubmitButton(canSubmit: boolean) {
		this.setState(() => ({ canSubmit }));
	}

	handleClose() {
		this.setState(
			() => ({ newCompanyRequest: this.resetFields() }),
			() => this.props.closeCompanyRequestForm()
		);
	}

	resetFields() {
		return fieldItems.reduce((newState, { name }) =>
			Object.assign({}, newState, {
				[name]: ""
			}), {}) as NewCompanyRequest;
	}

	setInputValue(name: string) {
		return (value: string) =>
			this.setState((state) => ({
				newCompanyRequest: Object.assign({}, state.newCompanyRequest, {
					[name]: value
				})
			}));
	}

	submitCompanyRequestForm() {
		const newCompanyRequest = Object.assign({
			companyName: "",
			contactName: "",
			contactEmail: "",
			companyWebsite: "",
			contactPhone: ""
		}, this.state.newCompanyRequest);

		if (this.canSubmit()) {
			this.props.submitRequest(newCompanyRequest);
			this.handleClose();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedCompanyRequestModal);

import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, Deployment } from "@connect/Interfaces";
import Builder from "Components/Deploy/Schedule/Builder";
import Overlay from "Components/Deploy/Schedule/Overlay";
import { Colors } from "Components/Global/Constants";
import { getActiveDeployment } from "Data/Selectors/DeploymentWizard";

const mapStateToProps = (state) => ({
	activeDeployment: getActiveDeployment(state)
});

interface IScheduleProps {
	activeDeployment: Deployment;
}

interface IScheduleState {
	containerHeight: number;
}

class Schedule extends React.Component<IScheduleProps, IScheduleState> {
	constructor(props: IScheduleProps) {
		super(props);

		this.state = {
			containerHeight: 0
		}

		this.styles = {
			base: {
				textAlign: "center",
				position: "relative",
				display: "flex",
				justifyContent: "center",
				height: "75vh",
				minHeight: 576,
				width: "100%",
				marginTop: 4
			},
			container: {
				position: "relative",
				height: "100%",
				width: "65%"
			},
			errorStyle: {
				border: `2px solid ${Colors.errorRed}`
			}
		}

		this.handleSetContainerHeight = this.handleSetContainerHeight.bind(this);
		this.incrementsPerHour = 4;
		this.hourCount = 24;
		this.containerHeightIncrement = this.hourCount * this.incrementsPerHour;
	}

	incrementsPerHour: number;
	hourCount: number;
	containerHeightIncrement: number;
	styles: CustomCSS;

	componentDidUpdate(prevProps: IScheduleProps, prevState: IScheduleState) {
		// we can replace the reference to container with a new object here
		// but we cannot reassign attributes of that object without emitting warnings
		// e.g. this.styles.container.height = nextState.containerHeight
		this.styles.container = { ...this.styles.container, height: this.state.containerHeight }

		if (this.state.containerHeight !== prevState.containerHeight) {
			this.forceUpdate();
		}
	}

	render() {
		const { activeDeployment } = this.props;

		if (!activeDeployment || !activeDeployment.schedule) {
			return null;
		}

		const { base, container, errorStyle } = this.styles;
		const { schedule } = activeDeployment;
		const valid = schedule && schedule.length;
		const error = (!valid && errorStyle);
		const { containerHeight } = this.state;

		return (
			<div style={base}>
				<div style={{ ...container, ...error }}>
					<Overlay />
					<Builder
						id="builder"
						minItemHeight={this.getMinItemHeight()}
						containerHeight={containerHeight}
						items={schedule}
						onSetContainerHeight={this.handleSetContainerHeight}
					/>
				</div>
			</div>
		);
	}

	getMinItemHeight() {
		return Math.round(this.state.containerHeight / this.hourCount);
	}

	handleSetContainerHeight(height: number) {
		const increment = this.containerHeightIncrement
		// set height to a multiple of 96 to allow 15 minute incremental dragging
		const newHeight = Math.floor(height / increment) * increment;
		this.setState((prevState) => {
			return update(prevState, {
				containerHeight: { $set: newHeight }
			});
		});
	}
}

export default connect(mapStateToProps)(Schedule);
import { createSelector } from "reselect";

import { IUser, Newsletter, UserSettings, WidgetState } from "@connect/Interfaces";
import { AppState, UserState } from "Data/Objects/AppState";

export function getUserState(state: AppState): UserState {
	return state.User;
}

export function getUser(state: UserState): IUser {
	return state.user;
}

export function getUserUUID(user: IUser): string {
	return user.uuid || "";
}

export function getPermissions(user: IUser): string[] {
	return user.permissions || [];
}

export function getWidgets(user: IUser): WidgetState {
	return user.settings && user.settings.widgets || {};
}

export function getNewsletters(user: IUser): Newsletter[] {
	return user.settings && user.settings.newsletters || [];
}

export function getAdAnalyticsNewsletter(newsletters: Newsletter[]) {
	return newsletters.includes(Newsletter.ANALYTICS);
}

export function getConnectUpdatesNewsletter(newsletters: Newsletter[]) {
	return newsletters.includes(Newsletter.GROUP);
}

export function getDeploymentReportsNewsletter(newsletters: Newsletter[]) {
	return newsletters.includes(Newsletter.DEPLOY);
}

export function getHealthReportsNewsletter(newsletters: Newsletter[]) {
	return newsletters.includes(Newsletter.HEALTH);
}

export function getSettings(user: IUser): UserSettings {
	return user.settings;
}

export const getCurrentUser = createSelector(
	[ getUserState ],
	getUser
);

export const getActiveUserUuid = createSelector(
	[ getCurrentUser ],
	getUserUUID
);

export const getUserNewsletters = createSelector(
	[ getCurrentUser ],
	getNewsletters
);

export const getUserPermissions = createSelector(
	[ getCurrentUser ],
	getPermissions
);

export const getAdAnalyticsNewsletterSetting = createSelector(
	[ getUserNewsletters ],
	getAdAnalyticsNewsletter
);

export const getConnectUpdateNewsletterSetting = createSelector(
	[ getUserNewsletters ],
	getConnectUpdatesNewsletter
);

export const getDeploymentReportsNewsletterSetting = createSelector(
	[ getUserNewsletters ],
	getDeploymentReportsNewsletter
);

export const getHealthReportsNewsletterSetting = createSelector(
	[ getUserNewsletters ],
	getHealthReportsNewsletter
);

export const getUserSettings = createSelector(
	[ getCurrentUser ],
	getSettings
);

export const getUserWidgets = createSelector(
	[ getCurrentUser ],
	getWidgets
);

export const makeSubscribesToAnalyticsReports = () => getAdAnalyticsNewsletterSetting;
export const makeSubscribesToConnectUpdates = () => getConnectUpdateNewsletterSetting;
export const makeSubscribesToDeploymentReports = () => getDeploymentReportsNewsletterSetting;
export const makeSubscribesToHealthReports = () => getHealthReportsNewsletterSetting;
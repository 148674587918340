import { getState } from "@connect/Data";
import { getEnvironmentString, isDev } from "@connect/Env";
import { FeatureToggle, IUser, LocalFeatureFlags } from "@connect/Interfaces";
import { getActiveCompanyId } from "Data/Selectors/Company";
import { getFeatureToggle } from "Data/Selectors/System";
import { getCurrentUser } from "Data/Selectors/User";

// Legacy Feature Flags //
export const localFeatureFlags: LocalFeatureFlags = {
	"adAnalyticsEmailReports": isDev(),
	"customUserPermissions": isDev(),
	"faceboxing": isDev(),
	"lastReboot": isDev()
};

export default localFeatureFlags;

// New Feature Flags //
export function fetchToggleConfig(): Promise<FeatureToggle[]> {
	let env = getEnvironmentString().toLowerCase();

	if (isDev()) {
		env = "development";
	}

	return new Promise((resolve, reject) => {
		return fetch(`//cdn.clintonconnect.com/feature-toggles/${env}.json`)
			.then((response: Response) => {
				const contentType = response.headers.get("content-type");

				if (contentType && contentType.indexOf("application/json") !== -1) {
					return resolve(response.json());
				}

				return resolve();
			}, (error) => {
				reject(error);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getLocalFeature(flag: string): boolean {
	if (isLocalFeature(flag)) {
		return localFeatureFlags[flag];
	}

	return false;
}

export function isLocalFeature(flag: string): boolean {
	return localFeatureFlags.hasOwnProperty(flag);
}

export function toggleFeature<T, V>(flag: string, withFlag: T, fallback: V): T | V {
	const state = getState();
	const user = getCurrentUser(state);
	const toggle = getFeatureToggle(getState(), flag);
	const localFeature = isLocalFeature(flag);
	const activeCompany = getActiveCompanyId(state);
	const featureCriteria = localFeature ? getLocalFeature(flag) : toggle && toggleRouter(toggle, user, activeCompany);

	return featureCriteria ? withFlag : fallback;
}

export function toggleRouter(toggle: FeatureToggle, user: IUser, activeCompanyId: string): boolean {
	// if the platform does not have the feature toggle enabled at all we don't have access
	if (!toggle || !user) {
		return false;
	}

	const { enabledForAll, enabledForCompanies, enabledForUsers, permissions } = toggle;
	const { permissions: userPermissions, uuid } = user;

	// if our current company is in the array of companies enabled for this toggle
	const enabledForCompany = enabledForCompanies.length
		&& enabledForCompanies.includes(activeCompanyId);

	// if our current user is in the array of users enabled for this toggle
	const enabledForUser = enabledForUsers.length
		&& enabledForUsers.includes(uuid);

	// if any of our current permissions are in the array of permissions enabled for this toggle
	const enabledForPermission = permissions.length
		&& permissions.find((permission) => !!(userPermissions && userPermissions.includes(permission)));

	// if enabledForAll OR any of the above mentioned scenarios
	if (enabledForAll || enabledForCompany || enabledForUser || enabledForPermission) {
		return true;
	}

	// otherwise we don't have access
	return false;
}
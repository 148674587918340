export interface ActiveUuidRoute {
	activeUuid: string;
}

export type Timezone = {
	id: string;
	displayName: string;
	description: string;
}

export type WithName = {
	name: string;
};

export type WithUuid = {
	uuid: string;
};

export type AsOption = {
	selected: boolean;
};

export type NameUuid = WithName & WithUuid;

export type NameUuidOption = NameUuid & AsOption;

export type KeyLabel = {
	key: string;
	label: string;
};

/**
 * Partial accepts another type/interface/class, and makes every property optional.
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-1.html#mapped-types
 */
export type Partial<T> = {
	[P in keyof T]?: T[P];
};

export type NumberOrString = number | string;

export type StringArrays = { [key: string]: string[] };

export type StringArrayObject = { [key: string]: (string | StringArrays)[]  };

export type StringObject = { [key: string]: string };

export type StringOrStringArrayObject = { [key: string]: string | string[] };

export interface OptionalTableProps {
	rowSelection?: {
		selectedRowKeys: string[],
		onChange: (keys: string[]) => void
	}
}

export type sortDataType = "media" | "ads" | "playlists" | "devices" | "deployments" | "healthReports" | "analytics"
| "actions";

export enum Filters {
	ADS = "ads",
	ANALYTICS = "analytics",
	DEPLOYMENTS = "deployments",
	DEPLOY_DEVICES = "deployDevices",
	DEVICES = "devices",
	HEALTH_DEVICES = "healthDevices",
	HEALTH_REPORTS = "healthReports",
	MEDIA = "media"
};

export enum Sorts {
	ACTIONS = "actions",
	ADS = "ads",
	ANALYTICS = "analytics",
	DEPLOYMENTS = "deployments",
	DEVICES = "devices",
	HEALTH_REPORTS = "healthReports",
	MEDIA = "media",
	PLAYLISTS = "playlists"
}

export type TagDataType = "media" | "ads" | "devices";

export type InputValidationState = {
	value: string,
	validateStatus: "success" | "warning" | "error" | "validating",
	errorMsg: string
}

export type Validator = (value: string) => boolean;

export type InputValidator = {
	callback: Validator;
	message: string;
	name: string;
};

// This is used for our LightbarFrame
export type Tuple12<T> = [ T, T, T, T, T, T, T, T, T, T, T, T ];

import { Action, VersionDispatch, VersionsDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { AdminVersionsState } from "Data/Objects/AppState";
import { createReducer, setState, unshiftToState } from "Data/Utils";

export function createVersion(state: AdminVersionsState, action: Action<VersionDispatch>) {
	const { version } = action.args;
	return unshiftToState(state, "versions", version);
}

export function setVersions(state: AdminVersionsState, action: Action<VersionsDispatch>) {
	const { versions } = action.args;
	return setState(state, "versions", versions);
}

export function setCurrentVersion(state: AdminVersionsState, action: Action<VersionDispatch>) {
	const { version } = action.args;
	return setState(state, "currentVersion", version);
}

const {
	CREATE_VERSION,
	SET_VERSIONS,
	SET_CURRENT_VERSION
} = ACTION_TYPES.Admin.Versions;

const reducers = {
	[CREATE_VERSION.type]: createVersion,
	[SET_VERSIONS.type]: setVersions,
	[SET_CURRENT_VERSION.type]: setCurrentVersion
};

export default createReducer(reducers, AdminVersionsState);
import * as React from "react";

import { ActionSetActions, ActionSetTriggers, ActionType, CustomCSS, TriggerType } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

const { gray, lightGray, selectedBlue, threeGray, white } = Colors;

interface ActionButtonProps {
	type: ActionType | TriggerType;
	selected: boolean;
	trigger?: boolean;
	onDelete?: () => void;
	onClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
}

interface ActionButtonState {
	hovered: boolean;
}

export class ActionButton extends React.PureComponent<ActionButtonProps, ActionButtonState> {
	constructor(props: ActionButtonProps) {
		super(props);

		this.state = {
			hovered: false
		};

		this.baseStyle = {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			justifySelf: "center"
		}

		this.styles = {
			baseButton: {
				alignItems: "center",
				background: lightGray,
				color: white,
				cursor: "pointer",
				display: "flex",
				height: 30,
				justifyContent: "center",
				paddingLeft: 2,
				position: "absolute",
				right: 1,
				textShadow: `0 1px 2px ${ threeGray }`,
				top: 0,
				width: 30,
				zIndex: 110
			},
			cardStyle: {
				...this.baseStyle,
				cursor: "pointer",
				width: 102.66,
				height: 100.39,
				position: "relative",
				background: this.props.selected ? selectedBlue : "transparent"
			},
			content: {
				...this.baseStyle,
				userSelect: "none",
				position: "relative"
			},
			icon: {
				...this.baseStyle,
				width: 60,
				height: 60,
				color: gray
			},
			title: {
				color: gray,
				fontSize: "1.1em",
				marginBottom: 10
			}
		}

		this.deleteAction = this.deleteAction.bind(this);
	}

	baseStyle: CustomCSS;
	styles: CustomCSS;

	render() {
		const { onClick, type, trigger } = this.props;
		let { title, icon, rotate } = type && (ActionSetActions[type] || ActionSetTriggers[type]);
		const { cardStyle, content } = this.styles;

		if (type === "network_request" && trigger) {
			title = ActionSetTriggers[type]?.title;
		}

		return (
			<div
				key="title"
				style={ cardStyle }
				onClick={ onClick }
				onMouseEnter={ this.handleHoverChange(true) }
				onMouseLeave={ this.handleHoverChange(false) }
			>
				{ this.renderDelete() }
				<div
					style={ content }
				>
					{ this.renderIcon(icon, rotate) }
					{ this.renderTitle(title) }
				</div>
			</div>
		);
	}

	renderDelete() {
		const { onDelete } = this.props;
		const { hovered } = this.state;
		const { baseButton } = this.styles;

		if (!hovered || !onDelete) {
			return null
		}

		return [ (
			<div
				key="delete"
				style={ baseButton }
				onClick={ this.deleteAction }>
				<Icon name="trash-alt" iconWeight="regular" />
			</div>
		) ];
	}

	renderIcon(name: string, rotate?: number) {
		return (
			<Icon
				name={ name }
				size="big"
				iconWeight="solid"
				rotate={ rotate }
				style={ this.styles.icon }
			/>
		);
	}

	renderTitle(title: string = "") {
		return (
			<span style={ this.styles.title }>
				{ title }
			</span>
		);
	}

	deleteAction(event: React.SyntheticEvent<HTMLDivElement>) {
		const { onDelete } = this.props;

		if (onDelete) {
			onDelete();
		}

		event.stopPropagation();
	}

	handleHoverChange(value: boolean) {
		return () => {
			this.setState({
				hovered: value
			});
		}
	}
}

export default ActionButton;
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { ActionSet, CustomCSS, Trigger } from "@connect/Interfaces";
import ActionSetCard from "Components/Actions/ActionSetCard";
import TriggerDropzone from "Components/Actions/TriggerDropzone";
import { Icon } from "Components/Global/Common";
import { setSelectedActionComponent } from "Data/Actions/UI/ActionSetBuilder";
import { getActiveActionSet } from "Data/Selectors/Actions";
import HeaderText from "Components/Global/HeaderText";
import { toggleFeature } from "@connect/Features";
import PresenceUsers from "Components/Global/PresenceUsers";
import { getActionSetAsync } from "Data/Actions/ActionsAsync";

interface ActionSetBuilderProps {
	activeAction: ActionSet;
	deselectActions: () => void;
	exitAction: () => void;
	getActionSet: (uuid: string) => void;
}

const mapStateToProps = (state) => {
	return {
		activeAction: getActiveActionSet(state)
	};
};

const mapDispatchToProps = (dispatch) => ({
	exitAction: () => dispatch(push("/actions")),
	getActionSet: (uuid: string) => dispatch(getActionSetAsync(uuid)),
	deselectActions: () => dispatch(setSelectedActionComponent(-1, ""))
});

export class ActionSetBuilder extends React.PureComponent<ActionSetBuilderProps, null> {
	constructor(props: ActionSetBuilderProps) {
		super(props);

		this.styles = {
			container: {
				height: "100vh",
				width: "100%",
				position: "relative",
				display: "flex",
				flexDirection: "column"
			},
			exit: {
				position: "absolute",
				top: 0,
				right: 0,
				margin: 10
			},
			title: {
				flex: "0 0 auto",
				marginBotton: 24
			},
			presenceWrapper: {
				position: "absolute",
				top: 5,
				left: 10
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { container } = this.styles;
		const { deselectActions } = this.props;

		return (
			<div onClick={ deselectActions } style={ container }>
				{ this.renderTitle() }
				{ this.renderExit() }
				{ this.renderPresenceUsers() }
				{ this.renderActionSetCards() }
				{ this.renderTriggerDropzone() }
			</div>
		);
	}

	componentDidMount() {
		const { getActionSet, activeAction } = this.props;

		this.props.deselectActions();
		getActionSet(activeAction.uuid);
	}

	renderPresenceUsers() {
		const { activeAction } = this.props;
		const { presenceWrapper } = this.styles;

		return toggleFeature("notifications",
			(
				<div style={ presenceWrapper }>
					<PresenceUsers
						type="actionSet"
						uuid={ activeAction.uuid } />
				</div>
			),
			null
		);
	}

	renderTriggerDropzone() {
		return <TriggerDropzone />;
	}

	renderActionSetCard(trigger: Trigger, index: number) {
		return <ActionSetCard key={ `${trigger.type}__${index}`} trigger={ trigger } triggerIndex={ index }/>;
	}

	renderActionSetCards() {
		const { data } = this.props.activeAction;
		if (!data || data.length === 0) {
			return null;
		}
		return this.props.activeAction.data.map((trigger, index) => {
			return this.renderActionSetCard(trigger, index);
		});
	}

	renderExit() {
		return (
			<div style={ this.styles.exit }>
				<Icon
					size="smaller"
					name="times"
					onClick={ this.props.exitAction }
				/>
			</div>
		);
	}

	renderTitle() {
		return (
			<HeaderText
				style={ this.styles.title }
				truncateLength={ 36 }
			>
				{ this.props.activeAction.name }
			</HeaderText>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionSetBuilder);
import * as React from "react";

import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { IconWeights } from "./Button";

const { orange, primaryBlue, primaryGreen, purple, red, lightGray, yellow } = Colors;

interface StatusProps {
	color?: string;
	icon?: string;
	on?: boolean;
	text?: string;
	iconWeight?: IconWeights;
}

export default class Status extends React.PureComponent<StatusProps> {
	render() {
		const { icon, text, iconWeight } = this.props;
		const iconName = icon || "circle";

		return (
			<div style={{
				float: "left",
				marginLeft: 3
			}}>
				<Icon
					name={ iconName }
					iconWeight={ iconWeight }
					style={{
						color: this.getColor(),
						marginRight: 4
					}} />
				{ text }
			</div>
		)
	}

	getColor() {
		const { on, color } = this.props;

		if (on) {
			return primaryGreen;
		} else if (color) {
			switch (color) {
				case "green":
					return primaryGreen;
				case "purple":
					return purple;
				case "blue":
					return primaryBlue;
				case "red":
					return red;
				case "gray":
					return lightGray;
				case "orange":
					return orange;
				case "yellow":
					return yellow;
				default:
					return red;
			}
		} else {
			return red;
		}
	}
}
import * as update from "immutability-helper";
import * as React from "react";

import { CustomCSS, IMedia, IMediaUpload } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import Upload from "Components/Global/Upload";

interface MediaThumbnailProps {
	media: IMedia;
	width: number;
	style?: CustomCSS;
}

interface MediaThumbnailState {
	isPortrait: boolean;
	// since images need resizing manually they
	// can flash super huge before getting resized
	// this allows us to hide images while they load
	// then show them once measured
	thumbnailOpacity: number;
}

export default class MediaThumbnail extends React.Component<MediaThumbnailProps, MediaThumbnailState> {
	constructor(props: MediaThumbnailProps) {
		super(props);

		this.state = {
			isPortrait: false,
			thumbnailOpacity: 0
		}
		this.styles = {
			center: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}
		}
	}

	styles: CustomCSS;

	componentWillReceiveProps(props: MediaThumbnailProps) {
		if (props.media.uuid !== this.props.media.uuid) {
			this.setState((prevState) => {
				return update(prevState, {
					thumbnailOpacity: {$set: 0}
				});
			});
		}
	}

	render() {
		const { media } = this.props;
		return (
			<div style={this.props.style}>
				{ media.state !== "ready" ?
					<div style={{
						...this.styles.center,
						width: this.props.width,
						height: this.props.width,
						flexDirection: "column",
						zIndex: 1 // this icon will appear behind the thumbnail image if it's semi-transparent... force it above
					}}>
						<span style={{
							...this.styles.center,
							background: "white",
							height: "100%",
							width: "100%"
						}}>
							{this.renderStatus()}
						</span>
					</div>
					: null }
				<div style={{
					...this.styles.center,
					width: this.props.width,
					height: this.props.width,
					marginTop: media.state !== "ready" ? -this.props.width : 0,
					flexDirection: !this.state.isPortrait ? "row" : "column"
				}}>
					<img
						style={{
							maxWidth: "100%",
							maxHeight: "100%",
							opacity: this.state.thumbnailOpacity
						}}
						src={this.getImageSource()}
						onLoad={(e: any) => {
							let isPortrait = e.target.width <= e.target.height;
							this.setState((prevState) => {
								return update(prevState, {
									isPortrait: {$set: isPortrait},
									thumbnailOpacity: {$set: (media.state === "ready") ? 1 : 0.5}
								});
							});
						}} />
				</div>
			</div>
		);
	}

	renderStatus() {
		const { media } = this.props;
		const { state } = media;

		if (state) {
			if (state ===  "exception") {
				return (
					<Icon
						name="exclamation-circle"
						size="small"
						fixedWidth={false}
						style={{color: "red"}} />
				);
			}
			return (
				<Icon
					name="clock"
					iconWeight="regular"
					size="small"
					fixedWidth={false}
					style={{color: "orange"}} />
			);
		}
		// HACK: Casting Media to a MediaUpload to get the progress...
		// Probably not the best way to do this, should fix when able.
		return <Upload percent={(media as IMediaUpload).progress} />
	}

	getImageSource() {
		const { media } = this.props;
		const type = media.mediaType;

		if (type === "image" || type === "banner") {
			return media.thumbs || media.uri;
		} else {
			if (media.state === "ready") {
				return media.thumbs;
			}

			return "/img/placeholder.png";
		}
	}
}
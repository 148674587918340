import * as React from "react";
import { connect } from "react-redux";
import { match as routerMatch, RouteComponentProps } from "react-router";
import { push } from "react-router-redux";

import { CustomCSS } from "@connect/Interfaces";
import { Loader } from "Components/Global/Common";
import { getSAMLRedirect } from "Data/Actions/UserAsync";
import { User } from "Data/Objects/User";
import { getCurrentUser } from "Data/Selectors/User";

interface SAMLParams extends routerMatch<null> {
	clientName: string;
}

interface SAMLPageProps extends RouteComponentProps<null> {
	getSAMLRedirect: (clientName: string) => void;
	user: User;
}

const mapDispatchToProps = (dispatch) => ({
	getSAMLRedirect: (clientName: string) => dispatch(getSAMLRedirect(clientName))
});

const mapStateToProps = (state) => ({
	user: getCurrentUser(state)
});

export class SAMLPage extends React.PureComponent<SAMLPageProps, null> {
	constructor(props: SAMLPageProps) {
		super(props);

		this.styles = {
			container: {
				height: "100vh",
				width: "100vw",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}
		}
	}

	styles: CustomCSS;

	componentDidMount() {
		const { match, user } = this.props;
		const params = match.params! as SAMLParams;
		const { clientName } = params;

		if (clientName && !user) {
			this.props.getSAMLRedirect(clientName);
		}

		if (clientName && user) {
			push("/");
		}
	}

	render() {
		return (
			<div style={ this.styles.container }>
				<Loader />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SAMLPage);
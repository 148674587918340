import { LOCATION_CHANGE } from "react-router-redux";

import { Utils } from "@connect/Utils";

const { hashCode } = Utils;

function pushWithoutRender(path: string) {
	const key = hashCode(path);

	return (dispatch) => {
		// Fake out react-router-redux actions to trigger state changes
		dispatch({
			type: LOCATION_CHANGE,
			payload: {
				pathname: path,
				search: "",
				hash: "",
				key
			}
		});
		// Replace the state of the url without triggering a page load
		history.pushState(null, "", path);
	}
}

function replaceWithoutRender(path: string) {
	const key = hashCode(path);

	return (dispatch) => {
		// Fake out react-router-redux actions to trigger state changes
		dispatch({
			type: LOCATION_CHANGE,
			payload: {
				pathname: path,
				search: "",
				hash: "",
				key
			}
		});
		// Replace the state of the url without triggering a page load
		history.replaceState(null, "", path);
	}
}

export {
	pushWithoutRender,
	replaceWithoutRender
}
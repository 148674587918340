import { notification } from "antd";
import * as update from "immutability-helper";
import { push } from "react-router-redux";

import { notifyBugSnagAsync, setBugSnagUserAsync } from "@connect/BugSnag";
import { AuthErrorTypes, ErrorResultV2, IUser, LoginResult, Newsletter, StatusResult, UserTokenResult,
	WidgetState } from "@connect/Interfaces";
import { pusherConnection } from "@connect/Pusher";
import { pusherConnection as pusherConnection2 } from "@connect/Pusher2";
import AuthApi from "Api/Auth";
import UserApi from "Api/User";
import { getActiveCompanyDetails, setCompanies } from "Data/Actions/Company";
import { getDeviceTypes } from "Data/Actions/Devices";
import { errorNotification, plainNotification, successNotification } from "Data/Actions/Notifications";
import { getRolesLists } from "Data/Actions/Roles";
import { setRedirect, setPusherCredentials } from "Data/Actions/System";
import { tryFetchVimeoVideosAsync } from "Data/Actions/SystemAsync";
import { setMenuItems } from "Data/Actions/UI";
import { loginUser, logoutUser, setToken, setUser, toggleTwoFactor,
	updateEmailPreferences, updateWidgets, verifyUser } from "Data/Actions/User";
import { LocationState } from "Data/Objects/AppState";
import { getCurrentUser, getUserNewsletters, getUserSettings } from "Data/Selectors/User";
import { toggleFeature } from "@connect/Features";
import { cloneDeep } from "lodash";
import { getState } from "Data/index";
import { setTermsConditionsModalVisibility } from "Data/Actions/UI/Modals";
import { termsVersion } from "Data/TermsAndConditions";
import { ROLES } from "Data/Objects/Roles";

const handleLogin = (dispatch) => (result: LoginResult) => {
	const { user, token, companies, message, system } = result;
	if (!result.error) {
		dispatch(loginUser(user, token.accessToken, companies[0]));
		dispatch(setBugSnagUserAsync(user));
		dispatch(setPusherCredentials(system.messaging.key))

		if (companies && companies.length > 0) {
			dispatch(setCompanies(companies));
			dispatch(getActiveCompanyDetails(companies[0].uuid));
		}

		const termsChanged = result.user?.acceptedTerms && result.user.acceptedTerms !== termsVersion;
		const isAdmin = user.role.name === ROLES.CEC_SUPPORT.value || user.role.name === ROLES.CEC_ADMIN.value

		if ((!result.user?.acceptedTerms || termsChanged) && !isAdmin) {
			dispatch(setTermsConditionsModalVisibility(true));
		}

		dispatch(getRolesLists());
		dispatch(getDeviceTypes());
		dispatch(setMenuItems());
		dispatch(tryFetchVimeoVideosAsync());
		// This initialization needs to be after the dispatch of setMenuItems
		// Otherwise the menu never gets initialized
		// Still unsure why this even happens
		if ( toggleFeature("notifications", true, false) ) {
			pusherConnection2.initializeConnection();
		} else {
			pusherConnection.initializeConnection();
		}
		dispatch(setRedirect(new LocationState()));
	} else {
		dispatch(errorNotification("Login Failed.", message));
	}
}

const handleLoginError = (dispatch) => (error: any) => {
	if (error.error !== AuthErrorTypes.TWO_FACTOR_REQUIRED) {
		dispatch(errorNotification("Login Error.", error));
	}

	return error;
}

export function changePassword(oldPassword: string, newPassword: string, newPasswordConfirmation: string) {
	return (dispatch) => {
		const api = new UserApi();
		const user = getCurrentUser(getState());

		return api.changePassword(oldPassword, newPassword, newPasswordConfirmation)
			.then((result: UserTokenResult) => {
				dispatch(setToken(result.token));
				dispatch(successNotification("Password changed successfully."));
				if (user.passwordReset) {
					dispatch(setUser({ ...user, passwordReset: false }));
					dispatch(push("/"));
				}
			}, (error) => dispatch(errorNotification("Password change failed.", error)))
			.catch((error) => {
				dispatch(errorNotification("Password change failed.", error));
				notifyBugSnagAsync(new Error(error));
			});
	};
}

export function forgotPassword(email: string) {
	return (dispatch) => {
		const api = new AuthApi();

		return api.forgotPassword(email)
			.then((result: null) => {
				dispatch(successNotification("Password reset email sent.", "Please check your email for further instructions."));
			}, (error: any) => {
				dispatch(errorNotification("An Error Occurred.", error));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export function login(email: string, password: string, twoFactorKey?: string, recovery?: boolean) {
	return (dispatch) => {
		const api = new AuthApi();

		return api.login(email, password, twoFactorKey, recovery)
			.then(handleLogin(dispatch), handleLoginError(dispatch))
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export function getSAMLRedirect(clientName: string) {
	return (dispatch) => {
		const api = new AuthApi();

		return api.getSAMLRedirect(clientName)
			.then((res) => {
				const { provider, location } = res;

				if (provider === clientName) {
					window.location.href = location;
				}
			}, (error) => {
				dispatch(errorNotification("An Error Occurred.", error));
			})
			.catch((error) => notifyBugSnagAsync(new Error(error)));
	}
}

export function ssoLogin(query: string) {
	return (dispatch) => {
		const api = new AuthApi();

		return api.ssoLogin(query)
			.then(handleLogin(dispatch), handleLoginError(dispatch))
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export function logout() {
	return (dispatch, getState) => {
		const state = getState();

		const user = getCurrentUser(state);

		if (!user) {
			return Promise.resolve();
		}

		const api = new AuthApi();

		return api.logout()
			.then((result: StatusResult) => {
				notification.destroy();
				dispatch(setRedirect(new LocationState()));
				if ( toggleFeature("notifications", true, false) ) {
					pusherConnection2.disconnect();
				} else {
					pusherConnection.disconnect();
				}
				dispatch(logoutUser());
				dispatch(plainNotification("You have been logged out."));
				if (user.singleSignOn) {
					const [ ssoType, provider ] = user.singleSignOn.split(":");
					dispatch(push(`/login/${ ssoType }/${ provider }`));
				}
			}, (error: any) => {
				dispatch(errorNotification("An Error Occurred.", error));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export function redirectAfterEmailVerify() {
	return (dispatch) => dispatch(push("/account"));
}

export function resendVerification(user: IUser) {
	return (dispatch) => {
		const api = new UserApi();

		return api.sendVerificationEmail(user.uuid)
			.then((result: ErrorResultV2) => {
				dispatch(successNotification("Email sent.", "Please check your email for further instructions."));
			}, (error) => {
				dispatch(errorNotification("Could not send email."));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export function resetPassword(token: string, newValue: string, verifyValue: string) {
	return (dispatch) => {
		const api = new AuthApi();

		return api.resetPassword(token, newValue, verifyValue)
			.then((result: boolean) => {
				if (result) {
					dispatch(successNotification("Your password has been reset!", "Please log in with your new password."));
				} else {
					dispatch(errorNotification("Could not reset password. Please try again."));
				}
				dispatch(push("/login"));
			}, (error) => {
				dispatch(errorNotification("Could not reset password.", error));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export function updateWidgetsAsync(newWidgets: WidgetState[]) {
	return (dispatch, getState) => {
		const { User: { user } } = getState();
		const api = new UserApi();
		const getKey = (w) => Object.keys(w)[0];
		let settings = cloneDeep(user.settings);
		let { widgets } = settings;

		if (!widgets) {
			widgets = {};
		}

		// Check to make sure that settings is not an array object
		// Because PHP is weird
		if (!settings || Array.isArray(settings)) {
			settings = {};
		}

		// get the key and remove it from the current object if it exists
		newWidgets.forEach((newWidget) => {
			const newKey = getKey(newWidget);

			if (widgets && widgets[newKey]) {
				delete widgets[newKey];
			}
		});

		// format our widgets so we can merge them cleanly
		const formattedWidgets = newWidgets.reduce((ws, widget) => {
			const newKey = getKey(widget);

			ws[newKey] = widget[newKey];

			return ws;
		}, {});
		const oldWidgets = widgets;
		const updatedWidgets = Object.assign({}, oldWidgets, formattedWidgets);

		let updatedSettings = update(settings, {
			widgets: {
				$set: updatedWidgets
			}
		});

		return api.updateUser({ settings: updatedSettings })
			.then((result) => {
				dispatch(updateWidgets(updatedWidgets));
			}, (error) => {
				dispatch(errorNotification("Could not update dashboard.", error));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	}
}

export function removeWidgetAsync(widgetToRemove: any) {
	return (dispatch, getState) => {
		const { User: { user } } = getState();
		const api = new UserApi();
		let settings = cloneDeep(user.settings);

		// Check to make sure that settings is not an array object
		// Because PHP is weird
		if (!settings || Array.isArray(settings)) {
			settings = {};
		}

		let updatedWidgets = settings.widgets;
		delete updatedWidgets[widgetToRemove];

		if (Object.keys(updatedWidgets).length === 0) {
			updatedWidgets = null;
		}

		let updatedSettings = update(settings, {
			widgets: {
				$set: updatedWidgets
			}
		});

		return api.updateUser({ settings: updatedSettings })
			.then((result) => {
				dispatch(updateWidgets(updatedWidgets));
			}, (error) => {
				dispatch(errorNotification("Could not update dashboard.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	}
}

export function updateEmailPreferencesAsync(newsletter: Newsletter) {
	return (dispatch, getState) => {
		const state = getState();
		const settings = getUserSettings(state);
		const newsletters = [ ...getUserNewsletters(state) ];
		const existingIndex = newsletters.indexOf(newsletter);
		const api = new UserApi();

		if (existingIndex > -1) {
			newsletters.splice(existingIndex, 1);
		} else {
			newsletters.push(newsletter);
		}

		const newSettings = Object.assign({}, settings, { newsletters });

		return api.updateUser({ settings: newSettings })
			.then((result) => {
				dispatch(updateEmailPreferences(newsletters));
			}, (error) => {
				dispatch(errorNotification("Could not update dashboard.", error));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	}
}

export function toggleTwoFactorAuth() {
	return (dispatch, getState) => {
		const { User: { user } } = getState();
		const { twoFactorEnabled } = user;
		dispatch(toggleTwoFactor(!twoFactorEnabled));
	};
}

export function updateUserInfo(email: string, name: string, phone: string) {
	return (dispatch, getState) => {
		const { User: { user } } = getState();
		const api = new UserApi();

		return api.updateUser({ email, name, phone })
			.then((result) => {
				const updatedUser = Object.assign({}, user, {
					email,
					name,
					phone
				});

				dispatch(setUser(updatedUser));
			}, (error) => {
				dispatch(errorNotification("Could not update account.", error));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export function updateUserAcceptedTerms() {
	return (dispatch, getState) => {
		const { User: { user } } = getState();
		const api = new UserApi();

		return api.updateUser({ acceptedTerms: termsVersion })
			.then(() => {
				const updatedUser = Object.assign({}, user, {
					acceptedTerms: termsVersion
				});

				dispatch(setUser(updatedUser));
				dispatch(setTermsConditionsModalVisibility(false));
			}, (error) => {
				dispatch(errorNotification("Could not update account.", error));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export function getUserDetails() {
	return (dispatch) => {
		const api = new UserApi();

		return api.getUserDetails()
			.then((result) => {
				dispatch(setUser(result));
				return result;
			});
	}
}

export function verifyEmail(token: string) {
	return (dispatch) => {
		const api = new UserApi();

		return api.verifyUserEmail(token)
			.then((result) => {
				dispatch(verifyUser());
				dispatch(successNotification("Your email address has been verified!"));
				dispatch(redirectAfterEmailVerify());
			}, (error) => {
				const message = typeof error === "string" ? error : (error.message || "");
				const errorTitle = message.includes("expired")
					? "Log in to verify your email address."
					: "Could not verify email address.";

				dispatch(errorNotification(errorTitle, message));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export function verifyPassword(password: string) {
	return (dispatch) => {
		const api = new UserApi();

		return api.verifyPassword(password)
			.then((result) => {
			}, (error) => dispatch(errorNotification("Incorrect Password", error.message)))
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

import { DeviceLEDSetting, DeviceStatus, IDevice, IDeviceGroup, NameUuid,
	PlaylistWithoutAds, DeviceCapability } from "@connect/Interfaces";
import { getState } from "@connect/Data";
import { getDeviceModels } from "Data/Selectors/Devices";

export const DeviceSvgModifiers = {
	10: {
		baseXMod: 0.5,
		baseYMod: 1,
		viewboxFactor: 2
	},
	13: {
		baseXMod: 0.25,
		baseYMod: 0.5,
		viewboxFactor: 1.5
	},
	21: {
		baseXMod: 0.1875,
		baseYMod: 0.375,
		viewboxFactor: 1.375
	},
	32: {
		baseXMod: 0.125,
		baseYMod: 0.25,
		viewboxFactor: 1.25
	},
	43: {
		baseXMod: 0,
		baseYMod: 0,
		viewboxFactor: 1
	}
};

export function checkDeviceCapabilities(model: string, capability: DeviceCapability) {
	const state = getState();
	const deviceModels = getDeviceModels(state);
	// Check to make sure the device model actually exists
	const [ currentDeviceModel ] = deviceModels.filter(deviceModel => deviceModel.title === model);

	if (!currentDeviceModel) {
		return false;
	}
	// If the model exists check to make sure the capability exists
	return currentDeviceModel.capabilities.indexOf(capability) >= 0;
}

export class Device implements IDevice {
	constructor(device: Partial<Device>) {
		this.company = device.company || "";
		this.model = device.model || "";
		this.name = device.name || "";
		this.serial = device.serial || "";
		this.snapshot = device.snapshot || "";
		this.store = device.store || "";
		this.type = device.type || "";
		this.uuid = device.uuid || "";
		this.cec_serial = device.name || "";
		this.activeDeployment = device.activeDeployment || null;
		this.audioLevel = device.audioLevel || 0;
		this.createdAt = device.createdAt || "";
		this.installDate = device.installDate || "";
		this.integrator = device.integrator || "";
		this.scheduleId = device.scheduleId || "";
		this.sleepModeEnabled = device.sleepModeEnabled || false;
		this.snapshotTimestamp = device.snapshotTimestamp || "";
		this.softwareVersion = device.softwareVersion || "";
		this.updatedAt = device.updatedAt || "";
		this.updatedBy = device.updatedBy || "";
		this.firmwareVersion = device.firmwareVersion || "";
	}

	company: string;
	deviceGroup: NameUuid;
	ethernetMac: string;
	group: NameUuid;
	model: string;
	name: string;
	playlist: PlaylistWithoutAds;
	sleepModeEnabled: boolean;
	serial: string;
	snapshot: string;
	snapshotThumbnail: string;
	status?: DeviceStatus;
	store: string;
	type: string;
	uuid: string;
	wifiMac: string;

	/* eslint-disable camelcase */
	audio_level: number;
	cec_serial: string;
	created_at: string;
	install_date: string;
	led_settings: DeviceLEDSetting[];
	snapshot_timestamp: string
	software_version: string;
	updated_at: string;
	/* eslint-enable camelcase */

	// API V2 compatibility
	activeDeployment: {
		name: string;
		uuid: string;
	} | null;

	audioLevel: number;
	cecSerial: string;
	createdAt: string;
	installDate: string;
	integrator: string;
	ledSettings: DeviceLEDSetting[];
	scheduleId: string;
	snapshotTimestamp: string;
	softwareVersion: string;
	timezone: string;
	updatedAt: string;
	updatedBy: string;
	firmwareVersion: string;
}

export class DeviceGroup implements IDeviceGroup {
	constructor(initialGroup: Partial<DeviceGroup>) {
		Object.keys(initialGroup).forEach((key) => {
			if (initialGroup.hasOwnProperty(key)) {
				this[key] = initialGroup[key];
			}
		});
	}

	uuid: string;
	name: string;
	children: string[];
	parent: string;
	devicesCount: number;
	devicesTotal: number;

	/* eslint-disable camelcase */
	created_at: string;
	updated_at: string;
	devices_count: number;
	devices_total: number;

	static isDeviceGroup(group: DeviceGroup) {
		return group.hasOwnProperty("parent") && group.hasOwnProperty("children");
	}
	/* eslint-enable camelcase */
}

export const AllGroup = new DeviceGroup({
	name: "All",
	parent: "",
	uuid: "0"
});

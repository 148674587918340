import { AccordionDispatch, Action } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { createReducer, setState } from "Data/Utils";
import { cloneDeep } from "lodash";

export function setCollapseExpanded(state: { [key: string]: string[] }, action: Action<AccordionDispatch>) {
	const { key, value } = action.args;
	const newState = cloneDeep(state);

	if (!state[key]) {
		newState[key] = [];
	}

	return setState(newState, key, value);
}

const reducers = {
	[ACTION_TYPES.UI.SET_COLLAPSE_EXPANDED.type]: setCollapseExpanded
};

export default createReducer(reducers, Object);

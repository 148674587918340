import * as React from "react";
import { connect } from "react-redux";
import { Alert } from "antd";
import { getDeviceHealthModalDeviceState } from "Data/Selectors/UI";
import { getDeviceHealthModalUUID } from "Data/Selectors/UI";
import { AppState } from "Data/Objects/AppState";
import Perishable from "Data/Objects/Perishable";

const mapStateToProps = (state: AppState) => {
	const selectedUUID = getDeviceHealthModalUUID(state);
	const { lastSeen, pingStatus } = getDeviceHealthModalDeviceState(state, selectedUUID);

	return {
		lastSeen,
		pingStatus
	}
}

interface NetworkWarningProps {
	lastSeen: string;
	pingStatus: Perishable<boolean>
}

export class NetworkWarning extends React.PureComponent<NetworkWarningProps> {
	constructor(props: NetworkWarningProps) {
		super(props);
	}

	renderTimeout: any;

	// TODO: These timeouts need to be addressed when we have a better solution
	// Until then they are needed to force updates when the Perishable items update
	componentDidMount() {
		this.renderTimeout = setInterval(() => {
			this.forceUpdate();

			if (!this.props.pingStatus.value) {
				clearInterval(this.renderTimeout);
			}
		}, 5000);
	}

	componentWillUnmount() {
		clearInterval(this.renderTimeout);
	}

	render() {
		const { lastSeen } = this.props;

		if (lastSeen) {
			return null;
		}

		return (
			this.renderWarning()
		);
	}

	renderWarning() {
		const { pingStatus } = this.props;
		let message = "Awaiting device notification status";

		if (!pingStatus.value) {
			message = "Device is not responding to notifications. Still trying";
		}

		const warningContent = (
			<div className="infinite-ellipsis">
				{ message }
			</div>
		);

		return (
			<Alert
				message=""
				description={ warningContent }
				type="warning"
			/>
		);
	}
}

export default connect(mapStateToProps)(NetworkWarning);
import { IStore } from "@connect/Interfaces";

export class Store implements IStore {
	constructor(name: string) {
		this.name = name;
	}

	city: string;
	closeTime: string;
	devicesCount: number;
	openTime: string;
	name: string;
	sleepModeEnabled: boolean;
	state: string;
	uuid: string;
	address1: string;
	address2: string;
	postalCode: string;
}
import * as React from "react";

import { CustomCSS, IAd } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { GridViewCard, IconData, IGridViewCardProps } from "Components/Global/GridViewCard";
import { cloneDeep } from "lodash";

interface AnalyticsCardProps extends IGridViewCardProps<IAd> {}

class AnalyticsCard extends GridViewCard<AnalyticsCardProps> {
	constructor(props: AnalyticsCardProps) {
		super(props);

		this.styles = {
			actions: {
				display: "flex",
				flex: "1 0 auto",
				justifyContent: "flex-end"
			},
			center: {
				alignItems: "center",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				textAlign: "center"
			},
			contentRow: {
				alignItems: "center",
				backgroundColor: Colors.offWhite,
				borderBottom: `1px solid ${Colors.white}`,
				borderRadius: "6px 6px 0px 0px",
				display: "flex",
				flexDirection: "column",
				fontSize: 14,
				height: 266,
				justifyContent: "space-around",
				width: "100%"
			},
			infoRow: {
				alignItems: "center",
				borderRadius: "6px 6px 0px 0px",
				display: "flex",
				fontSize: 14,
				height: 30,
				justifyContent: "space-around",
				marginTop: 2,
				padding: 10,
				width: "100%"
			},
			name: {
				display: "flex",
				flex: "1 0 70%",
				marginRight: 10,
				overflowX: "hidden",
				whiteSpace: "nowrap"
			},
			outer: {
				border: `1px solid ${Colors.white}`,
				borderRadius: 6,
				boxShadow: `0px 2px 10px ${Colors.lightGray}`,
				cursor: "pointer",
				justifySelf: "center",
				margin: 10,
				minHeight: 300,
				position: "relative",
				width: 286
			},
			thumbnail: {
				display: "flex",
				height: 180,
				margin: "8px 0 -4px"
			},
			totalPlays: {
				fontSize: "2em",
				lineHeight: "1.4em",
				width: "100%"
			},
			totalPlaysLabel: {
				fontSize: "0.8em",
				width: "100%"
			}
		};

		this.getAnalyticsName = this.getAnalyticsName.bind(this);
		this.renderIcon = this.renderIcon.bind(this);
		this.renderInfoRow = this.renderInfoRow.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { cardObject: ad, bulkSelectActive, isSelected } = this.props;
		const { contentRow, outer, thumbnail } = this.styles;

		let container = cloneDeep(outer);
		let row = cloneDeep(contentRow);

		if (bulkSelectActive && isSelected) {
			container.backgroundColor = "#D7E6FC";
			container.border = "1px solid #D7E6FC";
			row.borderBottom = "1px solid #D7E6FC";
			row.backgroundColor = "#D7E6FC";
		}

		return this.renderContainer(
			<div style={ container }>
				<div style={ row }>
					<img
						key={ ad.uuid }
						src={ ad.thumbnail }
						style={ thumbnail } />
					{ this.renderPlays() }
				</div>
				{ this.renderInfoRow() }
			</div>
		);
	}

	renderInfoRow() {
		const { infoRow, name } = this.styles;

		return (
			<div style={ infoRow }>
				<div style={ name }>
					{ this.getAnalyticsName() }
				</div>
				<div style={this.getActionsStyle()}>
					{(this.props.actionButtons || []).map(this.renderIcon)}
				</div>
			</div>
		);
	}

	renderIcon(iconData: IconData) {
		return (
			<Icon
				name={ iconData.name }
				key={ iconData.name }
				style={ this.styles.actions }
				iconWeight="regular"
				onClick={ this.clickIcon(iconData.callback) } />
		)
	}

	renderPlays() {
		const { center, totalPlays, totalPlaysLabel } = this.styles;
		const { totalPlays: adPlays } = this.props.cardObject;

		const plays = totalPlays ? Utils.abbreviateNumber(adPlays, 1) : 0;

		return (
			<div style={ center }>
				<div style={ totalPlays }>{ plays }</div>
				<div style={ totalPlaysLabel }>Total Ad Plays</div>
			</div>
		);
	}

	getActionsStyle() {
		return {
			...this.styles.actions,
			visibility: this.props.bulkSelectActive ? "hidden" : "visible"
		}
	}

	getAnalyticsName() {
		const { cardObject: ad } = this.props;

		if (!ad) {
			return null;
		}

		return (
			<b><Truncate length={ 24 }>{ ad.name }</Truncate></b>
		);
	}
}

export default AnalyticsCard;

import Radium from "radium";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { IconWeights } from "Components/Global/Icon";
import { cloneDeep } from "lodash";

const { filters, white } = Colors;

interface FilterDescription {
	name: string;
	icon: string;
	id: string;
	iconWeight?: IconWeights;
}

interface FilterProps {
	activeFilter: string;
	filters: FilterDescription[];
	onFilterChange: (name: string) => void;
}

interface FilterState {
	activeFilter: string;
}

class SidebarFilter extends React.PureComponent<FilterProps, FilterState> {
	constructor(props: FilterProps) {
		super(props);

		const selectedFilterItem = {
			background: filters.active,
			borderBottom: `1px solid ${filters.inactive}`,
			borderRadius: 2
		};

		this.styles = {
			filterIcon: {
				marginRight: 5
			},
			filterItem: {
				alignItems: "center",
				borderBottom: `1px solid ${filters.active}`,
				color: white,
				cursor: "pointer",
				display: "flex",
				flexDirection: "row",
				padding: "8px 4px",
				":hover": {
					...selectedFilterItem,
					background: filters.hover
				}

			},
			filterList: {
				listStyleType: "none",
				margin: 0,
				padding: 0
			},
			selectedFilterItem
		};

		this.renderFilterItem = this.renderFilterItem.bind(this);
		this.setActiveFilter = this.setActiveFilter.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<ul style={this.styles.filterList}>
				{this.props.filters.map(this.renderFilterItem)}
			</ul>
		);
	}

	renderFilterItem({name, icon, id, iconWeight}: FilterDescription, index: number) {
		const { activeFilter } = this.props;
		const { filterIcon, filterItem, selectedFilterItem } = this.styles;
		let style = cloneDeep(filterItem);

		if (activeFilter && id === activeFilter) {
			style = Object.assign(style, { ...selectedFilterItem });
		}

		return (
			<li key={`${id}_${index.toString}`}
				onClick={this.setActiveFilter(id)}
				style={style}
				role="button"
			>
				<Icon name={icon} fixedWidth={true} iconWeight={iconWeight} size="smaller" style={filterIcon} /> {name}
			</li>
		);
	}

	setActiveFilter(name: string) {
		return () => this.props.onFilterChange(name);
	}
}

export default Radium(SidebarFilter);

import { IApi } from "@connect/Interfaces";
import LaravelApi from "Api/LaravelApi";

let _api: LaravelApi;

const getApi: () => IApi = () => {
	if (_api) {
		return _api;
	}

	_api = new LaravelApi();

	return _api;
};

const Api: IApi = getApi();

export { getApi, Api };
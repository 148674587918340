import { Collapse } from "antd";
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, HealthReport, HealthReportColumn, IReportComponent } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ReportComponent from "Components/Health/ReportComponent";
import { ReportComponentsList, HealthReportColumns } from "Data/Objects/HealthReport";
import { getSelectedHealthReport } from "Data/Selectors/HealthReports";
import { getActiveUuid } from "Data/Selectors/UI";
import { toggleFeature } from "@connect/Features";

const { Panel } = Collapse;
const { darkestGray, white } = Colors

const mapStateToProps = (state) => {
	const activeUuid = getActiveUuid(state, "health");
	return {
		activeHealthReport: getSelectedHealthReport(state, activeUuid)
	};
};

interface ReportComponentSection {
	components: IReportComponent[];
	header: {
		icon: string;
		title: string;
	};
}

interface ReportComponentsProps {
	activeHealthReport: HealthReport;
}

export class ReportComponents extends React.Component<ReportComponentsProps, any> {
	constructor(props: ReportComponentsProps) {
		super(props);

		this.reportComponents = update(ReportComponentsList, {
			device: { $merge: {
				header: {
					icon: "info-circle",
					title: "Device Info"
				}
			} },
			status: { $merge: {
				header: {
					icon: "heartbeat",
					title: "Device Status"
				}
			} }
		});

		this.styles = {
			collapse: {
				display: "flex",
				flexDirection: "column",
				height: "100%"
			},
			header: {
				backgroundColor: darkestGray
			},
			item: {
				color: white,
				height: 35,
				lineHeight: "35px",
				padding: "0px 36px 0px 8px",
				width: "100%"
			},
			leftPadding: {
				paddingLeft: 8
			},
			noBorder: {
				border: 0,
				borderRadius: 0,
				overflow: "hidden"
			}
		};

		this.renderReportPanelItem = this.renderReportPanelItem.bind(this);
		this.setIfCurrentlyUsed = this.setIfCurrentlyUsed.bind(this);
		this.updateReportComponents = this.updateReportComponents.bind(this);
	}

	reportComponents: {
		device: ReportComponentSection;
		status: ReportComponentSection;
	};

	styles: CustomCSS;

	componentWillMount() {
		this.updateReportComponents();
	}

	componentWillReceiveProps(nextProps: ReportComponentsProps) {
		this.updateReportComponents(nextProps);
	}

	render() {
		const { collapse, noBorder } = this.styles;

		return (
			<Collapse style={{ ...collapse, ...noBorder }}>
				{this.renderReportPanel("device")}
				{this.renderReportPanel("status")}
			</Collapse>
		);
	}

	renderReportPanel(key: string) {
		const { components, header: { icon, title } } = this.reportComponents[key];
		const items = Utils.sort(components, "title", true).map(this.renderReportPanelItem);

		return (
			<Panel
				header={this.renderReportPanelHeader(icon, title)}
				key={key}
				style={this.styles.noBorder}>
				<div style={{ background: darkestGray }}>
					{ items }
				</div>
			</Panel>
		);
	}

	renderReportPanelHeader(icon: string, title: string) {
		const { header, item, leftPadding, noBorder } = this.styles;

		return (
			<div style={{ ...header, ...item, ...noBorder }}>
				<Icon fixedWidth={true} name={icon} />
				<span style={leftPadding}>{title}</span>
			</div>
		);
	}

	renderReportPanelItem(item: IReportComponent, index: number, items: IReportComponent[]) {
		const last = index + 1 === items.length;
		const featureToggled = toggleFeature("lastReboot", false, item.key === "status.uptime");

		if (HealthReportColumns[item.key].visibleComponent === false || featureToggled) {
			return null;
		}

		return (
			<ReportComponent key={index} item={item} last={last} />
		);
	}

	setIfCurrentlyUsed(props: ReportComponentsProps) {
		return (item: IReportComponent) => {
			const report = props.activeHealthReport;
			const used = report && report.columns && report.columns.includes(item.key as HealthReportColumn);

			return update(item, {
				currentlyUsed: { $set: used }
			});
		};
	}

	updateReportComponents(nextProps?: ReportComponentsProps) {
		const { device, status } = this.reportComponents;
		const props = nextProps || this.props;

		this.reportComponents.device.components = device.components.map(this.setIfCurrentlyUsed(props));
		this.reportComponents.status.components = status.components.map(this.setIfCurrentlyUsed(props));
	}
}

export default connect(mapStateToProps)(ReportComponents);

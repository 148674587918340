import { connect } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { TriggerPropertiesPanelContent,
	TriggerPropertiesPanelContentProps } from "Components/Actions/TriggerPropertiesPanelContent";
import { getSelectedTriggerType } from "Data/Selectors/ActionBuilder";

const mapStateToProps = (state) => {
	return {
		triggerType: getSelectedTriggerType(state)
	};
};

interface ButtonTriggerPropertiesPanelContentProps extends TriggerPropertiesPanelContentProps {}

export class ButtonTriggerPropertiesPanelContent
	extends TriggerPropertiesPanelContent<ButtonTriggerPropertiesPanelContentProps> {
	constructor(props: ButtonTriggerPropertiesPanelContentProps) {
		super(props);
	}

	styles: CustomCSS;

	render() {
		return this.renderContainer();
	}
}

export default connect(mapStateToProps)(ButtonTriggerPropertiesPanelContent);

import { Moment } from "moment";
import * as moment from "moment";
import Calendar, { Props } from "rc-calendar";
import "rc-calendar/assets/index.css";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Colors } from "Components/Global/Constants";

interface IDeploymentsCalendarProps {
	selectedDates: Moment[];
}

interface CustomCalendarProps extends Props {
	dateRender: (current?: Moment, value?: Moment) => React.ReactNode;
}

const CustomCalendar: React.SFC<CustomCalendarProps> = (props) => (
	<Calendar {...props} />
);

interface IDeploymentsCalendarState {
	month: number;
	year: number;
}

export default class DeploymentsCalendar extends React.Component<IDeploymentsCalendarProps, IDeploymentsCalendarState> {
	constructor(props: IDeploymentsCalendarProps) {
		super(props);

		const now = Utils.getDate(moment());

		this.state = {
			month: now.month,
			year: now.year
		};

		this.styles = {
			calendar: {
				border: 0,
				margin: 8,
				boxShadow: "unset",
				WebkitBoxShadow: "unset"
			},
			dayContainer: {
				width: "100%",
				margin: "0px 2px",
				padding: 4,
				textAlign: "center"
			}
		}

		this.renderDate = this.renderDate.bind(this);
		this.onPanelChange = this.onPanelChange.bind(this);
	}

	styles: CustomCSS;

	onPanelChange(date: Moment) {
		const month = date.month() + 1;
		const year = date.year();
		this.setState(() => ({
			month,
			year
		}));
	}

	render() {
		const { calendar } = this.styles;
		return (
			<CustomCalendar
				style={ calendar }
				defaultValue={ moment() }
				showToday={ false }
				showDateInput={ false }
				showOk={ false }
				onChange={ this.onPanelChange }
				dateRender={this.renderDate}
			/>
		);
	}

	renderDate(date: Moment) {
		const currentDate = date.date();
		const dayStyle = {
			padding: 2,
			color: this.dateInCurrentMonth(date) ? Colors.black : Colors.lightGray,
			border: this.dateSelected(date) ? `1px solid ${Colors.primaryBlue}` : "none"
		}

		return (
			<div style={ this.styles.dayContainer }>
				<div style={ dayStyle }>
					{ currentDate }
				</div>
			</div>
		);
	}

	dateSelected(date: Moment) {
		const selectedDates = this.props.selectedDates.map((d) => moment(d));
		return selectedDates.some((d) => date.isSame(d, "day"));
	}

	dateInCurrentMonth(date: Moment) {
		const { year, month } = this.state;
		const compareDate = Utils.getDate(date);
		const dateMonth = compareDate.month;
		const dateYear = compareDate.year;
		return month === dateMonth && year === dateYear;
	}
}
import { notification } from "antd";
import * as moment from "moment";
import { push } from "react-router-redux";

import { notifyBugSnag, setBugSnagCompanyAsync } from "@connect/BugSnag";
import { Action, CompaniesDispatch, CompaniesResult, CompanyDispatch, CompanyResult, CreateCompanyData,
	ExtendedCompaniesResult, ICompany, IExtendedCompany, IStore, KeyLabel, ManagedCompaniesDispatch,
	ManagedCompaniesTeamsDispatch, ManagedCompanyDispatch, StoreDispatch,
	StoresDispatch, StoresResult, BulkStoreResult } from "@connect/Interfaces";
import { pusherConnection } from "@connect/Pusher";
import { pusherConnection as pusherConnection2 } from "@connect/Pusher2";
import { Api } from "Api/Api";
import CompanyApi from "Api/Company";
import IntegratorsApi from "Api/Integrators";
import StoresApi from "Api/Stores";
import { resetActionSets } from "Data/Actions/Actions";
import { resetAdAnalytics, resetAds } from "Data/Actions/Ads";
import { resetDeployments } from "Data/Actions/Deployments";
import { getDeviceTypes, resetDevices } from "Data/Actions/Devices";
import { resetMedia } from "Data/Actions/Media";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { setFeatureTogglesAsync } from "Data/Actions/SystemAsync";
import {
	changeCompanyUIReset,
	setAsyncFetching,
	setAsyncState,
	setMenuItems
} from "Data/Actions/UI";
import { updateUserPermissions } from "Data/Actions/User";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";
import { CacheInvalidationPeriod } from "Data/Objects/Global";
import { getActiveCompanyId, getCompany } from "Data/Selectors/Company";
import { getLocationPaths } from "Data/Selectors/System";
import { getActiveNavigationItem, getManagedCompaniesAsyncState, getStoresAsyncState } from "Data/Selectors/UI";
import { toggleFeature } from "@connect/Features";
import { cloneDeep } from "lodash";
import { getRolesLists } from "Data/Actions/Roles";

const {
	CREATE_COMPANY,
	CREATE_MANAGED_COMPANY,
	CREATE_STORE,
	DELETE_COMPANY,
	DELETE_STORE,
	RESET_TEAMS,
	SET_ACTIVE_COMPANY,
	SET_COMPANIES,
	SET_MANAGED_COMPANIES,
	SET_MANAGED_COMPANIES_TEAMS,
	SET_MANAGED_COMPANY,
	SET_STORES,
	UPDATE_COMPANIES,
	UPDATE_COMPANY,
	UPDATE_STORE
} = ACTION_TYPES.Company;

/**
 * Simple Actions
 */

function createCompany(company: ICompany): Action<CompanyDispatch> {
	return new DispatchableAction(CREATE_COMPANY, { company });
}

function createManagedCompany(company: ICompany): Action<CompanyDispatch> {
	return new DispatchableAction(CREATE_MANAGED_COMPANY, { company });
}

function createStore(store: IStore): Action<StoreDispatch> {
	return new DispatchableAction(CREATE_STORE, { store });
}

function deleteCompany(company: ICompany): Action<CompanyDispatch> {
	return new DispatchableAction(DELETE_COMPANY, { company });
}

function deleteStore(store: IStore): Action<StoreDispatch> {
	return new DispatchableAction(DELETE_STORE, { store });
}

function resetIntegratorTeams(): Action<null> {
	return new DispatchableAction(RESET_TEAMS, null);
}

function setActiveCompany(companyId: string): Action<string> {
	return new DispatchableAction(SET_ACTIVE_COMPANY, companyId);
}

function setCompanies(companies: ICompany[]): Action<CompaniesDispatch> {
	return new DispatchableAction(SET_COMPANIES, { companies });
}

function setManagedCompanies(companies: IExtendedCompany[], update?: boolean): Action<ManagedCompaniesDispatch> {
	return new DispatchableAction(SET_MANAGED_COMPANIES, { companies, update });
}

function setManagedCompany(company: IExtendedCompany): Action<ManagedCompanyDispatch> {
	return new DispatchableAction(SET_MANAGED_COMPANY, { company });
}

function setManagedCompaniesTeams(company: string, teams: KeyLabel[]): Action<ManagedCompaniesTeamsDispatch> {
	return new DispatchableAction(SET_MANAGED_COMPANIES_TEAMS, { company, teams });
}

function setStores(stores: IStore[], reset?: boolean): Action<StoresDispatch> {
	return new DispatchableAction(SET_STORES, { stores, reset });
}

function updateCompany(company: ICompany): Action<CompanyDispatch> {
	return new DispatchableAction(UPDATE_COMPANY, { company });
}

function updateCompanies(companies: ICompany[], reset?: boolean): Action<CompaniesDispatch> {
	return new DispatchableAction(UPDATE_COMPANIES, { companies, reset });
}

function updateStore(store: IStore): Action<StoreDispatch> {
	return new DispatchableAction(UPDATE_STORE, { store });
}

/**
 * Async Actions
 */

function assignIntegrationTeamsAsync(company: ICompany, integrations: string[]) {
	return (dispatch) => Api.AdminApi.assignIntegrationTeams(company, integrations)
		.then((result) => {
			dispatch(updateCompany(company));
		}, (error) => {
			dispatch(errorNotification("Error updating integration teams.", error));
		})
		.catch(error => notifyBugSnag(new Error(error)));
}

function assignIntegratorsAsync(company: ICompany, integrators: string[]) {
	return (dispatch) => Api.AdminApi.assignIntegrators(company, integrators)
		.then(() => {
			// good to go
		}, (error) => {
			dispatch(errorNotification("Error updating integrators.", error));
		})
		.catch(error => notifyBugSnag(new Error(error)));
}

function createCompanyAsync({ integrator, name, cloudServiceProvider, customPermissions }: CreateCompanyData) {
	const api = new CompanyApi();
	return (dispatch) => api.createCompany(name, cloudServiceProvider, integrator, customPermissions)
		.then((result: ICompany) => {
			dispatch(createCompany(Object.assign({}, result, { active: true })));
			dispatch(createManagedCompany(Object.assign({}, result, { active: true })));
			dispatch(successNotification("Company created successfully."));
		}, error => dispatch(errorNotification("Error creating user.", error)))
		.catch(error => notifyBugSnag(new Error(error)));
}

function createStoreAsync(store: IStore) {
	const api = new StoresApi();
	return (dispatch) => api.createStore(store)
		.then((result: IStore) => {
			dispatch(createStore(result));
			dispatch(successNotification(`Store ${store.name} created successfully.`));
		}, error => dispatch(errorNotification("Could not create store.", error)))
		.catch(error => notifyBugSnag(new Error(error)));
}

function createBulkStoresAsync(storesCSV: string) {
	const api = new StoresApi();
	return (dispatch) => api.createBulkStores(storesCSV)
		.then((result: BulkStoreResult[]) => {
			const erroredStores = result.filter((res) => !res.success);
			const successfulStores = result.filter((res) => res.success);

			if (erroredStores.length > 0) {
				dispatch(errorNotification("Error Creating Stores", `Error creating ${ erroredStores.length } stores`))
			}

			if (successfulStores.length > 0) {
				dispatch(successNotification("Bulk Stores Created", `Successfully created ${ successfulStores.length } stores`));
			}
		},
		(error) => dispatch(errorNotification("Bulk Add Devices Failed", error)))
		.catch((error) => notifyBugSnag(new Error(error)));
}

function deactivateCompanyAsync(company: ICompany) {
	const api = new CompanyApi();
	const activeStatus = { active: !company.active };
	const companyUpdateStatus = company.active ? "deactivated" : "activated";
	const deactivatedCompany = Object.assign({}, company, activeStatus);

	return (dispatch, getState) => {
		const { User: { user } } = getState();
		const userCompany = user && user.company && user.company.uuid;

		return api.updateCompany(company, activeStatus)
			.then((result: any) => {
				if (company.uuid === userCompany) {
					dispatch(setActiveCompany(userCompany));
				}

				dispatch(updateCompany(deactivatedCompany));
				dispatch(successNotification(`Company successfully ${companyUpdateStatus}.`));
			}, error => dispatch(errorNotification("Could not deactivate company.", error)))
			.catch(error => notifyBugSnag(new Error(error)));
	};
}

function deleteCompanyAsync(company: ICompany) {
	return (dispatch) => Api.AdminApi.deleteCompany(company)
		.then(() => {
			dispatch(deleteCompany(company));
			dispatch(successNotification("Company successfully deleted."));
		}, error => dispatch(errorNotification("Could not delete company.", error)))
		.catch(error => notifyBugSnag(new Error(error)));
}

function deleteStoreAsync(store: IStore) {
	const api = new StoresApi();
	return (dispatch) => api.deleteStore(store)
		.then((result) => {
			dispatch(deleteStore(store));
			dispatch(successNotification(`Store ${store.name} successfully deleted.`));
		}, (error) => {
			dispatch(errorNotification(`Error deleting store ${store.name}.`, error))
		})
		.catch(error => notifyBugSnag(new Error(error)));
}

// NOTE: This is a RESET method that gets called when a user switches company.
function getActiveCompanyDetails(companyId: string, preserveUuid?: boolean) {
	return (dispatch, getState) => {
		const getRoot = (location: string) => "/" + location.split("/")[1];
		const state = getState();
		const activeNavigationItem = getActiveNavigationItem(state);
		const { Router: { location: { pathname } } } = state;
		const path = activeNavigationItem.includes("admin") || preserveUuid ? pathname : activeNavigationItem;
		const root = getRoot(path);

		if (getCompany(state, companyId)) {
			dispatch(resetActionSets());
			dispatch(resetAds());
			dispatch(resetAdAnalytics());
			dispatch(resetMedia());
			dispatch(resetDevices());
			dispatch(resetDeployments());
			dispatch(resetIntegratorTeams());
			dispatch(setActiveCompany(companyId));
			dispatch(changeCompanyUIReset());
			dispatch(setManagedCompanies([]));
			dispatch(getDeviceTypes());

			dispatch(getCompanyInfoAsync(companyId))
				.then((company: ICompany) => {
					dispatch(setBugSnagCompanyAsync({ uuid: companyId, name: company.name }));
					dispatch(setMenuItems());

					if ( toggleFeature("notifications", true, false) ) {
						pusherConnection2.rebindEvents();
					} else {
						pusherConnection.rebindEvents();
					}

					// after we have received new company info / permissions set, push to new path
					if (preserveUuid) {
						dispatch(push(path));
					} else {
						// get our updated state, and if the root location
						// has not changed, redirect to the root path only
						const currentState = getState();
						const { current, previous } = getLocationPaths(currentState);
						const currentRoot = getRoot(current);
						const previousRoot = getRoot(previous);

						// if the user was viewing a resource or deep link
						// redirect to the root level after company switch
						if (currentRoot === previousRoot && current !== previous) {
							dispatch(push(root));
						}
					}
				});
			notification.destroy();
			dispatch(setFeatureTogglesAsync());
		} else {
			dispatch(errorNotification("You do not have access to this company."));
		}
	};
}

// returns hashid needed for generating the qr code
function getCompanyAdminInfoAsync(companyId: string) {
	const api = new CompanyApi();
	return (dispatch) => api.getCompany({ uuid: companyId } as ICompany)
		.then((result: ICompany) => {
			dispatch(updateCompany(result));
		}, (error) => {
			dispatch(errorNotification("Could not load company information.", error));
		})
		.catch(error => notifyBugSnag(new Error(error)));
}

function getCompanyInfoAsync(companyId: string) {
	const api = new CompanyApi();
	return (dispatch) => api.getCompany({ uuid: companyId } as ICompany, true)
		.then((result: CompanyResult) => {
			const permissions = [ ...result.permissions ];
			let company = cloneDeep(result);

			delete company.permissions;

			dispatch(updateCompany(company));
			dispatch(updateUserPermissions(permissions))
			dispatch(getRolesLists());

			return result;
		}, (error) => {
			dispatch(errorNotification("Could not load company information.", error));
		})
		.catch(error => notifyBugSnag(new Error(error)));
}

function getCompanyListAsync(reset?: boolean) {
	const api = new CompanyApi();
	return (dispatch, getState) => {
		const { Company: CompanyState, UI: { asyncState } } = getState();
		const { currentPage, currentlyFetching, haveAllData, lastFetch, lastFetchedCompany } = asyncState.companies
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const companyChanged = CompanyState.activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = moment().diff(lastFetch, "minute") > CacheInvalidationPeriod || companyChanged
			|| reset;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		dispatch(setAsyncFetching("companies", true, CompanyState.activeCompanyId));

		const page = shouldInvalidateCache ? 0 : currentPage;

		return api.getCompanies(page + 1)
			.then((result: CompaniesResult) => {
				const { current_page, last_page } = result.meta;
				const isLastPage = current_page === last_page; // eslint-disable-line camelcase

				dispatch(updateCompanies(result.data, reset));
				dispatch(getCompanyInfoAsync(CompanyState.activeCompanyId));
				dispatch(setAsyncState(
					"companies",
					isLastPage,
					current_page
				));
				dispatch(setAsyncFetching("companies", false));

				return result;
			}, (error) => {
				dispatch(errorNotification("Error receiving data from server.", error));
				dispatch(setAsyncFetching("companies", false));
			})
			.catch(error => notifyBugSnag(new Error(error)));
	};
}

function getManagedCompaniesListAsync(reset?: boolean) {
	return (dispatch, getState) => {
		const state = getState();
		const activeCompanyId = getActiveCompanyId(state);
		const managedCompaniesAsyncState = getManagedCompaniesAsyncState(state);
		const { currentlyFetching, haveAllData, lastFetch, lastFetchedCompany, currentPage } = managedCompaniesAsyncState;
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const companyChanged = activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = moment().diff(lastFetch, "minute") > CacheInvalidationPeriod || companyChanged
			|| reset;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		if (shouldInvalidateCache) {
			dispatch(setManagedCompanies([]));
		}

		dispatch(setAsyncFetching("managedCompanies", true, activeCompanyId));

		const page = shouldInvalidateCache ? 0 : currentPage;
		const api = new CompanyApi();
		return api.getManagedCompanies(page + 1)
			.then((result: ExtendedCompaniesResult) => {
				const { meta: { current_page }, data, links: { next } } = result;

				dispatch(setManagedCompanies(data, page > 0));
				dispatch(setAsyncState(
					"managedCompanies",
					!next,
					current_page
				));
				dispatch(setAsyncFetching("managedCompanies", false));

				if (!!next) {
					dispatch(getManagedCompaniesListAsync());
				}
			}, (error) => {
				dispatch(errorNotification("Could not fetch Managed Companies.", error));
				dispatch(setAsyncFetching("managedCompanies", false));
			})
			.catch(error => notifyBugSnag(new Error(error)));
	};
}

function getManagedCompanyAsync(companyId: string) {
	const api = new CompanyApi();
	return (dispatch) => api.getManagedCompany(companyId)
		.then((result: ExtendedCompaniesResult) => {
			dispatch(setManagedCompany(result.data[0]));
		}, (error) => {
			dispatch(errorNotification("Could not fetch Managed Company details.", error));
		})
		.catch(error => notifyBugSnag(new Error(error)));
}

function getStoreAsync(uuid: string) {
	return (dispatch, getState) => {
		const api = new StoresApi();
		return api.getStore(uuid)
			.then((result: IStore) => {
				dispatch(updateStore(result));

				return result;
			}, (error) => {
				dispatch(errorNotification("Could not load store information.", error));
			})
			.catch(error => notifyBugSnag(new Error(error)));
	};
}

function getStoresListAsync() {
	return (dispatch, getState) => {
		const state = getState();
		const activeCompanyId = getActiveCompanyId(state);
		const storesState = getStoresAsyncState(state);
		const { currentlyFetching, haveAllData, lastFetch, lastFetchedCompany, currentPage } = storesState;
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const companyChanged = activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = moment().diff(lastFetch, "minute") > CacheInvalidationPeriod || companyChanged;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		if (shouldInvalidateCache) {
			dispatch(setStores([], true));
		}

		dispatch(setAsyncFetching("stores", true, activeCompanyId));

		const page = shouldInvalidateCache ? 0 : currentPage;
		const api = new StoresApi();
		return api.getStores(page + 1)
			.then((result: StoresResult) => {
				const { meta: { current_page }, data, links: { next } } = result;

				dispatch(setStores(data, shouldInvalidateCache));
				dispatch(setAsyncState(
					"stores",
					!next,
					current_page
				));
				dispatch(setAsyncFetching("stores", false));

				if (!!next) {
					dispatch(getStoresListAsync());
				}
			}, (error) => {
				dispatch(errorNotification("Could not fetch store numbers.", error));
				dispatch(setAsyncFetching("stores", false));
			})
			.catch(error => notifyBugSnag(new Error(error)));
	};
}

function syncIntegrationTeams(companyId: string, teams: KeyLabel[], oldTeams: KeyLabel[]) {
	const api = new IntegratorsApi();
	const teamKeys = teams.map(({ key }) => key);

	return (dispatch) => {
		// pre-emptively set new teams
		dispatch(setManagedCompaniesTeams(companyId, teams));

		return api.syncIntegrationTeams(teamKeys, companyId)
			.then(() => {
				// noop
			}, (error) => {
				// reset the old teams if our attempted sync fails
				dispatch(setManagedCompaniesTeams(companyId, oldTeams));
				dispatch(errorNotification(`Could not sync Integration Teams with ${ companyId }.`, error));
			})
			.catch((error) => notifyBugSnag(new Error(error)));
	};
}

function syncIntegrationTeamUsers(companyId: string, users: KeyLabel[]) {
	const api = new IntegratorsApi();
	const mappedUsers = users.map(({ key }) => key);

	return (dispatch) => {
		return api.syncIntegrationTeamUsers(mappedUsers, companyId)
			.then((result) => {
				dispatch(getManagedCompanyAsync(companyId));
			}, (error) => {
				dispatch(errorNotification(`Could not sync Integration Team Users with ${ companyId }.`, error));
			})
			.catch((error) => notifyBugSnag(new Error(error)));
	};
}

function updateCompanyAsync(company: ICompany) {
	const api = new CompanyApi();
	const { name, active, teamType, customPermissions, passwordRequirements, passwordReset } = company;
	const updates = {
		name,
		active,
		integrator: teamType === "integrator",
		customPermissions,
		passwordRequirements,
		passwordReset: passwordReset ?? false
	};

	return (dispatch, getState) => {
		const { User: { user } } = getState();
		const userCompany = user && user.company && user.company.uuid;

		return api.updateCompany(company, updates)
			.then(() => {
				if (company.uuid === userCompany) {
					dispatch(setActiveCompany(userCompany));
				}

				dispatch(updateCompany(company));
				dispatch(successNotification("Company Updated Successfully"));
			}, error => dispatch(errorNotification("Error updating company.", error)))
			.catch(error => notifyBugSnag(new Error(error)));
	};
}

function updateStoreAsync(store: IStore) {
	const api = new StoresApi();
	return (dispatch) => api.updateStore(store)
		.then(() => {
			dispatch(updateStore(store));
			dispatch(successNotification(`Store ${store.name} successfully updated.`));
		}, (error) => {
			dispatch(errorNotification("Could not update store.", error));
		})
		.catch(error => notifyBugSnag(new Error(error)));
}

export {
	assignIntegrationTeamsAsync,
	assignIntegratorsAsync,
	createCompany,
	createManagedCompany,
	createCompanyAsync,
	createStore,
	createStoreAsync,
	createBulkStoresAsync,
	deactivateCompanyAsync,
	deleteCompany,
	deleteCompanyAsync,
	deleteStore,
	deleteStoreAsync,
	getActiveCompanyDetails,
	getCompanyAdminInfoAsync,
	getCompanyInfoAsync,
	getCompanyListAsync,
	getManagedCompaniesListAsync,
	getManagedCompanyAsync,
	getStoreAsync,
	getStoresListAsync,
	resetIntegratorTeams,
	setActiveCompany,
	setCompanies,
	setManagedCompanies,
	setManagedCompaniesTeams,
	setManagedCompany,
	setStores,
	syncIntegrationTeams,
	syncIntegrationTeamUsers,
	updateCompanies,
	updateCompany,
	updateCompanyAsync,
	updateStore,
	updateStoreAsync
};

import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IMedia, MediaType } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { ButtonTypes } from "Components/Global/Button";
import { Button, Icon, Input } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import EditableTagGroup from "Components/Global/EditableTagGroup";
import FavoriteToggle from "Components/Global/FavoriteToggle";
import { IconWeights } from "Components/Global/Icon";
import PropertiesPanel, { PropertiesPanelButton } from "Components/Global/PropertiesPanel";
import MediaThumbnail from "Components/Media/MediaThumbnail";
import { deleteMediaAsync, updateMediaAsync } from "Data/Actions/MediaAsync";
import { setMediaPreview } from "Data/Actions/UI";
import { DefaultMaxLength } from "Data/Objects/Global";
import { Media } from "Data/Objects/Media";
import inputValidation from "Data/Objects/Validation";

interface MediaPropertiesPanelProps {
	deleteMedia: (uuid: string) => void;
	media: IMedia;
	updateMedia: (media: IMedia) => void;
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => void;
}

const mapDispatchToProps = (dispatch) => ({
	deleteMedia: (uuid: string) => dispatch(deleteMediaAsync(uuid)),
	updateMedia: (media: IMedia) => dispatch(updateMediaAsync(media)),
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => dispatch(setMediaPreview(uri, vimeoId, type))
});

export class MediaPropertiesPanel extends
	React.Component<MediaPropertiesPanelProps> {
	constructor(props: MediaPropertiesPanelProps) {
		super(props);

		this.leftColumnWidth = 100;
		this._mounted = false;
		this.thumbSize = 175;

		this.styles = {
			icon: {
				display: "flex",
				alignItems: "flex-end",
				justifyContent: "center",
				background: Colors.white,
				borderRadius: "50%",
				marginLeft: 5,
				width: 28,
				height: 28
			},
			leftColumn: {
				display: "inline-block",
				width: this.leftColumnWidth
			},
			statusContainer: {
				display: "inline-block",
				marginLeft: this.leftColumnWidth,
				marginTop: -28
			},
			tags: {
				backgroundColor: "rgba(0, 0, 0, .15)",
				boxShadow: "inset 0 0 1px rgba(0, 0, 0, .35)",
				overflowY: "auto",
				padding: 2
			},
			thumbnail: {
				margin: "0 auto",
				width: this.thumbSize
			}
		};

		this.promptDelete = this.promptDelete.bind(this);
		this.handleNameChanged = this.handleNameChanged.bind(this);
		this.toggleMediaPreview = this.toggleMediaPreview.bind(this);
		this.toggleBanner = this.toggleBanner.bind(this);
		this.handleTagsChanged = this.handleTagsChanged.bind(this);
	}

	leftColumnWidth: number;
	_mounted: boolean;
	thumbSize: number;

	styles: CustomCSS;

	componentDidMount() {
		this._mounted = true;
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	render() {
		return (
			<PropertiesPanel
				actions={this.getAvailableActions()}
				actionComponents={this.getAvailableActionComponents()}
				properties={this.getRenderedContent()}
				title="Media Properties"
			/>
		);
	}

	renderDimensions() {
		const { media: { height, width } } = this.props;
		const dimensions = `${width} x ${height}`;

		return (
			<div key={this.props.media.uuid + "_dimensions"}>
				<div style={this.styles.leftColumn}>
					Dimensions:
				</div>
				{dimensions}
			</div>
		);
	}

	renderDuration() {
		const { duration, mediaType, uuid } = this.props.media;

		if (mediaType !== "video") {
			return null;
		}

		return (
			<div key={uuid + "_duration"}>
				<div style={this.styles.leftColumn}>
					Duration:
				</div>
				{Utils.getHumanReadableDuration(duration)}
			</div>
		);
	}

	renderFileSize() {
		const { bytesize, uuid } = this.props.media;

		return (
			<div key={uuid + "_filesize"}>
				<div style={this.styles.leftColumn}>
					File Size:
				</div>
				{Utils.getHumanReadableBytesize(bytesize)}
			</div>
		);
	}

	renderFileType() {
		const { media } = this.props;

		return (
			<div key={media.uuid + "_filetype"}>
				<div style={this.styles.leftColumn}>
					File Type:
				</div>
				{Media.getTypeLabel(media)}
			</div>
		);
	}

	renderLink() {
		const { preview, uri, uuid } = this.props.media;

		return (
			<div key={uuid + "_link"}>
				<div style={this.styles.leftColumn}>
					Link:
				</div>
				<a
					href={preview ?? uri}
					target="_blank"
					rel="noopener noreferrer">
					{this.getTruncatedUri()}
				</a>
			</div>
		);
	}

	renderMediaTitleInput() {
		const { name, uuid } = this.props.media;

		return (
			<div key={uuid + "_title-input"}>
				<div style={this.styles.leftColumn}>
					Title:
				</div>
				<Input
					id={uuid}
					maxLength={ DefaultMaxLength }
					placeholder="Name"
					required={ true }
					value={name}
					saveCallback={this.handleNameChanged}
					validator={ inputValidation.name } />
			</div>
		);
	}

	renderMediaThumbnail() {
		const { media } = this.props;

		return (
			<div key={media.uuid + "_thumbnail"}>
				<div onClick={this.toggleMediaPreview}>
					<MediaThumbnail
						media={media}
						style={this.styles.thumbnail}
						width={this.thumbSize} />
				</div>
			</div>
		);
	}

	renderStateRow() {
		const { icon, leftColumn, statusContainer } = this.styles;
		const { state, uuid } = this.props.media;
		let iconName = "";
		let iconStyles;
		let statusMessage = "";
		let iconWeight;

		switch (state) {
			case "pending":
			case "new":
				iconName = "clock";
				iconWeight = "regular";
				iconStyles = { color: "orange" };
				statusMessage = "This file is currently being processed.";
				break;
			case "exception":
				iconName = "exclamation-circle";
				iconStyles = { color: "red" };
				statusMessage = "An error occurred while processing this file.";
				break;
			case "ready":
				iconName = "check-circle";
				iconStyles = { color: "green" };
				statusMessage = "This file has been processed and is ready for use.";
				break;
		}

		return (
			<div key={uuid + "_filestatus"}>
				<div style={leftColumn}>
					Status:
					<br />
					<div style={icon}>
						<Icon name={iconName} iconWeight={iconWeight} size="small" fixedWidth={false} style={iconStyles} />
					</div>
				</div>
				<div style={statusContainer}>
					{statusMessage}
				</div>
			</div>
		);
	}

	renderTags() {
		const { tags, uuid } = this.props.media;

		return (
			<div key={uuid + "_tags"}>
				<div>
					Tags:
				</div>
				<div className={ "has-scrollbars" } style={this.styles.tags} >
					<EditableTagGroup key={ uuid + JSON.stringify(tags) }
						collection={tags}
						onChange={this.handleTagsChanged}
					/>
				</div>
			</div>
		);
	}

	renderToggleBanner() {
		// Render the toggle button if the image is 1080 wide and < 100 tall
		const { media: { mediaType, uuid, height, width } } = this.props;
		const isBannerSized = width === 1080 && height <= 200;
		const shouldRender = (mediaType === "image" || mediaType === "banner") && isBannerSized;

		if (!shouldRender) {
			return null;
		}

		let buttonColor = "transparent",
			borderColor = Colors.white,
			buttonText = "Make Security Banner";

		if (mediaType === "banner") {
			buttonColor = Colors.primaryBlue,
			borderColor = Colors.primaryBlue,
			buttonText = "Security Banner";
		}

		return (
			<Button
				key={uuid + "_banner-toggle"}
				disabled={!shouldRender}
				type="primary"
				color={buttonColor}
				icon={"shield"}
				fluid={false}
				style={{
					borderColor: borderColor,
					width: "100%"
				}}
				onClick={ this.toggleBanner }>
				{buttonText}
			</Button>
		);
	}

	renderUploadedBy() {
		const { createdBy, uuid } = this.props.media;

		return (
			<div key={uuid + "_uploaded_by"}>
				<div style={this.styles.leftColumn}>
					Uploaded By:
				</div>
				{ createdBy && createdBy.name }
			</div>
		);
	}

	renderUploadedDate() {
		const { createdAt, uuid } = this.props.media;

		return (
			<div key={uuid + "_uploaded_date"}>
				<div style={this.styles.leftColumn}>
					Uploaded Date:
				</div>
				{Utils.getHumanReadableDate(createdAt)}
			</div>
		);
	}

	getAvailableActions(): PropertiesPanelButton[] {
		const actions = [
			{
				action: () => this.promptDelete(),
				key: `${this.props.media.uuid}_delete`,
				icon: { name: "trash-alt", weight: "regular" as IconWeights },
				name: "Delete Media",
				type: "danger" as ButtonTypes
			}
		];

		return actions;
	}

	getAvailableActionComponents() {
		const { uuid } = this.props.media;
		const toggleBanner = this.renderToggleBanner();

		let actionComponents = [
			(
				<FavoriteToggle
					key={uuid + "_favorite-toggle"}
					fullWidth={true}
					mediaId={uuid}
					type="button"
				/>
			)
		];

		if (toggleBanner) {
			actionComponents.unshift(toggleBanner);
		}

		return actionComponents;
	}

	getRenderedContent() {
		let properties = [
			this.renderMediaThumbnail(),
			this.renderMediaTitleInput(),
			this.renderFileType(),
			this.renderUploadedDate(),
			this.renderUploadedBy(),
			this.renderFileSize(),
			this.renderDimensions()
		];
		const duration = this.renderDuration();

		if (duration) {
			properties.push(duration);
		}

		properties.push(
			this.renderLink(),
			this.renderStateRow(),
			this.renderTags()
		);

		return properties;
	}

	getTruncatedUri() {
		const { preview, uri } = this.props.media;
		const fullUri = preview ?? uri;
		let truncatedUri = "";

		if (fullUri && fullUri !== "") {
			const { 1: uriWithoutPrefix } = fullUri.split("//");
			truncatedUri = uriWithoutPrefix.substr(0, 8);
			truncatedUri += String.fromCharCode(0x2026);
			truncatedUri += uriWithoutPrefix.substring(uriWithoutPrefix.length - 8);
		}

		return truncatedUri;
	}

	handleNameChanged(text: string) {
		this.props.updateMedia(Object.assign({}, this.props.media, {
			name: text
		}));
	}

	handleTagsChanged(data: string[]) {
		this.props.updateMedia(Object.assign({}, this.props.media, {
			tags: data
		}));
	}

	promptDelete() {
		Notifications.confirm("Delete " + this.props.media.name + "?",
			"Are you sure you want to delete this file?",
			"Delete", "Cancel", () => {
				this.props.deleteMedia(this.props.media.uuid);
			});
	}

	toggleBanner() {
		const { media } = this.props;
		const mediaType = media.mediaType === "image" ? "banner" : "image";

		this.props.updateMedia(Object.assign({}, media, {
			mediaType
		}));
	}

	toggleMediaPreview() {
		const { media, setMediaPreview: setPreview } = this.props;
		const { mediaType, uri, preview } = media;
		setPreview(preview ?? uri, -1, mediaType);
	}
}

export default connect(null, mapDispatchToProps)(MediaPropertiesPanel);
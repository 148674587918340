import { Form } from "antd";
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, ICompany, IUser, RolesList } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Button, Modal, Select } from "Components/Global/Common";
import Header from "Components/Global/Header";
import Input from "Components/Global/Input";
import { setCreateUserModal } from "Data/Actions/UI/Modals";
import { createUserAsync } from "Data/Actions/UsersAsync";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { User } from "Data/Objects/User";
import inputValidation from "Data/Objects/Validation";
import { getUsersList } from "Data/Selectors/Admin";
import { getActiveCompany, getCompanies } from "Data/Selectors/Company";
import { getCreateUserModalVisibility } from "Data/Selectors/UI";
import { getEditRolesList } from "Data/Selectors/Roles";
import { cloneDeep } from "lodash";

const { Option } = Select;

const mapStateToProps = (state) => ({
	activeCompany: getActiveCompany(state),
	users: getUsersList(state),
	companies: getCompanies(state),
	roles: getEditRolesList(state),
	visible: getCreateUserModalVisibility(state)
});

const mapDispatchToProps = (dispatch) => ({
	createUser: (user: IUser, company: ICompany) => dispatch(createUserAsync(user, company)),
	hideModal: () => dispatch(setCreateUserModal(false))
});

interface CreateUserModalProps {
	activeCompany: ICompany;
	users: IUser[];
	companies: ICompany[];
	createUser: (user: IUser, company: ICompany) => void;
	deleteUser: (user: IUser) => void;
	hideModal: () => void;
	roles: RolesList;
	search: string;
	selectedUsers: string[];
	selectUsers: (userUuids: string[]) => void;
	setSearch: (query: string) => void;
	visible: boolean;
}

interface CreateUserModalState {
	createUserData: IUser;
	instance: string;
	shouldDisplayRequiredFieldErrors: boolean;
	shouldDisplayValidationErrors: boolean;
}

class CreateUserModal extends React.Component<CreateUserModalProps, CreateUserModalState> {
	constructor(props: CreateUserModalProps) {
		super(props);

		this.state = {
			createUserData: Object.assign({}, new User(), { company: this.props.activeCompany }),
			instance: Utils.getGuid(),
			shouldDisplayRequiredFieldErrors: false,
			shouldDisplayValidationErrors: false
		}

		this.styles = {
			container: {
				textAlign: "left",
				marginBottom: 20
			},
			error: {
				color: "red",
				textAlign: "center"
			},
			form: {
				width: "90%",
				margin: "auto"
			},
			validationMessage: {
				textAlign: "center",
				marginBottom: 10
			},
			right: {
				textAlign: "right"
			},
			selectCompany: {
				marginBottom: 12
			},
			validation: {
				textAlign: "center",
				marginTop: -12,
				fontSize: 10
			}
		}

		this.handleCreateUserCompanyChange = this.handleCreateUserCompanyChange.bind(this);
		this.handleCreateUserRoleChange = this.handleCreateUserRoleChange.bind(this);
		this.handleCreateUserTextChange = this.handleCreateUserTextChange.bind(this);
		this.saveEmailInput = this.saveEmailInput.bind(this);
		this.saveNameInput = this.saveNameInput.bind(this);
		this.submitCreateUserRequest = this.submitCreateUserRequest.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.validateCreateUserInput = this.validateCreateUserInput.bind(this);
	}

	styles: CustomCSS;

	componentDidUpdate(prevProps: CreateUserModalProps) {
		const { activeCompany } = this.props;

		if (activeCompany.uuid !== prevProps.activeCompany.uuid) {
			let changedUserData = cloneDeep(this.state.createUserData);

			changedUserData.company = {
				uuid: activeCompany.uuid,
				name: activeCompany.name
			} as ICompany;

			this.updateUserData(changedUserData);
		}

	}

	render() {
		return (
			<Modal
				modalKey="createUserModal"
				visible={ this.props.visible }
				onCancel={ this.hideModal }
			>
				{ this.renderHeader() }
				{ this.renderForm() }
				{ this.renderValidation() }
				{ this.renderControls() }
			</Modal>
		);
	}

	renderCompany(company: ICompany) {
		const { uuid, name } = company;

		return (
			<Option
				value={ uuid }
				key={ uuid }
			>
				{ name }
			</Option>
		);
	}

	renderControls() {
		return (
			<div style={ this.styles.right }>
				<Button onClick={ this.hideModal }>
					Cancel
				</Button>&nbsp;
				<Button
					type="primary"
					onClick={ this.validateCreateUserInput }>
					Create
				</Button>
			</div>
		);
	}

	renderEmailInput() {
		const { createUserData, instance, shouldDisplayValidationErrors } = this.state;
		const { validation, error } = this.styles;
		const validationStyle = {
			...validation,
			display: shouldDisplayValidationErrors && this.emailHasError() ? "block" : "none"
		};

		return (
			<React.Fragment>
				<Input
					id={ `createUserEmail_${ instance }` }
					placeholder="Email"
					updateCallback={ this.saveEmailInput }
					value={ createUserData.email }
				/>
				<div style={ validationStyle }>
					<span style={ error }>
						Email address not valid.
					</span>
				</div>
			</React.Fragment>
		);
	}

	renderForm() {
		const { container, form } = this.styles;

		return (
			<div style={ container }>
				<Form onSubmit={ this.handleSubmit } style={ form }>
					{ this.renderNameInput() }
					{ this.renderEmailInput() }
					{ this.renderSelectCompany() }
					{ this.renderSelectRole() }
				</Form>
			</div>
		);
	}

	renderHeader() {
		return (
			<Header size={ 3 }>Create User</Header>
		);
	}

	renderNameInput() {
		const { createUserData, instance } = this.state;
		return (
			<Input
				id={ `createUserName_${ instance }` }
				placeholder="Name"
				updateCallback={ this.saveNameInput }
				value={ createUserData.name }
				validator={ inputValidation.name }
			/>
		);
	}

	renderRole(role: [string, string]) {
		const [ value, title ] = role;
		return (
			<Option
				key={ value }
				value={ value }
			>
				{ title }
			</Option>
		);
	}

	renderSelectCompany() {
		const { companies } = this.props;

		if (!hasPermission(PERMISSIONS.USERS_OWNER_EDIT) || companies && companies.length === 1) {
			return null;
		}

		const companiesToRender = companies
			?.sort((currentCompany, nextCompany) => {
				return currentCompany.name.localeCompare(nextCompany.name)
			})
			.map(this.renderCompany);

		return (
			<Select
				placeholder="Select a company..."
				value={ this.state.createUserData.company.uuid }
				style={ this.styles.selectCompany }
				showSearch
				filterOption
				optionFilterProp="children"
				onChange={ this.handleCreateUserCompanyChange }
			>
				{ companiesToRender }
			</Select>
		);
	}

	renderSelectRole() {
		const { roles } = this.props;
		const rolesToRender = roles ? Object.entries(roles).map(this.renderRole) : [];

		return (
			<Select
				placeholder="Select a role..."
				value={ this.state.createUserData.role.name }
				onChange={ this.handleCreateUserRoleChange }
			>
				{ rolesToRender }
			</Select>
		);
	}

	renderValidation() {
		const { red, validationMessage } = this.styles;

		return (
			<div style={{
				...validationMessage,
				display: this.state.shouldDisplayRequiredFieldErrors ? "block" : "none"
			}}>
				<span style={ red }>
					All form fields are required.
				</span>
			</div>
		);
	}

	emailHasError() {
		const { createUserData } = this.state;
		let passesRegex = Utils.isValidEmail(createUserData.email);
		return createUserData.email != null && !passesRegex;
	}

	handleCreateUserTextChange(value: string, key: string) {
		let changedUserData = this.state.createUserData;
		changedUserData[key] = value;
		this.updateUserData(changedUserData);
	}

	handleCreateUserCompanyChange(uuid: string) {
		const { companies } = this.props;
		// get index of this uuid in companies state
		let companyUuids = companies.map((c) => {
			return c.uuid
		});
		let company = companies[companyUuids.indexOf(uuid)] as any;
		let changedUserData = cloneDeep(this.state.createUserData);

		// mock up a basic company to send to the api
		changedUserData.company = {
			uuid: company.uuid,
			name: company.name
		} as ICompany;

		this.updateUserData(changedUserData);
	}

	handleCreateUserRoleChange = (value: string) => {
		let changedUserData = this.state.createUserData;
		changedUserData.role.name = value;
		this.updateUserData(changedUserData);
	}

	handleSubmit(e: React.FormEvent) {
		e.preventDefault();
	}

	resetShouldDisplayValidationErrors() {
		this.setState((prevState) => {
			return update(prevState, {
				shouldDisplayValidationErrors: { $set: false },
				shouldDisplayRequiredFieldErrors: { $set: false }
			});
		});
	}

	saveEmailInput(value: string) {
		this.handleCreateUserTextChange(value, "email");
	}

	saveNameInput(value: string) {
		this.handleCreateUserTextChange(value, "name")
	}

	submitCreateUserRequest() {
		const { createUserData } = this.state;

		this.resetShouldDisplayValidationErrors();

		this.props.createUser(createUserData, createUserData.company);

		this.hideModal();
	}

	hideModal() {
		const { activeCompany } = this.props;

		this.setState(() => ({
			createUserData: { ...new User(), company: activeCompany },
			instance: Utils.getGuid()
		}), this.props.hideModal);
	}

	updateUserData(changedUserData: IUser) {
		this.setState((prevState) => {
			return update(prevState, {
				createUserData: { $set: changedUserData }
			});
		});
	}

	validateCreateUserInput() {
		const { companies } = this.props;
		let d = this.state.createUserData;

		if (this.emailHasError()) {
			this.setState((prevState) => {
				return update(prevState, {
					shouldDisplayValidationErrors: { $set: true }
				});
			});
		} else if (d.name === "" || d.email === "" || d.role.name === "") {
			this.setState((prevState) => {
				return update(prevState, {
					shouldDisplayRequiredFieldErrors: { $set: true }
				});
			});
		}

		// for Company Owners, there is no choice of company so this is never set
		if (!d.company.uuid) {
			this.handleCreateUserCompanyChange(companies[0].uuid);
		} else {
			this.submitCreateUserRequest();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal);
import { Middleware } from "redux";

import { Action } from "@connect/Interfaces";
import { ActionType } from "Data/Objects/ActionTypes";

class DispatchableAction<A> implements Action<A> {
	constructor(actionType: ActionType, args: A) {
		this.args = args;
		this.type = actionType.type;
	}

	args: A;
	type: string;
}

const dispatchableMiddleware: Middleware = (store) => (next) => (action) => {
	if (action instanceof DispatchableAction) {
		return next(Object.assign({}, action));
	}

	return next(action);
};

export { DispatchableAction, dispatchableMiddleware };
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import ResourceButton from "Components/Admin/ResourceButton";

class ResourcesPage extends React.Component {
	constructor(props: any) {
		super(props);

		this.styles = {
			grid: {
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr",
				width: "80%",
				marginLeft: "10%",
				marginTop: 40
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { grid } = this.styles;

		return (
			<div style={ grid }>
				<ResourceButton
					buttonText="View >"
					title="Support"
					icon="user-headset"
					resource="https://support.clintonelectronics.com" />
				<ResourceButton
					buttonText="View >"
					title="Networking Guide"
					icon="wifi"
					resource="https://support.clintonelectronics.com/space/CECdocs/2313715732/Clinton+Connect+Network+Requirements" />
				<ResourceButton
					buttonText="View >"
					title="Clinton Connect Policies"
					icon="book"
					resource="https://support.clintonelectronics.com/space/CECdocs/2314010635/Clinton+Connect+Policies" />
				<ResourceButton
					buttonText="Download"
					title={ "Connect PVM Manual" }
					icon="book"
					resource="https://cdn.clintonconnect.com/resources/Conect-PVM-SETUP_ver10.22.19.1.pdf" />
				<ResourceButton
					buttonText="Download"
					title={ "10\"/13\" Setup Guide" }
					icon="wrench"
					resource="https://clintonelectronics.com/wp-content/uploads/2020/03/10.13_Basic_Setup_Guide.pdf" />
				<ResourceButton
					buttonText="Download"
					title="CE-P13NC-B Setup Guide"
					icon="wrench"
					resource="https://clintonelectronics.com/wp-content/uploads/2020/07/CE-P13NC-B_BasicSetup.pdf" />
				<ResourceButton
					buttonText="Download"
					title={ "21\” Setup Guide" }
					icon="wrench"
					resource="https://clintonelectronics.com/wp-content/uploads/2019/06/21_Basic_Setup_Guide.pdf" />
				<ResourceButton
					buttonText="Download"
					title={ "32\"/43\" Setup Guide" }
					icon="wrench"
					resource="https://cdn.clintonconnect.com/resources/32.43_Basic_Setup_Guide.pdf" />
				<ResourceButton
					buttonText="Download"
					title="CE-P43NC-SR-B Setup Guide"
					icon="wrench"
					resource="https://clintonelectronics.com/wp-content/uploads/2019/11/CE-P43NC-SR-B_BasicSetup_06.12.20.pdf" />
			</div>
		);
	}
}

export default ResourcesPage;
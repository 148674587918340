import { Action, UuidDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { createReducer } from "Data/Utils";

export function addReportNotification(state: string[], action: Action<UuidDispatch>) {
	const { uuid } = action.args;
	let newState = [ ...state ];

	if (state.indexOf(uuid) === -1) {
		newState.push(uuid);
	}

	return newState;
}

export function removeReportNotification(state: string[], action: Action<UuidDispatch>) {
	const index = state.indexOf(action.args.uuid);
	let newState = [ ...state ];

	if (index > -1) {
		newState.splice(index, 1);
	}

	return newState;
}

export function resetFinishedReports(state: string[], action: Action<null>) {
	return [];
}

const {
	ADD_REPORT_NOTIFICATION,
	REMOVE_REPORT_NOTIFICATION,
	RESET_FINISHED_REPORTS
} = ACTION_TYPES.UI;

const reducers = {
	[ADD_REPORT_NOTIFICATION.type]: addReportNotification,
	[REMOVE_REPORT_NOTIFICATION.type]: removeReportNotification,
	[RESET_FINISHED_REPORTS.type]: resetFinishedReports
};

export default createReducer(reducers, Array, 0);

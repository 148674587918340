import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, ICompany, IUser } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import AdminUsersTable from "Components/Admin/AdminUsersTable";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import { Colors } from "Components/Global/Constants";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import { setCreateUserModal } from "Data/Actions/UI/Modals";
import { setActiveSearch, setActiveSelection } from "Data/Actions/UI";
import { deleteUserAsync } from "Data/Actions/UsersAsync";
import { hasPermission } from "Data/Objects/Permissions";
import { getUsersList } from "Data/Selectors/Admin";
import { getActiveSearch, getActiveSelection } from "Data/Selectors/UI";

const { lightGray, offWhite } = Colors;

const mapStateToProps = (state) => ({
	selectedUsers: getActiveSelection(state, "users"),
	search: getActiveSearch(state, "users"),
	users: getUsersList(state)
});

const mapDispatchToProps = (dispatch) => ({
	deleteUser: (user: IUser) => dispatch(deleteUserAsync(user)),
	selectUsers: (userUuids: string[]) => dispatch(setActiveSelection("users", userUuids)),
	setSearch: (query: string) => dispatch(setActiveSearch("users", query)),
	showModal: () => dispatch(setCreateUserModal(true))
});

interface AdminUsersPageProps {
	users: IUser[];
	companies: ICompany[];
	deleteUser: (user: IUser) => void;
	search: string;
	selectedUsers: string[];
	selectUsers: (userUuids: string[]) => void;
	setSearch: (query: string) => void;
	showModal: () => void;
}

interface AdminUsersPageState {
	selectModeOn: boolean;
}

class AdminUsersPage extends React.Component <AdminUsersPageProps, AdminUsersPageState> {
	constructor(props: AdminUsersPageProps) {
		super(props);
		this.state = {
			selectModeOn: false
		}

		this.styles = {
			header: {
				padding: "0 30px"
			},
			buttonStyle: {
				color: lightGray,
				background: offWhite
			}
		}

		this.confirmDeleteUsers = this.confirmDeleteUsers.bind(this);
		this.deselectAllUsers = this.deselectAllUsers.bind(this);
		this.getBatchOperations = this.getBatchOperations.bind(this);
		this.handleBulkDelete = this.handleBulkDelete.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleUserSelection = this.handleUserSelection.bind(this);
		this.selectAllUsers = this.selectAllUsers.bind(this);
		this.showModal = this.showModal.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { selectModeOn } = this.state;

		return (
			<div style={ this.styles.header }>
				<ContentAreaTopBar
					batch={ this.getBatchOperations() }
					prefixButtons={ this.getHeaderPrefixButtons() }
					search={{
						filterText: this.props.search,
						onSearchChange: this.handleSearchChange
					}}
				/>
				<AdminUsersTable selectModeOn={ selectModeOn } />
			</div>
		);
	}

	getBatchOperations() {
		if (!hasPermission("users.any.delete")) {
			return;
		}

		const { selectedUsers, users } = this.props;
		const numSelected = selectedUsers.length;
		const batchButtons = [
			{
				disabled: numSelected === users.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular",
				onClick: this.selectAllUsers
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular",
				onClick: this.deselectAllUsers
			},
			{
				disabled: !numSelected,
				label: "Delete Users",
				icon: "trash",
				iconWeight: "regular",
				onClick: this.confirmDeleteUsers,
				type: "danger"
			}
		];

		return {
			active: this.state.selectModeOn,
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Users",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	confirmDeleteUsers () {
		Notifications.confirm(
			"Delete Selected Users",
			"Are you sure you want to delete the selected users?",
			"Yes",
			"No",
			this.handleBulkDelete
		);
	}

	getHeaderPrefixButtons() {
		return [ {
			icon: "user-plus",
			onClick: this.showModal,
			style: this.styles.buttonStyle,
			children: "Add User",
			className: "primary-button"
		} ];
	}

	showModal() {
		this.props.showModal();
	}

	toggleSelectMode() {
		this.setState(prevState => ({ selectModeOn: !prevState.selectModeOn }));
		this.props.selectUsers([]);
	}

	handleBulkDelete() {
		let userUuids = this.props.users.map((u) => {
			return u.uuid
		});
		this.props.selectedUsers.forEach((u) => {
			let user = this.props.users[userUuids.indexOf(u)];
			this.props.deleteUser(user);
		});

		this.deselectAllUsers();
	}

	handleSearchChange(value: string) {
		this.props.setSearch(value);
	}

	updateUserData(changedUserData: IUser) {
		this.setState((prevState) => {
			return update(prevState, {
				createUserData: { $set: changedUserData }
			});
		});
	}

	handleUserSelection(selection: any[]) {
		this.props.selectUsers(selection);
	}

	selectAllUsers() {
		this.handleUserSelection(this.props.users.map((user) => user.uuid));
	}

	deselectAllUsers() {
		this.handleUserSelection([]);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);
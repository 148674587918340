import { Input } from "antd";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";

const { Search } = Input;

export interface ISearchBarProps {
	filterText: string;
	onSearchChange: (value: string) => void;
	title?: string;
}

export default class SearchBar extends React.PureComponent<ISearchBarProps, any> {
	constructor(props: ISearchBarProps) {
		super(props);

		this.styles = {
			container: {
				flex: "1 0 auto",
				maxWidth: 200,
				marginLeft: 10
			},
			search: {
				borderRadius: 6,
				width: 200
			}
		}
		this.handleChange = this.handleChange.bind(this);
	}

	styles: CustomCSS;

	handleChange(e: React.FormEvent<HTMLInputElement>) {
		this.props.onSearchChange(e.currentTarget.value);
	}

	render() {
		const { title, filterText } = this.props;
		const { container, search } = this.styles;

		return (
			<span style={ container }>
				<style>
					{`
						.ant-input-search.ant-input-affix-wrapper .ant-input {
							height: 28px;
						}
						.ant-input-affix-wrapper.search-bar .ant-input-suffix {
							height: 12px !important;
						}
					`}
				</style>
				<Search
					key="search"
					className="search-bar"
					type="text"
					placeholder={ title ? title : "Search..." }
					value={ filterText }
					onChange={ this.handleChange }
					style={ search }
				/>
			</span>
		)
	}
}
import { ButtonType } from "antd/lib/button/button";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { push } from "react-router-redux";
import * as update from "immutability-helper";

import { ActiveUuidRoute, AdMediaLink, Context, CustomCSS,
	IAd, IAdTemplate, ICompany, IMedia, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import AdBuilder from "Components/Ads/AdBuilder";
import AdsContentArea from "Components/Ads/AdsContentArea";
import AdsPagePropertiesPanel from "Components/Ads/AdsPagePropertiesPanel";
import AdsPageSidebar from "Components/Ads/AdsPageSidebar";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import { IconWeights } from "Components/Global/Icon";
import { RequestNameTypes } from "Components/Global/RequestNameModal";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import { deleteAdAsync, setAdsAsync } from "Data/Actions/AdsAsync";
import { setMediaAsync } from "Data/Actions/MediaAsync";
import { setRequestNameModal as setRequestNameModalState, setTemplatesListModal } from "Data/Actions/UI/Modals";
import { setActiveFilters, setActiveSelection, setActiveSort, setActiveTags, setActiveUuid } from "Data/Actions/UI";
import {
	createNewActiveAd,
	getNewActiveAd,
	resetAdBuilder as resetBuilder,
	saveActiveAd,
	setActiveAd
} from "Data/Actions/UI/AdBuilder";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveAd } from "Data/Selectors/AdBuilder";
import { getActiveAd as getActiveAdFromCollection, getAdTags, getFilteredSortedTaggedAds } from "Data/Selectors/Ads";
import { getActiveCompany } from "Data/Selectors/Company";
import { getMediasByIds } from "Data/Selectors/Media";
import { getActiveFilters, getActiveSelection, getActiveSorts, getActiveTags, getActiveUuid } from "Data/Selectors/UI";
import { isEqual } from "lodash";

const { ANALYTICS, MODAL, PAGE } = Context;
const getContext = (props: AdsPageProps) => props.context || PAGE;

const mapStateToProps = (state) => {
	const activeAdUuid = getActiveUuid(state, "ads");
	const activeAd = getActiveAd(state); // adBuilder ui state ad
	const adFromCollection = getActiveAdFromCollection(state, activeAdUuid); // ads state ad
	const adMedia = activeAd ? activeAd.layout.media : [];
	const media = activeAdUuid ? adMedia.map(({ mediaId }) => mediaId) : [];
	const activeTags = getActiveTags(state, "ads");
	const adTags = getAdTags(state);
	const selectedAds = getActiveSelection(state, "ads");
	const sort = getActiveSorts(state, Sorts.ADS);
	const { filter } = getActiveFilters(state, Filters.ADS);
	const ads = getFilteredSortedTaggedAds(state, {
		sort: sort as SortTypes,
		filter: filter as string,
		tags: activeTags
	});

	return {
		ads,
		activeAd,
		activeAdUuid,
		activeCompany: getActiveCompany(state),
		adFromCollection,
		activeTags,
		adMedia,
		adTags,
		adsSortType: getActiveSorts(state, Sorts.ADS),
		filter,
		media: getMediasByIds(state, media),
		selectedAds
	};
};

const mapDispatchToProps = (dispatch) => ({
	sort: (sort: SortTypes) => dispatch(setActiveSort(Sorts.ADS, sort)),
	showTemplatesListModal: () => dispatch(setTemplatesListModal(true)),
	createAd: (ad: IAd) => dispatch(createNewActiveAd(ad)),
	fetchAd: (uuid: string) => dispatch(getNewActiveAd(uuid)),
	fetchMediaForAds: () => dispatch(setMediaAsync()),
	resetActiveUuid: () => dispatch(setActiveUuid("ads", "")),
	resetAdBuilder: () => dispatch(resetBuilder()),
	setActiveAdBuilderAd: (ad: IAd) => dispatch(setActiveAd(ad)),
	saveAndUpdateActiveAd: (ad: IAd) => dispatch(saveActiveAd(ad)),
	setAdTags: (tags: string[]) => dispatch(setActiveTags("ads", tags)),
	setFilter: (query: string) => dispatch(setActiveFilters("filter", Filters.ADS, query)),
	tryFetchNextAds: () => dispatch(setAdsAsync()),
	selectAds: (adUuids: string[]) => dispatch(setActiveSelection("ads", adUuids)),
	showRequestNameModal: (template: IAdTemplate) =>
		dispatch(setRequestNameModalState(true, RequestNameTypes.AD, template)),
	deleteAd: (ad: IAd) => dispatch(deleteAdAsync(ad)),
	pushToAdsPage: (state?: { selectModeOn: boolean }) => dispatch(push({
		pathname: "/ads",
		state
	}))
});

interface AdsPageProps extends RouteComponentProps<ActiveUuidRoute> {
	activeAd: IAd;
	activeAdUuid: string;
	activeCompany: ICompany;
	activeTags: string[];
	adFromCollection: IAd;
	adMedia: AdMediaLink[];
	ads: IAd[];
	adsSortType: SortTypes;
	adTags: string[];
	context?: Context;
	createAd: (ad: IAd) => void;
	fetchMediaForAds: () => void;
	fetchAd: (uuid: string) => Promise<void>;
	filter: string;
	media: IMedia[];
	onAdSelected?: (ad: IAd) => void; // this prop is used in the case of a modal ad context
	onCloseModal?: () => void;
	pushToAdsPage: (state?: { selectModeOn: boolean }) => void;
	resetActiveUuid: () => void;
	resetAdBuilder: () => void;
	saveAndUpdateActiveAd: (ad: IAd) => void;
	setActiveAdBuilderAd: (ad: IAd) => void;
	setAdTags: (tags: string[]) => void;
	setFilter: (query: string) => void;
	setSort: (sort: SortTypes) => void;
	showTemplatesListModal: () => void;
	showRequestNameModal: (template: IAdTemplate) => void;
	tryFetchNextAds: () => void;
	selectAds: (adUuids: string[]) => void;
	deleteAd: (ad: IAd) => void;
	selectedAds: string[];
}

interface AdsPageState {
	selectModeOn: boolean;
	agreeToLeave: boolean;
}

class AdsPage extends React.Component<AdsPageProps, AdsPageState> {
	constructor(props: AdsPageProps) {
		super(props);

		this.state = {
			selectModeOn: false,
			agreeToLeave: false
		};

		this.agreeToLoseChanges = false;

		this.styles = {
			bottomButton: {
				marginBottom: 10
			}
		};

		this.createNewAd = this.createNewAd.bind(this);
		this.handleDeleteAds = this.handleDeleteAds.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.confirmLeaving = this.confirmLeaving.bind(this);
		this.routerWillLeave = this.routerWillLeave.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.deleteAds = this.deleteAds.bind(this);
		this.deselectAll = this.deselectAll.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
	}

	defaultAdName = "Untitled Ad";
	isCreating = false;
	adBuilderDropTarget: Element;
	collapsiblePanelWidth = 250;
	propertiesPanel: any;

	agreeToLoseChanges: boolean;
	unblock: () => any;

	styles: CustomCSS;

	componentDidMount() {
		const {
			activeAd, activeAdUuid, adMedia, fetchAd, fetchMediaForAds, pushToAdsPage, history, media
		} = this.props;

		if (activeAdUuid && !activeAd) {
			fetchAd(activeAdUuid)
				.catch((err) => pushToAdsPage());
		}

		// history.block will check if there are unsaved changes
		// note we are able to do this because AdsPage is rendered as a Route component
		if (history && !this.unblock) {
			this.unblock = history.block(this.routerWillLeave);
		}

		if (activeAdUuid && adMedia.length !== media.length) {
			fetchMediaForAds();
		}
	}

	componentDidUpdate(prevProps: AdsPageProps) {
		const { activeAd, adFromCollection, activeAdUuid, activeCompany,
			setActiveAdBuilderAd, tryFetchNextAds } = this.props;

		if (activeCompany.uuid !== prevProps.activeCompany.uuid) {
			tryFetchNextAds();
		}

		if (activeAd && activeAdUuid && (activeAd.uuid !== activeAdUuid)) {
			setActiveAdBuilderAd(adFromCollection);
		}
	}

	componentWillUnmount() {
		const { activeAdUuid, location, resetAdBuilder } = this.props;
		if (this.unblock) {
			this.unblock();
			if (activeAdUuid && location.pathname.indexOf("ads") === -1) {
				resetAdBuilder();
			}
		}
	}

	render() {
		if (!hasPermission(PERMISSIONS.ADS_MANAGE)) {
			return null;
		}

		return (
			<React.Fragment>
				<ThreeColumnLayout
					leftContent={this.renderLeftContent()}
					centerContent={this.renderCenterContent()}
					rightContent={this.renderRightContent()}
				/>
			</React.Fragment>
		);
	}

	renderCenterContent() {
		const { activeAd,
			activeAdUuid,
			activeTags,
			adTags,
			adsSortType,
			context,
			onAdSelected,
			onCloseModal,
			setAdTags
		} = this.props;

		if (activeAdUuid && activeAd && ![ ANALYTICS, MODAL ].includes(context || {} as Context)) {
			// If we have an ad render the ad builder
			return (
				<AdBuilder />
			);
		}

		return (
			<React.Fragment>
				<ContentAreaTopBar
					batch={ this.getBatchOperations() }
					closeButton={onCloseModal}
					search={{
						filterText: this.props.filter,
						onSearchChange: this.handleSearchChange
					}}
					sort={{
						dataType: Sorts.ADS
					}}
					tags={{
						activeTags: activeTags,
						tags: adTags,
						tagSelectChange: setAdTags,
						tagType: "ads"
					}}
				/>
				<AdsContentArea
					key={ adsSortType }
					bulkSelectActive={ this.state.selectModeOn }
					context={ getContext(this.props) }
					onAdSelected={ onAdSelected }
					onCreateNewAd={ this.createNewAd }
				/>
			</React.Fragment>
		);
	}

	renderLeftContent() {
		if (this.hideSidebar()) {
			return undefined;
		}

		return (
			<AdsPageSidebar onCreateNewAd={ this.createNewAd } />
		);
	}

	renderRightContent() {
		if (this.hideSidebar()) {
			return undefined;
		}

		return (
			<AdsPagePropertiesPanel />
		);
	}

	createNewAd() {
		this.props.showTemplatesListModal();
	}

	getBatchOperations() {
		if (getContext(this.props) !== PAGE) {
			return undefined;
		}

		const { ads, selectedAds } = this.props;
		const numSelected = selectedAds.length;

		const batchButtons = [
			{
				disabled: numSelected === ads.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.selectAll
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.deselectAll
			},
			{
				disabled: !numSelected,
				label: "Delete Ads",
				icon: "trash",
				iconWeight: "regular" as IconWeights,
				onClick: this.handleDeleteAds,
				type: "danger" as ButtonType
			}
		];

		return {
			active: this.state.selectModeOn,
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Ads",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	selectAll() {
		const { ads, selectAds } = this.props;
		selectAds(ads.map((ad) => ad.uuid));
	}

	deselectAll() {
		this.props.selectAds([]);
	}

	handleDeleteAds() {
		Notifications.confirm(
			"Delete selected ads?",
			"Are you sure you want to delete the selected ads?",
			"Delete",
			"Cancel",
			this.deleteAds
		);
	}

	deleteAds() {
		const { selectedAds, ads, deleteAd } = this.props;
		const adsToDelete: IAd[] = ads.filter((ad) => {
			return selectedAds.includes(ad.uuid);
		});

		adsToDelete.forEach(deleteAd);
		this.toggleSelectMode();
	}

	toggleSelectMode() {
		const { selectAds } = this.props;
		selectAds([]);
		this.setState(prevState => ({ selectModeOn: !prevState.selectModeOn }));
	}

	handleSearchChange(value: string) {
		this.props.setFilter(value);
	}

	hideSidebar() {
		const { activeAd, activeAdUuid } = this.props;
		const noAd = !(activeAdUuid && activeAd);
		const isModal = getContext(this.props) !== PAGE;

		return (noAd || isModal);
	}

	routerWillLeave(nextLocation: any): false | void {
		const { activeAdUuid, activeAd, adFromCollection, location } = this.props;
		const getRootPath = (pathname: string) => pathname.split("/").filter(Boolean)[0];
		const locationChanged = getRootPath(location.pathname) !== getRootPath(nextLocation.pathname);
		const adIsChanged = !isEqual(activeAd, adFromCollection);

		if (!activeAdUuid || !adIsChanged) {
			return undefined;
		}
		// note this prompt/code path only applies to when a user navigates away via
		// a route. For other saved changes checking, see the AdsList and TemplatesListModal
		if (locationChanged && adIsChanged && !this.state.agreeToLeave) {
			this.confirmLeaving(nextLocation);
			return false;
		}

		return undefined;
	}

	confirmLeaving(nextLocation: any) {
		Notifications.confirm("You have unsaved changes.",
			"The current ad has never been saved. Leaving this page will result in losing any changes.",
			"Save Ad", "Lose Changes",
			() => {
				this.updateAd();
				this.setState((prevState) => {
					return update(prevState, {
						agreeToLeave: { $set: true }
					});
				}, this.leaveCallback(nextLocation));
			},
			() => {
				this.setState((prevState) => {
					return update(prevState, {
						agreeToLeave: { $set: true }
					});
				}, this.leaveCallback(nextLocation));
			})
	}

	leaveCallback(nextLocation: any) {
		return () => {
			this.props.history.push(nextLocation);
		}
	}

	setRef(name: string) {
		return (ref) => {
			if (ref) {
				this[name] = ref;
			}
		};
	}

	updateAd() {
		const { activeAd, saveAndUpdateActiveAd } = this.props;

		saveAndUpdateActiveAd(activeAd);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsPage);

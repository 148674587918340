import { AspectRatios, ComponentType, IAdTemplate, IBanner, IBaseComponent, IComponentDimension,
	IFeedComponent, IImageComponent, ISlideshowComponent, ITickerComponent,
	IVideoComponent, NameUuid, SlideshowAnimations } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { AdColors, MinimumComponentHeight, RatioHeightPercents } from "Components/Ads/Constants";
import { Colors } from "Components/Global/Constants";
import { cloneDeep } from "lodash";

export class AdTemplate implements IAdTemplate {
	constructor() {
		this.components = [];
		this.name = "";
		this.orientation = "portrait";
	}

	company: string;
	components: BaseComponent[];
	createdAt: string;
	createdBy?: NameUuid;
	name: string;
	orientation: "portrait" | "landscape";
	thumbnail: string;
	updatedAt: string;
	updatedBy?: NameUuid;
	uuid: string;
}

export const maxTickerHeightPercent = (300 / 1920) * 100;

export class ComponentDimension implements IComponentDimension {
	from?: string;
	units: "pixel" | "percent";
	value: number;
}

export abstract class BaseComponent implements IBaseComponent {
	constructor(type: ComponentType) {
		this.height = new ComponentDimension();
		this.left = new ComponentDimension();
		this.top = new ComponentDimension();
		this.type = type;
		this.width = new ComponentDimension();
	}

	height: ComponentDimension;
	id: string;
	left: ComponentDimension;
	top: ComponentDimension;
	type: ComponentType;
	width: ComponentDimension;
	zIndex: number;

	static clone(component: BaseComponent) {
		return cloneDeep(component);
	}
}

export const SlideshowDirections = {
	UP: {
		name: "up",
		symbol: "↑"
	},
	RIGHT: {
		name: "right",
		symbol: "→"
	},
	DOWN: {
		name: "down",
		symbol: "↓"
	},
	LEFT: {
		name: "left",
		symbol: "←"
	}
};
export const SlideshowDurations = { SHORTEST: 5, SHORT: 10, LONG: 15, LONGEST: 30 };
export const SlideshowTransitionDurations = { LONGEST: 2, SHORTEST: .5 };

export class SlideshowComponent extends BaseComponent implements ISlideshowComponent {
	constructor() {
		super("slideshow");

		SlideshowComponent.setDefaults(this);
	}

	animation: SlideshowAnimations;
	direction: string;
	durationPerSlide: number;
	transitionLength: number;

	static setDefaults(slideshowComponent: SlideshowComponent) {
		slideshowComponent.animation = SlideshowAnimations.FADE;
		slideshowComponent.direction = SlideshowDirections.LEFT.name;
		slideshowComponent.durationPerSlide = SlideshowDurations.SHORTEST;
		slideshowComponent.transitionLength = SlideshowTransitionDurations.LONGEST;
	}
}

export class TickerComponent extends BaseComponent implements ITickerComponent {
	constructor() {
		super("ticker");

		TickerComponent.setDefaults(this);
	}

	background: string;
	foreground: string;
	messages: string[];
	scroll: "left" | "right" | "fixed";
	speed: number;
	bold: boolean;
	italic: boolean;

	static setDefaults(tickerComponent: TickerComponent) {
		tickerComponent.background = AdColors.ticker.background;
		tickerComponent.foreground = Colors.white;
		tickerComponent.messages = [ "" ];
		tickerComponent.scroll = "left";
		tickerComponent.speed = 3;
		tickerComponent.bold = false;
		tickerComponent.italic = false;
	}
}

export class ImageComponent extends BaseComponent implements IImageComponent {
	constructor() {
		super("image");
	}

	backgroundColor: string;
	lock: string;
	scale: number;
}

export class VideoComponent extends BaseComponent implements IVideoComponent {
	constructor() {
		super("video");
		VideoComponent.setDefaults(this);
	}

	aspectRatio: AspectRatios;

	static setDefaults(videoComponent: VideoComponent) {
		videoComponent.aspectRatio = AspectRatios.SIXTEEN_NINE;
	}
}

export class FeedComponent extends BaseComponent implements IFeedComponent {
	constructor() {
		super("feed");

		FeedComponent.setDefaults(this);
	}

	// aspectRatio is no longer required by the Android app, but we
	// use it in the web app to enable exact aspect ratio selection
	aspectRatio: AspectRatios;
	banner: IBanner | null;

	static setDefaults(feedComponent: FeedComponent) {
		feedComponent.aspectRatio = AspectRatios.ONE_ONE;
	}
}

export const TemplateComponentFactory = {
	// generates a the appropriate component for the current
	// custom banded layout with default heights per type
	generate(type: string) {
		let component;
		switch (type) {
			case "ticker":
				component = new TickerComponent();
				component.height.value = MinimumComponentHeight;
				break;
			case "image":
				component = new ImageComponent();
				component.height.value = MinimumComponentHeight;
				break;
			case "video":
				component = new VideoComponent();
				component.height.value = RatioHeightPercents.sixteenByNine;
				break;
			case "feed":
				component = new FeedComponent();
				component.height.value = RatioHeightPercents.oneByOne;
				component.banner = null;
				break;
			case "slideshow":
				component = new SlideshowComponent();
				component.height.value = MinimumComponentHeight;
				break;
		}

		component.id = Utils.getGuid();
		component.top = {
			units: "percent",
			value: 0
		};
		component.left = {
			units: "percent",
			value: 0
		};
		component.width = {
			units: "percent",
			value: 100
		};
		component.height.units = "percent";

		return component;
	}
}

const t1FirstComponentId = "ed1e8213-e6de-4e02-b499-bc04fb5ae915";

const t1 = new AdTemplate();
t1.uuid = "c41e4145-f078-4b18-b3c2-2061f1f98cf9";
t1.name = "Banded 1";
t1.thumbnail = "/img/template1.jpg";
t1.components = [ {
	id: t1FirstComponentId,
	zIndex: 0,
	type: "image",
	top: {
		units: "percent",
		value: 0
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: 6.55875
	}
}, {
	id: "582a09b8-859d-45a7-a6a5-674eecb73bbe",
	zIndex: 1,
	type: "video",
	aspectRatio: AspectRatios.SIXTEEN_NINE,
	top: {
		units: "percent",
		value: 6.55875
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: RatioHeightPercents.sixteenByNine
	}
} as VideoComponent, {
	id: "f8642fbf-3b63-4ffe-bf37-7763eab675e8",
	zIndex: 2,
	type: "ticker",
	top: {
		units: "percent",
		value: 38.199375
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: 5.550625
	},
	messages: [ "" ],
	scroll: "left",
	speed: 3,
	foreground: Colors.white,
	background: AdColors.ticker.background
} as TickerComponent, {
	id: "f54a4659-c2c9-4434-aab4-83f90854a1ea",
	zIndex: 3,
	type: "feed",
	top: {
		units: "percent",
		value: 43.75
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: 56.25
	},
	aspectRatio: "1:1",
	banner: null
} as FeedComponent ];

const t2FirstComponentId = "d9e4a0f0-d5ba-4c5c-bdcd-24484d325644";

const t2 = new AdTemplate();
t2.uuid = "2a8315d1-a17e-4d94-ac3d-d17c73c8af38";
t2.name = "Banded 2";
t2.thumbnail = "/img/template2.jpg";
t2.components = [ {
	id: t2FirstComponentId,
	zIndex: 0,
	type: "image",
	top: {
		units: "percent",
		value: 0
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: 7.94
	}
}, {
	id: "d59986d2-6a57-474c-b421-2c815ab1d39f",
	zIndex: 1,
	type: "video",
	aspectRatio: AspectRatios.SIXTEEN_NINE,
	top: {
		units: "percent",
		value: 7.94
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: RatioHeightPercents.sixteenByNine
	}
} as VideoComponent, {
	id: "d20f0a0b-1c8a-4ffe-bbab-11a1b902948e",
	zIndex: 2,
	type: "image",
	top: {
		units: "percent",
		value: 39.58
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: 22.22
	}
}, {
	id: "f54a4659-c2c9-4434-aab4-83f90854a1ea",
	zIndex: 3,
	type: "feed",
	top: {
		units: "percent",
		value: 61.8
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: RatioHeightPercents.sixteenByNine
	},
	aspectRatio: AspectRatios.SIXTEEN_NINE,
	banner: null
} as FeedComponent, {
	id: "0e8ef76c-7b53-4632-8a8e-8c72941f6339",
	zIndex: 4,
	type: "ticker",
	top: {
		units: "percent",
		value: 93.44
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: 6.55875
	},
	messages: [ "" ],
	scroll: "left",
	speed: 3,
	foreground: Colors.white,
	background: AdColors.ticker.background
} as TickerComponent ];

const t3FirstComponentId = "0294e779-b7ac-4aef-bee8-35cdd341b37d";

const t3 = new AdTemplate();
t3.uuid = "9b886c5d-9179-458b-b0b7-6dfbcffe134a";
t3.name = "Full Video";
t3.thumbnail = "/img/template3.jpg";
t3.components = [ {
	id: t3FirstComponentId,
	zIndex: 0,
	aspectRatio: AspectRatios.NINE_SIXTEEN,
	type: "video",
	top: {
		units: "percent",
		value: 0
	},
	left: {
		units: "percent",
		value: 0
	},
	width: {
		units: "percent",
		value: 100
	},
	height: {
		units: "percent",
		value: 100
	}
} as VideoComponent ];

export const DefaultTemplates = [ t1, t2, t3 ];

export function recalculateComponentDimensions(components: BaseComponent[]) {
	return components
		// somehow we end up with empty objects once in awhile in the components array, that need to be filtered
		.filter((component) => component.hasOwnProperty("type"))
		.map((c, i, arr) => {
			if (i === 0) {
				c.top.value = 0;
			} else {
				const prevContent = arr[i - 1];
				c.top.value = prevContent.top.value + prevContent.height.value;
			}

			return c;
		});
}
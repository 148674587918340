import * as React from "react";
import { connect as connectToRedux } from "react-redux";

import { AnalyticsDataResult, AnalyticsResult, CustomCSS, IAd } from "@connect/Interfaces";
import { Icon, Loader } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { getActiveAd, getActiveAnalyticsFetching, getActiveAnalyticsFilters, getActiveAnalyticsResult,
	getActiveAnalyticsUuid, getActiveFilteredAnalyticsData } from "Data/Selectors/AdAnalytics";

const { primaryBlue, offWhite } = Colors;

export const mapStateToProps = (state) => {
	const data = getActiveFilteredAnalyticsData(state);

	return {
		ad: getActiveAd(state),
		data,
		range: getActiveAnalyticsFilters(state).dateRange,
		result: getActiveAnalyticsResult(state),
		activeUuid: getActiveAnalyticsUuid(state),
		disabled: !data.length,
		loading: getActiveAnalyticsFetching(state)
	};
};

export interface ISuperAnalyticsChartProps {
	ad: IAd;
	data: AnalyticsDataResult[];
	range: string;
	result: AnalyticsResult;
	icon: string;
	title: string;
	activeUuid: string;
	disabled: boolean;
	loading: boolean;
	showText: boolean;
}

export interface ISuperAnalyticsChartState {
}

export class SuperAnalyticsChart<T extends ISuperAnalyticsChartProps, S extends ISuperAnalyticsChartState>
	extends React.Component<T, S> {
	constructor(props: T) {
		super(props);

		this.styles = {
			chart: {
				display: "flex",
				flexDirection: "column",
				boxShadow: "0 4px 8px 0 #F3F3F3, 0 6px 20px 0 #F9F9F9",
				overflow: "hidden",
				position: "relative",
				width: "100%",
				userSelect: "none"
			},
			chartContainer: {
				height: "80%",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			content: {
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			description: {
				fontWeight: 700
			},
			disabled: {
				position: "absolute",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
				fontWeight: 600,
				minHeight: 120,
				zIndex: 10,
				width: "100%",
				height: "100%",
				background: "rgba(200, 200, 200, 0.1)",
				top: 0,
				pointerEvents: "none"
			},
			heading: {
				display: "flex",
				alignItems: "center",
				fontSize: "1.4em",
				fontWeight: 500,
				margin: 10
			},
			iconStyle: {
				marginRight: 6,
				color: primaryBlue
			},
			loading: {
				height: "100%",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				top: 0,
				pointerEvents: "none",
				position: "absolute",
				zIndex: 20
			},
			loadingIcon: {
				marginLeft: 6
			},
			loadingIndicator: {
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				zIndex: 100,
				fontWeight: 600,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center"
			},
			main: {
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column"
			},
			statBlock: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			},
			statistic: {
				fontSize: "1.5em",
				fontWeight: 700,
				marginBottom: -3
			},
			statsContainer: {
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				marginBottom: 10
			},
			viewButton: {
				padding: 10,
				border: `1px solid ${ offWhite }`
			},
			views: {
				display: "flex",
				position: "absolute",
				top: 10,
				right: 10
			},
			wrapper: {
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%"
			}
		};

		this.renderContainer = this.renderContainer.bind(this);
	}

	icon: string;
	name: string;
	styles: CustomCSS;

	render() {
		// We throw here to prevent rendering the SuperAnalyticsChart directly, but we must return a <div />
		// so the extending components can properly type their render methods.
		throw new Error("WARNING: SuperAnalyticsChart#render must be overridden in the extending component.");
		return <div />;
	}

	renderContainer(child: React.ReactChild) {
		const { content, wrapper } = this.styles;
		const wrappedContent = this.renderDisabled(
			<div style={ wrapper }>
				{ this.renderLoadingNotification() }
				<div style={{
					...wrapper,
					filter: this.props.loading ? "blur(5px)" : null
				}}>

					{ this.renderHeading() }
					<div style={ content }>
						{ child }
					</div>
				</div>
			</div>
		);

		return (
			<div style={ this.styles.chart }>
				{ wrappedContent }
			</div>
		);
	}

	renderDisabled(content: React.ReactChild) {
		const { disabled, loading, showText } = this.props;

		if (!disabled || loading) {
			return content;
		}

		const text = !showText ? null : (
			<React.Fragment>
				There does not appear to be any data available for this report.<br />
				Please try your request again later or change your filters.
			</React.Fragment>
		);

		return (
			<div style={ this.styles.disabled }>
				{ text }
			</div>
		);
	}

	renderLoadingNotification() {
		const { loading, showText } = this.props;
		const { loadingIcon, loadingIndicator } = this.styles;

		if (!loading) {
			return null;
		}

		const text = !showText ? null : "Updating your stats. This may take a moment...";

		return (
			<div style={ loadingIndicator }>
				{ text }
				<div style={ loadingIcon }>
					<Loader size="small" />
				</div>
			</div>
		);
	}

	renderHeading() {
		const { heading, iconStyle } = this.styles;
		const { icon, title } = this.props;

		return (
			<div style={ heading }>
				<Icon
					name={ icon }
					size="smallest"
					iconWeight="light"
					style={ iconStyle }
				/>
				{ title }
			</div>
		);
	}
}

export default connectToRedux(mapStateToProps)(SuperAnalyticsChart);
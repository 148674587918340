import { ApkVersion, IAdminApi, ICompany, UpdateResult } from "@connect/Interfaces";
import { DELETE, GET, POST } from "Api/V1/CRUD";

const getCompanyUrl = (uuid: string) => `/admin/company/${uuid}`;

export default class AdminApi implements IAdminApi {
	deleteCompany(company: ICompany) {
		return new Promise<any>((resolve, reject) => {
			DELETE(getCompanyUrl(company.uuid), {}, true)
				.then(resolve, reject);
		});
	}

	assignIntegrators(company: ICompany, integrators: string[]) {
		return POST(`/company/${company.uuid}/integrators`, {integrators}, true) as Promise<UpdateResult>;
	}

	assignIntegrationTeams(company: ICompany, teams: string[]) {
		return POST(`/company/${company.uuid}/integrations?action=sync`, {teams}, true) as Promise<UpdateResult>;
	}

	bulkCheckInDevices(devices: string) {
		return POST("/admin/device/import", devices, true, true) as Promise<null>;
	}

	getApkVersions() {
		return GET("/admin/app/releases?limit=5", true) as Promise<ApkVersion[]>;
	}

	createApkVersion(apkVersion: ApkVersion) {
		return POST("/admin/app/releases", apkVersion, true) as Promise<ApkVersion>;
	}

	publishApkVersion(apkVersion: ApkVersion) {
		return POST(`/admin/app/releases/${apkVersion.id}/publish`, {}, true) as Promise<null>;
	}

}

import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { CustomCSS, IPlaylist, IUser } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { ButtonTypes } from "Components/Global/Button";
import { Input } from "Components/Global/Common";
import { IconWeights } from "Components/Global/Icon";
import PropertiesPanel, { PropertiesPanelButton } from "Components/Global/PropertiesPanel";
import PlaylistActionSetView from "Components/Playlists/PlaylistActionSetView";
import { replaceWithoutRender } from "Data/Actions/Navigation";
import { createPlaylistAsync, deletePlaylistAsync, updatePlaylistAsync } from "Data/Actions/Playlists";
import { DefaultMaxLength } from "Data/Objects/Global";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import inputValidation from "Data/Objects/Validation";
import { getActivePlaylist } from "Data/Selectors/Playlists";
import { getActiveUuid } from "Data/Selectors/UI";
import { getCurrentUser } from "Data/Selectors/User";
import { cloneDeep } from "lodash";

interface PlaylistPropertiesPanelProps {
	activeUuid: string;
	createPlaylist: (playlist: IPlaylist) => Promise<IPlaylist>;
	deletePlaylist: (playlist: IPlaylist) => Promise<void>;
	deployPlaylist: (playlist: IPlaylist) => void;
	haveAds: boolean;
	playlist: IPlaylist;
	pushToPlaylistsPage: () => void;
	setActivePlaylist: (id: string) => void;
	updatePlaylist: (playlist: IPlaylist) => void;
	user: IUser;
}

const mapStateToProps = (state, ownProps) => {
	const activeUuid = getActiveUuid(state, "playlists");
	const playlist = getActivePlaylist(state)
	const user = getCurrentUser(state);

	return ({
		activeUuid,
		haveAds: playlist ? !!playlist.ads.length : false,
		playlist,
		user
	});
};

const mapDispatchToProps = (dispatch) => ({
	createPlaylist: (playlist: IPlaylist) => dispatch(createPlaylistAsync(playlist, true)),
	deletePlaylist: (playlist: IPlaylist) => dispatch(deletePlaylistAsync(playlist)),
	deployPlaylist: (playlist: IPlaylist) => dispatch(push({
		pathname: "/deploy",
		state: {
			deployment: {
				deploymentType: "schedule",
				schedule: playlist,
				scheduleType: "playlist"
			}
		}
	})),
	pushToPlaylistsPage: () => dispatch(push("/playlists")),
	setActivePlaylist: (id: string) => dispatch(replaceWithoutRender(`/playlists/${id}`)),
	updatePlaylist: (playlist: IPlaylist) => dispatch(updatePlaylistAsync(playlist))
});

export class PlaylistPropertiesPanel extends React.Component<PlaylistPropertiesPanelProps> {
	constructor(props: PlaylistPropertiesPanelProps) {
		super(props);

		this.styles = {
			row: {
				marginBottom: 14,
				display: "flex",
				justifyContent: "space-between"
			}
		};

		this.copyPlaylist = this.copyPlaylist.bind(this);
		this.deleteAllAds = this.deleteAllAds.bind(this);
		this.savePlaylist = this.savePlaylist.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { activeUuid, playlist } = this.props;
		if (!activeUuid || !playlist) {
			return null;
		}

		return (
			<PropertiesPanel
				actions={this.getAvailableActions()}
				properties={this.renderContent()}
				title="Playlist Properties"
				key={ playlist.actionSet ? playlist.actionSet.uuid : "properties" }
			/>
		);
	}

	renderContent() {
		const properties = [
			this.renderTitleInput(),
			this.renderPlaylistDuration(),
			this.renderTotalAds(),
			this.renderCreatedBy(),
			this.renderDateCreated(),
			this.renderLastModified(),
			this.renderActionSetView()
		];

		return properties;
	}

	renderCreatedBy() {
		const { createdBy } = this.props.playlist
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Created By:</div>
				<span>
					{ createdBy && createdBy.name }
				</span>
			</div>
		);
	}

	renderDateCreated() {
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Date Created:</div>
				<span>
					{ Utils.getHumanReadableDate(this.props.playlist.createdAt) }
				</span>
			</div>
		);
	}

	renderLastModified() {
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Last Modified:</div>
				<span>
					{ Utils.getHumanReadableDate(this.props.playlist.updatedAt) }
				</span>
			</div>
		);
	}

	renderPlaylistDuration() {
		const { ads, uuid } = this.props.playlist;
		const duration = ads.length ? ads.map(ad => Number(ad.duration)).reduce((a, b) => a + b) : 0;

		return (
			<div key={uuid + "_duration"} style={this.styles.row}>
				<div>
					Duration:
				</div>
				<div>
					{Utils.getHumanReadableDuration(duration)}
				</div>
			</div>
		);
	}

	renderTitleInput() {
		const { name, uuid } = this.props.playlist;

		return (
			<div key={uuid + "_title"}>
				Title:
				<Input
					id={uuid}
					maxLength={ DefaultMaxLength }
					saveCallback={this.savePlaylist}
					validator={ inputValidation.name }
					value={name}
				/>
			</div>
		);
	}

	renderTotalAds() {
		const { ads, uuid } = this.props.playlist;

		return (
			<div key={uuid + "_total"} style={this.styles.row}>
				<div>
					Total Ads:
				</div>
				<div>
					{ads.length}
				</div>
			</div>
		);
	}

	renderActionSetView() {
		return (
			<React.Fragment>
				<div>
					Playlist Action Set:
				</div>
				<PlaylistActionSetView key={ this.props.playlist.actionSet } />
			</React.Fragment>
		);
	}

	copyPlaylist() {
		const { createPlaylist, playlist, setActivePlaylist, user } = this.props;
		const { uuid, name } = user;

		let newPlaylist = cloneDeep(playlist);

		newPlaylist.uuid = "";
		newPlaylist.name = Utils.copyName(newPlaylist.name);
		newPlaylist.createdBy = {
			uuid,
			name
		};

		createPlaylist(newPlaylist)
			.then((playlistCopy) => setActivePlaylist(playlistCopy.uuid));
	}

	deleteAllAds() {
		const { playlist, updatePlaylist } = this.props;

		Notifications.confirm("Are you sure you want to clear this playlist?",
			"This will remove all ads from the playlist.",
			"Clear Playlist", "Cancel",
			() => {
				let newPlaylist = cloneDeep(playlist);

				newPlaylist.ads = [];

				updatePlaylist(newPlaylist);
			});
	}

	deletePlaylist() {
		const { deletePlaylist, playlist, pushToPlaylistsPage } = this.props;

		// confirm that user wants to delete all selected playlists
		Notifications.confirm("Delete selected playlist(s)?",
			"Are you sure you want to delete the selected playlist(s)? " +
		"The playlist(s) will be removed from any deployments they may have been part of.",
			"Delete", "Cancel", () => {
				deletePlaylist(playlist).then(() => {
					pushToPlaylistsPage();
				});
			});
	}

	getAvailableActions(): PropertiesPanelButton[] {
		const { activeUuid, deployPlaylist, haveAds, playlist } = this.props;

		let actions = [
			{
				action: () => this.copyPlaylist(),
				key: `${activeUuid}_copy`,
				icon: { name: "copy", weight: "regular" as IconWeights },
				name: "Copy Playlist"
			},
			{
				action: () => this.deleteAllAds(),
				key: `${activeUuid}_clear`,
				icon: { name: "eraser", weight: "solid" as IconWeights },
				name: "Clear Playlist",
				disabled: !haveAds
			},
			{
				action: () => this.deletePlaylist(),
				key: `${activeUuid}_delete`,
				icon: { name: "trash-alt", weight: "regular" as IconWeights },
				name: "Delete Playlist",
				type: "danger" as ButtonTypes
			}
		];

		if (hasPermission(PERMISSIONS.DEPLOYMENTS_MANAGE)) {
			actions.unshift({
				action: () => deployPlaylist(playlist),
				key: `${activeUuid}_deploy`,
				icon: { name: "rocket", weight: "solid" as IconWeights },
				name: "Deploy Now",
				type: "primary" as ButtonTypes
			});
		}

		return actions;
	}

	savePlaylist(value: string) {
		const { playlist, updatePlaylist } = this.props;

		if (value === playlist.name) {
			return;
		}

		let newPlaylist = cloneDeep(playlist);
		newPlaylist.name = value;

		updatePlaylist(newPlaylist);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistPropertiesPanel);
import { Action, AdDispatch, AdListDispatch, AdUuidDispatch, AnalyticsDispatch,
	AnalyticsNotificationDispatch, AnalyticsReportDownloadingDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { AdsState } from "Data/Objects/AppState";
import {
	createReducer,
	deleteFromState,
	getIndexFromState,
	mergeWithUuidArrays,
	pushToState,
	setState,
	updateStateArray,
	updateStateArrayItem
} from "Data/Utils";
import { toggleFeature } from "@connect/Features";

export function getAdIndex(state: AdsState, uuid: string) {
	return getIndexFromState(state.ads, uuid);
}

export function getAnalyticsIndex(state: AdsState, uuid: string) {
	return getIndexFromState(state.analytics, uuid);
}

export function createAd(state: AdsState, action: Action<AdDispatch>) {
	return pushToState(state, "ads", action.args.ad);
}

export function deleteAd(state: AdsState, action: Action<AdUuidDispatch>) {
	const index = getAdIndex(state, action.args.uuid);

	if (index >= 0) {
		return deleteFromState(state, "ads", index);
	}

	return state;
}

export function resetAds(state: AdsState, action: Action<null>) {
	return setState(state, "ads", []);
}

export function resetAnalytics(state: AdsState, action: Action<null>) {
	return setState(state, "analytics", []);
}

export function setAds(state: AdsState, action: Action<AdListDispatch>) {
	if (action.args.reset) {
		return setState(state, "ads", action.args.ads);
	}

	return mergeWithUuidArrays(state, "ads", action.args.ads);
}

export function setAnalyticsReportPending(state: AdsState, action: Action<AnalyticsReportDownloadingDispatch>) {
	const { uuid, value } = action.args;
	const index = state.pendingReports.indexOf(uuid);

	if (!value) {
		if (index > -1) {
			return deleteFromState(state, "pendingReports", index);
		}
		return state;
	}

	return pushToState(state, "pendingReports", uuid);
}

export function setNotificationSettings(state: AdsState, action: Action<AnalyticsNotificationDispatch>) {
	const { settings, uuid } = action.args;
	const index = getAnalyticsIndex(state, uuid);
	const notification = Object.assign({}, settings, { uuid });
	const analytic = { uuid, user: { notification } };

	if (index === -1) {
		return pushToState(state, "analytics", analytic);
	}

	const updatedAnalytics = Object.assign({}, state.analytics[index], analytic);

	return updateStateArray(state, "analytics", index, updatedAnalytics);
}

export function updateAd(state: AdsState, action: Action<AdDispatch>) {
	const { ad } = action.args;

	if (toggleFeature("notifications", true, false)) {
		return updateAd2(state, action);
	}

	const index = getAdIndex(state, ad.uuid)

	if (index === -1) {
		return pushToState(state, "ads", ad);
	}

	return updateStateArray(state, "ads", index, ad);
}

export function updateAd2(state: AdsState, action: Action<AdDispatch>) {
	const { ad } = action.args;
	return updateStateArrayItem(state, "ads", ad);
}

export function updateAdAnalytics(state: AdsState, action: Action<AnalyticsDispatch>) {
	const { uuid, analyticsResult } = action.args;
	const index = getAnalyticsIndex(state, uuid)
	const previousResult = state.analytics[index];

	if (index === -1) {
		return pushToState(state, "analytics", analyticsResult);
	}

	// otherwise, merge our analytics results and datasets, favoring the most recent
	const merged = Object.assign({}, previousResult, analyticsResult);

	const allData = analyticsResult.data ? analyticsResult.data.concat(previousResult.data) : previousResult.data;
	const keys = allData.map((d) => {
		const store = d.store && d.store.uuid;
		return `${ d.device.uuid }-${ d.date }-${ store }`;
	});

	merged.data = allData.filter((item, currentIndex) => {
		const store = item.store && item.store.uuid;
		const key = `${ item.device.uuid }-${ item.date }-${ store }`;
		return keys.indexOf(key) === currentIndex;
	});

	return updateStateArray(state, "analytics", index, merged);
}

export function updateNotificationSettings(state: AdsState, action: Action<AnalyticsNotificationDispatch>) {
	const { settings, uuid } = action.args;
	const index = getAnalyticsIndex(state, uuid);
	const notification = Object.assign({}, settings, { uuid });
	const updatedAnalytics = Object.assign({}, state.analytics[index], { user: { notification } });

	return updateStateArray(state, "analytics", index, updatedAnalytics);
}

const {
	CREATE_AD,
	DELETE_AD,
	RESET_ADS,
	RESET_ANALYTICS,
	SET_ADS,
	SET_ANALYTICS_REPORT_PENDING,
	SET_NOTIFICATION_SETTINGS,
	UPDATE_AD,
	UPDATE_AD_ANALYTICS,
	UPDATE_AD_ANALYTICS_NOTIFICATION_SETTINGS
} = ACTION_TYPES.Ads;

const reducers = {
	[CREATE_AD.type]: createAd,
	[DELETE_AD.type]: deleteAd,
	[RESET_ADS.type]: resetAds,
	[RESET_ANALYTICS.type]: resetAnalytics,
	[SET_ADS.type]: setAds,
	[SET_ANALYTICS_REPORT_PENDING.type]: setAnalyticsReportPending,
	[SET_NOTIFICATION_SETTINGS.type]: setNotificationSettings,
	[UPDATE_AD.type]: updateAd,
	[UPDATE_AD_ANALYTICS.type]: updateAdAnalytics,
	[UPDATE_AD_ANALYTICS_NOTIFICATION_SETTINGS.type]: updateNotificationSettings
}

export default createReducer(reducers, AdsState);
import { Action, Deployment, DeploymentDispatch, DeploymentListDispatch, DeploymentUuidDispatch, IUser,
	PartialUserListDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";

const {
	CREATE_DEPLOYMENT,
	CANCEL_DEPLOYMENT,
	DELETE_DEPLOYMENT,
	RESET_DEPLOYMENTS,
	SET_DEPLOYMENTS,
	SET_DEPLOYMENT_DETAILS,
	SET_APPROVERS
} = ACTION_TYPES.Deployments;

/**
 * Simple Actions
 */

export function createDeployment(deployment: Deployment): Action<DeploymentDispatch> {
	return new DispatchableAction(CREATE_DEPLOYMENT, { deployment });
}

export function cancelDeployment(deploymentId: string): Action<DeploymentUuidDispatch> {
	return new DispatchableAction(CANCEL_DEPLOYMENT, { deploymentId });
}

export function deleteDeployment(deploymentId: string): Action<DeploymentUuidDispatch> {
	return new DispatchableAction(DELETE_DEPLOYMENT, { deploymentId });
}

export function resetDeployments(): Action<null> {
	return new DispatchableAction(RESET_DEPLOYMENTS, null);
}

export function setDeployments(deployments: Deployment[], reset?: boolean): Action<DeploymentListDispatch> {
	return new DispatchableAction(SET_DEPLOYMENTS, { deployments, reset });
}

export function setDeploymentDetails(deployment: Deployment): Action<DeploymentDispatch> {
	return new DispatchableAction(SET_DEPLOYMENT_DETAILS, { deployment });
}

export function setApprovers(users: Partial<IUser>[]): Action<PartialUserListDispatch> {
	return new DispatchableAction(SET_APPROVERS, { users });
}
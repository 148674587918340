import { createSelector } from "reselect";

import {
	StateWithVisible,
	StateWithStringSelection,
	IDevice,
	MediaContext,
	Context
} from "@connect/Interfaces";
import {
	AppState,
	DeviceHealthModalStates,
	ModalsState,
	SelectMediaModalState,
	SelectActionsModalState,
	SelectAdsModalState,
	SelectPlaylistsModalState,
	RequestNameModalState,
	DeviceGroupTreeBrowserModalState,
	DeviceErrorLogModalState,
	CheckinDeviceModalState,
	CreateStoreModalState,
	CreateUserModalState,
	TemplatesListModalState
} from "Data/Objects/AppState";
import { getAllDevices, getDeviceGroupFromState, getDeviceGroups } from "Data/Selectors/Devices";

export function getModalsState(appState: AppState): ModalsState {
	return appState.UI.modals;
}

export function getHealthModalUUID(modalsState: ModalsState): string {
	return modalsState.activeDeviceHealthModalUUID;
}

export function getDeviceByUUID(devices: IDevice[], uuid: string): IDevice {
	return devices.filter((d) => d.uuid === uuid)[0];
}

export function getGroupUUID(device: IDevice): string {
	return device.deviceGroup.uuid;
}

export function getSnapshotRefreshing(deviceHealthModalState: DeviceHealthModalStates, uuid: string) {
	if (!deviceHealthModalState[uuid]) {
		return null;
	}
	return deviceHealthModalState[uuid].fetchingSnapshot;
}

export function getCompanyCreateModalVisible(modalsState: ModalsState): boolean {
	return modalsState.companyCreateModalVisible;
}

export function getModalVisibleState(state: StateWithVisible): boolean {
	return state.visible;
}

export function getModalSelectionState(state: StateWithStringSelection): string {
	return state.selection;
}

export function getCompanyRequestModalVisible(modalsState: ModalsState): boolean {
	return modalsState.companyRequestModalVisible;
}

export function getDeploymentApprovalModalVisible(modalsState: ModalsState): boolean {
	return modalsState.deploymentApprovalModalVisible;
}

export function getMediaPreviewModalVisible(modalsState: ModalsState): boolean {
	return modalsState.mediaPreviewModalVisible;
}

export function getPrivacyPolicyModalVisible(modalsState: ModalsState): boolean {
	return modalsState.privacyPolicyModalVisible;
}

export function getSelectMediaModalContext(state: SelectMediaModalState): MediaContext {
	return state && state.context || Context.NONE;
}

export function getSelectActionsModalContext(state: SelectActionsModalState): Context {
	return state && state.context || Context.NONE;
}

export function getSelectAdsModalContext(state: SelectAdsModalState): Context {
	return state && state.context || Context.NONE;
}

export function getSelectPlaylistsModalContext(state: SelectPlaylistsModalState): Context {
	return state && state.context || Context.NONE;
}

export function getSelectMediaModalOrientation(state: SelectMediaModalState): string {
	return state.orientation;
}

export function getRequestNameModalInfo(modalsState: ModalsState): RequestNameModalState {
	return modalsState.requestNameModal;
}

export function getDeviceGroupTreeBrowserModalInfo(modalsState: ModalsState): DeviceGroupTreeBrowserModalState {
	return modalsState.deviceGroupTreeBrowser;
}

export function getDeviceErrorLogModalInfo(modalsState: ModalsState): DeviceErrorLogModalState {
	return modalsState.deviceErrorLogModal;
}

export function getCheckinDeviceModalInfo(modalsState: ModalsState): CheckinDeviceModalState {
	return modalsState.checkinDeviceModal;
}

export function getCreateStoreModalInfo(modalsState: ModalsState): CreateStoreModalState {
	return modalsState.createStoreModal;
}

export function getCreateUserModalInfo(modalsState: ModalsState): CreateUserModalState {
	return modalsState.createUserModal;
}

export function getSelectMediaModalInfo(modalsState: ModalsState): SelectMediaModalState {
	return modalsState.selectMediaModal;
}

export function getTemplatesListModalInfo(modalsState: ModalsState): TemplatesListModalState {
	return modalsState.templatesListModal;
}

export function getSelectActionsModalInfo(modalsState: ModalsState): SelectActionsModalState {
	return modalsState.selectActionsModal;
}

export function getSelectAdsModalInfo(modalsState: ModalsState): SelectAdsModalState {
	return modalsState.selectAdsModal;
}

export function getSelectPlaylistsModalInfo(modalsState: ModalsState): SelectPlaylistsModalState {
	return modalsState.selectPlaylistsModal;
}

export function getTermsConditionsModalVisible(modalsState: ModalsState): boolean {
	return modalsState.termsConditionsModalVisible;
}

export function getDeviceHealthModalAppState(appState: AppState): DeviceHealthModalStates {
	return appState.UI.deviceHealthModalState;
}

export const getDeviceHealthModalUUID = createSelector(
	[ getModalsState ],
	getHealthModalUUID
);

export const getDeviceHealthModalDevice = createSelector(
	[ getAllDevices, getDeviceHealthModalUUID ],
	getDeviceByUUID
);

export const getDeviceHealthModalGroupUUID = createSelector(
	[ getDeviceHealthModalDevice ],
	getGroupUUID
);

export const getDeviceHealthModalGroup = createSelector(
	[ getDeviceGroups, getDeviceHealthModalGroupUUID ],
	getDeviceGroupFromState
);

export const getDeviceSnapshotRefreshing = createSelector(
	[ getDeviceHealthModalAppState, getDeviceHealthModalUUID ],
	getSnapshotRefreshing
);

export const getCompanyCreateModalVisibility = createSelector(
	[ getModalsState ],
	getCompanyCreateModalVisible
);

export const getCompanyRequestModalVisibility = createSelector(
	[ getModalsState ],
	getCompanyRequestModalVisible
);

export const getDeploymentApprovalModalVisibility = createSelector(
	[ getModalsState ],
	getDeploymentApprovalModalVisible
);

export const getMediaPreviewModalVisibility = createSelector(
	[ getModalsState ],
	getMediaPreviewModalVisible
);

export const getPrivacyPolicyModalVisibility = createSelector(
	[ getModalsState ],
	getPrivacyPolicyModalVisible
);

export const getRequestNameModalState = createSelector(
	[ getModalsState ],
	getRequestNameModalInfo
);

export const getSelectActionsModalState = createSelector(
	[ getModalsState ],
	getSelectActionsModalInfo
);

export const getSelectAdsModalState = createSelector(
	[ getModalsState ],
	getSelectAdsModalInfo
);

export const getSelectPlaylistsModalState = createSelector(
	[ getModalsState ],
	getSelectPlaylistsModalInfo
);

export const getDeviceGroupTreeBrowserModalState = createSelector(
	[ getModalsState ],
	getDeviceGroupTreeBrowserModalInfo
);

export const getDeviceErrorLogModalState = createSelector(
	[ getModalsState ],
	getDeviceErrorLogModalInfo
);

export const getCheckinDeviceModalState = createSelector(
	[ getModalsState ],
	getCheckinDeviceModalInfo
);

export const getCreateStoreModalState = createSelector(
	[ getModalsState ],
	getCreateStoreModalInfo
);

export const getCreateUserModalState = createSelector(
	[ getModalsState ],
	getCreateUserModalInfo
);

export const getSelectMediaModalState = createSelector(
	[ getModalsState ],
	getSelectMediaModalInfo
);

export const getDeviceGroupTreeBrowserModalSelection = createSelector(
	[ getDeviceGroupTreeBrowserModalState ],
	getModalSelectionState
);

export const getRequestNameModalVisibility = createSelector(
	[ getRequestNameModalState ],
	getModalVisibleState
);

export const getDeviceGroupTreeBrowserModalVisibility = createSelector(
	[ getDeviceGroupTreeBrowserModalState ],
	getModalVisibleState
);

export const getDeviceErrorLogModalVisibility = createSelector(
	[ getDeviceErrorLogModalState ],
	getModalVisibleState
);

export const getCheckinDeviceModalVisibility = createSelector(
	[ getCheckinDeviceModalState ],
	getModalVisibleState
);

export const getCreateStoreModalVisibility = createSelector(
	[ getCreateStoreModalState ],
	getModalVisibleState
);

export const getCreateUserModalVisibility = createSelector(
	[ getCreateUserModalState ],
	getModalVisibleState
);

export const getSelectMediaModalVisibility = createSelector(
	[ getSelectMediaModalState ],
	getModalVisibleState
);

export const getSelectActionsModalVisibility = createSelector(
	[ getSelectActionsModalState ],
	getModalVisibleState
);

export const getSelectAdsModalVisibility = createSelector(
	[ getSelectAdsModalState ],
	getModalVisibleState
);

export const getSelectPlaylistsModalVisibility = createSelector(
	[ getSelectPlaylistsModalState ],
	getModalVisibleState
);

export const getTemplatesListModalState = createSelector(
	[ getModalsState ],
	getTemplatesListModalInfo
);

export const getTemplatesListModalVisibility = createSelector(
	[ getTemplatesListModalState ],
	getModalVisibleState
);

export const getCurrentSelectActionsModalContext = createSelector(
	[ getSelectActionsModalState ],
	getSelectActionsModalContext
);

export const getCurrentSelectAdsModalContext = createSelector(
	[ getSelectAdsModalState ],
	getSelectAdsModalContext
);

export const getCurrentSelectMediaModalContext = createSelector(
	[ getSelectMediaModalState ],
	getSelectMediaModalContext
);

export const getCurrentSelectMediaModalOrientation = createSelector(
	[ getSelectMediaModalState ],
	getSelectMediaModalOrientation
);

export const getCurrentSelectPlaylistsModalContext = createSelector(
	[ getSelectPlaylistsModalState ],
	getSelectPlaylistsModalContext
);

export const getTermsConditionsModalVisibility = createSelector(
	[ getModalsState ],
	getTermsConditionsModalVisible
);
import * as React from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { IMedia, IMediaUpload, MediaFilterTypes, StringObject, ViewTypes, Filters } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Button, Header, Loader } from "Components/Global/Common";
import FavoriteToggle from "Components/Global/FavoriteToggle";
import Table from "Components/Global/Table";
import MediaGrid from "Components/Media/MediaGrid";
import MediaThumbnail from "Components/Media/MediaThumbnail";
import { setMediaAsync } from "Data/Actions/MediaAsync";
import { replaceWithoutRender } from "Data/Actions/Navigation";
import { setActiveFilters } from "Data/Actions/UI";
import { Media } from "Data/Objects/Media";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveFilters, getActiveUuid, getActiveView } from "Data/Selectors/UI";
import { getUploadingMedia } from "Data/Selectors/Media";

interface MediaFilter extends StringObject {
	type: string;
}

interface MediaContentAreaProps {
	activeFilter: MediaFilter;
	activeUuid: string;
	canFetch: boolean;
	currentUploads: IMediaUpload[];
	getMedia: () => Promise<void>;
	lastFetchedPage: number;
	media: IMedia[];
	onDeselect: () => void;
	onUploadMediaClicked: () => void;
	onMediaSelected?: (media: IMedia[]) => void; // this prop is used in the case of video or image context above
	originator: string;
	selectModeOn: boolean;
	setActiveMedia: (id: string) => void;
	setAllMediaFilter: () => void;
	viewType: ViewTypes;
}

const mapDispatchToProps = (dispatch) => ({
	getMedia: () => dispatch(setMediaAsync()),
	onDeselect: () => dispatch(replaceWithoutRender("/media")),
	setAllMediaFilter: () => dispatch(setActiveFilters("type", Filters.MEDIA, MediaFilterTypes.ALL)),
	setActiveMedia: (id: string) => dispatch(replaceWithoutRender(`/media/${id}`))
});

const mapStateToProps = (state, { originator }) => ({
	activeFilter: getActiveFilters(state, Filters.MEDIA),
	activeUuid: getActiveUuid(state, "media"),
	currentUploads: getUploadingMedia(state, originator),
	viewType: getActiveView(state, "media") || ViewTypes.GRID
});

export class MediaContentArea extends React.Component<MediaContentAreaProps> {
	constructor(props: MediaContentAreaProps) {
		super(props);

		this.fetchNextPageOfMedia = this.fetchNextPageOfMedia.bind(this);
		this.hasNoMedia = this.hasNoMedia.bind(this);
		this.renderEmpty = this.renderEmpty.bind(this);
		this.renderMediaGrid = this.renderMediaGrid.bind(this);
		this.renderMediaTable = this.renderMediaTable.bind(this);
		this.renderWrappedMediaContent = this.renderWrappedMediaContent.bind(this);
		this.setMediaThumbnail = this.setMediaThumbnail.bind(this);
	}

	_selectedRow: MediaThumbnail;
	cardMargin = 10;
	cardPadding = 10;
	cardWidth = 200;

	componentDidMount() {
		this.scrollIntoView();
	}

	componentDidUpdate(prevProps: MediaContentAreaProps) {
		if (prevProps.activeUuid !== this.props.activeUuid) {
			this.scrollIntoView();
		}
	}

	render() {
		if (!hasPermission(PERMISSIONS.MEDIA_MANAGE)) {
			return (<div />);
		}

		return (
			<div onClick={this.props.onDeselect}>
				{this.renderWrappedMediaContent()}
				<div style={{
					width: "100%",
					height: 40,
					marginTop: 40,
					textAlign: "center",
					display: this.props.canFetch ? "inline-block" : "none"
				}}>
					<Waypoint
						key={this.props.lastFetchedPage}
						onEnter={ this.fetchNextPageOfMedia }
					/>
					<Loader />
				</div>
			</div>
		);
	}

	renderEmpty() {
		const { activeFilter, onUploadMediaClicked, setAllMediaFilter } = this.props;

		if (!this.hasNoMedia()) {
			return null;
		}

		const allSelected = activeFilter.type === MediaFilterTypes.ALL;

		const emptyHeader = allSelected
			? "Media library is empty."
			: "No matching media.";
		const emptyMessage = allSelected
			? "Upload media to begin building ads."
			: null;
		const emptyButton = allSelected ? "Upload Media" : "View All Media";

		return (
			<div style={{
				textAlign: "center",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				height: "100vh"
			}}>
				<div>
					<Header size={1}>{emptyHeader}</Header>
					<div style={{
						opacity: 0.8,
						marginBottom: 15
					}}>
						<p>{emptyMessage}</p>
						<br />
						<Button icon={ allSelected ? "plus-circle" : "archive" }
							onClick={() => {
								if (allSelected) {
									onUploadMediaClicked();
								} else {
									setAllMediaFilter();
								}
							}}>
							{emptyButton}
						</Button>
					</div>
				</div>
			</div>
		);
	}

	renderWrappedMediaContent() {
		const { viewType } = this.props;

		if (ViewTypes.GRID === viewType) {
			return (
				<div
					onClick={(e) => {
						e.stopPropagation();
					}}
					style={{padding: 20}}
				>
					{this.renderMediaGrid()}
				</div>
			);
		}

		return (
			<div style={{padding: 20}}>
				{this.renderMediaTable()}
			</div>
		);
	}

	renderMediaGrid() {
		const { currentUploads, media, onMediaSelected, selectModeOn, onUploadMediaClicked } = this.props;

		return (
			<MediaGrid
				content={[ ...currentUploads, ...media ]}
				onMediaSelected={ onMediaSelected }
				onUploadMediaClicked = { onUploadMediaClicked }
				selectModeOn={ selectModeOn }
			/>
		);
	}

	renderMediaTable() {
		const { activeUuid } = this.props;

		const columns = [ {
			key: "thumb",
			render: (data: any, m: IMedia) => {
				return (
					<MediaThumbnail
						media={ m }
						ref={ this.setMediaThumbnail(m) }
						width={ 50 }
					/>
				);
			},
			width: 50
		}, {
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: Utils.columnSorter("name")
		}, {
			title: "Type",
			dataIndex: "mediaType",
			key: "type",
			render: (type: string, m: IMedia) => {
				return Media.getTypeLabel(m);
			},
			sorter: Utils.columnSorter("mediaType")
		}, {
			title: "Upload Date",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (date: string) => {
				return Utils.getHumanReadableDate(date);
			},
			sorter: Utils.columnSorter("createdAt")
		}, {
			title: "Favorited",
			sorter: Utils.columnSorter("favorite"),
			render: (t, d, i) => {
				return (
					<FavoriteToggle
						mediaId={d.uuid}
						type="icon"
						style={{
							fontSize: "24px",
							color: "#127EC0"
						}}/>
				);
			}
		} ];

		return (
			<Table
				bordered={false}
				columns={columns}
				dataSource={this.props.media}
				locale={{ emptyText: "No Media" }}
				onRow={(m: IMedia, index: number) => {
					return {
						onClick: (e: Event) => {
							e.stopPropagation();
							const { setActiveMedia, onMediaSelected } = this.props;
							setActiveMedia(m.uuid);
							if (onMediaSelected) {
								onMediaSelected([ m ]);
							}
						}
					}
				}}
				pagination={false}
				rowClassName={(m: IMedia) => {
					if (activeUuid && activeUuid === m.uuid) {
						return "active";
					}
					return "";
				}}
				rowKey={(m: IMedia) => {
					return m.uuid
				}}
				style={{cursor: "pointer"}}
			/>
		);
	}

	columnSorter(key: string) {
		return (a, b) => (a[key] > b[key]) ? -1 : 1;
	}

	fetchNextPageOfMedia() {
		if (this.props.canFetch) {
			this.props.getMedia();
		}
	}

	hasNoMedia() {
		const { canFetch, currentUploads, media } = this.props;
		return media.length === 0 && currentUploads.length === 0 && !canFetch;
	}

	scrollIntoView() {
		const isListView = this.props.viewType === ViewTypes.LIST;

		if (this._selectedRow && this.props.activeUuid && isListView) {
			(findDOMNode(this._selectedRow) as Element).scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center"
			});
		}
	}

	setMediaThumbnail(m: IMedia) {
		return (row: MediaThumbnail) => {
			if (this.props.activeUuid === m.uuid) {
				this._selectedRow = row;
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaContentArea);
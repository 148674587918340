import Radium from "radium";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, MediaContext as Context, MediaFilterTypes, Filters } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { setActiveFilters } from "Data/Actions/UI";
import { getActiveFilters } from "Data/Selectors/UI";
import { cloneDeep } from "lodash";

const mapDispatchToProps = (dispatch) => ({
	setMediaType: (filter: MediaFilterTypes) => dispatch(setActiveFilters("type", "media", filter))
});

const mapStateToProps = (state, ownProps) => ({
	selectedFilter: getActiveFilters(state, Filters.MEDIA).type
});

interface IMediaFiltersProps {
	context: Context;
	selectedFilter: MediaFilterTypes;
	setMediaType: (filter: MediaFilterTypes) => void;
}

export class MediaFilters extends React.PureComponent<IMediaFiltersProps> {
	constructor(props: IMediaFiltersProps) {
		super(props);

		const { filters } = Colors;

		const selectedFilterItem = {
			background: filters.active,
			borderBottom: `1px solid ${filters.inactive}`,
			borderRadius: 2
		};

		this.styles = {
			filterList: {
				listStyleType: "none",
				margin: 0,
				marginBottom: 30,
				padding: 0
			},
			filterRow: {
				padding: "8px 4px",
				borderBottom: `1px solid ${ filters.active }`,
				cursor: "pointer",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				":hover": {
					...selectedFilterItem,
					background: filters.hover
				}
			},
			filterRowSelected: selectedFilterItem,
			filterRowIcon: {
				marginRight: 5
			}
		}

		this.renderFilterButton = this.renderFilterButton.bind(this);
		this.setMediaFilter = this.setMediaFilter.bind(this);
	}

	styles: CustomCSS;

	render () {
		const mediaFilterButtons = [
			{
				name: "All",
				icon: "archive"
			},
			{
				name: "Favorites",
				icon: "star"
			},
			{
				name: "Images",
				icon: "image"
			},
			{
				name: "Videos",
				icon: "play-circle"
			},
			{
				name: "Banners",
				icon: "shield"
			},
			{
				name: "Unused",
				icon: "question-circle"
			}
		];

		return (
			<ul style={ this.styles.filterList }>
				{ mediaFilterButtons.map(this.renderFilterButton) }
			</ul>
		);
	}

	renderFilterButton(button: { name: string; icon: string; }) {
		const { filterRow, filterRowIcon, filterRowSelected } = this.styles;
		const { selectedFilter } = this.props;
		const { icon, name } = button;
		const filter = name.toUpperCase();

		let style = cloneDeep(filterRow);

		if (selectedFilter === MediaFilterTypes[filter]) {
			style = Object.assign({}, style, filterRowSelected);
		}

		return (
			<li
				role="button"
				key={ name + "-filter" }
				style={ style }
				onClick={ this.setMediaFilter(MediaFilterTypes[filter]) }
			>
				<Icon
					fixedWidth={ true }
					name={ icon }
					size="smaller"
					style={ filterRowIcon }
				/>
				{ name }
			</li>
		)
	}

	setMediaFilter(filter: MediaFilterTypes) {
		return () => {
			this.props.setMediaType(filter);
		};
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(MediaFilters));

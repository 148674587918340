import { ActionSetTriggers, CustomCSS, TriggerType } from "@connect/Interfaces";
import DurationThresholdProperties from "Components/Actions/DurationThresholdProperties";
import { Icon, Loader } from "Components/Global/Common";
import * as React from "react";

export interface ITriggerPropertiesPanelContent {
	renderContainer: (content: JSX.Element) => JSX.Element;
}

export interface TriggerPropertiesPanelContentProps {
	triggerType: TriggerType;
}

export class TriggerPropertiesPanelContent<T extends TriggerPropertiesPanelContentProps>
	extends React.Component<TriggerPropertiesPanelContentProps> implements ITriggerPropertiesPanelContent {
	constructor(props: TriggerPropertiesPanelContentProps) {
		super(props);

		this.styles = {
			image: {
				display: "inline-block",
				margin: "3em 0 0"
			},
			name: {
				display: "inline-block",
				marginTop: 10
			},
			row: {
				marginBottom: 14,
				textAlign: "center"
			}
		};

		this.renderContainer = this.renderContainer.bind(this);
		this.renderDurationThresholdProperties = this.renderDurationThresholdProperties.bind(this);
	}

	styles: CustomCSS;

	render() {
		// We throw here to prevent rendering the TriggerPropertiesPanelContent directly, but we must return a <div />
		// so the extending components can properly type their render methods.
		throw new Error("WARNING: TriggerPropertiesPanelContent#render must be overridden in the extending component.");
		return <div />;
	}

	renderContainer(children?: JSX.Element) {
		if (!this.props.triggerType) {
			return (
				<Loader size="small" />
			);
		}

		return (
			<div>
				{ this.renderIcon() }
				{ this.renderDurationThresholdProperties() }
				{ children }
			</div>
		);
	}

	renderIcon() {
		const { image, name, row } = this.styles;
		const { icon, title } = ActionSetTriggers[this.props.triggerType || "alarm_0"];

		return (
			<div style={ row }>
				<Icon name={ icon } size="bigger" style={ image } />
				<br />
				<span style={ name }>{ title }</span>
			</div>
		);
	}

	renderDurationThresholdProperties() {
		const { triggerType } = this.props;

		return <DurationThresholdProperties key={ triggerType } />
	}
}

export default TriggerPropertiesPanelContent;

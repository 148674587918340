import Radium from "radium";
import * as React from "react";
import {
	DropTarget,
	DropTargetCollector,
	DropTargetMonitor,
	DropTargetSpec
} from "react-dnd";
import { connect } from "react-redux";

import { CustomCSS, TriggerType } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";
import { addTriggerAsync } from "Data/Actions/ActionsAsync";
import { getActiveUuid } from "Data/Selectors/UI";
import { setSelectedActionComponent } from "Data/Actions/UI/ActionSetBuilder";

const { lightBlue, primaryBlue, gray, dropBackground } = Colors;

export const IDENTIFIER = "trigger";

const mapStateToProps = (state) => {
	return ({
		activeUuid: getActiveUuid(state, "actions")
	});
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	addTrigger: (triggerType: TriggerType) => dispatch(addTriggerAsync(triggerType)),
	setSelectedTrigger: (triggerIndex: number) => dispatch(setSelectedActionComponent(triggerIndex, ""))
});

export interface TriggerDropzoneCollectedProps {
	connectDropTarget?: Function;
	currentlyDragging?: boolean;
	isOver?: boolean;
	canDrop?: boolean;
	hoverItem?: TriggerDropzoneDropTarget;
}

export interface TriggerDropzoneProps {
	triggerIndex: number;
	activeUuid?: string;
	connectDropTarget?: Function;
	currentlyDragging?: boolean;
	isOver?: boolean;
	canDrop?: boolean;
	hoverItem?: TriggerDropzoneDropTarget;
	action?: any;
	addTrigger: (trigger: TriggerType) => void;
	setSelectedTrigger: (triggerIndex: number) => void;
}

interface TriggerDropzoneDropTarget {
	action?: any;
	data?: any;
	index?: number,
	isReorder?: boolean,
}

const dropTarget: DropTargetSpec<TriggerDropzoneProps> = {
	drop(props: TriggerDropzoneProps, monitor: DropTargetMonitor, component: TriggerDropzone) {
		props.addTrigger(monitor.getItem().triggerType);
		props.setSelectedTrigger(props.triggerIndex);
	}
}

const dropCollector: DropTargetCollector<
TriggerDropzoneCollectedProps
> = (dragConnect, monitor) => ({
	connectDropTarget: dragConnect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop(),
	hoverItem: monitor.getItem()
});

@Radium
class TriggerDropzone extends React.Component<TriggerDropzoneProps> {
	constructor(props: TriggerDropzoneProps) {
		super(props);

		this.styles = {
			container: {
				padding: "5px 0",
				position: "relative",
				width: "85%",
				left: "7.5%"
			},
			placeholder: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: 100,
				margin: "10px 0px",
				padding: "0 20px",
				border: "none",
				borderRadius: 3,
				color: gray,
				fontSize: 20,
				whiteSpace: "nowrap"
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { connectDropTarget } = this.props;
		const { container } = this.styles;

		return connectDropTarget && connectDropTarget(
			<div style={ container }>
				{this.renderDropNextTarget()}
			</div>
		);
	}

	renderDropNextTarget() {
		if (this.props.action) {
			return null;
		};
		const { placeholder } = this.styles;
		const { isOver } = this.props;
		placeholder.background = "none";
		placeholder.color = lightBlue;
		placeholder.border = "1px dashed " + primaryBlue;
		if (isOver) {
			placeholder.background = dropBackground
		}

		return (
			<div style={this.styles.placeholder}>
				Drag & Drop Next Trigger Here
			</div>
		);
	}
}

export default
connect(mapStateToProps, mapDispatchToProps)(
	DropTarget([ IDENTIFIER ], dropTarget, dropCollector)(TriggerDropzone)
);
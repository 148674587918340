import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { ButtonTypes, IconWeights } from "Components/Global/Button";
import { Button, Header } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

export interface PropertiesPanelButton {
	action: () => void;
	color?: string;
	disabled?: boolean;
	key: string;
	icon?: { name: string, weight: IconWeights, spin?: boolean};
	name: string;
	type?: ButtonTypes;
}

interface PropertiesPanelProps {
	actions: PropertiesPanelButton[];
	actionComponents?: JSX.Element[];
	properties: JSX.Element[] | JSX.Element | false | null;
	title: string;
}

interface PropertiesPanelState {
}

export default class PropertiesPanel extends React.PureComponent<PropertiesPanelProps, PropertiesPanelState> {
	constructor(props: PropertiesPanelProps) {
		super(props);

		this.styles = {
			bottomSection: {
				width: "100%"
			},
			button: {
				margin: "10px auto 0",
				width: "80%"
			},
			buttonInner: {
				width: "100%"
			},
			container: {
				alignItems: "center",
				display: "flex",
				flexDirection: "column",
				flexWrap: "nowrap",
				height: "100vh",
				justifyContent: "space-around",
				padding: 10,
				width: "100%",
				overflowX: "hidden"
			},
			header: {
				marginBottom: 10
			},
			propertyRow: {
				marginBottom: 14
			},
			topSection: {
				color: Colors.white,
				flexGrow: 1,
				maxWidth: 230,
				overflowY: "auto",
				overflowX: "hidden",
				width: "100%",
				position: "relative"
			}
		};

		this.renderActionComponent = this.renderActionComponent.bind(this);
		this.renderButton = this.renderButton.bind(this);
		this.renderButtons = this.renderButtons.bind(this);
		this.renderChild = this.renderChild.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { bottomSection, container, header, topSection } = this.styles;
		const content = this.props.properties;

		if (!content) {
			return null;
		}

		let children;

		if (Array.isArray(content)) {
			children = content.map(this.renderChild);
		} else {
			children = content;
		}

		return (
			<div className="con-properties-panel" style={container}>
				<div style={topSection}>
					<Header color="white" size={5} style={header}>
						{this.props.title}
					</Header>
					{children}
				</div>
				<div style={bottomSection}>
					{this.renderActionComponents()}
					{this.renderButtons()}
				</div>
			</div>
		);
	}

	renderActionComponent(component: JSX.Element, index: number) {
		const { button } = this.styles;

		return (
			<div key={this.props.title + "_action_" + index} style={button}>
				{component}
			</div>
		);
	}

	renderActionComponents() {
		const { actionComponents } = this.props;

		if (actionComponents && !actionComponents.length || !actionComponents) {
			return null;
		}

		return actionComponents.length && actionComponents.map(this.renderActionComponent);
	}

	renderButton(button: PropertiesPanelButton, index: number) {
		const { button: buttonStyle, buttonInner } = this.styles;
		return (
			<div key={this.props.title + button.name + index} style={buttonStyle}>
				<Button
					color={button.color}
					disabled={button.disabled}
					icon={button.icon ? button.icon.name : undefined}
					iconWeight={button.icon ? button.icon.weight : undefined}
					onClick={button.action}
					iconSpin={button.icon && button.icon.spin ? button.icon.spin : undefined}
					style={buttonInner}
					type={button.type}
				>
					{button.name}
				</Button>
			</div>
		);
	}

	renderButtons() {
		if (!this.props.actions.length) {
			return null;
		}

		return this.props.actions.map(this.renderButton);
	}

	renderChild(child: JSX.Element, index: number) {
		return (
			<div key={this.props.title + index} style={this.styles.propertyRow}>
				{child}
			</div>
		);
	}
}

import * as React from "react";
import { connect } from "react-redux";

import { ActionSetActions } from "@connect/Interfaces";
import { ActionPropertiesPanelContent,
	ActionPropertiesPanelContentProps } from "Components/Actions/ActionPropertiesPanelContent";
import { getSelectedActionType } from "Data/Selectors/ActionBuilder";
import { mapStateToProps as superMapStateToProps } from "Components/Actions/ActionPropertiesPanelContent";


const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		activeActionType: getSelectedActionType(state)
	}
};

interface AlarmActionPropertiesPanelContentProps extends ActionPropertiesPanelContentProps {
}

export class AlarmActionPropertiesPanelContent
	extends ActionPropertiesPanelContent<AlarmActionPropertiesPanelContentProps> {
	constructor(props: AlarmActionPropertiesPanelContentProps) {
		super(props);

		const { icon, title } = ActionSetActions.alarm_out;

		this.icon = icon;
		this.title = title;
	}

	render() {
		return this.renderContainer(this.renderContent());
	}

	renderContent() {
		// TODO: This panel is marked entirely as TBD in the mockup and should be revisited
		return <div />;
	}
}

export default connect(mapStateToProps)(AlarmActionPropertiesPanelContent);
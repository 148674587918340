import { IconName, IconPrefix } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { CustomIcons } from "Components/Global/Constants";

export type FlipTypes = "horizontal" | "vertical";
type SortDirections = "up" | "down";
type SizeTypes = "smallest" | "smaller" | "small" | "big" | "bigger" | "biggest";
export type IconWeights = "regular" | "solid" | "light" | "brands";

interface IconProps {
	sortDirection?: SortDirections;
	flip?: FlipTypes;
	name: string;
	rotate?: number;
	size?: SizeTypes;
	spin?: boolean;
	// fixedWidth corresponds to Font Awesome's
	// fixed width property which makes all icons
	// the same width... this improves the look
	// of icons used in a list. It defaults to
	// on because it's usually helpful. Turn
	// it off if it causes problems
	fixedWidth?: boolean;
	onClick?: (e?: any) => void;
	style?: CustomCSS;
	className?: string;
	iconWeight?: IconWeights;
	// iconStyle applies directly to the FontAwesomeIcon or CustomIcon
	iconStyle?: CustomCSS;
}

export default class Icon extends React.PureComponent<IconProps> {
	constructor(props: IconProps) {
		super(props);

		this.maybeClick = this.maybeClick.bind(this);
		this.renderArrow = this.renderArrow.bind(this);
	}

	render() {
		const {name, className, size, spin, flip, rotate, fixedWidth, style, iconStyle} = this.props;
		const customSize = size ? this.getSizeCustom(size) : "";
		// If a custom icon exists, prefer to render it
		const content = name in CustomIcons ? (
			<img
				src={ CustomIcons[name] }
				style={{
					display: "inline-block",
					height: customSize,
					width: customSize,
					...iconStyle
				}}
			/>
		) : (
			<React.Fragment>
				{ this.renderArrow() }
				<FontAwesomeIcon
					icon={[ this.getIconWeight(), name as IconName ]}
					spin={ spin }
					flip={ flip }
					size={ size ? this.getSizeString(size) : undefined }
					transform={{ rotate }}
					fixedWidth={ fixedWidth }
					style={ iconStyle }
				/>
			</React.Fragment>
		);

		return (
			<span style={ style }
				onClick={ this.maybeClick }
				className={ className }
			>
				{ content }
			</span>
		);
	}

	renderArrow() {
		const { fixedWidth, size, sortDirection: direction } = this.props;

		if (!direction) {
			return null;
		}

		const arrowIcon = direction === "up" ? "long-arrow-alt-down" : "long-arrow-alt-up";
		return (
			<React.Fragment>
				&nbsp;
				<FontAwesomeIcon
					icon={[ this.getIconWeight(), arrowIcon ]}
					size={ size ? this.getSizeString(size) : undefined }
					fixedWidth={fixedWidth}
				/>
			</React.Fragment>
		);
	}

	getIconWeight(): IconPrefix {
		switch (this.props.iconWeight) {
			case "regular":
				return "far";
			case "brands":
				return "fab";
			case "light":
				return "fal";
			case "solid":
			default:
				return "fas";
		}
	}

	getSizeCustom(size: SizeTypes) {
		switch (size) {
			case "biggest":
				return "5em";
			case "bigger":
				return "4em";
			case "big":
				return "3em";
			case "small":
				return "2em";
			case "smaller":
				return "1.33em";
			case "smallest":
			default:
				return "";
		}
	}

	getSizeString(size: SizeTypes) {
		switch (size) {
			case "biggest":
				return "5x";
			case "bigger":
				return "4x";
			case "big":
				return "3x";
			case "small":
				return "2x";
			case "smaller":
				return "lg";
			case "smallest":
			default:
				return undefined;
		}
	}

	maybeClick(event: React.SyntheticEvent<HTMLSpanElement>) {
		const { onClick } = this.props;

		if (onClick) {
			onClick(event);
		}
	}
}
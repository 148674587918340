import { IUser, KeyLabel, NameUuid, PaginatedV2Result, Team } from "@connect/Interfaces";

export type CloudProvider = "aws" | "azure";

export const CloudProviders = {
	"aws": "Amazon Web Services",
	"azure": "Microsoft Azure"
}

export interface CompanyResult extends ICompany {
	permissions?: string[];
}

export interface CompanyFilterOption {
	key: string;
	name: string;
	text: string;
	value: string;
}

export interface CompanyMenuOption {
	active: boolean;
	key: string;
	text: string;
	value: string;
}

export interface CreateCompanyData {
	name: string;
	integrator: boolean;
	cloudServiceProvider: CloudProvider;
	customPermissions: boolean;
}

export interface CompanyDispatch {
	company: ICompany;
}

export interface CompanyFilters {
	company?: string;
	sort?: string;
	text?: string;
}

export interface CompanyUpdate {
	active?: boolean;
	name?: string;
	customPermissions?: boolean;
	integrator?: boolean;
	passwordReset?: boolean;
}

export interface CompaniesResult extends PaginatedV2Result {
	data: ICompany[];
}

export interface CompaniesDispatch {
	companies: ICompany[];
	reset?: boolean;
}

export interface CompaniesPageCompany {
	devicesCount: number;
	name: string;
	owner: string;
	status: string;
	type: string;
	uuid: string;
}

export interface ExtendedCompaniesResult extends PaginatedV2Result {
	data: IExtendedCompany[];
}

export interface ManagedCompaniesDispatch {
	companies: IExtendedCompany[];
	update?: boolean;
}

export interface ManagedCompaniesTeamsDispatch {
	company: string;
	teams: KeyLabel[];
}

export interface ManagedCompanyDispatch {
	company: IExtendedCompany;
}

export interface ICompany extends NameUuid {
	active: boolean;
	cloudServiceProvider: CloudProvider;
	createdAt: string;
	customPermissions: boolean;
	hashid: string;
	integrations: NameUuid[];
	mediaLimit: MediaLimit;
	mediaUsage: MediaUsage;
	name: string;
	teamType: string;
	updatedAt: string;
	uuid: string;
	passwordRequirements: PasswordRequirements;
	passwordReset?: boolean;
}

export interface PasswordRequirements {
	minChars: number;
	minLowerCase: number;
	minUpperCase: number;
	minNumbers: number;
	minSpecialChars: number;
}

export interface IExtendedCompany extends ICompany {
	devicesCount: number;
	integrators?: Partial<Team>[];
	owners: Partial<IUser>[];
	usersCount: number;
}

export interface MediaLimit {
	imageSize: number;
	librarySize: number;
	monthlyImageSize: number;
	monthlyVideoLength: number;
	videoLength: number;
	videoSize: number;
}

export interface MediaUsage {
	banner: number;
	duration: number;
	image: number;
	library: number;
	video: number;
}

export interface NewCompanyRequest {
	companyName: string;
	contactName: string;
	contactEmail: string;
	companyWebsite: string;
	contactPhone: string;
}

import { createSelector } from "reselect";

import { AdMediaLink, IAd, IBaseComponent, IMedia, MediaCollectionArgs, MediaFilterTypes,
	SortTypes, WithUuid, IMediaUpload } from "@connect/Interfaces";
import { AppState, MediaState } from "Data/Objects/AppState";
import { isImage, isVideo } from "Data/Objects/Media";
import { getSortedCollection, identityReducer } from "Data/Utils";

export function filterBannerMedia(media: IMedia[]) {
	return media.filter(isBannerType);
}

export function getAdMediaIds(state: AppState, props: { ad: IAd, component: IBaseComponent }) {
	return props.ad.layout.media
		.filter((adMediaLink: AdMediaLink) => adMediaLink.layoutPosition === props.component.id)
		.map((adMediaLink: AdMediaLink) => adMediaLink.mediaId);
}

export function getCurrentTags(state: AppState, args: MediaCollectionArgs): string[] {
	return args.tags || [];
}

export function getMediaState(state: AppState): MediaState {
	return state.Media;
}

export function getMedia(state: MediaState): IMedia[] {
	return state.media;
}

export function getMediaFilteredByTags(media: IMedia[], tags: string[]) {
	if (!tags || tags.length === 0) {
		return media;
	}

	return media.filter((m: IMedia) => {
		if (m.tags.length && m.tags.some((t) => tags.includes(t))) {
			return true;
		}

		return false;
	});
}

export function getMediaFilteredByType(media: IMedia[], type: MediaFilterTypes) {
	return media.filter((m: IMedia) => {
		switch (type) {
			case MediaFilterTypes.ALL:
				return true;
			case MediaFilterTypes.FAVORITES:
				return m.favorite;
			case MediaFilterTypes.IMAGES:
				return isImage(m);
			case MediaFilterTypes.VIDEOS:
				return isVideo(m);
			case MediaFilterTypes.UNUSED:
				return m.adsCount === 0;
			case MediaFilterTypes.BANNERS:
				return m.mediaType === "banner";
			default:
				return false;
		}
	});
}

export function getMediaSorted(media: IMedia[], sortType: SortTypes) {
	return getSortedCollection<IMedia>(media, sortType);
}

export function getMediaSearchFiltered(media: IMedia[], search: string) {
	const filter = search ? search.toLowerCase() : "";
	return media.filter((m) => m.name.toLowerCase().includes(filter.toLowerCase()));
}

export function getMediaForAdComponent(adIds: string[], media: IMedia[]): IMedia[] {
	return adIds.map((id) => {
		const index = media.findIndex((m) => m.uuid === id);

		return media[index];
	}).filter(Boolean);
}

export function getMediaFromId(media: IMedia[], mediaId: string): IMedia | undefined {
	return media.find((m: IMedia) => m.uuid === mediaId);
}

export function getMediasFromIds(media: IMedia[], mediaIds: string[]): IMedia[] {
	return media.filter((m: IMedia) => mediaIds.includes(m.uuid));
}

export function getMediaId(state: any, props: WithUuid) {
	return props.uuid;
}

export function getMediaSort(state: AppState, args: MediaCollectionArgs): SortTypes {
	return args.sort || SortTypes.NEWEST_FIRST;
}

export function getMediaSearchFilter(state: AppState, args: MediaCollectionArgs): string {
	return args.filter || "";
}

export function getMediaType(state: AppState, args: MediaCollectionArgs) {
	return args.type || "ALL";
}

export function getTagsFromMedia(media: IMedia[]) {
	return media
		.reduce((arr: string[], m: IMedia) => {
			m.tags.forEach((t) => arr.push(t));
			return arr;
		}, [])
		.filter((t: string, index: number, self: string[]) => self.indexOf(t) === index);
}

export function isBannerType(media: IMedia) {
	return media.mediaType === "banner";
}

export const getAllMedia = createSelector(
	[ getMediaState ],
	getMedia
);

export const getBanners = createSelector(
	[ getAllMedia ],
	filterBannerMedia
);

export const getFilteredMedia = createSelector(
	[ getAllMedia, getMediaType ],
	getMediaFilteredByType
);

export const getMediaById = createSelector(
	[ getAllMedia, getMediaId ],
	getMediaFromId
);

export const getMediasByIds = createSelector(
	[ getAllMedia, identityReducer ],
	getMediasFromIds
);

export const getMediaTags = createSelector(
	[ getAllMedia ],
	getTagsFromMedia
);

export const getSortedMedia = createSelector(
	[ getAllMedia, getMediaSort ],
	getMediaSorted
);

export const getTaggedMedia = createSelector(
	[ getAllMedia, getCurrentTags ],
	getMediaFilteredByTags
);

export const getFilteredTaggedMedia = createSelector(
	[ getFilteredMedia, getCurrentTags ],
	getMediaFilteredByTags
);

export const getFilteredTaggedSortedMedia = createSelector(
	[ getFilteredTaggedMedia, getMediaSort ],
	getMediaSorted
);

export const getFilteredTaggedSortedSearchedMedia = createSelector(
	[ getFilteredTaggedSortedMedia, getMediaSearchFilter ],
	getMediaSearchFiltered
);

export const makeGetMediaById = () => getMediaById;

export const makeGetMediaForAdComponent = () => createSelector(
	[ getAdMediaIds, getAllMedia ],
	getMediaForAdComponent
);

/*
* Selectors for uploads array
*/

export function getFilteredUploads(state: AppState, originator: string): IMediaUpload[] {
	return state.Media.uploads.filter((u: IMediaUpload) => u.originator === originator);
}

export function getIsUploading(uploads: IMediaUpload[]): boolean {
	return uploads.length > 0;
}

export function getUploadMediaId(uploads: IMediaUpload[]): string[] {
	if (getIsUploading(uploads)) {
		return uploads.map(({ uuid }) => uuid);
	}

	return [];
}

export function getUploadProgress(uploads: IMediaUpload[]): number {
	if (getIsUploading(uploads)) {
		const [ { progress } ] = uploads;

		if (progress && progress > 10) {
			return progress;
		}

		return 10;
	}

	return 100;
}

export function getUploadMediaUuIds(uploads: IMediaUpload[]): string[] {
	return uploads.map((u) => u.uuid);
}

export function getUploadState(state: AppState) {
	return state.Media;
}

export function getUploadsArray(state: MediaState) {
	return state.uploads;
}

export function getMediaIsUploading(uuid: string, mediaIds: string[]): boolean {
	return !!mediaIds.find((id) => id === uuid);
}

export function getOriginalUploads(uploads: IMediaUpload[], originator: string) {
	return uploads.filter((u) => u.originator === originator);
}

export const getUploads = createSelector(
	[ getUploadState ],
	getUploadsArray
);

export const getUploadMediaIds = createSelector(
	[ getUploads ],
	getUploadMediaUuIds
);

export const getUploadingMedia = createSelector(
	[ getUploads, identityReducer ],
	getOriginalUploads
);

export const makeGetMediaIsUploading = () => createSelector(
	[ identityReducer, getUploadMediaIds ],
	getMediaIsUploading
);

export const makeGetIsUploading = () => createSelector(
	[ getFilteredUploads ],
	getIsUploading
);

export const makeGetUploadMediaId = () => createSelector(
	[ getFilteredUploads ],
	getUploadMediaId
);

export const makeGetUploadProgress = () => createSelector(
	[ getFilteredUploads ],
	getUploadProgress
);
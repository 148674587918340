import {
	Action,
	IMedia,
	MediaDispatch,
	MediaListDispatch,
	MediaUuidDispatch,
	IMediaUpload,
	MediaUploadDispatch
} from "@connect/Interfaces";
import { setActiveTags } from "Data/Actions/UI";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";
import { getAllMedia } from "Data/Selectors/Media";
import { getActiveTags } from "Data/Selectors/UI";

const {
	CREATE_MEDIA,
	DELETE_MEDIA,
	RESET_MEDIA,
	SET_MEDIA,
	UPDATE_MEDIA,
	END_UPLOAD,
	START_UPLOAD,
	UPDATE_PROGRESS,
	CLEAR_UPLOADS
} = ACTION_TYPES.Media;

/**
 * Simple Actions
 */

function createMedia(media: IMedia): Action<MediaDispatch> {
	return new DispatchableAction(CREATE_MEDIA, { media });
}

function deleteMedia(uuid: string): Action<MediaUuidDispatch> {
	return new DispatchableAction(DELETE_MEDIA, { uuid });
}

function resetMedia(): Action<null> {
	return new DispatchableAction(RESET_MEDIA, null);
}

function setMedia(media: IMedia[], reset?: boolean): Action<MediaListDispatch> {
	return new DispatchableAction(SET_MEDIA, { media, reset });
}

function updateMedia(media: IMedia): Action<MediaDispatch> {
	return new DispatchableAction(UPDATE_MEDIA, { media });
}

function removeTagsFromDeletedMedia(deletedMediaUuid: string) {
	return (dispatch, getState) => {
		const state = getState();
		const allMedia = getAllMedia(state);
		const activeTags = getActiveTags(state, "media");
		const deletedMedia = allMedia.find((m) => m.uuid === deletedMediaUuid);
		const deletedMediaTags = deletedMedia ? deletedMedia.tags : [];

		const remainingTags = allMedia
			.filter((m) => m.uuid !== deletedMediaUuid)
			.map((media) => media.tags)
			.reduce((a, b, index, arr) => a.concat(b), []);

		const tagsToRemove = deletedMediaTags.filter((tag) => !remainingTags.includes(tag));

		const newActiveTags = activeTags.filter((tag) => !tagsToRemove.includes(tag));
		dispatch(setActiveTags("media", newActiveTags));
	}
}

/*
*	Simple actions for uploads.
*/

function endUpload(upload: IMediaUpload): Action<MediaUploadDispatch> {
	return new DispatchableAction(END_UPLOAD, { upload });
}

function startUpload(upload: IMediaUpload): Action<MediaUploadDispatch> {
	return new DispatchableAction(START_UPLOAD, { upload });
}

function updateProgress(upload: IMediaUpload): Action<MediaUploadDispatch> {
	return new DispatchableAction(UPDATE_PROGRESS, { upload });
}

function clearUploads(): Action<null> {
	return new DispatchableAction(CLEAR_UPLOADS, null);
}

export {
	createMedia,
	deleteMedia,
	resetMedia,
	setMedia,
	updateMedia,
	removeTagsFromDeletedMedia,
	endUpload,
	startUpload,
	updateProgress,
	clearUploads
};

import { Action, MediaPreviewDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { MediaPreviewState } from "Data/Objects/AppState";
import { createReducer } from "Data/Utils";
import { cloneDeep } from "lodash";

export function setMediaPreview(state: MediaPreviewState, action: Action<MediaPreviewDispatch>) {
	const { uri, vimeoId, type } = action.args;
	let newState = cloneDeep(state);

	newState.vimeoId = vimeoId;
	newState.uri = uri;
	newState.type = type;

	return newState;
}

const {
	SET_MEDIA_PREVIEW
} = ACTION_TYPES.UI;

const reducers = {
	[SET_MEDIA_PREVIEW.type]: setMediaPreview
};

export default createReducer(reducers, MediaPreviewState, new MediaPreviewState());

import * as moment from "moment";
import { createSelector } from "reselect";

import { Deployment, DeploymentCollectionArgs, SortTypes } from "@connect/Interfaces";
import { AppState, DeploymentsState } from "Data/Objects/AppState";
import { getSortedCollection, identityReducer } from "Data/Utils";

export const areDraft = (d) => d.state === "draft";
export const areDeployed = (d) => d.state === "deployed";
export const areCancelled = (d) => d.state === "cancelled";
export const areLive = (now) => (d) => moment(d.endDate).valueOf() >= now.valueOf();
export const areUpcoming = (now) => (d) => moment(d.startDate).valueOf() >= now.valueOf();
export const isPendingApproval = (d) => d.submittedTo?.length && !d.approvedBy;

export function getDeploymentsState(state: AppState): DeploymentsState {
	return state.Deployments;
}

export function getDeployments(state: DeploymentsState): Deployment[] {
	return state.deployments;
}

export function getDeploymentsSearch(state: AppState, args: DeploymentCollectionArgs) {
	return args.search || "";
}

export function getDeploymentsSort(state: AppState, args: DeploymentCollectionArgs) {
	return args.sort || SortTypes.NEWEST_FIRST;
}

export function getDeploymentsStatus(state: AppState, args: DeploymentCollectionArgs) {
	return args.status || "all";
}

export function getDeploymentsType(state: AppState, args: DeploymentCollectionArgs) {
	return args.type || "all";
}

export function getDeployment(deployments: Deployment[], id: string): Deployment | undefined {
	return deployments.find((d) => d.uuid === id);
}

export function getDeploymentsByType(deployments: Deployment[], type: string) {
	if (type === "all") {
		return deployments;
	}

	return deployments.filter((deployment) => deployment.type === type);
}

export function getDeploymentsByStatus(deployments: Deployment[], status: string) {
	if (status === "all") {
		return deployments;
	}

	const now = moment();

	switch (status) {
		case "draft":
			return deployments.filter(areDraft);
		case "deployed":
			return deployments.filter(areDeployed);
		case "live":
			return deployments.filter(areDeployed).filter(areLive(now));
		case "upcoming":
			return deployments.filter(areUpcoming(now));
		default:
			return deployments;
	}
}

export function getDeploymentsSearched(deployments: Deployment[], search: string) {
	return deployments.filter((d) => d.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}

export function getDeploymentsSorted(deployments: Deployment[], sortType: SortTypes) {
	return getSortedCollection<Deployment>(deployments, sortType);
}

export function getApprovers(state: DeploymentsState) {
	if (state.approvers) {
		return state.approvers;
	}

	return [];
}

export const getDeploymentApprovers = createSelector(
	[ getDeploymentsState ],
	getApprovers
);

export const getAllDeployments = createSelector(
	[ getDeploymentsState ],
	getDeployments
);

export const getTypeDeployments = createSelector(
	[ getAllDeployments, getDeploymentsType ],
	getDeploymentsByType
);

export const getStatusDeployments = createSelector(
	[ getTypeDeployments, getDeploymentsStatus ],
	getDeploymentsByStatus
);

export const getSearchedDeployments = createSelector(
	[ getStatusDeployments, getDeploymentsSearch ],
	getDeploymentsSearched
);

export const getSortedDeployments = createSelector(
	[ getSearchedDeployments, getDeploymentsSort ],
	getDeploymentsSorted
);

export const getDeploymentById = createSelector(
	[ getAllDeployments, identityReducer ],
	getDeployment
);

import { ColumnProps } from "antd/lib/table/interface";
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { ApkVersion, CustomCSS } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { Button, Header, Input, Table } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { createVersionAsync, getVersionsAsync, publishVersionAsync } from "Data/Actions/VersionsAsync";

const mapDispatchToProps = (dispatch) => ({
	getVersions: () => dispatch(getVersionsAsync()),
	createVersion: (version: ApkVersion) => dispatch(createVersionAsync(version)),
	publishVersion: (version: ApkVersion) => dispatch(publishVersionAsync(version))
});

const mapStateToProps = (state) => ({
	versions: state.Admin.Versions.versions,
	currentVersion: state.Admin.Versions.currentVersion
});

interface IAdminVersionsState {
	newVersion: ApkVersion;
}

interface IAdminVersionsProps {
	getVersions: () => void;
	createVersion: (version: ApkVersion) => void;
	publishVersion: (version: ApkVersion) => void;
	versions: ApkVersion[];
	currentVersion: ApkVersion;
}

class AdminVersionsPage extends React.Component<IAdminVersionsProps, IAdminVersionsState> {
	constructor(props: IAdminVersionsProps) {
		super(props);

		this.state = {
			newVersion: {} as ApkVersion
		};

		this.styles = {
			flexColumns: {
				flex: "1 0 auto",
				margin: 25,
				padding: 25,
				border: "1px solid " + Colors.darkerGray
			},
			inputs: {
				marginTop: 10
			}
		};

		this.confirmCreateNewVersion = this.confirmCreateNewVersion.bind(this);
		this.updateVersionName = this.updateVersionName.bind(this);
		this.updateVersionUrl = this.updateVersionUrl.bind(this);
	}

	styles: {
		flexColumns: CustomCSS;
		inputs: CustomCSS;
	}

	componentWillMount() {
		this.props.getVersions();
	}

	render() {
		const { flexColumns, inputs } = this.styles;
		const { currentVersion, versions } = this.props;
		const { newVersion } = this.state;

		const columns: ColumnProps<ApkVersion>[] = [ {
			title: "Version",
			dataIndex: "version",
			render: (text, apkVersion: ApkVersion, index) => {
				return (
					<span style={this.getCurrentVersionFontWeight(apkVersion)}>
						{text}
					</span>
				);
			}
		}, {
			title: "URL",
			dataIndex: "uri",
			render: (text, apkVersion: ApkVersion, index) => {
				return (
					<a
						href={text}
						target="_blank"
						rel="noopener noreferrer"
						style={this.getCurrentVersionFontWeight(apkVersion)}>
						{this.getFileNameFromUri(text)}
					</a>
				);
			}
		}, {
			title: "Created",
			dataIndex: "created_at",
			render: (text, apkVersion: ApkVersion, index) => {
				return (
					<span style={this.getCurrentVersionFontWeight(apkVersion)}>
						{this.getLocalTime(text)}
					</span>
				);
			}
		}, {
			title: "Published",
			dataIndex: "published_at",
			render: (text, apkVersion: ApkVersion, index) => {
				return (
					<span style={this.getCurrentVersionFontWeight(apkVersion)}>
						{this.getLocalTime(text)}
					</span>
				);
			}
		}, {
			title: "Publish",
			render: (text, apkVersion: ApkVersion, index) => {
				if (!apkVersion.published_at || currentVersion !== apkVersion) {
					return (
						<Button
							circular
							type="primary"
							icon="rocket"
							onClick={ this.confirmApkVersion(apkVersion) } />
					);
				}

				return null;
			}
		} ];

		return (
			<div style={{ padding: "30px 30px 0px 30px" }}>
				<Header size={3}>Versions</Header>
				<div
					style={{
						display: "flex",
						alignItems: "center"
					}}>
					<div
						style={flexColumns}>
						<Header size={4}>Create a new version</Header>
						<Input
							id="version-name"
							placeholder="Version Name"
							style={inputs}
							updateCallback={ this.updateVersionName }
							value=""
						/>
						<Input
							id="version-url"
							placeholder="URL"
							style={inputs}
							updateCallback={ this.updateVersionUrl }
							value=""
						/>
						<Button
							type="primary"
							style={{float: "right"}}
							disabled={!newVersion.version || !newVersion.uri}
							onClick={ this.confirmCreateNewVersion }>
							Create
						</Button>
					</div>
					<div
						style={flexColumns}>
						<Header size={4} style={{textAlign: "center"}}>Current version</Header>
						<div
							style={{
								textAlign: "center",
								fontSize: "2rem",
								color: Colors.primaryBlue
							}}>
							v{currentVersion ? currentVersion.version : ""}
						</div>
					</div>
				</div>
				<Table
					columns={columns}
					dataSource={versions}
					rowKey={this.getApkId}
					locale={{emptyText: "No versions."}}
					pagination={false}/>
			</div>
		);
	}

	getApkId(apkVersion: ApkVersion) {
		return apkVersion.id.toString()
	}

	getFileNameFromUri(uri: string) {
		return uri.substring(uri.lastIndexOf("/") + 1)
	}

	getLocalTime(dateTime: string) {
		if (!dateTime) {
			return "";
		}

		return Utils.getHumanReadableDate(dateTime);
	}

	getCurrentVersionFontWeight(compareVersion: ApkVersion) {
		return { fontWeight: compareVersion === this.props.currentVersion ? "bold" : "normal" } as CustomCSS;
	}

	confirmApkVersion(apkVersion: ApkVersion) {
		return () => this.confirmPublishVersion(apkVersion);
	}

	confirmCreateNewVersion() {
		const { newVersion } = this.state;
		Notifications.confirm("Create new version?", (
			<div>
				<div>
					<b>Version Name:</b> {newVersion.version}
				</div>
				<div>
					<b>URL:</b>&nbsp;
					<a href={newVersion.uri} target="_blank" rel="noopener noreferrer">
						{this.getFileNameFromUri(newVersion.uri)}
					</a>
				</div>
			</div>
		), "Create", "Cancel", () => this.props.createVersion(newVersion));
	}

	confirmPublishVersion(apkVersion: ApkVersion) {
		Notifications.confirm("Publish new version?",
			apkVersion.version + " will become the current version",
			"Publish", "Cancel", () => this.props.publishVersion(apkVersion));
	}

	updateVersionName(value: string) {
		this.setState((prevState) => {
			return update(prevState, {
				newVersion: {
					version: { $set: value }
				}
			});
		});
	}

	updateVersionUrl(value: string) {
		this.setState((prevState) => {
			return update(prevState, {
				newVersion: {
					uri: { $set: value }
				}
			});
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminVersionsPage);
import * as update from "immutability-helper";
import { match as matchType, matchPath } from "react-router";
import { LOCATION_CHANGE, LocationChangeAction } from "react-router-redux";

import { store } from "@connect/Data";
import { Action, AdComponentDispatch, AdDispatch, AdLivePreviewDispatch, AdMediaArrayDispatch, AdMediaDispatch,
	ComponentIndexDispatch, ComponentResizingDispatch, IAd, MoveComponentDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { recalculateComponentDimensions } from "Data/Objects/AdTemplates";
import { AdBuilderState } from "Data/Objects/AppState";
import { PageUuidType } from "Data/Reducers/UI/activeViews";
import { createReducer } from "Data/Utils";
import { cloneDeep } from "lodash";

/*
 * Reducers related to the Ad Builder UI
 */

const {
	DELETE_COMPONENT,
	MOVE_COMPONENT,
	RESET_AD_BUILDER,
	SET_ACTIVE_AD,
	SET_COMPONENT_RESIZING,
	SET_SELECTED_COMPONENT,
	SET_SLIDESHOW_SLIDE,
	UPDATE_COMPONENT,
	UPDATE_AD_MEDIA,
	UPDATE_ALL_AD_MEDIA,
	UPDATE_LIVE_PREVIEW
} = ACTION_TYPES.UI.AdBuilder;

const {
	UPDATE_AD
} = ACTION_TYPES.Ads;

export function setUuidFromLocation(state: AdBuilderState, action: LocationChangeAction) {
	const { pathname } = action.payload;
	const match: matchType<PageUuidType> | null = matchPath(pathname, {
		path: "/ads/:uuid?"
	});

	if (match && match.params) {
		const { params: { uuid } } = match;

		if (uuid && uuid !== "") {
			const { 0: stateAd } = store.getState().Ads.ads.filter((ad: IAd) => ad.uuid === uuid);
			if (stateAd) {
				return update(state, {
					activeAd: {
						$set: stateAd
					}
				});
			}
		}

		return update(state, {
			activeAd: {
				$set: null
			}
		});
	}

	return state;
}

export function deleteComponent(state: AdBuilderState, action: Action<ComponentIndexDispatch>) {
	return update(state, {
		activeAd: {
			layout: {
				components: {
					$splice: [ [ action.args.componentIndex, 1 ] ]
				}
			}
		}
	});
}

export function moveComponent(state: AdBuilderState, action: Action<MoveComponentDispatch>) {
	const { componentIndex, newIndex } = action.args;
	const component = cloneDeep(state.activeAd.layout.components[componentIndex]);

	return update(state, {
		activeAd: {
			layout: {
				components: {
					$apply: (components) => {
						components.splice(componentIndex, 1);
						components.splice(newIndex, 0, component);

						return recalculateComponentDimensions(components);
					}
				}
			}
		}
	});
}

export function resetAdBuilder(state: AdBuilderState, action: Action<null>) {
	return new AdBuilderState();
}

export function setActiveAd(state: AdBuilderState, action: Action<AdDispatch>) {
	return update(state, {
		activeAd: {
			$set: action.args.ad
		}
	});
}

export function setComponentResizing(state: AdBuilderState, action: Action<ComponentResizingDispatch>) {
	const { componentIndex, resizing } = action.args;

	return update(state, {
		componentIsResizing: {
			[componentIndex]: { $set: resizing }
		}
	});
}

export function setSelectedComponent(state: AdBuilderState, action: Action<ComponentIndexDispatch>) {
	return update(state, {
		selectedComponentIndex: {
			$set: action.args.componentIndex
		}
	});
}

export function setSlideshowCurrentSlide(state: AdBuilderState, action: Action<ComponentIndexDispatch>) {
	return update(state, {
		slideshowCurrentSlide: {
			$set: action.args.componentIndex
		}
	});
}

export function updateComponent(state: AdBuilderState, action: Action<AdComponentDispatch>) {
	const components = update(state.activeAd.layout.components, {
		[action.args.componentIndex]: {
			$set: action.args.component
		}
	});
	return update(state, {
		activeAd: {
			layout: {
				components: {
					$set: recalculateComponentDimensions(components)
				}
			}
		}
	});
}

export function updateLivePreview(state: AdBuilderState, action: Action<AdLivePreviewDispatch>) {
	return update(state, {
		livePreview: { $set: action.args.livePreview }
	});
}

export function updateMedia(state: AdBuilderState, action: Action<AdMediaDispatch>) {
	return update(state, {
		activeAd: {
			layout: {
				media: {
					[action.args.mediaIndex]: {
						$set: action.args.media
					}
				}
			}
		}
	});
}

export function updateMediaArray(state: AdBuilderState, action: Action<AdMediaArrayDispatch>) {
	return update(state, {
		activeAd: {
			layout: {
				media: { $set: action.args.media }
			}
		}
	})
}

export function updateAd(state: AdBuilderState, action: Action<AdDispatch>) {
	const { activeAd } = state;

	if (!activeAd) {
		return state;
	}

	const { uuid: activeUuid } = activeAd;
	const { ad: updatedAd } = action.args;
	const { uuid: updatedUuid } = updatedAd;

	if (activeUuid === updatedUuid) {
		return update(state, {
			activeAd: {
				$merge: updatedAd
			}
		});
	}

	return state;
}

const reducers = {
	[LOCATION_CHANGE]: setUuidFromLocation,
	[DELETE_COMPONENT.type]: deleteComponent,
	[MOVE_COMPONENT.type]: moveComponent,
	[RESET_AD_BUILDER.type]: resetAdBuilder,
	[SET_ACTIVE_AD.type]: setActiveAd,
	[SET_COMPONENT_RESIZING.type]: setComponentResizing,
	[SET_SELECTED_COMPONENT.type]: setSelectedComponent,
	[SET_SLIDESHOW_SLIDE.type]: setSlideshowCurrentSlide,
	[UPDATE_COMPONENT.type]: updateComponent,
	[UPDATE_AD_MEDIA.type]: updateMedia,
	[UPDATE_ALL_AD_MEDIA.type]: updateMediaArray,
	[UPDATE_LIVE_PREVIEW.type]: updateLivePreview,
	[UPDATE_AD.type]: updateAd
}

export default createReducer(reducers, AdBuilderState);
import { Switch } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, DeviceLEDSetting, DeviceLEDSettingName, IDevice, IStore,
	DeviceCapabilities, LightbarPatterns, LightbarPattern} from "@connect/Interfaces";
import AudioSlider from "Components/Global/AudioSlider";
import { Colors } from "Components/Global/Constants";
import Icon from "Components/Global/Icon";
import OptionButton from "Components/Global/OptionButton";
import OptionButtonGroup from "Components/Global/OptionButtonGroup";
import { updateDeviceAsync } from "Data/Actions/Devices";
import { AppState } from "Data/Objects/AppState";
import { getStoreById } from "Data/Selectors/Company";
import { getDeviceHealthModalDevice, getDeviceHealthModalUUID } from "Data/Selectors/UI";
import { checkDeviceCapabilities } from "Data/Objects/Devices";
import { cloneDeep } from "lodash";
import { Select, Truncate } from "Components/Global/Common";

const { Option } = Select;

const { lightGray, black, primaryBlue } = Colors;

const mapStateToProps = (state: AppState) => {
	const selectedUUID = getDeviceHealthModalUUID(state);
	const device = getDeviceHealthModalDevice(state);
	const store = device.store ? getStoreById(state, device.store) : undefined;

	return {
		device,
		selectedUUID,
		store
	};
}

const mapDispatchToProps = (dispatch) => ({
	updateDevice: (device: IDevice) => dispatch(updateDeviceAsync(device))
});

interface DeviceSettingsCard {
	content: JSX.Element | null;
	icon: string;
	rotate?: number;
	title: string;
}

interface DeviceSettingsPanelProps {
	device: IDevice;
	selectedUUID: string;
	store?: IStore;
	updateDevice: (device: IDevice) => void;
}

export class DeviceSettingsPanel extends React.Component<DeviceSettingsPanelProps> {
	constructor(props: DeviceSettingsPanelProps) {
		super(props);

		this.styles = {
			grid: {
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr",
				gridGap: 16,
				margin: 8
			},
			title: {
				color: black,
				fontSize: "1.2em",
				marginBottom: 10
			},
			motion: {
				width: "100%",
				color: lightGray,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-evenly"
			},
			card: {
				boxShadow: "0 4px 8px 0 #F3F3F3, 0 6px 20px 0 #F9F9F9",
				width: 250,
				height: 250,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifySelf: "center",
				paddingTop: 40
			},
			groupButton: {
				width: "22%",
				height: 24,
				padding: 0,
				margin: 0
			},
			iconContainer: {
				margin: "30px 0px 22px",
				color: lightGray
			},
			sleepMode: {
				color: lightGray,
				fontSize: "0.9em",
				textAlign: "center",
				width: "100%"
			},
			unavailable: {
				height: 24,
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				color: lightGray
			},
			cardContent: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				color: lightGray,
				gap: 3
			}
		};

		this.renderCard = this.renderCard.bind(this);
		this.renderSleepModeSwitch = this.renderSleepModeSwitch.bind(this);
		this.handleAudioLevelChanged = this.handleAudioLevelChanged.bind(this);
		this.handleSleepModeChange = this.handleSleepModeChange.bind(this);
		this.toggleLEDSetting = this.toggleLEDSetting.bind(this);
		this.renderLightbarControls = this.renderLightbarControls.bind(this);
		this.renderLightbarPatternOption = this.renderLightbarPatternOption.bind(this);
		this.handleToggleLightbar = this.handleToggleLightbar.bind(this);
		this.handleSetLightbarPreset = this.handleSetLightbarPreset.bind(this);
		this.updateLEDSetting = this.updateLEDSetting.bind(this);
	}

	styles: CustomCSS;

	render() {
		const content = this.getCards().map(this.renderCard);

		return (
			<div style={ this.styles.grid }>
				{ content }
			</div>
		);
	}

	renderCard(card: DeviceSettingsCard) {
		const { title, icon, content, rotate } = card;

		return (
			<div key={ title } style={ this.styles.card }>
				{ this.renderIcon(icon, rotate ?? 0) }
				{ this.renderTitle(title) }
				{ content }
			</div>
		);
	}

	renderTitle(title: string) {
		return (
			<span style={ this.styles.title }>
				{ title }
			</span>
		);
	}

	renderIcon(name: string, rotate: number) {
		return (
			<div style={ this.styles.iconContainer }>
				<Icon
					name={ name }
					size="bigger"
					rotate={ rotate }
				/>
			</div>
		);
	}

	renderSlider() {
		const { audioLevel } = this.props.device;

		return (
			<AudioSlider
				hideMute
				volume={ audioLevel }
				handleChange={ this.handleAudioLevelChanged }
			/>
		);
	}

	renderLEDControls(ledName: DeviceLEDSettingName) {
		const { groupButton } = this.styles;
		const { device } = this.props;

		const settings = this.getSettings(ledName);

		if (!settings) {
			return this.renderFeatureUnavailable();
		}

		if (settings.name === "button_front" && !checkDeviceCapabilities(device.model, DeviceCapabilities.BUTTON_FRONT)) {
			return this.renderFeatureUnavailable();
		}

		const { enabled, flashing } = settings;

		const showSolid = enabled && !flashing;
		const showFlashing = enabled && flashing;

		return (
			<React.Fragment>
				<OptionButtonGroup>
					<OptionButton
						key="off"
						selected={ !enabled }
						style={{
							...groupButton,
							background: !enabled ? primaryBlue : lightGray
						}}
						onClick={ this.toggleLEDSetting(settings, ledName, "enabled") }
					>
						Off
					</OptionButton>
					<OptionButton
						key="flash"
						selected={ showFlashing }
						style={{
							...groupButton,
							background: showFlashing ? primaryBlue : lightGray
						}}
						onClick={ this.toggleLEDSetting(settings, ledName, "flashing") }
					>
						Flash
					</OptionButton>
					<OptionButton
						key="solid"
						selected={ showSolid }
						style={{
							...groupButton,
							background: showSolid ? primaryBlue : lightGray
						}}
						onClick={ this.toggleLEDSetting(settings, ledName, "solid") }
					>
						Solid
					</OptionButton>
				</OptionButtonGroup>

				{ this.renderMotionSwitch(ledName) }
			</React.Fragment>
		);
	}

	renderFeatureUnavailable() {
		return (
			<div style={ this.styles.unavailable }>
				Feature not available on this device
			</div>
		);
	}

	renderSleepModeSwitch() {
		const { device, store } = this.props;
		const checked = device.store && store && store.sleepModeEnabled && device.sleepModeEnabled !== false;

		return (
			<div style={ this.styles.sleepMode }>
				<Switch checked={ !!checked } onClick={ this.handleSleepModeChange } />
				<br /><br />
				When enabled, display will be off after hours.<br />
				Configure hours in the <a href="/admin/stores" target="_blank" rel="noopener noreferrer">Admin/Stores</a> page.
			</div>
		);
	}

	handleSleepModeChange(value: boolean) {
		const { device, updateDevice } = this.props;
		const updatedDevice = Object.assign({}, device, { sleepModeEnabled: value });

		updateDevice(updatedDevice);
	}

	handleAudioLevelChanged(value: number | number[]) {
		const { device, updateDevice } = this.props;
		const updatedDevice = Object.assign({}, device, { audioLevel: value });

		updateDevice(updatedDevice);
	}

	toggleLEDSetting(settings: DeviceLEDSetting, led: DeviceLEDSettingName, key: string) {
		return () => {
			const { device, updateDevice } = this.props;
			const newDevice = cloneDeep(device);
			const { ledSettings } = newDevice;
			const index = ledSettings.findIndex((setting) => setting.name === led);
			const newSettings = newDevice.ledSettings[index];

			// if user clicks a setting but LED is off, turn it on
			if (key !== "enabled") {
				newSettings.enabled = true;
			}

			// custom logic for "solid" since that's a derived property
			if (key === "solid") {
				newSettings.flashing = false;
			} else if (key === "enabled") {
				newSettings[key] = false;
				newSettings.flashing = false;
			} else {
				newSettings[key] = !newSettings[key];
			}

			updateDevice(newDevice);
		}
	}

	updateLEDSetting(name: DeviceLEDSettingName, key: string, value: boolean | number) {
		const { device, updateDevice } = this.props;
		let { ledSettings } = this.props.device;

		return () => {
			const settingIndex = ledSettings.findIndex((s: DeviceLEDSetting) => {
				return s.name === name;
			});

			if (settingIndex < 0) {
				// Our setting wasn't found, return
				return;
			}

			let setting = ledSettings[settingIndex];

			if (key === "flashing" || key === "motion") {
				setting.enabled = true;
			}

			setting[`${key}`] = value;

			ledSettings[settingIndex] = setting;

			updateDevice({
				...device,
				ledSettings
			});
		};
	}

	renderMotionSwitch(ledName: DeviceLEDSettingName) {
		const { device } = this.props
		const settings = this.getSettings(ledName);

		if (!settings || !checkDeviceCapabilities(device.model, DeviceCapabilities.CAMERA)) {
			return null;
		}

		const { enabled, motion } = settings;
		const showMotion = enabled && motion;

		return (
			<span style={ this.styles.motion }>
				Motion Activated:
				<Switch
					size="small"
					checked={ showMotion }
					onChange={ this.toggleLEDSetting(settings, ledName, "motion") }
				/>
			</span>
		);
	}

	renderLightbarControls() {
		const { device: { model } } = this.props ?? {};
		const settings = this.getSettings(DeviceLEDSettingName.Lightbar);
		const { cardContent } = this.styles;

		if (!settings || !checkDeviceCapabilities(model, DeviceCapabilities.LIGHTBAR)) {
			return null;
		}

		return (
			<div style={ cardContent } key={ JSON.stringify(settings) }>
				<Switch defaultChecked={ settings?.enabled } onClick={ this.handleToggleLightbar } />
				<span>Default Pattern:</span>
				<Select
					size="small"
					defaultValue={ settings?.preset ?? 0 }
					onSelect={ this.handleSetLightbarPreset }
				>
					{ LightbarPatterns.map(this.renderLightbarPatternOption) }
				</Select>
			</div>
		)
	}

	renderLightbarPatternOption(pattern: LightbarPattern, index: number) {
		return (
			<Option
				title={ pattern }
				key={ pattern }
				value={ index }
			>
				<Truncate length={ 40 } tooltipDisabled={ true }>{ pattern }</Truncate>
			</Option>
		);
	}

	handleToggleLightbar(value: boolean) {
		this.updateLEDSetting(DeviceLEDSettingName.Lightbar, "enabled", value)();
	}

	handleSetLightbarPreset(presetIndex: number) {
		this.updateLEDSetting(DeviceLEDSettingName.Lightbar, "enabled", true)();
		this.updateLEDSetting(DeviceLEDSettingName.Lightbar, "preset", presetIndex)();
	}

	getSettings(key: DeviceLEDSettingName) {
		const { ledSettings } = this.props.device;

		if (!ledSettings) {
			return null;
		}

		return ledSettings.filter((config) => config.name === key)[0];
	}

	getCards() {
		let cards: DeviceSettingsCard[] = [
			{
				title: "Audio Level",
				icon: "volume-up",
				content: this.renderSlider()
			},
			{
				title: "Front LED",
				icon: "lightbulb-on",
				content: this.renderLEDControls(DeviceLEDSettingName.Front)
			},
			{
				title: "Button LED",
				icon: "circle",
				content: this.renderLEDControls(DeviceLEDSettingName.ButtonFront)
			},
			{
				title: "Lightbar",
				icon: "magic",
				content: this.renderLightbarControls(),
				rotate: 45
			}
		];

		const { device, store } = this.props;

		if (device.store && store && store.sleepModeEnabled) {
			cards.push({
				title: "Sleep Mode",
				icon: "moon-stars",
				content: this.renderSleepModeSwitch()
			});
		}

		return cards;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettingsPanel);
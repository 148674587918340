import { Action, AllUsersDispatch, UserDispatch, UserListDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { AdminUsersState } from "Data/Objects/AppState";
import { createReducer, deleteFromState, getIndexFromState, mergeWithUuidArrays,
	setState, updateStateArray, updateStateArrayItem, pushToState } from "Data/Utils";
import { toggleFeature } from "@connect/Features";

export function setAllUsers(state: AdminUsersState, action: Action<AllUsersDispatch>) {
	const { reset, users } = action.args;
	if (reset) {
		return setState(state, "allUsers", users);
	}

	return mergeWithUuidArrays(state, "allUsers", users);
}

export function setUsers(state: AdminUsersState, action: Action<UserListDispatch>) {
	const { reset, users } = action.args;
	if (reset) {
		return setState(state, "users", users);
	}

	return mergeWithUuidArrays(state, "users", users);
}

export function createUser(state: AdminUsersState, action: Action<UserDispatch>) {
	const { user } = action.args;
	return pushToState(state, "users", user);
}

export function deleteUser(state: AdminUsersState, action: Action<UserDispatch>) {
	const { user } = action.args;
	const index = getIndexFromState(state.users, user.uuid);
	return deleteFromState(state, "users", index);
}

export function updateUser(state: AdminUsersState, action: Action<UserDispatch>) {
	const { user } = action.args;

	if (toggleFeature("notifications", true, false)) {
		return updateUser2(state, action);
	}

	const index = getIndexFromState(state.users, user.uuid);
	return updateStateArray(state, "users", index, user);
}

export function updateUser2(state: AdminUsersState, action: Action<UserDispatch>) {
	const { user } = action.args;
	return updateStateArrayItem(state, "users", user);
}

const {
	SET_ALL_USERS,
	SET_USERS,
	CREATE_USER,
	UPDATE_USER,
	DELETE_USER
} = ACTION_TYPES.Admin.Users;

const reducers = {
	[SET_ALL_USERS.type]: setAllUsers,
	[SET_USERS.type]: setUsers,
	[CREATE_USER.type]: createUser,
	[UPDATE_USER.type]: updateUser,
	[DELETE_USER.type]: deleteUser
};

export default createReducer(reducers, AdminUsersState);

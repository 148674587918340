import * as React from "react";
import { connect } from "react-redux";

import { ActionSetActions, CustomCSS } from "@connect/Interfaces";
import { ActionPropertiesPanelContent,
	ActionPropertiesPanelContentProps } from "Components/Actions/ActionPropertiesPanelContent";
import { LightBarList } from "Components/Actions/Lightbar/LightBarList";
import { updateSelectedActionPreset } from "Data/Actions/ActionsAsync";
import { getSelectedActionPreset, getSelectedActionType } from "Data/Selectors/ActionBuilder";
import { mapStateToProps as superMapStateToProps } from "Components/Actions/ActionPropertiesPanelContent";

const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		activeActionType: getSelectedActionType(state),
		selectedPreset: getSelectedActionPreset(state)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateSelectedPreset: (preset:  number) => dispatch(updateSelectedActionPreset(preset))
	}
}

interface LightbarActionPropertiesPanelContentProps extends ActionPropertiesPanelContentProps {
	selectedPreset: number;
	updateSelectedPreset: (preset: number) => void;
}

const styles = {
	lightbarListScrollbox: {
		height: 240,
		overflowY: "auto",
		padding: 5,
		backgroundColor: "black"
	}
} as CustomCSS;

export class LightbarActionPropertiesPanelContent
	extends ActionPropertiesPanelContent<LightbarActionPropertiesPanelContentProps> {
	constructor(props: LightbarActionPropertiesPanelContentProps) {
		super(props);

		const { icon, title } = ActionSetActions.lightbar;

		this.icon = icon;
		this.title = title;
	}

	render() {
		return this.renderContainer(this.renderContent());
	}

	renderContent() {
		const { selectedPreset, updateSelectedPreset } = this.props;
		const { lightbarListScrollbox } = styles;

		return (
			<div style={ lightbarListScrollbox }>
				<LightBarList
					onSelect={ updateSelectedPreset }
					selected={ selectedPreset }/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LightbarActionPropertiesPanelContent);
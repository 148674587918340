import { store } from "@connect/Data";
import { getCurrentUser } from "Data/Selectors/User";

const ROLES = {
	ALL: {
		value: "",
		text: "All"
	},
	CEC_ADMIN: {
		value: "cec-admin",
		text: "CEC Admin"
	},
	CEC_SUPPORT: {
		value: "cec-support",
		text: "CEC Support"
	},
	INTEGRATOR: {
		value: "integrator",
		text: "Integrator"
	},
	COMPANY_OWNER: {
		value: "owner",
		text: "Company Owner"
	},
	COMPANY_USER: {
		value: "user",
		text: "Company User"
	},
	MARKETER: {
		value: "marketer",
		text: "Marketer"
	}
}

function isAdmin() {
	const user = getCurrentUser(store.getState());

	if (!(user && user.role)) {
		return false;
	}

	switch (user.role.name) {
		case ROLES.CEC_ADMIN.value:
		case ROLES.CEC_SUPPORT.value:
			return true;
		default: return false;
	}
}

function isCompanyOwner() {
	const user = getCurrentUser(store.getState());

	if (!(user && user.role)) {
		return false;
	}

	return user.role.name === ROLES.COMPANY_OWNER.value;
}

export { ROLES, isAdmin, isCompanyOwner };
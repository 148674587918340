import { Popover } from "antd";
import Resizable, { ResizableDirection } from "re-resizable";
import * as React from "react";
import { DragSource, DropTarget, DropTargetMonitor } from "react-dnd";
import { findDOMNode } from "react-dom";
import { connect as connectToRedux } from "react-redux";

import { CustomCSS, IDeploymentSchedule, PlaylistAd } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import SideScroller from "Components/Global/SideScroller";
import {
	addScheduleItem, deleteScheduleItem, moveScheduleItem,
	resizeScheduleItem
} from "Data/Actions/UI/DeploymentWizard";

const mapDispatchToProps = (dispatch, { containerHeight }) => ({
	onAddItem: (item: IDeploymentSchedule, y: number, hoverIndex: number) =>
		dispatch(addScheduleItem(item, y, containerHeight, hoverIndex)),
	onDeleteItem: (item: IDeploymentSchedule) => dispatch(deleteScheduleItem(item)),
	onMoveItem: (dragIndex: number, hoverIndex: number) => dispatch(moveScheduleItem(dragIndex, hoverIndex)),
	onResizeItem: (item: IDeploymentSchedule, height: number) =>
		dispatch(resizeScheduleItem(item, height, containerHeight))
});

interface IScheduledItemProps {
	fromTop: number;
	resizable: boolean;
	containerHeight: number;
	listId: number;
	index: number;
	item: IDeploymentSchedule;
	onMoveItem: (dragIndex: number, hoverIndex: number) => void;
	onAddItem: (item: IDeploymentSchedule, y: number, hoverIndex: number) => void;
	onDeleteItem: (item: IDeploymentSchedule) => void;
	onResizeItem: (item: IDeploymentSchedule, height: number) => void;
	connectDropTarget?: any;
	currentlyDragging?: boolean;
	connectDragSource?: Function;
	minHeight: number;
	maxHeight: number;
}

class ScheduledItem extends React.Component <IScheduledItemProps> {
	constructor(props: IScheduledItemProps) {
		super(props);

		this.styles = {
			base: {
				paddingTop: -2,
				userSelect: "none",
				border: "1px solid lightgray",
				padding: "0px 4px",
				height: "100%",
				textAlign: "center",
				fontWeight: 500,
				fontSize: 14,
				backgroundColor: props.item.color
			},
			close: {
				display: "inline",
				float: "right",
				marginTop: -3,
				marginRight: -3
			},
			time: {
				display: "block",
				fontSize: 10.5,
				background: "#fff",
				border: "1px solid lightgray",
				position: "absolute",
				top: 2,
				left: 2,
				height: 15,
				width: 68,
				overflow: "hidden"
			},
			ellipsis: {
				position: "absolute",
				bottom: -4,
				zIndex: 1000,
				pointerEvents: "none",
				width: "100%",
				textAlign: "center",
				color: Colors.darkerGray
			},
			name: {
				position: "relative",
				top: -2
			},
			handleStyle: {
				width: "100%",
				bottom: -7
			},
			preview: {
				display: "inline",
				padding: "0 5px"
			},
			previewIcon: {
				color: Colors.gray
			},
			thumbnail: {
				height: 120,
				display: "flex"
			},
			thumbnailContainer: {
				maxWidth: 700,
				paddingLeft: 10,
				paddingRight: 10
			}
		}

		this.deleteItem = this.deleteItem.bind(this);
		this.renderPlaylistAdThumbnail = this.renderPlaylistAdThumbnail.bind(this);
		this.renderThumbnail = this.renderThumbnail.bind(this);
		this.resizeItem = this.resizeItem.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { item, currentlyDragging, connectDragSource, connectDropTarget,
			containerHeight, minHeight, maxHeight, resizable } = this.props;
		const { base, close, time, ellipsis, name, handleStyle, preview, previewIcon } = this.styles;
		const opacity = currentlyDragging ? 0 : 1;
		const handleHeight = 6;
		const snapSections = 96;
		const content = (
			<div style={{
				...base,
				opacity
			}}>
				<Icon
					style={close}
					size="smallest"
					name="times"
					onClick={this.deleteItem} />
				<span style={name}>
					<Popover placement="bottom"
						content={ this.renderThumbnail() }>
						<div style={ preview }>
							<Icon name="eye" size="smaller" style={ previewIcon } />
						</div>
					</Popover>
					<Truncate length={ 36 }>{ item.name }</Truncate>
				</span>
				<div style={time}>
					{Utils.getHoursMinutesFromStartTime(item.startTime)}
				</div>
				<span style={{
					...ellipsis,
					display: resizable ? "block" : "none"
				}}>
					...
				</span>
			</div>
		);
		const targetContent = connectDropTarget ? connectDropTarget(content) : content;
		const sourceContent = connectDragSource ? connectDragSource(targetContent) : targetContent;

		return (
			<Resizable
				key={ item.uuid }
				maxHeight={maxHeight}
				minHeight={minHeight}
				grid={[ 1, containerHeight / snapSections ]}
				size={{
					width: "100%",
					height: Utils.getHeightFromDuration(item.duration, containerHeight) + "px"
				}}
				enable={{
					top: false, right: false, bottom: resizable,
					left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false
				}}
				handleStyles={{
					bottom: {
						border: resizable ? handleHeight + "px solid white" : "none",
						...handleStyle
					}
				}}
				onResize={this.resizeItem}
			>
				{ sourceContent }
			</Resizable>
		);
	}

	renderThumbnail() {
		const { item } = this.props;
		const { thumbnailContainer } = this.styles;

		if (!item || !item.ads || !item.ads.length) {
			return (
				<div style={ thumbnailContainer }>
					No playlist preview available
				</div>
			);
		}

		const thumbnails = item.ads.map(this.renderPlaylistAdThumbnail);

		return (
			<div style={ thumbnailContainer }>
				<SideScroller id={ item.uuid }>
					{ thumbnails }
				</SideScroller>
			</div>
		);
	}

	renderPlaylistAdThumbnail(ad: PlaylistAd) {
		// playlists are unique in this context but ads are not
		// so for each ad we additionally append the key with the playlist uuid
		return (
			<img
				key={ this.props.item.uuid + ad.uuid }
				src={ ad.thumbnail }
				style={ this.styles.thumbnail } />
		)
	}

	deleteItem() {
		const { item, onDeleteItem } = this.props;

		onDeleteItem(item);
	}

	resizeItem(event: MouseEvent | TouchEvent, direction: ResizableDirection, ref: HTMLDivElement) {
		const { item, minHeight, onResizeItem } = this.props;

		if (onResizeItem) {
			const height = ref.clientHeight > minHeight ? ref.clientHeight : minHeight;
			onResizeItem(item, height);
		}
	}
}

const playlistSource = {
	beginDrag(props: any) {
		const { index, listId, item } = props;

		return {
			index,
			listId,
			schedule: item
		};
	}
};

const scheduleTarget = {
	hover(props: IScheduledItemProps, monitor: DropTargetMonitor, component: React.Component) {
		const { index, onMoveItem, listId } = props
		const item = (monitor.getItem() as IScheduledItemProps);
		const { index: dragIndex, listId: sourceListId } = item;
		const hoverIndex = index;
		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// Determine rectangle on screen
		const hoverBoundingRect = (findDOMNode(component) as Element).getBoundingClientRect();

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

		// Determine mouse position
		const clientOffset = monitor.getClientOffset() || { y: 0 };

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top;

		// Only perform the move when the mouse has crossed half of the items height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			return;
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			return;
		}

		// Time to actually perform the action
		if (onMoveItem && listId === sourceListId) {
			onMoveItem(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			(monitor.getItem() as {index: number}).index = hoverIndex;
		}
	},

	drop(props: IScheduledItemProps, monitor: DropTargetMonitor, component: React.Component) {
		const { fromTop, index, onAddItem, item } = props;
		const { uuid } = item;
		const dragIndex = (monitor.getItem() as IScheduledItemProps).index;
		const hoverIndex = index;
		let clientOffset = monitor.getClientOffset() || { y: 0 };
		clientOffset.y -= fromTop;

		// Don't replace items with themselves... instead return undefined per react-dnd spec
		if (dragIndex === hoverIndex) {
			return undefined;
		}

		const sourceObj = monitor.getItem();

		if (onAddItem) {
			onAddItem(sourceObj, clientOffset.y, hoverIndex);
		}

		return {
			listId: uuid
		};
	}
};

export default DragSource("PLAYLIST", playlistSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	currentlyDragging: monitor.isDragging()
}))(connectToRedux(null, mapDispatchToProps)(
	DropTarget("PLAYLIST", scheduleTarget, connect => ({
		connectDropTarget: connect.dropTarget()
	}))(ScheduledItem)))
import { ErrorResultV2, IIntegratorsApi, TeamsResult,
	TeamsSyncResult, TeamUsersResult } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

const teams = "integrators";
const users = "integrators/users";
const getUrl = (path: string, uuid?: string) => {
	if (!uuid) {
		return path;
	}

	return `companies/${ uuid }/${path}`
}

export default class IntegratorsApi extends ApiV2 implements IIntegratorsApi {
	assignIntegrationTeams(teamIds: string[], company?: string) {
		return this.POST(getUrl(teams, company), { teams: teamIds }) as Promise<ErrorResultV2>;
	}

	getIntegrationTeams(company?: string) {
		return this.GET(getUrl(teams, company)) as Promise<TeamsResult>;
	}

	getAdminIntegratorList(perPage: number, page: number) {
		return this.GET("integration-teams", { perPage, page }, true) as Promise<TeamsResult>;
	}

	removeIntegrationTeams(teamIds: string[], company?: string) {
		return this.DELETE(getUrl(teams, company), { teams: teamIds }) as Promise<ErrorResultV2>;
	}

	syncIntegrationTeams(teamIds: string[], company?: string) {
		return this.PUT(getUrl(teams, company), { teams: teamIds }) as Promise<TeamsSyncResult>;
	}

	assignIntegrationTeamUsers(userIds: string[], company?: string) {
		return this.POST(getUrl(users, company), { users: userIds }) as Promise<ErrorResultV2>;
	}

	getIntegrationTeamUsers(company?: string) {
		return this.GET(getUrl(users, company)) as Promise<TeamUsersResult>;
	}

	removeIntegrationTeamUsers(userIds: string[], company?: string) {
		return this.DELETE(getUrl(users, company), { users: userIds }) as Promise<ErrorResultV2>;
	}

	syncIntegrationTeamUsers(userIds: string[], company?: string) {
		return this.PUT(getUrl(users, company), { users: userIds }) as Promise<TeamsSyncResult>;
	}
}

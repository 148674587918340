import * as update from "immutability-helper";
import { push } from "react-router-redux";

import { getState as getDataState } from "@connect/Data";
import { AdMediaLink, IAd, IBaseComponent, ISlideshowComponent, ITickerComponent } from "@connect/Interfaces";
import { clearAdAsync, createAdAsync, getAdAsync, updateAdAsync as updateAdsAd } from "Data/Actions/AdsAsync";
import { setUnsaved } from "Data/Actions/System";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { Ad } from "Data/Objects/Ads";
import { recalculateComponentDimensions, TickerComponent } from "Data/Objects/AdTemplates";
import { getActiveAd } from "Data/Selectors/AdBuilder";
import { getActiveAd as getAdByUuid } from "Data/Selectors/Ads";
import { getMediaById } from "Data/Selectors/Media";
import { cloneDeep } from "lodash";

// INTERNAL ONLY
function saveAndSetSaved() {
	return (dispatch) => {
		const activeAd = getActiveAd(getDataState());

		if (activeAd) {
			return dispatch(updateAdAsync(activeAd));
		}

		return null;
	};
}

function updateAdAsync(ad: IAd, suppressSetActiveAd?: boolean) {
	return (dispatch) => {
		const newAd = Object.assign({}, ad, {
			updatedAt: Date.now()
		});

		return dispatch(updateAdsAd(newAd))
			.then(() => {
				if (suppressSetActiveAd) {
					return dispatch(setUnsaved("ads", false));
				}

				dispatch(setActiveAdSaving(newAd));
			});
	}
}

function updateDuration(ad: IAd) {
	const { layout: { components, media }} = ad;
	const hasType = (type: string) => (c) => c.type === type;
	const video = components.find(hasType("video"));
	const slideshow = components.find(hasType("slideshow"));
	// make sure we don't have an ad with "0" duration -- should always be a positive value
	const durationOrDefault = (duration?: number) => duration || Ad.DEFAULT_DURATION;

	return update(ad, {
		duration: { $apply: (duration) => {
			if (!!video) {
				const videoMedia = media.filter(({ layoutPosition }) => layoutPosition === video.id);
				const videoId = videoMedia[0] && videoMedia[0].mediaId;

				if (!videoId) {
					return durationOrDefault();
				}

				const vMedia = getMediaById(getDataState(), { uuid: videoId });

				return durationOrDefault(vMedia ? vMedia.duration : 0);
			}

			// if we don't have a video but we do have a slideshow
			if (!!slideshow) {
				const slideshowMedia = media.filter(({ layoutPosition }) => layoutPosition === slideshow.id);
				const slideshowDuration = (slideshow as ISlideshowComponent).durationPerSlide * slideshowMedia.length;

				return durationOrDefault(slideshowDuration);
			}

			// if we don't have a video OR slideshow
			return durationOrDefault(duration);
		}}
	});
}

/*
 * Actions related to the Ad Builder UI
 */

const {
	DELETE_COMPONENT,
	MOVE_COMPONENT,
	RESET_AD_BUILDER,
	SET_ACTIVE_AD,
	SET_COMPONENT_RESIZING,
	SET_SELECTED_COMPONENT,
	SET_SLIDESHOW_SLIDE,
	UPDATE_COMPONENT,
	UPDATE_AD_MEDIA,
	UPDATE_ALL_AD_MEDIA,
	UPDATE_LIVE_PREVIEW
} = ACTION_TYPES.UI.AdBuilder;

function deleteComponent(componentIndex: number) {
	return {
		type: DELETE_COMPONENT.type,
		args: {
			componentIndex
		}
	}
}

function moveComponent(componentIndex: number, newIndex: number) {
	return {
		type: MOVE_COMPONENT.type,
		args: { componentIndex, newIndex }
	};
}

export function resetAdBuilder() {
	return {
		type: RESET_AD_BUILDER.type,
		args: null
	};
}

function setActiveAd(ad: IAd) {
	return {
		type: SET_ACTIVE_AD.type,
		args: {
			ad
		}
	}
}

function setComponentResizing(componentIndex: number, resizing: boolean) {
	return {
		type: SET_COMPONENT_RESIZING.type,
		args: {
			componentIndex,
			resizing
		}
	}
}

export function setSelectedComponent(componentIndex: number) {
	return {
		type: SET_SELECTED_COMPONENT.type,
		args: {
			componentIndex
		}
	}
}

export function setSlideshowCurrentSlide(componentIndex: number) {
	return {
		type: SET_SLIDESHOW_SLIDE.type,
		args: {
			componentIndex
		}
	}
}

function updateComponent(componentIndex: number, component: IBaseComponent) {
	return {
		type: UPDATE_COMPONENT.type,
		args: {
			componentIndex,
			component
		}
	}
}

function updateAdMedia(mediaIndex: number, media: AdMediaLink) {
	return {
		type: UPDATE_AD_MEDIA.type,
		args: {
			mediaIndex,
			media
		}
	}
}

function updateAllAdMedia(media: AdMediaLink[]) {
	return {
		type: UPDATE_ALL_AD_MEDIA.type,
		args: {
			media
		}
	}
}

function updateLivePreview(livePreview: boolean) {
	return {
		type: UPDATE_LIVE_PREVIEW.type,
		args: {
			livePreview
		}
	};
}

// Async actions

export function addComponent(newComponent: IBaseComponent, hoverIndex: number) {
	return (dispatch, getState) => {
		dispatch(setUnsaved("ads", true));

		const activeAd = getActiveAd(getState());

		if (!activeAd) {
			return null;
		}

		const components = [ ...activeAd.layout.components ];

		components.splice(hoverIndex, 0, newComponent);

		const newAd = updateDuration(update(activeAd, {
			layout: {
				components: { $set: recalculateComponentDimensions(components) }
			}
		}));

		return dispatch(updateAdAsync(newAd)).then(() => {
			const index = newAd.layout.components.findIndex(({ id }) => id === newComponent.id);

			dispatch(setSelectedComponent(index));
		});
	};
}

export function addMediaToComponent(componentIndex: number, mediaIds: string[], type: string) {
	return (dispatch, getState) => {
		dispatch(setUnsaved("ads", true));

		const activeAd = getActiveAd(getState());

		if (!activeAd) {
			return null;
		}

		const { layout: { components, media }} = activeAd;
		const component = components[componentIndex];
		const notSlideshow = type !== "slideshow";
		const componentMedia = media.filter((m) => m.layoutPosition === component.id);
		const getSortNumber = (index) => {
			if (notSlideshow || (!media.length && index === 0)) {
				return 0;
			}

			// increment the "sort" property by one to maintain order
			const slideshowMediaSorts = componentMedia.map((m) => m.sort);
			// make sure we don't return -Infinity
			const highestSort = !slideshowMediaSorts.length ? 0 : Math.max(...slideshowMediaSorts);

			return highestSort + 1 + index;
		};
		const newMediaForAd = mediaIds.map((mediaId, index) => ({
			layoutPosition: component.id,
			mediaId,
			sort: getSortNumber(index)
		}));
		const mediaCommand = notSlideshow ? {
			$apply: (adMediaLinks) => {
				const newMedia = [ ...adMediaLinks ].filter(({ layoutPosition }) => layoutPosition !== component.id);

				newMedia.push(...newMediaForAd);

				return newMedia;
			}
		} : {
			$set: [ ...media, ...newMediaForAd ]
		};
		const updatedAd = updateDuration(update(activeAd, {
			layout: {
				media: mediaCommand
			}
		}));

		return dispatch(updateAdAsync(updatedAd));
	};
}

export function clearAd(ad: IAd) {
	return (dispatch, getState) => {
		dispatch(setUnsaved("ads", true));

		return dispatch(clearAdAsync(ad)).then(() => {
			const updatedAd = getAdByUuid(getState(), ad.uuid);

			dispatch(updateAdAsync(updatedAd));
		});
	};
}

export function clearComponent(componentIndex: number) {
	return (dispatch, getState) => {
		dispatch(setUnsaved("ads", true));

		const activeAd = getActiveAd(getState());

		if (!activeAd) {
			return null;
		}

		const { layout: { components, media }} = activeAd;
		let component = cloneDeep(components[componentIndex]);
		const { id, type } = component;

		if (type === "ticker") {
			TickerComponent.setDefaults(component as ITickerComponent);

			dispatch(updateComponent(componentIndex, component));
		}

		if (type === "feed") {
			component = Object.assign({}, component, { banner: null });
			dispatch(updateComponent(componentIndex, component));
		}

		const updatedMedia = media.filter((m) => m.layoutPosition !== id);
		const newAd = updateDuration(update(activeAd, {
			layout: {
				components: {
					[ componentIndex ]: { $set: component }
				},
				media: { $set: updatedMedia }
			}
		}));

		return dispatch(updateAdAsync(newAd));
	};
}

export function deleteComponentCard(componentIndex: number) {
	return (dispatch, getState) => {
		dispatch(setUnsaved("ads", true));

		const activeAd = getActiveAd(getState());

		if (!activeAd) {
			return null;
		}

		const component = activeAd.layout.components[componentIndex];
		const updatedMedia = activeAd.layout.media.filter((m) => m.layoutPosition !== component.id);
		const newAd = updateDuration(update(activeAd, {
			layout: {
				components: { $splice: [ [ componentIndex, 1 ] ] },
				media: { $set: updatedMedia }
			}
		}));

		return dispatch(updateAdAsync(newAd));
	};
}

export function createNewActiveAd(ad: IAd) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));

		return dispatch(createAdAsync(ad)).then((newAd) => {
			dispatch(setActiveAdSaving(newAd));
			dispatch(push(`/ads/${newAd.uuid}`));
			return newAd;
		});
	};
}

export function getNewActiveAd(uuid: string) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));

		return dispatch(getAdAsync(uuid)).then((ad) => {
			dispatch(setUnsaved("ads", false));
			dispatch(push(`/ads/${uuid}`));
		}, (err) => {
			// we only get here if the request for the ad comes back as unavailable
			// which means the ad does not belong to the currently active company
			// so take us back to the ads page
			dispatch(setUnsaved("ads", false));
			dispatch(push("/ads"));
		});
	};
}

export function removeMediaFromComponent(componentIndex: number, mediaIds: string[], type: string,
	indexToRemove?: number) {
	return (dispatch, getState) => {
		dispatch(setUnsaved("ads", true));

		const activeAd = getActiveAd(getState());

		if (!activeAd) {
			return null;
		}

		const { components, media } = activeAd.layout;
		const component = components[componentIndex];
		let newMedia = media.filter(({ layoutPosition, mediaId, sort }) => {
			// In the edge case where we need an index to remove, return false
			if (layoutPosition === component.id && indexToRemove !== null && indexToRemove === sort) {
				return false;
			}

			return layoutPosition !== component.id || mediaIds.indexOf(mediaId) === -1;
		});

		// Fix weird issues with sort here
		newMedia = newMedia.map((m, index) => {
			return {
				...m,
				sort: index
			}
		});

		const updatedAd = updateDuration(update(activeAd, {
			layout: {
				media: { $set: newMedia }
			}
		}));

		return dispatch(updateAdAsync(updatedAd));
	};
}

export function saveActiveAd(ad: IAd) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));

		dispatch(updateAdAsync(ad));
	};
}

export function updateAdDuration(duration?: string) {
	return (dispatch, getState) => {
		dispatch(setUnsaved("ads", true));

		const activeAd = getActiveAd(getState());

		if (!activeAd) {
			return null;
		}

		let newAd: IAd;

		if (duration !== undefined) {
			newAd = update(activeAd, {
				duration: { $set: parseInt(duration, 10) }
			});
		} else {
			newAd = updateDuration(activeAd);
		}

		return dispatch(updateAdAsync(newAd));
	};
}

export function updateAdName(name: string, suppressSetActiveAd?: boolean) {
	return (dispatch, getState) => {
		dispatch(setUnsaved("ads", true));

		const activeAd = getActiveAd(getState());
		const newAd = update(activeAd, {
			name: { $set: name }
		});

		dispatch(updateAdAsync(newAd, suppressSetActiveAd));
	};
}

export function updateAdTags(tags: string[]) {
	return (dispatch, getState) => {
		dispatch(setUnsaved("ads", true));

		const activeAd = getActiveAd(getState());
		const newAd = update(activeAd, {
			tags: { $set: tags }
		});

		dispatch(updateAdAsync(newAd));
	};
}

// actions that also set the "unsaved" flag

function deleteComponentSaving(componentIndex: number) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));
		dispatch(deleteComponent(componentIndex));
		dispatch(saveAndSetSaved());
	};
}

function moveComponentSaving(componentIndex: number, newIndex: number) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));
		dispatch(moveComponent(componentIndex, newIndex));
	};
}

// to be used after move/update component
function saveUnsavedAd() {
	return (dispatch) => {
		dispatch(saveAndSetSaved());
	};
}

function setActiveAdSaving(ad: IAd) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", false));
		dispatch(setActiveAd(ad));
	};
}

function setComponentResizingSaving(componentIndex: number, resizing: boolean) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));
		dispatch(setComponentResizing(componentIndex, resizing));
		dispatch(saveAndSetSaved());
	};
}

function updateComponentSaving(componentIndex: number, component: IBaseComponent, save?: boolean) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));
		dispatch(updateComponent(componentIndex, component));

		if (save) {
			dispatch(saveAndSetSaved());
		}
	};
}

function updateAdMediaSaving(mediaIndex: number, media: AdMediaLink) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));
		dispatch(updateAdMedia(mediaIndex, media));
		dispatch(saveAndSetSaved());
	};
}

function updateAllAdMediaSaving(media: AdMediaLink[]) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));
		dispatch(updateAllAdMedia(media));
		dispatch(saveAndSetSaved());
	};
}

function updateLivePreviewSaving(livePreview: boolean) {
	return (dispatch) => {
		dispatch(setUnsaved("ads", true));
		dispatch(updateLivePreview(livePreview));
		dispatch(saveAndSetSaved());
	};
}

export {
	deleteComponentSaving as deleteComponent,
	moveComponentSaving as moveComponent,
	saveUnsavedAd,
	setActiveAdSaving as setActiveAd,
	setComponentResizingSaving as setComponentResizing,
	updateComponentSaving as updateComponent,
	updateAdMediaSaving as updateAdMedia,
	updateAllAdMediaSaving as updateAllAdMedia,
	updateLivePreviewSaving as updateLivePreview
};
import { isEqual } from "lodash";
import { createSelector } from "reselect";

import { AnalyticsDataResult, AnalyticsResult, DeviceStats,
	ExtendedDeviceStats, FilterState, IAd, IDevice, SortTypes, StringOrStringArrayObject,
	Filters,
	Sorts} from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { AdsState, AppState, AsyncState } from "Data/Objects/AppState";
import { getActiveAdByUuid, getAdsState, getAllAds, getAppState } from "Data/Selectors/Ads";
import { getAnalyticsAsyncQuery, getNestedAsyncState } from "Data/Selectors/Async";
import { getStoreByUUID, getStores } from "Data/Selectors/Company";
import { getAllDevices, getDeviceByUUID } from "Data/Selectors/Devices";
import { getActiveFilters, getActiveSorts, getActiveUuid } from "Data/Selectors/UI";

export function generateExtendedDeviceStats(deviceStats: DeviceStats[], dataResults: AnalyticsDataResult[]) {
	return deviceStats.map((stat: DeviceStats) => {
		let extendedStat = {
			...stat,
			views: 0,
			dwellTime: 0,
			button: 0,
			alarm0: 0,
			alarm1: 0,
			motion: 0
		} as ExtendedDeviceStats;
		return dataResults
			.filter((dataResult: AnalyticsDataResult) => {
				return isEqual(stat.device, dataResult.device)
						&& isEqual(stat.store, dataResult.store)
			})
			.reduce((currExtendedStat: ExtendedDeviceStats, nextDataResult: AnalyticsDataResult) => {
				currExtendedStat.views += nextDataResult.views;
				currExtendedStat.dwellTime += nextDataResult.dwellTime;
				currExtendedStat.button += nextDataResult.buttonFront;
				currExtendedStat.alarm0 += nextDataResult.alarm0;
				currExtendedStat.alarm1 += nextDataResult.alarm1;
				currExtendedStat.motion += nextDataResult.motion;
				return currExtendedStat;
			}, extendedStat);
	});
}

export function getActiveAnalyticsUuid(state: AppState) {
	return getActiveUuid(state, "analytics");
}

export function filterAnalyticsData(result: AnalyticsResult, filters: FilterState) {
	if (!result || !result.data) {
		return [];
	}

	const { data } = result;
	const { dateRange, devices, sizes, stores } = filters;
	const [ startDate, endDate ] = (dateRange as string).split("_");
	const datesInRange = Utils.getDaysBetweenDates(startDate, endDate);

	// filter by date
	const dateFilteredResult = dateRange ? data.filter((d) => datesInRange.includes(d.date)) : data;

	// filter by device size
	const sizeFilteredResult = sizes.length ? dateFilteredResult.filter((d) => sizes.includes(d.size.toString()))
		: dateFilteredResult;

	// filter by stores
	const storeFilteredResult = stores.length ? sizeFilteredResult.filter((d) => {
		return d.store && d.store.uuid ? stores.includes(d.store.uuid) : false;
	}) : sizeFilteredResult;

	// filter by devices
	const deviceFilteredResult = devices && devices.length ? storeFilteredResult.filter((d) => {
		return d.device && d.device.uuid && devices.includes(d.device.uuid);
	}) : storeFilteredResult;

	return deviceFilteredResult;
}

export function getActiveAnalyticsFilters(state: AppState) {
	return getActiveFilters(state, Filters.ANALYTICS);
}

export function getDeviceStats(result: AnalyticsResult) {
	return result && result.deviceStats ? result.deviceStats : [];
}

export function getCurrentlyFetching(asyncState: AsyncState) {
	return asyncState ? asyncState.currentlyFetching : null;
}

export function getDateRange(filters: FilterState) {
	return filters ? filters.dateRange : null;
}

export function getAnalyticsDeviceCount(data: AnalyticsDataResult[]) {
	return data && data.length ? data
		.map((a) => a.device.uuid)
		.filter((item, index, arr) => arr.indexOf(item) === index)
		.length : 0;
}

export function getTopUuid(data: AnalyticsDataResult[], key: string) {
	if (!data || !data.length) {
		return null;
	}

	const sortedAnalytics = Utils.sort(data
		.reduce(Utils.incrementByNameUuid(key, "views"), []), "count")
		.filter((result) => !!result.value);

	return sortedAnalytics[0] && sortedAnalytics[0].value && sortedAnalytics[0].value.uuid;
}

export function getTopDevice(data: AnalyticsDataResult[]) {
	return getTopUuid(data, "device");
}

export function getTopStore(data: AnalyticsDataResult[]) {
	return getTopUuid(data, "store");
}

export function getPeakTraffic(results: AnalyticsResult) {
	return results && results.peaks && results.peaks.traffic;
}

export function getDeviceStoreUuid(device: IDevice) {
	return device && device.store;
}

export function getTimeOnScreen(result: AnalyticsResult) {
	return result && result.timeOnScreen;
}

export function getAnalyticsNotification(result: AnalyticsResult) {
	return result && result.user && result.user.notification;
}

export function getPendingReports(state: AdsState) {
	return state && state.pendingReports;
}

export function getAnalyticsReportPending(uuid: string, pendingReports: string[]) {
	return pendingReports.includes(uuid);
}

export function getAnalytics(state: AdsState): AnalyticsResult[] {
	return state.analytics;
}

export function getAnalyticsByUuid(state: AnalyticsResult[], uuid: string) {
	return state.filter((analytics) => analytics.uuid === uuid)[0];
}

export function sortAds(ads: IAd[], sortType: SortTypes) {
	return Utils.sortListBySortType(ads, sortType, "analytics");
}

export function filterAds(ads: IAd[], filter: string) {
	if (!filter) {
		return ads;
	}
	const filterText = filter.toLowerCase();
	return ads.filter((ad) => ad.name && ad.name.toLowerCase().includes(filterText));
}

export function getAdsWithAnalytics(ads: IAd[]) {
	return ads.filter((ad: IAd) => !!ad.analytics);
}

export function getFilter(filterObject: StringOrStringArrayObject) {
	return filterObject && filterObject.filter;
}

export function getSort(state: AppState) {
	return getActiveSorts(state, Sorts.ANALYTICS) as SortTypes;
}

export const getAllAnalytics = createSelector(
	[ getAdsState ],
	getAnalytics
);

export const getActiveAnalytics = createSelector(
	[ getAllAnalytics, getActiveAnalyticsUuid ],
	getAnalyticsByUuid
);

export const getActiveAnalyticsResult = createSelector(
	[ getAppState, getActiveAnalyticsUuid ],
	getActiveAnalytics
);

export const getActiveFilteredAnalyticsData = createSelector(
	[ getActiveAnalyticsResult, getActiveAnalyticsFilters ],
	filterAnalyticsData
);

export const getActiveDeviceStats = createSelector(
	[ getActiveAnalyticsResult ],
	getDeviceStats
);

export const getActiveExtendedDeviceStats = createSelector(
	[ getActiveDeviceStats, getActiveFilteredAnalyticsData ],
	generateExtendedDeviceStats
);

export const getActiveAnalyticsDateRange = createSelector(
	[ getActiveAnalyticsFilters ],
	getDateRange
);

export const getActiveAnalyticsAsyncQuery = createSelector(
	[ getActiveAnalyticsDateRange, getActiveAnalyticsUuid ],
	getAnalyticsAsyncQuery
);

export const getActiveAnalyticsAsyncState = createSelector(
	[ getAppState, getActiveAnalyticsAsyncQuery ],
	getNestedAsyncState
)

export const getActiveAnalyticsFetching = createSelector(
	[ getActiveAnalyticsAsyncState ],
	getCurrentlyFetching
);

// TEMPORARY BREAK

export const getActiveAd = createSelector(
	[ getAllAds, getActiveAnalyticsUuid ],
	getActiveAdByUuid
);

export const getActiveAnalyticsDeviceCount = createSelector(
	[ getActiveFilteredAnalyticsData ],
	getAnalyticsDeviceCount
);

export const getTopDeviceUuid = createSelector(
	[ getActiveFilteredAnalyticsData ],
	getTopDevice
);

export const getTopStoreUuid = createSelector(
	[ getActiveFilteredAnalyticsData ],
	getTopStore
);

export const getActiveAnalyticsTopDevice = createSelector(
	[ getAllDevices, getTopDeviceUuid ],
	getDeviceByUUID
);

export const getActiveAnalyticsTopStore = createSelector(
	[ getStores, getTopStoreUuid ],
	getStoreByUUID
);

export const getActiveAnalyticsPeakTraffic = createSelector(
	[ getActiveAnalyticsResult ],
	getPeakTraffic
);

export const getTopDeviceStoreUuid = createSelector(
	[ getActiveAnalyticsTopDevice ],
	getDeviceStoreUuid
);

export const getActiveAnalyticsTopDeviceStore = createSelector(
	[ getStores, getTopDeviceStoreUuid ],
	getStoreByUUID
);

export const getActiveAnalyticsTimeOnScreen = createSelector(
	[ getActiveAnalyticsResult ],
	getTimeOnScreen
);

export const getActiveAnalyticsNotification = createSelector(
	[ getActiveAnalyticsResult ],
	getAnalyticsNotification
);

export const getPendingAnalyticsReports = createSelector(
	[ getAdsState ],
	getPendingReports
);

export const getActiveAnalyticsReportPending = createSelector(
	[ getActiveAnalyticsUuid, getPendingAnalyticsReports ],
	getAnalyticsReportPending
);

export const getAdAnalyticsFilter = createSelector(
	[ getActiveAnalyticsFilters ],
	getFilter
);

export const getAnalyticsAds = createSelector(
	[ getAllAds ],
	getAdsWithAnalytics
);

export const getFilteredAnalyticsAds = createSelector(
	[ getAnalyticsAds, getAdAnalyticsFilter ],
	filterAds
);

export const getFilteredSortedAnalyticsAds = createSelector(
	[ getFilteredAnalyticsAds, getSort ],
	sortAds
);

export const getActiveAnalyticsAd = createSelector(
	[ getAllAds, getActiveAnalyticsUuid ],
	getActiveAdByUuid
);
import * as React from "react";
import * as update from "immutability-helper";
import { connect } from "react-redux";

import { ActionSet, CustomCSS, IPlaylist, NameUuid } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { NamePlaceholder } from "Components/Actions/Constants";
import { Input, Loader, Icon } from "Components/Global/Common";
import { updateActionSetAsync, getActionSetAsync } from "Data/Actions/ActionsAsync";
import inputValidation from "Data/Objects/Validation";
import { getActiveActionSet } from "Data/Selectors/Actions";
import { updatePlaylistAsync } from "Data/Actions/Playlists";
import { getTargetPlaylists } from "Data/Selectors/Playlists";

const mapDispatchToProps = (dispatch) => ({
	updateAction: (action: ActionSet) => dispatch(updateActionSetAsync(action)),
	getActionSet: (uuid: string) => dispatch(getActionSetAsync(uuid)),
	updatePlaylist: (playlist: IPlaylist) => dispatch(updatePlaylistAsync(playlist))
});

const mapStateToProps = (state) => {
	const activeAction = getActiveActionSet(state);
	return {
		activeAction,
		actionSetPlaylists: getTargetPlaylists(state, activeAction.playlists)
	}
};

interface ActionSetPropertiesPanelContentProps {
	activeAction: ActionSet;
	actionSetPlaylists: IPlaylist[];
	updateAction: (action: ActionSet) => void;
	getActionSet: (uuid: string) => void;
	updatePlaylist: (playlist: IPlaylist) => Promise<void>;
}

export class ActionSetPropertiesPanelContent extends React.Component<ActionSetPropertiesPanelContentProps> {
	constructor(props: ActionSetPropertiesPanelContentProps) {
		super(props);

		this.styles = {
			row: {
				marginBottom: 14
			},
			label: {
				display: "inline-block",
				width: 100
			},
			playlistContainer: {
				height: "auto",
				minHeight: 148,
				maxHeight: 296,
				width: "100%",
				backgroundColor: "#1e2027",
				border: "2px inset #23262e",
				overflowY: "auto"
			},
			itemContainer: {
				display: "flex",
				transition: "background 200ms",
				justifyContent: "space-between",
				width: "100%",
				position: "relative",
				padding: "2px 5px 2px 5px"
			},
			icon: {
				cursor: "pointer"
			},
			text: {
				overflow: "hidden",
				textOverflow: "ellipsis",
				whitespace: "nowrap",
				display: "inline"
			}
		}

		this.handleTextChanged = this.handleTextChanged.bind(this);
	}

	styles: CustomCSS;

	render() {
		if (!this.props.activeAction) {
			return (
				<Loader size="small" />
			);
		}

		return (
			<div>
				{ this.renderActionTitleInput() }
				{ this.renderCreatedBy() }
				{ this.renderDateCreated() }
				{ this.renderLastModified() }
				{ this.renderAssociatedPlaylists() }
			</div>
		);
	}

	renderActionTitleInput() {
		const { name, uuid } = this.props.activeAction;

		return (
			<div style={ this.styles.row }>
				Title:
				<Input
					id={ uuid }
					placeholder={ NamePlaceholder }
					value={ name }
					saveCallback={ this.handleTextChanged }
					validator={ inputValidation.name }
				/>
			</div>
		);
	}

	renderCreatedBy() {
		const { createdBy } = this.props.activeAction
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Created By:</div>
				<span>
					{ createdBy && createdBy.name }
				</span>
			</div>
		);
	}

	renderDateCreated() {
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Date Created:</div>
				<span>
					{ Utils.getHumanReadableDate(this.props.activeAction.createdAt) }
				</span>
			</div>
		);
	}

	renderLastModified() {
		const { label, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }>Last Modified:</div>
				<span>
					{ Utils.getHumanReadableDate(this.props.activeAction.updatedAt) }
				</span>
			</div>
		);
	}

	renderAssociatedPlaylists() {
		const { row, playlistContainer } = this.styles;
		const { activeAction } = this.props;
		const { playlists } = activeAction;

		if (!playlists || !playlists.length) {
			return null;
		}

		return (
			<div style={ row }>
				Associated Playlists:
				<div style={ playlistContainer }>
					{ playlists.map(playlist => this.renderPlaylist(playlist)) }
				</div>
			</div>
		);
	}

	renderPlaylist(playlist: NameUuid) {
		const { itemContainer, text, icon } = this.styles;
		const path = `/playlists/${ playlist.uuid }`;

		return (
			<div style={ itemContainer }>
				<div style={ text }>
					<a href={ path }>
						{ playlist.name }
					</a>
				</div>
				<Icon name="times" style={ icon } onClick={ this.handleRemovePlaylistAction(playlist) }/>
			</div>
		);
	}

	handleRemovePlaylistAction(playlist: NameUuid) {
		return () => {
			this.handleRemoveActionSet(playlist);
		}
	}

	handleRemoveActionSet(playlistNameUuid: NameUuid) {
		const { actionSetPlaylists, updatePlaylist, getActionSet, activeAction } = this.props;

		const currentPlaylist = actionSetPlaylists.filter(playlist => playlist.uuid === playlistNameUuid.uuid);

		const newPlaylist = update(currentPlaylist[0], {
			actionSet: { $set: null }
		});

		updatePlaylist(newPlaylist).then(() => getActionSet(activeAction.uuid));
	}

	handleTextChanged(text: string) {
		if (!text) {
			return;
		}

		const name = Utils.getEndTruncatedName(text, 191);

		this.props.updateAction(Object.assign({}, this.props.activeAction, {
			name
		}));
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionSetPropertiesPanelContent);

import { Col, Row } from "antd";
import * as React from "react";

import { ComponentType, CustomCSS } from "@connect/Interfaces";
import DraggableNewComponentCard from "Components/Ads/DraggableNewComponentCard";
import { Colors } from "Components/Global/Constants";

const { gray, white } = Colors;

interface ComponentsViewProps {
	selectedComponentType: ComponentType;
	adHasFeed: boolean;
	adHasSlideshow: boolean;
	adHasVideo: boolean;
	disabled: boolean;
}

export default class ComponentsView extends React.Component<ComponentsViewProps> {
	constructor(props: ComponentsViewProps) {
		super(props);

		this.styles = {
			container: {
				overflowX: "hidden",
				color: white
			},
			warning: {
				color: gray,
				fontSize: ".85em"
			},
			disabled: {
				color: gray,
				fontSize: 10,
				textAlign: "center",
				marginBottom: 10,
				width: "100%"
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { adHasFeed, adHasSlideshow, adHasVideo, disabled, selectedComponentType } = this.props;
		const { container, disabled: disabledStyle } = this.styles;

		if (disabled) {
			return (
				<p style={ disabledStyle }>
					There isn't enough room for another component. Please resize an existing component.
				</p>
			);
		}

		return (
			<div style={ container }>
				<Row type="flex" justify="center" gutter={22}>
					<Col span={11}>
						<DraggableNewComponentCard
							contentType="ticker"
							selectedComponentType={selectedComponentType}
						/>
					</Col>
					<Col span={11}>
						<DraggableNewComponentCard
							contentType="image"
							selectedComponentType={selectedComponentType}
						/>
					</Col>
				</Row>
				<Row type="flex" justify="center" gutter={22}>
					<Col span={11}>
						<DraggableNewComponentCard
							disabled={adHasVideo}
							contentType="video"
							selectedComponentType={selectedComponentType}
						/>
					</Col>
					<Col span={11}>
						<DraggableNewComponentCard
							disabled={adHasFeed}
							contentType="feed"
							selectedComponentType={selectedComponentType}
						/>
					</Col>
				</Row>
				<Row type="flex" justify="center" gutter={22}>
					<Col span={11}>
						<DraggableNewComponentCard
							disabled={adHasSlideshow}
							contentType="slideshow"
							selectedComponentType={selectedComponentType}
						/>
					</Col>
					<Col span={11}>
						&nbsp;
					</Col>
					{ adHasFeed ? this.renderWarning("Ad may only contain one Security Feed.") : null }
					{ adHasSlideshow ? this.renderWarning("Ad may only contain one Slideshow.") : null }
					{ adHasVideo ? this.renderWarning("Ad may only contain one Video.") : null }
				</Row>
			</div>
		);
	}

	renderWarning(warning: string) {
		return (
			<span style={this.styles.warning}>
				{ warning }
			</span>
		);
	}
}
import * as update from "immutability-helper";

import { Action, DeviceDispatch, DeviceGroupDispatch, DeviceGroupsDispatch, DeviceModelsDispatch,
	DevicesDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DevicesState } from "Data/Objects/AppState";
import { createReducer, deleteFromState, getIndexFromState, mergeWithUuidArrays, pushToState,
	setState, updateStateArray, updateStateArrayItem } from "Data/Utils";
import { toggleFeature } from "@connect/Features";

export function setDeviceModels(state: DevicesState, action: Action<DeviceModelsDispatch>) {
	const modelNames = action.args.deviceModels.map(model => model.title);

	return update(state, {
		deviceModels: { $set: action.args.deviceModels },
		deviceModelNames: { $set: modelNames }
	});
}

/*
 * Devices Reducers
 */

export function setDevices(state: DevicesState, action: Action<DevicesDispatch>) {
	const { reset, devices, companyUuid } = action.args;
	const type = (!companyUuid || companyUuid === "Unassociated") ? "unassociatedDevices" : "devices";
	if (reset) {
		return setState(state, type, devices)
	}
	return mergeWithUuidArrays(state, type, devices);
}

export function updateDevice(state: DevicesState, action: Action<DeviceDispatch>) {
	const { device } = action.args;

	const index = getDeviceIndex(state, device.uuid);

	if (index === -1) {
		return pushToState(state, "devices", device);
	}

	return updateStateArrayItem(state, "devices", device);
}

export function updateDevice2(state: DevicesState, action: Action<DeviceDispatch>) {
	const { device } = action.args;
	return updateStateArrayItem(state, "devices", device);
}

export function deleteDevice(state: DevicesState, action: Action<DeviceDispatch>) {
	const { device, companyUuid } = action.args;
	const type = !companyUuid ? "unassociatedDevices" : "devices";
	const index = getDeviceIndex(state, device.uuid, !companyUuid);
	return deleteFromState(state, type, index);
}

/*
 * Device Groups Reducers
 */
export function setDeviceGroups(state: DevicesState, action: Action<DeviceGroupsDispatch>) {
	const { reset, deviceGroups } = action.args;

	if (reset) {
		return setState(state, "deviceGroups", deviceGroups);
	}

	return mergeWithUuidArrays(state, "deviceGroups", deviceGroups);
}

export function updateDeviceGroup(state: DevicesState, action: Action<DeviceGroupDispatch>) {
	const { deviceGroup } = action.args;

	if (toggleFeature("notifications", true, false)) {
		return updateDeviceGroup2(state, action);
	}

	const { uuid, parent } = deviceGroup;
	const index = deviceGroupExists(state, uuid);

	if (index === -1) {
		return pushToState(state, "deviceGroups", deviceGroup);
	}

	let newState = state;

	const oldDeviceGroup = state.deviceGroups[index];

	// If this device group is being moved, update the parent's children and the child's parent
	if (oldDeviceGroup && oldDeviceGroup.parent && oldDeviceGroup.parent !== parent) {
		const oldParentIndex = deviceGroupExists(state, oldDeviceGroup.parent);

		if (oldParentIndex >= 0 && oldParentIndex < state.deviceGroups.length) {
			const oldParent = state.deviceGroups[oldParentIndex];

			const newOldParentChildren = oldParent.children.filter((child: string) => {
				return child !== uuid;
			});

			const newOldParent = Object.assign({}, oldParent, { children: newOldParentChildren });
			newState = updateStateArray(state, "deviceGroups", oldParentIndex, newOldParent);
		}

		newState = addGroupToParent(newState, parent, uuid);
	}

	return updateStateArray(newState, "deviceGroups", index, deviceGroup);
}

export function updateDeviceGroup2(state: DevicesState, action: Action<DeviceGroupDispatch>) {
	const { deviceGroup } = action.args;
	return updateStateArrayItem(state, "deviceGroups", deviceGroup);
}

export function addGroupToParent(state: DevicesState, parent: string, groupId: string) {
	const index = deviceGroupExists(state, parent);

	if (index === -1) {
		return state;
	}

	const group = update(state.deviceGroups[index], {
		children: { $push: [ groupId ] }
	});

	return updateStateArray(state, "deviceGroups", index, group);
}

export function deleteDeviceGroup(state: DevicesState, action: Action<DeviceGroupDispatch>) {
	const { deviceGroup } = action.args;
	const index = getDeviceGroupIndex(state, deviceGroup.uuid);
	return deleteFromState(state, "deviceGroups", index);
}

export function resetDevices(state: DevicesState, action: Action<null>) {
	return new DevicesState();
}

export function createDeviceGroup(state: DevicesState, action: Action<DeviceGroupDispatch>) {
	const { deviceGroup } = action.args;
	return pushToState(state, "deviceGroups", deviceGroup);
}

/*
 * Helper Functions
 */
export function getDeviceIndex(state: DevicesState, uuid: string, unassociated?: boolean) {
	const deviceState = unassociated ? state.unassociatedDevices : state.devices;
	return getIndexFromState(deviceState, uuid);
}

export function getDeviceGroupIndex(state: DevicesState, uuid: string) {
	return getIndexFromState(state.deviceGroups, uuid);
}

export function deviceGroupExists(state: DevicesState, uuid: string) {
	return state.deviceGroups.findIndex((group) => group.uuid === uuid);
}

const { SET_DEVICE_MODELS, SET_DEVICES, UPDATE_DEVICE, DELETE_DEVICE, SET_DEVICE_GROUPS,
	UPDATE_DEVICE_GROUP, DELETE_DEVICE_GROUP, RESET_DEVICES, CREATE_DEVICE_GROUP } = ACTION_TYPES.Devices;

const reducers = {
	[CREATE_DEVICE_GROUP.type]: createDeviceGroup,
	[SET_DEVICE_MODELS.type]: setDeviceModels,
	[SET_DEVICES.type]: setDevices,
	[UPDATE_DEVICE.type]: updateDevice,
	[DELETE_DEVICE.type]: deleteDevice,
	[SET_DEVICE_GROUPS.type]: setDeviceGroups,
	[UPDATE_DEVICE_GROUP.type]: updateDeviceGroup,
	[DELETE_DEVICE_GROUP.type]: deleteDeviceGroup,
	[RESET_DEVICES.type]: resetDevices
};

export default createReducer(reducers, DevicesState);
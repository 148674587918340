import { store } from "@connect/Data";
import { IUser } from "@connect/Interfaces";
import { getCurrentUser } from "Data/Selectors/User";

const PERMISSIONS = {
	ACTIONS_MANAGE: "actions.manage",
	ADS_MANAGE: "ads.manage",
	ANALYTICS_VIEW: "analytics.view",
	BILLING_MANAGE: "billing.manage",
	COMPANIES_ANY_EDIT: "companies.any.edit",
	COMPANIES_CREATE: "companies.create",
	COMPANIES_DELETE: "companies.delete",
	COMPANIES_EDIT: "companies.edit",
	COMPANIES_REQUEST: "companies.request",
	COMPANIES_TRANSFER: "companies.transfer",
	COMPANIES_VIEW: "companies.view",
	DEPLOYMENTS_DEPLOY: "deployments.deploy",
	DEPLOYMENTS_MANAGE: "deployments.manage",
	DEVICES_CHECK_IN: "devices.check-in",
	DEVICES_MANAGE: "devices.manage",
	MEDIA_MANAGE: "media.manage",
	OWN_ANALYTICS_VIEW: "own.analytics.view",
	OWN_BILLING_MANAGE: "own.billing.manage",
	OWN_COMPANIES_EDIT: "own.companies.edit",
	OWN_COMPANIES_VIEW: "own.companies.view",
	OWN_DEVICES_VIEW: "own.devices.view",
	OWN_DEVICES_MANAGE: "own.devices.manage",
	VIEW_COMPANY_INTEGRATIONS: "own.companies.integrations.view",
	SYSTEM_INTEGRATORS_MANAGE: "system.integrators.manage",
	PLAYLISTS_MANAGE: "playlists.manage",
	DEVICE_HEALTH_VIEW: "reports.device-health.view",
	DEVICE_HEALTH_CREATE: "reports.device-health.create",
	DEVICE_HEALTH_RUN: "reports.device-health.run",
	DEVICE_HEALTH_NOTIFICATION: "reports.device-health.notification.create",
	SELF_EDIT: "self.edit",
	SELF_VIEW: "self.view",
	USERS_2FA_DISABLE: "users.2fa.disable",
	USERS_ANY_CREATE: "users.any.create",
	USERS_ANY_DELETE: "users.any.delete",
	USERS_ANY_EDIT: "users.any.edit",
	USERS_CREATE: "users.create",
	USERS_DELETE: "users.delete",
	USERS_EDIT: "users.edit",
	USERS_IMPERSONATE: "users.impersonate",
	USERS_INTEGRATOR_CREATE: "users.integrator.create",
	USERS_INTEGRATOR_DELETE: "users.integrator.delete",
	USERS_INTEGRATOR_EDIT: "users.integrator.edit",
	USERS_INTEGRATOR_VIEW: "users.integrator.view",
	USERS_MARKETER_CREATE: "users.marketer.create",
	USERS_MARKETER_DELETE: "users.marketer.delete",
	USERS_MARKETER_EDIT: "users.marketer.edit",
	USERS_MARKETER_VIEW: "users.marketer.view",
	USERS_OWNER_CREATE: "users.owner.create",
	USERS_OWNER_DELETE: "users.owner.delete",
	USERS_OWNER_EDIT: "users.owner.edit",
	USERS_OWNER_VIEW: "users.owner.view",
	USERS_USER_CREATE: "users.user.create",
	USERS_USER_DELETE: "users.user.delete",
	USERS_USER_EDIT: "users.user.edit",
	USERS_USER_VIEW: "users.user.view",
	USERS_VIEW: "users.view"
};

// allow the user to be passed in; default it to the value of a memoized selector
function hasPermission(permission: string, currentUser: IUser = getCurrentUser(store.getState())) {
	if (!(currentUser && currentUser.permissions)) {
		return false;
	}
	let requestedPermissions = [ permission ];
	const userPermissions = currentUser.permissions;

	if (permission === "users.any.edit") {
		requestedPermissions = [ PERMISSIONS.USERS_EDIT, PERMISSIONS.USERS_INTEGRATOR_EDIT, PERMISSIONS.USERS_OWNER_EDIT,
			PERMISSIONS.USERS_MARKETER_EDIT, PERMISSIONS.USERS_USER_EDIT, PERMISSIONS.USERS_CREATE ];
	} else if (permission === "users.any.create") {
		requestedPermissions = [ PERMISSIONS.USERS_CREATE, PERMISSIONS.USERS_OWNER_CREATE, PERMISSIONS.USERS_USER_CREATE,
			PERMISSIONS.USERS_INTEGRATOR_CREATE, PERMISSIONS.USERS_MARKETER_CREATE ]
	} else if (permission === "companies.any.edit") {
		requestedPermissions = [ PERMISSIONS.COMPANIES_CREATE, PERMISSIONS.COMPANIES_DELETE,
			PERMISSIONS.COMPANIES_EDIT, PERMISSIONS.COMPANIES_VIEW ];
	} else if (permission === "users.any.delete") {
		requestedPermissions = [ PERMISSIONS.USERS_OWNER_DELETE, PERMISSIONS.USERS_DELETE, PERMISSIONS.USERS_MARKETER_DELETE,
			PERMISSIONS.USERS_INTEGRATOR_DELETE, PERMISSIONS.USERS_USER_DELETE ];
	}

	return requestedPermissions
		.map((permission: string) => {
			return userPermissions.includes(permission);
		})
		.reduce((prev: boolean, next: boolean) => {
			return prev || next;
		}, false);
}

export {
	hasPermission,
	PERMISSIONS
};

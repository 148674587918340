import { Switch } from "antd";
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { AspectRatios, CustomCSS, IFeedComponent } from "@connect/Interfaces";
import AspectRatioPicker from "Components/Ads/AspectRatioPicker";
import BannerList from "Components/Ads/BannerList";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import OptionButton from "Components/Global/OptionButton";
import { updateComponent } from "Data/Actions/UI/AdBuilder";
import { getSelectedComponentIndex } from "Data/Selectors/AdBuilder";
import { getAdBuilderAd, getSelectedComponentByIndex } from "Data/Selectors/UI";

interface IFeedPropertiesContentProps {
	canChangeAspectRatio: boolean;
	component: IFeedComponent;
	componentIndex: number;
	onComponentUpdated: (index: number, component: IFeedComponent) => void;
}

const mapDispatchToProps = (dispatch) => ({
	onComponentUpdated: (index: number, component: IFeedComponent) =>
		dispatch(updateComponent(index, component, true))
});

const mapStateToProps = (state) => {
	const activeAd = getAdBuilderAd(state);
	const componentIndex = getSelectedComponentIndex(state);
	const component = getSelectedComponentByIndex(activeAd, componentIndex);

	return ({
		component,
		componentIndex
	});
};

export class FeedPropertiesPanelContent extends React.Component<IFeedPropertiesContentProps> {
	constructor(props: IFeedPropertiesContentProps) {
		super(props);

		this.aspectButtonWidth = 50;
		this.height = (this.width * 9) / 16;
		this.width = 150;

		this.styles = {
			aspectButton: {
				display: "flex",
				flex: 1,
				justifyContent: "center",
				alignItems: "center",
				margin: "0 5px",
				color: Colors.white,
				fontSize: "0.7rem",
				cursor: "pointer",
				borderRadius: 6,
				width: this.aspectButtonWidth,
				height: 24
			},
			bannerRow: {
				display: "flex",
				width: "100%",
				flexDirection: "row",
				marginBottom: 14
			},
			bannerRowBottom: {
				marginBottom: "5px"
			},
			bannerRowLabel: {
				flex: 6,
				lineHeight: "24px",
				verticalAlign: "middle"
			},
			bannerRowRight: {
				flexDirection: "row",
				flex: 4,
				display: "flex"
			},
			bannerRowTop: {
				marginTop: 14
			},
			flexEight: {
				flex: 8
			},
			flexTwo: {
				flex: 2
			},
			message: {
				color: "#888888",
				textAlign: "center",
				fontSize: ".85em",
				marginBottom: "10px"
			},
			options: {
				marginTop: "5px"
			},
			row: {
				display: "flex"
			}
		}

		this.setBannerFlashing = this.setBannerFlashing.bind(this);
		this.setBannerMotion = this.setBannerMotion.bind(this);
		this.setBannerPosition = this.setBannerPosition.bind(this);
		this.toggleBanner = this.toggleBanner.bind(this);
	}

	aspectButtonWidth: number;
	height: number;
	width: number;

	styles: CustomCSS;

	render() {
		return (
			<div>
				{ this.renderAspectPicker() }
				{ this.renderBannerToggle() }
				{ this.renderBannerOptions() }
			</div>
		);
	}

	renderAspectPicker() {
		if (!this.props.canChangeAspectRatio) {
			return null;
		}

		const { ONE_ONE, SIXTEEN_NINE, NINE_SIXTEEN, FOUR_THREE } = AspectRatios;

		return (
			<AspectRatioPicker ratios={[ SIXTEEN_NINE, FOUR_THREE, ONE_ONE, NINE_SIXTEEN ]} />
		);
	}

	renderBannerOptions() {
		const { component } = this.props;

		if (!(component && component.banner)) {
			return null;
		}

		const { position, onMotion, id, flashing } = component.banner;
		const { aspectButton, bannerRow, bannerRowLabel, bannerRowRight,
			bannerRowTop, flexEight, flexTwo, message, options, row } = this.styles;

		return (
			<div style={ options }>
				<div style={{
					...row,
					...message
				}}>
						Choose a default banner or upload your own.
						Custom banners must be 1080px wide, up to 200px tall and a JPG or transparent PNG.
				</div>
				<BannerList selectedBannerId={ id || "" } />
				<div style={{ ...bannerRow, ...bannerRowTop }}>
					<div style={ bannerRowLabel }>
						Location:
					</div>
					<div style={ bannerRowRight }>
						<OptionButton
							key={ "banner_position_top" }
							selected={ position === "top" }
							style={ aspectButton }
							onClick={ this.setBannerPosition("top") }>
							<Icon name="arrow-up"/>
						</OptionButton>
						<OptionButton
							key={ "banner_position_bottom" }
							selected={ position === "bottom" }
							style={ aspectButton }
							onClick={ this.setBannerPosition("bottom") }>
							<Icon name="arrow-down"/>
						</OptionButton>
					</div>
				</div>
				<div style={ bannerRow }>
					<div style={{
						...bannerRowLabel,
						...flexEight
					}}>Flashing:</div>
					<div style={ flexTwo }>
						<Switch
							key={ "flashing_" + component.id + (flashing ? "_true" : "_false") }
							size="small"
							checked={ flashing }
							onChange={ this.setBannerFlashing } />
					</div>
				</div>
				<div style={ bannerRow }>
					<div style={{
						...bannerRowLabel,
						...flexEight
					}}>Motion Activated:</div>
					<div style={ flexTwo }>
						<Switch
							key={ "motion_" + component.id + (onMotion ? "_true" : "_false") }
							size="small"
							checked={ onMotion }
							onChange={ this.setBannerMotion } />
					</div>
				</div>
			</div>
		);
	}

	renderBannerToggle() {
		const { component } = this.props;
		const { bannerRow, bannerRowBottom, bannerRowLabel, flexEight, flexTwo } = this.styles;

		return (
			<div style={{
				...bannerRow,
				...bannerRowBottom
			}}>
				<div style={{
					...bannerRowLabel,
					...flexEight
				}}><Icon name="shield"/>Security Banner:</div>
				<div style={ flexTwo }>
					<Switch
						key={ component.id }
						checked={ component.banner !== null }
						onChange={ this.toggleBanner } />
				</div>
			</div>
		);
	}

	setBannerFlashing(flashing: boolean) {
		const { component, componentIndex, onComponentUpdated } = this.props;

		onComponentUpdated(componentIndex, update(component, {
			banner: {
				flashing: { $set: flashing }
			}
		}));
	}

	setBannerMotion(motion: boolean) {
		const { component, componentIndex, onComponentUpdated } = this.props;

		onComponentUpdated(componentIndex, update(component, {
			banner: {
				onMotion: { $set: motion }
			}
		}));
	}

	setBannerPosition(position: "top" | "bottom") {
		return () => {
			const { component, componentIndex, onComponentUpdated } = this.props;

			onComponentUpdated(componentIndex, update(component, {
				banner: {
					position: { $set: position }
				}
			}));
		};
	}

	toggleBanner(bannerToggle: boolean) {
		const { component, componentIndex, onComponentUpdated } = this.props;
		let newBanner;

		if (bannerToggle) {
			newBanner = {
				id: null,
				onMotion: false,
				flashing: false,
				position: "top"
			}
		} else {
			newBanner = null;
		}

		onComponentUpdated(componentIndex, update(component, {
			banner: { $set: newBanner }
		}));
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedPropertiesPanelContent);

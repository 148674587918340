import * as React from "react";
import { connect } from "react-redux";

import { history } from "@connect/Data";
import { Context as Context, IAd } from "@connect/Interfaces";
import AdsPage from "Components/Ads/AdsPage";
import { Modal } from "Components/Global/Common";
import { updateSelectedActionAd } from "Data/Actions/ActionsAsync";
import { updateAdAsync } from "Data/Actions/AdsAsync";
import { setSelectAdsModal } from "Data/Actions/UI/Modals";
import { getCurrentSelectAdsModalContext, getSelectAdsModalVisibility } from "Data/Selectors/UI";

const mapStateToProps = (state) => ({
	context: getCurrentSelectAdsModalContext(state),
	visible: getSelectAdsModalVisibility(state)
});

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(setSelectAdsModal(false, "")),
	updateAd: (ad: IAd) => dispatch(updateAdAsync(ad)),
	updateSelectedActionAd: (adUuid: string, duration: number) => dispatch(updateSelectedActionAd(adUuid, duration))
});

interface ISelectAdsModalProps {
	context: Context;
	hideModal: () => void;
	visible: boolean;
	onAdSelected: (ad: IAd) => void;
	updateAd: (ad: IAd) => Promise<void>;
	updateSelectedActionAd: (adUuid: string, duration: number) => Promise<void>;
}

export class UnwrappedSelectAdsModal extends React.PureComponent<ISelectAdsModalProps> {
	constructor(props: ISelectAdsModalProps) {
		super(props);
		this.handleSelectAnalytics = this.handleSelectAnalytics.bind(this);
		this.hide = this.hide.bind(this);
		this.onAdSelected = this.onAdSelected.bind(this);
	}

	render() {
		const { context, visible } = this.props;

		return (
			<Modal
				fullScreen
				modalKey={ `select_ads_modal_${ String(visible) }` }
				// can't put width inline-- ant modal doesn't respect it in the inline style
				width="100%"
				visible={ visible }
				onCancel={ this.hide }
			>
				<AdsPage
					context={ context }
					key={ context }
					onAdSelected={ this.onAdSelected }
					onCloseModal={ this.hide }
				/>
			</Modal>
		);
	}

	onAdSelected(ad: IAd) {
		switch (this.props.context) {
			case Context.ANALYTICS:
				return this.handleSelectAnalytics(ad);
			case Context.ACTIONS:
				return this.handleSelectAction(ad);
			default:
				return null;
		}
	}

	handleSelectAction(ad: IAd) {
		// TODO once new triggers & actions UI is built
		this.props.updateSelectedActionAd(ad.uuid, ad.duration)
			.then(() => {
				this.hide();
			});
	}

	handleSelectAnalytics(ad: IAd) {
		this.props.updateAd(Object.assign({}, ad, {
			analytics: new Date()
		})).then(() => {
			this.hide();
			history.push(`/analytics/${ ad.uuid }`);
		});
	}

	hide() {
		this.props.hideModal();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedSelectAdsModal);
import { Action, RolesDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { RolesState } from "Data/Objects/AppState";
import { createReducer, setState } from "Data/Utils";

export function setEditRolesList(state: RolesState, action: Action<RolesDispatch>) {
	return setState(state, "editRoles", action.args);
}

export function setRolesList(state: RolesState, action: Action<RolesDispatch>) {
	return setState(state, "roles", action.args);
}

const {
	SET_EDIT_ROLES_LIST,
	SET_ROLES_LIST
} = ACTION_TYPES.Roles;

const reducers = {
	[SET_EDIT_ROLES_LIST.type]: setEditRolesList,
	[SET_ROLES_LIST.type]: setRolesList
};

export default createReducer(reducers, RolesState);
import { Slider } from "antd";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";

interface IAudioSliderProps {
	volume: number;
	handleChange: (volume: number | number[]) => void;
	hideMute?: boolean;
}

class AudioSlider extends React.PureComponent<IAudioSliderProps> {
	constructor(props: IAudioSliderProps) {
		super(props);
		this.styles = {
			container: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "row",
				marginTop: -7,
				float: "left"
			} as CustomCSS,
			slider: {
				width: 100
			} as CustomCSS,
			icon: {
				marginRight: 7
			} as CustomCSS
		}
		this.handleMute = this.handleMute.bind(this);
	}

	styles: {
		container: CustomCSS;
		slider: CustomCSS;
		icon: CustomCSS;
	}

	render() {
		const { handleChange, volume } = this.props;
		const { container, slider } = this.styles;
		return (
			<div key={ volume } style={ container }>
				{ this.renderMute() }
				<div style={ slider }>
					<Slider
						min={ 0 }
						max={ 100 }
						defaultValue={ volume || 0 }
						onAfterChange={ handleChange } />
				</div>
			</div>
		)
	}

	renderMute() {
		if (this.props.hideMute) {
			return null;
		}

		return (
			<Icon
				size="smaller"
				style={ this.styles.icon }
				name="volume-off"
				onClick={ this.handleMute }
			/>
		);
	}

	handleMute() {
		this.props.handleChange(0);
	}
}

export default AudioSlider;
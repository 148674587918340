import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Notifications } from "@connect/Notifications";
import AnalyticsCard from "Components/Analytics/AnalyticsCard";
import CreateNewAnalyticsButton from "Components/Analytics/CreateNewAnalyticsButton";
import GridView, { IGridViewProps } from "Components/Global/GridView";
import { updateAdAsync } from "Data/Actions/AdsAsync";
import { addToActiveSelection, removeActiveSelection } from "Data/Actions/UI";
import { Ad } from "Data/Objects/Ads";
import { AppState } from "Data/Objects/AppState";
import { getActiveSelection, getActiveUuid } from "Data/Selectors/UI";

const mapStateToProps = (state: AppState) => ({
	activeSelection: getActiveSelection(state, "analytics"),
	activeUuid: getActiveUuid(state, "analytics")
});

const mapDispatchToProps = (dispatch) => ({
	addToSelected: (ids: string[]) => dispatch(addToActiveSelection("analytics", ids)),
	removeFromSelected: (ids: string[]) => dispatch(removeActiveSelection("analytics", ids)),
	setActive: (uuid: string) => dispatch(push(`/analytics/${uuid}`)),
	updateAd: (ad: Ad) => dispatch(updateAdAsync(ad, true))
});

interface IAnalyticsGridProps extends IGridViewProps {
	content: Ad[];
	onCreateNewAnalytics: () => void;
	updateAd: (ad: Ad) => void;
}

export class AnalyticsGrid extends GridView<IAnalyticsGridProps> {
	constructor(props: IAnalyticsGridProps) {
		super(props);

		this.deleteAnalytics = this.deleteAnalytics.bind(this);
		this.renderAnalyticsCard = this.renderAnalyticsCard.bind(this);
		this.renderCreateNewAnalytics = this.renderCreateNewAnalytics.bind(this);
	}

	render() {
		const cards = [
			this.renderCreateNewAnalytics(),
			...this.props.content.map(this.renderAnalyticsCard)
		];

		return this.renderContainer(cards);
	}

	renderAnalyticsCard(ad: Ad, index: number) {
		const isSelected = this.isSelected(ad.uuid);

		return (
			<AnalyticsCard
				bulkSelectActive={ this.props.selectModeOn }
				cardObject={ ad }
				cardType="analytics"
				isSelected={ isSelected }
				key={ `${ ad.uuid }_analytics_card_${ index }` }
				onCardSelection={ this.handleCardSelection(ad, isSelected) }
				actionButtons={[
					{ callback: this.deleteAnalytics, name: "trash-alt" }
				]}
			/>
		);
	}

	renderCreateNewAnalytics() {
		const { onCreateNewAnalytics, selectModeOn } = this.props;

		if (selectModeOn) {
			return null;
		}

		return (
			<CreateNewAnalyticsButton key="create_new_analytics" onCreateNewAnalytics={ onCreateNewAnalytics } />
		);
	}

	deleteAnalytics(ad: Ad) {
		Notifications.confirm("Remove ad analytics?",
			"Are you sure you want to remove analytics from the selected Ad?",
			"Remove", "Cancel", () => {
				this.props.updateAd(Object.assign({}, ad, {
					analytics: null
				}));
			}
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsGrid);

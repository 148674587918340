import { ButtonType } from "antd/lib/button/button";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { push } from "react-router-redux";

import { ActiveUuidRoute, Context, CustomCSS, ICompany, IPlaylist, SortTypes,
	ViewTypes,
	Sorts} from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import AdsList from "Components/Ads/AdsList";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import { Button, Icon } from "Components/Global/Common";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import { IconWeights } from "Components/Global/Icon";
import { RequestNameTypes } from "Components/Global/RequestNameModal";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import PlaylistContentArea from "Components/Playlists/PlaylistContentArea";
import PlaylistEditor from "Components/Playlists/PlaylistEditor";
import PlaylistPropertiesPanel from "Components/Playlists/PlaylistPropertiesPanel";
import { setRequestNameModal as setRequestNameModalState } from "Data/Actions/UI/Modals";
import { createPlaylistAsync, deletePlaylistAsync, getPlaylistAsync, getPlaylistsAsync } from "Data/Actions/Playlists";
import { setActiveSearch, setActiveSelection, setActiveSort } from "Data/Actions/UI";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveCompany } from "Data/Selectors/Company";
import { getActivePlaylist, getAllPlaylists, getFilteredSortedPlaylists } from "Data/Selectors/Playlists";
import {
	canFetchPlaylists,
	getActiveSearch,
	getActiveSelection,
	getActiveSorts,
	getActiveUuid,
	haveAllPlaylists as getHaveAllPlaylists
} from "Data/Selectors/UI";

const { ACTIONS, ADS, PAGE } = Context;
const getContext = (props: PlaylistsPageProps) => props.context || PAGE;

const mapStateToProps = (state) => {
	const activeUuid = getActiveUuid(state, "playlists");
	const playlistsSortType = getActiveSorts(state, "playlists") as SortTypes;
	const searchText = getActiveSearch(state, "playlists");
	return {
		activeCompany: getActiveCompany(state),
		activePlaylist: getActivePlaylist(state),
		activeUuid,
		allPlaylists: getAllPlaylists(state),
		currentlyFetching: canFetchPlaylists(state),
		searchText,
		haveAllPlaylists: getHaveAllPlaylists(state),
		playlists: getFilteredSortedPlaylists(state, { sort: playlistsSortType, search: searchText }),
		playlistsSortType,
		adsSortType: getActiveSorts(state, Sorts.ADS),
		selectedPlaylists: getActiveSelection(state, "playlists")
	};
};

const mapDispatchToProps = (dispatch) => ({
	createPlaylist: (playlist: IPlaylist, isCopy?: boolean) => dispatch(createPlaylistAsync(playlist, isCopy)),
	deletePlaylist: (playlist: IPlaylist) => dispatch(deletePlaylistAsync(playlist)),
	fetchPlaylist: (uuid: string) => dispatch(getPlaylistAsync({ uuid } as IPlaylist)),
	getPlaylists: () => dispatch(getPlaylistsAsync(true)), // on the PlaylistsPage we should always get expanded
	pushToPlaylistsPage: (state?: { selectModeOn: boolean }) => dispatch(push({
		pathname: "/playlists",
		state
	})),
	selectPlaylists: (playlists: string[]) => dispatch(setActiveSelection("playlists", playlists)),
	setActivePlaylist: (id: string) => dispatch(push(`/playlists/${id}`)),
	setSearch: (query: string) => dispatch(setActiveSearch("playlists", query)),
	setAdsSort: (sort: SortTypes) => dispatch(setActiveSort(Sorts.ADS, sort)),
	setRequestNameModal: (value: boolean) => dispatch(setRequestNameModalState(value, RequestNameTypes.PLAYLIST))
});

interface PlaylistsPageProps extends RouteComponentProps<ActiveUuidRoute> {
	activeCompany: ICompany;
	activePlaylist: IPlaylist;
	activeUuid: string;
	adsSortType: SortTypes;
	allPlaylists: IPlaylist[];
	context?: Context;
	createPlaylist: (playlist: IPlaylist, isCopy?: boolean) => Promise<IPlaylist>;
	currentlyFetching: boolean;
	deletePlaylist: (playlist: IPlaylist) => void;
	fetchPlaylist: (uuid: string) => void;
	getPlaylists: () => void;
	haveAllPlaylists: boolean;
	onCloseModal?: () => void;
	onPlaylistsSelected?: (playlists: IPlaylist[]) => void;
	playlists: IPlaylist[];
	playlistsSortType: SortTypes;
	pushToPlaylistsPage: (state?: { selectModeOn: boolean }) => void;
	searchText: string;
	selectPlaylists: (playlists: string[]) => void;
	selectedPlaylists: string[];
	setActivePlaylist: (id: string) => void;
	setRequestNameModal: (value: boolean) => null;
	setSearch: (value: string) => void;
	setAdsSort: (sort: SortTypes) => void;
}

interface PlaylistsPageState {
	selectModeOn: boolean;
	adsViewType: ViewTypes;
}

export class PlaylistsPage extends React.Component<PlaylistsPageProps, PlaylistsPageState> {
	constructor(props: PlaylistsPageProps) {
		super(props);

		const { location } = props;
		const selectModeOn = location && location.state && location.state.selectModeOn || false;

		this.state = {
			selectModeOn,
			adsViewType: ViewTypes.GRID
		}

		this.styles = {
			adListHeader: {
				display: "block",
				width: "100%"
			},
			adListButtons: {
				display: "inline",
				float: "right"
			},
			adListViewButton: {
				marginRight: 6
			},
			bottomButton: {
				marginBottom: 10
			},
			gallery: {
				position: "relative"
			}
		};

		this.deselectAllPlaylists = this.deselectAllPlaylists.bind(this);
		this.getBatchOperations = this.getBatchOperations.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSelectPlaylists = this.handleSelectPlaylists.bind(this);
		this.handleSetAlphaSort = this.handleSetAlphaSort.bind(this);
		this.handleSetDateSort = this.handleSetDateSort.bind(this);
		this.maybeResetPage = this.maybeResetPage.bind(this);
		this.promptDeletePlaylist = this.promptDeletePlaylist.bind(this);
		this.renderAdsList = this.renderAdsList.bind(this);
		this.renderCenterContent = this.renderCenterContent.bind(this);
		this.renderLeftContent = this.renderLeftContent.bind(this);
		this.renderPlaylistsGallery = this.renderPlaylistsGallery.bind(this);
		this.renderRightContent = this.renderRightContent.bind(this);
		this.selectAllPlaylists = this.selectAllPlaylists.bind(this);
		this.setAdsViewType = this.setAdsViewType.bind(this);
		this.showNewPlaylistModal = this.showNewPlaylistModal.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
	}

	styles: CustomCSS;

	componentWillMount() {
		if (hasPermission(PERMISSIONS.PLAYLISTS_MANAGE)) {
			this.props.getPlaylists();
		}
	}

	componentDidMount() {
		this.maybeResetPage();
	}

	componentWillReceiveProps(nextProps: PlaylistsPageProps, nextState: PlaylistsPageState) {
		const { activeCompany, location } = nextProps;
		const selectModeOn = location && location.state && location.state.selectModeOn;

		if (selectModeOn && selectModeOn !== this.state.selectModeOn) {
			this.setState(() => ({ selectModeOn }));
		}

		if (this.props.activeCompany !== activeCompany) {
			this.props.getPlaylists();
		}

		this.maybeResetPage(nextProps);
	}

	render() {
		if (!(hasPermission(PERMISSIONS.PLAYLISTS_MANAGE) || hasPermission(PERMISSIONS.DEPLOYMENTS_MANAGE))) {
			return null;
		}

		return (
			<React.Fragment>
				<ThreeColumnLayout
					leftContent={ this.renderLeftContent() }
					centerContent={ this.renderCenterContent() }
					rightContent={ this.renderRightContent() } />
			</React.Fragment>
		);
	}

	renderAdsList() {
		const { adsSortType } = this.props;
		const { adsViewType } = this.state;
		const { adListHeader, adListButtons, adListViewButton } = this.styles;
		const dateSortDirection = adsSortType === SortTypes.NEWEST_FIRST ? "up" : "down";
		const viewIcon = adsViewType === ViewTypes.GRID ? "th-list" : "th-large";
		const sortIcon = adsSortType === SortTypes.ALPHA ? "sort-alpha-down" : "sort-alpha-up";

		return new AccordionElement(
			<div style={ adListHeader }>
				My Ads
				<div style={ adListButtons }>
					<span
						style={ adListViewButton }
						onClick={ this.setAdsViewType }
					>
						<Icon name={ viewIcon } />
					</span>
					<span onClick={ this.handleSetAlphaSort }>
						<Icon name={ sortIcon } />
					</span>
					<span onClick={ this.handleSetDateSort }>
						<Icon name="calendar" sortDirection={ dateSortDirection } />
					</span>
				</div>
			</div>
			, (
				<AdsList
					context="playlistsPanel"
					adsSortType={ adsSortType }
					adsViewType={ adsViewType }
				/>
			));
	}

	renderCenterContent() {
		const context = getContext(this.props);

		if (this.props.activePlaylist && context !== ADS && context !== ACTIONS ) {
			return (
				<PlaylistEditor />
			);
		}

		return this.renderPlaylistsGallery();
	}

	renderLeftContent() {
		const context = getContext(this.props);
		if (!this.props.activeUuid || context === ADS || context === ACTIONS) {
			return undefined;
		}

		return (
			<Accordion
				header={
					<Button
						type="primary"
						icon="plus-circle"
						fluid
						corners="rounded"
						onClick={ this.showNewPlaylistModal }
					>
						New Playlist
					</Button>
				}
				elements={[ this.renderAdsList() ]} />
		);
	}

	renderPlaylistsGallery() {
		const { onCloseModal, onPlaylistsSelected, searchText } = this.props;

		return (
			<div style={ this.styles.gallery }>
				<ContentAreaTopBar
					batch={ this.getBatchOperations() }
					closeButton={onCloseModal}
					sort={{
						dataType: Sorts.PLAYLISTS
					}}
					search={{
						filterText: searchText,
						onSearchChange: this.handleSearchChange
					}}
				/>
				<PlaylistContentArea
					context={ getContext(this.props) }
					selectModeOn={ this.state.selectModeOn }
					onCreatePlaylist={ this.showNewPlaylistModal }
					onPlaylistsSelected={ onPlaylistsSelected }
				/>
			</div>
		)
	}

	renderRightContent() {
		const context = getContext(this.props);
		if (!this.props.activeUuid || context === ADS || context === ACTIONS) {
			return undefined;
		}

		return (
			<PlaylistPropertiesPanel />
		);
	}

	deselectAllPlaylists() {
		this.props.selectPlaylists([]);
	}

	getBatchOperations() {
		const context = getContext(this.props);
		if (context !== PAGE && context !== ACTIONS) {
			return undefined;
		}

		const { playlists, selectedPlaylists } = this.props;
		const numSelected = selectedPlaylists.length;

		const batchButtons = [
			{
				disabled: numSelected === playlists.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.selectAllPlaylists
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.deselectAllPlaylists
			},
			{
				disabled: !numSelected,
				label: "Delete Playlists",
				icon: "trash",
				iconWeight: "regular" as IconWeights,
				onClick: this.promptDeletePlaylist,
				type: "danger" as ButtonType
			}
		];

		if (getContext(this.props) === Context.ACTIONS) {
			batchButtons.push({
				disabled: !numSelected,
				label: "Add Action To Selection",
				icon: "check",
				iconWeight: "regular" as IconWeights,
				onClick: this.handleSelectPlaylists,
				type: "primary"
			});
		}

		return {
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Playlists",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	handleSearchChange(value: string) {
		this.props.setSearch(value);
	}

	handleSelectPlaylists() {
		const { onPlaylistsSelected, playlists, selectedPlaylists } = this.props;
		const selection = playlists.filter((p) => selectedPlaylists.includes(p.uuid));
		return onPlaylistsSelected && onPlaylistsSelected(selection);
	}

	handleSetAlphaSort(event: React.SyntheticEvent) {
		event.preventDefault();

		const { setAdsSort, adsSortType } = this.props;
		const { ALPHA, REVERSE_ALPHA } = SortTypes;
		const newSortType = adsSortType === ALPHA ? REVERSE_ALPHA : ALPHA;

		setAdsSort(newSortType);
	}

	handleSetDateSort(event: React.SyntheticEvent) {
		event.preventDefault();

		const { setAdsSort, adsSortType } = this.props;
		const { NEWEST_FIRST, OLDEST_FIRST } = SortTypes;
		const newSortType = adsSortType === NEWEST_FIRST ? OLDEST_FIRST : NEWEST_FIRST;

		setAdsSort(newSortType);
	}

	maybeResetPage(nextProps?: PlaylistsPageProps) {
		const props = nextProps || this.props;
		const { activePlaylist, activeUuid, fetchPlaylist, match } = props;

		// we want to make sure we actually have an activeUuid e.g. when deleting a playlist
		// an incoming match.params will not have one but it will potentially still be in props
		if (activeUuid && match && match.params.activeUuid && !activePlaylist) {
			fetchPlaylist(activeUuid);
		}
	}

	promptDeletePlaylist() {
		const { allPlaylists, deletePlaylist, selectedPlaylists, selectPlaylists } = this.props;
		const playlists = selectedPlaylists.length && selectedPlaylists.length === 1 ? "playlist" : "playlists";

		// confirm that user wants to delete all selected playlists
		Notifications.confirm(`Delete selected ${playlists}?`,
			`Are you sure you want to delete the selected ${playlists}? ` +
		`The ${playlists} will be removed from any deployments they may have been part of.`,
			"Delete", "Cancel", () => {
				allPlaylists
					.filter(({ uuid }) => selectedPlaylists.includes(uuid))
					.forEach((s) => deletePlaylist(s));

				selectPlaylists([]);
			});
	}

	selectAllPlaylists() {
		const { playlists, selectPlaylists } = this.props;

		selectPlaylists(playlists.map(({ uuid }) => uuid));
	}

	setAdsViewType(event: React.SyntheticEvent) {
		event.preventDefault();

		const isGrid = this.state.adsViewType === ViewTypes.GRID
		let adsViewType = isGrid ? ViewTypes.LIST : ViewTypes.GRID;
		this.setState({ adsViewType });
	}

	showNewPlaylistModal() {
		this.props.setRequestNameModal(true);
	}

	toggleSelectMode() {
		const { activeUuid, pushToPlaylistsPage, selectPlaylists } = this.props;
		const newSelectMode = { selectModeOn: !this.state.selectModeOn };

		selectPlaylists([]);

		if (activeUuid) {
			pushToPlaylistsPage(newSelectMode);
		} else {
			this.setState(() => (newSelectMode));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistsPage);
import { notifyBugSnagAsync } from "@connect/BugSnag";
import { CustomPermissions } from "@connect/Interfaces";
import PermissionsApi from "Api/Permissions";
import { errorNotification } from "Data/Actions/Notifications";
import { setPermissionSettings } from "Data/Actions/Permissions";

export function fetchPermissionSettings() {
	return (dispatch) => {
		const api = new PermissionsApi();

		return api.getPermissionSettings()
			.then((result: CustomPermissions) => {
				dispatch(setPermissionSettings(result));
			}, (error) => {
				dispatch(errorNotification("Error getting permissions settings."))
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

export function updatePermissionSettings(permissions: CustomPermissions) {
	return (dispatch) => {
		const api = new PermissionsApi();

		return api.updatePermissionSettings(permissions)
			.then(() => {
				dispatch(setPermissionSettings(permissions));
			}, (error) => {
				dispatch(errorNotification("Error setting permissions settings."))
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

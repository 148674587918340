import * as React from "react";

import LightbarPreview from "Components/Actions/Lightbar/Preview";
import { LightbarPresets } from "Data/Objects/Actions";

export interface LightBarListProps {
	onSelect?: (index: number) => void;
	selected: number;
}

export class LightBarList extends React.Component<LightBarListProps> {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<div>
				{
					LightbarPresets.map((preset, index) => {
						return (
							<LightbarPreview
							// eslint-disable-next-line
								key={ index }
								index={ index }
								onClick={ this.handleLightBarSelect(index) }
								disabled
								selected={ index === this.props.selected } />
						);
					})
				}
			</div>
		)
	}

	handleLightBarSelect(index: number) {
		const { onSelect } = this.props;
		return () => {
			if (onSelect) {
				onSelect(index);
			}
		}
	}
}
export const AD_CARD_WIDTH = 95;

export const DEFAULT_CARD = {
	width: 200,
	padding: 10,
	margin: 10
}

const scheduleBuilder = {
	blue: "rgba(69,184,243, 0.7)",
	green: "rgba(6,204,180, 0.7)",
	yellow:	"rgba(244,206,52, 0.7)",
	orange: "rgba(248,166,51, 0.7)",
	red: "rgba(255,98,89, 0.7)",
	purple: "rgba(208,136,225, 0.7)",
	gray: "rgba(164,164,164, 0.7)"
};
let builderColorsArray: string[] = [];

for (const c in scheduleBuilder) {
	if (scheduleBuilder.hasOwnProperty(c)) {
		builderColorsArray.push(scheduleBuilder[c]);
	}
}

export const Colors = {
	// blues
	darkBlue: "#1E6DAF",
	lightBlue: "#D1E4FC",
	primaryBlue: "#127EC0",
	selectedBlue: "#D7E6FC",
	dropBackground: "rgba(209, 229, 252, 0.65)",

	// greens
	primaryGreen: "#33B889",

	// oranges
	orange: "#FFA500",
	darkOrange: "#F34C27",

	// whites
	white: "#FFFFFF",
	offWhite: "#F7F7F7",

	// grays
	gray: "#777777",
	lightishGray: "#555555",
	darkGray: "#272A34",
	darkishGray: "#2E333F",
	threeGray: "#333",
	twoGray: "#222",
	fiveGray: "#555",
	sixGray: "#666",
	darkerGray: "#12131A",
	gandalf: "#404042", // thanks @jroufa
	darkestGray: "#0F1115",
	lightGray: "#A4A4A7",
	lightestGray: "#D9D9D9",
	evenMoreLightGray: "#EEEEEE",

	// reds
	red: "#FD4445",
	errorRed: "#f04134",

	// misc
	slate: "#748191",
	black: "#000000",
	yellow: "#F4CE34",
	purple: "#5A4CAD",
	tan: "#FFEEBF",
	violet: "#C894D7",
	scheduleBuilder,
	builderColorsArray,
	filters: {
		active: "rgba(255, 255, 255, 0.1)",
		hover: "rgba(255, 255, 255, 0.05)",
		hoverAlt: "rgba(100, 100, 100, 0.4)",
		hoverLite: "rgba(100, 100, 100, 0.2)",
		inactive: "rgba(255, 255, 255, 0)"
	}
}

export const WIDGET_COLORS = {
	media: Colors.primaryBlue,
	ad: Colors.primaryGreen,
	deployment: Colors.orange,
	device: Colors.darkOrange,
	admin: Colors.purple,
	misc: Colors.lightGray
}

export const MimeTypes = {
	audio: "audio/*",
	video: "video/*",
	image: "image/*",
	csv: "text/csv"
}

import * as clinton from "@connect/Images/Icons/clinton_c.svg";

export const CustomIcons = {
	clinton
}
import { Button, Slider } from "antd";
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, ITickerComponent } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import ColorPickerButton from "Components/Global/ColorPickerButton";
import { Icon, Input } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import OptionButton from "Components/Global/OptionButton";
import OptionButtonGroup from "Components/Global/OptionButtonGroup";
import { updateComponent } from "Data/Actions/UI/AdBuilder";
import { TickerMaxLength } from "Data/Objects/Global";
import { getActiveAd, getSelectedComponentIndex } from "Data/Selectors/AdBuilder";

const mapDispatchToProps = (dispatch) => ({
	onComponentUpdated: (index: number, component: ITickerComponent) => dispatch(updateComponent(index, component, true))
});

const mapStateToProps = (state) => {
	const activeAd = getActiveAd(state);
	const index = getSelectedComponentIndex(state);
	const component = activeAd && index > -1 ? activeAd.layout.components[index] : null;

	return {
		component,
		componentIndex: index
	};
};

interface ITickerPropertiesContentProps {
	component: ITickerComponent;
	componentIndex: number;
	onComponentUpdated: (index: number, component: ITickerComponent) => void;
}

interface ITickerPropertiesContentState {
	showForegroundPicker: boolean;
	showBackgroundPicker: boolean;
}

export class TickerPropertiesPanelContent extends
	React.Component<ITickerPropertiesContentProps, ITickerPropertiesContentState> {
	constructor(props: ITickerPropertiesContentProps) {
		super(props);

		this.state = {
			showForegroundPicker: false,
			showBackgroundPicker: false
		}

		this.styles = {
			base: {
				color: Colors.white
			},
			row: {
				width: "100%",
				marginBottom: 14
			},
			textStyle: {
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between"
			},
			scrollButton: {
				width: "22%",
				height: 28,
				padding: 0,
				margin: 0
			},
			formatButton: {
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "center",
				marginLeft: 5,
				color: Colors.white,
				fontSize: "0.7rem",
				background: "#2e333f",
				border: 0,
				cursor: "pointer",
				borderRadius: 6,
				width: 50,
				height: 26
			},
			formatButtonSelected: {
				background: Colors.primaryBlue
			},
			floatRight: {
				float: "right"
			},
			colorPickerContainer: {
				marginBottom: 14,
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			},
			directionButtons: {
				width: "100%",
				display: "flex",
				justifyContent: "center",
				marginTop: 14,
				marginBottom: 14,
				padding: 0
			},
			speedSlider: {
				margin: "15px 10px",
				color: Colors.white
			}
		}

		this.toggleBackgroundPickerVisibility = this.toggleBackgroundPickerVisibility.bind(this);
		this.toggleForegroundPickerVisibility = this.toggleForegroundPickerVisibility.bind(this);
		this.updateMessage = this.updateMessage.bind(this);
		this.toggleBold = this.toggleBold.bind(this);
		this.toggleItalic = this.toggleItalic.bind(this);
		this.changeColor = this.changeColor.bind(this);
		this.setScrollingTextSpeed = this.setScrollingTextSpeed.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<div key={ this.props.component.id + this.props.component.messages[0] }>
				{ this.renderBannerDimensions() }
				{ this.renderMessageInput() }
				{ this.renderTextStyle() }
				{ this.renderTextColor() }
				{ this.renderBackgroundColor() }
				{ this.renderScrollingTextDirection() }
				{ this.renderScrollingTextSpeed() }
			</div>
		);
	}

	renderBackgroundColor() {
		const { floatRight, colorPickerContainer, row } = this.styles;
		const { component, componentIndex } = this.props;
		const { background } = component;

		return (
			<div style={row}>
				<span>Background Color:</span>
				<span style={floatRight}>{background}</span>
				<div style={colorPickerContainer}>
					<ColorPickerButton
						key={ background + componentIndex + component.id }
						startColor={ background }
						onColorSelected={ this.changeColor("background") }/>
				</div>
			</div>
		);
	}

	renderBannerDimensions() {
		// calculate the component dimensions in px... they come in as %
		// note that this currently assumes 1920x1080 in portrait
		const { component } = this.props;
		const componentWidth = Utils.getComponentWidthFromPercent(component.width.value);
		const componentHeight = Utils.getComponentHeightFromPercent(component.height.value);

		return (
			<div style={this.styles.row}>
				<span>Banner Dimensions: {componentWidth}x{componentHeight}px</span>
			</div>
		);
	}

	renderDoneButton(onclick: () => any) {
		return (
			<div style={{
				width: "100%",
				textAlign: "right"
			}}>
				<Button onClick={onclick}>
					Done
				</Button>
			</div>
		);
	}

	renderMessageInput() {
		const { id, messages } = this.props.component;
		const value = messages ? messages[0] : ""

		return (
			<div style={this.styles.row}>
				<div>Message:</div>
				<Input
					emoji={true}
					id={id}
					maxLength={ TickerMaxLength }
					saveCallback={this.updateMessage}
					value={value}
				/>
			</div>
		);
	}

	renderScrollingTextDirection() {
		const { directionButtons, row, scrollButton } = this.styles;
		const { scroll } = this.props.component;
		return (
			<div style={row}>
				<div>Scrolling Text Direction:</div>
				<OptionButtonGroup style={directionButtons}>
					<OptionButton
						key="left"
						selected={ scroll === "left" }
						style={ scrollButton }
						onClick={ this.setScroll("left") }>
						<Icon name="arrow-left" size="smaller" />
					</OptionButton>
					<OptionButton
						key="fixed"
						selected={ scroll === "fixed" }
						style={ scrollButton }
						onClick={ this.setScroll("fixed") }>
						<Icon name="ban" size="smaller" />
					</OptionButton>
					<OptionButton
						key="right"
						selected={ scroll === "right" }
						style={ scrollButton }
						onClick={ this.setScroll("right") }>
						<Icon name="arrow-right" size="smaller" />
					</OptionButton>
				</OptionButtonGroup>
			</div>
		);
	}

	renderScrollingTextSpeed() {
		const { id, speed } = this.props.component;

		return (
			<div style={this.styles.row}>
				<div>Scrolling Text Speed:</div>
				<div style={this.styles.speedSlider}>
					<Slider
						key={id + speed}
						min={1}
						max={5}
						marks={{ 1: 1, 3: 3, 5: 5 }}
						defaultValue={speed}
						tipFormatter={null}
						step={1}
						onChange={this.setScrollingTextSpeed} />
				</div>
			</div>
		);
	}

	renderTextColor() {
		const { floatRight, colorPickerContainer, row } = this.styles;
		const { component, componentIndex } = this.props;
		const { foreground } = component;

		return (
			<div style={row}>
				<span>Text Color:</span>
				<span style={floatRight}>{foreground}</span>
				<div style={colorPickerContainer}>
					<ColorPickerButton
						key={ foreground + componentIndex + component.id }
						startColor={ foreground }
						onColorSelected={ this.changeColor("foreground") }/>
				</div>
			</div>
		);
	}

	renderTextStyle() {
		const { textStyle, formatButton, formatButtonSelected, row } = this.styles;
		const { bold, italic } = this.props.component;

		return (
			<div style={row}>
				<div style={textStyle}>
					<span>Text Style:</span>
					<div>
						<OptionButton
							key="bold"
							style={{
								...formatButton,
								...(bold ? formatButtonSelected : null)
							}}
							onClick={this.toggleBold}>
							<strong>Bold</strong>
						</OptionButton>
						<OptionButton
							key="italic"
							style={{
								...formatButton,
								...(italic ? formatButtonSelected : null)
							}}
							onClick={this.toggleItalic}>
							<i>Italic</i>
						</OptionButton>
					</div>
				</div>
			</div>
		);
	}

	changeColor(place: string) {
		return (color: string) => {
			const { component, componentIndex, onComponentUpdated } = this.props;

			onComponentUpdated(componentIndex, update(component, {
				[place]: { $set: color }
			}));
		}
	}

	deleteMessage(index: number) {
		const { component, componentIndex, onComponentUpdated } = this.props;

		onComponentUpdated(componentIndex, update(component, {
			messages: { $splice: [ [ index, 1 ] ] }
		}));
	}

	setScroll(scroll: string) {
		return () => {
			const { component, componentIndex, onComponentUpdated } = this.props;

			onComponentUpdated(componentIndex, update(component, {
				scroll: { $set: scroll }
			}));
		}
	}

	setScrollingTextSpeed(value: number) {
		const { component, componentIndex, onComponentUpdated } = this.props;

		onComponentUpdated(componentIndex, update(component, {
			speed: { $set: value }
		}));
	}

	toggleBackgroundPickerVisibility() {
		this.setState((prevState) => {
			return update(prevState, {
				showBackgroundPicker: { $set: !prevState.showBackgroundPicker }
			});
		});
	}

	toggleBold() {
		const { component, componentIndex, onComponentUpdated } = this.props;

		onComponentUpdated(componentIndex, update(component, {
			bold: { $set: !this.props.component.bold }
		}));
	}

	toggleForegroundPickerVisibility() {
		this.setState((prevState) => {
			return update(prevState, {
				showForegroundPicker: { $set: !prevState.showForegroundPicker }
			});
		});
	}

	toggleItalic() {
		const { component, componentIndex, onComponentUpdated } = this.props;

		onComponentUpdated(componentIndex, update(component, {
			italic: { $set: !this.props.component.italic }
		}));
	}

	updateMessage(newMessage: string) {
		const { component, componentIndex, onComponentUpdated } = this.props;

		onComponentUpdated(componentIndex, update(component, {
			messages: { $set: [ newMessage ] }
		}));
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TickerPropertiesPanelContent);

import { Collapse } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IDevice, IStore, Filters } from "@connect/Interfaces";
import DeviceSizeFilter from "Components/Devices/DeviceSizeFilter";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import TagFilter from "Components/Global/TagFilter";
import { getStoresListAsync } from "Data/Actions/Company";
import { tryFetchDevicesAsync } from "Data/Actions/Devices";
import { setActiveFilterArray } from "Data/Actions/UI";
import { getActiveAnalyticsFilters } from "Data/Selectors/AdAnalytics";
import { getFilteredStores } from "Data/Selectors/Company";
import { getAllDevices } from "Data/Selectors/Devices";

const { Panel } = Collapse;
const { darkestGray, white, black } = Colors;

const mapDispatchToProps = (dispatch) => ({
	setDevicesFilter: (devices: string[]) => dispatch(setActiveFilterArray("devices", Filters.ANALYTICS, devices)),
	setStoresFilter: (stores: string[]) => dispatch(setActiveFilterArray("stores", Filters.ANALYTICS, stores)),
	setSizeFilter: (sizes: string[]) => dispatch(setActiveFilterArray("sizes", Filters.ANALYTICS, sizes)),
	fetchDevices: () => dispatch(tryFetchDevicesAsync()),
	fetchStores: () => dispatch(getStoresListAsync())
});

const mapStateToProps = (state) => {
	const filters = getActiveAnalyticsFilters(state);
	const { devices, stores, sizes } = filters;

	return {
		devices: getAllDevices(state),
		devicesFilter: devices,
		storesFilter: stores,
		sizeFilter: sizes,
		stores: getFilteredStores(state, "")
	};
};

interface AnalyticsFiltersProps {
	devices: IDevice[];
	devicesFilter: string[];
	fetchDevices: () => void;
	fetchStores: () => void;
	setDevicesFilter: (devices: string[]) => void;
	setStoresFilter: (stores: string[]) => void;
	setSizeFilter: (sizes: string[]) => void;
	sizeFilter: string[];
	stores: IStore[];
	storesFilter: string[];
}

export class UnwrappedAnalyticsFilters extends React.Component<AnalyticsFiltersProps> {
	constructor(props: AnalyticsFiltersProps) {
		super(props);

		this.styles = {
			collapse: {
				display: "flex",
				flexDirection: "column",
				height: "100%"
			},
			header: {
				backgroundColor: darkestGray
			},
			item: {
				color: white,
				height: 35,
				lineHeight: "35px",
				padding: "0px 36px 0px 8px",
				width: "100%"
			},
			leftPadding: {
				paddingLeft: 8
			},
			noBorder: {
				border: 0,
				borderRadius: 0,
				overflow: "hidden"
			},
			input: {
				width: "calc(100% - 12px)",
				padding: "8px 0px",
				margin: "0px 6px"
			},
			clear: {
				position: "absolute",
				right: 34
			},
			select: {
				color: white,
				width: 220
			},
			dark: {
				background: black
			}
		}

		this.clearAllFilters = this.clearAllFilters.bind(this);
		this.clearDevicesFilter = this.clearDevicesFilter.bind(this);
		this.clearSizeFilter = this.clearSizeFilter.bind(this);
		this.clearStoreFilter = this.clearStoreFilter.bind(this);
		this.handleSetDevicesFilter = this.handleSetDevicesFilter.bind(this);
		this.handleSetStoresFilter = this.handleSetStoresFilter.bind(this);
		this.renderReportPanelHeader = this.renderReportPanelHeader.bind(this);
		this.setProperty = this.setProperty.bind(this);
		this.setSizeProperty = this.setSizeProperty.bind(this);
	}

	styles: {
		input: CustomCSS;
		clear: CustomCSS;
		select: CustomCSS;
		collapse: CustomCSS;
		noBorder: CustomCSS;
		header: CustomCSS;
		item: CustomCSS;
		leftPadding: CustomCSS;
		dark: CustomCSS;
	}

	componentWillMount() {
		const { fetchDevices, fetchStores } = this.props;

		fetchDevices();
		fetchStores();
	}

	render() {
		const { collapse, noBorder } = this.styles;

		return (
			<Collapse
				defaultActiveKey={[ "Device Size", "Stores" ]}
				style={{
					...collapse,
					...noBorder
				}}
			>
				{ this.renderDeviceSizePanel() }
				{ this.renderStoresPanel() }
				{ this.renderDevicesPanel() }
			</Collapse>
		);
	}

	renderDeviceSizePanel() {
		return this.renderPanel("Device Size", (
			<DeviceSizeFilter
				onClick={ this.setSizeProperty }
				sizeFilter={ this.props.sizeFilter }
			/>
		), this.clearSizeFilter);
	}

	renderDevicesPanel() {
		const { input, select } = this.styles;
		const { devicesFilter, devices } = this.props;
		const nameFilter = devices
			.filter((device) => devicesFilter.includes(device.uuid))
			.map((device) => device.name);
		const deviceList = devices.map((device) => device.name);

		return this.renderPanel("Devices", (
			<div style={ input }>
				<TagFilter
					key={ nameFilter.length }
					theme="dark"
					style={ select }
					activeTags={ nameFilter }
					tags={ deviceList }
					tagSelectChange={ this.handleSetDevicesFilter }
					tagType={ "devices" }
				/>
			</div>
		), this.clearDevicesFilter);
	}

	renderPanel(header: string, content: JSX.Element, onClear: () => void) {
		const { dark, noBorder } = this.styles;

		return (
			<Panel
				header={ this.renderReportPanelHeader(header, onClear) }
				key={ header }
				style={ noBorder }
			>
				<div style={ dark }>
					{ content }
				</div>
			</Panel>
		);
	}

	renderReportPanelHeader(title: string, onClear: () => void) {
		const { header, item, leftPadding, noBorder, clear } = this.styles;

		return (
			<div style={{
				...header,
				...item,
				...noBorder
			}}>
				<span style={ leftPadding }>{ title }</span>
				<Icon
					style={ clear }
					size="smaller"
					name="times-circle"
					iconWeight="regular"
					onClick={ this.handlePanelHeaderClick(onClear) }
				/>
			</div>
		);
	}

	renderStoresPanel() {
		const { input, select } = this.styles;
		const { storesFilter, stores } = this.props;
		const nameFilter = stores
			.filter((store) => storesFilter.includes(store.uuid))
			.map((store) => store.name);
		const storeList = stores.map((store) => store.name);

		return this.renderPanel("Stores", (
			<div style={ input }>
				<TagFilter
					key={ nameFilter.length }
					theme="dark"
					style={ select }
					activeTags={ nameFilter }
					tags={ storeList }
					tagSelectChange={ this.handleSetStoresFilter }
					tagType={ "devices" }
				/>
			</div>
		), this.clearStoreFilter);
	}

	handlePanelHeaderClick(onClear: () => void) {
		return (e: React.SyntheticEvent) => {
			e.stopPropagation();
			onClear();
		}
	}

	handleSetDevicesFilter(tagNames: string[]) {
		const { devices, setDevicesFilter } = this.props;
		const deviceUuids = devices
			.filter((device) => tagNames.includes(device.name))
			.map((device) => device.uuid);

		setDevicesFilter(deviceUuids);
	}

	handleSetStoresFilter(tagNames: string[]) {
		const { stores, setStoresFilter } = this.props;
		const storeUuids = stores
			.filter((store) => tagNames.includes(store.name))
			.map((store) => store.uuid);

		setStoresFilter(storeUuids);
	}

	setProperty(prop: string, value: string, setter: Function) {
		const filter = [ ...this.props[prop] ];

		if (filter.includes(value)) {
			filter.splice(filter.indexOf(value), 1);
			return setter(filter);
		}

		filter.push(value);
		setter(filter);
	}

	clearAllFilters() {
		this.clearSizeFilter();
		this.clearStoreFilter();
	}

	clearDevicesFilter() {
		this.props.setDevicesFilter([]);
	}

	clearSizeFilter() {
		this.props.setSizeFilter([]);
	}

	clearStoreFilter() {
		this.props.setStoresFilter([]);
	}

	setSizeProperty(size: string) {
		this.setProperty("sizeFilter", size, this.props.setSizeFilter);
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(UnwrappedAnalyticsFilters);

import { createSelector } from "reselect";

import { ActionSet, ActionSetCollectionArgs, SortTypes } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { ActionsState, AppState } from "Data/Objects/AppState";
import { getActiveUuid } from "Data/Selectors/UI";

export function getActionsState(state: AppState) {
	return state.Actions;
};

export function getSets(state: ActionsState) {
	return state.actionSets;
};

export function getUuid(state: AppState) {
	return getActiveUuid(state, "actions");
}

export function getSetByUuid(sets: ActionSet[], uuid: string) {
	return sets.filter((set) => set.uuid === uuid)[0];
}

export const getActionSets = createSelector(
	[ getActionsState ],
	getSets
);

export const getActiveActionSet = createSelector(
	[ getActionSets, getUuid ],
	getSetByUuid
);

export function getActionsSorted(actions: ActionSet[], sortType: SortTypes) {
	return Utils.sortListBySortType(actions, sortType);
}

export function getActionsFilteredBySearch(actions: ActionSet[], filter: string) {
	const filterText = filter.toLowerCase();
	return actions.filter((ad) => ad.name && ad.name.toLowerCase().includes(filterText));
}

export function getActionSort(state: AppState, args: ActionSetCollectionArgs): SortTypes {
	return args.sort || SortTypes.NEWEST_FIRST
}

export function getActionsFilter(state: AppState, args: ActionSetCollectionArgs): string {
	return args.filter || "";
}

export const getAllActions = createSelector(
	[ getActionsState ],
	getSets
);

export const getFilteredActions = createSelector(
	[ getAllActions, getActionsFilter ],
	getActionsFilteredBySearch
);

export const getFilteredSortedActions = createSelector(
	[ getFilteredActions, getActionSort ],
	getActionsSorted
);
import * as React from "react";
import { connect } from "react-redux";
import getSlider, { SliderOpts } from "simple-slider-ts";

import { Colors } from "Components/Global/Constants";
import { Ad } from "Data/Objects/Ads";
import { Media } from "Data/Objects/Media";
import { getActiveAd } from "Data/Selectors/AdBuilder";
import { getAdMediaForComponent } from "Data/Selectors/Ads";

interface IAnimationBase extends SliderOpts {
	delay: number;
	trTime: number;
}

interface IAnimation extends IAnimationBase {
	endVal: number;
	startVal: number;
	trProp: string;
	unit: string;
	visVal: number;
}

interface ISlideshowProps {
	ad: Ad;
	animation: "fade" | "push" | "wipe" | "none";
	direction: "up" | "right" | "down" | "left";
	durationPerSlide: 5 | 10 | 15 | 20;
	height: number;
	media: Media[];
	onChangeEnd: (index: number, nextIndex: number) => void;
	transitionLength: .5 | 1 | 1.5 | 2;
	width: string;
}

interface ISlideshowState {
	animations: {
		fade: IAnimation;
		none: IAnimation;
		push: IAnimation;
		wipe: IAnimation;
	};
}

function getAnimationDetails(props: ISlideshowProps) {
	const { direction, durationPerSlide, transitionLength } = props;
	const leftRight = direction === "left" || direction === "right";
	const leftUp = direction === "up" || direction === "left";
	const upLeft = direction === "left" || direction === "up";
	const base = {
		delay: durationPerSlide || 5,
		trTime: transitionLength || .5
	};

	return {
		fade: {
			...base,
			trProp: "opacity",
			unit: "",
			startVal: 0,
			visVal: 1,
			endVal: 0
		},
		push: {
			...base,
			trProp: leftRight ? "left" : "top",
			unit: "%",
			startVal: leftUp ? 100 : -100,
			visVal: 0,
			endVal: leftUp ? -100 : 100
		},
		wipe: {
			...base,
			trProp: leftRight ? "left" : "top",
			unit: "%",
			startVal: upLeft ? 100 : -100,
			visVal: 0,
			endVal: 0
		},
		none: {
			...base,
			trProp: "visible",
			unit: "%",
			startVal: 0,
			visVal: 100,
			endVal: 0
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	const ad = getActiveAd(state);
	const { component } = ownProps;
	const id = component ? component.id : null;

	return {
		ad,
		media: getAdMediaForComponent(state, ad ? ad.layout.media : [], id)
	};
};

export class Slideshow extends React.Component<ISlideshowProps, ISlideshowState> {
	constructor(props: ISlideshowProps) {
		super(props);

		this.state = {
			animations: getAnimationDetails(props)
		};

		this.borderWidth = 2;

		this.renderImage = this.renderImage.bind(this);
		this.getConfig = this.getConfig.bind(this);
		this.getSliderForComponent = this.getSliderForComponent.bind(this);
		this.setSliderComponentRef = this.setSliderComponentRef.bind(this);
	}

	borderWidth: number;
	sliderComponent: HTMLDivElement;
	sliderInstance: any;

	static getDerivedStateFromProps(props: ISlideshowProps, state: ISlideshowState) {
		return {
			animations: getAnimationDetails(props)
		};
	}

	componentDidMount() {
		this.getSliderForComponent();
	}

	componentDidUpdate(prevProps: ISlideshowProps) {
		const { animation, direction, durationPerSlide, media, transitionLength } = prevProps;

		if (animation !== this.props.animation
			|| direction !== this.props.direction
			|| durationPerSlide !== this.props.durationPerSlide
			|| media.length !== this.props.media.length
			|| transitionLength !== this.props.transitionLength) {
			this.getSliderForComponent();
		}
	}

	componentWillUnmount() {
		this.sliderInstance.dispose();
	}

	render() {
		const { height, media, width } = this.props;

		return (
			<div
				style={{ height, width }}
				ref={ this.setSliderComponentRef }
			>
				{ media.map(this.renderImage) }
			</div>
		);
	}

	renderImage(m: Media, i: number) {
		return (
			<div
				key={m.uuid + i + m.name}
				style={{
					height: "inherit",
					width: this.props.width,
					backgroundRepeat: "no-repeat",
					position: "absolute",
					overflow: "hidden",
					backgroundImage: "url(" + m.uri + ")",
					backgroundSize: "cover",
					backgroundPosition: "center center",
					backgroundColor: Colors.white
				}}
			/>
		);
	}

	getConfig(): SliderOpts {
		const { animation } = this.props;
		const { animations } = this.state;

		if (animation) {
			return animations[animation];
		}

		return animations.none;
	}

	getSliderForComponent() {
		const { media, onChangeEnd } = this.props;
		const config = {
			containerElem: this.sliderComponent,
			onChangeEnd: onChangeEnd || null,
			...this.getConfig()
		} as SliderOpts;

		if (this.sliderInstance) {
			this.sliderInstance.updateConfig(config, true);
		} else if (this.sliderComponent && media.length) {
			this.sliderInstance = getSlider(config);
		}

		// trigger file list highlight
		onChangeEnd(0, 1);
	}

	setSliderComponentRef(div: HTMLDivElement) {
		this.sliderComponent = div;
	}
}

export default connect(mapStateToProps, null)(Slideshow);

import * as React from "react";
import { DropTarget, DropTargetSpec } from "react-dnd";
import { connect as connectToRedux } from "react-redux";

import { CustomCSS } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import ScheduledItem from "Components/Deploy/Schedule/ScheduledItem";
import { addScheduleItem } from "Data/Actions/UI/DeploymentWizard";

interface IBuilderProps extends DropTargetSpec<IBuilderProps> {
	id: string;
	minItemHeight: number;
	containerHeight: number;
	items: any[];
	onSetContainerHeight: (clientHeight: number) => void;
	onAddItem: (item: any, y: number) => void;
	connectDropTarget?: any;
	connectDragSource?: Function;
}

const mapDispatchToProps = (dispatch, { containerHeight }) => ({
	onAddItem: (item, yOffset) => dispatch(addScheduleItem(item, yOffset, containerHeight))
});

const scheduleTarget = {
	drop(props: any, monitor: any, component: any) {
		const { id, onAddItem } = props;
		let clientOffset = monitor.getClientOffset();

		if (clientOffset) {
			const sourceObj = monitor.getItem();

			clientOffset.y -= component.fromTop;

			onAddItem(sourceObj, clientOffset.y);
		}

		return {
			listId: id
		};
	}
};

class Builder extends React.Component <IBuilderProps> {
	constructor(props: IBuilderProps) {
		super(props);

		this.styles = {
			base: {
				background: "transparent",
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				zIndex: 100
			}
		}

		this.getBuilderDiv = this.getBuilderDiv.bind(this);
	}

	styles: CustomCSS;
	fromTop: number;
	_container: HTMLDivElement;

	componentDidMount() {
		const { containerHeight, onSetContainerHeight } = this.props;
		const { _container } = this;

		if (_container && !containerHeight) {
			// if our ref already exists, get the absolute
			// position of it and pass up the clientHeight
			onSetContainerHeight(_container.clientHeight);
		}
	}

	render() {
		const { connectDropTarget, containerHeight, items, minItemHeight, onAddItem, id } = this.props;
		const { base } = this.styles;
		this.fromTop = Utils.getAbsolutePosition(this._container).y;
		return connectDropTarget(
			<div
				id="schedule-builder"
				style={ base }
				key={ items.length }
				ref={ this.getBuilderDiv }>
				{items.map((item, i) => {
					return (
						<ScheduledItem
							key={`${item.uuid}-${items.length}-${item.startTime}-${item.color}`}
							fromTop={this.fromTop}
							resizable={i !== items.length - 1}
							containerHeight={containerHeight}
							minHeight={minItemHeight}
							maxHeight={this.getMaxItemHeight(item, i)}
							listId={id}
							index={i}
							item={item}
							onAddItem={onAddItem}
						/>
					);
				})}
			</div>
		);
	}

	getBuilderDiv(div: HTMLDivElement) {
		this._container = div;
	}

	getMaxItemHeight(item: any, index: number) {
		// max height should be the total of the durations of the current
		// duration plus the next duration less the minimum duration (60)
		const { items, containerHeight } = this.props;
		const minDuration = 60;
		const nextItem = items[index + 1];
		if (nextItem && nextItem.duration) {
			return Utils.getHeightFromDuration(item.duration + nextItem.duration - minDuration, containerHeight);
		}
		return containerHeight;
	}
}

export default connectToRedux(null, mapDispatchToProps)(DropTarget("PLAYLIST", scheduleTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop()
}))(Builder));
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IMedia } from "@connect/Interfaces";
import Button from "Components/Global/Button";
import Icon, { IconWeights } from "Components/Global/Icon";
import { updateMediaAsync } from "Data/Actions/MediaAsync";
import { getMediaById } from "Data/Selectors/Media";
import { cloneDeep } from "lodash";

interface FavoriteToggleProps {
	fullWidth?: boolean;
	type: "button" | "icon";
	media: IMedia;
	mediaId: string;
	style?: CustomCSS;
	updateMedia: (media: IMedia) => void;
}

const mapDispatchToProps = (dispatch) => ({
	updateMedia: (media: IMedia) => dispatch(updateMediaAsync(media))
});

const mapStateToProps = (state, ownProps) => ({
	media: getMediaById(state, { uuid: ownProps.mediaId })
});

export class FavoriteToggle extends React.PureComponent<FavoriteToggleProps> {
	constructor(props: FavoriteToggleProps) {
		super(props);

		this.renderButton = this.renderButton.bind(this);
		this.renderIcon = this.renderIcon.bind(this);
		this.handleFavoriteToggle = this.handleFavoriteToggle.bind(this);
	}

	render() {
		if (!this.props.media) {
			return null;
		}

		switch (this.props.type) {
			case "button":
				return (this.renderButton());
			case "icon":
				return (this.renderIcon());
		}
	}

	renderButton() {
		const { fullWidth, media } = this.props;
		let buttonColor = "transparent",
			icon = "star",
			iconWeight = "regular",
			borderColor = "white",
			buttonText = "Add Favorite";

		if (media.favorite) {
			buttonColor = "#127EC0",
			icon = "star",
			iconWeight = "",
			borderColor = "#127EC0",
			buttonText = "Favorited";
		}

		return (
			<Button
				type="primary"
				color={buttonColor}
				icon={icon}
				iconWeight={iconWeight as IconWeights}
				fluid={!fullWidth}
				style={{
					borderColor: borderColor,
					width: fullWidth ? "100%" : "auto"
				}}
				onClick={ this.handleFavoriteToggle }>
				{buttonText}
			</Button>
		);
	}

	renderIcon() {
		return (
			<Icon
				name={ "star" }
				iconWeight={ this.props.media.favorite ? "solid" : "regular" }
				onClick={ this.handleFavoriteToggle }
				style={this.props.style}/>
		);
	}

	handleFavoriteToggle(e: React.SyntheticEvent<any>) {
		e.stopPropagation();

		let updatedMedia = cloneDeep(this.props.media);
		updatedMedia.favorite = !updatedMedia.favorite;

		this.props.updateMedia(updatedMedia);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteToggle);
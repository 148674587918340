import * as React from "react";

import { history, store } from "@connect/Data";
import { CustomCSS } from "@connect/Interfaces";
import { Button } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { clearCacheAndLogout } from "Data/Actions/SystemAsync";

const { offWhite, lightGray, lightestGray, white } = Colors;

interface ErrorScreenProps {
	error: {};
	info: ErrorInfo;
}

interface ErrorScreenState {
	expanded: boolean;
}

interface ErrorInfo {
	componentStack: string;
}

class ErrorScreen extends React.PureComponent<ErrorScreenProps, ErrorScreenState> {
	constructor(props: ErrorScreenProps) {
		super(props);

		this.state = {
			expanded: false
		}

		this.styles = {
			container: {
				width: "100vw",
				height: "100vh",
				paddingTop: "20vh",
				background: offWhite
			},
			content: {
				background: white,
				width: 760,
				padding: "70px 40px 25px",
				margin: "auto",
				borderRadius: 2,
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 4px"
			},
			header: {
				fontWeight: 500,
				fontSize: "2em",
				textAlign: "center",
				marginBottom: 35
			},
			text: {
				fontWeight: 500,
				fontSize: "1.3em",
				textAlign: "center",
				color: lightGray,
				marginBottom: 35
			},
			clintonIcon: {
				width: 80,
				height: 80,
				position: "absolute",
				top: "21%",
				left: "50%",
				transform: "translateX(-50px) translateY(-50px)"
			},
			buttonsContainer: {
				marginBottom: 35,
				display: "flex",
				width: "100%"
			},
			buttonSection: {
				flex: 1,
				textAlign: "center"
			},
			button: {
				width: "50%",
				height: 50,
				fontSize: "1.6em"
			},
			errorContainer: {
				width: "100%",
				maxHeight: 200,
				overflowY: "auto",
				padding: 10,
				color: lightGray,
				border: `1px solid ${ lightestGray }`,
				marginBottom: 5
			},
			expander: {
				color: lightGray,
				textAlign: "center",
				fontSize: "1.3em",
				fontWeight: 500,
				cursor: "pointer"
			}
		}

		this.toggleExpanded = this.toggleExpanded.bind(this);
		this.renderError = this.renderError.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { button, buttonsContainer, buttonSection, clintonIcon,
			container, content, expander, header, text } = this.styles;

		const expanderText = this.state.expanded ? "Less Details" : "More Details";

		return (
			<div style={ container }>
				<div style={ content }>
					<img src="/img/ClintonConnect_C.svg" style={ clintonIcon } />
					<p style={ header }>Something Went Wrong</p>
					<div style={ text }>
						<p>Refresh the page to try again.</p>
						<p>If the problem persists, log out to clear your application cache.</p>
					</div>
					<div style={ buttonsContainer }>
						<div style={ buttonSection }>
							<Button
								onClick={ this.handleLogout }
								style={ button }
								size="large"
								type="primary"
								icon="sign-out-alt"
							>
								LOG OUT
							</Button>
						</div>
					</div>
					{ this.renderError() }
					<div style={ expander } onClick={ this.toggleExpanded } >
						{ expanderText }
					</div>
				</div>
			</div>
		);
	}

	handleLogout() {
		history.push("/");
		store.dispatch(clearCacheAndLogout());
	}

	toggleExpanded() {
		this.setState(prevState => ({
			expanded: !prevState.expanded
		}));
	}

	renderError() {
		if (!this.props.error || !this.state.expanded) {
			return null;
		}

		const { errorContainer } = this.styles;

		return (
			<div style={ errorContainer }>
				{ this.props.info.componentStack }
			</div>
		)
	}
}

export default ErrorScreen;
import * as update from "immutability-helper";
import { createSelector } from "reselect";

import { WidgetLocationState, WidgetState } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { ActiveObjectArrayState } from "Data/Objects/AppState";
import { initialWidgetLocations, WIDGET_TYPES } from "Data/Objects/Widgets";
import { getUserPermissions, getUserWidgets } from "Data/Selectors/User";
import { cloneDeep } from "lodash";

export function getRenderableUserWidgets(
	widgets: WidgetState,
	widgetLocations: WidgetLocationState,
	permissions: string[]
): ActiveObjectArrayState {
	const widgetNames = Object.keys(widgets).map((w) => w.toUpperCase().replace(" ", "_"));
	const widgetValues = Object.values(widgets).map((widget, index) => {
		// guard against old widget formatting, where we didn't have indices
		if (typeof widget === "string") {
			widget = { position: widget, index };
		}

		return widget;
	});

	return widgetValues.reduce((locations, loc, index, values) => {
		const name = widgetNames[index];

		if (Utils.userHasPermissions(WIDGET_TYPES[name].permissions, permissions)) {
			if (!locations[loc.position]) {
				locations[loc.position] = [];
			}

			return update(locations, {
				[loc.position]: { $push: [ Object.assign({}, loc, { name }) ] }
			});
		}

		return locations;
	}, {});
}

export function getUserWidgetLocations(widgets: WidgetState): WidgetLocationState {
	const widgetValues = Object.values(widgets);
	const locations = cloneDeep(initialWidgetLocations);

	return widgetValues.reduce((state, next, index) => {
		state[next.position] = true;
		return state;
	}, locations);
}

export const getWidgetLocations = createSelector(
	[ getUserWidgets ],
	getUserWidgetLocations
);

export const getRenderableWidgets = createSelector(
	[ getUserWidgets, getWidgetLocations, getUserPermissions ],
	getRenderableUserWidgets
);

import { CloudProvider, ICompany, IExtendedCompany, IUser, MediaLimit, MediaUsage, NameUuid,
	Team, PasswordRequirements} from "@connect/Interfaces";
import { cloneDeep } from "lodash";

export class Company implements ICompany {
	active: boolean;
	cloudServiceProvider: CloudProvider;
	createdAt: string;
	customPermissions: boolean;
	hashid: string;
	integrations: NameUuid[];
	mediaLimit: MediaLimit;
	mediaUsage: MediaUsage;
	name: string;
	teamType: string;
	updatedAt: string;
	uuid: string;
	passwordRequirements: PasswordRequirements;

	static clone(company: Company) {
		return cloneDeep(company);
	}
}

export class ExtendedCompany extends Company implements IExtendedCompany {
	devicesCount: number;
	integrators?: Partial<Team>[];
	owners: Partial<IUser>[];
	usersCount: number;
}
import { Action, MediaDispatch, MediaListDispatch, MediaUuidDispatch, MediaUploadDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { MediaState } from "Data/Objects/AppState";
import {
	createReducer,
	deleteFromState,
	getIndexFromState,
	mergeWithUuidArrays,
	pushToState,
	setState,
	updateStateArray,
	updateStateArrayItem
} from "Data/Utils";
import { toggleFeature } from "@connect/Features";
import * as update from "immutability-helper";

export function getMediaIndex(state: MediaState, uuid: string) {
	return getIndexFromState(state.media, uuid);
}

export function createMedia(mediaState: MediaState, action: Action<MediaDispatch>) {
	return pushToState(mediaState, "media", action.args.media);
}

export function deleteMedia(mediaState: MediaState, action: Action<MediaUuidDispatch>) {
	const index = getMediaIndex(mediaState, action.args.uuid);
	if (index >= 0) {
		return deleteFromState(mediaState, "media", index);
	}

	return mediaState;
}

export function resetMedia(mediaState: MediaState, action: Action<null>) {
	return setState(mediaState, "media", []);
}

export function setMedia(mediaState: MediaState, action: Action<MediaListDispatch>) {
	if (action.args.reset) {
		return setState(mediaState, "media", action.args.media);
	}

	return mergeWithUuidArrays(mediaState, "media", action.args.media);
}

export function updateMedia(mediaState: MediaState, action: Action<MediaDispatch>) {
	const { media } = action.args;

	if (toggleFeature("notifications", true, false)) {
		return updateMedia2(mediaState, action);
	}

	const index = getMediaIndex(mediaState, media.uuid);

	if (index === -1) {
		return pushToState(mediaState, "media", media);
	}

	return updateStateArray(mediaState, "media", index, media);
}

export function updateMedia2(mediaState: MediaState, action: Action<MediaDispatch>) {
	const { media } = action.args;
	return updateStateArrayItem(mediaState, "media", media);
}

/*
* Reducers for managing uploads
*/

export function getUploadIndex(mediaState: MediaState, uuid: string) {
	return getIndexFromState(mediaState.uploads, uuid);
}

export function endUpload(mediaState: MediaState, action: Action<MediaUploadDispatch>) {
	const index = getUploadIndex(mediaState, action.args.upload.uuid);
	return deleteFromState(mediaState, "uploads", index);
}

export function startUpload(mediaState: MediaState, action: Action<MediaUploadDispatch>) {
	return pushToState(mediaState, "uploads", action.args.upload);
}

export function updateProgress(mediaState: MediaState, action: Action<MediaUploadDispatch>) {
	const { upload } = action.args;
	const index = getUploadIndex(mediaState, upload.uuid);
	return updateStateArray(mediaState, "uploads", index, upload);
}

export function clearUploads(mediaState: MediaState, action: Action<null>) {
	return update(mediaState, {
		uploads: {
			$set: []
		}
	});
}

const {
	CREATE_MEDIA,
	DELETE_MEDIA,
	RESET_MEDIA,
	SET_MEDIA,
	UPDATE_MEDIA,
	END_UPLOAD,
	START_UPLOAD,
	UPDATE_PROGRESS,
	CLEAR_UPLOADS
} = ACTION_TYPES.Media;

const reducers = {
	[CREATE_MEDIA.type]: createMedia,
	[DELETE_MEDIA.type]: deleteMedia,
	[RESET_MEDIA.type]: resetMedia,
	[SET_MEDIA.type]: setMedia,
	[UPDATE_MEDIA.type]: updateMedia,
	[END_UPLOAD.type]: endUpload,
	[START_UPLOAD.type]: startUpload,
	[UPDATE_PROGRESS.type]: updateProgress,
	[CLEAR_UPLOADS.type]: clearUploads
};

export default createReducer(reducers, MediaState);

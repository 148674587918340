import { match as matchType, matchPath } from "react-router";
import { LOCATION_CHANGE, LocationChangeAction } from "react-router-redux";

import { Action, ActiveUuidDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ActiveStringState } from "Data/Objects/AppState";
import { initialActiveUuidStates } from "Data/Objects/UI";
import { createReducer, setState } from "Data/Utils";

export type PageUuidType = {
	page: string;
	uuid: string;
};

export function setActiveUuid(state: ActiveStringState, action: Action<ActiveUuidDispatch>) {
	const { path, uuid } = action.args;

	return setState(state, path, uuid);
}

export function setUuidFromLocation(state: ActiveStringState, action: LocationChangeAction) {
	const { pathname } = action.payload
	const match: matchType<PageUuidType> | null = matchPath(pathname, {
		path: "/:page/:uuid?"
	});

	const empty = new ActiveStringState(initialActiveUuidStates);

	if (match && match.params) {
		const { params: { page, uuid } } = match;
		if (page !== "admin" && state[page] !== uuid) {
			return setState(empty, page, uuid);
		} else {
			return state;
		}
	}

	return empty;
}

export function resetActiveUuids(state: ActiveStringState, action: Action<null>) {
	return new ActiveStringState(initialActiveUuidStates);
}

const reducers = {
	[ACTION_TYPES.UI.SET_ACTIVE_UUID.type]: setActiveUuid,
	[LOCATION_CHANGE]: setUuidFromLocation,
	[ACTION_TYPES.UI.RESET_ACTIVE_UUIDS.type]: resetActiveUuids
};

export default createReducer(reducers, ActiveStringState, initialActiveUuidStates);

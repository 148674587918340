import { ColumnProps } from "antd/lib/table/interface";
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, IStore } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import StoreInfoPanel from "Components/Admin/StoreInfoPanel";
import { Table } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { deleteStoreAsync, getStoresListAsync } from "Data/Actions/Company";
import { setActiveSearch, setActiveSelection, setActiveUuid } from "Data/Actions/UI";
import { getStores } from "Data/Selectors/Company";
import { getActiveSearch, getActiveSelection, getStoresAsyncState } from "Data/Selectors/UI";
import { deepSearch } from "Data/Utils";

const mapStateToProps = (state) => {
	const { currentPage, haveAllData } = getStoresAsyncState(state);
	const stores = getStores(state);

	return {
		filterText: getActiveSearch(state, "stores"),
		selectedStores: getActiveSelection(state, "stores"),
		currentPage,
		haveAllData,
		stores
	}
};

const mapDispatchToProps = (dispatch) => ({
	deleteStore: (currentStore: IStore) => dispatch(deleteStoreAsync(currentStore)),
	getStores: () => dispatch(getStoresListAsync()),
	selectStores: (userUuids: string[]) => dispatch(setActiveSelection("stores", userUuids)),
	setActiveStore: (storeUuid: string) => dispatch(setActiveUuid("stores", storeUuid)),
	setSearch: (query: string) => dispatch(setActiveSearch("stores", query))
});

interface OptionalTableProps {
	rowSelection?: {
		onChange: (keys: string[]) => void,
		selectedRowKeys: string[],
	}
	expandedRowKeys?: string[];
}

interface AdminStoresTableProps {
	currentPage: number;
	deleteStore: (currentStore: IStore) => void;
	filterText: string;
	getStores: () => void;
	handleStoreSelection?: (uuids: string[]) => void;
	haveAllData: boolean;
	lastFetchedPage: number;
	selectedStores: string[];
	selectModeOn?: boolean;
	selectStores: (userUuids: string[]) => void;
	setActiveStore: (uuid: string) => void;
	setSearch: (query: string) => void;
	stores: IStore[];
}

class AdminStoresTable extends React.Component<AdminStoresTableProps> {
	constructor(props: AdminStoresTableProps) {
		super(props);

		this.styles = {
			header: {
				padding: "0 30px"
			},
			question: {
				color: Colors.lightGray,
				cursor: "pointer",
				marginLeft: 6
			},
			link: {
				textDecoration: "none"
			}
		}

		this.handlePaging = this.handlePaging.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
	}

	styles: CustomCSS;
	columns: ColumnProps<IStore>[];

	componentWillMount() {
		this.props.getStores();
	}

	render() {
		const { currentPage, selectedStores, selectModeOn } = this.props;
		const props: OptionalTableProps = {};

		if (selectModeOn) {
			props.rowSelection = {
				selectedRowKeys: selectedStores,
				onChange: this.handleSelectChange
			};

			props.expandedRowKeys = [ "" ];
		}

		return (
			<React.Fragment>
				<Table
					{ ...props }
					size="middle"
					className="admin-table"
					rowKey={ this.getRowKey }
					columns={ this.getColumns() }
					expandRowByClick
					indentSize={ 0 }
					expandedRowRender={ this.renderRow }
					dataSource={ this.getDataSource() }
					locale={{ emptyText: "No Stores" }}
					pagination={ false }
				/>
				<Waypoint
					key={ String(currentPage) }
					onEnter={ this.handlePaging }
				/>
			</React.Fragment>
		);
	}

	renderRow(record: IStore) {
		return (
			<StoreInfoPanel
				key={ record.uuid }
				currentStore={ record }
			/>
		);
	}

	confirmDeleteBulk() {
		Notifications.confirm(
			`Are you sure you want to delete ${ this.props.selectedStores.length } stores?`,
			"",
			"Delete",
			"Cancel",
			this.deleteBulk
		);
	}

	deleteBulk() {
		const { stores, deleteStore, selectedStores, selectStores } = this.props;

		stores.filter((store) => {
			return selectedStores.includes(store.uuid);
		}).forEach((store, index, arr) => {
			deleteStore(store);

			if (index === arr.length - 1) {
				selectStores([]);
			}
		});
	}

	getColumns() {
		return [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				sorter: Utils.columnSorter("name"),
				defaultSortOrder: "descend"
			},
			{
				title: "Address 1",
				dataIndex: "address1",
				key: "address1",
				sorter: Utils.columnSorter("address1")
			},
			{
				title: "Address 2",
				dataIndex: "address2",
				key: "address2",
				sorter: Utils.columnSorter("address2")
			},
			{
				title: "City",
				dataIndex: "city",
				key: "city",
				sorter: Utils.columnSorter("city")
			},
			{
				title: "State",
				dataIndex: "state",
				key: "state",
				sorter: Utils.columnSorter("state")
			},
			{
				title: "ZIP Code",
				dataIndex: "postalCode",
				key: "postalCode",
				sorter: Utils.columnSorter("postalCode")
			}
		] as ColumnProps<IStore>[];
	}

	getDataSource() {
		const { stores, filterText } = this.props;
		const keys = [ "name", "address1", "address2", "city", "state", "postalCode" ];

		return deepSearch<IStore>(stores, filterText, keys);
	}

	getRowKey(record: IStore) {
		return record.uuid || "";
	}

	handlePaging() {
		this.props.getStores();
	}

	handleSelectChange(selectedRowKeys: string[]) {
		this.props.selectStores(selectedRowKeys);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminStoresTable);
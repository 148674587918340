import { Switch } from "antd";
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, CustomPermissions, ICompany } from "@connect/Interfaces";
import { Button, Header, Table } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { updateCompanyAsync } from "Data/Actions/Company";
import { fetchPermissionSettings, updatePermissionSettings } from "Data/Actions/PermissionsAsync";
import { getActiveCompany } from "Data/Selectors/Company";
import { getCustomPermissions } from "Data/Selectors/Permissions";

const { primaryGreen, white, scheduleBuilder: { yellow } } = Colors;

const mapDispatchToProps = (dispatch) => ({
	fetchPermissions: () => dispatch(fetchPermissionSettings()),
	setHasCustomPermissions: (company: ICompany) => dispatch(updateCompanyAsync(company)),
	updatePermissions: (permissions: CustomPermissions) => dispatch(updatePermissionSettings(permissions))
});

const mapStateToProps = (state) => {
	const company = getActiveCompany(state);

	return {
		company,
		hasCustomPermissions: getActiveCompany(state).customPermissions,
		permissions: getCustomPermissions(state)
	};
};

interface PermissionTableRow {
	integrator: boolean;
	marketer: boolean;
	owner: boolean;
	permission: string;
	user: boolean;
}

interface UserPermissionsProps {
	company: ICompany;
	fetchPermissions: () => void;
	hasCustomPermissions: boolean;
	permissions: CustomPermissions;
	setHasCustomPermissions: (company: ICompany) => Promise<void>;
	updatePermissions: (permissions: CustomPermissions) => void;
}

interface UserPermissionsState {
	permissions: CustomPermissions;
}

export class UserPermissionsPage extends React.Component<UserPermissionsProps, UserPermissionsState> {
	constructor(props: UserPermissionsProps) {
		super(props);

		this.permissionMap = {
			manageAdAnalytics: "Manage Ad Analytics",
			manageCompany: "Manage Users",
			manageContent: "Manage Content",
			deployContent: "Deploy Content to Devices",
			manageDevices: "Manage Devices",
			manageHealthReports: "Manage Health Reports",
			managePermissions: "Manage Permissions"
		};

		this.state = {
			permissions: props.permissions
		};

		this.styles = {
			container: {
				padding: "0px 30px"
			},
			disabled: {
				cursor: "not-allowed",
				opacity: 0.4
			},
			innerContainer: {
				margin: "10px 0 20px"
			},
			leftButton: {
				marginRight: 10
			},
			notice: {
				background: yellow,
				padding: "5px 10px"
			}
		};

		this.getColumns = this.getColumns.bind(this);
		this.getPermissionData = this.getPermissionData.bind(this);
		this.renderButtons = this.renderButtons.bind(this);
		this.renderEditButton = this.renderEditButton.bind(this);
		this.renderPermissionToggle = this.renderPermissionToggle.bind(this);
		this.renderSaveButton = this.renderSaveButton.bind(this);
		this.savePermissions = this.savePermissions.bind(this);
		this.setCustomPermissions = this.setCustomPermissions.bind(this);
		this.setDefaultPermissions = this.setDefaultPermissions.bind(this);
		this.toggleEdit = this.toggleEdit.bind(this);
	}

	permissionMap: { [key: string]: string };
	styles: CustomCSS;

	componentDidMount() {
		this.props.fetchPermissions();
	}

	componentDidUpdate(prevProps: UserPermissionsProps) {
		const { permissions } = this.props;

		if (prevProps.permissions !== permissions) {
			this.setState(() => ({
				permissions
			}));
		}
	}

	render() {
		const { container, disabled, innerContainer, notice } = this.styles;
		const tableStyle = this.props.hasCustomPermissions ? null : disabled;

		return (
			<div style={ container }>
				<Header size={ 3 }>Custom User Permissions</Header>
				<p style={{ ...innerContainer, ...notice }}>
					<strong>Please note:</strong>
					<br />
					Custom User Permissions alter the default permissions your users will receive.
					<br />
					Please do not alter this setting unless you have been properly trained in its usage.
				</p>
				{ this.renderButtons() }
				<hr />
				<div style={ tableStyle }>
					<Table
						columns={ this.getColumns() }
						dataSource={ this.getPermissionData() }
						pagination={ false }
						rowKey="permission"
					/>
				</div>
			</div>
		)
	}

	renderButtons() {
		return (
			<div style={ this.styles.innerContainer }>
				{ this.renderEditButton() }
				{ this.renderSaveButton() }
				<Button
					disabled={ !this.props.hasCustomPermissions }
					icon="redo"
					onClick={ this.setDefaultPermissions }
				>
					Revert To Default
				</Button>
			</div>
		);
	}

	renderEditButton() {
		if (this.props.hasCustomPermissions) {
			return null;
		}

		return (
			<Button
				icon="pencil"
				style={ this.styles.leftButton }
				onClick={ this.toggleEdit }
			>
				Edit Permissions
			</Button>
		);
	}

	renderPermissionToggle(role: string) {
		return (value: boolean, record: PermissionTableRow) => {
			return (
				<Switch
					checked={ value }
					disabled={ !this.props.hasCustomPermissions }
					key={ role + record.permission }
					onChange={ this.togglePermissionChange(role, record.permission) }
				/>
			);
		};
	}

	renderSaveButton() {
		if (!this.props.hasCustomPermissions) {
			return null;
		}

		const style = {
			...this.styles.leftButton,
			color: white
		};

		return (
			<Button
				color={ primaryGreen }
				icon="save"
				style={ style }
				onClick={ this.savePermissions }
			>
				Save
			</Button>
		);
	}

	getColumns() {
		return [
			{
				dataIndex: "permission",
				key: "permission",
				width: 200
			},
			{
				title: "Integrator",
				dataIndex: "integrator",
				key: "integrator",
				render: this.renderPermissionToggle("integrator")
			},
			{
				title: "Company Owner",
				dataIndex: "owner",
				key: "owner",
				render: this.renderPermissionToggle("owner")
			},
			{
				title: "Company User",
				dataIndex: "user",
				key: "user",
				render: this.renderPermissionToggle("user")
			},
			{
				title: "Marketer",
				dataIndex: "marketer",
				key: "marketer",
				render: this.renderPermissionToggle("marketer")
			}
		];
	}

	getPermissionData(): PermissionTableRow[] {
		const { permissions } = this.state;

		if (!permissions) {
			return [];
		}

		const roles = Object.keys(permissions);
		const permissionMapEntries = Object.entries(this.permissionMap);

		return permissionMapEntries.map(([ permission, name ], index) => {
			let row = {
				permission: name
			};

			roles.forEach((role) => {
				row[role] = permissions[role][permission];
			});

			return row as PermissionTableRow;
		});
	}

	savePermissions() {
		this.props.updatePermissions(this.state.permissions);
	}

	setCustomPermissions(value: boolean) {
		const { company, fetchPermissions, setHasCustomPermissions } = this.props;
		const customCompany = Object.assign({}, company, {
			customPermissions: value
		});

		setHasCustomPermissions(customCompany).then(() => {
			fetchPermissions();
		});
	}

	setDefaultPermissions() {
		this.setCustomPermissions(false);
	}

	toggleEdit() {
		this.setCustomPermissions(true);
	}

	togglePermissionChange(role: string, permission: string) {
		return (value) => {
			const [ permissionType ] = Object.entries(this.permissionMap)
				.filter(([ key, val ]) => val === permission)
				.map(([ key ]) => key);

			this.setState((state) => update(state, {
				permissions: {
					[role]: {
						[permissionType]: { $set: value }
					}
				}
			}));
		};
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissionsPage);
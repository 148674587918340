import * as React from "react";

import { CustomCSS, DeploySteps, Filters } from "@connect/Interfaces";
import StepFooter from "Components/Deploy/StepFooter";
import StepHeader from "Components/Deploy/StepHeader";
import DeviceSelection from "Components/Global/DeviceSelection";
import { DeployStepDetails } from "Data/Objects/Deployments";

export class DevicesStep extends React.Component {
	constructor(props: {}) {
		super(props);

		this.styles = {
			container: {
				position: "relative",
				height: "100%"
			},
			content: {
				margin: "0px auto",
				height: "80vh",
				minHeight: "80vh",
				width: "90%"
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { title, subtitle } = DeployStepDetails[DeploySteps.DEVICES];
		const { container, content } = this.styles;

		return (
			<div style={ container } >
				<StepHeader
					title={ title }
					subtitle={ subtitle } />
				<div style={ content }>
					<DeviceSelection
						filterSet={ Filters.DEPLOY_DEVICES }
						valid={ true }
					/>
				</div>
				<StepFooter />
			</div>
		)
	}
}

export default DevicesStep;

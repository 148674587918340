import * as update from "immutability-helper";

import { Action, ActiveFilterArrayDispatch, ActiveFilterDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ActiveFilterState } from "Data/Objects/AppState";
import { initialActiveFilterStates } from "Data/Objects/UI";
import { createReducer, setState } from "Data/Utils";

export function setActiveFilter(state: ActiveFilterState, action: Action<ActiveFilterDispatch>) {
	const { filter, path, value } = action.args;

	if (state[path][filter] === value) {
		return state;
	}

	return update(state, {
		[path]: { $apply: (filters) => setState(filters, filter, value) }
	});
}

export function setActiveFilterArray(state: ActiveFilterState, action: Action<ActiveFilterArrayDispatch>) {
	const { filter, path, value } = action.args;

	return update(state, {
		[path]: { $apply: (filters) => setState(filters, filter, value) }
	});
}

export function resetActiveFilters(state: ActiveFilterState, action: Action<null>) {
	return new ActiveFilterState(initialActiveFilterStates);
}

const reducers = {
	[ACTION_TYPES.UI.RESET_ACTIVE_FILTERS.type]: resetActiveFilters,
	[ACTION_TYPES.UI.SET_ACTIVE_FILTERS.type]: setActiveFilter,
	[ACTION_TYPES.UI.SET_ACTIVE_FILTER_ARRAY.type]: setActiveFilterArray
};

export default createReducer(reducers, ActiveFilterState, initialActiveFilterStates);

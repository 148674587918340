import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";

export default class ScheduleBuilderOverlay extends React.Component {
	constructor(props: {}) {
		super(props);

		this.styles = {
			base: {
				position: "absolute",
				top: 0,
				left: "-5%",
				height: "100%",
				width: "100%",
				zIndex: 0,
				display: "grid",
				gridAutoRows: "4.1666666666666667%"
			},
			bottom: {
				borderBottom: "1px solid lightgray"
			},
			row: {
				width: "5%",
				borderTop: "1px solid lightgray"
			},
			labelRow: {
				width: "105%",
				borderTop: "1px solid lightgray"
			},
			label: {
				float: "left",
				fontSize: 10,
				color: "lightgray",
				position: "relative",
				top: -8,
				left: -32
			},
			lastLabel: {
				top: 17
			},
			lastRow: {
				width: "105%",
				borderBottom: "1px solid lightgray"
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { base, bottom, row, labelRow, label, lastLabel, lastRow } = this.styles;
		return (
			<div style={base}>
				<div style={labelRow}>
					<span style={label}>12am</span>
				</div>
				<div style={row} />
				<div style={row} />
				<div style={labelRow}>
					<span style={label}>3am</span>
				</div>
				<div style={row} />
				<div style={row} />
				<div style={labelRow}>
					<span style={label}>6am</span>
				</div>
				<div style={row} />
				<div style={row} />
				<div style={labelRow}>
					<span style={label}>9am</span>
				</div>
				<div style={row} />
				<div style={row} />
				<div style={labelRow}>
					<span style={label}>12pm</span>
				</div>
				<div style={row} />
				<div style={row} />
				<div style={labelRow}>
					<span style={label}>3pm</span>
				</div>
				<div style={row} />
				<div style={row} />
				<div style={labelRow}>
					<span style={label}>6pm</span>
				</div>
				<div style={row} />
				<div style={row} />
				<div style={labelRow}>
					<span style={label}>9pm</span>
				</div>
				<div style={{ ...row, ...bottom }} />
				<div style={lastRow}>
					<span style={{...label, ...lastLabel}}>12am</span>
				</div>
			</div>
		);
	}
}
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, WidgetLocationState } from "@connect/Interfaces";
import DashboardHeader from "Components/Home/DashboardHeader";
import WidgetDropTarget from "Components/Home/WidgetDropTarget";
import ActivityWidget from "Components/Home/Widgets/ActivityWidget";
import FutureDeploymentsWidget from "Components/Home/Widgets/FutureDeploymentsWidget";
import MediaUsageWidget from "Components/Home/Widgets/MediaUsageWidget";
import WelcomeWidget from "Components/Home/Widgets/WelcomeWidget";
import { removeWidgetAsync } from "Data/Actions/UserAsync";
import { ActiveObjectArrayState } from "Data/Objects/AppState";
import { WIDGET_TYPES } from "Data/Objects/Widgets";
import { getCurrentUser } from "Data/Selectors/User";
import { getRenderableWidgets, getWidgetLocations } from "Data/Selectors/Widgets";

const mapStateToProps = (state) => {
	const { name, permissions } = getCurrentUser(state);

	return {
		renderableWidgets: getRenderableWidgets(state),
		username: name,
		userPermissions: permissions,
		widgetLocations: getWidgetLocations(state)
	};
};

const mapDispatchToProps = (dispatch) => ({
	removeWidget: (widgets: any) => dispatch(removeWidgetAsync(widgets))
});

interface DashboardProps {
	onDelete: (toDelete: any) => void;
	removeWidget: (widgets: any) => void;
	renderableWidgets: ActiveObjectArrayState;
	username: string;
	userPermissions: string[];
	widgetLocations: WidgetLocationState;
}

class Dashboard extends React.Component<DashboardProps> {

	constructor(props: DashboardProps) {
		super(props);

		this._widgets = new Map();

		this.styles = {
			dashboardContainer: {
				position: "relative",
				margin: 20
			},
			fullWidgetsContainer: {
				minHeight: 80,
				marginBottom: 10,
				width: "100%"
			},
			halfWidgetsContainer: {
				display: "flex",
				flexDirection: "row",
				width: "100%"
			},
			leftWidgetsContainer: {
				flexBasis: "auto",
				flexGrow: 1,
				minHeight: 80,
				position: "relative",
				boxSizing: "border-box",
				marginRight: "1%",
				maxWidth: "49%"
			},
			rightWidgetsContainer: {
				flexBasis: "auto",
				flexGrow: 1,
				minHeight: 80,
				position: "relative",
				boxSizing: "border-box",
				marginLeft: "1%",
				maxWidth: "49%"
			}
		};

		this.getCachedWidget = this.getCachedWidget.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.renderWidgets = this.renderWidgets.bind(this);
	}

	_widgets: Map<string, JSX.Element>;
	styles: CustomCSS;

	render() {
		const { username, widgetLocations: { left, right, top } } = this.props;
		const { dashboardContainer, fullWidgetsContainer, halfWidgetsContainer, leftWidgetsContainer,
			rightWidgetsContainer } = this.styles;

		return (
			<div>
				<DashboardHeader
					username={username} />
				<div style={dashboardContainer}>
					<div style={fullWidgetsContainer}>
						<WidgetDropTarget location="top" forceVisible={!top}>
							{this.renderWidgets("top")}
						</WidgetDropTarget>
					</div>
					<div style={halfWidgetsContainer}>
						<div style={leftWidgetsContainer}>
							<WidgetDropTarget location="left" forceVisible={!left}>
								{this.renderWidgets("left")}
							</WidgetDropTarget>
						</div>
						<div style={rightWidgetsContainer}>
							<WidgetDropTarget location="right" forceVisible={!right}>
								{this.renderWidgets("right")}
							</WidgetDropTarget>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderWidgets(place: "top" | "left" | "right") {
		const widgets = this.props.renderableWidgets[place];

		if (!widgets) {
			return null;
		}

		return widgets.map(({ name }, index) => {
			return (
				<div className={ name } key={ name + widgets.length }>
					{ this.getWidget(WIDGET_TYPES[name].name, index) }
				</div>
			);
		});
	}

	getCachedWidget(key: string, widget: JSX.Element) {
		if (!this._widgets.has(key)) {
			this._widgets.set(key, widget);
		}

		return this._widgets.get(key);
	}

	getWidget(name: string, index: number) {
		switch (name) {
			case WIDGET_TYPES.MEDIA_USAGE.name:
				return this.getCachedWidget(name, (
					<MediaUsageWidget
						key={ index }
						onDelete={ () => this.handleDelete(name) } />
				));
			case WIDGET_TYPES.USER_ACTIVITY.name:
				return this.getCachedWidget(name, (
					<ActivityWidget
						key={ index }
						onDelete={ () => this.handleDelete(name) } />
				));
			case WIDGET_TYPES.FUTURE_DEPLOYMENTS.name:
				return this.getCachedWidget(name, (
					<FutureDeploymentsWidget
						key={ index }
						onDelete={ () => this.handleDelete(name) } />
				));
			case WIDGET_TYPES.WELCOME_VIDEO.name:
				return this.getCachedWidget(name, (
					<WelcomeWidget
						key={ index }
						onDelete={ () => this.handleDelete(name) } />
				));
			default: return this.getCachedWidget(name, (
				<div key={ index }>{ name } placeholder</div>
			));
		}
	}

	handleDelete(toDelete: any) {
		this.props.removeWidget(toDelete);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
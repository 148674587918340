export const Timezones = [
	{
		id: "Etc/GMT-12",
		displayName: "Dateline Standard Time",
		description: "(GMT-12:00) International Date Line West"
	},
	{
		id: "Pacific/Midway",
		displayName: "Samoa Standard Time",
		description: "(GMT-11:00) Midway Island, Samoa"
	},
	{
		id: "US/Hawaii",
		displayName: "Hawaiian Standard Time",
		description: "(GMT-10:00) Hawaii"
	},
	{
		id: "US/Alaska",
		displayName: "Alaskan Standard Time",
		description: "(GMT-09:00) Alaska"
	},
	{
		id: "US/Pacific",
		displayName: "Pacific Standard Time",
		description: "(GMT-08:00) Pacific Time (US and Canada); Tijuana"
	},
	{
		id: "US/Mountain",
		displayName: "Mountain Standard Time",
		description: "(GMT-07:00) Mountain Time (US and Canada)"
	},
	{
		id: "America/Chihuahua",
		displayName: "Mexico Standard Time 2",
		description: "(GMT-07:00) Chihuahua, La Paz, Mazatlan"
	},
	{
		id: "US/Arizona",
		displayName: "U.S. Mountain Standard Time",
		description: "(GMT-07:00) Arizona"
	},
	{
		id: "US/Central",
		displayName: "Central Standard Time",
		description: "(GMT-06:00) Central Time (US and Canada)"
	},
	{
		id: "Canada/Saskatchewan",
		displayName: "Canada Central Standard Time",
		description: "(GMT-06:00) Saskatchewan"
	},
	{
		id: "America/Mexico_City",
		displayName: "Mexico Standard Time",
		description: "(GMT-06:00) Guadalajara, Mexico City, Monterrey"
	},
	{
		id: "Mexico/General",
		displayName: "Central America Standard Time",
		description: "(GMT-06:00) Central America"
	},
	{
		id: "US/Eastern",
		displayName: "Eastern Standard Time",
		description: "(GMT-05:00) Eastern Time (US and Canada)"
	},
	{
		id: "US/East-Indiana",
		displayName: "U.S. Eastern Standard Time",
		description: "(GMT-05:00) Indiana (East)"
	},
	{
		id: "America/Bogota",
		displayName: "S.A. Pacific Standard Time",
		description: "(GMT-05:00) Bogota, Lima, Quito"
	},
	{
		id: "America/Caracas",
		displayName: "Venezuela Standard Time",
		description: "(GMT-04:30) Caracas"
	},
	{
		id: "Canada/Atlantic",
		displayName: "Atlantic Standard Time",
		description: "(GMT-04:00) Atlantic Time (Canada)"
	},
	{
		id: "America/La_Paz",
		displayName: "S.A. Western Standard Time",
		description: "(GMT-04:00) Georgetown, La Paz, San Juan"
	},
	{
		id: "America/Santiago",
		displayName: "Pacific S.A. Standard Time",
		description: "(GMT-04:00) Santiago"
	},
	{
		id: "America/Manaus",
		displayName: "Central Brazilian Standard Time",
		description: "(GMT-04:00) Manaus"
	},
	{
		id: "America/Asuncion",
		displayName: "Paraguay Standard Time",
		description: "(GMT-04:00) Asuncion"
	},
	{
		id: "Canada/Newfoundland",
		displayName: "Newfoundland and Labrador Standard Time",
		description: "(GMT-03:30) Newfoundland and Labrador"
	},
	{
		id: "America/Montevideo",
		displayName: "Montevideo Standard Time",
		description: "(GMT-03:00) Montevideo"
	},
	{
		id: "Brazil/East",
		displayName: "E. South America Standard Time",
		description: "(GMT-03:00) Brasilia"
	},
	{
		id: "America/Argentina/Buenos_Aires",
		displayName: "Argentina Standard Time",
		description: "(GMT-03:00) Buenos Aires"
	},
	{
		id: "America/Godthab",
		displayName: "Greenland Standard Time",
		description: "(GMT-03:00) Greenland"
	},
	{
		id: "America/Noronha",
		displayName: "Mid-Atlantic Standard Time",
		description: "(GMT-02:00) Mid-Atlantic"
	},
	{
		id: "Atlantic/Azores",
		displayName: "Azores Standard Time",
		description: "(GMT-01:00) Azores"
	},
	{
		id: "Atlantic/Cape_Verde",
		displayName: "Cape Verde Standard Time",
		description: "(GMT-01:00) Cape Verde Islands"
	},
	{
		id: "GMT",
		displayName: "GMT Standard Time",
		description: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London"
	},
	{
		id: "Africa/Casablanca",
		displayName: "Morocco Standard Time",
		description: "(GMT) Casablanca"
	},
	{
		id: "Europe/Belgrade",
		displayName: "Central Europe Standard Time",
		description: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
	},
	{
		id: "Europe/Sarajevo",
		displayName: "Central European Standard Time",
		description: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
	},
	{
		id: "Europe/Brussels",
		displayName: "Romance Standard Time",
		description: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"
	},
	{
		id: "Europe/Amsterdam",
		displayName: "W. Europe Standard Time",
		description: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
	},
	{
		id: "Africa/Lagos",
		displayName: "W. Central Africa Standard Time",
		description: "(GMT+01:00) West Central Africa"
	},
	{
		id: "EET",
		displayName: "E. Europe Standard Time",
		description: "(GMT+02:00) Minsk"
	},
	{
		id: "Africa/Cairo",
		displayName: "Egypt Standard Time",
		description: "(GMT+02:00) Cairo"
	},
	{
		id: "Europe/Helsinki",
		displayName: "FLE Standard Time",
		description: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius"
	},
	{
		id: "Europe/Athens",
		displayName: "GTB Standard Time",
		description: "(GMT+02:00) Athens, Bucharest, Istanbul"
	},
	{
		id: "Asia/Beirut",
		displayName: "Middle East Standard Time",
		description: "(GMT+02:00) Beirut"
	},
	{
		id: "Asia/Jerusalem",
		displayName: "Israel Standard Time",
		description: "(GMT+02:00) Jerusalem"
	},
	{
		id: "Asia/Amman",
		displayName: "Jordan Standard Time",
		description: "(GMT+02:00) Amman"
	},
	{
		id: "Africa/Harare",
		displayName: "South Africa Standard Time",
		description: "(GMT+02:00) Harare, Pretoria"
	},
	{
		id: "Libya",
		displayName: "Namibia Standard Time",
		description: "(GMT+02:00) Windhoek"
	},
	{
		id: "Europe/Moscow",
		displayName: "Russian Standard Time",
		description: "(GMT+03:00) Moscow, St. Petersburg, Volgograd"
	},
	{
		id: "Asia/Kuwait",
		displayName: "Arab Standard Time",
		description: "(GMT+03:00) Kuwait, Riyadh"
	},
	{
		id: "Africa/Nairobi",
		displayName: "E. Africa Standard Time",
		description: "(GMT+03:00) Nairobi"
	},
	{
		id: "Asia/Baghdad",
		displayName: "Arabic Standard Time",
		description: "(GMT+03:00) Baghdad"
	},
	{
		id: "Asia/Tehran",
		displayName: "Iran Standard Time",
		description: "(GMT+03:30) Tehran"
	},
	{
		id: "Indian/Mauritius",
		displayName: "Mauritius Standard Time",
		description: "(GMT+04:00) Port Louis"
	},
	{
		id: "Asia/Muscat",
		displayName: "Arabian Standard Time",
		description: "(GMT+04:00) Abu Dhabi, Muscat"
	},
	{
		id: "Asia/Tbilisi",
		displayName: "Georgia Standard Time",
		description: "(GMT+04:00) Tbilisi"
	},
	{
		id: "Asia/Baku",
		displayName: "Caucasus Standard Time",
		description: "(GMT+04:00) Baku"
	},
	{
		id: "Asia/Yerevan",
		displayName: "Armenian Standard Time",
		description: "(GMT+04:00) Yerevan"
	},
	{
		id: "Asia/Kabul",
		displayName: "Transitional Islamic State of Afghanistan Standard Time",
		description: "(GMT+04:30) Kabul"
	},
	{
		id: "Asia/Yekaterinburg",
		displayName: "Ekaterinburg Standard Time",
		description: "(GMT+05:00) Ekaterinburg"
	},
	{
		id: "Asia/Tashkent",
		displayName: "West Asia Standard Time",
		description: "(GMT+05:00) Tashkent"
	},
	{
		id: "Asia/Karachi",
		displayName: "Pakistan Standard Time",
		description: "(GMT+05:00) Islamabad, Karachi"
	},
	{
		id: "Asia/Kolkata",
		displayName: "India Standard Time",
		description: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi, Sri Lanka"
	},
	{
		id: "Asia/Kathmandu",
		displayName: "Nepal Standard Time",
		description: "(GMT+05:45) Kathmandu"
	},
	{
		id: "Asia/Dhaka",
		displayName: "Central Asia Standard Time",
		description: "(GMT+06:00) Astana, Dhaka"
	},
	{
		id: "Asia/Almaty",
		displayName: "N. Central Asia Standard Time",
		description: "(GMT+06:00) Almaty, Novosibirsk"
	},
	{
		id: "Asia/Rangoon",
		displayName: "Myanmar Standard Time",
		description: "(GMT+06:30) Yangon (Rangoon)"
	},
	{
		id: "Asia/Bangkok",
		displayName: "S.E. Asia Standard Time",
		description: "(GMT+07:00) Bangkok, Hanoi, Jakarta"
	},
	{
		id: "Asia/Krasnoyarsk",
		displayName: "North Asia Standard Time",
		description: "(GMT+07:00) Krasnoyarsk"
	},
	{
		id: "Hongkong",
		displayName: "China Standard Time",
		description: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
	},
	{
		id: "Singapore",
		displayName: "Singapore Standard Time",
		description: "(GMT+08:00) Kuala Lumpur, Singapore"
	},
	{
		id: "Asia/Taipei",
		displayName: "Taipei Standard Time",
		description: "(GMT+08:00) Taipei"
	},
	{
		id: "Australia/West",
		displayName: "W. Australia Standard Time",
		description: "(GMT+08:00) Perth"
	},
	{
		id: "Asia/Irkutsk",
		displayName: "North Asia East Standard Time",
		description: "(GMT+08:00) Irkutsk, Ulaanbaatar"
	},
	{
		id: "Asia/Seoul",
		displayName: "Korea Standard Time",
		description: "(GMT+09:00) Seoul"
	},
	{
		id: "Asia/Tokyo",
		displayName: "Tokyo Standard Time",
		description: "(GMT+09:00) Osaka, Sapporo, Tokyo"
	},
	{
		id: "Asia/Yakutsk",
		displayName: "Yakutsk Standard Time",
		description: "(GMT+09:00) Yakutsk"
	},
	{
		id: "Australia/Darwin",
		displayName: "A.U.S. Central Standard Time",
		description: "(GMT+09:30) Darwin"
	},
	{
		id: "Australia/Adelaide",
		displayName: "Cen. Australia Standard Time",
		description: "(GMT+09:30) Adelaide"
	},
	{
		id: "Australia/Canberra",
		displayName: "A.U.S. Eastern Standard Time",
		description: "(GMT+10:00) Canberra, Melbourne, Sydney"
	},
	{
		id: "Australia/Brisbane",
		displayName: "E. Australia Standard Time",
		description: "(GMT+10:00) Brisbane"
	},
	{
		id: "Australia/Tasmania",
		displayName: "Tasmania Standard Time",
		description: "(GMT+10:00) Hobart"
	},
	{
		id: "Asia/Vladivostok",
		displayName: "Vladivostok Standard Time",
		description: "(GMT+10:00) Vladivostok"
	},
	{
		id: "Pacific/Guam",
		displayName: "West Pacific Standard Time",
		description: "(GMT+10:00) Guam, Port Moresby"
	},
	{
		id: "Asia/Srednekolymsk",
		displayName: "Central Pacific Standard Time",
		description: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia"
	},
	{
		id: "Pacific/Fiji",
		displayName: "Fiji Islands Standard Time",
		description: "(GMT+12:00) Fiji, Kamchatka, Marshall Islands"
	},
	{
		id: "Pacific/Auckland",
		displayName: "New Zealand Standard Time",
		description: "(GMT+12:00) Auckland, Wellington"
	},
	{
		id: "Asia/Kamchatka",
		displayName: "Kamchatka Standard Time",
		description: "(GMT+12:00) Petropavlovsk-Kamchatsky"
	},
	{
		id: "Pacific/Tongatapu",
		displayName: "Tonga Standard Time",
		description: "(GMT+13:00) Nuku'alofa"
	}
];
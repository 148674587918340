import { InputValidator, Validator } from "@connect/Interfaces"
import { DefaultMaxLength } from "Data/Objects/Global";
import { Utils } from "@connect/Utils";

// The W3 HTML5 <input type="email"> spec defines the regular expression used below
// https://www.w3.org/TR/html5/sec-forms.html#email-state-typeemail
export function email(value: string): boolean {
	// eslint-disable-next-line
	const validEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

	return validEmail.test(value);
}

export const emailHelp = "Please input a valid email.";

export const emailValidator: InputValidator = {
	callback: email,
	message: emailHelp,
	name: "email"
}

export function maxLengthHelp(length: number) {
	return `Please keep input under ${ length.toString() } characters long.`;
}

export function maxLength(length: number, returnInputValidator: boolean = false): InputValidator | Validator {
	const callback = (value: string) => (value.length <= length);

	if (returnInputValidator) {
		return {
			callback,
			message: maxLengthHelp(length),
			name: "maxLength"
		};
	}

	return callback;
}

export function minLengthHelp(length: number) {
	return `Input must be at least ${ length.toString() } characters long.`;
}

export function minLength(length: number, returnInputValidator: boolean = false): InputValidator | Validator {
	const callback = (value: string) => (value.length >= length);

	if (returnInputValidator) {
		return {
			callback,
			message: minLengthHelp(length),
			name: "minLength"
		};
	}

	return callback;
}

export function name(value: string): boolean {
	return RegExp(/^[\p{L}\p{M}\p{N}\p{Pc}\p{Pd}\p{Zs}\.]+$/, "u").test(value);
}

export const nameHelp = "Input may contain only letters, dashes, periods and spaces.";

export const nameValidator: InputValidator = {
	callback: name,
	message: nameHelp,
	name: "name"
}

export function noLeadingNonWordChars(value: string): boolean {
	return /^(?!\W)/.test(value);
}

export function notEmpty(value: string): boolean {
	return !!(value && value.length > 0);
}

export const notEmptyHelp = "Input may not be empty.";

export const notEmptyValidator: InputValidator = {
	callback: notEmpty,
	message: notEmptyHelp,
	name: "notEmpty"
}

export function nameValidation(length: number): InputValidator[] {
	return [
		maxLength(length, true) as InputValidator,
		nameValidator,
		notEmptyValidator
	];
}

export function validateUri(value: string): boolean {
	return (
		/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value)
	);
}

export const uriValidator: InputValidator = {
	callback: validateUri,
	message: "URL formatting error",
	name: "properURI"
}

export function isMinChars(password: string, minChars: number): boolean {
	return Utils.isValidMinChars(password, minChars);
}

export function isMinLowerCase(password: string, minLowerCase: number): boolean {
	return Utils.isValidMinLowerCase(password, minLowerCase);
}

export function isMinUpperCase(password: string, minUpperCase: number): boolean {
	return Utils.isValidMinUpperCase(password, minUpperCase);
}

export function isMinNumbers(password: string, minNumbers: number): boolean {
	return Utils.isValidMinNumbers(password, minNumbers);
}

export function isMinSpecialChars(password: string, minSpecialChars: number): boolean {
	return Utils.isValidMinSpecialChars(password, minSpecialChars);
}

export function minCharsValidator(minChars: number): InputValidator {
	return {
		callback: (password: string) => isMinChars(password, minChars),
		message: `Password must be at least ${ minChars } characters(s) long`,
		name: "minChars"
	}
}

export function minLowerCaseValidator(minLowerCase: number): InputValidator {
	return {
		callback: (password: string) => isMinLowerCase(password, minLowerCase),
		message: `Password must contain at least ${ minLowerCase } lower case character(s)`,
		name: "minLowerCase"
	}
}

export function minUpperCaseValidator(minUpperCase: number): InputValidator {
	return {
		callback: (password: string) => isMinUpperCase(password, minUpperCase),
		message: `Password must contain at least ${ minUpperCase } upper case character(s)`,
		name: "minUpperCase"
	}
}

export function minNumbersValidator(minNumbers: number): InputValidator {
	return {
		callback: (password: string) => isMinNumbers(password, minNumbers),
		message: `Password must contain at least ${ minNumbers } number(s)`,
		name: "minNumbers"
	}
}

export function minSpecialCharsValidator(minSpecialChars: number): InputValidator {
	return {
		callback: (password: string) => isMinSpecialChars(password, minSpecialChars),
		message: `Password must contain at least ${ minSpecialChars } special character(s)`,
		name: "minSpecialChars"
	}
}

export const inputValidation: { [name: string]: InputValidator[] } = {
	email: [
		emailValidator,
		notEmptyValidator
	],
	name: nameValidation(DefaultMaxLength), // we use other lengths around the app but 191 is most common
	password: [
		notEmptyValidator
	],
	uri: [
		uriValidator
	]
}

export function getValidatorErrors(errors: string[], validator: InputValidator[]): string[] {
	return validator.filter((v) => errors.includes(v.name))
		.map((v) => v.message)
}

export default inputValidation;

import { Action, ActionSet, ActionSetDispatch, ActionSetsDispatch, UuidDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";

const {
	CREATE_ACTION_SET,
	DELETE_ACTION_SET,
	RESET_ACTION_SETS,
	SET_ACTION_SETS,
	UPDATE_ACTION_SET
} = ACTION_TYPES.Actions

/*
 * Simple Actions
 */

function createActionSet(actionSet: ActionSet): Action<ActionSetDispatch> {
	return new DispatchableAction(CREATE_ACTION_SET, { actionSet });
}

function deleteActionSet(uuid: string): Action<UuidDispatch> {
	return new DispatchableAction(DELETE_ACTION_SET, { uuid });
}

function resetActionSets(): Action<null> {
	return new DispatchableAction(RESET_ACTION_SETS, null);
}

function setActionSets(actionSets: ActionSet[], reset?: boolean): Action<ActionSetsDispatch> {
	return new DispatchableAction(SET_ACTION_SETS, { actionSets, reset });
}

function updateActionSet(actionSet: ActionSet): Action<ActionSetDispatch> {
	return new DispatchableAction(UPDATE_ACTION_SET, { actionSet });
}

export {
	createActionSet,
	deleteActionSet,
	resetActionSets,
	setActionSets,
	updateActionSet
}
import { combineReducers } from "redux";

import actionBuilderState from "Data/Reducers/UI/actionBuilder";
import activeFilters from "Data/Reducers/UI/activeFilters";
import activeNavigationItem from "Data/Reducers/UI/activeNavigationItem";
import activeSearch from "Data/Reducers/UI/activeSearch";
import activeSelection from "Data/Reducers/UI/activeSelection";
import activeSorts from "Data/Reducers/UI/activeSorts";
import activeTags from "Data/Reducers/UI/activeTags";
import activeUuids from "Data/Reducers/UI/activeUuids";
import activeViews from "Data/Reducers/UI/activeViews";
import adBuilderState from "Data/Reducers/UI/adBuilder";
import asyncState from "Data/Reducers/UI/asyncState";
import collapse from "Data/Reducers/UI/collapse";
import deploymentWizardState from "Data/Reducers/UI/deploymentWizardState"
import deviceHealthModalState from "Data/Reducers/UI/deviceHealthModalState";
import expandedDeviceGroups from "Data/Reducers/UI/expandedDeviceGroups";
import finishedReports from "Data/Reducers/UI/finishedReports";
import leftAccordion from "Data/Reducers/UI/leftAccordion";
import mediaPreview from "Data/Reducers/UI/mediaPreview";
import menu from "Data/Reducers/UI/menu";
import modals from "Data/Reducers/UI/modals"
import runningReports from "Data/Reducers/UI/runningReports";

export default combineReducers({
	actionBuilderState,
	activeFilters,
	activeNavigationItem,
	activeSearch,
	activeSelection,
	activeSorts,
	activeTags,
	activeUuids,
	activeViews,
	adBuilderState,
	asyncState,
	collapse,
	deploymentWizardState,
	deviceHealthModalState,
	expandedDeviceGroups,
	finishedReports,
	leftAccordion,
	mediaPreview,
	menu,
	modals,
	runningReports
});
import { Popover } from "antd";
import Radium from "radium";
import * as React from "react";
import {
	DragSource,
	DragSourceCollector,
	DragSourceSpec,
	DropTarget,
	DropTargetCollector,
	DropTargetMonitor,
	DropTargetSpec
} from "react-dnd";

import { CustomCSS, IAd, PlaylistAd } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Button, Icon, Link, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import * as AdRow from "Components/Global/DraggableAdRow";
import { DnDIdentifier, GridViewCardType } from "Components/Global/GridViewCard";

const { black, lightestGray, lightBlue, offWhite, primaryBlue, gray, dropBackground } = Colors;

export const IDENTIFIER = "playlistItemCard";

export interface DraggablePlaylistItemCollectedProps {
	connectDragSource?: Function;
	connectDropTarget?: Function;
	currentlyDragging?: boolean;
	isOver?: boolean;
	canDrop?: boolean;
	hoverItem?: DraggablePlaylistDropTarget;
}

export interface DraggablePlaylistItemDragObject {
	ad: IAd | PlaylistAd | null;
	index: number;
	isReorder?: boolean;
}

export interface DraggablePlaylistItemProps {
	connectDragSource?: Function;
	connectDropTarget?: Function;
	currentlyDragging?: boolean;
	isOver?: boolean;
	canDrop?: boolean;
	hoverItem?: DraggablePlaylistDropTarget;
	ad: IAd | PlaylistAd | null;
	copyAd: (ad: IAd | PlaylistAd | null, index: number) => void;
	deleteAd: (index: number) => void;
	moveAd: (ad: IAd | PlaylistAd | null, beforeIndex: number, index: number) => void;
	index: number;
}

@Radium
class DraggablePlaylistItem extends React.Component<DraggablePlaylistItemProps> {
	constructor(props: DraggablePlaylistItemProps) {
		super(props);

		this.styles = {
			container: {
				padding: "5px 0"
			},
			card: {
				display: "grid",
				gridTemplateColumns: "40% 40% 20%",
				justifyContent: "space-between",
				alignItems: "center",
				height: 65,
				padding: "0 20px",
				border: "none",
				borderRadius: 3,
				color: black,
				background: offWhite,
				":hover": {
					cursor: "move"
				}
			},
			dropPreviewCard: {
				marginBottom: "10px",
				display: "none",
				border: "1px dashed " + primaryBlue,
				background: lightBlue,
				color: gray
			},
			durationContainer: {
				flex: "1 1 40%",
				whiteSpace: "nowrap"
			},
			duration: {
				fontSize: "16px",
				color: gray,
				marginLeft: 10
			},
			nameContainer: {
				display: "flex",
				textAlign: "left",
				flex: "1 1 20%"
			},
			name: {
				fontSize: "20px",
				marginLeft: 20,
				color: black
			},
			buttonContainer: {
				display: "flex",
				justifyContent: "flex-end",
				flex: "1 1 40%"
			},
			button: {
				background: lightestGray,
				color: gray,
				borderRadius: 3,
				marginLeft: 5,
				width: 30,
				height: 30,
				padding: 0
			},
			placeholder: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: 65,
				padding: "0 20px",
				border: "none",
				borderRadius: 3,
				color: gray,
				fontSize: 20,
				whiteSpace: "nowrap"
			},
			thumbnailContainer: {
				paddingLeft: 10,
				paddingRight: 10
			}
		}

		this.renderThumbnail = this.renderThumbnail.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { connectDragSource, connectDropTarget, currentlyDragging } = this.props;
		const { container } = this.styles;

		if (currentlyDragging) {
			return null;
		}

		const content = (
			<div style={ container }>
				{this.renderDropPreviewCard()}
				{this.renderDropNextTarget()}
				{this.renderAdCard()}
			</div>
		);
		const targetContent = connectDropTarget ? connectDropTarget(content) : content;
		const sourceContent = connectDragSource ? connectDragSource(targetContent) : targetContent;

		return sourceContent;
	}

	// this is the main ad card for each ad in the playlist
	renderAdCard() {
		if (!this.props.ad) {
			return null;
		}

		const { ad } = this.props;
		const { card } = this.styles;
		card.color = black;
		card.border = "none";
		card.background = offWhite;

		return (
			<div style={card}>
				{this.renderDuration(ad)}
				{this.renderName(ad)}
				{this.renderButtons(ad)}
			</div>
		);
	}

	// placeholder ad to prompt user to drop next ad
	renderDropNextTarget() {
		if (this.props.ad) {
			return null
		};
		const { placeholder } = this.styles;
		const { isOver } = this.props;
		placeholder.background = "none";
		placeholder.color = lightBlue;
		placeholder.border = "1px dashed " + primaryBlue;
		if (isOver) {
			placeholder.background = dropBackground
		}

		return (
			<div style={this.styles.placeholder}>
				{(this.props.index === 0) ? "Drag & Drop Your First Ad Here." : "Drag & Drop Next Ad Here."}
			</div>
		);
	}

	// this is the dropPreviewCard - only used for displaying where an ad may be dropped
	renderDropPreviewCard() {
		const { canDrop, hoverItem } = this.props;
		const hoverAd = hoverItem ? hoverItem.ad || hoverItem.data : null;
		const { ad } = this.props;
		const showDropPreview = ad && this.props.canDrop && this.props.isOver;
		const { card, dropPreviewCard, buttonContainer } = this.styles;

		if (!canDrop || !hoverItem || !hoverAd) {
			return null;
		};

		if (showDropPreview) {
			dropPreviewCard.display = "grid";
			card.color = lightBlue;
			card.border = "1px dashed " + primaryBlue;
			card.background = dropBackground;
		} else {
			dropPreviewCard.display = "none";
		}

		return (
			<div key={this.props.index} style={{ ...card, ...dropPreviewCard }}>
				{this.renderDuration(hoverAd)}
				{this.renderName(hoverAd)}
				<div style={buttonContainer}/>
			</div>
		);
	}

	renderDuration(ad: IAd | PlaylistAd) {
		if (!ad) {
			return null;
		};
		const { durationContainer, duration } = this.styles;
		return (
			<div style={durationContainer}>
				<div style={{
					display: "flex",
					alignItems: "center"
				}}>
					<Icon name="clock" iconWeight="regular" size="smaller" style={{color: gray}} />
					<span style={duration}>
						{Utils.getHumanReadableDuration(ad.duration as number)}
					</span>
				</div>
			</div>
		);
	}

	renderName(ad: IAd | PlaylistAd) {
		if (!ad) {
			return null;
		};
		const { name, uuid } = ad;
		return (
			<div style={this.styles.nameContainer}>
				<div>
					<Popover placement="bottom"
						content={ this.renderThumbnail() }>
						<div style={{display: "inline"}}>
							<Icon name="eye" size="smaller" style={{color: gray}} />
						</div>
					</Popover>
					<Link to={ `/ads/${ uuid }` }>
						<span style={this.styles.name}>
							<Truncate length={ 24 }>{ name }</Truncate>
						</span>
					</Link>
				</div>
			</div>
		);
	}

	renderThumbnail() {
		const { ad } = this.props;
		const { thumbnailContainer } = this.styles;

		if (!ad || !ad.thumbnail) {
			return "No ad preview available";
		}

		return (
			<div style={ thumbnailContainer }>
				<img width={ 100 } src={ ad.thumbnail } />
			</div>
		);
	}

	renderButtons(ad: IAd | PlaylistAd) {
		if (!ad) {
			return null
		};

		const { copyAd, deleteAd, index } = this.props;
		const { buttonContainer, button } = this.styles;

		return (
			<div style={buttonContainer}>
				<div>
					<Button
						key="copy"
						icon="copy"
						iconWeight="regular"
						style={button}
						onClick={() => copyAd(ad, index)} />
					<Button
						key="trash"
						className="warning-button"
						icon="trash-alt"
						iconWeight="regular"
						style={button}
						onClick={() => deleteAd(index)} />
				</div>
			</div>
		);
	}
}

const dragSource: DragSourceSpec<DraggablePlaylistItemProps, DraggablePlaylistItemDragObject> = {
	canDrag(props: DraggablePlaylistItemProps) {
		return !!props.ad; // shouldn't be able to drag the placeholder one
	},
	beginDrag(props: DraggablePlaylistItemProps) {
		return {
			ad: props.ad,
			index: props.index,
			// props passed onto the drop target from sources
			// which are also DraggablePlaylistItems... will have these props
			// Ad cards will not... so we can tell if this was just a card
			// being reordered or if it was a new ad by the presence of this boolean
			isReorder: true
		}
	}
}

const dragCollector: DragSourceCollector<
DraggablePlaylistItemCollectedProps
> = (dragConnect, monitor) => {
	return {
		connectDragSource: dragConnect.dragSource(),
		currentlyDragging: monitor.isDragging()
	}
}

interface DraggablePlaylistDropTarget {
	ad?: IAd | PlaylistAd,
	data?: IAd | PlaylistAd,
	index?: number,
	isReorder?: boolean,
	type?: GridViewCardType
}

const dropTarget: DropTargetSpec<DraggablePlaylistItemProps> = {
	drop(props: DraggablePlaylistItemProps, monitor: DropTargetMonitor, component: DraggablePlaylistItem) {
		const { ad, data, index, isReorder } = (monitor.getItem() as DraggablePlaylistDropTarget);
		const { copyAd, moveAd } = props;
		const adToMove = ad || data || null;
		const indexToUse = typeof index !== "number" ? -1 : index;
		let droppedBeforeIndex = component.props.index;

		if (isReorder) {
			if (indexToUse < droppedBeforeIndex) {
				droppedBeforeIndex--;
			}

			moveAd(adToMove, droppedBeforeIndex, indexToUse);
		} else {
			copyAd(adToMove, droppedBeforeIndex);
		}
	}
}

const dropCollector: DropTargetCollector<
DraggablePlaylistItemCollectedProps
> = (dragConnect, monitor) => {
	return {
		connectDropTarget: dragConnect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
		hoverItem: monitor.getItem()
	}
}

const dropTargetIdentifier = [ DnDIdentifier, AdRow.IDENTIFIER, IDENTIFIER ];
const dropTargetObject = DropTarget(dropTargetIdentifier, dropTarget, dropCollector)(DraggablePlaylistItem)

export default DragSource(IDENTIFIER, dragSource, dragCollector)(dropTargetObject);
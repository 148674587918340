import Radium from "radium";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { ActiveUuidRoute, CustomCSS, HealthReport, ICompany, Filters, Sorts, SortTypes } from "@connect/Interfaces";
import { Icon, Link, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { getAllReportsAsync } from "Data/Actions/HealthReportAsync";
import { replaceWithoutRender } from "Data/Actions/Navigation";
import { getFilteredSortedHealthReports } from "Data/Selectors/HealthReports";
import { getActiveUuid, getFinishedReports, getActiveFilters, getActiveSorts } from "Data/Selectors/UI";

const { darkerGray, filters, primaryGreen, white } = Colors;

const mapStateToProps = (state, ownProps) => {
	const { Company: CompanyState } = state;
	const { filter } = getActiveFilters(state, Filters.HEALTH_REPORTS);
	const sort = getActiveSorts(state, Sorts.HEALTH_REPORTS);
	const finishedReports = getFinishedReports(state);
	const reports = getFilteredSortedHealthReports(state, {
		sort: sort as SortTypes,
		filter: filter as string
	});
	const activeReportId = getActiveUuid(state, "health");

	return {
		activeCompany: CompanyState.activeCompany,
		activeReportId,
		incomingReportId: ownProps.match.params.activeUuid,
		reports,
		finishedReports
	};
};

const mapDispatchToProps = (dispatch) => ({
	getAllReports: (full?: boolean) =>
		dispatch(getAllReportsAsync(full)),
	setActiveReport: (uuid: string) =>
		dispatch(replaceWithoutRender(`/health/${uuid}`))
});

interface SavedReportsProps extends RouteComponentProps<ActiveUuidRoute> {
	activeCompany: ICompany;
	activeReportId: string;
	finishedReports: string[];
	getAllReports: (full?: boolean) => void;
	incomingReportId: string;
	reports: HealthReport[];
	setActiveReport: (uuid: string) => void;
}

interface SavedReportsState {
	initialFetch: boolean;
}

@Radium
export class SavedReports extends React.Component<SavedReportsProps, SavedReportsState> {
	constructor(props: SavedReportsProps) {
		super(props);

		this.state = {
			initialFetch: false
		};

		this.styles = {
			container: {
				border: 0,
				borderRadius: 0
			},
			fullWidth: {
				width: "100%"
			},
			globeIcon: {
				float: "right"
			},
			link: {
				color: white,
				textDecoration: "none"
			},
			reportItem: {
				background: darkerGray,
				color: white,
				cursor: "pointer",
				display: "flex",
				flexGrow: 1,
				justifyContent: "space-between",
				padding: "6px 14px 6px 10px",
				width: "100%",
				":hover": {
					background: filters.hover
				}
			},
			ready: {
				color: primaryGreen,
				marginLeft: 4
			}
		};

		this.renderSavedReport = this.renderSavedReport.bind(this);
		this.renderReportName = this.renderReportName.bind(this);
		this.updateComponentData = this.updateComponentData.bind(this);
	}

	styles: CustomCSS;

	componentWillMount() {
		this.updateComponentData();
	}

	componentWillReceiveProps(nextProps: SavedReportsProps) {
		this.updateComponentData(nextProps);
	}

	render() {
		const { reports } = this.props;

		return (
			<div style={this.styles.container}>
				{
					reports.length
						? reports.map(this.renderSavedReport)
						: this.renderEmpty()
				}
			</div>
		);
	}

	renderEmpty() {
		return null;
	}

	renderSavedReport(item: HealthReport, index: number, items: HealthReport[]) {
		const { global, name, uuid } = item;
		const active = uuid === this.props.activeReportId;
		const style = {
			...this.styles.reportItem,
			background: active ? filters.hoverAlt : "none"
		};

		return (
			<div key={ index } style={ style }>
				{ this.renderReportName(!!global, name, uuid) }
				{ this.renderReportReady(item) }
			</div>
		);
	}

	renderReportReady(item: HealthReport) {
		if (!this.props.finishedReports.includes(item.uuid)) {
			return null;
		}

		return (
			<Icon
				name="check-circle"
				size="smallest"
				style={ this.styles.ready }
			/>
		);
	}

	renderReportName(global: boolean, name: string, uuid: string) {
		const { fullWidth, globeIcon, link } = this.styles;
		const style = { ...fullWidth, ...link };
		let inner = (
			<Truncate length={ 26 }>{ name }</Truncate>
		);

		if (!global) {
			return (
				<Link to={ `/health/${ uuid }` } style={ style }>
					{ inner }
				</Link>
			);
		}

		return (
			<Link to={`/health/${uuid}`} style={style}>
				{inner}
				<Icon name="globe" iconWeight="regular" style={globeIcon} />
			</Link>
		);
	}

	updateComponentData(props?: SavedReportsProps) {
		const { activeCompany, activeReportId, getAllReports, incomingReportId, setActiveReport } = props || this.props;
		const { initialFetch } = this.state;

		if (this.props.activeCompany.uuid !== activeCompany.uuid || !initialFetch) {
			getAllReports();

			if (!initialFetch) {
				this.setState(() => ({
					initialFetch: true
				}));
			}
		}

		if (incomingReportId && activeReportId !== incomingReportId) {
			setActiveReport(incomingReportId);
		}
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedReports));

import Radium from "radium";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, ITickerComponent } from "@connect/Interfaces";
import { AdColors } from "Components/Ads/Constants";
import {
	AdComponentCard,
	IAdComponentCardProps,
	IAdComponentCardState,
	SuperComponentCard,
	SuperComponentClass
} from "Components/Ads/SuperComponentCard";

interface TickerComponentCardProps extends IAdComponentCardProps<ITickerComponent> {}

interface TickerComponentCardState extends IAdComponentCardState {}

const marqueeLeftKeyframes = Radium.keyframes({
	"0%": {
		transform: "translateX(100%)"
	},
	"100%": {
		transform: "translateX(-100%)"
	}
}, "marquee-left");
const marqueeRightKeyframes = Radium.keyframes({
	"0%": {
		transform: "translateX(-100%)"
	},
	"100%": {
		transform: "translateX(100%)"
	}
}, "marquee-right");

@Radium
export class TickerComponentCard extends AdComponentCard<TickerComponentCardProps, TickerComponentCardState> {
	constructor(props: TickerComponentCardProps) {
		super(props);

		this.icon = "text-width";
		this.name = "Ticker Tape";

		this.state = this.getInitialState(props);

		this.styles = Object.assign({}, this.styles, {
			ticker: {
				fontSize: "100%",
				fontStyle: "initial",
				fontWeight: "normal",
				marginBottom: "0.125em",
				whiteSpace: "pre"
			}
		});

		this.renderContainer = this.renderContainer.bind(this);
	}

	icon: string;
	name: string;
	styles: {
		baseButton: CustomCSS;
		bottomResizeHandle: CustomCSS;
		card: CustomCSS;
		container: CustomCSS;
		deleteButton: CustomCSS;
		eraseButton: CustomCSS;
		heightAdjuster: CustomCSS;
		livePreview: CustomCSS;
		livePreviewName: CustomCSS;
		promptIcon: CustomCSS;
		promptText: CustomCSS;
		resolutionOverlay: CustomCSS;
		snapButton: CustomCSS;
		ticker: CustomCSS;
		upload: CustomCSS;
		video: CustomCSS;
	};

	render() {
		if (!this.shouldShowLivePreview()) {
			return this.renderContainer(<div />);
		}

		const { component } = this.props;
		const { messages, speed, scroll } = component;
		const previewMessage = messages && messages.length ? messages[0] : "";
		const style = this.getTickerStyle();

		return this.renderContainer(
			<div
				key={ `${ speed }_${ scroll }_${ previewMessage }` }
				style={ style }
			>
				{previewMessage}
			</div>
		);
	}

	getCardStyles(): CustomCSS {
		const { component, livePreview } = this.props;

		return Object.assign({}, super.getCardStyles(), {
			backgroundColor: livePreview ? component.background : AdColors.ticker.background
		}) as CustomCSS;
	}

	getTickerStyle(): CustomCSS {
		const { bold, foreground, italic, scroll, speed } = this.props.component;
		const fixedScroll = scroll === "fixed";
		const fixed = fixedScroll ? "center" : "";
		const speeds = [ "", "30s", "25s", "20s", "15s", "10s" ];
		const animationTime = !fixedScroll ? speeds[speed] : "";
		let animationName = Object("");

		if (scroll === "left") {
			animationName = marqueeLeftKeyframes;
		} else if (scroll === "right") {
			animationName =  marqueeRightKeyframes
		}

		return Object.assign({}, this.styles.ticker, {
			alignSelf: fixed,
			animationDuration: !fixedScroll ? animationTime : "",
			animationName: !fixedScroll ? animationName : "",
			animationTimingFunction: !fixedScroll ? "linear" : "",
			animationIterationCount: !fixedScroll ? "infinite" : "",
			color: foreground,
			fontSize: this.state.pxHeight * 0.7,
			fontStyle: italic ? "italic" : "initial",
			fontWeight: bold ? "bold" : "normal",
			justifyContent: fixed
		});
	}

	shouldShowLivePreview() {
		const { livePreview, component } = this.props;

		return !!(livePreview && component.messages && component.messages.length && component.messages[0]);
	}
}

const {
	Draggable,
	mapStateToProps,
	mapDispatchToProps
} = SuperComponentCard;

export const DndTickerComponentCard: SuperComponentClass<TickerComponentCardProps> = Draggable(
	connect(mapStateToProps, mapDispatchToProps)(TickerComponentCard)
);

export default DndTickerComponentCard;

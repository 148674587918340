import { Section } from "@connect/Interfaces";

export const termsVersion = "1.55.0";

export default
[
	{
		title: "Terms & Conditions",
		data: [
			{
				subtitle: "Welcome to Clinton Connect",
				data: [
					{
						type: "paragraph",
						data:
							`These terms and conditions outline the rules and regulations for the use of Clinton Connect,
							owned and operated by Clinton Electronics. Clinton Electronics is located at: 6701 Clinton Rd,
							Loves Park, IL - 61111, United States`
					},
					{
						type: "paragraph",
						data:
							`By accessing this website we assume you accept these terms and conditions in full. Do not
							continue to use Clinton Connect if you do not accept all of the terms and conditions stated
							on this page. The following terminology applies to these Terms and Conditions, Privacy
							Statement and Disclaimer Notice and any or all Agreements: “Client”, “You” and “Your” refers
							to you, the person accessing this website and accepting the Company’s terms and conditions.
							“The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”,
							or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All
							terms refer to the offer, acceptance and consideration of payment necessary to undertake
							the process of our assistance to the Client in the most appropriate manner, whether by formal
							meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s
							needs in respect of provision of the Company’s stated services/products, in accordance with and
							subject to, prevailing law of United States. Any use of the above terminology or other words in
							the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and
							therefore as referring to same.`
					}
				]
			},
			{
				subtitle: "Cookies",
				data: [
					{
						type: "paragraph",
						data:
							`We employ the use of cookies. By using Clinton Electronics's website you consent to the use of
							cookies in accordance with Clinton Electronics’s privacy policy. Most of the modern day interactive
							web sites use cookies to enable us to retrieve user details for each visit. Cookies are used in
							some areas of our site to enable the functionality of this area and ease of use for those people
							visiting. Some of our affiliate / advertising partners may also use cookies.`
					}
				]
			},
			{
				subtitle: "License",
				data: [
					{
						type: "paragraph",
						data:
							`Unless otherwise stated, Clinton Electronics and/or it’s licensors own the intellectual property
							rights for all material on Clinton Electronics. All intellectual property rights are reserved.
							You may view and/or print pages from https://app.clintonconnect.com for your own personal use
							subject to restrictions set in these terms and conditions.`
					}
				]
			},
			{
				subtitle: "You Must Not:",
				data: [
					{
						type: "bullets",
						data: [
							"Republish material from https://app.clintonconnect.com",
							"Sell, rent or sub-license material from https://app.clintonconnect.com",
							"Reproduce, duplicate or copy material from https://app.clintonconnect.com",
							"Redistribute content from Clinton Electronics (unless content is specifically made for redistribution)"
						]
					}
				]
			},
			{
				subtitle: "Hyperlinking to our Content",
				data: [
					{
						type: "paragraph",
						data: "The following organizations may link to our Web site without prior written approval:"
					},
					{
						type: "bullets",
						data: [
							"Search Engines",
							"News Organizations",
							`Online directory distributors when they list us in the directory may link to our Web site in the
							same manner as they hyperlink to the Web sites of other listed businesses`,
							`Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping
							malls, and charity fundraising groups which may not hyperlink to our Web site`
						]
					},
					{
						type: "paragraph",
						data:
							`These organizations may link to our home page, to publications or to other Web site information
							so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship,
							endorsement or approval of the linking party and its products or services; and (c) fits within the
							context of the linking party's site.`
					},
					{
						type: "paragraph",
						data:
							`We may consider and approve in our sole discretion other link requests from the following types
							of organizations:`
					},
					{
						type: "bullets",
						data: [
							`Commonly-known consumer and/or business information sources such as Chambers of Commerce, American
							Automobile Association, AARP and Consumers Union; dot.com community sites`,
							`Associations or other groups representing charities, including charity giving sites, online
							directory distributors`,
							"Internet portals",
							"Accounting, law and consulting firms whose primary clients are businesses",
							"Educational institutions and trade associations"
						]
					},
					{
						type: "paragraph",
						data:
							`We will approve link requests from these organizations if we determine that: (a) the link would not reflect
							unfavorably on us or our accredited businesses (for example, trade associations or other organizations
							representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed
							to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from the
							visibility associated with the hyperlink outweighs the absence of ; and (d) where the link is in the context
							of general resource information or is otherwise consistent with editorial content in a newsletter or similar
							product furthering the mission of the organization.`
					},
					{
						type: "paragraph",
						data:
							`These organizations may link to our home page, to publications or to other Web site information so long as
							the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval
							of the linking party and it products or services; and (c) fits within the context of the linking party's site.`
					},
					{
						type: "paragraph",
						data:
							`If you are among the organizations listed in paragraph 2 above and are interested in linking to our website,
							you must notify us by sending an e-mail to support@clintonconnect.com. Please include your name, your
							organization name, contact information (such as a phone number and/or e-mail address) as well as the URL
							of your site, a list of any URLs from which you intend to link to our Web site, and a list of the URL(s)
							on our site to which you would like to link. Allow 2-3 weeks for a response.`
					},
					{
						type: "paragraph",
						data: "Approved organizations may hyperlink to our Web site as follows:"
					},
					{
						type: "bullets",
						data: [
							"By use of our corporate name",
							"By use of the uniform resource locator (Web address) being linked to",
							`By use of any other description of our Web site or material being linked to that makes sense within
							the context and format of content on the linking party's site`
						]
					},
					{
						type: "paragraph",
						data:
							`No use of Clinton Connect's logo or other artwork will be allowed for linking absent a trademark
							license agreement.`
					}
				]
			},
			{
				subtitle: "Iframes",
				data: [
					{
						type: "paragraph",
						data:
							`Without prior approval and express written permission, you may not create frames around our Web pages or
							use other techniques that alter in any way the visual presentation or appearance of our Web site.`
					}
				]
			},
			{
				subtitle: "Facial Detection Technology (FDT)",
				data: [
					{
						type: "paragraph",
						data:
							`Clinton Connect PVMs have the ability to detect people and faces. By default, this feature is
							disabled. Our detection algorithm is designed to prevent the platform from being used for any
							type of profiling based on race, age, gender, or national origin. Clinton Electronics does not
							store any facial or biometric data on the device or in the cloud when this feature is used.`
					},
					{
						type: "paragraph",
						data:
							`Without limitation, by accepting these terms and using the product, you agree not to engage in
							any FDT activity that violates any federal, state, or local laws or regulations.`
					}
				]
			},
			{
				subtitle: "Reservation of Rights",
				data: [
					{
						type: "paragraph",
						data:
							`We reserve the right at any time and in its sole discretion to request that you remove all links or any
							particular link to our Web site. You agree to immediately remove all links to our Web site upon such
							request. We also reserve the right to amend these terms and conditions and its linking policy at any time.
							By continuing to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.`
					}
				]
			},
			{
				subtitle: "Removal of links from our website",
				data: [
					{
						type: "paragraph",
						data:
							`If you find any link on our Web site or any linked web site objectionable for any reason, you may
							contact us about this. We will consider requests to remove links but will have no obligation to do
							so or to respond directly to you. Whilst we endeavour to ensure that the information on this website
							is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the
							website remains available or that the material on the website is kept up to date.`
					}
				]
			},
			{
				subtitle: "Content Liability",
				data: [
					{
						type: "paragraph",
						data:
							`We shall have no responsibility or liability for any content appearing on your Web site. You
							agree to indemnify and defend us against all claims arising out of or based upon your Website.
							No link(s) may appear on any page on your Web site or within any context containing content or
							materials that may be interpreted as libelous, obscene or criminal, or which infringes,
							otherwise violates, or advocates the infringement or other violation of, any third party rights.`
					}
				]
			},
			{
				subtitle: "Disclaimer",
				data: [
					{
						type: "paragraph",
						data:
							`To the maximum extent permitted by applicable law, we exclude all representations, warranties
							and conditions relating to our website and the use of this website (including, without limitation,
								any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or
								the use of reasonable care and skill). Nothing in this disclaimer will:`
					},
					{
						type: "bullets",
						data: [
							"Limit or exclude our or your liability for death or personal injury resulting from negligence",
							"Limit or exclude our or your liability for fraud or fraudulent misrepresentation",
							"Limit any of our or your liabilities in any way that is not permitted under applicable law",
							"Exclude any of our or your liabilities that may not be excluded under applicable law"
						]
					},
					{
						type: "paragraph",
						data:
							`The limitations and exclusions of liability set out in this Section and elsewhere in this
							disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising
							under the disclaimer or in relation to the subject matter of this disclaimer including liabilities
							arising in contract, in tort (including negligence) and for breach of statutory duty. To the
							extent that the website and the information and services on the website are provided free of
							charge, we will not be liable for any loss or damage of any nature.`
					}
				]
			}
		]
	}
] as Section[];
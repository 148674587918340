import { Checkbox } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { toggleFeature } from "@connect/Features";
import { CustomCSS, IUser, Newsletter } from "@connect/Interfaces";
import { Header } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { updateEmailPreferencesAsync } from "Data/Actions/UserAsync";
import {
	getAdAnalyticsNewsletterSetting,
	getConnectUpdateNewsletterSetting,
	getCurrentUser,
	getDeploymentReportsNewsletterSetting,
	getHealthReportsNewsletterSetting
} from "Data/Selectors/User";

const { ANALYTICS, DEPLOY, GROUP, HEALTH } = Newsletter;

const mapDispatchToProps = (dispatch, ownProps: EmailPreferencesPageProps) => ({
	toggleAnalyticsUpdates: () => dispatch(updateEmailPreferencesAsync(ANALYTICS)),
	toggleConnectUpdates: () => dispatch(updateEmailPreferencesAsync(GROUP)),
	toggleDeploymentUpdates: () => dispatch(updateEmailPreferencesAsync(DEPLOY)),
	toggleHealthUpdates: () => dispatch(updateEmailPreferencesAsync(HEALTH)),
	updateUserPreferences: () => null
});

const mapStateToProps = (state) => ({
	user: getCurrentUser(state),
	adAnalyticsNotifications: getAdAnalyticsNewsletterSetting(state),
	connectUpdateNotifications: getConnectUpdateNewsletterSetting(state),
	deploymentReportNotifications: getDeploymentReportsNewsletterSetting(state),
	healthReportNotifications: getHealthReportsNewsletterSetting(state)
});

interface EmailPreferenceOption {
	checked: boolean;
	label: string;
	onClick: () => void;
	text: string;
	disabled?: boolean;
}

interface EmailPreferencesPageProps {
	user: IUser;
	adAnalyticsNotifications: boolean;
	connectUpdateNotifications: boolean;
	deploymentReportNotifications: boolean;
	healthReportNotifications: boolean;
	toggleAnalyticsUpdates: () => void;
	toggleConnectUpdates: () => void;
	toggleDeploymentUpdates: () => void;
	toggleHealthUpdates: () => void;
	updateUserPreferences: () => void;
}

class EmailPreferencesPage extends React.Component<EmailPreferencesPageProps> {
	constructor(props: EmailPreferencesPageProps) {
		super(props);

		this.styles = {
			container: {
				padding: "0 30px"
			},
			description: {
				color: Colors.gray,
				display: "block"
			},
			header: {
				display: "inline-block",
				width: "100%"
			},
			optionWrapper: {
				display: "flex",
				marginBottom: 8
			},
			option: {
				marginLeft: 12
			},
			spaced: {
				margin: 16
			},
			title: {
				fontWeight: "bold"
			}
		};

		this.getOptions = this.getOptions.bind(this);
		this.renderOption = this.renderOption.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { container, header, spaced } = this.styles;
		const optionsToRender = this.getOptions().map(this.renderOption);

		return (
			<div style={ container }>
				<Header size={ 3 } style={ header }>Notifications</Header>
				<div style={ spaced }>
					I'd like to receive the following emails:
					<div style={ spaced }>
						{ optionsToRender }
					</div>
				</div>
			</div>
		);
	}

	renderOption(preference: EmailPreferenceOption) {
		const { label, text, checked, onClick, disabled } = preference;
		const { description, option, optionWrapper, title } = this.styles;

		return (
			<div style={ optionWrapper } key={ label }>
				<Checkbox disabled={ disabled } checked={ checked } onChange={ onClick } />
				<div style={ option }>
					<span style={ title }>{ label }</span>
					<span style={ description }>{ text }</span>
				</div>
			</div>
		);
	}

	getOptions() {
		const {
			adAnalyticsNotifications,
			connectUpdateNotifications,
			deploymentReportNotifications,
			healthReportNotifications,
			toggleAnalyticsUpdates,
			toggleConnectUpdates,
			toggleDeploymentUpdates,
			toggleHealthUpdates
		} = this.props;

		let options = [
			{
				label: "Deployment Reports",
				text: `Stats on total devices, success rate, and missed
					deployments. Sent 24 hours after deployment.`,
				checked: deploymentReportNotifications,
				onClick: toggleDeploymentUpdates
			},
			{
				label: "Health Reports",
				text: `A customized daily or weekly device status
					report, configured on the Health page.`,
				checked: healthReportNotifications,
				onClick: toggleHealthUpdates
			},
			{
				label: "Clinton Connect Updates",
				text: "Learn about new features, improvements, and tutorials.",
				checked: connectUpdateNotifications,
				onClick: toggleConnectUpdates
			}
		];

		// TODO: Before releasing analytics email reports to prod, remove this toggle
		if (toggleFeature("adAnalyticsEmailReports", true, false)) {
			options.push({
				label: "Ad Analytics Reports",
				text: "A customized daily or weekly status report on Ad Analytics.",
				checked: adAnalyticsNotifications,
				onClick: toggleAnalyticsUpdates
			});
		}

		return options;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailPreferencesPage);

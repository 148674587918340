import * as React from "react";
import * as update from "immutability-helper";
import { connect } from "react-redux";

import { CustomCSS, ICompany } from "@connect/Interfaces";
import { Button } from "Components/Global/Common";
import { getActiveCompany } from "Data/Selectors/Company";
import PasswordPrompt from "Components/Global/PasswordPrompt";
import { verifyPassword } from "Data/Actions/UserAsync";
import { updateCompanyAsync } from "Data/Actions/Company";
import PasswordPolicies from "Components/Admin/PasswordPolicies";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";

const mapStateToProps = (state) => ({
	company: getActiveCompany(state)
});

const mapDispatchToProps = (dispatch) => ({
	verifyPassword: (password: string) => dispatch(verifyPassword(password)),
	updateCompany: (company: ICompany) => dispatch(updateCompanyAsync(company))
});

interface PasswordPoliciesPageState {
	verified: boolean | undefined;
}

interface PasswordPoliciesPageProps {
	company: ICompany;
	verifyPassword: (password: string) => Promise<void>;
	updateCompany: (company: ICompany) => Promise<void>;
}

class PasswordPoliciesPage extends React.Component<PasswordPoliciesPageProps, PasswordPoliciesPageState> {
	constructor(props: PasswordPoliciesPageProps) {
		super(props);

		this.state = {
			verified: undefined
		}

		this.styles = {
			button: {
				marginTop: 10
			},
			grid: {
				display: "grid",
				width: "100%",
				gridTemplateColumns: "50% 50%"
			},
			headerStyle: {
				marginLeft: 12,
				paddingBottom: 14
			},
			info: {
				paddingLeft: 26
			},
			input: {
				width: 80
			},
			rightColumn: {
				justifyContent: "flex start"
			},
			steps: {
				width: "40%",
				marginLeft: 26
			},
			usage: {
				marginBottom: 10
			}
		}

		this.handleVerifyPassword = this.handleVerifyPassword.bind(this);
		this.showPasswordPrompt = this.showPasswordPrompt.bind(this);
		this.handlePasswordReset = this.handlePasswordReset.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { grid } = this.styles;
		const { verified } = this.state;

		if (verified) {
			return (
				<div style={ grid }>
					<PasswordPolicies />
					{ this.renderPasswordResetRequired() }
				</div>
			);
		} else if (verified === false) {
			return (
				this.renderPasswordPrompt()
			);
		} else {
			return (
				this.renderUsageInfo()
			);
		}
	}

	renderPasswordResetRequired() {
		const { rightColumn, headerStyle, info, button } = this.styles;
		return (
			<div style={ rightColumn }>
				<h3 style={ headerStyle }>Force Password Reset</h3>
				<div style={ info }>
					<div>
						Warning: This will require a password reset on next login for all users on this company.
					</div>
					<Button
						type="danger"
						style={ button }
						disabled= { !hasPermission(PERMISSIONS.COMPANIES_EDIT) }
						onClick={ this.handlePasswordReset }
					>
						Require Password Reset
					</Button>
				</div>
			</div>
		);
	}

	renderUsageInfo() {
		const { steps, usage } = this.styles;

		return (
			<div style={ steps }>
				<p style={ usage }>
					A password policy is often part of an organization's official regulations for computer security.
					Press the button below to modify minimum password requirements or to require all user to enter a
					new password on their next login.
				</p>
				<Button
					type="primary"
					onClick={ this.showPasswordPrompt }>
					Modify Password Policies
				</Button>
			</div>
		);
	}

	renderPasswordPrompt() {
		const { steps } = this.styles;

		return (
			<div style={ steps }>
				<PasswordPrompt onContinue={ this.handleVerifyPassword } />
			</div>
		);
	}

	handlePasswordReset() {
		const { company, updateCompany } = this.props;
		const passwordReset = true;

		if (hasPermission(PERMISSIONS.COMPANIES_EDIT)) {
			updateCompany({ ...company, passwordReset });
		}
	}

	handleVerifyPassword(password: string) {
		const { verifyPassword } = this.props;

		verifyPassword(password).then((result) => {
			if (result !== null) {
				this.updateState("verified", true);
			}
		})
	}

	showPasswordPrompt() {
		this.updateState("verified", false);
	}

	updateState(key: string, value: number | boolean) {
		this.setState((state) => {
			return update(state, {
				[key]: { $set: value }
			});
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPoliciesPage);

import { Action, ApkVersion, FeatureToggle, FeatureToggleConfigDispatch, ILocationState,
	RedirectDispatch, VimeoVideos } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";

const {
	SET_CONNECTED_STATUS,
	SET_FEATURE_TOGGLES,
	SET_REDIRECT,
	SET_VIMEO_VIDEOS,
	SET_UNSAVED,
	SET_CURRENT_APK_VERSION,
	CLEAR_CACHE,
	SET_PUSHER_CREDENTIALS
} = ACTION_TYPES.System;

export function setRedirect(location: ILocationState): Action<RedirectDispatch> {
	return { type: SET_REDIRECT.type, args: { location }};
}

export function setVimeoVideos(videos: VimeoVideos) {
	return { type: SET_VIMEO_VIDEOS.type, args: { videos }};
}

export function setConnectedStatus(connected: boolean) {
	return { type: SET_CONNECTED_STATUS.type, args: { connected }}
}

export function setFeatureToggles(config: FeatureToggle[]): Action<FeatureToggleConfigDispatch> {
	return new DispatchableAction(SET_FEATURE_TOGGLES, { config });
}

export function setUnsaved(section: string, value: boolean) {
	return {
		type: SET_UNSAVED.type, args: {
			section, value
		}
	};
}

export function setCurrentApkVersion(version: Partial<ApkVersion>) {
	return new DispatchableAction(SET_CURRENT_APK_VERSION, {
		version
	});
}

export function clearCache(): Action<null> {
	return new DispatchableAction(CLEAR_CACHE, null);
}

export function setPusherCredentials(key: string) {
	return { type: SET_PUSHER_CREDENTIALS.type, args: { key }};
}
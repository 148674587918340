import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, IPlaylist, SortTypes } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Input, Loader } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import EmptyList from "Components/Global/EmptyList";
import PlaylistListItem from "Components/Playlists/PlaylistListItem";
import { getPlaylistsAsync } from "Data/Actions/Playlists";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getAllPlaylists } from "Data/Selectors/Playlists";
import { haveAllPlaylists as getHaveAllPlaylists, makeLastPlaylistsPage } from "Data/Selectors/UI";

const mapStateToProps = (state) => {
	const getLastPageFetched = makeLastPlaylistsPage();

	return ({
		haveAllPlaylists: getHaveAllPlaylists(state),
		lastPageFetched: getLastPageFetched(state),
		playlists: getAllPlaylists(state)
	});
};

const mapDispatchToProps = (dispatch) => ({
	getPlaylists: () => dispatch(getPlaylistsAsync(true))
});

interface PlaylistListProps {
	getPlaylists: () => void;
	haveAllPlaylists: boolean;
	lastPageFetched: number;
	onPlaylistClicked?: (playlist: IPlaylist) => void;
	playlists: IPlaylist[];
	selectedPlaylistId?: string;
	sortType: SortTypes;
}

interface PlaylistListState {
	playlistFilter: string;
	selectedPlaylistId: string;
}

export class PlaylistList extends React.Component<PlaylistListProps, PlaylistListState> {
	constructor(props: PlaylistListProps) {
		super(props);

		this.state = {
			playlistFilter: "",
			selectedPlaylistId: props.selectedPlaylistId || ""
		};

		this.styles = {
			inputWrapper: {
				height: 48,
				background: Colors.darkerGray,
				display: "block",
				width: "calc(100% - 12px)",
				padding: "8px 0px",
				margin: "0px 6px",
				position: "sticky",
				top: 0,
				zIndex: 100
			},
			list: {
				maxWidth: "100%",
				overflowX: "hidden",
				cursor: "pointer",
				transition: "background 200ms"
			},
			waypointWrapper: {
				float: "left",
				width: "100%"
			}
		};

		this.getFilteredPlaylists = this.getFilteredPlaylists.bind(this);
		this.getSortedPlaylists = this.getSortedPlaylists.bind(this);
		this.renderEmptyList = this.renderEmptyList.bind(this);
		this.renderList = this.renderList.bind(this);
		this.renderPlaylistItem = this.renderPlaylistItem.bind(this);
		this.renderWaypoint = this.renderWaypoint.bind(this);
		this.selectPlaylist = this.selectPlaylist.bind(this);
		this.updateFilter = this.updateFilter.bind(this);
	}

	styles: CustomCSS;

	componentWillMount() {
		if (hasPermission(PERMISSIONS.PLAYLISTS_MANAGE)) {
			this.props.getPlaylists();
		}
	}

	componentWillReceiveProps(nextProps: PlaylistListProps) {
		const {selectedPlaylistId} = nextProps;
		if (selectedPlaylistId) {
			this.setState(() => ({ selectedPlaylistId }))
		}
	}

	render() {
		return (
			<React.Fragment>
				<div style={ this.styles.inputWrapper }>
					<Input
						hideMaxLength
						color="dark"
						id="playlistsListFilter"
						placeholder="Filter - My Playlists"
						updateCallback={this.updateFilter}
						value={""}
					/>
				</div>
				{ this.renderList() }
				{ this.renderEmptyList() }
				{ this.renderWaypoint() }
			</React.Fragment>
		);
	}

	renderEmptyList() {
		if (this.props.playlists.length > 0) {
			return null;
		}

		return (
			<EmptyList type="playlists" />
		);
	}

	renderList() {
		const playlists = this.getSortedPlaylists();

		if (!playlists.length) {
			return null;
		}

		return playlists
			.filter(this.getFilteredPlaylists)
			.map(this.renderPlaylistItem);
	}

	renderPlaylistItem(playlist: IPlaylist, i: number) {
		const selected = this.state.selectedPlaylistId === playlist.uuid;
		const style = {
			...this.styles.list,
			background: selected ? Colors.filters.hoverAlt : "none"
		};

		return (
			<div key={ playlist.uuid + i + "_wrapper" }
				onClick={ this.selectPlaylist(playlist) }
				style={ style }
			>
				<PlaylistListItem
					draggable
					key={ playlist.uuid + i }
					playlist={ playlist }
					selected={ selected }
				/>
			</div>
		);
	}

	renderWaypoint() {
		const { getPlaylists, haveAllPlaylists, lastPageFetched } = this.props;
		const havePlaylists = {
			display: haveAllPlaylists ? "none" : "inline-block"
		};

		return (
			<div key={ lastPageFetched }
				style={{
					...this.styles.waypointWrapper,
					...havePlaylists
				}}>
				<Waypoint onEnter={ getPlaylists } />
				<Loader size="small" />
			</div>
		);
	}

	getFilteredPlaylists(playlist: IPlaylist) {
		const { playlistFilter } = this.state;

		return !playlistFilter || playlist.name.toLowerCase().indexOf(playlistFilter.toLowerCase()) > -1;
	}

	getSortedPlaylists() {
		const { playlists, sortType } = this.props;

		return Utils.sortListBySortType(playlists, sortType);
	}

	selectPlaylist(playlist: IPlaylist) {
		return () => {
			if (this.props.onPlaylistClicked) {
				this.props.onPlaylistClicked(playlist);
			}

			this.setState((prevState) => {
				return update(prevState, {
					selectedPlaylistId: { $set: playlist.uuid }
				});
			});
		};
	}

	updateFilter(val: string) {
		this.setState((prevState) => {
			return update(prevState, {
				playlistFilter: { $set: val }
			});
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistList);
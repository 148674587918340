import { Collapse, Select as AntSelect } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CloudProviders, CustomCSS, ICompany, IExtendedCompany, IUser, KeyLabel, NameUuid,
	Team,
	Filters} from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import QRCode from "Components/Admin/QRCode";
import { Button, Icon, Input, Link, Select, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import HelpPopover from "Components/Global/HelpPopover";
import {
	deactivateCompanyAsync,
	deleteCompanyAsync,
	getActiveCompanyDetails,
	syncIntegrationTeams,
	syncIntegrationTeamUsers,
	updateCompanyAsync
} from "Data/Actions/Company";
import { getAdminIntegratorListAsync } from "Data/Actions/Team";
import { setActiveFilters } from "Data/Actions/UI";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import inputValidation from "Data/Objects/Validation";
import { getActiveCompanyId, getCompany, getManagedCompany } from "Data/Selectors/Company";
import { getAdminTeams, getTeam } from "Data/Selectors/Team";
import { haveAllTeamAsync } from "Data/Selectors/UI";
import { Utils } from "@connect/Utils";

const { lightGray } = Colors;
const { OWN_COMPANIES_EDIT, SYSTEM_INTEGRATORS_MANAGE } = PERMISSIONS;
const { OptGroup } = AntSelect;

const mapDispatchToProps = (dispatch) => ({
	fetchIntegrators: () => dispatch(getAdminIntegratorListAsync()),
	deactivateCompany: (company: IExtendedCompany) => dispatch(deactivateCompanyAsync(company)),
	deleteCompany: (company: IExtendedCompany) => dispatch(deleteCompanyAsync(company)),
	setActiveCompany: (companyId: string) => dispatch(getActiveCompanyDetails(companyId)),
	setIntegratorsFilter: (integrator: string) => dispatch(setActiveFilters("integrator", Filters.DEVICES, integrator)),
	syncIntegrationCompanies: (companyId: string, teams: KeyLabel[], oldTeams: KeyLabel[]) =>
		dispatch(syncIntegrationTeams(companyId, teams, oldTeams)),
	syncIntegrationCompanyUsers: (companyId: string, users: KeyLabel[]) =>
		dispatch(syncIntegrationTeamUsers(companyId, users)),
	updateCompany: (company: ICompany) => dispatch(updateCompanyAsync(company))
});

const mapStateToProps = (state, ownProps) => {
	const activeCompany = getActiveCompanyId(state);
	const { company, integrationCompany, isAdmin } = ownProps;
	const uuid = typeof company === "string" ? company : company.uuid;
	const companyInfo = integrationCompany
		? getTeam(state, uuid)
		: getManagedCompany(state, uuid);
	const anyCompany = (companyInfo as IExtendedCompany | Team);
	const extendedCompany = (companyInfo as IExtendedCompany);
	const teamCompany = (companyInfo as Team);
	const devices = anyCompany.devicesCount;
	const users = integrationCompany
		? teamCompany.users
		: extendedCompany.owners;
	let integrations;
	const companyWithHash = Object.assign({}, companyInfo, {
		hashid: (getCompany(state, uuid) || { hashid: "" }).hashid
	});

	if (isAdmin) {
		const getUsers = (allTeamUsers, { users: teamUsers }) => {
			return [ ...allTeamUsers, ...teamUsers ];
		};
		const selectedCompanies = extendedCompany.integrators || [];
		const selectedCompanyUsers = selectedCompanies ? selectedCompanies.reduce(getUsers, []) : [];
		const toUuid = ({ name, uuid: id }) => ({ label: name, key: id });
		const companyIsSelected = ({ uuid: id }) => !!selectedCompanies.find((team) => team.uuid === id);
		const integrationCompanies = getAdminTeams(state);
		const sortedIntegrationCompanies = Utils.sort(integrationCompanies, "name", true);
		const selectedIntegrationCompanies = sortedIntegrationCompanies && sortedIntegrationCompanies.length ?
			sortedIntegrationCompanies.filter(companyIsSelected) : [];

		integrations = {
			haveAllTeams: haveAllTeamAsync(state),
			integrationCompanies,
			selectedIntegrationCompanies,
			selectedCompanies: selectedCompanies.map(toUuid),
			selectedCompanyUsers: selectedCompanyUsers.map(toUuid),
			sortedIntegrationCompanies
		};
	}

	return {
		activeCompany,
		company: companyWithHash,
		companyInfo,
		devices,
		...integrations,
		users
	};
};

interface CompanyInfoProps {
	activeCompany: string;
	company: IExtendedCompany;
	companyInfo: IExtendedCompany | Team;
	deleteCompany: (company: IExtendedCompany) => void;
	deactivateCompany: (company: IExtendedCompany) => void;
	devices: number;
	fetchIntegrators: () => void;
	haveAllTeams?: boolean;
	integrationCompany?: boolean;
	integrationCompanies?: Team[];
	selectedIntegrationCompanies?: NameUuid[];
	isAdmin?: boolean;
	selectedCompanies?: KeyLabel[];
	selectedCompanyUsers?: KeyLabel[];
	sortedIntegrationCompanies?: Team[];
	setActiveCompany: (companyId: string) => void;
	setIntegratorsFilter: (integrator: string) => void;
	syncIntegrationCompanies: (companyId: string, teams: KeyLabel[], oldTeams: KeyLabel[]) => void;
	syncIntegrationCompanyUsers: (companyId: string, users: KeyLabel[]) => void;
	updateCompany: (company: ICompany) => void;
	users: Partial<IUser>[];
}

interface CompanyInfoState {
}

export class CompanyInfo extends React.Component<CompanyInfoProps, CompanyInfoState> {
	constructor(props: CompanyInfoProps) {
		super(props);

		this.styles = {
			big: {
				fontSize: "3em"
			},
			centered: {
				margin: "0 auto",
				textAlign: "center",
				width: "100%"
			},
			collapse: {
				display: "flex",
				flexDirection: "column"
			},
			container: {
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr",
				width: "100%"
			},
			disabled: {
				color: lightGray
			},
			icon: {
				paddingRight: 6
			},
			input: {
				maxWidth: "100%"
			},
			label: {
				marginLeft: -6,
				wordBreak: "normal",
				display: "block"
			},
			parentCompany: {
				paddingLeft: 5,
				fontSize: 14,
				color: "black",
				background: "whitesmoke"
			},
			section: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "0px 18px"
			},
			sectionBorder: {
				borderLeft: `1px solid ${ lightGray }`
			},
			total: {
				fontSize: "1.5em"
			}
		};

		this.deactivateCompany = this.deactivateCompany.bind(this);
		this.promptDeleteCompany = this.promptDeleteCompany.bind(this);
		this.promptPasswordReset = this.promptPasswordReset.bind(this);
		this.handleSaveName = this.handleSaveName.bind(this);
		this.renderCloudProvider = this.renderCloudProvider.bind(this);
		this.renderCompanyOwner = this.renderCompanyOwner.bind(this);
		this.renderCompanyOwners = this.renderCompanyOwners.bind(this);
		this.renderCompanyStatus = this.renderCompanyStatus.bind(this);
		this.renderIntegrationCompanies = this.renderIntegrationCompanies.bind(this);
		this.renderIntegrationUserGroups = this.renderIntegrationUserGroups.bind(this);
		this.setIntegrationCompanies = this.setIntegrationCompanies.bind(this);
		this.setIntegrationCompanyUsers = this.setIntegrationCompanyUsers.bind(this);
		this.viewDevices = this.viewDevices.bind(this);
	}

	styles: CustomCSS;

	componentWillMount() {
		const { fetchIntegrators, haveAllTeams, isAdmin } = this.props;

		if (isAdmin && !haveAllTeams) {
			fetchIntegrators();
		}
	}

	render() {
		return (
			<div style={ this.styles.container }>
				{ this.renderLeft() }
				{ this.renderCenter() }
				{ this.renderRight() }
			</div>
		);
	}

	renderCompanyOwner(user: IUser, index: number) {
		const { icon } = this.styles;
		const { email, name, phone, uuid } = user;
		const headerName = (
			<span>{ name }</span>
		);
		const emailSection = !email ? null : (
			<div>
				<Icon iconWeight="regular" name="envelope" style={ icon } />
				<a href={ `mailto:${ email }` }>{ email }</a>
			</div>
		);
		const phoneSection = !phone ? null : (
			<div>
				<Icon name="phone" style={ icon } />{ phone }
			</div>
		);

		return (
			<Collapse.Panel header={ headerName } key={ uuid + index }>
				{ emailSection }
				{ phoneSection }
			</Collapse.Panel>
		);
	}

	renderCompanyOwners() {
		const { users } = this.props;

		if (!users || !users.length) {
			return null;
		}

		const active = users[0].uuid || "" + 0;

		return (
			<Collapse accordion defaultActiveKey={[ active ]}
				style={ this.styles.collapse } >
				{ users.map(this.renderCompanyOwner) }
			</Collapse>
		);
	}

	renderCompanyStatus() {
		const { activeCompany, companyInfo, integrationCompany, isAdmin } = this.props;

		if (integrationCompany && !isAdmin || activeCompany === companyInfo.uuid || !hasPermission(OWN_COMPANIES_EDIT)) {
			return null;
		}

		const { label, companyStatusActive, companyStatusInactive } = this.styles;
		const isActive = (this.props.companyInfo as IExtendedCompany).active
		const status = isActive ? companyStatusActive : companyStatusInactive;
		const activeStatus = isActive ? "Approved - Active" : "Not Approved - Inactive";
		const verb = isActive ? "Deactivate" : "Activate";
		const icon = isActive ? "eye-slash" : "eye";

		return (
			<React.Fragment>
				<br />
				<span style={ label }>Company Status</span>
				<span style={ status }>{ activeStatus }</span>
				<br />
				<Button
					disabled={ !hasPermission(OWN_COMPANIES_EDIT) }
					icon={ icon }
					onClick={ this.deactivateCompany }
				>
					{ `${ verb } Company` }
				</Button>
				<br />
				<Button
					disabled={ !hasPermission(OWN_COMPANIES_EDIT) }
					className="warning-button"
					icon="trash-alt"
					onClick={ this.promptDeleteCompany }
				>
					Delete Company
				</Button>
				<br />
				<Button
					disabled={ !hasPermission(OWN_COMPANIES_EDIT)}
					className="warning-button"
					icon="sign-out-alt"
					onClick={ this.promptPasswordReset }
				>
					Force Password Reset
				</Button>
			</React.Fragment>
		);
	}

	renderIntegrationCompanies() {
		const { label } = this.styles;
		const {
			integrationCompany,
			isAdmin,
			selectedCompanies,
			selectedCompanyUsers,
			sortedIntegrationCompanies
		} = this.props;

		const cannotView = !hasPermission(SYSTEM_INTEGRATORS_MANAGE) && !isAdmin;

		if (integrationCompany || cannotView || !sortedIntegrationCompanies) {
			return null;
		}
		const companies = sortedIntegrationCompanies.map(this.renderSelectOption);

		return (
			<React.Fragment>
				<br />
				<span style={ label }>Integration Companies</span>
				<Select
					labelInValue
					mode="tags"
					onChange={ this.setIntegrationCompanies }
					value={ selectedCompanies }
				>
					{ companies }
				</Select>
				<br />
				<span style={ label }>Integration Company Users</span>
				<Select
					labelInValue
					mode="tags"
					onChange={ this.setIntegrationCompanyUsers }
					value={ selectedCompanyUsers }
				>
					{ this.renderIntegrationUserGroups() }
				</Select>
			</React.Fragment>
		);
	}

	renderIntegrationUserGroups() {
		const { selectedIntegrationCompanies } = this.props;

		if (!selectedIntegrationCompanies) {
			return null;
		}

		return (selectedIntegrationCompanies || [])
			.map((integrationCompany: Team) => {
				const { uuid, name, users } = integrationCompany;
				const label = (
					<div style={ this.styles.parentCompany }>
						{ name }
					</div>
				);
				return (
					<OptGroup  key= { uuid } label={ label }>
						{ users.map(this.renderSelectOption) }
					</OptGroup>
				)
			});
	}

	renderLeft() {
		const { disabled, input, label, section } = this.styles;
		const { companyInfo, integrationCompany } = this.props;
		const { name, uuid } = companyInfo;
		const companyName = integrationCompany
			? (
				<span className="selectable" style={ disabled }><Truncate length={ 40 }>{ name }</Truncate></span>
			)
			: (
				<Input
					style={ input }
					disabled={ !hasPermission(OWN_COMPANIES_EDIT) }
					id={ uuid }
					value={ name }
					saveCallback={ this.handleSaveName }
					validator={ inputValidation.name } />
			);

		return (
			<div style={ section }>
				<span style={ label }>Company Name:</span>
				{ companyName }
				<br />
				<span style={ label }>Company ID:</span>
				<span style={ disabled }>
					<span className="selectable">{ uuid }</span>
				</span>
				<br />
				{ this.renderCloudProvider() }
				<span style={ label }>Company Owner:</span>
				{ this.renderCompanyOwners() }
			</div>
		);
	}

	renderCloudProvider() {
		const { isAdmin, integrationCompany, company: { cloudServiceProvider } } = this.props;
		const { disabled, label } = this.styles;

		if (!isAdmin || integrationCompany) {
			return null;
		}

		return (
			<React.Fragment>
				<span style={ label }>Cloud Service Provider:</span>
				<span className="selectable" style={ disabled }>{ CloudProviders[cloudServiceProvider] }</span><br />
			</React.Fragment>
		);
	}

	handleSaveName(name: string) {
		const { company, updateCompany } = this.props;

		updateCompany({
			...company as ICompany,
			name
		});
	}

	renderCenter() {
		const { centered, label, section, sectionBorder } = this.styles;
		const { companyInfo, integrationCompany } = this.props;
		const qrProp = integrationCompany ? "integratorId" : "companyId";
		const qrProps = {};

		qrProps[qrProp] = companyInfo.uuid;

		return (
			<div style={{ ...section, ...sectionBorder }}>
				<span style={ label }>
					QR Code:
					<HelpPopover title="Company QR Code">
						<p>This QR code is used to register your device(s) with the proper company and integrator.</p>
					</HelpPopover>
				</span>
				<div style={ centered }>
					<QRCode { ...qrProps } style={{ margin: "0 auto" }} />
				</div>
				{ this.renderIntegrationCompanies() }
			</div>
		);
	}

	renderRight() {
		const { big, centered, label, section, sectionBorder, total } = this.styles;
		const { devices } = this.props;

		return (
			<div style={{ ...section, ...sectionBorder }}>
				<span style={ label }>Managed Devices:</span>
				<div style={{ ...big, ...centered }}>{ devices }</div>
				<div style={{ ...centered, ...total }}>Total Devices</div>
				<div style={ centered }><Link onClick={ this.viewDevices } to="/devices">View Devices ></Link></div>
				{ this.renderCompanyStatus() }
			</div>
		);
	}

	renderSelectOption(item: NameUuid, index: number) {
		const { name, uuid } = item;
		return (
			<Select.Option
				key={ uuid + index + "_companyInfoSelectOption" }
				title={ name }
				value={ uuid }
			>
				<Truncate end length={ 24 }>{ name }</Truncate>
			</Select.Option>
		);
	}

	deactivateCompany() {
		const { deactivateCompany, company } = this.props;
		deactivateCompany(company);
	}

	promptDeleteCompany() {
		const { deleteCompany, company } = this.props;
		Notifications.confirm(
			"Delete Company",
			(
				<React.Fragment>
					<p>Are you sure you would like to delete <strong>{ company.name }</strong>?</p>
					<hr />
					<p><strong>{ company.name }</strong> contains the following assets:</p>
					<p>
						{ company.devicesCount } devices <br />
						{ company.usersCount } users
					</p>
				</React.Fragment>
			),
			"Confirm",
			"Cancel",
			() => deleteCompany(company)
		);
	}

	promptPasswordReset() {
		const { updateCompany, company } = this.props;
		Notifications.confirm(
			"Cause Company Password Reset",
			(
				<p>Are you sure you would like to cause all users from <strong>{ company.name } </strong>
					to reset their password?
				</p>
			),
			"Confirm",
			"Cancel",
			() => updateCompany({ ...company, passwordReset: true })
		);
	}

	setIntegrationCompanies(teamIds: KeyLabel[]) {
		const { company, selectedCompanies, syncIntegrationCompanies } = this.props;
		syncIntegrationCompanies(company.uuid, teamIds, selectedCompanies || []);
	}

	setIntegrationCompanyUsers(userIds: KeyLabel[]) {
		const { company, syncIntegrationCompanyUsers } = this.props;

		syncIntegrationCompanyUsers(company.uuid, userIds);
	}

	viewDevices() {
		const { company, integrationCompany, setActiveCompany, setIntegratorsFilter } = this.props;
		const { uuid } = company;

		if (integrationCompany) {
			setIntegratorsFilter(uuid);
		} else {
			setActiveCompany(uuid);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);

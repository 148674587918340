import * as moment from "moment";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { IMedia, MediaFilterTypes, MediaResult, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import MediaApi from "Api/Media";
import { deleteMedia, removeTagsFromDeletedMedia, resetMedia, setMedia, updateMedia } from "Data/Actions/Media";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { resetAsyncStates, setAsyncFetching, setAsyncState } from "Data/Actions/UI";
import { CacheInvalidationPeriod } from "Data/Objects/Global";
import { getMediaAsyncQueryState, getMediaFavorite, getMediaFilter,
	getMediaLastFetchAll, getNestedAsyncState } from "Data/Selectors/Async";
import { getActiveCompanyId } from "Data/Selectors/Company";
import { getMediaById } from "Data/Selectors/Media";
import { getActiveFilters, getActiveSorts } from "Data/Selectors/UI";
import { isEqual } from "lodash";

/**
 * Async Actions
 */

function deleteMediaAsync(uuid: string, suppressNotification?: boolean) {
	const api = new MediaApi();

	return (dispatch) => api.deleteMedia(uuid)
		.then(() => {
			dispatch(removeTagsFromDeletedMedia(uuid));
			dispatch(deleteMedia(uuid));
			if (!suppressNotification) {
				dispatch(successNotification("Media successfully deleted."));
			}
		}, (error) => {
			if (!suppressNotification) {
				dispatch(errorNotification("Error deleting media.", error));
			}
		})
		.catch((error) => {
			dispatch(notifyBugSnagAsync(new Error(error)));
		});
}

function getMediaAsync(uuid: string) {
	const api = new MediaApi();

	return (dispatch) => api.getMedia(uuid)
		.then((result: IMedia) => {
			dispatch(updateMedia(result));
		}, (error) => {
			dispatch(errorNotification("Error getting media.", error));
		})
		.catch((error) => {
			dispatch(notifyBugSnagAsync(new Error(error)));
		});
}

// TODO: dedupe this pagination ish
function setBannersAsync() {
	const api = new MediaApi();

	return (dispatch, getState) => {
		const { Company, UI: { asyncState } } = getState();
		const { currentPage, currentlyFetching, haveAllData, lastFetch, lastFetchedCompany } = asyncState.banners;
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const companyChanged = Company.activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = moment().diff(lastFetch, "minute") > CacheInvalidationPeriod || companyChanged;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		dispatch(setAsyncFetching("banners", true, Company.activeCompanyId));

		const page = shouldInvalidateCache ? 0 : currentPage;

		return api.getBanners(undefined, page + 1)
			.then((result: MediaResult) => {
				// TODO: Need to fix this pagination bug with resetting on banner load
				dispatch(setMedia(result.data, false));
				dispatch(setAsyncState(
					"banners",
					!result.links.next,
					result.meta.current_page
				));
				dispatch(setAsyncFetching("banners", false));
			},
			(error) => {
				dispatch(errorNotification("Error loading custom banners.", error));
				dispatch(setAsyncFetching("banners", false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function setMediaAsync() {
	const api = new MediaApi();

	return (dispatch, getState) => {
		const state = getState();
		const filterType = getActiveFilters(state, Filters.MEDIA).type as MediaFilterTypes;
		const sortType = getActiveSorts(state, Sorts.MEDIA) as SortTypes;
		const sortAndFilter = { filterType, sortType };
		const asyncState = getMediaAsyncQueryState(sortAndFilter);
		const asyncMedia = getNestedAsyncState(state, asyncState);
		const activeCompanyId = getActiveCompanyId(state);
		const { currentPage, currentlyFetching, haveAllData } = asyncMedia;
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const lastFetchAll = getMediaLastFetchAll(state);
		const lastFetchDiff = moment().diff(moment(lastFetchAll), "minute");
		const shouldInvalidateCache = lastFetchDiff > CacheInvalidationPeriod;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		if (shouldInvalidateCache) {
			dispatch(resetMedia());
			dispatch(resetAsyncStates());
		}

		dispatch(setAsyncFetching(asyncState, true, activeCompanyId));

		const page = shouldInvalidateCache ? 0 : currentPage;
		const favorite = getMediaFavorite(sortAndFilter);
		const sort = Utils.getApiSort(sortAndFilter);
		const mediaType = getMediaFilter(sortAndFilter);

		return api.getMediaList(undefined, page + 1, favorite, sort, mediaType)
			.then((result: MediaResult) => {
				dispatch(setMedia(result.data, shouldInvalidateCache));
				dispatch(setAsyncState(
					asyncState,
					!result.links.next,
					result.meta.current_page
				));
				dispatch(setAsyncFetching(asyncState, false));
			},
			(error) => {
				dispatch(errorNotification("Error getting media.", error));
				dispatch(setAsyncFetching(asyncState, false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function updateMediaAsync(media: IMedia) {
	const api = new MediaApi();

	return (dispatch, getState) => {
		const state = getState();
		const oldMedia = getMediaById(state, media);

		if (!isEqual(oldMedia, media)) {
			api.updateMedia(media)
				.then((result: Response) => {
					dispatch(updateMedia(media));
					dispatch(successNotification(`Updated ${media.name}.`));
				}, (error) => {
					dispatch(errorNotification("Error updating media.", error));
					dispatch(updateMedia(oldMedia || {} as IMedia));
				})
				.catch((error) => {
					dispatch(notifyBugSnagAsync(new Error(error)));
				});
		}
	};
}

export {
	deleteMediaAsync,
	getMediaAsync,
	setBannersAsync,
	setMediaAsync,
	updateMediaAsync
};

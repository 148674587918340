import { Progress } from "antd";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { HelpPopover } from "Components/Global/Common";


interface MediaUsageSectionProps {
	percent: number;
	upperLabel: string;
	lowerLabel: string;
	type: "circle" | "dashboard" | "line";
	vertical?: boolean;
	info?: {
		header: JSX.Element | string,
		content: JSX.Element
	}
}

export default class MediaUsageSection extends React.PureComponent<MediaUsageSectionProps> {
	constructor(props: any) {
		super(props);

		this.styles = {
			container: {
				display: "block",
				textAlign: this.props.vertical ? " left" : "center",
				width: this.props.vertical ? "100%" : "32%",
				padding: "5px",
				flexShrink: 1
			},
			margin: {
				marginBottom: "10px",
				marginTop: "10px"
			},
			table: {
				display: "table",
				margin: "0 auto"
			},
			align: {
				textAlign: this.props.vertical ? "left" : "center"
			},
			usageHelpPopoverContent: {
				maxWidth: 500,
				padding: 10
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { container, margin, table, align } = this.styles;
		return (
			<div style={ container }>
				<div style={ align }>
					{ this.props.upperLabel }
					{ this.renderInfo() }
				</div>
				<div style={ margin }>
					<Progress
						width={ 100 }
						strokeColor={ this.checkUsageColor(this.props.percent) }
						type={ this.props.type }
						percent={ this.props.percent }
						format={ this.formatPercent }
						strokeWidth={ 5 }
						style={ table } />
				</div>
				<div style={ align }>
					{ this.props.lowerLabel }
				</div>
			</div>
		);
	}

	renderInfo() {
		const { usageHelpPopoverContent } = this.styles;
		const { info } = this.props;

		if (!info) {
			return null;
		}

		return (
			<HelpPopover title={ info.header } placement="right">
				<div style={ usageHelpPopoverContent }>
					{ info.content }
				</div>
			</HelpPopover>
		)
	}

	checkUsageColor(percent: number) {
		if (percent <= 50) {
			return "rgb(103, 204, 129)";
		} else if (percent > 50 && percent <= 75) {
			return "rgb(233, 129, 38)";
		}
		return "rgb(194, 27, 36)";
	}

	formatPercent(percent: number) {
		return Math.floor(percent) + "%";
	}
}
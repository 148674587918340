import Radium from "radium";
import * as React from "react";

import { CustomCSS, IUser, NameUuid } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";

const { darkishGray, filters: { hoverAlt }, white } = Colors;

interface DeviceIntegratorsFilterProps {
	integratorFilter: string;
	integrators: NameUuid[];
	onClick: (uuid: string) => () => void;
	user: IUser;
}

@Radium
export class DeviceIntegratorsFilter extends React.Component<DeviceIntegratorsFilterProps> {
	constructor(props: DeviceIntegratorsFilterProps) {
		super(props);

		this.styles = {
			button: {
				border: `1px solid ${ darkishGray }`,
				color: white,
				padding: "8px 16px",
				transition: "background 200ms",
				userSelect: "none",
				":hover": {
					background: hoverAlt,
					cursor: "pointer"
				}
			}
		};

		this.clickButton = this.clickButton.bind(this);
		this.renderButton = this.renderButton.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<div>
				{ this.props.integrators.map(this.renderButton) }
			</div>
		);
	}

	renderButton(integrator: NameUuid) {
		const { user } = this.props;
		const { name, uuid } = integrator;

		if (user.role.name === "integrator" && user.company.uuid !== uuid) {
			return null;
		}

		return (
			<div
				role="button"
				key={ uuid }
				onClick={ this.clickButton(uuid) }
				style={ this.getButtonStyle(uuid) }
			>
				{ name }
			</div>
		);
	}

	clickButton(uuid: string) {
		return this.props.onClick(uuid);
	}

	getButtonStyle(integratorId: string) {
		return {
			...this.styles.button,
			background: this.props.integratorFilter === integratorId ? hoverAlt : "none"
		}
	}
}

export default DeviceIntegratorsFilter;

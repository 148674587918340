import * as React from "react";
import { DragSource, DragSourceCollector, DragSourceSpec } from "react-dnd";

import { Utils } from "@connect/Utils";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { Ad } from "Data/Objects/Ads";

export const IDENTIFIER = "adRow";

interface DraggableAdRowCollectedProps {
	connectDragSource?: Function;
}

interface DraggableAdRowDragObject {
	ad: Ad;
}

interface DraggableAdRowProps extends DragSourceSpec<DraggableAdRowProps, DraggableAdRowDragObject> {
	connectDragSource: Function;
	draggable: boolean;
	ad: Ad;
}

class DraggableAdRow extends React.PureComponent<DraggableAdRowProps> {
	constructor(props: DraggableAdRowProps) {
		super(props);
	}

	render() {
		const element = (
			<div style={{
				padding: 6,
				display: "flex",
				whiteSpace: "nowrap",
				background: Colors.darkerGray
			}}>
				<Icon name="th-large"
					size="smaller" />
				<div style={{
					flex: "1 1 100%",
					margin: "0 6px",
					maxWidth: !this.props.draggable ? "100%" : "50%",
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis"
				}}>
					{this.props.ad.name}
				</div>
				{ this.props.draggable ?
					<div style={{
						flex: "1 1 50%",
						textAlign: "right",
						color: "rgba(255, 255, 255, 0.5)"
					}}>
						<Icon name="clock" iconWeight="regular" />{Utils.getHumanReadableDuration(this.props.ad.duration)}
					</div>
					: null }
			</div>
		);

		if (this.props.draggable) {
			return this.props.connectDragSource(element);
		} else {
			return element;
		}
	}
}

const source: DragSourceSpec<DraggableAdRowProps, DraggableAdRowDragObject> = {
	beginDrag(props: DraggableAdRowProps) {
		return {
			ad: props.ad
		}
	}
}

const collector: DragSourceCollector<DraggableAdRowCollectedProps> = (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource()
	}
}

export default DragSource(IDENTIFIER, source, collector)(DraggableAdRow);
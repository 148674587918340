import Radium from "radium";
import * as React from "react";
import { DragSource, DragSourceCollector, DragSourceMonitor, DragSourceSpec } from "react-dnd";

import { ComponentType, CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

export const NewComponentIdentifier = "newComponentCard";
const { slate, darkGray, white, lightishGray, black } = Colors;

const source: DragSourceSpec<DraggableNewComponentCardProps, DraggableNewComponentCardDragObject> = {
	beginDrag(props: DraggableNewComponentCardProps) {
		return {
			contentType: props.contentType
		}
	},
	endDrag(props: DraggableNewComponentCardProps, monitor: DragSourceMonitor) {
		const dropResult = monitor.getDropResult();

		if (dropResult) {
			return monitor.getItem();
		}

		return null;
	}
}

const collector: DragSourceCollector<DraggableNewComponentCardCollectedProps> = (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource(),
		currentlyDragging: monitor.isDragging()
	}
}

export interface DraggableNewComponentCardCollectedProps {
	currentlyDragging?: boolean,
	connectDragSource?: Function,
}

export interface DraggableNewComponentCardDragObject {
	contentType: string
}

export interface DraggableNewComponentCardProps {
	disabled?: boolean,
	currentlyDragging?: boolean,
	connectDragSource?: Function,
	selectedComponentType: ComponentType;
	contentType: string
}

@Radium
class DraggableNewComponentCard extends React.Component<DraggableNewComponentCardProps> {
	constructor(props: DraggableNewComponentCardProps) {
		super(props);

		this.styles = {
			container: {
				marginBottom: 10
			},
			base: {
				padding: "1.5rem 0",
				textAlign: "center",
				textShadow: "0 1px 1px rgba(0, 0, 0, 0.2)",
				boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
				transition: "background 500ms",
				borderRadius: 5,
				background: black,
				":hover": {
					boxShadow: "0 3px 5px rgba(0, 0, 0, 0.5)",
					cursor: "move"
				}
			},
			label: {
				marginTop: "0.4rem",
				fontSize: "0.7rem"
			}
		}
	}

	styles: {
		container: CustomCSS;
		base: CustomCSS;
		label: CustomCSS;
	}

	render() {
		const { currentlyDragging, disabled, contentType, connectDragSource, selectedComponentType } = this.props;
		const { container, base, label } = this.styles;

		if (currentlyDragging) {
			return null;
		}

		let specificProps = {
			background: Colors.black,
			border: disabled ? `2px solid ${ darkGray }` : `2px solid ${ lightishGray }`,
			icon: "",
			name: ""
		};

		switch (contentType) {
			case "ticker":
				specificProps.icon = "text-width",
				specificProps.name = "Ticker Tape"
				break;
			case "image":
				specificProps.icon = "image",
				specificProps.name = "Image"
				break;
			case "video":
				specificProps.icon = "play-circle",
				specificProps.name = "Video"
				break;
			case "feed":
				specificProps.icon = "video",
				specificProps.name = "Security Feed"
				break;
			case "slideshow":
				specificProps.icon = "exchange",
				specificProps.name = "Slideshow"
				break;
		}

		const { name, background, border, icon } = specificProps;
		const content = (
			<div
				key={ contentType }
				style={{
					...container,
					pointerEvents: disabled ? "none" : "auto"
				}}
			>
				<div style={{
					...base,
					border,
					background: contentType === selectedComponentType ? slate : background,
					color: disabled ? darkGray : white
				}}>
					<Icon name={ icon } size="small" />
					<div style={ label }>
						{ name }
					</div>
				</div>
			</div>
		);

		if (connectDragSource) {
			return connectDragSource(content);
		}

		return content;
	}
}

export default DragSource(NewComponentIdentifier, source, collector)(Radium(DraggableNewComponentCard));

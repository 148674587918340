import * as update from "immutability-helper";
import Radium from "radium";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IVideoComponent } from "@connect/Interfaces";
import { AdColors } from "Components/Ads/Constants";
import {
	AdComponentCard,
	IAdComponentCardProps,
	IAdComponentCardState,
	SuperComponentCard,
	SuperComponentClass
} from "Components/Ads/SuperComponentCard";
import { Colors } from "Components/Global/Constants";

interface VideoComponentCardProps extends IAdComponentCardProps<IVideoComponent> {}

@Radium
export class VideoComponentCard extends AdComponentCard<VideoComponentCardProps, IAdComponentCardState> {
	constructor(props: VideoComponentCardProps) {
		super(props);

		this.icon = "play-circle";
		this.isVideoPlaying = false;
		this.name = "Video";

		this.styles = Object.assign(this.styles, {
			video: {
				width: "100%",
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}
		});

		this.handleVideoClick = this.handleVideoClick.bind(this);
		this.renderContainer = this.renderContainer.bind(this);
		this.setVideoIsPlaying = this.setVideoIsPlaying.bind(this);
	}

	icon: string;
	isVideoPlaying: boolean;
	name: string;
	styles: {
		baseButton: CustomCSS;
		bottomResizeHandle: CustomCSS;
		card: CustomCSS;
		container: CustomCSS;
		deleteButton: CustomCSS;
		eraseButton: CustomCSS;
		heightAdjuster: CustomCSS;
		livePreview: CustomCSS;
		livePreviewName: CustomCSS;
		promptIcon: CustomCSS;
		promptText: CustomCSS;
		resolutionOverlay: CustomCSS;
		snapButton: CustomCSS;
		upload: CustomCSS;
		video: CustomCSS;
	};

	render() {
		const { media } = this.props;

		if (!this.shouldShowLivePreview()) {
			return this.renderContainer(<div />);
		}

		const { thumbs, uri, preview } = media && media[0] || { preview: "", thumbs: "", uri: "" };
		const srcUri = preview ?? uri;

		return this.renderContainer(
			<div style={ this.styles.video }>
				<video
					onPlay={ this.setVideoIsPlaying(true) }
					onPause={ this.setVideoIsPlaying(false) }
					id="video-component"
					key={ srcUri }
					width="100%"
					height="100%"
					poster={ thumbs }
					onClick={ this.handleVideoClick }
					onMouseOver={ this.toggleVideoProperty("controls") }
					onMouseDown={ this.toggleVideoProperty("draggable") }
					onMouseLeave={ this.toggleVideoProperty("controls") }
					onMouseUp={ this.toggleVideoProperty("draggable") }
					loop>
					<source src={ srcUri } type="video/mp4" />
				</video>
			</div>
		);
	}

	getCardStyles() {
		const { livePreview, media } = this.props;
		const withImage = livePreview && media && media[0];

		return update(this.styles.card, {
			backgroundColor: { $set: withImage ? Colors.black : AdColors.video.background }
		});
	}

	getKey(suffix: string) {
		const key = super.getKey(suffix);
		const { media } = this.props;
		const { uuid } = (media && media[0]) ? media[0] : { uuid: "" }

		return `${uuid}_${key}`;
	}

	handleVideoClick(e: React.SyntheticEvent<HTMLVideoElement>) {
		e.preventDefault();

		const video = (e.target as HTMLVideoElement);
		const playing = this.isVideoPlaying;

		if (video.paused && !playing) {
			this.isVideoPlaying = true;
			video.play();
		} else if (!video.paused && playing) {
			this.isVideoPlaying = false;
			video.pause();
		}
	}

	setVideoIsPlaying(play: boolean = false) {
		return () => {
			this.isVideoPlaying = play;
		}
	}

	// ensure that we show the image icon if we don't have a media for this component
	shouldShowLivePreview() {
		const { livePreview, media } = this.props;

		return !!(livePreview && media && media[0]);
	}

	toggleVideoProperty(prop: string) {
		return (e: React.SyntheticEvent<HTMLVideoElement>) => {
			const video = (e.target as HTMLVideoElement);
			const attr = video[prop];

			if (prop !== "draggable") {
				video[prop] = !attr;
			}
		}
	}
}

const {
	Draggable,
	mapStateToProps,
	mapDispatchToProps
} = SuperComponentCard;

export const DndVideoComponentCard: SuperComponentClass<VideoComponentCardProps> = Draggable(
	connect(mapStateToProps, mapDispatchToProps)(VideoComponentCard)
);

export default DndVideoComponentCard;

import { Action, SetPermissionSettingsDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { PermissionsState } from "Data/Objects/AppState";
import {
	createReducer,
	setState
} from "Data/Utils";

export function resetPermissions(state: PermissionsState, action: Action<null>) {
	return setState(state, "permissions", null);
}

export function setPermissions(state: PermissionsState, action: Action<SetPermissionSettingsDispatch>) {
	return setState(state, "permissions", action.args.permissions);
}

const {
	RESET_PERMISSIONS,
	SET_PERMISSIONS
} = ACTION_TYPES.Permissions;

const reducers = {
	[RESET_PERMISSIONS.type]: resetPermissions,
	[SET_PERMISSIONS.type]: setPermissions
};

export default createReducer(reducers, PermissionsState);

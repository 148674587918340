import { createSelector } from "reselect";

import { IPlaylist, PlaylistCollectionArgs, SortTypes, NameUuid } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { AppState, PlaylistState } from "Data/Objects/AppState";
import { getActiveUuid } from "Data/Selectors/UI";
import { getSortedCollection, identityReducer } from "Data/Utils";

export function getActivePlaylistByUuid(playlists: IPlaylist[], playlistId: string) {
	return playlists.filter(({ uuid }) => uuid === playlistId)[0];
}

export function getUuid(state: AppState) {
	return getActiveUuid(state, "playlists");
}

export function getPlaylistDuration(playlist: IPlaylist) {
	if (!playlist) {
		return null;
	}

	const { ads } = playlist;
	let length = 0;

	if (ads) {
		length = ads.map((ad) => ad.duration).reduce((a, b) => a + b, 0);
	}

	return Utils.getHumanReadableDuration(length, false, false, true);
}

export function getPlaylistsFilteredBySearch(playlists: IPlaylist[], search: string) {
	return playlists.filter(({ name }) => {
		return name && name.toLowerCase().indexOf(search.toLowerCase()) > -1
	});
}

export function getPlaylistsSearch(state: AppState, args: PlaylistCollectionArgs): string {
	return args.search || "";
}

export function getPlaylistsSort(state: AppState, args: PlaylistCollectionArgs): SortTypes {
	return args.sort || SortTypes.NEWEST_FIRST;
}

export function getPlaylistsSorted(playlists: IPlaylist[], sortType: SortTypes) {
	return getSortedCollection<IPlaylist>(playlists, sortType);
}

export function getPlaylistState(state: AppState): PlaylistState {
	return state.Playlists;
}

export function getPlaylists(state: PlaylistState): IPlaylist[] {
	return state.playlists;
}

export function getPlaylistsByNameUuid(allPlaylists: IPlaylist[],
	targetPlaylists: NameUuid[] | undefined): IPlaylist[] {

	if (!targetPlaylists) {
		return [];
	}

	const targetUuids = targetPlaylists.map((value: NameUuid) => value.uuid);

	return allPlaylists.filter((playlist: IPlaylist) => {
		return targetUuids.indexOf(playlist.uuid) >= 0;
	});
}

export const getAllPlaylists = createSelector(
	[ getPlaylistState ],
	getPlaylists
);

export const getTargetPlaylists = createSelector(
	[ getAllPlaylists, identityReducer ],
	getPlaylistsByNameUuid
);

export const getActivePlaylist = createSelector(
	[ getAllPlaylists, getUuid ],
	getActivePlaylistByUuid
);

export const getFilteredPlaylists = createSelector(
	[ getAllPlaylists, getPlaylistsSearch ],
	getPlaylistsFilteredBySearch
);

export const getSortedPlaylists = createSelector(
	[ getAllPlaylists, getPlaylistsSort ],
	getPlaylistsSorted
);

export const getFilteredSortedPlaylists = createSelector(
	[ getFilteredPlaylists, getPlaylistsSort ],
	getPlaylistsSorted
);

export const getActivePlaylistDuration = createSelector(
	[ getActivePlaylist ],
	getPlaylistDuration
);
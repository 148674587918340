import { Modal } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { CustomCSS, IUser, Section, Subsection, Term } from "@connect/Interfaces";
import { getTermsConditionsModalVisibility } from "Data/Selectors/UI";
import TermsAndConditions, { termsVersion } from "Data/TermsAndConditions";
import { Button } from "Components/Global/Common";
import { getCurrentUser } from "Data/Selectors/User";
import { updateUserAcceptedTerms } from "Data/Actions/UserAsync";
import { setTermsConditionsModalVisibility } from "Data/Actions/UI/Modals";

const mapDispatchToProps = (dispatch) => ({
	closeModal: () => dispatch(setTermsConditionsModalVisibility(false)),
	acceptTerms: () => dispatch(updateUserAcceptedTerms())
});

const mapStateToProps = (state) => ({
	visible: getTermsConditionsModalVisibility(state),
	user: getCurrentUser(state)
});

interface TermsConditionsModalProps {
	acceptTerms: () => void;
	closeModal: () => void;
	user: IUser;
	visible: boolean;
}

const styles = {
	term: {
		marginBottom: 10
	},
	container: {
		height: 500,
		overflowY: "auto",
		paddingRight: 20
	}
} as CustomCSS;

class TermsConditionsModal extends React.Component<TermsConditionsModalProps, {}> {
	constructor(props: TermsConditionsModalProps) {
		super(props);

		this.renderTerms = this.renderTerms.bind(this);
		this.renderSection = this.renderSection.bind(this);
		this.renderSubsection = this.renderSubsection.bind(this);
		this.renderTerm = this.renderTerm.bind(this);
		this.renderTermDataItem = this.renderTermDataItem.bind(this);
	}

	render() {
		const { visible, user, closeModal } = this.props;
		const termsAccepted = !!user?.acceptedTerms && user.acceptedTerms === termsVersion;
		const closable = !user || termsAccepted;

		return (
			<Modal
				destroyOnClose
				closable={ closable }
				maskClosable={ closable }
				keyboard={ closable }
				onCancel={ closeModal }
				footer={ this.renderFooter() }
				width={ 800 }
				visible={ visible }>
				<div style={ styles.container }>
					{ this.renderTerms(TermsAndConditions) }
				</div>
			</Modal>
		);
	}

	renderTerms(sections: Section[]) {
		return sections.map(this.renderSection);
	}

	renderSection(section: Section) {
		return (
			<React.Fragment key={ section.title }>
				<h1>{ section.title }</h1>
				{ section.data.map(this.renderSubsection) }
			</React.Fragment>
		)
	}

	renderSubsection(subsection: Subsection) {
		return (
			<React.Fragment key={ subsection.subtitle }>
				<h2>{ subsection.subtitle }</h2>
				{ subsection.data.map(this.renderTerm) }
			</React.Fragment>
		);
	}

	renderTerm(term: Term, index: number) {
		if (term.type === "bullets") {
			return (
				<ul key={ term.type + "_" + index }>
					{ (term.data as string[]).map(this.renderTermDataItem) }
				</ul>
			);
		}
		return (
			<p key={ term.data as string + "_" + index } style={ styles.term }>
				{ term.data }
			</p>
		);
	}

	renderTermDataItem(item: string) {
		return (
			<li key={ item }>{ item }</li>
		);
	}

	renderFooter = () => {
		const { user } = this.props;
		const termsAccepted = !!user?.acceptedTerms && user.acceptedTerms === termsVersion;

		if (!user || termsAccepted) {
			return null;
		}

		return (
			<Button type="primary" onClick={ this.handleSubmit }>
				I Agree
			</Button>
		);
	}

	handleSubmit = () => {
		this.props.acceptTerms();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditionsModal);
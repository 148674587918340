import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IDevice } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Button, Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { requestDeviceScreenshotAsync } from "Data/Actions/UIAsync";
import { AppState } from "Data/Objects/AppState";
import Perishable from "Data/Objects/Perishable";
import { getDeviceHealthModalDevice, getDeviceHealthModalUUID } from "Data/Selectors/UI";
import { getDeviceHealthModalDeviceState } from "Data/Selectors/UI";
import NetworkWarning  from "Components/Devices/NetworkWarning";

const { lightGray, evenMoreLightGray, black } = Colors;

const mapDispatchToProps = (dispatch, ownProps: DeviceScreenshotPanelProps) => ({
	requestDeviceScreenshot: (uuid: string) => dispatch(requestDeviceScreenshotAsync(uuid))
})

const mapStateToProps = (state: AppState, ownProps: DeviceScreenshotPanelProps) => {
	const device = getDeviceHealthModalDevice(state);
	const selectedUUID = getDeviceHealthModalUUID(state);
	const { fetchingScreenshot, screenshotTime, screenshotURL } = getDeviceHealthModalDeviceState(state, selectedUUID);

	return {
		selectedUUID,
		device,
		screenshotTime,
		screenshotURL,
		fetchingScreenshot
	};
}

interface DeviceScreenshotPanelProps {
	selectedUUID: string;
	device: IDevice;
	screenshotTime: string;
	screenshotURL: string;
	fetchingScreenshot: Perishable<boolean>;
	requestDeviceScreenshot: (uuid: string) => void;
}

export class DeviceScreenshotPanel extends React.Component<DeviceScreenshotPanelProps> {
	constructor(props: DeviceScreenshotPanelProps) {
		super(props);

		this.styles = {
			date: {
				color: black
			},
			footer: {
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				margin: "24px 0px -16px"
			},
			frame: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "50vh",
				width: "28.125vh",
				margin: "auto",
				background: evenMoreLightGray,
				cursor: "pointer",
				padding: 4
			},
			gray: {
				color: lightGray
			},
			header: {
				marginBottom: 12
			},
			card: {
				boxShadow: "0 4px 8px 0 #F3F3F3, 0 6px 20px 0 #F9F9F9",
				padding: "30px 40px",
				margin: "10px 5px 5px 5px"
			}
		};
	}

	styles: CustomCSS;
	renderTimeout: any;

	// TODO: These timeouts need to be addressed when we have a better solution
	// Until then they are needed to force updates when the Perishable items update
	componentDidMount() {
		this.renderTimeout = setInterval(() => {
			this.forceUpdate()
		}, 10000);
	}

	componentWillUnmount() {
		clearInterval(this.renderTimeout);
	}

	render() {
		return (
			<React.Fragment>
				<NetworkWarning />
				<div style={ this.styles.card }>
					{ this.renderHeader() }
					{ this.renderFrame() }
					{ this.renderScreenshot() }
					{ this.renderFooter() }
				</div>
			</React.Fragment>
		);
	}

	renderHeader() {
		return (
			<div style={ this.styles.header }>
				This is a still screenshot of what content is shown on the screen at
				the time of the request. This will expire when closing this window.
				Depending on the network connection and internet upload speed, the
				"Request Screenshot" can take up to several minutes.
			</div>
		);
	}

	renderFooter() {
		return (
			<div style={ this.styles.footer }>
				{ this.renderImageDate() }
				{ this.renderUpdateScreenshotButton() }
			</div>
		);
	}

	renderUpdateScreenshotButton() {
		const { screenshotURL, fetchingScreenshot } = this.props;

		return (
			<Button
				disabled={ !!(!screenshotURL || fetchingScreenshot.value) }
				icon="image"
				onClick={ this.initiateRequestDeviceScreenshot() }
			>
				Update Screenshot
			</Button>
		);
	}

	renderImageDate() {
		const { screenshotTime} = this.props;
		const date = screenshotTime ? Utils.getHumanReadableDate(screenshotTime) : "N/A";

		return (
			<div>
				Date of image:&nbsp;
				<span style={ this.styles.date }>
					{ date }
				</span>
			</div>
		);
	}

	renderFrame() {
		const { screenshotURL, fetchingScreenshot } = this.props;
		const fetching = fetchingScreenshot.value;

		if (screenshotURL && !fetching) {
			return null;
		}

		const { frame, gray } = this.styles;
		const icon = fetching ? "sync" : "image";

		return (
			<div
				style={ frame }
				onClick={ this.initiateRequestDeviceScreenshot() }
			>
				<Icon
					name={ icon }
					size="small"
					style={ gray }
					spin={ !!fetching }
				/>
				<br />
				<span style={ gray }>
					Request Screenshot
				</span>
			</div>
		);
	}

	renderScreenshot() {
		const { screenshotURL, fetchingScreenshot } = this.props;

		if (!screenshotURL || fetchingScreenshot.value) {
			return null;
		}

		return (
			<img
				src={ screenshotURL }
				style={ this.styles.frame }
			/>
		);
	}

	initiateRequestDeviceScreenshot() {
		const { requestDeviceScreenshot, selectedUUID } = this.props;

		return () => {
			requestDeviceScreenshot(selectedUUID);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceScreenshotPanel);
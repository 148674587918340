import { Slider, Switch } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IBaseComponent, IImageComponent, IMedia, MediaContext } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import ColorPickerButton from "Components/Global/ColorPickerButton";
import { Input } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import OptionButton from "Components/Global/OptionButton";
import { setSelectMediaModal } from "Data/Actions/UI/Modals";
import { updateComponent } from "Data/Actions/UI/AdBuilder";
import { getSelectedComponent, getSelectedComponentIndex, getSelectedComponentMedia } from "Data/Selectors/AdBuilder";
import { getAdMedia } from "Data/Selectors/Ads";

interface PanelProps {
	component: IBaseComponent;
	media: IMedia[];
	selectedComponentIndex: number;
	showSelectMediaModal: () => void;
	updateSelectedComponent: (index: number, component: IBaseComponent) => void;
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateSelectedComponent: (index: number, component: IBaseComponent) =>
		dispatch(updateComponent(index, component, true)),
	showSelectMediaModal: () => {
		dispatch(setSelectMediaModal(true, [], "", MediaContext.IMAGE))
	}
});

const mapStateToProps = (state, ownProps) => {
	const component = getSelectedComponent(state);
	const media = getSelectedComponentMedia(state);

	return {
		media: getAdMedia(state, media),
		component,
		selectedComponentIndex: getSelectedComponentIndex(state)
	};
};

const styles = {
	lockOptionsContainer: {
		display: "grid",
		gridTemplateColumns: "auto auto auto",
		width: "40%",
		marginLeft: "30%",
		marginTop: 10,
		marginBottom: 20
	},
	row: {
		marginBottom: 14
	},
	label: {
		width: 100,
		display: "inline-block"
	},
	small: {
		fontSize: "0.8em"
	},
	advancedScalingContainer: {
		display: "flex",
		flexDirection: "row",
		marginTop: 10
	},
	advancedScalingLabel: {
		flex: 8,
		lineHeight: "24px",
		verticalAlign: "middle"
	},
	scaleLockOptionsContainer: {
		display: "flex",
		flexDirection: "row",
		paddingTop: 10
	},
	scaleLockOptionsLabel: {
		flex: 7
	},
	scaleLockOptionsContent: {
		flex: 3
	},
	colorPickerContainer: {
		display: "flex",
		justifyContent: "center"
	},
	optionsButton: {
		height: 26.666,
		padding: 0,
		margin: 2,
		fontSize: "1 rem"
	}
} as CustomCSS;

const marks = {
	10: { label: "10%", style: { paddingLeft: 20 } },
	200: { label: "200%", style: { paddingRight: 30 } }
};

class ImagePropertiesPanelContent extends React.Component<PanelProps> {
	constructor(props: PanelProps) {
		super(props);

		this.onScaleSliderChange = this.onScaleSliderChange.bind(this);
		this.renderLockOption = this.renderLockOption.bind(this);
		this.renderScaleLockOptions = this.renderScaleLockOptions.bind(this);
		this.setBackgroundColor = this.setBackgroundColor.bind(this);
		this.setImageLock = this.setImageLock.bind(this);
		this.showSelectMediaModal = this.showSelectMediaModal.bind(this);
		this.toggleCustomScale = this.toggleCustomScale.bind(this);
	}

	render() {
		return (
			<div>
				{ this.renderComponentDimensions() }
				{ this.renderImageSelect() }
				{ this.renderImageDetails() }
				{ this.renderAdvancedScaling() }
				{ this.renderScaleLockOptions() }
			</div>
		);
	}

	renderAdvancedScaling() {
		const { advancedScalingContainer, advancedScalingLabel } = styles;
		const { component: cpt } = this.props;
		const component = cpt as IImageComponent;
		const checked = !!component.lock && !!component.scale;

		return (
			<div style={advancedScalingContainer}>
				<div style={advancedScalingLabel}>Advanced Scaling</div>
				<div style={{flex: 2}}>
					<Switch
						key={ component.id + checked }
						onChange={this.toggleCustomScale}
						defaultChecked={ checked }
					/>
				</div>
			</div>
		);
	}

	renderComponentDimensions() {
		const { component } = this.props;

		// calculate the component dimensions in px... they come in as %
		// note that this currently assumes 1920x1080 in portrait
		const componentWidth = Utils.getComponentWidthFromPercent(component.width.value);
		const componentHeight = Utils.getComponentHeightFromPercent(component.height.value);

		return (
			<div style={styles.row}>
				Component Dimensions: {componentWidth}x{componentHeight}px
			</div>
		);
	}

	renderImageSelect() {
		const [ media ] = this.props.media;

		return (
			<div style={styles.row}>
				<Input
					button={{
						callback: this.showSelectMediaModal(),
						text: media ? "Replace" : "Select"
					}}
					disabled
					id={ media ? media.uuid : "newImage" }
					readOnly
					value={ media ? media.name : "" }
				/>
			</div>
		);
	}

	renderImageDetails() {
		const [ media ] = this.props.media;

		if (!media) {
			return null;
		}

		const { label, row } = styles;

		return (
			<div>
				<div style={row}>
					<div style={label}>
						Dimensions:
					</div>
					{ media.width && media.height ?
						<span>
							{media.width}x{media.height}px
						</span>
						: null
					}
				</div>
				<div>
					<div style={label}>
						File Size:
					</div>
					<span>{Utils.getHumanReadableBytesize(media.bytesize)}</span>
				</div>
			</div>
		);
	}

	renderScaleLockOptions() {
		const { scaleLockOptionsContainer, scaleLockOptionsLabel,
			colorPickerContainer, scaleLockOptionsContent } = styles;
		const { backgroundColor, lock, scale } = this.props.component as IImageComponent;

		if (!lock) {
			return null;
		}

		return (
			<div>
				{this.renderLockOptions()}
				<Slider
					marks={ marks }
					min={ 10 }
					max={ 200 }
					step={ 5 }
					value={ scale }
					onChange={ this.onScaleSliderChange }/>
				<div style={ scaleLockOptionsContainer }>
					<div style={ scaleLockOptionsLabel }>
						Background Color:
					</div>
					<div style={ scaleLockOptionsContent }>
						{ backgroundColor }
					</div>
				</div>
				<div style={ colorPickerContainer }>
					<ColorPickerButton
						key={ backgroundColor }
						startColor={ backgroundColor }
						onColorSelected={ this.setBackgroundColor }/>
				</div>
			</div>
		);
	}

	renderLockOptions() {
		let lockOptions = [
			{
				lock: "topLeft",
				icon: "↖"
			},
			{
				lock: "topCenter",
				icon: "↑"
			},
			{
				lock: "topRight",
				icon: "↗"
			},
			{
				lock: "middleLeft",
				icon: "←"
			},
			{
				lock: "middleCenter",
				icon: "○"
			},
			{
				lock: "middleRight",
				icon: "→"
			},
			{
				lock: "bottomLeft",
				icon: "↙"
			},
			{
				lock: "bottomCenter",
				icon: "↓"
			},
			{
				lock: "bottomRight",
				icon: "↘"
			}
		];

		return (
			<div style={styles.lockOptionsContainer}>
				{lockOptions.map(this.renderLockOption)}
			</div>
		);
	}

	renderLockOption(lockOption: any) {
		const { optionsButton } = styles;
		const { lock, icon } = lockOption;
		const { lock: componentLock } = this.props.component as IImageComponent;

		return (
			<OptionButton
				rounded
				key={ lock }
				selected={ componentLock === lock }
				style={ optionsButton }
				onClick={ this.setImageLock(lock) }
			>
				{ icon }
			</OptionButton>
		)
	}

	getButtonBackground(lock: string) {
		const { lock: componentLock } = this.props.component as IImageComponent;

		if (componentLock === lock) {
			return Colors.primaryBlue;
		}

		return "gray";
	}

	onScaleSliderChange(scale: number) {
		const { component, selectedComponentIndex, updateSelectedComponent } = this.props;

		const updatedComponent = Object.assign({}, component, {
			scale
		});

		updateSelectedComponent(selectedComponentIndex, updatedComponent);
	}

	setImageLock(lock: string) {
		return () => {
			const { component, selectedComponentIndex, updateSelectedComponent } = this.props;

			const updatedComponent = Object.assign({}, component, {
				lock
			});

			updateSelectedComponent(selectedComponentIndex, updatedComponent);
		}
	}

	setBackgroundColor(backgroundColor: string) {
		const { component, selectedComponentIndex, updateSelectedComponent } = this.props;

		const updatedComponent = Object.assign({}, component, {
			backgroundColor
		});

		updateSelectedComponent(selectedComponentIndex, updatedComponent);
	}

	showSelectMediaModal() {
		return () => {
			this.props.showSelectMediaModal();
		}
	}

	toggleCustomScale(value: boolean) {
		const { component, selectedComponentIndex, updateSelectedComponent } = this.props;
		const scale = value ? 105 : null;
		const lock = value ? "middleCenter" : null;
		const color = value ? Colors.white : null;
		const newComponent = Object.assign({}, component, { scale, lock, color });

		updateSelectedComponent(selectedComponentIndex, newComponent);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagePropertiesPanelContent);
import { Alert, Popover } from "antd";
import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { CustomCSS, Deployment, DeploymentDeviceStatus, IDeploymentSchedule } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import { Button, Icon, Table, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import HelpPopover from "Components/Global/HelpPopover";
import { getDeploymentAsync, redeployDeployment } from "Data/Actions/DeploymentsAsync";
import { calculateDeviceCounts } from "Data/Objects/Deployments";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getDeploymentById, areCancelled } from "Data/Selectors/Deployments";
import { getActiveUuid } from "Data/Selectors/UI";
import HeaderText from "Components/Global/HeaderText";
import Status from "Components/Global/Status";

const { lightGray, primaryBlue, primaryGreen, red, selectedBlue } = Colors;

const mapDispatchToProps = (dispatch) => ({
	getDeploymentDetails: (uuid: string) => dispatch(getDeploymentAsync(uuid)),
	goToDeployPage: () => dispatch(push("/deploy")),
	redeploy: (uuid: string) => dispatch(redeployDeployment(uuid)),
	selectDevice: (uuid: string) => dispatch(push(`/devices/${uuid}`))
});

const mapStateToProps = (state) => {
	const activeUuid = getActiveUuid(state, "deploymentReport")
	const deployment = getDeploymentById(state, activeUuid);
	const info = deployment && deployment.deviceStatus || [];
	const deviceUuids = info.map((status: DeploymentDeviceStatus) => status.uuid);

	return {
		activeUuid,
		deployment,
		deviceUuids
	};
};

type DeploymentDeviceFilter = "total" | "success" | "missed";

interface DeploymentReportProps {
	activeUuid: string;
	deployment: Deployment;
	deviceUuids: string[];
	getDeploymentDetails: (uuid: string) => Promise<void>;
	goToDeployPage: () => void;
	redeploy: (uuid: string) => void;
	selectDevice: (uuid: string) => void;
}

interface DeploymentReportState {
	activeFilter: DeploymentDeviceFilter;
	filteredDevices: string[];
}

interface FormattedDeviceData {
	group: string;
	key: string;
	model: string;
	name: string;
	online: boolean;
	pusher: boolean;
	status: boolean;
	uuid: string;
};

class DeploymentReport extends React.Component<DeploymentReportProps, DeploymentReportState> {
	constructor(props: DeploymentReportProps) {
		super(props);

		this.styles = {
			center: {
				margin: "36px auto",
				textAlign: "center"
			},
			clipboard: {
				textAlign: "center",
				paddingTop: 5
			},
			close: {
				cursor: "pointer",
				position: "absolute",
				right: 18,
				top: 5
			},
			column: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				padding: 20
			},
			columnGroup: {
				width: "100%",
				display: "flex",
				justifyContent: "space-between",
				textAlign: "left"
			},
			dateRange: {
				width: "55%",
				marginLeft: "4%",
				justifyContent: "center",
				display: "flex",
				flexDirection: "row",
				textAlign: "center"
			},
			content: {
				display: "flex",
				flexDirection: "column",
				height: "100%",
				margin: "0 auto",
				maxWidth: "85vw",
				position: "relative"
			},
			failure: {
				color: red
			},
			filter: {
				border: `1px solid ${lightGray}`,
				borderLeft: 0,
				cursor: "pointer",
				flexBasis: 1,
				flexGrow: 1,
				padding: 6,
				textAlign: "center"
			},
			filterInfo: {
				fontSize: "1.1em",
				fontWeight: "bold"
			},
			filterNumber: {
				fontSize: "2em",
				fontWeight: "bold"
			},
			header: {
				display: "flex",
				justifyContent: "center"
			},
			hyphen: {
				fontSize: "1.1em",
				fontWeight: "bold",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				paddingTop: "1.1rem"
			},
			infoDetail: {
				fontSize: "1.1em",
				fontWeight: "bold"
			},
			infoPopup: {
				textAlign: "center",
				width: "100%"
			},
			infoTitle: {
				color: lightGray
			},
			leftBorder: {
				borderLeft: `1px solid ${lightGray}`
			},
			link: {
				color: primaryBlue,
				cursor: "pointer"
			},
			note: {
				margin: "0 auto",
				padding: "6px 12px",
				textAlign: "center"
			},
			redeploy: {
				bottom: 18,
				position: "absolute",
				right: 18
			},
			scheduleDetails: {
				listStyleType: "none",
				margin: 0,
				padding: "0 12px"
			},
			scheduleItem: {
				color: lightGray,
				display: "flex",
				justifyContent: "space-between",
				width: "100%"
			},
			scheduleItemInner: {
				paddingRight: 12
			},
			scheduleItemInnerRight: {
				paddingLeft: 12
			},
			selected: {
				backgroundColor: selectedBlue
			},
			status: {
				borderRadius: 5,
				display: "inline-block",
				height: 8,
				margin: "-1px 6px 0 0",
				verticalAlign: "middle",
				width: 8
			},
			success: {
				color: primaryGreen
			},
			table: {
				margin: "24px 0",
				textAlign: "left"
			},
			helpedLegend: {
				display: "flex",
				flexDirection: "column",
				marginTop: 16
			}
		};

		this.state = {
			activeFilter: "total",
			filteredDevices: props.deviceUuids
		};

		this.closeReport = this.closeReport.bind(this);
		this.formatDeviceData = this.formatDeviceData.bind(this);
		this.getFilteredDevices = this.getFilteredDevices.bind(this);
		this.redeploy = this.redeploy.bind(this);
		this.renderClose = this.renderClose.bind(this);
		this.renderHeader = this.renderHeader.bind(this);
		this.renderInfo = this.renderInfo.bind(this);
		this.renderFilters = this.renderFilters.bind(this);
		this.renderDeviceName = this.renderDeviceName.bind(this);
		this.renderDevices = this.renderDevices.bind(this);
		this.renderRedeploy = this.renderRedeploy.bind(this);
		this.renderScheduleDetails = this.renderScheduleDetails.bind(this);
		this.renderStatus = this.renderStatus.bind(this);
		this.renderStatusIcon = this.renderStatusIcon.bind(this);
		this.selectDevice = this.selectDevice.bind(this);
		this.selectFilter = this.selectFilter.bind(this);
		this.setFiltersRef = this.setFiltersRef.bind(this);
	}

	styles: CustomCSS;
	filters: HTMLDivElement;

	componentDidMount() {
		const { activeUuid, getDeploymentDetails, goToDeployPage } = this.props;

		if (!activeUuid) {
			goToDeployPage();
		} else {
			getDeploymentDetails(activeUuid).then(() => {
				const { activeFilter } = this.state;
				const selectActive = this.selectFilter(activeFilter);

				selectActive(null);
			});
		}
	}

	render() {
		if (!this.props.deployment) {
			return null;
		}

		return (
			<React.Fragment>
				{ this.renderClose() }
				<div style={ this.styles.content }>
					{ this.renderHeader() }
					{ this.renderInfo() }
					{ this.renderFilters() }
					{ this.renderDevices() }
				</div>
				{ this.renderRedeploy() }
			</React.Fragment>
		);
	}

	renderClose() {
		return (
			<Icon
				style={ this.styles.close }
				size="smaller"
				iconWeight="regular"
				name="times"
				onClick={ this.closeReport } />
		);
	}

	renderDeviceName(name: string, record: FormattedDeviceData) {
		const canManage = hasPermission(PERMISSIONS.DEVICES_MANAGE);
		const onClick = canManage ? this.selectDevice(record.uuid) : () => null;
		const style = canManage ? this.styles.link : null;

		return (
			<div
				key={ `deviceName_${name}_${record.uuid}` }
				onClick={ onClick }
				style={ style }
			>
				{ name }
			</div>
		);
	}

	renderDeviceStatus(value: any, record: FormattedDeviceData) {
		return (
			<div key={ `deviceStatus_${record.name}_${record.uuid}` }>
				{ record.status ? "Success" : "Missed" }
			</div>
		);
	}

	renderStatusTitle() {

		const helpLegend = (
			<div style={ this.styles.helpedLegend }>
				<Status icon="check-circle" iconWeight="regular" text="Green - Successful" color="green"/>
				<Status icon="exclamation-circle" iconWeight="regular" text="Red - Missed" color="red"/>
			</div>
		)

		return (
			<div>
				Status
				<HelpPopover title="Status" placement="right">
					Displays whether or not this deployment was successfully received by the device.
					{ helpLegend }
				</HelpPopover>
			</div>
		)
	}

	renderDevices() {
		// TODO: Fix typing for this
		const columns: any[] = [
			{
				title: this.renderStatusTitle(),
				dataIndex: "status",
				key: "status",
				render: this.renderStatusIcon,
				width: 50
			},
			{
				title: "Device Name",
				dataIndex: "name",
				key: "name",
				render: this.renderDeviceName,
				sorter: Utils.columnSorter("name"),
				width: 200,
				defaultSortOrder: "descend"
			},
			{
				title: "Group",
				dataIndex: "group",
				key: "group",
				sorter: Utils.columnSorter("group"),
				width: 125
			},
			{
				title: "Store",
				dataIndex: "store",
				key: "store",
				sorter: Utils.columnSorter("store"),
				width: 100
			},
			{
				title: "Model",
				dataIndex: "model",
				key: "model",
				sorter: Utils.columnSorter("model"),
				width: 100
			},
			{
				title: "Pusher Status",
				dataIndex: "pusher",
				key: "pusher",
				render: this.renderStatus("pusher"),
				sorter: Utils.columnSorter("pusher"),
				width: 100
			},
			{
				title: "Online Status",
				dataIndex: "online",
				key: "online",
				render: this.renderStatus("online"),
				sorter: Utils.columnSorter("online"),
				width: 100
			}
		];

		let height = 500;

		if (this.filters) {
			const { offsetTop, clientHeight } = this.filters;
			const bodyHeight = document.body.clientHeight;
			const bottomMargin = 160;
			height = bodyHeight - (offsetTop + clientHeight + bottomMargin);
		}

		return (
			<Table
				columns={ columns }
				dataSource={ this.formatDeviceData() }
				pagination={ false }
				style={ this.styles.table }
				scroll={{ y: height }}
			/>
		);
	}

	renderFilters() {
		const {
			column,
			columnGroup,
			failure,
			filter,
			filterInfo,
			filterNumber,
			leftBorder,
			selected,
			success
		} = this.styles;
		const selectedFilter = (type: DeploymentDeviceFilter) => type === this.state.activeFilter ? selected : null;
		const { missCount, succeedCount, total } = calculateDeviceCounts(this.props.deployment);

		return (
			<div style={ columnGroup } ref={ this.setFiltersRef }>
				<div
					onClick={ this.selectFilter("total") }
					style={{ ...column, ...filter, ...leftBorder, ...selectedFilter("total") }}>
					<div style={ filterNumber }>{ total }</div>
					<div style={ filterInfo }>Total Devices</div>
				</div>
				<div
					onClick={ this.selectFilter("success") }
					style={{ ...column, ...filter, ...selectedFilter("success") }}>
					<div style={{ ...filterNumber, ...success }}>{ succeedCount }</div>
					<div style={ filterInfo }>Successful Deployment</div>
				</div>
				<div
					onClick={ this.selectFilter("missed") }
					style={{ ...column, ...filter, ...selectedFilter("missed") }}>
					<div style={{ ...filterNumber, ...failure }}>{ missCount }</div>
					<div style={ filterInfo }>
						Missed Deployment
						<HelpPopover title="Missed Deployments" placement="left">
							<div>
								<p>While there are many reasons a device may not successfully receive a deployment,
									below are some reasons this could happen.</p>
								<ul>
									<li>The device lost connection to WiFi</li>
									<li>The WiFi password was changed</li>
									<li>The network connection speed is slow</li>
									<li>Too many devices on the network</li>
								</ul>
							</div>
						</HelpPopover>
					</div>
				</div>
			</div>
		);
	}

	renderHeader() {
		const { deployment } = this.props;
		const displayNote = moment().diff(deployment.startDate, "hours") <= 24;
		const { note, clipboard, header } = this.styles;
		const text = " Deployment Report"
		return (
			<React.Fragment>
				<div style={ header }>
					<h1 style={ clipboard }>
						<Icon iconWeight="regular" name="clipboard-check"/>
					</h1>
					<HeaderText>
						{ text }
					</HeaderText>
				</div>
				{ displayNote ? (
					<Alert
						style={ note }
						description="Complete statistics will not be available until 24 hours after the deployment."
						message=""
						type="warning"
					/>
				) : null }
			</React.Fragment>
		);
	}

	renderInfo() {
		const { column, columnGroup, infoDetail, infoPopup, infoTitle, link, scheduleDetails } = this.styles;
		const { createdBy, name, schedule } = this.props.deployment;

		return (
			<div style={ columnGroup }>
				<div style={ column }>
					<div style={ infoTitle }>Title</div>
					<div style={ infoDetail }><Truncate length={ 40 }>{ name }</Truncate></div>
				</div>
				{ this.renderDateRange() }
				<div style={ column }>
					<div style={ infoTitle }>Schedule</div>
					<Popover
						content={(
							<ul style={ scheduleDetails }>
								{ schedule.map(this.renderScheduleDetails) }
							</ul>
						)}
						placement="bottom"
						title={(
							<div style={ infoPopup }>
								Scheduled Playlists
							</div>
						)}
						trigger="click"
					>
						<div style={{ ...infoDetail, ...link }}>View Details ></div>
					</Popover>
				</div>
				<div style={ column }>
					<div style={ infoTitle }>Created By</div>
					<div style={ infoDetail }><Truncate length={ 26 }>{ createdBy && createdBy.name }</Truncate></div>
				</div>
			</div>
		);
	}

	renderDateRange() {
		const { column, dateRange, infoTitle, infoDetail } = this.styles;
		const { startDate } = this.props.deployment;

		return (
			<div style={ dateRange }>
				<div style={ column }>
					<div style={ infoTitle }>Deployment Start Date</div>
					<div style={ infoDetail }>{ Utils.getHumanReadableDate(startDate) }</div>
				</div>
				{ this.renderEndDate() }
			</div>
		);
	}

	renderEndDate() {
		const { type, endDate } = this.props.deployment;
		const { column, infoDetail, infoTitle, hyphen } = this.styles;

		if (type !== "event") {
			return null;
		}

		return (
			<React.Fragment>
				<div style={ hyphen }>-</div>
				<div style={ column }>
					<div style={ infoTitle }>Deployment End Date</div>
					<div style={ infoDetail }>{ Utils.getHumanReadableDate(endDate) }</div>
				</div>
			</React.Fragment>
		);
	}

	renderRedeploy() {
		if (areCancelled(this.props.deployment)) {
			return null;
		}

		return (
			<div style={ this.styles.redeploy }>
				<Button
					icon="redo"
					onClick={ this.redeploy }
					type="primary"
				>
					Re-Deploy
				</Button>
			</div>
		);
	}

	renderScheduleDetails(schedule: IDeploymentSchedule, index: number) {
		const { scheduleItem, scheduleItemInner, scheduleItemInnerRight } = this.styles;
		const { duration, name, startTime, uuid } = schedule;
		const time = Utils.getTimeStringDuration(duration, startTime);

		return (
			<li style={ scheduleItem } key={ `${name}_${index}_${uuid}` }>
				<span style={ scheduleItemInner }>{ <Truncate length={ 40 }>{ name }</Truncate> }</span>
				<span style={ scheduleItemInnerRight }>{ time }</span>
			</li>
		);
	}

	renderStatus(type: string) {
		return (data: boolean, record: FormattedDeviceData) => {
			const word = data ? "Online" : "Offline";
			const backgroundColor = data ? primaryGreen : red;

			return (
				<div key={ `status_${type}_${record.key}` }>
					<span style={{ ...this.styles.status, backgroundColor }}>&nbsp;</span>
					<span>{ word }</span>
				</div>
			);
		};
	}

	renderStatusIcon(data: boolean, record: FormattedDeviceData) {
		const icon = data ? "check-circle" : "exclamation-circle";
		const color = data ? primaryGreen : red;

		return (
			<Icon key={ `statusIcon_${record.key}` } iconWeight="regular" name={ icon } style={{ color }} />
		);
	}

	setFiltersRef(element: HTMLDivElement) {
		this.filters = element;
	}

	closeReport() {
		this.props.goToDeployPage();
	}

	formatDeviceData(): FormattedDeviceData[] {
		const { filteredDevices } = this.state;
		const { deployment } = this.props;
		const { deviceStatus } = deployment;

		const filtered = filteredDevices && filteredDevices.length && deviceStatus ? filteredDevices.map((key) => {
			const info = deviceStatus.find((d) => d.uuid === key);
			const { group, model, name, online, pusher, success, store } = info as DeploymentDeviceStatus;
			const groupName = group || "-";
			const storeName = store || "-";

			return {
				group: groupName,
				key,
				model: model,
				store: storeName,
				name: name,
				online: online,
				pusher: pusher,
				status: success,
				uuid: key
			};
		}) : [];

		return filtered;
	}

	getFilteredDevices(filter: DeploymentDeviceFilter) {
		const { deployment: { deviceStatus }, deviceUuids } = this.props;

		if (filter === "total") {
			return deviceUuids;
		}

		const value = filter === "success";

		return deviceStatus.filter((s) => s.success === value).map((s) => s.uuid);
	}

	redeploy(event: React.SyntheticEvent<HTMLElement>) {
		event.preventDefault();

		Notifications.confirm(
			`Redeploy ${name}`,
			`Are you sure you want to redeploy ${name} to missed devices?`,
			"Redeploy",
			"Cancel",
			() => {
				this.props.redeploy(this.props.deployment.uuid);

				Notifications.success(
					`Deployment ${name} has been redeployed to missed devices.`
				);
			},
			() => null
		);
	}

	selectDevice(uuid: string) {
		return (event) => {
			event.preventDefault();

			this.props.selectDevice(uuid);
		};
	}

	selectFilter(activeFilter: DeploymentDeviceFilter) {
		return (event) => {
			if (event) {
				event.preventDefault();
			}

			this.setState(() => ({
				activeFilter,
				filteredDevices: this.getFilteredDevices(activeFilter)
			}));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentReport);

import * as update from "immutability-helper";
import Radium from "radium";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, ISlideshowComponent } from "@connect/Interfaces";
import { AdColors } from "Components/Ads/Constants";
import Slideshow from "Components/Ads/Slideshow";
import {
	AdComponentCard,
	IAdComponentCardProps,
	IAdComponentCardState,
	SuperComponentCard,
	SuperComponentClass
} from "Components/Ads/SuperComponentCard";
import { setSlideshowCurrentSlide } from "Data/Actions/UI/AdBuilder";

interface SlideshowComponentCardProps extends IAdComponentCardProps<ISlideshowComponent> {
	setCurrentSlide?: (index: number) => void;
}

interface SlideshowComponentCardState extends IAdComponentCardState {}

@Radium
export class SlideshowComponentCard extends AdComponentCard<SlideshowComponentCardProps, SlideshowComponentCardState> {
	constructor(props: SlideshowComponentCardProps) {
		super(props);

		this.icon = "exchange";
		this.name = "Slideshow";

		this.styles = update(this.styles, {
			card: {
				backgroundColor: { $set: AdColors.slideshow.background },
				backgroundImage: { $set: "" }
			}
		});

		this.renderContainer = this.renderContainer.bind(this);
		this.setSlideshowCurrentSlide = this.setSlideshowCurrentSlide.bind(this);
	}

	icon: string;
	name: string;
	styles: {
		baseButton: CustomCSS;
		bottomResizeHandle: CustomCSS;
		card: CustomCSS;
		container: CustomCSS;
		deleteButton: CustomCSS;
		eraseButton: CustomCSS;
		heightAdjuster: CustomCSS;
		livePreview: CustomCSS;
		livePreviewName: CustomCSS;
		promptIcon: CustomCSS;
		promptText: CustomCSS;
		resolutionOverlay: CustomCSS;
		snapButton: CustomCSS;
		upload: CustomCSS;
	};

	render() {
		if (!this.shouldShowLivePreview()) {
			return this.renderContainer(<div />);
		}

		const { component, media } = this.props;
		const { pxHeight } = this.state;
		const { animation, direction, durationPerSlide, transitionLength } = component;

		return this.renderContainer(
			<Slideshow
				animation={ animation }
				component={ component }
				direction={ direction }
				durationPerSlide={ durationPerSlide }
				height={ pxHeight }
				key={ this.getKey(`slideshowInternal_${media.length}`) }
				onChangeEnd={ this.setSlideshowCurrentSlide }
				transitionLength={ transitionLength }
				width="100%"
			/>
		);
	}

	getKey(suffix: string) {
		const key = super.getKey(suffix);
		const { media } = this.props;
		const uuids = media.length ? media.map(({ uuid }) => uuid).reduce((ids, id) => `${ids}_${id}`, "") : "";

		return `${uuids}_${key}`;
	}

	setSlideshowCurrentSlide(current: number) {
		const { setCurrentSlide } = this.props;

		if (setCurrentSlide) {
			setCurrentSlide(current);
		}
	}

	// ensure that we show the slideshow icon if we don't have media for this component
	shouldShowLivePreview() {
		const { livePreview, media } = this.props;

		return !!(livePreview && media && media.length);
	}
}

const {
	Draggable,
	mapStateToProps,
	mapDispatchToProps
} = SuperComponentCard;

const slideshowMapDispatch = (dispatch, ownProps) => {
	return Object.assign({}, mapDispatchToProps(dispatch, ownProps), {
		setCurrentSlide: (index: number) => dispatch(setSlideshowCurrentSlide(index))
	});
};

export const DndSlideshowComponentCard: SuperComponentClass<SlideshowComponentCardProps> = Draggable(
	connect(mapStateToProps, slideshowMapDispatch)(SlideshowComponentCard)
);

export default DndSlideshowComponentCard;

import { Action, LastNotificationDispatch, NameUuid, PresenceUserDispatch,
	PresenceChannelDispatch } from "@connect/Interfaces";
import { createReducer } from "Data/Utils";
import { PusherState } from "Data/Objects/AppState";
import * as update from "immutability-helper";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";

const {
	SET_LAST_NOTIFICATION,
	USER_JOINED_CHANNEL,
	USER_LEFT_CHANNEL,
	RESET_CHANNEL
} = ACTION_TYPES.Pusher;

export function setLastNotification(state: PusherState, action: Action<LastNotificationDispatch>) {
	const { channel, uuid } = action.args;
	return update(state, {
		lastNotification: {
			[channel]: {
				$set: uuid
			}
		}
	})
}

export function userJoinedChannel(state: PusherState, action: Action<PresenceUserDispatch>) {
	const { channel, user } = action.args;
	const channelExists = state && state.presenceChannels && state.presenceChannels[channel];

	if (!channelExists) {
		return update(state, {
			presenceChannels: {
				[channel]: {
					$set: [ user ]
				}
			}
		})
	}

	const userExists = channelExists && state.presenceChannels[channel].find((u: NameUuid) => {
		return u.uuid === user.uuid;
	});

	if (userExists) {
		return state;
	}

	return update(state, {
		presenceChannels: {
			[channel]: {
				$push: [ user ]
			}
		}
	})
}

export function userLeftChannel(state: PusherState, action: Action<PresenceUserDispatch>) {
	const { channel, user } = action.args;
	const channelExists = state && state.presenceChannels && state.presenceChannels[channel];
	const userExists = channelExists && state.presenceChannels[channel].find((u: NameUuid) => {
		return u.uuid === user.uuid;
	});

	if (!userExists) {
		return state;
	}

	return update(state, {
		presenceChannels: {
			[channel]: {
				$set: state.presenceChannels[channel].filter((u: NameUuid) => {
					return user.uuid !== u.uuid;
				})
			}
		}
	})
}

export function resetChannel(state: PusherState, action: Action<PresenceChannelDispatch>) {
	const { channel } = action.args;
	const channelExists = state && state.presenceChannels && state.presenceChannels[channel];
	if (!channelExists) {
		return state;
	}
	return update(state, {
		presenceChannels: {
			[channel]: {
				$set: []
			}
		}
	})
}

const reducers = {
	[SET_LAST_NOTIFICATION.type]: setLastNotification,
	[USER_JOINED_CHANNEL.type]: userJoinedChannel,
	[USER_LEFT_CHANNEL.type]: userLeftChannel,
	[RESET_CHANNEL.type]: resetChannel
};

export default createReducer(reducers, PusherState);
import Radium from "radium";
import * as React from "react";
import { ConnectDragPreview } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { CustomCSS, IDeviceGroup } from "@connect/Interfaces";
import { Icon, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import {
	DnDGridViewCard, GridViewCard, GridViewDndComponent, IGridViewCardProps
} from "Components/Global/GridViewCard";

interface DeviceGroupCardProps extends IGridViewCardProps<IDeviceGroup> {
	bulkSelectActive?: boolean;
	cardObject: IDeviceGroup;
	connectDragPreview?: ConnectDragPreview;
	isDragging?: boolean;
	isDroppable?: boolean;
	isFocused?: boolean;
	noAutoScroll?: boolean;
	onDrop?: (dropTargetObject: IDeviceGroup) => void;
	selectedDevicesCount: number;
	selectedGroupCount: number;
}

@Radium
class DeviceGroupCard extends GridViewCard<DeviceGroupCardProps> {
	constructor(props: DeviceGroupCardProps) {
		super(props);

		this.styles = Object.assign(this.styles, {
			base: {
				userSelect: "none",
				display: "flex",
				alignItems: "center",
				borderRadius: 2,
				transition: "all 500ms ease",
				":hover": {
					cursor: "pointer",
					boxShadow: "#f1f1f1"
				},
				height: 50,
				boxShadow: "2px 2px 8px #ccc",
				width: 200
			},
			name: {
				fontSize: 12,
				fontWeight: "bold",
				letterSpacing: 1,
				marginLeft: 6
			},
			model: {
				marginTop: -2
			},
			deviceGroup: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				marginBottom: 6
			},
			icon: {
				color: Colors.lightGray,
				marginLeft: 18
			}
		});
	}

	styles: {
		base: CustomCSS;
		name: CustomCSS;
		model: CustomCSS;
		deviceGroup: CustomCSS;
		icon: CustomCSS;
	}

	componentDidMount() {
		const { connectDragPreview } = this.props;

		if (connectDragPreview) {
			connectDragPreview(getEmptyImage(), {
				captureDraggingState: true
			});
		}
	}

	render() {
		return this.renderContainer(
			<div
				key={ this.props.cardObject.uuid }
				style={ this.getCardStyle() }
			>
				{ this.renderIcon() }
				{ this.renderName() }
			</div>
		);
	}

	getCardStyle() {
		return {
			...this.styles.base,
			opacity: this.props.isDragging ? 0.5 : 1,
			background: this.getBackgroundColor()
		}
	}

	renderIcon() {
		return (
			<Icon
				name="folder"
				size="small"
				style={this.styles.icon}
			/>
		)
	}

	renderName() {
		return (
			<div style={this.styles.name}>
				<Truncate>{ this.props.cardObject.name }</Truncate>
			</div>
		);
	}

	getBackgroundColor() {
		const { isSelected, bulkSelectActive, isFocused } = this.props;

		if (bulkSelectActive && isSelected && !isFocused) {
			return "#d7e6fc";
		}

		if (isFocused && !bulkSelectActive) {
			return "#f1f1f1";
		}

		return "transparent";
	}
}

export const DraggableDeviceCard: GridViewDndComponent<any> =
	DnDGridViewCard.GridViewCardDraggable(
		DnDGridViewCard.GridViewCardDroppable(DeviceGroupCard));

export default DraggableDeviceCard;
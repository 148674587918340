import { Company } from "../../Data/Objects/Company";

interface SupportedFileType {
	fileType: string;
	typeCheck: (file: File) => boolean;
	fileChecks: ((file: File) => boolean)[];
	handleFile: (file: File) => {};
}

export default abstract class FileHandler {
	allowedFileTypes: string[];
	restrictedFileTypes: string[];
	company: Company;
	supportedFileTypes: SupportedFileType[];
	abstract handleFiles(files: File[]);
}
import * as React from "react";
import { DragLayer } from "react-dnd";

import { CustomCSS } from "@connect/Interfaces";
import { AdColors } from "Components/Ads/Constants";

interface ISlideshowDragPreviewProps {
	sourceClientOffset?: {
		x: number;
		y: number;
	},
	isDragging?: boolean;
	item?: any;
	containerWidth?: number;
	containerHeight?: number;
}

export class SlideshowDragPreview extends React.PureComponent<ISlideshowDragPreviewProps> {
	constructor(props: ISlideshowDragPreviewProps) {
		super(props);
		const { item, containerWidth } = props;

		this.styles = {
			base: {
				// actual drag preview
				alignItems: "center",
				background: item && item.component.background,
				border: "1px solid black",
				display: "flex",
				position: "relative",
				left: 0,
				top: 0,
				transition: "all 500ms ease",
				userSelect: "none",
				width: "100%",
				opacity: 0.8
			},
			layerStyles: {
				// the old element in the DOM that was originally the drag start
				left: 0,
				position: "fixed",
				pointerEvents: "none",
				top: 0,
				width: containerWidth,
				zIndex: 100
			}
		};
	}

	styles: {
		base: CustomCSS;
		layerStyles: CustomCSS;
	};

	render() {
		const { isDragging, item } = this.props;

		if (!isDragging || !item || !item.component || item.component.type !== "slideshow") {
			return null;
		}

		return (
			<div style={ this.styles.layerStyles }>
				<div style={ this.getPreviewContainerStyle() }>
					<div style={ this.getDragPreviewStyle() } />
				</div>
			</div>
		);
	}

	getDragPreviewStyle() {
		const { containerHeight, item } = this.props;
		const { height, foreground } = item.component;

		return {
			...this.styles.base,
			height: item && containerHeight && containerHeight * (height.value / 100),
			background: AdColors.slideshow.background,
			color: foreground
		}
	}

	getPreviewContainerStyle(): CustomCSS {
		const { sourceClientOffset } = this.props;

		if (!sourceClientOffset) {
			return {
				display: "none"
			}
		}

		const { x, y } = sourceClientOffset;
		const transform = `translate(${ x }px, ${ y }px)`;

		return {
			transform,
			WebkitTransform: transform
		};
	}
}

export default DragLayer<ISlideshowDragPreviewProps>((monitor) => ({
	sourceClientOffset: monitor.getSourceClientOffset(),
	isDragging: monitor.isDragging(),
	item: monitor.getItem()
}))(SlideshowDragPreview);

import { Steps } from "antd";
import * as update from "immutability-helper";
import * as qrious from "qrious";
import * as React from "react";
import { connect } from "react-redux";

import { notifyBugSnag } from "@connect/BugSnag";
import { CustomCSS, IUser, ICompany } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import AuthApi from "Api/Auth";
import AdminRecoveryCodes from "Components/Admin/AdminRecoveryCodes";
import { QriousConfig } from "Components/Devices/Constants";
import { Button, Header, Icon, Input, Loader } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ErrorDescription from "Components/Global/ErrorDescription";
import PasswordPrompt from "Components/Global/PasswordPrompt";
import { changePassword, forgotPassword, toggleTwoFactorAuth } from "Data/Actions/UserAsync";
import { getCurrentUser } from "Data/Selectors/User";
import { getActiveCompany } from "Data/Selectors/Company";

const { gray, primaryGreen } = Colors;

const mapStateToProps = (state) => ({
	user: getCurrentUser(state),
	company: getActiveCompany(state)
});

const mapDispatchToProps = (dispatch) => ({
	resetPasswordByEmail: (email: string) => dispatch(forgotPassword(email)),
	setPassword: (oldPassword, newPassword, newPasswordConfirmation) =>
		dispatch(changePassword(oldPassword, newPassword, newPasswordConfirmation)),
	toggleTwoFactorAuth: () => dispatch(toggleTwoFactorAuth())
});

interface AntStep {
	backAction?: () => void;
	content: () => string | JSX.Element;
	nextAction?: () => void;
	title: string;
}

interface ISecurityPageState {
	authCode: string;
	changePassword: {
		oldPassword: string;
		newPassword: string;
		newPasswordConfirmation: string;
	}
	qrCode: string;
	showConfirmPassword: boolean;
	showSteps: boolean;
	step: number;
}

interface ISecurityPageProps {
	resetPasswordByEmail: (email: string) => void;
	setPassword: (oldPassword: string, newPassword: string, newPasswordConfirmation: string) => Promise<void>;
	toggleTwoFactorAuth: () => void;
	user: IUser;
	company: ICompany;
}

class SecurityPage extends React.Component<ISecurityPageProps, ISecurityPageState> {
	constructor(props: ISecurityPageProps) {
		super(props);

		this.state = {
			authCode: "",
			changePassword: {
				oldPassword: "",
				newPassword: "",
				newPasswordConfirmation: ""
			},
			qrCode: "",
			showConfirmPassword: false,
			showSteps: false,
			step: 0
		};

		this.styles = {
			changePasswordStyle: {
				marginBottom: 10,
				display: "block"
			},
			check: {
				color: primaryGreen
			},
			controlButton: {
				width: 72,
				margin: 8
			},
			controls: {
				textAlign: "right"
			},
			description: {
				color: gray,
				marginBottom: 40
			},
			enableButton: {
				marginBottom: 40
			},
			grid: {
				display: "grid",
				width: "100%",
				gridTemplateColumns: "50% 50%"
			},
			gridChild: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "0px 18px 0px 18px"
			},
			headerStyle: {
				marginLeft: 12,
				paddingBottom: 14
			},
			info: {
				paddingLeft: 26
			},
			input: {
				marginTop: 24
			},
			passwordReqs: {
				color: gray,
				width: 250,
				fontSize: 10
			},
			qrCode: {
				display: "block",
				marginTop: 24,
				width: this.qrSize,
				height: this.qrSize
			},
			requirements: {
				position: "relative",
				left: -20,
				marginTop: 5,
				listStyleType: "none"
			},
			rowLabel: {
				fontWeight: "bold",
				textAlign: "left",
				paddingRight: 24
			},
			section: {
				marginBottom: 20
			},
			table: {
				marginTop: 16,
				borderSpacing: 8,
				textAlign: "center"
			},
			tableRow: {
				background: "transparent"
			}
		}

		this.clickToggleShowPassword = this.clickToggleShowPassword.bind(this);
		this.disableTwoFactor = this.disableTwoFactor.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.nextStep = this.nextStep.bind(this);
		this.passwordHasError = this.passwordHasError.bind(this);
		this.prevStep = this.prevStep.bind(this);
		this.renderStepContent = this.renderStepContent.bind(this);
		this.renderStepOne = this.renderStepOne.bind(this);
		this.renderStepThree = this.renderStepThree.bind(this);
		this.renderStepTwo = this.renderStepTwo.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.setInputRef = this.setInputRef.bind(this);
		this.setNewPassword = this.setNewPassword.bind(this);
		this.setNewPasswordConfirmation = this.setNewPasswordConfirmation.bind(this);
		this.setOldPassword = this.setOldPassword.bind(this);
		this.submit = this.submit.bind(this);
		this.toggleShowPassword = this.toggleShowPassword.bind(this);
		this.toggleShowTwoFactor = this.toggleShowTwoFactor.bind(this);
		this.updateChangePasswordInfo = this.updateChangePasswordInfo.bind(this);
		this.updatePassword = this.updatePassword.bind(this);

		this.qrSize = 300;
		this.api = new AuthApi();
		this.steps = [
			{
				title: "Get an Authenticator App",
				content: this.renderStepOne,
				nextAction: this.nextStep
			},
			{
				title: "Scan QR Code",
				content: this.renderStepTwo,
				nextAction: this.nextStep,
				backAction: this.prevStep
			},
			{
				title: "Verify Your First Token",
				content: this.renderStepThree,
				nextAction: this.submit,
				backAction: this.prevStep
			}
		];
	}

	styles: CustomCSS;

	steps: AntStep[];
	qrSize: number;
	api: AuthApi;
	changePasswordConfirmPasswordInput: Input;
	changePasswordNewPasswordInput: Input;
	changePasswordOldPasswordInput: Input;

	render() {
		return (
			<div style={this.styles.grid}>
				{ this.renderPassword() }
				{ this.renderTwoFactor() }
			</div>
		);
	}

	renderAuthenticatorTable() {
		const { table, tableRow, rowLabel} = this.styles;

		const compatibility = {
			"Google Authenticator": {
				options: {
					iOS: true,
					Android: true,
					Mac: false,
					Windows: false
				},
				url: "https://support.google.com/accounts/answer/1066447"
			},
			"Microsoft Authenticator": {
				options: {
					iOS: true,
					Android: true,
					Mac: false,
					Windows: false
				},
				url: "https://www.microsoft.com/en-us/account/authenticator"
			},
			"Authy": {
				options: {
					iOS: true,
					Android: true,
					Mac: false,
					Windows: false
				},
				url: "https://authy.com/"
			},
			"1Password": {
				options: {
					iOS: true,
					Android: true,
					Mac: true,
					Windows: true
				},
				url: "https://1password.com/"
			}
		}

		const rowNames = Object.keys(compatibility);
		const columnNames = Object.keys(Object.values(compatibility)[0].options);

		const columnLabels = [ "" ]
			.concat(columnNames)
			.map((item, index) => (
				<th key={ `th-${item}` }>{item}</th>
			));

		const getRowValues = (valueArray: boolean[]) => valueArray.map((value, index) =>
			// eslint-disable-next-line
			<td key={ `td-${index}` }>{ value && "•" }</td>
		);

		const rows = rowNames
			.map((name, index) => (
				<tr key={ `row-${name}` } style={ tableRow }>
					<td style={ rowLabel }>
						<a href={compatibility[name].url} target="_blank" rel="noopener noreferrer">{ name }</a>
					</td>
					{ getRowValues(Object.values(compatibility[name].options)) }
				</tr>
			));

		return (
			<table style={ table }>
				<tbody>
					<tr>
						{ columnLabels }
					</tr>
					{ rows }
				</tbody>
			</table>
		);
	}

	renderBody() {
		const { showSteps, showConfirmPassword } = this.state;
		const { twoFactorEnabled } = this.props.user;

		if (twoFactorEnabled && !showConfirmPassword) {
			return this.renderDisableButton();
		}

		if (!twoFactorEnabled && !showSteps && !showConfirmPassword) {
			return this.renderEnableButton();
		}

		return (
			<div style={ this.styles.section }>
				{ this.renderPasswordInput() }
				{ this.renderSteps() }
				{ this.renderStepContent() }
				{ this.renderControls() }
			</div>
		);
	}

	renderControls() {
		if (this.state.showConfirmPassword) {
			return null;
		}

		const { steps } = this;
		const { step } = this.state;
		const { nextAction, backAction } = steps[step];
		const { controls, controlButton } = this.styles;

		return (
			<div style={ controls }>
				<Button
					style={ controlButton }
					disabled={ !backAction }
					onClick={ backAction }>
					Back
				</Button>
				<Button
					type="primary"
					style={ controlButton }
					disabled={ !nextAction }
					onClick={ nextAction }>
					{ step === steps.length - 1 ? "Submit" : "Next" }
				</Button>
			</div>
		);
	}

	renderDescription() {
		const { description, check } = this.styles;

		if (!this.props.user.twoFactorEnabled) {
			return (
				<div style={ description }>
					Two-Factor Authentication is a security process where the user provides two
					forms of ID to log in to Clinton Connect. This helps to keep your account
					safe. Two-Factor Authentication is easy to set up and takes just a minute.
				</div>
			);
		}

		return (
			<div style={ description }>
				<Icon name="check-circle" style={ check } /> Two-Factor Authentication is enabled for your account.
			</div>
		);
	}

	renderDisableButton() {
		return (
			<Button
				type="danger"
				style={ this.styles.enableButton }
				onClick={ this.clickToggleShowPassword }>
				Disable Two-Factor Authentication
			</Button>
		);
	}

	renderEnableButton() {
		return (
			<Button
				type="primary"
				style={ this.styles.enableButton }
				onClick={ this.toggleShowTwoFactor }>
				Enable Two-Factor Authentication
			</Button>
		);
	}

	renderPassword() {
		const { changePasswordStyle, gridChild, headerStyle, info, label } = this.styles;
		const { newPassword, newPasswordConfirmation, oldPassword } = this.state.changePassword;

		return (
			<div style={ gridChild }>
				<Header size={ 3 } style={ headerStyle }>Password</Header>
				<div style={ info }>
					<span style={ label }>Old Password:</span>
					<Input
						id="changePasswordOldPassword"
						ref={this.setInputRef("changePasswordOldPasswordInput")}
						type="password"
						updateCallback={ this.setOldPassword }
						style={ changePasswordStyle }
						value={ oldPassword }
					/>
					<span style={ label }>New Password:</span>
					<Input
						autoComplete="notARealPassword" // fake id here because "off" doesnt work with Chrome
						id="changePasswordNewPassword"
						ref={this.setInputRef("changePasswordNewPasswordInput")}
						type="password"
						updateCallback={ this.setNewPassword }
						style={ changePasswordStyle }
						value={ newPassword }
					/>
					<span style={ label }>Confirm New Password:</span>
					<Input
						autoComplete="notARealPassword" // fake id here because "off" doesnt work with Chrome
						id="changePasswordConfirmPassword"
						ref={this.setInputRef("changePasswordConfirmPasswordInput")}
						type="password"
						updateCallback={ this.setNewPasswordConfirmation }
						style={ changePasswordStyle }
						value={ newPasswordConfirmation }
					/>
					{ this.renderRequirements() }
					<div>
						Can't remember your current password? <a onClick={ this.resetPassword }>Reset your password by email></a>
					</div>
				</div>
			</div>
		);
	}

	renderRequirements() {
		const { passwordReqs, section, requirements } = this.styles;
		const { newPassword, newPasswordConfirmation } = this.state.changePassword;
		const { company: { passwordRequirements } } = this.props;
		const { minChars, minLowerCase, minUpperCase, minNumbers, minSpecialChars } = passwordRequirements;

		const hasMinChars = Utils.isValidMinChars(newPassword, minChars);
		const hasMinLowerCase = Utils.isValidMinLowerCase(newPassword, minLowerCase);
		const hasMinUpperCase = Utils.isValidMinUpperCase(newPassword, minUpperCase);
		const hasMinNumbers = Utils.isValidMinNumbers(newPassword, minNumbers);
		const hasMinSpecialChars = Utils.isValidMinSpecialChars(newPassword, minSpecialChars);

		const passHasError = !hasMinChars || !hasMinLowerCase || !hasMinUpperCase || !hasMinNumbers
			|| !hasMinSpecialChars;
		const passwordConfirmationDoesntMatch = newPassword !== newPasswordConfirmation;
		const passwordsDontMatch = passwordConfirmationDoesntMatch || newPassword.length < 1;
		const passHasMatchIcon = passwordsDontMatch ? "circle" : "check-circle";
		const disableUpdatePassword = passwordConfirmationDoesntMatch || passHasError;

		return (
			<React.Fragment>
				<div style={{ ...section, ...passwordReqs }}>
					<span>
						Password must:
					</span>
					<ul style={ requirements }>
						{ this.renderRequirement(minChars, hasMinChars, "be at least MIN character(s) long") }
						{ this.renderRequirement(minLowerCase, hasMinLowerCase, "contain at least MIN lowercase character(s)") }
						{ this.renderRequirement(minUpperCase, hasMinUpperCase, "contain at least MIN uppercase character(s)") }
						{ this.renderRequirement(minNumbers, hasMinNumbers, "contain at least MIN number(s)") }
						{ this.renderRequirement(minSpecialChars, hasMinSpecialChars, "contain at least MIN special character(s)") }
						<li>
							<Icon name={ passHasMatchIcon } iconWeight="regular"/>
							{ " Passwords must match" }
						</li>
					</ul>
				</div>
				<div style={ section }>
					<Button
						type="primary"
						disabled={ disableUpdatePassword }
						onClick={ this.updatePassword }>
						Update Password
					</Button>
				</div>
			</React.Fragment>
		);
	}

	renderRequirement(minimumNumber: number, meetsRequirement: boolean, template: string) {
		const icon = meetsRequirement ? "check-circle" : "circle";

		if (!minimumNumber || minimumNumber < 0) {
			return null;
		}

		return (
			<li>
				<Icon
					name={ icon }
					iconWeight="regular"
				/>
				{ template.replace("MIN", minimumNumber.toString()) }
			</li>
		)
	}

	renderPasswordInput() {
		if (!this.state.showConfirmPassword) {
			return null;
		}

		return <PasswordPrompt onContinue={this.toggleShowPassword} />
	}

	renderQr() {
		const { qrCode } = this.state;

		if (!qrCode) {
			return <Loader />;
		}

		return (
			<img
				src={ qrCode }
				style={ this.styles.qrCode } />
		);
	}

	renderStepContent() {
		if (this.state.showConfirmPassword) {
			return null;
		}

		return this.steps[this.state.step].content();
	}

	renderStepOne() {
		return (
			<div style={ this.styles.description }>
				An authenticator app is required to enable Two-Factor Authentication. Clinton Connect supports
				any of the following authenticators. You'll need to install and launch one before proceeding.
				{ this.renderAuthenticatorTable() }
			</div>
		);
	}

	renderSteps() {
		if (this.state.showConfirmPassword) {
			return null;
		}

		const steps = this.steps.map((item, index) => (
			<Steps.Step
				key={ `step-${item.title}-${item.content.length}` }
				title={ item.title } />
		));

		return (
			<Steps direction="vertical" current={ this.state.step }>
				{ steps }
			</Steps>
		);
	}

	renderStepThree() {
		const { description, input } = this.styles;

		return (
			<div style={ description }>
				Your authenticator app should now provide you with a token for Clinton Connect.
				Please type this token below to complete setup.
				<Input
					id="verify"
					hideMaxLength
					style={ input }
					saveCallback={this.handleInput}
					value={ "" } />
			</div>
		);
	}

	renderStepTwo() {
		const { description } = this.styles;
		const { qrCode } = this.state;

		return (
			<div style={ description } key={ qrCode }>
				Scan this QR code with your authenticator app to link it with your account.
				{ this.renderQr() }
			</div>
		);
	}

	renderTwoFactor() {
		const { gridChild, headerStyle, info } = this.styles;

		return (
			<div style={ gridChild }>
				<Header size={ 3 } style={ headerStyle }>Two-Factor Authentication</Header>
				<div style={ info }>
					{ this.renderDescription() }
					{ this.renderBody() }
					<AdminRecoveryCodes />
				</div>
			</div>
		);
	}

	clickToggleShowPassword() {
		this.toggleShowPassword();
	}

	disableTwoFactor(password: string) {
		this.api.disableTwoFactorAuth(password)
			.then((response) => {
				this.props.toggleTwoFactorAuth();
				this.setState(prevState => ({
					showSteps: false,
					step: 0,
					showConfirmPassword: !prevState.showConfirmPassword
				}));
			}, (error) => Notifications.error("Could not disable Two Factor Authentication",
				<ErrorDescription error={ error } />))
			.catch((error) => notifyBugSnag(new Error(error)));
	}

	generateQrCode(url: string) {
		let qrConfig = new QriousConfig();
		qrConfig.size = this.qrSize;
		qrConfig.value = url;
		const qr = new qrious(qrConfig);
		return qr.toDataURL("image/jpeg");
	}

	getTwoFactorCode(password: string) {
		const api = new AuthApi();
		return api.getTwoFactorKey(password)
			.then(result => {
				this.setState(prevState => ({
					qrCode: this.generateQrCode(result.qrCodeUrl),
					showSteps: true,
					showConfirmPassword: !prevState.showConfirmPassword
				}));
			}, (error) => Notifications.error("Could not get Two Factor Authentication code",
				<ErrorDescription error={ error } />))
			.catch((error) => notifyBugSnag(new Error(error)));
	}

	handleInput(value: string) {
		this.setState({ authCode: value });
	}

	nextStep() {
		this.setState(prevState => ({ step: prevState.step + 1 }));
	}

	passwordHasError(pass: string) {
		return !Utils.isValidPassword(pass);
	}

	prevStep() {
		this.setState(prevState => ({ step: prevState.step - 1 }));
	}

	resetPassword() {
		const { resetPasswordByEmail, user } = this.props;

		resetPasswordByEmail(user.email);
	}

	setInputRef(name: string) {
		return (component: Input) => {
			this[name] = component;
		};
	}

	setNewPassword(value: string) {
		this.updateChangePasswordInfo(value, "newPassword");
	}

	setNewPasswordConfirmation(value: string) {
		this.updateChangePasswordInfo(value, "newPasswordConfirmation");
	}

	setOldPassword(value: string) {
		this.updateChangePasswordInfo(value, "oldPassword");
	}

	submit() {
		this.api.verifyTwoFactorAuth(this.state.authCode)
			.then((response) => this.props.toggleTwoFactorAuth(),
				(error) => Notifications.error("Could not verify token", <ErrorDescription error={ error } />))
			.catch((error) => notifyBugSnag(new Error(error)));
	}

	toggleShowPassword(password?: string) {
		if (this.state.showConfirmPassword && password && !this.props.user.twoFactorEnabled) {
			return this.getTwoFactorCode(password);
		} else if (this.state.showConfirmPassword && password && this.props.user.twoFactorEnabled) {
			return this.disableTwoFactor(password);
		}

		return this.setState(prevState => ({ showConfirmPassword: !prevState.showConfirmPassword }));
	}

	toggleShowTwoFactor() {
		if (this.state.showConfirmPassword) {
			this.getTwoFactorCode("")
				.then((result) => {
					this.setState(prevState => ({ showSteps: !prevState.showSteps }));
				}, (error) => Notifications.error("Could not display Two Factor information."))
				.catch((error) => notifyBugSnag(new Error(error)));
		}

		this.toggleShowPassword("");
	}

	updateChangePasswordInfo(value: string, key: string) {
		this.setState((prevState) => update(prevState, {
			changePassword: { [key]: { $set: value } }
		}));
	}

	updatePassword() {
		const { oldPassword, newPassword, newPasswordConfirmation } = this.state.changePassword;
		const resetPasswordFields = () => {
			this.setState((prevState) => ({
				changePassword: {
					oldPassword: "",
					newPassword: "",
					newPasswordConfirmation: ""
				}
			}), () => {
				this.changePasswordConfirmPasswordInput.updateInput("");
				this.changePasswordNewPasswordInput.updateInput("");
				this.changePasswordOldPasswordInput.updateInput("");
			});
		};

		this.props.setPassword(oldPassword, newPassword, newPasswordConfirmation)
			.then(resetPasswordFields)
			.catch(resetPasswordFields);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityPage);

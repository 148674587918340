import { ICompany, IUser, Newsletter } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";

const {
	LOGIN_USER,
	LOGOUT_USER,
	SET_TOKEN,
	SET_USER,
	VERIFY_USER,
	UPDATE_EMAIL_PREFERENCES,
	UPDATE_WIDGETS,
	TOGGLE_TWO_FACTOR,
	UPDATE_PERMISSIONS
} = ACTION_TYPES.User;

/**
 * Simple Actions
 */

function loginUser(user: IUser, token: string, company: ICompany) {
	return new DispatchableAction(LOGIN_USER, { user, token, company });
}

function logoutUser() {
	return new DispatchableAction(LOGOUT_USER, null);
}

function setToken(token: string) {
	return new DispatchableAction(SET_TOKEN, { token });
}

function setUser(user: IUser) {
	return new DispatchableAction(SET_USER, { user });
}

function verifyUser() {
	return new DispatchableAction(VERIFY_USER, true);
}

function updateWidgets(widgets: any) {
	return new DispatchableAction(UPDATE_WIDGETS, widgets);
}

function updateEmailPreferences(newsletters: Newsletter[]) {
	return new DispatchableAction(UPDATE_EMAIL_PREFERENCES, { newsletters });
}

function toggleTwoFactor(on: boolean) {
	return new DispatchableAction(TOGGLE_TWO_FACTOR, on);
}

function updateUserPermissions(permissions: string[]) {
	return new DispatchableAction(UPDATE_PERMISSIONS, { permissions });
}

export {
	loginUser,
	logoutUser,
	setToken,
	setUser,
	toggleTwoFactor,
	updateEmailPreferences,
	updateUserPermissions,
	updateWidgets,
	verifyUser
};

import { CustomPermissions, ErrorResultV2, IPermissionsApi } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class PermissionsApi extends ApiV2 implements IPermissionsApi {
	getPermissionSettings() {
		return this.GET("permissions") as Promise<CustomPermissions>;
	}

	updatePermissionSettings(permissions: CustomPermissions) {
		return this.PUT("permissions", permissions) as Promise<ErrorResultV2>;
	}
}

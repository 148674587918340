import * as moment from "moment";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { Activity, PaginatedDataResult } from "@connect/Interfaces";
import ActivityApi from "Api/Activity";
import { errorNotification } from "Data/Actions/Notifications";
import { setAsyncFetching, setAsyncState } from "Data/Actions/UI";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { CacheInvalidationPeriod } from "Data/Objects/Global";

const {
	SET_ACTIVITIES
} = ACTION_TYPES.Admin.Activities;

/**
 * Simple Actions
 */

function setActivities(activities: Activity[], reset: boolean) {
	return { type: SET_ACTIVITIES.type, args: { activities, reset }};
}

/**
 * Async Actions
 */

function getActivitiesAsync() {
	return (dispatch, getState) => {
		const { Company, UI: { asyncState } } = getState();
		const { activeCompanyId } = Company;
		const { currentPage, currentlyFetching, haveAllActivities, lastFetch, lastFetchedCompany } = asyncState.activity;
		const dontNeedToFetch = haveAllActivities || currentlyFetching;
		const companyChanged = Company.activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = moment().diff(lastFetch, "minutes") >= CacheInvalidationPeriod || companyChanged;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		dispatch(setAsyncFetching("activity", true, activeCompanyId));

		const page = (shouldInvalidateCache ? 0 : currentPage) + 1;

		const api = new ActivityApi();
		return api.getActivityLog(activeCompanyId, { page })
			.then((result: PaginatedDataResult<Activity>) => {
				const { data, links, meta } = result;
				const { current_page } = meta;

				dispatch(setActivities(data, shouldInvalidateCache));
				dispatch(setAsyncState(
					"activity",
					!links.next,
					current_page
				));
				dispatch(setAsyncFetching("activity", false));
			}, (error) => {
				dispatch(errorNotification("Error getting Activities.", error));
				dispatch(setAsyncFetching("activity", false));
			})
			.catch(error => {
				notifyBugSnagAsync(new Error(error));
				dispatch(setAsyncFetching("activity", false));
			});
	};
}

export {
	getActivitiesAsync,
	setActivities
};

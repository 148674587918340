import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, ICompany, IDevice, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import DeviceGrid from "Components/Devices/DeviceGrid";
import { Loader } from "Components/Global/Common";
import EmptyList from "Components/Global/EmptyList";
import { tryFetchDevicesAsync } from "Data/Actions/Devices";
import { getActiveCompany } from "Data/Selectors/Company";
import { getDevicesForSelection, getFilteredSortedSearchedDevices } from "Data/Selectors/Devices";
import { getActiveFilters, getActiveSorts, makeHaveAllDevices, makeLastDevicesPage } from "Data/Selectors/UI";

const mapDispatchToProps = (dispatch) => ({
	fetchDevices: (companyUuid: string) => dispatch(tryFetchDevicesAsync(companyUuid))
});

// if we provide a `filterSet` prop, we can request different filters e.g. for Deployment/Health device selection
const mapStateToProps = (state, { filterSet }) => {
	const haveAllDevices = makeHaveAllDevices();
	const lastPageFetched = makeLastDevicesPage();
	const filters = getActiveFilters(state, filterSet || Filters.DEVICES);
	const { model, filter, store, sizes, status, stores, group, integrator } = filters;
	const devices = filterSet ? getDevicesForSelection(state, {
		model: model as string,
		filter: filter as string,
		store: store as string
	}) : getFilteredSortedSearchedDevices(state, {
		sizes: sizes as string[],
		status: status as string[],
		stores: stores as string[],
		filter: filter as string,
		sort: getActiveSorts(state, Sorts.DEVICES) as SortTypes,
		group: group as string,
		integrator: integrator as string
	});

	return {
		activeCompany: getActiveCompany(state),
		devices,
		haveAllDevices: haveAllDevices(state),
		lastPageFetched: lastPageFetched(state)
	};
};

interface DeviceGridWrapperProps {
	activeCompany: ICompany;
	devices: IDevice[];
	fetchDevices: (uuid: string) => void;
	filterSet?: string;
	haveAllDevices: boolean;
	lastPageFetched: number;
	selectModeOn: boolean;
}

export class DeviceGridWrapper extends React.Component<DeviceGridWrapperProps> {
	constructor(props: DeviceGridWrapperProps) {
		super(props);

		this.styles = {
			devices: {
				height: "100%",
				overflowY: "auto"
			},
			waypoint: {
				height: 40,
				marginTop: 40,
				width: "100%",
				textAlign: "center"
			}
		};

		this.handlePaging = this.handlePaging.bind(this);
		this.renderWaypoint = this.renderWaypoint.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		const { fetchDevices, activeCompany } = this.props;

		fetchDevices(activeCompany.uuid);
	}

	render() {
		const { devices, filterSet, haveAllDevices, selectModeOn } = this.props;
		const content = !devices.length && haveAllDevices ? (
			<EmptyList type="devices" />
		) : (
			<DeviceGrid
				content={ devices }
				filterSet={ filterSet }
				key="device-grid"
				selectModeOn={ selectModeOn }
			/>
		);

		return (
			<div style={ this.styles.devices }>
				{ content }
				{ this.renderWaypoint() }
			</div>
		);
	}

	renderWaypoint() {
		const { haveAllDevices, lastPageFetched, activeCompany, devices } = this.props;
		const key = `${activeCompany.uuid}-${lastPageFetched}-${devices.length}`;

		if (haveAllDevices) {
			return null;
		}

		return (
			<div style={ this.styles.waypoint }>
				<Waypoint key={ key } onEnter={ this.handlePaging } />
				<Loader />
			</div>
		);
	}

	handlePaging() {
		const { lastPageFetched, haveAllDevices, activeCompany, fetchDevices } = this.props;

		if (lastPageFetched !== -1 || !haveAllDevices) {
			fetchDevices(activeCompany.uuid);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceGridWrapper);

import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { Activity, CustomCSS, ICompany } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Link, Loader, Table } from "Components/Global/Common";
import { getActivitiesAsync } from "Data/Actions/Activity";
import { getActivity } from "Data/Selectors/Activity";
import { getActiveCompany } from "Data/Selectors/Company";
import { makeHaveAllActivities, makeLastActivityPage } from "Data/Selectors/UI";

const mapStateToProps = (state) => {
	const lastFetchedPage = makeLastActivityPage();
	const haveAllActivities = makeHaveAllActivities();
	return {
		activeCompany: getActiveCompany(state),
		activities: getActivity(state),
		haveAllActivities: haveAllActivities(state),
		lastFetchedPage: lastFetchedPage(state)
	}
};

const mapDispatchToProps = (dispatch) => ({
	getActivities: () => dispatch(getActivitiesAsync())
});

interface IActivityTableProps {
	activities: Activity[];
	activeCompany: ICompany;
	getActivities: () => void;
	haveAllActivities: boolean;
	currentPage: number;
}

class ActivityTable extends React.PureComponent<IActivityTableProps> {
	constructor(props: IActivityTableProps) {
		super(props);

		this.styles = {
			loader: {
				width: "100%",
				textAlign: "center",
				padding: "6px 0px 6px 0px"
			},
			wrapper: {
				height: "100%"
			}
		}

		this.columns = [
			{
				title: "Date / Time",
				dataIndex: "createdAt",
				sorter: Utils.dateColumnSorter("createdAt"),
				render: Utils.getHumanReadableDate,
				defaultSortOrder: "ascend"
			},
			{
				title: "Type",
				dataIndex: "subjectType",
				sorter: Utils.columnSorter("subjectType"),
				render: this.renderTypeCell
			},
			{
				title: "Modified By",
				sorter: Utils.columnSorter("causer.name"),
				dataIndex: "causer.name"
			},
			{
				title: "Details",
				sorter: Utils.columnSorter("subject.name"),
				dataIndex: "subject.name",
				render: this.renderDetailsCell
			}
		]

		this.handlePaging = this.handlePaging.bind(this);
		this.renderDetailsCell = this.renderDetailsCell.bind(this);
		this.renderTypeCell = this.renderTypeCell.bind(this);
	}

	styles: CustomCSS;

	columns: ColumnProps<Activity>[];

	componentWillMount() {
		this.props.getActivities();
	}

	componentWillReceiveProps(nextProps: IActivityTableProps) {
		if (this.props.activeCompany.uuid !== nextProps.activeCompany.uuid) {
			this.props.getActivities();
		}
	}

	render() {
		const { wrapper } = this.styles;

		return (
			<div style={ wrapper }>
				{ this.renderTable() }
				{ this.renderWaypoint() }
				{ this.renderLoader() }
			</div>
		);
	}

	renderTable() {
		return (
			<Table
				size="middle"
				rowKey={ this.getRowKey }
				columns={ this.columns }
				dataSource={ this.props.activities }
				locale={{ emptyText: "No Activities" }}
				pagination={ false }
			/>
		);
	}

	renderDetailsCell(text: string, record: Activity) {
		const pages = { "ad": "ads", "ads": "ads", "deployment": "deploy", "device": "devices",
			"healthReport": "health", "media": "media", "playlist": "playlists" };
		const types = Object.keys(pages);
		const { description, subject: { uuid }, subjectType } = record;

		if (!types.includes(subjectType) || description === "deleted" || !uuid) {
			return text;
		}

		return <Link to={ "/" + pages[subjectType] + "/" + uuid }>{ text }</Link>
	}

	renderTypeCell(text: string, record: Activity) {
		return (
			text && record.description
				? Utils.properCase(text) + " " + record.description
				: null
		);
	}

	renderLoader() {
		if (this.props.haveAllActivities) {
			return null;
		}

		return (
			<div style={ this.styles.loader }>
				<Loader />
			</div>
		);
	}

	renderWaypoint() {
		return (
			<Waypoint
				key={this.props.currentPage}
				onEnter={this.handlePaging} />
		);
	}

	getRowKey(record: Activity, index: number) {
		return index.toString();
	}

	handlePaging() {
		if (!this.props.haveAllActivities) {
			this.props.getActivities();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTable);
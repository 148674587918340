import * as React from "react";
import { connect } from "react-redux";

import { ViewTypes } from "@connect/Interfaces";
import DeviceCard from "Components/Devices/DeviceCard";
import GridView, { IGridViewProps } from "Components/Global/GridView";
import { replaceWithoutRender } from "Data/Actions/Navigation";
import { addToActiveSelection, removeActiveSelection, setActiveUuid, setMediaPreview } from "Data/Actions/UI";
import { Device } from "Data/Objects/Devices";
import {
	getActiveSelection,
	getActiveUuid,
	getActiveView
} from "Data/Selectors/UI";

interface IDeviceGridProps extends IGridViewProps {
	filterSet?: string;
	selectedView: ViewTypes;
	setActiveDeviceGroup: (id: string) => void;
	setPreview: (uri: string) => void;
}

const defaultView = ViewTypes.GRID;

const mapStateToProps = (state, { filterSet }) => ({
	activeSelection: getActiveSelection(state, filterSet || "devices"),
	activeUuid: filterSet ? "" : getActiveUuid(state, "devices"),
	selectedView: filterSet ? defaultView :  getActiveView(state, "devices") || defaultView
});

const mapDispatchToProps = (dispatch, { filterSet }) => ({
	addToSelected: (ids: string[]) => dispatch(addToActiveSelection(filterSet || "devices", ids)),
	removeFromSelected: (ids: string[]) => dispatch(removeActiveSelection(filterSet || "devices", ids)),
	setActive: (id: string) => filterSet ? null : dispatch(replaceWithoutRender(`/devices/${id}`)),
	setActiveDeviceGroup: (id: string) => dispatch(setActiveUuid("deviceGroups", id)),
	setPreview: (uri: string) => dispatch(setMediaPreview(uri, -1, "image"))
});

export class DeviceGrid extends GridView<IDeviceGridProps> {
	constructor(props: IDeviceGridProps) {
		super(props);

		this.handleCardSelection = this.handleCardSelection.bind(this);
		this.renderDevice = this.renderDevice.bind(this);
	}

	render() {
		const cards = (this.props.content || []).map(this.renderDevice);

		return this.renderContainer(cards);
	}

	renderDevice(device: Device, i: number) {
		const { selectModeOn, selectedView } = this.props;
		const { name, uuid } = device;
		const selected = this.isSelected(uuid);

		return (
			<DeviceCard
				key={ name + "_" + uuid + "_" + i }
				bulkSelectActive={ selectModeOn }
				cardObject={ device }
				cardType="device"
				isSelected={ selected }
				onCardSelection={ this.handleCardSelection(device, selected) }
				onDoubleClick={ this.handleShowSnapshot(device) }
				isDraggable={ true }
				selectedView={ selectedView }
			/>
		);
	}

	handleCardSelection(device: Device, isSelected: boolean) {
		return (bulkAndShift: boolean) => {
			const { filterSet, selectModeOn, setActiveDeviceGroup } = this.props;

			if (!selectModeOn && !filterSet) {
				setActiveDeviceGroup("");
			}

			return super.handleCardSelection(device, isSelected)(bulkAndShift);
		};
	}

	handleShowSnapshot(device: Device) {
		return () => {
			const { filterSet, setPreview } = this.props;

			if (!filterSet) {
				setPreview(device.snapshot);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceGrid);
import { Action, UuidDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { createReducer } from "Data/Utils";

export function addRunningReport(state: string[], action: Action<UuidDispatch>) {
	const { uuid } = action.args;
	let newState = [ ...state ];

	if (state.indexOf(uuid) === -1) {
		newState.push(uuid);
	}

	return newState;
}

export function removeRunningReport(state: string[], action: Action<UuidDispatch>) {
	const index = state.indexOf(action.args.uuid);
	let newState = [ ...state ];

	if (index > -1) {
		newState.splice(index, 1);
	}

	return newState;
}

export function resetRunningReports(state: string[], action: Action<null>) {
	return [];
}

const {
	ADD_RUNNING_REPORT,
	REMOVE_RUNNING_REPORT,
	RESET_RUNNING_REPORTS
} = ACTION_TYPES.UI;

const reducers = {
	[ADD_RUNNING_REPORT.type]: addRunningReport,
	[REMOVE_RUNNING_REPORT.type]: removeRunningReport,
	[RESET_RUNNING_REPORTS.type]: resetRunningReports
};

export default createReducer(reducers, Array, 0);

import * as React from "react";
import { DragSource, DragSourceCollector, DragSourceSpec } from "react-dnd";
import { Colors } from "../Global/Constants";

interface WidgetListItemDragObject {
	type: string;
}

interface WidgetListItemCollectedProps {
	connectDragSource: Function;
}

interface WidgetListItemProps {
	connectDragSource?: Function;
	type: string;
	used: boolean;
}

interface WidgetListItemState {
}

const cardSource: DragSourceSpec<WidgetListItemProps, WidgetListItemDragObject> = {
	beginDrag(props: WidgetListItemProps) {
		return {
			type: props.type
		};
	}
};

const DragCollector: DragSourceCollector<WidgetListItemCollectedProps> = (connect, monitor) => ({
	connectDragSource: connect.dragSource()
});

export class WidgetListItem extends React.Component<WidgetListItemProps, WidgetListItemState> {

	render() {
		const { connectDragSource, used, type } = this.props;
		const content = (
			<div style={{
				padding: 5,
				paddingLeft: 40,
				color: used ? Colors.gray : Colors.white
			}}>
				{ type }
			</div>
		);

		if (connectDragSource) {
			return connectDragSource(content);
		}

		return content;
	}
}

export default DragSource("Widget", cardSource, DragCollector)(WidgetListItem);
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, ICompany, IMedia } from "@connect/Interfaces";
import BannerListItem from "Components/Ads/BannerListItem";
import { SecurityBanners } from "Components/Ads/Constants";
import { Droppable, UnwrappedDroppable } from "Components/Global/DropZone";
import Icon from "Components/Global/Icon";
import MediaHandler from "Components/Global/MediaHandler";
import OptionButton from "Components/Global/OptionButton";
import { setBannersAsync } from "Data/Actions/MediaAsync";
import { getBanners } from "Data/Selectors/Media";

type BannerType = "default" | "custom";

interface BannerListProps {
	activeCompany: ICompany;
	banners: IMedia[];
	getAllBanners: () => void;
	selectedBannerId: string;
}

interface BannerListState {
	bannerType: BannerType;
}

const mapDispatchToProps = (dispatch) => ({
	getAllBanners: () => dispatch(setBannersAsync())
});

const mapStateToProps = (state) => ({
	banners: getBanners(state)
});

const styles = {
	bannerListContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		marginBottom: "3px"
	},
	bannerListButton: {
		padding: "5px",
		flex: 1,
		margin: 0
	},
	bannerListScrollbox: {
		height: "240px",
		overflowY: "auto",
		padding: "5px",
		backgroundColor: "black"
	},
	uploadBannerButton: {
		width: "100%",
		padding: 3,
		border: "1px solid gray",
		color: "gray",
		textAlign: "center",
		marginBottom: 5,
		cursor: "pointer"
	}
} as CustomCSS;

export class BannerList extends React.Component<BannerListProps, BannerListState> {
	constructor(props: BannerListProps) {
		super(props);

		this.droppable = null;

		this.state = {
			bannerType: "default"
		};

		this.fetchBanners();

		this.fetchBanners = this.fetchBanners.bind(this);
		this.openDroppable = this.openDroppable.bind(this);
		this.renderBannerItem = this.renderBannerItem.bind(this);
		this.setBannerType = this.setBannerType.bind(this);
		this.setCustomBannerType = this.setCustomBannerType.bind(this);
		this.setDefaultBannerType = this.setDefaultBannerType.bind(this);
	}

	droppable: connect<UnwrappedDroppable>;

	componentWillReceiveProps() {
		this.fetchBanners();
	}

	render() {
		const { getAllBanners, banners: libraryBanners } = this.props;
		const defaultSelected = this.state.bannerType === "default";
		const banners = defaultSelected ? SecurityBanners : libraryBanners;

		return (
			<Droppable
				ref={(ref) => {
					this.droppable = ref
				}}
				prompt={null}
				onDrop={this.onMediaDrop}>
				<div style={styles.bannerListContainer}>
					<OptionButton
						selected={defaultSelected}
						style={ { ...styles.bannerListButton, borderTopLeftRadius: 6  } }
						onClick={this.setDefaultBannerType}>
						Default Banners
					</OptionButton>
					<OptionButton
						selected={!defaultSelected}
						style={ { ...styles.bannerListButton, borderTopRightRadius: 6  } }
						onClick={this.setCustomBannerType}>
						Custom Banners
					</OptionButton>
				</div>
				<div style={styles.bannerListScrollbox}>
					{this.renderUpload()}
					{banners.map(this.renderBannerItem)}
					<Waypoint onEnter={getAllBanners} />
				</div>
			</Droppable>
		);
	}

	renderBannerItem(banner: IMedia, index: number) {
		return (
			<BannerListItem
				banner={banner}
				key={banner.uuid + index}
			/>
		);
	}

	renderUpload() {
		if (this.state.bannerType === "default") {
			return null;
		}

		return (
			<div
				style={styles.uploadBannerButton}
				onClick={this.openDroppable}>
				<Icon name="plus-circle"/>Upload Media
			</div>
		);
	}

	fetchBanners() {
		if (this.state.bannerType === "custom") {
			this.props.getAllBanners();
		}
	}

	onMediaDrop(files: File[]) {
		let handler = new MediaHandler();
		handler.allowedFileTypes = [ "Banner" ];
		handler.handleFiles(files);
	}

	openDroppable() {
		this.droppable.getWrappedInstance().open();
	}

	setBannerType(type: BannerType) {
		if (this.state.bannerType !== type) {
			this.setState((prevState) => ({
				bannerType: type
			}));
		}
	}

	setCustomBannerType() {
		this.setBannerType("custom");
	}

	setDefaultBannerType() {
		this.setBannerType("default");
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerList);
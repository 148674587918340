import { Layout } from "antd";
import Radium from "radium";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

const { Sider, Content } = Layout;

interface ThreeColumnLayoutProps {
	leftContent?: JSX.Element;
	leftWidth?: number;
	leftCollapsible?: boolean;
	leftCollapsed?: boolean;
	rightContent?: JSX.Element;
	rightWidth?: number;
	rightCollapsible?: boolean;
	rightCollapsed?: boolean;
	centerContent: JSX.Element | undefined;
}

interface ThreeColumnLayoutState {
	leftCollapsed: boolean;
	rightCollapsed: boolean;
}

const siderDefaultWidth = 250;
enum CollapsibleKeys { LEFT, RIGHT }

class ThreeColumnLayout extends React.PureComponent<ThreeColumnLayoutProps, ThreeColumnLayoutState> {
	constructor(props: ThreeColumnLayoutProps) {
		super(props);

		this.state = {
			leftCollapsed: props.leftCollapsed || false,
			rightCollapsed: props.rightCollapsed || false
		};

		this.styles = {
			leftSider: {
				backgroundColor: "#0E0F15",
				color: Colors.white,
				height: "100vh"
			},
			rightSider: {
				backgroundColor: "#272A34",
				color: Colors.white,
				height: "100vh"
			},
			siderContent: {
				display: "flex",
				flexDirection: "row"
			},
			collapseHandle: {
				width: 10,
				fontSize: "0.6rem",
				backgroundColor: "#181a21",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				cursor: "pointer",
				":hover": {
					backgroundColor: "#22252f"
				}
			},
			layout: {
				height: "100%",
				overflowY: "auto"
			},
			content: {
				maxHeight: "100vh",
				minHeight: "100%",
				position: "relative",
				overflowY: "auto"
			},
			rightSiderContentWrapper: {
				flexGrow: 1
			},
			leftSiderContentWrapper: {
				flexGrow: 1,
				height: "100%",
				overflow: "hidden"
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { leftContent, leftCollapsible, rightContent, rightCollapsible, centerContent } = this.props;
		const { leftCollapsed, rightCollapsed } = this.state;
		const { layout, content, siderContent, leftSider,
			collapseHandle, rightSider, rightSiderContentWrapper } = this.styles;
		const leftSiderContentWrapper = Object.assign({}, this.styles.leftSiderContentWrapper, {
			display: leftCollapsed ? "none" : "inherit"
		});
		const leftWidth = this.props.leftWidth || siderDefaultWidth;
		const rightWidth = this.props.rightWidth || siderDefaultWidth;

		return (
			<Layout style={ layout }>
				{leftContent ?
					<Sider
						style={ leftSider }
						collapsible={leftCollapsible}
						collapsed={leftCollapsed}
						width={leftWidth}
						collapsedWidth={collapseHandle.width}
						trigger={null}>
						<div style={ siderContent }>
							<div style={ leftSiderContentWrapper }>
								{leftContent}
							</div>
							{leftCollapsible ?
								<div
									key={CollapsibleKeys.LEFT}
									style={ collapseHandle }
									onClick={() => {
										this.toggleCollapsed(CollapsibleKeys.LEFT);
									}}
								>
									<Icon name={leftCollapsed ? "caret-right" : "caret-left"} />
								</div>
								: null}
						</div>
					</Sider>
					: null}
				<Content style={ content }>
					{centerContent}
				</Content>
				{rightContent ?
					<Sider
						style={ rightSider }
						collapsible={rightCollapsible}
						collapsed={rightCollapsed}
						width={rightWidth}
						collapsedWidth={collapseHandle.width}
						trigger={null}>
						<div style={ siderContent }>
							{rightCollapsible ?
								<div
									key={CollapsibleKeys.RIGHT}
									style={ collapseHandle }
									onClick={() => {
										this.toggleCollapsed(CollapsibleKeys.RIGHT);
									}}
								>
									<Icon name={rightCollapsed ? "caret-left" : "caret-right"} />
								</div>
								: null}
							<div style={ rightSiderContentWrapper }>
								{rightContent}
							</div>
						</div>
					</Sider>
					: null}
			</Layout>
		);
	}

	toggleCollapsed(which: CollapsibleKeys) {
		const { leftCollapsed, rightCollapsed } = this.state;
		let newState;

		if (which === CollapsibleKeys.LEFT) {
			newState = { leftCollapsed: !leftCollapsed };
		} else {
			newState = { rightCollapsed: !rightCollapsed };
		}

		this.setState(() => newState);
	}
}

export default Radium(ThreeColumnLayout);
import { Slider, Switch } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, TriggerDwell } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { updateSelectedTriggerDwell } from "Data/Actions/ActionsAsync";
import { getSelectedTriggerDwell } from "Data/Selectors/ActionBuilder";
import { Colors } from "Components/Global/Constants";
import { DwellDurations } from "Components/Actions/Constants";
import { SliderMarks } from "antd/lib/slider";

interface DwellPropertiesProps {
	dwell: TriggerDwell;
	updateDwell: (value: TriggerDwell) => void;
}

const getMark = (value: number) => {
	return DwellDurations.indexOf(value);
}

const mapStateToProps = (state) => {
	return {
		dwell: getSelectedTriggerDwell(state)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateDwell: (dwell: TriggerDwell) =>
			dispatch(updateSelectedTriggerDwell(dwell))
	}
}

class DwellProperties extends React.Component<DwellPropertiesProps> {
	constructor(props: DwellPropertiesProps) {
		super(props);

		this.styles = {
			label: {
				display: "inline-block"
			},
			mark: {
				marginTop: 4
			},
			row: {
				margin: "55px 0px 7px"
			},
			slider: {
				margin: "0px 10px 55px 10px"
			},
			switchButton: {
				marginLeft: 135
			},
			description: {
				fontSize: "0.95em",
				color: Colors.slate,
				marginTop: 5
			}
		};

		this.updateDwellEnabled = this.updateDwellEnabled.bind(this);
		this.updateDwellDuration = this.updateDwellDuration.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { dwell } = this.props;

		if (!dwell) {
			return null;
		}

		const { enabled } = dwell;
		const { label, row, switchButton, description } = this.styles;

		return (
			<div style={ row }>
				<div style={ label }><Icon name="clock" /> Dwell:</div>
				<Switch
					style={ switchButton }
					checked={ enabled }
					onChange={ this.updateDwellEnabled }
				/>
				<p style={ description }>
					Set the amount of trigger detection time before an Action takes place.
				</p>
				{this.renderSlider()}
			</div>
		);
	}

	renderSlider() {
		const { dwell } = this.props;
		const { enabled, duration } = dwell;
		const { slider } = this.styles;

		if (!enabled) {
			return null;
		}

		const marks = DwellDurations.reduce((obj: SliderMarks, seconds: number) => ({
			...obj, [getMark(seconds)]: {
				label: seconds + " sec",
				style: this.styles.mark
			}
		}), {});

		let content: any = (
			<div style={ slider }>
				<Slider
					key={ duration }
					tipFormatter={ null }
					step={ null }
					marks={ marks }
					min={ 0 }
					max={ 3 }
					defaultValue={ getMark(duration) }
					onAfterChange={ this.updateDwellDuration } />
			</div>
		);

		return content;
	}

	updateDwellDuration(sliderValue: number) {
		const { dwell, updateDwell } = this.props;
		updateDwell({...dwell, duration: DwellDurations[sliderValue]});
	}

	updateDwellEnabled(enabled: boolean) {
		const { dwell, updateDwell} = this.props
		updateDwell({...dwell, enabled});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DwellProperties);

import { getState, store } from "@connect/Data";
import {
	ICompany,
	PusherEvent,
	WithUuid,
	IStore,
	Action,
	StoreDispatch,
	CompanyDispatch
} from "@connect/Interfaces";
import { createActionSet, updateActionSet } from "Data/Actions/Actions";
import { createAd, updateAd } from "Data/Actions/Ads";
import { createDeployment, deleteDeployment, setDeploymentDetails } from "Data/Actions/Deployments";
import { createDevice, createDeviceGroup, deleteDevice, deleteDeviceGroup, updateDevice,
	updateDeviceGroup } from "Data/Actions/Devices";
import { createReport, updateReport } from "Data/Actions/HealthReport";
import { createMedia, deleteMedia, updateMedia } from "Data/Actions/Media";
import { createPlaylist, updatePlaylist } from "Data/Actions/Playlists";
import { fetchMissedNotifications, setLastNotification, userJoinedChannel, userLeftChannel,
	resetChannel, handleSpeedTestUpdate, handleDevicePong, handleSoftwareUpdating,
	handleSoftwareUpdated, handleDatabaseUploadStarted, handleDatabaseUploadCompleted, handleCacheCleared,
	handleRebootStarted, handleRebootCompleted, handleScheduleUpdating, handleScheduleUpdated, handleDeviceAssociated,
	handleDeviceReleased, handleSpeedTestFailed, handleHealthReportResult, integratorsChangedCompany,
	integratorsChangedIntegrator, passwordResetRequired, handleAdDeleted, handlePlaylistDeleted, handleActionDeleted,
	handleHealthReportDeleted,
	handleScreenshotUpdated
} from "Data/Actions/Pusher";
import { createUser, deleteUser, updateUser } from "Data/Actions/Users";
import { getActiveCompany, getCompanies } from "Data/Selectors/Company";
import { getActiveUserUuid } from "Data/Selectors/User";
import * as Pusher from "pusher-js";
import { DispatchableAction } from "Data/Objects/DispatchableAction";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { getPusherCredentials } from "Data/Selectors/System";

/*
 * Company Actions used in pusher (put here temporarily to avoid nasty circular dependencies)
 */

const {
	UPDATE_COMPANY,
	DELETE_COMPANY,
	CREATE_COMPANY,
	UPDATE_STORE,
	DELETE_STORE,
	CREATE_STORE
} = ACTION_TYPES.Company

function updateCompany(company: ICompany): Action<CompanyDispatch> {
	return new DispatchableAction(UPDATE_COMPANY, { company });
}

function deleteCompany(company: ICompany): Action<CompanyDispatch> {
	return new DispatchableAction(DELETE_COMPANY, { company });
}

function createCompany(company: ICompany): Action<CompanyDispatch> {
	return new DispatchableAction(CREATE_COMPANY, { company });
}

function updateStore(store: IStore): Action<StoreDispatch> {
	return new DispatchableAction(UPDATE_STORE, { store });
}

function deleteStore(store: IStore): Action<StoreDispatch> {
	return new DispatchableAction(DELETE_STORE, { store });
}

function createStore(store: IStore): Action<StoreDispatch> {
	return new DispatchableAction(CREATE_STORE, { store });
}

const deleteWrapper = (actionCreator: Function) => {
	return (data: WithUuid) => {
		return actionCreator(data.uuid);
	}
}

const EVENT_BINDINGS = {
	media: {
		updated: {
			action: updateMedia
		},
		deleted: {
			action: deleteWrapper(deleteMedia)
		},
		created: {
			action: createMedia
		}
	},
	ad: {
		updated: {
			action: updateAd
		},
		deleted: {
			action: handleAdDeleted
		},
		created: {
			action: createAd
		}
	},
	playlist: {
		updated: {
			action: updatePlaylist
		},
		deleted: {
			action: handlePlaylistDeleted
		},
		created: {
			action: createPlaylist
		}
	},
	action: {
		updated: {
			action: updateActionSet
		},
		deleted: {
			action: handleActionDeleted
		},
		created: {
			action: createActionSet
		}
	},
	device: {
		updated: {
			action: updateDevice
		},
		deleted: {
			action: deleteWrapper(deleteDevice)
		},
		created: {
			action: createDevice
		},
		speedTestStarted: {
			action: handleSpeedTestUpdate
		},
		speedTestProgress: {
			action: handleSpeedTestUpdate
		},
		speedTestCompleted: {
			action: handleSpeedTestUpdate
		},
		speedTestFailed: {
			action: handleSpeedTestFailed
		},
		databaseUploadStarted: {
			action: handleDatabaseUploadStarted
		},
		databaseUploadCompleted: {
			action: handleDatabaseUploadCompleted
		},
		softwareUpdating: {
			action: handleSoftwareUpdating
		},
		softwareUpdated: {
			action: handleSoftwareUpdated
		},
		pong: {
			action: handleDevicePong
		},
		cacheCleared: {
			action: handleCacheCleared
		},
		rebootStarted: {
			action: handleRebootStarted
		},
		rebootCompleted: {
			action: handleRebootCompleted
		},
		scheduleUpdating: {
			action: handleScheduleUpdating
		},
		scheduleUpdated: {
			action: handleScheduleUpdated
		},
		associated: {
			action: handleDeviceAssociated
		},
		released: {
			action: handleDeviceReleased
		},
		screenshotUpdated: {
			action: handleScreenshotUpdated
		}
	},
	deviceGroup: {
		updated: {
			action: updateDeviceGroup
		},
		deleted: {
			action: deleteWrapper(deleteDeviceGroup)
		},
		created: {
			action: createDeviceGroup
		}
	},
	company: {
		updated: {
			action: updateCompany
		},
		deleted: {
			action: deleteWrapper(deleteCompany)
		},
		created: {
			action: createCompany
		},
		integratorUsersChanged: {
			company: integratorsChangedCompany,
			integrator: integratorsChangedIntegrator
		},
		integratorsChanged: {
			company: integratorsChangedCompany,
			integrator: integratorsChangedIntegrator
		},
		passwordResetRequired: {
			action: passwordResetRequired
		}
	},
	store: {
		updated: {
			action: updateStore
		},
		deleted: {
			action: deleteStore
		},
		created: {
			action: createStore
		}
	},
	user: {
		updated: {
			action: updateUser
		},
		deleted: {
			action: deleteUser
		},
		created: {
			action: createUser
		}
	},
	deployment: {
		updated: {
			action: setDeploymentDetails
		},
		deleted: {
			action: deleteWrapper(deleteDeployment)
		},
		created: {
			action: createDeployment
		}
	},
	healthReport: {
		resultCompleted: {
			action: handleHealthReportResult
		},
		updated: {
			action: updateReport
		},
		deleted: {
			action: handleHealthReportDeleted
		},
		created: {
			action: createReport
		}
	}
};

export class PusherConnection {
	pusherConnection: Pusher.Pusher;
	companyChannel: Pusher.Channel;
	integratorChannel: Pusher.Channel;
	userChannel: Pusher.Channel;
	presenceChannels: {
		[type: string]: {
			[uuid: string]: Pusher.Channel;
		}
	}

	constructor() {
		this.presenceChannels = {};
	}

	initializeConnection() {
		this.connectPusher();
		this.connectCompanyChannel();
		this.connectIntegratorChannel();
		this.connectUserChannel();
	}

	connectPusher() {
		const { user } = getState().User;
		const pusherCredentials = getPusherCredentials(getState())

		if (user && !this.pusherConnection) {

			this.pusherConnection = new Pusher(pusherCredentials.key, {
				authEndpoint: "https://development.api.clintonconnect.com/broadcasting/auth",
				auth: {
					headers: {
						"Authorization": "Bearer " + getState().User.token
					}
				},
				disableStats: true,
				cluster: pusherCredentials.cluster,
				enabledTransports: [ "ws", "wss" ],
				encrypted: true,
				wsHost: "conloquium.clintonconnect.com"
			});
		}
	}

	rebindEvents() {
		this.initializeConnection();
		this.connectCompanyChannel(true);
		this.connectIntegratorChannel(true);
		this.connectUserChannel(true);
	}

	connectCompanyChannel(reset?: boolean) {
		const activeCompany = getActiveCompany(getState());

		if (!activeCompany) {
			return;
		}

		const channelName = "private-company-" + activeCompany.uuid;
		if (!this.companyChannel || this.companyChannel.name !== channelName) {
			this.pusherConnection.allChannels().forEach((channel: Pusher.Channel) => {
				if (channel.name.indexOf("company") >=0) {
					this.pusherConnection.unsubscribe(channel.name);
				}
			});
			this.companyChannel = this.pusherConnection.subscribe(channelName);
		}
		if (!this.companyChannel.subscribed || reset) {
			this.companyChannel.unbind_global();
			this.companyChannel.bind_global(this.createPusherHandler(channelName));
		}
		store.dispatch(fetchMissedNotifications(channelName));
	}

	connectIntegratorChannel(reset?: boolean) {
		const companies = getCompanies(getState());
		const integratorCompany = companies.find((c: ICompany) => {
			return c.teamType === "integrator"
		});

		if (!integratorCompany) {
			return;
		}

		const channelName = "private-integrator-" + integratorCompany.uuid;
		if (!this.integratorChannel || this.integratorChannel.name !== channelName) {
			this.pusherConnection.allChannels().forEach((channel: Pusher.Channel) => {
				if (channel.name.indexOf("integrator") >=0) {
					this.pusherConnection.unsubscribe(channel.name);
				}
			})
			this.integratorChannel = this.pusherConnection.subscribe(channelName);
		}
		if (!this.integratorChannel.subscribed || reset) {
			this.integratorChannel.unbind_global();
			this.integratorChannel.bind_global(this.createPusherHandler(channelName));
		}
	}

	connectUserChannel(reset?: boolean) {
		const userUUID = getActiveUserUuid(getState());

		if (!userUUID) {
			return;
		}

		const channelName = "private-user-" + userUUID;
		if (!this.userChannel || this.userChannel.name !== channelName) {
			this.pusherConnection.allChannels().forEach((channel: Pusher.Channel) => {
				if (channel.name.indexOf("user") >=0) {
					this.pusherConnection.unsubscribe(channel.name);
				}
			});
			this.userChannel = this.pusherConnection.subscribe(channelName);
		}
		if (!this.userChannel.subscribed || reset) {
			this.userChannel.unbind_global();
			this.userChannel.bind_global(this.createPusherHandler(channelName));
		}
	}

	connectPresenceChannel(type: string, uuid: string) {
		this.connectPusher();

		const channelName = `presence-${ type }@${ uuid }`;
		const presenceChannel = this.pusherConnection.subscribe(channelName);

		presenceChannel.bind("pusher:subscription_succeeded", () => {
			const { members } = (presenceChannel as any);
			Object.keys(members.members).map((key) => {
				store.dispatch(userJoinedChannel(channelName, members.members[key]));
			})
		});

		presenceChannel.bind("pusher:member_added", function({ info }) {
			store.dispatch(userJoinedChannel(channelName, info));
		});

		presenceChannel.bind("pusher:member_removed", function({ info }) {
			store.dispatch(userLeftChannel(channelName, info));
		});

		this.presenceChannels = Object.assign(this.presenceChannels, {
			[type]: {
				[uuid]: presenceChannel
			}
		});
	}

	disconnectPresenceChannel(type: string, uuid: string) {
		const presenceChannelExists = this.presenceChannels && this.presenceChannels[type]
		&& this.presenceChannels[type][uuid];
		if (presenceChannelExists) {
			const channelName = `presence-${ type }@${ uuid }`;
			this.presenceChannels[type][uuid].unbind_all();
			this.pusherConnection.unsubscribe(channelName);
			delete this.presenceChannels[type][uuid];
			store.dispatch(resetChannel(channelName));
		}
	}

	createPusherHandler(channel: string) {
		const [ , channelType ] = channel.split("-", 2);

		return (eventType: string, notification: PusherEvent) => {
			if (!notification || !channelType) {
				return;
			}

			const channelNotification = { Channel: channel, Notification: { ...notification } }
			// eslint-disable-next-line
			console.log(channelNotification);

			let actionCreator =
				EVENT_BINDINGS?.[notification.type]?.[notification.event]?.[channelType] ??
				EVENT_BINDINGS?.[notification.type]?.[notification.event]?.action;

			if (actionCreator) {
				notification.entities.forEach((uuid: string) => {
					if (notification.batch) {
						notification.data.forEach(data => {
							store.dispatch(actionCreator(
								{ ...data }
							));
						});
					} else {
						store.dispatch(actionCreator(
							{ ...notification.data, uuid }
						));
					}
				});
			}
			store.dispatch(setLastNotification(channel, notification.uuid));
		}
	}

	disconnect() {
		this.pusherConnection.allChannels().map((channel: Pusher.Channel) => {
			this.pusherConnection.unsubscribe(channel.name);
		});
		this.pusherConnection.unbind_all();
		this.pusherConnection.disconnect();

		delete this.pusherConnection;
		delete this.companyChannel;
		delete this.integratorChannel;
		delete this.userChannel;
	}
}

export const pusherConnection = new PusherConnection();
import Radium from "radium";
import * as React from "react";

import { CustomCSS, DeviceSizeTypes } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";

const { darkishGray, filters: { hoverAlt }, white } = Colors;

interface DeviceSizeFilterProps {
	onClick: (size: string) => void;
	sizeFilter: string[];
}

@Radium
export class DeviceSizeFilter extends React.Component<DeviceSizeFilterProps> {
	constructor(props: DeviceSizeFilterProps) {
		super(props);

		this.styles = {
			button: {
				width: 50,
				height: 50,
				color: white,
				border: `1px solid ${ darkishGray }`,
				margin: 10,
				textAlign: "center",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				transition: "background 200ms",
				userSelect: "none",
				":hover": {
					background: hoverAlt,
					cursor: "pointer"
				}
			},
			container: {
				alignItems: "center",
				display: "flex",
				justifyContent: "center"
			}
		};

		this.clickButton = this.clickButton.bind(this);
		this.renderDeviceSizeButton = this.renderDeviceSizeButton.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<div style={ this.styles.container }>
				{ Object.values(DeviceSizeTypes).map(this.renderDeviceSizeButton) }
			</div>
		);
	}

	renderDeviceSizeButton(size: string) {
		return (
			<div
				role="button"
				key={ size }
				onClick={ this.clickButton(size) }
				style={ this.getSizeButtonStyle(size) }
			>
				{ size }"
			</div>
		);
	}

	clickButton(size: string) {
		return () => this.props.onClick(size);
	}

	getSizeButtonStyle(size: string) {
		return {
			...this.styles.button,
			background: this.props.sizeFilter.includes(size) ? hoverAlt : "none"
		}
	}
}

export default DeviceSizeFilter;

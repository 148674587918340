import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";

interface LEDProps {
	frame: string;
}

export default class LED extends React.Component<LEDProps> {
	constructor(props: LEDProps) {
		super(props);

		this.styles = {
			led: {
				background: Colors.black,
				display: "inline-block",
				height: 19.17,
				flex: 1
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { frame } = this.props;
		const { led } = this.styles;
		const style = {
			...led,
			background: frame
		};

		return (
			<div style={ style } />
		);
	}
}

export enum ENVIRONMENTS { LOCAL, DEVELOPMENT, STAGING, PRODUCTION };
export let ENVIRONMENT: ENVIRONMENTS;
export const isLocalhost = () => !window?.location?.hostname.includes("app.clintonconnect.com");
export const getEnvironmentString = () => isLocalhost() ? "LOCAL" : ENVIRONMENTS[ENVIRONMENT];
export const isDev = () => [ "LOCAL", "DEVELOPMENT" ].includes(getEnvironmentString());

switch (process.env.REACT_APP_ENVIRONMENT) {
	case "PRODUCTION":
		ENVIRONMENT = ENVIRONMENTS.PRODUCTION;
		break;
	case "STAGING":
		ENVIRONMENT = ENVIRONMENTS.STAGING;
		break;
	case "LOCAL":
		ENVIRONMENT = ENVIRONMENTS.LOCAL;
		break;
	case "DEVELOPMENT":
	default:
		ENVIRONMENT = ENVIRONMENTS.DEVELOPMENT;
		break;
}

export function envSwitch<T>(prod: T, staging: T, dev: T, local?: T): T {
	let val: T;

	switch (ENVIRONMENT) {
		case ENVIRONMENTS.LOCAL:
			val = local || dev; // we should always return T
			break;
		case ENVIRONMENTS.PRODUCTION:
			val = prod;
			break;
		case ENVIRONMENTS.STAGING:
			val = staging;
			break;
		case ENVIRONMENTS.DEVELOPMENT:
		default:
			val = dev;
	}

	return val;
}

import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, Deployment, IDeploymentsApi, SortTypes } from "@connect/Interfaces";
import DeploymentsCalendar from "Components/Home/Widgets/FutureDeploymentsWidget/DeploymentsCalendar";
import FutureDeployments from "Components/Home/Widgets/FutureDeploymentsWidget/FutureDeployments";
import { getDeploymentsAsync } from "Data/Actions/DeploymentsAsync";
import { getDeployAsyncQueryState, getNestedAsyncState } from "Data/Selectors/Async";
import { getActiveCompanyId } from "Data/Selectors/Company";
import { getSortedDeployments } from "Data/Selectors/Deployments";

const sort = SortTypes.OLDEST_FIRST;
const status = "upcoming";
const type = "all";

const mapStateToProps = (state) => {
	const asyncQuery = getDeployAsyncQueryState({ filterType: { status, type }, sortType: sort });
	const { currentPage, haveAllData } = getNestedAsyncState(state, asyncQuery);

	return {
		activeCompanyId: getActiveCompanyId(state),
		deployments: getSortedDeployments(state, { search: "", sort, status, type }),
		haveAllDeployments: haveAllData,
		lastPageFetched: currentPage
	};
};

const mapDispatchToProps = (dispatch) => ({
	getDeployments: () => dispatch(getDeploymentsAsync({ status, type }, sort))
});

interface FutureDeploymentsContainerProps {
	activeCompanyId: string;
	deployments: Deployment[];
	getDeployments: () => void;
	haveAllDeployments: boolean;
	lastPageFetched: number;
}

export class FutureDeploymentsContainer extends React.Component<FutureDeploymentsContainerProps> {
	constructor(props: FutureDeploymentsContainerProps) {
		super(props);

		this.styles = {
			container: {
				display: "flex",
				overflowY: "hidden",
				height: "100%"
			}
		};

		this.getDeployments = this.getDeployments.bind(this);
	}

	api: IDeploymentsApi;

	styles: CustomCSS;

	componentDidMount() {
		if (this.props.lastPageFetched === 0) {
			this.getDeployments();
		}
	}

	componentDidUpdate({ activeCompanyId: prevCompanyId }: FutureDeploymentsContainerProps) {
		const { activeCompanyId } = this.props;

		// if our company has changed, fetch
		if (activeCompanyId !== prevCompanyId) {
			this.getDeployments();
		}
	}

	render() {
		const { deployments, haveAllDeployments } = this.props;
		const selectedDates = deployments.map((deployment) => moment(deployment.startDate));

		return (
			<div style={ this.styles.container }>
				<FutureDeployments
					deployments={ deployments }
					haveAllDeployments={ haveAllDeployments }
					onFetchDeployments={ this.getDeployments }
				/>
				<DeploymentsCalendar selectedDates={selectedDates} />
			</div>
		);
	}

	getDeployments() {
		this.props.getDeployments();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FutureDeploymentsContainer);
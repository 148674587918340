import * as React from "react";
import { connect } from "react-redux";
import { NameUuid, CustomCSS } from "@connect/Interfaces";
import { getPresenceChannelUsers } from "Data/Selectors/Pusher";
import { Tooltip } from "antd";
import { Colors } from "Components/Global/Constants";
import { pusherConnection } from "@connect/Pusher2";

const { builderColorsArray } = Colors;

interface PresenceUsersProps {
	users: NameUuid[];
	type: string;
	uuid: string;
};
interface PresenceUsersState {};

const mapStateToProps = (state, ownProps: PresenceUsersProps) => ({
	users: getPresenceChannelUsers(state, `presence-${ ownProps.type }@${ ownProps.uuid }`)
});

class PresenceUsers extends React.Component<PresenceUsersProps, PresenceUsersState> {
	constructor(props: PresenceUsersProps) {
		super(props);

		this.renderUserBubble = this.renderUserBubble.bind(this);
		this.renderRemainingUsersBubble = this.renderRemainingUsersBubble.bind(this);

		this.styles = {
			bubble: {
				borderRadius: "50%",
				color: "white",
				width: 30,
				height: 30,
				lineHeight: "30px",
				textAlign: "center",
				marginRight: 5
			},
			wrapper: {
				display: "flex",
				flexDirection: "row",
				marginTop: 5
			}
		}
	}

	styles: CustomCSS;

	componentDidMount() {
		const { type, uuid } = this.props;

		if (type && uuid) {
			pusherConnection.connectPresenceChannel(type, uuid);
		}
	}

	componentWillUnmount() {
		const { type, uuid } = this.props;

		if (type && uuid) {
			pusherConnection.disconnectPresenceChannel(type, uuid);
		}
	}

	render() {
		const { users } = this.props;
		if (!users || users.length === 0) {
			return null;
		}

		const { wrapper } = this.styles;

		return (
			<div style={ wrapper }>
				{ users.slice(0, 4).map(this.renderUserBubble) }
				{ this.renderRemainingUsersBubble(users.slice(4)) }
			</div>
		)
	}

	renderUserBubble(user: NameUuid, index: number) {
		const userInitials = user.name
			.split(" ")
			.map((namePart: string) => namePart[0])
			.join("");

		const background = builderColorsArray[index];

		return (
			<Tooltip key={ user.uuid } title={ user.name }>
				<div style={ { ...this.styles.bubble, background } }>
					{ userInitials }
				</div>
			</Tooltip>
		);
	}

	renderRemainingUsersBubble(users: NameUuid[]) {
		if (!users || users.length === 0) {
			return null;
		}

		const tooltipText = users
			.map(this.renderRemainingUser);

		const { 4: background } = builderColorsArray;

		return (
			<Tooltip title={ <React.Fragment>{ tooltipText }</React.Fragment> }>
				<div style={ { ...this.styles.bubble, background } }>
					{ `+${ users.length }` }
				</div>
			</Tooltip>
		);
	}

	renderRemainingUser(user: NameUuid) {
		return (
			<React.Fragment key={ user.uuid }>
				{ user.name }
				<br />
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(PresenceUsers);
export class ActionType {
	constructor(type: string) {
		this.type = type;
	}

	type: string;
}

export const ACTION_TYPES = {
	Admin: {
		Activities: {
			SET_ACTIVITIES: new ActionType("SET_ACTIVITIES"),
			SET_ACTIVITIES_FETCHING: new ActionType("SET_ACTIVITIES_FETCHING")
		},
		Versions: {
			CREATE_VERSION: new ActionType("CREATE_VERSION"),
			SET_CURRENT_VERSION: new ActionType("SET_CURRENT_VERSION"),
			SET_VERSIONS: new ActionType("SET_VERSIONS"),
			PUBLISH_VERSION: new ActionType("PUBLISH_VERSION")
		},
		Users: {
			CREATE_USER: new ActionType("CREATE_USER"),
			UPDATE_USER: new ActionType("UPDATE_USER"),
			DELETE_USER: new ActionType("DELETE_USER"),
			SET_ALL_USERS: new ActionType("SET_ALL_USERS"),
			SET_USERS: new ActionType("SET_USERS")
		}
	},
	Actions: {
		CREATE_ACTION_SET: new ActionType("CREATE_ACTION_SET"),
		DELETE_ACTION_SET: new ActionType("DELETE_ACTION_SET"),
		RESET_ACTION_SETS: new ActionType("RESET_ACTION_SETS"),
		SET_ACTION_SETS: new ActionType("SET_ACTION_SETS"),
		UPDATE_ACTION_SET: new ActionType("UPDATE_ACTION_SET")
	},
	Ads: {
		CREATE_AD: new ActionType("CREATE_AD"),
		DELETE_AD: new ActionType("DELETE_AD"),
		RESET_ADS: new ActionType("RESET_ADS"),
		RESET_ANALYTICS: new ActionType("RESET_ANALYTICS"),
		SET_ADS: new ActionType("SET_ADS"),
		SET_ANALYTICS_REPORT_PENDING: new ActionType("SET_ANALYTICS_REPORT_PENDING"),
		SET_NOTIFICATION_SETTINGS: new ActionType("SET_NOTIFICATION_SETTINGS"),
		UPDATE_AD: new ActionType("UPDATE_AD"),
		UPDATE_AD_ANALYTICS: new ActionType("UPDATE_AD_ANALYTICS"),
		UPDATE_AD_ANALYTICS_DATA: new ActionType("UPDATE_AD_ANALYTICS_DATA"),
		UPDATE_AD_ANALYTICS_NOTIFICATION_SETTINGS: new ActionType("UPDATE_AD_ANALYTICS_NOTIFICATION_SETTINGS")
	},
	Company: {
		CREATE_COMPANY: new ActionType("CREATE_COMPANY"),
		CREATE_MANAGED_COMPANY: new ActionType("CREATE_MANAGED_COMPANY"),
		CREATE_STORE: new ActionType("CREATE_STORE"),
		DELETE_COMPANY: new ActionType("DELETE_COMPANY"),
		DELETE_STORE: new ActionType("DELETE_STORE"),
		RESET_TEAMS: new ActionType("RESET_TEAMS"),
		SET_ACTIVE_COMPANY: new ActionType("SET_ACTIVE_COMPANY"),
		SET_COMPANIES: new ActionType("SET_COMPANIES"),
		SET_MANAGED_COMPANIES: new ActionType("SET_MANAGED_COMPANIES"),
		SET_MANAGED_COMPANIES_TEAMS: new ActionType("SET_MANAGED_COMPANIES_TEAMS"),
		SET_MANAGED_COMPANIES_TEAMS_USERS: new ActionType("SET_MANAGED_COMPANIES_TEAMS_USERS"),
		SET_MANAGED_COMPANY: new ActionType("SET_MANAGED_COMPANY"),
		SET_ADMIN_TEAMS: new ActionType("SET_ADMIN_TEAMS"),
		SET_STORES: new ActionType("SET_STORES"),
		SET_TEAMS: new ActionType("SET_TEAMS"),
		UPDATE_COMPANY: new ActionType("UPDATE_COMPANY"),
		UPDATE_COMPANIES: new ActionType("UPDATE_COMPANIES"),
		UPDATE_STORE: new ActionType("UPDATE_STORE")
	},
	Deployments: {
		CREATE_DEPLOYMENT: new ActionType("CREATE_DEPLOYMENT"),
		CANCEL_DEPLOYMENT: new ActionType("CANCEL_DEPLOYMENT"),
		DELETE_DEPLOYMENT: new ActionType("DELETE_DEPLOYMENT"),
		RESET_DEPLOYMENTS: new ActionType("RESET_DEPLOYMENTS"),
		SET_DEPLOYMENTS: new ActionType("SET_DEPLOYMENTS"),
		SET_DEPLOYMENT_DETAILS: new ActionType("SET_DEPLOYMENT_DETAILS"),
		SET_APPROVERS: new ActionType("SET_APPROVERS")
	},
	Devices: {
		SET_DEVICE_MODELS: new ActionType("SET_DEVICE_MODELS"),
		SET_DEVICES: new ActionType("SET_DEVICES"),
		UPDATE_DEVICE: new ActionType("UPDATE_DEVICE"),
		DELETE_DEVICE: new ActionType("DELETE_DEVICE"),
		SET_DEVICE_GROUPS: new ActionType("SET_DEVICE_GROUPS"),
		UPDATE_DEVICE_GROUP: new ActionType("UPDATE_DEVICE_GROUP"),
		DELETE_DEVICE_GROUP: new ActionType("DELETE_DEVICE_GROUP"),
		SELECT_DEVICES: new ActionType("SELECT_DEVICES"),
		CREATE_DEVICE: new ActionType("CREATE_DEVICE"),
		RELEASE_DEVICES: new ActionType("RELEASE_DEVICES"),
		RESET_DEVICES: new ActionType("RESET_DEVICES"),
		CREATE_DEVICE_GROUP: new ActionType("CREATE_DEVICE_GROUP")
	},
	HealthReports: {
		CREATE_REPORT: new ActionType("CREATE_REPORT"),
		DELETE_REPORT: new ActionType("DELETE_REPORT"),
		GET_ALL_REPORTS: new ActionType("GET_ALL_REPORTS"),
		RESET_REPORTS: new ActionType("RESET_REPORTS"),
		SET_REPORT_RESULT: new ActionType("SET_REPORT_RESULT"),
		SET_REPORT_DOWNLOADING: new ActionType("SET_REPORT_DOWNLOADING"),
		UPDATE_HEALTH_REPORTS_NOTIFICATION_SETTINGS: new ActionType("UPDATE_HEALTH_REPORTS_NOTIFICATION_SETTINGS"),
		UPDATE_REPORT: new ActionType("UPDATE_REPORT"),
		RUN_REPORT: new ActionType("RUN_REPORT")
	},
	Media: {
		CREATE_MEDIA: new ActionType("CREATE_MEDIA"),
		DELETE_MEDIA: new ActionType("DELETE_MEDIA"),
		RESET_MEDIA: new ActionType("RESET_MEDIA"),
		SET_MEDIA: new ActionType("SET_MEDIA"),
		UPDATE_MEDIA: new ActionType("UPDATE_MEDIA"),
		START_UPLOAD: new ActionType("START_UPLOAD"),
		UPDATE_PROGRESS: new ActionType("UPDATE_PROGRESS"),
		END_UPLOAD: new ActionType("END_UPLOAD"),
		CLEAR_UPLOADS: new ActionType("CLEAR_UPLOADS")
	},
	Permissions: {
		RESET_PERMISSIONS: new ActionType("RESET_PERMISSIONS"),
		SET_PERMISSIONS: new ActionType("SET_PERMISSIONS")
	},
	Playlists: {
		CREATE_PLAYLIST: new ActionType("CREATE_PLAYLIST"),
		DELETE_PLAYLIST: new ActionType("DELETE_PLAYLIST"),
		RESET_PLAYLISTS: new ActionType("RESET_PLAYLISTS"),
		SET_PLAYLISTS: new ActionType("SET_PLAYLISTS"),
		UPDATE_PLAYLIST: new ActionType("UPDATE_PLAYLIST")
	},
	Pusher: {
		SET_LAST_NOTIFICATION: new ActionType("SET_LAST_NOTIFICATION"),
		USER_JOINED_CHANNEL: new ActionType("USER_JOINED_CHANNEL"),
		USER_LEFT_CHANNEL: new ActionType("USER_LEFT_CHANNEL"),
		RESET_CHANNEL: new ActionType("RESET_CHANNEL")
	},
	RESET: new ActionType("RESET"),
	Roles: {
		SET_ROLES_LIST: new ActionType("SET_ROLES_LIST"),
		SET_EDIT_ROLES_LIST: new ActionType("SET_EDIT_ROLES_LIST")
	},
	UI: {
		ActionSetBuilder: {
			SET_SELECTED_ACTION_COMPONENT: new ActionType("SET_SELECTED_ACTION_COMPONENT")
		},
		AdBuilder: {
			DELETE_COMPONENT: new ActionType("DELETE_COMPONENT"),
			MOVE_COMPONENT: new ActionType("MOVE_COMPONENT"),
			RESET_AD_BUILDER: new ActionType("RESET_AD_BUILDER"),
			SET_ACTIVE_AD: new ActionType("SET_ACTIVE_AD"),
			SET_COMPONENT_RESIZING: new ActionType("SET_COMPONENT_RESIZING"),
			SET_SELECTED_COMPONENT: new ActionType("SET_SELECTED_COMPONENT"),
			SET_SLIDESHOW_SLIDE: new ActionType("SET_SLIDESHOW_SLIDE"),
			UPDATE_COMPONENT: new ActionType("UPDATE_COMPONENT"),
			UPDATE_AD_MEDIA: new ActionType("UPDATE_AD_MEDIA"),
			UPDATE_ALL_AD_MEDIA: new ActionType("UPDATE_ALL_AD_MEDIA"),
			UPDATE_LIVE_PREVIEW: new ActionType("UPDATE_LIVE_PREVIEW")
		},
		DeploymentWizard: {
			DELETE_DEPLOYMENT_SCHEDULE_ITEM: new ActionType("DELETE_DEPLOYMENT_SCHEDULE_ITEM"),
			MOVE_DEPLOYMENT_SCHEDULE_ITEM: new ActionType("MOVE_DEPLOYMENT_SCHEDULE_ITEM"),
			RESIZE_DEPLOYMENT_SCHEDULE_ITEM: new ActionType("RESIZE_DEPLOYMENT_SCHEDULE_ITEM"),
			SET_ACTIVE_DEPLOYMENT: new ActionType("SET_ACTIVE_DEPLOYMENT"),
			SET_ACTIVE_DEPLOYMENT_SCHEDULE: new ActionType("SET_ACTIVE_DEPLOYMENT_SCHEDULE"),
			SET_DEPLOYMENT_SCHEDULE_DATES: new ActionType("SET_DEPLOYMENT_SCHEDULE_DATES"),
			SET_DEPLOYMENT_SCHEDULED: new ActionType("SET_DEPLOYMENT_SCHEDULED"),
			SET_DEPLOYMENT_STEP: new ActionType("SET_DEPLOYMENT_STEP")
		},
		Modals: {
			SET_CHECKIN_DEVICE_MODAL: new ActionType("SET_CHECKIN_DEVICE_MODAL"),
			SET_CREATE_STORE_MODAL: new ActionType("SET_CREATE_STORE_MODAL"),
			SET_CREATE_USER_MODAL: new ActionType("SET_CREATE_USER_MODAL"),
			SET_COMPANY_CREATE_MODAL_VISIBILITY: new ActionType("SET_COMPANY_CREATE_MODAL_VISIBILITY"),
			SET_COMPANY_REQUEST_MODAL_VISIBILITY: new ActionType("SET_COMPANY_REQUEST_MODAL_VISIBILITY"),
			SET_DEPLOYMENT_APPROVAL_MODAL_UUID: new ActionType("SET_DEPLOYMENT_APPROVAL_MODAL_UUID"),
			SET_DEPLOYMENT_APPROVAL_MODAL_VISIBILITY: new ActionType("SET_DEPLOYMENT_APPROVAL_MODAL_VISIBILITY"),
			SET_DEVICE_ERROR_LOG_MODAL: new ActionType("SET_DEVICE_ERROR_LOG_MODAL"),
			SET_DEVICE_GROUP_TREE_BROWSER_MODAL: new ActionType("SET_DEVICE_GROUP_TREE_BROWSER_MODAL"),
			SET_DEVICE_HEALTH_MODAL_UUID: new ActionType("SET_DEVICE_HEALTH_MODAL_UUID"),
			SET_MEDIA_PREVIEW_MODAL_VISIBILITY: new ActionType("SET_MEDIA_PREVIEW_MODAL_VISIBILITY"),
			SET_PRIVACY_POLICY_MODAL_VISIBILITY: new ActionType("SET_PRIVACY_POLICY_MODAL_VISIBILITY"),
			SET_REQUEST_NAME_MODAL: new ActionType("SET_REQUEST_NAME_MODAL_VISIBILITY"),
			SET_SELECT_ACTIONS_MODAL: new ActionType("SET_SELECT_ACTIONS_MODAL"),
			SET_SELECT_ADS_MODAL: new ActionType("SET_SELECT_ADS_MODAL"),
			SET_SELECT_MEDIA_MODAL: new ActionType("SET_SELECT_MEDIA_MODAL"),
			SET_SELECT_PLAYLISTS_MODAL: new ActionType("SET_SELECT_PLAYLISTS_MODAL"),
			SET_TEMPLATES_LIST_MODAL: new ActionType("SET_TEMPLATES_LIST_MODAL"),
			SET_TERMS_CONDITIONS_MODAL_VISIBILITY: new ActionType("SET_TERMS_CONDITIONS_MODAL_VISIBILITY")
		},
		ADD_ACTIVE_SELECTION: new ActionType("ADD_ACTIVE_SELECTION"),
		SET_ACTIVE_FILTERS: new ActionType("SET_ACTIVE_FILTERS"),
		SET_ACTIVE_FILTER_ARRAY: new ActionType("SET_ACTIVE_FILTER_ARRAY"),
		SET_ACTIVE_SEARCH: new ActionType("SET_ACTIVE_SEARCH"),
		SET_ACTIVE_SELECTION: new ActionType("SET_ACTIVE_SELECTION"),
		REMOVE_ACTIVE_SELECTION: new ActionType("REMOVE_ACTIVE_SELECTION"),
		REMOVE_ACTIVE_SELECTION_ITEM: new ActionType("REMOVE_ACTIVE_SELECTION_ITEM"),
		SET_ACTIVE_SORT: new ActionType("SET_ACTIVE_SORT"),
		SET_ACTIVE_TAGS: new ActionType("SET_ACTIVE_TAGS"),
		SET_ACTIVE_UUID: new ActionType("SET_ACTIVE_UUID"),
		SET_ACTIVE_VIEW: new ActionType("SET_ACTIVE_VIEW"),
		SET_ASYNC_FETCHING: new ActionType("SET_ASYNC_FETCHING"),
		SET_ASYNC_STATE: new ActionType("SET_ASYNC_STATE"),
		CREATE_ASYNC_STATE: new ActionType("CREATE_ASYNC_STATE"),
		SET_LEFT_ACCORDION_EXPANDED: new ActionType("SET_LEFT_ACCORDION_EXPANDED"),
		SET_COLLAPSE_EXPANDED: new ActionType("SET_COLLAPSE_EXPANDED"),
		ADD_RUNNING_REPORT: new ActionType("ADD_RUNNING_REPORT"),
		REMOVE_RUNNING_REPORT: new ActionType("REMOVE_RUNNING_REPORT"),
		ADD_REPORT_NOTIFICATION: new ActionType("ADD_REPORT_NOTIFICATION"),
		REMOVE_REPORT_NOTIFICATION: new ActionType("REMOVE_REPORT_NOTIFICATION"),
		SET_DEVICE_UPDATING_ADS: new ActionType("SET_DEVICE_UPDATING_ADS"),
		SET_DEVICE_REBOOTING: new ActionType("SET_DEVICE_REBOOTING"),
		SET_PING_STATUS: new ActionType("SET_PING_STATUS"),
		SET_DEVICE_SCREENSHOT_STATUS: new ActionType("SET_DEVICE_SCREENSHOT_STATUS"),
		SET_DEVICE_SCREENSHOT_TIME: new ActionType("SET_DEVICE_SCREENSHOT_TIME"),
		SET_DEVICE_DATABASE_STATUS: new ActionType("SET_DEVICE_DATABASE_STATUS"),
		SET_DEVICE_ERROR_LOG_STATUS: new ActionType("SET_DEVICE_ERROR_LOG_STATUS"),
		SET_DEVICE_CLEAR_CACHE_STATUS: new ActionType("SET_DEVICE_CLEAR_CACHE_STATUS"),
		SET_DEVICE_SCREENSHOT_URL: new ActionType("SET_DEVICE_SCREENSHOT_URL"),
		SET_DEVICE_DB_URL: new ActionType("SET_DEVICE_DB_URL"),
		SET_DEVICE_ERRORS: new ActionType("SET_DEVICE_ERRORS"),
		SET_DEVICE_SNAPSHOT_STATUS: new ActionType("SET_DEVICE_SNAPSHOT_STATUS"),
		SET_DEVICE_SPEED_TEST_RESULT: new ActionType("SET_DEVICE_SPEED_TEST_RESULT"),
		SET_DEVICE_LAST_SEEN: new ActionType("SET_DEVICE_LAST_SEEN"),
		SET_DEVICE_SOFTWARE_UPDATING: new ActionType("SET_DEVICE_SOFTWARE_UPDATING"),
		SET_EXPANDED_DEVICE_GROUPS: new ActionType("SET_EXPANDED_DEVICE_GROUPS"),
		SET_MENU_ITEMS: new ActionType("SET_MENU_ITEMS"),
		UPDATE_MENU_BADGE: new ActionType("UPDATE_MENU_BADGE"),
		SET_MEDIA_PREVIEW: new ActionType("SET_MEDIA_PREVIEW"),
		RESET_ACTIVE_FILTERS: new ActionType("RESET_ACTIVE_FILTERS"),
		RESET_ACTIVE_SEARCH: new ActionType("RESET_ACTIVE_SEARCH"),
		RESET_ACTIVE_SELECTION: new ActionType("RESET_ACTIVE_SELECTION"),
		RESET_ACTIVE_SORTS: new ActionType("RESET_ACTIVE_SORTS"),
		RESET_ACTIVE_TAGS: new ActionType("RESET_ACTIVE_TAGS"),
		RESET_ACTIVE_UUIDS: new ActionType("RESET_ACTIVE_UUIDS"),
		RESET_ACTIVE_VIEWS: new ActionType("RESET_ACTIVE_VIEWS"),
		RESET_ASYNC_STATES: new ActionType("RESET_ASYNC_STATES"),
		RESET_DEVICE_HEALTH_MODAL_STATE: new ActionType("RESET_DEVICE_HEALTH_MODAL_STATE"),
		RESET_EXPANDED_DEVICE_GROUPS: new ActionType("RESET_EXPANDED_DEVICE_GROUPS"),
		RESET_FINISHED_REPORTS: new ActionType("RESET_FINISHED_REPORTS"),
		RESET_RUNNING_REPORTS: new ActionType("RESET_RUNNING_REPORTS")
	},
	User: {
		LOGIN_USER: new ActionType("LOGIN_USER"),
		LOGOUT_USER: new ActionType("LOGOUT_USER"),
		SET_USER: new ActionType("SET_USER"),
		SET_TOKEN: new ActionType("SET_TOKEN"),
		VERIFY_USER: new ActionType("VERIFY_USER"),
		UPDATE_EMAIL_PREFERENCES: new ActionType("UPDATE_EMAIL_PREFERENCES"),
		UPDATE_WIDGETS: new ActionType("UPDATE_WIDGETS"),
		TOGGLE_TWO_FACTOR: new ActionType("ENABLE_TWO_FACTOR"),
		UPDATE_PERMISSIONS: new ActionType("UPDATE_PERMISSIONS")
	},
	System: {
		SET_CONNECTED_STATUS: new ActionType("SET_CONNECTED_STATUS"),
		SET_CURRENT_APK_VERSION: new ActionType("SET_CURRENT_APK_VERSION"),
		SET_FEATURE_TOGGLES: new ActionType("SET_FEATURE_TOGGLES"),
		SET_REDIRECT: new ActionType("SET_REDIRECT"),
		SET_UNSAVED: new ActionType("SET_UNSAVED"),
		SET_VIMEO_VIDEOS: new ActionType("SET_VIMEO_VIDEOS"),
		CLEAR_CACHE: new ActionType("CLEAR_CACHE"),
		SET_PUSHER_CREDENTIALS: new ActionType("SET_PUSHER_CREDENTIALS")
	}
}
import { Button, Card, Form } from "antd";
import * as update from "immutability-helper";
import { parse } from "query-string";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { CustomCSS, PasswordRequirements, InputValidator } from "@connect/Interfaces";
import { Input, Loader } from "Components/Global/Common";
import { resetPassword, verifyEmail } from "Data/Actions/UserAsync";
import {
	minSpecialCharsValidator,
	minCharsValidator,
	minLowerCaseValidator,
	minUpperCaseValidator,
	minNumbersValidator
} from "Data/Objects/Validation";

const FormItem = Form.Item;

const mapStateToProps = (state, { location }) => {
	const { pathname, search } = location;
	const { token, policy } = parse(search);
	const reset = pathname.includes("reset");
	const verify = pathname.includes("verify");
	let passwordRequirements = {};

	if (policy) {
		passwordRequirements = JSON.parse(window.atob(decodeURIComponent(policy)))
	}

	return ({
		setPassword: !!reset,
		token,
		passwordRequirements,
		verify: !!verify
	});
}

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (token, newValue, verifyValue) =>
		dispatch(resetPassword(token, newValue, verifyValue)),
	verifyEmail: (token) => dispatch(verifyEmail(token))
});

interface ITokenVerifyPageProps extends RouteComponentProps<any> {
	setPassword: boolean;
	token: string;
	resetPassword: (token: string, newValue: string, verifyValue: string) => Promise<void | ((dispatch: any) => void)>;
	passwordRequirements: PasswordRequirements;
	verify: boolean;
	verifyEmail: (token: string) => void;
}

interface ITokenVerifyPageState {
	newPassword: string;
	verifyNewPassword: string;
	loaderVisibility: boolean;
	newPasswordVisibility: boolean;
	canSubmit: boolean;
}

class TokenVerifyPage extends React.Component<ITokenVerifyPageProps, ITokenVerifyPageState> {
	constructor(props: ITokenVerifyPageProps) {
		super(props);

		this.state = {
			newPassword: "",
			verifyNewPassword: "",
			loaderVisibility: false,
			newPasswordVisibility: false,
			canSubmit: false
		};

		this.styles = {
			borderRadius: {
				borderRadius: 6
			},
			button: {
				width: "100%"
			},
			card: {
				width: "30em"
			},
			label: {
				color: "#8e8e8e",
				fontSize: "larger"
			},
			wrapper: {
				backgroundColor: "#2e333f",
				position: "absolute",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 1000
			},
			wrapperInner: {
				display: "flex",
				height: "100%",
				alignItems: "center",
				justifyContent: "center"
			}
		};

		this.setLoader = this.setLoader.bind(this);
		this.submitPasswordVerification = this.submitPasswordVerification.bind(this);
	}

	styles: CustomCSS;

	componentWillMount() {
		const { setPassword, verify } = this.props;

		if (setPassword) {
			this.setState((prevState) => {
				return update(prevState, {
					newPasswordVisibility: { $set: true }
				});
			});
		}

		if (verify && !setPassword) {
			this.verifyEmail();
		}
	}

	render() {
		const { wrapper, wrapperInner } = this.styles;
		const { loaderVisibility } = this.state;

		return (
			<div style={wrapper}>
				<div style={wrapperInner}>
					{
						loaderVisibility
							? <Loader loading={loaderVisibility} />
							: this.renderPasswordCard()
					}
				</div>
			</div>
		);
	}

	renderPasswordCard() {
		const {  newPassword, newPasswordVisibility, verifyNewPassword } = this.state;
		const { borderRadius, button, card, label } = this.styles;
		const { passwordRequirements } = this.props;
		const { minChars, minLowerCase, minUpperCase, minNumbers, minSpecialChars } = passwordRequirements;
		const formLabel = (item: string) => (<span style={label}>{item}</span>);
		const doesNotMatch = newPassword.length > 0 && newPassword !== verifyNewPassword;
		let error = "";
		let status: "success" | "error" = "success";

		if (doesNotMatch) {
			error = "Password must match.";
			status = "error";
		}

		const passwordValidator: InputValidator[] = [
			minCharsValidator(minChars),
			minLowerCaseValidator(minLowerCase),
			minUpperCaseValidator(minUpperCase),
			minNumbersValidator(minNumbers),
			minSpecialCharsValidator(minSpecialChars)
		]

		return (
			<Card
				style={{
					...card,
					display: newPasswordVisibility ? "block" : "none"
				}}
				title="Create Your New Password"
			>
				<Form
					layout="vertical"
					onSubmit={this.submitPasswordVerification}
				>
					<FormItem
						label={formLabel("New Password")}
						colon={false}
						hasFeedback={false}
					>
						<Input type="password"
							id="newPass"
							style={ borderRadius }
							updateCallback={ this.updateNewPassword }
							value=""
							validator={ passwordValidator }
							disableSubmitCallback={ this.disableSubmitButton }
						/>
					</FormItem>
					<FormItem
						label={ formLabel("Verify Password") }
						colon={ false }
						hasFeedback={ false }
						validateStatus={ status }
						help={ error }
					>
						<Input type="password"
							id="newPassConfirm"
							style={borderRadius}
							updateCallback={ this.updateVerifyNewPassword }
							value=""
						/>
					</FormItem>
					<FormItem>
						<Button
							htmlType="submit"
							size="large"
							style={button}
							type="primary"
							disabled={ !this.state.canSubmit || doesNotMatch }
						>
							Verify
						</Button>
					</FormItem>
				</Form>
			</Card>
		);
	}

	disableSubmitButton = (canSubmit: boolean) => {
		this.setState(() => ({ canSubmit }));
	}

	resetPassword() {
		const { resetPassword: resetPasswordAction, token } = this.props;
		const {
			verifyNewPassword,
			newPassword
		} = this.state;

		this.setState((prevState) => {
			return update(prevState, {
				loaderVisibility: { $set: true }
			});
		});

		resetPasswordAction(token, newPassword, verifyNewPassword);

		this.setLoader(false);
	}

	updateState(key: string, value: string | boolean) {
		this.setState((state) => {
			return update(state, {
				[key]: { $set: value }
			});
		});
	}

	updateNewPassword = (password: string) => {
		this.updateState("newPassword", password)
	}

	updateVerifyNewPassword = (password: string) => {
		this.updateState("verifyNewPassword", password)
	}

	setLoader(value: boolean) {
		this.setState((prevState) => update(prevState, {
			loaderVisibility: { $set: value }
		}));
	}

	submitPasswordVerification(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const { verifyNewPassword, newPassword } = this.state;

		if (newPassword !== verifyNewPassword || !this.state.canSubmit) {
			return;
		}

		return this.resetPassword();
	}

	verifyEmail() {
		const { token, verifyEmail: verifyEmailAction } = this.props;

		this.setLoader(true);

		verifyEmailAction(token);

		this.setLoader(false);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenVerifyPage);
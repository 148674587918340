import { Action, PlaylistDispatch, PlaylistListDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { PlaylistState } from "Data/Objects/AppState";
import {
	createReducer,
	deleteFromState,
	getIndexFromState,
	mergeWithUuidArrays,
	pushToState,
	setState,
	updateStateArray,
	updateStateArrayItem
} from "Data/Utils";
import { toggleFeature } from "@connect/Features";

export function createPlaylist(state: PlaylistState, action: Action<PlaylistDispatch>) {
	return pushToState(state, "playlists", action.args.playlist);
}

export function deletePlaylist(state: PlaylistState, action: Action<PlaylistDispatch>) {
	const index = getIndexFromState(state.playlists, action.args.playlist.uuid)
	return deleteFromState(state, "playlists", index);
}

export function resetPlaylists(state: PlaylistState, action: Action<null>) {
	return setState(state, "playlists", []);
}

export function setPlaylists(state: PlaylistState, action: Action<PlaylistListDispatch>) {
	let { playlists, reset } = action.args;

	if (reset) {
		return setState(state, "playlists", playlists);
	}

	return mergeWithUuidArrays(state, "playlists", playlists);
}

export function updatePlaylist(state: PlaylistState, action: Action<PlaylistDispatch>) {
	const { playlist } = action.args;

	if (toggleFeature("notifications", true, false)) {
		return updatePlaylist2(state, action);
	}

	const index = getIndexFromState(state.playlists, playlist.uuid)

	return updateStateArray(state, "playlists", index, playlist);
}

export function updatePlaylist2(state: PlaylistState, action: Action<PlaylistDispatch>) {
	const { playlist } = action.args;
	return updateStateArrayItem(state, "playlists", playlist);
}

const {
	CREATE_PLAYLIST,
	DELETE_PLAYLIST,
	RESET_PLAYLISTS,
	SET_PLAYLISTS,
	UPDATE_PLAYLIST
} = ACTION_TYPES.Playlists;

const reducers = {
	[CREATE_PLAYLIST.type]: createPlaylist,
	[DELETE_PLAYLIST.type]: deletePlaylist,
	[RESET_PLAYLISTS.type]: resetPlaylists,
	[SET_PLAYLISTS.type]: setPlaylists,
	[UPDATE_PLAYLIST.type]: updatePlaylist
};

export default createReducer(reducers, PlaylistState);

import * as update from "immutability-helper";
import * as qrious from "qrious";

import { store as reduxStore } from "@connect/Data";
import { Action, CompaniesDispatch, CompanyDispatch, DeviceDispatch, ICompany, IDevice, IStore,
	ManagedCompaniesDispatch, ManagedCompaniesTeamsDispatch, ManagedCompanyDispatch, MediaDispatch, StoreDispatch,
	StoresDispatch, TeamsDispatch, UserLoginDispatch } from "@connect/Interfaces";
import { QriousConfig } from "Components/Devices/Constants";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { AppState, CompanyState } from "Data/Objects/AppState";
import { Company } from "Data/Objects/Company";
import { getStoreByUUID } from "Data/Selectors/Company";
import { getDeviceByUUID } from "Data/Selectors/UI";
import {
	addDataToState,
	createReducer,
	mergeStateArray,
	mergeWithState,
	mergeWithUuidArrays,
	setState,
	updateStateArrayItem,
	updateStateItem,
	deleteFromState,
	pushToState
} from "Data/Utils";
import { toggleFeature } from "@connect/Features";
import { cloneDeep } from "lodash"

export function createCompany(state: CompanyState, action: Action<CompanyDispatch>) {
	return update(state, {
		companies: { $unshift: [ action.args.company ] }
	});
}

export function createManagedCompany(state: CompanyState, action: Action<CompanyDispatch>) {
	const newManagedCompany = {
		...action.args.company,
		devicesCount: 0,
		owners: [],
		integrators: []
	}
	return update(state, {
		managedCompanies: { $unshift: [ newManagedCompany ] }
	});
}

export function createStore(state: CompanyState, action: Action<StoreDispatch>) {
	return pushToState(state, "stores", action.args.store);
}

export function deleteCompany(state: CompanyState, action: Action<CompanyDispatch>) {
	const companyIndex = getIndex(state, "companies", action.args.company);
	const managedCompanyIndex = getIndex(state, "managedCompanies", action.args.company)
	let newState = cloneDeep(state);

	if (companyIndex !== -1) {
		newState = update(newState, {
			companies: { $splice: [ [ companyIndex, 1 ] ] }
		});
	}

	if (managedCompanyIndex !== -1) {
		newState = update(newState, {
			managedCompanies: { $splice: [ [ managedCompanyIndex, 1 ] ]}
		});
	}

	return newState;
}

export function deleteStore(state: CompanyState, action: Action<StoreDispatch>) {
	const index = getIndex(state, "stores", action.args.store);

	return deleteFromState(state, "stores", index);
}

export function generateQrCode(hashId: string) {
	let qrConfig = new QriousConfig();

	qrConfig.size = 2000;
	qrConfig.value = JSON.stringify({ id: hashId });

	const qr = new qrious(qrConfig);

	return { qrBase64: qr.toDataURL() };
}

export function getIndex(state: CompanyState, key: string, value: ICompany | IStore): number {
	return state[key]
		.map(c => c.uuid)
		.indexOf(value.uuid);
}

export function loginUser(state: CompanyState, action: Action<UserLoginDispatch>) {
	const { company } = action.args;

	return update(state, {
		activeCompanyId: { $set: company.uuid },
		activeCompany: { $set: company }
	});
}

export function logoutUser(state: CompanyState, action: Action<null>) {
	return update(state, {
		activeCompanyId: { $set: "" },
		activeCompany: { $set: new Company() },
		companies: { $set: [] }
	});
}

export function setActiveCompany(state: CompanyState, action: Action<string>) {
	const newActiveCompany = state.companies
		.filter(({ uuid }) => uuid === action.args)[0] || new Company();

	return update(state, {
		activeCompany: { $set: newActiveCompany },
		activeCompanyId: { $set: action.args }
	});
}

export function resetTeams(state: CompanyState, action: Action<null>) {
	return setState(state, "teams", []);
}

export function setCompanies(state: CompanyState, action: Action<CompaniesDispatch>) {
	return setState(state, "companies", action.args.companies || []);
}

export function setManagedCompanies(state: CompanyState, action: Action<ManagedCompaniesDispatch>) {
	const { companies, update: shouldUpdate } = action.args;

	if (shouldUpdate) {
		return addDataToState(state, "managedCompanies", companies || []);
	}

	return setState(state, "managedCompanies", companies || []);
}

export function setManagedCompaniesTeams(state: CompanyState, action: Action<ManagedCompaniesTeamsDispatch>) {
	const { company, teams } = action.args;
	const { managedCompanies } = state;
	const companyIndex = managedCompanies.findIndex(({ uuid }) => uuid === company);
	const managedCompany = managedCompanies[companyIndex];
	const newCompany = Object.assign({}, managedCompany, {
		integrators: teams.map(({ key, label }) => {
			const integrator = managedCompany.integrators && managedCompany.integrators.find(({ uuid }) => uuid === key);

			if (!integrator) {
				return { name: label, users: [], uuid: key };
			}

			return integrator;
		})
	});

	return mergeWithState(state, "managedCompanies", companyIndex, newCompany);
}

export function setManagedCompany(state: CompanyState, action: Action<ManagedCompanyDispatch>) {
	const { company } = action.args;
	const index = state.managedCompanies.map((managedCompany) => managedCompany.uuid).indexOf(company.uuid);
	return mergeWithState(state, "managedCompanies", index, company);
}

export function setStores(state: CompanyState, action: Action<StoresDispatch>) {
	const { reset, stores } = action.args;
	if (reset) {
		return setState(state, "stores", stores);
	}
	return mergeWithUuidArrays(state, "stores", stores || []);
}

export function setAdminTeams(state: CompanyState, action: Action<TeamsDispatch>) {
	return setState(state, "adminTeams", action.args.teams || []);
}

export function setTeams(state: CompanyState, action: Action<TeamsDispatch>) {
	return setState(state, "teams", action.args.teams || []);
}

export function updateCompany(state: CompanyState, action: Action<CompanyDispatch>) {
	if (toggleFeature("notifications", true, false)) {
		return updateCompany2(state, action);
	}

	const companyIndex = state.companies
		.map(c => c.uuid)
		.indexOf(action.args.company.uuid);
	const managedCompanyIndex = state.managedCompanies
		.map(c => c.uuid)
		.indexOf(action.args.company.uuid);
	const updateIsActive = action.args.company.uuid === state.activeCompany.uuid;
	const hashId = action.args.company.hashid;
	let qrCode;
	if (hashId) {
		qrCode = generateQrCode(hashId);
	}
	let updatedCompany = Object.assign({}, action.args.company, qrCode);

	if (updatedCompany.permissions) {
		delete updatedCompany.permissions;
	}

	return update(state, {
		activeCompany: { $set: updateIsActive ? action.args.company : state.activeCompany },
		companies: {
			[companyIndex]: { $set: updatedCompany }
		},
		managedCompanies: {
			[managedCompanyIndex]: { $set: updatedCompany }
		}
	});
}

export function updateCompany2(state: CompanyState, action: Action<CompanyDispatch>) {
	const { company } = action.args;

	let updatedState = updateStateArrayItem(state, "companies", company);
	updatedState = updateStateArrayItem(updatedState, "managedCompanies", company);

	if (state.activeCompanyId === company.uuid) {
		updatedState = updateStateItem(updatedState, "activeCompany", company);
	}

	return updatedState;
}

export function updateCompanies(state: CompanyState, action: Action<CompaniesDispatch>) {
	const { companies, reset } = action.args;

	if (reset) {
		return setState(state, "companies", companies)
	}

	return mergeStateArray(state, "companies", companies || []);
}

export function updateMediaUsage(state: CompanyState, action: Action<MediaDispatch>) {
	if (action.args.media.usage) {
		return update(state, {
			activeCompany: {
				mediaUsage: { $set: action.args.media.usage }
			}
		});
	}

	return state;
}

export function updateStore(state: CompanyState, action: Action<StoreDispatch>) {
	const incomingStore = action.args.store;

	if (toggleFeature("notifications", true, false)) {
		return updateStore2(state, action);
	}

	const storeIndex = state.stores.findIndex((store) => store.uuid === incomingStore.uuid);

	if (storeIndex === -1) {
		return update(state, {
			stores: { $push: [ incomingStore ] }
		});
	}

	return update(state, {
		stores: {
			[storeIndex]: { $set: incomingStore }
		}
	});
}

export function updateStore2(state: CompanyState, action: Action<StoreDispatch>) {
	const { store } = action.args;
	return updateStateArrayItem(state, "stores", store);
}

export function updateStoreDeviceCounts(state: CompanyState, action: Action<DeviceDispatch>) {
	const oldAppState: AppState = reduxStore.getState();
	const oldDevice: IDevice = getDeviceByUUID(oldAppState.Devices.devices, action.args.device.uuid);
	const newDevice = action.args.device;

	let newState = {...state};

	// If the store is changed, update our counts (or if we're checking in a new device)
	if ((newDevice && !oldDevice) || oldDevice.store !== newDevice.store && newDevice.store !== undefined) {
		let oldStore = getStoreByUUID(state.stores, oldDevice?.store);
		let newStore = getStoreByUUID(state.stores, newDevice?.store);

		if (oldStore && oldStore.devicesCount >= 1) {
			oldStore = {
				...oldStore,
				devicesCount: oldStore.devicesCount - 1
			};
			newState = updateStore(newState, {
				type: UPDATE_STORE.type,
				args: {
					store: oldStore
				}
			});
		}

		if (newStore) {
			newStore = {
				...newStore,
				devicesCount: newStore.devicesCount + 1
			};
			newState = updateStore(newState, {
				type: UPDATE_STORE.type,
				args: {
					store: newStore
				}
			});
		}
	}

	return newState;
}

const {
	LOGIN_USER,
	LOGOUT_USER
} = ACTION_TYPES.User;

const {
	CREATE_MEDIA
} = ACTION_TYPES.Media;

const {
	CREATE_COMPANY,
	CREATE_MANAGED_COMPANY,
	CREATE_STORE,
	DELETE_COMPANY,
	DELETE_STORE,
	RESET_TEAMS,
	SET_ACTIVE_COMPANY,
	SET_ADMIN_TEAMS,
	SET_COMPANIES,
	SET_MANAGED_COMPANIES,
	SET_MANAGED_COMPANIES_TEAMS,
	SET_MANAGED_COMPANY,
	SET_STORES,
	SET_TEAMS,
	UPDATE_COMPANY,
	UPDATE_COMPANIES,
	UPDATE_STORE
} = ACTION_TYPES.Company;

const {
	UPDATE_DEVICE
} = ACTION_TYPES.Devices;

const reducers = {
	[CREATE_COMPANY.type]: createCompany,
	[CREATE_MANAGED_COMPANY.type]: createManagedCompany,
	[CREATE_MEDIA.type]: updateMediaUsage,
	[CREATE_STORE.type]: createStore,
	[DELETE_COMPANY.type]: deleteCompany,
	[DELETE_STORE.type]: deleteStore,
	[LOGIN_USER.type]: loginUser,
	[LOGOUT_USER.type]: logoutUser,
	[RESET_TEAMS.type]: resetTeams,
	[SET_ACTIVE_COMPANY.type]: setActiveCompany,
	[SET_COMPANIES.type]: setCompanies,
	[SET_MANAGED_COMPANIES.type]: setManagedCompanies,
	[SET_MANAGED_COMPANIES_TEAMS.type]: setManagedCompaniesTeams,
	[SET_MANAGED_COMPANY.type]: setManagedCompany,
	[SET_STORES.type]: setStores,
	[SET_TEAMS.type]: setTeams,
	[SET_ADMIN_TEAMS.type]: setAdminTeams,
	[UPDATE_COMPANY.type]: updateCompany,
	[UPDATE_COMPANIES.type]: updateCompanies,
	[UPDATE_DEVICE.type]: updateStoreDeviceCounts,
	[UPDATE_STORE.type]: updateStore
};

export default createReducer(reducers, CompanyState);
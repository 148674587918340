import { Action, ActiveViewDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ActiveStringState } from "Data/Objects/AppState";
import { initialActiveViewStates } from "Data/Objects/UI";
import { createReducer, setState } from "Data/Utils";

export type PageUuidType = {
	page: string;
	uuid: string;
};

export function setActiveView(state: ActiveStringState, action: Action<ActiveViewDispatch>) {
	const { path, view } = action.args;

	return setState(state, path, view);
}

export function resetActiveViews(state: ActiveStringState, action: Action<null>) {
	return new ActiveStringState(initialActiveViewStates);
}

const reducers = {
	[ACTION_TYPES.UI.SET_ACTIVE_VIEW.type]: setActiveView,
	[ACTION_TYPES.UI.RESET_ACTIVE_VIEWS.type]: resetActiveViews
};

export default createReducer(reducers, ActiveStringState, initialActiveViewStates);

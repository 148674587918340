import { Modal, Tabs } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IDevice } from "@connect/Interfaces";
import DeviceInfoSection from "Components/Devices/DeviceHealthModal/DeviceInfoSection";
import DeviceScreenshotPanel from "Components/Devices/DeviceHealthModal/DeviceScreenshotPanel";
import DeviceSettingsPanel from "Components/Devices/DeviceHealthModal/DeviceSettingsPanel";
import DeviceStatusPanel from "Components/Devices/DeviceHealthModal/DeviceStatusPanel";
import DeviceSupportPanel from "Components/Devices/DeviceHealthModal/DeviceSupportPanel";
import { Icon } from "Components/Global/Common";
import { fetchUpdatedDeviceAsync } from "Data/Actions/Devices";
import { setDeviceHealthModalUUID } from "Data/Actions/UI/Modals";
import { fetchCurrentApk } from "Data/Actions/SystemAsync";
import { pingDeviceAsync } from "Data/Actions/UIAsync";
import { AppState } from "Data/Objects/AppState";
import { getDeviceHealthModalDevice, getDeviceHealthModalUUID } from "Data/Selectors/UI";

const { TabPane } = Tabs;

const mapDispatchToProps = (dispatch, ownProps: DeviceHealthModalProps) => {
	return {
		closeModal: () => dispatch(setDeviceHealthModalUUID("")),
		fetchUpdatedDevice: (uuid: string) => dispatch(fetchUpdatedDeviceAsync(uuid)),
		pingDevice: (uuid: string) => dispatch(pingDeviceAsync(uuid)),
		fetchApkVersion: () => dispatch(fetchCurrentApk())
	}
}

const mapStateToProps = (state: AppState) => {
	const selectedUUID = getDeviceHealthModalUUID(state);
	const device = getDeviceHealthModalDevice(state)
	return {
		selectedUUID,
		device
	}
}

interface DeviceHealthModalProps {
	selectedUUID: string;
	device: IDevice;
	closeModal: () => void;
	fetchUpdatedDevice: (uuid: string) => void;
	pingDevice: (uuid: string) => void;
	fetchApkVersion: () => void;
}

interface DeviceHealthModalState {

}

export class DeviceHealthModal extends React.Component<DeviceHealthModalProps, DeviceHealthModalState> {

	constructor(props: DeviceHealthModalProps) {
		super(props);

		this.styles = {
			headerContainer: {
				paddingBottom: 15
			},
			headerLeft: {
				float: "left"
			},
			headerRight: {
				float: "right"
			},
			modal: {
				height: 825
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { device, selectedUUID, closeModal } = this.props;

		if (!device) {
			return null;
		}

		return (
			<Modal
				style={this.styles.modal}
				key={selectedUUID}
				width={900}
				footer={null}
				title={this.renderHeader()}
				visible={selectedUUID !== null}
				onCancel={ closeModal } >
				<Tabs type="card">
					{this.renderTabs()}
				</Tabs>
			</Modal>
		);
	}

	componentDidMount() {
		// Default the modal to closed on page load
		this.props.closeModal();
	}

	componentDidUpdate(prevProps: DeviceHealthModalProps) {
		// Compare UUIDs and dispatch actions accordingly
		const { selectedUUID, pingDevice, fetchUpdatedDevice, fetchApkVersion } = this.props;
		if (selectedUUID && selectedUUID !== prevProps.selectedUUID) {
			fetchUpdatedDevice(selectedUUID);
			pingDevice(selectedUUID);
			fetchApkVersion();
		}
	}

	renderTabs() {
		const { device } = this.props;

		const tabs = [
			{
				icon: "info-circle",
				title: "Info",
				content: <DeviceInfoSection device={ device } />
			},
			{
				icon: "heartbeat",
				title: "Status",
				content: <DeviceStatusPanel />
			},
			{
				icon: "cog",
				title: "Settings",
				content: <DeviceSettingsPanel />
			},
			{
				icon: "image",
				title: "Screenshot",
				content: <DeviceScreenshotPanel selectedUUID={ this.props.selectedUUID } />
			},
			{
				icon: "wrench",
				title: "Support",
				content: <DeviceSupportPanel device={ device }/>
			}
		];

		return tabs.map(this.renderTab);
	}

	renderTab(tab: any, index: number) {
		const tabHeader = (
			<span><Icon name={tab.icon} /> {tab.title}</span>
		);

		return (
			<TabPane tab={tabHeader} key={ `${ index }` }>{tab.content}</TabPane>
		);
	}

	renderHeader() {
		const { headerContainer, headerLeft } = this.styles;

		return (
			<div style={ headerContainer }>
				<div style={ headerLeft }>
					<Icon name="cogs" /> Device Overview
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceHealthModal);
import * as React from "react";
import { connect } from "react-redux";

import { CompanyMenuOption, CustomCSS, ICompany, IUser } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon, Select, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { getActiveCompanyDetails } from "Data/Actions/Company";
import { getActiveCompany, getCompanyOptions } from "Data/Selectors/Company";
import { getCurrentUser } from "Data/Selectors/User";

const mapDispatchToProps = (dispatch) => ({
	setActiveCompany: (companyId: string) => dispatch(getActiveCompanyDetails(companyId))
});

const mapStateToProps = (state) => {
	const user = getCurrentUser(state);
	const company = getActiveCompany(state)

	return {
		companies: getCompanyOptions(state),
		company,
		selectedCompany: company && company.uuid,
		user
	};
}

interface IStatusBarProps {
	companies: CompanyMenuOption[];
	company: ICompany;
	selectedCompany: string;
	setActiveCompany: (companyId: string) => void;
	user: IUser;
}

class StatusBar extends React.PureComponent <IStatusBarProps, any> {
	constructor(props: IStatusBarProps) {
		super(props);

		this.styles = {
			companySelect: {
				minWidth: 150
			},
			icon: {
				paddingRight: 6,
				verticalAlign: "text-bottom"
			},
			name: {
				color: Colors.primaryBlue,
				marginLeft: 6
			},
			wrapper: {
				padding: "8px 8px 18px 8px"
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { user } = this.props;
		const { icon, name, wrapper } = this.styles;

		return (
			<div style={wrapper}>
				<Icon
					name="user"
					size="smaller"
					style={icon} />
				Logged in as:
				<span style={{
					...name,
					marginRight: 40
				}}>
					<Truncate end length={ 40 }>{user.name}</Truncate>
				</span>
				<Icon
					name="building"
					size="smaller"
					style={icon} />
				Company name:
				{ this.renderName() }
			</div>
		);
	}

	renderCompanyOption(company: CompanyMenuOption, index: number) {
		const { active, text, value } = company;

		return (
			<Select.Option value={ value } key={ value + index + active.toString() }>
				{ text }
			</Select.Option>
		);
	}

	renderName() {
		const { companies, company, selectedCompany, setActiveCompany } = this.props;
		const { companySelect, name } = this.styles;

		if (!(companies && companies.length > 1)) {
			return (
				<span style={name}>
					{company ? <Truncate length={ 40 }>{ company.name }</Truncate> : ""}
				</span>
			);
		}

		const sortedCompanies = Utils.sort(companies, "text", true);

		return (
			<Select
				className="statusbar-company-select"
				defaultValue={ selectedCompany }
				dropdownStyle={ companySelect }
				onChange={ setActiveCompany }
			>
				{ sortedCompanies.map(this.renderCompanyOption) }
			</Select>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
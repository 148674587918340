import * as React from "react";
import { connect } from "react-redux";

import { IAd, IBaseComponent, IMedia, MediaContext as Context } from "@connect/Interfaces";
import { Modal } from "Components/Global/Common";
import MediaPage from "Components/Media/MediaPage";
import { setSelectMediaModal } from "Data/Actions/UI/Modals";
import { addMediaToComponent } from "Data/Actions/UI/AdBuilder";
import { getActiveAd, getSelectedComponent } from "Data/Selectors/AdBuilder";
import {
	getCurrentSelectMediaModalContext,
	getCurrentSelectMediaModalOrientation,
	getSelectMediaModalVisibility
} from "Data/Selectors/UI";

const mapStateToProps = (state, ownProps) => ({
	activeAd: getActiveAd(state),
	visible: getSelectMediaModalVisibility(state),
	context: getCurrentSelectMediaModalContext(state),
	orientation: getCurrentSelectMediaModalOrientation(state),
	selectedComponent: getSelectedComponent(state)
});

const mapDispatchToProps = (dispatch) => ({
	addComponentMedia: (index: number, ids: string[], type: string) => dispatch(addMediaToComponent(index, ids, type)),
	hideModal: () => dispatch(setSelectMediaModal(false, [], "", ""))
});

interface SelectMediaProps {
	activeAd: IAd;
	addComponentMedia: (index: number, ids: string[], type: string) => void;
	context: string;
	orientation: string;
	onMediaSelected: (media: IMedia[]) => void;
	visible: boolean;
	selectedComponent: IBaseComponent;
	hideModal: () => void;
}

export class UnwrappedSelectMediaModal extends React.PureComponent<SelectMediaProps> {
	constructor(props: SelectMediaProps) {
		super(props);

		this.hide = this.hide.bind(this);
		this.handleMediaSelected = this.handleMediaSelected.bind(this);
	}

	render() {
		const { visible } = this.props;

		return (
			<Modal
				fullScreen
				// can't put width inline-- ant modal doesn't respect it in the inline style
				modalKey="select_media_modal"
				width="100%"
				visible={ visible }
				onCancel={ this.hide }
				bodyStyle={{
					padding: 0
				}}
			>
				{ this.renderMediaPage() }
			</Modal>
		);
	}

	renderMediaPage() {
		const { context, orientation, visible } = this.props;

		if (!visible) {
			return null;
		}

		return (
			<MediaPage
				key={ context }
				context={ context }
				orientation={ orientation }
				onCloseModal={ this.hide }
				onMediaSelected={ this.handleMediaSelected }
			/>
		);
	}

	handleMediaSelected(media: IMedia[]) {
		const { context } = this.props

		// if a single media is selected (non-slideshow), base content checks on that media
		if (media.length === 1) {
			const [ selectedMedia ] = media;
			const { mediaType } = selectedMedia;

			// do not allow selection of the wrong type of media
			if (mediaType === context || mediaType === "banner" && context === Context.IMAGE) {
				this.handleUpdateAdMedia([ selectedMedia ]);
			}
		// otherwise, select all of the media.
		} else {
			this.handleUpdateAdMedia(media);
		}
	}

	handleUpdateAdMedia(media: IMedia[]) {
		const { activeAd, addComponentMedia, selectedComponent } = this.props;
		const componentIndex = activeAd.layout.components.indexOf(selectedComponent);
		const mediaIds = media.map(({uuid}) => uuid);

		addComponentMedia(componentIndex, mediaIds, selectedComponent.type);
		this.hide();
	}

	hide() {
		this.props.hideModal();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedSelectMediaModal);
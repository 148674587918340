import * as update from "immutability-helper";
import Radium from "radium";
import { NumberSize, ResizableDirection } from "re-resizable";
import * as React from "react";
import {
	ConnectDragPreview,
	DndComponentClass,
	DragSource,
	DragSourceCollector,
	DragSourceMonitor,
	DragSourceSpec
} from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend"
import { connect as connectToRedux } from "react-redux";
import { Rnd } from "react-rnd";

import { AdMediaLink, CustomCSS, IAd, IBaseComponent, IMedia } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { MaxTickerHeightPercent, MinimumComponentHeight, RatioHeightPercents } from "Components/Ads/Constants";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { Droppable } from "Components/Global/DropZone";
import MediaHandler from "Components/Global/MediaHandler";
import Upload from "Components/Global/Upload";
import { setMediaAsync } from "Data/Actions/MediaAsync";
import {
	addMediaToComponent,
	clearComponent as clearComponentCard,
	deleteComponentCard,
	moveComponent as moveComponentCard,
	saveUnsavedAd,
	setSelectedComponent,
	updateComponent as updateComponentCard
} from "Data/Actions/UI/AdBuilder";
import { getActiveAd, getAdComponentsHeight, getSelectedComponentIndex } from "Data/Selectors/AdBuilder";
import { getAdMediaForComponent } from "Data/Selectors/Ads";
import { getMediaCanFetch } from "Data/Selectors/Async";
import { getAllMedia } from "Data/Selectors/Media";
import { getActiveUuid } from "Data/Selectors/UI";
import { makeGetIsUploading, makeGetUploadMediaId, makeGetUploadProgress } from "Data/Selectors/Media";

const { black, lightGray, primaryGreen, sixGray, threeGray, white } = Colors;

export const AdComponentIdentifier = "adComponentCard";

const DragSpec: DragSourceSpec<IAdComponentCardProps<any>, IAdComponentDropItem> = {
	beginDrag(props: IAdComponentCardProps<any>): IAdComponentDropItem {
		return {
			index: props.index,
			component: props.component
		};
	},
	canDrag(props: IAdComponentCardProps<any>, monitor: DragSourceMonitor) {
		return !props.resizing;
	},
	endDrag(props: IAdComponentCardProps<any>, monitor: DragSourceMonitor) {
		const dropResult = monitor.getDropResult();

		if (dropResult) {
			return monitor.getItem();
		}

		return null;
	}
};

const DragCollector: DragSourceCollector<
IAdComponentCardCollectedProps
> = (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging()
	};
};

export const mapDispatchToProps = (dispatch, ownProps) => {
	const { component, index } = ownProps;
	return {
		addMedia: (mediaIds: string[]) => dispatch(addMediaToComponent(index, mediaIds, component.type)),
		clearComponent: () => dispatch(clearComponentCard(index)),
		deleteComponent: () => dispatch(deleteComponentCard(index)),
		getMedia: () => dispatch(setMediaAsync()),
		moveComponent: (dragIndex: number, hoverIndex: number) => dispatch(moveComponentCard(dragIndex, hoverIndex)),
		saveAd: () => dispatch(saveUnsavedAd()),
		selectComponent: () => dispatch(setSelectedComponent(index)),
		updateComponent: (newComponent: any, save?: boolean) => dispatch(updateComponentCard(index, newComponent, save))
	};
};

export const mapStateToProps = (state, ownProps) => {
	const getIsUploading = makeGetIsUploading();
	const getUploadMediaId = makeGetUploadMediaId();
	const getUploadProgress = makeGetUploadProgress();
	const activeAdId = getActiveUuid(state, "ads");
	const { id } = ownProps.component;
	const ad = getActiveAd(state);
	const originator = `ad+${activeAdId}+${id}`;
	const uploadMediaIds = getUploadMediaId(state, originator);
	const adLayoutMedia = ad ? ad.layout.media : [];

	return {
		ad,
		activeAdId,
		allMedia: getAllMedia(state),
		canFetchMedia: getMediaCanFetch(state),
		componentMedia: adLayoutMedia.filter(({ layoutPosition }) => layoutPosition === id),
		componentsHeight: getAdComponentsHeight(state),
		isSelected: getSelectedComponentIndex(state) === ownProps.index,
		media: getAdMediaForComponent(state, adLayoutMedia, id),
		uploading: getIsUploading(state, originator),
		uploadMediaIds,
		uploadProgress: getUploadProgress(state, originator)
	};
};

const ConnectedDragSource = DragSource<IAdComponentCardProps<any>>(AdComponentIdentifier, DragSpec, DragCollector);

export type SuperComponentClass<T> = DndComponentClass<T>;

export const SuperComponentCard = {
	Draggable: ConnectedDragSource,
	mapStateToProps,
	mapDispatchToProps
};

export interface IAdComponentCardCollectedProps {
	connectDragSource?: Function;
	isDragging?: boolean;
}

export interface IAdComponentDropItem {
	index: number;
	component: React.Component;
}

export interface IAdComponentCardProps<Component extends IBaseComponent> {
	ad: IAd;
	activeAdId: string;
	addMedia: (ids: string[]) => void;
	allMedia: IMedia[];
	canFetchMedia: boolean;
	clearComponent: () => void;
	component: Component;
	componentMedia: AdMediaLink[];
	componentsHeight: number;
	connectDragPreview: ConnectDragPreview;
	connectDragSource: Function;
	containerHeight: number;
	containerWidth: number;
	customDragLayer?: boolean;
	deleteComponent: () => void;
	disableDrop?: boolean;
	getMedia: () => void;
	index: number;
	isDragging?: boolean;
	isSelected: boolean;
	livePreview: boolean;
	media: IMedia[];
	moveComponent: (dragIndex: number, hoverIndex: number) => void;
	resizing: boolean;
	saveAd: () => void;
	selectComponent: () => void;
	setIsResizing: (resizing: boolean) => void;
	updateComponent: (component: Component, save?: boolean) => void;
	uploading: boolean;
	uploadMediaIds: string[];
	uploadProgress: number;
}

export interface IAdComponentCardState {
	pxHeight: number;
}

@Radium
export class AdComponentCard<T extends IAdComponentCardProps<any>, S extends IAdComponentCardState>
	extends React.PureComponent<T, S> {
	constructor(props: T) {
		super(props);

		this.styles = {
			baseButton: {
				alignItems: "center",
				background: sixGray,
				cursor: "pointer",
				display: "flex",
				height: 30,
				justifyContent: "center",
				paddingLeft: 2,
				position: "absolute",
				right: 0,
				textShadow: `0 1px 2px ${ threeGray }`,
				top: 0,
				width: 30,
				zIndex: 110
			},
			bottomResizeHandle: {
				alignItems: "center",
				background: "rgba(255, 255, 255, 0.5)",
				bottom: 0,
				color: lightGray,
				cursor: "row-resize",
				display: "flex",
				height: "1em",
				justifyContent: "center",
				left: 1,
				textAlign: "center",
				width: "calc(100% - 2px)",
				zIndex: 1000
			},
			card: {
				alignItems: "center",
				backgroundColor: "",
				backgroundImage: "",
				backgroundPosition: "",
				backgroundRepeat: "no-repeat",
				backgroundScale: "",
				backgroundSize: "",
				borderColor: black,
				borderStyle: "solid",
				borderWidth: 1,
				boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
				color: "white",
				display: "flex",
				height: "100%",
				justifyContent: "center",
				overflow: "hidden",
				position: "relative",
				textShadow: "0 1px 1px rgba(0, 0, 0, 0.2)",
				transition: "box-shadow 250ms",
				width: "100%",
				":hover": {
					boxShadow: "0 0 7px rgba(0, 0, 0, 1)",
					cursor: "move"
				}
			},
			container: {
				height: 0,
				overflow: "hidden",
				position: "relative",
				":hover": {}
			},
			livePreview: {
				position: "relative",
				textAlign: "center",
				top: 0,
				transform: "none"
			},
			livePreviewName: {
				margin: "0.5rem auto 0"
			},
			promptIcon: {
				color: primaryGreen
			},
			promptText: {
				padding: 4,
				fontSize: ".35em"
			},
			resolutionOverlay: {
				background: "rgba(0, 0, 0, 0.2)",
				bottom: 0,
				display: "none",
				fontSize: 12,
				height: 20,
				left: 0,
				opacity: 0.5,
				paddingLeft: 2,
				paddingRight: 2,
				pointerEvents: "none",
				position: "absolute",
				textAlign: "left",
				textShadow: `0 1px 2px ${ threeGray }`,
				visibility: "visible",
				zIndex: 100
			},
			upload: {
				alignItems: "center",
				background: "#404244",
				display: "flex",
				height: "100%",
				justifyContent: "center",
				left: 0,
				position: "absolute",
				top: 0,
				width: "100%",
				zIndex: 1000
			}
		};

		this.state = this.getInitialState(props);

		this.updateStyles(props, this.state);

		this.canFitContent = this.canFitContent.bind(this);
		this.changeComponentHeight = this.changeComponentHeight.bind(this);
		this.changeComponentHeightStart = this.changeComponentHeightStart.bind(this);
		this.changeComponentHeightStop = this.changeComponentHeightStop.bind(this);
		this.clearComponent = this.clearComponent.bind(this);
		this.deleteComponent = this.deleteComponent.bind(this);
		this.fillComponentHeight = this.fillComponentHeight.bind(this);
		this.fetchMediaIfNeeded = this.fetchMediaIfNeeded.bind(this);
		this.getAcceptedTypes = this.getAcceptedTypes.bind(this);
		this.getCardKey = this.getCardKey.bind(this);
		this.getCardStyles = this.getCardStyles.bind(this);
		this.getContainerKey = this.getContainerKey.bind(this);
		this.getDroppablePrompt = this.getDroppablePrompt.bind(this);
		this.getIcon = this.getIcon.bind(this);
		this.getIsHovering = this.getIsHovering.bind(this);
		this.getKey = this.getKey.bind(this);
		this.getName = this.getName.bind(this);
		this.getRndRef = this.getRndRef.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.isDroppable = this.isDroppable.bind(this);
		this.isResizable = this.isResizable.bind(this);
		this.isResizableType = this.isResizableType.bind(this);
		this.renderContainer = this.renderContainer.bind(this);
		this.renderIcons = this.renderIcons.bind(this);
		this.renderLivePreviewNoMedia = this.renderLivePreviewNoMedia.bind(this);
		this.renderPrompt = this.renderPrompt.bind(this);
		this.renderSize = this.renderSize.bind(this);
		this.renderUploading = this.renderUploading.bind(this);
		this.setIsSelected = this.setIsSelected.bind(this);
		this.setIsSelectedEvent = this.setIsSelectedEvent.bind(this);
		this.shouldShowLivePreview = this.shouldShowLivePreview.bind(this);
		this.updateHeight = this.updateHeight.bind(this);
	}

	_rnd: Rnd;
	icon: string;
	name: string;
	styles: {
		baseButton: CustomCSS;
		bottomResizeHandle: CustomCSS;
		card: CustomCSS;
		container: CustomCSS;
		livePreview: CustomCSS;
		livePreviewName: CustomCSS;
		promptIcon: CustomCSS;
		promptText: CustomCSS;
		resolutionOverlay: CustomCSS;
		upload: CustomCSS;
	};

	static getDerivedStateFromProps(props: IAdComponentCardProps<any>, state: IAdComponentCardState) {
		const { component, containerHeight } = props;
		const pxHeight = containerHeight * (component.height.value / 100);

		return {
			pxHeight
		};
	}

	componentDidMount() {
		const { connectDragPreview, customDragLayer } = this.props;
		if (connectDragPreview && customDragLayer) {
			// Use empty image as a drag preview so browsers don't draw it
			// and we can draw whatever we want on the custom drag layer instead.
			connectDragPreview(getEmptyImage(), {
				// IE fallback: specify that we'd rather screenshot the node
				// when it already knows it's being dragged so we can hide it with CSS.
				captureDraggingState: true
			});
		}
		this.updateStyles(this.props, this.state);

		this.fetchMediaIfNeeded();
	}

	componentDidUpdate() {
		this.fetchMediaIfNeeded();
	}

	render() {
		// We throw here to prevent rendering the AdComponentCard directly, but we must return a <div />
		// so the extending components can properly type their render methods.
		throw new Error("WARNING: AdComponentCard#render must be overridden in the extending component.");
		return <div />;
	}

	renderContainer(content: React.ReactChild) {
		const { pxHeight } = this.state;
		const { container } = this.styles;
		const cardStyles = Object.assign({}, this.getCardStyles(), {
			borderColor: this.props.isSelected ? white : black,
			height: pxHeight
		});
		const card = (
			<div key={ this.getCardKey() } style={ cardStyles }>
				{ this.renderUploading() }
				{ content }
				{ this.renderLivePreviewNoMedia() }
				{ this.renderIcons() }
				{ this.renderSize() }
			</div>
		);
		const droppable = this.renderDroppable(card);
		const rnd = this.renderRnd(droppable);

		return this.props.connectDragSource(
			<div key={ this.getContainerKey() } onClick={ this.setIsSelectedEvent } style={{ ...container, height: pxHeight  }}>
				{ rnd }
			</div>
		);
	}

	renderDroppable(card: React.ReactChild) {
		if (!this.isDroppable()) {
			return card;
		}

		return (
			<Droppable
				prompt={ this.getDroppablePrompt() }
				onDrop={ this.handleDrop }>
				{ card }
			</Droppable>
		);
	}

	renderRnd(maybeDroppable: React.ReactChild) {
		const { pxHeight } = this.state;
		const { bottomResizeHandle } = this.styles;
		const isResizable = this.isResizableType();

		if (!isResizable) {
			return maybeDroppable;
		}

		return (
			<Rnd
				bounds=".adbuilder-pvm-screen"
				disableDragging={ true }
				dragAxis="none"
				enableResizing={{
					top: false,
					bottom: isResizable,
					right: false,
					left: false,
					topRight: false,
					topLeft: false,
					bottomRight: false,
					bottomLeft: false
				}}
				enableUserSelectHack={ false }
				onResize={ this.changeComponentHeight }
				onResizeStart={ this.changeComponentHeightStart }
				onResizeStop={ this.changeComponentHeightStop }
				ref={ this.getRndRef }
				resizeHandleClasses={{
					bottom: "adcomponentcard-resize"
				}}
				resizeHandleStyles={{
					bottom: bottomResizeHandle
				}}
				size={{ height: pxHeight, width: "100%" }}
			>
				{ maybeDroppable }
			</Rnd>
		);
	}

	renderIcons() {
		const cardIsHovering = this.getIsHovering(this.getCardKey());
		const { baseButton } = this.styles;
		const rightStyle = (multiplier: number) => multiplier * 30;
		let right: number;
		let icons: React.ReactElement[] = [];

		if (cardIsHovering) {
			icons.push((
				<div className="adcomponentcard-button" key={ this.getKey("delete") }
					style={ baseButton }
					onClick={ this.deleteComponent }>
					<Icon name="trash-alt" iconWeight="regular" />
				</div>
			));

			right = rightStyle(icons.length);
			icons.push((
				<div className="adcomponentcard-button" key={ this.getKey("erase") }
					style={{ ...baseButton, right }}
					onClick={ this.clearComponent }>
					<Icon name="eraser" iconWeight="solid"/>
				</div>
			));

			if (this.isResizable()) {
				right = rightStyle(icons.length);
				icons.push((
					<div className="adcomponentcard-button" key={ this.getKey("snap") }
						style={{ ...baseButton, right }}
						onClick={ this.fillComponentHeight }>
						<Icon name="arrows-v" />
					</div>
				))
			}
		}

		return icons;
	}

	renderLivePreviewNoMedia() {
		if (this.shouldShowLivePreview()) {
			return null;
		}

		const { livePreview, livePreviewName } = this.styles;
		const display = this.props.component.height.value <= 15 ? "none" : "block";

		return (
			<div style={ livePreview }>
				<Icon name={ this.getIcon() } size="small" />
				<div style={{ ...livePreviewName, display }}>{ this.getName() }</div>
			</div>
		);
	}

	renderPrompt(text?: string) {
		const { promptIcon, promptText } = this.styles;

		return (
			<div>
				<Icon
					name="plus-circle"
					style={ promptIcon } />&nbsp;
					Drop Files... <br />
				<div style={ promptText }>
					{ text }
				</div>
			</div>
		);
	}

	renderSize() {
		const { component, isSelected } = this.props;
		const width = Utils.getComponentWidthFromPercent(component.width.value);
		const height = Utils.getComponentHeightFromPercent(component.height.value);
		const display = isSelected ? "block" : "none";

		return (
			<div style={{ ...this.styles.resolutionOverlay, display }}>
				{ width }&times;{ height }px
			</div>
		);
	}

	renderUploading() {
		const { uploading, uploadProgress } = this.props;

		if (!uploading) {
			return null;
		}

		return (
			<div style={{ ...this.styles.upload }}>
				<Upload percent={ uploadProgress } />
			</div>
		);
	}

	canFitContent(newHeightPercent: number) {
		const { component, componentsHeight } = this.props;
		const heightWithout = componentsHeight - component.height.value;
		const newHeightCanFit = newHeightPercent <= (100 - heightWithout);

		if (component.type === "ticker") {
			return newHeightPercent <= MaxTickerHeightPercent && newHeightCanFit;
		}

		return newHeightCanFit;
	}

	changeComponentHeight(
		event: MouseEvent,
		direction: ResizableDirection,
		ref: HTMLDivElement,
		delta: NumberSize
	) {
		const { component, componentsHeight, containerHeight } = this.props;
		const { pxHeight } = this.state;
		const getHeightPercent = (height) => height / containerHeight * 100;

		let { offsetHeight } = ref;
		let newHeightPercent = getHeightPercent(offsetHeight);
		const maxHeightPercent = 100 - (componentsHeight - component.height.value);
		const overMinimumHeight = component.type === "feed"
			? newHeightPercent >= RatioHeightPercents.sixteenByNine
			: newHeightPercent >= MinimumComponentHeight;
		const hasChange = offsetHeight !== pxHeight;
		const shrinking = (offsetHeight - pxHeight) < 0;
		let canFit = shrinking || this.canFitContent(newHeightPercent);

		if (!shrinking && !canFit && componentsHeight < 100) {
			const maxTickerHeight = MaxTickerHeightPercent < maxHeightPercent ? MaxTickerHeightPercent : maxHeightPercent;
			newHeightPercent = component.type === "ticker" ? maxTickerHeight : maxHeightPercent;
			offsetHeight = containerHeight * newHeightPercent / 100;
			canFit = true;
		}

		// set the new height if this one is at least 5%, we haven't exceeded 100% total height
		// and limit tickers to a max of 300px (15.6%) of the screen height
		if (overMinimumHeight && hasChange && canFit) {
			this.updateHeight(offsetHeight, newHeightPercent);
		}
	}

	changeComponentHeightStart(
		event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
		direction: ResizableDirection,
		ref: HTMLDivElement
	) {
		this.props.setIsResizing(true);
	}

	changeComponentHeightStop(
		event: MouseEvent,
		direction: ResizableDirection,
		ref: HTMLDivElement,
		delta: NumberSize
	) {
		const { saveAd, setIsResizing } = this.props;

		setIsResizing(false);
		saveAd();
	}

	clearComponent(event: React.SyntheticEvent<HTMLDivElement>) {
		event.stopPropagation();

		this.props.clearComponent();
	}

	componentCanUseMedia(media: IMedia) {
		const { activeAdId, component } = this.props;
		const originator = `ad+${activeAdId}+${component.id}`;
		const mediaWithOriginator = media.originator ? media.originator === originator : true;
		const requiredType = (() => {
			switch (component.type) {
				case "image":
				case "slideshow":
					return "image";
				case "video":
					return "video";
				case "feed":
					return "banner";
				default:
					return null;
			}
		})();

		return requiredType === media.mediaType && mediaWithOriginator;
	}

	deleteComponent(event: React.SyntheticEvent<HTMLDivElement>) {
		event.stopPropagation();

		this.props.deleteComponent();
	}

	fetchMediaIfNeeded() {
		const { canFetchMedia, componentMedia, getMedia, media } = this.props;

		if (canFetchMedia && componentMedia.length !== media.length) {
			getMedia();
		}
	}

	fillComponentHeight(event: React.SyntheticEvent<HTMLDivElement>) {
		event.stopPropagation();

		const { component, componentsHeight, containerHeight } = this.props;
		const emptySpace = 100 - componentsHeight;
		const currentComponentHeight = component.height.value;
		const fullComponent = currentComponentHeight + emptySpace;
		const newTickerHeight = MaxTickerHeightPercent > fullComponent ? fullComponent : MaxTickerHeightPercent;
		const newComponentHeight = component.type === "ticker" ? newTickerHeight : fullComponent;
		const offsetHeight = newComponentHeight / 100 * containerHeight;

		this.updateHeight(offsetHeight, newComponentHeight, true);
	}

	getAcceptedTypes() {
		switch (this.props.component.type) {
			case "video":
				return [ "Video" ];
			case "image":
				return [ "Image" ];
			case "slideshow":
				return [ "Image" ];
			default: return [];
		}
	}

	getCardKey() {
		return this.getKey("card");
	}

	// override this in the extending component, e.g. to customize the card style for adding an image
	getCardStyles() {
		return this.styles.card;
	}

	getContainerKey() {
		return this.getKey("card-container");
	}

	getDroppablePrompt() {
		const { component } = this.props;
		const { height, type } = component as IBaseComponent;
		const componentHeight = Utils.getComponentHeightFromPercent(height.value);

		if (componentHeight <= 400) {
			return this.renderPrompt();
		}

		switch (type) {
			case "video":
				return this.renderPrompt(`Videos must be less than 15 minutes long,
					HD (1920 x 1080 / 1080 x 1920), and smaller than 1.00gb`);
			case "image":
			case "slideshow":
				return this.renderPrompt("Images must be smaller than 32mb");
		}

		return null;
	}

	getIcon() {
		return this.icon || "";
	}

	protected getInitialState(props: T): S {
		const { component, containerHeight } = props;
		const pxHeight = containerHeight * (component.height.value / 100);

		return {
			pxHeight
		} as S;
	}

	getIsHovering(key: string) {
		return Radium.getState(this.state, key, ":hover");
	}

	getKey(suffix: string) {
		const { component, isSelected } = this.props;
		const { id, type } = component as IBaseComponent;
		const selected = isSelected ? "selected" : ""

		return `${id}_${type}_${selected}_${suffix}`;
	}

	getName() {
		return this.name || "";
	}

	getRndRef(el: Rnd) {
		if (el) {
			this._rnd = el;
		}
	}

	handleDrop(files: File[]) {
		const { activeAdId, addMedia, component } = this.props;
		let handler = new MediaHandler(`ad+${activeAdId}+${component.id}`);

		this.setIsSelected();

		handler.allowedFileTypes = this.getAcceptedTypes();

		handler.onSuccess = (media) => {
			addMedia([ media.uuid ]);
		};

		// only accept the first file and do not accept non-file input / divs / etc
		if (files.length) {
			if (component.type === "slideshow") {
				handler.handleFiles(files);
			} else {
				handler.handleFiles([ files[0] ]);
			}
		}
	}

	isDroppable() {
		switch (this.props.component.type) {
			case "video":
			case "image":
			case "slideshow":
				return !this.props.disableDrop;
			default: return false;
		}
	}

	isResizable() {
		const { component, componentsHeight } = this.props;
		const resizableSize = componentsHeight < 100;
		const tickerIsResizable = component.type === "ticker"
			? component.height.value < MaxTickerHeightPercent
			: true;

		return resizableSize && this.isResizableType() && tickerIsResizable;
	}

	isResizableType() {
		switch (this.props.component.type) {
			case "feed":
			case "image":
			case "slideshow":
			case "ticker":
			case "video":
				return true;
			default:
				return false;
		}
	}

	setIsSelected(selected?: boolean) {
		const isSelected = selected !== undefined ? selected : true;

		if (isSelected) {
			this.props.selectComponent()
		}
	}

	setIsSelectedEvent(event: React.SyntheticEvent<HTMLDivElement>) {
		event.stopPropagation();

		this.setIsSelected();
	}

	// override this in the extending component, e.g. whether a component has media or text or not
	shouldShowLivePreview() {
		return this.props.livePreview;
	}

	updateHeight(height: number, percent: number, save?: boolean) {
		const { component, updateComponent } = this.props;
		const newComponent = update(component, {
			height: {
				value: {
					$set: percent
				}
			}
		});

		this._rnd.updateSize({
			height, width: "100%"
		});

		updateComponent(newComponent, save);
	}

	updateStyles(props: IAdComponentCardProps<any>, state: IAdComponentCardState) {
		const { component, isSelected } = props;
		const { pxHeight } = state;

		this.styles = update(this.styles, {
			card: {
				borderColor: { $set: isSelected ? white : black },
				height: { $set: pxHeight }
			},
			container: {
				height: { $set: pxHeight }
			},
			livePreviewName: {
				display: { $set: component.height.value <= 15 ? "none" : "block" }
			},
			resolutionOverlay: {
				display: { $set: isSelected ? "block" : "none" }
			}
		});
	}
}

export default ConnectedDragSource(
	connectToRedux(mapStateToProps, mapDispatchToProps)(AdComponentCard)
);

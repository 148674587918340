import * as React from "react";
import { connect } from "react-redux";

import { ActionSetActions, Context, CustomCSS, IAd } from "@connect/Interfaces";
import { ActionPropertiesPanelContent,
	ActionPropertiesPanelContentProps } from "Components/Actions/ActionPropertiesPanelContent";
import { Input } from "Components/Global/Common";
import { getAdAsync } from "Data/Actions/AdsAsync";
import { setSelectAdsModal } from "Data/Actions/UI/Modals";
import { getSelectedActionAd, getSelectedActionAdUuid, getSelectedActionType } from "Data/Selectors/ActionBuilder";
import { Utils } from "@connect/Utils";
import { mapStateToProps as superMapStateToProps } from "Components/Actions/ActionPropertiesPanelContent";

const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		activeActionType: getSelectedActionType(state),
		selectedAd: getSelectedActionAd(state),
		selectedAdUuid: getSelectedActionAdUuid(state)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showSelectAdModal: () => dispatch(setSelectAdsModal(true, Context.ACTIONS)),
		tryFetchAd: (uuid: string) => dispatch(getAdAsync(uuid))
	}
}

interface AdActionPropertiesPanelContentProps extends ActionPropertiesPanelContentProps {
	selectedAd: IAd;
	selectedAdUuid: string;
	showSelectAdModal: () => void;
	tryFetchAd: (uuid: string) => void;
}

export class AdActionPropertiesPanelContent
	extends ActionPropertiesPanelContent<AdActionPropertiesPanelContentProps> {
	constructor(props: AdActionPropertiesPanelContentProps) {
		super(props);

		this.styles = {
			...this.styles,
			adThumbnail: {
				marginLeft: "auto",
				marginRight: "auto",
				marginBottom: 20,
				display: "block"
			},
			durationRow: {
				marginBottom: 10,
				marginTop: 10
			},
			label: {
				display: "inline-block",
				width: 100
			}
		}

		const { icon, title } = ActionSetActions.ad;

		this.icon = icon;
		this.title = title;

	}

	styles: CustomCSS;

	componentDidMount() {
		const { selectedAd, selectedAdUuid, tryFetchAd } = this.props;
		if (selectedAdUuid && !selectedAd) {
			tryFetchAd(selectedAdUuid)
		};
	}

	render() {
		const { selectedAd, selectedAdUuid, showSelectAdModal } = this.props;

		return this.renderContainer(
			<React.Fragment>
				{ this.renderThumbnail() }
				<Input
					button={{
						callback: showSelectAdModal,
						text: selectedAdUuid ? "Replace" : "Select"
					}}
					key={ selectedAdUuid }
					disabled
					hideMaxLength
					id={ "adSelector" }
					readOnly
					value={ selectedAd ? selectedAd.name : "" }
				/>
				{ this.renderDuration() }
			</React.Fragment>
		);
	}

	renderThumbnail() {
		const { selectedAd } = this.props;

		if (!selectedAd) {
			return null;
		}

		const { thumbnail } = selectedAd;

		return (
			<img width="100" src={ thumbnail } style={ this.styles.adThumbnail } />
		);
	}

	renderDuration() {
		const { durationRow, label } = this.styles;
		const { selectedAd } = this.props;

		if (!selectedAd) {
			return null;
		}

		return (
			<div style={ durationRow }>
				<div style={ label }>Ad Duration: </div>
				<span>
					{ Utils.getFormattedDuration(selectedAd.duration) }
				</span>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdActionPropertiesPanelContent);

import * as React from "react";

import { ViewTypes } from "@connect/Interfaces";
import { Button } from "Components/Global/Common";

export interface IViewSwitchProps {
	onViewChange: () => void;
	view: ViewTypes;
}

export default class ViewSwitch extends React.PureComponent<IViewSwitchProps> {
	render() {
		const { onViewChange, view } = this.props;
		const icon = this.getIcon(view);

		return (
			<Button
				icon={ icon }
				onClick={ onViewChange }
			/>
		);
	}

	getIcon(view: ViewTypes) {
		switch (view) {
			case ViewTypes.GRID:
				return "th";
			case ViewTypes.CHART:
				return "chart-bar";
			default:
				return "list-ul";
		}
	}
}

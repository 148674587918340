import { createSelector } from "reselect";

import { HealthReport, HealthReportCollectionArgs, HealthReportResult, SortTypes } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { AppState, HealthReportState } from "Data/Objects/AppState";
import { identityReducer } from "Data/Utils";

export function getHealthReportsState(state: AppState): HealthReportState {
	return state.HealthReports;
}

export function getHealthReports(state: HealthReportState): HealthReport[] {
	return state.reports;
}

export function getHealthReportResults(state: HealthReportState): HealthReportResult[] {
	return state.results;
}

export function getHealthReportsSorted(reports: HealthReport[], sortType: SortTypes) {
	return Utils.sortListBySortType(reports, sortType);
}

export function getHealthReportSort(state: AppState, args: HealthReportCollectionArgs): SortTypes {
	return args.sort || SortTypes.NEWEST_FIRST
}

export function getHealthReportFilter(state: AppState, args: HealthReportCollectionArgs): string {
	return args.filter || "";
}

export function getHealthReportsFilteredBySearch(reports: HealthReport[], filter: string) {
	const filterText = filter.toLowerCase();
	return reports.filter((report) => report.name.toLowerCase().includes(filterText));
}

export function getHealthReport(reports: HealthReport[], id: string) {
	return reports.find(({ uuid }) => uuid === id);
}

export function getHealthResult(results: HealthReportResult[], id: string) {
	return results.find(({ uuid }) => uuid === id);
}

export function getReportsDownloading(state: HealthReportState) {
	return state.downloadingReports;
}

export function getHealthReportIsDownloading(downloadingReports: string[], id: string) {
	return downloadingReports.indexOf(id) > -1;
}

export const getAllHealthReports = createSelector(
	[ getHealthReportsState ],
	getHealthReports
);

export const getHealthResults = createSelector(
	[ getHealthReportsState ],
	getHealthReportResults
);

export const getDownloadingReports = createSelector(
	[ getHealthReportsState ],
	getReportsDownloading
);

export const getSortedHealthReports = createSelector(
	[ getAllHealthReports, getHealthReportSort ],
	getHealthReportsSorted
);

export const getFilteredHealthReports = createSelector(
	[ getAllHealthReports, getHealthReportFilter ],
	getHealthReportsFilteredBySearch
);

export const getFilteredSortedHealthReports = createSelector(
	[ getFilteredHealthReports, getHealthReportSort ],
	getHealthReportsSorted
);

export const getSelectedHealthReport = createSelector(
	[ getAllHealthReports, identityReducer ],
	getHealthReport
);

export const getSelectedReportResult = createSelector(
	[ getHealthResults, identityReducer ],
	getHealthResult
);

export const getReportIsDownloading = createSelector(
	[ getDownloadingReports, identityReducer ],
	getHealthReportIsDownloading
);
import * as React from "react";
import { connect } from "react-redux";
import { getSelectedTriggerParameter } from "Data/Selectors/ActionBuilder.ts";
import { updateSelectedTriggerParameter } from "Data/Actions/ActionsAsync";
import Input from "Components/Global/Input";
import { maxLength } from "Data/Objects/Validation";
import { InputValidator } from "@connect/Interfaces";

interface ParameterPropertiesProps {
	parameter: string;
	updateParameter: (value: string) => void;
}

const mapStateToProps = (state) => {
	return {
		parameter: getSelectedTriggerParameter(state)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateParameter: (parameter: string) =>
			dispatch(updateSelectedTriggerParameter(parameter))
	}
}

class ParameterProperties extends React.Component<ParameterPropertiesProps> {
	constructor(props: ParameterPropertiesProps) {
		super(props);
	}

	render() {
		const { parameter, updateParameter } = this.props;
		const maxLengthValidator = [ maxLength(191, true) as InputValidator ];

		return (
			<div>
				<div>Parameter:</div>
				<Input
					id="network-request-parameter"
					key={ parameter }
					value={ parameter }
					saveCallback={ updateParameter }
					validator={ maxLengthValidator }
				/>
				<div>Preview: http://x.x.x.x:61111/triggers?parameter={ parameter }</div>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ParameterProperties);
import { createSelector } from "reselect";

import { CustomPermissions } from "@connect/Interfaces";
import { AppState, PermissionsState } from "Data/Objects/AppState";

export function getPermissionsState(state: AppState): PermissionsState {
	return state.Permissions;
}

export function getPermissions(state: PermissionsState): CustomPermissions {
	return state.permissions;
}

export const getCustomPermissions = createSelector(
	[ getPermissionsState ],
	getPermissions
);

import * as update from "immutability-helper";

import { Action, SetTokenDispatch, UserDispatch, UserLoginDispatch, UserNewslettersDispatch,
	UserPermissionsDispatch, UserWidgetDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { UserState } from "Data/Objects/AppState";
import { createReducer, setState } from "Data/Utils";

export function loginUser(state: UserState, action: Action<UserLoginDispatch>) {
	return update(state, {
		user: { $set: action.args.user },
		token: { $set: action.args.token }
	});
}

export function logoutUser(state: UserState, action: Action<null>) {
	if (state.user) {
		return {
			token: ""
		};
	}
	return state;
}

export function setToken(state: UserState, action: Action<SetTokenDispatch>) {
	return setState(state, "token", action.args.token);
}

export function setUser(state: UserState, action: Action<UserDispatch>) {
	return setState(state, "user", action.args.user);
}

export function toggleTwoFactor(state: UserState, action: Action<boolean>) {
	return update(state, {
		user: { twoFactorEnabled: { $set: action.args }}
	});
}

export function updateEmailPreferences(state: UserState, action: Action<UserNewslettersDispatch>) {
	return update(state, {
		user: {
			settings: {
				newsletters: { $set: action.args.newsletters }
			}
		}
	});
}

export function updateUserPermissions(state: UserState, action: Action<UserPermissionsDispatch>) {
	return update(state, {
		user: {
			permissions: { $set: action.args.permissions }
		}
	});
}

export function updateWidgets(state: UserState, action: Action<UserWidgetDispatch>) {
	return update(state, {
		user: {
			settings: { $apply: (settings) => update(settings || {}, {
				widgets: {
					$set: action.args
				}
			}) }
		}
	});
}

export function verifyUser(state: UserState, action: Action<boolean>) {
	// only update a state if there is one currently / user is logged in
	if (state && state.user) {
		return update(state, {
			user: { verified: { $set: true }}
		});
	}

	return state;
}

const {
	LOGIN_USER,
	LOGOUT_USER,
	SET_TOKEN,
	SET_USER,
	TOGGLE_TWO_FACTOR,
	UPDATE_EMAIL_PREFERENCES,
	UPDATE_PERMISSIONS,
	UPDATE_WIDGETS,
	VERIFY_USER
} = ACTION_TYPES.User;

const reducers = {
	[LOGIN_USER.type]: loginUser,
	[LOGOUT_USER.type]: logoutUser,
	[SET_TOKEN.type]: setToken,
	[SET_USER.type]: setUser,
	[TOGGLE_TWO_FACTOR.type]: toggleTwoFactor,
	[UPDATE_EMAIL_PREFERENCES.type]: updateEmailPreferences,
	[UPDATE_PERMISSIONS.type]: updateUserPermissions,
	[UPDATE_WIDGETS.type]: updateWidgets,
	[VERIFY_USER.type]: verifyUser
};

export default createReducer(reducers, UserState);
import * as update from "immutability-helper";
import * as moment from "moment";
import { MigrationManifest } from "redux-persist";

import {
	AccordionState,
	ActionBuilderState,
	ActionsState,
	ActiveFilterState,
	ActiveObjectArrayState,
	ActiveStringArrayState,
	ActiveStringState,
	AdBuilderState,
	AdminActivityState,
	AdminUsersState,
	AdminVersionsState,
	AppState,
	AsyncStates,
	DeploymentWizardState,
	DeviceHealthModalStates,
	initialUnsavedStates,
	LocationState,
	MediaPreviewState,
	ModalsState,
	NavigationItemState,
	PlaylistState
} from "Data/Objects/AppState";
import { Company } from "Data/Objects/Company";
import {
	initialActiveFilterStates,
	initialActiveSearchStates,
	initialActiveSelectionStates,
	initialActiveSortStates,
	initialActiveTagStates,
	initialActiveUuidStates,
	initialActiveViewStates,
	initialAsyncStates
} from "Data/Objects/UI";

// <Internal use only> -- Playlist State have async data prior to 1.11
interface OldPlaylist extends PlaylistState {
	currentPage: number;
	currentlyFetching: boolean;
	haveAllPlaylists: boolean;
	lastFetch: moment.Moment;
	lastFetchedCompany: string;
}
function makeOP(playlistState: any): OldPlaylist {
	return playlistState as OldPlaylist;
}
// </Internal use only>

const V1_6 = (state: AppState) => Object.assign(new AppState(), state, {
	Company: {
		activeCompanyId: state.Company.activeCompanyId || "",
		activeCompany: state.Company.activeCompany || new Company(),
		companies: state.Company.companies || []
	},
	Devices: {
		deviceModels: state.Devices.deviceModels || [],
		deviceModelNames: state.Devices.deviceModelNames || []
	},
	HealthReports: {
		reports: state.HealthReports.reports || [],
		results: state.HealthReports.results || [],
		downloadingReports: state.HealthReports.downloadingReports || []
	},
	Media: {
		media: state.Media.media || []
	},
	Playlists: {
		currentPage: makeOP(state.Playlists).currentPage || 0,
		currentlyFetching: makeOP(state.Playlists).currentlyFetching || false,
		haveAllPlaylists: makeOP(state.Playlists).haveAllPlaylists || false,
		lastFetch: makeOP(state.Playlists).lastFetch || null,
		lastFetchedCompany: makeOP(state.Playlists).lastFetchedCompany || null,
		playlists: state.Playlists.playlists || []
	},
	Roles: {
		editRoles: state.Roles.editRoles || [],
		roles: state.Roles.roles || []
	},
	UI: {
		activeHealthReport: state.UI.activeHealthReport || "",
		activeNavigationItem: state.UI.activeNavigationItem || "/",
		asyncState: state.UI.asyncState || new AsyncStates(initialAsyncStates),
		deviceHealthModalState: state.UI.deviceHealthModalState || new DeviceHealthModalStates(),
		finishedReports: state.UI.finishedReports || [],
		leftAccordion: state.UI.leftAccordion || new AccordionState(),
		menu: state.UI.menu || [],
		runningReports: state.UI.runningReports || []
	},
	Uploads: {
		uploads: state.Media.uploads || []
	},
	User: {
		token: state.User.token || "",
		user: state.User.user || undefined
	}
});
const V1_7 = (state: AppState) => update(state, {
	Admin: { $set: {
		Activity: new AdminActivityState(),
		Versions: new AdminVersionsState(),
		Users: new AdminUsersState()
	} },
	Company: {
		stores: { $set: [] },
		teams: { $set: [] }
	},
	Devices: {
		devices: { $set: [] },
		deviceGroups: { $set: [] },
		unassociatedDevices: { $set: [] }
	},
	UI: {
		activeFilters: { $set: new ActiveFilterState(initialActiveFilterStates) },
		activeSearch: { $set: new ActiveStringState(initialActiveSearchStates) },
		activeSelection: { $set: new ActiveStringArrayState(initialActiveSelectionStates) },
		activeSorts: { $set: new ActiveObjectArrayState(initialActiveSortStates) },
		activeTags: { $set: new ActiveStringArrayState(initialActiveTagStates) },
		activeUuids: { $set: new ActiveStringState(initialActiveUuidStates) },
		activeViews: { $set: new ActiveStringState(initialActiveViewStates) },
		mediaPreview: { $set: new MediaPreviewState() }
	}
});
const V1_8 = (state: AppState) => update(state, {
	Admin: {
		Activity: { $set: state.Admin.Activity || new AdminActivityState() },
		Versions: { $set: state.Admin.Versions || new AdminVersionsState() },
		Users: { $set: state.Admin.Users || new AdminUsersState() }
	},
	Ads: { $set: {
		ads: []
	} },
	Company: {
		stores: { $set: state.Company.stores || [] },
		teams: { $set: state.Company.teams || [] }
	},
	Deployments: { $set: {
		deployments: []
	} },
	Devices: {
		devices: { $set: state.Devices.devices || [] },
		deviceGroups: { $set: state.Devices.deviceGroups || [] },
		unassociatedDevices: { $set: state.Devices.unassociatedDevices || [] }
	},
	UI: {
		activeFilters: { $set: state.UI.activeFilters || new ActiveFilterState(initialActiveFilterStates) },
		activeSearch: { $set: state.UI.activeSearch || new ActiveStringState(initialActiveSearchStates) },
		activeSelection: { $set: state.UI.activeSelection || new ActiveStringArrayState(initialActiveSelectionStates) },
		activeSorts: { $set: state.UI.activeSorts || new ActiveObjectArrayState(initialActiveSortStates) },
		activeTags: { $set: state.UI.activeTags || new ActiveStringArrayState(initialActiveTagStates) },
		activeUuids: { $set: state.UI.activeUuids || new ActiveStringState(initialActiveUuidStates) },
		activeViews: { $set: state.UI.activeViews || new ActiveStringState(initialActiveViewStates) },
		adBuilderState: { $set: new AdBuilderState() },
		collapse: { $set: new AccordionState() },
		mediaPreview: { $set: state.UI.mediaPreview || new MediaPreviewState() }
	}
});
const V1_9 = (state: AppState) => update(state, {
	Ads: {
		ads: { $set: state.Ads.ads || [] }
	},
	Deployments: {
		deployments: { $set: state.Deployments.deployments || [] }
	},
	System: { $set: {
		connected: true,
		videos: {}
	} },
	UI: {
		collapse: { $set: state.UI.collapse || new AccordionState() }
	},
	Uploads: { $set: {
		uploads: []
	} }
});
const V1_10 = (state: AppState) => update(state, {
	System: {
		connected: { $set: state.System.connected || true },
		redirect: { $set: new LocationState() },
		unsaved: { $set: new ActiveStringState(initialUnsavedStates, "All changes saved") },
		videos: { $set: state.System.videos || {} }
	},
	UI: {
		collapse: { $set: state.UI.collapse || new AccordionState() },
		modals: { $set: new ModalsState() },
		expandedDeviceGroups: { $set: [] }
	},
	Uploads: {
		uploads: { $set: state.Media.uploads || [] }
	}
});
const V1_11 = (state: AppState) => update(state, {
	System: {
		redirect: { $set: state.System.redirect || new LocationState() },
		unsaved: { $set: state.System.unsaved || new ActiveStringState(initialUnsavedStates, "All changes saved") }
	},
	UI: {
		activeNavigationItem: { $set: new NavigationItemState() },
		expandedDeviceGroups: { $set: state.UI.expandedDeviceGroups || [] }
	}
});
const V1_12 = (state: AppState) => update(state, {
	// ConnectWeb 1.11 was the last version that required PlaylistState mocking, only playlists left as of 1.12
	Playlists: { $set: {
		playlists: state.Playlists.playlists || []
	} },
	System: {
		features: { $set: [] }
	},
	UI: {
		activeNavigationItem: { $set: state.UI.activeNavigationItem || new NavigationItemState() }
	}
});
const V1_14 = (state: AppState) => update(state, {
	Company: {
		adminTeams: { $set: [] },
		managedCompanies: { $set: [] }
	},
	Deployments: {
		approvers: { $set: [] }
	},
	Permissions: { $set: {
		permissions: null
	} },
	Roles: { $set: {
		editRoles: {},
		roles: {}
	} },
	System: {
		features: { $set: state.System.features || [] },
		location: { $set: {
			current: new LocationState(),
			previous: new LocationState()
		} }
	}
});
const V1_15 = (state: AppState) => update(state, {
	Company: {
		adminTeams: { $set: state.Company.adminTeams || [] },
		managedCompanies: { $set: state.Company.managedCompanies || [] }
	},
	Deployments: {
		approvers: { $set: state.Deployments.approvers || [] }
	},
	Permissions: {
		permissions: { $set: state.Permissions.permissions || null }
	},
	Roles: {
		editRoles: { $set: state.Roles.editRoles || {} },
		roles: { $set: state.Roles.roles || {} }
	},
	System: {
		currentApk: { $set: null }
	},
	UI: {
		asyncState: { $set: new AsyncStates() },
		deviceHealthModalState: { $set: new DeviceHealthModalStates() }
	}
});
const V1_20 = (state: AppState) => update(state, {
	Ads: {
		analytics: { $set: [] },
		finishedReports: { $set: [] },
		pendingReports: { $set: [] }
	},
	System: {
		currentApk: { $set: state.System.currentApk || null }
	},
	UI: {
		activeFilters: { $set: new ActiveFilterState(initialActiveFilterStates) },
		activeSearch: { $set: new ActiveStringState(initialActiveSearchStates) },
		activeSelection: { $set: new ActiveStringArrayState(initialActiveSelectionStates) },
		activeSorts: { $set: new ActiveObjectArrayState(initialActiveSortStates) },
		activeTags: { $set: new ActiveStringArrayState(initialActiveTagStates) },
		activeViews: { $set: new ActiveStringState(initialActiveViewStates) },
		asyncState: { $set: state.UI.asyncState || new AsyncStates() },
		deploymentWizardState: { $set: new DeploymentWizardState() },
		deviceHealthModalState: { $set: state.UI.deviceHealthModalState || new DeviceHealthModalStates() }
	}
});
const V1_21 = (state: AppState) => update(state, {
	Ads: {
		analytics: { $set: state.Ads.analytics || [] },
		finishedReports: { $set: state.Ads.finishedReports || [] },
		pendingReports: { $set: state.Ads.pendingReports || [] }
	},
	UI: {
		activeFilters: { $set: state.UI.activeFilters || new ActiveFilterState(initialActiveFilterStates) },
		activeSearch: { $set: state.UI.activeSearch || new ActiveStringState(initialActiveSearchStates) },
		activeSelection: { $set: state.UI.activeSelection || new ActiveStringArrayState(initialActiveSelectionStates) },
		activeSorts: { $set: state.UI.activeSorts || new ActiveObjectArrayState(initialActiveSortStates) },
		activeTags: { $set: state.UI.activeTags || new ActiveStringArrayState(initialActiveTagStates) },
		activeViews: { $set: state.UI.activeViews || new ActiveStringState(initialActiveViewStates) }
	}
});
const V1_22 = (state: AppState) => update(state, {
	UI: {
		modals: { $set: new ModalsState() }
	}
});
const V1_22A = (state: AppState) => update(state, {
	UI: {
		activeSelection: { $set: new ActiveStringArrayState(initialActiveSelectionStates) }
	}
});
const V1_23 = (state: AppState) => update(state, {
	Actions: { $set: new ActionsState() }
});
const V1_25A = (state: AppState) => update(state, {
	Actions: { $set: new ActionsState() },
	UI: {
		actionBuilderState: { $set: new ActionBuilderState() },
		modals: { $set: new ModalsState() }
	}
});
const V1_47 = (state: AppState) => update(state, {
	UI: {
		actionBuilderState: { $set: new ActionBuilderState() }
	}
});

export default {
	0: (state: AppState) => state,
	1: V1_6, // Migrations started in ConnectWeb v1.6
	2: V1_7, // ConnectWeb 1.7
	3: V1_8, // ConnectWeb 1.8
	4: V1_9, // ConnectWeb 1.9
	5: V1_10, // ConnectWeb 1.10
	6: V1_11, // ConnectWeb 1.11
	7: V1_12, // ConnectWeb 1.12
	8: V1_14, // ConnectWeb 1.14
	9: V1_15, // ConnectWeb 1.15
	10: V1_20, // ConnectWeb 1.20
	11: V1_21, // ConnectWeb 1.21
	12: V1_22, // ConnectWeb 1.22
	13: V1_22A, // currently v1.22.1 -- add "analytics" to active selection state
	14: V1_23, // ConnectWeb 1.23
	15: V1_25A, // ConnectWeb 1.25.1
	16: V1_47 // ConnectWeb 1.47.0
} as MigrationManifest;

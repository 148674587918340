import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";

interface OptionButtonProps {
	className?: string;
	onClick?: (variable?: any) => void | Function;
	fluid?: boolean;
	square?: boolean;
	rounded?: boolean;
	style?: CustomCSS;
	size?: "small" | "medium" | "large";
	selected?: boolean;
	disabled?: boolean;
	lightMode?: boolean;
}

class OptionButton extends React.PureComponent<OptionButtonProps> {
	constructor(props: OptionButtonProps) {
		super(props);
		this.styles = {
			base: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				margin: "0 5px",
				background: Colors.darkishGray,
				color: Colors.white,
				textAlign: "center",
				fontSize: "0.7rem",
				cursor: "pointer",
				userSelect: "none"
			},
			small: {
				width: 27
			},
			medium: {
				width: 34
			},
			large: {
				width: 50
			},
			rounded: {
				borderRadius: 3
			},
			selected: {
				background: Colors.primaryBlue
			},
			disabled: {
				opacity: 0.25,
				cursor: "not-allowed"
			},
			square: {
				height: this.getWidth(),
				width: this.getWidth()
			},
			fluid: {
				height: "auto",
				width: "auto",
				minWidth: "unset"
			},
			lightMode: {
				background: Colors.white
			},
			lightModeSelected: {
				background: Colors.selectedBlue
			}
		}
	}

	styles: CustomCSS;

	render() {
		const { className, onClick } = this.props;
		return (
			<div className={ className }
				style={ this.getStyle() }
				onClick={ onClick }>
				{ this.props.children }
			</div>
		);
	}

	getWidth() {
		switch (this.props.size) {
			case "small": return 27;
			case "medium": return 34;
			case "large": return 50;
			default: return 27;
		}
	}

	getStyle() {
		const { base } = this.styles;
		return {
			...base,
			...this.getThemeStyle(),
			...this.getCornerStyle(),
			...this.getSelectedStyle(),
			...this.getTypeStyle(),
			...this.getSizeStyle(),
			...this.getDisabledStyle(),
			...this.getFluidStyle(),
			...this.props.style
		}
	}

	getCornerStyle () {
		return this.props.rounded && this.styles.rounded;
	}

	getTypeStyle() {
		return this.props.square && this.styles.square;
	}

	getSelectedStyle() {
		const { selected, lightMode } = this.props;
		const { selected: selectedStyle, lightModeSelected } = this.styles;

		if (selected) {
			if (lightMode) {
				return lightModeSelected;
			}

			return selectedStyle;
		}

		return null;
	}

	getDisabledStyle() {
		return this.props.disabled && this.styles.disabled;
	}

	getFluidStyle() {
		return this.props.fluid && this.styles.fluid;
	}

	getSizeStyle() {
		if (!this.props.size) {
			return null;
		}

		return this.styles[this.props.size];
	}

	getThemeStyle() {
		return this.props.lightMode && this.styles.lightMode;
	}
}

export default OptionButton;
import * as update from "immutability-helper";
import { LOCATION_CHANGE, LocationChangeAction } from "react-router-redux";

import { getState } from "@connect/Data";
import { Action, ActiveDeselectDispatch, ActiveSelectionDispatch } from "@connect/Interfaces";
import { DeviceGroupDispatch } from "@connect/Interfaces";
import { removeActiveSelectionItem } from "Data/Actions/UI";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { ActiveStringArrayState, UIState } from "Data/Objects/AppState";
import { initialActiveSelectionStates } from "Data/Objects/UI";
import { getDeviceGroupById } from "Data/Selectors/Devices";
import { getLocationPaths } from "Data/Selectors/System";
import { addDataToState, createReducer, setState } from "Data/Utils";

export function addToActiveSelection(state: ActiveStringArrayState, action: Action<ActiveSelectionDispatch>) {
	const { path, selection } = action.args;
	const newItems = selection.filter((item: string) => {
		return !state[path].includes(item);
	});

	return addDataToState(state, path, newItems);
}

export function clearSelectionStates(state: ActiveStringArrayState, action: LocationChangeAction) {
	const locations = getLocationPaths(getState());

	// use `current` because `previous` is still what it was prior to this action
	if (action.payload.pathname !== locations.current) {
		return UIState.getNewActiveSelectionState();
	}

	return state;
}

export function setActiveSelection(state: ActiveStringArrayState, action: Action<ActiveSelectionDispatch>) {
	const { path, selection } = action.args;

	return setState(state, path, selection);
}

export function removeFromActiveSelection(state: ActiveStringArrayState, action: Action<ActiveSelectionDispatch>) {
	const { path, selection } = action.args;
	const subState = state[path];
	const newSubState = subState.slice().filter((uuid) => !selection.includes(uuid));

	return update(state, {
		[path]: { $set: newSubState }
	});
}

export function removeItemFromSelection(state: ActiveStringArrayState, action: Action<ActiveDeselectDispatch>) {
	const { path, uuid } = action.args;
	const newState = state[path].filter(id => id !== uuid);
	return setState(state, path, newState);
}

export function removeDeviceGroup(state: ActiveStringArrayState, action: Action<DeviceGroupDispatch>) {
	const { deviceGroup } = action.args;
	const { uuid, parent } = deviceGroup;
	const prevDeviceGroup = getDeviceGroupById(getState(), uuid);
	if (prevDeviceGroup && prevDeviceGroup.parent !== parent) {
		const removedSelectAction = removeActiveSelectionItem("deviceGroups", uuid);
		return removeItemFromSelection(state, removedSelectAction);
	} else {
		return state;
	}
}

export function resetActiveSelection(state: ActiveStringArrayState, action: Action<null>) {
	return new ActiveStringArrayState(initialActiveSelectionStates);
}

const reducers = {
	[ACTION_TYPES.UI.ADD_ACTIVE_SELECTION.type]: addToActiveSelection,
	[ACTION_TYPES.UI.REMOVE_ACTIVE_SELECTION.type]: removeFromActiveSelection,
	[ACTION_TYPES.UI.REMOVE_ACTIVE_SELECTION_ITEM.type]: removeItemFromSelection,
	[ACTION_TYPES.UI.RESET_ACTIVE_SELECTION.type]: resetActiveSelection,
	[ACTION_TYPES.UI.SET_ACTIVE_SELECTION.type]: setActiveSelection,
	[ACTION_TYPES.Devices.UPDATE_DEVICE_GROUP.type]: removeDeviceGroup,
	[LOCATION_CHANGE]: clearSelectionStates
};

export default createReducer(reducers, ActiveStringArrayState, initialActiveSelectionStates);

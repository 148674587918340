import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Context, IAd } from "@connect/Interfaces";
import AdCard from "Components/Ads/AdCard";
import { Colors } from "Components/Global/Constants";
import GridView, { IGridViewProps } from "Components/Global/GridView";
import { addToActiveSelection, removeActiveSelection } from "Data/Actions/UI";
import { AppState } from "Data/Objects/AppState";
import { getActiveSelection, getActiveUuid } from "Data/Selectors/UI";

const mapStateToProps = (state: AppState) => ({
	activeSelection: getActiveSelection(state, "ads"),
	activeUuid: getActiveUuid(state, "ads")
});

const mapDispatchToProps = (dispatch) => ({
	addToSelected: (ids: string[]) => dispatch(addToActiveSelection("ads", ids)),
	removeFromSelected: (ids: string[]) => dispatch(removeActiveSelection("ads", ids)),
	setActive: (uuid: string) => dispatch(push(`/ads/${uuid}`))
});

interface IAdsGridProps extends IGridViewProps {
	content: IAd[];
	context?: Context;
	onAdSelected?: (ad: IAd) => void; // this prop is used in the case of a modal ad context
	onCreateNewAd: () => void;
}

export class AdsGrid extends GridView<IAdsGridProps> {
	constructor(props: IAdsGridProps) {
		super(props);

		this.handleCardSelection = this.handleCardSelection.bind(this);
		this.renderAdCard = this.renderAdCard.bind(this);
	}

	render() {
		const cards = [
			this.renderCreateAd(),
			...this.props.content.map(this.renderAdCard)
		];

		return this.renderContainer(cards);
	}

	renderAdCard(ad: IAd, index: number) {
		const { selectModeOn } = this.props;
		const { uuid } = ad;
		const isSelected = this.isSelected(uuid);

		return (
			<AdCard
				key={ `${ uuid }_ad_card_${ index }` }
				bulkSelectActive={ selectModeOn }
				cardObject={ ad }
				cardType="ad"
				fontColor={ Colors.black }
				format={ "wide" }
				isSelected={ isSelected }
				onCardSelection={ this.handleCardSelection(ad, isSelected) }
			/>
		);
	}

	renderCreateAd() {
		const { selectModeOn, context, onCreateNewAd } = this.props;

		if (selectModeOn || context === Context.MODAL) {
			return null;
		}

		return (
			<AdCard
				key="create_ad_card"
				cardObject={ {} as IAd }
				cardType="ad"
				onCreateAdClicked={ onCreateNewAd }
				bulkSelectActive={ selectModeOn }
			/>
		);
	}

	handleCardSelection(ad: IAd, isSelected: boolean) {
		return (bulkAndShift: boolean) => {
			const { context, onAdSelected, selectModeOn } = this.props;

			if (onAdSelected && context !== Context.PAGE && !selectModeOn) {
				return onAdSelected(ad);
			}

			return super.handleCardSelection(ad, isSelected)(bulkAndShift);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsGrid);
import * as React from "react";
import * as update from "immutability-helper";
import * as animations from "react-animations";
import { connect } from "react-redux";
import { CustomCSS, AuthErrorTypes } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";
import { VERSION } from "Data/Objects/AppState";
import { RouteComponentProps, match as routerMatch } from "react-router-dom";
import { Card, Form, Button } from "antd";
import { Input, Icon, Loader } from "Components/Global/Common";
import inputValidation from "Data/Objects/Validation";
import { Utils } from "@connect/Utils";
import { Notifications } from "@connect/Notifications";
import ErrorDescription from "Components/Global/ErrorDescription";
import { forgotPassword, login, ssoLogin } from "Data/Actions/UserAsync";
import { setPrivacyPolicyModalVisibility, setTermsConditionsModalVisibility } from "Data/Actions/UI/Modals";
import Radium from "radium";
import { push } from "react-router-redux";

interface LoginPageProps extends RouteComponentProps<null> {
	forgotPassword: (email: string) => any;
	login: (login: string, password: string, twoFactorKey?: string, recovery?: boolean) => any;
	showPrivacyPolicy: () => void;
	showTermsAndConditions: () => void;
	loginSSO: (query: string) => void;
	push: (path: string) => void;
}

enum CardType {
	LOGIN,
	TWOFACTOR,
	RECOVERY,
	FORGOTPASSWORD
}

interface LoginPageState {
	loginEmail: string;
	password: string;
	forgotPasswordEmail: string;
	twoFactorKey: string;
	loginCard: CardType;
}

interface SAMLParams extends routerMatch<null> {
	clientName: string;
	ssoType: string;
}

export const mapDispatchToProps = (dispatch) => ({
	forgotPassword: (email: string) => dispatch(forgotPassword(email)),
	login: (email: string, password: string, twoFactorKey?: string, recovery?: boolean) =>
		dispatch(login(email, password, twoFactorKey, recovery)),
	showPrivacyPolicy: () => dispatch(setPrivacyPolicyModalVisibility(true)),
	showTermsAndConditions: () => dispatch(setTermsConditionsModalVisibility(true)),
	loginSSO: (query: string) => dispatch(ssoLogin(query)),
	push: (path: string) => dispatch(push(path))
});

@Radium
class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
	constructor(props: LoginPageProps) {
		super(props);

		this.animationDuration = 800;

		this.state = {
			loginEmail: "",
			password: "",
			twoFactorKey: "",
			forgotPasswordEmail: "",
			loginCard: CardType.LOGIN
		}

		this.styles = {
			base: {
				minHeight: 295,
				width: "75%",
				animation: "x " + this.animationDuration + "ms",
				animationName: Radium.keyframes(animations.flipInX, "flipInX"),
				display: "inline-block"
			},
			borderRadius: {
				borderRadius: 6
			},
			bottomLink: {
				color: "rgba(0, 0, 0, 0.4)",
				fontSize: "larger"
			},
			copyright: {
				display: "flex",
				alignItems: "flex-end",
				justifyContent: "center",
				paddingBottom: "2em",
				color: Colors.white,
				fontSize: "smaller"
			},
			fullWidth: {
				width: "100%"
			},
			help: {
				display: "flex",
				alignItems: "flex-end",
				justifyContent: "center",
				paddingBottom: "1em",
				color: Colors.white,
				fontSize: "small"
			},
			label: {
				color: "#8e8e8e",
				fontSize: "larger"
			},
			loginButton: {
				marginTop: "2em",
				width: "100%"
			},
			loginIcon: {
				marginRight: "1em"
			},
			loginLogo: {
				position: "relative",
				height: "5em",
				top: "2.75em",
				left: "calc(50% - 2.5em)",
				zIndex: 1
			},
			logoAndForm: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			pvmOutline: {
				height: "90vh"
			},
			rightSide: {
				backgroundImage: "linear-gradient(90deg, rgba(34, 147, 206, .8), rgba(0, 0, 70, .8)), url(./img/Login_Girl.jpg)",
				backgroundSize: "cover",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				gridColumn: "2 / 3",
				gridRow: "1 / 5"
			},
			wrapper: {
				backgroundColor: "#2e333f",
				position: "absolute",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 900,
				display: "grid",
				gridTemplateColumns: "minmax(300px, 1fr) 2fr",
				gridTemplateRows: "1fr 2fr 1fr",
				height: "100%",
				minHeight: "100%"
			},
			text: {
				color: Colors.lightGray,
				marginBottom: 8
			},
			heading: {
				color: Colors.black,
				margin: "10px 0 6px 0"
			},
			upperFooter: {
				textAlign: "center"
			},
			privacyTermsContainer: {
				display: "flex",
				cursor: "pointer"
			},
			terms: {
				marginRight: 10
			},
			loading: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "100vw",
				height: "100vh"
			},
			ssoCard: {
				marginTop: 20
			}
		};

		this.toggleRecoveryCodeInput = this.toggleRecoveryCodeInput.bind(this);
		this.updateLoginEmail = this.updateLoginEmail.bind(this);
		this.updateLoginPassword = this.updateLoginPassword.bind(this);
		this.handleLogIn = this.handleLogIn.bind(this);
		this.handleForgotPasswordLink = this.handleForgotPasswordLink.bind(this);
		this.updateTwoFactorKey = this.updateTwoFactorKey.bind(this);
		this.updateForgotPasswordEmail = this.updateForgotPasswordEmail.bind(this);
		this.handleForgotPasswordButton = this.handleForgotPasswordButton.bind(this);
		this.handleLoginLink = this.handleLoginLink.bind(this);
		this.handleSSOLogin = this.handleSSOLogin.bind(this);
	}

	animationDuration: number;
	styles: CustomCSS;

	componentWillMount() {
		const { location, loginSSO } = this.props;

		if (Utils.isSamlPage()) {
			loginSSO(location.search);
		}
	}

	render() {
		return (
			<React.Fragment>
				{ this.renderLoginUI() }
				{ this.renderLoadingUI() }
			</React.Fragment>
		);
	}

	renderLoadingUI() {
		if (Utils.isSamlPage()) {
			return null;
		}

		return (
			<div style={ this.styles.loading }>
				<Loader />
			</div>
		);
	}

	renderLoginUI() {
		const {
			copyright, logoAndForm, fullWidth, pvmOutline, rightSide,
			wrapper, help, upperFooter, privacyTermsContainer, terms
		} = this.styles;
		const { location, showTermsAndConditions, showPrivacyPolicy } = this.props;
		const copyrightYears = "2017-" + (new Date()).getFullYear();

		if (location.search) {
			return null;
		}

		return (
			<div style={ wrapper }>
				<div style={ logoAndForm }>
					<img src="/img/ClintonConnect_webLOGIN_white.svg" style={ fullWidth } />
				</div>
				<div style={ logoAndForm }>
					{ this.renderCardContent() }
				</div>
				<div style={ help }>
					<div style={ upperFooter }>
						<div style={ privacyTermsContainer }>
							<div style={ terms } onClick={ showTermsAndConditions }>Terms & Conditions</div>
							<div onClick={ showPrivacyPolicy }>Privacy Policy</div>
						</div>
					</div>
				</div>
				<div style={ copyright }>
					&copy; { copyrightYears } Clinton Electronics.  Version { VERSION }
				</div>
				<div style={ rightSide }>
					<img src="/img/PVM_Outline.svg" style={ pvmOutline } />
				</div>
			</div>
		);
	}

	renderCardContent() {
		if (Utils.isSamlPage()) {
			return this.renderSAMLLogin();
		}

		return (
			<React.Fragment>
				{ this.renderLoginForm() }
				{ this.render2faForms() }
				{ this.renderPasswordForm() }
			</React.Fragment>
		)
	}

	renderSAMLLogin() {
		const { base, loginButton, loginIcon, loginLogo, label, ssoCard } = this.styles;

		return (
			<div style={ base }>
				<img src="/img/ClintonConnect_C.svg" style={ loginLogo } />
				<Card>
					<Form style={ ssoCard }>
						<span style={ label }>You have been logged out.</span>
						<Button
							size="large"
							style={ loginButton }
							type="primary"
							onClick={ this.handleSSOLogin }
						>
							<Icon name="sign-in" size="smaller" style={ loginIcon } />
							Log Back In
						</Button>
					</Form>
				</Card>
			</div>
		);
	}


	renderLoginForm() {
		if (this.state.loginCard !== CardType.LOGIN) {
			return null;
		}

		const { base, borderRadius, bottomLink, loginButton, loginIcon, loginLogo, label } = this.styles;

		return (
			<div style={ base }>
				<img src="/img/ClintonConnect_C.svg" style={ loginLogo } />
				<Card>
					<Form>
						<span style={ label }>Email</span>
						<Input
							id="loginEmail"
							validator={ inputValidation.email }
							style={ borderRadius }
							hideMaxLength
							updateCallback={ this.updateLoginEmail }
							onPressEnter={ this.handleLogIn }
							type="email"
							value={ this.state.loginEmail }
						/>
						<span style={ label }>Password</span>
						<Input
							id="loginPassword"
							validator= { inputValidation.password }
							style={ borderRadius }
							hideMaxLength
							updateCallback={ this.updateLoginPassword }
							onPressEnter={ this.handleLogIn }
							type="password"
							value={ this.state.password }
						/>
						<Button
							size="large"
							style={ loginButton }
							type="primary"
							onClick={ this.handleLogIn }
						>
							<Icon name="sign-in" size="smaller" style={ loginIcon } />
							CONNECT
						</Button>
					</Form>
					<div style={ loginButton }>
						<a href="#"
							onClick={ this.handleForgotPasswordLink }
							style={ bottomLink }>
							Forgot Your Password?
						</a>
					</div>
				</Card>
			</div>
		);
	}

	render2faForms() {
		const { loginCard } = this.state;
		if (loginCard !== CardType.TWOFACTOR && loginCard !== CardType.RECOVERY) {
			return null;
		}

		const recovery = loginCard === CardType.RECOVERY;
		const title = recovery ?
			"Recovery Code Required" :
			"Authenticator Token Required";
		const message = recovery ?
			"You'll need one of your recovery codes to continue." :
			"You'll need the six digit code from your authenticator app to continue.";
		const linkText = recovery ?
			"Log in with an Authenticator Token" :
			"Log in with a Recovery Code";
		const warning = recovery ?
			"If you have lost your Recovery Codes you will need to contact support to regain access to your account." :
			"";

		const {
			base, borderRadius, label, loginButton, loginIcon, loginLogo, text, heading, bottomLink
		} = this.styles;

		return (
			<div
				key={ loginCard }
				style={ base }
			>
				<img src="/img/ClintonConnect_C.svg" style={ loginLogo } />
				<Card>
					<Form>
						<div style={ { ...label, ...heading } }>{ title }</div>
						<p style={ text }>{ message }</p>
						<Input
							id="twoFactorKey"
							style={ borderRadius }
							hideMaxLength
							updateCallback={ this.updateTwoFactorKey }
							onPressEnter={ this.handleLogIn }
							type="text"
							value={ this.state.twoFactorKey }
							autoComplete="false"
						/>
						<Button
							size="large"
							style={ loginButton }
							type="primary"
							onClick={ this.handleLogIn }
						>
							<Icon name="sign-in" size="smaller" style={ loginIcon } />
								CONNECT
						</Button>
						<br />
						<a onClick={ this.toggleRecoveryCodeInput }>
							{ linkText }
						</a>
						<br />
						<p>{ warning }</p>
					</Form>
					<div style={ loginButton }>
						<a href="#"
							onClick={ this.handleLoginLink }
							style={ bottomLink }>
							Return to Log in page
						</a>
					</div>
				</Card>
			</div>
		);
	}

	renderPasswordForm() {
		if (this.state.loginCard !== CardType.FORGOTPASSWORD) {
			return null;
		}

		const { base, borderRadius, bottomLink, label, loginLogo, loginButton, heading } = this.styles;

		return (
			<div style={ base }>
				<img src="/img/ClintonConnect_C.svg" style={ loginLogo } />
				<Card>
					<Form>
						<div style={ { ...label, ...heading } }>Forgot Password</div>
						<div>Please enter the email address associated with your account.</div>
						<span style={ label }>Email</span>
						<Input
							id="loginForgotPassword"
							style={ borderRadius }
							hideMaxLength
							saveCallback={ this.updateForgotPasswordEmail }
							type="email"
							value=""
						/>
						<Button
							htmlType="submit"
							size="large"
							style={ loginButton }
							type="primary"
							onClick={ this.handleForgotPasswordButton }
						>
							Send
						</Button>
					</Form>
					<div style={ loginButton }>
						<a href="#"
							onClick={ this.handleLoginLink }
							style={ bottomLink }>
							Return to Log in page
						</a>
					</div>
				</Card>
			</div>
		);
	}

	updateState(key: string, value: string | boolean | CardType, callback?: () => void) {
		this.setState((state) => {
			return update(state, {
				[key]: { $set: value }
			});
		}, callback);
	}

	updateLoginEmail(email: string) {
		this.updateState("loginEmail", email);
	}

	updateLoginPassword(password: string) {
		this.updateState("password", password);
	}

	updateTwoFactorKey(twoFactorKey: string) {
		this.updateState("twoFactorKey", twoFactorKey);
	}

	toggleRecoveryCodeInput() {
		const { loginCard } = this.state;
		const twoFactorCard: CardType = loginCard === CardType.TWOFACTOR ? CardType.RECOVERY : CardType.TWOFACTOR

		this.showCard(twoFactorCard);
	}

	updateForgotPasswordEmail(email: string) {
		this.updateState("forgotPasswordEmail", email)
	}

	handleForgotPasswordButton() {
		if (Utils.isValidEmail(this.state.forgotPasswordEmail)) {
			this.props.forgotPassword(this.state.forgotPasswordEmail);
			this.showCard(CardType.LOGIN);
		}
	}

	handleForgotPasswordLink() {
		this.showCard(CardType.FORGOTPASSWORD);
	}

	handleLogIn(e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) {
		this.validateUser(e.shiftKey);
	}

	handleLoginLink() {
		this.showCard(CardType.LOGIN);
		this.updateState("twoFactorKey", "");
	}

	showCard(cardType: CardType) {
		this.updateState("loginCard", cardType);
	}

	handleSSOLogin() {
		const { match, push } = this.props;
		const params = match.params! as SAMLParams;
		const { ssoType, clientName } = params;

		push(`/${ ssoType }/${ clientName }`);
	}

	validateUser(shiftDown: boolean) {
		const { loginEmail, password, twoFactorKey, loginCard } = this.state;

		if (!shiftDown) {
			if (!Utils.isValidEmail(loginEmail)) {
				return;
			}
		}

		const showRecoveryCodeInput = loginCard === CardType.RECOVERY;
		this.props.login(loginEmail, password, twoFactorKey, showRecoveryCodeInput)
			.then(
				(result) => {
					if (result?.error === AuthErrorTypes.TWO_FACTOR_REQUIRED) {
						this.showCard(CardType.TWOFACTOR);
					}
				},
				(error) => {
					Notifications.error("Error logging in", <ErrorDescription error={ error } />);
				}
			)
	}
}

export default connect(null, mapDispatchToProps)(LoginPage);
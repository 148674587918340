import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { ActionSet, Context, CustomCSS, DeviceGroupChildren, IAd, IAdTemplate,
	IDeviceGroup, IPlaylist, NameUuid, PlaylistAd, Filters } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { ButtonTypes } from "Components/Global/Button";
import { Input } from "Components/Global/Common";
import { Modal, ModalKeys } from "Components/Global/Modal";
import { createActionSetAsync, updateActionSetAsync } from "Data/Actions/ActionsAsync";
import { createDeviceGroupAsync } from "Data/Actions/Devices";
import { createReportAsync } from "Data/Actions/HealthReportAsync";
import {
	setRequestNameModal,
	setSelectActionsModal,
	setSelectAdsModal,
	setSelectPlaylistsModal
} from "Data/Actions/UI/Modals";
import { createPlaylistAsync, updatePlaylistAsync } from "Data/Actions/Playlists";
import { createNewActiveAd } from "Data/Actions/UI/AdBuilder";
import { Ad } from "Data/Objects/Ads";
import { ActionBuilderState } from "Data/Objects/AppState";
import { DefaultMaxLength, DeviceGroupMaxLength } from "Data/Objects/Global";
import { Playlist } from "Data/Objects/Playlists";
import { nameValidation } from "Data/Objects/Validation";
import { getActionBuilderState } from "Data/Selectors/ActionBuilder";
import { getActiveActionSet } from "Data/Selectors/Actions";
import { getDeviceGroupById, getDeviceGroupPath } from "Data/Selectors/Devices";
import { getCurrentSelectPlaylistsModalContext, getRequestNameModalState } from "Data/Selectors/UI";
import { getActivePlaylist } from "Data/Selectors/Playlists";
import { getActiveFilters, getActiveSelection, getActiveUuid } from "Data/Selectors/UI";
import { cloneDeep } from "lodash";

export enum RequestNameTypes {
	ACTION = "action",
	AD = "ad",
	ANALYTICS = "analytics",
	PLAYLIST = "playlist",
	REPORT = "report",
	DEVICE_GROUP = "device_group"
};

interface RequestNameModalProps {
	actionBuilderState: ActionBuilderState;
	activeActionSet: ActionSet;
	activeAd?: string;
	activeDeviceGroup?: IDeviceGroup;
	activePlaylist: IPlaylist;
	playlistsModalContext: Context;
	adTemplate: IAdTemplate;
	createAction: (action: Partial<ActionSet>) => Promise<ActionSet>;
	createAd: (ad: IAd) => Promise<Ad>;
	createDeviceGroupAction?: (name: string, children: DeviceGroupChildren, activeGroup?: IDeviceGroup) => void;
	createPlaylist?: (playlist: IPlaylist, isCopy?: boolean) => Promise<IPlaylist>;
	deviceGroupPath?: NameUuid[];
	hideActionsModal: () => void;
	hideAdsModal: () => void;
	hidePlaylistModal: () => void;
	onPlaylistSelected?: (playlist: IPlaylist) => void;
	type: RequestNameTypes | "";
	saveNewReport: (name: string) => void;
	selectedDevices?: string[];
	selectedGroups?: string[];
	setActiveAction?: (id: string) => void;
	setActivePlaylist?: (id: string) => void;
	hideModal: () => void;
	updateActionSet: (action: ActionSet) => void;
	updatePlaylist: (playlist: IPlaylist) => void;
	visible?: boolean;
}

interface RequestNameModalState {
	canSubmit: boolean;
	label: string;
	maxLength: number;
	modalHeader: string;
	name: string;
	prompt: string;
}

const mapDispatchToProps = (dispatch) => ({
	createAction: (action: Partial<ActionSet>) => dispatch(createActionSetAsync(action)),
	createAd: (ad: IAd) => dispatch(createNewActiveAd(ad)),
	createDeviceGroupAction: (name: string, children: DeviceGroupChildren, activeGroup: IDeviceGroup) =>
		dispatch(createDeviceGroupAsync(name, activeGroup, children)),
	createPlaylist: (playlist: IPlaylist, isCopy?: boolean) => dispatch(createPlaylistAsync(playlist, isCopy)),
	hideActionsModal: () => dispatch(setSelectActionsModal(false)),
	hideAdsModal: () => dispatch(setSelectAdsModal(false)),
	hideModal: () => dispatch(setRequestNameModal(false, "", undefined)),
	hidePlaylistModal: () => dispatch(setSelectPlaylistsModal(false, [])),
	updateActionSet: (action: ActionSet) => dispatch(updateActionSetAsync(action)),
	saveNewReport: (name: string) => dispatch(createReportAsync(name)),
	setActiveAction: (id: string) => dispatch(push(`/actions/${id}`)),
	setActivePlaylist: (id: string) => dispatch(push(`/playlists/${id}`)),
	updatePlaylist: (playlist: IPlaylist) => dispatch(updatePlaylistAsync(playlist))
});

const mapStateToProps = (state) => {
	const { visible, type, adTemplate } = getRequestNameModalState(state);
	const filters = getActiveFilters(state, Filters.DEVICES);

	return {
		actionBuilderState: getActionBuilderState(state),
		activeActionSet: getActiveActionSet(state),
		activeAd: getActiveUuid(state, "ads"),
		activeDeviceGroup: getDeviceGroupById(state, filters.group as string),
		activePlaylist: getActivePlaylist(state),
		playlistsModalContext: getCurrentSelectPlaylistsModalContext(state),
		adTemplate,
		type,
		visible,
		deviceGroupPath: getDeviceGroupPath(state, filters.group as string),
		selectedDevices: getActiveSelection(state, "devices"),
		selectedGroups: getActiveSelection(state, "deviceGroups")
	}
};

export class RequestNameModal extends React.Component<RequestNameModalProps, RequestNameModalState> {
	constructor(props: RequestNameModalProps) {
		super(props);
		this.state = {
			canSubmit: true,
			name: "",
			label: "",
			maxLength: 190,
			modalHeader: "",
			prompt: ""
		}

		this.styles = {
			inputStyle: {
				marginTop: "5px"
			},
			promptStyle: {
				marginTop: 10
			},
			modalStyle: {
				height: 125
			}
		}

		this.hide = this.hide.bind(this);
		this.disableSubmit = this.disableSubmit.bind(this);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleOnSubmit = this.handleOnSubmit.bind(this);
		this.setInputRef = this.setInputRef.bind(this);
	}

	styles: CustomCSS;
	_input: Input;

	static	getDerivedStateFromProps(props: RequestNameModalProps, state: RequestNameModalState) {
		const newState = cloneDeep(state);

		const { maxLength, prefix, prompt, label } = RequestNameModal.getModalState(props);
		const { canSubmit, name } = newState;

		const uppercasedType = Utils.properCaseString(label, "_");
		let defaultName = name;
		const headerString = `Create New ${ uppercasedType }`

		let newCanSubmit = false;

		if (!name && prefix && !canSubmit) {
			defaultName = RequestNameModal.getDefaultName(props, state);
		}

		if (defaultName.length) {
			newCanSubmit = true;
		}

		return update(newState, {
			label: { $set: uppercasedType },
			maxLength: { $set: maxLength },
			prefix: { $set: prefix },
			prompt: { $set: prompt },
			name: { $set: defaultName },
			modalHeader: { $set: headerString },
			canSubmit: { $set: newCanSubmit }
		});
	}

	static getDefaultName(props: RequestNameModalProps, state: RequestNameModalState) {
		const { prefix } = RequestNameModal.getModalState(props);
		const timestamp = Utils.getDefaultTimestamp();

		const uppercasedPrefix = Utils.properCaseString(prefix, "_");

		let defaultName = "";

		if (!state.name.length && prefix) {
			defaultName = `Untitled ${ uppercasedPrefix } ${ timestamp }`;
		}

		return defaultName;
	}

	static getDeviceGroupPath (deviceGroupPath: NameUuid[]) {
		return deviceGroupPath && deviceGroupPath.reduce((p, { name }, index) => {
			if (index === 0) {
				return name + p;
			}

			return `${ p } ${ name } / `;
		}, " / ");
	}

	static getModalState(props: RequestNameModalProps) {
		const { deviceGroupPath, type } = props;
		const empty = {
			label: "",
			maxLength: DefaultMaxLength,
			prefix: "",
			prompt: ""
		}

		if (type) {
			const RequestNameModalTypes = {
				action: {
					label: "action",
					maxLength: DefaultMaxLength,
					prefix: "action",
					prompt: ""
				},
				ad: {
					label: "ad",
					maxLength: DefaultMaxLength,
					prefix: "ad",
					prompt: ""
				},
				playlist: {
					label: "playlist",
					maxLength: DefaultMaxLength,
					prefix: "playlist",
					prompt: ""
				},
				report: {
					label: "report",
					maxLength: DefaultMaxLength,
					prefix: "report",
					prompt: ""
				},
				device_group: empty
			}

			if (deviceGroupPath) {
				RequestNameModalTypes.device_group = {
					label: "device_group",
					maxLength: DeviceGroupMaxLength,
					prefix: "",
					prompt: this.getDeviceGroupPath(deviceGroupPath)
				}
			}

			return RequestNameModalTypes[type];
		}

		return empty;
	}

	shouldComponentUpdate(nextProps: RequestNameModalProps, nextState: RequestNameModalState) {
		if ((!nextState.name || nextState.name !== this.state.name) && nextProps.visible !== this.props.visible ||
			this.state.canSubmit !== nextState.canSubmit) {
			return true;
		}

		return false;
	}

	render() {
		const { name, canSubmit, label, maxLength, modalHeader, prompt } = this.state;
		const { type, visible } = this.props;

		if (!type) {
			return null;
		}

		const { inputStyle, promptStyle, modalStyle } = this.styles;

		const actions = [
			{
				text: "Cancel",
				callback: this.hide
			},
			{
				text: "Submit",
				type: "primary" as ButtonTypes,
				callback: this.handleOnSubmit,
				disabled: !canSubmit,
				keyCode: ModalKeys.ENTER
			}
		];

		const title = {
			text: modalHeader
		};

		return (
			<Modal
				modalKey={ `request_name_modal_${ String(visible) }` }
				visible={ visible }
				actions={ actions }
				onCancel={ this.hide }
				title={ title }
			>
				<div style={ modalStyle }>
					<p>Please enter a name for your new { label }</p>
					<p style={ promptStyle }>{ prompt }</p>
					<Input
						autoFocus
						autoselect="true"
						maxLength={ maxLength }
						disableSubmitCallback={ this.disableSubmit }
						id="requestNameModalInput"
						ref={ this.setInputRef }
						updateCallback={ this.handleNameChange }
						style={ inputStyle }
						value={ name }
						validator={ nameValidation(maxLength) }
					/>
				</div>
			</Modal>
		);
	}

	disableSubmit(isValid: boolean) {
		this.setState((prevState) => ({
			canSubmit: isValid
		}));
	}

	handleNameChange(value: string) {
		if (value === "") {
			this.disableSubmit(false);
		}

		this.setState((prevState) => {
			return update((prevState), { name: { $set: value } });
		});
	}

	handleOnSubmit() {
		if (this.props.type) {
			this.handleSubmit();

			this.setState((prevState) => {
				return update((prevState), { name: { $set: "" } });
			}, this.hide);
		}
	}

	handleSubmit() {
		const { type } = this.props;
		const { name } = this.state;
		switch (type) {
			case "action":
				return this.createAction(name);
			case "ad":
				return this.createAd(name);
			case "device_group":
				return this.createDeviceGroup(name);
			case "playlist":
				return this.createPlaylist(name);
			case "report":
				return this.createReport(name);
			default: return null;
		}
	}

	createAction(name: string) {
		const { activePlaylist, createAction, hideActionsModal, setActiveAction, updatePlaylist } = this.props;
		const newAction = { name };

		if (createAction && setActiveAction) {
			createAction(newAction).then(({ uuid }) => {
				if (activePlaylist) {
					const updatedPlaylist = Object.assign({}, activePlaylist, {
						actionSet: {
							...newAction,
							uuid
						}
					});

					updatePlaylist(updatedPlaylist);
					hideActionsModal();
				}

				setActiveAction(uuid);
			});
		}
	}

	createDeviceGroup(name: string) {
		const {
			activeDeviceGroup,
			createDeviceGroupAction,
			selectedDevices,
			selectedGroups,
			hideModal
		} = this.props;

		if (createDeviceGroupAction && hideModal ) {
			const selection = {
				devices: selectedDevices || [],
				deviceGroups: selectedGroups || []
			}

			createDeviceGroupAction(name, selection, activeDeviceGroup);
			hideModal();
		}
	}

	createPlaylist(name: string = "Untitled Playlist") {
		const { activeActionSet, activeAd, createPlaylist, onPlaylistSelected, setActivePlaylist,
			playlistsModalContext } = this.props;
		if (createPlaylist && setActivePlaylist) {
			let newPlaylist = new Playlist();

			newPlaylist.uuid = "";
			newPlaylist.name = name;
			newPlaylist.orientation = "portrait";
			newPlaylist.ads = [];
			newPlaylist.actionSet = activeActionSet && {
				name: activeActionSet.name,
				uuid: activeActionSet.uuid
			};

			// Only add our ad to the playlist if we have an active ad and are on the ads page
			if (activeAd && playlistsModalContext === Context.ADS) {
				newPlaylist.ads.push({ uuid: activeAd } as PlaylistAd);
			}

			createPlaylist(newPlaylist).then(({ uuid }) => {
				if (onPlaylistSelected) {
					const playlist = Object.assign({}, newPlaylist, { uuid });
					return onPlaylistSelected(playlist);
				}

				setActivePlaylist(uuid);
				this.props.hidePlaylistModal();
			});
		}
	}

	createAd(name: string) {
		const { actionBuilderState, activeActionSet, adTemplate, createAd, hideAdsModal,
			type, updateActionSet } = this.props;
		const { selectedTrigger } = actionBuilderState;

		let newAd = new Ad().fromTemplate(adTemplate);

		if (type === RequestNameTypes.ANALYTICS) {
			newAd.analytics = Utils.getDefaultTimestamp();
		}

		newAd.name = name;
		createAd(newAd).then((ad) => {
			// if we're manipulating an actionSet, set the adUuid of that action
			if (activeActionSet) {
				const index = selectedTrigger;
				const adIndex = activeActionSet.data[index].actions.findIndex((d) => d.type === "ad");

				const updatedActionSet = update(activeActionSet, {
					data: {
						[index]: {
							actions: {
								[adIndex]: {
									adUuid: {
										$set: ad.uuid
									}
								}
							}
						}
					}
				});

				updateActionSet(updatedActionSet);
			}

			hideAdsModal();
			this.hide();
		});
	}

	createReport(name: string) {
		this.props.saveNewReport(name);
	}

	hide() {
		this.props.hideModal();
		this.setState({
			canSubmit: false,
			name: "",
			label: "",
			maxLength: 190,
			prompt: ""
		});
	}

	setInputRef(component: Input) {
		this._input = component;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestNameModal);

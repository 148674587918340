import { ButtonType } from "antd/lib/button/button";
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { CustomCSS, ICompany, IExtendedCompany, IUser } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import ManagedCompaniesTable from "Components/Admin/ManagedCompaniesTable";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import { Header, Loader } from "Components/Global/Common";
import ContentAreaTopBar, { SuffixButtonProps } from "Components/Global/ContentAreaTopBar";
import { IconWeights } from "Components/Global/Icon";
import { deactivateCompanyAsync, deleteCompanyAsync, getManagedCompaniesListAsync } from "Data/Actions/Company";
import { setCompanyCreateModalVisibility, setCompanyRequestModalVisibility } from "Data/Actions/UI/Modals";
import { getAdminIntegratorListAsync } from "Data/Actions/Team";
import { setActiveSearch } from "Data/Actions/UI";
import { AsyncState } from "Data/Objects/AppState";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveCompany, getManagedCompanies as selectCompanies } from "Data/Selectors/Company";
import { getActiveSearch, getAdminTeamsAsyncState, getManagedCompaniesAsyncState } from "Data/Selectors/UI";
import { getCurrentUser } from "Data/Selectors/User";

const mapDispatchToProps = (dispatch) => ({
	toggleCompanyActive: (company: ICompany) => dispatch(deactivateCompanyAsync(company)),
	deleteCompany: (company: ICompany) => dispatch(deleteCompanyAsync(company)),
	fetchIntegrators: () => dispatch(getAdminIntegratorListAsync()),
	getManagedCompanies: () => dispatch(getManagedCompaniesListAsync()),
	openCompanyCreateForm: () => dispatch(setCompanyCreateModalVisibility(true)),
	openCompanyRequestForm: () => dispatch(setCompanyRequestModalVisibility(true)),
	setCompanySearch: (value: string) => dispatch(setActiveSearch("managedCompanies", value))
});

const mapStateToProps = (state) => {
	const user = getCurrentUser(state);
	const companies = selectCompanies(state);
	const company = getActiveCompany(state);

	return {
		activeCompanyId: company && company.uuid,
		companies,
		companySearch: getActiveSearch(state, "managedCompanies"),
		user,
		asyncState: getManagedCompaniesAsyncState(state),
		adminAsyncState: getAdminTeamsAsyncState(state)
	};
};

interface ManagedCompaniesPageProps {
	activeCompanyId: string;
	companies: IExtendedCompany[];
	companySearch: string;
	deleteCompany: (company: ICompany) => void;
	toggleCompanyActive: (company: ICompany) => void;
	fetchIntegrators: () => void;
	getManagedCompanies: () => void;
	openCompanyCreateForm: () => void;
	openCompanyRequestForm: () => void;
	setCompanySearch: (value: string) => void;
	user: IUser;
	asyncState: AsyncState;
	adminAsyncState: AsyncState;
}

interface ManagedCompaniesPageState {
	selectedCompanies: string[];
	selectModeOn: boolean;
}

export class ManagedCompaniesPage extends React.Component<
ManagedCompaniesPageProps,
ManagedCompaniesPageState
> {
	constructor(props: ManagedCompaniesPageProps) {
		super(props);

		this.state = {
			selectedCompanies: [],
			selectModeOn: false
		};

		this.styles = {
			container: {
				padding: "0px 30px"
			},
			table: {
				paddingTop: 14
			},
			waypoint: {
				height: 40,
				marginTop: 40,
				textAlign: "center",
				width: "100%"
			}
		};

		this.createCompany = this.createCompany.bind(this);
		this.deleteSelected = this.deleteSelected.bind(this);
		this.deselectAll = this.deselectAll.bind(this);
		this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.renderCompanyAssets = this.renderCompanyAssets.bind(this);
		this.requestCompany = this.requestCompany.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.selectCompany = this.selectCompany.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
		this.setSelectedActiveState = this.setSelectedActiveState.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		const { asyncState, getManagedCompanies, fetchIntegrators, adminAsyncState } = this.props;
		const { currentlyFetching, haveAllData } = asyncState;
		const { currentlyFetching: fetchingAdmin, haveAllData: haveAllAdmin } = adminAsyncState;

		if (!currentlyFetching || !haveAllData) {
			getManagedCompanies();
		}

		if (!fetchingAdmin && this.isAdmin() || !haveAllAdmin && this.isAdmin()) {
			fetchIntegrators();
		}
	}

	componentDidUpdate(prevProps: ManagedCompaniesPageProps) {
		const { activeCompanyId, getManagedCompanies } = this.props;

		if (activeCompanyId !== prevProps.activeCompanyId) {
			getManagedCompanies();
		}
	}

	render() {
		const { selectedCompanies, selectModeOn } = this.state;
		const { companySearch, getManagedCompanies } = this.props;
		const { currentPage, currentlyFetching, haveAllData } = this.props.asyncState;
		const waypointStyle = {
			...this.styles.waypoint,
			display: currentlyFetching || !haveAllData ? "inline-block" : "none"
		};

		return (
			<div style={ this.styles.container }>
				<Header size={ 3 }>Managed Companies</Header>
				<ContentAreaTopBar
					batch={ this.getBatchOperations() }
					prefixButtons={ this.getHeaderPrefixButtons() }
					search={{
						filterText: companySearch,
						onSearchChange: this.handleSearchChange
					}}
				/>
				<ManagedCompaniesTable
					key={`${selectedCompanies.length}-${currentPage}`}
					selectedCompanies={ selectedCompanies }
					selectModeOn={ selectModeOn }
					setSelected={ this.selectCompany }
					companySearch={ companySearch }
				/>
				<div style={waypointStyle}>
					<Waypoint key={ currentPage } onEnter={ getManagedCompanies } />
					<Loader />
				</div>
			</div>
		);
	}

	renderCompanyAssets(companyUuid: string) {
		const [ company ] = this.props.companies.filter((c) => companyUuid === c.uuid);

		return (
			<p key={ `company_assets_${ companyUuid }` }>
				<strong>{ company.name }</strong>: { company.devicesCount } devices, { company.usersCount } users
			</p>
		);
	}

	createCompany() {
		this.props.openCompanyCreateForm();
	}

	deleteSelected() {
		const { activeCompanyId, companies, deleteCompany } = this.props;
		const { selectedCompanies } = this.state;
		const attemptingToDeleteSelf = selectedCompanies.includes(activeCompanyId);

		this.state.selectedCompanies
			.filter((uuid) => uuid !== activeCompanyId)
			.forEach((uuid) => {
				const company = companies.find((c) => c.uuid === uuid) as ICompany;
				deleteCompany(company);
			});

		if (attemptingToDeleteSelf) {
			Notifications.warning(`Cannot delete active company.
				Please see your administrator for further assistance.`);
		}
	}

	setSelectedActiveState(active: boolean) {
		return () => {
			const { companies, toggleCompanyActive, activeCompanyId } = this.props;
			this.state.selectedCompanies.forEach((uuid) => {
				const company = companies.find((c) => c.uuid === uuid) as ICompany;
				const notOwnCompany = activeCompanyId !== uuid;

				if (company.active !== active && notOwnCompany) {
					toggleCompanyActive(company);
				}
			});
		}
	}

	deselectAll() {
		this.setState(() => ({
			selectedCompanies: []
		}));
	}

	getBatchOperations() {
		if (!this.isAdmin()) {
			return;
		}

		const { companies } = this.props;
		const numSelected = this.state.selectedCompanies.length;

		const buttons = [
			{
				disabled: numSelected === companies.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.selectAll
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular" as IconWeights,
				onClick: this.deselectAll
			},
			{
				disabled: !numSelected,
				label: "Activate",
				icon: "eye",
				iconWeight: "regular" as IconWeights,
				onClick: this.setSelectedActiveState(true)
			},
			{
				disabled: !numSelected,
				label: "Deactivate",
				icon: "eye-slash",
				iconWeight: "regular" as IconWeights,
				onClick: this.setSelectedActiveState(false)
			},
			{
				disabled: !numSelected,
				label: "Delete Companies",
				icon: "trash",
				iconWeight: "regular" as IconWeights,
				onClick: this.handleDeleteSelected,
				type: "danger" as ButtonType
			}
		];

		return {
			active: this.state.selectModeOn,
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Companies",
			buttons: buttons as IBatchOperationsButton[]
		};
	}

	getHeaderPrefixButtons() {
		const { COMPANIES_CREATE, COMPANIES_REQUEST } = PERMISSIONS;
		const createCompany = {
			icon: "plus-circle",
			onClick: this.createCompany,
			children: "Add Company",
			className: "primary-button"
		};
		const requestCompany = {
			icon: "plus-circle",
			onClick: this.requestCompany,
			children: "Request Company",
			className: "primary-button"
		};
		let buttons: SuffixButtonProps[] = [];

		if (hasPermission(COMPANIES_CREATE)) {
			buttons.push(createCompany);
		} else if (hasPermission(COMPANIES_REQUEST)) {
			buttons.push(requestCompany);
		}

		return buttons;
	}

	handleDeleteSelected() {
		this.toggleSelectMode();

		const { selectedCompanies } = this.state;
		const haveCompanies = selectedCompanies.length > 1;
		const noun = haveCompanies ? "companies" : "company";
		const contains = haveCompanies ? "contain" : "contains";
		const count = haveCompanies ? selectedCompanies.length + " " : "";
		const selected = `the selected ${ count }${ noun }`

		Notifications.confirm(
			`Delete ${ Utils.properCase(noun) }`,
			(
				<React.Fragment>
					<p>Are you sure you would like to delete { selected }?</p>
					<hr />
					<p>{ Utils.properCase(selected) } { contains } the following assets:</p>
					{ selectedCompanies.map(this.renderCompanyAssets) }
				</React.Fragment>
			),
			"Confirm",
			"Cancel",
			this.deleteSelected
		);
	}

	handleSearchChange(value: string) {
		this.props.setCompanySearch(value);
	}

	isAdmin() {
		return this.props.user.role.name.startsWith("cec-");
	}

	requestCompany() {
		this.props.openCompanyRequestForm();
	}

	selectAll() {
		this.setState(() => ({
			selectedCompanies: this.props.companies.map(({ uuid }) => uuid)
		}));
	}

	selectCompany(selectedCompanies: string[]) {
		this.setState(() => ({
			selectedCompanies
		}));
	}

	toggleSelectMode() {
		this.setState((state) => ({
			selectModeOn: !state.selectModeOn
		}));
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagedCompaniesPage);

import * as moment from "moment";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { getState } from "@connect/Data";
import { fetchToggleConfig } from "@connect/Features";
import { ApkVersion, NewCompanyRequest, VimeoVideos } from "@connect/Interfaces";
import SystemApi from "Api/System";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { setCurrentApkVersion, setFeatureToggles, setVimeoVideos, clearCache } from "Data/Actions/System";
import { setAsyncFetching, setAsyncState } from "Data/Actions/UI";
import { logout } from "Data/Actions/UserAsync";
import { CacheInvalidationPeriod } from "Data/Objects/Global";

export function tryFetchVimeoVideosAsync() {
	const { UI: { asyncState }, System: { videos } } = getState();
	const { lastFetch, lastFetchedCompany } = asyncState.videos;
	const expired = videos && moment().diff(lastFetch, "minute") > CacheInvalidationPeriod;

	return (dispatch) => {
		if (!lastFetchedCompany || expired) {
			return dispatch(fetchVimeoVideosAsync());
		}
	}
}

export function fetchVimeoVideosAsync() {
	return (dispatch) => {
		const { Company } = getState();
		const api = new SystemApi();
		dispatch(setAsyncFetching("videos", true, Company.activeCompanyId));
		return api.getVimeoVideos()
			.then((result: VimeoVideos) => {
				dispatch(setVimeoVideos(result));
				dispatch(setAsyncState("videos", false, 0));
				dispatch(setAsyncFetching("videos", false, Company.activeCompanyId));

			}, (error) => {
				dispatch(errorNotification("Error getting tutorial videos."))
				dispatch(setAsyncFetching("videos", false, Company.activeCompanyId));

			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
				dispatch(setAsyncFetching("videos", false, Company.activeCompanyId));
			});
	}
}

export function setFeatureTogglesAsync() {
	return (dispatch) => {
		return fetchToggleConfig()
			.then((config) => {
				dispatch(setFeatureToggles(config));
			});
	};
}

export function requestNewCompany(opts: NewCompanyRequest) {
	return (dispatch) => {
		const api = new SystemApi();

		return api.requestNewCompany(opts)
			.then(() => {
				dispatch(successNotification("Company request sent."))
			});
	};
}

export function fetchCurrentApk() {
	return (dispatch) => {
		const api = new SystemApi();
		return api.getCurrentAPK()
			.then((result: Partial<ApkVersion>) => {
				dispatch(setCurrentApkVersion(result));
			});
	}
}

export function clearCacheAndLogout() {
	return (dispatch) => {
		dispatch(logout()).then(() => {
			dispatch(clearCache())
		});
	}
}
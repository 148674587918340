import { createSelector } from "reselect";

import { FeatureToggle, VimeoTutorial, VimeoVideos } from "@connect/Interfaces";
import { AppState, LocationState, SystemState } from "Data/Objects/AppState";
import { getMediaPreviewVimeoId } from "Data/Selectors/UI";
import { identityReducer } from "Data/Utils";

export function getSystemState(state: AppState): SystemState {
	return state.System;
}

export function getConnectedState(state: SystemState) {
	return state.connected;
}

export function getLocationStatePaths(state: SystemState) {
	return {
		current: state.location.current.pathname,
		previous: state.location.previous.pathname
	};
}

export function getVimeoVideos(state: SystemState) {
	return state.videos;
}

export function getConnectTutorialVideos(state: VimeoVideos) {
	return state["Tutorial Videos"];
}

export function getInstallTutorialVideos(state: VimeoVideos) {
	return state["PVM Installation"];
}

export function getAllVimeoVideos(videos: VimeoVideos) {
	let allVideos: VimeoTutorial[] = [];
	Object.keys(videos).forEach((category) => {
		const videosInCategory = Object.values(videos[category]);
		videosInCategory.forEach((video) => allVideos.push(video));
	});
	return allVideos;
}

export function getVimeoVideoById(videos: VimeoTutorial[], id: number) {
	return videos.find((video) => video.id === id);
}

export function getRedirectState(state: SystemState): LocationState {
	return state.redirect;
}

export function getUnsaved(state: SystemState, section: string) {
	return state.unsaved[section];
}

export function getAllFeatureToggles(state: SystemState) {
	return state.features;
}

export function getFeatureToggleByName(features: FeatureToggle[], name: string) {
	return features.find((feature) => feature.name === name);
}

export function getCurrentApkVersion(state: SystemState) {
	if (state.currentApk && state.currentApk.version) {
		return state.currentApk.version
	}
	return null;
}

export function getPusherCredential(state: SystemState) {
	return state.pusherCredentials;
}

export const getConnected = createSelector(
	[ getSystemState ],
	getConnectedState
);

export const getRedirect = createSelector(
	[ getSystemState ],
	getRedirectState
);

export const getVideos = createSelector(
	[ getSystemState ],
	getVimeoVideos
);

export const getAllVideos = createSelector(
	[ getVideos ],
	getAllVimeoVideos
);

export const getVimeoVideo = createSelector(
	[ getAllVideos, getMediaPreviewVimeoId ],
	getVimeoVideoById
);

export const getVimeoVideoWithId = createSelector(
	[ getAllVideos, identityReducer ],
	getVimeoVideoById
);

export const getConnectTutorials = createSelector(
	[ getVideos ],
	getConnectTutorialVideos
);

export const getInstallTutorials = createSelector(
	[ getVideos ],
	getInstallTutorialVideos
);

export const getUnsavedState = createSelector(
	[ getSystemState, identityReducer ],
	getUnsaved
);

export const getFeatureToggles = createSelector(
	[ getSystemState ],
	getAllFeatureToggles
);

export const getFeatureToggle = createSelector(
	[ getFeatureToggles, identityReducer ],
	getFeatureToggleByName
);

export const getLocationPaths = createSelector(
	[ getSystemState ],
	getLocationStatePaths
);

export const getCurrentApk = createSelector(
	[ getSystemState ],
	getCurrentApkVersion
);

export const getPusherCredentials = createSelector(
	[ getSystemState ],
	getPusherCredential
)
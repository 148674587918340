import { Action, ExpandedDeviceGroupsDispatch } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { createReducer } from "Data/Utils";

export function setExpandedDeviceGroups(state: string[], action: Action<ExpandedDeviceGroupsDispatch>) {
	const { groups } = action.args;
	return groups;
}

export function resetExpandedDeviceGroups(state: string[], action: Action<null>) {
	return [];
}

const {
	SET_EXPANDED_DEVICE_GROUPS,
	RESET_EXPANDED_DEVICE_GROUPS
} = ACTION_TYPES.UI;

const reducers = {
	[SET_EXPANDED_DEVICE_GROUPS.type]: setExpandedDeviceGroups,
	[RESET_EXPANDED_DEVICE_GROUPS.type]: resetExpandedDeviceGroups
};

export default createReducer(reducers, Array, 0);

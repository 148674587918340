import * as React from "react";
import { connect } from "react-redux";

import { AdLayout, CustomCSS, IAd, SortTypes, ViewTypes, Sorts } from "@connect/Interfaces";
import AdsList from "Components/Ads/AdsList";
import ComponentsView from "Components/Ads/ComponentsView";
import { FullComponentHeight, MinimumComponentHeight } from "Components/Ads/Constants";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import { Button, Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { pushWithoutRender } from "Data/Actions/Navigation";
import { setActiveSort } from "Data/Actions/UI";
import { getActiveAd, getAdComponentsHeight } from "Data/Selectors/AdBuilder";
import { getActiveSorts, getActiveUuid } from "Data/Selectors/UI";

const mapDispatchToProps = (dispatch) => ({
	setSort: (sort: SortTypes) => dispatch(setActiveSort(Sorts.ADS, sort)),
	navigateToAd: (ad: IAd) => dispatch(pushWithoutRender(`/ads/${ad.uuid}`))
});

const mapStateToProps = (state) => {
	const activeUuid = getActiveUuid(state, "ads");
	const componentsHeight = getAdComponentsHeight(state);
	const heightThreshold = FullComponentHeight - MinimumComponentHeight;
	const canDragComponent = componentsHeight < heightThreshold;

	return {
		activeUuid,
		adsSortType: getActiveSorts(state, Sorts.ADS),
		ad: getActiveAd(state),
		canDragComponent,
		componentsHeight,
		selectedComponent: {} // TODO: selectedComponentSelector once component selection is written
	}
};

export interface IAdsPageSidebarProps {
	ad: IAd;
	activeUuid: string;
	adsSortType: SortTypes;
	canDragComponent: boolean;
	componentsHeight: number;
	navigateToAd: (ad: IAd) => void;
	onCreateNewAd: () => void;
	setSort: (sort: SortTypes) => void;
	setActiveAdUuid: (ad: IAd) => void;
	selectedComponent: any;
}

export interface IAdsPageSidebarState {
	adsViewType: ViewTypes;
}

export class AdsPageSidebar extends React.Component<IAdsPageSidebarProps, IAdsPageSidebarState> {
	constructor(props: IAdsPageSidebarProps) {
		super(props);

		this.state = {
			adsViewType: ViewTypes.LIST
		}

		this.styles = {
			componentListHeader: {
				color: Colors.lightGray,
				textAlign: "center",
				marginBottom: 10,
				width: "100%",
				height: "100%",
				fontSize: "0.9em"
			},
			adListHeader: {
				display: "block",
				width: "100%"
			},
			adListButtons: {
				display: "inline",
				float: "right"
			},
			adListViewButton: {
				marginRight: 6
			}
		}

		this.handleSetAlphaSort = this.handleSetAlphaSort.bind(this);
		this.handleSetDateSort = this.handleSetDateSort.bind(this);
		this.setAdsViewType = this.setAdsViewType.bind(this);
		this.renderAdsListElement = this.renderAdsListElement.bind(this);
	}

	styles: {
		componentListHeader: CustomCSS;
		adListHeader: CustomCSS;
		adListButtons: CustomCSS;
		adListViewButton: CustomCSS;
	};

	render() {
		let elements = [ this.renderAdsListElement() ];

		if (this.props.ad) {
			elements.unshift(this.renderComponentListElement());
		}

		return (
			<Accordion
				header={ this.renderHeader() }
				elements={ elements }
			/>
		);
	}

	renderComponentListElement() {
		const { ad, canDragComponent } = this.props;
		const { components } = (ad.layout as AdLayout);
		let hasFeed;
		let hasSlideshow;
		let hasVideo;

		components.forEach(({ type }) => {
			switch (type) {
				case "feed":
					hasFeed = true;
					break;
				case "slideshow":
					hasSlideshow = true;
					break;
				case "video":
					hasVideo = true;
					break;
			}
		});

		return new AccordionElement("Ad Components", (
			<React.Fragment>
				{ this.renderComponentListHeader() }
				<ComponentsView
					selectedComponentType={ this.getSelectedComponentType() }
					adHasFeed={ hasFeed }
					adHasSlideshow={ hasSlideshow }
					adHasVideo={ hasVideo }
					disabled={ !canDragComponent }
				/>
			</React.Fragment>
		));
	}

	renderComponentListHeader() {
		if (!this.props.canDragComponent) {
			return null;
		}

		return (
			<div style={ this.styles.componentListHeader }>
				Drag desired component onto ad layout
			</div>
		);
	}

	renderAdsListElement() {
		const { adsSortType } = this.props;
		const { adsViewType } = this.state;
		const { adListHeader, adListButtons, adListViewButton } = this.styles;
		const { NEWEST_FIRST, ALPHA } = SortTypes;
		const dateSortDirection = adsSortType === NEWEST_FIRST ? "up" : "down";
		const viewIcon = adsViewType === ViewTypes.GRID ? "th-list" : "th-large";
		const sortIcon = adsSortType === ALPHA ? "sort-alpha-down" : "sort-alpha-up";

		return new AccordionElement(
			<div style={ adListHeader }>
				My Ads
				<div style={ adListButtons }>
					<span
						style={ adListViewButton }
						onClick={ this.setAdsViewType }
					>
						<Icon name={ viewIcon } />
					</span>
					<span onClick={ this.handleSetAlphaSort }>
						<Icon name={ sortIcon } />
					</span>
					<span onClick={ this.handleSetDateSort }>
						<Icon name="calendar" sortDirection={ dateSortDirection } />
					</span>
				</div>
			</div>
			, this.renderAdList());
	}

	renderAdList() {
		return (
			<AdsList
				context="adsPanel"
				onAdClicked={ this.props.navigateToAd }
				adsViewType={ this.state.adsViewType }
			/>
		);
	}

	renderHeader() {
		return (
			<Button
				type="primary"
				icon="plus-circle"
				fluid
				corners="rounded"
				onClick={ this.props.onCreateNewAd }
			>
				New Ad
			</Button>
		);
	}

	handleSetAlphaSort(event: React.SyntheticEvent) {
		event.preventDefault();

		const { setSort, adsSortType } = this.props;
		const { ALPHA, REVERSE_ALPHA } = SortTypes;
		const newSortType = adsSortType === ALPHA ? REVERSE_ALPHA : ALPHA;

		setSort(newSortType);
	}

	handleSetDateSort(event: React.SyntheticEvent) {
		event.preventDefault();

		const { setSort, adsSortType } = this.props;
		const { NEWEST_FIRST, OLDEST_FIRST } = SortTypes;
		const newSortType = adsSortType === NEWEST_FIRST ? OLDEST_FIRST : NEWEST_FIRST;

		setSort(newSortType);
	}

	setAdsViewType(event: React.SyntheticEvent) {
		event.preventDefault();

		const isGrid = this.state.adsViewType === ViewTypes.GRID
		let adsViewType = isGrid ? ViewTypes.LIST : ViewTypes.GRID;
		this.setState({ adsViewType });
	}

	getSelectedComponentType() {
		const { ad, selectedComponent } = this.props;
		const components = ad && (ad.layout as AdLayout).components;

		return components
			.filter((component) => component.id === selectedComponent.uuid)
			.map((object) => object.type)[0];
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsPageSidebar);

import { Select as AntSelect } from "antd";
import { SelectProps as AntSelectProps } from "antd/lib/select/index";
import * as React from "react";

interface ISelectProps extends AntSelectProps {
	children?: any;
}

export class Select extends React.PureComponent<ISelectProps> {
	constructor(props: ISelectProps) {
		super(props);
		this.getSelectContainer = this.getSelectContainer.bind(this);
	}

	static Option = AntSelect.Option;

	render() {
		return (
			<AntSelect
				getPopupContainer={ this.getSelectContainer }
				{ ...this.props }
			/>
		);
	}

	// quick note regarding positioning of these elements-- if the popup
	// box of this or any Ant component is scrolling with the page,
	// whatever container / element needs to be scrollable above this
	// should have position: "relative" set on it for this to work
	// https://ant.design/docs/react/faq
	getSelectContainer(trigger?: Element | undefined) {
		if (trigger && trigger.parentNode) {
			return trigger.parentNode as HTMLElement;
		}

		return document.body as HTMLElement;
	}
}
import { ApkVersion } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";

const {
	CREATE_VERSION,
	SET_CURRENT_VERSION,
	SET_VERSIONS,
	PUBLISH_VERSION
} = ACTION_TYPES.Admin.Versions;

export function createVersion(version: ApkVersion) {
	return { type: CREATE_VERSION.type, args: { version }};
}

export function setCurrentVersion(version: ApkVersion) {
	return { type: SET_CURRENT_VERSION.type, args: { version }};
}

export function setVersions(versions: ApkVersion[]) {
	return { type: SET_VERSIONS.type, args: { versions }};
}

export function publishVersion(version: ApkVersion) {
	return { type: PUBLISH_VERSION.type, args: { version }};
}
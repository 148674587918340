import { createSelector } from "reselect";

import { FilterState, IAd, IMenuItem } from "@connect/Interfaces";
import { AdBuilderState, AppState, AsyncState, AsyncStates, DeviceHealthModalState, DeviceHealthModalStates,
	MediaPreviewState, UIState } from "Data/Objects/AppState";

export function canFetch(asyncState: AsyncState) {
	const { currentlyFetching, haveAllData, query } = asyncState;
	const canFetchInit = !currentlyFetching && !haveAllData;
	const asyncQuery = query as {};
	const queryKeys = Object.keys(asyncQuery);

	if (!queryKeys.length) {
		return canFetchInit;
	}

	return queryKeys.reduce((canFetchAll, key) => {
		const state = asyncQuery[key];
		return canFetchAll && !state.currentlyFetching && !state.haveAllData;
	}, canFetchInit);
}

export function lastPage(asyncState: AsyncState) {
	return asyncState.currentPage;
}

export function haveAllAsyncData(asyncState: AsyncState) {
	return asyncState.haveAllData;
}

export function haveAllDevicesData(asyncState: AsyncState) {
	return asyncState.haveAllData;
}

export function haveAllPlaylistsData(asyncState: AsyncState) {
	return asyncState.haveAllData;
}

export function getAsyncUIState(state: UIState) {
	return state.asyncState;
}

export function getUIState(state: AppState): UIState {
	return state.UI;
}

export function getAdBuilderUIState(state: UIState): AdBuilderState {
	return state.adBuilderState;
}

export function getActiveAdBuilderAd(state: AdBuilderState): IAd {
	return state.activeAd;
}

export function getExpandedGroupsState(state: UIState): string[] {
	return state.expandedDeviceGroups;
}

export function getSelectedComponentIndex(state: AdBuilderState): number {
	return state.selectedComponentIndex;
}

export function getSelectedComponentByIndex(ad: IAd, index: number) {
	if (index === -1) {
		return null;
	}

	return ad.layout.components[index];
}

export function getActivityAsync(asyncStates: AsyncStates) {
	return asyncStates.activity;
}

export function getAnalyticsAsync(asyncStates: AsyncStates) {
	return asyncStates.analytics;
}

export function getUsersAsync(asyncStates: AsyncStates) {
	return asyncStates.users;
}

export function getActionsAsync(asyncStates: AsyncStates) {
	return asyncStates.actions;
}

export function getAdsAsync(asyncStates: AsyncStates) {
	return asyncStates.ads;
}

export function getCompaniesAsync(asyncStates: AsyncStates) {
	return asyncStates.companies;
}

export function getDeploymentsAsync(asyncStates: AsyncStates) {
	return asyncStates.deployments;
}

export function getDeviceGroupsAsync(asyncStates: AsyncStates) {
	return asyncStates.deviceGroups;
}

export function getDevicesAsync(asyncStates: AsyncStates) {
	return asyncStates.devices;
}

export function getPlaylistsAsync(asyncStates: AsyncStates) {
	return asyncStates.playlists;
}

export function getManagedCompaniesAsync(asyncStates: AsyncStates) {
	return asyncStates.managedCompanies;
}

export function getAdminTeamsAsync(asyncStates: AsyncStates) {
	return asyncStates.managedCompanies;
}

export function getStoresAsync(asyncStates: AsyncStates) {
	return asyncStates.stores;
}

export function getTeamAsync(asyncStates: AsyncStates) {
	return asyncStates.teams;
}

export function getHealthReportsAsync(asyncStates: AsyncStates) {
	return asyncStates.healthReport;
}

export function getActiveNavItem(state: UIState) {
	return state.activeNavigationItem.currentLocation;
}

export function getPreviousNavItem(state: UIState) {
	return state.activeNavigationItem.previousLocation;
}

export function getFinishedReportsLength(state: UIState) {
	return state.finishedReports.length;
}

export function getMenu(state: UIState) {
	return state.menu;
}

export function getHealthReportKey(state: IMenuItem[]) {
	const healthMenu = state.find((i) => i.name === "Health");

	return healthMenu ? healthMenu.path : null;
}

export function getAccordionState(state: UIState, activeNavItem: string): string[] | null {
	return state.leftAccordion[activeNavItem] || null;
}

export function getCollapseState(state: UIState, activeNavItem: string): string[] | null {
	return state.collapse[activeNavItem] || null;
}

export function getActiveFilterState(state: UIState, path: string): FilterState {
	return state.activeFilters[path];
}

export function getActiveSearchState(state: UIState, path: string): string {
	return state.activeSearch[path];
}

export function getActiveSelectionState(state: UIState, path: string): string[] {
	return state.activeSelection[path];
}

export function getActiveSortState(state: UIState, path: string): string {
	return state.activeSorts[path];
}

export function getActiveTagState(state: UIState, path: string): string[] {
	return state.activeTags[path];
}

export function getActiveUuidState(state: UIState, path: string): string {
	return state.activeUuids[path];
}

export function getActiveViewState(state: UIState, path: string): string {
	return state.activeViews[path];
}

export function getPath(state: AppState, path: string) {
	return path;
}

export function getMediaPreview(state: UIState) {
	return state.mediaPreview;
}

export function getMediaPreviewId(mediaPreview: MediaPreviewState) {
	return mediaPreview.vimeoId;
}

export function getFinishedReportsState(state: UIState) {
	return state.finishedReports;
}

export function getRunningReportsState(state: UIState) {
	return state.runningReports;
}

export function getIsRunningReport(runningReports: string[], id: string) {
	return runningReports.indexOf(id) > -1;
}

export function getDeploymentWizardUIState(state: UIState) {
	return state.deploymentWizardState;
}

export function getDeviceHealthModalUIState(state: UIState) {
	return state.deviceHealthModalState;
}

export function getDeviceHealthModalDeviceUIState(state: DeviceHealthModalStates, id: string): DeviceHealthModalState {
	const potentialState = state[id];
	return potentialState || new DeviceHealthModalState();
}

export const getFinishedReports = createSelector(
	[ getUIState ],
	getFinishedReportsState
);

export const getRunningReports = createSelector(
	[ getUIState ],
	getRunningReportsState
);

export const getMediaPreviewState = createSelector(
	[ getUIState ],
	getMediaPreview
);

export const getMediaPreviewVimeoId = createSelector(
	[ getMediaPreviewState ],
	getMediaPreviewId
);

export const getAsyncState = createSelector(
	[ getUIState ],
	getAsyncUIState
);

export const getActivityAsyncState = createSelector(
	[ getAsyncState ],
	getActivityAsync
);

export const getAnalyticsAsyncState = createSelector(
	[ getAsyncState ],
	getActivityAsync
);

export const getUsersAsyncState = createSelector(
	[ getAsyncState ],
	getUsersAsync
);

export const getCompaniesAsyncState = createSelector(
	[ getAsyncState ],
	getCompaniesAsync
);

export const getDeploymentsAsyncState = createSelector(
	[ getAsyncState ],
	getDeploymentsAsync
);

export const getDeviceGroupsAsyncState = createSelector(
	[ getAsyncState ],
	getDeviceGroupsAsync
);

export const getDevicesAsyncState = createSelector(
	[ getAsyncState ],
	getDevicesAsync
);

export const getPlaylistsAsyncState = createSelector(
	[ getAsyncState ],
	getPlaylistsAsync
);

export const canFetchPlaylists = createSelector(
	[ getPlaylistsAsyncState ],
	canFetch
);

export const getExpandedDeviceGroups = createSelector(
	[ getUIState ],
	getExpandedGroupsState
);

export const getManagedCompaniesAsyncState = createSelector(
	[ getAsyncState ],
	getManagedCompaniesAsync
);

export const getAdminTeamsAsyncState = createSelector(
	[ getAsyncState ],
	getAdminTeamsAsync
);

export const getStoresAsyncState = createSelector(
	[ getAsyncState ],
	getStoresAsync
);

export const getTeamAsyncState = createSelector(
	[ getAsyncState ],
	getTeamAsync
);

export const haveAllTeamAsync = createSelector(
	[ getTeamAsyncState ],
	haveAllAsyncData
);

export const getActionsAsyncState = createSelector(
	[ getAsyncState ],
	getActionsAsync
);

export const getAdsAsyncState = createSelector(
	[ getAsyncState ],
	getAdsAsync
);

export const canFetchActions = createSelector(
	[ getActionsAsyncState ],
	canFetch
);

export const canFetchAds = createSelector(
	[ getAdsAsyncState ],
	canFetch
);

export const getHealthReportsAsyncState = createSelector(
	[ getAsyncState ],
	getHealthReportsAsync
);

export const canFetchHealthReports = createSelector(
	[ getHealthReportsAsyncState ],
	canFetch
);

export const getActiveNavigationItem = createSelector(
	[ getUIState ],
	getActiveNavItem
);

export const getPreviousNavigationItem = createSelector(
	[ getUIState ],
	getPreviousNavItem
);

export const getActiveFilters = createSelector(
	[ getUIState, getPath ],
	getActiveFilterState
);

export const getActiveSearch = createSelector(
	[ getUIState, getPath ],
	getActiveSearchState
);

export const getActiveSelection = createSelector(
	[ getUIState, getPath ],
	getActiveSelectionState
);

export const getActiveSorts = createSelector(
	[ getUIState, getPath ],
	getActiveSortState
);

export const getActiveTags = createSelector(
	[ getUIState, getPath ],
	getActiveTagState
);

export const getActiveUuid = createSelector(
	[ getUIState, getPath ],
	getActiveUuidState
);

export const getAdBuilderState = createSelector(
	[ getUIState ],
	getAdBuilderUIState
);

export const getAdBuilderAd = createSelector(
	[ getAdBuilderState ],
	getActiveAdBuilderAd
);

export const getAdBuilderComponentIndex = createSelector(
	[ getAdBuilderState ],
	getSelectedComponentIndex
);

export const getActiveView = createSelector(
	[ getUIState, getPath ],
	getActiveViewState
);

export const getMenuItems = createSelector(
	[ getUIState ],
	getMenu
);

export const getHealthReportsNavKey = createSelector(
	[ getMenuItems ],
	getHealthReportKey
);

export const getCollapseExpandedState = createSelector(
	[ getUIState, getActiveNavigationItem ],
	getCollapseState
);

export const getLeftAccordionExpanded = createSelector(
	[ getUIState, getActiveNavigationItem ],
	getAccordionState
);

export const getNumFinishedReports = createSelector(
	[ getUIState ],
	getFinishedReportsLength
);

export const haveAllPlaylists = createSelector(
	[ getPlaylistsAsyncState ],
	haveAllPlaylistsData
);

export const makeCanFetchDevices = () => createSelector(
	[ getDevicesAsyncState ],
	canFetch
);

export const makeLastActivityPage = () => createSelector(
	[ getActivityAsyncState ],
	lastPage
);

export const makeHaveAllActivities = () => createSelector(
	[ getActivityAsyncState ],
	haveAllAsyncData
);

export const makeLastUsersPage = () => createSelector(
	[ getUsersAsyncState ],
	lastPage
);

export const makeHaveAllUsers = () => createSelector(
	[ getUsersAsyncState ],
	haveAllAsyncData
);

export const makeLastDeploymentsPage = () => createSelector(
	[ getDeploymentsAsyncState ],
	lastPage
);

export const makeLastDevicesPage = () => createSelector(
	[ getDevicesAsyncState ],
	lastPage
);

export const makeHaveAllDeployments = () => createSelector(
	[ getDeploymentsAsyncState ],
	haveAllAsyncData
);

export const makeHaveAllDevices = () => createSelector(
	[ getDevicesAsyncState ],
	haveAllDevicesData
);

export const makeLastPlaylistsPage = () => createSelector(
	[ getPlaylistsAsyncState ],
	lastPage
);

export const makeLastActionsPage = () => createSelector(
	[ getActionsAsyncState ],
	lastPage
);

export const makeLastAdsPage = () => createSelector(
	[ getAdsAsyncState ],
	lastPage
);

export const makeHaveAllAds = () => createSelector(
	[ getAdsAsyncState ],
	haveAllAsyncData
);

export const makeGetActiveSort = () => getActiveSorts;

export const getReportIsRunning = createSelector(
	[ getRunningReports, getPath ],
	getIsRunningReport
);

export const getDeploymentWizardState = createSelector(
	[ getUIState ],
	getDeploymentWizardUIState
);

export const getDeviceHealthModalState = createSelector(
	[ getUIState ],
	getDeviceHealthModalUIState
);

export const getDeviceHealthModalDeviceState = createSelector(
	[ getDeviceHealthModalState, getPath ],
	getDeviceHealthModalDeviceUIState
);
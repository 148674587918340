import { ApkVersion, ISystemApi, NewCompanyRequest, VimeoVideos, DeviceModel } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class SystemApi extends ApiV2 implements ISystemApi {
	getVimeoVideos() {
		return this.GET("system/tutorials", undefined, true) as Promise<VimeoVideos>;
	}

	getHeartbeat() {
		return this.GET("webhook/status", undefined, true);
	}

	requestNewCompany(opts: NewCompanyRequest) {
		return this.POST("request", opts);
	}

	getCurrentAPK() {
		return this.GET("system/software?filter[version]=current", undefined, true) as Promise<ApkVersion>;
	}

	getDeviceModels() {
		return this.GET("system/device-models", undefined, true) as Promise<DeviceModel[]>;
	}
}
import { IDevice, IDeviceApi, IDeviceGroup, StatusResult } from "@connect/Interfaces";
import { GET, POST } from "Api/V1/CRUD";
import { sendPushNotification } from "Api/V1/Utils";

export default class DeviceApi implements IDeviceApi {
	attachDevices(deviceGroup: IDeviceGroup, devices: IDevice[]) {
		let deviceUuids = devices.map((d, i) => {
			return d.uuid;
		});

		return POST("/devices/groups/" + deviceGroup.uuid + "/attach", { devices: deviceUuids }) as Promise<StatusResult>;
	}

	rebootDevice(deviceUUID: string) {
		return GET("/device/" + deviceUUID + "/reboot") as Promise<null>;
	}

	updateDeviceAds(deviceUuid: string) {
		return sendPushNotification("device-" + deviceUuid, "pushSchedule", null);
	}

	requestSpeedTest(deviceUuid: string) {
		return sendPushNotification("device-" + deviceUuid, "requestSpeedTest", null);
	}

	requestDeviceDb(deviceUuid: string) {
		return sendPushNotification("device-" + deviceUuid, "requestDb", null);
	}

	getDeviceScreenshot(deviceUuid: string) {
		return sendPushNotification("device-" + deviceUuid, "requestScreenshot", null);
	}

	updateDeviceSnapshot(deviceUuid: string) {
		return sendPushNotification("device-" + deviceUuid, "refreshSnapshot", null);
	}

	requestClearCache(deviceUuid: string) {
		return sendPushNotification("device-" + deviceUuid, "requestClearCache", null);
	}

	pingDevice(deviceUuid: string) {
		return sendPushNotification("device-" + deviceUuid, "pingDevice", null);
	}

	updateDeviceSoftware(deviceUuid: string) {
		return sendPushNotification("device-" + deviceUuid, "updateSoftware", null);
	}
}

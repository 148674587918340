import * as update from "immutability-helper";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

interface ISideScrollerProps {
	align?: "left" | "right" | "center";
	id: string;
	itemStyles?: CustomCSS;
}

interface ISideScrollerState {
	rightPagerVisible: boolean;
	leftPagerVisible: boolean;
}

export default class SideScroller extends React.PureComponent<ISideScrollerProps, ISideScrollerState> {
	constructor(props: ISideScrollerProps) {
		super(props);
		this.state = {
			rightPagerVisible: true,
			leftPagerVisible: false
		}

		this.styles = {
			content: {
				alignItems: "center",
				background: "transparent",
				width: "100%",
				textAlign: props.align || "center",
				overflowX: "overlay",
				overflowY: "hidden",
				whiteSpace: "nowrap",
				height: "100%",
				padding: "14px 0"
			},
			icon: {
				color: Colors.filters.hoverAlt
			},
			item: {
				display: "inline-block",
				border: `1px solid ${ Colors.darkerGray }`,
				margin: 6,
				...props.itemStyles
			},
			left: {
				position: "absolute",
				padding: 6,
				left: 0,
				width: 50,
				height: "100%",
				alignItems: "center",
				zIndex: 1000
			},
			outer: {
				position: "relative",
				display: "flex",
				background: "transparent",
				alignItems: "center",
				justifyContent: "space-around",
				width: "100%",
				height: "100%"
			},
			pager: {
				display: "flex",
				background: "transparent",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
				width: 25,
				height: 100,
				userSelect: "none",
				cursor: "default"
			},
			right: {
				position: "absolute",
				padding: 6,
				right: 0,
				width: 50,
				height: "100%",
				textAlign: "right",
				alignItems: "center",
				justifyContent: "flex-end",
				zIndex: 500
			}
		}

		this.setContent = this.setContent.bind(this);
		this.scrollLeft = this.scrollLeft.bind(this);
		this.scrollRight = this.scrollRight.bind(this);
		this.interval = 0;
		this.timeout = 0;
	}

	styles: CustomCSS;
	interval: number;
	timeout: number;
	content: HTMLDivElement;

	componentDidMount() {
		// set up listeners to update the component when window loads and resizes
		[ "resize", "load" ].forEach((event) => window.addEventListener(event, () => this.forceUpdate()));
		window.setTimeout(() => this.forceUpdate(), 0);
	}

	componentWillUnmount() {
		window.clearInterval(this.interval);
		window.clearTimeout(this.timeout);
	}

	render() {
		const { children: childProps, id } = this.props;
		const children = React.Children.toArray(childProps);
		const scrollableContainer = document.getElementById(`content-${ id }`);
		const { clientWidth, scrollWidth } = scrollableContainer || { clientWidth: 0, scrollWidth: 0 };
		const scrollable = scrollWidth > clientWidth && children.length;
		let { content, icon, item, left, outer, right } = this.styles;

		const leftStyle = {
			...left,
			display: this.state.leftPagerVisible && scrollable ? "flex" : "none"
		};

		const rightStyle = {
			...right,
			display: this.state.rightPagerVisible && scrollable ? "flex" : "none"
		}

		return (
			<div style={outer} id="sidescroller_outer" key={ `scroller_${ children.length }` }>
				<div
					key={ `scrollable_pager_left_${ scrollable }` }
					style={ leftStyle }
					onMouseOver={() => {
						this.scrollLeft();
					}}
					onMouseOut={() => {
						window.clearInterval(this.interval);
					}}>
					<Icon
						key={ `${ children.length}_scrollable_${ scrollable }_left` }
						style={ icon }
						name="chevron-left"
					/>
				</div>
				<div id={"content-" + id} style={content} ref={ this.setContent }>
					{children.map((c, i) => {
						return (
							// eslint-disable-next-line
							<div style={item} key={ i }>
								{c}
							</div>
						)
					})}
				</div>
				<div
					key={ `scrollable_pager_right_${ scrollable }` }
					style={ rightStyle }
					onMouseOver={() => {
						this.scrollRight();
					}}
					onMouseOut={() => {
						window.clearInterval(this.interval);
					}}>
					<Icon
						key={ `${ children.length}_scrollable_${ scrollable }_right` }
						name="chevron-right"
						style={ icon }
					/>
				</div>
			</div>
		);
	}

	setContent(ref: HTMLDivElement) {
		this.content = ref;
	}

	scrollLeft() {
		let x = this.content.scrollLeft;
		this.interval = window.setInterval(() => {
			x -= 2;
			this.content.scrollLeft = x;
			if (this.content.scrollLeft <= 1 && this.state.leftPagerVisible) {
				// once we've scrolled all the way over, hide left pager
				this.toggleLeftPager();
			} else if (!this.state.rightPagerVisible) {
				// show right pager once we've scrolled
				this.toggleRightPager();
			}
		}, .5)
	}

	scrollRight() {
		let x = this.content.scrollLeft;
		this.interval = window.setInterval(() => {
			x += 2;
			this.content.scrollLeft = x;
			if (this.content.scrollLeft + this.content.clientWidth >= this.content.scrollWidth && this.state.rightPagerVisible) {
				// once we've scrolled all the way over, hide right pager
				this.toggleRightPager();
			} else if (!this.state.leftPagerVisible) {
				// show left pager once we've scrolled
				this.toggleLeftPager();
			}
		}, .5)
	}

	toggleRightPager() {
		this.setState((prevState) => {
			return update(prevState, {
				rightPagerVisible: { $set: !prevState.rightPagerVisible }
			});
		});
	}

	toggleLeftPager() {
		this.setState((prevState) => {
			return update(prevState, {
				leftPagerVisible: { $set: !prevState.leftPagerVisible }
			});
		});
	}
}
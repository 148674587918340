import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { ActionSet, Context, CustomCSS, SortTypes, Sorts } from "@connect/Interfaces";
import ActionsGrid from "Components/Actions/ActionsGrid";
import Loader from "Components/Global/Loader";
import { tryFetchActionsAsync } from "Data/Actions/ActionsAsync";
import { getFilteredSortedActions } from "Data/Selectors/Actions";
import {
	canFetchActions,
	getActionsAsyncState,
	getActiveSearch,
	getActiveSorts,
	makeLastActionsPage
} from "Data/Selectors/UI";

const mapStateToProps = (state) => {
	const actionsSortType = getActiveSorts(state, Sorts.ACTIONS) as SortTypes;
	const getLastActionsPage = makeLastActionsPage();
	const search = getActiveSearch(state, "actions");

	return {
		actions: getFilteredSortedActions(state, {
			sort: getActiveSorts(state, Sorts.ACTIONS) as SortTypes,
			filter: search
		}),
		asyncState: getActionsAsyncState(state),
		canFetch: canFetchActions(state),
		lastFetchedPage: getLastActionsPage(state),
		actionsSortType,
		filter: search
	};
};

const mapDispatchToProps = (dispatch) => ({
	getActions: () => dispatch(tryFetchActionsAsync())
});

interface ActionsContentAreaProps {
	context: Context;
	canFetch: boolean;
	getActions: () => void;
	onCreateAction: () => void;
	onActionSelected: (action: ActionSet) => void;
	actions: ActionSet[];
	actionsSortType: SortTypes;
	filter: string;
	selectModeOn: boolean;
}

class ActionsContentArea extends React.Component<ActionsContentAreaProps> {
	constructor(props: ActionsContentAreaProps) {
		super(props);

		this.styles = {
			waypoint: {
				height: 40,
				marginTop: 40,
				textAlign: "center",
				width: "100%"
			}
		};

		this.renderWaypoint = this.renderWaypoint.bind(this);
		this.fetchActions = this.fetchActions.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<React.Fragment>
				{ this.renderActionsGrid() }
				{ this.renderWaypoint() }
			</React.Fragment>
		);
	}

	renderActionsGrid() {
		const {
			context,
			onCreateAction,
			onActionSelected,
			actions,
			actionsSortType,
			filter,
			selectModeOn
		} = this.props;
		const key = actionsSortType.toString() + filter;

		return (
			<ActionsGrid
				content={ actions }
				context={ context }
				key={ key }
				minWidth="560px"
				onCreateAction={ onCreateAction }
				onActionSelected={ onActionSelected }
				selectModeOn={ selectModeOn }
			/>
		);
	}

	renderWaypoint() {
		const { canFetch, actions } = this.props;
		const style = { ...this.styles.waypoint, display: canFetch ? "inline-block" : "none" };

		return (
			<div style={ style }>
				<Waypoint
					key={ actions.length + canFetch.toString() }
					onEnter={ this.fetchActions }
				/>
				<Loader />
			</div>
		);
	}

	fetchActions() {
		const { canFetch, getActions } = this.props;
		if (canFetch) {
			getActions();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsContentArea);

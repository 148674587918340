import { createSelector } from "reselect";

import { DeviceCollectionArgs, DeviceSelectionArgs, IDevice, IDeviceGroup, NameUuid,
	SortTypes } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { AppState, DevicesState } from "Data/Objects/AppState";
import { AllGroup } from "Data/Objects/Devices";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { identityReducer } from "Data/Utils";

const getGroup = (id) => (group) => group.uuid === id;

export function getDevicesState(state: AppState): DevicesState {
	return state.Devices;
}

export function getDeviceGroupFromState(groups: IDeviceGroup[], groupId: string) {
	return groups.find((g) => g.uuid === groupId);
}

export function getDeviceGroupParentsFromState(groups: IDeviceGroup[], groupId: string): string[] {
	let parents: string[] = [];
	let currentGroup = groups.find(getGroup(groupId));

	if (groupId === "0") {
		return [];
	}

	while (currentGroup && currentGroup.parent) {
		let { parent } = currentGroup;

		parents.unshift(parent);

		currentGroup = groups.find(getGroup(parent));
	}

	return parents;
}

export function getDeviceGroupPathFromState(groups: IDeviceGroup[], groupId: string): NameUuid[] {
	const topGroup = { name: "All", uuid: "0" };
	let parents: NameUuid[] = [];
	let currentGroup = groups.find(getGroup(groupId));

	while (currentGroup && currentGroup.parent) {
		let { name, parent, uuid } = currentGroup;

		parents.unshift({ name, uuid });

		currentGroup = groups.find(getGroup(parent));
	}

	parents.unshift(topGroup);

	if (currentGroup && currentGroup.name !== parents[0].name) {
		parents.splice(1, 0, { name: currentGroup.name, uuid: currentGroup.uuid });
	}

	return parents;
}

export function getDevicesSorted(devices: IDevice[], sortType: SortTypes) {
	const { NEWEST_FIRST, OLDEST_FIRST } = SortTypes;
	const installDateSort = (sortType === NEWEST_FIRST || sortType === OLDEST_FIRST) ? "installDate" : undefined;
	return Utils.sortListBySortType(devices, sortType, installDateSort);
}

export function getDevicesFilteredBySearch(devices: IDevice[], search: string) {
	const filter = search ? search.toLowerCase() : "";
	if (!filter) {
		return devices;
	}

	if (devices && devices.length) {
		return devices.filter((d) => d.name.toLowerCase().includes(filter) || d.serial.toLowerCase().includes(filter));
	}

	return [];
}

export function getDevicesFilteredBySize(devices: IDevice[], sizes: string[]): IDevice[] {
	let filteredDevices: IDevice[] = [];

	sizes.forEach((size) => {
		devices.forEach((device) => {
			if (device && device.model.includes(size) && !filteredDevices.includes(device)) {
				filteredDevices.push(device);
			}
		});
	});

	if (sizes.length === 0) {
		filteredDevices = [ ...devices ];
	}

	return filteredDevices;
}

export function devicesWithoutGroups(device: IDevice) {
	const { deviceGroup } = device;
	return !deviceGroup || deviceGroup && deviceGroup.uuid === AllGroup.uuid;
}

export function devicesWithoutDeployments(device: IDevice) {
	const { activeDeployment } = device;

	return activeDeployment && !activeDeployment.name;
}

export function getDevicesFilteredByStatus(devices: IDevice[], status: string[]) {
	const filterByGroup = status.includes("Not Assigned to Group");
	const filterByDeployment = status.includes("No Active Deployment");
	let result = [ ...devices ];

	if (!status.length) {
		return result;
	}

	if (filterByGroup) {
		result = result.filter(devicesWithoutGroups);
	}

	if (filterByDeployment) {
		result = result.filter(devicesWithoutDeployments);
	}

	return result;
}

export function getDevicesFilteredByStore(devices: IDevice[], storeFilter: string[]): IDevice[] {
	let filteredDevices: IDevice[] = [];

	if (storeFilter.length === 0) {
		return [ ...devices ];
	}

	if (storeFilter.length && devices.length) {
		storeFilter.forEach((storeUuid) => {
			devices.forEach((device) => {
				if (device.store && device.store.includes(storeUuid) && !filteredDevices.includes(device)) {
					filteredDevices.push(device);
				}
			});
		});
	}

	return filteredDevices;
}

export function getDevicesFilteredByGroup(devices: IDevice[], group: string) {
	if (group === "0") {
		return devices;
	}

	return devices.filter((device) => device.deviceGroup && device.deviceGroup.uuid === group);
}

export function getDevicesFilteredByIntegrator(devices: IDevice[], integratorId: string) {
	const isIntegrator = hasPermission(PERMISSIONS.DEVICES_MANAGE);

	if (!isIntegrator || !integratorId || integratorId === "0") {
		return devices;
	}

	return devices.filter((device) => device.integrator && device.integrator === integratorId);
}

export function getDeviceGroupsList(state: DevicesState): IDeviceGroup[] {
	return state.deviceGroups;
}

export function getDevices(state: DevicesState): IDevice[] {
	return state.devices;
}

export function getDeviceGroupList(state: DevicesState): IDeviceGroup[] {
	return state.deviceGroups;
}

export function getUnassociatedDeviceList(state: DevicesState): IDevice[] {
	return state.unassociatedDevices;
}

export function getDeviceFilter(state: AppState, args: DeviceCollectionArgs | DeviceSelectionArgs): string {
	return args.filter || "";
}
export function getDeviceSort(state: AppState, args: DeviceCollectionArgs | DeviceSelectionArgs): SortTypes {
	return args.sort || SortTypes.NEWEST_FIRST;
}

export function getDeviceGroup(state: AppState, args: DeviceCollectionArgs): string {
	return args.group || "0";
}

export function getDeviceIntegrator(state: AppState, args: DeviceCollectionArgs): string {
	return args.integrator || "";
}

export function getDeviceSizes(state: AppState, args: DeviceCollectionArgs): string[] {
	return args.sizes || [];
}

export function getDeviceStatuses(state: AppState, args: DeviceCollectionArgs): string[] {
	return args.status || [];
}

export function getDeviceStores(state: AppState, args: DeviceCollectionArgs): string[] {
	return args.stores || [];
}

export function getDevicesFromArray(devices: IDevice[], devicesToFind: string[]) {
	return devices.filter((device) => devicesToFind.includes(device.uuid));
}

export function getDeviceGroupsFromArray(deviceGroups: IDeviceGroup[], deviceGroupsToFind: string[]) {
	return deviceGroups.filter((deviceGroup) => deviceGroupsToFind.includes(deviceGroup.uuid));
}

export function getDeviceByUUID(devices: IDevice[], deviceId: string) {
	return devices.filter((d) => d.uuid === deviceId)[0];
}

export function getModels(state: DevicesState) {
	return state.deviceModels;
}

export function getModelNames(state: DevicesState) {
	return state.deviceModelNames;
}

export function getDeviceIsVisible(groups: IDeviceGroup[], activeGroup: string) {
	return groups.filter((group) => String(group.parent) === activeGroup);
}

export const getAllDevices = createSelector(
	[ getDevicesState ],
	getDevices
);

export const getSizeFilteredDevices = createSelector(
	[ getAllDevices, getDeviceSizes ],
	getDevicesFilteredBySize
)

export const getSortedDevices = createSelector(
	[ getAllDevices, getDeviceSort ],
	getDevicesSorted
);

export const getDeviceGroups = createSelector(
	[ getDevicesState ],
	getDeviceGroupsList
);

export const getDeviceGroupById = createSelector(
	[ getDeviceGroups, identityReducer ],
	getDeviceGroupFromState
);

export const getDeviceById = createSelector(
	[ getAllDevices, identityReducer ],
	getDeviceByUUID
);

export const getStoreFilteredDevices = createSelector(
	[ getSizeFilteredDevices, getDeviceStores ],
	getDevicesFilteredByStore
);

export const getStatusFilteredDevices = createSelector(
	[ getStoreFilteredDevices, getDeviceStatuses ],
	getDevicesFilteredByStatus
);

export const getGroupFilteredDevices = createSelector(
	[ getStatusFilteredDevices, getDeviceGroup ],
	getDevicesFilteredByGroup
);

export const getIntegratorFilteredDevices = createSelector(
	[ getGroupFilteredDevices, getDeviceIntegrator ],
	getDevicesFilteredByIntegrator
);

export const getFilteredSortedDevices = createSelector(
	[ getIntegratorFilteredDevices, getDeviceSort ],
	getDevicesSorted
);

export const getFilteredDevices = createSelector(
	[ getAllDevices, getDeviceFilter ],
	getDevicesFilteredBySearch
);

export const getFilteredSortedSearchedDevices = createSelector(
	[ getFilteredSortedDevices, getDeviceFilter ],
	getDevicesFilteredBySearch
);

export function getSelectionFilters(state: AppState, selectionFilters: DeviceSelectionArgs) {
	return selectionFilters;
}

export function getFilteredDevicesForSelection(devices: IDevice[], selectionFilters: DeviceSelectionArgs) {
	const { model, filter, store } = selectionFilters;

	return devices
		.filter((device) => {
			if (!model && !filter && !store) {
				return true;
			}

			return (!model || device.model.includes(model))
				&& (!filter || device.name.toLowerCase().includes(filter.toLowerCase()))
				&& (!store || device.store && device.store.includes(store));
		})
}

export const getDevicesForSelection = createSelector(
	[ getSortedDevices, getSelectionFilters ],
	getFilteredDevicesForSelection
);

export const getDeviceGroupPath = createSelector(
	[ getDeviceGroups, identityReducer ],
	getDeviceGroupPathFromState
);

export const getDeviceGroupParents = createSelector(
	[ getDeviceGroups, identityReducer ],
	getDeviceGroupParentsFromState
);

export const getUnassociatedDevices = createSelector(
	[ getDevicesState ],
	getUnassociatedDeviceList
);

export const getDevicesByArray = createSelector(
	[ getAllDevices, identityReducer ],
	getDevicesFromArray
);

export const getDeviceGroupsByArray = createSelector(
	[ getDeviceGroups, identityReducer ],
	getDeviceGroupsFromArray
);

export const getDeviceModels = createSelector(
	[ getDevicesState ],
	getModels
);

export const getDeviceModelNames = createSelector(
	[ getDevicesState ],
	getModelNames
);

export const getVisibleDeviceGroups = createSelector(
	[ getDeviceGroups, identityReducer ],
	getDeviceIsVisible
);
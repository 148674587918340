import * as moment from "moment";
import { push } from "react-router-redux";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { AdAnalyticsNotification, AdResult, AnalyticsResult, AnalyticsSubsets,
	IAd, SortTypes, Filters, Sorts } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import AdApi from "Api/Ad";
import {
	createAd,
	deleteAd,
	resetAdAnalytics,
	resetAds,
	setAds,
	setAnalyticsReportPending,
	setNotification,
	updateAd,
	updateAdAnalytics,
	updateNotification
} from "Data/Actions/Ads";
import { errorNotification, successNotification, confirmNotification } from "Data/Actions/Notifications";
import { resetAsyncStates, setAsyncFetching, setAsyncState } from "Data/Actions/UI";
import { setActiveAd } from "Data/Actions/UI/AdBuilder";
import { Ad } from "Data/Objects/Ads";
import { CacheInvalidationPeriod } from "Data/Objects/Global";
import { getActiveAnalyticsNotification } from "Data/Selectors/AdAnalytics";
import {
	getAdsAsyncQueryState,
	getAdsLastFetchAll,
	getAnalyticsAsyncQuery,
	getAnalyticsLastFetchAll,
	getAnalyticsQueries,
	getNestedAsyncState
} from "Data/Selectors/Async";
import { getActiveCompanyId } from "Data/Selectors/Company";
import { getActiveFilters, getActiveSorts, getAdBuilderAd } from "Data/Selectors/UI";
import { getActiveUserUuid, getCurrentUser } from "Data/Selectors/User";

/**
 * Async Actions
 */

function clearAdAsync(ad: IAd) {
	return (dispatch) => {
		let clearedAd = Ad.clone(ad);

		clearedAd.duration = Ad.DEFAULT_DURATION;
		clearedAd.layout.components = [];
		clearedAd.layout.media = [];

		return dispatch(updateAdAsync(clearedAd));
	};
}

function createAdAsync(ad: IAd) {
	const api = new AdApi();

	return (dispatch, getState) => {
		const state = getState();
		const currentUser = getCurrentUser(state)
		const { name, uuid } = currentUser;
		const newAd = Object.assign({}, Ad.clone(ad), {
			uuid: Utils.getGuid(),
			createdBy: { name, uuid }
		});

		return api.createAd(newAd)
			.then((result) => {
				const createdAd = Object.assign({}, newAd, {
					uuid: result.uuid,
					createdAt: Utils.getISOTimestamp()
				});

				dispatch(createAd(createdAd));
				dispatch(successNotification(`Created ${newAd.name}`));

				return createdAd;
			}, (error) => {
				dispatch(errorNotification("Error creating ad.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function deleteAdAsync(ad: IAd, force?: boolean) {
	const api = new AdApi();

	return (dispatch) => api.deleteAd(ad, force)
		.then(() => {
			dispatch(deleteAd(ad.uuid));
			dispatch(successNotification("Ad successfully deleted."));
		}, (error) => {
			let noResults = false;
			let notify = (success, err) => success
				? dispatch(successNotification("Ad successfully deleted."))
				: dispatch(errorNotification("Error deleting ad.", err));

			// we can't always find the model on the server
			if (error.message.includes("No query results")) {
				// notify the user (as we would with any error) and locally delete the garbage data
				noResults = true;
				dispatch(deleteAd(ad.uuid));
			}

			if (error.error === "has_action") {
				const title = `Force Delete ${ad.name}?`;
				const message = "Ad is currently assigned to a triggered action, delete anyway?";
				const onConfirm = () => {
					dispatch(deleteAdAsync(ad, true));
				}

				dispatch(confirmNotification(title, message, "Delete", "Cancel", onConfirm));
			}

			if (error.error !== "has_action") {
				notify(noResults, error);
			}
		})
		.catch((error) => {
			dispatch(notifyBugSnagAsync(new Error(error)));
		});
}

function duplicateAdAsync(ad: IAd) {
	const api = new AdApi();

	return (dispatch, getState) => {
		const { User: { user } } = getState();
		const { name, uuid } = user;

		const newAd = Object.assign({}, Ad.clone(ad), {
			uuid: Utils.getGuid(),
			name: Utils.copyName(ad.name),
			createdBy: {
				uuid,
				name
			},
			analytics: null
		});

		return api.createAd(newAd)
			.then((result) => {
				const { uuid: resultId } = result;
				const createdAd = Object.assign({}, newAd, {
					uuid: resultId,
					createdAt: Utils.getISOTimestamp()
				});
				dispatch(createAd(createdAd));
				dispatch(push(`/ads/${ resultId }`));
				dispatch(successNotification(`Created ${ newAd.name }`));
			}, (error) => {
				dispatch(errorNotification("Error copying ad.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function getAdAsync(uuid: string) {
	const api = new AdApi();

	return (dispatch) => api.getAd(uuid)
		.then((result: IAd) => {
			dispatch(updateAd(result));

			return result;
		}, (error) => {
			const notificationError = error.message.includes("No query results for model")
				? { message: "Requested ad does not belong to the currently active company." }
				: error;

			dispatch(errorNotification("Error getting ad.", notificationError));

			// we need to pass this along for the Ad Builder
			throw error;
		})
		.catch((error) => {
			dispatch(notifyBugSnagAsync(new Error(error)));

			// pass it for the next promise in the chain to catch
			throw error;
		});
}

function getAdAnalyticsAsync(uuid: string) {
	const api = new AdApi();

	return (dispatch, getState) => {
		const state = getState();
		const filters = getActiveFilters(state, Filters.ANALYTICS);
		const { dateRange, devices, stores, sizes } = filters;

		const asyncStateQuery = getAnalyticsAsyncQuery(dateRange as string, uuid);
		const { 2: requestedDateRange } = asyncStateQuery.split(".");
		let requestedStartDate = "";
		let requestedEndDate = "";

		if (requestedDateRange) {
			[ requestedStartDate, requestedEndDate ] = requestedDateRange.split("_");
		}
		const asyncAnalytics = getNestedAsyncState(state, asyncStateQuery);
		const activeCompanyId = getActiveCompanyId(state);
		const { currentlyFetching } = asyncAnalytics;

		if (currentlyFetching) {
			return Promise.resolve();
		}

		const lastFetchAll = getAnalyticsLastFetchAll(state);
		const lastFetchDiff = moment().diff(moment(lastFetchAll), "minute");
		const shouldInvalidateCache = lastFetchDiff > CacheInvalidationPeriod;

		const requestedDates = Utils.getDaysBetweenDates(requestedStartDate, requestedEndDate);
		const previousQueries = getAnalyticsQueries(state);
		const cachedRanges = Object.keys(previousQueries || {}).filter((range) => range.split(".")[0] === uuid);

		const cachedDates = cachedRanges
			.map((range) => {
				const [ start, end ] = range.split(".")[1].split("_");
				return Utils.getDaysBetweenDates(start, end);
			})
			.join(",")
			.split(",");

		const dataExistsInCurrentSet = cachedRanges.length && cachedRanges.includes(`${ uuid }.${ requestedDateRange }`) ||
			requestedDates.every((d) => cachedDates.includes(d));

		let startDate, endDate, dayCount;

		if (requestedDateRange) {
			[ startDate, endDate ] = (requestedDateRange as any).split("_");
			dayCount = Math.abs(moment(endDate).diff(moment(startDate), "days")) + 1;
		}

		// filter by selected range, and if we already have the data results for that range in
		// our collection, only request the dwell, peaks, timeOnScreen, and trend (minus data)
		const filterSet = {
			days: dayCount || 7,
			devices: dataExistsInCurrentSet ? devices as string[] : [],
			start_date: startDate || "",
			data: dataExistsInCurrentSet
				? [ "dwell", "peaks", "timeOnScreen", "trend", "deviceStats" ] as AnalyticsSubsets[]
				: [],
			stores: dataExistsInCurrentSet ? stores as string[] : [],
			sizes: dataExistsInCurrentSet ? sizes as string[] : []
		};

		if (shouldInvalidateCache) {
			// dispatch actions to resetAsyncStates
			dispatch(resetAdAnalytics());
			return dispatch(resetAsyncStates());
		}

		dispatch(setAsyncFetching(asyncStateQuery, true, activeCompanyId));

		api.getAdAnalytics(uuid, filterSet)
			.then((result: AnalyticsResult) => {
				dispatch(updateAdAnalytics(uuid, result));
				dispatch(setAsyncState(asyncStateQuery, true, 0));
				dispatch(setAsyncFetching(asyncStateQuery, false));
				return result;
			}, (error) => {
				dispatch(errorNotification("Error getting ad analytics.", error));
				dispatch(setAsyncFetching(asyncStateQuery, false));
				// we need to pass this along for the Ad Builder
				throw error;
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
				dispatch(setAsyncFetching(asyncStateQuery, false));
				// pass it for the next promise in the chain to catch
				throw error;
			});
	}
}

function requestGetAnalyticsReportDownloadAsync(uuid: string) {
	const api = new AdApi();

	return (dispatch, getState) => {
		const state = getState();
		const filters = getActiveFilters(state, Filters.ANALYTICS);
		const { dateRange, stores, sizes } = filters;
		const [ startDate, endDate ] = (dateRange as string).split("_");
		const dayCount = Utils.getDaysBetweenDates(startDate, endDate).length;
		const user = getActiveUserUuid(state);

		const filterSet = {
			days: dayCount || 7,
			start_date: startDate,
			stores: stores as string[] || null,
			sizes: sizes as string[] || null,
			user
		}

		dispatch(setAnalyticsReportPending(uuid, true));
		return api.getRequestReportExport(uuid, filterSet)
			.then(() => {
				dispatch(successNotification("Report data requested", "We will notify you when the report is ready."));
				setTimeout(() => dispatch(setAnalyticsReportPending(uuid, false)), 300000);
			}, (error) => {
				dispatch(errorNotification("Could not request report data"));
				dispatch(setAnalyticsReportPending(uuid, false));
			})
			.catch((error) => {
				dispatch(setAnalyticsReportPending(uuid, false));
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	}
}

function getAnalyticsReportDownloadAsync(uuid: string, token: string, name: string) {
	const api = new AdApi();
	return (dispatch) => {
		api.getReportExport(uuid, token)
			.then((response: Response) => {
				response.text()
					.then((csvText) => {
						Utils.downloadCsv(csvText, name);
					});
			}, (error) => {
				dispatch(setAnalyticsReportPending(uuid, false))
				dispatch(errorNotification(error));
			})
			.catch((error) => {
				dispatch(setAnalyticsReportPending(uuid, false))
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	}
}

function getAnalyticsNotificationAsync(uuid: string) {
	const api = new AdApi();

	return (dispatch) => {
		return api.getAnalyticsNotificationSettings(uuid)
			.then((result: AdAnalyticsNotification) => {
				dispatch(setNotification(uuid, result));
			},
			(error) => {
				dispatch(errorNotification("Error getting analytics notification settings.", error));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

function setAdsAsync(withAnalytics: boolean = false) {
	const api = new AdApi();

	return (dispatch, getState) => {
		const state = getState();
		const activeCompanyId = getActiveCompanyId(state);
		const sortType = getActiveSorts(state, Sorts.ADS) as SortTypes;
		const sortAndFilter = { sortType, withAnalytics };
		const asyncState = getAdsAsyncQueryState(sortAndFilter);
		const { currentPage, currentlyFetching, haveAllData, lastFetchedCompany } = getNestedAsyncState(state, asyncState);
		const dontNeedToFetch = haveAllData || currentlyFetching;
		const lastFetchAll = getAdsLastFetchAll(state);
		const lastFetchDiff = moment().diff(lastFetchAll, "minute");
		const companyChanged = activeCompanyId !== lastFetchedCompany;
		const shouldInvalidateCache = lastFetchDiff > CacheInvalidationPeriod || companyChanged;

		if (dontNeedToFetch && !shouldInvalidateCache) {
			return Promise.resolve();
		}

		if (shouldInvalidateCache) {
			dispatch(resetAds());
			dispatch(resetAsyncStates());
		}

		dispatch(setAsyncFetching(asyncState, true, activeCompanyId));

		const page = shouldInvalidateCache ? 0 : currentPage;
		const sort = Utils.getApiSort(sortAndFilter);

		return api.getAds(100, page + 1, sort, withAnalytics)
			.then((result: AdResult) => {
				dispatch(setAds(result.data, shouldInvalidateCache));
				dispatch(setAsyncState(
					asyncState,
					!result.links.next,
					result.meta.current_page
				));
				dispatch(setAsyncFetching(asyncState, false));
			}, (error) => {
				dispatch(errorNotification("Error getting ads", error));
				dispatch(setAsyncFetching(asyncState, false));
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	}
}

function updateAdAsync(ad: IAd, notifyOnSuccess?: boolean) {
	const api = new AdApi();

	return (dispatch, getState) => {
		const oldAd = getAdBuilderAd(getState());
		const isAdBuilder = oldAd && oldAd.uuid === ad.uuid;

		dispatch(updateAd(ad));
		if (isAdBuilder) {
			// keep ad builder UI state in sync with updated ad state
			dispatch(setActiveAd(ad));
		}

		return api.updateAd(ad)
			.then((result: Response) => {
				if (notifyOnSuccess) {
					Notifications.success("Ad successfully updated!");
				}
			}, (error) => {
				dispatch(errorNotification(`Error updating ${ad.name}.`));

				// since we are using this to add/remove analytics from an ad now, we need to check if we should even do this
				if (isAdBuilder) {
					dispatch(updateAd(oldAd));
					dispatch(setActiveAd(oldAd));
				}
			})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));

				if (isAdBuilder) {
					dispatch(updateAd(oldAd));
					dispatch(setActiveAd(oldAd));
				}
			});
	};
}

function updateAnalyticsNotificationAsync(uuid: string, notification: AdAnalyticsNotification) {
	const api = new AdApi();

	return (dispatch, getState) => {
		const state = getState();
		const notificationSettings = getActiveAnalyticsNotification(state);

		dispatch(updateNotification(uuid, notification));

		return api.updateAnalyticsNotificationSettings(uuid, notification)
			.then((result: AdAnalyticsNotification) => result,
				(error) => {
					dispatch(updateNotification(uuid, notificationSettings || {} as AdAnalyticsNotification));
					dispatch(errorNotification("Error updating analytics notification settings.", error));
				})
			.catch((error) => {
				dispatch(notifyBugSnagAsync(new Error(error)));
			});
	};
}

export {
	clearAdAsync,
	createAdAsync,
	deleteAdAsync,
	duplicateAdAsync,
	getAdAsync,
	getAdAnalyticsAsync,
	getAnalyticsNotificationAsync,
	getAnalyticsReportDownloadAsync,
	requestGetAnalyticsReportDownloadAsync,
	setAdsAsync,
	updateAdAsync,
	updateAnalyticsNotificationAsync
}
import * as React from "react";

interface EmptyListProps {
	type: string;
}

export default class EmptyList extends React.PureComponent<EmptyListProps> {
	render() {
		return (
			<div style={{
				height: "-webkit-fill-available",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}>
				There are no {this.props.type}.
			</div>
		);
	}
}
import { notifyBugSnagAsync } from "@connect/BugSnag";
import { Action, RolesDispatch, RolesList } from "@connect/Interfaces";
import { Api } from "Api/Api";
import { errorNotification } from "Data/Actions/Notifications";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";
import { cloneDeep } from "lodash";

const {
	SET_ROLES_LIST,
	SET_EDIT_ROLES_LIST
} = ACTION_TYPES.Roles;

/**
 * Simple Actions
 */

function setRolesList(rolesList: RolesList): Action<RolesDispatch> {
	return new DispatchableAction(SET_ROLES_LIST, { rolesList });
}

function setEditRolesList(rolesList: RolesList): Action<RolesDispatch> {
	return new DispatchableAction(SET_EDIT_ROLES_LIST, { rolesList });
}

/**
 * Async Actions
 */

function getRolesLists() {
	return (dispatch, getState) => {
		return Api.SystemApi.getRolesList()
			.then((result: RolesList) => {
				// result should resemble the example below with one key/value
				// pair per role that the logging in user is allowed to create
				// {
				//     marketer: "Marketer",
				//     company_user: "Company User",
				//     etc...
				// }

				// roles list for general use
				let roles = cloneDeep(result);

				delete roles.integrator;

				// roles list for editing/creating users
				let editRoles = {};

				// user.user
				const { permissions } = getState().User.user;

				Object.keys(roles).forEach((k) => {
					if (permissions.indexOf("users." + k + ".create") > -1) {
						editRoles[k] = roles[k];
					}
				});

				dispatch(setRolesList(roles));
				dispatch(setEditRolesList(editRoles));
			}, (error) => {
				dispatch(errorNotification("Could not get user roles.", error));
			})
			.catch(error => notifyBugSnagAsync(new Error(error)));
	};
}

export { getRolesLists, setEditRolesList, setRolesList };

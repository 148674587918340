import * as React from "react";
import { connect } from "react-redux";

import DeviceImage from "@connect/Components/Devices/DeviceImage";
import { CustomCSS } from "@connect/Interfaces";
import { DeviceButton } from "Components/Ads/DeviceButton";
import { Header, Modal } from "Components/Global/Common";
import { RequestNameTypes } from "Components/Global/RequestNameModal";
import { setRequestNameModal, setTemplatesListModal } from "Data/Actions/UI/Modals";
import { AdTemplate, DefaultTemplates } from "Data/Objects/AdTemplates";
import { getTemplatesListModalVisibility } from "Data/Selectors/UI";

interface TemplatesListModalProps {
	hideModal: () => void;
	showRequestNameModal: (adTemplate: AdTemplate) => void;
	visible: boolean;
}

const mapStateToProps = (state, ownProps) => ({
	visible: getTemplatesListModalVisibility(state)
});

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(setTemplatesListModal(false)),
	showRequestNameModal: (template: AdTemplate) => dispatch(setRequestNameModal(true, RequestNameTypes.AD, template))
});

export class UnwrappedTemplatesListModal extends React.Component<TemplatesListModalProps> {
	constructor(props: TemplatesListModalProps) {
		super(props);

		this.styles = {
			modalContent: {
				color: "#888",
				textAlign: "center"
			}
		}
		this.hide = this.hide.bind(this);
		this.selectTemplate = this.selectTemplate.bind(this);
	}

	styles: CustomCSS;

	renderedTemplateCells: any[];
	renderedTemplates: JSX.Element[];

	render() {
		const { visible } = this.props;

		return (
			<Modal
				modalKey={ `templatesListModal_visible_${ visible }` }
				visible={ visible }
				onCancel={ this.hide }
			>
				<div style={ this.styles.modalContent }>
					<Header color="gray" size={ 1 }>Select Layout</Header>
					<span>Choose from our convenient pre-made templates or create your own.</span>
					{ this.renderTemplateCells() }
				</div>
			</Modal>
		);
	}

	renderTemplateCells = () => {
		this.renderedTemplateCells = [
			(
				<div key={"new"} style={{ flex: "0 1 auto", margin: 30, textAlign: "center" }}>
					<DeviceButton onClick={ this.selectTemplate(new AdTemplate()) }>
						<DeviceImage
							height={ 107 }
							model="CE-P43-B"
						/>
						<div>Custom</div>
					</DeviceButton>
				</div>
			)
		];

		this.renderedTemplateCells.push(DefaultTemplates.map((t: AdTemplate, i: number) => {
			return (
				<div key={t.uuid} style={{ flex: "0 1 auto", margin: 30, textAlign: "center" }}>
					<DeviceButton onClick={ this.selectTemplate(t) }>
						<DeviceImage
							height={ 107 }
							model="CE-P43-B"
							thumbnail={ t.thumbnail }
						/>
						<div>
							{t.name}
						</div>
					</DeviceButton>
				</div>
			);
		}));

		let templateCount = 0;
		this.renderedTemplates = [];

		while (this.renderedTemplateCells.length >= 4) {
			let take = this.renderedTemplateCells.splice(0, 4);
			this.renderedTemplates.push(
				<div style={{ display: "flex" }} key={templateCount}>
					{take}
				</div>
			);
			templateCount++;
		}

		if (this.renderedTemplateCells.length > 0) {
			this.renderedTemplates.push(
				<div style={{ display: "flex" }} key={templateCount}>
					{this.renderedTemplateCells}
				</div>
			);
		}

		return this.renderedTemplates;
	}

	hide() {
		this.props.hideModal();
	}

	selectTemplate(template: AdTemplate) {
		return () => {
			this.props.showRequestNameModal(template);
			this.hide();
		};
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedTemplatesListModal);
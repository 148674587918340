import { combineReducers } from "redux";

import Activity from "Data/Reducers/Activity";
import Users from "Data/Reducers/Users";
import Versions from "Data/Reducers/Versions";

export default combineReducers({
	Activity,
	Versions,
	Users
});
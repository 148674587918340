import Radium from "radium";
import * as React from "react";
import * as animations from "react-animations";

import { CustomCSS } from "@connect/Interfaces";

interface DeviceButtonProps {
	onClick?: Function;
}

class DeviceButtonUnwrapped extends React.Component<DeviceButtonProps, any> {
	constructor(props: DeviceButtonProps) {
		super(props);

		this.clickDeviceButton = this.clickDeviceButton.bind(this);
	}

	styles = {
		base: {
			cursor: "pointer",
			transition: "transform 125ms ease-out",
			":hover": {
				transform: "scale(1.025, 1.025)"
			}
		},
		clicked: {
			animation: "x 1s",
			animationName: Radium.keyframes(animations.pulse, "pulse")
		},
		notClicked: {
			animation: "x 600ms",
			animationName: Radium.keyframes(animations.fadeOut, "fadeOut")
		}
	}

	state = {
		clicked: false,
		notClicked: false
	}

	render() {
		return (
			<div style={{
				...this.styles.base,
				...(this.state.clicked && this.styles.clicked),
				...(this.state.notClicked && this.styles.notClicked)
			} as CustomCSS}
			onClick={ this.clickDeviceButton }
			>
				{this.props.children}
			</div>
		);
	}

	clickDeviceButton() {
		const { onClick } = this.props;

		this.setState(() => ({ clicked: true }));

		setTimeout(() => {
			if (onClick) {
				onClick();
			}
		}, 500)
	}
}

const DeviceButton = Radium(DeviceButtonUnwrapped);

export { DeviceButton }
import { HealthReport, HealthReportApiResult, HealthReportNotification, HealthReportResult, IHealthReportApi,
	InitialStreamResults, StreamResults } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class HealthReportApi extends ApiV2 implements IHealthReportApi {
	createReport(report: Partial<HealthReport>) {
		const { name, devices, deviceGroups, columns } = report;
		return this.POST("reports/health", {
			columns: columns || [ "status.timestamp", "device.name" ],
			name,
			devices,
			deviceGroups
		});
	}

	deleteReport(uuid: string) {
		return this.DELETE(`reports/health/${uuid}`);
	}

	getNotificationSettings(uuid: string) {
		return this.GET(`reports/health/${uuid}/notification`);
	}

	getReport(uuid: string, expandDevices?: boolean, appendNotification?: boolean) {
		const url = `reports/health/${uuid}`;
		let attrs: any = {};

		if (expandDevices) {
			attrs.expand = "devices,deviceGroups";
		}

		if (appendNotification) {
			attrs.append = "notification";
		}

		return this.GET(url, attrs);
	}

	getReportExport(uuid: string) {
		return this.GET(`reports/health/${uuid}/results/export`);
	}

	getReportResults(
		uuid: string,
		onStart: (initialResults: InitialStreamResults) => void,
		onProgress: (results: StreamResults<HealthReportResult>) => void
	) {
		return this.STREAM(`reports/health/${uuid}/results`, onStart, onProgress);
	}

	getReports(page?: number, perPage?: number, expandDevices?: boolean): Promise<HealthReportApiResult> {
		const url = "reports/health";
		let attrs: any = {};

		if (perPage) {
			attrs.perPage = perPage;
		}
		if (page) {
			attrs.page = page;
		}
		if (expandDevices) {
			attrs.expand = "devices,deviceGroups";
		}

		return this.GET(url, attrs) as Promise<HealthReportApiResult>;
	}

	runReport(uuid: string) {
		return this.POST(`reports/health/${uuid}/execute`, {});
	}

	updateNotificationSettings(uuid: string, settings: HealthReportNotification) {
		return this.PUT(`reports/health/${uuid}/notification`, settings);
	}

	updateReport(report: HealthReport) {
		return this.PUT(`reports/health/${report.uuid}`, report);
	}
}

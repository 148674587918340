import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import {
	Area,
	AreaChart,
	CartesianGrid,
	LineType,
	ResponsiveContainer,
	Tooltip,
	TooltipProps,
	XAxis,
	YAxis
} from "recharts";

import { AnalyticsDataResult, CustomCSS } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import AnalyticsTooltip from "Components/Analytics/AnalyticsTooltip";
import { ISuperAnalyticsChartProps, ISuperAnalyticsChartState, mapStateToProps as superMapStateToProps,
	SuperAnalyticsChart } from "Components/Analytics/SuperAnalyticsChartComponent";
import { XAxisTick } from "Components/Analytics/XAxisTick";
import { Colors } from "Components/Global/Constants";
import { cloneDeep } from "lodash";

const { darkBlue, lightGray, primaryBlue } = Colors;

interface DwellStat {
	stat: number;
	title: string;
}

export class DwellTimeChart extends SuperAnalyticsChart<ISuperAnalyticsChartProps, ISuperAnalyticsChartState> {
	constructor(props: ISuperAnalyticsChartProps) {
		super(props);

		this.styles = Object.assign({}, this.styles, {});

		this.renderYAxisTick = this.renderYAxisTick.bind(this);
		this.renderStats = this.renderStats.bind(this);
		this.renderTooltip = this.renderTooltip.bind(this);
	}

	stats: DwellStat[];
	data: AnalyticsDataResult[];
	styles: CustomCSS;

	componentWillMount() {
		this.setupComponent()
	}

	componentWillUpdate(nextProps: ISuperAnalyticsChartProps) {
		this.setupComponent(nextProps);
	}

	render() {
		const { chartContainer, main, statsContainer } = this.styles;
		const { disabled, loading } = this.props;
		const [ start, end ] = this.props.range.split("_");
		const allDates = Utils.getDaysBetweenDates(start, end);
		const thirtyPlus = allDates.length > 30;
		let interval = 0;

		if (disabled) {
			return this.renderContainer(<div />);
		}

		switch (allDates.length) {
			case 30:
				interval = 8; // (30 / 4)
				break;
			case 90:
				interval = 3; // (90 / 7 / 4)
				break;
			case 365:
				interval = 13; // (365 / 7 / 4)
				break;
		}

		// get our largest duration to use in formatting our y axis
		const maxDwell = Math.max.apply(null, this.data.map((d) => d.dwellTime));
		// recharts displays 0.5s, 1s, etc for low values; this takes up differing amounts of space
		// figure we'll use three characters of space if we have under 5 minutes of total dwellTime
		const yLabelLength = maxDwell >= 300 ? String(Math.ceil(maxDwell / 60)).length + 1 : 3;
		const calculatedYAxisWidth = 14 * yLabelLength;

		const xAxisProps = {
			style: { fill: lightGray },
			dataKey: "date",
			stroke: "6px",
			tick: this.renderXAxisTick(interval, thirtyPlus),
			interval
		}

		const yAxisProps = {
			style: { fill: lightGray },
			tickFormatter: this.renderYAxisTick,
			width: calculatedYAxisWidth
		}

		const areaProps = {
			type: "linear" as LineType,
			dataKey: "dwellTime",
			stroke: darkBlue,
			strokeWidth: 3,
			fill: primaryBlue
		}

		const chartProps = {
			data: this.data,
			margin: { top: 20, bottom: 10, right: 20 }
		}

		const tooltipProps = {
			content: this.renderTooltip(thirtyPlus),
			cursor: { visibility: "hidden" },
			isAnimationActive: false
		};

		const chart = loading ? <div /> : (
			<AreaChart { ...chartProps }>
				<CartesianGrid strokeDasharray="1 1" vertical={ false } />
				<XAxis { ...xAxisProps } />
				<YAxis { ...yAxisProps } />
				<Tooltip { ...tooltipProps } />
				<Area { ...areaProps } />
			</AreaChart>
		);

		return this.renderContainer(
			<div style={ main }>
				<div style={ chartContainer }>
					<ResponsiveContainer width="90%" height="90%">
						{ chart }
					</ResponsiveContainer>
				</div>
				<div style={ statsContainer }>
					{ this.renderStats() }
				</div>
			</div>
		);
	}

	renderStats() {
		return this.stats.map((item: DwellStat) => {
			const { stat, title } = item;
			const { statBlock, statistic, description } = this.styles;

			return (
				<div style={ statBlock } key={ title }>
					<span style={ statistic }>{ stat } sec</span>
					<span style={ description }>{ title }</span>
				</div>
			);
		});
	}

	renderTooltip(thirtyPlus: boolean) {
		return ({ active, payload, label }: TooltipProps) => {
			if (!active) {
				return null;
			}

			const { payload: data } = payload ? payload[0] : { payload: { date: "", dwellTime: "" } };
			const { date, dwellTime: value } = data;

			return (
				<AnalyticsTooltip
					label={ "Total Dwell" }
					value={ Utils.getHumanReadableDuration(value, false, true) }
					date={ date }
					combineDates={ thirtyPlus }
				/>
			);
		}
	}

	renderXAxisTick(interval: number, timesSeven: boolean) {
		return (props: any) => {
			return <XAxisTick { ...props } interval={ interval } timesSeven />;
		}
	}

	renderYAxisTick(secondsDuration: string) {
		const minutes = secondsDuration ? Number(secondsDuration) / 60 : 0;
		if (minutes > 1) {
			return `${ Math.ceil(minutes) }m`;
		}
		return `${ secondsDuration }s`;
	}

	formatData(nextProps?: ISuperAnalyticsChartProps) {
		const props = nextProps || this.props;
		const { range, data } = props;
		const [ start, end ] = range.split("_");
		const allDates = Utils.getDaysBetweenDates(start, end);
		const subdivide = allDates.length > 30 // if we are looking at more than 30 days, display weeks
		const newDates = allDates
			.filter((date, index) => subdivide ? index % 7 === 0 : true)
			.map((date) => {
				return {
					date,
					dwellTime: 0
				} as Partial<AnalyticsDataResult>
			});

		cloneDeep(data).forEach((item, i) => {
			const dates = newDates.map((d) => d.date);
			const momentDate = moment(item.date);
			const dateIndex = subdivide
				? dates.findIndex((d) => momentDate.isSameOrBefore(d, "week"))
				: dates.indexOf(item.date);

			if (dateIndex === -1) {
				newDates.push(item);
				return;
			}

			const keys = Object.keys(item);

			// iterate over the values in each row of data
			keys.forEach((key) => {
				const value = item[key];

				// not an object
				if (value === undefined) {
					return newDates[dateIndex] = item;
				}

				// discard non-numeric values
				if (typeof(value) === "number") {
					// append data to the last date-entry if we don't have an index of it
					if (dateIndex === -1) {
						return newDates[newDates.length - 1][key] += value;
					}

					// if we're all good here, concatenate the value to the appropriate date index
					return newDates[dateIndex][key] += value;
				}
			});
		});

		this.data = Utils.sort(newDates, "date", true);
	}

	formatStats(nextProps?: ISuperAnalyticsChartProps) {
		const props = nextProps || this.props;
		const { result, ad } = props;
		const toFixed = (int: number) => Number(int.toFixed(2));

		this.stats = [
			{
				stat: result && result.dwell ? toFixed(result.dwell.average) : 0,
				title: "Average Dwell"
			},
			{
				stat: result && result.dwell ? toFixed(result.dwell.longest) : 0,
				title: "Longest Dwell"
			},
			{
				stat: ad ? toFixed(ad.duration) : 0,
				title: "Ad Length"
			}
		];
	}

	setupComponent(nextProps?: ISuperAnalyticsChartProps) {
		this.formatStats(nextProps);
		this.formatData(nextProps);
	}
}

export default connect(superMapStateToProps, null)(DwellTimeChart);
import Radium from "radium";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";
import Icon, { IconWeights } from "Components/Global/Icon";

interface DeviceSupportCardProps {
	title?: string;
	icon: string;
	onClick: any;
	disabled: boolean;
	spin?: boolean;
	iconWeight?: IconWeights;
	hidden?: boolean;
}

class DeviceSupportCard extends React.Component<DeviceSupportCardProps> {
	constructor(props: DeviceSupportCardProps) {
		super(props);

		this.baseStyle = {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			justifySelf: "center"
		}

		this.styles = {
			cardStyle: {
				...this.baseStyle,
				boxShadow: "0 4px 8px 0 #F3F3F3, 0 6px 20px 0 #F9F9F9",
				width: 250,
				height: 250,
				position: "relative"
			},
			icon: {
				...this.baseStyle,
				width: 100,
				height: 100,
				color: Colors.lightGray
			},
			title: {
				color: Colors.black,
				fontSize: "1.2em",
				marginBottom: 10
			},
			clickable: {
				...this.baseStyle,
				userSelect: "none",
				transition: "all 250ms ease-out",
				cursor: "pointer",
				padding: 20,
				position: "relative",
				":hover": {
					transform: "scale(1.05, 1.05)",
					cursor: "pointer"
				},
				":active": {
					transform: "scale(1.025, 1.025)",
					cursor: "pointer"
				}
			}
		}

		this.handleClick = this.handleClick.bind(this);
	}

	baseStyle: CustomCSS;
	styles: CustomCSS;

	render() {
		const { title, icon, children, iconWeight, disabled, spin, hidden } = this.props;
		const { cardStyle, clickable } = this.styles;

		return (
			<div
				key={ title }
				style={{
					...cardStyle,
					background: !disabled ? "none" : "#f2f2f2",
					display: hidden ? "none" : "flex"
				}}
			>
				<div
					style={{
						...clickable,
						pointerEvents: !disabled ? "auto" : "none"
					}}
					onClick={ this.handleClick }
				>
					{ this.renderIcon(icon, iconWeight, spin) }
					{ this.renderTitle(title) }
				</div>
				{ children }
			</div>
		);
	}

	renderIcon(name: string, iconWeight?: IconWeights, spin?: boolean) {
		return (
			<Icon
				name={ name }
				size="big"
				spin={ spin }
				iconWeight={ iconWeight }
				style={ this.styles.icon }
			/>
		);
	}

	renderTitle(title: string = "") {
		return (
			<span style={ this.styles.title }>
				{ title }
			</span>
		);
	}

	handleClick() {
		const { disabled, onClick } = this.props;

		if (!disabled) {
			onClick();
		}
	}
}

export default Radium(DeviceSupportCard);
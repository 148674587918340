import * as React from "react";

import { Colors } from "Components/Global/Constants";
import { checkDeviceCapabilities, DeviceSvgModifiers } from "Data/Objects/Devices";
import { DeviceCapabilities } from "@connect/Interfaces";

const { fiveGray, threeGray, twoGray, white, gandalf } = Colors;

interface DeviceImageProps {
	height: number;
	model: string;
	thumbnail?: string;
}

interface DeviceImageState {
}

export default class DeviceImage extends React.Component<DeviceImageProps, DeviceImageState> {
	constructor(props: DeviceImageProps) {
		super(props);
	}

	render() {
		const { height, model } = this.props;
		const width = (9 * height) / 16;

		const svgModifiers = this.getBaseModifiers();
		const viewBoxFactor = svgModifiers.viewboxFactor;
		const baseX = width * svgModifiers.baseXMod;
		const baseY = height * svgModifiers.baseYMod;

		const radius = 4; // used for both pvm rounded corner and front button

		const viewBox = "0 1 " + width * viewBoxFactor + " " + height * viewBoxFactor;

		if (!model) {
			return null;
		}

		return (
			<svg width={ width } height={ height } style={{ borderRadius: 3 }} viewBox={ viewBox }>
				{ this.getGradientDefs() }
				{ this.getSvgRect(baseX, baseY, width, height, radius)}
				{ this.getPVMContent(baseX, baseY, width, height) }
				{ this.renderCamera(baseX, baseY, radius, width, height) }
			</svg>
		);
	}

	getSvgRect(baseX: number, baseY: number, width: number, height: number, radius: number) {
		const { model } = this.props;

		if (!model) {
			return null;
		}

		const whiteModel = model.includes("W");
		const PVMStroke = whiteModel ? gandalf : white;
		const bezel = whiteModel ? "bezelw" : "bezel";

		const bezelFill = `url(#${ bezel })`;

		return (
			<rect x={ baseX } y={ baseY } width={ width } height={ height } fill={ bezelFill } stroke={ PVMStroke }
				strokeWidth={ 1 } rx={ radius } ry={ radius } />
		)
	}

	getGradientDefs() {
		return (
			<defs>
				{ this.getGradient("bezel", gandalf, twoGray) }
				{ this.getGradient("bezelw", gandalf, white) }
				{ this.getGradient("screen", threeGray, "#57595b", "100%") }
			</defs>
		)
	}

	getPVMContent(baseX: number, baseY: number, width: number, height: number) {
		const { model, thumbnail } = this.props;

		if (!model) {
			return null;
		}

		const screenX = baseX + 5;
		const screenY = baseY + 5;
		const screenWidth = width - 10;

		let screenHeight = height - 25;
		if (!checkDeviceCapabilities(model, DeviceCapabilities.CAMERA)) {
			screenHeight += 15;
		}

		if (thumbnail) {
			return (
				<image width={ screenWidth } height={ screenHeight } x={ screenX } y={ screenY }
					href={ thumbnail } preserveAspectRatio="none" />
			);
		}

		return (
			<rect width={ screenWidth } height={ screenHeight } x={ screenX } y={ screenY }
				fill="url(#screen)" stroke={ fiveGray } strokeWidth={ 1 } />
		)
	}

	renderCamera(baseX: number, baseY: number, radius: number, width: number, height: number) {
		const { model } = this.props;

		if (!model) {
			return null;
		}

		const centerX = baseX + (width / 2);
		const centerY = baseY + (height - 11);

		if (!checkDeviceCapabilities(model, DeviceCapabilities.CAMERA)) {
			return null;
		}

		return (
			<circle cx={ centerX } cy={ centerY } r={ radius } fill={ fiveGray } />
		);
	}

	getGradient(id: string, zeroStop: string, fullStop: string, zeroOffset: string = "0%") {
		return (
			<linearGradient id={ id } gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="1" y2="1">
				<stop offset={ zeroOffset } stopColor={ zeroStop } stopOpacity="0%"/>
				<stop offset="100%" stopColor={ fullStop } stopOpacity="100%"/>
			</linearGradient>
		);
	}

	getBaseModifiers() {
		const { model } = this.props;

		const [ , screenSize ] = model?.match(/CE-[A-Z]?([0-9]{2})[A-Z]*-.{1,4}/) ?? [];

		if (screenSize) {
			return DeviceSvgModifiers[screenSize]
		}

		return DeviceSvgModifiers[43];
	}
}
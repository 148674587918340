import { IUser } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";

const {
	CREATE_USER,
	DELETE_USER,
	UPDATE_USER,
	SET_ALL_USERS,
	SET_USERS
} = ACTION_TYPES.Admin.Users;

export function createUser(user: IUser) {
	return { type: CREATE_USER.type, args: { user }};
}

export function updateUser(user: IUser) {
	return { type: UPDATE_USER.type, args: { user }};
}

export function deleteUser(user: IUser) {
	return { type: DELETE_USER.type, args: { user }};
}

export function setAllUsers(users: IUser[], reset?: boolean) {
	return { type: SET_ALL_USERS.type, args: { users, reset }};
}

export function setUsers(users: IUser[], reset?: boolean) {
	return { type: SET_USERS.type, args: { users, reset }};
}
import * as moment from "moment";
import * as React from "react";

import { Colors } from "Components/Global/Constants";

interface XAxisTickProps {
	payload: any;
	x: number;
	y: number;
	timesSeven: boolean;
	interval: number;
}

export class XAxisTick extends React.PureComponent <XAxisTickProps> {
	constructor(props: XAxisTickProps) {
		super(props);
	}

	render() {
		const { payload, x, y, interval, timesSeven } = this.props;

		if (!payload && !payload.value ) {
			return "";
		}

		const dateMoment = moment(payload.value);
		const divisor = timesSeven ? interval * 7 : interval;
		const date = dateMoment.format("DD");
		const month = date === "01" || payload.index === 0 || Number(date) - divisor <= 0 ? (
			<React.Fragment>
				<br />{ dateMoment.format("MMM") }
			</React.Fragment>
		) : null;
		const textAttrs = (lower?: boolean) => ({
			x: 0,
			y: 0,
			dy: lower ? 20 : 8,
			fill: Colors.lightGray,
			textAnchor: "middle"
		});

		return (
			<g transform={ `translate(${x},${y})` }>
				<text { ...textAttrs() }>{ date }</text>
				<text { ...textAttrs(true) }>{ month }</text>
			</g>
		);
	}
}

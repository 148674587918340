import { createElement } from "react";

import { notifyBugSnagAsync } from "@connect/BugSnag";
import { Notifications } from "@connect/Notifications";
import ErrorDescription from "Components/Global/ErrorDescription";

export const errorNotification = (title: string, error?: any) => (dispatch) => {
	if (error) {
		const hasMessage = error.message !== undefined && error.message.length > 0;
		let innerError: any;

		if (typeof error === "string") {
			innerError = error;
		} else if (hasMessage) {
			if (typeof error.message === "string" && !error.errors) {
				innerError = createElement("div", null, error.message);
			} else {
				innerError = createElement(ErrorDescription, { error });
			}
		}

		dispatch(notifyBugSnagAsync(new Error(error)));
		Notifications.error(title, innerError);
	}

	return null;
};

export const successNotification = (title: string, message?: any) => (dispatch) => {
	Notifications.success(title, createElement("div", null, message));
	return null;
};

export const confirmNotification = (title: string, message?: string, okText?: string,
	cancelText?: string, onConfirm?: () => void) => (dispatch) => {

	Notifications.confirm(title, createElement("div", null, message), okText, cancelText, onConfirm);
	return null;
};

export const plainNotification = (title: string, message?: any, duration?: number) => (dispatch) => {
	Notifications.plain(title, createElement("div", null, message), null, duration);
	return null;
};

import * as update from "immutability-helper";
import Radium from "radium";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IImageComponent } from "@connect/Interfaces";
import { AdColors } from "Components/Ads/Constants";
import {
	AdComponentCard,
	IAdComponentCardProps,
	IAdComponentCardState,
	SuperComponentCard,
	SuperComponentClass
} from "Components/Ads/SuperComponentCard";
import { Colors } from "Components/Global/Constants";

interface ImageComponentCardProps extends IAdComponentCardProps<IImageComponent> {}

@Radium
export class ImageComponentCard extends AdComponentCard<ImageComponentCardProps, IAdComponentCardState> {
	constructor(props: ImageComponentCardProps) {
		super(props);

		this.icon = "image";
		this.name = "Image";

		this.styles = Object.assign(this.styles, {
		});

		this.renderContainer = this.renderContainer.bind(this);
	}

	icon: string;
	name: string;
	styles: {
		baseButton: CustomCSS;
		bottomResizeHandle: CustomCSS;
		card: CustomCSS;
		container: CustomCSS;
		deleteButton: CustomCSS;
		eraseButton: CustomCSS;
		heightAdjuster: CustomCSS;
		livePreview: CustomCSS;
		livePreviewName: CustomCSS;
		promptIcon: CustomCSS;
		promptText: CustomCSS;
		resolutionOverlay: CustomCSS;
		snapButton: CustomCSS;
		upload: CustomCSS;
	};

	render() {
		return this.renderContainer(<div />);
	}

	getBackgroundSize(scale: number) {
		if (!scale || scale === null) {
			return "cover";
		}

		return scale + "% auto";
	}

	getCardStyles() {
		const { component, livePreview, media } = this.props;
		const { backgroundColor, lock, scale } = component;
		const withImage = livePreview && media && media[0];
		const backgroundColorStyle = withImage ? (backgroundColor || Colors.white) : AdColors.image.background;
		const backgroundImage = withImage && media ? `url(${media[0].uri})` : "";
		const backgroundPosition = withImage ? this.getImageLock(lock) : this.styles.card.backgroundPosition;
		const backgroundSize = withImage ? this.getBackgroundSize(scale) : this.styles.card.backgroundSize;

		return update(this.styles.card, {
			backgroundColor: { $set: backgroundColorStyle },
			backgroundImage: { $set: backgroundImage },
			backgroundPosition: { $set: backgroundPosition },
			backgroundSize: { $set: backgroundSize }
		});
	}

	getImageLock(lock: string) {
		switch (lock) {
			case "topLeft":
				return "left top";
			case "topCenter":
				return "center top";
			case "topRight":
				return "right top";
			case "middleLeft":
				return "left center";
			case "middleCenter":
				return "center center";
			case "middleRight":
				return "right center";
			case "bottomLeft":
				return "left bottom";
			case "bottomCenter":
				return "center bottom";
			case "bottomRight":
				return "right bottom";
			default:
				return "center center";
		}
	}

	getKey(suffix: string) {
		const key = super.getKey(suffix);
		const { media } = this.props;
		const { uuid } = media && media.length ? media[0] : { uuid: "" }

		return `${uuid}_${key}`;
	}

	// ensure that we show the image icon if we don't have a media for this component
	shouldShowLivePreview() {
		const { livePreview, media } = this.props;

		return !!(livePreview && media && media[0]);
	}
}

const {
	Draggable,
	mapStateToProps,
	mapDispatchToProps
} = SuperComponentCard;

export const DndImageComponentCard: SuperComponentClass<ImageComponentCardProps> = Draggable(
	connect(mapStateToProps, mapDispatchToProps)(ImageComponentCard)
);

export default DndImageComponentCard;

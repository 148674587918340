import { notifyBugSnag } from "@connect/BugSnag";
import { store } from "@connect/Data";
import { IMedia, IMediaUpload, MEDIA_ERRORS } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Utils } from "@connect/Utils";
import FileHandler from "Components/Global/FileHandler";
import Uploader from "Components/Global/Uploader";
import { createMedia } from "Data/Actions/Media";
import { endUpload, startUpload, updateProgress } from "Data/Actions/Media";
import { MediaUpload } from "Data/Objects/Uploads";
import { getActiveCompany } from "Data/Selectors/Company";

interface SupportedFileType {
	fileType: string;
	typeCheck: (file: File) => boolean;
	fileChecks: ((file: File) => boolean)[];
	handleFile: (file: File) => {};
}

export default class MediaHandler extends FileHandler {
	constructor(originator: string = "") {
		super();

		this.company = getActiveCompany(store.getState());

		this.originator = originator;

		this.generalLimitCheck = this.generalLimitCheck.bind(this);
		this.imageLimitCheck = this.imageLimitCheck.bind(this);
		this.videoLimitCheck = this.videoLimitCheck.bind(this);
		this.canBeBanner = this.canBeBanner.bind(this);

		this.isAcceptedType = this.isAcceptedType.bind(this);
		this.isImage = this.isImage.bind(this);
		this.isVideo = this.isVideo.bind(this);

		this.handleFiles = this.handleFiles.bind(this);
		this.handleBannerFile = this.handleBannerFile.bind(this);
		this.handleImageFile = this.handleImageFile.bind(this);
		this.handleVideoFile = this.handleVideoFile.bind(this);

		this.supportedFileTypes = [
			{
				fileType: "Banner",
				typeCheck: this.isImage,
				fileChecks: [
					this.generalLimitCheck,
					this.imageLimitCheck,
					this.canBeBanner
				],
				handleFile: this.handleBannerFile
			},
			{
				fileType: "Image",
				typeCheck: this.isImage,
				fileChecks: [
					this.generalLimitCheck,
					this.imageLimitCheck
				],
				handleFile: this.handleImageFile
			},
			{
				fileType: "Video",
				typeCheck: this.isVideo,
				fileChecks: [
					this.generalLimitCheck,
					this.videoLimitCheck
				],
				handleFile: this.handleVideoFile
			}
		] as SupportedFileType[];

		this.restrictedFileTypes = [
			"image/vnd.adobe.photoshop",
			"image/svg+xml"
		];
	}

	originator: string;
	onSuccess: (media: any) => void;

	/*
	 * Checks for allowed file types, throws errors on unallowed files,
	 * sends files to their appropriate handlers
	 */
	handleFiles(f: File[]) {
		let files: File[] = [ ...f ];

		// use for...of instead of forEach as forEach won't wait for all
		// async items to be finished and will run handleError too early
		for (const file of files) {

			// get the accepted types so as not to recurse over the structure for unnecessary types
			const types = this.supportedFileTypes.filter((type) => this.allowedFileTypes.includes(type.fileType));

			// for each accepted type, do a type check on our current file
			types.forEach(async (currentType, i) => {
				const isType = await currentType.typeCheck(file);

				// if this file is accepted by the handler, handle, then filter files
				if (isType) {
					const { name, type, lastModified } = file;
					// Modified from https://stackoverflow.com/questions/40911927/instantiate-file-object-in-microsoft-edge
					let blob: any = new Blob([ file ], { type });
					blob.name = Utils.removeSpecialChars(name);
					blob.lastModifiedDate = lastModified;
					currentType.handleFile(blob as File);
					files = files.filter(currentFile => currentFile !== file);
				}

				// if we have finished with our last file and type, handle errors
				if (f.indexOf(file) === f.length - 1 && i === types.length - 1) {
					files.map((currentFile: File) => {
						this.handleError(currentFile, MEDIA_ERRORS.UNSUPPORTED_TYPE_ERROR);
					});
				}
			});
		}
	}

	// region Functions for handling different file types
	handleImageFile(file: File, fileChecks?: ((file: File) => boolean)[]) {
		if (this.generalLimitCheck(file) && this.imageLimitCheck(file)) {
			this.handleUploadMedia(file);
		}
	}

	async handleVideoFile(file: File) {
		if (this.generalLimitCheck(file) && (await this.videoLimitCheck(file))) {
			this.handleUploadMedia(file);
		}
	}

	async handleBannerFile(file: File) {
		if (this.generalLimitCheck(file) && this.imageLimitCheck(file) && (await this.bannerLimitCheck(file))) {
			this.handleUploadMedia(file);
		}
	}
	// endregion

	// region Functions for checking against limits
	generalLimitCheck(file: File) {
		if (this.company.mediaUsage.library + file.size > this.company.mediaLimit.librarySize) {
			this.handleError(file, MEDIA_ERRORS.LIBRARY_USAGE_ERROR);
			return false;
		}
		return true;
	}

	imageLimitCheck(file: File) {
		if (file.size > this.company.mediaLimit.imageSize) {
			this.handleError(file, MEDIA_ERRORS.IMAGE_SIZE_ERROR);
			return false;
		}
		if (this.company.mediaUsage.image + file.size > this.company.mediaLimit.monthlyImageSize) {
			this.handleError(file, MEDIA_ERRORS.IMAGE_USAGE_ERROR);
			return false;
		}
		return true;
	}

	async bannerLimitCheck(file: File) {
		if (!(await this.canBeBanner(file))) {
			this.handleError(file, MEDIA_ERRORS.BANNER_WRONG_SIZE_ERROR);
			return false;
		}
		return true;
	}

	async videoLimitCheck(file: File) {
		let video = await this.getVideo(file);

		if (file.size > this.company.mediaLimit.videoSize) {
			this.handleError(file, MEDIA_ERRORS.VIDEO_SIZE_ERROR);
			return false;
		}
		if (video) {
			// Only attempt to check video duration/resolution after the video has been fetched
			if (video.duration > this.company.mediaLimit.videoLength) {
				this.handleError(file, MEDIA_ERRORS.VIDEO_DURATION_ERROR);
				return false;
			}
			if (video.videoWidth > 1920 || video.videoHeight > 1920) {
				this.handleError(file, MEDIA_ERRORS.VIDEO_RESOLUTION_ERROR);
				return false;
			}
			if (this.company.mediaUsage.duration + video.duration > this.company.mediaLimit.monthlyVideoLength) {
				this.handleError(file, MEDIA_ERRORS.VIDEO_USAGE_ERROR);
				return false;
			}
		}
		return true;
	}
	// endregion

	// region Functions for checking different file types
	isAcceptedType(file: File) {
		return !this.restrictedFileTypes.includes(file.type);
	}

	isImage(file: File) {
		return this.isAcceptedType(file) && new RegExp("^image/").test(file.type);
	}

	async isVideo(file: File) {
		if (file.type && this.isAcceptedType(file)) {
			return new RegExp("^video/").test(file.type);
		}

		const testResult = await this.testVideoFileSignature(file)
			.then((result: boolean) => result, (error: string) => {
				this.handleError(file, MEDIA_ERRORS.FILE_READER_ERROR, error);
				return false;
			})
			.catch(error => notifyBugSnag(new Error(error)));

		return testResult;
	}

	async testVideoFileSignature(file: File) {
		const { name } = file;
		const blob = file.slice(0, 4);
		const extension = name.slice(name.lastIndexOf(".") + 1);
		const videoHexes = {
			avi: "52494646",
			divx: "52494646",
			mkv: "1A45DFA3"
		};

		const extAccepted = Object.keys(videoHexes).indexOf(extension) > -1;
		if (!extAccepted) {
			return false;
		}

		const testVideoPromise = new Promise<boolean>((resolve, reject) => {
			const reader: FileReader = new FileReader();
			reader.readAsArrayBuffer(blob);
			reader.onload = (readerEvent: ProgressEvent & { target: FileReader }) => {
				const uint = new Uint8Array(readerEvent.target.result as ArrayBuffer);
				let bytes: string[] = [];
				uint.forEach((byte) => bytes.push(byte.toString(16)));
				const hex = bytes.join("").toUpperCase();

				// if hex is 52494646 or 1A45DFA3, which is either a divx or mkv file, resolve
				// reference: https://en.wikipedia.org/wiki/List_of_file_signatures
				const hexAccepted = Object.values(videoHexes).indexOf(hex) > -1;
				resolve(hexAccepted);
			};
			reader.onerror = (errorEvent: ProgressEvent) => {
				const errorMessage = (errorEvent.target as FileReader).error;
				reject(errorMessage);
			}
		});

		return await testVideoPromise;
	}
	// endregion

	async getVideo(file: File) {
		// Only actually fetch a video if the browser supports playing it
		if (document.createElement("video").canPlayType(file.type)) {
			let fetchVideoPromise = new Promise<any>((resolve, reject) => {
				let video = document.createElement("video");
				video.oncanplay = () => {
					resolve(video);
				};
				video.onerror = (error) => {
					reject();
				};
				video.src = window.URL.createObjectURL(file);
			});

			return await fetchVideoPromise;
		}
		return null;
	}

	// region Functions for checking banner requirements
	async canBeBanner(file: File) {
		let imageDimensions = await this.getImageDimensions(file);
		return ((imageDimensions.width === 1080) && (imageDimensions.height <= 200));
	}

	async getImageDimensions(file: File) {
		let fetchImagePromise = new Promise<any>((resolve, reject) => {
			let i = new Image();
			i.onerror = reject;
			i.onload = function() {
				resolve(i);
			};
			i.src = window.URL.createObjectURL(file);
		})
		let image = await fetchImagePromise;

		return {
			width: image.width,
			height: image.height
		};
	}
	// endregion

	/*
	 * Function for handling media uploads
	 */
	async handleUploadMedia(file: File) {
		let mediaUpload = new MediaUpload(file, this.originator);

		if (this.isImage(file)) {
			mediaUpload.mediaType = "image";
		} else if (await this.isVideo(file)) {
			mediaUpload.mediaType = "video";
		}

		if (mediaUpload.mediaType) {
			let uploader = new Uploader(mediaUpload.file);

			mediaUpload.uploader = uploader;

			uploader.onError = (errorType: MEDIA_ERRORS, error?: string) => {
				this.deleteUpload(mediaUpload);
				if (error) {
					this.handleError(file, errorType, error);
				}
			};

			uploader.onMediaCreated = (uuid: string) => {
				mediaUpload.uuid = uuid;
				mediaUpload.name = mediaUpload.file.name;
				mediaUpload.created_at = Utils.getHumanReadableDate(new Date().toUTCString());
				mediaUpload.progress = 10;

				this.createUpload(mediaUpload);
			};

			uploader.onProgress = (progress) => {
				const percent = (progress.loaded / progress.total) * 100;
				// Because we start progress at 10%, never let it fall back
				if (percent > 10) {
					this.updateUpload(Object.assign({}, mediaUpload, {
						progress: percent
					}));
				}
			};

			uploader.onSuccess = (media) => {
				this.finalizeUpload(media);
				this.deleteUpload(mediaUpload);
				if (this.onSuccess) {
					this.onSuccess(media);
				}
				Notifications.success("File Uploaded", file.name);
			};

			uploader.upload(this.allowedFileTypes.includes("Banner") ? "banner" : undefined);

			return null;
		}
		return this.handleError(file, MEDIA_ERRORS.UNSUPPORTED_TYPE_ERROR);
	}

	// region CRUD for Media/MediaUploads
	createUpload(media: IMediaUpload) {
		store.dispatch(startUpload(media));
	}

	deleteUpload(media: IMediaUpload) {
		store.dispatch(endUpload(media));
	}

	finalizeUpload(media: IMedia) {
		media.originator = this.originator;
		store.dispatch(createMedia(media));
	}

	updateUpload(media: IMediaUpload) {
		store.dispatch(updateProgress(media));
	}
	// endregion

	/*
	 * Function for throwing media errors
	 */
	handleError(file: File, errorType: MEDIA_ERRORS, error?: any) {
		switch (errorType) {
			case MEDIA_ERRORS.LIBRARY_USAGE_ERROR:
				Notifications.error("Library Full", this.createLibraryUsageError(file));
				break;
			case MEDIA_ERRORS.IMAGE_SIZE_ERROR:
				Notifications.error("Image Too Large", this.createImageSizeError(file));
				break;
			case MEDIA_ERRORS.IMAGE_USAGE_ERROR:
				Notifications.error("Image Library Full", this.createImageUsageError(file));
				break;
			case MEDIA_ERRORS.VIDEO_SIZE_ERROR:
				Notifications.error("Video Too Large", this.createVideoSizeError(file));
			case MEDIA_ERRORS.VIDEO_RESOLUTION_ERROR:
				Notifications.error("Invalid Video Resolution", this.createVideoResolutionError(file));
				break;
			case MEDIA_ERRORS.VIDEO_DURATION_ERROR:
				Notifications.error("Video Too Long", this.createVideoDurationError(file));
				break;
			case MEDIA_ERRORS.VIDEO_USAGE_ERROR:
				Notifications.error("Video Library Full", this.createVideoUsageError(file));
				break;
			case MEDIA_ERRORS.BANNER_WRONG_SIZE_ERROR:
				Notifications.error("Banner Wrong Size", this.createBannerSizeError(file));
				break;
			case MEDIA_ERRORS.UNSUPPORTED_TYPE_ERROR:
				Notifications.error("Unsupported Type", this.createUnsupportedTypeError(file));
				break;
			case MEDIA_ERRORS.FILE_READER_ERROR:
				Notifications.error("Error reading file", this.createFileReaderError(file, error));
			default:
				Notifications.error("Error uploading file", error);
		}
	}

	// region Generators for error message text
	createFileReaderError(file: File, error: any) {
		return `${file.name} could not be processed. Please check that
			your file is not corrupted and try again. ${error}`;
	}

	createLibraryUsageError(file: File) {
		return (
			file.name + " could not be uploaded.  You are over your maximum " +
			"library size of " + Utils.getHumanReadableBytesize(this.company.mediaLimit.librarySize)
		);
	}

	createImageSizeError(file: File) {
		return (
			file.name + " could not be uploaded.  Images must be under " +
			Utils.getHumanReadableBytesize(this.company.mediaLimit.imageSize)
		);
	}

	createImageUsageError(file: File) {
		return (
			file.name + " could not be uploaded.  You are over your monthly " +
			"image upload limit of " + Utils.getHumanReadableBytesize(this.company.mediaLimit.monthlyImageSize)
		);
	}

	createVideoSizeError(file: File) {
		return (
			file.name + " could not be uploaded.  Videos must be under " +
			Utils.getHumanReadableBytesize(this.company.mediaLimit.videoSize)
		);
	}

	createVideoResolutionError(file: File) {
		return (
			file.name + " could not be uploaded.  Only SD and HD videos " +
			"(up to 1920 x 1080 or 1080 x 1920) may be added to your Media Library."
		);
	}

	createVideoDurationError(file: File) {
		return (
			file.name + " could not be uploaded.  Videos must be under " +
			Utils.getMinutesFromSeconds(this.company.mediaLimit.videoLength) + " minutes long"
		);
	}

	createVideoUsageError(file: File) {
		return (
			file.name + " could not be uploaded.  You are over your monthly " +
			"video upload limit of " + Utils.getMinutesFromSeconds(this.company.mediaLimit.monthlyVideoLength) +  " minutes"
		);
	}

	createBannerSizeError(file: File) {
		return (
			file.name + " could not be uploaded.  Banners must be 1080 pixels wide " +
			"and under 200 pixels tall."
		);
	}

	createUnsupportedTypeError(file: File) {
		return (
			file.name + " could not be uploaded.  Currently Clinton Connect supports " + this.getSupportedFileTypes()
		);
	}

	getSupportedFileTypes() {
		const allowedTypes = this.allowedFileTypes;
		let supportedString = "";
		if (allowedTypes.length > 1) {
			supportedString = " and ";
		}
		if (allowedTypes.includes("Image")) {
			supportedString = this.createImageTypeString() + supportedString;
		}
		if (allowedTypes.includes("Video")) {
			supportedString += this.createVideoTypeString();
		}

		supportedString += ".";
		return supportedString;
	}

	createImageTypeString() {
		return "JPEG, PNG, and GIF images";
	}

	createVideoTypeString() {
		return "all video format types with the exception of Apple Intermediate Video, " +
			"HDV 720p60, Go2Meeting3(G2M3), and Avid Meridien Uncompressed"
	}
	// endregion
}
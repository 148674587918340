import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { CustomCSS, IPlaylist, PlaylistAd } from "@connect/Interfaces";
import { Icon, Loader } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import DraggablePlaylistItem from "Components/Playlists/DraggablePlaylistItem";
import { getPlaylistAsync, updatePlaylistAsync } from "Data/Actions/Playlists";
import { getActivePlaylist } from "Data/Selectors/Playlists";
import { getActiveUuid } from "Data/Selectors/UI";
import { cloneDeep } from "lodash";
import HeaderText from "Components/Global/HeaderText";
import PresenceUsers from "Components/Global/PresenceUsers";
import { toggleFeature } from "@connect/Features";

interface PlaylistEditorProps {
	activeUuid: string;
	getPlaylist: (playlist: IPlaylist) => void;
	onClose: () => void;
	playlist: IPlaylist;
	updatePlaylist: (playlist: IPlaylist) => void;
}

const mapDispatchToProps = (dispatch) => ({
	getPlaylist: (playlist: IPlaylist) => dispatch(getPlaylistAsync(playlist)),
	onClose: () => dispatch(push("/playlists")),
	updatePlaylist: (playlist: IPlaylist) => dispatch(updatePlaylistAsync(playlist))
});

const mapStateToProps = (state, ownProps) => {
	const activeUuid = getActiveUuid(state, "playlists");

	return {
		activeUuid,
		playlist: getActivePlaylist(state)
	};
};

export class PlaylistEditor extends React.Component<PlaylistEditorProps, any> {
	constructor(props: PlaylistEditorProps) {
		super(props);

		this.styles = {
			container: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "100vh"
			},
			containerOuter: {
				position: "relative"
			},
			header: {
				flex: "0 0 auto"
			},
			list: {
				flex: "1 1 auto",
				width: "85%",
				overflowY: "auto",
				position: "relative"
			},
			footer: {
				width: "85%",
				flex: "0 0 auto",
				textAlign: "center",
				background: Colors.white
			},
			clear: {
				textAlign: "right"
			},
			closeButton: {
				cursor: "pointer",
				margin: 10,
				position: "absolute",
				right: 0,
				top: 0
			},
			loader: {
				marginTop: 100,
				textAlign: "center"
			},
			presenceWrapper: {
				position: "absolute",
				top: 5,
				left: 10
			}
		}

		this.copyAd = this.copyAd.bind(this);
		this.deleteAd = this.deleteAd.bind(this);
		this.moveAd = this.moveAd.bind(this);
	}

	styles: CustomCSS;

	componentDidMount() {
		const { getPlaylist, playlist } = this.props;

		if (playlist) {
			getPlaylist(playlist);
		}
	}

	render() {
		const { playlist } = this.props;
		const { container, containerOuter, header, list, loader } = this.styles;

		if (!playlist) {
			return (
				<div style={ loader }>
					<Loader />
				</div>
			);
		}

		const { ads, name } = playlist;

		return (
			<div style={containerOuter}>
				{this.renderCloseButton()}
				{ this.renderPresenceUsers() }
				<div style={container}>
					<div style={header}>
						<HeaderText truncateLength={ 36 }>
							{ name }
						</HeaderText>
					</div>
					<div style={list}>
						{this.renderPlaylistItems()}
						{this.renderDraggablePlaylistItem(null, ads.length)}
					</div>
				</div>
			</div>
		);
	}

	renderPresenceUsers() {
		const { presenceWrapper } = this.styles;
		const { activeUuid } = this.props;
		return toggleFeature("notifications",
			(
				<div style={ presenceWrapper }>
					<PresenceUsers
						type="playlist"
						uuid={ activeUuid }/>
				</div>
			),
			null
		);
	}

	renderCloseButton() {
		return (
			<Icon
				style={this.styles.closeButton}
				size="smaller"
				name="times"
				onClick={this.props.onClose} />
		);
	}

	renderDraggablePlaylistItem(ad: PlaylistAd | null, index: number) {
		return (
			<DraggablePlaylistItem
				ad={ad}
				copyAd={this.copyAd}
				deleteAd={this.deleteAd}
				index={index}
				moveAd={this.moveAd}
			/>
		);
	}

	renderPlaylistItems() {
		const { ads } = this.props.playlist;

		if (!ads) {
			return null;
		}

		return ads.map((ad, i) => {
			return (
				// eslint-disable-next-line
				<div key={i}>
					{ad ? this.renderDraggablePlaylistItem(ad, i) : null}
				</div>
			);
		});
	}

	copyAd(ad: PlaylistAd, index: number) {
		const { playlist, updatePlaylist } = this.props;

		let newPlaylist = cloneDeep(playlist);

		newPlaylist.ads.splice(index, 0, ad);

		updatePlaylist(newPlaylist);
	}

	deleteAd(index: number) {
		const { playlist, updatePlaylist } = this.props;

		let newPlaylist = cloneDeep(playlist);

		newPlaylist.ads.splice(index, 1);

		updatePlaylist(newPlaylist);
	}

	moveAd(ad: PlaylistAd, beforeIndex: number, index: number) {
		const { playlist, updatePlaylist } = this.props;

		let newPlaylist = cloneDeep(playlist);

		newPlaylist.ads.splice(index, 1);
		newPlaylist.ads.splice(beforeIndex, 0, ad);

		updatePlaylist(newPlaylist);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistEditor);
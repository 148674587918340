import * as React from "react";
import {
	DropTarget,
	DropTargetCollector,
	DropTargetMonitor,
	DropTargetSpec
} from "react-dnd";
import { connect } from "react-redux";

import {  ActionType, CustomCSS, TriggerType } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";
import { addActionAsync } from "Data/Actions/ActionsAsync";
import { actionIdentifier } from "Data/Objects/Actions";
import { setSelectedActionComponent } from "Data/Actions/UI/ActionSetBuilder";

const { dropBackground, lightBlue, primaryBlue, white } = Colors;

interface NewActionDropzoneProps {
	type: TriggerType;
	triggerIndex: number;
	connectDropTarget: Function;
	currentlyDragging?: boolean;
	isOver?: boolean;
	canDrop?: boolean;
	hoverItem?: NewActionDropzoneDropTarget;
	addAction: (triggerIndex: number, actionType: ActionType) => void;
	setSelectedAction: (triggerIndex: number, actionType: ActionType) => void;
}

export interface NewActionDropzoneCollectedProps {
	connectDropTarget?: Function;
	currentlyDragging?: boolean;
	isOver?: boolean;
	canDrop?: boolean;
	hoverItem?: NewActionDropzoneDropTarget;
}

interface NewActionDropzoneDropTarget {
	action?: any;
	data?: any;
	index?: number,
	isReorder?: boolean,
	type?: any;
}

const dropTarget: DropTargetSpec<NewActionDropzoneProps> = {
	drop(props: NewActionDropzoneProps, monitor: DropTargetMonitor, component: NewActionDropzone) {
		const { actionType } = monitor.getItem();
		props.addAction(props.triggerIndex, actionType);
		props.setSelectedAction(props.triggerIndex, actionType);
	}
}

const dropCollector: DropTargetCollector<NewActionDropzoneCollectedProps> = (dragConnect, monitor) => ({
	connectDropTarget: dragConnect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop(),
	hoverItem: monitor.getItem()
});

const ConnectedDropTarget = DropTarget<any>([ actionIdentifier ], dropTarget, dropCollector);

const mapDispatchToProps = (dispatch) => ({
	addAction: (triggerIndex: number, actionType: ActionType) => dispatch(addActionAsync(triggerIndex, actionType)),
	setSelectedAction: (triggerIndex: number, actionType: ActionType) =>
		dispatch(setSelectedActionComponent(triggerIndex, actionType))
});

export class NewActionDropzone extends React.PureComponent<NewActionDropzoneProps> {
	constructor(props: NewActionDropzoneProps) {
		super(props);

		this.baseStyle = {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			justifySelf: "center"
		}

		this.styles = {
			cardStyle: {
				display: "flex",
				width: 102.66,
				height: 100.39,
				minWidth: 102.66,
				position: "relative",
				border: `1px dashed ${ primaryBlue }`,
				color: lightBlue,
				textAlign: "center",
				margin: "30px 4px auto 10px",
				padding: 5
			},
			title: {
				fontSize: "0.96em"
			},
			main: {
				userSelect: "none",
				position: "relative",
				...this.baseStyle
			}
		}

		this.getCardStyle = this.getCardStyle.bind(this);
	}

	baseStyle: CustomCSS;
	styles: CustomCSS;

	render() {
		const { main } = this.styles;
		const { connectDropTarget } = this.props;

		return connectDropTarget(
			<div style={ this.getCardStyle() }>
				<div style={ main }>
					{ this.renderTitle() }
				</div>
			</div>
		);
	}

	renderTitle() {
		return (
			<span style={ this.styles.title }>
				Drag & Drop Action Here
			</span>
		);
	}

	getCardStyle() {
		return {
			...this.styles.cardStyle,
			background: this.props.isOver ? dropBackground : white
		}
	}
}

export default connect(null, mapDispatchToProps)(
	ConnectedDropTarget(NewActionDropzone)
);
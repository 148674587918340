import { Deployment, DeploymentPageLocationState, DeploymentType, DeploySteps,
	IAd, IDeploymentSchedule, IPlaylist } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Colors } from "Components/Global/Constants";
import { createDeploymentAsync, fetchDeploymentTargets, updateDeploymentAsync } from "Data/Actions/DeploymentsAsync";
import { hideDeploymentApprovalModal, showDeploymentApprovalModal } from "Data/Actions/UI/Modals";
import { pushWithoutRender } from "Data/Actions/Navigation";
import { errorNotification, successNotification } from "Data/Actions/Notifications";
import { setActiveSelection } from "Data/Actions/UI";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveDeployment, getDeploymentScheduleDates } from "Data/Selectors/DeploymentWizard";
import { cloneDeep } from "lodash";

const { builderColorsArray, scheduleBuilder: { blue } } = Colors;

/*
 * Actions related to the Deployment Wizard
 */
const {
	DELETE_DEPLOYMENT_SCHEDULE_ITEM,
	MOVE_DEPLOYMENT_SCHEDULE_ITEM,
	RESIZE_DEPLOYMENT_SCHEDULE_ITEM,
	SET_ACTIVE_DEPLOYMENT,
	SET_ACTIVE_DEPLOYMENT_SCHEDULE,
	SET_DEPLOYMENT_SCHEDULE_DATES,
	SET_DEPLOYMENT_SCHEDULED,
	SET_DEPLOYMENT_STEP
} = ACTION_TYPES.UI.DeploymentWizard;

export function deleteScheduleItem(item: IDeploymentSchedule) {
	return {
		type: DELETE_DEPLOYMENT_SCHEDULE_ITEM.type,
		args: {
			item
		}
	};
}

export function moveScheduleItem(dragIndex: number, hoverIndex: number) {
	return {
		type: MOVE_DEPLOYMENT_SCHEDULE_ITEM.type,
		args: {
			dragIndex,
			hoverIndex
		}
	};
}

export function resizeScheduleItem(item: IDeploymentSchedule, height: number, containerHeight: number) {
	return {
		type: RESIZE_DEPLOYMENT_SCHEDULE_ITEM.type,
		args: {
			item,
			height,
			containerHeight
		}
	};
}

export function setActiveDeployment(deployment: Deployment) {
	return {
		type: SET_ACTIVE_DEPLOYMENT.type,
		args: {
			deployment
		}
	};
}

export function setActiveDeploymentSchedule(schedule: IDeploymentSchedule[], itemMoved?: boolean) {
	return {
		type: SET_ACTIVE_DEPLOYMENT_SCHEDULE.type,
		args: {
			schedule,
			itemMoved
		}
	};
}

export function setDeploymentScheduleDates(startDate: string, endDate?: string) {
	return {
		type: SET_DEPLOYMENT_SCHEDULE_DATES.type,
		args: {
			scheduleDates: [ startDate, endDate || null ]
		}
	}
}

export function setDeploymentScheduled(scheduled: boolean) {
	return {
		type: SET_DEPLOYMENT_SCHEDULED.type,
		args: {
			scheduled
		}
	}
}

export function setDeploymentStep(step?: DeploySteps) {
	return {
		type: SET_DEPLOYMENT_STEP.type,
		args: {
			step
		}
	}
}

export function startScheduleWizard() {
	return (dispatch) => {
		dispatch(setActiveDeployment( { type: DeploymentType.SCHEDULE } as Deployment ));
		dispatch(setDeploymentStep(DeploySteps.CREATE_NEW));
		dispatch(setDeploymentScheduled(false));
	}
}

export function startEventWizard() {
	return (dispatch) => {
		dispatch(setActiveDeployment( { type: DeploymentType.EVENT } as Deployment ));
		dispatch(setDeploymentStep(DeploySteps.CREATE_NEW));
		dispatch(setDeploymentScheduled(false));
	}
}

export function cancelWizard() {
	return (dispatch) => {
		dispatch(setActiveDeployment({} as Deployment));
		dispatch(setDeploymentStep());
		dispatch(setDeploymentScheduleDates("", ""));
		dispatch(setActiveSelection("deployDevices", []));
		dispatch(setActiveSelection("deployDevices_groups", []));
	}
}

export function createDeploymentFromSchedule(deployment: DeploymentPageLocationState) {
	return (dispatch) => {
		const { deploymentType, schedule, scheduleType } = deployment;
		const { ads, name, uuid } = schedule as IPlaylist;
		const { duration, thumbnail } = schedule as IAd;
		const timestamp = Utils.getDefaultTimestamp();
		const remainingChars = 190 - timestamp.length;
		const newDeployment = {
			name: `${ name.substring(0, remainingChars) } ${ timestamp }`,
			devices: [],
			deviceGroups: [],
			submittedTo: [],
			schedule: [
				{
					ads: ads || [ { name, uuid, duration, thumbnail } ],
					color: blue,
					duration: 1440,
					name: name,
					startTime: 0,
					type: scheduleType,
					uuid: uuid
				}
			],
			type: deploymentType
		};

		return dispatch(createDeploymentAsync(newDeployment)).then((result: Deployment) => {
			dispatch(setActiveDeployment(result));
			dispatch(setDeploymentStep(DeploySteps.DEVICES));
			dispatch(setActiveSelection("deployDevices", []));
			dispatch(setActiveSelection("deployDevices_groups", []));
			dispatch(pushWithoutRender(`/deploy/${result.uuid}`));
		});
	}
}

export function completeDeploymentWizard() {
	return (dispatch, getState) => {
		const state = getState();
		const deployment = getActiveDeployment(state);
		const [ startDate, endDate ] = getDeploymentScheduleDates(state);
		const { name } = deployment;
		const hasDeployPermission = hasPermission(PERMISSIONS.DEPLOYMENTS_DEPLOY);
		const completionCallback = hasDeployPermission ? finishDeployment : showApprovalModal;

		const updatedDeployment = Object.assign({}, deployment,
			{ startDate, endDate, state: hasDeployPermission ? "deployed" : "draft" });

		return dispatch(updateDeploymentAsync(updatedDeployment))
			.then(() => dispatch(completionCallback()))
			.catch(() => dispatch(errorNotification(`There was an error with Deployment ${name}`)));
	};
}

export function finishDeployment() {
	return (dispatch, getState) => {
		const state = getState();
		const deployment = getActiveDeployment(state);
		const [ tempStartDate ] = getDeploymentScheduleDates(state);
		const { name, startDate } = deployment;
		const notificationMessage = `Deployment ${name} has been scheduled for
			${Utils.getHumanReadableDate(startDate || tempStartDate)}.`;

		dispatch(successNotification(notificationMessage));
		dispatch(pushWithoutRender("/deploy"));
		dispatch(cancelWizard());
	};
}

export function showApprovalModal() {
	return (dispatch) => {
		dispatch(fetchDeploymentTargets())
			.then(() => dispatch(showDeploymentApprovalModal()));
	};
}

export function submitDeploymentApproval(submittedTo: string[]) {
	return (dispatch, getState) => {
		const deployment = getActiveDeployment(getState());
		const updatedDeployment = Object.assign({}, deployment, {
			submittedTo
		});

		return dispatch(updateDeploymentAsync(updatedDeployment))
			.then((result: any) => {
				dispatch(successNotification("Deployment submitted for approval"));
				dispatch(hideDeploymentApprovalModal());
				dispatch(pushWithoutRender("/deploy"));
				dispatch(cancelWizard());
			}, (error) => {
				dispatch(errorNotification("Error submitting deployment for approval.", error));
			});
	};
}

export function addScheduleItem(item: any, y: number, containerHeight: number, hoverIndex?: number) {
	return (dispatch, getState) => {
		const state = getState();
		const { schedule } = getActiveDeployment(state);
		const hoverItem = schedule[hoverIndex || 0];
		const minutesFromBottom = Utils.getDurationFromHeight(containerHeight - y, containerHeight);
		const snapMinutes = 15;
		let startTime = Utils.getStartMinutesFromPx(y, containerHeight);
		startTime = Math.round(startTime / snapMinutes) * snapMinutes;

		// get the item type and set the current and newItem(s)
		const { playlist, ad } = item;
		let type;

		if (playlist) {
			type = "playlist"
		} else if (ad) {
			type = "ad";
		}

		const currentItem = type && item[type];
		const newItem = cloneDeep(currentItem);

		// filter out events without a type and items already on the schedule so as not to create duplicates
		if (type && !currentItem.duration) {
			// add color and type
			newItem.color = builderColorsArray[schedule.length % builderColorsArray.length];
			newItem.type = type;

			// if this is the first item, set the start time to 0 and duration to full day
			if (schedule.length === 0) {
				newItem.startTime = 0;
				newItem.duration = Utils.dayMinutes;

			// otherwise do some calculation to get the start height (in px) as long as the drop is
			// > 60 minutes from the bottom of the container and the next item, as well as > 60 minutes
			// from the item above it, schedule the item
			} else if (
				minutesFromBottom > 60
				&& hoverItem
				&& startTime + 60 < hoverItem.startTime + hoverItem.duration
				&& startTime > hoverItem.startTime + 60
			) {
				newItem.startTime = startTime;

			// there is still a condition where it is difficult to drop a new playlist on a space of 2-3hrs duration
			} else if (hoverItem.duration >= 120 && hoverItem.duration < 180) {
				newItem.startTime = hoverItem.startTime + 60;

			// if we can't drop anything, inform the user that there is not enough space
			} else {
				dispatch(errorNotification("Not enough space",
					"You must have at least one hour available for each item in your schedule."));

				return null;
			}

			return dispatch(setActiveDeploymentSchedule([ ...schedule, newItem ]));
		}
	};
}
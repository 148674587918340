import { POST } from "Api/V1/CRUD";

export function sendPushNotification(channels: string | string[], event: string, data: any) {
	return new Promise<boolean>((resolve, reject) => {
		POST("/notification", {
			channels,
			event,
			data
		}, true)
			.then((response) => {
				resolve(true);
			}, reject);
	});
}

import { ColumnProps } from "antd/lib/table";
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { Button, Input, Modal, Select, Table } from "Components/Global/Common";
import { CsvHandler, CsvResults } from "Components/Global/CsvHandler";
import { DropZone } from "Components/Global/DropZone";
import {
	checkInDevicesAsync, createDeviceAsync, releaseDevicesAsync, setDevices, tryFetchDevicesAsync
} from "Data/Actions/Devices";
import { setCheckinDeviceModal } from "Data/Actions/UI/Modals";
import { setActiveSelection } from "Data/Actions/UI";
import { Company } from "Data/Objects/Company";
import { Device } from "Data/Objects/Devices";
import { getAllDevices, getDeviceModelNames, getUnassociatedDevices } from "Data/Selectors/Devices";
import { getCheckinDeviceModalVisibility } from "Data/Selectors/UI";
import { getActiveSelection, makeCanFetchDevices, makeHaveAllDevices, makeLastDevicesPage } from "Data/Selectors/UI";

const { Option } = Select;

const mapStateToProps = (state) => {
	const lastPageFetched = makeLastDevicesPage();
	const haveAllDevices = makeHaveAllDevices();
	const canFetchDevices = makeCanFetchDevices();
	return {
		activeCompany: state.Company.activeCompany,
		canFetch: canFetchDevices(state),
		devices: getAllDevices(state),
		unassociatedDevices: getUnassociatedDevices(state),
		haveAllDevices: haveAllDevices(state),
		lastPageFetched: lastPageFetched(state),
		selectedDevices: getActiveSelection(state, "devices"),
		models: getDeviceModelNames(state),
		visible: getCheckinDeviceModalVisibility(state)
	}
};

const mapDispatchToProps = (dispatch) => ({
	getDevices: (companyUuid: string) => dispatch(tryFetchDevicesAsync(companyUuid, true)),
	selectDevices: (deviceUuids: string[]) => dispatch(setActiveSelection("devices", deviceUuids)),
	createDevice: (device: Partial<Device>) => dispatch(createDeviceAsync(device)),
	removeDevices: (devices: Device[]) => dispatch(releaseDevicesAsync(devices, "removeDevice")),
	releaseDevices: (devices: Device[]) => dispatch(releaseDevicesAsync(devices, "releaseDevice")),
	addDevices: (devices: Device[]) => dispatch(setDevices(devices, false, "")),
	checkInDevicesAsync: (devices: Device[]) => dispatch(checkInDevicesAsync(devices)),
	hideModal: () => dispatch(setCheckinDeviceModal(false))
});

interface AdminDevicesPageProps {
	activeCompany: Company;
	canFetch: boolean;
	devices: Device[];
	unassociatedDevices: Device[];
	selectedDevices: string[];
	haveAllDevices: boolean;
	lastPageFetched: number;
	models: string[];
	getDevices: (companyUuid: string) => void;
	selectDevices: (deviceUuids: string[]) => void;
	createDevice: (device: Partial<Device>) => void;
	removeDevices: (devices: Device[]) => void;
	releaseDevices: (devices: Device[]) => void;
	addDevices: (devices: Device[]) => void;
	checkInDevicesAsync: (devices: Partial<Device>[]) => void;
	hideModal: () => void;
	visible: boolean;
}

/* eslint-disable camelcase */
interface AdminNewDeviceRecord {
	serial?: string;
	cec_serial?: string;
	model?: string;
	name?: string;
	deviceId?: string;
}
/* eslint-enable camelcase */

interface AdminDevicesPageState {
	newDevice: AdminNewDeviceRecord;
	bulkCheckIn: { devices: Partial<Device>[], resultVisibility: boolean };
}

export class AdminDevicesPage extends React.Component<AdminDevicesPageProps, AdminDevicesPageState> {
	constructor(props: AdminDevicesPageProps) {
		super(props);

		this.state = {
			newDevice: {
				serial: "",
				cec_serial: "",
				model: ""
			},
			bulkCheckIn: {
				devices: [],
				resultVisibility: false
			}
		}

		this.hideModal = this.hideModal.bind(this);
		this.handleCreateDevice = this.handleCreateDevice.bind(this);
		this.updateNewDevice = this.updateNewDevice.bind(this);
		this.handleBulkCheckIn = this.handleBulkCheckIn.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.openDropzone = this.openDropzone.bind(this);
		this.selectModel = this.selectModel.bind(this);
		this.updateDeviceCecSerial = this.updateDeviceCecSerial.bind(this);
		this.updateDeviceSerial = this.updateDeviceSerial.bind(this);
		this.setDropZoneRef = this.setDropZoneRef.bind(this);
		this.toggleBulkResultVisibility = this.toggleBulkResultVisibility.bind(this);
	}

	unassociated: string;
	perPage: number;
	dropzone: DropZone;
	bulkCheckInForm: HTMLFormElement;
	processedPercent: number;

	styles = {
		cancel: {
			margin: "15px 0px 10px"
		},
		checkInInputs: {
			display: "block",
			width: "100%"
		},
		dropzone: {
			marginBottom: 8,
			flexDirection: "column",
			width: 250,
			padding: 6
		},
		link: {
			display: "inline",
			textDecoration: "none"
		}
	}

	componentWillMount() {
		this.props.getDevices(this.props.activeCompany.uuid);
		this.props.selectDevices([]);
	}

	render() {
		const { checkInInputs, dropzone, link, cancel } = this.styles;
		const { newDevice } = this.state;

		return (
			<React.Fragment>
				<Modal
					modalKey="checkinDevice"
					visible={this.props.visible}
					width={300}
					title={{ text: "Check-In Device" }}
					onCancel={this.hideModal}
				>
					<div>
						<Input
							id="deviceId"
							style={checkInInputs}
							placeholder="Device ID"
							value={newDevice.serial || ""}
							saveCallback={ this.updateDeviceSerial } />
						<Input
							id="deviceSerial"
							style={checkInInputs}
							placeholder="Serial"
							value={newDevice.cec_serial || ""}
							saveCallback={ this.updateDeviceCecSerial } />
						<Select
							style={checkInInputs}
							placeholder="Select model"
							onChange={ this.selectModel }>
							{this.props.models.map((m, i) => {
								return (
									<Option key={m} value={m}>{m}</Option>
								);
							})}
						</Select>
						<p style={{ height: 26, textAlign: "center" }}>
							- OR -
						</p>
						<DropZone
							style={ dropzone }
							ref={ this.setDropZoneRef }
							prompt={""}
							onDrop={ this.handleDrop } >
								Drop a CSV here for <a onClick={ this.openDropzone }>&nbsp;Bulk Check In</a><br />
							<a style={ link } href="/csv/DevicesBulkUploadTemplate.csv" download>Download Template</a>
						</DropZone>
					</div>
					<Button
						key={0}
						fluid
						style={ cancel }
						onClick={ this.hideModal }
					>
							Cancel
					</Button>
					<Button
						type="primary"
						key={1}
						fluid
						onClick={ this.handleCreateDevice }
					>
							Check-In Device
					</Button>
				</Modal>
				{ this.renderBulkCheckInModal() }
			</React.Fragment>
		);
	}

	renderBulkCheckInModal() {
		const { resultVisibility } = this.state.bulkCheckIn;

		if (!resultVisibility) {
			return null;
		}

		return (
			<Modal
				modalKey="bulkCheckinModal"
				visible={resultVisibility}
				title={{ text: "Confirm Import Results" }}
				onCancel={this.toggleBulkResultVisibility}
			>
				<div>
					<div>
						The following devices will be imported:
						{this.renderBulkImportResultsUI()}
					</div>
				</div>
				<Button
					key={0}
					style={{marginRight: 10}}
					onClick={this.toggleBulkResultVisibility}
				>
					Cancel
				</Button>
				<Button
					key={1}
					type="primary"
					onClick={this.handleBulkCheckIn}
				>
					Import
				</Button>
			</Modal>
		);
	}

	renderBulkImportResultsUI() {
		const tableColumns: ColumnProps<any>[] = [ {
			key: "serial",
			dataIndex: "serial",
			title: "Serial"
		}, {
			key: "model",
			dataIndex: "model",
			title: "Model"
		}, {
			key: "name",
			dataIndex: "name",
			title: "Name"
		} ];

		const tableData = this.state.bulkCheckIn.devices.map((d) => {
			return {
				serial: d[0],
				model: d[1],
				name: d[2]
			}
		});

		return (
			<Table
				columns={tableColumns}
				dataSource={tableData}
				pagination={{
					pageSize: 10,
					total: this.state.bulkCheckIn.devices.length
				}}
				rowKey={ this.stringifyData } />
		);
	}

	hideModal() {
		this.props.hideModal();
	}

	updateNewDevice(value: string, key: string) {
		this.setState((prevState) => {
			return update(prevState, {
				newDevice: {
					[key]: { $set: value }
				}
			});
		});
	}

	updateDeviceSerial(value: string) {
		this.updateNewDevice(value, "serial");
	}

	updateDeviceCecSerial(value: string) {
		this.updateNewDevice(value, "cec_serial");
	}

	selectModel(v: string) {
		this.setState((prevState) => {
			return update(prevState, {
				newDevice: {
					model: { $set: v }
				}
			});
		});
	}

	openDropzone() {
		this.dropzone.open();
	}

	handleDrop(files: File[]) {
		const handler = new CsvHandler();
		handler.requiredHeaders = [ "Serial", "Model", "Name" ];
		handler.onSuccess = this.handleSuccess;
		handler.handleFiles(files);
	}

	handleSuccess(results: CsvResults) {
		this.setState((prevState) => {
			return update(prevState, {
				bulkCheckIn: {
					devices: { $set: results.data },
					resultVisibility: { $set: true }
				}
			});
		}, () => this.hideModal());
	}

	handleCreateDevice() {
		this.props.createDevice(this.state.newDevice);
		this.hideModal();
	}

	setDropZoneRef(node: DropZone) {
		this.dropzone = node;
	}

	handleBulkCheckIn() {
		this.props.checkInDevicesAsync(this.state.bulkCheckIn.devices);
		this.toggleBulkResultVisibility();
	}

	toggleBulkResultVisibility() {
		const { resultVisibility } = this.state.bulkCheckIn;

		this.setState((prevState) => {
			return update(prevState, {
				bulkCheckIn: {
					resultVisibility: { $set: !resultVisibility }
				}
			});
		}, () => {
			// if this is hidden now, reset the form
			if (!resultVisibility && this.bulkCheckInForm) {
				this.bulkCheckInForm.reset();
			}
		});
	}

	stringifyData(data: AdminNewDeviceRecord) {
		return JSON.stringify(data);
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDevicesPage);
import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, Deployment, IUser } from "@connect/Interfaces";
import { Button, Select } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { Modal } from "Components/Global/Modal";
import { hideDeploymentApprovalModal } from "Data/Actions/UI/Modals";
import { submitDeploymentApproval } from "Data/Actions/UI/DeploymentWizard";
import { AppState } from "Data/Objects/AppState";
import { getDeploymentApprovers } from "Data/Selectors/Deployments";
import { getActiveDeployment } from "Data/Selectors/DeploymentWizard";
import { getDeploymentApprovalModalVisibility } from "Data/Selectors/UI";

const { Option } = Select;

const mapDispatchToProps = (dispatch, ownProps: DeploymentApprovalModalProps) => ({
	closeModal: () => dispatch(hideDeploymentApprovalModal()),
	submitDeployment: (approvers: string[]) => dispatch(submitDeploymentApproval(approvers))
});

const mapStateToProps = (state: AppState) => ({
	deployment: getActiveDeployment(state),
	deploymentApprovers: getDeploymentApprovers(state),
	isVisible: getDeploymentApprovalModalVisibility(state)
});

interface DeploymentApprovalModalProps {
	closeModal: () => void;
	deployment: Deployment;
	deploymentApprovers: Partial<IUser>[];
	isVisible: boolean;
	submitDeployment: (approver: string[]) => void;
}

interface DeploymentApprovalModalState {
	approvers: string[];
	maskClosable: boolean;
}

class DeploymentApprovalModal extends React.Component<DeploymentApprovalModalProps, DeploymentApprovalModalState> {
	constructor(props: DeploymentApprovalModalProps) {
		super(props);

		this.state = {
			approvers: [],
			maskClosable: true
		}

		this.styles = {
			container: {
				paddingBottom: 24
			},
			dropdown: {
				width: "100%",
				marginBottom: 20
			},
			submitButton: {
				float: "right",
				color: Colors.white
			}
		};

		this.approverChanged = this.approverChanged.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleDropdownVisibleChange = this.handleDropdownVisibleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.renderModalContent = this.renderModalContent.bind(this);
	}

	dropdownTimeout: number;
	styles: CustomCSS;

	componentWillUnmount() {
		window.clearTimeout(this.dropdownTimeout);
	}

	render() {
		const { deployment, isVisible } = this.props;

		return (
			<Modal
				modalKey="submit-deployment-for-approval-modal"
				onCancel={ this.handleCloseModal }
				title={{ text: "Submit Deployment for Approval" }}
				visible={ !!deployment && isVisible }
				width={ 300 }
			>
				{ this.renderModalContent() }
			</Modal>
		);
	}

	renderModalContent() {
		const { deployment, deploymentApprovers } = this.props;
		const { approvers } = this.state;
		const { container, dropdown, submitButton } = this.styles;
		let submittedTo: string[] = [];

		if (deployment && deployment.submittedTo && deployment.submittedTo.length) {
			({submittedTo} = deployment);
		}

		return (
			<div style={container}>
				<Select
					mode="multiple"
					style={dropdown}
					onChange={this.approverChanged}
					defaultValue={ submittedTo }
					onDropdownVisibleChange={ this.handleDropdownVisibleChange }
				>
					{ deploymentApprovers.map(this.renderOption) }
				</Select>
				<Button
					disabled={ !approvers || approvers.length === 0 }
					color={ Colors.primaryGreen }
					style={ submitButton }
					onClick={ this.onSubmit }>
					Submit
				</Button>
			</div>
		);
	}

	renderOption(value: IUser) {
		const { uuid, name } = value;

		return (
			<Option key={ uuid } value={ uuid }>{ name }</Option>
		)
	}

	handleCloseModal() {
		if (this.state.maskClosable) {
			this.props.closeModal();
		}
	}

	handleDropdownVisibleChange(visible: boolean) {
		this.dropdownTimeout = window.setTimeout(() => {
			this.setState({ maskClosable: !visible });
		}, 100);
	}

	approverChanged(value: string[], option: any) {
		this.setState((prevState) => update(prevState, {
			$set: {
				approvers: value
			}
		}));
	}

	onSubmit() {
		const { submitDeployment } = this.props;
		const { approvers } = this.state;

		submitDeployment(approvers);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentApprovalModal);

import { IMedia, IMediaUpload, MediaType, NameUuid } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { cloneDeep } from "lodash";

export class Media implements IMedia {
	adsCount?: number;
	bytesize: number;
	company: string;
	createdAt: string;
	createdBy?: NameUuid;
	duration: number;
	favorite: boolean;
	height: number;
	krakenId: string;
	mediaType: MediaType;
	md5: string;
	name: string;
	originator?: string; // used in the ad builder to keep track of which component an upload belongs to
	state: "pending" | "new" | "ready" | "exception";
	tags: string[];
	thumbs: string; // thumbs was created as a string[] but is now just a string
	updatedAt: string;
	updatedBy?: NameUuid;
	uri: string;
	usage?: {
		banner: number;
		duration: number;
		image: number;
		library: number;
		video: number;
	}

	uuid: string;
	width: number;

	static clone(media: Media) {
		return cloneDeep(media);
	}

	static getTypeLabel(media: Media) {
		return Utils.properCase(media.mediaType);
	}
}

export function isImage(m: Media) {
	const upload = m as IMediaUpload;
	return [ "image", "banner" ].includes(m.mediaType) || upload.file && upload.file.type.indexOf("image") > -1;
}

export function isVideo(m: Media) {
	const upload = m as IMediaUpload;
	return m.mediaType === "video" || upload.file && upload.file.type.indexOf("video") > -1;
}
import { Checkbox, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import * as React from "react";
import { connect } from "react-redux";

import { CloudProvider, CreateCompanyData, CustomCSS } from "@connect/Interfaces";
import { Button, Input, Select } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { createCompanyAsync } from "Data/Actions/Company";
import { setCompanyCreateModalVisibility } from "Data/Actions/UI/Modals";
import { getCompanyCreateModalVisibility } from "Data/Selectors/UI";

const { lightGray, primaryGreen, white } = Colors;
const { Option } = Select;

const mapDispatchToProps = (dispatch) => ({
	closeCompanyCreateForm: () => dispatch(setCompanyCreateModalVisibility(false)),
	submitRequest: (state: CreateCompanyData) => dispatch(createCompanyAsync(state))
});

const mapStateToProps = (state) => ({
	visible: getCompanyCreateModalVisibility(state)
});

interface ICreateModalProps {
	closeCompanyCreateForm: () => void;
	submitRequest: (state: CreateCompanyData) => void;
	visible: boolean;
}

interface ICreateModalState {
	canSubmit: boolean;
	newCompany: CreateCompanyData;
}

export class UnwrappedCompanyCreateModal extends React.Component<ICreateModalProps, ICreateModalState> {
	constructor(props: ICreateModalProps) {
		super(props);

		this.styles = {
			cancelButton: {
				color: lightGray
			},
			center: {
				textAlign: "center"
			},
			section: {
				marginBottom: 10
			},
			submitButton: {
				color: white,
				marginLeft: 10
			},
			selectProvider: {
				width: "100%",
				marginBottom: 10
			}
		}

		this.state = {
			canSubmit: false,
			newCompany: {
				integrator: false,
				name: "",
				customPermissions: false,
				cloudServiceProvider: "aws"
			}
		};

		this.disableSubmitButton = this.disableSubmitButton.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleCreateCompanyIntegratorChange = this.handleCreateCompanyIntegratorChange.bind(this);
		this.handleCreateCompanyNameChange = this.handleCreateCompanyNameChange.bind(this);
		this.renderFooterButtons = this.renderFooterButtons.bind(this);
		this.submitCompanyCreateForm = this.submitCompanyCreateForm.bind(this);
		this.handleSelectProvider = this.handleSelectProvider.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { section, selectProvider } = this.styles;
		const { newCompany } = this.state;

		return (
			<Modal
				destroyOnClose
				footer={ this.renderFooterButtons() }
				key="Create Company"
				onCancel={ this.handleClose }
				title="Create Company"
				visible={ this.props.visible }
				width="auto"
			>
				<p style={ section }>
					Please fill in the appropriate company information below.
				</p>
				<Input
					disableSubmitCallback={ this.disableSubmitButton }
					hideMaxLength
					id="createCompanyName"
					placeholder="Company Name"
					saveCallback={ this.handleCreateCompanyNameChange }
					style={ section }
					type="text"
					updateCallback={ this.handleCreateCompanyNameChange }
					value={ newCompany.name }
				/>
				<Select
					defaultValue="aws"
					style={ selectProvider }
					onChange={ this.handleSelectProvider }>
					<Option value="aws">Amazon Web Services</Option>
					<Option value="azure">Microsoft Azure</Option>
				</Select>
				<div>
					<Checkbox
						checked={ newCompany.integrator }
						onChange={ this.handleCreateCompanyIntegratorChange }
					/>&nbsp;
					Integrator
				</div>
			</Modal>
		);
	}

	handleSelectProvider(value: CloudProvider) {
		// Set cloud service state
		this.setState((state) => ({
			newCompany: Object.assign({}, state.newCompany, {
				cloudServiceProvider: value
			})
		}));
	}

	renderFooterButtons() {
		const { cancelButton, submitButton } = this.styles;
		const submitDisabled = !this.canSubmit();

		return (
			<div>
				<Button
					icon="times"
					onClick={ this.handleClose }
					style={ cancelButton }
				>
					Cancel
				</Button>
				<Button
					disabled={ submitDisabled }
					icon="check"
					color={ primaryGreen }
					onClick={ this.submitCompanyCreateForm }
					style={ submitButton }
				>
					Submit
				</Button>
			</div>
		);
	}

	canSubmit() {
		const { canSubmit, newCompany } = this.state;

		return canSubmit && newCompany.name;
	}

	disableSubmitButton(canSubmit: boolean) {
		this.setState(() => ({ canSubmit }));
	}

	handleClose() {
		this.setState(
			() => ({ newCompany: {
				integrator: false, name: "", customPermissions: false, cloudServiceProvider: "aws"
			} }),
			() => this.props.closeCompanyCreateForm()
		);
	}

	handleCreateCompanyIntegratorChange(event: CheckboxChangeEvent) {
		this.setState((state) => ({
			newCompany: Object.assign({}, state.newCompany, {
				integrator: event.target.checked
			})
		}));
	}

	handleCreateCompanyNameChange(value: string) {
		this.setState((state) => ({
			newCompany: Object.assign({}, state.newCompany, {
				name: value
			})
		}));
	}

	submitCompanyCreateForm() {
		const newCompany = Object.assign({
			integrator: false, name: null, owner: ""
		}, this.state.newCompany);

		if (this.canSubmit()) {
			this.props.submitRequest(newCompany);
			this.handleClose();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedCompanyCreateModal);

import { createSelector } from "reselect";
import { PusherState, AppState } from "Data/Objects/AppState";
import { WithUuid } from "@connect/Interfaces";
import { identityReducer } from "Data/Utils";

export function getPusherState(state: AppState) {
	return state.Pusher;
}

export function getNotification(state: PusherState, channel: string) {
	return state && state.lastNotification && state.lastNotification[channel];
}

export function getChannelUsers(state: PusherState, channel: string): WithUuid[] {
	return state && state.presenceChannels && state.presenceChannels[channel];
}

export const getLastNotification = createSelector(
	[ getPusherState, identityReducer ],
	getNotification
);

export const getPresenceChannelUsers = createSelector(
	[ getPusherState, identityReducer ],
	getChannelUsers
);
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

interface StepHeaderProps {
	title: {
		icon: string,
		text: string
	}
	subtitle: string
};

const { lightGray, primaryBlue, black } = Colors;

class StepHeader extends React.Component<StepHeaderProps> {
	constructor(props: StepHeaderProps) {
		super(props);

		this.styles = {
			icon: {
				color: primaryBlue
			},
			container: {
				height: "10vh",
				padding: 30,
				textAlign: "center",
				color: lightGray
			},
			title: {
				fontSize: 24,
				fontWeight: 500,
				color: black
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { subtitle, title: { icon, text } } = this.props;
		const { icon: iconStyle, container, title } = this.styles;
		return (
			<div style={ container }>
				<div style={ title }>
					<Icon
						name={ icon }
						style={ iconStyle } />
					{ ` ${ text }` }
				</div>
				{ subtitle }
			</div>
		)
	}
}

export default StepHeader;
import Countdown from "antd/lib/statistic/Countdown";
import moment = require("moment");
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { CustomCSS, ICompany, MediaFilterTypes, Filters } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Loader } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import MediaUsageSection from "Components/Global/MediaUsageSection";
import { setActiveFilters } from "Data/Actions/UI";
import { getActiveCompany } from "Data/Selectors/Company";

interface MediaUsagePanelProps {
	vertical?: boolean;
	uuid: string;
	company: ICompany;
	type: "circle" | "dashboard" | "line";
	spacers: boolean;
	showUnusedMedia: () => void;
}

interface MediaUsagePanelState {
	loading: boolean;
}

const mapStateToProps = (state, ownProps) => ({
	company: getActiveCompany(state)
});

const mapDispatchToProps = (dispatch) => ({
	showUnusedMedia: (filter: MediaFilterTypes) => {
		dispatch(setActiveFilters("type", Filters.MEDIA, MediaFilterTypes.UNUSED));
		dispatch(push("/media"));
	}
});

export class MediaUsagePanel extends React.PureComponent<MediaUsagePanelProps, MediaUsagePanelState> {
	constructor(props: any) {
		super(props);

		this.state = {
			loading: true
		}

		this.styles = {
			container: {
				display: "flex",
				flexWrap: "wrap",
				flexDirection: this.props.vertical ? "column" : "row",
				justifyContent: "center",
				margin: 10
			},
			loader: {
				height: 124,
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			},
			vertical: {
				display: this.props.spacers ? "block" : "none",
				width: "100%",
				minHeight: 2,
				background: "#272A34",
				borderRadius: "5px"
			},
			horizontal: {
				display: this.props.spacers ? "block" : "none",
				width: 2,
				minHeight: 2,
				background: Colors.lightestGray,
				borderRadius: "5px"
			},
			countdown: {
				marginTop: 10
			},
			freeUpSpace: {
				marginTop: 10
			}
		}
	}

	styles: CustomCSS;

	maxMonthlyImageSize;
	maxMonthlyVideoLength;
	maxMonthlyVideoMinutes;
	monthlyImageUsage;
	monthlyVideoLength;
	maxLibrarySize;
	libraryUsage;

	componentWillMount() {
		if (this.props.company && this.props.company.mediaLimit) {
			this.setState(() => ({ loading: false }));
		}
	}

	componentWillReceiveProps(nextProps: MediaUsagePanelProps) {
		this.setState(() => ({
			loading: !(nextProps.company && nextProps.company.mediaLimit)
		}));
	}

	render() {
		const { container, loader } = this.styles;

		if (!this.state.loading && this.props.company) {
			const { mediaLimit, mediaUsage } = this.props.company;

			// monthly limits
			this.maxMonthlyImageSize = mediaLimit ? mediaLimit.monthlyImageSize : null;
			this.maxMonthlyVideoLength = mediaLimit ?
				mediaLimit.monthlyVideoLength : null;
			this.monthlyImageUsage = mediaUsage ? mediaUsage.image : null;
			this.monthlyVideoLength = mediaUsage ? mediaUsage.duration : null;
			this.maxLibrarySize = mediaLimit ? mediaLimit.librarySize : null;
			this.libraryUsage = mediaUsage ? mediaUsage.library : null;
		}

		if (!this.state.loading) {
			return (
				<div style={ container }>
					<MediaUsageSection
						info={ this.getPanelInfo("image") }
						type={this.props.type}
						vertical={this.props.vertical}
						percent={(this.monthlyImageUsage / this.maxMonthlyImageSize) * 100}
						upperLabel="Monthly Image Size"
						lowerLabel={ Utils.getHumanReadableBytesize(this.monthlyImageUsage) + " / " +
						Utils.getHumanReadableBytesize(this.maxMonthlyImageSize) }/>
					{this.renderSpacer()}
					<MediaUsageSection
						info={ this.getPanelInfo("video") }
						type={this.props.type}
						vertical={this.props.vertical}
						percent={(this.monthlyVideoLength / this.maxMonthlyVideoLength) * 100}
						upperLabel="Monthly Video Length"
						lowerLabel={ Utils.getHumanReadableDuration(this.monthlyVideoLength) + " / " +
						Utils.getHumanReadableDuration(this.maxMonthlyVideoLength) }/>
					{this.renderSpacer()}
					<MediaUsageSection
						info={ this.getPanelInfo("library") }
						type={this.props.type}
						vertical={this.props.vertical}
						percent={(this.libraryUsage / this.maxLibrarySize) * 100}
						upperLabel="Total Library Size"
						lowerLabel={ Utils.getHumanReadableBytesize(this.libraryUsage) + " / " +
						Utils.getHumanReadableBytesize(this.maxLibrarySize) }/>
				</div>
			);
		}

		return (
			<div style={ loader }>
				<Loader size="small" />
			</div>
		);
	}

	getPanelInfo(panelType: "image" | "video" | "library") {
		const { countdown: countdownStyle, freeUpSpace: freeUpSpaceStyle } = this.styles;

		const deadline = moment()
			.utc() // UTC Timezone
			.add(1, "M") // Next month
			.set("date", 1) // First day
			.set("hour", 0) // 0th Hour
			.set("minute", 0) // 0th Minute
			.valueOf(); // Millisecond value
		let header = "";
		let content = "";

		switch (panelType) {
			case "image":
				header = "Monthly Image Size";
				content = `Each uploaded image is optimized using a Lossy compression that reduces file
					size by at least 60% without any noticeable loss in image quality. Clinton Connect
					offers up to 1 GB of uploaded images per month.`;
				break;
			case "video":
				header = "Monthly Video Length";
				content = `Each uploaded video is optimized and transcoded into the appropriate file format
					for playback on Connect PVMs. Clinton Connect offers up to 1 hour of uploaded videos per month.`;
				break;
			case "library":
				header = "Total Library Size";
				content = `Clinton Connect comes with 100 GB of free cloud storage for media.  If you are near
					your limit, you can delete your unused files to free up additional space.`;
				break;
		}

		const countdown = (
			<Countdown
				style={ countdownStyle }
				title="Your monthly limit will reset at midnight on the first day of every month."
				format="DD:HH:mm:ss"
				value={ deadline } />
		);

		const freeUpSpace = (
			<div style={ freeUpSpaceStyle } >
				<a onClick={ this.props.showUnusedMedia }>
					If you are near your limit, you can delete your unused media to free up additional space.
				</a>
			</div>
		);

		return {
			header,
			content: (
				<div>
					<div>{ content }</div>
					{ panelType !== "library" ? countdown : freeUpSpace }
				</div>
			)
		};
	}

	checkUsageColor(percent: number) {
		if (percent <= 50) {
			return "media-usage-panel-progress-green";
		} else if (percent > 50 && percent <= 75) {
			return "media-usage-panel-progress-orange";
		} else {
			return "media-usage-panel-progress-red";
		}
	}

	renderSpacer() {
		const { vertical, horizontal } = this.styles;
		return (
			// Render the spacer
			this.props.vertical ? (
				<div style={ vertical } /> ) : (
				<div style={ horizontal } />
			)
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaUsagePanel);
import * as React from "react";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";

import { ActionSet, Context, CustomCSS, IPlaylist, SortTypes, Sorts } from "@connect/Interfaces";
import { Loader } from "Components/Global/Common";
import PlaylistGrid from "Components/Playlists/PlaylistGrid";
import { getPlaylistsAsync } from "Data/Actions/Playlists";
import { getActiveActionSet } from "Data/Selectors/Actions";
import { getFilteredSortedPlaylists } from "Data/Selectors/Playlists";
import {
	getActiveSearch,
	getActiveSorts,
	haveAllPlaylists as getHaveAllPlaylists
} from "Data/Selectors/UI";

const mapStateToProps = (state) => {
	const playlistsSortType = getActiveSorts(state, Sorts.PLAYLISTS) as SortTypes;
	const searchText = getActiveSearch(state, "playlists");
	return {
		activeActionSet: getActiveActionSet(state),
		haveAllPlaylists: getHaveAllPlaylists(state),
		playlists: getFilteredSortedPlaylists(state, { sort: playlistsSortType, search: searchText }),
		playlistsSortType,
		searchText
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPlaylists: () => dispatch(getPlaylistsAsync(true)) // on the PlaylistsPage we should always get expanded
});

interface PlaylistContentAreaProps {
	activeActionSet: ActionSet;
	context: Context;
	getPlaylists: () => void;
	haveAllPlaylists: boolean;
	onCreatePlaylist: () => void;
	onPlaylistsSelected: (playlist: IPlaylist) => void;
	playlists: IPlaylist[];
	playlistsSortType: SortTypes;
	searchText: string;
	selectModeOn: boolean;
}

class PlaylistContentArea extends React.Component<PlaylistContentAreaProps> {
	constructor(props: PlaylistContentAreaProps) {
		super(props);

		this.styles = {
			waypoint: {
				height: 40,
				marginTop: 40,
				textAlign: "center",
				width: "100%"
			}
		};

		this.renderWaypoint = this.renderWaypoint.bind(this);
		this.fetchPlaylists = this.fetchPlaylists.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<React.Fragment>
				{ this.renderPlaylistGrid() }
				{ this.renderWaypoint() }
			</React.Fragment>
		);
	}

	renderPlaylistGrid() {
		const {
			context,
			onCreatePlaylist,
			onPlaylistsSelected,
			playlists,
			playlistsSortType,
			searchText,
			selectModeOn
		} = this.props;

		const key = playlistsSortType.toString() + searchText;

		return (
			<PlaylistGrid
				content={ playlists }
				context={ context }
				key={ key }
				minWidth="560px"
				onCreatePlaylist={ onCreatePlaylist }
				onPlaylistsSelected={ onPlaylistsSelected }
				selectModeOn={ selectModeOn }
			/>
		);
	}

	renderWaypoint() {
		const { haveAllPlaylists, playlists } = this.props;
		const style = { ...this.styles.waypoint, display: !haveAllPlaylists ? "inline-block" : "none" };

		return (
			<div style={ style }>
				<Waypoint key={ playlists.length + haveAllPlaylists.toString() } onEnter={ this.fetchPlaylists } />
				<Loader />
			</div>
		);
	}

	fetchPlaylists() {
		const { haveAllPlaylists, getPlaylists } = this.props;
		if (!haveAllPlaylists) {
			getPlaylists();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistContentArea);
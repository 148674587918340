import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Button, Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

interface ResourceButtonProps {
	buttonText: string;
	title: string;
	icon: string;
	resource: string;
}

class ResourceButton extends React.Component<ResourceButtonProps> {
	constructor(props: ResourceButtonProps) {
		super(props);

		this.styles = {
			card: {
				width: 300,
				minHeight: 300,
				boxShadow: "rgb(243, 243, 243) 0px 4px 8px 0px, rgb(249, 249, 249) 0px 6px 20px 0px",
				transition: "height 0.3s ease-out",
				textAlign: "center",
				position: "relative",
				justifySelf: "center",
				marginBottom: 20
			},
			icon: {
				color: Colors.lightGray
			},
			expandButton: {
				bottom: 30,
				left: "50%",
				transform: "translateX(-50%)",
				position: "absolute"
			},
			content: {
				width: "100%",
				paddingTop: 100
			},
			title: {
				marginTop: 30,
				fontWeight: "bolder",
				fontSize: 18
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { card, icon: iconStyle, expandButton, content,
			title: titleStyle } = this.styles;
		const { title, icon, resource, buttonText } = this.props;

		return (
			<div style={ card }>
				<div style={ content }>
					<Icon name={ icon } size="big" style={ iconStyle } />
					<div style={ titleStyle }>
						{ title }
					</div>
				</div>
				<Button style={ expandButton } onClick={ this.downloadResource(resource) } >
					{ buttonText }
				</Button>
			</div>
		);
	}

	downloadResource(resource: string) {
		return () => {
			Utils.initiateDownload(resource);
		}
	}
}

export default ResourceButton;
import { Popover } from "antd";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { TooltipPlacement } from "antd/lib/tooltip";

const { lightGray } = Colors;

interface IHelpPopoverProps {
	title: string | React.ReactNode;
	placement?: TooltipPlacement;
}

export class HelpPopover extends React.PureComponent<IHelpPopoverProps> {
	constructor(props: IHelpPopoverProps) {
		super(props);

		this.styles = {
			helpContent: {
				margin: 0,
				padding: "0 12px"
			},
			helpTitle: {
				padding: "5px 12px 0",
				width: "100%"
			},
			question: {
				color: lightGray,
				cursor: "pointer",
				marginLeft: 6
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { children, title, placement } = this.props;
		const { helpContent, helpTitle, question } = this.styles;


		return (
			<Popover
				content={(
					<div style={ helpContent }>
						{ children }
					</div>
				)}
				title={(
					<div style={ helpTitle }>
						{ title }
					</div>
				)}
				trigger="click"
				placement={ placement || "top" }
			>
				<Icon
					iconWeight="regular"
					name="question-circle"
					style={ question }
				/>
			</Popover>
		);
	}
}

export default HelpPopover;

import * as React from "react";
import { connect } from "react-redux";

import { ActionSetActions, CustomCSS } from "@connect/Interfaces";
import { ActionPropertiesPanelContent,
	ActionPropertiesPanelContentProps } from "Components/Actions/ActionPropertiesPanelContent";
import { Icon } from "Components/Global/Common";
import { getSelectedActionType } from "Data/Selectors/ActionBuilder";
import { mapStateToProps as superMapStateToProps } from "Components/Actions/ActionPropertiesPanelContent";


const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		activeActionType: getSelectedActionType(state)
	};
};

interface DefaultPlaylistActionPropertiesPanelContentProps extends ActionPropertiesPanelContentProps {}

export class DefaultPlaylistActionPropertiesPanelContent
	extends ActionPropertiesPanelContent<DefaultPlaylistActionPropertiesPanelContentProps> {
	constructor(props: DefaultPlaylistActionPropertiesPanelContentProps) {
		super(props);

		this.icon = ActionSetActions.default.icon;
		this.title = ActionSetActions.default.title;

		this.styles = Object.assign(this.styles, {
			image: {
				display: "inline-block",
				margin: "2em 0 0"
			},
			name: {
				display: "inline-block",
				margin: "10px 0 2em"
			},
			redBox: {
				margin: "2em auto 0",
				padding: "0 10px",
				width: 80
			}
		});
	}

	styles: CustomCSS;

	render() {
		return this.renderContainer();
	}

	renderIcon() {
		const { image, name, redBox, row } = this.styles;

		return (
			<div style={ row }>
				<div style={ redBox }>
					<Icon name={ this.icon } size="bigger" style={ image } />
					<br />
					<span style={ name }>{ this.title }</span>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(DefaultPlaylistActionPropertiesPanelContent);

import Radium from "radium";
import * as React from "react";

import { toggleFeature } from "@connect/Features";
import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { IconWeights } from "Components/Global/Icon";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { isAdmin } from "Data/Objects/Roles";

interface IAdminNavButtonsProps {
	handleClick: (e: React.SyntheticEvent<HTMLElement>) => void;
	isIntegrator: boolean;
	isUserOrMarketer: boolean;
	isSAMLUser: boolean;
	pageState: string;
}

export interface NavButton {
	permission: string | string[];
	icon: string;
	iconWeight: IconWeights;
	page: string;
	label: string;
}

export class AdminNavButtons extends React.PureComponent<IAdminNavButtonsProps> {
	constructor(props: IAdminNavButtonsProps) {
		super(props);

		const { isIntegrator, isUserOrMarketer, isSAMLUser } = props;
		this.navButtons = [
			{
				permission: PERMISSIONS.SELF_EDIT,
				icon: "user",
				iconWeight: "solid" as IconWeights,
				page: "account",
				label: "My Account"
			},
			(!isIntegrator || isAdmin() ? {
				permission: PERMISSIONS.VIEW_COMPANY_INTEGRATIONS,
				icon: "building",
				iconWeight: "regular" as IconWeights,
				page: "integration-companies",
				label: "Integration Companies"
			} : null),
			(isIntegrator ? {
				icon: "building",
				iconWeight: "regular" as IconWeights,
				page: "managed-companies",
				label: "Managed Companies"
			} : null),
			{
				permission: PERMISSIONS.OWN_COMPANIES_EDIT,
				icon: "store-alt",
				iconWeight: "solid" as IconWeights,
				page: "stores",
				label: "Stores"
			},
			{
				permission: PERMISSIONS.USERS_USER_EDIT,
				icon: "users",
				iconWeight: "solid" as IconWeights,
				page: "users",
				label: "Users"
			},
			{
				permission: PERMISSIONS.DEVICES_MANAGE,
				icon: "tablet",
				iconWeight: "regular" as IconWeights,
				page: "devices",
				label: "Devices"
			},
			{
				permission: PERMISSIONS.OWN_COMPANIES_EDIT,
				icon: "clock",
				iconWeight: "regular" as IconWeights,
				page: "activity",
				label: "Activity"
			},
			{
				permission: PERMISSIONS.DEVICES_CHECK_IN,
				icon: "android",
				iconWeight: "brands" as IconWeights,
				page: "versions",
				label: "Versions"
			},
			(!isSAMLUser ? {
				permission: null,
				icon: "shield-check",
				iconWeight: "regular" as IconWeights,
				page: "security",
				label: "Security"
			} : null),
			{
				permission: PERMISSIONS.OWN_COMPANIES_EDIT,
				icon: "lock-alt",
				iconWeight: "regular" as IconWeights,
				page: "passwordpolicies",
				label: "Password Policies"
			},
			(isUserOrMarketer ? null : toggleFeature("customUserPermissions", {
				permission: null,
				icon: "users-cog",
				iconWeight: "solid" as IconWeights,
				page: "permissions",
				label: "Custom User Permissions"
			}, null)),
			{
				permission: null,
				icon: "play",
				iconWeight: "solid" as IconWeights,
				page: "video",
				label: "Video Tutorials"
			},
			{
				permission: null,
				icon: "file-alt",
				iconWeight: "solid" as IconWeights,
				page: "resources",
				label: "Resources"
			},
			{
				permission: null,
				icon: "envelope",
				iconWeight: "regular" as IconWeights,
				page: "emailPreferences",
				label: "Email Preferences"
			}
		] as NavButton[];

		const { active, hover, inactive } = Colors.filters;
		const selectedStyle = {
			background: active,
			borderBottom: `1px solid ${ inactive }`,
			borderRadius: 2
		};

		this.styles = {
			navList: {
				listStyleType: "none",
				margin: 0,
				padding: 0
			},
			navRow: {
				padding: "8px 4px",
				borderBottom: `1px solid ${ hover }`,
				cursor: "pointer",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				":hover": {
					...selectedStyle,
					background: hover
				}
			},
			navRowSelected: selectedStyle,
			navRowIcon: {
				marginRight: 5
			}
		};

		this.renderNavButton = this.renderNavButton.bind(this);
	}

	navButtons: NavButton[];
	styles: CustomCSS;

	render() {
		return (
			<ul style={ this.styles.navList }>
				{ this.navButtons.map(this.renderNavButton) }
			</ul>
		);
	}

	renderNavButton(button: NavButton) {
		if (!button) {
			return null;
		}

		const { icon, iconWeight, label, page, permission } = button;
		const canRender = permission ? this.hasPermission(permission) : true;

		if (!canRender) {
			return null;
		}

		const { handleClick, pageState } = this.props;
		const isSelected = pageState === page;
		const { navRow, navRowIcon, navRowSelected } = this.styles;
		const key = page.toLowerCase() + "_" + isSelected.toString();
		const style = {
			...navRow,
			...(isSelected && navRowSelected)
		};

		return (
			<li
				role="button"
				key={ key }
				data-page={ page }
				style={ style }
				onClick={ handleClick }
			>
				<Icon fixedWidth
					name={ icon }
					size="smaller"
					iconWeight={ iconWeight }
					style={ navRowIcon } />
				{ label || page }
			</li>
		);
	}

	hasPermission(perms: string | string[]): boolean {
		if (!perms || !perms.length) {
			return true;
		}

		if (!Array.isArray(perms)) {
			return !!hasPermission(perms);
		}

		return perms.every((perm) => !!hasPermission(perm));
	}
}

export default Radium(AdminNavButtons);

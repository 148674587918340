import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, MediaType } from "@connect/Interfaces";
import { Modal } from "Components/Global/Common";
import MediaPreview from "Components/Global/MediaPreview";
import { setMediaPreview } from "Data/Actions/UI";
import { getMediaPreviewModalVisibility } from "Data/Selectors/UI";

const mapStateToProps = (state, ownProps) => ({
	visible: getMediaPreviewModalVisibility(state)
});

const mapDispatchToProps = (dispatch) => ({
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => dispatch(setMediaPreview(uri, vimeoId, type))
});

interface IMediaPreviewProps {
	visible: boolean;
	onClose: () => void;
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => void;
}

export class UnwrappedMediaPreviewModal extends React.PureComponent<IMediaPreviewProps> {
	constructor(props: IMediaPreviewProps) {
		super(props);
		this.styles = {
			center: {
				textAlign: "center"
			}
		}

		this.handleClose = this.handleClose.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { visible } = this.props;
		return (
			<Modal
				modalKey={ String(visible) }
				width="auto"
				visible={ visible }
				onCancel={ this.handleClose }>
				<MediaPreview />
			</Modal>
		);
	}

	handleClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}

		this.props.setMediaPreview("", -1, "" as MediaType);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedMediaPreviewModal);
import * as update from "immutability-helper";
import * as React from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";

import { CustomCSS, ICompany } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { getActiveCompany } from "Data/Selectors/Company";

interface IDroppableProps {
	activeCompany?: ICompany;
	disabled?: boolean;
	fullScreen?: boolean;
	onDrop: (files: File[]) => void;
	prompt?: JSX.Element | string;
}

interface IDroppableState {
	dropzoneActive: boolean;
}

interface IDropZoneProps extends IDroppableProps {
	width?: number;
	height?: number;
	style?: {};
	clear?: boolean;
}

const mapStateToProps = (state) => ({
	activeCompany: getActiveCompany(state)
});

// Can be used to wrap an entire component and make it a droppable surface
export class UnwrappedDroppable extends React.PureComponent<IDroppableProps, IDroppableState> {
	constructor(props: IDroppableProps) {
		super(props);

		const { fullScreen } = props;

		this.state = {
			dropzoneActive: false
		};

		this.styles = {
			dropzoneStyle: {
				height: fullScreen ? "100vh" : "100%",
				position: fullScreen ? "absolute" : "relative",
				width: "100%",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0
			},
			fullscreenWrapper: {
				position: "sticky",
				top: 0,
				margin: 0,
				padding: 0
			},
			overlay: {
				position: fullScreen ? "sticky" : "absolute",
				display: "flex",
				width: "100%",
				height: fullScreen ? "100vh" : "100%",
				maxHeight: "100vh",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
				fontSize: "300%",
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				border: "1px dashed " + Colors.primaryBlue,
				background: Colors.dropBackground,
				backdropFilter: "blur(4px)",
				color: Colors.black,
				zIndex: 10000
			},
			wrapper: {
				position: "relative",
				height: "auto",
				width: "auto"
			}
		}
		this.open = this.open.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onDragEnter = this.onDragEnter.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
	}

	styles: CustomCSS;
	dropzoneRef: Dropzone;

	render() {
		const { dropzoneActive } = this.state;
		const { fullscreenWrapper, wrapper } = this.styles;
		const { fullScreen } = this.props;

		let content;

		if (fullScreen) {
			content = (
				<div style={ wrapper }>
					<div style={{ ...fullscreenWrapper, zIndex: dropzoneActive ? 100000 : 0 }}>
						{ this.renderDropzone() }
					</div>
					{this.props.children}
				</div>
			);
		} else {
			content = this.renderDropzone(true);
		}

		return content;
	}

	renderDropzone(renderChildren?: boolean) {
		const { dropzoneActive } = this.state;
		const { dropzoneStyle, overlay } = this.styles;
		const { disabled } = this.props;

		return (
			<Dropzone
				ref={(node) => {
					this.dropzoneRef = node
				}}
				disableClick
				disablePreview
				disabled={ disabled }
				style={ dropzoneStyle }
				onDrop={ this.onDrop }
				onDragEnter={ this.onDragEnter }
				onDragLeave={ this.onDragLeave }
			>
				{dropzoneActive && (
					<div style={ overlay }>
						{this.renderPrompt()}
					</div>
				)}
				{ renderChildren && this.props.children }
			</Dropzone>
		);
	}

	renderPrompt() {
		const { activeCompany } = this.props;

		if (!activeCompany) {
			return null;
		}

		const { videoLength, videoSize, imageSize } = activeCompany.mediaLimit;
		const videoSizeLimit = Utils.getHumanReadableBytesize(videoSize);
		const imageSizeLimit = Utils.getHumanReadableBytesize(imageSize);

		return this.props.hasOwnProperty("prompt") ? this.props.prompt : (
			<div>
				<Icon
					name="plus-circle"
					style={{ color: Colors.primaryGreen }}/>
					&nbsp; Drop file(s)...
				<div style={{
					fontSize: ".4em",
					marginTop: 20
				}}>
						* Videos must be less than {Utils.getMinutesFromSeconds(videoLength)} minutes long, HD (1920
						x 1080 / 1080 x 1920) or below, and smaller than {videoSizeLimit}.<br />
						* Images must be smaller than {imageSizeLimit}.
				</div>
			</div>
		);
	}

	open() {
		this.dropzoneRef.open();
	}

	onDragEnter(event: Event) {
		// make sure the user can't drag page elements into the dropzone
		// https://github.com/react-dropzone/react-dropzone/issues/255
		const e = event as any;
		const dt = e.dataTransfer || e.originalEvent.dataTransfer;
		const hasFiles = !( dt.types && ( dt.types.indexOf ?
			dt.types.indexOf( "Files" ) !== -1 : dt.types.contains( "Files" ) ));

		if (hasFiles) {
			this.setDropZone(false);
		} else {
			this.setDropZone(true)
		}
	}

	setDropZone(bool: boolean) {
		this.setState((prevState) => {
			return update(prevState, {
				dropzoneActive: { $set: bool }
			});
		});
	}

	onDragLeave() {
		this.setDropZone(false);
	}

	onDrop(files: File[]) {
		if (files) {
			this.props.onDrop(files);
		}
		this.setState((prevState) => {
			return update(prevState, {
				files: { $set: files },
				dropzoneActive: { $set: false }
			});
		});
	}
}

export const Droppable = connect(mapStateToProps, null, null, { withRef: true })(UnwrappedDroppable);

// can be used to create a droppable area / div, styled however the implementation requirement looks
export class DropZone extends React.Component<IDropZoneProps> {
	constructor(props: IDropZoneProps) {
		super(props);

		this.styles = {
			base: {
				position: "relative",
				display: "flex",
				width: "100%",
				height: "100%",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
				border: "1px dashed " + Colors.primaryBlue,
				color: Colors.black
			}
		}

		this.open = this.open.bind(this);
	}

	dropzoneRef: connect<UnwrappedDroppable>;
	styles: CustomCSS;
	render() {
		const { children, onDrop, prompt, style, clear } = this.props;
		const getComputedStyle = () => {
			if (clear) {
				return {};
			}

			return {
				...this.styles.base,
				...style
			}
		}

		return (
			<Droppable
				ref={(node) => {
					this.dropzoneRef = node
				}}
				prompt={prompt}
				onDrop={onDrop}>
				<div style={ getComputedStyle() }>
					{children}
				</div>
			</Droppable>
		);
	}

	open() {
		this.dropzoneRef.getWrappedInstance().open();
	}
}
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";

interface ActivityIndicatorProps {
	activity: number;
}

const ActivityColors = {
	BLUE: Colors.primaryBlue,
	GREEN: Colors.primaryGreen,
	YELLOW: Colors.yellow,
	RED: Colors.errorRed,
	GRAY: Colors.lightGray
}

class ActivityIndicator extends React.Component<ActivityIndicatorProps> {
	constructor(props: ActivityIndicatorProps) {
		super(props);

		this.styles = {
			baseRectangle: {
				width: 15,
				height: 7.5,
				display: "inline-block",
				marginRight: 2
			}
		}

		this.renderRectangle = this.renderRectangle.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { activity } = this.props;
		const { BLUE, GREEN, YELLOW, RED, GRAY } = ActivityColors;
		if (activity < 25) {
			return this.renderRectangles([ BLUE, GRAY, GRAY, GRAY ]);
		} else if (activity >= 25 && activity < 50) {
			return this.renderRectangles([ GREEN, GREEN, GRAY, GRAY ]);
		} else if (activity >= 50 && activity < 75) {
			return this.renderRectangles([ YELLOW, YELLOW, YELLOW, GRAY ]);
		} else {
			return this.renderRectangles([ RED, RED, RED, RED ]);
		}
	}

	renderRectangles(colors: string[]) {
		return (
			<div>
				{colors.map(this.renderRectangle)}
			</div>
		);
	}

	renderRectangle(color: string, index: number) {
		const { baseRectangle } = this.styles;
		const rectangleStyle = {
			...baseRectangle,
			backgroundColor: color
		};
		return (
			<div key={ `activity-rectangle-${ index }` } style={ rectangleStyle } />
		)
	}
}

export default ActivityIndicator;
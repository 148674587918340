import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IUser } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Button } from "Components/Global/Common";
import { updateUserAsync } from "Data/Actions/UsersAsync";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { getActiveUserUuid } from "Data/Selectors/User";
import { cloneDeep } from "lodash";

const mapDispatchToProps = (dispatch) => ({
	updateUser: (user: IUser) => dispatch(updateUserAsync(user))
});

const mapStateToProps = (state) => ({
	activeUuid: getActiveUserUuid(state)
});

interface DeactivateUserButtonProps extends React.ComponentProps<any> {
	activeUuid: string;
	disabled?: boolean;
	updateUser: (user: IUser) => Promise<any>;
	user: IUser;
}

class DeactivateUserButton extends React.PureComponent<DeactivateUserButtonProps> {
	constructor(props: DeactivateUserButtonProps) {
		super(props);

		this.styles = {
			center: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			},
			deactivateButton: {
				width: 180,
				margin: "20px 0 20px"
			}
		}

		this.handleToggleUserActive = this.handleToggleUserActive.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { deactivateButton } = this.styles;
		const { activeUuid, user, disabled, className } = this.props;
		const { active, role, uuid } = user;
		const isActiveUser = activeUuid === uuid;

		if (!isActiveUser && !hasPermission(PERMISSIONS[`USERS_${ role.name.toUpperCase() }_DELETE`]) || disabled) {
			return null;
		}

		return (
			<Button
				className={ className }
				icon={ active ? "eye" : "eye-slash" }
				type={ active ? undefined : "primary" }
				style={ deactivateButton }
				onClick={ this.handleToggleUserActive }
			>
				{ active ? "Deactivate" : "Activate" }
			</Button>
		);
	}

	handleToggleUserActive() {
		const { activeUuid, user, updateUser } = this.props;
		const newUser = cloneDeep(user);

		newUser.active = !newUser.active;

		if (user.uuid === activeUuid) {
			return Notifications.confirm(
				"Deactivate Account",
				`
					Warning: you are about to deactivate your account. Once your
					account is deactivated you will be logged out and you
					will not be able to log in again.
				`,
				"Confirm",
				"Cancel",
				() => updateUser(newUser)
			);
		}

		updateUser(newUser);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateUserButton);
import { Card as AntCard } from "antd";
import { HeightProperty } from "csstype";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Icon } from "Components/Global/Common";

interface CardProps {
	header?: string | JSX.Element;
	color?: string;
	bodyStyle?: CustomCSS;
	containerStyle?: CustomCSS;
	bordered?: boolean;
	extra?: string | JSX.Element;
	loading?: boolean;
	hoverable?: boolean;
	title?: string | JSX.Element;
	children: JSX.Element | JSX.Element[];
	height?: HeightProperty<string | number>;
	onClose?: () => void;
}

export default class Card extends React.PureComponent<CardProps> {
	constructor(props: CardProps) {
		super(props);

		const { color, height } = props;

		this.styles = {
			container: {
				margin: "6px",
				display: "inline-block",
				borderTop: color ? `5px solid ${color}` : null,
				boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.1)",
				borderRadius: 6,
				width: "100%",
				height: height || "auto",
				zIndex: 100,
				overflow: "hidden",
				...this.props.containerStyle
			},
			body: {
				padding: "0 0 25px 0",
				height: "100%",
				overflow: "hidden",
				...this.props.bodyStyle
			},
			headerStyle: {
				display: "flex",
				justifyContent: "space-between"
			},
			content: {
				height: "100%",
				overflow: "auto",
				marginBottom: 20
			},
			close: {
				marginRight: 3
			}
		};

		this.close = this.close.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { container, body } = this.styles;
		const { bordered, extra, loading, hoverable, title } = this.props;
		return (
			<AntCard
				style={ container }
				bodyStyle={ body }
				bordered={ bordered }
				extra={ extra }
				loading={ loading }
				hoverable={ hoverable }
				title={ title }>
				{ this.renderHeader() }
				{ this.renderContent() }
			</AntCard>
		);
	}

	renderHeader() {
		return (
			<span style={ this.styles.headerStyle }>
				{ this.props.header }{ this.renderClose() }
			</span>
		);
	}

	renderClose() {
		return (
			<Icon
				size="smaller"
				name="times"
				style={ this.styles.close }
				onClick={ this.close } />
		);
	}

	renderContent() {
		return (
			<div style={ this.styles.content }>
				{ this.props.children }
			</div>
		);
	}

	close() {
		const { onClose } = this.props;
		if (onClose) {
			onClose();
		}
	}
}

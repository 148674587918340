import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Context, IAd, IMedia, IPlaylist } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import AdPropertiesPanelContent from "Components/Ads/AdPropertiesPanelContent";
import FeedPropertiesPanelContent from "Components/Ads/FeedPropertiesPanelContent";
import ImagePropertiesPanelContent from "Components/Ads/ImagePropertiesPanelContent";
import SlideshowPropertiesPanelContent from "Components/Ads/SlideshowPropertiesPanelContent";
import TickerPropertiesPanelContent from "Components/Ads/TickerPropertiesPanelContent";
import VideoPropertiesPanelContent from "Components/Ads/VideoPropertiesPanelContent";
import { ButtonTypes, IconWeights } from "Components/Global/Button";
import { Colors } from "Components/Global/Constants";
import PropertiesPanel, { PropertiesPanelButton } from "Components/Global/PropertiesPanel";
import { deleteAdAsync, duplicateAdAsync } from "Data/Actions/AdsAsync";
import { setSelectPlaylistsModal } from "Data/Actions/UI/Modals";
import { updatePlaylistAsync } from "Data/Actions/Playlists";
import { clearAd as clearAdAsync, clearComponent, deleteComponent, setActiveAd } from "Data/Actions/UI/AdBuilder";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import {
	getActiveAd,
	getAdComponentsHeight,
	getComponentByIndex,
	getSelectedComponentIndex
} from "Data/Selectors/AdBuilder";
import { getAdMediaForComponent } from "Data/Selectors/Ads";
import { getActiveUuid } from "Data/Selectors/UI";

const mapDispatchToProps = (dispatch) => ({
	clearAd: (ad: IAd) => dispatch(clearAdAsync(ad)),
	clearSelectedComponent: (index: number) => dispatch(clearComponent(index)),
	deleteAd: (ad: IAd) => dispatch(deleteAdAsync(ad)),
	deleteSelectedComponent: (index: number) => dispatch(deleteComponent(index)),
	deployAd: (ad: IAd) => dispatch(push({
		pathname: "/deploy",
		state: {
			deployment: {
				deploymentType: "schedule",
				schedule: ad,
				scheduleType: "ad"
			}
		}
	})),
	duplicateAd: (ad: IAd) => dispatch(duplicateAdAsync(ad)),
	pushToAdsPage: () => dispatch(push("/ads")),
	setActivePlaylist: (id: string) => dispatch(push(`/playlists/${id}`)),
	setAd: (ad: IAd) => dispatch(setActiveAd(ad)),
	setPlaylistModal: (visible: boolean) => dispatch(setSelectPlaylistsModal(visible, [], Context.ADS)),
	updatePlaylist: (playlist: IPlaylist) => dispatch(updatePlaylistAsync(playlist))
});

const mapStateToProps = (state) => {
	const activeAd = getActiveAd(state);
	const adId = getActiveUuid(state, "ads");
	const componentsHeight = getAdComponentsHeight(state);
	const index = getSelectedComponentIndex(state);
	const selectedComponent = getComponentByIndex(state, index);
	const selectedComponentMedia = selectedComponent
		? getAdMediaForComponent(state, activeAd ? activeAd.layout.media : [], selectedComponent.id)
		: [];

	return {
		activeAd,
		adId,
		componentsHeight,
		selectedComponent,
		selectedComponentIndex: index,
		selectedComponentMedia
	};
};

interface AdsPagePropertiesPanelProps {
	activeAd: IAd;
	adId: string;
	clearAd: (ad: IAd) => void;
	clearSelectedComponent: (id: number) => void;
	componentsHeight: number;
	deleteAd: (ad: IAd) => void;
	deleteSelectedComponent: (id: number) => void;
	deployAd: (ad: IAd) => void;
	duplicateAd: (ad: IAd) => void;
	pushToAdsPage: () => void;
	selectedComponent: any; // TODO: does this have a real type?
	selectedComponentIndex: number;
	selectedComponentMedia: IMedia[];
	selectComponent: () => void;
	setActivePlaylist: (id: string) => void;
	setAd: (ad: IAd) => void;
	setPlaylistModal: (visible: boolean) => void;
	updatePlaylist: (playlist: IPlaylist) => Promise<void>;
}

export class AdsPagePropertiesPanel extends React.Component<AdsPagePropertiesPanelProps> {
	constructor(props: AdsPagePropertiesPanelProps) {
		super(props);

		this.promptClearAd = this.promptClearAd.bind(this);
		this.promptDeleteAd = this.promptDeleteAd.bind(this);
		this.selectPlaylists = this.selectPlaylists.bind(this);
		this.setPlaylistModal = this.setPlaylistModal.bind(this);
	}

	render() {
		return (
			<React.Fragment>
				<PropertiesPanel
					actions={ this.getAvailableActions() }
					properties={ this.renderCurrentPanelContent() }
					title={ this.getActiveTitle() }
				/>
			</React.Fragment>
		);
	}

	renderCurrentPanelContent() {
		const { componentsHeight, selectedComponent } = this.props;

		if (!selectedComponent) {
			return (
				<AdPropertiesPanelContent
					contentsHeightPercent={ componentsHeight }
				/>
			);
		}

		switch (selectedComponent.type) {
			case "image":
				return <ImagePropertiesPanelContent />;
			case "ticker":
				return <TickerPropertiesPanelContent />;
			case "video":
				return <VideoPropertiesPanelContent />;
			case "slideshow":
				return <SlideshowPropertiesPanelContent />;
			case "feed":
				return <FeedPropertiesPanelContent canChangeAspectRatio={ !!this.props.activeAd } />;
			default:
				return null;
		}
	}

	getActiveTitle() {
		const { selectedComponent } = this.props;

		if (!selectedComponent) {
			return "";
		}

		switch (selectedComponent.type) {
			case "feed":
				return "Security Feed Properties";
			case "image":
				return "Image Properties";
			case "slideshow":
				return "Slideshow Properties";
			case "ticker":
				return "Ticker Tape Properties";
			case "video":
				return "Video Properties";
			default:
				return "Ad Properties";
		}
	}

	getAvailableActions(): PropertiesPanelButton[] {
		let actions: PropertiesPanelButton[] = [];
		const {
			activeAd,
			adId,
			clearSelectedComponent,
			deleteSelectedComponent,
			deployAd,
			duplicateAd,
			selectedComponent,
			selectedComponentIndex,
			selectedComponentMedia
		} = this.props;

		const clearAd = {
			action: this.promptClearAd,
			icon: { name: "eraser", weight: "solid" as IconWeights },
			key: `${adId}_clear_ad`,
			name: "Clear Ad"
		};
		const clearComponentAction = {
			action: () => clearSelectedComponent(selectedComponentIndex),
			icon: { name: "eraser", weight: "solid" as IconWeights },
			key: `${adId}_clear_component`,
			name: "Clear Component"
		};
		const copyAd = {
			action: () => duplicateAd(activeAd),
			icon: { name: "copy", weight: "regular" as IconWeights },
			key: `${adId}_duplicate_ad`,
			name: "Copy Ad"
		};
		const deleteAd = {
			action: this.promptDeleteAd,
			icon: { name: "trash-alt", weight: "regular" as IconWeights },
			key: `${adId}_delete_ad`,
			name: "Delete Ad",
			type: "danger" as ButtonTypes
		};
		const deleteComponentAction = {
			action: () => deleteSelectedComponent(selectedComponentIndex),
			icon: { name: "trash-alt", weight: "regular" as IconWeights },
			key: `${adId}_delete_component`,
			name: "Delete Component",
			type: "danger" as ButtonTypes
		};
		const deploy = {
			action: () => deployAd(activeAd),
			icon: { name: "rocket", weight: "solid" as IconWeights },
			key: `${adId}_deploy`,
			name: "Deploy Now",
			type: "primary" as ButtonTypes
		}

		const addToPlaylist = {
			action: () => this.setPlaylistModal(true),
			icon: { name: "th-list", weight: "solid" as IconWeights },
			key: `${ adId }_add_to_playlist`,
			name: "Add to Playlist",
			type: "primary" as ButtonTypes,
			color: Colors.primaryBlue
		}

		if (hasPermission(PERMISSIONS.PLAYLISTS_MANAGE)) {
			actions.push(addToPlaylist);
		}

		if (hasPermission(PERMISSIONS.DEPLOYMENTS_MANAGE)) {
			actions.push(deploy);
		}

		if (selectedComponent) {
			const tickerHasContent = selectedComponent.type === "ticker" && selectedComponent.messages[0];
			const feedHasBanner = selectedComponent.type === "feed" && selectedComponent.banner != null;

			if (selectedComponentMedia.length || tickerHasContent || feedHasBanner) {
				actions.push(clearComponentAction);
			}

			actions.push(deleteComponentAction);
		} else {
			actions.push(clearAd, copyAd, deleteAd);
		}

		return actions;
	}

	promptClearAd() {
		const { activeAd, clearAd } = this.props;

		Notifications.confirm("Are you sure you want to clear this ad?",
			"Clearing an ad removes all ad components.",
			"Clear Ad", "Cancel", () => {
				clearAd(activeAd);
			});
	}

	promptDeleteAd() {
		const { activeAd, deleteAd, pushToAdsPage, setAd } = this.props;

		Notifications.confirm(`Delete ${activeAd.name}?`,
			"Are you sure you want to delete this ad?",
			"Delete", "Cancel", () => {
				setAd({} as IAd);
				deleteAd(activeAd);
				pushToAdsPage();
			});
	}

	selectPlaylists(playlists: IPlaylist[]) {
		const { activeAd, setActivePlaylist, updatePlaylist } = this.props;
		const [ playlist ] = playlists;
		const { ads: playlistAds, name, uuid } = playlist;

		updatePlaylist(Object.assign({}, playlist, {
			ads: playlistAds.concat(activeAd)
		})).then(() => {
			this.setPlaylistModal(false);
			Notifications.success(`Ad successfully added to ${ name }`);
			setActivePlaylist(uuid);
		});
	}

	setPlaylistModal(visible: boolean) {
		this.props.setPlaylistModal(visible)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsPagePropertiesPanel);

import { Menu } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { ENVIRONMENT, ENVIRONMENTS } from "@connect/Env";
import { CompanyMenuOption, CustomCSS, MediaType, VimeoTutorial } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon, Link } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { getActiveCompanyDetails } from "Data/Actions/Company";
import { setMediaPreview } from "Data/Actions/UI";
import { MenuItem } from "Data/Objects/UI";
import { User } from "Data/Objects/User";
import { getActiveCompanyId, getCompanyOptions } from "Data/Selectors/Company";
import { getConnectTutorials, getInstallTutorials } from "Data/Selectors/System";
import { getActiveNavigationItem, getMenuItems } from "Data/Selectors/UI";
import { getCurrentUser } from "Data/Selectors/User";

const { Item, SubMenu } = Menu;

const mapDispatchToProps = (dispatch) => ({
	setActiveCompanyDetails: (companyId: string) => dispatch(getActiveCompanyDetails(companyId)),
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => dispatch(setMediaPreview(uri, vimeoId, type))
});

const mapStateToProps = (state) => ({
	activeCompany: getActiveCompanyId(state),
	activeNavigationItem: getActiveNavigationItem(state),
	companies: getCompanyOptions(state),
	menuItems: getMenuItems(state),
	user: getCurrentUser(state),
	videos: {
		connect: getConnectTutorials(state),
		install: getInstallTutorials(state)
	}
});

interface MainNavigationProps extends RouteComponentProps<any> {
	activeCompany: string;
	activeNavigationItem: string;
	companies: CompanyMenuOption[];
	menuItems: MenuItem[];
	setActiveCompanyDetails: (companyId: string) => void;
	setMediaPreview: (uri: string, vimeoId: number, type: MediaType) => void;
	user: User;
	videos: {
		connect: VimeoTutorial[];
		install: VimeoTutorial[];
	}
}

export class MainNavigation extends React.PureComponent<MainNavigationProps> {
	constructor(props: MainNavigationProps) {
		super(props);

		this.styles = {
			bugMenu: {
				position: "absolute",
				width: 65
			},
			companyMenu: {
				paddingBottom: 2,
				paddingTop: 2,
				width: 65
			},
			companyOuterMenu: {
				bottom: ENVIRONMENT === ENVIRONMENTS.PRODUCTION ? 130 : 195,
				position: "absolute"
			},
			helpMenu: {
				position: "absolute",
				width: 65
			},
			logoutMenu: {
				position: "absolute",
				width: 65
			},
			icon: {
				display: "inline-block",
				padding: 15
			},
			iconStyle: {
				lineHeight: "1.33em",
				verticalAlign: "initial"
			},
			badge: {
				alignItems: "center",
				justifyContent: "center",
				width: 14,
				height: 14,
				position: "absolute",
				top: 10,
				right: 12,
				fontSize: ".75em",
				background: Colors.red,
				color: Colors.white,
				borderRadius: 90
			}
		};

		this.toggleTutorial = this.toggleTutorial.bind(this);
		this.scrollIntoView = this.scrollIntoView.bind(this);
		this.setActiveCompany = this.setActiveCompany.bind(this);
		this.setScrollToItem = this.setScrollToItem.bind(this);
	}

	scrollToItem: HTMLDivElement;
	scrollTimeout: number;
	styles: CustomCSS;

	componentWillUnmount() {
		clearTimeout(this.scrollTimeout);
	}

	render() {
		return (
			<Menu theme="dark"
				selectedKeys={[ this.props.activeNavigationItem ]}
				mode="vertical"
				onOpenChange={ this.scrollIntoView }
			>
				{this.renderMenuItems()}
				{this.renderCompanyMenu()}
				{this.renderBugMenuItem()}
				{this.renderHelpMenuItem()}
				{this.renderLogoutMenuItem()}
			</Menu>
		);
	}

	renderMenuItems() {
		return this.props.menuItems.map(({ icon, name, path, iconWeight, badge }, i) => {
			return (
				<Item
					className="main-nav-item"
					key={path}
				>
					<div
						className="main-nav-item-badge"
						style={{
							...this.styles.badge,
							display: this.getBadgeDisplay(badge)
						}}
					>
						{ badge }
					</div>
					<Link to={path}>
						<Icon
							name={icon}
							iconWeight={iconWeight}
							size={"smaller"}
							style={this.styles.icon}
							iconStyle={this.styles.iconStyle}/>
						<div className="title">{ name }</div>
					</Link>
				</Item>
			);
		});
	}

	renderCompanyMenu() {
		const { activeCompany, companies } = this.props;

		if (!(companies && companies.length > 1)) {
			return null;
		}

		const { companyMenu, companyOuterMenu, icon } = this.styles;
		const sortedCompanies = Utils.sort(companies, "text", true);

		return (
			<SubMenu
				className="main-nav-company-menu"
				style={ companyOuterMenu }
				title={
					<div
						className="main-nav-item"
						style={ companyMenu }
					>
						<Icon name="building"
							iconWeight="solid"
							size={"smaller"}
							style={icon} />
						<div className="title">Company</div>
					</div>
				}
				key={ activeCompany }
			>
				{sortedCompanies.map((c, i) => {
					const { text, value } = c;
					const active = Boolean(value === activeCompany);
					const companyClass = active ? "ant-menu-item-selected" : "";

					return (
						// highlight the active company in the switcher menu
						<Item
							className={ companyClass }
							key={ value }
							onClick={ this.setActiveCompany(value) }
						>
							<div ref={ active ? this.setScrollToItem : ""}>
								{ text }
							</div>
						</Item>
					);
				})}
			</SubMenu>
		);
	}

	renderLogoutMenuItem() {
		return (
			<Item
				className="main-nav-item logout-menu"
				style={ this.styles.logoutMenu }
			>
				<Link to="/logout">
					<Icon
						name="sign-out-alt"
						size={"smaller"}
						style={this.styles.icon} />
					<div className="title">Log out</div>
				</Link>
			</Item>
		)
	}

	renderHelpMenuItem() {
		return (
			<Item
				className="main-nav-item help-menu"
				style={ this.styles.helpMenu }
			>
				<div onClick={ this.toggleTutorial }>
					<Icon
						name="question-circle"
						size={"smaller"}
						style={this.styles.icon} />
					<div className="title">Help</div>
				</div>
			</Item>
		);
	}

	renderBugMenuItem() {
		if (ENVIRONMENT === ENVIRONMENTS.PRODUCTION) {
			return null;
		}

		return (
			<Item
				className="main-nav-item bug-menu"
				style={ this.styles.bugMenu }
				onClick={ this.openBugReporter }
			>
				<div>
					<Icon
						name="bug"
						size={"smaller"}
						style={this.styles.icon} />
					<div className="title">Bug Report</div>
				</div>
			</Item>
		);
	}

	toggleTutorial() {
		const tutorials = this.props.videos.connect;
		const tutorial = (() => {
			const keys = Object.keys(tutorials);
			const getKeyFromPage = (k) => tutorials[k].page === this.props.activeNavigationItem;
			return Number(keys.filter(getKeyFromPage)[0] || 0);
		})();

		// tutorial 0 is non-existent but used to display the standard "Need Help?" modal when no video exists
		if (tutorial > -1) {
			this.props.setMediaPreview("", tutorial, "video");
		}
	}

	getBadgeDisplay(badge: number = 0) {
		if (badge) {
			return "flex";
		}
		return "none";
	}

	openBugReporter() {
		window.open(
			"https://clintonelectronics.atlassian.net/secure/CreateIssue.jspa?pid=10300&issuetype=10003",
			"_blank",
			"noopener noreferrer"
		);
	}

	scrollIntoView() {
		if (this.scrollToItem) {
			this.scrollTimeout = window.setTimeout(this.scrollToItem.scrollIntoView.bind(this.scrollToItem), 50);
		}
	}

	setActiveCompany(uuid: string) {
		return () => {
			const { activeCompany, setActiveCompanyDetails } = this.props;
			if (uuid === activeCompany) {
				return;
			}
			setActiveCompanyDetails(uuid);
		}
	}

	setScrollToItem(node: HTMLDivElement) {
		if (node) {
			this.scrollToItem = node;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);
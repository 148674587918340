import { Alert } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CloudProviders, CustomCSS, ICompany, IUser } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import QRCode from "Components/Admin/QRCode";
import { Button, Header, Icon, Input } from "Components/Global/Common";
import DeactivateUserButton from "Components/Global/DeactivateUserButton";
import HelpPopover from "Components/Global/HelpPopover";
import { updateCompanyAsync } from "Data/Actions/Company";
import { setPrivacyPolicyModalVisibility, setTermsConditionsModalVisibility } from "Data/Actions/UI/Modals";
import { clearCacheAndLogout } from "Data/Actions/SystemAsync";
import { resendVerification, updateUserInfo } from "Data/Actions/UserAsync";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import inputValidation from "Data/Objects/Validation";
import { getActiveCompany } from "Data/Selectors/Company";
import { getCurrentUser } from "Data/Selectors/User";

const mapDispatchToProps = (dispatch) => ({
	resendVerificationEmail: (user: IUser) => dispatch(resendVerification(user)),
	updateCompany: (company: ICompany) => dispatch(updateCompanyAsync(company)),
	updateUser: (email, name, phone) => dispatch(updateUserInfo(email, name, phone)),
	clearCache: () => dispatch(clearCacheAndLogout()),
	showPrivacyPolicy: () => dispatch(setPrivacyPolicyModalVisibility(true)),
	showTermsAndConditions: () => dispatch(setTermsConditionsModalVisibility(true))
});

const mapStateToProps = (state) => {
	const user = getCurrentUser(state);
	const company = getActiveCompany(state);

	return {
		company,
		user
	};
}

interface AccountPageProps {
	clearCache: () => void;
	company: ICompany;
	resendVerificationEmail: (user: IUser) => Promise<void>;
	updateCompany: (company: ICompany) => void;
	updateUser: (email: string, name: string, phone: string) => void;
	user: IUser;
	showPrivacyPolicy: () => void;
	showTermsAndConditions: () => void;
};

class AccountPage extends React.Component<AccountPageProps> {
	constructor(props: AccountPageProps) {
		super(props);

		this.styles = {
			clearCache: {
				marginBottom: 10
			},
			grid: {
				display: "grid",
				width: "100%",
				gridTemplateColumns: "50% 50%"
			},
			gridChild: {
				display: "flex",
				flexFlow: "column wrap",
				padding: "0px 18px 18px 18px"
			},
			headerStyle: {
				marginLeft: 12,
				paddingBottom: 14
			},
			info: {
				paddingLeft: 26
			},
			label: {
				marginLeft: -6,
				wordBreak: "normal",
				display: "block"
			},
			verificationWarning: {
				marginTop: 20
			},
			privacyTerms: {
				position: "absolute",
				display: "flex",
				cursor: "pointer",
				bottom: 5
			},
			terms: {
				marginRight: 10
			}
		};

		this.clearCache = this.clearCache.bind(this);
		this.emailValidator = this.emailValidator.bind(this);
		this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
		this.updateCompanyName = this.updateCompanyName.bind(this);
		this.updateUser = this.updateUser.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<div style={this.styles.grid}>
				{ this.renderPersonalInfo() }
				{ this.renderCompanyInfo() }
				{ this.renderClearCache() }
			</div>
		);
	}

	renderClearCache() {
		const { gridChild, headerStyle, info, clearCache, privacyTerms, terms } = this.styles;
		const { showTermsAndConditions, showPrivacyPolicy } = this.props;

		return (
			<div style={ gridChild }>
				<Header size={ 3 } style={ headerStyle }>
					Utilities
				</Header>
				<div style={ info }>
					<Button
						icon="recycle"
						style={ clearCache }
						onClick={ this.clearCache }
					>
						Clear Cache
					</Button>
					<p>
					Are you having application issues? Start by clicking this button to clear your local application data.
					You will be logged out during this process. If the problem persists, please contact
						<a
							href="https://clintonelectronics.atlassian.net/servicedesk/customer/portals"
							target="_blank"
							rel="noopener noreferrer"
						>
							{ " Clinton Connect support." }
						</a>
					</p>
				</div>
				<div style={ privacyTerms }>
					<div style={ terms } onClick={ showTermsAndConditions }>Terms & Conditions</div>
					<div onClick={ showPrivacyPolicy }>Privacy Policy</div>
				</div>
			</div>
		);
	}

	renderCompanyInfo() {
		const { gridChild, headerStyle, info, label } = this.styles;
		const canEditCompany = hasPermission(PERMISSIONS.OWN_COMPANIES_EDIT);
		const { company, user } = this.props;

		if (!company) {
			return null;
		}

		const { name, teamType, uuid, cloudServiceProvider } = company;
		const { name: userRole } = user.role;
		const isOwner = userRole === "owner" || hasPermission(PERMISSIONS.USERS_INTEGRATOR_VIEW);
		const isIntegrator = teamType === "integrator";

		return (
			<div style={ gridChild }>
				<Header size={ 3 } style={ headerStyle }>Company Info</Header>
				<div style={ info }>
					<span style={ label }>Company Name:</span>
					<Input
						disabled={ !canEditCompany }
						hideMaxLength={ !canEditCompany }
						id="companyInfoName"
						value={ name }
						saveCallback={ this.updateCompanyName }
						validator={ inputValidation.name }
					/>
					<span style={ label }>Company ID:</span>
					<Input disabled hideMaxLength
						id="companyInfoUUID"
						value={ uuid }
					/>
					<span style={ label }>Cloud Service Provider:</span>
					<Input disabled hideMaxLength
						id="companyInfoCloudServiceProvider"
						value={ CloudProviders[cloudServiceProvider] }
					/>
					<span style={ label }>
						QR Code:
						<HelpPopover title="Company QR Code">
							<p>This QR code is used to register your device(s) with the proper company and integrator.</p>
						</HelpPopover>
					</span>
					<QRCode
						companyId={ uuid }
						showIntegratorSelect={ isOwner && !isIntegrator }
					/>
				</div>
			</div>
		);
	}

	renderPersonalInfo() {
		const { gridChild, headerStyle, info, label } = this.styles;
		const { user } = this.props;
		const { email, name, phone, role, singleSignOn, uuid } = user;
		const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

		if (!user) {
			return null;
		}

		return (
			<div style={ gridChild }>
				<Header size={ 3 } style={ headerStyle }>Personal Info</Header>
				<div style={ info }>
					<span style={ label }>Name:</span>
					<Input
						disabled={ !!singleSignOn }
						id="personalInfoName"
						value={ name }
						saveCallback={ this.updateUser("name") }
						validator={ inputValidation.name }
					/>
					<span style={ label }>
						Email (User Name):
						<HelpPopover title="Email Address">
							<p>Your email address is used to login.</p>
						</HelpPopover>
					</span>
					<Input
						id="personalInfoEmail"
						disabled={ !!singleSignOn }
						saveCallback={ this.updateUser("email") }
						value={ email }
						className="userInfo"
						validator={ inputValidation.email }
					/>
					{ this.renderUserVerificationWarning() }
					<span style={ label }>Phone #:</span>
					<Input
						disabled={ !!singleSignOn }
						hideMaxLength
						id="personalInfoPhone"
						saveCallback={ this.updateUser("phone") }
						value={ phone }
					/>
					<span style={ label }>
						Role:
						<HelpPopover title="User Role">
							<p>Your role is used to determine your user's capabilities within the Connect application.</p>
						</HelpPopover>
					</span>
					<Input disabled hideMaxLength
						id="personalInfoRole"
						value={ role.title }
					/>
					<span style={ label }>User ID:</span>
					<Input disabled hideMaxLength
						id="personalInfoUUID"
						value={ uuid }
					/>
					<span style={ label }>Time Zone:</span>
					<Input disabled hideMaxLength
						id="personalInfoTimeZone"
						value={ timeZone }
					/>
					<DeactivateUserButton user={ user } className="warning-button" />
				</div>
			</div>
		);
	}

	renderUserVerificationWarning() {
		const { user } = this.props

		if (user.verified) {
			return null;
		}

		return (
			<div style={ this.styles.verificationWarning }>
				<Alert type="warning" message={
					<div>
						<Icon name="envelope" iconWeight="regular"/>
						Your email is unverified.
						Please click the link in the email we sent you in order
						to verify this email address belongs to you.
						<p>
							<a onClick={ this.handleResendVerificationEmail }>
								Resend email.
							</a>
						</p>
					</div>
				} />
			</div>
		);
	}

	handleResendVerificationEmail() {
		const { resendVerificationEmail, user } = this.props;
		resendVerificationEmail(user);
	}

	clearCache() {
		this.props.clearCache();
	}

	emailValidator(value: string) {
		return value !== "" && Utils.isValidEmail(value);
	}

	updateCompanyName(value: string) {
		if (!hasPermission(PERMISSIONS.OWN_COMPANIES_EDIT)) {
			return;
		}

		const { company, updateCompany } = this.props;

		updateCompany(Object.assign({}, company, {
			name: value
		}));
	}

	updateUser(key: string) {
		return (value: string) => {
			const { updateUser, user } = this.props;
			const newUser = Object.assign({}, user, { [key]: value });
			const { email, name, phone } = newUser;

			updateUser(email, name, phone);
		};
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);

import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, WidgetType } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Accordion, AccordionElement } from "Components/Global/Accordion";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import ThreeColumnLayout from "Components/Global/ThreeColumnLayout";
import Dashboard from "Components/Home/Dashboard";
import WidgetListItem from "Components/Home/WidgetListItem";
import { WIDGET_CATEGORIES } from "Data/Objects/Widgets";
import { getCurrentUser } from "Data/Selectors/User";

const { white, lightGray } = Colors;

const mapStateToProps = (state) => {
	const { name, settings, permissions } = getCurrentUser(state);

	return { name, settings, userPermissions: permissions };
};

const styles = {
	accordionHeader: {
		color: white,
		fontSize: 16,
		textAlign: "left",
		marginLeft: 5
	},
	accordionSubHeader: {
		color: lightGray,
		textAlign: "left",
		marginLeft: 5
	}
} as CustomCSS;

interface HomePageProps {
	name: string;
	settings: any; // this is typed exactly as User; will be `any` until we provide a better type
	userPermissions: string[];
}

class HomePage extends React.Component<HomePageProps, any> {
	constructor(props: any) {
		super(props);
	}

	accordionElements: AccordionElement[];

	render() {
		return (
			<ThreeColumnLayout
				centerContent={this.renderCenterContent()}
				leftContent={this.renderLeftContent()}
			/>
		);
	}

	renderLeftContent() {
		return (
			<Accordion
				condense
				header={this.renderAccordionHeader()}
				elements={this.createAccordionElements()} />
		);
	}

	renderAccordionHeader() {
		return (
			<div>
				<p style={styles.accordionHeader}>Dashboard Tools</p>
				<p style={styles.accordionSubHeader}>Drag desired widget onto dashboard</p>
			</div>
		);
	}

	renderCenterContent() {
		return (
			<div>
				<Dashboard
					username={this.props.name}
					settings={this.props.settings} />
			</div>
		);
	}

	createAccordionElements() {
		let accordionElements: AccordionElement[] = [];
		Object.keys(WIDGET_CATEGORIES).map((key) => {
			const accordionList = this.createAccordionList(WIDGET_CATEGORIES[key].widgets);
			if (WIDGET_CATEGORIES[key].widgets.length > 0) {
				let newElement = new AccordionElement(
					(
						<div>
							<Icon fixedWidth={true} iconWeight="regular" name={WIDGET_CATEGORIES[key].icon} /> { key }
						</div>
					),
					accordionList,
					WIDGET_CATEGORIES[key].color
				);
				accordionElements.push(newElement);
			}
		});

		return accordionElements.filter(element => element.content.props.children[0] !== null);
	}

	createAccordionList(items: WidgetType[]) {
		const { userPermissions, settings } = this.props;
		const widgetNames = Object.keys(settings.widgets || {});

		return (
			<div>
				{items.map((item) => {
					const { name, permissions } = item;
					if (!Utils.userHasPermissions(permissions, userPermissions)) {
						return null;
					}

					return (
						<WidgetListItem
							used={widgetNames.includes(name)}
							key={name}
							type={name}
						/>
					);
				})}
			</div>
		);
	}
}

export default connect(mapStateToProps)(HomePage);
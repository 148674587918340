import Radium from "radium";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Colors } from "Components/Global/Constants";

const { darkishGray, filters: { hoverAlt }, white } = Colors;

interface DeviceStatusFilterProps {
	onClick: (status: string) => void;
	statusFilter: string[];
}

@Radium
export class DeviceStatusFilter extends React.Component<DeviceStatusFilterProps> {
	constructor(props: DeviceStatusFilterProps) {
		super(props);

		this.deviceStatuses = [
			"Not Assigned to Group",
			"No Active Deployment"
		];

		this.styles = {
			button: {
				border: `1px solid ${ darkishGray }`,
				color: white,
				padding: "8px 16px",
				transition: "background 200ms",
				userSelect: "none",
				":hover": {
					background: hoverAlt,
					cursor: "pointer"
				}
			}
		};

		this.clickButton = this.clickButton.bind(this);
		this.renderDeviceStatusButton = this.renderDeviceStatusButton.bind(this);
	}

	deviceStatuses: string[];
	styles: CustomCSS;

	render() {
		return (
			<div>
				{ this.deviceStatuses.map(this.renderDeviceStatusButton) }
			</div>
		);
	}

	renderDeviceStatusButton(status: string) {
		return (
			<div
				role="button"
				key={ status }
				onClick={ this.clickButton(status) }
				style={ this.getStatusButtonStyle(status) }
			>
				{ status }
			</div>
		);
	}

	clickButton(status: string) {
		return () => this.props.onClick(status);
	}

	getStatusButtonStyle(status: string) {
		return {
			...this.styles.button,
			background: this.props.statusFilter.includes(status) ? hoverAlt : "none"
		}
	}
}

export default DeviceStatusFilter;

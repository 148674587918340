import * as update from "immutability-helper";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, ICompany, IStore } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import AdminStoresTable from "Components/Admin/AdminStoresTable";
import { IBatchOperationsButton } from "Components/Global/BatchOperations";
import { Colors } from "Components/Global/Constants";
import ContentAreaTopBar from "Components/Global/ContentAreaTopBar";
import { deleteStoreAsync } from "Data/Actions/Company";
import { setCreateStoreModal } from "Data/Actions/UI/Modals";
import { setActiveSearch, setActiveSelection } from "Data/Actions/UI";
import { getStores } from "Data/Selectors/Company";
import { getActiveSearch, getActiveSelection } from "Data/Selectors/UI";

const { lightGray, offWhite } = Colors;

const mapStateToProps = (state) => {
	const stores = getStores(state);

	return {
		search: getActiveSearch(state, "stores"),
		selectedStores: getActiveSelection(state, "stores"),
		stores
	}
};

const mapDispatchToProps = (dispatch) => ({
	deleteStore: (currentStore: IStore) => dispatch(deleteStoreAsync(currentStore)),
	selectStores: (storeUuids: string[]) => dispatch(setActiveSelection("stores", storeUuids)),
	setSearch: (query: string) => dispatch(setActiveSearch("stores", query)),
	showModal: () => dispatch(setCreateStoreModal(true))
});

interface AdminStoresPageProps {
	stores: IStore[];
	companies: ICompany[];
	deleteStore: (currentStore: IStore) => void;
	search: string;
	selectedStores: string[];
	selectStores: (storeUuids: string[]) => void;
	setSearch: (query: string) => void;
	showModal: () => void;
}

interface AdminStoresPageState {
	selectModeOn: boolean;
}

class AdminStoresPage extends React.Component <AdminStoresPageProps, AdminStoresPageState> {
	constructor(props: AdminStoresPageProps) {
		super(props);
		this.state = {
			selectModeOn: false
		}

		this.styles = {
			header: {
				padding: "0 30px"
			},
			buttonStyle: {
				color: lightGray,
				background: offWhite
			}
		}

		this.confirmDeleteStores = this.confirmDeleteStores.bind(this);
		this.deselectAllStores = this.deselectAllStores.bind(this);
		this.getBatchOperations = this.getBatchOperations.bind(this);
		this.handleBulkDelete = this.handleBulkDelete.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleStoreSelection = this.handleStoreSelection.bind(this);
		this.selectAllStores = this.selectAllStores.bind(this);
		this.showCreateStoreModal = this.showCreateStoreModal.bind(this);
		this.toggleSelectMode = this.toggleSelectMode.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { selectModeOn } = this.state;
		const { search } = this.props;

		return (
			<div style={ this.styles.header }>
				<ContentAreaTopBar
					batch={ this.getBatchOperations() }
					prefixButtons={ this.getHeaderPrefixButtons() }
					search={{
						filterText: search,
						onSearchChange: this.handleSearchChange
					}}
				/>
				<AdminStoresTable selectModeOn={ selectModeOn } />
			</div>
		);
	}

	confirmDeleteStores () {
		Notifications.confirm(
			"Delete Selected Stores",
			"Are you sure you want to delete the selected stores?",
			"Yes",
			"No",
			this.handleBulkDelete
		);
	}

	deselectAllStores() {
		this.handleStoreSelection([]);
	}

	getBatchOperations() {
		const { selectedStores, stores } = this.props;
		const numSelected = selectedStores.length;
		const batchButtons = [
			{
				disabled: numSelected === stores.length,
				label: "Select All",
				icon: "plus-square",
				iconWeight: "regular",
				onClick: this.selectAllStores
			},
			{
				disabled: !numSelected,
				label: "Deselect All",
				icon: "minus-square",
				iconWeight: "regular",
				onClick: this.deselectAllStores
			},
			{
				disabled: !numSelected,
				label: "Delete Stores",
				icon: "trash",
				iconWeight: "regular",
				onClick: this.confirmDeleteStores,
				type: "danger"
			}
		];

		return {
			active: this.state.selectModeOn,
			batchCallback: this.toggleSelectMode,
			batchLabel: "Select Stores",
			buttons: batchButtons as IBatchOperationsButton[]
		};
	}

	getHeaderPrefixButtons() {
		return [ {
			disabled: this.state.selectModeOn,
			icon: "plus-circle",
			onClick: this.showCreateStoreModal,
			style: this.styles.buttonStyle,
			children: "Add Store",
			className: "primary-button"
		} ];
	}

	handleBulkDelete() {
		let storeUuids = this.props.stores.map((u) => {
			return u.uuid
		});
		this.props.selectedStores.forEach((u) => {
			let store = this.props.stores[storeUuids.indexOf(u)];
			this.props.deleteStore(store);
		});

		this.deselectAllStores();
	}

	handleSearchChange(value: string) {
		this.props.setSearch(value);
	}

	handleStoreSelection(selection: any[]) {
		this.props.selectStores(selection);
	}

	selectAllStores() {
		this.handleStoreSelection(this.props.stores.map((store) => store.uuid));
	}

	showCreateStoreModal() {
		this.props.showModal();
	}

	toggleSelectMode() {
		this.setState(prevState => ({ selectModeOn: !prevState.selectModeOn }));
		this.props.selectStores([]);
	}

	updateStoreData(changedStoreData: IStore) {
		this.setState((prevState) => {
			return update(prevState, {
				createStoreData: { $set: changedStoreData }
			});
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminStoresPage);
import { IPlaylist, IUser, NameUuid, PlaylistAd, PlaylistWithoutAds } from "@connect/Interfaces";

export class Playlist implements IPlaylist {
	actionSet: NameUuid | undefined; // TODO: ensure that this is typed correctly after CON-4888
	ads: PlaylistAd[];
	company: string;
	createdAt: string;
	createdBy?: Partial<IUser>;
	orientation: "portrait" | "landscape";
	name: string;
	updatedAt: string;
	updatedBy?: IUser;
	uuid: string;
}

/**
 * Use this prior to sending a playlist to the API, as it expects to receive "ads" as an array of ad UUIDS only.
 */
export function flattenAds(playlist: IPlaylist): PlaylistWithoutAds {
	const { ads } = playlist;
	return Object.assign({}, playlist, {
		ads: (ads && ads.length) ? [ ...ads ].map(({ uuid }) => uuid) : []
	});
}
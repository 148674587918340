import { Collapse } from "antd";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, IStore, IUser, NameUuid, Team, Filters } from "@connect/Interfaces";
import DeviceIntegratorsFilter from "Components/Devices/DeviceIntegratorsFilter";
import DeviceSizeFilter from "Components/Devices/DeviceSizeFilter";
import DeviceStatusFilter from "Components/Devices/DeviceStatusFilter";
import { Icon } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import TagFilter from "Components/Global/TagFilter";
import { getStoresListAsync } from "Data/Actions/Company";
import { getIntegratorListAsync } from "Data/Actions/Team";
import { setActiveFilterArray, setActiveFilters } from "Data/Actions/UI";
import { hasPermission, PERMISSIONS } from "Data/Objects/Permissions";
import { isCompanyOwner } from "Data/Objects/Roles";
import { getFilteredStores } from "Data/Selectors/Company";
import { getTeamOptions, getTeams } from "Data/Selectors/Team";
import { getActiveFilters } from "Data/Selectors/UI";
import { getCurrentUser } from "Data/Selectors/User";
import { Utils } from "@connect/Utils";

const { Panel } = Collapse;
const { darkestGray, white, black } = Colors;

const mapDispatchToProps = (dispatch) => ({
	fetchStores: () => dispatch(getStoresListAsync()),
	getIntegrators: () => dispatch(getIntegratorListAsync()),
	setIntegratorsFilter: (integrator: string) => dispatch(setActiveFilters("integrator", Filters.DEVICES, integrator)),
	setStoresFilter: (stores: string[]) => dispatch(setActiveFilterArray("stores", Filters.DEVICES, stores)),
	setSizeFilter: (sizes: string[]) => dispatch(setActiveFilterArray("sizes", Filters.DEVICES, sizes)),
	setStatusFilter: (status: string[]) => dispatch(setActiveFilterArray("status", Filters.DEVICES, status))
});

const mapStateToProps = (state) => {
	const filters = getActiveFilters(state, Filters.DEVICES);
	const { integrator, stores, sizes, status } = filters;

	return {
		companies: getTeams(state),
		integratorFilter: integrator,
		integrators: getTeamOptions(state),
		storesFilter: stores,
		sizeFilter: sizes,
		statusFilter: status,
		stores: getFilteredStores(state, ""),
		user: getCurrentUser(state)
	};
};

interface DeviceFiltersProps {
	companies: Team[];
	fetchStores: () => void;
	getIntegrators: () => void;
	integratorFilter: string;
	integrators: NameUuid[];
	setIntegratorsFilter: (integrator: string) => void;
	setStoresFilter: (stores: string[]) => void;
	setSizeFilter: (sizes: string[]) => void;
	setStatusFilter: (status: string[]) => void;
	sizeFilter: string[];
	statusFilter: string[];
	stores: IStore[];
	storesFilter: string[];
	user: IUser;
}

export class UnconnectedDeviceFilters extends React.Component<DeviceFiltersProps> {
	constructor(props: DeviceFiltersProps) {
		super(props);

		this.styles = {
			collapse: {
				display: "flex",
				flexDirection: "column",
				height: "100%"
			},
			header: {
				backgroundColor: darkestGray
			},
			item: {
				color: white,
				height: 35,
				lineHeight: "35px",
				padding: "0px 36px 0px 8px",
				width: "100%"
			},
			leftPadding: {
				paddingLeft: 8
			},
			noBorder: {
				border: 0,
				borderRadius: 0,
				overflow: "hidden"
			},
			input: {
				width: "calc(100% - 12px)",
				padding: "8px 0px",
				margin: "0px 6px"
			},
			clear: {
				position: "absolute",
				right: 34
			},
			select: {
				width: 220
			},
			dark: {
				background: black
			}
		}

		this.clearAllFilters = this.clearAllFilters.bind(this);
		this.clearIntegratorFilter = this.clearIntegratorFilter.bind(this);
		this.clearSizeFilter = this.clearSizeFilter.bind(this);
		this.clearStatusFilter = this.clearStatusFilter.bind(this);
		this.clearStoreFilter = this.clearStoreFilter.bind(this);
		this.handleSetStoresFilter = this.handleSetStoresFilter.bind(this);
		this.renderIntegratorPanel = this.renderIntegratorPanel.bind(this);
		this.renderReportPanelHeader = this.renderReportPanelHeader.bind(this);
		this.setIntegratorId = this.setIntegratorId.bind(this);
		this.setProperty = this.setProperty.bind(this);
		this.setSizeProperty = this.setSizeProperty.bind(this);
		this.setStatusProperty = this.setStatusProperty.bind(this);
	}

	styles: {
		input: CustomCSS;
		clear: CustomCSS;
		select: CustomCSS;
		collapse: CustomCSS;
		noBorder: CustomCSS;
		header: CustomCSS;
		item: CustomCSS;
		leftPadding: CustomCSS;
		dark: CustomCSS;
	}

	componentWillMount() {
		const { companies, getIntegrators } = this.props;
		const canViewIntegrators =  hasPermission(PERMISSIONS.VIEW_COMPANY_INTEGRATIONS);
		const canManageDevices = hasPermission(PERMISSIONS.DEVICES_MANAGE);

		if (!companies.length && canViewIntegrators && canManageDevices) {
			getIntegrators();
		}

		this.props.fetchStores();
	}

	render() {
		const { collapse, noBorder } = this.styles;

		let keys = [ "Device Size", "Stores", "Device Status" ];

		if (hasPermission(PERMISSIONS.VIEW_COMPANY_INTEGRATIONS) && hasPermission(PERMISSIONS.DEVICES_MANAGE)) {
			keys.push("Integrators");
		}

		return (
			<Collapse
				defaultActiveKey={ keys }
				style={{
					...collapse,
					...noBorder
				}}
			>
				{ this.renderDeviceSizePanel() }
				{ this.renderStoresPanel() }
				{ this.renderStatusPanel() }
				{ this.renderIntegratorPanel() }
			</Collapse>
		);
	}

	renderDeviceSizePanel() {
		return this.renderPanel("Device Size", (
			<DeviceSizeFilter
				onClick={ this.setSizeProperty }
				sizeFilter={ this.props.sizeFilter }
			/>
		), this.clearSizeFilter);
	}

	renderIntegratorPanel() {
		const { integratorFilter, integrators, user } = this.props;

		if (!hasPermission(PERMISSIONS.VIEW_COMPANY_INTEGRATIONS) && !isCompanyOwner()) {
			return null;
		}

		return this.renderPanel("Integrators", (
			<DeviceIntegratorsFilter
				integratorFilter={ integratorFilter }
				integrators={ integrators }
				onClick={ this.setIntegratorId }
				user={ user }
			/>
		), this.clearIntegratorFilter);
	}

	renderStoresPanel() {
		const { input, select } = this.styles;
		const { storesFilter, stores } = this.props;
		const sortedStores = Utils.sort(stores, "name", true);
		const nameFilter = sortedStores
			.filter((store) => storesFilter.includes(store.uuid))
			.map((store) => store.name);
		const storeList = sortedStores.map((store) => store.name);

		return this.renderPanel("Stores", (
			<div style={ input }>
				<TagFilter
					key={ nameFilter.length }
					theme="dark"
					style={ select }
					activeTags={ nameFilter }
					tags={ storeList }
					tagSelectChange={ this.handleSetStoresFilter }
					tagType={ "devices" }
				/>
			</div>
		), this.clearStoreFilter);
	}

	renderStatusPanel() {
		return this.renderPanel("Device Status", (
			<DeviceStatusFilter
				onClick={ this.setStatusProperty }
				statusFilter={ this.props.statusFilter }
			/>
		), this.clearStatusFilter);
	}

	renderPanel(header: string, content: JSX.Element, onClear: () => void) {
		const { dark, noBorder } = this.styles;

		return (
			<Panel
				header={ this.renderReportPanelHeader(header, onClear) }
				key={ header }
				style={ noBorder }
			>
				<div style={ dark }>
					{ content }
				</div>
			</Panel>
		);
	}

	renderReportPanelHeader(title: string, onClear: () => void) {
		const { header, item, leftPadding, noBorder, clear } = this.styles;

		return (
			<div style={{
				...header,
				...item,
				...noBorder
			}}>
				<span style={ leftPadding }>{ title }</span>
				<Icon
					style={ clear }
					size="smaller"
					name="times-circle"
					iconWeight="regular"
					onClick={ this.handlePanelHeaderClick(onClear) }
				/>
			</div>
		);
	}

	handlePanelHeaderClick(onClear: () => void) {
		return (e: React.SyntheticEvent) => {
			e.stopPropagation();
			onClear();
		}
	}

	handleSetStoresFilter(tagNames: string[]) {
		const { stores, setStoresFilter } = this.props;
		const storeUuids = stores
			.filter((store) => tagNames.includes(store.name))
			.map((store) => store.uuid);

		setStoresFilter(storeUuids);
	}

	setProperty(prop: string, value: string, setter: Function) {
		return () => {
			const filter = [ ...this.props[prop] ];

			if (filter.includes(value)) {
				filter.splice(filter.indexOf(value), 1);
				return setter(filter);
			}

			filter.push(value);
			setter(filter);
		}
	}

	clearIntegratorFilter() {
		this.props.setIntegratorsFilter("");
	}

	clearStoreFilter() {
		this.props.setStoresFilter([]);
	}

	clearSizeFilter() {
		this.props.setSizeFilter([]);
	}

	clearStatusFilter() {
		this.props.setStatusFilter([]);
	}

	clearAllFilters(e: React.SyntheticEvent) {
		if (e) {
			e.stopPropagation();
		}
		this.clearIntegratorFilter();
		this.clearStoreFilter();
		this.clearSizeFilter();
		this.clearStatusFilter();
	}

	setIntegratorId(uuid: string) {
		return () => {
			const { integratorFilter, setIntegratorsFilter } = this.props;
			const id = integratorFilter === uuid ? "" : uuid;

			setIntegratorsFilter(id);
		};
	}

	setSizeProperty(size: string) {
		this.setProperty("sizeFilter", size, this.props.setSizeFilter)();
	}

	setStatusProperty(status: string) {
		this.setProperty("statusFilter", status, this.props.setStatusFilter)();
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(UnconnectedDeviceFilters);
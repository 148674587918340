import { notification } from "antd";
import * as React from "react";
import { push } from "react-router-redux";
import * as SparkMD5 from "spark-md5";

import { notifyBugSnag } from "@connect/BugSnag";
import { getState, store } from "@connect/Data";
import { CustomCSS, IChunk, IMedia, IMediaUpload, InitiateUploadResult, IUploader, MEDIA_ERRORS,
	MediaType } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import MediaApi from "Api/Media";
import { Button } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";
import { deleteMediaAsync } from "Data/Actions/MediaAsync";
import { endUpload } from "Data/Actions/Media";

const styles = {
	viewDuplicateButton: {
		color: "white",
		marginRight: 10,
		minWidth: 120
	},
	duplicateButtonsContainer: {
		width: "100%",
		textAlign: "right",
		marginTop: 10
	}
} as CustomCSS;

export default class Uploader implements IUploader {
	constructor(file: File) {
		this.file = file;
		this.sparkMD5 = new SparkMD5.ArrayBuffer();
		this.fileMD5 = "";
		this.chunkSize = 5 * 1024 * 1024; // 5MB
		this.totalChunks = Math.floor(file.size / this.chunkSize);
		this.chunkUrl = "";
		this.queue = [];
		this.chunksRead = -1;
		this.totalUploadedBytes = 0;
		this.totalFailCount = 0;
		this.isFailed = false;
		this.isCancelled = false;

		this.uploadChunk = this.uploadChunk.bind(this);
		this.cancelDuplicateUpload = this.cancelDuplicateUpload.bind(this);
		this.forceDuplicateUpload = this.forceDuplicateUpload.bind(this);
	}

	file: File;
	sparkMD5: SparkMD5.ArrayBuffer;
	fileMD5: string;
	chunkSize: number;
	totalChunks: number;
	chunkUrl: string;
	queue: Chunk[];
	chunksRead: number;
	totalUploadedBytes: number;
	totalFailCount: number;
	isFailed: boolean;
	isCancelled: boolean;
	mediaUUID: string;

	onMediaCreated: (uuid: string) => void;
	onProgress: (progress: ProgressEvent) => void;
	onSuccess: (media: IMedia) => void;
	onError: (errorType?: MEDIA_ERRORS, error?: string) => void;

	cancel = () => {
		this.isCancelled = true;
	}

	upload = (mediaType?: MediaType) => {
		const api = new MediaApi();
		// first call the api with the file name to
		// get the endpoint to upload to
		api.initiateMediaUpload(this.file, mediaType)
			.then((result: InitiateUploadResult) => {
				this.mediaUUID = result.uuid;

				if (this.onMediaCreated) {
					this.onMediaCreated(result.uuid);
				}

				this.chunkUrl = result.location;

				// then queue up the chunks for upload
				for (let i = 0; i <= this.totalChunks; i++) {
					let chunkStart = i * this.chunkSize;
					// chunkEnd should either be the calculated chunkEnd
					// or the last byte of the file, whichever is less
					let chunkEnd = ((chunkStart + this.chunkSize) > this.file.size)
						? this.file.size : chunkStart + this.chunkSize;

					let chunk = new Chunk(i, chunkStart, chunkEnd);
					this.queue.push(chunk);
				}

				this.processNextItem();
			}, (error) => {
				this.failFile(error.message);
			})
			.catch(error => notifyBugSnag(new Error(error)));
	}

	processNextItem(error?: string) {
		// if there are items to process,
		// and we aren't in a failed or cancelled state
		// get the first chunk in the queue
		if (this.queue.length && !this.isFailed && !this.isCancelled) {
			let chunk = this.queue.shift() as Chunk;

			// if this chunk has failed 3x fail the whole file
			// or if we've had 6 total fails fail the whole file
			if (chunk.failCount > 2 || this.totalFailCount > 5 || error) {
				this.failFile(error);
			} else if (!chunk.blob) {
				// if this chunk has a blob, it's already been read

				this.readChunk(chunk);
			} else {
				this.uploadChunk(chunk);
			}
		}
	}

	readChunk(chunk: Chunk) {
		let fileReader = new FileReader();
		fileReader.onabort = () => {
			this.failChunk(chunk);
		};
		fileReader.onerror = () => {
			this.failChunk(chunk);
		};
		fileReader.onloadend = (e: any) => {
			if (e.target.readyState === 2) { // 2 === DONE
				// we successfully read this chunk
				this.chunksRead++;
				let arrayBuffer = e.target.result as ArrayBuffer;

				// get the MD5 info we need
				this.sparkMD5.append(arrayBuffer as any); // the ts definition file for this method is wrong
				if (this.chunksRead === this.totalChunks) {
					this.fileMD5 = this.sparkMD5.end();
				}

				let chunkSparkMD5 = new SparkMD5.ArrayBuffer();
				chunkSparkMD5.append(arrayBuffer as any); // the ts definition file for this method is wrong
				chunk.chunkMD5 = chunkSparkMD5.end();

				// keep its blob and put it back
				// on the queue to be uploaded
				chunk.blob = new Blob([ new Uint8Array(arrayBuffer) ]);
				this.queue.push(chunk);
				this.processNextItem();
			} else {
				this.failChunk(chunk);
			}
		}

		let blob = this.file.slice(chunk.chunkStart, chunk.chunkEnd);
		fileReader.readAsArrayBuffer(blob);
	}

	uploadChunk(chunk: Chunk, force?: boolean) {
		const { User: { token }} = getState();
		let headers = {
			"Authorization": `Bearer ${token}`,
			"X-Total-Size": this.file.size.toString(),
			"X-Chunk-Size": chunk.chunkSize.toString(),
			"X-Current-Chunk": chunk.currentChunk.toString(),
			"X-Last-Chunk": this.totalChunks.toString(),
			"X-Current-Chunk-Start": chunk.chunkStart.toString(),
			"X-Current-Chunk-End": chunk.chunkEnd.toString(),
			"X-Current-Chunk-MD5": chunk.chunkMD5
		}

		if (this.fileMD5) {
			headers["X-File-MD5"] = this.fileMD5;
		}

		let formData = new FormData();
		formData.append("file", chunk.blob, this.file.name);

		if (force) {
			this.chunkUrl += "?force=true";
		}

		fetch(this.chunkUrl, {
			method: "POST",
			mode: "cors",
			headers: new Headers(headers),
			body: formData
		})
			.then((res) => {
				if (res.status === 201) { // that was the final chunk... we're done
					this.reportProgress(chunk);
					if (this.onSuccess) {
						res.json()
							.then((media: IMedia) => {
								this.onSuccess(media);
							}, (error) => {
								this.failFile(error.message);
							})
							.catch(error => notifyBugSnag(new Error(error)));
					}
				} else if (res.status === 202) { // chunk was accepted
					this.reportProgress(chunk);
					this.processNextItem();
				} else {
					res.json()
						.then((error) => {
							if (error.error === "possible_duplicate") {
								// Enter the duplicate media workflow
								Notifications.warning(
									"Duplicate File " + this.file.name,
									this.renderDuplicateNotification(
										() => {
											this.cancelDuplicateUpload(error.uuids[0]);
										},
										() => {
											this.forceDuplicateUpload(chunk);
										}
									),
									0,
									() => {
										this.dismissDuplicateUpload(error.uuids[0]);
									},
									this.mediaUUID
								);
							} else {
								this.failChunk(chunk, error.message);
							}
						});
				}
			}, (error) => {
				this.failChunk(chunk);
			})
			.catch(error => notifyBugSnag(new Error(error)));
	}

	renderDuplicateNotification(confirmCallback: () => void, cancelCallback: () => void) {
		return (
			<div>
				It looks like this file has already been uploaded to your media library.
				<br />
				<div style={styles.duplicateButtonsContainer}>
					<Button
						onClick={ confirmCallback }
						color={ Colors.primaryBlue }
						style={styles.viewDuplicateButton}
						corners="rounded">
						View
					</Button>
					<Button
						onClick={ cancelCallback }
						corners="rounded">
						Upload Anyway
					</Button>
				</div>
			</div>
		);
	}

	cancelDuplicateUpload(duplicateUUID: string) {
		if (this.mediaUUID) {
			this.dismissDuplicateUpload(duplicateUUID);
			store.dispatch(push(`/media/${duplicateUUID}`));
		}
	}

	forceDuplicateUpload(chunk: Chunk) {
		this.uploadChunk(chunk, true);
		notification.close(this.mediaUUID);
	}

	dismissDuplicateUpload(duplicateUUID: string) {
		if (this.mediaUUID) {
			notification.close(this.mediaUUID);
			store.dispatch(endUpload({ uuid: this.mediaUUID } as IMediaUpload));
			store.dispatch(deleteMediaAsync(this.mediaUUID, true));
		}
	}

	reportProgress(chunk: Chunk) {
		this.totalUploadedBytes += chunk.chunkSize;
		let progressEvent = new ProgressEvent("file-upload-progress", {
			lengthComputable: true,
			loaded: this.totalUploadedBytes,
			total: this.file.size
		});

		if (this.onProgress) {
			this.onProgress(progressEvent);
		}
	}

	failChunk(chunk: Chunk, error?: string) {
		this.totalFailCount++;
		chunk.failCount++;
		this.queue.push(chunk);
		this.processNextItem(error);
	}

	failFile = (error: string = "") => {
		this.isFailed = true;
		if (this.onError) {
			if (/resolution/.test(error)) {
				this.onError(MEDIA_ERRORS.VIDEO_RESOLUTION_ERROR);
			}
			this.onError(undefined, error);
		}
	}
}
class Chunk implements IChunk {
	constructor(currentChunk: number, chunkStart: number, chunkEnd: number) {
		this.currentChunk = currentChunk;
		this.chunkStart = chunkStart;
		this.chunkEnd = chunkEnd;
		this.chunkSize = chunkEnd - chunkStart;
	}

	currentChunk: number;
	chunkStart: number;
	chunkEnd: number;
	chunkSize: number;
	blob: Blob;
	chunkMD5: string;
	failCount: number = 0;
}
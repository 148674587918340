import * as React from "react";
import Waypoint from "react-waypoint";

import { CustomCSS, Deployment } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Icon, Link, Loader, Truncate } from "Components/Global/Common";
import { Colors } from "Components/Global/Constants";

const { black, primaryBlue } = Colors;

interface IFutureDeploymentsProps {
	deployments: Deployment[];
	haveAllDeployments: boolean;
	onFetchDeployments: () => void;
}

export default class FutureDeployments extends React.Component<IFutureDeploymentsProps> {
	constructor(props: IFutureDeploymentsProps) {
		super(props);

		this.styles = {
			container: {
				width: "100%",
				margin: 10,
				overflowY: "auto"
			},
			deploymentRow: {
				borderBottom: `1px solid ${black}`,
				display: "flex",
				justifyContent: "space-between",
				padding: "10px 0"
			},
			deploymentRowContent: {
				display: "flex",
				justifyContent: "space-around",
				marginLeft: "2.3em"
			},
			deploymentRowContentInner: {
				flexGrow: 1
			},
			deploymentRowLeft: {
				flexGrow: 2
			},
			deploymentRowTitle: {
				color: primaryBlue
			},
			waypoint: {
				textAlign: "center",
				width: "100%"
			}
		};

		this.renderDeployment = this.renderDeployment.bind(this);
		this.renderNoData = this.renderNoData.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { deployments, haveAllDeployments, onFetchDeployments } = this.props;
		const { container, waypoint } = this.styles;

		return (
			<div style={ container }>
				<div>
					{
						deployments.length
							? deployments.map(this.renderDeployment)
							: this.renderNoData()
					}
				</div>
				<div style={{
					...waypoint,
					display: !haveAllDeployments && deployments ? "inline-block" : "none"
				}}>
					<Waypoint onEnter={ onFetchDeployments } />
					<Loader />
				</div>
			</div>
		);
	}

	renderDeployment(row: Deployment, index: number, allDeps: Deployment[]) {
		const {
			deploymentRow,
			deploymentRowContent,
			deploymentRowContentInner,
			deploymentRowLeft,
			deploymentRowTitle
		} = this.styles;
		const { uuid, name, type } = row;
		const isEvent = type === "event";
		const icon = isEvent ? "exclamation-triangle" : "calendar";

		return (
			<div
				key={ uuid }
				style={ deploymentRow }>
				<div style={ deploymentRowLeft }>
					<h2 style={ deploymentRowTitle }>
						<Link to={ `/deploy/${ uuid }` }>
							<Icon name={ icon } /> <Truncate length={ 40 }>{ name }</Truncate>
						</Link>
					</h2>
					<div style={ deploymentRowContent }>
						<div style={ deploymentRowContentInner }>Deploys { Utils.getHumanReadableDate(row.startDate) }</div>
					</div>
				</div>
			</div>
		);
	}

	renderNoData() {
		return (
			<p>
				There are no upcoming deployments.
			</p>
		);
	}
}

import { ErrorResultV2, IStore, IStoresApi, StoresResult, HeaderObject } from "@connect/Interfaces";
import ApiV2 from "Api/ApiV2";

export default class StoresApi extends ApiV2 implements IStoresApi {
	getStores(page?: number, perPage?: number): Promise<StoresResult> {
		const url = "stores";
		let attrs = {
			perPage: perPage || 200,
			page: page || 1
		};

		return this.GET(url, attrs) as Promise<StoresResult>;
	}

	getStore(uuid: string) {
		return this.GET(`stores/${ uuid }`) as Promise<IStore>;
	}

	createStore(store: IStore) {
		return this.POST("stores", store) as Promise<IStore>;
	}

	createBulkStores(storesCSV: string) {
		return this.POST("stores/import", storesCSV, false, {
			"Content-Type": "multipart/form-data"
		} as HeaderObject, true) as Promise<any>;
	}

	deleteStore(store: IStore) {
		return this.DELETE(`stores/${ store.uuid }`) as Promise<ErrorResultV2>;
	}

	updateStore(store: IStore) {
		return this.PUT(`stores/${ store.uuid }`, store) as Promise<ErrorResultV2>;
	}

}

import { TimePicker } from "antd";
import * as moment from "moment";
import * as React from "react";

import { CustomCSS, IStore } from "@connect/Interfaces";
import { Notifications } from "@connect/Notifications";
import { Button } from "Components/Global/Common";

const displayFormat = "hh:mm a";
const timeFormat = "HH:mm";

export interface IStoreHoursProps {
	fieldSpacing?: number;
	store: IStore;
	updateStore: (field: string) => (value: boolean | number | string) => void;
}

export class StoreHours extends React.PureComponent<IStoreHoursProps> {
	constructor(props: IStoreHoursProps) {
		super(props);

		const margin = `${ props.fieldSpacing || 10}px 0`;

		this.styles = {
			buttonField: {
				margin,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "100%"
			},
			centered: {
				margin: "3px auto"
			},
			control: {
				display: "block"
			},
			helpText: {
				padding: "3px 0"
			},
			hoursField: {
				width: "48%"
			},
			label: {
				wordBreak: "normal"
			},
			picker: {
				margin: "3px 0",
				width: "100%"
			},
			section: {
				display: "block"
			},
			storeHours: {
				display: "flex",
				justifyContent: "space-between"
			},
			usageHelpContent: {
				maxWidth: "50vw",
				padding: 10
			}
		};

		this.renderHours = this.renderHours.bind(this);
		this.renderSleepModeButton = this.renderSleepModeButton.bind(this);
		this.setPickerState = this.setPickerState.bind(this);
		this.setSleepMode = this.setSleepMode.bind(this);
	}

	styles: CustomCSS;

	render() {
		return (
			<div style={ this.styles.section }>
				<div style={ this.styles.storeHours }>
					{ this.renderHours("openTime") }
					{ this.renderHours("closeTime") }
				</div>
				{ this.renderSleepModeButton() }
			</div>
		);
	}

	renderHours(field: string) {
		const { store } = this.props;
		const fieldName = field === "openTime" ? "Store Open" : "Store Closed";
		const { control, hoursField, label, picker } = this.styles;
		const timeValue = store[field] || "00:00";
		const momentValue = moment(timeValue, timeFormat);

		return (
			<div style={ hoursField }>
				<span style={ label }>{ fieldName }:</span>
				<TimePicker
					allowClear={ false }
					defaultValue={ momentValue }
					format={ displayFormat }
					onChange={ this.setPickerState(field) }
					style={{ ...control, ...picker }}
					use12Hours
					value={ momentValue }
				/>
			</div>
		);
	}

	renderSleepModeButton() {
		const { buttonField, centered, control } = this.styles;
		const { closeTime, openTime, sleepModeEnabled } = this.props.store;
		const text = `${ sleepModeEnabled ? "Disable" : "Enable" } Sleep Mode`;
		const buttonDisabled = openTime === closeTime;

		return (
			<div style={ buttonField }>
				<Button
					disabled={ buttonDisabled }
					icon="moon"
					onClick={ this.setSleepMode }
					style={{ ...centered, ...control }}
				>
					{ text }
				</Button>
			</div>
		);
	}

	renderSleepModeDialog() {
		const { helpText } = this.styles;
		return (
			<React.Fragment>
				<p style={ helpText }>All Devices assigned to this store will enter sleep mode during non-store hours.</p>
				<p style={ helpText }>During sleep mode the screen will turn off 1 hour after closing and will turn back on
					1 hour before the store opens.</p>
				<p style={ helpText }>While in sleep mode if triggered by an event such as motion,{" "}
					face detection or alarm input &mdash; the screen will automatically turn on.
					Triggers must first be setup in <a href="/actions" target="_blank" rel="noopener noreferrer">Actions</a>{" "}
					and assigned to <a href="/playlists" target="_blank" rel="noopener noreferrer">Playlists</a>{" "}
					that are deployed to these devices.</p>
				<p style={ helpText }>Sleep mode can be disabled on individual devices by locating that device on the{" "}
					<a href="/devices" target="_blank" rel="noopener noreferrer">Devices</a> page and turning Sleep Mode off in
					the Device Overview/Settings.
				</p>
			</React.Fragment>
		);
	}

	setPickerState(field: string) {
		return (value: moment.Moment) => {
			const newTime = value.format(timeFormat); // we get a string value but it is formatted in the `displayFormat`
			const updateField = this.props.updateStore(field);

			updateField(newTime);
		};
	}

	setSleepMode(event: React.SyntheticEvent<Button>) {
		event.preventDefault();

		const { store, updateStore } = this.props;
		const key = "sleepModeEnabled";
		const value = store[key];
		const updateSleepMode = () => updateStore(key)(!value);

		// if enabled, allow them to turn it off, otherwise show a confirmation dialog
		if (value) {
			updateSleepMode();
		} else {
			Notifications.confirm(
				"Confirm Sleep Mode",
				this.renderSleepModeDialog(),
				"Confirm",
				"Cancel",
				updateSleepMode
			);
		}
	}
}

export default StoreHours;

import * as moment from "moment";
import * as React from "react";

import { CustomCSS } from "@connect/Interfaces";
import { Utils } from "@connect/Utils";
import { Colors } from "Components/Global/Constants";

const { gray, lightGray, offWhite, white } = Colors;

interface AnalyticsTooltipProps {
	date?: string;
	label?: string;
	text?: string;
	combineDates?: boolean;
	value?: string | number;
}

export default class AnalyticsTooltip extends React.Component<AnalyticsTooltipProps> {
	constructor(props: AnalyticsTooltipProps) {
		super(props);

		this.styles = {
			container: {
				background: white,
				border: `1px solid ${ offWhite }`,
				borderRadius: 3,
				color: lightGray,
				padding: 10
			},
			content: {
				color: gray,
				fontWeight: "bold"
			}
		};
	}

	styles: CustomCSS;

	render() {
		const { container } = this.styles;
		const date = this.getDateString();
		const label = (
			<React.Fragment>
				{ this.renderLabel() }
				{ this.renderContent() }
			</React.Fragment>
		);

		return (
			<div style={ container }>
				<span>{ date }</span>
				<br />
				{ label }
				{ this.renderText() }
			</div>
		);
	}

	renderContent() {
		const { value } = this.props;
		const { content } = this.styles;

		if (!content) {
			return null;
		}

		return <span style={ content }>{ `: ${ value }` }</span>
	}

	renderLabel() {
		const { label } = this.props;
		if (!label) {
			return null;
		}

		return <span>{ Utils.properCase(label) }</span>
	}

	renderText() {
		const { text } = this.props;
		if (!text) {
			return null;
		}

		return <span>{ text }</span>;
	}

	getDateString() {
		const { date, combineDates } = this.props;
		const moDate = moment(date);
		const formatted = moDate.format("MMM DD");

		if (!combineDates) {
			return formatted;
		}

		return formatted + " - " + moDate.add(7, "d").format("MMM DD");
	}
}

import { Action, AdAnalyticsNotification, AdDispatch, AdListDispatch, AdUuidDispatch, AnalyticsDispatch,
	AnalyticsNotificationDispatch, AnalyticsReportDownloadingDispatch, AnalyticsResult, IAd } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { DispatchableAction } from "Data/Objects/DispatchableAction";

const {
	CREATE_AD,
	DELETE_AD,
	RESET_ADS,
	RESET_ANALYTICS,
	SET_ADS,
	SET_ANALYTICS_REPORT_PENDING,
	SET_NOTIFICATION_SETTINGS,
	UPDATE_AD,
	UPDATE_AD_ANALYTICS,
	UPDATE_AD_ANALYTICS_NOTIFICATION_SETTINGS
} = ACTION_TYPES.Ads

function createAd(ad: IAd): Action<AdDispatch> {
	return new DispatchableAction(CREATE_AD, { ad });
}

function deleteAd(uuid: string): Action<AdUuidDispatch> {
	return new DispatchableAction(DELETE_AD, { uuid });
}

function resetAds(): Action<null> {
	return new DispatchableAction(RESET_ADS, null);
}

function resetAdAnalytics(): Action<null> {
	return new DispatchableAction(RESET_ANALYTICS, null);
}

function setAds(ads: IAd[], reset?: boolean): Action<AdListDispatch> {
	return new DispatchableAction(SET_ADS, { ads, reset })
}

function setAnalyticsReportPending(uuid: string, value: boolean): Action<AnalyticsReportDownloadingDispatch> {
	return new DispatchableAction(SET_ANALYTICS_REPORT_PENDING, { uuid, value });
}

function setNotification(
	uuid: string,
	settings: AdAnalyticsNotification
): Action<AnalyticsNotificationDispatch> {
	return new DispatchableAction(SET_NOTIFICATION_SETTINGS, { uuid, settings });
}

function updateAd(ad: IAd): Action<AdDispatch> {
	return new DispatchableAction(UPDATE_AD, { ad });
}

function updateAdAnalytics(uuid: string, analyticsResult: AnalyticsResult): Action<AnalyticsDispatch> {
	return new DispatchableAction(UPDATE_AD_ANALYTICS, { uuid, analyticsResult });
}

function updateNotification(
	uuid: string,
	settings: AdAnalyticsNotification
): Action<AnalyticsNotificationDispatch> {
	return new DispatchableAction(UPDATE_AD_ANALYTICS_NOTIFICATION_SETTINGS, { uuid, settings });
}

export {
	createAd,
	deleteAd,
	resetAds,
	resetAdAnalytics,
	setAds,
	setAnalyticsReportPending,
	setNotification,
	updateAd,
	updateAdAnalytics,
	updateNotification
}
import * as moment from "moment";

const ONE_MINUTE = 1000 * 60 * 1;

export default class Perishable<T> {

	constructor(value: T, timeout?: number) {
		this._value = value;

		if (timeout) {
			this._expiration = moment.now() + timeout;
		} else {
			this._expiration = moment.now() + ONE_MINUTE;
		}

		this.isExpired = this.isExpired.bind(this);
	}

	_value: T;
	_expiration: number;

	get value(): T | null {
		if (moment.now() > this._expiration) {
			return null;
		}
		return this._value;
	}

	isExpired(): boolean {
		return moment.now() > this._expiration;
	}
}
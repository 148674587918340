import { ActionType } from "@connect/Interfaces";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";

const {
	SET_SELECTED_ACTION_COMPONENT
} = ACTION_TYPES.UI.ActionSetBuilder;

function setSelectedActionComponent(triggerIndex: number, actionType?: ActionType) {
	return {
		type: SET_SELECTED_ACTION_COMPONENT.type,
		args: {
			triggerType: triggerIndex,
			actionType: actionType
		}
	}
}

export {
	setSelectedActionComponent
}
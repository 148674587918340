import * as React from "react";
import { connect } from "react-redux";

import { ActionSet, Context, IPlaylist } from "@connect/Interfaces";
import ActionsPage from "Components/Actions/ActionsPage";
import { Modal } from "Components/Global/Common";
import { setSelectActionsModal } from "Data/Actions/UI/Modals";
import { updatePlaylistAsync } from "Data/Actions/Playlists";
import { getCurrentSelectActionsModalContext, getSelectActionsModalVisibility } from "Data/Selectors/UI";
import { getActivePlaylist } from "Data/Selectors/Playlists";

const mapStateToProps = (state) => {
	return {
		activePlaylist: getActivePlaylist(state),
		context: getCurrentSelectActionsModalContext(state),
		visible: getSelectActionsModalVisibility(state)
	}
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(setSelectActionsModal(false, "")),
	updatePlaylist: (playlist: IPlaylist) => dispatch(updatePlaylistAsync(playlist))
});

interface ISelectActionsModalProps {
	activePlaylist?: IPlaylist;
	context: Context;
	hideModal: () => void;
	visible: boolean;
	onActionSelected: (action: ActionSet) => void;
	updatePlaylist: (playlist: IPlaylist) => Promise<void>;
}

export class UnwrappedSelectActionsModal extends React.PureComponent<ISelectActionsModalProps> {
	constructor(props: ISelectActionsModalProps) {
		super(props);
		this.handleSelectPlaylistAction = this.handleSelectPlaylistAction.bind(this);
		this.hide = this.hide.bind(this);
		this.onActionSelected = this.onActionSelected.bind(this);
	}

	render() {
		const { context, visible } = this.props;

		return (
			<Modal
				fullScreen
				modalKey={ `select_actions_modal_${ String(visible) }` }
				// can't put width inline-- ant modal doesn't respect it in the inline style
				width="100%"
				visible={ visible }
				onCancel={ this.hide }
			>
				<ActionsPage
					context={ context }
					key={ context }
					onActionSelected={ this.onActionSelected }
					onCloseModal={ this.hide }
				/>
			</Modal>
		);
	}

	onActionSelected(action: ActionSet) {
		switch (this.props.context) {
			case Context.PLAYLISTS:
				return this.handleSelectPlaylistAction(action);
			default:
				return null;
		}
	}

	handleSelectPlaylistAction(action: ActionSet) {
		const { activePlaylist, updatePlaylist } = this.props;
		const { name, uuid } = action;

		if (activePlaylist) {
			updatePlaylist(Object.assign({}, activePlaylist, {
				actionSet: { name, uuid }
			})).then(() => {
				this.hide();
			});
		}
	}

	hide() {
		this.props.hideModal();
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedSelectActionsModal);
import { Progress } from "antd";
import * as React from "react";

interface IUploadProps {
	percent: number;
}

export default class Upload extends React.PureComponent<IUploadProps> {
	render() {
		return (
			<div style={{ width: "80%" }}>
				<Progress showInfo={false} percent={this.props.percent} />
			</div>
		);
	}
}